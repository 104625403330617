import styled from 'styled-components';
import { Form } from 'antd';

export const DarkForm = styled(Form)`
  * {
    color: ${(props) => props.theme.textColor};
  }

  .ant-form-horizontal {
    .ant-form-item {
      flex-direction: row;

      .ant-form-item-label {
        margin-bottom: 0 !important;
        margin-left: 0;
      }
    }
  }
`;