import React, { useContext } from 'react';
// import darkTheme from '../../styles/dark';
import lightTheme from '../../styles/light';

const defaultTheme = {
  '@layout-header-background': '#38344B',
  '@layout-trigger-background': '@layout-header-background',
  '@menu-dark-submenu-bg': 'darken(@layout-header-background, 5%)',
  '@global-foreground-color': '#38344b',
  '@global-background-color': '#2a2837',
  '@global-dark-primary-color': '#4c4766',
  '@global-dark-secondary-color': '#38344b',
  '@global-dark-highlight-color': '#736b99',
  '@global-action-color': '#1890ff',
  '@global-text-color': '#f5f5f5',
  '@global-text-contrast-color': '#f5f5f5',
  '@global-text-foreground-color': '#f5f5f5'
};

const ThemeContext = React.createContext(defaultTheme);

export const getTheme = () => {
  const theme = JSON.parse(localStorage.getItem('theme')) || defaultTheme;

  const currentTheme = theme || {};
  return { ...lightTheme, ...currentTheme };
};

export const setTheme = (theme) => {
  if (theme) {
    localStorage.setItem('theme', JSON.stringify(theme));
  } else {
    localStorage.removeItem('theme');
  }

  const newTheme = getTheme();
  applyTheme(newTheme);
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const applyTheme = (theme) => {
  const metaThemeColor = document.querySelector('meta[name=theme-color]');
  metaThemeColor.setAttribute('content', theme['@layout-header-background']);

  return window.less.modifyVars(theme);
};

export default ThemeContext;
