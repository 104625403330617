import React from 'react';

import ItemComunicado from './ComunicadosItem';
import ItemConteudos from './ConteudosItem';

export default function Item({ item, usuario, isFiltering, comunicados = false, categoriaPai }) {
  if (comunicados) {
    return <ItemComunicado item={ item }/> ;
  }
  return <ItemConteudos item={item} usuario={usuario} isFiltering={isFiltering} categoriaPai={categoriaPai} />;
}
