const typesPergunta = {
  MULTIPLA_ESCOLHA: 'M',
  SOMATORIA: 'S',
};

const selectPergunta = [
  { key: typesPergunta.MULTIPLA_ESCOLHA, value: typesPergunta.MULTIPLA_ESCOLHA, title: 'Escolha única' },
  { key: typesPergunta.SOMATORIA, value: typesPergunta.SOMATORIA, title: 'Caixa de seleção' },
];

const getMaxOrdem = (data) => Math.abs(Math.max(...data.map((row) => (row.ordem ? row.ordem : 0)))) + 1;

export { typesPergunta, selectPergunta, getMaxOrdem };
