import React from 'react';
import styled from 'styled-components';
import { Button, List, Modal, Rate, Spin, Text, View } from '@components';
import { Icon } from '@components-teammove';
import { apiGetFeedback } from '@ducks/wikis';
import WikiUsuario from '../Visualizacao/Usuario';

const NotaFeedback = styled(Rate)`
  color: #e7711b !important;
  > li:not(:last-child) {
    margin-right: 2px;
  }
`;

const TextNota = styled(Text)`
  color: #e7711b !important;
  padding: 4px 8px 0px 0px;
`;

const TextData = styled(Text)`
  margin-top: 17px;
`;

const TextDescricao = styled(Text)`
  font-size: 16px;
`;

const Container = styled(View)`
  width: 100%;
`;

export default async function WikiFeedbacks(id) {
  const modal = Modal.info({
    title: (
      <View horizontal alignItems="center" justifyContent="space-between">
        <Text>Feedbacks</Text>
        <Button
          type="icon"
          icon={<Icon.CloseOutlined />}
          onClick={() => {
            close();
          }}
        />
      </View>
    ),
    content: (
      <View>
        <Spin />
      </View>
    ),
    maskClosable: true,
    className: 'modal-top no-buttons',
    icon: null,
    onCancel() {
      modal.destroy();
    },
  });

  const close = () => {
    modal.destroy();
  };

  const feedbacks = await apiGetFeedback(id);

  modal.update({
    content: (
      <List
        itemLayout="vertical"
        size="small"
        dataSource={feedbacks}
        renderItem={(item) => (
          <List.Item>
            <Container key={item.cdUsuario} horizontal alignItems="flex-start" justifyContent="space-between">
              <WikiUsuario
                marginTop={10}
                usuario={{
                  nome: item.nmUsuario,
                  avatar: item.avatarUrl,
                  perfil: item.descricaoPerfil,
                  corPerfil: item.corPerfil,
                }}
              >
                <View horizontal alignItems="center">
                  <TextNota strong>{item.notaFeedback.toFixed(1)}</TextNota>
                  <NotaFeedback disabled defaultValue={item.notaFeedback} />
                </View>
                <TextDescricao>{item.textoFeedback}</TextDescricao>
              </WikiUsuario>
              <TextData>{item.dataHora}</TextData>
            </Container>
          </List.Item>
        )}
      />
    ),
  });
}
