const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const findWithIndex = (list, cbFunction) => {
  for (let index = 0; index < list?.length; index++) {
    const item = list[index];
    const result = cbFunction(item);
    if (result) {
      return { ...item, index };
    }
  }
};

const getMinId = (data) => data.length ? Math.abs(Math.min(...data.map((row) => (row.id ? row.id : 0)))) * -1 - 1 : -1;

export { reorder, getMinId, findWithIndex };
