import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row } from '@components-teammove';
import { getIdApiDuplicados } from '@ducks/configuracoesUnidadesIdApiDuplicados';

const IdApiDuplicados = () => {
  const dispatch = useDispatch();

  const idApiDuplicados = useSelector(({ configuracoesUnidadesIdApiDuplicados }) => configuracoesUnidadesIdApiDuplicados.get('idApiDuplicados').toArray());
  const loadingIdApiDuplicados = useSelector(({ configuracoesUnidadesIdApiDuplicados }) => configuracoesUnidadesIdApiDuplicados.get('loadingIdApiDuplicados'));

  useEffect(() => {
    dispatch(getIdApiDuplicados());
  }, []);

  const columns = [
    {
      title: 'Id API',
      dataIndex: 'idApi',
      sorter: true,
      render: (text) => (
        <Row justify='space-between'>
          {text}
        </Row>
      ),
    },
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: true,
      render: (text) => (
        <Row justify='space-between'>
          {text}
        </Row>
      ),
    },
    {
      title: 'Identificador',
      dataIndex: 'identifier',
      sorter: true,
      render: (text) => (
        <Row justify='space-between'>
          {text}
        </Row>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      render: (text) => (
        <Row justify='space-between'>
          {text}
        </Row>
      ),
    },
  ];

  return(
    <Table
      columns={columns}
      dataSource={idApiDuplicados}
      loading={loadingIdApiDuplicados}
      rowKey="idApi"
    />
  );
};

export default IdApiDuplicados;