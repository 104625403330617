import React from 'react';
import { View } from '@components-teammove';
import { Item } from './styles';

const TooltipItem = ({ titulo, descricao }) => (
  <View>
    <Item strong>
      {titulo}
    </Item>
    <Item>
      {descricao}
    </Item>        
  </View>
);

export default TooltipItem;