import { moment } from '@utils';

export const formatParams = (params) => {
  const {
    initialDate,
    typePeriod,
    companies, 
    typesActivities,
    typesCompanies,
    users
  } = params;

  const formatStartDate = () => {
    const currentDate = moment();
    const initialFormatedDate = moment(initialDate);

    const getPeriod = () => {
      if(typePeriod === 'DIARIO') {
        return 'day';
      }else if(typePeriod === 'SEMANAL') {
        return 'week';
      }else if(typePeriod === 'MENSAL') {
        return 'month';
      }
    };

    const isAfterCondition = currentDate.isAfter(initialFormatedDate, getPeriod());
    const isBeforeCondition = currentDate.isBefore(initialFormatedDate, getPeriod());

    if(isAfterCondition) {
      return initialFormatedDate.endOf(getPeriod()).format('YYYY-MM-DD HH:mm:ss');
    }else if(isBeforeCondition) {
      return initialFormatedDate.startOf(getPeriod()).format('YYYY-MM-DD HH:mm:ss');
    }else {
      return currentDate.format('YYYY-MM-DD HH:mm:ss');
    }
  };

  const formatTypePeriod = (typePeriod) => {
    if(typePeriod === 'DIARIO') {
      return 'daily';
    }else if(typePeriod === 'SEMANAL') {
      return 'weekly';
    }else if(typePeriod === 'MENSAL') {
      return 'monthly';
    }
  };

  const initialDateParam = initialDate ? `startDate=${formatStartDate()}` : '';
  const typePeriodParam = typePeriod ? `periodType=${formatTypePeriod(typePeriod)}` : '';
  const companiesFilter = companies ? `companies=${companies.join(' ')}` : '';
  const typesActivitiesFilter = typesActivities ? `activitiesKinds=${typesActivities.join(' ')}` : '';
  const usersFilter = users ? `users=${users.join(' ')}` : '';
  const typesCompaniesFilter = typesCompanies ? `companiesKinds=${typesCompanies.join(' ')}` : '';

  return [
    initialDateParam,
    typePeriodParam,
    companiesFilter,
    typesActivitiesFilter,
    usersFilter,
    typesCompaniesFilter,
  ]
    .filter((param) => param)
    .join('&');
};
