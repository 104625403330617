import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { saveAtributo as saveAtributoSdk } from '@sdk/Usuarios';

//Action Types
export const Types = {
  SAVE: 'CONFIGURACOES_USUARIOS_ATRIBUTO/SAVE',
  SAVE_SUCCESS: 'CONFIGURACOES_USUARIOS_ATRIBUTO/SAVE_SUCCESS',
  SAVE_ERROR: 'CONFIGURACOES_USUARIOS_ATRIBUTO/SAVE_ERROR',

  RESET: 'CONFIGURACOES_USUARIOS_ATRIBUTO/RESET',
};

//Action Creators
export const saveAtributo = (atributo) => ({ type: Types.SAVE, atributo });
export const saveAtributoSuccess = () => ({ type: Types.SAVE_SUCCESS });
export const saveAtributoError = (error) => ({ type: Types.SAVE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchSaveAtributo(action) {
  try {
    const { atributo } = action;

    yield call(saveAtributoSdk, atributo);
    yield put(saveAtributoSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(saveAtributoError(err));
  }
}

export const saga = [
  takeLatest(Types.SAVE, fetchSaveAtributo),
];

// Reducer
const initialState = Map({
  loading: false,
  success: false,
  error: false,
});

const handleSaveAtributo = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleSaveAtributoSuccess = (state, action) => {
  return state
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleSaveAtributoError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SAVE: return handleSaveAtributo(state, action);
    case Types.SAVE_SUCCESS: return handleSaveAtributoSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveAtributoError(state, action);
    case Types.RESET: return initialState;

    default: return state;
  }
}
