import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification , moment } from '@utils';
import {
  getReport as getReportSdk,
} from '@sdk/Report';

//Action Types
export const Types = {
  GET_REPORT: 'REPORT/GET_REPORT',
  GET_REPORT_SUCCESS: 'REPORT/GET_REPORT_SUCCESS',
  GET_REPORT_ERROR: 'REPORT/GET_REPORT_ERROR',

  UPDATE_REPORT: 'REPORT/UPDATE_REPORT',
  UPDATE_REPORT_SUCCESS: 'REPORT/UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_ERROR: 'REPORT/UPDATE_REPORT_ERROR',

  RESET: 'REPORT/RESET',
};

//Action Creators
export const getReport = (start, end) => ({ type: Types.GET_REPORT, start, end });
export const getReportSuccess = (report) => ({ type: Types.GET_REPORT_SUCCESS, report });
export const getReportError = (error) => ({ type: Types.GET_REPORT_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetReport(action) {
  try {
    const { start, end } = action;

    const report = yield call(getReportSdk, start, end);
    yield put(getReportSuccess(report));
  } catch (err) {
    Notification.error(err.message);
    yield put(getReportError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_REPORT, fetchGetReport),
];

// Reducer
const initialState = Map({
  report: {},

  loading: false,
  success: false,
  error: false,
});

const handleGetReport = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetReportSuccess = (state, action) => {
  const { report } = action;

  report.days.sort((a, b) => moment(a.date).diff(moment(b.date)));

  return state
    .set('report', report)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetReportError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_REPORT: return handleGetReport(state, action);
    case Types.GET_REPORT_SUCCESS: return handleGetReportSuccess(state, action);
    case Types.GET_REPORT_ERROR: return handleGetReportError(state, action);

    case Types.RESET: return initialState;

    default: return state;
  }
}
