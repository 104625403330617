import { Modal } from 'antd';
import styled, { css } from 'styled-components';
import './styles.css';

const ModalStyled = styled(Modal)`
  ${(props) =>
  props.top &&
    css`
      top: 20px;
    `}
`;

export default ModalStyled;
