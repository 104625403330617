import styled from 'styled-components';
import { Image, Icon } from '@components-teammove';

export const Label = styled.span`
  font-size: 18px;
  font-weight: 700;
  padding-top: 8px;
  color: ${({ theme }) => theme.textColor};
  font-family: 'Open Sans', sans-serif;
`;

export const CardTitle = styled(Label)`
  font-family: 'Open Sans', sans-serif;
`;

export const MyIntegrationCard = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 18px 28px 18px 16px;
  border-radius: 16px;
`;

export const IntegrationPicture = styled(Image)`
  width: 48px;
  height: 48px;
`;

export const IntegrationDescription = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const IntegrationEmail = styled(IntegrationDescription)`
  opacity: .5;
`;

export const CloseIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
`;