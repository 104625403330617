import styled from 'styled-components';
import { View, Text, Row, Icon } from '@components-teammove';

export const ItemView = styled(Row)`
  padding: 10px;
  
  :not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  }
`;

export const BadgeCount = styled.span`
  margin-left: 12px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const CaminhoCategoriaView = styled(Text)`
  flex: 1;
`;

export const CategoriaView = styled(View)`
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 250px;
`;

export const CaminhoView = styled(View)`
  font-size: 12px;
  color: #777777;
`;

export const Accesses = styled(Row)`
  gap: 4px;
  align-items: center;
  padding: 7px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 27px;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const AccessTotal = styled.span`
  color: ${({ theme }) => theme.textColor};
`;