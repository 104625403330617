import { formatDate } from '../../../utils/Datas';

export const initialWorkingDays = (startAt, endAt) => {
  const arr = [];
  const dt = new Date(startAt);
  
  for(arr, dt; dt <= new Date(endAt); dt.setDate(dt.getDate() + 1)) {  
    const dayName = dt.toDateString().substring(0, 3);
    if (dayName === 'Sat' || dayName === 'Sun') continue;

    arr.push(formatDate(dt));
  }

  return arr;

};