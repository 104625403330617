import React from 'react';
import { View } from '@components-teammove';
import styled from 'styled-components';

const ViewTitle = styled(View)`
  font-size: 20px;
  padding: 15px 0 5px 0;
  color: #262626;
  width: fit-content;
  border-bottom: 1px solid #f7f7f7;
  flex-direction: column;
`;

const ViewAction = styled(View)`
  cursor: pointer;
  color: #1890ff;
  font-size: 14px;
`;

const Title = ({ children, action, actionTitle, ...otherProps }) => {
  return (
    <ViewTitle {...otherProps}>
      <View>{children}</View>
      {action && <ViewAction onClick={action}>{actionTitle}</ViewAction>}
    </ViewTitle>
  );
};

export default Title;
