import React from 'react';
import { GoogleMap } from '@components-teammove';

export default function Endereco({ company }) {
  
  return(
    <GoogleMap
      center={{ lat: parseFloat(company.latitude) || 0, lng: parseFloat(company.longitude) || 0 }} 
      zoom={15} 
      mapStyles={{ height: '100%', width: '100%', borderRadius: '12px', marginBottom: '12px' }} 
      marker={{ position: { lat: parseFloat(company.latitude) || 0, lng: parseFloat(company.longitude) || 0 } }}
    />
  );
}