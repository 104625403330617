import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getDashboard as getDashboardSdk,
  getParticipationsPerFamilies as getParticipationsPerFamiliesSdk,
  getCards as getCardsSdk,
  getRankingProducts as getRankingProductsSdk,
  getRankingClients as getRankingClientsSdk,
} from '@sdk/DashboardPedidos';

export const FILTERS_KEY = 'FILTROS/DASHBOARD_PEDIDOS';
export const PARAMS_KEY = 'FILTROS/DASHBOARD_PEDIDOS_PARAMS';

//Action Types
export const Types = {
  GET_DASHBOARD: 'PEDIDOS_DASHBOARD/GET_DASHBOARD',
  GET_DASHBOARD_SUCCESS: 'PEDIDOS_DASHBOARD/GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_ERROR: 'PEDIDOS_DASHBOARD/GET_DASHBOARD_ERROR',

  GET_PARTICIPATIONS_PER_FAMILIES: 'PEDIDOS_DASHBOARD/GET_PARTICIPATIONS_PER_FAMILIES',
  GET_PARTICIPATIONS_PER_FAMILIES_SUCCESS: 'PEDIDOS_DASHBOARD/GET_PARTICIPATIONS_PER_FAMILIES_SUCCESS',
  GET_PARTICIPATIONS_PER_FAMILIES_ERROR: 'PEDIDOS_DASHBOARD/GET_PARTICIPATIONS_PER_FAMILIES_ERROR',

  GET_CARDS: 'PEDIDOS_DASHBOARD/GET_CARDS',
  GET_CARDS_SUCCESS: 'PEDIDOS_DASHBOARD/GET_CARDS_SUCCESS',
  GET_CARDS_ERROR: 'PEDIDOS_DASHBOARD/GET_CARDS_ERROR',

  GET_RANKING_PRODUCTS: 'PEDIDOS_DASHBOARD/GET_RANKING_PRODUCTS',
  GET_RANKING_PRODUCTS_SUCCESS: 'PEDIDOS_DASHBOARD/GET_RANKING_PRODUCTS_SUCCESS',
  GET_RANKING_PRODUCTS_ERROR: 'PEDIDOS_DASHBOARD/GET_RANKING_PRODUCTS_ERROR',

  GET_RANKING_CLIENTS: 'PEDIDOS_DASHBOARD/GET_RANKING_CLIENTS',
  GET_RANKING_CLIENTS_SUCCESS: 'PEDIDOS_DASHBOARD/GET_RANKING_CLIENTS_SUCCESS',
  GET_RANKING_CLIENTS_ERROR: 'PEDIDOS_DASHBOARD/GET_RANKING_CLIENTS_ERROR',
};

//Action Creators
export const getDashboard = (params) => ({ type: Types.GET_DASHBOARD, params });
export const getDashboardSuccess = (data) => ({ type: Types.GET_DASHBOARD_SUCCESS, data });
export const getDashboardError = (error) => ({ type: Types.GET_DASHBOARD_ERROR, error });

export const getParticipationsPerFamilies = (params) => ({ type: Types.GET_PARTICIPATIONS_PER_FAMILIES, params });
export const getParticipationsPerFamiliesSuccess = (data) => ({ type: Types.GET_PARTICIPATIONS_PER_FAMILIES_SUCCESS, data });
export const getParticipationsPerFamiliesError = (error) => ({ type: Types.GET_PARTICIPATIONS_PER_FAMILIES_ERROR, error });

export const getCards = (params) => ({ type: Types.GET_CARDS, params });
export const getCardsSuccess = (data) => ({ type: Types.GET_CARDS_SUCCESS, data });
export const getCardsError = (error) => ({ type: Types.GET_CARDS_ERROR, error });

export const getRankingProducts = (params) => ({ type: Types.GET_RANKING_PRODUCTS, params });
export const getRankingProductsSuccess = (data) => ({ type: Types.GET_RANKING_PRODUCTS_SUCCESS, data });
export const getRankingProductsError = (error) => ({ type: Types.GET_RANKING_PRODUCTS_ERROR, error });

export const getRankingClients = (params) => ({ type: Types.GET_RANKING_CLIENTS, params });
export const getRankingClientsSuccess = (data) => ({ type: Types.GET_RANKING_CLIENTS_SUCCESS, data });
export const getRankingClientsError = (error) => ({ type: Types.GET_RANKING_CLIENTS_ERROR, error });

//saga
function* fetchDashboardSaga(action) {
  try {
    const { params } = action;       
    const resp = yield call(getDashboardSdk, params);
    yield put(getDashboardSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDashboardError(err));
  }
}

function* fetchParticipationsPerFamiliesSaga(action) {
  try {
    const { params } = action;       
    const resp = yield call(getParticipationsPerFamiliesSdk, params);
    yield put(getParticipationsPerFamiliesSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getParticipationsPerFamiliesError(err));
  }
}

function* fetchCardsSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getCardsSdk, params);
    yield put(getCardsSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCardsError(err));
  }
}

function* fetchRankingProductsSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getRankingProductsSdk, params);
    yield put(getRankingProductsSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    console.error(err);
    yield put(getRankingProductsError(err));
  }
}

function* fetchRankingClientsSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getRankingClientsSdk, params);
    yield put(getRankingClientsSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getRankingClientsError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_DASHBOARD, fetchDashboardSaga),
  takeLatest(Types.GET_PARTICIPATIONS_PER_FAMILIES, fetchParticipationsPerFamiliesSaga),
  takeLatest(Types.GET_CARDS, fetchCardsSaga),
  takeLatest(Types.GET_RANKING_PRODUCTS, fetchRankingProductsSaga),
  takeLatest(Types.GET_RANKING_CLIENTS, fetchRankingClientsSaga),
];

// Reducer
const initialState = Map({  
  dashboard: List(),
  loading: false,
  success: false,
  error: false,

  participationsPerFamilies: List(),
  loadingParticipationsPerFamilies: false,
  successParticipationsPerFamilies: false,
  errorParticipationsPerFamilies: false,

  cards: List(),
  loadingCards: false,
  successCards: false,
  errorCards: false,

  rankingProducts: List(),
  loadingRankingProducts: false,
  successRankingProducts: false,
  errorRankingProducts: false,

  rankingClients: List(),
  loadingRankingClients: false,
  successRankingClients: false,
  errorRankingClients: false,
});

const handleGetDashboard = (state, action) => {
  return state    
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetDashboardSuccess = (state, action) => {
  return state
    .set('dashboard', List(action.data))
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetDashboardError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleGetParticipationsPerFamilies = (state, action) => {
  return state    
    .set('loadingParticipationsPerFamilies', true)
    .set('successParticipationsPerFamilies', false)
    .set('errorParticipationsPerFamilies', false);
};

const handleGetParticipationsPerFamiliesSuccess = (state, action) => {
  return state
    .set('participationsPerFamilies', List(action.data))
    .set('loadingParticipationsPerFamilies', false)
    .set('successParticipationsPerFamilies', true)
    .set('errorParticipationsPerFamilies', false);
};

const handleGetParticipationsPerFamiliesError = (state, action) => {
  return state
    .set('loadingParticipationsPerFamilies', false)
    .set('successParticipationsPerFamilies', false)
    .set('errorParticipationsPerFamilies', action.error);
};

const handleGetCards = (state, action) => {
  return state    
    .set('loadingCards', true)
    .set('successCards', false)
    .set('errorCards', false);
};

const handleGetCardsSuccess = (state, action) => {
  return state
    .set('cards', action.data)
    .set('loadingCards', false)
    .set('successCards', true)
    .set('errorCards', false);
};

const handleGetCardsError = (state, action) => {
  return state
    .set('loadingCards', false)
    .set('successCards', false)
    .set('errorCards', action.error);
};

const handleGetRankingProducts = (state, action) => {
  return state
    .set('loadingRankingProducts', true)
    .set('successRankingProducts', false)
    .set('errorRankingProducts', false);
};

const handleGetRankingProductsSuccess = (state, action) => {
  return state
    .set('rankingProducts', action.data)
    .set('loadingRankingProducts', false)
    .set('successRankingProducts', true)
    .set('errorRankingProducts', false);
};

const handleGetRankingProductsError = (state, action) => {
  return state
    .set('loadingRankingProducts', false)
    .set('successRankingProducts', false)
    .set('errorRankingProducts', action.error);
};

const handleGetRankingClients = (state, action) => {
  return state    
    .set('loadingRankingClients', true)
    .set('successRankingClients', false)
    .set('errorRankingClients', false);
};

const handleGetRankingClientsSuccess = (state, action) => {
  return state
    .set('rankingClients', action.data)
    .set('loadingRankingClients', false)
    .set('successRankingClients', true)
    .set('errorRankingClients', false);
};

const handleGetRankingClientsError = (state, action) => {
  return state
    .set('loadingRankingClients', false)
    .set('successRankingClients', false)
    .set('errorRankingClients', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DASHBOARD: return handleGetDashboard(state, action);
    case Types.GET_DASHBOARD_SUCCESS: return handleGetDashboardSuccess(state, action);
    case Types.GET_DASHBOARD_ERROR: return handleGetDashboardError(state, action);

    case Types.GET_PARTICIPATIONS_PER_FAMILIES: return handleGetParticipationsPerFamilies(state, action);
    case Types.GET_PARTICIPATIONS_PER_FAMILIES_SUCCESS: return handleGetParticipationsPerFamiliesSuccess(state, action);
    case Types.GET_PARTICIPATIONS_PER_FAMILIES_ERROR: return handleGetParticipationsPerFamiliesError(state, action);

    case Types.GET_CARDS: return handleGetCards(state, action);
    case Types.GET_CARDS_SUCCESS: return handleGetCardsSuccess(state, action);
    case Types.GET_CARDS_ERROR: return handleGetCardsError(state, action);

    case Types.GET_RANKING_PRODUCTS: return handleGetRankingProducts(state, action);
    case Types.GET_RANKING_PRODUCTS_SUCCESS: return handleGetRankingProductsSuccess(state, action);
    case Types.GET_RANKING_PRODUCTS_ERROR: return handleGetRankingProductsError(state, action);

    case Types.GET_RANKING_CLIENTS: return handleGetRankingClients(state, action);
    case Types.GET_RANKING_CLIENTS_SUCCESS: return handleGetRankingClientsSuccess(state, action);
    case Types.GET_RANKING_CLIENTS_ERROR: return handleGetRankingClientsError(state, action);

    default: return state;
  }
}
