import React from 'react';
import { Form, Input, Collapse, Field, InputNumber, GroupField, View } from '@components';
import { Icon , ColorPicker } from '@components-teammove';

import styled from 'styled-components';

const StyledGroupField = styled(GroupField)`
  border-bottom: 2px solid #aaa;
  margin-bottom: 10px;
`;

const IconDelete = styled(Icon.DeleteOutlined)`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const AdicionarFeedback = styled(View)`
  width: fit-content;
  padding: 10px 10px 10px 0;
`;

const StyledField = styled(Field)`
  margin-bottom: 0;
`;

const ViewDelete = styled(View)`
  height: fit-content;
`;

export default function Feedbacks() {
  return (
    <Collapse defaultActiveKey='feedbacks-1'>
      <Collapse.Panel header={<strong>Feedback Geral</strong>} key='feedbacks-1'>
        <Form.List name='feedbacks'>
          {(fields, { add, remove }) => {
            let index = 0;

            return (
              <>
                {fields.map((field) => {
                  index += 1;
                  return (
                    <StyledGroupField key={`fb-${index}`}>
                      <Field
                        label='Feedback'
                        name={[field.name, 'feedback']}
                        rules={[{ required: true, message: 'Informe o feedback' }]}
                        flex={12}
                      >
                        <Input.TextArea rows={2} />
                      </Field>

                      <Field
                        label='% Máximo'
                        name={[field.name, 'prMaximo']}
                        rules={[{ required: true, message: 'Informe o percentual limite' }]}
                      >
                        <InputNumber
                          min={1}
                          max={100}
                          formatter={(value) => `até ${value}%`}
                          parser={(value) => value.replace('%', '').replace('até ', '')}
                        />
                      </Field>

                      <Field label='Cor' name={[field.name, 'cor']} valuePropName='color' getValueFromEvent={(e) => e.hex}>
                        <ColorPicker.CirclePicker
                          colors={[
                            '#d50000',
                            '#ff6d00',
                            '#ffab00',
                            '#ffd600',
                            '#00c853',
                            '#00bfa5',
                            '#00b8d4',
                            '#304ffe',
                            '#6200ea',
                            '#aa00ff',
                          ]}
                          width='125px'
                          circleSize={20}
                          circleSpacing={5}
                        />
                      </Field>

                      <View justifyContent='center'>
                        <ViewDelete>
                          {/*eslint-disable-next-line*/}
                          <IconDelete onClick={() => remove(field.name)} className='action-element' />
                        </ViewDelete>
                      </View>
                    </StyledGroupField>
                  );
                })}
                <StyledField>
                  {/*eslint-disable-next-line*/}
                  <AdicionarFeedback onClick={() => add()} horizontal alignItems='center' className='action-element'>
                    <Icon.PlusOutlined />
                    &nbsp;Feedback
                  </AdicionarFeedback>
                </StyledField>
              </>
            );
          }}
        </Form.List>
      </Collapse.Panel>
    </Collapse>
  );
}
