import styled from 'styled-components';
import { Row, Column, Text, Empty, Icon } from '@components-teammove';
import { lighten } from 'polished';

export const TheRunHeader = styled(Row)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const RowStyle = styled(Row)`
  ${({ lastChild }) => lastChild && `
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  `};
  border: ${({ active })=> active ? '2px var(--super-success-color) solid' : '' };
  :hover {
		cursor: pointer;
    background-color: ${({ theme, color }) => color === '1' ? lighten(0.085, theme.darkSecondaryColor) : lighten(0.085,theme.darkPrimaryColor)};
	}
  padding: 0 60px 0 12px;
  background-color: ${({ theme, color }) => color === '1' ? theme.darkSecondaryColor : theme.darkPrimaryColor};
`;

export const PanelTheRun = styled(Column)`
  
`;

export const VerticalDivider = styled.div`
  border-left: 2px solid ${({ color })=> color || '#D9D9D9' };
  height: 44px;
`;

export const Div = styled.div`
  height: 10px;
  width: 100%;
  background-color: #000000;
`;

export const Typography = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ maxWidth }) => maxWidth && maxWidth};
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 150px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Dot = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color ? color : '#FFCC00'};
`;

export const LineLegend = styled.div`
  border: none;
  ${({ vertical, typeLine, color, theme }) => `
    ${vertical ? 'border-left' : 'border-top'}: 2px ${typeLine ? typeLine : 'solid'} ${color ? color : theme.textColor};
  `}
  width: ${({ vertical })=> vertical ? '3px' : '12px'};
  height: ${({ vertical })=> vertical ? '12px' : '2px'};
`;

export const HorizontalDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.textColor};
  opacity: 0.5;
  width: 100%;
  padding: 0 12px;
`;

export const Marker = styled(Icon.MDI)`
  color: ${({ color, theme })=> color ? color : theme.textColor};
`;