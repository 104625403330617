import React, { useState } from 'react';
import { ColorFormItem, DivRow, FlexDivRow, QuestionActionButton } from './styles';
import { Form, Input, Picker, Icon, Skeleton, Button, Row } from '@components-teammove';
import { arrayReorder } from '@utils';

const Classifications = ({ opcoesAtivas, loadingDel, handleDelete, handleNew, setOpcoesAtivas, data, idList, setIdList, classificacao, setClassificacao, form, loading, success, loadingSave, handleSalvar, onDelete, children, ...props }) => {
  const [idLoadingDell, setIdLoadingDel] = useState();

  const handleReorder = (oldOrder, newOrder) => {
    setClassificacao(() => {
      const newOpcoes =
        arrayReorder(classificacao.opcoes, oldOrder, newOrder)
          .map((item, index) => ({ ...item, order: index }));
      return { ...classificacao, opcoes: newOpcoes };
    });
  };

  const handleInputChange = (id, field, value) => {
    setClassificacao((prevClassificacoes) => {
      const updatedOpcoes = prevClassificacoes.opcoes.map((item) =>
        item.tempId === id ? { ...item, [field]: value } : item
      );
      return { ...prevClassificacoes, opcoes: updatedOpcoes };
    });
  };

  return(
    <Form layout='vertical' form={form}>
      {loading ? <Skeleton.Form /> : (
        <Form.Provider>
          {classificacao?.opcoes?.map((item, index) => (
          
            <>
              <FlexDivRow  key={item.tempId}>
                <Form.Item name={`name${item.tempId}`} rules={[{ required: true, whitespace: true, message: 'Informe o tipo!' }]}>
                  <Input
                    onChange={(e) => handleInputChange(item.tempId, 'description', e.target.value)}
                    placeholder='Informe o nome'
                    autoFocus
                  />
                </Form.Item>

                <DivRow>
                  <ColorFormItem
                    name={`cor${item.tempId}`}
                    valuePropName='color'
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[`cor${item.tempId}`] !== currentValues[`cor${item.tempId}`]
                    }
                    rules={[{ required: true, message: 'Insira uma cor' }]}
                  >
                    <Picker
                      onChange={(cor) => handleInputChange(item.tempId, 'color', cor)}
                      size='small'
                    />
                  </ColorFormItem>
                  <QuestionActionButton type='secondary' size='small' icon={<Icon.UpOutlined />} title='Mover para cima' disabled={index === 0} onClick={() => handleReorder(item.order, item.order - 1)} />
                  <QuestionActionButton type='secondary' size='small' icon={<Icon.DownOutlined />} title='Mover para baixo' disabled={index === opcoesAtivas?.length - 1} onClick={() => handleReorder(item.order, item.order + 1)} />
                  <QuestionActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} disabled={item.existsInContato} title={item.existsInContato ? 'Não é possível excluir classificação em uso' : 'Excluir'} onClick={() => {setIdLoadingDel(index);handleDelete(item, index);}} loading={loadingDel && idLoadingDell === index}/>
                </DivRow>
              </FlexDivRow>
            </>
                    
          ))}
          <Row gap='12px'>
            <Button context='header' fillWidth size='small' type='secondary' onClick={()=> handleNew()}>+ adicionar classificação</Button>
            <Button context='header' fillWidth size='small' type='primary' onClick={()=> handleSalvar()} loading={loadingSave}>Salvar</Button>
          </Row>
        </Form.Provider>
      )}
      
    </Form>
  );
  
};

export default Classifications;