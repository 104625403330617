import styled, { css } from 'styled-components';
import { List, View, Image } from '@components';
import { Link } from 'react-router-dom';

export const ListItem = styled(List.Item)`
  color: #262626 !important;
  transition: 0.2s;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  
  :hover {
    color: #1890ff !important;
    background-color: #f7f9f9 !important;
  }
`;

export const LinkTrail = styled(Link)`
  color: #262626 !important;
  transition: 0.2s;
  width: 100%;
`;

export const ViewTrail = styled(View)`
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
  !props.ativo &&
    css`
      opacity: 0.7;
      text-decoration: line-through;
    `};
`;

export const ImageTrail = styled(Image)`
  width: 100px;
  height: 80px;
  border-radius: 3px 3px 0px 0px;
  margin-right: 10px;
`;