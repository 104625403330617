import styled from 'styled-components';
import { Image, Icon } from '@components-teammove';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
`;

export const Container = styled(Column)`
  gap: 28px;
`;

export const ContentColumn = styled(Column)`
  gap: 8px;
`;

export const ContentTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
`;

export const FoldersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ActionsContainer = styled(Column)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  gap: 8px;
`;

export const FavoriteIndicator = styled(Icon.Bootstrap)`
  color: var(--warning-color);
`;

export const ToggleReadIndicator = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const ActionIndicatorBox = styled.div`
  padding: 6px 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  height: 28px;
  display: ${({ favorite, read }) => (favorite) ? 'flex' : 'none'};
  align-items: center;

  ${FavoriteIndicator} {
    color: ${({ favorite, theme }) => favorite ? 'var(--warning-color)' : theme.textColor};

    :hover {
      color: var(--warning-color);
    }
  }
`;

export const FolderCard = styled(Row)`
  position: relative;
  gap: 12px;
  align-items: center;
  border-radius: 12px;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  cursor: pointer;
  transition: background-color .25s;

  :hover {
    background-color: ${({ theme }) => theme.darkHighlightColor};

    ${ActionsContainer} {
      top: 10px;
      right: 10px;

      ${ActionIndicatorBox} {
        display: flex;
        background-color: ${({ theme }) => theme.darkPrimaryColor};
      }
    }
  }

  ${({ favorite }) => favorite && `
    ${ContentTitle} {
      max-width: 85%;
    }
  `}
`;

export const FolderCardSkeleton = styled(FolderCard)`
  height: 86px;
  pointer-events: none;
  background-color: transparent;
  padding: 0;
`;

export const FolderImage = styled(Image)`
  border-radius: 8px;
  max-height: 65px;
  max-width: 88px;
`;

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const ItemCard = styled(Column)`
  position: relative;
  border-radius: 12px;
  padding: 8px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  gap: 8px;
  cursor: pointer;
  transition: background-color .25s;
  :hover {
    background-color: ${({ theme }) => theme.darkHighlightColor};

    ${ActionIndicatorBox} {
      display: flex;
    }
  }
`;

export const ItemCardSkeleton = styled(ItemCard)`
  height: 172px;
  pointer-events: none;
  background-color: transparent;
  padding: 0;
`;

export const ItemImage = styled(Image)`
  border-radius: 8px;
  max-width: 100%;
  height: 114px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const ItemTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 166px;
  @supports not (-webkit-line-clamp: 1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 
`;

export const ItemSummary = styled.span`
  font-size: 10px;
  font-weight: 500;
  opacity: .5;
  color: ${({ theme }) => theme.textColor};
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 166px;
  @supports not (-webkit-line-clamp: 1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

`;

export const NoItemImage = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 114px;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentPath = styled.span`
  color: ${({ theme }) => theme.textColor};
  overflow: hidden;
  max-width: 255px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  opacity: .5;
`;