import { get, post } from '../api';
import { InfoUsuario } from '@utils';

export const getActivityCheckInOut = () => {
  const useApiNode = true;
  return get(`/api/current/activity?userId=${InfoUsuario.get('cdUsuario')}`,{}, useApiNode);
};

export const postCheckInOut = (isCheckInOut, idActivityTemp, body) => {
  return post(`/api/tarefa/check${isCheckInOut.toLowerCase()}/${idActivityTemp}`, body);
};

