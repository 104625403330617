import { get, post } from '@sdk/api';
import { InfoUsuario } from '@utils';

export const getQuiz = async (id) => {
  const data = await get(`/api/wiki/ead/quiz/tentativas/${id}?usuario=${InfoUsuario.get('cdUsuario')}`);
  data.perguntas = data.perguntas.sort((a, b) => a.ordem - b.ordem);
  return data;
};

export const postNovaTentativa = async (data) => {
  return post('/api/wiki/ead/quiz/tentativas/novo', data);
};

export const postResponder = async (data) => {
  return post('/api/wiki/ead/quiz/tentativas/responder', data);
};

export const postFinalizarTentativa = async (id, desistencia) => {
  return post(`/api/wiki/ead/quiz/tentativas/finalizar/${id}${desistencia ? '?desistencia=S' : ''}`);
};

export const getTentativaConcluida = async (id) => {
  return get(`/api/wiki/ead/quiz/tentativa/${id}`);
};

export const getListaTentativasQuiz = async (id) => {
  return await get(`/api/wiki/ead/quiz/historico/tentativas/${id}`);
};
