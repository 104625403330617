import { del, get, post, put } from '../api';

export const getAgrupadores = () => {
  return get('/api/campaigns/roles');
};

export const postAgrupador = (tipo) => {
  return post('/api/campaigns/roles', tipo);
};

export const updateAgrupador = (tipo) => {
  return put('/api/campaigns/roles', tipo);
};

export const deleteAgrupador = (id) => {
  return del(`/api/campaigns/roles/${id}`);
};

export const getAgrupador = (externalId) => {
  return get(`/api/campaigns/roles/${externalId}`);
};