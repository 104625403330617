import styled from 'styled-components';
import { View } from '@components-teammove';

export const PageContainer = styled(View)`
  gap: 20px;
`;

export const InfoContainerView = styled(View)`
  gap: 12px;
  ${({ preventGrowOnOverflow }) => preventGrowOnOverflow && `
    overflow-x: auto;
  `}
`;

export const LargerInfoContainerView = styled(InfoContainerView)`
  gap: 20px;
`;

export const Row = styled(View)`
  flex-direction: row;
  gap: 12px;
`;

export const GridRow = styled(View)`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 2fr;
`;

export const AddressView = styled(View)`
  max-height: 750px;
  gap: 10px;
  border-radius: 18px;
  padding: 20px 16px 16px 16px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  flex: 1;
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const VerticalMargin = styled(View)`
  margin-bottom: 20px;
`;