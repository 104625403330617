import styled from 'styled-components';
import { Pagination } from 'antd';
import { Text } from '@components-teammove';

export const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    background-color: unset !important;
    border:unset !important;
  }

  .ant-pagination-item a {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 0.5 !important;
  }

  .ant-pagination-item-active a {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 1.0 !important;
  }

  .ant-pagination-item a:hover {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-item:hover a {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-item-active {
    background: ${({ theme }) => theme.actionColor} !important;
  }

  .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color: ${({ theme }) => theme.textColor} !important;
    background-color: ${({ theme }) => theme.actionColor} !important;
    border-radius: 6px !important;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 0.5 !important;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 0.5 !important;
    user-select: none !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border: none !important;
    color: ${({ theme }) => theme.textColor} !important;
    user-select: none !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.darkHighlightColor} !important;
  }

  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.actionColor} !important;
    
  }

  .ant-select-item {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.actionColor} !important;
    color: ${({ theme }) => theme.textColor} !important;
    font-weight: 400 !important;
  }
`;

export const TextStyle = styled(Text)`
  user-select: none !important;
`;