import React from 'react';
import { Style } from './styles';
import { Popconfirm } from 'antd';

const StyledPopConfirm = ({ children, ...otherProps }) => (
  <Popconfirm
    okText="Confirma"
    cancelText="Cancela"
    {...otherProps}
  >
    {children}
  </Popconfirm>
);

const PopConfirm = ({ children, ...props }) => (
  <>
    <StyledPopConfirm {...props}>
      {children}
    </StyledPopConfirm>
    <Style/>
  </>
);

export default PopConfirm;