import styled from 'styled-components';
import View from '../View';

export const ContainerColor = styled(View)`
  cursor: pointer;  
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.darkHighlightColor};
  min-width: 56px;
  min-height: 56px;
  background: ${(props) => props.color || props.theme.darkPrimaryColor};
`;

export const ContentPopover = styled(View)`
  position: absolute;
  z-index: 2;
`;

export const ContentCover = styled(View)`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;