import styled from 'styled-components';
import { Text, Icon } from '@components-teammove';

export const TitleText = styled(Text)`
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    line-height: 27.24px;
`;

export const IconContainer = styled.div`
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ borderColor, theme }) => borderColor === 'green' ? 'var(--success-color)' : borderColor === 'red' ? 'var(--danger-color)' : borderColor === 'white' ? theme.textColor : theme.darkPrimaryColor};
    cursor: pointer;
`;

export const IconFeedback = styled(Icon.Feather)`
    font-size: 32px;
    color: ${({ colorIcon, theme }) => colorIcon === 'green' ? 'var(--success-color)' : colorIcon === 'red' ? 'var(--danger-color)' : theme.textColor};
`;

export const TextFeedback = styled(Text)`
    font-size: 12px;
    font-weight: ${({ optionsFeedback }) => optionsFeedback ? 600 : 400};
    line-height: 16.36px;
    color: ${({ theme }) => theme.textColor};
    opacity: ${({ optionsFeedback }) => optionsFeedback ? '1' : '0.5'};
`;

export const ContainerField = styled.div`
    display: flex;
    justify-content: ${({ hasText }) => hasText ? 'flex-start' : 'center'};	
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    border: 1px solid ${({ theme }) => theme.darkHighlightColor};
`;

export const ButtonOption = styled.div`
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    width: 100%;
    padding: 13px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    border-radius: 12px;
    cursor: pointer;
`;

export const OptionsText = styled(Text)`
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
`;