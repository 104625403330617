import React, { useEffect, useState } from 'react';
import { Column, Row, Icon, Progress, Skeleton  } from '@components-teammove';
import { InfoUsuario } from '@utils';
import { getMetasDiarias } from '@ducks/metas';
import { Card, ColumnCongratsText, ContainerProgress, Description, IconContainer, ProgressText, Title } from './styles';
import { useDispatch, useSelector } from 'react-redux';

const Diario = ({ metas, fromHeader }) => {
  const dispatch = useDispatch();

  const [filteredMetas, setFilteredMetas] = useState([]);

  const metasDiarias = useSelector(({ metas }) => metas.get('metasDiarias'));
  const successMetasDiarias = useSelector(({ metas }) => metas.get('successMetasDiarias'));
  const loadingMetasDiarias = useSelector(({ metas }) => metas.get('loadingMetasDiarias'));

  useEffect(() => {
    if(successMetasDiarias) return;
    dispatch(getMetasDiarias(InfoUsuario.get('cdUsuario')));
  }, []);

  useEffect(() => {
    if(!successMetasDiarias) return;
    const filteredMetasWithGoals = metasDiarias.filter((metaDiaria) => metaDiaria.numberOfActivitiesGoal > 0);
    setFilteredMetas(fromHeader ? filteredMetasWithGoals : filteredMetasWithGoals.filter((metaDiaria) =>
      !metas.reachedActivitiesKinds.some((reachedActivity) => 
        reachedActivity.id === metaDiaria.activityKindId)));
  }, [successMetasDiarias]);

  return (
    loadingMetasDiarias ? <Skeleton.LargeBox/> : successMetasDiarias && (
      <>
        <Title titleDaily>Missões diárias</Title>
        <Column gap='12px' justify='flex-start' width='100%'>
          {metas && metas.reachedActivitiesKinds.map((reachedActivityKind) => (
            <Card key={reachedActivityKind.id}>
              <ColumnCongratsText>
                <Title>Parabéns {InfoUsuario.get('nmUsuario')}</Title>
                <Description>Você bateu a sua meta do dia de:</Description>
              </ColumnCongratsText>
              <Column>
                <Row justify='space-between' align='center'>
                  <Row align='center'>
                    <IconContainer bgColor={reachedActivityKind.color}>
                      <Icon.MDI type={reachedActivityKind.icon}/>
                    </IconContainer>
                    <Title titleActivity>{reachedActivityKind.name}</Title>
                  </Row>
                  <Title percent>{(reachedActivityKind.numberOfFinishedActivitiesPercent || 0).toFixed(2)}%</Title>
                </Row>
                <ContainerProgress>
                  <Progress percent={reachedActivityKind.numberOfFinishedActivitiesPercent || 0} showInfo={false} strokeColor={reachedActivityKind.color} strokeWidth={30}/>
                  <ProgressText>
                    {reachedActivityKind.numberOfFinishedActivities}/
                    {reachedActivityKind.numberOfActivitiesGoal.toFixed(2).toString().replace('.', ',')}
                  </ProgressText>
                </ContainerProgress>
              </Column>
            </Card>
          ))}
          {filteredMetas.length > 0 && filteredMetas.map((meta) => (
            <Card key={meta.activityKindId}>
              <Row justify='space-between' align='center'>
                <Row align='center'>
                  <IconContainer bgColor={meta.activityKindColor}>
                    <Icon.MDI type={meta.activityKindIcon} />
                  </IconContainer>
                  <Title titleActivity>{meta.activityKindName}</Title>
                </Row>
                <Title percent>{(meta.numberOfFinishedActivitiesPercent || 0).toFixed(2)}%</Title>
              </Row>
              <ContainerProgress>
                <Progress percent={meta.numberOfFinishedActivitiesPercent || 0} showInfo={false} strokeColor={meta.activityKindColor} strokeWidth={30} />
                <ProgressText>{meta.numberOfFinishedActivities}/{meta.numberOfActivitiesGoal.toFixed(2).toString().replace('.', ',')}</ProgressText>
              </ContainerProgress>
            </Card>
          ))}
        </Column>
      </>
    )
  );
};

export default Diario;