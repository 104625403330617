import React from 'react';
import { CheckIcon, Children, Row, StyledRadio, StyledRadioButton, StyledRadioGroup } from './styles';

const Radio = ({ checked, onChange = () => '', children, label, ...props }) => {
  const handleKeyPress = ({ which }) => {
    if (which === 13) {
      onChange(!checked);
    }
  };

  return label ? (
    <>
      <Row>
        <StyledRadio checked={checked} onClick={() => onChange(!checked)} onKeyPress={handleKeyPress} tabIndex={0} {...props}>
          {checked && <CheckIcon type='check'/>}
        </StyledRadio>
        <Children>{label}</Children>
      </Row>
      {children}
    </>
  ) : (
    <Row>
      <StyledRadio checked={checked} onClick={() => onChange(!checked)} onKeyPress={handleKeyPress} tabIndex={0} {...props}>
        {checked && <CheckIcon type='check'/>}
      </StyledRadio>
      {children && (
        <Children>{children}</Children>
      )}
    </Row>
  );
};

Radio.Group = ({ value, onChange, disabled = false, children, ...props }) => {
  const handleChange = (radioValue, checked) => {
    if (checked) {
      onChange(radioValue);
    }
  };

  return(
    <StyledRadioGroup {...props}>
      {children && children.length > 0 && React.Children.map(children, (child) => child && (
        React.cloneElement(child, { onChange: (checked) => handleChange(child.props.value, checked), checked: value === child.props.value, value: child.props.value, disabled: disabled })
      ))}
    </StyledRadioGroup>
  );
};

Radio.Button = ({ checked, onChange = () => '', children, ...props }) => {
  return(
    <Row>
      <StyledRadioButton type={checked ? 'primary' : 'secondary'} checked={checked} onClick={() => onChange(!checked)} size='small' context='header' {...props}>
        {children}
      </StyledRadioButton>
      {/* {children && (
        <Children>{children}</Children>
      )} */}
    </Row>
  );
};

export default Radio;