import styled from 'styled-components';
import { Column, ContentTitle, Row, Button, Icon } from '@components-teammove';

export const ButtonStyled = styled(Button)``;

export const UserCard = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    ${ButtonStyled}{
      background-color: ${({ theme }) => theme.darkSecondaryColor};
    }
  }

  ${({ disabled }) => disabled && `
    opacity: .5;
  `}:hover {
    background-color: ${({ theme }) => theme.darkPrimaryColor};
   
  }
`;

export const UserName = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  line-height: 18px;
`;

export const CardText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  line-height: 18px;
`;

export const CardSecondaryText = styled(CardText)`
  opacity: .5;
`;

export const CardSubTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const ColumnStyle = styled(Column)`
  gap: 20px;
`;

export const ContentTeamLocation = styled(Column)`
  gap: 12px;
`;

export const TitleTeamLocation = styled(ContentTitle)`
  font-size: 20px;
  font-weight: 700;
`;

export const NameLocation = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.textColor};
  font-weight: 400;
  line-height: 32px;
`;

export const NumberCheckin = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.textColor};
  font-weight: 700;
  line-height: 32px;
`;

export const IconStyle = styled(Icon.Feather)`
  color: ${({ color }) => color };
`;

export const RowStyle = styled(Row)`
  gap: 12px;   
  align-items: center;
`;

export const RowLocationAndCheckin = styled(Row)`
  gap: 8px;
`;

export const RowCard = styled(Row)`
  justify-content: space-between;
  flex: 1;
`;

export const IconMDI = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

