import React from 'react';
import { CardSubTitle, IconStyle, RowStyle, RowLocationAndCheckin, RowCard, UserCard, UserName, CardSecondaryText, CardText, FeatherIcon, ContentTeamLocation, TitleTeamLocation, ColumnStyle, NumberCheckin, NameLocation, IconMDI } from './styles';
import { Row, Column, Avatar, Skeleton, Grid, DividedLine, ContentHeader, ViewControl, Tooltip, Icon } from '@components-teammove';

import { formatDate, formatTime, getDownloadUrlAvatar, moment } from '@utils';
import { useHistory } from 'react-router-dom';

export default function UsersList({ viewBy, handleViewByChange, users, loading }) {
  const history = useHistory();

  return loading ? (
    <Grid numberOfColumns={3} gap='12px'>
      <Skeleton height='250px' borderRadius='12px'/>
      <Skeleton height='250px' borderRadius='12px'/>
      <Skeleton height='250px' borderRadius='12px'/>
    </Grid>
  ) : (
    <ColumnStyle>
      <ContentTeamLocation>
        <TitleTeamLocation>
          Localização da equipe
        </TitleTeamLocation>

        <Grid numberOfColumns={2} gap='12px'>
          
          <UserCard>
            <RowCard>
              <RowStyle>
                <IconStyle color={'var(--success-color)'} type='map-pin'/>
                <RowLocationAndCheckin>
                  <NumberCheckin>{users?.locationsSummary?.rightLocations}</NumberCheckin>
                  <NameLocation>check-ins</NameLocation>
                </RowLocationAndCheckin>
              </RowStyle>
            </RowCard>
          </UserCard>

          <UserCard>
            <RowCard>
              <RowStyle>
                <IconStyle color={'var(--danger-color)'} type='map-pin'/>
                <RowLocationAndCheckin>
                  <NumberCheckin>{users?.locationsSummary?.wrongLocations}</NumberCheckin>
                  <NameLocation>sem registros</NameLocation>
                </RowLocationAndCheckin>
              </RowStyle>
            </RowCard>
          </UserCard>

        </Grid>
      </ContentTeamLocation>
      
      <ContentHeader 
        title={viewBy} 
        extra={(
          <ViewControl 
            options={[
              { icon: <Icon.MDI type='format-list-bulleted'/>, name: 'Cards de usuários' }, 
              { icon: <Icon.Feather type='map-pin'/>, name: 'Mapa' },
            ]} 
            current={viewBy} 
            onChange={handleViewByChange}
          />
        )}
       
      />
      <Grid numberOfColumns={3} gap='12px'>
        {users.users?.map((user) => (
          <UserCard key={user.userId} onClick={() => history.push('/controle-atividades/' + user.userId)} disabled={user.numberOfActivities === 0}>
            <Column gap='16px' flex={1}>
              <Row justify='space-between' >
                <Row gap='12px' align='center'>
                  <Avatar size='large' src={getDownloadUrlAvatar(user?.userImage)}/>
                  <Column gap='4px'>
                    <UserName>{user.userName}</UserName>
                    <CardSecondaryText>{user.userProfile}</CardSecondaryText>
                  </Column>
                </Row>
                <Tooltip title={`Última localização registrada em ${moment(user?.lastLocationDate).format('DD/MM/YYYY HH:mm')}`}>
                  <IconMDI type='cellphone-nfc'/>
                </Tooltip>
                
              </Row>
              <Column gap='12px'>
                <Row justify='space-between'>
                  <CardSubTitle>Atividades</CardSubTitle>
                  <CardSubTitle>{Math.round((user.numberOfClosedActivities / (user.numberOfActivities)) * 100) || 0}%</CardSubTitle>
                </Row>
                <DividedLine
                  divisions={[
                    { label: 'Concluído', total: user.numberOfClosedActivities, percentage: (user.numberOfClosedActivities / (user.numberOfActivities)) * 100, status: 'success' }, 
                    { label: 'Cancelado', total: user.numberOfCanceledActivities, percentage: (user.numberOfCanceledActivities / (user.numberOfActivities)) * 100, status: 'danger' },
                    { label: 'Pendente', total: user.numberOfOnGoingActivities, percentage: (user.numberOfOnGoingActivities / (user.numberOfActivities)) * 100 }
                  ]}
                />
              </Column>
              {user.lastCheckinDate ? (
                <Column gap='12px'>
                  <Row gap='12px' align='center'>
                    <CardSubTitle>Último checkin</CardSubTitle>
                    <CardSecondaryText>{formatDate(user.lastCheckinDate)} às {formatTime(user.lastCheckinDate)}</CardSecondaryText>
                  </Row>
                  <Row gap='15px' align='center'>
                    <FeatherIcon type='home'/>
                    <Column>
                      <CardText>{user.companyShortName} - {user.companyName}</CardText>
                      <CardSecondaryText>{user.companyCity} - {user.companyState}</CardSecondaryText>
                    </Column>
                  </Row>
                </Column>
              ) : (
                <Row justify='center' align='center' flex={1}>
                  <CardSecondaryText>Último checkin não encontrado</CardSecondaryText>
                </Row>
              )}
            </Column>
          </UserCard>
        ))}
      </Grid>
    </ColumnStyle>
  );
}