export const getLagLngFromAddress = async (address) => {
  const ans = await fetch((`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBgvAQ2f4AcfkV0cERdTvpou9h2E-675So`));
  const latLng = await ans.json(); // https://maps.googleapis.com/maps/api/js?key=AIzaSyBgvAQ2f4AcfkV0cERdTvpou9h2E-675So
  return latLng;
};

export const getDistanceBetweenLocations = (locationA, locationB) => {
  const Radius = 6378.137; // Radius of earth in KM
  const dLat = locationB.lat * Math.PI / 180.0 - locationA.lat * Math.PI / 180.0;
  const dLon = locationB.lng * Math.PI / 180 - locationA.lng * Math.PI / 180;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
          + Math.cos(locationA.lat * Math.PI / 180) * Math.cos(locationB.lat * Math.PI / 180)
          * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  // eslint-disable-next-line id-length
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const dist = Radius * c;
  return (dist * 1000.0);
};

export const stateOptions = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export const getCurrentLatLng = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude } = position.coords;
          const { longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error('Geolocalização não é suportada pelo navegador.'));
    }
  });
};

export const getDiffLocation = (lat1, lon1, lat2, lon2) => {
  if ((!lat1) || (!lon1) || (!lat2) || (!lon2)) {
    return null;
  }

  // Converta as coordenadas para radianos
  lat1 = parseFloat(lat1.toString().replace(',', '.')) * (Math.PI / 180);
  lon1 = parseFloat(lon1.toString().replace(',', '.')) * (Math.PI / 180);
  lat2 = parseFloat(lat2.toString().replace(',', '.')) * (Math.PI / 180);
  lon2 = parseFloat(lon2.toString().replace(',', '.')) * (Math.PI / 180);

  const diam = 12742;
  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;
  const haversine = ((1 - Math.cos(dLat)) + (1 - Math.cos(dLon)) * Math.cos(lat1) * Math.cos(lat2)) / 2;

  const distancia = Math.round((diam * Math.asin(Math.sqrt(haversine))) * 1000) / 1000;

  return (distancia > 1) ? parseFloat(distancia.toFixed(1)) : distancia;
};