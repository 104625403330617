/* eslint-disablea*/

import React, { useState } from 'react';
import { AvatarUser, View } from '@components';
import { prettyTimeDifference, moment } from '@utils';
import styled from 'styled-components';
import types from '../types';
import Edicao from './Editar';
import AcoesAutor from './AcoesAutor';
import AcoesUsuario from './AcoesUsuario';

const ContentView = styled(View)`
  width: 100%;
  margin-left: 15px;
`;

const ViewComentarios = styled(View)`
  margin-top: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  width: 100%;
`;

const InfoComentario = styled(View)`
  width: 100%;
`;

const IntervaloComentario = styled(View)`
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.8;
`;

export default function ListaComentarios({ data, handlers }) {
  const { comentarios, tipoOrdenacao } = data;
  const [edicaoComentario, setEdicaoComentario] = useState({ display: 'none' });

  const ordernarComentarios = (a, b) => {
    let sorter = 0;

    switch (tipoOrdenacao) {
      case types.ordenacao.DATA:
        if (a.usuarioLogado && b.usuarioLogado) {
          sorter = b.dtCriacao - a.dtCriacao;
        } else if (a.usuarioLogado) {
          sorter = -1;
        } else if (b.usuarioLogado) {
          sorter = 1;
        } else {
          sorter = b.dtCriacao - a.dtCriacao;
        }

        break;
      case types.ordenacao.RELEVANTE:
        if (a.usuarioLogado && b.usuarioLogado) {
          sorter = b.qtLike - a.qtLike;
        } else if (a.usuarioLogado) {
          sorter = -1;
        } else if (b.usuarioLogado) {
          sorter = 1;
        } else {
          sorter = b.qtLike - a.qtLike;
        }

        break;

      default:
        return sorter;
    }

    return sorter;
  };

  return comentarios
    .sort((a, b) => ordernarComentarios(a, b))
    .map((row) => {
      const { cdCliente, cdUsuario, nmUsuario, id, dtCriacao, comentario: texto } = row;
      const prettyTime = prettyTimeDifference(moment(dtCriacao).toDate());

      return (
        <ViewComentarios horizontal key={id}>
          <AvatarUser cdCliente={cdCliente} cdUsuario={cdUsuario} nmUsuario={nmUsuario} />
          <ContentView>
            <View horizontal>
              <InfoComentario>
                <View horizontal alignItems='center'>
                  <strong>{nmUsuario}</strong>
                  {id > 0 && prettyTime.difference.length > 0 ? (
                    <IntervaloComentario>{prettyTime.difference[0]} atrás</IntervaloComentario>
                  ) : (
                    <View />
                  )}
                </View>
                <Edicao data={{ edicaoComentario, id, texto, comentarios }} handlers={{ ...handlers, setEdicaoComentario }} />
              </InfoComentario>

              <AcoesAutor data={{ edicaoComentario, row, comentarios }} handlers={{ ...handlers, setEdicaoComentario }} />
            </View>

            <AcoesUsuario data={{ row, comentarios }} handlers={handlers} />
          </ContentView>
        </ViewComentarios>
      );
    });
}
