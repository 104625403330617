import React, { useEffect, useMemo } from 'react';
import { ContentCurrentActivity, IconActivity, Typography } from './styles';
import { Icon, Accountant, Tooltip, Column } from '@components-teammove';
import { InfoUsuario, moment, formatTimeWithSeconds, getCurrentLatLng, Notification } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCheckInOut, postCheckInOut } from '@ducks/atividadesCheckInOut';

const CurrentActivityHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const activityCheckInOut = useSelector(({ atividadesCheckInOut }) => atividadesCheckInOut.get('activityCheckInOut'));
  const successActivityCheckInOut = useSelector(({ atividadesCheckInOut }) => atividadesCheckInOut.get('successActivityCheckInOut'));
  const interruptRequest = useSelector(({ voip }) => voip.get('interruptRequest'));

  const timeToAtt = useMemo(() => activityCheckInOut?.checkinDate ? 10000 : 60000, [activityCheckInOut]);
  const successFinishChecklist = useSelector(({ checklists }) => checklists.get('successFinishChecklist')); 
  const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));

  useEffect(() => {
    if(interruptRequest || !infoUsuario.cdUsuario) return;
    dispatch(getCheckInOut());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(interruptRequest || !infoUsuario.cdUsuario) return;
      dispatch(getCheckInOut());
    }, timeToAtt); 

    return () => clearInterval(intervalId);
  }, [interruptRequest, timeToAtt]);

  useEffect(() => {
    if(!successFinishChecklist || !infoUsuario.cdUsuario) return;
    dispatch(getCheckInOut());
  }, [successFinishChecklist]);

  useEffect(() => {
    if(successActivityCheckInOut && Object.keys(activityCheckInOut).length > 0) {
      const { workingDays = null } = InfoUsuario.get('workingJourney') || {};
      const isWorkingDayPassed = checkIfWorkdayPassed(workingDays);

      if(isWorkingDayPassed) {
        if(activityCheckInOut.checkinType === 'G') {
          getCurrentLatLng()
            .then((coords) => {
              dispatch(postCheckInOut('out', activityCheckInOut.id, checkoutAutomatic(activityCheckInOut, coords), true));
            })
            .catch((error) => {
              Notification.error('Verifique se as permissões de localização estão ativas');
            });
        } else {
          dispatch(postCheckInOut('out', activityCheckInOut.id, checkoutAutomatic(activityCheckInOut), true));
        }
      }
    }
  }, [successActivityCheckInOut]);

  const checkoutAutomatic = (activityCheckInOut, coords) => {
    const today = moment();
    const checkinDate = moment(activityCheckInOut.checkinDate);
    const dataNow = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
    if(!coords) return { data:  dataNow }; 

    return {
      data: dataNow,
      latitude: checkinDate.isSame(today, 'day') ? coords.latitude : 0,
      longitude: checkinDate.isSame(today, 'day') ? coords.longitude : 0,
    };
  };

  const checkIfWorkdayPassed = (workingDays) => {
    const today = moment();
    const currentDay = today.locale('en').format('dddd').toUpperCase();
    today.locale('pt-br');
    const currentTime = formatTimeWithSeconds(today);
    if(!workingDays) return false;
    const todayWorkDay =  workingDays.find((workDay) => workDay.day === currentDay);

    if (!todayWorkDay || todayWorkDay.shifts.length === 0) return false;

    for (let index = 0; index < todayWorkDay.shifts.length; index++) {
      const currentShift = todayWorkDay.shifts[index];

      if (index === 0 && currentTime < currentShift.start) {
        return false;
      }

      if (currentTime >= currentShift.start && currentTime <= currentShift.end) {
        return false;
      }

      const nextShift = todayWorkDay.shifts[index + 1];
      if (currentTime > currentShift.end && nextShift && currentTime < nextShift.start) {
        return true;
      }
    }

    const lastShift = todayWorkDay.shifts[todayWorkDay.shifts.length - 1];
    if (currentTime > lastShift.end) {
      return true;
    }

    return false;
  };
  
  return(
  activityCheckInOut?.checkinDate ? (
    <ContentCurrentActivity color={activityCheckInOut?.activityColor} onClick={() => {history.push(`/atividades/agenda/${activityCheckInOut.id}`);}}>
      <IconActivity color={activityCheckInOut?.activityColor}>
        <Icon.MDI size='16px' type={activityCheckInOut?.icon}/>
      </IconActivity>
      <Column>
        <Tooltip title={activityCheckInOut?.companyFullName + '-' + activityCheckInOut?.name}>
          <Typography maxWidth='200px'>{activityCheckInOut?.companyFullName} - {activityCheckInOut?.name}</Typography>
        </Tooltip>
      </Column>
      <Accountant time={activityCheckInOut?.checkinDate?.slice(0, -5).replace('T', ' ')}/>
    </ContentCurrentActivity> ) : null
  );
};

export default CurrentActivityHeader;