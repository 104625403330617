import React, { useState } from 'react';
import Categorias from './Categorias';
import Atendimento from './Atendimento';
import Geral, { ExtraGeral } from './Geral';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button } from '@components-teammove';
import { useHistory } from 'react-router-dom';
import { TelasNomenclaturas, Session } from '@utils';
import { useSelector } from 'react-redux';
import Classificacao from './Classificacao';

export default function ConfiguracoesUsuarios() {
  const history = useHistory();
  const theme = useSelector(({ app }) => app.get('theme'));

  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_TICKETS_TAB') || 'Departamento/Categoria');
  const [visibleRegisterClassificacao ,setVisibleRegisterClassificacao] = useState(false);

  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_TICKETS_TAB', newTab);
  };

  const ExtraAtendimento = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => history.push('/configuracoes/chamados/atendimento')}>Novo</Button>,
  ];

  const ExtraClassificacao = () => [
    <Button key="2" type="primary" size='small' context='header' onClick={() => setVisibleRegisterClassificacao(true)}>Nova Classificação</Button>,
  ];

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Configurações ' + ticketNomenclatures.plural}
          extra={currentTab === 'Departamento/Categoria' ? null : currentTab === 'Atendimento' ? <ExtraAtendimento/> : currentTab === 'Geral' ? <ExtraGeral/> : <ExtraClassificacao/>}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/chamados', name: `Configurações de ${ticketNomenclatures.plural}`, current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Departamento/Categoria' }, { name: 'Atendimento' }, { name: 'Geral' }, { name: 'Classificação' }]} current={currentTab} onChange={handleChangeTab}/>
            {currentTab === 'Departamento/Categoria' ? (
              <Categorias/>
            ) : currentTab === 'Atendimento' ? (
              <Atendimento/>
            ) : currentTab === 'Geral' ? (
              <Geral/>
            ) : 
              (
                <Classificacao visibleRegister={visibleRegisterClassificacao} setVisibleRegister={setVisibleRegisterClassificacao}/>
              )}
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}