import styled from 'styled-components';
import { Text } from '@components-teammove';

export const Main = styled.div`
 height: 100%;
 width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 60px 20px 0px 20px;
  
`;

export const HeaderStyled = styled.div`
 height: 60px;
 width: 100%;
 position: fixed;
 z-index: 100;
 background-color: ${({ theme }) => theme.foregroundColor};
 display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImage = styled.img`
  max-width: 150px;
  object-fit: cover;
   user-select: none;
`;

export const SubTitle = styled(Text)`
  font-size: 16px;
  opacity: 0.5;
  font-weight: 400;
  line-height: 21.79px;
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
`;