import React, { useMemo, useState } from 'react';
import { CollapseIcon, Scrollable } from './styles';
import { CardSecondaryTitle, ContentCard } from '../styles';
import { Column, Row, Button } from '@components-teammove';
import Task from './Task';

export default function Tasks({ tasks }) {
  const pending = useMemo(() => tasks?.filter(({ status, active }) => (status === 'EM_ANDAMENTO' || status === 'NAO_INICIADO') && active), [tasks]);
  const closed = useMemo(() => tasks?.filter(({ status, active }) => status === 'CONCLUIDA' && active), [tasks]);
  const canceled = useMemo(() => tasks?.filter(({ active }) => !active), [tasks]);

  const [collapsedCards, setCollapsedCards] = useState({});

  return (
    <>
      {pending?.length > 0 && (
        <ContentCard>
          <Column gap='12px'>
            <Row justify='space-between'>
              <CardSecondaryTitle>Pendentes</CardSecondaryTitle>
              <Button type='icon' transparent size='small' icon={(<CollapseIcon type={`chevron-${collapsedCards.pending ? 'right' : 'down'}`}/>)} onClick={() => setCollapsedCards({ ...collapsedCards, pending: !collapsedCards.pending })}/>
            </Row>
            {!collapsedCards.pending && (
              <Scrollable>
                {pending.map((task) => (
                  <Task task={task} key={task.id}/>
                ))}
              </Scrollable>
            )}
          </Column>
        </ContentCard>
      )}

      {closed?.length > 0 && (
        <ContentCard>
          <Column gap='12px'>
            <Row justify='space-between'>
              <CardSecondaryTitle>Finalizadas</CardSecondaryTitle>
              <Button type='icon' transparent size='small' icon={(<CollapseIcon type={`chevron-${collapsedCards.closed ? 'right' : 'down'}`}/>)} onClick={() => setCollapsedCards({ ...collapsedCards, closed: !collapsedCards.closed })}/>
            </Row>
            {!collapsedCards.closed && (
              <Scrollable>
                {closed.map((task) => (
                  <Task task={task} key={task.id}/>
                ))}
              </Scrollable>
            )}
          </Column>
        </ContentCard>
      )}

      {canceled?.length > 0 && (
        <ContentCard>
          <Column gap='12px'>
            <Row justify='space-between'>
              <CardSecondaryTitle>Canceladas</CardSecondaryTitle>
              <Button type='icon' transparent size='small' icon={(<CollapseIcon type={`chevron-${collapsedCards.canceled ? 'right' : 'down'}`}/>)} onClick={() => setCollapsedCards({ ...collapsedCards, canceled: !collapsedCards.canceled })}/>
            </Row>
            {!collapsedCards.canceled && (
              <Scrollable>
                {canceled.map((task) => (
                  <Task isCanceled task={task} key={task.id}/>
                ))}
              </Scrollable>
            )}
          </Column>
        </ContentCard>
      )}
    </>
  );
}