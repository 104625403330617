import React, { useEffect, useState } from 'react';
import { Modal, Form, FormItem, Input, Select } from '@components-teammove';
import SelectCategoryModal from '../../Cadastro/SelectCategoryModal';

export default function ModalCategoria({ visible, onCancel, onChangeCategoria, idsCategoria, categoriaAtual, loading }) {
  const [form] = Form.useForm();
  const [ isVisibleSelectCategoryModal, setIsVisibleSelectCategoryModal ] = useState(false);
  const [ isVisibleEditModal, setIsVisibleEditModal ] = useState(false);

  useEffect(() => {
    setIsVisibleSelectCategoryModal(false);
    setIsVisibleEditModal(true);
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      onChangeCategoria(values.categoria);
    }).catch((err) => console.error(err.message));
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSetValueForm = (field, value) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  };

  return (<>

    {isVisibleSelectCategoryModal && (
      <SelectCategoryModal
        form={form}
        visible={visible}
        onCancel={()=> {
          setIsVisibleSelectCategoryModal(false);
          setIsVisibleEditModal(true);
        }}
        handleSetValueForm={handleSetValueForm}
        setIsVisibleSelectCategoryModal={setIsVisibleSelectCategoryModal}
        setIsVisibleRegisterModal={setIsVisibleEditModal}
      />
    )}
    {isVisibleEditModal && (
      <Modal
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{ loading }}
        okText="Alterar"
        title="Alterar categoria"
        closable={false}
      >
        <Form layout="vertical" form={form}>
          <FormItem label="De">
            <Input disabled value={categoriaAtual} />
          </FormItem>

          <Form.Item label="Departamento/Categoria" name="categoria" rules={[{ required: true, message: 'Informe um Departamento/Categoria!' }]} hidden/>
          <Form.Item label="Departamento/Categoria" name="categoriaPath" rules={[{ required: true, message: 'Informe um Departamento/Categoria!' }]} marginless>
            <Select 
              name='categoriaPath'
              placeholder="Selecione um departamento/categoria"
              onClick={() => {
                setIsVisibleSelectCategoryModal(true);
                setIsVisibleEditModal(false);
              }}
            />
          </Form.Item>
          
        </Form>
      </Modal>
    )}
    
  </>
  );
}