import styled, { createGlobalStyle } from 'styled-components';
import Icon from '../Icon';
import { TimePicker } from 'antd';
import { lighten } from 'polished';

export const StyledTimePicker = styled(TimePicker)`
  &.ant-picker {
    width: 100%;
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border-color: ${({ theme }) => theme.darkHighlightColor} !important;
    border-width: 2px;
    color: ${({ theme }) => theme.textColor} !important;
    padding: 14px;

    .ant-picker-separator {
      color: ${({ theme }) => theme.textColor};
      opacity: ${({ value }) => !value && 0.5};
    }

    .ant-picker-input {
      > input {
        color: ${({ theme }) => theme.textColor};
        font-size: 16px;

        ::placeholder {
          color: ${({ theme }) => theme.textColor};
          opacity: .5;
        }
      }

      :hover {
        .ant-picker-clear {
          background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
        }
      }
      .ant-picker-clear:not(:hover) {
        background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
      }
    }

    .ant-picker-suffix {
      opacity: ${({ value }) => !value && 0.5}; 
    }

    &.ant-picker-range .ant-picker-active-bar {
      background: ${({ theme }) => theme.actionColor};
    }

    :focus-within, .ant-picker-focused {
      border: 2px solid ${({ theme }) => theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }
  }
`;

export const DropdownContainerStyles = createGlobalStyle`
  .ant-picker-dropdown {
    background-color: transparent;
    --border-color: ${({ theme }) => theme.darkHighlightColor};

    .ant-picker-panel-container {
      background-color: ${({ theme }) => theme.darkPrimaryColor};
      color: ${({ theme }) => theme.textColor};

      .ant-picker-panel {
        border-color: var(--border-color);
      }

      .ant-picker-header {
        color: ${({ theme }) => theme.textColor};

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: ${({ theme }) => theme.textColor};
        }
      }

      .ant-picker-content {
        .ant-picker-time-panel-column {
          border-color: var(--border-color);
        }
      }

      .ant-picker-time-panel-cell {
        .ant-picker-time-panel-cell-inner {
          color: ${({ theme }) => theme.textColor};

          &.ant-picker-cell-disabled {
            background-color: ${({ theme }) => theme.darkSecondary};
            opacity: .4;

            :before {
              background-color: ${({ theme }) => theme.darkSecondary};
            }

            .ant-picker-cell-inner {
              color: ${({ theme }) => theme.textColor};
            }
          }

          :hover {
            background-color: ${({ theme }) => theme.actionColor};
          }
        }
      }

      .ant-picker-footer {
        border-color: var(--border-color);

        .ant-picker-ranges {
          .ant-picker-now {
            color: ${({ theme }) => theme.actionColor};
            background: transparent;

            :hover {
              color: ${({ theme }) => lighten(0.085, theme.actionColor)};
              border: none;
            }
          }

          .ant-picker-ok > button.ant-btn[disabled] {
            background-color: ${({ theme }) => theme.actionColor};
            color: ${({ theme }) => theme.textContrastColor};
            border: none;
            opacity: .5;
          }
        }
      }
    }

    .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background-color: ${({ theme }) => theme.actionColor} !important;
    }
  }
`;

export const StyledRangeTimePicker = styled(TimePicker.RangePicker)`
  &.ant-picker {
    width: 100%;
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    border-color: ${(props) => props.theme.darkHighlightColor} !important;
    border-width: 2px;
    color: ${(props) => props.theme.textColor} !important;
    padding: 14px;

    .ant-picker-separator {
      color: ${(props) => props.theme.textColor};
      ${({ value }) => !value && `
        opacity: .5;
      `}
    }

    .ant-picker-input {
      > input {
        color: ${(props) => props.theme.textColor};
        font-size: 16px;
        text-align: center;

        ::placeholder {
          color: ${(props) => props.theme.textColor};
          opacity: .5;
        }
      }
    }

    .ant-picker-suffix {
      ${({ value }) => !value && `
        opacity: .5;
      `}
    }

    &.ant-picker-range .ant-picker-active-bar {
      background: ${(props) => props.theme.actionColor};
    }

    :focus-within, .ant-picker-focused {
      border: 2px solid ${(props) => props.theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }

    :hover {
      .ant-picker-clear {
        opacity: 0 !important;
      }
    }
  }
`;

export const DropdownContainerRangePickerStyles = createGlobalStyle`
  .ant-picker-dropdown {
    background-color: transparent;

    .ant-picker-panel-container {
      background-color: ${(props) => props.theme.darkPrimaryColor};
      color: ${(props) => props.theme.textColor};

      .ant-picker-panels {
        width: 100%;
      }

      .ant-picker-header {
        color: ${(props) => props.theme.textColor};

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: ${(props) => props.theme.textColor};
        }
      }

      .ant-picker-time-panel-column:not(:first-child) {
        border-color: ${({ theme }) => theme.darkHighlightColor};
      }

      .ant-picker-time-panel-cell {
        color: ${(props) => props.theme.textColor};

        &.ant-picker-time-panel-cell-disabled {
          background-color: ${(props) => props.theme.darkPrimaryColor};
          opacity: .4;

          :before {
            background-color: ${(props) => props.theme.darkPrimaryColor};
          }

          .ant-picker-time-panel-cell-inner {
            color: ${(props) => props.theme.textColor};
          }
        }

        .ant-picker-time-panel-cell-inner {
          color: ${({ theme }) => theme.textColor};
        }

        &.ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            background-color: ${({ theme }) => theme.actionColor};
          }
        }

        :hover:not(.ant-picker-time-panel-cell-in-view) .ant-picker-time-panel-cell-inner, 
        :hover:not(.ant-picker-time-panel-cell-selected):not(.ant-picker-time-panel-cell-range-start):not(.ant-picker-time-panel-cell-range-end):not(.ant-picker-time-panel-cell-range-hover-start):not(.ant-picker-time-panel-cell-range-hover-end) .ant-picker-time-panel-cell-inner {
          background-color: ${(props) => props.theme.actionColor};
        }

        &.ant-picker-time-panel-cell-in-view {
          &.ant-picker-time-panel-cell-in-range,
          &.ant-picker-time-panel-cell-range-start,
          &.ant-picker-time-panel-cell-range-end {

            &.ant-picker-time-panel-cell-range-start-single {
              :before {
                background-color: transparent;
              }
            }

            &.ant-picker-time-panel-cell-range-hover-start,
            &.ant-picker-time-panel-cell-range-hover-end {
              .ant-picker-time-panel-cell-inner {
                :not(.ant-picker-time-panel-cell-range-end) {
                  ::after {
                    background-color: ${(props) => props.theme.actionColor} !important;
                  }
                }
              }
            }
            :before {
              background-color: ${(props) => props.theme.darkPrimaryColor};
            }
          }
        }
      }

      .ant-picker-panel {
        border-color: ${({ theme }) => theme.darkHighlightColor};
        flex: 1;
      }

      .ant-picker-footer {
        .ant-picker-ranges .ant-picker-preset .ant-tag {
          color: ${(props) => props.theme.textColor};
          background: transparent;
          border-color: ${(props) => props.theme.textColor};

          :hover {
            background-color: ${(props) => props.theme.actionColor};
            border: none;
          }
        }

        .ant-picker-ok > button.ant-btn[disabled] {
          background-color: ${({ theme }) => theme.actionColor};
          color: ${({ theme }) => theme.textContrastColor};
          border: none;
          opacity: .5;
        }
      }
    }

    .ant-picker-cell-in-view {
      .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: ${(props) => props.theme.actionColor} !important;
      }

      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${(props) => props.theme.actionColor};
      }

      &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: ${(props) => props.theme.actionColor};
        }
      }

      &.ant-picker-range-hover, &.ant-picker-range-hover-end {
        border-color: ${(props) => props.theme.actionColor};

        ::after {
          background-color: ${(props) => props.theme.actionColor};
          border-color: ${(props) => props.theme.actionColor};
        }
      }
    }
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;

export const ErrorIcon = styled(Icon.MDI)`
  path {
    color: var(--danger-color) !important;
  }
`;