import React from 'react';
import { Row, Text, LineChart, Tooltip, Column, Icon } from '@components-teammove';
import { ContentActivityGroup, ContentBody, ContentHeader, Dot, DotIcon, IconStyle, HorizontalLine, LineChartDiv, RowStyle, ContainerIcon } from './styles';
import { dataExample, optionsForChart } from './rules';
import { moment } from '@utils';

export const CardActivityGroup = ({ onClick, activeType, id, info, moments, isExample, height, icon, color, typePeriod, initialDate, isGroupActivity, ...props }) => {
  const goalData = moments?.map((goal)=> goal.idealFinishedQuantity);
  const options = optionsForChart(typePeriod);

  const scheduleData = () => {
    const lastCumulativeScheduledQuantity = moments?.[moments?.length - 1].cumulativeScheduledQuantity;
    const lastCumulativeFinishedQuantity = moments?.[moments?.length - 1].cumulativeFinishedQuantity;
    if (lastCumulativeScheduledQuantity === lastCumulativeFinishedQuantity) {
      return [];
    } else {
      const newScheduleList = [];

      for (let index = 0; index < moments?.length; index++) {
        if (index === 0 || moments?.[index].cumulativeScheduledQuantity !== moments?.[index - 1].cumulativeScheduledQuantity) {
          newScheduleList.push(moments?.[index].cumulativeScheduledQuantity);
        }
      }
      return newScheduleList;
    }
  };

  const finishedData = moments?.filter((item) => moment().isSameOrAfter(moment(item.start.replace('Z', '')), typePeriod === 'DIARIO' ? 'hour' : typePeriod === 'SEMANAL' ? 'day' : 'week')
  ).map((item) => item.cumulativeFinishedQuantity);

  function verifyAllIsZero(array) {
    return array?.every((element) => element === 0);
  }

  const labels = (moments, typePeriod) => {

    const DIARIO = 'DIARIO';
    const MENSAL = 'MENSAL';
    const SEMANAL = 'SEMANAL';

    if (typePeriod === DIARIO ) {
      return moments?.map((item, index)=> moment.utc(item.start).format('HH'));
    } 
    else if (typePeriod === SEMANAL) {
      return moments?.map((item, index)=> moment(item.description).date());
    } 
    else if (typePeriod === MENSAL) {
      return ['S1','S2','S3','S4','S5'];
    } 
  };

  const renderTooltipContentGroups = (groupActivity) => (
    <Column gap='10px'>
      <Text size='12px' weight='700'>Agrupador de meta de:</Text>
      {groupActivity.map((group) => (
        <Row key={group.id} gap='8px' align='center'>
          <ContainerIcon colorBg={group.color}>
            <Icon.MDI type={group.icon}/>
          </ContainerIcon>
          <Text size='16x' weight='400'>{group.name}</Text>
        </Row>
      ))}
    </Column>
  );

  const data = {
    labels: labels(moments, typePeriod),
    datasets: [
      !verifyAllIsZero(scheduleData()) && (
        {
          label: 'Agendada',
          data: scheduleData(),
          borderColor: '#FFCC00',
          backgroundColor: '#FFCC00',
          borderDash: [10, 10],
        }),
      {
        label: 'Meta',
        data: goalData,
        borderColor: '#1890FF',
        backgroundColor: '#1890FF',
      },
      {
        label: 'Finalizado',
        data: finishedData,
        borderColor: '#34C759',
        backgroundColor: '#34C759',
      }
    ]
  };

  return(
    <>
      
      <ContentActivityGroup onClick={onClick} shadow={isExample} width={isExample && '40%'} activeType={activeType}>
        <Tooltip title={isGroupActivity ? renderTooltipContentGroups(isGroupActivity) : ''} placement="topLeft">
          <ContentHeader activeType={activeType} align='center' justify='space-between' width='100%'>
            <Row align='center' gap='12px' width='100%'>
              <DotIcon color={color}>
                <IconStyle type={icon ? icon : 'briefcase'}/>
              </DotIcon>
              <Row justify='space-between' align='baseline' width='100%'>
                <Row gap='12px' align={isExample ? 'center' : 'baseline'}>
                  <Text maxWidth={isExample ? '100px' : 'max-content'} textOverflow='ellipsis' whiteSpace='nowrap' verticalAlign='baseline' overflow='hidden' size='16px' weight='700'>{isExample ? 'Visitas' : info?.name}</Text>
                  <Text size='14px' weight='400' color={info?.finishedActivitiesGoalPercent >= 100 && '#34C759'} lowOpacity={!(info?.finishedActivitiesGoalPercent >= 100)}>{isExample ? '33,3%' : `${info?.finishedActivitiesGoalPercent.toFixed(2)}%`}</Text>
                </Row>
                <Row>
                  <Text size='14px' weight='400'>{isExample ? '20 / 60' : `${info?.numberOfFinishedActivities} / ${info?.numberOfActivitiesGoal.toFixed(2)}` }</Text>
                </Row>
              </Row>
            </Row>
         
          </ContentHeader>
        </Tooltip>
        <ContentBody gap='6px'>
          {isExample && (
            <Row align='center' justify='space-between' padding='12px 12px 6px 12px'>
              <Row gap='8px' align='center'>
                <Dot color='#FFCC00'/>
                <Text size='14px' weight='400'>Agendado</Text>
              </Row>
              <Text size='14px' weight='400'>15</Text>
            </Row>
          )}
          
          <HorizontalLine />
       
          <RowStyle justify='center'>
            {isExample ? (
              <LineChart data={dataExample} options={options}/>
            ) : (
              <LineChartDiv>
                <LineChart data={data} options={options}/>
              </LineChartDiv>
            )}
          </RowStyle>

        </ContentBody>
      </ContentActivityGroup>
       
    </>
  );
};