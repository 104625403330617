import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Notification, useSearchParams } from '@utils';
import App from '@app';
import { Spin } from '@components';
import { setInfoUsuario } from '@ducks/login';
import { setRoutes } from '@ducks/app';

export default function Redirect({ history }) {
  const { user, url } = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(setInfoUsuario(JSON.parse(user)));
      dispatch(setRoutes(JSON.parse(user)));
      history.replace(url);
    } catch (err) {
      Notification.error('Erro ao redirecionar ' + err.message);
    }
  });

  return (
    <Spin>
      <App />
    </Spin>
  );
}
