import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputNumber, Select, Skeleton } from '@components-teammove';
import { getConfiguracao, saveConfiguracao } from '@ducks/configuracoesChamadosGeral';
import { Notification } from '@utils';
import { SmallFormItem } from '../../styles';

const TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION = {
  BLOCK: 'BLOQUEAR',
  BLOCK_TO_PERIOD: 'BLOQUEAR_POR_PERIODO',
  NO_BLOCK: 'NAO_BLOQUEAR'
};

const Geral = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [typeBlockOpenTicketAwaitingEvaluation, setTypeBlockOpenTicketAwaitingEvaluation] = useState();

  const configuracao = useSelector(({ configuracoesChamadosGeral }) => configuracoesChamadosGeral.get('configuracao'));
  const loading = useSelector(({ configuracoesChamadosGeral }) => configuracoesChamadosGeral.get('loadingGet'));
  const success = useSelector(({ configuracoesChamadosGeral }) => configuracoesChamadosGeral.get('successGet'));

  useEffect(() => {
    dispatch(getConfiguracao());

    document.getElementById('saveConfig').onclick = handleSave;
  }, []);

  useEffect(() => {
    if (success) {
      handleSetValues(configuracao);  
    }
  }, [success]);

  const handleSave = () => {
    const values = form.getFieldsValue();
    try {
      const { aguardandoRespostaDias, reaberturaAguardandoAvaliacaoDias, tipoBloqueioAberturaAguardandoAvaliacao, aberturaAguardandoAvaliacaoDias } = values;
      if ((!aguardandoRespostaDias) || (!reaberturaAguardandoAvaliacaoDias) || (tipoBloqueioAberturaAguardandoAvaliacao === TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK_TO_PERIOD && !aberturaAguardandoAvaliacaoDias)) {
        throw new Error('Preencha os dados obrigatórios!');
      } else if (aguardandoRespostaDias < 1 || aguardandoRespostaDias > 30) {
        throw new Error('Intervalo deve ser entre 1-30');
      } else {
        if (values.tipoBloqueioAberturaAguardandoAvaliacao !== TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK_TO_PERIOD) delete values.aberturaAguardandoAvaliacaoDias;
        dispatch(saveConfiguracao(values));
      }
    } catch(err) {
      Notification.error(err.message);
    }
  };

  const handleSetValues = (values) => {
    form.setFieldsValue(values);
    setTypeBlockOpenTicketAwaitingEvaluation(values.tipoBloqueioAberturaAguardandoAvaliacao);
  };

  const showDaysConfigurationToOpenTicket = useMemo(() => {
    return typeBlockOpenTicketAwaitingEvaluation === TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK_TO_PERIOD;
  }, [typeBlockOpenTicketAwaitingEvaluation]);

  return (loading ? <Skeleton.Form /> : 
    <Form layout="vertical" form={form}>
      <SmallFormItem label="Dias de fechamento de um ticket pendente" name="aguardandoRespostaDias" rules={[{ required: true, message: 'Informe dias de fechamento de um ticket pendente!', min: 1, max: 30 }]}>
        <InputNumber />
      </SmallFormItem>
      <SmallFormItem label="Dias permitidos para reabrir um ticket aguardando avaliação" name="reaberturaAguardandoAvaliacaoDias" rules={[{ required: true, message: 'Informe os dias permitidos para reabrir um ticket aguardando avaliação!', min: 1, max: 1000 }]}>
        <InputNumber />
      </SmallFormItem >
      <SmallFormItem label="Tipo de bloqueio para abertura de tickets com a existência de avaliações pendentes" name="tipoBloqueioAberturaAguardandoAvaliacao" rules={[{ required: true, message: 'Informe o tipo de bloqueio para a abertura de tickets quando existem avaliações pendentes!' }]}>
        <Select onChange={(value) => setTypeBlockOpenTicketAwaitingEvaluation(value)}>
          <Select.Option key={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK} value={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK}>Bloquear para todos</Select.Option>
          <Select.Option key={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK_TO_PERIOD} value={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.BLOCK_TO_PERIOD}>Bloquear por período</Select.Option>
          <Select.Option key={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.NO_BLOCK} value={TYPE_TO_BLOCK_OPEN_TICKET_AWAITING_EVALUATION.NO_BLOCK}>Não bloquear</Select.Option>
        </Select>
      </SmallFormItem>
      {showDaysConfigurationToOpenTicket && <SmallFormItem label="Bloquear a abertura de tickets após o usuário possuir X dias de tickets com avaliações pendentes" name="aberturaAguardandoAvaliacaoDias" rules={[{ required: true, message: 'Informe após quantos dias o bloqueio deve ser aplicado para usuários com avaliações pendentes!', min: 1, max: 1000 }]}>
        <InputNumber placeholder='N° de dias'/>
      </SmallFormItem >}
    </Form>  
  );
};

export const ExtraGeral = () => [    
  <Button id="saveConfig" key="1" size='small' context='header'>Salvar</Button>,
];

export default Geral;