import { get, post, put } from '../api';

export const getTiposAtividades = () => {
  return get('/api/activitiesTypes');
};

export const postTipoAtividade = (tipoAtividade) => {
  return post('/api/activitiesTypes', tipoAtividade);
};

export const updateTipoAtividade = (tipoAtividade) => {
  return put('/api/activitiesTypes', tipoAtividade);
};

export const getTipoAtividade = (id) => {
  return get(`/api/activitiesTypes/${id}`);
};

export const reorderTiposAtividades = (tiposAtividades) => {
  return put('/api/activitiesTypes/reorder', tiposAtividades);
};

export const getActionPlansActivitiesTypes = () => {
  return get('/api/activitiesTypes/actionPlan');
};

export const getActivitiesTypesGroup = () => {
  return get('/api/activitiesTypes/groups');
};