import React from 'react';
import { CloseIcon, DropdownContainerStyles, DropdownStyles, StyledTreeSelect, LoadingIcon } from './styles';
import { Icon, Spin } from '@components-teammove';
import { TreeSelect as TreeSelectAntd } from 'antd';

const TreeSelect = ({ onChange, loading, disabled, strategy = TreeSelectAntd.SHOW_PARENT, ...props }) => {
  const uniqueId = Date.now();
  
  return(
    <DropdownContainerStyles id={uniqueId}>
      <StyledTreeSelect 
        showCheckedStrategy={strategy} 
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        showArrow
        suffixIcon={
          loading ? (
            <Spin 
              indicator={
                <LoadingIcon
                  spin
                />
              }          
            />
          ) : (
            props.value?.length > 0 ? (
              <CloseIcon type='X' onClick={() => onChange()} />
            ) : (
              <Icon.Feather type='fi-chevron-down' />
            )
          )
        }
        allowClear={false}
        dropdownRender={(menu) => (
          <DropdownStyles>
            {menu}
          </DropdownStyles>
        )}
        getPopupContainer={() => document.getElementById(uniqueId)}
        onChange={onChange}
        treeNodeFilterProp='title'
        disabled={disabled || loading}
        {...props}
      />
    </DropdownContainerStyles>
  );
};
export default TreeSelect;