import { getPerfis } from '@sdk/Perfil';

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Perfis',
    name: 'profiles',
    placeholder: 'Selecione um ou mais perfis',
    action: getPerfis,
  },
  
];

export const formatedTime = ({ hours, minutes, seconds }, noSeconds) => {
  const addZero = (num) => String(num).padStart(2, '0');
  
  const replaceNegativeNumberForZero = (num) => {
    return (Math.sign(num) === 1 ? addZero(num) : '00');
  };
  
  return `
  ${replaceNegativeNumberForZero(hours)}:${replaceNegativeNumberForZero(minutes)}${noSeconds ? '' : ':' + replaceNegativeNumberForZero(seconds)}
  `;
}; 

export  const formatTimeInTotalHours = ({ hours, minutes, seconds }) => {
  const minutesToHours = minutes / 60;
  const secondsToHours = seconds / 3600;
 
  return (hours + minutesToHours + secondsToHours);
}; 

export const fullFilledPercentage = (total, filled) => {
  return (filled * 100 / total); 
};

export function addPointToTotalHours(numero) {
  return numero.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}