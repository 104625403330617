import styled, { css } from 'styled-components';
import { Rate, Text, View  } from '@components';
import { Icon } from '@components-teammove';

export const Meta = styled(View)`
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 4px;
`;

export const FillView = styled(View)`
  flex: 1;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const IconStar = styled(Icon.StarFilled)`
  margin-left: auto;
  font-size: 15px;
  color: gold !important;
  ${(props) =>
  props.invisible !== undefined &&
    css`
      visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
      margin-right: 5px;
    `}
`;

export const ComunicadoTagsContainer = styled(View)`
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const ComunicadoSequencial = styled(View)`
    /* color: ${({ theme }) => theme.textColor}; */
    opacity: .5;
    font-size: 12px;
    font-weight: 400;
    ${(props) =>
  props.publicado !== undefined && props.publicado === false &&
    css`
        text-decoration: line-through;
      `}
`;

export const ComunicadoTag = styled(View)`
  ${(props) =>
  props.background &&
    css`
      background-color: ${props.background};
    `}
  border-radius: 20px;
  margin-right: 10px;
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;
  display: flex;

`;

export const ComunicadoTagText = styled(Text)`
  font-size: 11px;
  color: #FFFFFF !important;
  font-weight: 500;
`;

export const ComunicadoTitulo = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
    /* color: ${({ theme }) => theme.textColor}; */
    ${(props) =>
  props.publicado !== undefined && props.publicado === false &&
      css`
        text-decoration: line-through;
      `}
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Description = styled(View)`
  max-width: 600px;
`;

export const ComunicadoResumo = styled(Text)`
  &.ant-typography {
    margin-left: 20px;
    margin-bottom: 0px !important;
    font-size: 14px;
    font-weight: 400;
    /* color: ${({ theme }) => theme.textColor}; */
    opacity: .5;
    ${(props) =>
  props.publicado !== undefined && props.publicado === false &&
      css`
        text-decoration: line-through;
      `}

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const NotaFeedback = styled(Rate)`
  color: #e7711b !important;
  > li:not(:last-child) {
    margin-right: 2px;
  }
  > li {
    cursor: pointer !important;
  }
`;

export const TextSmall = styled(Text)`
  &.ant-typography {
    /* color: ${({ theme }) => theme.textColor}; */
    opacity: .5;
    font-size: 12px;
    font-weight: 700;
    margin-right: .5rem;
    ${(props) =>
  props.margin &&
      css`
        margin-bottom: ${props.margin}px;
      `}
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const ContainerComplete = styled(View)`
  flex: 1;
`;

export const UnreadIndicator = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.actionColor};
`;