import { PageHeader } from 'antd';
import styled, { css } from 'styled-components';

const PageHeaderStyled = styled(PageHeader)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .ant-page-header-heading {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    background-color: inherit;
  }

  .ant-page-header-content {
    width: 100%;
    flex: 1;
  }

  ${(props) =>
  props.fixed &&
    css`
      padding-top: 0px !important;

      .ant-page-header-content {
        padding-top: 0px !important;
      }

      .ant-page-header-heading {
        padding-top: 16px;
        padding-bottom: 12px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    `}

  ${({ padding }) => padding &&
    css`
      padding: ${padding};
    `}

  @media(max-width: 768px) {
    span[class*='extra'] {
      .ant-btn-group {
        button {
          span ~ span {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    span[class*='extra'] {
      display: unset;
      float: right;
      width: unset;
    }
  }
`;

export default PageHeaderStyled;
