import React, { useState } from 'react';
import { View, Spin  } from '@components';
import { Icon } from '@components-teammove';
import VisualizacaoPersonalizacao from '../Personalizacao';
import VisualizacaoLista from '../Lista';
import { 
  ButtonPersonalizar,
  ButtonLista,
  WikiImage,
  TextoLista,
  Container,
} from './styles';

export default function VisualizacaoImagem({ conteudo, blockCustomization, loadingBlockCustomization }) {
  const [personaliza, setPersonaliza] = useState(false);
  const [personalizaLista, setPersonalizaLista] = useState(false);
  const [loading, setLoading] = useState(true);
  const parametros = conteudo.get('parametrosImagem') ? JSON.parse(conteudo.get('parametrosImagem')) : null;

  const handleOnLoad = () => setLoading(false);

  return (
    <View>
      {conteudo.get('parametrosImagem') && (
        <View horizontal>
          <Container>
            <ButtonPersonalizar loading={loadingBlockCustomization} type={blockCustomization ? 'disabled' : 'success'} disabled={blockCustomization} icon={<Icon.MDI type='brush' />} onClick={() => setPersonaliza(true)}>
             Personalizar
            </ButtonPersonalizar>
            {blockCustomization && <TextoLista>* Preencha os valores na lista de produtos.</TextoLista>}
          </Container>
          {parametros.textos?.some((item) => item.productItemList > 0) &&
            <ButtonLista icon={<Icon.MDI type='file-document-edit-outline' />} onClick={() => setPersonalizaLista(true)}>
              Lista de Produtos
            </ButtonLista>}
        </View>
      )}
      {personaliza && <VisualizacaoPersonalizacao conteudo={conteudo} onClose={() => setPersonaliza(false)} />}
      {personalizaLista && <VisualizacaoLista conteudo={conteudo} onClose={() => setPersonalizaLista(false)} />}

      {loading && (
        <View alignItems='center'>
          <Spin />
        </View>
      )}

      <WikiImage src={conteudo.get('conteudo')} alt='imagem' onLoad={handleOnLoad} onError={handleOnLoad} />
    </View>
  );
}
