import styled from 'styled-components';
import { List, View, Image } from '@components';

export const ContentItems = styled(View)`
  margin-top: 15px;
`;

export const ListItem = styled(List.Item)`
  color: #262626 !important;
  transition: 0.2s;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  cursor: pointer;
  
  :hover {
    color: #1890ff !important;
    background-color: #f7f9f9 !important;
  }
`;

export const ViewTrail = styled(View)`
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const ImageTrail = styled(Image)`
  width: 100px;
  height: 80px;
  border-radius: 3px 3px 0px 0px;
  margin-right: 10px;
`;