import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Form, Table, InputNumber, Select } from '@components-teammove';
import { putUnidadeEscalaRegra } from '@sdk/Unidades';
import { Notification, TelasNomenclaturas } from '@utils';
import { SmallFormItem } from '../../../styles';
import { MarginBottom } from './styles';

export default function AfastamentoCadastro({ visible, onCancel, onSuccessSave }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const [unidadesRegra, setUnidadesRegra] = useState([...location.state.regra.unidades]);
  const [loading, setLoading] = useState(false);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const tipoRegra = location.state.regra.tipo;
  const campoValor = location.state.regra.campo;
  const nomeCampo = location.state.regra.regra;
  const unidadesPermitidas = location.state.unidades.map((unidade) => ({
    ...unidade,
    key: unidade.cdEmpresa,
    value: unidade.cdEmpresa,
    label: unidade.nome,
    title: unidade.nome,
  }));
  const initialValuesSelect = location.state.regra.unidades.map((unidade) => unidade.cdEmpresa);
  const initialValuesForm = location.state.regra.unidades.reduce(
    (finalValue, value) => ({
      ...finalValue,
      [`${value.cdEmpresa}`]: value[campoValor],
    }),
    {}
  );

  const columns = [
    {
      title: `${companyNomenclature.nomenclatura}`,
      dataIndex: 'nome',
      sorter: true,
    },
    {
      title: nomeCampo,
      dataIndex: campoValor,
      width: 120,
      render: (text, { cdEmpresa }) => (
        <SmallFormItem name={`${cdEmpresa}`} rules={[{ required: true, message: 'Valor obrigatório!' }]}>
          <InputNumber placeholder='0'/>
        </SmallFormItem>
      ),
    },
  ];

  const handleOnChange = (values) => {
    const unidadesRegraNova = unidadesPermitidas.filter((unidade) => values.includes(unidade.cdEmpresa));
    setUnidadesRegra(unidadesRegraNova);
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);

      const unidades = Object.keys(values).map((key) => ({ idUnidade: parseInt(key), valor: values[key] }));

      try {
        await putUnidadeEscalaRegra(tipoRegra, unidades);
        onSuccessSave();
        onCancel();
      } catch (err) {
        setLoading(false);
        Notification.error(err.message);
      }
    }).catch(Form.scrollToFirstError);
  };

  const onBeforeCancel = () => {
    if(Object.keys(initialValuesForm).length > 0) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(initialValuesForm).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      width={720}
      visible={visible}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      title='Regra de Folga'
      onOk={handleSalvar}
      okButtonProps={{ loading }}
    >
      <Form layout='vertical'>
        <Form.Item label={'Selecionar' + (companyNomenclature.plural).toLowerCase()}>
          <Select
            mode='multiple'
            options={unidadesPermitidas}
            placeholder={'Selecione ' + (companyNomenclature.artigo) + 's ' + (companyNomenclature.plural).toLowerCase()}
            defaultValue={initialValuesSelect}
            onChange={handleOnChange}
          />
        </Form.Item>
      </Form>

      <Form layout='vertical' form={form} initialValues={initialValuesForm}>
        <Table columns={columns} dataSource={unidadesRegra} loading={false} rowKey='cdEmpresa' status='ativo' />
      </Form>
      <MarginBottom/>
    </Modal>
  );
}
