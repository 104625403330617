const set = (nomenclaturas) => {
  return localStorage.setItem('telasNomenclaturas', JSON.stringify(nomenclaturas));
};

const get = () => {
  return localStorage.getItem('telasNomenclaturas');
};

const remove = () => {
  return localStorage.removeItem('telasNomenclaturas');
};

const getNomenclature = (tela) => {
  try {
    const nomenclatures = JSON.parse(get());

    if (!nomenclatures) return undefined;

    const object = nomenclatures.filter((item) => item.tela === tela)[0];
    return object ? object.nomenclatura : undefined;
  }
  catch (err) {
    return undefined;
  }
};

const getNomenclatures = (tela) => {
  try {
    const nomenclatures = JSON.parse(get());

    const defaultNomenclatures = {
      'CHAMADOS': {
        nomenclatura: 'Atendimento',
        plural: 'Atendimentos',
        artigo: 'o'
      },
      'UNIDADES': {
        nomenclatura: 'Unidade',
        plural: 'Unidades',
        artigo: 'a'
      },
      'EAD': {
        nomenclatura: 'EAD',
        plural: 'EAD',
        artigo: 'o'
      }
    };

    if (!nomenclatures) return defaultNomenclatures[tela];

    const nomenclature = nomenclatures.find((item) => item.tela === tela);

    if (!nomenclature) return defaultNomenclatures[tela];

    const defaultNomenclature = defaultNomenclatures[tela];
    nomenclature.nomenclatura = nomenclature.nomenclatura || defaultNomenclature.nomenclatura;
    nomenclature.plural = nomenclature.plural || defaultNomenclature.plural;
    nomenclature.artigo = nomenclature.artigo || defaultNomenclature.artigo;
   
    return nomenclature;
  }
  catch (err) {
    return undefined;
  }
};

export default {
  set,
  get,
  remove,
  getNomenclature,
  getNomenclatures,
};