import styled from 'styled-components';
import { Row, Image } from '@components-teammove';

export const Container = styled.div`
  padding: 38px 33px;
`;

const Text = styled.span`
  color: #000000
`;

export const OrderNumber = styled(Text)`
  font-size: 28px;
  font-weight: 700;
`;

export const SubTitle = styled(Text)`
  font-size: 12px;
  font-weight: 700;
`;

export const Label = styled(Text)`
  font-size: 10px;
  font-weight: 700;
`;

export const SimpleText = styled(Text)`
  font-size: 10px;
  font-weight: 400;
`;

export const RegularText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
`;

export const CreatedByAt = styled(RegularText)`
  opacity: .5;
`;

export const InfoCard = styled(Row)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-color: #F0F0F1;
  border-radius: 8px;
  padding: 7px 16px;
`;

export const TotalInfoCard = styled(InfoCard)`
  padding: 7px 8px;
`;

export const CustomerLogo = styled(Image)`
  object-fit: contain;
  max-height: 84px;
`;