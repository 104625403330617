import styled from 'styled-components';
import { Icon, Column, Row } from '@components-teammove';
import { lighten } from 'polished';

export const DotIcon = styled.div`
  width: 36px;
  height: 32px;
  background-color: ${({ color }) => color ? color : '#000000'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconStyle = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const ContentHeader = styled(Row)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 16px 16px 0 0;
  padding: 12px;
  :hover {
		background-color: ${({ theme })=> lighten(0.085, theme.darkPrimaryColor)};
		cursor: pointer;
	}
`;

export const ContentBody = styled(Column)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 0 0 16px 16px;
`;

export const ContentActivityGroup = styled(Column)`
  ${({ shadow }) => shadow && 'box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25)'};
  border-radius: 16px;
  color: ${({ theme }) => theme.textColor};
  border: ${({ activeType })=> activeType ? '2px var(--super-success-color) solid' : '' };
  :hover {
		cursor: pointer;
    ${ContentHeader} {
      background-color: ${({ theme }) => lighten(0.085, theme.darkPrimaryColor)};
    }
	}
  min-width: 32.85%;
  flex: 1;
`;

export const Dot = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color ? color : '#FFCC00'};
`;

export const HorizontalLine = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const ContentLineChart = styled.div`
  padding: 0 20px 20px 20px;
`;

export const RowStyle = styled(Row)`
  padding: 12px;
  height: 100%;
  width: 100%;
`;

export const LineChartDiv = styled.div`
  width: 100%;
  min-height: 250px;
`;

export const RowCards = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ colorBg }) => colorBg};
  padding: 6px;
`;