import { get, post, put } from '../api';

export const getOrdersKinds = () => {
  return get('/api/orders/orderKinds');
};

export const getOrderKind = (orderKindId) => {
  return get(`/api/orders/orderKinds/${orderKindId}`);
};

export const postOrderKind = (orderKind) => {
  return post('/api/orders/orderKinds', orderKind);
};

export const putOrderKind = (orderKind) => {
  return put('/api/orders/orderKinds', orderKind);
};