import { View } from '@components/';
import React from 'react';
import styled from 'styled-components';

const getBgColor = (pr) => {
  if (pr < 50.0) {
    return '#CC4444';
  } else if (pr < 80.0) {
    return '#FF9637';
  } else {
    return '#66CC66';
  }
};

const ViewProgressaoAulas = styled(View)`
  border-radius: 0 0 3px 3px;
  background-color: #cccccc;
  height: 6px;
`;

const ViewProgressaoAulaAtual = styled(View)`
  height: 100%;
  border-radius: 5px;
  background-color: ${(props) => props.bgColor};
  width: ${(props) => props.width}%;
`;

export default function ProgressBar({ pr }) {
  return (
    <ViewProgressaoAulas>
      <ViewProgressaoAulaAtual bgColor={getBgColor(pr)} width={pr} />
    </ViewProgressaoAulas>
  );
}
