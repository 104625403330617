import styled from 'styled-components';
import { Row } from '@components-teammove';

export const Flex = styled(Row)`
  flex-direction: ${({ direction }) => direction};
`;

export const ContentTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  margin-left: 20px;
`;

export const ContentCard = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 28px;
  padding: 20px;
  height: 100%;
`;

export const RankingContainer = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
`;