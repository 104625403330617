import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';

const getAction = (URI, folder) => `${URI}/api/upload/${encodeURIComponent(folder)}`;

const UploadComponent = ({ URI, folder, children, ...otherProps }) => (
  <Upload action={getAction(URI, folder)} {...otherProps}>
    {children}
  </Upload>
);

const DraggerComponent = ({ URI, folder, children, ...otherProps }) => (
  <Upload.Dragger action={getAction(URI, folder)} {...otherProps}>
    {children}
  </Upload.Dragger>
);

const propTypes = {
  URI: PropTypes.string.isRequired,
  headers: PropTypes.object.isRequired,
  folder: PropTypes.string.isRequired,
  onDownload: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  folder: '',
};

UploadComponent.propTypes = propTypes;
UploadComponent.defaultProps = defaultProps;

DraggerComponent.propTypes = propTypes;
DraggerComponent.defaultProps = defaultProps;

UploadComponent.Dragger = DraggerComponent;
export default UploadComponent;
