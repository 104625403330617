import React from 'react';
import { StyledInputMask } from './styles';

const InputMask = ({ onChange, form, formFieldName, ...props }) => (
  <StyledInputMask 
    autocomplete='off' 
    allowClear={false} 
    onChange={onChange}
    {...props}
  />
);

export default InputMask;