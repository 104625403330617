import styled, { css } from 'styled-components';
import View from '../View';

const Body = styled(View)`
  flex: 1;
  width: 100%;

  ${(props) =>
  props.fixed
    ? css`
          overflow: initial;
        `
    : css`
          overflow: auto;
        `}
`;

export default Body;
