import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: .4fr .6fr;
`;

export const ContentCard = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const CardTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const CardSubTitle = styled(CardTitle)`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  line-height: 18px;
  text-decoration: ${({ isCanceled })=> isCanceled && 'line-through'}
`;

export const CardText = styled(CardTitle)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const UserProfile = styled(CardText)`
  opacity: .5;
`;

export const ClosedPercent = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const CardSecondaryTitle = styled(CardTitle)`
  font-size: 16px;
  line-height: 18px;
`;