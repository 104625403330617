import styled from 'styled-components';
import { Input, Button, Row, Form } from '@components-teammove';

export const ObservationBox = styled(Input.TextArea)`
  &.ant-input {
    height: 175px !important;
    max-height: 175px !important;
  }
`;

export const ButtonFooter = styled(Button)`
  width: 100% !important;
`;

export const RowButton = styled(Row)`
  margin-bottom: 20px;
`;

export const FormContainer = styled(Form)`
    margin-top: 8px;
    width: 100%;
`;