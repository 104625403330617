import { getPerfis } from '@sdk/Perfil';

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Perfis',
    name: 'profiles',
    placeholder: 'Selecione um ou mais perfis',
    action: getPerfis,
  },
  
];
