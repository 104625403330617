import styled from 'styled-components';
import { Icon , Button } from '@components-teammove';

import { Column } from '../styles';

export const Container = styled(Column)`
  gap: 40px;
  max-width: 90%;

  @media screen and (min-width: 1000px) {
    max-width: 80%;
    margin: auto;
  }
`;

export const GroupTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const QuestionNumber = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const QuestionContainer = styled.div`
  border-radius: 28px;
  padding: 48px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  position: relative;
`;

export const QuestionTitle = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};

  span.required-mark {
    color: var(--danger-color);
    background-color: transparent;
  }
`;

export const HelpText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const HelpIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};
  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const TipText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;

  strong {
    font-weight: 700;
  }
`;

export const ToggleHistoricButton = styled(Button)`
  &.ant-btn.ant-btn-link {
    font-weight: 400;
    opacity: .5;
  }
`;

export const HistoricTitle = styled(QuestionTitle)`
  padding-bottom: 34px;
  border-bottom: 2px solid ${({ theme }) => theme.backgroundColor};
`;

export const HistoricItemColumn = styled(Column)`
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.backgroundColor};
`;

export const HistoricItemDate = styled(TipText)`

`;

export const HistoricItemAnswer = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const PreviousQuestionIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  position: absolute;
  top: 50%;
  left: -3rem;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const NextQuestionIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  position: absolute;
  top: 50%;
  right: -3rem;
  transform: translateY(-50%);
  cursor: pointer;
`;