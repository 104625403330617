import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, Switch, Picker, Skeleton } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getFamilies, resetSuccess, postFamily, putFamily } from '@ducks/settingsProductsFamilies';
import { InitialValues } from './rules';

export default function FamilyRegister({ onCancel, visible, categoryId }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const id = location.state?.data.id;
  const dispatch = useDispatch();

  const families = useSelector(({ families }) => families.get('families'));
  const loadingFamilies = useSelector(({ families }) => families.get('loadingFamilies'));
  const successFamilies = useSelector(({ families }) => families.get('successFamilies'));
  
  const loadingFamilySave = useSelector(({ families }) => families.get('loadingFamilySave')); 

  const [originalFieldsValue, setOriginalFieldsValue] = useState();

  useEffect(() => {
    if(id !== undefined) {
      dispatch(getFamilies(id));
    }else{
      form.setFieldsValue(InitialValues);
    }
  }, []);

  useEffect(()=>{
    return () => {
      history.push('#');
      dispatch(resetSuccess());
    };
  },[]);

  useEffect(() => {
    if(successFamilies) {
      form.setFieldsValue(families);
      setOriginalFieldsValue(form.getFieldsValue());
    }
  }, [successFamilies]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      values.categoryId = families.categoryId;
      
      if(id !== undefined) {
        values.id = families.id;      
        dispatch(putFamily(values));
      }else{
        values.categoryId = categoryId;
        dispatch(postFamily(values));
      }
    });
  };
  
  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return(
    <Modal
      title='Nova Familia'
      visible={visible}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      okButtonProps={{ loading:loadingFamilySave }}
      onOk={handleSave}
    >
      <Form layout='vertical' form={form}>
        <Form.Item label='Título da categoria' name='name' rules={[{ required: true, message: 'Informe o titulo!' }]}>
          {loadingFamilies ?  <Skeleton.Form/> : (
            <Input placeholder='Informe o título da categoria' autoFocus/>)
          }
        </Form.Item>    
        <Form.Item label='Cor' name='color' rules={[{ required: true, message: 'Informe uma cor!' }]} valuePropName='color'>
          {loadingFamilies ?  <Skeleton.Form/> : (
            <Picker position='inherit'/>)
          }
        </Form.Item> 
        {id !== undefined && (
          <Form.Item label='Ativo' name='active' valuePropName='checked'>
            {loadingFamilies ? <Skeleton.Circle/> :
              <Switch/>}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}