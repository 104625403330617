/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Visualizacao } from '@containers/Wikis/Conteudos/Visualizacao';
import { Button, View, Tag } from '@components';
import { Icon } from '@components-teammove';
import styled, { css } from 'styled-components';
import { postCurtido, postLido } from '@ducks/wikisAcoes';
import { alterCurrentAula, selectAula, setAndamentoQuiz } from '@ducks/wikisEAD';
import { useParams } from 'react-router-dom';
import Comentarios from '@containers/Wikis/Comentarios';
import { tiposConteudo } from '@containers/Wikis/EAD/rules';

const MainView = styled(View)`
  width: 100%;
  height: 100%;
`;

const MateriaView = styled(View)`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const AulaView = styled(View)`
  font-size: 16px;
`;

const ContentView = styled(View)`
  width: 100%;
  max-width: 60vw;
  ${({ quiz }) =>
    quiz
      ? css`
          height: 100%;
          min-height: 60vh;
        `
      : css`
          min-height: 60vh;
        `};
  flex: 1;
  align-self: center;
  overflow-y: auto;
  margin-top: 15px;
`;

const ResumoView = styled(View)`
  width: 100%;
  white-space: pre-wrap;
`;

const AcoesView = styled(View)`
  width: 100%;
  margin-top: 20px;
`;

const AcoesViewQuiz = styled(View)`
  width: 100%;
  padding-right: 5px;
`;

const Acoes = styled(View)`
  flex: 1;
`;

const AcaoAulaView = styled(View)`
  width: fit-content;
  padding: 15px 0 0 5px;
  align-items: center;

  ${({ enabled }) => {
    if (!enabled) {
      return css`
        pointer-events: none;
        opacity: 0.5;
      `;
    } else {
      return css`
        cursor: pointer;
        :hover {
          color: #1890ff !important;
        }
      `;
    }
  }}
`;

const AcoesAulaViewHidden = styled(View)`
  display: none;
`;

const AulaAnteriorIcon = styled(Icon.ArrowLeftOutlined)`
  margin-right: 5px;
`;

const ProximaAulaIcon = styled(Icon.ArrowRightOutlined)`
  margin-left: 5px;
`;

const AulaFinalizadaIcon = styled(Icon.CheckOutlined)`
  margin-left: 5px;
`;

const ComentariosView = styled(View)`
  width: 100%;
  margin-top: 20px;
`;

const IconLiked = styled(Icon.HeartFilled)`
  color: #ed4956 !important;
  margin-right: 10px;
`;

const IconLike = styled(Icon.HeartOutlined)`
  margin-right: 10px;
`;

const ViewLike = styled(View)`
  margin-top: 10px;
  width: fit-content;
  span {
    width: fit-content;
    font-size: 30px;
  }
  flex-direction: row;
  align-items: center;
`;

const TagStyled = styled(Tag)``;

export default function Aula() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isQuiz, setIsQuiz] = useState(false);

  const aula = useSelector(({ wikisEAD }) => wikisEAD.get('currentAula'));
  const theme = useSelector(({ app }) => app.get('theme'));

  const aulaAnterior = useSelector(({ wikisEAD }) => wikisEAD.get('aulaAnterior'));
  const proximaAula = useSelector(({ wikisEAD }) => wikisEAD.get('proximaAula'));

  useEffect(() => {
    setIsQuiz(aula.get('tipo') === tiposConteudo.QUIZ);
  }, [aula]);

  const handleSelection = ({ aula, materiaId, materiaTitulo }) => {
    dispatch(selectAula({ aula, cursoId: id, materiaId, materiaTitulo }));
  };

  const handleFinalizarAula = (event) => {
    if (event) event.stopPropagation();
    dispatch(postLido(aula.get('id'), !aula.get('lido')));
    dispatch(alterCurrentAula({ lido: !aula.get('lido') }));
  };

  const handlePreviousAula = (event) => {
    event.stopPropagation();
    handleSelection(aulaAnterior);
  };
  const handleNextAula = (event) => {
    event.stopPropagation();
    
    if (!isQuiz && !aula.get('lido')) {
      handleFinalizarAula();
    }

    handleSelection(proximaAula);
  };

  const handleLike = (event) => {
    event.stopPropagation();
    const curtido = aula.get('curtido');
    dispatch(postCurtido(aula.get('id'), !curtido));
    dispatch(alterCurrentAula({ curtido: !curtido }));
  };
  
  const isNextClassQuiz = () => {
    return proximaAula && proximaAula.aula && proximaAula.aula.tipo === 'QUIZ';
  };

  const ContentButtonPreviousClass = (
    <Acoes justifyContent='center' alignItems='flex-start'>
      <AcaoAulaView horizontal enabled={aulaAnterior} onClick={(event) => handlePreviousAula(event)}>
        <Button>
          <AulaAnteriorIcon /> Aula anterior
        </Button>
      </AcaoAulaView>
    </Acoes>
  );

  const ContentButtonNextClass = (
    <Acoes justifyContent='center' alignItems='flex-end'>
      <AcaoAulaView horizontal enabled={proximaAula} onClick={(event) => handleNextAula(event)}>
        <Button type='success'>
          {isNextClassQuiz() ? 'Responder quiz' : 'Próxima aula'} <ProximaAulaIcon />
        </Button>
      </AcaoAulaView>
    </Acoes>
  );

  return (
    <MainView>
      <MateriaView>{aula.get('materiaTitulo')}</MateriaView>
      <AulaView horizontal alignItems='center'>
        {isQuiz ? <TagStyled color={theme['@primary-color']}>quiz</TagStyled> : <></>} {aula.get('titulo')}
      </AulaView>
      {aula.get('resumo') && (
        <ResumoView>{aula.get('resumo')}</ResumoView>
      )}
      <ContentView quiz={isQuiz}>
        <Visualizacao conteudo={aula} origin='ead' height='98%' idCategoria={aula.get('id')} onInitQuiz={(data) => dispatch(setAndamentoQuiz(data))} />
        {isQuiz && 
          <AcoesViewQuiz horizontal>
            {ContentButtonPreviousClass}
            {ContentButtonNextClass}
          </AcoesViewQuiz>
        }
      </ContentView>
      {isQuiz ? (
        <></>
      ) : (
        <>
          <AcoesView horizontal>
            {ContentButtonPreviousClass}
            <Acoes justifyContent='center' alignItems='center'>
              <AcoesAulaViewHidden>
                <AcaoAulaView enabled horizontal onClick={(event) => handleFinalizarAula(event)}>
                  {aula.get('lido') ? (
                    <AcaoAulaView enabled horizontal>
                      <Button>
                        Aula concluída <AulaFinalizadaIcon />
                      </Button>
                    </AcaoAulaView>
                  ) : (
                    <AcaoAulaView enabled horizontal>
                      <Button type='success'>Finalizar aula</Button>
                    </AcaoAulaView>
                  )}
                </AcaoAulaView>
              </AcoesAulaViewHidden>
            </Acoes>
            {ContentButtonNextClass}
          </AcoesView>
          {aula.get('curtido') ? (
            <ViewLike>
              <IconLiked onClick={(event) => handleLike(event)} />
              {aula.get('qtCurtido') === 1 ? (
                <View horizontal>
                  <b>Você</b>&nbsp;curtiu esse conteúdo
                </View>
              ) : aula.get('qtCurtido') > 2 ? (
                <View horizontal>
                  <b>Você</b>&nbsp;e&nbsp;<b>outras {aula.get('qtCurtido') - 1}</b>&nbsp;pessoas curtiram esse conteúdo
                </View>
              ) : (
                <View horizontal>
                  <b>Você</b>&nbsp;e&nbsp;<b>outra</b>&nbsp;pessoa curtiram esse conteúdo
                </View>
              )}
            </ViewLike>
          ) : (
            <ViewLike>
              <IconLike onClick={(event) => handleLike(event)} />
              {aula.get('qtCurtido') === 1 ? (
                <View horizontal>
                  <b>1</b>&nbsp;pessoa curtiu esse conteúdo
                </View>
              ) : (
                aula.get('qtCurtido') > 0 && (
                  <View horizontal>
                    <b>{aula.get('qtCurtido')}</b>&nbsp;pessoas curtiram esse conteúdo
                  </View>
                )
              )}
            </ViewLike>
          )}
          <ComentariosView>
            <Comentarios data={[]} idCategoria={aula.get('id')} />
          </ComentariosView>
        </>
      )}
    </MainView>
  );
}
