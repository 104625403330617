import React from 'react';
import { StyledViewControl, ViewOption } from './styles';

const ViewControl = ({ options, current, onChange, disabled, ...props }) => (
  <StyledViewControl {...props}>
    {options.map((option) => (
      <ViewOption current={current === option.name} key={option.name} title={option.name} onClick={() => !disabled && onChange(option.name)}>
        {option.icon}
      </ViewOption>
    ))}
  </StyledViewControl>
);

export default ViewControl;