import React from 'react';
import { ControlsAnimation, PlayerAnimation } from './styles';

const Animations = ({ controls, src, animationWidth, ...props }) => {
  return (
    <PlayerAnimation
      src={src}
      autoplay
      animationWidth={animationWidth}
      {...props} 
    >
      <ControlsAnimation visible={controls} buttons={['play', 'repeat', 'frame', 'debug']}/>
    </PlayerAnimation>
  );
};

export default Animations;