import React, { useEffect } from 'react';
import { Button, View, Spin } from '@components';
import styled from 'styled-components';
import { estadosQuiz } from '../rules';
import { useDispatch, useSelector } from 'react-redux';
import { InfoUsuario } from '@utils';
import { postNovaTentativa, reset } from '@ducks/wikisEADQuiz';
import Historico from './Historico';
import ResumoUltimaTentativa from './ResumoUltimaTentativa';

const Titulo = styled(View)`
  font-size: 48px;
  font-weight: bold;
  text-align: center;
`;

export default function Inicio({ data, handlers }) {
  const { quiz } = data;
  const { setEstadoAtual, onInitQuiz } = handlers;
  const dispatch = useDispatch();

  const loadingTentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('loadingTentativa'));
  const successTentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successTentativa'));

  useEffect(() => {
    if (successTentativa) {
      setEstadoAtual(estadosQuiz.RESPONDENDO);
      dispatch(reset());
    }
  }, [successTentativa]);

  const handleClickIniciarQuiz = () => {
    const { id, versao } = quiz;
    onInitQuiz && onInitQuiz({ desistencia: false, finalizado: false });
    dispatch(postNovaTentativa({ idQuiz: id, versao, cdUsuario: InfoUsuario.get('cdUsuario') }));
  };

  return (
    <>
      <Titulo>{quiz.descricao}</Titulo>

      {loadingTentativa ? (
        <View>
          <Spin />
        </View>
      ) : (
        <Button type='success' onClick={handleClickIniciarQuiz}>
          {quiz.tentativas?.length > 0 ? 'Refazer Quiz' : 'Iniciar Quiz'}
        </Button>
      )}
      <ResumoUltimaTentativa data={{ tentativa: quiz.tentativas && quiz.tentativas[0] }} handlers={{ setEstadoAtual }} />
      <Historico data={{ quiz }} handlers={{ setEstadoAtual }} />
    </>
  );
}
