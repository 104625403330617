import React from 'react';
import { DashboardCard, HorizontalContainer } from '@components-teammove';

export default function HistoricoTickets({ dashboard, company, handleRedirectToTickets }) {
  return(
    <HorizontalContainer 
      items={dashboard.map((status) => (
        <DashboardCard key={status.id} statusItem={status} color={status?.cor} description={status?.descricao} total={status?.total} onClick={() => handleRedirectToTickets(status)} gridItem setAtTheBottom/>
      ))}
    />
  );
}