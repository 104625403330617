import Text from '../Text';
import View from '../View';
import Icon from '../Icon';
import styled from 'styled-components';

export const Row = styled(View)`
  flex-direction: row;
`;

export const StyledCheckboxGroup = styled.div`
  display: ${({ vertical }) => vertical ? 'flex' : 'grid'};
  ${({ vertical }) => vertical && `
    flex-direction: column;
  `}
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: ${({ gap, vertical }) => gap || (vertical ? '20px' : '24px')};
`;

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.checked ? props.theme.actionColor : props.theme.darkPrimaryColor};
  border: 1px solid ${(props) => props.checked ? props.theme.actionColor : props.theme.darkHighlightColor};
  border-radius: 8px;
  padding: 1px;
  width: 24px;
  height: 24px;
  flex: none !important;
  cursor: pointer;
`;

export const CheckIcon = styled(Icon.MDI)`
  color: ${(props) => props.theme.textContrastColor} !important;

  path {
    color: ${(props) => props.theme.textContrastColor} !important;
  }
`;

export const Children = styled(Text)`
  margin-left: 16px;
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
  }
`;