import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getPriceTables as getPriceTablesSdk,
  getPriceTable as getPriceTableSdk,
  postPriceTable as postPriceTableSdk,
  putPriceTable as putPriceTableSdk,
} from '@sdk/PriceTables';

//Action Types
export const Types = {
  GET_PRICE_TABLES: 'PRODUCTS/GET_PRICE_TABLES',
  GET_PRICE_TABLES_SUCCESS: 'PRODUCTS/GET_PRICE_TABLES_SUCCESS',
  GET_PRICE_TABLES_ERROR: 'PRODUCTS/GET_PRICE_TABLES_ERROR',

  GET_PRICE_TABLE: 'PRODUCTS/GET_PRICE_TABLE',
  GET_PRICE_TABLE_SUCCESS: 'PRODUCTS/GET_PRICE_TABLE_SUCCESS',
  GET_PRICE_TABLE_ERROR: 'PRODUCTS/GET_PRICE_TABLE_ERROR',

  POST_PRICE_TABLE: 'PRODUCTS/POST_PRICE_TABLE',
  POST_PRICE_TABLE_SUCCESS: 'PRODUCTS/POST_PRICE_TABLE_SUCCESS',
  POST_PRICE_TABLE_ERROR: 'PRODUCTS/POST_PRICE_TABLE_ERROR',

  PUT_PRICE_TABLE: 'PRODUCTS/PUT_PRICE_TABLE',
  PUT_PRICE_TABLE_SUCCESS: 'PRODUCTS/PUT_PRICE_TABLE_SUCCESS',
  PUT_PRICE_TABLE_ERROR: 'PRODUCTS/PUT_PRICE_TABLE_ERROR',
  
  RESET: 'PRODUCTS/RESET',
  
  RESET_SUCCESS: 'PRODUCTS/RESET_SUCCESS',
};

//Action Creators
export const getPriceTables = () => ({ type: Types.GET_PRICE_TABLES });
export const getPriceTablesSuccess = (priceTables) => ({ type: Types.GET_PRICE_TABLES_SUCCESS, priceTables });
export const getPriceTablesError = (error) => ({ type: Types.GET_PRICE_TABLES_ERROR, error });

export const getPriceTable = (priceTableId) => ({ type: Types.GET_PRICE_TABLE, priceTableId });
export const getPriceTableSuccess = (priceTable) => ({ type: Types.GET_PRICE_TABLE_SUCCESS, priceTable });
export const getPriceTableError = (error) => ({ type: Types.GET_PRICE_TABLE_ERROR, error });

export const postPriceTable = (priceTable) => ({ type: Types.POST_PRICE_TABLE, priceTable });
export const postPriceTableSuccess = (priceTable) => ({ type: Types.POST_PRICE_TABLE_SUCCESS, priceTable });
export const postPriceTableError = (error) => ({ type: Types.POST_PRICE_TABLE_ERROR, error });

export const putPriceTable = (priceTable) => ({ type: Types.PUT_PRICE_TABLE, priceTable });
export const putPriceTableSuccess = (priceTable) => ({ type: Types.PUT_PRICE_TABLE_SUCCESS, priceTable });
export const putPriceTableError = (error) => ({ type: Types.PUT_PRICE_TABLE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

//saga
function* fetchGetPriceTables(action) {
  try {
    const priceTables = yield call(getPriceTablesSdk);
    yield put(getPriceTablesSuccess(priceTables));
  } catch (err) {
    Notification.error(err.message);
    yield put(getPriceTablesError(err));
  }
}

function* fetchGetPriceTable(action) {
  try {
    const { priceTableId } = action;
    const priceTable = yield call(getPriceTableSdk, priceTableId);
    yield put(getPriceTableSuccess(priceTable));
  } catch (err) {
    Notification.error(err.message);
    yield put(getPriceTableError(err));
  }
}

function* fetchPostPriceTable(action) {
  try{
    let { priceTable } = action;
    priceTable = yield call(postPriceTableSdk, priceTable);
    yield put(postPriceTableSuccess(priceTable));
  } catch (err) {
    Notification.error(err.message);
    yield put(postPriceTableError(err));
  }
}
  
function* fetchPutPriceTable(action) {
  try{
    let { priceTable } = action;
    priceTable = yield call(putPriceTableSdk, priceTable);
    yield put(putPriceTableSuccess(priceTable)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(putPriceTableError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_PRICE_TABLES, fetchGetPriceTables),
  takeLatest(Types.GET_PRICE_TABLE, fetchGetPriceTable),
  takeLatest(Types.POST_PRICE_TABLE, fetchPostPriceTable),
  takeLatest(Types.PUT_PRICE_TABLE, fetchPutPriceTable),
];

// Reducer
const initialState = Map({
  priceTables: List(),
  loadingPriceTables: false,
  successPriceTables: false,
  errorPriceTables: false,

  priceTable: List(),
  loadingPriceTable: false,
  successPriceTable: false,
  errorPriceTable: false,
  
  loadingPriceTableSave:false,
  successPriceTableSave:false,
  errorPriceTableSave:false,
});

const handleGetPriceTables = (state, action) => {
  return state.set('loadingPriceTables', true).set('successPriceTables', false).set('errorPriceTables', false);
};
    
const handleGetPriceTablesSuccess = (state, action) => {
  const { priceTables } = action;
  
  return state.set('priceTables', priceTables).set('loadingPriceTables', false).set('successPriceTables', true).set('errorPriceTables', false);
};
    
const handleGetPriceTablesError = (state, action) => {
 
  return state.set('loadingPriceTables', false).set('successPriceTables', false).set('errorPriceTables', action.error);
};
  
const handleGetPriceTable = (state, action) => {
  return state.set('loadingPriceTable', true).set('successPriceTable', false).set('errorPriceTable', false);
};
    
const handleGetPriceTableSuccess = (state, action) => {
  const { priceTable } = action;
  
  return state.set('priceTable', priceTable).set('loadingPriceTable', false).set('successPriceTable', true).set('errorPriceTable', false);
};
    
const handleGetPriceTableError = (state, action) => {
 
  return state.set('loadingPriceTable', false).set('successPriceTable', false).set('errorPriceTable', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingPriceTableSave', true).set('successPriceTableSave', false).set('errorPriceTableSave', false);
};
  
const handleSaveSuccess = (state, action) => {
  return state.set('loadingPriceTableSave', false).set('successPriceTableSave', true).set('errorPriceTableSave', false);
};
  
const handleSaveError = (state, action) => {
  return state.set('loadingPriceTableSave', false).set('successPriceTableSave', false).set('errorPriceTableSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};
  
const handleResetSuccess = (state, action) => {
  return state.set('successPriceTables', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PRICE_TABLES:
      return handleGetPriceTables(state, action);
    case Types.GET_PRICE_TABLES_SUCCESS:
      return handleGetPriceTablesSuccess(state, action);
    case Types.GET_PRICE_TABLES_ERROR:
      return handleGetPriceTablesError(state, action);

    case Types.GET_PRICE_TABLE:
      return handleGetPriceTable(state, action);
    case Types.GET_PRICE_TABLE_SUCCESS:
      return handleGetPriceTableSuccess(state, action);
    case Types.GET_PRICE_TABLE_ERROR:
      return handleGetPriceTableError(state, action);
  
    case Types.POST_PRICE_TABLE:
      return handleSave(state, action);
    case Types.POST_PRICE_TABLE_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_PRICE_TABLE_ERROR:
      return handleSaveError(state, action);
    
    case Types.PUT_PRICE_TABLE:
      return handleSave(state, action);
    case Types.PUT_PRICE_TABLE_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_PRICE_TABLE_ERROR:
      return handleSaveError(state, action);
  
    case Types.RESET:
      return handleReset(state, action);
  
    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);
  
    default:
      return state;
  }
}