import { Menu } from 'antd';
import styled, { css } from 'styled-components';

const MenuItem = styled(Menu.Item)`
  ${(props) => (props.type === 'danger') && css`
    * {
      color: #FF6866 !important;
    }
  `}
`;

Menu.Item = MenuItem;
export default Menu;
