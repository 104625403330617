import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Column, 
  Row, 
  Button, 
  Skeleton,
  Form
} from '@components-teammove';
import { getCurrentYear, getCurrentMonth } from '@utils';
import ChartProgress from './ChartProgress';

import { 
  Header,
  Panel,
  FeatherIcon,
  SelectStyle,
  YearText,
  YearButton,
  FormItemStyles,
  StyleEmpty,
} from './styles';

import { getYearSummary, getClustersByGroup, getCompanyClusterSituation } from '@ducks/gestaoCarteira';
import { getClusters } from '@ducks/configuracoesUnidadesClusters';
import { verifyDate } from '../rules';

const PanelDashboard = ({ status, filters, search, setLimit }) => {
  const dispatch = useDispatch();
  const selectorRef = useRef(null);
  const [form] = Form.useForm();

  const yearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('yearSummary'));
  const loadingYearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingYearSummary'));
  const successYearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('successYearSummary'));
  
  const clustersGroups = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('clusters'));
  
  const clustersByGroup = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('clustersByGroup'));

  const isAllValuesZero = yearSummary?.every((obj) => Object.values(obj).every((val) => val === 0));
  
  const limit = 20;
  const month = parseInt(getCurrentMonth());
  const [ activeMonth, setActiveMonth ] = useState(month);
  const trueMonth = activeMonth + 1;

  const year = parseInt(getCurrentYear());
  const [ activeYear, setActiveYear ] = useState(year);

  const group = parseInt(localStorage.getItem('GROUP'));
  const [ activeGroup, setActiveGroup ] = useState(group);
  
  const cluster = parseInt(localStorage.getItem('CLUSTER'));
  const [ activeCluster, setActiveCluster ] = useState(cluster);

  useEffect(() => {
    if (selectorRef.current) {
      selectorRef.current.focus();
    }
  }, []);

  useEffect(() => {
    dispatch(getClusters());
    dispatch(dispatch(getClustersByGroup(activeGroup)));
    dispatch(getYearSummary(activeGroup, activeYear, filters, activeCluster, trueMonth));
    form.setFieldsValue(
      { activeGroup, activeCluster }
    );
    
  },[filters]);
  
  const handleChangeGroup = (group) => {    
    setActiveGroup(group);
    setActiveCluster(0);
    setLimit(limit);
    dispatch(getYearSummary(group, activeYear, filters, 0, trueMonth));
    dispatch(getClustersByGroup(group));
    dispatch(getCompanyClusterSituation(group, `${verifyDate(activeYear, activeMonth)}`, search, filters, 0, limit, status));
    form.setFieldsValue({ activeGroup: group, activeCluster: 0 });
  };

  const handleChangeCluster = (cluster) => {
    setActiveCluster(cluster);
    setLimit(limit);
    dispatch(getYearSummary(activeGroup, activeYear, filters, cluster, trueMonth));
    dispatch(getCompanyClusterSituation(activeGroup, `${verifyDate(activeYear, activeMonth)}`, search, filters, cluster, limit, status));
  };

  const handleActiveMonth = (value)=> {
    const falseMonth = value;
    const trueMonth = value + 1; 
    if(activeMonth !== falseMonth) {
      setLimit(limit);
      setActiveMonth((prevActive) => prevActive === value ? null : falseMonth);
      dispatch(getYearSummary(activeGroup, activeYear, filters, activeCluster, trueMonth));
      dispatch(getCompanyClusterSituation(activeGroup, `${verifyDate(activeYear, falseMonth)}`, search, filters, activeCluster, limit, status));
    }
  };
  
  const handleChangeYear = (year) => {
    setActiveYear(year);
    setLimit(limit);
    dispatch(getYearSummary(activeGroup, year, filters, activeCluster, trueMonth));
    dispatch(getCompanyClusterSituation(activeGroup, `${verifyDate(year, activeMonth)}`, search, filters, activeCluster, limit, status));
  };

  return(
    <> 
      {loadingYearSummary ? <Skeleton.Map/> : (
        
        <Column>
          <Header justify='space-between'>
            <Row align='center' gap='12px'>
              <Form form={form} >
                <FormItemStyles name='activeGroup'>
                  <SelectStyle
                    onChange={(group) => handleChangeGroup(group)}
                    placeholder='Grupo de cluster' 
                    options={clustersGroups.map((clusterGroup) => ({ label: clusterGroup.nome, value: clusterGroup.id }))}
                    fitContent
                  />
                </FormItemStyles>
              </Form>

              <Form form={form}>
                <FormItemStyles name='activeCluster'>
                  <SelectStyle 
                    ref={selectorRef}
                    onChange={(cluster) => handleChangeCluster(cluster)}
                    placeholder='Cluster' 
                    options={[
                      ...clustersByGroup.filter((clusterByGroup) => clusterByGroup.numberOfCompanies !== 0).map((cluster) => ({ label: cluster.name + ' (' + cluster.qtDays + ' dias)' , title: cluster.name, value: cluster.id })),
                      { label: 'Todos', title: 'todos', value: 0 }
                    ]}
                    fitContent
                  />
                </FormItemStyles>
              </Form>
              
            </Row>
            <Row align='center' gap='16px'>
              <YearText>{activeYear}</YearText>
              <Row align='center' gap='12px'>
                <Button.Group>
                  <YearButton
                    type='secondary' 
                    size='smaller'
                    context='list'
                    icon={<FeatherIcon type='chevron-left'/>} 
                    title='Voltar um ano'
                    onClick={() => handleChangeYear(activeYear - 1)} 
                  />
                  <YearButton
                    type='secondary' 
                    size='smaller'
                    context='list'
                    icon={<FeatherIcon type='chevron-right'/>} 
                    title='Passar um ano'
                    onClick={() => handleChangeYear(activeYear + 1)} 
                    disabled={parseInt(getCurrentYear()) === activeYear}
                  />
                </Button.Group>
              </Row>
            </Row>
    
          </Header>
          
          {successYearSummary && !isAllValuesZero ? (
            <Panel>
              {yearSummary?.map((item, index) => (
                <Column key={item.id} onClick={() => handleActiveMonth(index)} >
                  <ChartProgress data={item} trueMonth={index + 1} active={activeMonth === index}/>
                </Column>
              ))}
            </Panel>
          ) : (
            <StyleEmpty description='Sem dados'/>
          )} 
          
        </Column>
      ) }
      
    </>
  );
};

export default PanelDashboard;