import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, ContentTitle, Upload, Button, StatusTag, Skeleton, Stepper, Grid, Row, Image, PopConfirm, Modal } from '@components-teammove';
import { UploadsContainer, AddUploadCard, PlusIconWrapper, FeatherIcon, SmallText, CreatedBy, CompanyInfoCell, SimpleText, ImportantText, InfoCell, RegularText, ProductInfoCell, ProductPictureWrapper, TotalCell, UploadCard, UploadImage, IconWrapper, NoImage } from './styles'; 
import { InfoUsuario, download, formatDate, formatTime, formatCurrency, Notification, isImage, getTypeByFileExtension, getIconByType , TelasNomenclaturas, formatCnpj } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getDetailedOrder , postUploads , deleteUpload, putStatus } from '@ducks/pedidos';
import { getStatusColor } from '../rules';
import { getSteps } from './rules';
import { authorization } from '@sdk/api';

const { REACT_APP_API } = window.env;

export default function Visualization() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const detailedOrder = useSelector(({ pedidos }) => pedidos.get('detailedOrder'));
  const loadingDetailedOrder = useSelector(({ pedidos }) => pedidos.get('loadingDetailedOrder'));
  const successDetailedOrder = useSelector(({ pedidos }) => pedidos.get('successDetailedOrder'));
  const loadingUploadDelete = useSelector(({ pedidos }) => pedidos.get('loadingUploadDelete'));
  const successUploadDelete = useSelector(({ pedidos }) => pedidos.get('successUploadDelete'));
  const successUploadsSave = useSelector(({ pedidos }) => pedidos.get('successUploadsSave'));
  const loadingStatusSave = useSelector(({ pedidos }) => pedidos.get('loadingStatusSave'));
  const successStatusSave = useSelector(({ pedidos }) => pedidos.get('successStatusSave'));

  const [uploads, setUploads] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [visiblePictures, setVisiblePictures] = useState(false);
  const [currentPicture, setCurrentPicture] = useState('');
  const [deletedUpload, setDeletedUpload] = useState();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    document.title = 'TeamMove';
    dispatch(getDetailedOrder(parseInt(id)));
  }, [successStatusSave]);

  useEffect(() => {
    if (successDetailedOrder || successUploadDelete || successUploadsSave) {
      setUploads(detailedOrder.uploads?.map(({ name, url, id }) => ({ name, url, uid: id })) || []);
    }
  }, [successDetailedOrder, successUploadDelete, successUploadsSave]);

  const handleImageClick = (index) => {
    setCurrentPicture(index);
    setVisiblePictures(true);
  };

  const handleUploadsChange = ({ file, fileList }) => {
    setUploads(fileList);

    switch (file.status) {
      case 'uploading': {
        setUploading(true);
        break;
      }
      case 'error': {
        setUploading(false);
        Notification.error('Erro ao fazer upload. ' + file.error);
        break;
      }
      case 'done': {   
        setUploading(false);
        Notification.success('Anexo salvo com sucesso');
        setUploads([...fileList]);
        dispatch(postUploads(parseInt(id), fileList.filter(({ response }) => response).map(({ response, url, name }) => ({ url: url || response, name, orderId: parseInt(id) }))));
        break;
      }
      default: break;
    }
  };

  const handleUploadDelete = (uploadId) => {
    setDeletedUpload(uploadId);
    dispatch(deleteUpload(uploadId));
  };

  const handleOrderCancel = () => {
    dispatch(putStatus({ orderId: parseInt(id), newStatus: 'CANCELADO' }));
  };

  const handlePrint = () => {
    document.title = (detailedOrder.id + '_' + detailedOrder.companyShortName + '_' + detailedOrder.companyName).split(/\s+/).join('');
    history.push(`/pedidos/${id}/detalhes/print`, { detailedOrder });
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Pedido #' + id}
          subTitle={loadingDetailedOrder ? (
            <Skeleton.MediumLine/>
          ) : successDetailedOrder && (
            <StatusTag 
              color={getStatusColor(detailedOrder.status)} 
              text={(detailedOrder.status?.charAt(0).toUpperCase() + detailedOrder.status?.slice(1).toLowerCase()).replace('_', ' ')} 
              size='large'
              fixedWidth='150px'
            />
          )}
          darkenedSubTitle={false}
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/pedidos', name: 'Pedidos' },
                { path: `/pedidos/${id}/detalhes`, name: 'Pedido #' + id },
              ]}
            />
          )}
          extra={[
            <Button key='print' size='small' context='header' type='secondary' onClick={handlePrint}>Imprimir</Button>,
            <Button visible={InfoUsuario.perm('pedidosEditar') && detailedOrder.status !== 'CANCELADO'} key='print' size='small' context='header' type='secondary' loading={loadingStatusSave} onClick={handleOrderCancel}>Cancelar pedido</Button>,
            <Button visible={InfoUsuario.perm('pedidosEditar') && (detailedOrder.status === 'ABERTO' || detailedOrder.status === 'EM_ANDAMENTO') && detailedOrder.status !== 'ENTREGUE'} key='edit' size='small' context='header' type='primary' onClick={() => history.push('/pedidos/' + id)}>Editar pedido</Button>,
          ]}
        >
          <Column gap='20px'>
            {loadingDetailedOrder ? (
              <Skeleton.MediumLine/>
            ) : successDetailedOrder && (
              <>
                <Column gap='8px'>
                  <SmallText>Criado por</SmallText>
                  <CreatedBy>{detailedOrder.userName} em {formatDate(detailedOrder.createdAt)} às {formatTime(detailedOrder.createdAt)}</CreatedBy>
                </Column>

                <Stepper 
                  steps={getSteps(detailedOrder)}
                />

                <Grid templateColumns='.7fr .3fr' gap='12px'>
                  <Column gap='8px'>
                    <SmallText>
                      {companyNomenclature.nomenclatura}
                    </SmallText>
                    <CompanyInfoCell>
                      <Column gap='2px'>
                        <SimpleText>{detailedOrder.companyShortName}</SimpleText>
                        <ImportantText>{detailedOrder.companyName}</ImportantText>
                        <SimpleText>{formatCnpj(detailedOrder.companyCnpj)}</SimpleText>
                        <SimpleText>{detailedOrder.companyCity} - {detailedOrder.companyState}</SimpleText>
                      </Column>
                    </CompanyInfoCell>
                  </Column>

                  <Column gap='8px'>
                    <SmallText>
                      Prazo
                    </SmallText>
                    <InfoCell align='center'>
                      <RegularText>
                        {formatDate(detailedOrder.dueDate)}
                      </RegularText>
                    </InfoCell>
                  </Column>
                </Grid>

                <Grid templateColumns='.5fr .2fr .1fr .2fr' gap='12px'>
                  <SmallText>Produtos</SmallText>
                  <SmallText>Preço unitário</SmallText>
                  <SmallText>Quantidade</SmallText>
                  <SmallText>Total do ítem</SmallText>
                  {detailedOrder.items.map((item, index) => (
                    <>
                      <ProductInfoCell align='center' hasImage={!!item.picture}>
                        <Row gap='16px'>
                          {item.picture && (
                            <ProductPictureWrapper>
                              <Image src={item.picture}/>
                            </ProductPictureWrapper>
                          )}
                          <RegularText>{item.productName}</RegularText>
                        </Row>
                      </ProductInfoCell>
                      <InfoCell align='center' justify='flex-end'>
                        <RegularText>{formatCurrency(item.productPrice)}</RegularText>
                      </InfoCell>
                      <InfoCell align='center' justify='center'>
                        <RegularText>{item.quantity}</RegularText>
                      </InfoCell>
                      <InfoCell align='center' justify='flex-end'>
                        <RegularText>{formatCurrency(item.productPrice * item.quantity)}</RegularText>
                      </InfoCell>
                    </>
                  ))}
                  <div/>
                  <Column gap='8px'>
                    <SmallText>Peso total</SmallText>
                    <TotalCell justify='flex-end'>
                      <ImportantText>{(detailedOrder.totalWeight.toFixed(2)).replace('.', ',')} Kg</ImportantText>
                    </TotalCell>
                  </Column>
                  <Column gap='8px'>
                    <SmallText>Qnt. total</SmallText>
                    <TotalCell justify='center'>
                      <ImportantText>{detailedOrder.totalQuantity}</ImportantText>
                    </TotalCell>
                  </Column>
                  <Column gap='8px'>
                    <SmallText>Total pedido</SmallText>
                    <TotalCell justify='flex-end'>
                      <ImportantText>{formatCurrency(detailedOrder.totalPrice)}</ImportantText>
                    </TotalCell>
                  </Column>
                </Grid>

                <Column>
                  <ContentTitle>Anexos</ContentTitle>
                  <UploadsContainer>
                    {InfoUsuario.perm('pedidosEditar') && (
                      <AddUploadCard>
                        <PlusIconWrapper>
                          <FeatherIcon type='fi-plus' size='1.75rem'/>
                        </PlusIconWrapper>
                        <Upload
                          fileList={uploads}
                          headers={authorization()}
                          URI={REACT_APP_API}
                          onChange={handleUploadsChange}
                          folder={`Pedidos/${InfoUsuario.get('cdCliente')}`}
                          onDownload={(upload) => download(upload.response)}
                        >
                          <Button type='primary' size='small' context='list' loading={uploading}>{uploading ? 'Adicionando' : 'Adicionar arquivo'}</Button>
                        </Upload>
                      </AddUploadCard>
                    )}
                    {detailedOrder.uploads?.map(({ url, name, id }, index) => (
                      <UploadCard key={id}>
                        <PopConfirm
                          title='Deseja remover este produto?'
                          onConfirm={() => handleUploadDelete(id)}
                        >
                          <Button.Delete fixed placement='top-right' loading={loadingUploadDelete && id === deletedUpload}/>
                        </PopConfirm>
                        <Column gap='8px'>
                          {isImage(url) ? (
                            <UploadImage src={url} onClick={() => handleImageClick(index)}/>
                          ) : (
                            <a href={url} target='_blank' rel="noopener noreferrer">
                              <NoImage>
                                <IconWrapper>
                                  {getIconByType(getTypeByFileExtension(url.substring(url.lastIndexOf('.') + 1)), '1.75rem')}
                                </IconWrapper>
                              </NoImage>
                            </a>
                          )}
                          <SmallText>{name}</SmallText>
                        </Column>
                      </UploadCard>
                    ))}
                  </UploadsContainer>
                </Column>
              </>
            )}
          </Column>

          {visiblePictures && (
            <Modal.Picture
              onCancel={() => setVisiblePictures(false)}
              pictures={detailedOrder.uploads.map(({ url }) => url)}
              initialImageIndex={currentPicture}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}