import styled from 'styled-components';

import { Row, Column, Text } from '@components-teammove';

export const Line = styled.div`
  display: flex;
  gap: 4px;
  margin: 12px 0 40px;
`;

export const Division = styled.div`
  border-radius: 8px;
  background-color: ${({ status }) => status ? `var(--${status}-color)` : 'var(--undefined-color)'};
  width: ${({ percentage }) => percentage + '%'};
  height: 14px;
`;

export const SimpleLine = styled(Row)`

`;

export const SimpleDivision = styled.div`
  display: flex;
  background-color: ${({ status, theme, useBgColor, colorTheme }) => status ? `var(--${status}-color)` : useBgColor ? `${theme.backgroundColor}` : colorTheme ? `${colorTheme}` : 'var(--undefined-color)'};
  width: ${({ percentage }) => percentage + '%'};
  height: 9px;

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const MainLabel = styled(Text)`
  &.ant-typography {
    font-size: 32px;
    font-weight: 400;
  }
`;

export const MainIconWrapper = styled(MainLabel)`
  
`;

export const MainPercentage = styled(MainLabel)`
  font-weight: 700;
`;

export const MainRow = styled(Row)`
  gap: 4px;

  * {
    color: ${({ status }) => status ? `var(--${status}-color)` : 'var(--undefined-color)'};
  }

  ${MainLabel}, ${MainPercentage} {
    &.ant-typography {
      color: ${({ status }) => status ? `var(--${status}-color)` : 'var(--undefined-color)'};
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
`;

export const Label = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
  }
`;

export const SimpleLabel = styled(Label)`
  &.ant-typography {
    font-size: 14px;
  }
`;

export const IconWrapper = styled.div`
  font-size: 22px;
  display: flex;
  align-items: center;
  * {
    color: ${({ status }) => status ? `var(--${status}-color)` : 'var(--undefined-color)'};
  }
`;

export const Percentage = styled(Label)`
  &.ant-typography {
    font-weight: 700;
    opacity: 1;
  }
`;

export const SimplePercentage = styled(SimpleLabel)`
  &.ant-typography {
    font-weight: 700;
    opacity: 1;
  }
`;

export const StatusIndicator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ status }) => 'var(--' + status + '-color)'};
`;

export const ColumnStyle = styled(Column)`
  width: 100%;
`;