import React from 'react';
import { Modal, Column, Icon } from '@components-teammove';
import { formatTelephone, formatNineDigitsTelephone } from '@utils';
import { ButtonText, CallButton, ContainerGrid, ContainerItem, EmptyDiv, LabelText, NumberText, TagLabel, TitleText } from './styles';

const ForWhoCalling = ({ visible, onCancel, handlePrepareToCall, infoForCallingModal }) => {

  const handleToCall = (numberToCall) => {
    const contactSelected = infoForCallingModal.contacts.find((contact) => contact.telefone === numberToCall);
    const { companyName, companyId } = infoForCallingModal;

    const callName = contactSelected ? contactSelected.nome : companyName;
    const contactCallId = contactSelected?.id;
    const companyCallId = companyId;

    onCancel();
    handlePrepareToCall(numberToCall, callName, companyCallId, contactCallId, !!contactSelected);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.length === 10 ? formatTelephone(phoneNumber) : formatNineDigitsTelephone(phoneNumber);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      cancelText='Fechar'
      title='Para quem deseja ligar?'
      okText='Abrir discador'
      onOk={handleToCall}
    >
      <Column margin='20px 0'>
        <ContainerItem lastItem={infoForCallingModal.contacts.length === 0}>
          <Column>
            <TitleText>{infoForCallingModal.identifier}</TitleText>
            <NumberText>{formatPhoneNumber(infoForCallingModal.telephoneUnity)}</NumberText>
          </Column>
          <CallButton onClick={() => handleToCall(infoForCallingModal.telephoneUnity)}>
            <Icon.Feather type='phone' size={16} color='white' />
            <ButtonText>Ligar</ButtonText>
          </CallButton>
        </ContainerItem>
        {infoForCallingModal.contacts.map((contact, index) => (
          <ContainerItem key={contact.nome} lastItem={index === infoForCallingModal.contacts.length - 1}>
            <Column width='30%'>
              <TitleText>{contact.nome}</TitleText>
              <NumberText>{formatPhoneNumber(contact.telefone)}</NumberText>
            </Column>
            <ContainerGrid flexStart>
              <TitleText opacity>{contact.cargo}</TitleText>
            </ContainerGrid>
            {contact.contactClassification ? (
              <ContainerGrid flexEnd>
                <TagLabel tagCor={contact.contactClassification.cor}>
                  <LabelText>{contact.contactClassification.descricao}</LabelText>
                </TagLabel>
              </ContainerGrid>
            ) : (
              <EmptyDiv/>
            )}
            <ContainerGrid callButton flexEnd>
              <CallButton onClick={() => handleToCall(contact.telefone)}>
                <Icon.Feather type='phone' size={16} color='white' />
                <ButtonText>Ligar</ButtonText>
              </CallButton>
            </ContainerGrid>
          </ContainerItem>
        ))}
      </Column>
    </Modal>
  );
};

export default ForWhoCalling;