const set = (key, filtros) => {
  return sessionStorage.setItem(`FILTROS/${key}`, JSON.stringify(filtros));
};

const get = (key) => {
  try {
    const filtros = sessionStorage.getItem(`FILTROS/${key}`);
    return {
      ...JSON.parse(filtros),
    };
  } catch (err) {
    return {};
  }
};

const remove = (key) => {
  return sessionStorage.removeItem(`FILTROS/${key}`);
};

export default {
  set,
  get,
  remove,
};
