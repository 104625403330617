export const initialFilters = [
  {
    type: 'RANGE',
    label: 'Selecione um período',
    tagLabel: 'Período',
    name: 'data',
    required: true
  },
  {
    type: 'SELECT',
    label: 'Usuários',
    placeholder: 'Seleciona os usuários',
    name: 'usuario',
    options: []
  }
];