import React from 'react';
import { Back, DarkPageHeader, Extra, GapView, PageSubtitle, PageTitle, Row, SpaceBetweenRow } from './styles';
import { View } from '@components-teammove';

const PageHeader = ({ title, subHeader, breadcrumb, onBack, subTitle, extra, darkenedSubTitle = true, marginlessHeader = true, children, ...props }) => (
  <DarkPageHeader 
    title={(
      <GapView>
        <View>
          {breadcrumb}
          <SpaceBetweenRow>
            <PageTitle>
              <Row gap='6px' align='center'>
                {onBack && (
                  <Back type='arrow-left-short' onClick={() => onBack()}/>
                )}
                {title}
              </Row>
              {subTitle && (
                <PageSubtitle darkenedSubTitle={darkenedSubTitle}>{subTitle}</PageSubtitle>
              )}
            </PageTitle>
            {extra && <Extra>{extra.isArray ? extra.map((extra) => extra) : extra}</Extra>}
          </SpaceBetweenRow>
        </View>
          
        {subHeader && subHeader}
      </GapView>
    )}
    marginlessHeader={marginlessHeader}
    {...props}
  >
    {children}
  </DarkPageHeader>
);

export default PageHeader;