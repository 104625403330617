import { View, Title, Text } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';

export const GapView = styled(View)`
  gap: 12px;
`;

export const SpaceBetweenColumn = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

export const Go = styled(Icon.Bootstrap)`
  align-self: center;
  position: relative;
  right: 33px;
  transform: scale(2);
`;

export const Total = styled(Title)`
  &.ant-typography {
    font-size: 40px;
    font-weight: 400;
    color: #F5F5F5;
    margin-bottom: 0;
  }
`;

export const TotalClosed = styled(Text)`
  &.ant-typography {
    font-weight: 400;
    color: #F5F5F5;
  }
`;

export const StatusCard = styled(View)`
  flex: 1;
  flex-direction: row;
  gap: 12px;
  background-color: ${({ color }) => color};
  color: #F5F5F5;
  font-size: 20px;
  font-weight: 700;
  border-radius: 18px;
  padding: 14px 16px;
  transition: transform .5s;
  cursor: pointer;

  :first-child:last-child {
    font-size: 40px;
    font-weight: 600;

    ${Total} {
      font-size: 64px;
      font-weight: 400;
    }

    ${Go} {
      align-self: flex-end;
      bottom: 33px;
    }
  }

  transform-origin: center;
  :hover {
    transform: scaleY(1.0725);
  }
`;

export const Column = styled(View)`
  flex: 1;
  gap: 12px;
`;

export const Container = styled(View)`
  flex-direction: row;
  gap: 12px;
  ${({ fixHeight }) => fixHeight && `
    min-height: 225px;
  `}

  ${({ loading, noFlex }) => loading && `
    ${StatusCard} {
      height: 116px;
      ${noFlex ? `
        flex: none;
      ` : ''}
      padding: 0;
      pointer-events: none;

      :first-child:last-child {
        height: 100%;
      }
    }
  `}
  
  ${StatusCard} {
    :first-child {
      transform-origin: left;
    }
    :last-child {
      transform-origin: right;
    }
    :hover {
      transform: scale(1.0125);
    }
  }

  ${Column} {
    ${StatusCard} {
      transform-origin: center;
      :hover {
        transform: scale(1.0125);
      }
    }
    :first-child {
      ${StatusCard} {
        transform-origin: left;
        :hover {
          transform: scale(1.0125);
        }
      }
    }
    :first-child:last-child {
      ${StatusCard} {
        transform-origin: center;
        :hover {
          transform: scaleY(1.0125);
        }
      }
    }
    :last-child {
      ${StatusCard} {
        transform-origin: right;
        :hover {
          transform: scale(1.0125);
        }
      }
    }
  }

  ${StatusCard} {
    padding: 12px 16px;
  }
`;

export const TitleCards = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
  }
  font-size: 20px;
  font-weight: 700;
  padding-left: 20px;
`;