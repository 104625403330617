import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Column, Text, Divider, ChartPie, Skeleton } from '@components-teammove';
import { Container, SelectStyle, StyleEmpty } from './styles';

import { getClassificationInformationDash } from '@ducks/chamadosDashboard';

export default function ClassificationsData({ currentFilters, currentParams }) {
  const dispatch = useDispatch();

  const classificationInformationDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('classificationInformationDash'));
  const loadingClassificationInformationDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingClassificationInformationDash'));
  const successClassificationInformationDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successClassificationInformationDash'));

  const [selectedClassification, setSelectedClassification] = useState();

  const totalOptionsQuantities = useMemo(() => {
    if (selectedClassification) {
      return selectedClassification.options.reduce((total, option) => total + option.quantity, 0);
    }
  }, [selectedClassification]);

  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getClassificationInformationDash({ ...currentFilters, ...currentParams }));
    }
  }, [currentFilters, currentParams]);

  useEffect(() => {
    if (successClassificationInformationDash && classificationInformationDash?.length > 0) {
      setSelectedClassification(classificationInformationDash[0]);
    }
  }, [successClassificationInformationDash]);

  const changeSelectedClassification = (classificationId) => {
    if (classificationId) {
      const foundClassification = classificationInformationDash.find((classificationDash) => classificationDash.id === classificationId);

      setSelectedClassification(foundClassification);
    }
  };
 
  return(
    loadingClassificationInformationDash ? (
      <Skeleton.Map/>
    ) : successClassificationInformationDash && (
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Dados de</Text>
            <Text size='20px' weight='800' line='20px'>Classificações</Text>
          </Column>
          {classificationInformationDash.length > 0 && (
            <SelectStyle
              value={selectedClassification?.id}
              onChange={(classification) => changeSelectedClassification(classification)}
              placeholder='Selecione a classificação' 
              options={classificationInformationDash.map((classification) => ({ value: classification.id, label: classification.name }))}
              fitContent
            />
          )}
        </Row>
        <Divider/>
        {classificationInformationDash.length < 1 || !selectedClassification ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <Row justify='space-between' align='center'>
              <ChartPie data={selectedClassification?.options.map(({ name, color, quantity }) => ({ label: name, color, quantity }))}/>
              <Column gap='10px' padding='0 30px 0 0 '>
                {selectedClassification?.options?.map((option) => (
                  <Row key={option.id} gap='29px' justify='space-between'>
                    <Text size='20px' weight='700'>{option.quantity}</Text>
                    <Text size='20px' weight='400' lowOpacity>{((option.quantity / totalOptionsQuantities) * 100).toFixed()}%</Text>
                  </Row>
                ))}
              </Column>
            </Row>
          )}
      
      </Container>
    )
  );
}