import React, { useEffect , useState } from 'react';
import { Form, Input, Modal, Button, InputNumber, Select, Switch, Divider, Spin } from '@components';
import { Icon , ColorPicker } from '@components-teammove';

import { getAtributosSelect } from '@sdk/Usuarios';
import { authorization } from '@sdk/api'; 
import { getListaProdutosSelect, getListaProdutosItemSelect } from '@sdk/Wikis/Personalizacao';
import { initialValues, TYPE_PERSONALIZATION } from './Types';
import { SliderInput } from './CustomComponents/slider';
import { ImageContainer, UploadStyled, UploadInput, UploadView } from './styles';
import { InfoUsuario } from '@utils';

const { REACT_APP_API } = window.env;

export default function ModalCad({ visible, onCancel, textoEdicao, conteudo }) {
  const [form] = Form.useForm();
  const [fieldType, setFieldType] = useState(TYPE_PERSONALIZATION.SIMPLES);
  const [idListProduct, setIdListProduct] = useState(null);
  const [listProduct, setListProduct] = useState([]);
  const [itemsListProduct, setItemsListProduct] = useState([]);
  const [imageType, setImageType] = useState(false);
  const [uploadListLength, setUploadListLength] = useState(0);
  const [uploadListUrl, setUploadListUrl] = useState('');
  const [loadingUrl, setLoadingUrl] = useState(false);

  const tipo = conteudo.get('tipo');
  const usuario = InfoUsuario.get();
  
  useEffect(() => {
    if ((visible) && (textoEdicao)) {
      form.resetFields();
      form.setFieldsValue(textoEdicao);
      setFieldType(textoEdicao.type);
      setImageType(textoEdicao.hasImage);
      setUploadListUrl(textoEdicao.imageUrl);
      setIdListProduct(textoEdicao.productList);
      if (listProduct.length === 0) {
        handleSearchListProduct();
      }
    }
  }, [visible]);
  
  useEffect(() => {
    const type = form.getFieldValue('type');
    switch (type) {
      case TYPE_PERSONALIZATION.LISTA_PRODUTO:
        if (listProduct.length === 1) {
          handleChangeListProduct(listProduct[0].key);
        }
        break;

      default: setIdListProduct(null);
        break;
    }
  }, [fieldType]);

  useEffect(() => {
    setItemsListProduct([]);

    if (idListProduct) {
      handleSearchItemsListProduct(idListProduct);
    }

  }, [idListProduct]);

  const handleSearchItemsListProduct = (idListProduct) => {
    getListaProdutosItemSelect(idListProduct)
      .then((res) => {
        setItemsListProduct(res);
      });
  };

  const handleSearchListProduct = () => {
    getListaProdutosSelect()
      .then((res) => {
        setListProduct(res);
      });
  };

  const handleChangeListProduct = (idListProduct) => {
    form.setFieldsValue({ ...form.getFieldsValue(), productList: idListProduct, productItemList: null, productItemListFormat: null });
    setIdListProduct(idListProduct);
  };
  
  const handleChange = (info) => {
    if(info.file.status === 'uploading') {
      setLoadingUrl(true);
    } else if (info.file.status === 'done') {
      setLoadingUrl(false);
    }
    setUploadListUrl(info.file.response);
    setUploadListLength(info.fileList.length);

    let img = new Image();
    img.src = info.file.response;
    img.onload = () => form.setFieldsValue({
      imageWidth: img.width,
      imageHeight: img.height
    });

    form.setFieldsValue({
      imageUrl: info.file.response
    });
  };

  const handleChangeInput = (e) => {
    if (e.target.value === '') {
      setUploadListLength(0);
      setUploadListUrl('');
      form.setFieldsValue({
        imageUrl: ''
      });
    }
  };
  const onOk = () => {
    const type = form.getFieldValue('type');
    setUploadListLength(0);
    switch (type) {
      case TYPE_PERSONALIZATION.SIMPLES:
      case TYPE_PERSONALIZATION.ATRIBUTO_USUARIO:
        form.setFieldsValue({ ...form.getFieldsValue(), productList: null, productItemList: null, productItemListFormat: null });
        break;
      default:
        break;
    }
    form.submit();
  };
  return (
    <Modal forceRender visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical" name="textoForm" initialValues={initialValues}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="label" label="Label do campo" rules={[{ required: true, message: 'Informe o label do campo' }]}>
          <Input placeholder="Label do campo" />
        </Form.Item>

        <Form.Item name="texto" label="Valor padrão">
          <Input placeholder="Valor padrão" />
        </Form.Item>

        <Form.Item name="type" label="Tipo de Campo">
          <Select showSearch={false} onSelect={(e) => setFieldType(e)}>
            <Select.Option value={TYPE_PERSONALIZATION.SIMPLES}>Simples (Digitação Manual)</Select.Option>
            <Select.Option value={TYPE_PERSONALIZATION.ATRIBUTO_USUARIO}>Atributos de Usuário</Select.Option>
            <Select.Option value={TYPE_PERSONALIZATION.LISTA_PRODUTO}>Lista de Produtos</Select.Option>
          </Select>
        </Form.Item>

        {fieldType === TYPE_PERSONALIZATION.ATRIBUTO_USUARIO && 
          <Form.Item name="atributo" label="Atributo" extra="Irá preencher com o valor informado no cadastro do usuário">
            <Select action={() => getAtributosSelect()} placeholder="Informe o atributo" />
          </Form.Item>
        }
        {fieldType === TYPE_PERSONALIZATION.LISTA_PRODUTO && 
          <Form.Item name="productList" label="Lista de Produtos" extra="Selecione a lista de produtos a ser utilizada" rules={[{ required: true, message: 'Informe a lista a ser utilizada' }]}>
            <Select value={idListProduct} placeholder="Selecione a Lista de Produtos" onSelect={(e) => handleChangeListProduct(e)}>
              {listProduct.map((item) => (
                <Select.Option key={item.key} value={item.value}>{item.title}</Select.Option>
              ))}
            </Select>  
          </Form.Item>
        }
        
        {itemsListProduct?.length > 0 &&
            <>
              
              <Form.Item name="productItemList" label="Item Lista de Produtos" extra="Selecione o item da lista de produtos" rules={[{ required: true, message: 'Informe o item a ser utilizado' }]}>
                <Select optionFilterProp="label">
                  {itemsListProduct.map((item) => (
                    <Select.Option key={item.key} value={item.value} label={item.title}>{item.title}</Select.Option>
                  ))}
                </Select>

              </Form.Item>
              <Form.Item name="productItemListFormat" label="Escolha o tipo de valor" rules={[{ required: true, message: 'Informe o tipo de formatação' }]}>
                <Select>
                  <Select.Option value="FI">Inteiro</Select.Option>
                  <Select.Option value="FR">Real</Select.Option>
                  <Select.Option value="FC">Centavos</Select.Option>
                </Select>
              </Form.Item>
              {(tipo === 'PDF' || tipo === 'IMAGEM') &&
              <>
                <Form.Item name="hasImage" valuePropName="checked" label="Definir preço especial">
                  <Switch onChange={(e) => setImageType(e)}/>
                </Form.Item>
                {imageType &&
                <ImageContainer>
                  <Form.Item name="imageUrl" label="Upload da imagem">
                    <UploadView>
                      <UploadStyled
                        headers={authorization()}
                        URI={REACT_APP_API}
                        multiple={false}
                        folder={`/Wiki/${usuario.cdCliente}`}
                        showUploadList={false}
                        onChange={handleChange}
                      >
                        <Button disabled={uploadListLength > 0}><Icon.UploadOutlined />Upload</Button>
                      </UploadStyled>
                      { loadingUrl &&
                          <Spin/>
                      }
                      {
                        !loadingUrl &&
                        <UploadInput allowClear value={uploadListUrl} onChange={(e) => handleChangeInput(e)}/>
                      }
                    </UploadView>
                  </Form.Item>
                  <Form.Item name="imageWidth" label="Largura da imagem (px)">
                    <InputNumber min={1}/>
                  </Form.Item>

                  <Form.Item name="imageHeight" label="Altura da imagem (px)">
                    <InputNumber min={1}/>
                  </Form.Item>

                  <Form.Item shouldUpdate={(prev, current) => prev.xImage !== current.xImage} label="Posição X da Imagem" extra="% relativo a largura do arquivo">
                    {({ getFieldValue, setFieldsValue }) => (
                      <SliderInput value={getFieldValue('xImage') || 0} onChange={(value) => (!isNaN(value)) ? setFieldsValue({ xImage: value }) : false} />
                    )}
                  </Form.Item>
                  <Form.Item shouldUpdate={(prev, current) => prev.yImage !== current.yImage} label="Posição Y da Imagem" extra="% relativo a altura do arquivo">
                    {({ getFieldValue, setFieldsValue }) => (
                      <SliderInput value={getFieldValue('yImage') || 0} onChange={(value) => (!isNaN(value)) ? setFieldsValue({ yImage: value }) : false} />
                    )}
                  </Form.Item>
                </ImageContainer>
                }
                <Divider/>
              </>}
            </>
        }
               
        <Form.Item shouldUpdate={(prev, current) => prev.posX !== current.posX} label="Posição X" extra="% relativo a largura do arquivo">
          {({ getFieldValue, setFieldsValue }) => (
            <SliderInput value={getFieldValue('posX') || 0} onChange={(value) => (!isNaN(value)) ? setFieldsValue({ posX: value }) : false} />
          )}
        </Form.Item>

        <Form.Item shouldUpdate={(prev, current) => prev.posY !== current.posY} label="Posição Y" extra="% relativo a altura do arquivo">
          {({ getFieldValue, setFieldsValue }) => (
            <SliderInput value={getFieldValue('posY') || 0} onChange={(value) => (!isNaN(value)) ? setFieldsValue({ posY: value }) : false} />
          )}
        </Form.Item>

        <Form.Item name="fontSize" label="Tamanho da fonte">
          <InputNumber placeholder="70" />
        </Form.Item>

        <Form.Item name="color" label="Cor da fonte" valuePropName="color" getValueFromEvent={(e) => e.hex}>
          <ColorPicker.CompactPicker />
        </Form.Item>

        <Form.Item name="font" label="Fonte">
          <Select>
            <Select.Option value="Aller Std Rg">Aller</Select.Option>
            <Select.Option value="Aller Std Bd">Aller Bold</Select.Option>
            <Select.Option value="Aller Std BdIt">Aller Bold Italic</Select.Option>
            <Select.Option value="Aller Std It">Aller Italic</Select.Option>
            <Select.Option value="Aller Std Lt">Aller Light</Select.Option>
            <Select.Option value="Aller Std LtIt">Aller Light Italic</Select.Option>
            <Select.Option value="Arial">Arial</Select.Option>
            <Select.Option value="Arial Black">Arial Black</Select.Option>
            <Select.Option value="Calibri">Calibri</Select.Option>
            <Select.Option value="Calibrib">Calibri Bold</Select.Option>
            <Select.Option value="Calibrib_1">Calibri Bold 1</Select.Option>
            <Select.Option value="Calibriz">Calibri Bold Italic</Select.Option>
            <Select.Option value="Calibrii">Calibri Italic</Select.Option>
            <Select.Option value="Calibril">Calibri Light</Select.Option>
            <Select.Option value="Calibrili">Calibri Light Italic</Select.Option>
            <Select.Option value="Cocogoose trial">Cocogoose</Select.Option>
            <Select.Option value="Cocogoose Pro Semilight trial">Cocogoose Bold</Select.Option>
            <Select.Option value="Comic Sans MS">Comic Sans MS</Select.Option>
            <Select.Option value="Courier New">Courier New</Select.Option>
            <Select.Option value="Georgia">Georgia</Select.Option>
            <Select.Option value="Gineso">Gineso</Select.Option>
            <Select.Option value="Gineso Norm Bold">Gineso Norm Bold</Select.Option>
            <Select.Option value="Impact">Impact</Select.Option>
            <Select.Option value="Tahoma">Tahoma</Select.Option>
            <Select.Option value="Times New Roman">Times New Roman</Select.Option>
            <Select.Option value="Omnes Bold">Omnes Bold </Select.Option>
            <Select.Option value="Trebuchet MS">Trebuchet MS</Select.Option>
            <Select.Option value="Verdana">Verdana</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="prefix" label="Prefixo do texto">
          <Input />
        </Form.Item>

        <Form.Item name="suffix" label="Sufixo do texto">
          <Input />
        </Form.Item>

        <Form.Item name="format" label="Formatar texto">
          <Select>
            <Select.Option value="lower">minúsculas</Select.Option>
            <Select.Option value="upper">MAIÚSCULAS</Select.Option>
            <Select.Option value="first">Primeira letra da frase em maiúscula</Select.Option>
            <Select.Option value="each">Colocar Cada Palavra em Maiúscula</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Visível" name="visible" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label="Editável" name="editable" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label="Obrigatório" name="required" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item name="alinhamento" label="Alinhamento">
          <Select>
            <Select.Option value="start">Esquerda</Select.Option>
            <Select.Option value="middle">Centralizado</Select.Option>
            <Select.Option value="end">Direita</Select.Option>
          </Select>
        </Form.Item>
        { tipo === 'PDF' &&
          <Form.Item name="page" label="Página do PDF" extra="Somente para personalização do tipo PDF" rules={[{ required: true, message: 'Informe a página do PDF' }]}>
            <InputNumber placeholder="0" min={0} />
          </Form.Item>
        }
      </Form>
    </Modal>
  );
}
