import React, { useState } from 'react';
import { Column, Skeleton } from '@components-teammove';
import { ArrowIcon, ParentCard, ParentCardHeader, ParentCardSkeletonWrapper } from './styles';

const FixedCascader = ({ options, loading, disabled, ...props }) => {
  const [openParents, setOpenParents] = useState(options.map((option) => option.id));
  const [openSubParents, setOpenSubParents] = useState([]);

  const handleToogleParent = (parentId) => {
    if (openParents.includes(parentId)) {
      setOpenParents(openParents.filter((id) => id !== parentId));
    } else {
      setOpenParents([...openParents, parentId]);
    }
  };

  const handleToogleSubParent = (subParentId) => {
    if (openSubParents.includes(subParentId)) {
      setOpenSubParents(openSubParents.filter((id) => id !== subParentId));
    } else {
      setOpenSubParents([...openSubParents, subParentId]);
    }
  };

  const buttonClick = (child) => {
    if (disabled) {
      return;
    }
    props.onChange(
      props.value.includes(child.id)
        ? props.value.filter((val) => val !== child.id)
        : [...props.value, child.id]
    );
  };

  return (
    <Column gap='24px'>
      {!loading ? options.map((option) => (
        <Column gap='12px' key={option.id}>
          <ParentCard onClick={() => handleToogleParent(option.id)}>
            <ParentCardHeader onClick={() => handleToogleParent(option.id)} >
              {option.name}
              <ArrowIcon type={`chevron-${openParents.includes(option.id) ? 'up' : 'down'}`} />
            </ParentCardHeader>
          </ParentCard>

          {openParents.includes(option.id) && option.children.map((subParent) => (
            <ParentCard subParent key={subParent.id} onClick={() => handleToogleSubParent(subParent.id)}>
              <ParentCardHeader onClick={() => handleToogleSubParent(subParent.id)}>
                {subParent.name}
                <ArrowIcon type={`chevron-${openSubParents.includes(subParent.id) ? 'up' : 'down'}`} />
              </ParentCardHeader>

              {openSubParents.includes(subParent.id) && (
                <Column gap='8px'>
                  {subParent.children.map((child) => (
                    <div
                      key={child.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        buttonClick(child);
                      }}
                      onKeyDown={() => ''}
                      role='button'
                      tabIndex={0}
                    >
                      {props.renderItem(child)}
                    </div>
                  ))}
                </Column>
              )}
            </ParentCard>
          ))}
        </Column>
      )) : new Array(3).fill(0).map(() => (
        <Column gap='12px' key={Date.now()}>
          <ParentCardSkeletonWrapper>
            <Skeleton />
          </ParentCardSkeletonWrapper>
        </Column>
      ))}
    </Column>
  );
};

export default FixedCascader;