import { get, post, put } from '../api';

export const getIntegrationsWithUserValue = async (userId) => {
  return get(`/api/userIntegrations?userId=${userId}`);
};

export const getUserIntegrations = async (userId) => {
  return get(`/api/userIntegrations/${userId}`);
};

export const addUserIntegration = async ({ userId, integrationId }) => {
  return post('/api/userIntegrations?platform=WEB', { userId, integrationId });
};

export const updateUserIntegration = async ({ userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn }) => {
  return put('/api/userIntegrations?platform=WEB', { userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn });
};