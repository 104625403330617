import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getAllCampaignsRegisters as getCampanhasSdk, getCampaignRegister as getCampanhaSdk, postCampaign as postCampanhaSdk, putCampaign as updateCampanhaSdk, deleteCampaign as deleteCampanhaSdk } from '@sdk/Campanhas';

//Action Types
export const Types = {
  GET_CAMPANHAS: 'CAMPANHAS_CADASTRO/GET_CAMPANHAS',
  GET_CAMPANHAS_SUCCESS: 'CAMPANHAS_CADASTRO/GET_CAMPANHAS_SUCCESS',
  GET_CAMPANHAS_ERROR: 'CAMPANHAS_CADASTRO/GET_CAMPANHAS_ERROR',

  GET_CAMPANHA: 'CAMPANHAS_CADASTRO/GET_CAMPANHA',
  GET_CAMPANHA_SUCCESS: 'CAMPANHAS_CADASTRO/GET_CAMPANHA_SUCCESS',
  GET_CAMPANHA_ERROR: 'CAMPANHAS_CADASTRO/GET_CAMPANHA_ERROR',

  SAVE_CAMPANHA: 'CAMPANHAS_CADASTRO/SAVE_CAMPANHA',
  SAVE_CAMPANHA_SUCCESS: 'CAMPANHAS_CADASTRO/SAVE_CAMPANHA_SUCCESS',
  SAVE_CAMPANHA_ERROR: 'CAMPANHAS_CADASTRO/SAVE_CAMPANHA_ERROR',

  DELETE_CAMPANHA: 'CAMPANHAS_CADASTRO/DELETE_CAMPANHA',
  DELETE_CAMPANHA_SUCCESS: 'CAMPANHAS_CADASTRO/DELETE_CAMPANHA_SUCCESS',
  DELETE_CAMPANHA_ERROR: 'CAMPANHAS_CADASTRO/DELETE_CAMPANHA_ERROR',

  RESET: 'CAMPANHAS_CADASTRO/RESET',
};

//Action Creators
export const getCampanhas = (params) => ({ type: Types.GET_CAMPANHAS, limit: params?.limit, search: params?.search });
export const getCampanhasSuccess = (campanhasObj) => ({ type: Types.GET_CAMPANHAS_SUCCESS, campanhasObj });
export const getCampanhasError = (error) => ({ type: Types.GET_CAMPANHAS_ERROR, error }); 

export const getCampanha = (externalId) => ({ type: Types.GET_CAMPANHA, externalId });
export const getCampanhaSuccess = (campanha) => ({ type: Types.GET_CAMPANHA_SUCCESS, campanha });
export const getCampanhaError = (error) => ({ type: Types.GET_CAMPANHA_ERROR, error }); 

export const saveCampanha = (campanha) => ({ type: Types.SAVE_CAMPANHA, campanha });
export const saveCampanhaSuccess = (campanha) => ({ type: Types.SAVE_CAMPANHA_SUCCESS, campanha });
export const saveCampanhaError = (error) => ({ type: Types.SAVE_CAMPANHA_ERROR, error });

export const deleteCampanha = (id) => ({ type: Types.DELETE_CAMPANHA, id });
export const deleteCampanhaSuccess = () => ({ type: Types.DELETE_CAMPANHA_SUCCESS });
export const deleteCampanhaError = (error) => ({ type: Types.DELETE_CAMPANHA_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetCampanhas(action) {
  try {
    const { limit, search } = action;
    const campanhasObj = yield call(getCampanhasSdk, { limit, search });
    yield put(getCampanhasSuccess(campanhasObj));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCampanhasError(err));
  }
}

function* fetchGetCampanha(action) {
  try {
    const { externalId } = action;
    const campanha = yield call(getCampanhaSdk, externalId);
    yield put(getCampanhaSuccess(campanha));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCampanhaError(err));
  }
}

function* fetchSaveCampanha(action) {
  try {
    let { campanha } = action;

    if (campanha.id) {
      campanha = yield call(updateCampanhaSdk, campanha);
    } else {
      campanha = yield call(postCampanhaSdk, campanha);
    }
  
    yield put(saveCampanhaSuccess(campanha));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCampanhaError(err));
  }
}

function* fetchDeleteCampanha(action) {
  try {
    const { id } = action;
    yield call(deleteCampanhaSdk, id);
    yield put(deleteCampanhaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteCampanhaError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CAMPANHAS, fetchGetCampanhas),
  takeLatest(Types.GET_CAMPANHA, fetchGetCampanha),
  takeLatest(Types.SAVE_CAMPANHA, fetchSaveCampanha),
  takeLatest(Types.DELETE_CAMPANHA, fetchDeleteCampanha),
];

// Reducer
const initialState = Map({
  campanhas: List(),
  total: 0,
  loading: false,
  success: false,
  error: false,
  campanha: {},
  loadingCampanha: false,
  successCampanha: false,
  errorCampanha: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
});

const handleGetCampanhas = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCampanhasSuccess = (state, action) => {
  const { campanhasObj } = action;

  return state.set('campanhas', campanhasObj.campaigns).set('total', campanhasObj.total).set('loading', false).set('success', true).set('error', false);
};

const handleGetCampanhasError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetCampanha = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCampanhaSuccess = (state, action) => {
  const { campanha } = action;

  return state.set('campanha', campanha).set('loading', false).set('success', true).set('error', false);
};

const handleGetCampanhaError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveCampanha = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveCampanhaSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveCampanhaError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleDeleteCampanha = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteCampanhaSuccess = (state, action) => {
  const { campanhas } = action;

  return state.set('campanhas', campanhas).set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteCampanhaError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CAMPANHAS:
      return handleGetCampanhas(state, action);
    case Types.GET_CAMPANHAS_SUCCESS:
      return handleGetCampanhasSuccess(state, action);
    case Types.GET_CAMPANHAS_ERROR:
      return handleGetCampanhasError(state, action);
    case Types.GET_CAMPANHA:
      return handleGetCampanha(state, action);
    case Types.GET_CAMPANHA_SUCCESS:
      return handleGetCampanhaSuccess(state, action);
    case Types.GET_CAMPANHA_ERROR:
      return handleGetCampanhaError(state, action);
    case Types.SAVE_CAMPANHA:
      return handleSaveCampanha(state, action);
    case Types.SAVE_CAMPANHA_SUCCESS:
      return handleSaveCampanhaSuccess(state, action);
    case Types.SAVE_CAMPANHA_ERROR:
      return handleSaveCampanhaError(state, action);
    case Types.DELETE_CAMPANHA:
      return handleDeleteCampanha(state, action);
    case Types.DELETE_CAMPANHA_SUCCESS:
      return handleDeleteCampanhaSuccess(state, action);
    case Types.DELETE_CAMPANHA_ERROR:
      return handleDeleteCampanhaError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}