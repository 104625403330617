import React from 'react';
import { List, Text, View, Column } from '@components-teammove';
import { CampaignImage, GapRow, PositivationIcon, SalesIcon } from '../../styles';
import { formatDate } from '@utils';

import styled from 'styled-components';
import { ListTitle } from './styles';

const StyledList = styled(List)`
  margin-bottom: 1.5rem;
  cursor: pointer;
`;

const TinyMarginBottom = styled(View)`
  margin-bottom: .75rem;
`;

export default function ListaSubcampanhas({ subcampaigns, handleClick, isPositivation }) {  
  return(
    subcampaigns.length > 0 ? (
      <Column gap='8px'>
        <ListTitle>Subcampanhas</ListTitle>
        <StyledList
          title='Subcampanhas'
          dataSource={subcampaigns}
          renderItem={(subcampaign) => (
            <List.Item key={subcampaign.customId} onClick={() => handleClick(subcampaign)}>
              <Column gap='10px'>
                <Text strong>
                  <GapRow>
                    {isPositivation ? <PositivationIcon type='thumbs-up'/> : <SalesIcon type='dollar-sign'/>}
                    {subcampaign.name}
                  </GapRow>
                </Text>
                {subcampaign.startAt && subcampaign.endAt && <Text>Período: {formatDate(subcampaign.startAt)} até {formatDate(subcampaign.endAt)}</Text>}
              </Column>
              <CampaignImage src={subcampaign.image}/>
            </List.Item>
          )}
        />
      </Column>
    ) : (
      <TinyMarginBottom>
        <Text>Sem subcampanhas</Text>
      </TinyMarginBottom>
    )
  );
}