import styled from 'styled-components';
import { Text } from '@components-teammove';

export const Divider = styled.div`
  border-top: 2px solid ${({ theme })=> theme.darkSecondaryColor};
`;

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ opacity })=> opacity && opacity};
`;

export const HistoricSimbol = styled.div`
width: 16px;
height: 16px;
background-color: green;
border-radius: 50%;
margin-right: 5px;
`;

export const Line = styled.div`
  width: 2px;
  height: 16px;
  background-color: green;
  margin: 0 7px;
`;

export const ButtonStyle = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;