import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app/';

import { Icon , 
  Body, 
  PageHeader, 
  Breadcrumb, 
  ActiveFilters, 
  Button, 
  Column, 
  Filter, 
  Row, 
  DateControllerHeader 
} from '@components-teammove';

import { TelasNomenclaturas, Filtros, InfoUsuario, mountDataTreeSelect, exportExcel } from '@utils';

import { initialModalFilters } from './rules';

import { getUsuarios } from '@sdk/Usuarios';
import { getCategorias } from '@sdk/Chamados';
import { getAllClassificationsWithOptions } from '@sdk/Classificacoes';
import { 
  PARAMS_KEY, 
  FILTERS_KEY, 
  getCategoryRanking, 
  getOpenTicketsDash, 
  getConclusionOnTimeDash, 
  getClassificationInformationDash, 
  getSatisfactionIndexDash,
  getExcelData,
  getCompaniesRanking
} from '@ducks/chamadosDashboard';

import Cards from './Cards';
import OpenTicketsEvolution from './OpenTicketsEvolution';
import CategoriesRanking from './CategoriesRanking';
import OnTimeDeliveries from './OnTimeDeliveries';
import SatisfactionIndex from './SatisfactionIndex';
import ClassificationsData from './ClassificationsData';
import CompaniesRanking from './CompaniesRanking';

export default function DashboardChamados() {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const usuario = InfoUsuario.get();
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');
  
  const [ visibleFilterModal, setVisibleFilterModal ] = useState(false);

  const [ currentFilters, setCurrentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));

  const [ usuarios, setUsuarios] = useState();
  const [ categoriasTree, setCategoriasTree] = useState();
  const [ classificationsWithOptions, setClassificationsWithOptions] = useState();
  const [ filtersModal, setFiltersModal] = useState(initialModalFilters);
  const [ isShowingInactiveCategories, setIsShowingInactiveCategories ] = useState(false);
  const [ categoriesValuesWithActiveAndInactive, setCategoriesValuesWithActiveAndInactive ] = useState();
  const [ categoriesValuesOnlyActive, setCategoriesValuesOnlyActive ] = useState();

  const excelData = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('excelData'));
  const loadingExcelData = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingExcelData'));
  
  const getOptions = () => {
    return classificationsWithOptions?.map((classification) => ({
      type: 'SELECT',
      name: `idsClassificationsItens_${classification.id}`,
      label: classification.titulo + ' - Opções',
      placeholder: `Selecione ${classification.titulo}`,
      options:classification.opcoes.map((item)=> ({ value: item.id, label: item.titulo })),
      dependsOn:'idsClassifications',
      idDepends: classification.id,
    }));  
  };
 
  useEffect(()=> {
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => usuario.hierarquia?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
      getCategorias(false, false)
        .then((resp) => { 
          const response = resp.filter((item)=> item.ativo);
          const mountDataActive = mountDataTreeSelect(response, 'id', 'idCategoria', 'titulo');
          const mountData = mountDataTreeSelect(resp, 'id', 'idCategoria', 'titulo');
          setCategoriesValuesOnlyActive(mountDataActive);
          setCategoriesValuesWithActiveAndInactive(mountData);

          if(!isShowingInactiveCategories) {
            setCategoriasTree(mountDataActive);
          }else {
            setCategoriasTree(mountData);
          }
          
        });
      getAllClassificationsWithOptions()
        .then((resp)=>{
          setClassificationsWithOptions(resp);
        }); 
    })();
  },[]);

  useEffect(() => {
    if (usuarios && categoriasTree && classificationsWithOptions) {
      setFiltersModal([...filtersModal.map((filter) => {
        if (filter.name === 'idUserResponsible') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idUserCreation') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'idCategory') {
          return { ...filter, options: categoriasTree };
        }
        if (filter.name === 'idsClassifications') {
          return { ...filter, 
            options: classificationsWithOptions?.map((classification) => ({
              value: classification.id,
              label: classification.titulo,
            })) 
          };
        }
        return { ...filter };
      }), ...getOptions()]);
    }
  }, [usuarios, categoriasTree, classificationsWithOptions]);

  useEffect(() => {
    if(currentParams.initialDate) {
      Filtros.set(PARAMS_KEY, currentParams);
      dispatch(getCategoryRanking({ ...currentFilters, ...currentParams }, { limit:10 }));
      dispatch(getCompaniesRanking({ ...currentFilters, ...currentParams }, { limit:10 }));
      dispatch(getOpenTicketsDash({ ...currentFilters, ...currentParams }));
      dispatch(getConclusionOnTimeDash({ ...currentFilters, ...currentParams }));
      dispatch(getClassificationInformationDash({ ...currentFilters, ...currentParams }));
      dispatch(getSatisfactionIndexDash({ ...currentFilters, ...currentParams }));
      dispatch(getExcelData({ ...currentFilters, ...currentParams }));
    }
  }, [currentParams]);

  useEffect(() => {
    if(categoriesValuesWithActiveAndInactive) {
      if(!isShowingInactiveCategories) {
        setCategoriasTree(categoriesValuesOnlyActive);
      }else {
        setCategoriasTree(categoriesValuesWithActiveAndInactive);
  
      }
    }
  },[isShowingInactiveCategories]);

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
  };
  
  const handleSearchChangingNewFilters = (filtros) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    setCurrentFilters(filtros);
    handleSearch(filtros);
  };

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  const handleExport = () => {
    const fileName = 'Tickeks TeamMove - ' + currentParams.formattedRangeDate;
    
    const novoArray = excelData.map((item) => ({ 
      codigoResponsavel: item.assignedToUserId,
      nomeResponsavel: item.assignedToUserName,
      categoria: item.category ,
      codigoCriador: item.createdByUserId,
      nomeCriador: item.createdByUserName,
      dataCriacao: item.creationDate,
      dataConclusao: item.completionDate,
      numeroTicket: item.protocol,
      codigoUnidade: item.shortName,
      status: item.status,
      codigoTicket: item.ticketId,
      tituloTicket: item.ticketTitle,
      nomeUnidade: item.unitName,
    }));

    exportExcel(novoArray, fileName);
  };

  const handleActiveCategories = () => {
    setIsShowingInactiveCategories(!isShowingInactiveCategories);
  };
  
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Dashboard de ' + (ticketNomenclatures.plural)}
          onBack={() => window.history.back()}
          extra={[
            <Button size='small' type='secondary' loading={loadingExcelData} disabled={excelData.length === 0} key='1' icon={<Icon.MDI type='upload' />} onClick={() => handleExport()} >
            Exportar
            </Button>,
            <Button size='small' type='secondary' key='2' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilterModal(true)}>
            Filtros
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/chamados', name: `${ticketNomenclatures.plural}`, current: true },
                { path: '/chamados/dashboard-chamados', name: `Dashboard de ${ticketNomenclatures.plural}`, current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          <Column gap='28px'>
            <DateControllerHeader
              onChange={handleChangePeriod}
              typePeriod={currentParams.typePeriod}
              initialDate={currentParams.initialDate}
              formattedTypePeriod={currentParams.formattedRangeDate}
            />
            <Column gap='32px'>
              <OpenTicketsEvolution currentFilters={currentFilters} currentParams={currentParams}/>
              <Cards currentFilters={currentFilters} currentParams={currentParams}/>
            </Column>
            <Row gap='12px'>
              <OnTimeDeliveries currentFilters={currentFilters} currentParams={currentParams}/>
              <SatisfactionIndex currentFilters={currentFilters} currentParams={currentParams}/>
            </Row>
            <ClassificationsData currentFilters={currentFilters} currentParams={currentParams}/>
            <CategoriesRanking currentFilters={currentFilters} currentParams={currentParams}/>
            <CompaniesRanking currentFilters={currentFilters} currentParams={currentParams}/>
          </Column>
          
        </PageHeader>
        {visibleFilterModal && (
          <Filter 
            visible={visibleFilterModal} 
            onClose={() => setVisibleFilterModal(false)}
            filters={filtersModal}
            filtros={currentFilters}
            onSearch={handleSearchChangingNewFilters}
            onChangeValues={{ idCategory: { change: handleActiveCategories, value: isShowingInactiveCategories } }}
          />
        )}
      </Body>
    </App>
  );
}
