import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Form, Input, Select, DatePicker, TimePicker, Row, InputNumber, Column, Text } from '@components-teammove';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { moment, InfoUsuario, TelasNomenclaturas } from '@utils';
import { getAtividade, resetAtividade, getDadosCadastro , postAtividade, updateAtividade, getUsersByCompany } from '@ducks/atividades';
import { getChecklistPreview, reset, getChecklistFinishPreview } from '@ducks/checklists';
import { ActivityIcon, ActivityName, IconWrapper, WarningIcon, WarningText } from './styles';
import { getUserIntegrations, reset as resetIntegrations } from '@ducks/integracoes';
import { getUnidades } from '@sdk/Unidades';

export default function Cadastro({ visible, onCancel, idCompany, idUser }) {
  const { idActivity: id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [currentActivityType, setCurrentActivityType] = useState();
  const [selectedNotificationOption, setSelectedNotificationOption] = useState('NAO'); 
  const [originalActivity, setOriginalActivity] = useState();
  const [activityIsFastFilling, setActivityIsFastFilling] = useState(false);

  const notificationTimeUnityOptions = [
    { label: 'Minutos', value: 'MINUTO' }, 
    { label: 'Horas', value: 'HORA' }, 
    { label: 'Dias', value: 'DIA' }, 
    { label: 'Semanas', value: 'SEMANA' }
  ];

  const notificationOptions = [
    { label: 'Não notificar', value: 'NAO' }, 
    { label: 'Hora do evento', value: '0' }, 
    { label: '5 minutos antes', value: '5' }, 
    { label: '10 minutos antes', value: '10' }, 
    { label: '15 minutos antes', value: '15' }, 
    { label: '30 minutos antes', value: '30' }, 
    { label: '1 hora antes', value: '1' }, 
    { label: 'Personalizado', value: 'PERSONALIZADO' }
  ];

  const dispatch = useDispatch();

  const activity = useSelector(({ atividades }) => atividades.get('atividade'));
  const success = useSelector(({ atividades }) => atividades.get('success'));
  const registerData = useSelector(({ atividades }) => atividades.get('dadosCadastro'));
  const loadingRegisterData = useSelector(({ atividades }) => atividades.get('loadingDadosCadastro'));
  const successRegisterData = useSelector(({ atividades }) => atividades.get('successDadosCadastro'));
  const loadingSave = useSelector(({ atividades }) => atividades.get('loadingSave'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));
  const errorSave = useSelector(({ atividades }) => atividades.get('errorSave'));
  const checklistPreview = useSelector(({ checklists }) => checklists.get('checklistPreview'));
  const successChecklistPreview = useSelector(({ checklists }) => checklists.get('successChecklistPreview'));

  const usersByCompany = useSelector(({ atividades }) => atividades.get('usersByCompany'));
  const loadingUsersByCompany = useSelector(({ atividades }) => atividades.get('loadingUsersByCompany'));
  const successUsersByCompany = useSelector(({ atividades }) => atividades.get('successUsersByCompany'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const isCustomNotification = useMemo(() => selectedNotificationOption === 'PERSONALIZADO', [selectedNotificationOption]);

  const [ users, setUsers ] = useState([]);
  const [ unidadesOptions, setUnidadesOptions ] = useState([]);
  const [loadingGetUnidades, setLoadingGetUnidades] = useState(true);
  
  useEffect(() => {
    if(idCompany) {
      form.setFieldsValue({ idCompany: idCompany });
      getUsers(idCompany);
    }
    if(idUser) {
      form.setFieldsValue({ idUser: idUser });
    }
    if (id) {
      dispatch(getAtividade(id));
    }
    getUnidades(InfoUsuario.get('cdUsuario'), true).then((resp) => {
      setUnidadesOptions(resp);
      setLoadingGetUnidades(false);
    });
    dispatch(getDadosCadastro());
    dispatch(getUserIntegrations(InfoUsuario.get('cdUsuario')));
    return () => {
      dispatch(resetAtividade());
      dispatch(resetIntegrations());
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (success) {
      if (activity.hasReminder) {
      
        const defaultNotificationTimeOptions = [
          { time: 10, unity: 'MINUTO' },
          { time: 15, unity: 'MINUTO' },
          { time: 30, unity: 'MINUTO' },
          { time: 1, unity: 'HORA' }
        ];

        const activityNotificationIsDefault = defaultNotificationTimeOptions.find(({ time, unity }) => time === activity.reminderTimeQuantity && unity === activity.reminderTimeUnity);
        const isCustomNotification = activity.reminderTimeQuantity !== 0 && !activityNotificationIsDefault;

        if (isCustomNotification) {
          setSelectedNotificationOption('PERSONALIZADO');
        } else if(activityNotificationIsDefault) {
          setSelectedNotificationOption(activity.reminderTimeQuantity.toString());
        } else {
          setSelectedNotificationOption('0');
        }
      }

      form.setFieldsValue({
        ...activity,
        date: moment(activity.start),
        start: moment(activity.start),
        end: moment(activity.end),
      });
      
      if (successRegisterData) {
        setCurrentActivityType(registerData.activityTypes.find(({ id }) => id === activity.idTypeActivity));
        form.setFieldsValue({ activityTypeId: activity.idTypeActivity });
      }

      setOriginalActivity(form.getFieldsValue());
      getUsers(activity.idCompany);
    }
  }, [success, successRegisterData]);

  useEffect(() => {
    if (successRegisterData && !id && !idUser) {
      const assignedUser = InfoUsuario.get('cdUsuario');
      form.setFieldsValue({ idUser: assignedUser });
    }
  }, [successRegisterData]);

  useEffect(() => {
    if (currentActivityType && (!id || registerData.activityTypes.find(({ name }) => name === form.getFieldValue('name')))) {
      form.setFieldsValue({ name: currentActivityType.name });
    }
    if(!id) {
      const isFastActivity  = currentActivityType && currentActivityType.fastFilling;
      setActivityIsFastFilling(isFastActivity);
      form.setFieldsValue({
        date:  moment(),
        start: moment(),
        end: moment().add(1, 'hour'),
      });
    }
  }, [currentActivityType]);

  useEffect(() => {
    if (successSave) {
      if(activityIsFastFilling && !id) {
        if(!activity?.idChecklist || activity?.useCheckin === 'S' || activity?.useCheckin === 'G') {
          onCancel();
        } else {
          dispatch(getChecklistPreview(activity?.idChecklist));
          dispatch(getChecklistFinishPreview(activity?.idChecklist));
        }
      } else {
        onCancel();
      }
    }
  }, [successSave, activity]);

  useEffect(() => {
    if (successChecklistPreview) {
      const idFirstAnswer = checklistPreview.groups[0].asks[0].id;
      onCancel();
      history.push(`/atividades/agenda/${activity.idActivity}/checklist/${activity?.idChecklist}/answer`, { question: idFirstAnswer, checklistId: activity?.idChecklist, activityId: activity.idActivity });
    }
  }, [successChecklistPreview]);

  useEffect(() => {
    if (errorSave) {
      form.setFields([{ name: 'idCompany', errors: [errorSave] }]);
    }
  }, [errorSave]);

  useEffect(() => {
    if (successUsersByCompany) {
      setUsers(usersByCompany);
    }
  }, [successUsersByCompany]);

  const getUsers = (idCompany) => {
    dispatch(getUsersByCompany(idCompany));
  };

  const renderActivityTypeOption = (activityType) => (
    <Row gap='15px' align='center'>
      <IconWrapper color={activityType.color}>
        <ActivityIcon type={activityType.icon}/>
      </IconWrapper>
      <ActivityName>{activityType.name}</ActivityName>
    </Row>
  );

  const onBeforeCancel = () => {
    if(originalActivity) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalActivity).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      let hasReminder = true;

      if (values.start && !values.end) {
        values.end = moment(values.start).add(1, 'hour');
      }
      if (!values.start) {
        values.start = moment(values.date).startOf('day');
      }
      if (!values.end) {
        values.end = moment(values.date).startOf('day');
      }

      if (!values.start.isSame(values.date, 'day')) {
        values.start = moment(values.date).set({ hour: values.start.format('HH'), minute: values.start.format('mm'), second: values.start.format('ss'), millisecond: values.start.format('ms') });
      }
      if (!values.end.isSame(values.date, 'day')) {
        values.end = moment(values.date).set({ hour: values.end.format('HH'), minute: values.end.format('mm'), second: values.end.format('ss'), millisecond: values.end.format('ms') });
      }

      if(selectedNotificationOption === 'NAO') {
        hasReminder = false;
      }

      values = {
        ...values, 
        name: values.name || currentActivityType.name,
        idTypeActivity: currentActivityType.id, 
        start: new Date(values.start?.valueOf()), 
        end: new Date(values.end?.valueOf()),
        hasReminder,
      };
      delete values.date;
      delete values.activityTypeId;
      delete values.period;

      if (id) {
        dispatch(updateAtividade({ ...values, id: parseInt(id) }));
      } else {
        if (!values.idUser) {
          values.idUser = InfoUsuario.get('cdUsuario');
        }
        dispatch(postAtividade(values));
      }

    }).catch(Form.scrollToFirstError);
  };

  const handleNotificationChange = (option) => {
    setSelectedNotificationOption(option);
    let reminderTimeUnity = 'MINUTO';

    if(option !== 'PERSONALIZADO' && option !== 'NAO') {
      if(option === '1') {
        reminderTimeUnity = 'HORA';
      } 
      form.setFieldsValue({ reminderTimeQuantity: option, reminderTimeUnity });
    }
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      onOk={handleOk}
      okButtonProps={{ loading: loadingSave }}
      title={(id ? 'Alteração de' : 'Nova') + ' atividade'}
    >
      <Form form={form} layout='vertical'>
        {success && !activity.allowToEdit && (
          <WarningText>
            <WarningIcon/>
              Essa atividade já foi iniciada e não pode ter seu checklist alterado
          </WarningText>
        )}
        <Form.Item name='idTemplate' hidden/>
        <Form.Item label='Tipo de atividade' name='activityTypeId' rules={[{ required: true, message: 'Insira um tipo de atividade' }]}>
          <Select 
            placeholder='Tipo de atividade' 
            options={registerData.activityTypes
              ?.slice()
              .sort((a, b) => a.order - b.order)
              .map((activityType) => ({
                label: renderActivityTypeOption(activityType),
                title: activityType.name,
                value: activityType.id
              }))
            }
            loading={loadingRegisterData}
            onChange={(newType) => setCurrentActivityType(registerData.activityTypes.find(({ id }) => id === newType))}
            value={currentActivityType?.id}
            fitContent
            disabled={success && !activity?.allowToEdit}
          />
        </Form.Item>
        <Form.Item name='name' label='Título da atividade'>
          <Input placeholder='Título da atividade'/>
        </Form.Item>

        <Row gap='20px'>
          <Form.Item name='date' label='Data' rules={[{ required: true, message: 'Insira a data' }]}>
            <DatePicker 
              format='DD/MM/YYYY'
            />
          </Form.Item>
          <Form.Item name='start' label='Início'>
            <TimePicker
              format='HH:mm'
              placeholder='Início'
            />
          </Form.Item>
          <Form.Item name='end' label='Fim'>
            <TimePicker
              format='HH:mm'
              placeholder='Fim'
            />
          </Form.Item>
        </Row>
        <Form.Item label='Notificação'>
          <Select 
            value={selectedNotificationOption}
            onChange={(value) => handleNotificationChange(value)}
            options={notificationOptions}
          />
        </Form.Item>
        <Row gap='10px'> 
          <Form.Item 
            hidden={!isCustomNotification}
            name='reminderTimeQuantity'
            rules={[
              {
                required: isCustomNotification,
                message: 'Preencha o número personalizado.',
              },
            ]}
          >
            <InputNumber placeholder='Tempo'/>
          </Form.Item>
          <Form.Item
            hidden={!isCustomNotification}
            name='reminderTimeUnity'
            rules={[
              {
                required: isCustomNotification,
                message: 'Selecione o tempo personalizado.',
              },
            ]}
          >
            <Select placeholder='Medida' options={notificationTimeUnityOptions}/>
          </Form.Item>
        </Row>
        {currentActivityType?.metric === 'PLANO_DE_ACAO' && (
          <Form.Item name='objective' label='Objetivo'>
            <Input placeholder='Objetivo'/>
          </Form.Item>
        )}
        {currentActivityType?.useCompany !== 'N' && (
          <Form.Item name='idCompany' label={companyNomenclature.nomenclatura} rules={currentActivityType?.useCompany === 'S' && [{ required: true, message: `Selecione ${companyNomenclature.artigo} ${companyNomenclature.nomenclatura.toLowerCase()}` }]}>
            <Select
              optionLabelProp='title'
              disabled={!unidadesOptions}
              loading={loadingGetUnidades}
              listItemHeight={10} 
              listHeight={250}
              placeholder={'Selecione ' + (companyNomenclature.artigo) + ' ' + (companyNomenclature.nomenclatura).toLowerCase()}
              onChange={(item)=> getUsers(item)}
              filterOption={(input, option) => {
                const searchText = input.toLowerCase();
                return (
                  option.title?.toLowerCase().indexOf(searchText) >= 0 ||
                  option.nmEmpresa?.toLowerCase().indexOf(searchText) >= 0 ||
                  option.nmReduzido?.toLowerCase().indexOf(searchText) >= 0
                );
              }}  
            >
              {unidadesOptions?.map((item)=> (
                <Select.Option key={item.key} {...item}>
                  <Column>
                    <Text size='16px' weight='700' line='1.2'>{item?.nmReduzido}</Text>
                    <Text size='16px' weight='700' line='1.2'>{item?.nmEmpresa}</Text>
                    <Text size='12px' line='1.2'>{item?.endereco}</Text>
                    <Text size='12px' line='1.2'>${item?.cidade} - {item?.estado}</Text>
                  </Column>
                </Select.Option>
              ))}
              
            </Select>
          </Form.Item>
        )}

        {users.length > 0 && (
          <Form.Item name='idUser' label='Responsável' rules={[{ required: true, message: 'Insira o responsável' }]}>
            <Select 
              placeholder='Pesquise pelo usuário'
              loading={loadingUsersByCompany} 
              options={users.map(({ name, id }) => ({ value: id, label: name }))} 
              disabled={success && !activity?.allowToEdit}
            />
          </Form.Item>
        )}
       
        <Form.Item name='notes' label='Notas'>
          <Input.TextArea placeholder='Escreva uma nota sobre esta atividade'/>
        </Form.Item>
      </Form>
    </Modal>
  );
}