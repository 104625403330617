import React, { useEffect, useState } from 'react';
import { formatCategory } from './rules';
import { Column, Tree, Button, View, Icon } from '@components-teammove';

import CategoryRegister from './CategoryRegister';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesFamilies, reset, orderProductCategory, orderProductFamilies } from '@ducks/produtos';

export default function CategoriasSubcategorias() {
  const dispatch = useDispatch();
  const [visibleCategoryRegister, setVisibleCategoryRegister] = useState(false);

  const categoriesFamilies = useSelector(({ produtos }) => produtos.get('categoriesFamilies'));
  const loadingCategoriesFamilies = useSelector(({ produtos }) => produtos.get('loadingCategoriesFamilies'));

  const successFamilySave = useSelector(({ families }) => families.get('successFamilySave'));
  const successCategorySave = useSelector(({ categories }) => categories.get('successCategorySave'));

  useEffect(() => {
    dispatch(getCategoriesFamilies());
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (successFamilySave) {
      dispatch(getCategoriesFamilies());
    }
  }, [successFamilySave]);

  useEffect(() => {
    if (successCategorySave) {
      dispatch(getCategoriesFamilies());
    }
  }, [successCategorySave]);

  const onDrop = (info) => {
    const { dragNode, node, dropToGap } = info;
    const dropPos = node.pos.split('-');
    const position = parseInt(dropPos[dropPos.length - 1]);
    const dragItem = categoriesFamilies.filter((item) => item.families && item.families.length > 0);
    const idNodeParent = parseInt(node.key.charAt(0)); 
    const dragId = parseInt(dragNode.key.slice(1));
    const nodeId = parseInt(node.key.slice(1));

    if (!dropToGap) {
      if (dragItem) return;
    } else {
      if(node.children && dragNode.children) {
        const id = parseInt(dragNode.key);
        dispatch(orderProductCategory(id, idNodeParent || null, position));
      } else {
        dispatch(orderProductFamilies(dragId, idNodeParent || null, nodeId, position));
      }
    }
  };

  return (
    <Column>
      {loadingCategoriesFamilies || categoriesFamilies.length > 0 ? (
        <>
          <Tree
            multiple
            defaultExpandAll
            loading={loadingCategoriesFamilies}
            treeData={formatCategory(categoriesFamilies)}
            onDrop={onDrop}
            draggable
          />
          <Button
            type='secondary'
            size='small'
            rounder
            onClick={() => setVisibleCategoryRegister(true)}
          >
            + Nova Categoria
          </Button>
        </>
      ) : (
        <View alignItems="center">
          <Button type="primary" icon={<Icon.PlusOutlined />} onClick={() => setVisibleCategoryRegister(true)}>Adicionar Novo</Button>
        </View>
      )}

      {visibleCategoryRegister && (
        <CategoryRegister
          visible={visibleCategoryRegister}
          onCancel={() => setVisibleCategoryRegister(false)}
        />
      )}
    </Column>
  );

}
