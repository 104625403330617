import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getCamposDinamicos as getCamposDinamicosSdk, postCampoDinamico as postCampoDinamicoSdk, updateCampoDinamico as updateCampoDinamicoSdk, updateCamposDinamicos as updateCamposDinamicosSdk } from '@sdk/CamposDinamicos';

//Action Types
export const Types = {
  GET_CAMPOS_DINAMICOS: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_DINAMICOS',
  GET_CAMPOS_DINAMICOS_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_DINAMICOS_SUCCESS',
  GET_CAMPOS_DINAMICOS_ERROR: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_DINAMICOS_ERROR',

  SAVE_CAMPO_DINAMICO: 'CONFIGURACOES_UNIDADES/SAVE_CAMPO_DINAMICO',
  SAVE_CAMPO_DINAMICO_SUCCESS: 'CONFIGURACOES_UNIDADES/SAVE_CAMPO_DINAMICO_SUCCESS',
  SAVE_CAMPO_DINAMICO_ERROR: 'CONFIGURACOES_UNIDADES/SAVE_CAMPO_DINAMICO_ERROR',

  DELETE_CAMPO_DINAMICO: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_DINAMICO',
  DELETE_CAMPO_DINAMICO_SUCCESS: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_DINAMICO_SUCCESS',
  DELETE_CAMPO_DINAMICO_ERROR: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_DINAMICO_ERROR',

  SAVE_CAMPOS_DINAMICOS: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_DINAMICOS',
  SAVE_CAMPOS_DINAMICOS_SUCCESS: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_DINAMICOS_SUCCESS',
  SAVE_CAMPOS_DINAMICOS_ERROR: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_DINAMICOS_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES/RESET',
};

//Action Creators
export const getCamposDinamicos = () => ({ type: Types.GET_CAMPOS_DINAMICOS });
export const getCamposDinamicosSuccess = (camposDinamicos) => ({ type: Types.GET_CAMPOS_DINAMICOS_SUCCESS, camposDinamicos });
export const getCamposDinamicosError = (error) => ({ type: Types.GET_CAMPOS_DINAMICOS_ERROR, error }); 

export const saveCampoDinamico = (campoDinamico) => ({ type: Types.SAVE_CAMPO_DINAMICO, campoDinamico });
export const saveCampoDinamicoSuccess = (campoDinamico) => ({ type: Types.SAVE_CAMPO_DINAMICO_SUCCESS, campoDinamico });
export const saveCampoDinamicoError = (error) => ({ type: Types.SAVE_CAMPO_DINAMICO_ERROR, error }); 

export const deleteCampoDinamico = (campoDinamico) => ({ type: Types.DELETE_CAMPO_DINAMICO, campoDinamico });
export const deleteCampoDinamicoSuccess = (campoDinamico) => ({ type: Types.DELETE_CAMPO_DINAMICO_SUCCESS, campoDinamico });
export const deleteCampoDinamicoError = (error) => ({ type: Types.DELETE_CAMPO_DINAMICO_ERROR, error }); 

export const saveCamposDinamicos = (camposDinamicos) => ({ type: Types.SAVE_CAMPOS_DINAMICOS, camposDinamicos });
export const saveCamposDinamicosSuccess = (camposDinamicos) => ({ type: Types.SAVE_CAMPOS_DINAMICOS_SUCCESS, camposDinamicos });
export const saveCamposDinamicosError = (error) => ({ type: Types.SAVE_CAMPOS_DINAMICOS_ERROR, error }); 

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetCamposDinamicos(action) {
  try {
    const camposDinamicos = yield call(getCamposDinamicosSdk);
    yield put(getCamposDinamicosSuccess(camposDinamicos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCamposDinamicosError(err));
  }
}

function* fetchSaveCampoDinamico(action) {
  try {
    let { campoDinamico } = action;
    
    if (campoDinamico.id) {
      campoDinamico = yield call(updateCampoDinamicoSdk, campoDinamico);
    }else{
      campoDinamico = yield call(postCampoDinamicoSdk, campoDinamico);
    }

    yield put(saveCampoDinamicoSuccess(campoDinamico));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCampoDinamicoError(err));
  }
}

function* fetchDeleteCampoDinamico(action) {
  try {
    let { campoDinamico } = action;
    
    yield call(getCamposDinamicosSdk);
    campoDinamico = yield call(updateCampoDinamicoSdk, campoDinamico);

    yield put(deleteCampoDinamicoSuccess(campoDinamico));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteCampoDinamicoError(err));
  }
}

function* fetchSaveCamposDinamicos(action) {
  try {
    let { camposDinamicos } = action;
    
    yield put(getCamposDinamicosSuccess(camposDinamicos));
    camposDinamicos = yield call(updateCamposDinamicosSdk, camposDinamicos);

    yield put(saveCamposDinamicosSuccess(camposDinamicos));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCamposDinamicosError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CAMPOS_DINAMICOS, fetchGetCamposDinamicos),
  takeLatest(Types.SAVE_CAMPO_DINAMICO, fetchSaveCampoDinamico),
  takeLatest(Types.DELETE_CAMPO_DINAMICO, fetchDeleteCampoDinamico),
  takeLatest(Types.SAVE_CAMPOS_DINAMICOS, fetchSaveCamposDinamicos),
];

// Reducer
const initialState = Map({
  camposDinamicos: List(),
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
  loadingSaveCamposDinamicos: false,
  successSaveCamposDinamicos: false,
  errorSaveCamposDinamicos: false
});

const handleGetCamposDinamicos = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCamposDinamicosSuccess = (state, action) => {
  const { camposDinamicos } = action;

  return state.set('camposDinamicos', camposDinamicos).set('loading', false).set('success', true).set('error', false);
};

const handleGetCamposDinamicosError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveCampoDinamico = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveCampoDinamicoSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveCampoDinamicoError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleDeleteCampoDinamico = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteCampoDinamicoSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteCampoDinamicoError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSaveCamposDinamicos = (state, action) => {
  return state.set('loadingSaveCamposDinamicos', true).set('successSaveCamposDinamicos', false).set('errorSaveCamposDinamicos', false);
};

const handleSaveCamposDinamicosSuccess = (state, action) => {
  return state.set('loadingSaveCamposDinamicos', false).set('successSaveCamposDinamicos', true).set('errorSaveCamposDinamicos', false);
};

const handleSaveCamposDinamicosError = (state, action) => {
  return state.set('loadingSaveCamposDinamicos', false).set('successSaveCamposDinamicos', false).set('errorSaveCamposDinamicos', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CAMPOS_DINAMICOS:
      return handleGetCamposDinamicos(state, action);
    case Types.GET_CAMPOS_DINAMICOS_SUCCESS:
      return handleGetCamposDinamicosSuccess(state, action);
    case Types.GET_CAMPOS_DINAMICOS_ERROR:
      return handleGetCamposDinamicosError(state, action);
    case Types.SAVE_CAMPO_DINAMICO:
      return handleSaveCampoDinamico(state, action);
    case Types.SAVE_CAMPO_DINAMICO_SUCCESS:
      return handleSaveCampoDinamicoSuccess(state, action);
    case Types.SAVE_CAMPO_DINAMICO_ERROR:
      return handleSaveCampoDinamicoError(state, action);
    case Types.DELETE_CAMPO_DINAMICO:
      return handleDeleteCampoDinamico(state, action);
    case Types.DELETE_CAMPO_DINAMICO_SUCCESS:
      return handleDeleteCampoDinamicoSuccess(state, action);
    case Types.DELETE_CAMPO_DINAMICO_ERROR:
      return handleDeleteCampoDinamicoError(state, action);
    case Types.SAVE_CAMPOS_DINAMICOS:
      return handleSaveCamposDinamicos(state, action);
    case Types.SAVE_CAMPOS_DINAMICOS_SUCCESS:
      return handleSaveCamposDinamicosSuccess(state, action);
    case Types.SAVE_CAMPOS_DINAMICOS_ERROR:
      return handleSaveCamposDinamicosError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
