import moment, { getNomeDia } from '../../../utils/Datas';

export const getReportPeriod = (baseDate) => {
  const period = { start: baseDate || moment(), end: baseDate || moment() };
  switch (getNomeDia(baseDate || moment())) {
    case 'seg':
      return {
        start: period.start.subtract(7, 'days'),
        end: period.end.subtract(3, 'days')
      };
    case 'ter':
      return {
        start: period.start.subtract(8, 'days'),
        end: period.end.subtract(4, 'days')
      };
    case 'qua':
      return {
        start: period.start.subtract(9, 'days'),
        end: period.end.subtract(5, 'days')
      };
    case 'qui':
      return {
        start: period.start.subtract(10, 'days'),
        end: period.end.subtract(6, 'days')
      };
    case 'sex':
      return {
        start: period.start.subtract(4, 'days'),
        end: period.end
      };
    default: return;
  }
};

export const getColorByPercentage = (percent, theme) => {
  if (percent < 50) {
    return 'var(--danger-color)';
  } else if (percent < 70) {
    return 'var(--warning-color)';
  } else if (percent < 90) {
    return 'var(--success-color)';
  } else {
    return theme['@global-action-color'];
  }
};