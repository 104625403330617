import { dateToAnsi } from '@utils';

export const formatFilters = (filters) => {
  const { companies, dateRange, status, search } = filters;

  let companiesParam = companies ? 'companies=' + companies.join(' ') : '';
  let startDateParam = dateRange ? 'startDate=' + dateToAnsi(dateRange[0]) : '';
  let endDateParam = dateRange ? 'endDate=' + dateToAnsi(dateRange[1]) : '';
  let statusParam = status ? 'status=' + status.join(' ') : '';
  let searchParam = search ? 'search=' + search : '';

  return [companiesParam, startDateParam, endDateParam, statusParam, searchParam].filter((param) => param).join('&');
};