import React, { useMemo } from 'react';

import { Map } from 'immutable';
import { formatDate, formaDateToNow } from '@utils';
import ConteudoIcone from '@containers/Wikis/Conteudos/Icone';
import WikiVisualizacaoHtml from './Html';
import wikiFeedbacks from '@containers/Wikis/Conteudos/Feedbacks';
import { Tag } from '@components'; 
import { StatusTag, ContentTitle, Badge } from '@components-teammove';
import {
  Meta,
  Title,
  TextSmall,
  Description,
  DescriptionHtml,
  TextResumo,
  TextNota,
  ContainerFeedback,
  NotaFeedback,
  Actions,
  IconStar,
  Extra,
  ComunicadoTagsContainer,
  ComunicadoSequencial,
  ItemInfoRow,
  StyledDivider,
  CommonText
} from './styles';
import User from './User';
import { useSelector } from 'react-redux';

export default function ItemConteudos({ item, usuario, isFiltering, categoriaPai }) {
  const comunicadoHtml = item.tipo === 'HTML' && item.comunicado;
  const visualizaData = item.dtInicioVisualizacao && item.comunicado;

  const conteudos = useSelector(({ wikis }) => wikis.get('conteudos').toArray());

  const parent = useMemo(() => conteudos.find(({ id }) => id === parseInt(item.id)), [conteudos]);

  return (
    <>
      <Meta>
        <Title>
          {isFiltering && item.path ? (
            <TextSmall>
              {item.path.map((itemPath) => {
                const path = itemPath.split('||');
                return <CommonText key={path[0]}>{path[0] === item.id.toString() ? '' : path[1] + ' > '}</CommonText>;
              })}
            </TextSmall>
          ) : null}
          {visualizaData && (
            <>
              <ItemInfoRow>
                {categoriaPai && 
              <ComunicadoTagsContainer>
                <StatusTag size='large' color={categoriaPai.get('tagCorPai')} text={categoriaPai.get('tituloPai')}/>
                <ComunicadoSequencial publicado={categoriaPai.get('publicado')}>
                  {categoriaPai.get('sequencialPrefixo')}
                  {categoriaPai.get('sequencial')}
                </ComunicadoSequencial>
              </ComunicadoTagsContainer>
                }
                <TextSmall>
                  {comunicadoHtml ? formaDateToNow(item.dtInicioVisualizacao, null, true) : formatDate(item.dtInicioVisualizacao)}
                </TextSmall>
              </ItemInfoRow>

              <StyledDivider/>
            </>
          )}
          <CommonText strong>
            {!item.lido && (!item.comunicado || (!item.tipo === 'GRUPO' && item.comunicado)) ? <Badge status='processing' /> : null}
            {visualizaData && parent && <ContentTitle>{parent.tituloPai}</ContentTitle>}
            {item.titulo}
          </CommonText>
        </Title>
        <Description>
          {comunicadoHtml ? (
            <DescriptionHtml>
              <WikiVisualizacaoHtml conteudo={Map(item)}/>
              {usuario && <User usuario={usuario} marginTop={30} useTextColor/>}
            </DescriptionHtml>
          ) : (
            item.resumo && (
              <TextResumo ellipsis={{ rows: 2 }}>
                {item.resumo}
              </TextResumo>
            )
          )}
        </Description>
        {item.notaMedia && item.notaMedia !== 0 ? (
          <ContainerFeedback
            horizontal
            onClick={(e) => {
              e.stopPropagation();
              wikiFeedbacks(item.id);
            }}
          >
            <TextNota strong>{item.notaMedia.toFixed(1)}</TextNota>
            <NotaFeedback disabled defaultValue={item.notaMedia} />
          </ContainerFeedback>
        ) : null}
        <Actions>
          {item.favorito && <IconStar />}
          {item.tags && item.tags.split(';').map((tag) => <Tag key={tag}>{tag}</Tag>)}
        </Actions>
      </Meta>
    
      {!comunicadoHtml && (
        <Extra>
          <ConteudoIcone item={item} />
        </Extra>
      )}
    </>
  );

}