import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, View, Radio, Upload, Button, Select, Switch, TimePicker } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';
import { authorization } from '@sdk/api';
import { download, InfoUsuario, getValueFromFile, moment } from '@utils';
import ErrorFileTooBig from '../../../../../../../../components/Upload/ErrorFileTooBig';

const { REACT_APP_API } = window.env;

const Edit = styled(Icon.EditOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const ViewMateriaTitle = styled(View)`
  font-size: 14px;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  svg{
    margin: .2rem .2rem 0;
  }
`;

const ViewAnexo = styled(View)`
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #cccc;
`;

const ViewDeleteAnexo = styled(View)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const tiposConteudos = [
  { chave: 'I', descricao: 'Imagem' },
  { chave: 'L', descricao: 'Link' },
  { chave: 'P', descricao: 'PDF' },
  { chave: 'V', descricao: 'Vídeo' },
];

export default function ModalAula({ data,  materias = [], visible, onCancel, loading, onOk }) {
  const { aula, index, materiaId, materiaTitulo } = data;
  const [form] = Form.useForm();
  const [tipo, setTipo] = useState(null);
  const [anexo, setAnexo] = useState(null);
  const [isChangingMateria, setIsChangingMateria] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setTipo(aula?.tipo);

    if (['I', 'P', 'V'].includes(aula?.tipo)) {
      if (aula?.upload) {
        setAnexo(aula.upload);
      } else {
        setAnexo({ path: aula.conteudo, name: 'Anexo' });
      }
    }
  }, [aula]);

  const getInitialValues = () => ({
    ...aula,
    duracao: aula?.duracao ? moment(aula?.duracao, 'HH:mm:ss') : null,
  });

  const getTypeFromFile = (type) => {
    if (type.includes('pdf')) {
      return 'P';
    }
    if (type.includes('png') || type.includes('jpeg') || type.includes('jpg')) {
      return 'I';
    }
    if (type.includes('mp4') || type.includes('aiv') || type.includes('wmv')) {
      return 'V';
    }
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      const { titulo, newMateriaId, tipo, resumo, conteudo, upload, publicado, duracao } = values;

      onOk({
        index,
        aula: {
          ...aula,
          titulo,
          newMateriaId,
          tipo,
          resumo,
          conteudo,
          upload: tipo !== 'L' && (anexo ? anexo : {
            tipo: getTypeFromFile(upload[0].type),
            path: upload[0].response,
            name: upload[0].name,
            size: upload[0].size,
          }),
          publicado,
          duracao: duracao !== null ? moment(duracao).format('HH:mm:ss') : null,
        },
        materiaId,
      });
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const getUploadTypeSpecifier = () => {
    switch (tipo) {
      case 'I':
        return 'image/*';
      case 'V':
        return 'video/*';
      case 'P':
        return '.pdf';
      default:
        return null;
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      setAnexo({ tipo: getTypeFromFile(info.file.type), path: info.file.response, name: info.file.name, size: info.file.size });
      setErrorMsg('');
    }
    if (info.file.status === 'error') {
      setErrorMsg('Tamanho de vídeo máximo: 10Mb');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      maskClosable={false}
      title={
        <View>
          <View>{aula ? 'Editar aula' : 'Nova aula'}</View>
          {!isChangingMateria && 
            <ViewMateriaTitle>Matéria: {materiaTitulo} <Edit onClick={() => setIsChangingMateria(true)}/></ViewMateriaTitle>
          }
        </View>
      }
    >
      <Form layout='vertical' form={form} initialValues={{ ...getInitialValues(), newMateriaId: materiaTitulo }}>
        {isChangingMateria && 
          <Form.Item label='Matéria' name='newMateriaId' rules={[{ required: true, message: 'Informe uma matéria' }]}>
            <Select options={materias.map((materia) => ({ label: materia.titulo, value: materia.id }))} defaultValue={materiaId}/>
          </Form.Item>
        }
        <Form.Item label='Título' name='titulo' rules={[{ required: true, message: 'Informe um título' }]}>
          <Input autoFocus />
        </Form.Item>
        <Form.Item label='Resumo' name='resumo'>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item label='Duração' name='duracao'>
          <TimePicker />
        </Form.Item>
        <Form.Item label='Tipo' name='tipo' rules={[{ required: true, message: 'Informe o tipo!' }]}>
          <Radio.Group
            buttonStyle='solid'
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
              form.setFieldsValue({ upload: undefined });
            }}
          >
            {tiposConteudos.map((tipo) => (
              <Radio.Button key={tipo.chave} value={tipo.chave}>
                {tipo.descricao}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {['L'].includes(tipo) && (
          <Form.Item label='Conteúdo' name='conteudo' rules={[{ required: true, message: 'Informe o conteudo!' }]}>
            <Input />
          </Form.Item>
        )}

        {['I', 'V', 'P'].includes(tipo) && (
          <Form.Item
            label='Anexo'
            name='upload'
            valuePropName='fileList'
            getValueFromEvent={getValueFromFile}
            rules={[{ required: !anexo, message: 'Informe o anexo!' }]}
          >
            {!anexo ? (
              <Upload
                listType='picture'
                headers={authorization()}
                folder={`EAD/${InfoUsuario.get('cdCliente')}`}
                URI={REACT_APP_API}
                onDownload={(file) => download(file.response)}
                onChange={handleChange}
                onRemove={() => setErrorMsg('')}
                accept={getUploadTypeSpecifier()}
              >
                <Button>
                  <Icon.UploadOutlined /> Clique para anexar
                </Button>
              </Upload>
            ) : (
              <View>
                <ViewAnexo horizontal>
                  <View>{anexo.name}</View>
                  <ViewDeleteAnexo
                    onClick={(event) => {
                      event.stopPropagation();
                      setAnexo(null);
                      form.setFieldsValue({ upload: null });
                    }}
                  >
                    <Icon.DeleteOutlined />
                  </ViewDeleteAnexo>
                </ViewAnexo>
              </View>
            )}
            {errorMsg && <ErrorFileTooBig message={errorMsg}/>}
          </Form.Item>
        )}

        {data.aula && <Form.Item label="Publicado" name="publicado" valuePropName="checked">
          <Switch />
        </Form.Item>}

      </Form>
    </Modal>
  );
}
