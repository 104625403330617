
export const TYPE_PERSONALIZATION = {
  SIMPLES: 'SI',
  ATRIBUTO_USUARIO: 'AU',
  LISTA_PRODUTO: 'LP',
};

export const initialValues = {
  color: '#ff0000',
  fontSize: 70,
  alinhamento: 'start',
  font: 'Arial',
  posX: 50,
  posY: 50,
  imageUrl: '',
  yImage: 50,
  xImage: 50,
  visible: true,
  editable: true,
  required: false,
  page: 0,
  type: 'SI',
  hasImage: false,
};