import React,  { useState, useEffect } from 'react';
import { Button, Form, View, Menu, Dropdown, Divider, Filter } from '@components';
import { Icon } from '@components-teammove';
import {
  ModalPersonalization, 
  Container,
  FormParams,
  FormContainer,
  NumberRound,
  ItemNumber,
  ItemTitle,
  ListaTitle,
  SugestaoTag,
  SugestaoTagText,
  PriceInput,
  SpecialPriceInput,
  SearchView,
  SpecialView,
  DropdownButton,
  StyledFormItem
} from './styles';

import { useSelector, useDispatch } from 'react-redux';
import { saveValuesListProduct, saveListProductEdit } from '@ducks/wikisPersonalizacao';
import { debounce } from 'debounce';

export default function VisualizacaoLista ({ onClose, conteudo }) {
  const parametros = JSON.parse(conteudo.get('parametrosImagem'));

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  const [customList, setCustomList] = useState([]);
  const [searchText, setSearchText] = useState('');

  const lista = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('listaProdutos'));
  const loadingSaveValuesListProduct = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('loadingSaveValuesListProduct'));
    
  useEffect(() => {
    const listaUpdated = lista.map((lista) => ({ ...lista, itens: lista.itens.map((item) => {
      if (item.itemUsuario.precoEspecial) item.itemUsuario.valor = 0;
      return { ...item, currentMenuItem: item.itemUsuario.precoEspecial || (!item.itemUsuario.valor && !item.valorSugerido) ? 2 : item.itemUsuario.valor ? 1 : item.valorSugerido ? 3 : 1 };
    }) }));
    setCustomList(listaUpdated);

    const initialValues = listaUpdated
      .reduce((state, list) => list.itens
        .reduce((state, item) => {
          if (!item.itemUsuario.valor) item.itemUsuario.valor = item.itemUsuario.precoEspecial ? 0 : (item.valorSugerido || 0);
          return { ...state, [`item${item.id}`]: item.itemUsuario.valor };
        }, {}), {});

    form.setFieldsValue(initialValues);
  }, []);

  useEffect(() => {
    const newFormValues = customList
      .reduce((state, list) => list.itens
        .reduce((state, item) => ({ ...state, [`item${item.id}`]: item.currentMenuItem === 1 ? item.itemUsuario.valor : item.currentMenuItem === 2 ? 0 : item.valorSugerido }), {}), {});

    form.setFieldsValue(newFormValues);
    Object.keys(newFormValues).forEach((key) => {
      if (newFormValues[key] === 0) {
        form.setFieldsValue({ ...newFormValues, [key]: undefined });
      }
    });
  }, [customList]);
  
  const handleOnContinue = () => {
    form.validateFields().then((values) => {
      dispatch(saveListProductEdit(true));

      const customListToSend = customList.map((list) => ({ ...list, itens: list.itens.map((item) => {
        delete item.currentMenuItem;
        return item;
      }) }));

      dispatch(saveValuesListProduct(customListToSend));

      if(onClose) {
        onClose();
      }
    });
    
  };

  const handleOnCancel = () => {
    if(onClose) {
      onClose();
    }
  };

  const handleMenuClick = (e, item) => {  
    const clickedItemKey = parseInt(e.key);

    switch(clickedItemKey) {
      case 1:
        handleChangeValueItemUser({ idItem: item.id, precoEspecial: false }, clickedItemKey);
        break;
      case 2:
        handleChangeValueItemUser({ idItem: item.id, valor: item.itemUsuario.valor || 0, precoEspecial: true }, clickedItemKey);
        break;
      case 3:
        handleChangeValueItemUser({ idItem: item.id, valor: item.valorSugerido, precoEspecial: false }, clickedItemKey);
        break;
      default:break;
    }
  };

  const handleDebounceChangeValueItemUser = debounce((idItem, value) => {
    handleChangeValueItemUser({ idItem: idItem, valor: value });
  });

  const handleChangeValueItemUser = (newItemUser, newSelectedMenuItem = 1) => {
    setCustomList(customList.map((itemList) => (
      {
        ...itemList,
        itens: itemList.itens.map((item) => ({
          ...item,
          currentMenuItem: item.id === newItemUser.idItem ? newSelectedMenuItem : item.currentMenuItem,
          itemUsuario: {
            ...item.itemUsuario,
            valor: item.id === newItemUser.idItem && newItemUser.valor >= 0 ? newItemUser.valor : item.itemUsuario.valor,
            precoEspecial: item.id === newItemUser.idItem && newItemUser.precoEspecial !== null && newItemUser.precoEspecial !== undefined ? newItemUser.precoEspecial : item.itemUsuario.precoEspecial
          }
        }))
      }
    ) ));
  };

  const formatValorSugeridoLabel = (item) => {
    let sugeridoString = item.valorSugerido.toString();
    if(sugeridoString.substring(sugeridoString.lastIndexOf('.') + 1 , sugeridoString.length).length === 1) {
      sugeridoString += '0';
    }
    return <SugestaoTagText>R$ {sugeridoString}</SugestaoTagText>;
  };

  const menu = (item) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, item)}>
        <Menu.Item key="1">
      Digitar valor
        </Menu.Item>
        <Menu.Item key="2">
      Preço especial  
        </Menu.Item>
        <Menu.Item key="3">
          {item.valorSugerido ? <View horizontal alignItems='center'>Sugestão<SugestaoTag>{formatValorSugeridoLabel(item)}</SugestaoTag></View> : null }
        </Menu.Item>
      </Menu> );
  };

  const handleChangeFilter = (item) => {
    if(searchText === '') return true;
    const itemName = item.nome.toString();
    return itemName.toLowerCase().includes(searchText.toLowerCase());   
  };

  return parametros.textos?.some((item) => item.productItemList > 0) ?
    ( 
      <ModalPersonalization title='Lista de Produtos' visible onCancel={() => handleOnContinue()} footer={null}>
        <Container alignItems='center'>
          <FormParams layout='vertical' form={form}>
            { customList.map((listaMap) => {
              return ( 
                <View key={listaMap.id}>
                  <View alignItems="center">
                    <ListaTitle key={listaMap.id}>{listaMap.nome}</ListaTitle>
                    <SearchView>
                      <Filter onChange={(text) => setSearchText(text)} onSearch={() => null}/>
                    </SearchView>
                    <Divider />
                  </View>
                  {listaMap.itens.filter((item) => handleChangeFilter(item)).map((item, index) => {
                    return (
                      <View key={item.id}>
                        <FormContainer>
                          <View horizontal alignItems='center' justifyContent='space-between'>
                            <View horizontal alignItems='center'>
                              <NumberRound alignItems='center' justifyContent='center'>
                                <ItemNumber>{index + 1}</ItemNumber>
                              </NumberRound>
                              <ItemTitle>{item.nome}</ItemTitle>
                            </View>
                            <View horizontal>
                              <StyledFormItem 
                                name={`item${item.id}`} 
                                rules={ item.currentMenuItem === 2 ? [] : [{ required: true, min: 0.01, type: 'number', message: 'Insira um valor' }] }
                              >
                                {(item.currentMenuItem === 1 || item.currentMenuItem === 3) && 
                                  <PriceInput
                                    prefix="R$"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    selectAllOnFocus
                                    value={item.currentMenuItem === 1 ? item.itemUsuario.valor : item.valorSugerido}
                                    onChangeEvent={(event, maskedvalue, floatvalue) => handleDebounceChangeValueItemUser(item.id, floatvalue)}
                                  />
                                }
                                {item.currentMenuItem === 2 && 
                                  <SpecialView>
                                    <SpecialPriceInput 
                                      disabled
                                      value={'Preço Especial'}
                                    />
                                  </SpecialView>
                                }
                              </StyledFormItem>
                              <Dropdown overlay={menu(item)}>
                                <DropdownButton>
                                  <Icon.DownOutlined />
                                </DropdownButton>
                              </Dropdown>
                          
                            </View>
                          </View>
                        </FormContainer>
                        <Divider />
                      </View>
                    );
                
                  })}
                </View>
              );
            })}
        
          </FormParams>

          <View horizontal width={250} justifyContent='space-evenly'>
            <Button size='large' danger onClick={handleOnCancel}>
          Cancelar
            </Button>
            <Button size='large' type='success' onClick={handleOnContinue} loading={loadingSaveValuesListProduct}>
          Salvar
            </Button>
          </View>
        </Container>
      </ModalPersonalization>
    ) : null;
}
