import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Divider, Column, HorizontalBarChart, Skeleton } from '@components-teammove';
import { ContentCompaniesGraphic, ContentGraphic, RowStyle, SelectPeriod, StyleEmpty } from './styles';
import { TelasNomenclaturas } from '@utils';
import { getCompaniesGraphic } from '@ducks/chamadosDashboard';

export default function CompaniesGraphic({ currentFilters, currentParams, topRange, setTopRange }) {
  const dispatch = useDispatch();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  
  const [ heightGraphic, setHeightGraphic ] = useState(300);

  const companiesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('companiesGraphic'));
  const loadingCompaniesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingCompaniesGraphic'));
  const successCompaniesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successCompaniesGraphic'));
  
  useEffect(() => {
    if(successCompaniesGraphic) {
      setHeightGraphic(companiesGraphic.companyRanking.length * 100);
    }
  }, [successCompaniesGraphic]);

  useEffect(() => {
    setTopRange(3);
    dispatch(getCompaniesGraphic({ ...currentFilters, ...currentParams }, { limit: 3 }));
  }, [currentFilters, currentParams]);

  const handleChangeTopRange = (value) => {
    setTopRange(value);
    dispatch(getCompaniesGraphic({ ...currentFilters, ...currentParams }, { limit: value }));
    setHeightGraphic(value * 100);
  };

  return(
    loadingCompaniesGraphic ? (
      <Skeleton.Map/>
    ) : successCompaniesGraphic && (
      companiesGraphic.companyRanking.length === 0 ? (
        <StyleEmpty description="Sem dados" />
      ) : (
        <ContentCompaniesGraphic>
          <Column gap='30px'>
            <RowStyle align='center' justify='space-between'>
              <Text size='20px' weight='700' >
                {companyNomenclature.plural}
              </Text>
              <SelectPeriod
                placeholder='Tops'
                value={topRange}
                options={[
                  { value:3, label: 'Top 3' },
                  { value:5, label: 'Top 5' },
                  { value:10, label: 'Top 10' },
                  { value:20, label: 'Top 20' },
                  { value:50, label: 'Top 50' },
                  { value:100, label: 'Top 100' },
                ]}
                onChange={(value) => handleChangeTopRange(value)}
              />
        
            </RowStyle>
            <Column gap='20px'>
              <Divider/>
              <ContentGraphic heightGraphic={heightGraphic}>
                <HorizontalBarChart data={companiesGraphic.companyRanking.map(({ numberOfTickets, name }) => ({ value: numberOfTickets, label: name }))} />
              </ContentGraphic>
            </Column>
        
          </Column>
        </ContentCompaniesGraphic>
      )
      
    )
  );
}