import React from 'react';
import { Checkbox } from '@components';
import styled, { css } from 'styled-components';
import { hexToRGBA } from '@utils';

const CheckboxStlyed = styled(Checkbox)`
  margin-left: 0 !important;
  margin-bottom: 10px;
  white-space: initial;

  padding: 2px 5px;
  border-radius: 3px;
  width: fit-content;

  ${({ acerto, erro }) => {
  if (acerto) {
    return css`
        background-color: ${() => hexToRGBA('#66CC66', 0.1)};
      `;
  } else if (erro) {
    return css`
        background-color: ${() => hexToRGBA('#CC4444', 0.1)};
      `;
  }
}}
`;

export default function Somatoria({ data }) {
  const { opcoes, valores } = data;

  return opcoes.map((opcao) => {
    const valor = valores.find((val) => val.id === opcao.id);
    return (
      <CheckboxStlyed
        key={opcao.id}
        defaultChecked={valor.correta}
        acerto={valor.correta === opcao.correta}
        erro={valor.correta !== opcao.correta}
      >
        {opcao.opcao}
      </CheckboxStlyed>
    );
  });
}
