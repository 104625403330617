import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

Image.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default Image;
