import { get, post, put } from '../api';

export const getWhitelabels = () => {
  return get('/api/whitelabel');
};

export const getWhitelabel = (id = -1) => {
  return get(`/api/whitelabel/${id}`);
};

export const saveWhitelabel = (whitelabel) => {
  return post('/api/whitelabel', whitelabel);
};

export const updateWhitelabel = (whitelabel) => {
  return put(`/api/whitelabel/${whitelabel.id}`, whitelabel);
};

export const getDefaultWhitelabel = (idCustomer) => {
  return get(`/api/whitelabel/default/${idCustomer}`);
};

export const setDefaultWhitelabel = (id) => {
  return put(`/api/whitelabel/default/${id}`);
};