import styled from 'styled-components';
import { Radio, Filter, View , ColumnChart } from '@components-teammove';

export const RadioGroupStyled = styled(Radio.Group)`
  margin-bottom: 10px;
`;

export const ColumnChartStyle = styled(ColumnChart)`
    * text {
        font-size: 14px;
        fill:${(props) => props.theme.textColor};
    }

    svg g g g g path{
      stroke: ${(props) => props.theme.darkPrimaryColor};
  }

`;

export const FilterStyle = styled(Filter)`
  &.ant-modal {
    .ant-modal-body {
     height: 200px; 
   }
  }
`;

export const ViewFiltroDia = styled(View)`
  font-size: 12px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  width: fit-content;
  padding: 0 5px;
  border-radius: 5px;
`;

export const ViewFiltroDiaFechar = styled(View)`
  margin-left: 10px;
  cursor: pointer;
  padding: 0 5px;
`;
