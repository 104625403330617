import React, { useEffect, useState } from 'react';

import { Row, Text } from '@components-teammove';
import { SelectPeriod, SelectPeriodButton, TagDate } from './styles';

import LeftRightGroupButton from './LeftRightGroupButton';
import { getInitialDateByPeriod, getRangeDate, handleChangeInitialDate, handleChangeRangeDate } from '../../containers/Chamados/DashboardChamados/rules';

import { getCurrentMomentStartOfMonth, compareDates } from '@utils';
import { updateModalConfirmation } from '@ducks/configuracoesMetaAtividades';
import { useDispatch, useSelector } from 'react-redux';

const DateControllerHeader = ({ onChange, typePeriod, initialDate, formattedTypePeriod, defaultPeriod, defaultOptions, colorArrowButton, renderOptionsAsButtons, modalConfirmation, fromMonthTable }) => {

  const dispatch = useDispatch();
  const selectOptions = defaultOptions || [];
  const modalConfirm = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('updateModalConfirmation'));
  const [dateChange, setDateChange] = useState({});

  useEffect(() => {
    let changedValue = false;
    if(!typePeriod) {
      typePeriod = defaultPeriod || 'DIARIO';
      changedValue = true;
    }  
    if(!initialDate) {
      initialDate = getInitialDateByPeriod(typePeriod || 'DIARIO');
      changedValue = true;
    }
    if(!formattedTypePeriod) {
      formattedTypePeriod = getRangeDate(typePeriod || 'DIARIO'); 
      changedValue = true;
    } 
    if (changedValue) {
      onChange({ typePeriod, initialDate, formattedTypePeriod });
    }

  },[]);

  useEffect(() => {
    if(modalConfirm) {   
      const { initialDate, action, typePeriod } = dateChange;
      changeDate(initialDate, action, typePeriod);
      dispatch(updateModalConfirmation(false));
    }
  }, [modalConfirm]);
  
  const handleTypePeriodChange = (value) => {
    const typePeriod = value;
    const initialDate = getInitialDateByPeriod(value);
    const formattedTypePeriod = getRangeDate(value);
    onChange({ typePeriod, initialDate, formattedTypePeriod });
  };
  
  const getLabelForCurrentTimePeriod = () => {
    if(typePeriod === 'DIARIO') {
      return 'Hoje';
    }else if(typePeriod === 'SEMANAL') {
      return 'Semana Atual';
    }else if(typePeriod === 'MENSAL') {
      return 'Mês Atual';
    }else if(typePeriod === 'TRIMESTRAL') {
      return 'Trimestre Atual';
    }else if(typePeriod === 'SEMESTRAL') {
      return 'Semestre Atual';
    }else if(typePeriod === 'ANUAL') {
      return 'Ano Atual';
    }else if(typePeriod === 'TODOS') {
      return 'Todos';
    }
  };

  const goToCurrentTime = () => {
    const initialDate = getInitialDateByPeriod(typePeriod);
    const formattedTypePeriod = getRangeDate(typePeriod);
    onChange({ typePeriod, initialDate, formattedTypePeriod });
  };

  const changeDate = (initialDate, action, typePeriod) => {
    const initialDateModify = handleChangeInitialDate(initialDate, action, typePeriod);
    const initialDateFormated = handleChangeRangeDate(initialDateModify, typePeriod);
    onChange({ typePeriod, initialDate: initialDateModify, formattedTypePeriod: initialDateFormated });
  };

  const handleDateChange = (initialDate, action, typePeriod) => {
    if (compareDates(initialDate, getCurrentMomentStartOfMonth()) && fromMonthTable) {
      setDateChange({ initialDate, action, typePeriod });
      modalConfirmation();
      return;
    }
    else {
      changeDate(initialDate, action, typePeriod);
    }
  };

  const SelectPeriodButtons = () => {
    return(
      <Row>
        <SelectPeriodButton onClick={()=> handleTypePeriodChange('DIARIO', 'typePeriod')} active={typePeriod === 'DIARIO'} radius='12px 0px 0px 12px'><Text size='16px' weight='600'>Dia</Text></SelectPeriodButton>
        <SelectPeriodButton onClick={()=> handleTypePeriodChange('SEMANAL', 'typePeriod')} active={typePeriod === 'SEMANAL'}><Text size='16px' weight='600'>Semana</Text></SelectPeriodButton>
        <SelectPeriodButton onClick={()=> handleTypePeriodChange('MENSAL', 'typePeriod')} active={typePeriod === 'MENSAL'} radius='0px 12px 12px 0px'><Text size='16px' weight='600'>Mês</Text></SelectPeriodButton>
      </Row>
    );
  };
  return (
    <>
      <Row justify={typePeriod === 'TODOS' ? 'end' : 'space-between'}>

        {typePeriod !== 'TODOS' && (
          <Row gap='24px' align='center'>
            <TagDate>
              <Text font='16px' weight='600' onClick={goToCurrentTime}>{getLabelForCurrentTimePeriod()}</Text>
            </TagDate>
            <LeftRightGroupButton 
              initialDate={initialDate} 
              currentDateFormatted={formattedTypePeriod} 
              handleDateChange={handleDateChange}
              typePeriod={typePeriod}
              colorArrowButton={colorArrowButton}
            />
          </Row>
        )}
        
        {renderOptionsAsButtons ? (
          <SelectPeriodButtons/>
        ) : (
          selectOptions && selectOptions.length !== 1 && (
            <SelectPeriod 
              size='small'
              value={typePeriod}
              options={selectOptions && selectOptions.length > 0 ? (
                selectOptions.map((period)=>(
                  { label: period.label, value: period.value }
                ))
              ) : [
                { label: 'Diário', value: 'DIARIO' },
                { label: 'Semanal', value: 'SEMANAL' },
                { label: 'Mensal', value: 'MENSAL' },
                { label: 'Trimestral', value: 'TRIMESTRAL' },
                { label: 'Semestral', value: 'SEMESTRAL' },
                { label: 'Anual', value: 'ANUAL' },
                { label: 'Mostrar tudo', value: 'TODOS' },
              ]}
              onChange={(value) => handleTypePeriodChange(value, 'typePeriod')}
            />
          )
        )}
      </Row>
    </>
  );
};

export default DateControllerHeader;