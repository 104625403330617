import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getUserIntegrations as getUserIntegrationsSdk,
  getIntegrationsWithUserValue as getIntegrationsWithUserValueSdk,
  addUserIntegration as postUserIntegrationSdk,
  updateUserIntegration as putUserIntegrationSdk
} from '@sdk/Integracoes';

export const FILTERS_KEY = 'FILTROS/INTEGRACOES';

//Action Types
export const Types = {
  GET_INTEGRATIONS: 'INTEGRATIONS/GET_INTEGRATIONS',
  GET_INTEGRATIONS_SUCCESS: 'INTEGRATIONS/GET_INTEGRATIONS_SUCCESS',
  GET_INTEGRATIONS_ERROR: 'INTEGRATIONS/GET_INTEGRATIONS_ERROR',

  GET_USER_INTEGRATIONS: 'INTEGRATIONS/GET_USER_INTEGRATIONS',
  GET_USER_INTEGRATIONS_SUCCESS: 'INTEGRATIONS/GET_USER_INTEGRATIONS_SUCCESS',
  GET_USER_INTEGRATIONS_ERROR: 'INTEGRATIONS/GET_USER_INTEGRATIONS_ERROR',

  ADD_USER_INTEGRATION: 'INTEGRATIONS/ADD_USER_INTEGRATION',
  ADD_USER_INTEGRATION_SUCCESS: 'INTEGRATIONS/ADD_USER_INTEGRATION_SUCCESS',
  ADD_USER_INTEGRATION_ERROR: 'INTEGRATIONS/ADD_USER_INTEGRATION_ERROR',

  UPDATE_USER_INTEGRATION: 'INTEGRATIONS/UPDATE_USER_INTEGRATION',
  UPDATE_USER_INTEGRATION_SUCCESS: 'INTEGRATIONS/UPDATE_USER_INTEGRATION_SUCCESS',
  UPDATE_USER_INTEGRATION_ERROR: 'INTEGRATIONS/UPDATE_USER_INTEGRATION_ERROR',

  RESET: 'INTEGRATIONS/RESET',
};

//Action Creators
export const getIntegrations = (userId) => ({ type: Types.GET_INTEGRATIONS, userId });
export const getIntegrationsSuccess = (integrations) => ({ type: Types.GET_INTEGRATIONS_SUCCESS, integrations });
export const getIntegrationsError = (error) => ({ type: Types.GET_INTEGRATIONS_ERROR, error });

export const getUserIntegrations = (userId) => ({ type: Types.GET_USER_INTEGRATIONS, userId });
export const getUserIntegrationsSuccess = (userIntegrations) => ({ type: Types.GET_USER_INTEGRATIONS_SUCCESS, userIntegrations });
export const getUserIntegrationsError = (error) => ({ type: Types.GET_USER_INTEGRATIONS_ERROR, error }); 

export const addUserIntegration = ({ userId, integrationId }) => ({ type: Types.ADD_USER_INTEGRATION, userId, integrationId });
export const addUserIntegrationSuccess = (userIntegration) => ({ type: Types.ADD_USER_INTEGRATION_SUCCESS, userIntegration });
export const addUserIntegrationError = (error) => ({ type: Types.ADD_USER_INTEGRATION_ERROR, error });

export const updateUserIntegration = ({ userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn }) => ({ type: Types.UPDATE_USER_INTEGRATION, userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn });
export const updateUserIntegrationSuccess = (userIntegration) => ({ type: Types.UPDATE_USER_INTEGRATION_SUCCESS, userIntegration });
export const updateUserIntegrationError = (error) => ({ type: Types.UPDATE_USER_INTEGRATION_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetIntegrations(action) {
  try {
    const { userId } = action;
    const integrations = yield call(getIntegrationsWithUserValueSdk, userId);
    yield put(getIntegrationsSuccess(integrations));
  } catch (err) {
    Notification.error(err.message);
    yield put(getIntegrationsError(err));
  }
}

function* fetchGetUserIntegrations(action) {
  try {
    const { userId } = action;
    const userIntegrations = yield call(getUserIntegrationsSdk, userId);
    yield put(getUserIntegrationsSuccess(userIntegrations));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUserIntegrationsError(err));
  }
}

function* fetchAddUserIntegration(action) {
  try {
    const { userId, integrationId } = action;
    const userIntegration = yield call(postUserIntegrationSdk, { userId, integrationId });
    yield put(addUserIntegrationSuccess(userIntegration));
  } catch (err) {
    Notification.error(err.message);
    yield put(addUserIntegrationError(err));
  }
}

function* fetchUpdateUserIntegration(action) {
  try {
    const { userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn } = action;
    const userIntegration = yield call(putUserIntegrationSdk, { userId, integrationId, value, tokenType, accessToken, refreshToken, expiresIn });
    yield put(updateUserIntegrationSuccess(userIntegration));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateUserIntegrationError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_USER_INTEGRATIONS, fetchGetUserIntegrations),
  takeLatest(Types.GET_INTEGRATIONS, fetchGetIntegrations),
  takeLatest(Types.ADD_USER_INTEGRATION, fetchAddUserIntegration),
  takeLatest(Types.UPDATE_USER_INTEGRATION, fetchUpdateUserIntegration),
];

// Reducer
const initialState = Map({
  integrations: List(),
  loadingIntegrations: false,
  successIntegrations: false,
  errorIntegrations: false,

  userIntegrations: List(),
  loadingUserIntegrations: false,
  successUserIntegrations: false,
  errorUserIntegrations: false,

  loadingAddUserIntegration: false,
  successAddUserIntegration: false,
  errorAddUserIntegration: false,

  loadingUpdateUserIntegration: false,
  successUpdateUserIntegration: false,
  errorUpdateUserIntegration: false,
});

const handleGetIntegrations = (state, action) => {
  return state.set('loadingIntegrations', true).set('successIntegrations', false).set('errorIntegrations', false);
};
const handleGetIntegrationsSuccess = (state, action) => {
  const { integrations } = action;
  return state.set('integrations', integrations).set('loadingIntegrations', false).set('successIntegrations', true).set('errorIntegrations', false);
};
const handleGetIntegrationsError = (state, action) => {
  return state.set('loadingIntegrations', false).set('successIntegrations', false).set('errorIntegrations', action.error);
};

const handleGetUserIntegrations = (state, action) => {
  return state.set('loadingUserIntegrations', true).set('successUserIntegrations', false).set('errorUserIntegrations', false);
};
const handleGetUserIntegrationsSuccess = (state, action) => {
  const { userIntegrations } = action;

  return state.set('userIntegrations', userIntegrations).set('loadingUserIntegrations', false).set('successUserIntegrations', true).set('errorUserIntegrations', false);
};
const handleGetUserIntegrationsError = (state, action) => {
  return state.set('loadingUserIntegrations', false).set('successUserIntegrations', false).set('errorUserIntegrations', action.error);
};

const handleAddUserIntegration = (state) => {
  return state.set('loadingAddUserIntegration', true).set('successAddUserIntegration', false).set('errorAddUserIntegration', false);
};
const handleAddUserIntegrationSuccess = (state) => {
  return state.set('loadingAddUserIntegration', false).set('successAddUserIntegration', true).set('errorAddUserIntegration', false);
};
const handleAddUserIntegrationError = (state, action) => {
  return state.set('loadingAddUserIntegration', false).set('successAddUserIntegration', false).set('errorAddUserIntegration', action.error);
};

const handleUpdateUserIntegration = (state, action) => {
  return state.set('loadingUpdateUserIntegration', true).set('successUpdateUserIntegration', false).set('errorUpdateUserIntegration', false);
};
const handleUpdateUserIntegrationSuccess = (state, action) => {
  return state.set('loadingUpdateUserIntegration', false).set('successUpdateUserIntegration', true).set('errorUpdateUserIntegration', false);
};
const handleUpdateUserIntegrationError = (state, action) => {
  return state.set('loadingUpdateUserIntegration', false).set('successUpdateUserIntegration', false).set('errorUpdateUserIntegration', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_INTEGRATIONS:
      return handleGetIntegrations(state, action);
    case Types.GET_INTEGRATIONS_SUCCESS:
      return handleGetIntegrationsSuccess(state, action);
    case Types.GET_INTEGRATIONS_ERROR:
      return handleGetIntegrationsError(state, action);

    case Types.GET_USER_INTEGRATIONS:
      return handleGetUserIntegrations(state, action);
    case Types.GET_USER_INTEGRATIONS_SUCCESS:
      return handleGetUserIntegrationsSuccess(state, action);
    case Types.GET_USER_INTEGRATIONS_ERROR:
      return handleGetUserIntegrationsError(state, action);

    case Types.ADD_USER_INTEGRATION:
      return handleAddUserIntegration(state, action);
    case Types.ADD_USER_INTEGRATION_SUCCESS:
      return handleAddUserIntegrationSuccess(state, action);
    case Types.ADD_USER_INTEGRATION_ERROR:
      return handleAddUserIntegrationError(state, action);

    case Types.UPDATE_USER_INTEGRATION:
      return handleUpdateUserIntegration(state, action);
    case Types.UPDATE_USER_INTEGRATION_SUCCESS:
      return handleUpdateUserIntegrationSuccess(state, action);
    case Types.UPDATE_USER_INTEGRATION_ERROR:
      return handleUpdateUserIntegrationError(state, action);
    
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}