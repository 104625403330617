import styled from 'styled-components';
import { Text, Column } from '@components-teammove';

export const ContentChartProgress = styled(Column)`
	padding: 14px 16px 14px 16px;
	border-radius: 18px;
	width: 52px;
	:hover {
		cursor: pointer;
		background-color: ${({ theme })=> theme.darkPrimaryColor};
	}	
	:active {
		background-color: ${({ theme })=> theme.darkPrimaryColor};
	}
	background-color: ${({ active, theme })=> active ? `${theme.darkPrimaryColor}` : '' };
	border: ${({ active })=> active ? '2px var(--super-success-color) solid ' : '' };
	
}`;

export const MonthText = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	opacity: ${({ active })=> active ? '1' : '.5' };
`;
