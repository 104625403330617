import { DirectionsRenderer, DirectionsService, GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getDistanceBetweenLocations } from '@utils';
import { InfoWindowView, MapStyles, Title } from './styles';

const API_KEY = 'AIzaSyBgvAQ2f4AcfkV0cERdTvpou9h2E-675So' ;

const GoogleMapComponent = ({ markers, marker, zoom, center, mapStyles, info, showMarkersRoutes, extraMapItem }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const showMarkersRoutesRef = useRef(showMarkersRoutes);

  const markersSortedByDistance = useMemo(() => {
    return markers && [
      markers[0], 
      ...[...markers]
        .slice(1)
        .sort((a, b) => getDistanceBetweenLocations(markers[0].position, a.position) > getDistanceBetweenLocations(markers[0].position, b.position) ? 1 : -1)
    ];
  }, [markers]);

  useEffect(() => {
    showMarkersRoutesRef.current = showMarkersRoutes;
  }, [showMarkersRoutes]);

  useEffect(() => {
    if (map && markers) {
      map.setCenter(center);
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(({ position }) => {
        bounds.extend(position);
      });
      map.fitBounds(bounds);
    }
  }, [center, markers]);

  useEffect(() => {
    if (!showMarkersRoutes) {
      setDirectionsResponse(null);
    } 
  }, [showMarkersRoutes]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    if (info) {
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('legend'));
    }
    setMap(map);
  };
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  });

  const clustererOptions = {
    maxZoom: 4, 
    imagePath: 'https://teammove.app/icones/maps-clusters/m' 
  };

  const directionsCallback = useCallback((response) => {
    if (!showMarkersRoutesRef.current) {
      return;
    }
    if (response?.status === 'OK') {
      setDirectionsResponse(response);
    }
  }, [showMarkersRoutes]);

  return isLoaded ? (
    <MapStyles>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={center}
        zoom={zoom}
        onLoad={(map) => handleOnLoad(map)}
      >
        {markers ? (
          <MarkerClusterer options={clustererOptions}>
            {(clusterer) => markers.map((marker) => (
              <Marker 
                key={marker.key} 
                position={new window.google.maps.LatLng(marker.position.lat, marker.position.lng)} 
                options={marker.icon ? { icon: marker.icon } : marker.disabledColor && { icon: 'https://teammove.app/icones/maps-clusters/marker_grey.png' }}
                clusterer={clusterer} 
                onClick={() => handleActiveMarker(marker.key)}
              >
                {activeMarker === marker.key ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <InfoWindowView onClick={marker.infoWindowClickHandler && (() => marker.infoWindowClickHandler())}>
                      <Title title>{marker.identifier}</Title>
                      <br/><Title>{marker.name}</Title>
                      <br/><Title>{marker.city}, {marker.state}</Title>
                    </InfoWindowView>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </MarkerClusterer>
        ) : marker ? (
          <Marker position={new window.google.maps.LatLng(marker.position.lat, marker.position.lng)}/>
        ) : null}
        {extraMapItem}

        {showMarkersRoutes && markers && (
          <DirectionsService
            // required
            options={{ 
              destination: `${String(markersSortedByDistance[markers.length - 1].position.lat)}, ${String(markersSortedByDistance[markers.length - 1].position.lng)}`,
              origin: `${String(markers[0].position.lat)}, ${String(markers[0].position.lng)}`,
              travelMode: 'DRIVING',
              waypoints: markersSortedByDistance
                .slice(1, markersSortedByDistance.length - 1)
                .map(({ position }) => ({ location: `${String(position.lat)}, ${String(position.lng)}` }))
            }}
            // required
            callback={directionsCallback}
          />
        )}

        {directionsResponse !== null && (
          <DirectionsRenderer
            // required
            options={{ 
              directions: directionsResponse,
              suppressMarkers: true,
            }}
          />
        )}

        {info && <InfoWindow position={center}>{info}</InfoWindow>}
      </GoogleMap>
    </MapStyles>
  ) : <></>;
};

export default GoogleMapComponent;