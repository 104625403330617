import styled from 'styled-components';
import { Rate } from 'antd';

export const StyledRate = styled(Rate)`
  &.ant-rate {
    font-size: 36px;
    .ant-rate-star {
      div[aria-checked="true"] {
        span.anticon {
          svg {
            path {
              color: ${({ useSuccessColor }) => useSuccessColor ? 'var(--success-color)' : '#fadb14'};
            }
          }
        }
      }
    }
  }
`;