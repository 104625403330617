import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledText = styled(Typography.Text)`
  &.ant-typography {
    color: ${({ theme, color }) => color ? color : theme.textColor};
  }
  font-size: ${({ size })=> size };
  font-weight: ${({ weight })=> weight };
  line-height: ${({ line })=> line };
  opacity: ${({ lowOpacity }) => lowOpacity && '.5'};
  align-self: ${({ alignSelf }) => alignSelf};
  cursor: ${({ cursor }) => cursor};
  
  text-overflow: ${({ textOverflow })=> textOverflow };
  white-space: ${({ whiteSpace })=> whiteSpace};
  overflow: ${({ overflow })=> overflow};
  width: ${({ maxWidth }) => maxWidth};
  vertical-align: ${({ verticalAlign }) => verticalAlign};

  &.ant-typography.ant-typography-disabled {
    color: ${({ theme })=> theme.textColor};
    opacity: 0.5;
  }
`;