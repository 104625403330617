import { get, post, put } from '../api';

export const getExtraFields = () => {
  return get('/api/products/extraFields');
};

export const postExtraFields = (extraFields) => {
  return post('/api/products/extraFields', extraFields);
};

export const putExtraFields = (extraFields) => {
  return put('/api/products/extraFields', extraFields);
};

export const updateOrder = (extraFields) =>{
  return put('/api/products/extraFields', extraFields);
};

