import React from 'react';
import { mountDataTree } from '@utils';
import Titulo from './Titulo';
import { FeatherIcon } from './styles';

export const mountCategorias = (categorias) => {
  categorias = categorias.map((cat) => ({
    ...cat,
    title: (<Titulo {...cat} />),
    selectable: false,
    dragOver: cat.tipo === 'GRUPO',
    icon: (<FeatherIcon type={(cat.tipo === 'GRUPO') ? 'folder' : 'file'} active={cat.ativo}/>),
  }));

  return mountDataTree(categorias, 'id', 'idCategoria', 'title');
};