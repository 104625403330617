import React, { useEffect, useState } from 'react';
import { Modal, Form, Select } from '@components-teammove';
import { manipulationHeight } from '@utils';
import { JourneyColumnsTitle, DescriptionDeleteModal, FormItem } from './styles';
import { useSelector } from 'react-redux';

const ModalMoverPerfis = ({ visible, onClose, idJornada }) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [newJourneyId, setNewJourneyId] = useState(null);
  const [options, setOptions] = useState([]);

  const atendimentos = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('atendimentos').toArray());
  const loadingDeleteAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingDeleteAtendimento'));

  useEffect(() => {
    setOptions(atendimentos.filter((atendimento) => atendimento.id !== idJornada).map((atendimento) => ({ value: atendimento.id, label: atendimento.description })));
  }, []);

  const confirmDelete = () => {
    onClose(newJourneyId);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title='Mover perfis'
      onOk={confirmDelete}
      okText='Mover e excluir'
      okButtonProps={{ loading: loadingDeleteAtendimento }}
      cancelButtonProps={{ disabled: loadingDeleteAtendimento }}
    >
      <JourneyColumnsTitle>Você tem perfis vinculados a jornada</JourneyColumnsTitle>
      <DescriptionDeleteModal>Para excluir uma jornada você precisa mover os perfis vinculados para outra jornada</DescriptionDeleteModal>
      <Form layout='vertical'>
        <FormItem selectOpen={openSelect} dropdownHeight={dropdownHeight} name='jornadaGroup' rules={[{ required: true, message: 'Informe a jornada ' }]} label={'Jornadas'}>
          <Select
            value={newJourneyId}
            onChange={(value) => setNewJourneyId(value)}
            placeholder='Selecione a jornada' 
            options={options}
            onDropdownVisibleChange={(open) => manipulationHeight(open, setOpenSelect, setDropdownHeight, options)}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ModalMoverPerfis;