import { get } from '../api';
import { formatParams } from './rules';

export const getOpenTickets = (params) => {
  return get(`/api/dash/tickets/openTicketsDash?${formatParams(params)}`);
};

export const getTicketsByStatus = (params) => {
  return get(`/api/dash/tickets/ticketsByStatusDash?${formatParams(params)}`);
};

export const getConclusionOnTime = (params) => {
  return get(`/api/dash/tickets/conclusionOnTimeDash?${formatParams(params)}`);
};

export const getClassificationInformation = (params) => {
  return get(`/api/dash/tickets/classificationInformationDash?${formatParams(params)}`);
};

export const getCategoryRanking = (params, loaders) => {
  return get(`/api/dash/tickets/categoryRankingDash?${formatParams({ ...params, ...loaders })}`);
};

export const getSatisfactionIndex = (params) => {
  return get(`/api/dash/tickets/satisfactionIndexDash?${formatParams(params)}`);
};

export const getSatisfactionIndexSummary = (params) => {
  return get(`/api/dash/tickets/satisfactionIndexSummary?${formatParams(params)}`);
};

export const getSatisfactionIndexTickets = (params, loaders) => {
  return get(`/api/dash/tickets/satisfactionIndexTickets?${formatParams({ ...params,...loaders })}`);
};

export const getExcelData = (params)=> {
  return get(`/api/dash/tickets/exportToExcelTicketDash?${formatParams(params)}`);
};

export const getCompaniesRanking = (params, loaders) => {
  return get(`/api/dash/tickets/companyRankingDash?${formatParams({ ...params, ...loaders })}`);
};