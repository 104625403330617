import { get } from '../api';
import { formatParams } from './rules';
import { InfoUsuario } from '@utils';

export const getAgendaManagementUsers = (params) => {
  return get(`/api/agendaManagement/users?userId=${InfoUsuario.get('cdUsuario')}&${formatParams(params)}`);
};

export const getAgendaManagementSummary = (params, idUser) => {
  return get(`/api/agendaManagement/summary?userId=${idUser || InfoUsuario.get('cdUsuario')}&summarizeChildren=${!idUser}&${formatParams(params)}`);
};

export const getUserActivityTypeGroupsSummary = (userId, params) => {
  return get(`/api/agendaManagement/${userId}/activityTypeGroups?${formatParams(params)}`);
};
