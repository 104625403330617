import styled from 'styled-components';
import { Text, View, Image } from '@components';
import { Icon, Row, StyledProgress } from '@components-teammove';

import PrintProvider, { Print } from 'react-easy-print';

export const PrintableTable = styled.table`
  font-size: 22px;
  width: 100%;

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  thead > tr > th {
    font-weight: 600;
    font-size: 22px;
    background-color: #F2F2F2;
  }
  
  tr:nth-child(2n) td {
    background-color: #F2F2F2;
  }

  th, td {
    width: 12%;
  }

  th:first-child,
  td:first-child {
    width: 44%;
    padding-left: 40px;
  }

  page-break-after:auto;
  tr    { page-break-inside:avoid; page-break-after:auto; }
  td    { page-break-inside:avoid; page-break-after:auto; }
  div   { page-break-inside:avoid; }
  thead { display:table-row-group; }
  tfoot { display:table-footer-group; }
`;

export const ColoredText = styled(Text)`
  color: #2B2839 !important;
  font-size: 18px;

  @media print {
    display: inline;
    font-size: 22px;
  }
`;

export const UsagePercent = styled(ColoredText)`
  display: block;
`;

export const GreetingSubtitle = styled(ColoredText)`
  @media print {
    font-size: 36px;
  }
`;

export const AbsoluteSubtitle = styled(GreetingSubtitle)`
  @media print {
    position: relative;
    left: 0;
    transform: none;
    max-width: 60%;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

export const AbsoluteText = styled(ColoredText)`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);

  @media print {
    bottom: 0;
    width: 100%;
  }
`;

export const CenteredView = styled(View)`
  align-items: center;
  position: relative;
`;

export const CheckIcon = styled(Icon.MDI)`
  color: #70BF73;
`;

export const CloseIcon = styled(Icon.MDI)`
  color: #F04D4D;
`;

export const Container = styled(View)`
  @media print {
    margin: 0 20px;
    display: block;
  }
`;

export const DayHeader = styled(View)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background: #2B2839;
  border-radius: 14px;

  * {
    color: #FFFFFF;
    font-size: 32px;

    @media print {
      font-size: 22px;
    }
  }
`;

export const PrintableProgress = styled(StyledProgress)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
`;

export const DayProgress = styled(PrintableProgress)`
  @media print {
    width: 60px;
    height: 50px;

    .ant-progress-text {
      font-size: 1.25em;
    }
  }
`;

export const DayView = styled(View)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background: #F2F2F2;
  border-radius: 14px;
  text-align: center;
  flex: 1;
  height: fit-content;
  position: relative;
`;

export const FlexFillRow = styled(Row)`
  flex: 1;

  @media print {
    display: inline-block;
    width: calc(100% - (35% + 40px));
  }
`;

export const Greeting = styled(ColoredText)`
  font-size: 98px;
  font-weight: 1000;

  @media print {
    font-size: 60px;
  }
`;

export const Header = styled(Row)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background: #2B2839;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
`;

export const HeaderText = styled(Text)`
  color: #FFFFFF !important;

  @media print {
    font-size: 20px;
  }
`;

export const JustifyCenterRow = styled(FlexFillRow)`
  justify-content: center;

  @media print {
    text-align: center;
    width: 20%;
  }
`;

export const LineProgress = styled(PrintableProgress)`
  .ant-progress-text {
    font-size: 2em;
  }
`;

export const LogoImage = styled(Image)`
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  object-fit: contain;
  user-select: none;
  max-height: 84px;

  ${({ isOurLogo }) => isOurLogo && `
    width: 200px;
    height: 30px;
  `}
`;

export const MarginfulProgress = styled(PrintableProgress)`
  margin: 1rem 2rem 0;
  height: 210px;

  @media print {
    margin: 1rem 1rem 1rem;
    height: 105px;

    .ant-progress-text {
      font-size: 2em;
    }
  }
`;  

export const PaddingLeft = styled(View)`
  padding: 39px;
  max-width: 52.5%;

  @media print {
    max-width: 80%;
  }
`;

export const PrintContainer = styled(Print)`
  * {
    -webkit-print-color-adjust: exact !important;
  }
`;

export const PrintProviderContainer = styled(PrintProvider)`
  .ant-layout-sider {
    display: none;
  }
`;

export const PrintableContainer = styled(View)`
  @media print {
    position: sticky;
    top: 0;
    left: 0;
    width: fit-content;
    z-index: 1000;

    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
`;

export const ProfileHeader = styled(View)`
  position: relative;
  z-index: 2;
  page-break-inside:avoid; 
  page-break-before:auto;

  @media print {
    margin: 45px 0 5px;
    top: 10px;
    display: block;
  }
`;

export const ProfileTitle = styled(GreetingSubtitle)`
  color: #433E59 !important;
`;

export const ProfileUsersTableTitle = styled(Row)`
  width: 100%;

  >*:first-child {
    width: 40%;
    display: flex;
    align-self: center;
    position: relative;
    top: 10px;
  }

  @media print {
    >*:first-child {
      display: inline-block;
      width: calc(35% + 40px);
    }

    position: relative;
    top: 0;
    display: block;
    width: 100%;
  }
`;

export const RowWithGap = styled(Row)`
  @media print {
    gap: 5px;
  }
`;

export const TinyMarginBottom = styled(View)`
  margin-bottom: .5rem;
`; 