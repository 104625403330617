import styled from 'styled-components';
import { Form } from '@components-teammove';

export const InputStyle = styled.input`
  width: 100%;
  background-color: transparent;
  border: none; /* Remove as bordas */
  outline: none; /* Remove o contorno quando selecionado */
  
  &:focus {
    outline: none; /* Remove o contorno quando selecionado */
  }
  &.ant-form-item-control-input {
    min-height: 27.6 px;  
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const TdStyle = styled.td`
  border-bottom: ${({ lastColumn, theme })=> lastColumn ? `2px solid ${theme.darkPrimaryColor}` : '' };
  border-right: ${({ lastColumn, theme })=> lastColumn ? `2px solid ${theme.darkPrimaryColor}` : '' };
  background-color: ${({ cellWithError, cellChanged })=> cellChanged ? 'rgba(255, 204, 0, 0.3) !important' : cellWithError ? 'rgba(255, 59, 48, 0.3) !important'  : ''};
  cursos: text !important;
`;

export const DivStyle = styled.div`
  min-height: 32px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 1px 2px;
`;
