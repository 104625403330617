import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
`;

export const UnreadIndicator = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${({ theme }) => theme.actionColor};
  border-radius: 50%;
  flex: none;
`;