import styled from 'styled-components';
import { Column, Empty } from '@components-teammove';

export const Container = styled(Column)`
  background-color: ${({ theme })=> theme.darkPrimaryColor};
  border-radius: 18px;
  padding: 20px 20px 17px 20px;
`;

export const CardSummary = styled(Column)`
  border-radius: 18px;
  padding: 20px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  width: 100%;
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
