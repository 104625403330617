import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getTipos as getTiposSdk, getTipo as getTipoSdk, postTipo as postTipoSdk, updateTipo as updateTipoSdk } from '@sdk/TiposCampanhas';

//Action Types
export const Types = {
  GET_TIPOS: 'CONFIGURACOES_TIPOS/GET_TIPOS',
  GET_TIPOS_SUCCESS: 'CONFIGURACOES_TIPOS/GET_TIPOS_SUCCESS',
  GET_TIPOS_ERROR: 'CONFIGURACOES_TIPOS/GET_TIPOS_ERROR',

  SAVE_TIPO: 'CONFIGURACOES_TIPOS/SAVE_TIPO',
  SAVE_TIPO_SUCCESS: 'CONFIGURACOES_TIPOS/SAVE_TIPO_SUCCESS',
  SAVE_TIPO_ERROR: 'CONFIGURACOES_TIPOS/SAVE_TIPO_ERROR',

  GET_TIPO: 'CONFIGURACOES_TIPOS/GET_TIPO',
  GET_TIPO_SUCCESS: 'CONFIGURACOES_TIPOS/GET_TIPO_SUCCESS',
  GET_TIPO_ERROR: 'CONFIGURACOES_TIPOS/GET_TIPO_ERROR',

  RESET: 'CONFIGURACOES_TIPOS/RESET',
  RESET_SUCCESS_SAVE: 'CONFIGURACOES_TIPOS/RESET_SUCCESS_SAVE',
};

//Action Creators
export const getTipos = () => ({ type: Types.GET_TIPOS });
export const getTiposSuccess = (tipos) => ({ type: Types.GET_TIPOS_SUCCESS, tipos });
export const getTiposError = (error) => ({ type: Types.GET_TIPOS_ERROR, error }); 

export const saveTipo = (tipo) => ({ type: Types.SAVE_TIPO, tipo });
export const saveTipoSuccess = (tipo) => ({ type: Types.SAVE_TIPO_SUCCESS, tipo });
export const saveTipoError = (error) => ({ type: Types.SAVE_TIPO_ERROR, error }); 

export const getTipo = (id) => ({ type: Types.GET_TIPO, id });
export const getTipoSuccess = (tipo) => ({ type: Types.GET_TIPO_SUCCESS, tipo });
export const getTipoError = (error) => ({ type: Types.GET_TIPO_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccessSave = () => ({ type: Types.RESET_SUCCESS_SAVE });

//saga
function* fetchGetTipos(action) {
  try {
    const tipos = yield call(getTiposSdk);
    yield put(getTiposSuccess(tipos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTiposError(err));
  }
}

function* fetchGetTipo(action) {
  try {
    const { id } = action;
    const tipo = yield call(getTipoSdk, id);
    yield put(getTipoSuccess(tipo));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTipoError(err));
  }
}

function* fetchSaveTipo(action) {
  try {
    let { tipo } = action;

    if (tipo.id) {
      tipo = yield call(updateTipoSdk, tipo);
    } else {
      tipo = yield call(postTipoSdk, tipo);
    }
    yield put(saveTipoSuccess(tipo));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveTipoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TIPOS, fetchGetTipos),
  takeLatest(Types.GET_TIPO, fetchGetTipo),
  takeLatest(Types.SAVE_TIPO, fetchSaveTipo),
];

// Reducer
const initialState = Map({
  tipos: List(),
  loadingTipos: false,
  successTipos: false,
  errorTipos: false,
  tipo: null,
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetTipos = (state, action) => {
  return state.set('loadingTipos', true).set('successTipos', false).set('errorTipos', false);
};

const handleGetTiposSuccess = (state, action) => {
  const { tipos } = action;

  return state.set('tipos', tipos).set('loadingTipos', false).set('successTipos', true).set('errorTipos', false);
};

const handleGetTiposError = (state, action) => {
  return state.set('loadingTipos', false).set('successTipos', false).set('errorTipos', action.error);
};

const handleGetTipo = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetTipoSuccess = (state, action) => {
  const { tipo } = action;

  return state.set('tipo', tipo).set('loading', false).set('success', true).set('error', false);
};

const handleGetTipoError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveTipo = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveTipoSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveTipoError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccessSave = (state, action) => {
  return state.set('successSave', initialState.get('successSave'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TIPOS:
      return handleGetTipos(state, action);
    case Types.GET_TIPOS_SUCCESS:
      return handleGetTiposSuccess(state, action);
    case Types.GET_TIPOS_ERROR:
      return handleGetTiposError(state, action);
    case Types.GET_TIPO:
      return handleGetTipo(state, action);
    case Types.GET_TIPO_SUCCESS:
      return handleGetTipoSuccess(state, action);
    case Types.GET_TIPO_ERROR:
      return handleGetTipoError(state, action);
    case Types.DELETE_TIPO:
    case Types.SAVE_TIPO:
      return handleSaveTipo(state, action);
    case Types.SAVE_TIPO_SUCCESS:
      return handleSaveTipoSuccess(state, action);
    case Types.SAVE_TIPO_ERROR:
      return handleSaveTipoError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SUCCESS_SAVE:
      return handleResetSuccessSave(state, action);

    default:
      return state;
  }
}
