import React, { useEffect, useState } from 'react';
import { Table, Button, Text, SearchBar, ContentTitle, Row, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { getCamposCustomizaveis, saveCamposCustomizaveis } from '@ducks/configuracoesUnidadesCamposCustomizaveis';
import { getCamposDinamicos , saveCamposDinamicos, reset } from '@ducks/configuracoesUnidadesCamposDinamicos';
import { arrayReorder, TelasNomenclaturas } from '@utils';
import { Margin } from './styles';

const CamposPersonalizados = () => {
  const [search, setSearch] = useState();

  const dispatch = useDispatch();
  const camposCustomizaveis = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('camposCustomizaveis'));
  const loadingCustomizaveis = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('loading'));
  const camposDinamicos = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('camposDinamicos'));
  const loadingDinamicos = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('loading'));

  const loadingDelete = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('successDelete'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    dispatch(getCamposCustomizaveis());
    dispatch(getCamposDinamicos());

    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (successDelete) {
      dispatch(getCamposDinamicos());
    }
  }, [successDelete]);

  const handleReorderCustomizados = (currentIndex, newIndex) => {
    const newCamposCustomizaveis = arrayReorder(camposCustomizaveis, currentIndex, newIndex)
      .map((customField, index) => ({ ...customField, order: index }));
        
    dispatch(saveCamposCustomizaveis(newCamposCustomizaveis));
  };

  const handleReorderDinamicos = (campoDinamico, newCampoDinamico) => {
    const newCamposDinamicos = arrayReorder(camposDinamicos, campoDinamico.pos, newCampoDinamico.pos)
      .map((dynamicField, index) => ({ ...dynamicField, pos: index }));
        
    dispatch(saveCamposDinamicos(newCamposDinamicos));
  };

  const columns = [
    {
      title: 'Título',
      dataIndex: 'name',
      sorter: true,
      width: '25%',
    },
    {
      title: 'Nome do Campo',
      dataIndex: 'alias',
      sorter: true,
      width: '25%',
    },
    {
      title: `Aparece nas Informações d${companyNomenclature.artigo} ${companyNomenclature.nomenclatura}`,
      dataIndex: 'spotlight',
      sorter: true,
      width: '25%',
      render: (text, campoCustomizavel) => (
        <Text>{campoCustomizavel.spotlight ? 'Sim' : 'Não'}</Text>
      )
    },
    {
      title: 'Visível',
      align: 'center',
      dataIndex: 'visible',
      sorter: true,
      width: '25%',
      render: (text, campoCustomizavel, index) => (
        <Row justify='space-between' align='center'>
          <Text>{campoCustomizavel.visible ? 'Sim' : 'Não'}</Text>
          <Button.Group>
            <Button
              type='secondary' 
              size='smaller'
              context='list'
              icon={<Icon.UpOutlined/>} 
              title='Mover para cima' 
              disabled={index === 0} 
              onClick={() => handleReorderCustomizados(index, index - 1)}
            />
            <Button
              type='secondary' 
              size='smaller'
              context='list'
              icon={<Icon.DownOutlined/>} 
              title='Mover para baixo' 
              disabled={index === camposCustomizaveis.length - 1} 
              onClick={() => handleReorderCustomizados(index, index + 1)}
            />
          </Button.Group>
        </Row>
      )
    },
  ];

  const dynamicColumns = [
    {
      title: 'Título',
      dataIndex: 'title',
      sorter: true,
    },
    {
      title: 'Nome do Campo',
      dataIndex: 'alias',
      sorter: true,
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      sorter: true,
      render: (text, { kind }) => (
        <Text>{kind === 'IMAGE' ? 'Imagem' : 'Texto'}</Text>
      )
    },
    {
      title: `Aparece nos Detalhes d${companyNomenclature.artigo} ${companyNomenclature.nomenclatura}`,
      dataIndex: 'previewDetail',
      width: '20%',
      sorter: true,
      render: (text, campoDinamico, index) => (
        <Row justify='space-between' align='center'>
          <Text>{campoDinamico.previewDetail ? 'Sim' : 'Não'}</Text>
          <Button.Group>
            <Button
              type='secondary' 
              size='smaller'
              context='list'
              icon={<Icon.UpOutlined/>} 
              title='Mover para cima' 
              disabled={index === 0} 
              onClick={() => handleReorderDinamicos(campoDinamico, { ...campoDinamico, pos: campoDinamico.pos - 1 })}
            />
            <Button
              type='secondary' 
              size='smaller'
              context='list'
              icon={<Icon.DownOutlined/>} 
              title='Mover para baixo' 
              disabled={index === camposDinamicos.filter(({ active }) => active).length - 1} 
              onClick={() => handleReorderDinamicos(campoDinamico, { ...campoDinamico, pos: campoDinamico.pos + 1 })}
            />
          </Button.Group>
        </Row>
      )
    },
  ];

  const handleSearch = ({ text }) => {
    setSearch(text);
  };

  const filterCustomizaveis = () => {
    if (!search) return camposCustomizaveis;
    return camposCustomizaveis.filter(({ name }) => name.trim().toLowerCase().includes(search.trim().toLowerCase()));
  };

  const filterDinamicos = () => {
    if (!search) return camposDinamicos;
    return camposDinamicos.filter(({ title, alias }) => title.trim().toLowerCase().includes(search.trim().toLowerCase()) || alias.trim().toLowerCase().includes(search.trim().toLowerCase()));
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar'/>
      <ContentTitle>Campos Customizáveis</ContentTitle>
      <Table columns={columns} dataSource={filterCustomizaveis()} loading={loadingCustomizaveis || loadingDelete} rowKey='field' status='ativo' />
      <Margin/>
      <ContentTitle>Campos Dinâmicos</ContentTitle>
      <Table columns={dynamicColumns} dataSource={filterDinamicos()} loading={loadingDinamicos || loadingDelete} rowKey='id' status='ativo' />
    </>
  );
};

export default CamposPersonalizados;