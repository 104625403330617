export const checkBlockByListProduct = (listProduct) => {
  let block = false;
  for (let indexList = 0; indexList < listProduct.length; indexList++) {
    const list = listProduct[indexList];
    for (let indexItem = 0; indexItem < list.itens.length; indexItem++) {
      const item = list.itens[indexItem];
      if (!item.itemUsuario.precoEspecial && item.itemUsuario.valor === 0) {
        block = true;
        break;
      }
    }
    if (block) break;
  }
  return block;
};