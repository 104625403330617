import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { InfoUsuario } from '@utils';
import Menu from './Menu';

const LayoutSider = styled(Layout.Sider)`
  background: ${({ theme }) => theme.darkSecondaryColor} !important;

  div {
    background: ${({ theme }) => theme.darkSecondaryColor} !important;
  }

  ul.ant-menu {
    background: ${({ theme }) => theme.darkSecondaryColor} !important;
  }

  * {
    color: ${({ theme }) => theme.textColor} !important;
  }

  ul.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: ${({ theme }) => theme.backgroundColor} !important;
  }

  i.ant-menu-submenu-arrow::before, i.ant-menu-submenu-arrow::after {
    background-color: ${({ theme }) => theme.textColor} !important;
  }
`;

const Sider = ({ siderProps, infoUsuario, routes }) => {
  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') === 'true');
  const [broken, setBroken] = useState(false);

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapsed', collapsed);
  };

  const onBreakpoint = (broken) => {
    setBroken(broken);
    if (broken) handleCollapse(true);
  };

  const onCollapse = (collapsed, type) => {
    if (type === 'clickTrigger') {
      handleCollapse(collapsed);
    }
  };

  let siderWidth = 0;
  let zeroWidthTriggerStyle = { display: 'none' };
  if (InfoUsuario.hasUser(infoUsuario)) {
    siderWidth = (siderProps) ? siderProps.width : 200;
    zeroWidthTriggerStyle = null;
  }

  return (
    <LayoutSider
      collapsed={collapsed}
      defaultCollapsed={false}
      collapsible
      breakpoint="lg"
      collapsedWidth={(broken) ? '0' : '80'}
      zeroWidthTriggerStyle={zeroWidthTriggerStyle}
      width={siderWidth}
      onBreakpoint={onBreakpoint}
      onCollapse={onCollapse}
    >
      <Menu {...{ infoUsuario, collapsed, routes }} />
    </LayoutSider>
  );
};

export default Sider;
