import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDadosCadastro as getGrupos } from '@ducks/wikisCadastro';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Tabs, Table, Button, Column, Row } from '@components-teammove';
import GrupoCadastro from './Cadastro';
import { useHistory } from 'react-router-dom';

const ComunicadosGrupos = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));
  const dados = useSelector(({ wikisCadastro }) => wikisCadastro.get('dadosCadastro'));
  const loadingGrupos = useSelector(({ wikisCadastro }) => wikisCadastro.get('loadingDadosCadastro'));
  const successSave = useSelector(({ wikisCadastro }) => wikisCadastro.get('successPutGrupo'));

  const [visibleRegister, setVisibleRegister] = useState(false);

  useEffect(() => {
    dispatch(getGrupos());
  }, [successSave]);

  const handleEditClick = (grupo) => {
    history.push('?grupo=' + grupo.id, grupo );
    setVisibleRegister(true);
  };

  const columns = [
    {
      title: 'Grupo',
      dataIndex: 'titulo',
      sorter: true,
      render: (text, grupo) => (
        <Row justify='space-between' align='center'>
          {text}
          <Button type='secondary' context='list' size='small' onClick={() => handleEditClick(grupo)}>
            Editar
          </Button>
        </Row>
      ),
    },
  ];

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Grupos'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/comunicados', name: 'Configurações de Comunicados', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Grupos' }]} current='Grupos'/>
            <Table
              columns={columns}
              dataSource={dados ? dados.grupos : null}
              loading={loadingGrupos}
              rowKey="id"
              status="ativo"
            />
          </Column>
        </PageHeader>

        {visibleRegister && (
          <GrupoCadastro
            visible={visibleRegister}
            onCancel={() => setVisibleRegister(false)}
          />
        )}
      </Body>
    </App>
  );
};

export default ComunicadosGrupos;