import React from 'react';
import { JustifyCenterView, Row } from '../styles';
import { AnswerButtonContainer, ButtonPendency, CoverAnswerPending, LabelAnswerPending, MessagePendingText } from './styles';
import { InfoUsuario } from '@utils';
import { Icon } from '@components-teammove';

export default function MessageAnswerPending({ handleOpenAguardandoResposta, ...props }) {
  return(
    <Row>
      <CoverAnswerPending src='https://res.cloudinary.com/teammove/image/upload/v1637581264/New/Chamados/cover-answer-pending.svg' />
      <JustifyCenterView>
        <MessagePendingText>Olá {InfoUsuario.get('nmUsuario')},</MessagePendingText>
        <LabelAnswerPending>Estamos aguardando seu retorno!</LabelAnswerPending>
        <MessagePendingText>O prazo para atendimento da sua solicitação está pausado até você responder esta pendência.</MessagePendingText>
        <AnswerButtonContainer>
          <ButtonPendency type='secondary' icon={<Icon.WarningOutlined />} onClick={handleOpenAguardandoResposta}>Responder pendência</ButtonPendency>
        </AnswerButtonContainer>
      </JustifyCenterView>
    </Row>
  );
}