import { getPerfis } from '@sdk/Perfil';

const filters = [
  {
    type: 'SELECT',
    name: 'perfil',
    label: 'Perfil',
    placeholder: 'Selecione os perfis',
    action: getPerfis
  }
];

const getDate = (dateStr) => {
  const date = dateStr.substring(0, 10).split('-');
  return new Date(date[0], date[1] - 1, date[2]);
};

const getColor = (filter) => {
  switch (filter) {
    case 'TODOS':
      return '#409D93';
    case 'WEB':
      return '#1976d2';
    case 'APP':
      return '#f57c00';
    default:
  }
};

const getColorArray = (filter) => {
  switch (filter) {
    case 'TODOS':
      return ['#b2dfdb','#7ca7a1','#3b6259', '#00251a'];
    case 'WEB':
      //return ['#bbdefb', '#002171'];
      return ['#81C2FF', '#1890FF', '#1271C8', '#003C8C'];
    case 'APP':
      return ['#ffe0b2', '#e39d76', '#c75b3b', '#ac1900'];
    default:
  }
};

const getCalendarConfig = (filter, data) => {
  const response = { data: {} };
  response.data.columns = [{ type: 'date', label: 'Data' }];
  let rows = [];

  switch (filter) {
    case 'TODOS':
      rows = data.map((item) => {
        if (item.qtAcessoWeb + item.qtAcessoApp > 0) {
          return { date: getDate(item.date), value: item.qtAcessoWeb + item.qtAcessoApp };
        }
        return undefined;
      });

      response.data.rows = rows.filter((el) => el != null);
      break;
    case 'WEB':
      rows = data.map((item) => {
        if (item.qtAcessoWeb > 0) {
          return { date: getDate(item.date), value: item.qtAcessoWeb };
        }
        return undefined;
      });

      response.data.rows = rows.filter((el) => el != null);
      break;
    case 'APP':
      rows = data.map((item) => {
        if (item.qtAcessoApp > 0) {
          return { date: getDate(item.date), value: item.qtAcessoApp };
        }
        return undefined;
      });

      response.data.rows = rows.filter((el) => el != null);
      break;
    default:
      return;
  }

  return response;
};

const getCalendarParams = ({ history, link = '/gestao-equipe', filtering }) => {
  const params = { style: { width: '910px', height: '167px' } };

  if (!filtering) {
    params.style = { ...params.style, cursor: 'pointer' };
    params.chartEvents = [
      {
        eventName: 'select',
        callback: ({ chartWrapper }) => {
          const data = chartWrapper.getDataTable();
          const chart = chartWrapper.getChart();
          const selection = chart.getSelection();

          if (selection.length > 0 && selection[0].row > -1) {
            const date = data.getValue(selection[0].row, 0);
            history.push(`${link}?dia=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
          }
        },
      },
    ];
  }

  return params;
};

const typeFiltroPlataforma = { TODOS: 'TODOS', APP: 'APP', WEB: 'WEB' };
const typeFiltroDado = { ACESSOS: 'ACESSOS', USUARIOS: 'USUARIOS' };

export { filters, getCalendarConfig, getCalendarParams, getColor, getColorArray, typeFiltroPlataforma, typeFiltroDado };
