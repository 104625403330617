import styled from 'styled-components';
import { TypeAnimation } from 'react-type-animation';

export const TypeAnimationStyled = styled(TypeAnimation)`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.textColor};
    display: inline-block;
    white-space: pre-wrap;
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
`;