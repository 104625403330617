import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, TelasNomenclaturas } from '@utils';
import {
  getNomenclaturas as getNomenclaturasSdk,
  saveNomenclaturas as saveNomenclaturasSdk,
} from '@sdk/TelasNomenclaturas';

//Action Types
export const Types = {
  GET_NOMENCLATURAS: 'CONFIGURACOES_TELAS_NOMENCLATURAS/GET_NOMENCLATURAS',
  GET_NOMENCLATURAS_SUCCESS: 'CONFIGURACOES_TELAS_NOMENCLATURAS/GET_NOMENCLATURAS_SUCCESS',
  GET_NOMENCLATURAS_ERROR: 'CONFIGURACOES_TELAS_NOMENCLATURAS/GET_NOMENCLATURAS_ERROR',
  
  SAVE_NOMENCLATURAS: 'CONFIGURACOES_TELAS_NOMENCLATURAS/SAVE_NOMENCLATURAS',
  SAVE_NOMENCLATURAS_SUCCESS: 'CONFIGURACOES_TELAS_NOMENCLATURAS/SAVE_NOMENCLATURAS_SUCCESS',
  SAVE_NOMENCLATURAS_ERROR: 'CONFIGURACOES_TELAS_NOMENCLATURAS/SAVE_NOMENCLATURAS_ERROR',
};

//Action Creators
export const getNomenclaturas = () => ({ type: Types.GET_NOMENCLATURAS });
export const getNomenclaturasSuccess = (data) => ({ type: Types.GET_NOMENCLATURAS_SUCCESS, data });
export const getNomenclaturasError = (error) => ({ type: Types.GET_NOMENCLATURAS_ERROR, error });

export const saveNomenclaturas = (nomenclaturas) => ({ type: Types.SAVE_NOMENCLATURAS, nomenclaturas });
export const saveNomenclaturasSuccess = () => ({ type: Types.SAVE_NOMENCLATURAS_SUCCESS });
export const saveNomenclaturasError = (error) => ({ type: Types.SAVE_NOMENCLATURAS_ERROR, error });

//saga
function* fetchGetNomenclaturas() {
  try {
    const nomenclaturas = yield call(getNomenclaturasSdk);
    yield put(getNomenclaturasSuccess(nomenclaturas));
  } catch (err) {
    Notification.error(err.message);
    yield put(getNomenclaturasError(err));
  }
}

function* fetchSaveNomenclaturas(action) {
  try {
    const { nomenclaturas } = action;
    yield call(saveNomenclaturasSdk, nomenclaturas);
    yield put(saveNomenclaturasSuccess());
    Notification.success('Nomenclaturas salvas com sucesso!');
    TelasNomenclaturas.set(nomenclaturas);
  } catch (err) {
    Notification.error(err.message);
    yield put(saveNomenclaturasError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_NOMENCLATURAS, fetchGetNomenclaturas),
  takeLatest(Types.SAVE_NOMENCLATURAS, fetchSaveNomenclaturas),
];

// Reducer
const initialState = Map({
  nomenclaturas: List(),
  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetNomenclaturas = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetNomenclaturasSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('nomenclaturas', List(data))
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetNomenclaturasError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleSaveNomenclaturas = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handleSaveNomenclaturasSuccess = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};
  
const handleSaveNomenclaturasError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_NOMENCLATURAS: return handleGetNomenclaturas(state, action);
    case Types.GET_NOMENCLATURAS_SUCCESS: return handleGetNomenclaturasSuccess(state, action);
    case Types.GET_NOMENCLATURAS_ERROR: return handleGetNomenclaturasError(state, action);
  
    case Types.SAVE_NOMENCLATURAS: return handleSaveNomenclaturas(state, action);
    case Types.SAVE_NOMENCLATURAS_SUCCESS: return handleSaveNomenclaturasSuccess(state, action);
    case Types.SAVE_NOMENCLATURAS_ERROR: return handleSaveNomenclaturasError(state, action);
  
    default: return state;
  }
}