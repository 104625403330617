import React from 'react';
import { Collapse } from '@components';
import CollapseHeader from './CollapseHeader';
import CollapsePanel from './CollapsePanel';
import { useSelector } from 'react-redux';

export default function Lista({ data, handlers }) {
  const { usuarios } = data;
  const { handleVisualizarResultado } = handlers;

  const successListaTentativasQuiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successListaTentativasQuiz'));

  return (
    successListaTentativasQuiz && (
      <Collapse>
        {usuarios.map((usuario) => {
          return (
            <Collapse.Panel key={usuario.cdUsuario} header={<CollapseHeader data={{ usuario }} handlers={{ handleVisualizarResultado }} />}>
              <CollapsePanel data={{ usuario }} handlers={{ handleVisualizarResultado }} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    )
  );
}
