import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getAtributos as getAtributosSdk, getUsuario as getUsuarioSdk, getDuplicatedIdApi as getDuplicatedIdApiSdk } from '@sdk/Usuarios';

//Action Types
export const Types = {
  GET_ATRIBUTOS: 'CONFIGURACOES_USUARIOS/GET_ATRIBUTOS',
  GET_ATRIBUTOS_SUCCESS: 'CONFIGURACOES_USUARIOS/GET_ATRIBUTOS_SUCCESS',
  GET_ATRIBUTOS_ERROR: 'CONFIGURACOES_USUARIOS/GET_ATRIBUTOS_ERROR',

  GET_USUARIO: 'CONFIGURACOES_USUARIOS/GET_USUARIO',
  GET_USUARIO_SUCCESS: 'CONFIGURACOES_USUARIOS/GET_USUARIO_SUCCESS',
  GET_USUARIO_ERROR: 'CONFIGURACOES_USUARIOS/GET_USUARIO_ERROR',

  GET_ID_API_DUPLICADO: 'CONFIGURACOES_USUARIOS/GET_ID_API_DUPLICADO',
  GET_ID_API_DUPLICADO_SUCCESS: 'CONFIGURACOES_USUARIOS/GET_ID_API_DUPLICADO_SUCCESS',
  GET_ID_API_DUPLICADO_ERROR: 'CONFIGURACOES_USUARIOS/GET_ID_API_DUPLICADO_ERROR',

  RESET: 'CONFIGURACOES_USUARIOS/RESET',
};

//Action Creators
export const getAtributos = () => ({ type: Types.GET_ATRIBUTOS });
export const getAtributosSuccess = (atributos) => ({ type: Types.GET_ATRIBUTOS_SUCCESS, atributos });
export const getAtributosError = (error) => ({ type: Types.GET_ATRIBUTOS_ERROR, error });

export const getUsuario = ({ cdUsuario }) => ({ type: Types.GET_USUARIO, cdUsuario });
export const getUsuarioSuccess = (data) => ({ type: Types.GET_USUARIO_SUCCESS, data });
export const getUsuarioError = (error) => ({ type: Types.GET_USUARIO_ERROR, error });

export const getIdApiDuplicados = () => ({ type: Types.GET_ID_API_DUPLICADO });
export const getIdApiDuplicadosSuccess = (data) => ({ type: Types.GET_ID_API_DUPLICADO_SUCCESS, data });
export const getIdApiDuplicadosError = (error) => ({ type: Types.GET_ID_API_DUPLICADO_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetAtributos(action) {
  try {
    const atributos = yield call(getAtributosSdk);
    yield put(getAtributosSuccess(atributos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtributosError(err));
  }
}

function* fetchGetUsuario(action) {
  try {
    const { cdUsuario } = action;
    const usuario = yield call(getUsuarioSdk, { cdUsuario });
    yield put(getUsuarioSuccess(usuario));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUsuarioError(err));
  }
}

function* fetchGetIdApiDuplicados(action) {
  try {
    const idApiDuplicados = yield call(getDuplicatedIdApiSdk);
    yield put(getIdApiDuplicadosSuccess(idApiDuplicados));
  } catch(err) {
    Notification.error(err.message);
    yield put(getIdApiDuplicadosError(err));
  }
}

export const saga = [takeLatest(Types.GET_ATRIBUTOS, fetchGetAtributos), takeLatest(Types.GET_USUARIO, fetchGetUsuario), takeLatest(Types.GET_ID_API_DUPLICADO, fetchGetIdApiDuplicados)];

// Reducer
const initialState = Map({
  atributos: List(),
  loadingAtributos: false,
  successAtributos: false,
  errorAtributos: false,

  usuario: Map(),
  loadingUsuario: false,
  successUsuario: false,
  errorUsuario: false,

  idApiDuplicados: List(),
  loadingIdApiDuplicados: false,
  successIdApiDuplicados: false,
  errorIdApiDuplicados: false
});

const handleGetAtributos = (state, action) => {
  return state.set('loadingAtributos', true).set('successAtributos', false).set('errorAtributos', false);
};

const handleGetAtributosSuccess = (state, action) => {
  const { atributos } = action;

  return state.set('atributos', List(atributos)).set('loadingAtributos', false).set('successAtributos', true).set('errorAtributos', false);
};

const handleGetAtributosError = (state, action) => {
  return state.set('loadingAtributos', false).set('successAtributos', false).set('errorAtributos', action.error);
};

const handleGetUsuario = (state, action) => {
  return state.set('loadingUsuario', true).set('successUsuario', false).set('errorUsuario', false);
};

const handleGetUsuarioSuccess = (state, action) => {
  const { data } = action;

  return state.set('usuario', data).set('loadingUsuario', false).set('successUsuario', true).set('errorUsuario', false);
};

const handleGetUsuarioError = (state, action) => {
  return state.set('loadingUsuario', false).set('successUsuario', false).set('errorUsuario', action.error);
};

const handleGetIdApiDuplicado = (state, action) => {
  return state
    .set('loadingIdApiDuplicados', true)
    .set('successIdApiDuplicados', false)
    .set('errorIdApiDuplicados', false);
};

const handleGetIdApiDuplicadoSuccess = (state, action) => {
  return state
    .set('idApiDuplicados', List(action.data))
    .set('loadingIdApiDuplicados', false)
    .set('successIdApiDuplicados', true)
    .set('errorIdApiDuplicados', false);
};

const handleGetIdApiDuplicadoError = (state, action) => {
  return state
    .set('loadingIdApiDuplicados', false)
    .set('successIdApiDuplicados', false)
    .set('errorIdApiDuplicados', action.error);
};

const handleReset = (state, action) => {
  return state.set('usuario', {});
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ATRIBUTOS:
      return handleGetAtributos(state, action);
    case Types.GET_ATRIBUTOS_SUCCESS:
      return handleGetAtributosSuccess(state, action);
    case Types.GET_ATRIBUTOS_ERROR:
      return handleGetAtributosError(state, action);

    case Types.GET_USUARIO:
      return handleGetUsuario(state, action);
    case Types.GET_USUARIO_SUCCESS:
      return handleGetUsuarioSuccess(state, action);
    case Types.GET_USUARIO_ERROR:
      return handleGetUsuarioError(state, action);

    case Types.GET_ID_API_DUPLICADO:
      return handleGetIdApiDuplicado(state, action);
    case Types.GET_ID_API_DUPLICADO_SUCCESS:
      return handleGetIdApiDuplicadoSuccess(state, action);
    case Types.GET_ID_API_DUPLICADO_ERROR:
      return handleGetIdApiDuplicadoError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
