import React from 'react';
import * as AntIcons from '@ant-design/icons';
import IconMDI from '@mdi/react';
import * as IconsMDI from '@mdi/js';
import * as IconsFeather from 'react-icons/fi';
import * as IconsVsc from 'react-icons/vsc';
import * as IconsBox from 'react-icons/bi';
import * as IconsBootstrap from 'react-icons/bs';
import * as IconsRemix from 'react-icons/ri';
import * as IconsSimple from 'react-icons/si';
import * as IconsTabler from 'react-icons/tb';

const Font = AntIcons.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1608026_par1p8jg4i9.js',
});

const TypedIconsMDI = Object.entries(IconsMDI).map((item) => ({
  name: item[0].slice(3).split(/(?=[A-Z])/).join('-').toLowerCase(),
  path: item[1],
}));

const MDI = ({ type, ...otherProps }) => {
  const icon = TypedIconsMDI.find((icon) => icon.name === type);

  return (icon) ? (
    <IconMDI {...otherProps} path={icon.path} />
  ) : null;
};

const Feather = ({ type, ...otherProps }) => {
  const typeFormatted = ((type.includes('fi-') ? '' : 'fi-') + type).split('-').reduce((state, name) => state + name[0].toUpperCase() + name.substring(1), '');
  const IconFeather = IconsFeather[typeFormatted];
  return <IconFeather size='1.5rem' {...otherProps}/>;
};

const Vsc = ({ type, ...otherProps }) => {
  const Icon = IconsVsc[type];
  return <Icon size='1.5rem' {...otherProps}/>;
};

const Box = ({ type, ...otherProps }) => {
  const typeFormatted = ((type.toLowerCase().includes('bi-') ? '' : 'bi-') + type).split('-').reduce((state, name) => state + name[0].toUpperCase() + name.substring(1), '');
  const IconBox = IconsBox[typeFormatted];
  return <IconBox size='1.5rem' {...otherProps}/>;
};

const Bootstrap = ({ type, ...otherProps }) => {
  const typeFormatted = ((type.toLowerCase().includes('bs-') ? '' : 'bs-') + type).split('-').reduce((state, name) => state + name[0].toUpperCase() + name.substring(1), '');
  const IconBoostrap = IconsBootstrap[typeFormatted];
  return <IconBoostrap size='1.5rem' {...otherProps}/>;
};

const Remix = ({ type, ...otherProps }) => {
  const formattedType = (type.startsWith('ri-') ? type : 'ri-' + type).replace(/-/g, ' ').replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); }).replace(/\s/g, '');
  const IconRemix = IconsRemix[formattedType];
  return <IconRemix size='1.5rem' {...otherProps}/>;
};

const Simple = ({ type, ...otherProps }) => {
  const typeFormatted = ((type.includes('si-') ? '' : 'si-') + type).split('-').reduce((state, name) => state + name[0].toUpperCase() + name.substring(1), '');
  const IconSimple = IconsSimple[typeFormatted];
  return <IconSimple size='1.5rem' {...otherProps}/>;
};

const Tabler = ({ type, ...otherProps }) => {
  const typeFormatted = ((type.includes('tb-') ? '' : 'tb-') + type).split('-').reduce((state, name) => state + name[0].toUpperCase() + name.substring(1), '');
  const IconTabler = IconsTabler[typeFormatted];
  return <IconTabler size='1.5rem' {...otherProps}/>;
};

const TeamMove = ({ type, ...otherProps }) => {
  return type === 'cluster-outlined' && (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
      <g clipPath="url(#clip0_768_1893)">
        <circle cx="12" cy="21" r="2" stroke="#F5F5F5" strokeWidth="2"/>
        <circle cx="21" cy="21" r="2" stroke="#F5F5F5" strokeWidth="2"/>
        <circle cx="3" cy="21" r="2" stroke="#F5F5F5" strokeWidth="2"/>
        <circle cx="12" cy="3" r="2" stroke="#F5F5F5" strokeWidth="2"/>
        <path d="M12 4.5V11.5M12 20V11.5M12 11.5H19C20.1046 11.5 21 12.3954 21 13.5V20M12 11.5H5C3.89543 11.5 3 12.3954 3 13.5V20" stroke="#F5F5F5" strokeWidth="2"/>
      </g>
      <defs>
        <clipPath id="clip0_768_1893">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

MDI.defaultProps = {
  size: 1,
};

export default {
  ...AntIcons,
  Font,
  MDI,
  Feather,
  Vsc,
  Box,
  Bootstrap,
  Remix,
  Simple,
  Tabler,
  TeamMove
};
