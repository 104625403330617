import readXlsxFile from 'read-excel-file';

export const getObjectFromFile = async (file) => {
  const rows = await readXlsxFile(file);
  
  const CAMPAIGN = 'campanha';
  const GOAL = 'meta';
  const VALUE = 'realizado';
  
  let campaignValues = [];
  const headers = [];
  for (let indexRow = 0; indexRow < rows.length; indexRow++) {
    const columns = rows[indexRow];
    if (indexRow === 0) {
      for (const headerColumns of columns) {
        headers.push(headerColumns.toLowerCase());
      }
    } else {
      let indexCampaign = -1;
      let indexRoleCampaign = -1;
      //let indexRoleCampaignGoal = -1;
      
      let campaignName = null;
      let valueExcelDto = { customId: null, roles: [], children: [] };
      let userGoal = null;
      let userValue = null;
      let userName = null;

      for (let indexColumn = 0; indexColumn < columns.length; indexColumn++) {
        const valueColumn = columns[indexColumn];
        const nameHeaderColumn = headers[indexColumn];
        switch(nameHeaderColumn) {
          case CAMPAIGN:
            campaignName = valueColumn;
            indexCampaign = campaignValues.findIndex((item) => item.customId === valueColumn);
            if (indexCampaign > -1) {
              valueExcelDto = campaignValues[indexCampaign];
            } else {
              valueExcelDto.customId = valueColumn;
            }
            break;
          case GOAL:
            userGoal = Math.floor(valueColumn * 100) / 100;
            break;
          case VALUE:
            userValue = Math.floor(valueColumn * 100) / 100;
            break;
          default:
            userName = valueColumn.toString();
            if (userGoal <= 0) {
              throw Error(`Não existe uma meta para ${campaignName} | Agrupador: ${userName}`);
            }
            // eslint-disable-next-line no-loop-func
            //indexRoleCampaignGoal = valueExcelDto.roles.findIndex((item) => item.name === nameHeaderColumn && item.userName === userName && item.userGoal === userGoal && item.userValue === userValue);
            //if (indexRoleCampaignGoal > -1) {
            //throw Error(`Existe um valor duplicado para ${campaignName}! Agrupador: ${nameHeaderColumn} | Usuário: ${userName} | Meta: ${userGoal} | Valor: ${userValue}`);
            //}
            // eslint-disable-next-line no-loop-func
            indexRoleCampaign = valueExcelDto.roles.findIndex((item) => item.name === nameHeaderColumn && item.userName === userName);
            if (indexRoleCampaign < 0) {
              valueExcelDto.roles.push({ name: nameHeaderColumn, userName, userValue, userGoal });
            } else {
              valueExcelDto.roles[indexRoleCampaign].userValue += userValue;
              valueExcelDto.roles[indexRoleCampaign].userGoal += userGoal;
            }
            break;
        }
      }

      if (indexCampaign < 0) {
        campaignValues.push(valueExcelDto);
      } else {
        campaignValues[indexCampaign] = valueExcelDto;
      }
    }
  }
  return campaignValues;
};