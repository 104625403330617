import React, { useEffect, useState } from 'react';
import { Collapse, View } from '@components';
import Aulas from './Aulas';
import styled from 'styled-components';
import { getLastSelectedAula, selectAula, handleEndAula } from '@ducks/wikisEAD';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import ProgressBar from '../../ProgressBar';
import { formatDecimal } from '@utils/';

const ViewHeader = styled(View)`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ViewHeaderDetail = styled(View)`
  font-size: 14px;
  font-weight: normal;
`;

const CollapseStyled = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 0;
  }
`;

const ViewPrAula = styled(View)`
  line-height: 14px;
  font-size: 12px;
`;

export default function Materias({ data }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [keyCollapse, setKeyCollapse] = useState([]);

  const currentAula = useSelector(({ wikisEAD }) => wikisEAD.get('currentAula'));
  const currentAulaInicio = useSelector(({ wikisEAD }) => wikisEAD.get('currentAulaInicio'));

  const handleChangeCollapse = (key) => {
    setKeyCollapse(key);
  };

  useEffect(() => {
    if (!currentAula.get('materiaId')) return;
    handleChangeCollapse([...keyCollapse, currentAula.get('materiaId')]);
  }, [currentAula]);

  const onUnload = (e) => {
    e.preventDefault();
    const eadCurrentAula = localStorage.getItem('ead-currentAula');
    if (eadCurrentAula) {
      handleEndAula({
        currentAula: Map(JSON.parse(eadCurrentAula)),
        currentAulaInicio: new Date(localStorage.getItem('ead-currentAulaInicio')),
      });
    }
  };

  useEffect(() => {
    if (currentAulaInicio === null) return;
    localStorage.setItem('ead-currentAulaInicio', currentAulaInicio);
  }, [currentAulaInicio]);

  useEffect(() => {
    if (currentAula === null) return;
    localStorage.setItem('ead-currentAula', JSON.stringify(currentAula));
  }, [currentAula]);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return () => {
      const eadCurrentAula = localStorage.getItem('ead-currentAula');
      if (eadCurrentAula) {
        handleEndAula({
          currentAula: Map(JSON.parse(eadCurrentAula)),
          currentAulaInicio: new Date(localStorage.getItem('ead-currentAulaInicio')),
        });
      }
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [id]);

  const handleSelection = ({ aula, materiaId, materiaTitulo }) => {
    dispatch(selectAula({ aula, cursoId: id, materiaId, materiaTitulo }));
  };

  const handleLastSelectedAula = () => {
    if (data.length === 0) return;

    const lastAulaInfo = JSON.parse(getLastSelectedAula({ cursoId: id }));

    const defaultSelection = { aula: data[0].aulas[0], materiaTitulo: data[0].titulo, materiaId: data[0].id };

    if (lastAulaInfo && lastAulaInfo !== null) {
      const lastMateria = data.filter((materia) => materia.id === lastAulaInfo.materiaId)[0];
      if (lastMateria) {
        const lastAula = lastMateria.aulas.filter((aula) => aula.id === lastAulaInfo.aulaId)[0];
        if (lastAula) {
          handleSelection({ aula: lastAula, materiaTitulo: lastMateria.titulo, materiaId: lastMateria.id });
          handleChangeCollapse([...keyCollapse, lastMateria.id]);
        } else {
          handleSelection(defaultSelection);
          handleChangeCollapse([...keyCollapse, data[0].id]);
        }
      } else {
        handleSelection(defaultSelection);
        handleChangeCollapse([...keyCollapse, data[0].id]);
      }
    } else {
      handleSelection(defaultSelection);
      handleChangeCollapse([...keyCollapse, data[0].id]);
    }
  };

  useEffect(() => {
    handleLastSelectedAula();
  }, []);

  const getPrAula = (materia) => (materia.aulas.filter((aula) => aula.lido).length / materia.aulas.length) * 100;

  const getHeader = (row) => (
    <ViewHeader>
      {row.titulo}
      <ViewHeaderDetail>
        <ProgressBar pr={getPrAula(row)} />

        <ViewPrAula alignItems='flex-end'>{formatDecimal(getPrAula(row), 2)}%</ViewPrAula>
      </ViewHeaderDetail>
    </ViewHeader>
  );

  const getPanels = () => {
    return data.map((row) => {
      return (
        <Collapse.Panel header={getHeader(row)} key={row.id}>
          <Aulas data={row} handleSelection={handleSelection} />
        </Collapse.Panel>
      );
    });
  };

  return (
    <CollapseStyled expandIconPosition='right' onChange={(key) => handleChangeCollapse(key)} activeKey={keyCollapse}>
      {getPanels()}
    </CollapseStyled>
  );
}
