import React from 'react';
import { Avatar, Row, Column } from '@components-teammove';
import { Container, Separator, UserNameText, UserProfileText } from './styles';

const UserProfile = ({ src, name, profile, profileColor, darkened }) => (
  <Container darkened={darkened}>
    <Row gap='24px'>
      <Row gap='12px'>
        <Avatar src={src}/>
        <Separator bgColor={profileColor}/>
      </Row>
      <Column>
        <UserNameText>{name}</UserNameText>
        <UserProfileText>{profile}</UserProfileText>
      </Column>
    </Row>
  </Container>
);

export default UserProfile;