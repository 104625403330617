import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Filtros, Notification } from '@utils';
import {
  getAcessoDiario as getAcessoDiarioSdk,
  getAcessosUltimos30Dias as getAcessosUltimos30DiasSdk,
  getAcessosUsuario as getAcessosUsuarioSdk,
  getAcessosPerfil as getAcessosPerfilSdk,
} from '@sdk/GestaoEquipe';

export const FILTERS_KEY = 'GESTAO_EQUIPE';

//Action Types
export const Types = {
  GET_ACESSO_DIARIO: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSO_DIARIO',
  GET_ACESSO_DIARIO_SUCCESS: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSO_DIARIO_SUCCESS',
  GET_ACESSO_DIARIO_ERROR: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSO_DIARIO_ERROR',

  GET_ACESSOS_ULTIMOS_30_DIAS: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_ULTIMOS_30_DIAS',
  GET_ACESSOS_ULTIMOS_30_DIAS_SUCCESS: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_ULTIMOS_30_DIAS_SUCCESS',
  GET_ACESSOS_ULTIMOS_30_DIAS_ERROR: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_ULTIMOS_30_DIAS_ERROR',

  GET_ACESSOS_USUARIO: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_USUARIO',
  GET_ACESSOS_USUARIO_SUCCESS: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_USUARIO_SUCCESS',
  GET_ACESSOS_USUARIO_ERROR: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_USUARIO_ERROR',

  GET_ACESSOS_PERFIL: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_PERFIL',
  GET_ACESSOS_PERFIL_SUCCESS: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_PERFIL_SUCCESS',
  GET_ACESSOS_PERFIL_ERROR: 'CONFIGURACOES_GESTAO_EQUIPE/GET_ACESSOS_PERFIL_ERROR',

  RESET: 'CONFIGURACOES_GESTAO_EQUIPE/RESET',
};

//Action Creators
export const getAcessoDiario = ({ filtros }) => ({ type: Types.GET_ACESSO_DIARIO, filtros });
export const getAcessoDiarioSuccess = (data) => ({ type: Types.GET_ACESSO_DIARIO_SUCCESS, data });
export const getAcessoDiarioError = (error) => ({ type: Types.GET_ACESSO_DIARIO_ERROR, error });

export const getAcessosUltimos30Dias = ({ filtros }) => ({ type: Types.GET_ACESSOS_ULTIMOS_30_DIAS, filtros });
export const getAcessosUltimos30DiasSuccess = (data) => ({ type: Types.GET_ACESSOS_ULTIMOS_30_DIAS_SUCCESS, data });
export const getAcessosUltimos30DiasError = (error) => ({ type: Types.GET_ACESSOS_ULTIMOS_30_DIAS_ERROR, error });

export const getAcessosUsuario = ({ page = 1, filtros }) => ({ type: Types.GET_ACESSOS_USUARIO, page, filtros });
export const getAcessosUsuarioSuccess = (data) => ({ type: Types.GET_ACESSOS_USUARIO_SUCCESS, data });
export const getAcessosUsuarioError = (error) => ({ type: Types.GET_ACESSOS_USUARIO_ERROR, error });

export const getAcessosPerfil = ({ filtros }) => ({ type: Types.GET_ACESSOS_PERFIL, filtros });
export const getAcessosPerfilSuccess = (data) => ({ type: Types.GET_ACESSOS_PERFIL_SUCCESS, data });
export const getAcessosPerfilError = (error) => ({ type: Types.GET_ACESSOS_PERFIL_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetAcessoDiario(action) {
  try {
    const { filtros } = action; // eslint-disable-next-line
    const { usuario, ...filtroStorage } = filtros;
    Filtros.set(FILTERS_KEY, filtroStorage);
    const acessos = yield call(getAcessoDiarioSdk, filtros);
    yield put(getAcessoDiarioSuccess(acessos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAcessoDiarioError(err));
  }
}

function* fetchGetAcessosUltimos30Dias(action) {
  try {
    const { filtros } = action; // eslint-disable-next-line
    const { usuario, ...filtroStorage } = filtros;
    Filtros.set(FILTERS_KEY, filtroStorage);
    const acessos = yield call(getAcessosUltimos30DiasSdk, filtros);
    yield put(getAcessosUltimos30DiasSuccess(acessos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAcessosUltimos30DiasError(err));
  }
}

function* fetchGetAcessosPerfil(action) {
  try {
    const { filtros } = action; // eslint-disable-next-line
    const { usuario, ...filtroStorage } = filtros;
    Filtros.set(FILTERS_KEY, filtroStorage);
    const acessos = yield call(getAcessosPerfilSdk, filtros);
    yield put(getAcessosPerfilSuccess(acessos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAcessosPerfilError(err));
  }
}

function* fetchGetAcessosUsuario(action) {
  try {
    const { page, filtros } = action; // eslint-disable-next-line
    const { usuario, ...filtroStorage } = filtros;
    Filtros.set(FILTERS_KEY, filtroStorage);
    const acessos = yield call(getAcessosUsuarioSdk, page, filtros);
    yield put(getAcessosUsuarioSuccess(acessos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAcessosUsuarioError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ACESSO_DIARIO, fetchGetAcessoDiario),
  takeLatest(Types.GET_ACESSOS_ULTIMOS_30_DIAS, fetchGetAcessosUltimos30Dias),
  takeLatest(Types.GET_ACESSOS_USUARIO, fetchGetAcessosUsuario),
  takeLatest(Types.GET_ACESSOS_PERFIL, fetchGetAcessosPerfil),
];

// Reducer
const initialState = Map({
  filtros: Filtros.get(FILTERS_KEY),

  acessoDiario: List(),
  loadingAcessoDiario: false,
  successAcessoDiario: false,
  errorAcessoDiario: false,

  acessosUltimos30Dias: List(),
  loadingAcessosUltimos30Dias: false,
  successAcessosUltimos30Dias: false,
  errorAcessosUltimos30Dias: false,

  acessosUsuario: List(),
  loadingAcessosUsuario: false,
  successAcessosUsuario: false,
  errorAcessosUsuario: false,

  xCurrentPageAcessosUsuario: 0,
  xTotalPageAcessosUsuario: 0,
  hasMoreAcessosUsuario: false,

  acessosPerfil: List(),
  loadingAcessosPerfil: false,
  successAcessosPerfil: false,
  errorAcessosPerfil: false,
});

const handleGetAcessoDiario = (state, action) => {
  // eslint-disable-next-line
  const { usuario, save, ...filtroStorage } = action.filtros;

  if (save) state = state.set('filtros', filtroStorage);

  return state.set('loadingAcessoDiario', true).set('successAcessoDiario', false).set('errorAcessoDiario', false);
};

const handleGetAcessoDiarioSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('acessoDiario', List(data))
    .set('loadingAcessoDiario', false)
    .set('successAcessoDiario', true)
    .set('errorAcessoDiario', false);
};

const handleGetAcessoDiarioError = (state, action) => {
  return state.set('loadingAcessoDiario', false).set('successAcessoDiario', false).set('errorAcessoDiario', action.error);
};

const handleGetAcessosUltimos30Dias = (state, action) => {
  // eslint-disable-next-line
  const { usuario, save, ...filtroStorage } = action.filtros;

  if (save) state = state.set('filtros', filtroStorage);

  return state.set('loadingAcessosUltimos30Dias', true).set('successAcessosUltimos30Dias', false).set('errorAcessosUltimos30Dias', false);
};

const handleGetAcessosUltimos30DiasSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('acessosUltimos30Dias', List(data))
    .set('loadingAcessosUltimos30Dias', false)
    .set('successAcessosUltimos30Dias', true)
    .set('errorAcessosUltimos30Dias', false);
};

const handleGetAcessosUltimos30DiasError = (state, action) => {
  return state
    .set('loadingAcessosUltimos30Dias', false)
    .set('successAcessosUltimos30Dias', false)
    .set('errorAcessosUltimos30Dias', action.error);
};

const handleGetAcessosUsuario = (state, action) => {
  // eslint-disable-next-line
  const { usuario, save, ...filtroStorage } = action.filtros;

  if (save) state = state.set('filtros', filtroStorage);

  return state.set('loadingAcessosUsuario', true).set('successAcessosUsuario', false).set('errorAcessosUsuario', false);
};

const handleGetAcessosUsuarioSuccess = (state, action) => {
  const { data, xCurrentPage, xTotalPage } = action.data;

  let todosAcessos = [];

  if (xCurrentPage === 1) {
    todosAcessos = List(data);
  } else {
    todosAcessos = List([...state.get('acessosUsuario').toArray(), ...data]);
  }

  return state
    .set('acessosUsuario', List(todosAcessos))
    .set('xCurrentPageAcessosUsuario', xCurrentPage)
    .set('xTotalPageAcessosUsuario', xTotalPage)
    .set('hasMoreAcessosUsuario', xTotalPage !== xCurrentPage)
    .set('loadingAcessosUsuario', false)
    .set('successAcessosUsuario', true)
    .set('errorAcessosUsuario', false);
};

const handleGetAcessosUsuarioError = (state, action) => {
  return state.set('loadingAcessosUsuario', false).set('successAcessosUsuario', false).set('errorAcessosUsuario', action.error);
};

const handleGetAcessosPerfil = (state, action) => {
  // eslint-disable-next-line
  const { usuario, save, ...filtroStorage } = action.filtros;
  if (save) state = state.set('filtros', filtroStorage);
  return state.set('loadingAcessosPerfil', true).set('successAcessosPerfil', false).set('errorAcessosPerfil', false);
};

const handleGetAcessosPerfilSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('acessosPerfil', List(data))
    .set('loadingAcessosPerfil', false)
    .set('successAcessosPerfil', true)
    .set('errorAcessosPerfil', false);
};

const handleGetAcessosPerfilError = (state, action) => {
  return state.set('loadingAcessosPerfil', false).set('successAcessosPerfil', false).set('errorAcessosPerfil', action.error);
};

const handleReset = (state, action) => {
  return state
    .set('acessosUsuario', List())
    .set('loadingAcessosUsuario', false)
    .set('successAcessosUsuario', false)
    .set('errorAcessosUsuario', false)
    .set('xTotalPageAcessosUsuario', 0)
    .set('xCurrentPageAcessosUsuario', 0)
    .set('hasMoreAcessosUsuario', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ACESSO_DIARIO:
      return handleGetAcessoDiario(state, action);
    case Types.GET_ACESSO_DIARIO_SUCCESS:
      return handleGetAcessoDiarioSuccess(state, action);
    case Types.GET_ACESSO_DIARIO_ERROR:
      return handleGetAcessoDiarioError(state, action);

    case Types.GET_ACESSOS_ULTIMOS_30_DIAS:
      return handleGetAcessosUltimos30Dias(state, action);
    case Types.GET_ACESSOS_ULTIMOS_30_DIAS_SUCCESS:
      return handleGetAcessosUltimos30DiasSuccess(state, action);
    case Types.GET_ACESSOS_ULTIMOS_30_DIAS_ERROR:
      return handleGetAcessosUltimos30DiasError(state, action);

    case Types.GET_ACESSOS_USUARIO:
      return handleGetAcessosUsuario(state, action);
    case Types.GET_ACESSOS_USUARIO_SUCCESS:
      return handleGetAcessosUsuarioSuccess(state, action);
    case Types.GET_ACESSOS_USUARIO_ERROR:
      return handleGetAcessosUsuarioError(state, action);

    case Types.GET_ACESSOS_PERFIL:
      return handleGetAcessosPerfil(state, action);
    case Types.GET_ACESSOS_PERFIL_SUCCESS:
      return handleGetAcessosPerfilSuccess(state, action);
    case Types.GET_ACESSOS_PERFIL_ERROR:
      return handleGetAcessosPerfilError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
