import { InfoUsuario } from '@utils';
import { del, get, post, put } from '../api';

export const getAgrupadores = () => {
  return get(`/api/companies/groups?userId=${InfoUsuario.get('cdUsuario')}`);
};

export const postAgrupador = (agrupador) => {
  return post('/api/companies/groups', agrupador);
};

export const updateAgrupador = (agrupador) => {
  return put(`/api/companies/groups/${agrupador.id}`, agrupador);
};

export const deleteAgrupador = (id) => {
  return del(`/api/companies/groups/${id}`);
};

export const getAgrupador = (id) => {
  return get(`/api/companies/groups/${id}`);
};