import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getChecklistTemplates as getChecklistTemplatesSdk } from '@sdk/ChecklistTemplates';

//Action Types
export const Types = {
  GET_CHECKLIST_TEMPLATES: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES',
  GET_CHECKLIST_TEMPLATES_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_SUCCESS',
  GET_CHECKLIST_TEMPLATES_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_ERROR',

  RESET: 'CHECKLIST_TEMPLATES/RESET',
};

//Action Creators
export const getChecklistTemplates = () => ({ type: Types.GET_CHECKLIST_TEMPLATES });
export const getChecklistTemplatesSuccess = (checklistTemplates) => ({ type: Types.GET_CHECKLIST_TEMPLATES_SUCCESS, checklistTemplates });
export const getChecklistTemplatesError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATES_ERROR, error }); 

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetChecklistTemplates(action) {
  try {
    const checklistTemplates = yield call(getChecklistTemplatesSdk);
    yield put(getChecklistTemplatesSuccess(checklistTemplates));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplatesError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECKLIST_TEMPLATES, fetchGetChecklistTemplates)
];

// Reducer
const initialState = Map({
  checklistTemplates: List(),
  loading: false,
  success: false,
  error: false
});

const handleGetChecklistTemplates = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetChecklistTemplatesSuccess = (state, action) => {
  const { checklistTemplates } = action;

  return state.set('checklistTemplates', checklistTemplates).set('loading', false).set('success', true).set('error', false);
};

const handleGetChecklistTemplatesError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECKLIST_TEMPLATES:
      return handleGetChecklistTemplates(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_SUCCESS:
      return handleGetChecklistTemplatesSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_ERROR:
      return handleGetChecklistTemplatesError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
