import React from 'react';
import { StyledPagination, TextStyle } from './styles';
import { Text, Icon, Row } from '@components-teammove';

const Pagination = ({ onChange, onShowSizeChange, currentPageNumber, defaultPageSize, showSizeChanger, total, ...props }) => {

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Text>
          <Row align='center'>
            <Icon.Feather type='chevron-left'/>
            <TextStyle>Anterior</TextStyle>
          </Row>
        </Text>
      );
    }
    if (type === 'next') {
      return (
        <Text>
          <Row align='center'>
            <TextStyle >Próximo</TextStyle>
            <Icon.Feather type='chevron-right'/>
          </Row>
        </Text>
      );
    }
    return originalElement;
  };

  return (
    <StyledPagination 
      pageSizeOptions={['10', '25', '50', '100']}
      showSizeChanger={showSizeChanger}
      onShowSizeChange={(page, pageSize)=> onShowSizeChange(page, pageSize)}
      current={currentPageNumber} 
      defaultPageSize={defaultPageSize} 
      total={total}
      onChange={(e) => onChange(e)}
      itemRender={itemRender} 
      {...props}
    />
  );
 
};

export default Pagination;