import styled from 'styled-components';
import { View } from '@components';
import { Button } from '@components-teammove';

export const Container = styled(View)`
  gap: 12px;
`;

export const LoadMoreButton = styled(Button)`
  align-self: center;
`;