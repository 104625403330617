import React, { useEffect, useState } from 'react';
import { SortableContainer } from '@components';
import { Column, Text, Skeleton } from '@components-teammove';
import { capitalize } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTipoAtividade, getTiposAtividades, reorderTipos } from '../../../../store/ducks/configuracoesAtividadesTipos';
import { ActivityIcon, ActivityIconView, ActivityName, ActivityStatusText, ActivityView, Container, GapView, MinorGap } from './styles';
import ModalCadastroAtividade from './Cadastro';
import { useHistory } from 'react-router-dom';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';

export default function TiposAtividades({ setModalTipoVisible, modalTipoVisible }) {
  const history = useHistory();
  const active = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const inactive = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('inactive'));
  const loadingTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingTiposAtividades'));
  const successTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('successTiposAtividades'));
  const dispatch = useDispatch();

  const [items, setItems] = useState({ active: [], inactive: [] });

  useEffect(() => {
    dispatch(getTiposAtividades());
  }, []);

  useEffect(() => {
    if (successTiposAtividades) {
      setItems({ active, inactive });
    }
  }, [successTiposAtividades]);

  const handleGetAtividade = (id) => {
    if (id) dispatch(getTipoAtividade(id));
    history.push(`?id=${id || -1}`);
    setModalTipoVisible(true);
  };

  const handleDragEnd = (event, list) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items[list].findIndex((item) => item.id === active.id);
        const newIndex = items[list].findIndex((item) => item.id === over.id);
        
        const newItems = { ...items, [list]: arrayMove(items[list], oldIndex, newIndex).map((item, index) => ({ ...item, ordem: index })) };
        dispatch(reorderTipos([...newItems.active, ...newItems.inactive]));
        return newItems;
      });
    }
  };

  return(
    <GapView>

      {loadingTiposAtividades ? (
        <>
          <MinorGap>
            <ActivityStatusText>Tipos de Atividades ativas</ActivityStatusText>
            <Container>
              {new Array(9).fill('').map((__, index) => (
                <ActivityView key={String(index)}>
                  <Skeleton/>
                </ActivityView>
              ))}
            </Container>
          </MinorGap>
          <MinorGap>
            <ActivityStatusText>Tipos de Atividades inativas</ActivityStatusText>
            <Container>
              {new Array(6).fill('').map((__, index) => (
                <ActivityView key={String(index)}>
                  <Skeleton/>
                </ActivityView>
              ))}
            </Container>
          </MinorGap>
        </>
      ) : (
        <>
          <MinorGap>
            <ActivityStatusText>Tipos de Atividades ativas</ActivityStatusText>
            <Container>
              <SortableContainer items={items.active.map(({ id }) => id)} handleDragEnd={(event) => handleDragEnd(event, 'active')}>
                {items.active.map((activity) => (
                  <SortableItem handle key={activity.id} id={activity.id} onClick={() => handleGetAtividade(activity.id)}>
                    <ActivityIconView bgColor={activity.cor}>
                      <ActivityIcon type={activity.icone}/>
                    </ActivityIconView>
                    <Column>
                      <Text size='14px' weight='700' lowOpacity>{capitalize(activity.idTarefaTipoGrupo)}</Text>
                      <ActivityName>{activity.nome}</ActivityName>
                    </Column>
                  </SortableItem>
                ))}
              </SortableContainer>
            </Container>
          </MinorGap>
          <MinorGap>
            <ActivityStatusText>Tipos de Atividades inativas</ActivityStatusText>
            <Container>
              <SortableContainer items={items.inactive.map(({ id }) => id)} handleDragEnd={(event) => handleDragEnd(event, 'inactive')}>
                {items.inactive.map((activity) => (
                  <SortableItem handle key={activity.id} id={activity.id} onClick={() => handleGetAtividade(activity.id)} inactive>
                    <ActivityIconView bgColor={activity.cor}>
                      <ActivityIcon type={activity.icone}/>
                    </ActivityIconView>
                    <ActivityName>{activity.nome}</ActivityName>
                  </SortableItem>
                ))}
              </SortableContainer>
            </Container>
          </MinorGap>
        </>
      )}
      {modalTipoVisible && (
        <ModalCadastroAtividade visible={modalTipoVisible} setVisible={setModalTipoVisible}/>
      )}
    </GapView>
  );
}