import styled from 'styled-components';
import { Modal } from '@components-teammove';

export const LoadingComplete = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 10px solid var(--success-color);
`;

export const ModalStyle = styled(Modal)`
  --padding-top-header: 16px;
  --padding-bottom-header: 16px;
  --padding-horizontal: 12px;
  top: 40px !important;
  .ant-modal-body {
    max-height: 490px !important;
    ${({ currentStep })=> currentStep === 3 && 'height: 490px !important'};
  }
  &.ant-modal {
    .ant-modal-header {
      border-bottom: 2px solid ${({ theme }) => theme.darkSecondaryColor} !important;
    }
  }
}
`;

export const ValidationImportMessage = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 145px;
`;
