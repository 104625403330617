import { get, post, put } from '../api';
import { InfoUsuario, moment, dateToAnsi } from '@utils';
import { getQueryParamsAtividades } from './rules';

export const getAtividades = ({ filtros, limit, offset, search, orderBy, allowGoals, useFinishedDateOnFinishedActivities }) => {
  return get(`/api/activities/${InfoUsuario.get('cdUsuario')}${getQueryParamsAtividades({ ...filtros, limit, offset, search, orderBy })}${allowGoals ? '&allowGoals=true' : ''}${useFinishedDateOnFinishedActivities ? '&useFinishedDateOnFinishedActivities=true' : ''}`);
};

export const getAtividade = (activityId) => {
  return get(`/api/activities/edition/${activityId}`);
};

export const getAtividadeDetalhe = (activityId) => {
  return get(`/api/activities/detail/${activityId}?userId=${InfoUsuario.get('cdUsuario')}`);
};

export const getDadosCadastroAtividade = () => {
  return get(`/api/activities/registration/${InfoUsuario.get('cdUsuario')}`);
};

export const postAtividade = (activity) => {
  return post(
    '/api/activities/registration', {
      ...activity, 
      idUserRegister: InfoUsuario.get('cdUsuario')
    }
  );
};

export const putAtividade = (activity) => {
  return put(
    '/api/activities/edition', {
      ...activity, 
      idUserRegister: InfoUsuario.get('cdUsuario') 
    }
  );
};

export const finishAtividade = (activityId, observation) => {
  return post(`/api/activities/finish/${activityId}`, { idUser: InfoUsuario.get('cdUsuario'), date: dateToAnsi(moment(new Date())), observation });
};

export const cancelAtividade = (activityId, observation) => {
  return post(`/api/activities/cancel/${activityId}`, {
    idUser: InfoUsuario.get('cdUsuario'), 
    date: dateToAnsi(moment(new Date())), 
    observation
  });
};

export const reopenAtividade = (activityId, observation) => {
  return post(`/api/activities/reopen/${activityId}`, {
    idUser: InfoUsuario.get('cdUsuario'), 
    date: dateToAnsi(moment(new Date())), 
    observation
  });
};

export const getUsersByCompany = (companyId) => {
  return get(`/api/activities/usersByCompany?companyId=${companyId}`);
};