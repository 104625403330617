import React, { useState } from 'react';
import { Button, Divider, PopConfirm } from '@components-teammove';
import { BackIcon, CloseIcon, CollapseDownButton, CollapseUpButton, DarkModal, GeneralBodyText, GlobalStyles, ModalTitle, ModalTitleContainer, Row, SpaceBetweenRow } from './styles';
import PicturesOverlay from './PicturesOverlay';

const StyledModal = ({ 
  type = 'form', 
  stickyHeader = true, 
  footer, 
  numberOfButtons = 2, 
  collapsibleHeader, 
  onHeaderCollapse, 
  headerCollapsed, 
  onBack,
  onOk, 
  okButtonProps, 
  okText = 'Salvar', 
  okButtonConfirm,
  okButtonConfirmProps,
  onCancel, 
  cancelButtonProps, 
  cancelText = 'Cancelar', 
  cancelButtonConfirm,
  cancelButtonConfirmProps,
  title, 
  header, 
  width, 
  onBeforeCancel, 
  closable = true,
  backable = false,
  children, 
  ...props 
}) => {
  const [visibleConfirmCancel, setVisibleConfirmCancel] = useState(false);

  const handleCancel = () => {
    if (onBeforeCancel && onBeforeCancel()) {
      setVisibleConfirmCancel(true);
    } else {
      onCancel();
    }
  };
  
  return(
    <>
      <DarkModal 
        type={type}
        closable={false}
        maskStyle={{ backdropFilter: 'blur(2.5px)' }}
        width={width || 666}
        onCancel={handleCancel}
        hasHeader={header || title}
        collapsibleHeader={collapsibleHeader}
        onHeaderCollapse={onHeaderCollapse}
        headerCollapsed={headerCollapsed}
        hasFooter={footer !== null}
        title={!header ? title && (
          <>
            <ModalTitleContainer>
              {backable && (<BackIcon type='arrow-left' onClick={() => handleCancel()}/>)}
              <ModalTitle>
                {title}
              </ModalTitle>
              {closable && (<CloseIcon type='close' onClick={() => handleCancel()}/>)}
            </ModalTitleContainer>
            {collapsibleHeader && (headerCollapsed ? <CollapseUpButton onClick={() => onHeaderCollapse(!headerCollapsed)}/> : <CollapseDownButton onClick={() => onHeaderCollapse(!headerCollapsed)}/>)}
          </>
        ) : (
          <>
            <SpaceBetweenRow>
              {backable && (<BackIcon type='arrow-left' onClick={() => handleCancel()}/>)}
              {title || header}
              {closable && (<CloseIcon type='close' onClick={() => handleCancel()}/>)}
            </SpaceBetweenRow>
            {title && (
              <>
                {collapsibleHeader && <Divider margin/>}
                {header}
              </>
            )}
            {collapsibleHeader && (headerCollapsed ? <CollapseUpButton onClick={() => onHeaderCollapse(!headerCollapsed)}/> : <CollapseDownButton onClick={() => onHeaderCollapse(!headerCollapsed)}/>)}
          </>
        )}
        footer={footer !== null ? !footer ? (
          <DefaultFooter
            numberOfButtons={numberOfButtons} 
            onCancel={handleCancel} 
            cancelText={cancelText} 
            cancelButtonProps={cancelButtonProps} 
            cancelButtonConfirm={cancelButtonConfirm} 
            cancelButtonConfirmProps={cancelButtonConfirmProps}
            onOk={onOk} 
            okText={okText} 
            okButtonProps={okButtonProps}
            okButtonConfirm={okButtonConfirm}
            okButtonConfirmProps={okButtonConfirmProps}
          />
        ) : footer : null}
        {...props}
      >
        {!header && !title && (
          <>
            {backable && (<BackIcon type='arrow-left' onClick={() => handleCancel()}/>)}
            {closable && (<CloseIcon type='close' onClick={() => handleCancel()}/>)}
          </>
        )}
        {children}
      </DarkModal>

      {visibleConfirmCancel && (
        <CancelConfirmation 
          onOk={() => {
            onCancel(); setVisibleConfirmCancel(false);
          }}
          onCancel={() => setVisibleConfirmCancel(false)}
        />
      )}
    </>
  );
};

const DefaultFooter = ({ numberOfButtons = 2, onCancel, cancelText, cancelButtonProps, cancelButtonConfirm, cancelButtonConfirmProps, onOk, okText, okButtonProps, okButtonConfirm, okButtonConfirmProps }) => (
  <Row>
    {numberOfButtons > 1 && (cancelButtonConfirm ? (
      <PopConfirm {...cancelButtonConfirmProps}>
        <Button context='form' type='secondary' onClick={onCancel} {...cancelButtonProps}>{cancelText}</Button>  
      </PopConfirm>
    ) : (
      <Button context='form' type='secondary' onClick={onCancel} {...cancelButtonProps}>{cancelText}</Button>
    ))}
    {okButtonConfirm ? (
      <PopConfirm {...okButtonConfirmProps}>
        <Button context='form' type='primary' onClick={onOk} {...okButtonProps}>{okText}</Button>  
      </PopConfirm>
    ) : (
      <Button context='form' type='primary' onClick={onOk} {...okButtonProps}>{okText}</Button>
    )}
  </Row>
);

const CancelConfirmation = ({ onOk, onCancel }) => (
  <DarkModal 
    type='message'
    closable={false}
    maskStyle={{ backdropFilter: 'blur(2.5px)' }}
    hasHeader
    hasFooter
    title={(
      <ModalTitleContainer>
        <ModalTitle>
        Deseja realmente fechar?
        </ModalTitle>
      </ModalTitleContainer>
    )}
    footer={(
      <DefaultFooter onCancel={onCancel} cancelText='Continuar preenchendo' onOk={onOk} okText='Fechar'/>
    )}
    width={600}
    onCancel={onCancel}
    visible
  >
    <GeneralBodyText>O formulário contém informações que não foram salvas. Fechar mesmo assim?</GeneralBodyText>
  </DarkModal>
);

StyledModal.Confirm = ({ content, ...props }) => (
  <StyledModal {...props}>
    <GeneralBodyText>{content}</GeneralBodyText>
  </StyledModal>
);

StyledModal.Picture = ({ ...props }) => (
  <PicturesOverlay {...props}/>
);
StyledModal.info = DarkModal.info;
StyledModal.success = DarkModal.success;
StyledModal.warning = DarkModal.warning;
StyledModal.warning = DarkModal.warning;
StyledModal.confirm = DarkModal.confirm;
StyledModal.GlobalStyles = (theme) => <GlobalStyles theme={theme}/>;

export default StyledModal;