import styled, { css } from 'styled-components';
import { Paragraph, Rate, Text, View  } from '@components';
import { Icon } from '@components-teammove';

export const Meta = styled(View)`
flex-direction: column;
justify-content: center;
flex: 1;
padding-right: 20px;
`;

export const Title = styled(View)``;

export const Description = styled(View)`
max-width: 600px;
`;

export const DescriptionHtml = styled(View)`
margin-top: 20px;
`;

export const ContainerFeedback = styled(View)`
align-items: center;
cursor: pointer;
`;

export const Actions = styled(View)`
flex-direction: row;

> * {
  margin: 5px 5px 5px 0px;
}
`;

export const Extra = styled(View)`
align-items: center;
justify-content: center;
`;

export const TextResumo = styled(Paragraph)`
margin-top: 2px;
`;

export const NotaFeedback = styled(Rate)`
color: #e7711b !important;
> li:not(:last-child) {
  margin-right: 2px;
}
> li {
  cursor: pointer !important;
}
`;

export const TextNota = styled(Text)`
color: #e7711b !important;
padding: 4px 8px 0px 0px;
`;

export const TextSmall = styled(Text)`
font-size: 12px;
${(props) =>
  props.margin &&
  css`
    margin-bottom: ${props.margin}px;
  `}

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const IconStar = styled(Icon.StarFilled)`
font-size: 20px;
color: gold !important;
${(props) =>
  props.invisible !== undefined &&
  css`
    visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
    margin-right: 5px;
  `}
`;

export const ComunicadoTagsContainer = styled(View)`
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

export const ComunicadoTag = styled(View)`
  ${(props) =>
  props.background &&
    css`
      background-color: ${props.background};
    `}
  border-radius: 20px;
  margin-right: 10px;
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;
  display: flex;

`;

export const ComunicadoTagText = styled(Text)`
  font-size: 11px;
  color: #FFFFFF !important;
  font-weight: 500;
`;

export const ComunicadoSequencial = styled(View)`
    color: #7F7F7F;
    font-size: 12px;
    font-weight: 500;
    ${(props) =>
  props.publicado !== undefined && props.publicado === false &&
    css`
        text-decoration: line-through;
      `}
`;
