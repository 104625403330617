import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, InputNumber, Switch, Row, Picker } from '@components-teammove';
import { putGrupo , resetSuccessSave } from '@ducks/wikisCadastro';
import { ColumnFormItem, SequentialText, SequentialTextLabel } from './styles';

export default function GrupoCadastro({ visible, onCancel }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [tagCor, setTagCor] = useState(null);

  const [sequencialNrDigitos, setSequencialNrDigitos] = useState(null);
  const [sequencialPrefixo, setSequencialPrefixo] = useState(null);

  const loading = useSelector(({ wikisCadastro }) => wikisCadastro.get('loadingPutGrupo'));
  const success = useSelector(({ wikisCadastro }) => wikisCadastro.get('successPutGrupo'));

  const initialValues = {
    ativo: true,
    ...location.state,
  };

  useEffect(() => {
    if (!initialValues.id) {
      history.push('/configuracoes/comunicados');
    } else {
      setSequencialPrefixo(initialValues.sequencialPrefixo);
      setSequencialNrDigitos(initialValues.sequencialNrDigitos);
      setTagCor(initialValues.tagCor);
    }

    return () => dispatch(resetSuccessSave());
  }, []);

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      const grupo = { ...initialValues, ...values, tagCor };
      dispatch(putGrupo(grupo));
    });
  };

  const onBeforeCancel = () => {
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      title='Cadastro de grupo de comunicados'
      visible={visible}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      onOk={handleSalvar}
      okButtonProps={{ loading }}
      numberOfButtons={1}
    >
      <Form layout='vertical' form={form} initialValues={initialValues}>
        <Form.Item label='Título' name='titulo'>
          <Input disabled />
        </Form.Item>

        <Row gap='25px'>
          <ColumnFormItem label='Prefixo do Sequencial' name='sequencialPrefixo'>
            <Input placeholder='Informe o prefixo' maxLength={10} onChange={(e) => setSequencialPrefixo(e.target.value)} />
            {sequencialNrDigitos && sequencialNrDigitos > 0 && (
              <Row>
                <SequentialTextLabel>Preview:&nbsp;</SequentialTextLabel>
                <SequentialText>{sequencialPrefixo}{'0'.repeat(sequencialNrDigitos - 1)}X</SequentialText>
              </Row>
            )}
          </ColumnFormItem>

          <Form.Item
            label='Digitos do Sequencial'
            name='sequencialNrDigitos'
            rules={[
              {
                validator: (rules, value) =>
                  value && value !== 0 ? Promise.resolve() : Promise.reject('Número de digitos precisa ser maior que zero'),
              },
            ]}
          >
            <InputNumber onChange={(value) => setSequencialNrDigitos(value)} />
          </Form.Item>
        </Row>

        <Form.Item label='Cor' name='tagCor' valuePropName='color'>
          <Picker/>
        </Form.Item>

        <Form.Item label='Utilizar sequencial' name='sequencialAtivo' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}
