import styled from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ noBackground }) => !noBackground ? 'rgba(0,0,0,.75)' : 'transparent'}; 
  display: flex;
  align-items: center;
  justify-content: center;
`;