import { post } from '../../../api';
import { InfoUsuario } from '@utils';

const mainPath = '/api/wiki/comentarios/acao';

const postComentarioAcao = async ({ idComentario, cdUsuario = InfoUsuario.get('cdUsuario'), acao }) => {
  return await post(`${mainPath}/salvar`, { idComentario, cdUsuario, acao });
};

export { postComentarioAcao };
