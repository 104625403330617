/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { GroupedColumn, StackedColumn } from '@antv/g2plot';
import View from '../../View';
import styled, { css } from 'styled-components';

const StyledView = styled(View)`
  overflow-x: auto;
  path {
    ${(props) =>
      props.pointer &&
      css`
        cursor: pointer !important;
      `}
  }
`;

const DEFAULT_PARAMS = {
  legend: {
    visible: true,
    position: 'top-left',
  },
  xAxis: {
    title: {
      visible: false,
    },
  },
  yAxis: {
    title: {
      visible: false,
    },
  },
  columnSize: 60,
};

export default function ColumnChart({ data, params, ...rest }) {
  const chartRef = useRef(null);
  const [plot, setPlot] = useState();

  useEffect(() => {
    if (plot) {
      plot.changeData(data);
    } else {
      const bar = params && params.stacked ? StackedColumn : GroupedColumn;
      const plotFinal = new bar(chartRef.current, {
        ...DEFAULT_PARAMS,
        ...params,
        data,
      });

      plotFinal.render();
      setPlot(plotFinal);
    }
  }, [data]);

  return <StyledView ref={chartRef} {...rest} />;
}
