import { get, post, put } from '../api';

export const getTipos = () => {
  return get('/api/campaigns/types');
};

export const postTipo = (tipo) => {
  return post('/api/campaigns/types', tipo);
};

export const updateTipo = (tipo) => {
  return put('/api/campaigns/types', tipo);
};

export const getTipo = (externalId) => {
  return get(`/api/campaigns/types/${externalId}`);
};