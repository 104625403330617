import styled from 'styled-components';
import { Column, Text } from '@components-teammove';

export const ColumnContainer = styled(Column)`
    height: ${({ heightModal }) => heightModal ? '350px' : 'auto'};
`;

export const IconWrapper = styled.div`
  border-radius: 8px;
  padding: 15px 13px;
  background-color: ${({ color }) => color};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActivityName = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme })=> theme.textColor};
  }
`;