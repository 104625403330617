import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Input, View, Button } from '@components-teammove';
import { StyledText } from '../styles';
import { FillRow } from './styles';

const FormParams = styled(Form)`
  width: 100%;
`;

export default function PersonalizacaoParametros({ parametros, onChangeText, next, previous, form, handleOnContinue }) {
  const [title, setTitle] = useState(false);
  const [titleLP, setTitleLP] = useState(false);

  useEffect(() => {
    if (!parametros) return;

    const objectValuesFromArrayOfFields = parametros.reduce((values, { id, texto }) => ({ ...values, [`param${id}`]: texto }), {});
    form.setFieldsValue(objectValuesFromArrayOfFields);
    handleTitle(parametros);
  }, [parametros]);

  const handleTitle = (parametros) => {
    if (parametros) {
      parametros.forEach((el) => {
        if (el.type === 'LP') {
          setTitle(true);
        } else {
          setTitleLP(true);
        }
      });
    }
  };

  const handleOnCancel = () => {
    if (previous) {
      previous();
    }
  };

  return (
    <>
      {(!title || titleLP) && (
        <>
          <StyledText>{'Informe os parâmetros para continuar'}</StyledText>
          <FormParams layout='vertical' form={form}>
            <>
              {parametros.map(({ id, label, required, editable, type }, index) => (
                <View key={id}>
                  {type !== 'LP' &&
                    <Form.Item key={id} label={label} name={`param${id}`} rules={[{ required, message: `${label} é obrigatório` }]}>
                      <Input disabled={!editable} onChange={(e) => onChangeText(index, e.target.value)} />
                    </Form.Item>
                  }
                </View>
              )
              )
              }
            </>
          </FormParams>
        </>
      )
      }
      <FillRow flex={1} gap='25px'>
        <Button type='secondary' fillWidth onClick={handleOnCancel}>
          Cancelar
        </Button>
        <Button fillWidth onClick={handleOnContinue}>
          Continuar
        </Button>
      </FillRow>
    </>
  );
}
