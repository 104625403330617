import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import App from '@app';

import { Body, PageHeader, Breadcrumb, Row, Column, Text, AudioPlayer, StatusTag, Skeleton, Avatar, Modal } from '@components-teammove';
import { TelasNomenclaturas, getDownloadUrlAvatar, formatTimeWithSeconds, formatDate, formatTelephone } from '@utils';

import { getCallDetailed, putEditCallNote } from '@ducks/callActivity';
import { MoreInfoTag, Card, FeatherIcon, ContentWritings, CardWriting, ImportanceIcon, InputText } from './styles';

export default function CallActivityDetailed() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const { id } = useParams();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  
  const { 
    callDetailed, 
    loadingCallDetailed, 
    successCallDetailed,
    loadingEditCallNote,
    successEditCallNote,
    errorEditCallNote
  } = useSelector(({ callActivity }) => ({ 
    callDetailed: callActivity.get('callDetailed'),
    loadingCallDetailed: callActivity.get('loadingCallDetailed'),
    successCallDetailed: callActivity.get('successCallDetailed'),
    loadingEditCallNote: callActivity.get('loadingEditCallNote'),
    successEditCallNote: callActivity.get('successEditCallNote'),
    errorEditCallNote: callActivity.get('errorEditCallNote')
  }));

  const [ visibleEditNotes, setVisibleEditNotes ] = useState(false);
  const [ editTranscription, setEditTranscription ] = useState('');

  useEffect(() => {
    dispatch(getCallDetailed(parseInt(id)));
  },[]);

  useEffect(() => {
    if(successCallDetailed) {
      setEditTranscription(callDetailed.details?.notes);
    }
  },[successCallDetailed]);

  useEffect(() => {
    if(successEditCallNote || errorEditCallNote) {
      setVisibleEditNotes(false);
    }
  
  },[successEditCallNote, errorEditCallNote]);

  const handleEditNotes = () => {
    dispatch(putEditCallNote(callDetailed.details?.voiceCallRecordDetailId, editTranscription));
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={(
            <Row gap='14px' align='center'>
              <div>Ligações</div>
              {successCallDetailed && (
                <StatusTag color={callDetailed?.details?.recordStatus === 'completed' ? '#34C759' : 'ausente'} text={callDetailed?.details?.recordStatus === 'completed' ? 'concluída' : 'ausente'} size='average' fixedWidth='105px'/>
              )}
            </Row>
            
          )}
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades', name: 'Atividades', current: true } ,
                { path: '/atividades/ligacoes', name: 'Ligações' },
                { path: `/atividades/ligacoes/detalhes/${id}`, name: 'Ligações', current: true },
              ]}
            />
          )}
        >
          {loadingCallDetailed ? (<Skeleton.Map/>) : successCallDetailed && (
            <Column gap='24px'>
              <Row width='100%' gap='12px'>
                <Column width='80%' gap='12px'>
                  <Row align='flex-end' gap='12px'>
                    <FeatherIcon type='home' size='24px'/>
                    <Text size='18px' weight='700'>Lead / {companyNomenclature.nomenclatura}</Text>
                  </Row>
                  <Card gap='18px' padding='20px' height='100%'>
                    <Row justify='space-between' align='flex-start'>
                      <Row align='baseline' gap='12px'>
                        <Text size='28px' weight='700'>{callDetailed.company?.companyShortName}</Text>
                        <Text size='18px' weight='400'>{callDetailed.company?.companyName}</Text>
                      </Row>
                      <MoreInfoTag onClick={()=> history.push(`/unidades/${callDetailed?.company?.companyId}`)}>
                        <Text size='14px' weight='700'>+ info</Text>
                      </MoreInfoTag>
                    </Row>
                
                    <Column >
                      <Text size='16px' weight='400'>
                        {callDetailed?.company?.address ? callDetailed.company?.address : ''} 
                        {callDetailed?.company.address && callDetailed?.company?.address ? ' - ' : ''} 
                        {callDetailed?.company?.neighborhood ? callDetailed.company?.neighborhood : ''}
                      </Text>
                      <Text size='16px' weight='600'>
                        {callDetailed?.company?.city ? callDetailed.company?.city : ''} 
                        {callDetailed?.company?.city && callDetailed?.company?.state ? ' - ' : ''} 
                        {callDetailed?.company?.state ? callDetailed.company?.state : ''} 
                        {callDetailed?.company?.state && callDetailed?.company.zipCode ? ' - ' : ''} 
                        {callDetailed?.company?.zipCode ? callDetailed.company.zipCode : ''}
                      </Text>
                    </Column>
                  </Card>
                </Column>

                <Column width='20%' gap='12px'>
                
                  <Text size='18px' weight='700'>Feita em:</Text>
            
                  <Column gap='12px' height='100%'>
                    <Card padding='20px' height='100%' justify='center'>
                      <Row gap='4px'>
                        <FeatherIcon type='calendar' size='24px'/>
                        <Text size='16px' weight='700'>Data:</Text>
                        <Text size='16px' weight='400'>{formatDate(callDetailed.details?.recordingStartTime)}</Text>
                      </Row>
                  
                    </Card>
                    <Card padding='20px' height='100%' justify='center'>
                      <Row gap='4px'>
                        <FeatherIcon type='clock' size='24px'/>
                        <Text size='16px' weight='700'>Horário:</Text>
                        <Text size='16px' weight='400'>{formatTimeWithSeconds(callDetailed.details?.recordingStartTime)}</Text>
                      </Row>
                    </Card>
                  </Column>
              
                </Column>
              </Row>
              <Row width='100%' gap='12px'>
                <Row gap='12px' width='100%'>
                  <Column gap='12px' width='100%'>
                    <Text size='18px' weight='700'>Responsável</Text>
                    <Card padding='12px' height='74.2px' justify='center'>
                      <Row gap='12px' align='center'>
                        <Avatar size='44px' src={getDownloadUrlAvatar(callDetailed.user?.avatarUrl)} />
                        <Column>
                          <Text size='18px' weight='400'>{callDetailed.user?.userName}</Text>
                          <Text size='14px' weight='400' lowOpacity>{callDetailed.user?.userRole}</Text>
                        </Column>
                      </Row>
                    </Card>
                  </Column>
                  {callDetailed.contato && (
                    <Column gap='12px' width='100%'>
                      <Text size='18px' weight='700'>Contato</Text>
                      <Card padding='12px'>
                        <Row gap='12px' align='center'>
                          <Column>
                            <Text size='18px' weight='400'>{callDetailed.contact?.contactName}</Text>
                            <Text size='14px' weight='400' lowOpacity>{formatTelephone(callDetailed.contact?.contactPhone)}</Text>
                          </Column>
                        </Row>
 
                      </Card>
                    </Column>
                  )}
                 
                </Row>
              
                <Column gap='12px' width='100%'>
                  <Row gap='12px' align='center'>
                    <FeatherIcon type='phone' size='20px'/>
                    <Text size='18px' weight='700'>Ligação</Text>
                  </Row>
                  <Row align='center' gap='12px' width='100%'>
                    <Column width='90%'>
                      <AudioPlayer downloadFileName={`gravacao_da_ligacao_para_${callDetailed.contact?.contactName || ''}.mp3`} src={callDetailed.details?.recordingUrl} bgColor='secondary' padding='24.3px 15px' width='240px'/>
                    </Column>
                
                    <ImportanceIcon importance={callDetailed.classification}>
                      <FeatherIcon
                        size='24px' type={callDetailed.classification === 'Relevante' ? 'thumbs-up' :  callDetailed.classification === 'Nao_Relevante' ? 'thumbs-down' : 'user-x'} importance={callDetailed.classification}
                      />
                    </ImportanceIcon>
                  </Row>
                
                </Column>
            
              </Row>
              
              <ContentWritings gap='20px'>
                {callDetailed.details?.highlightsTranscript && (
                  <Column gap='16px'>
                    <Text size='16px' weight='400'>Highlights da ligação</Text>
                    <CardWriting>
                      <Text size='16px' weight='400'>
                        {callDetailed.details?.highlightsTranscript}
                      </Text>
                    </CardWriting>
                  </Column>
                )}
              
                {callDetailed.details?.fullTranscript && (
                  <Column gap='16px'>
                    <Text size='16px' weight='400'>Transcrição da ligação</Text>
                    <CardWriting>
                      <Text size='16px' weight='400'>
                        {callDetailed.details?.fullTranscript}
                      </Text>
                    </CardWriting>
                  </Column>
                )}
                  
                <Column gap='16px'>
                  <Row gap='12px'>
                    <Text size='16px' weight='400'>Observação</Text>
                    <FeatherIcon cursor type='edit' size='24px' onClick={()=>setVisibleEditNotes(true)}/>
                  </Row>
                  {callDetailed.details?.notes && (
                    <CardWriting>
                      <Text size='16px' weight='400'>
                        {callDetailed.details?.notes}
                          
                      </Text>
                    </CardWriting>
                  )}
                  
                </Column>

              </ContentWritings>
            
            </Column>
          )}
          {visibleEditNotes && (
            <Modal 
              title= 'Editar Observação'
              visible={visibleEditNotes}
              onCancel={()=>setVisibleEditNotes(false)}
              onOk={()=>handleEditNotes()}
              okText='Editar'
              okButtonProps={{ loading: loadingEditCallNote, disabled: editTranscription ===  callDetailed.details?.notes }}
            >
              <InputText
                placeholder='Linha de impressão 3' 
                value={editTranscription} 
                onChange={(e) => setEditTranscription(e.target.value)} 
                autoFocus
              />
            </Modal>
          )}
        </PageHeader>
          
      </Body>
    </App>
  );

}
