import React from 'react';
import { StyledRow, GapFill, IconCircle, LabelText, Scrollable, StyledColumn } from './styles';

const Stepper = ({ steps }) => (
  <Scrollable>
    <StyledRow align='flex-start' justify='flex-end' flex={1}>
      {steps.map(({ icon, label, state }, index) => (
        <>
          <StyledColumn key={icon + label} align='center' gap='12px'>
            <StyledRow align='center' justify='flex-end'>
              <GapFill state={steps.at(index - 1)?.state} visible={index !== 0}/>
              <IconCircle key={icon} state={state}>
                {icon}
              </IconCircle>
              <GapFill state={state} visible={index !== steps.length - 1}/>
            </StyledRow>
            <LabelText key={label}>{label}</LabelText>
          </StyledColumn>
          {index !== steps.length - 1 && <GapFill state={state} visible/>}
        </>
      ))}
    </StyledRow>
  </Scrollable>
);

export default Stepper;