import Icon from '../Icon';
import CurrencyInput from 'react-currency-input';
import styled from 'styled-components';

export const StyledInputMoney = styled(CurrencyInput)`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  color: ${(props) => props.theme.textColor} !important;
  font-size: 16px;

  ::placeholder {
    color: ${(props) => props.theme.textColor} !important;
    opacity: 50%;
  }

  /* background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 1px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 16px;

  ${({ disabled }) => disabled && `
    opacity: .5;
  `}

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  .ant-input-suffix {
    display: flex;
    align-items: center;
  } */
`;

export const ErrorIcon = styled(Icon.MDI)`
  path {
    color: var(--danger-color) !important;
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;