import styled from 'styled-components';
import { View, Text } from '@components';
import { Row } from '../styles';
import { Icon } from '@components-teammove';

export const Container = styled(Row)`
  margin-right: ${({ collapsed })=> collapsed ? '120px' : '340px'};
  justify-content: space-between;
  align-items: flex-end;
  padding: 24px 8%;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 13px 33px;
  align-items: center;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const LikeIcon = styled(Icon.LikeOutlined)`
  &.anticon {
    color: ${({ theme }) => theme.darkHighlightColor};
    font-size: 40px;
  }
`;

export const Label = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.darkHighlightColor};
    align-self: flex-start;
  }
`;

export const ActionView = styled(View)`
  flex: 1;
  margin-top: 15px;

  button {
    max-width: 200px;
    margin-top: 5px;
  }
`;

export const ActionText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.darkHighlightColor};
  }
`;