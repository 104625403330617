import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row, Icon } from '@components-teammove';
import { getAgrupadoresMetasAtividades, deleteAgrupadorMetaAtividade } from '@ducks/configuracoesAgrupadoresMetasAtividades';
import CadastroAgrupadoresMetas from './CadastroAgrupadoresMetas';
import { TagAgrupadorStyled } from './styles';

const AgrupadoresMetas = ({ visibleAddAgrupadorModalMeta, setVisibleAddAgrupadorModalMeta }) => {
  const dispatch = useDispatch();

  const [infoAgrupador, setInfoAgrupador] = useState(null);

  const agrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('agrupadoresMetas'));
  const loadingAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('loadingAgrupadoresMetas'));

  const successPutAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('successPutAgrupadoresMetas'));

  const successDeleteAgrupadorMeta = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('successDeleteAgrupadorMeta'));

  useEffect(() => {
    dispatch(getAgrupadoresMetasAtividades());
  }, []);

  useEffect(() => {
    if (successPutAgrupadoresMetas || successDeleteAgrupadorMeta) dispatch(getAgrupadoresMetasAtividades());
  }, [successPutAgrupadoresMetas, successDeleteAgrupadorMeta]);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    dispatch(deleteAgrupadorMetaAtividade(id));
  };

  const handleOpenEdit = (agrupador) => {
    setInfoAgrupador(agrupador);
    setVisibleAddAgrupadorModalMeta(true);
  };

  const handleClose = () => {
    setVisibleAddAgrupadorModalMeta(false);
    setInfoAgrupador(null);
  };
    
  const columns = [
    {
      title: 'Agrupador',
      dataIndex: 'name',
      width: '50%',
      sorter: true,
      render: (name, agrupador) => (
        <Row align='center' gap='12px'>
          <TagAgrupadorStyled colorBg={agrupador.color}>
            <Icon.MDI type={agrupador.icon} size='20px'/>
          </TagAgrupadorStyled>
          {name}
        </Row>
      ),
    },
    {
      title: 'Tipos de atividade',
      dataIndex: 'activityKinds',
      sorter: true,
      render: (activityKinds, agrupador) => (
        <Row justify='space-between' align='center'>
          {activityKinds.map((activityKind) => activityKind.name).join(', ')}
          <Row align='center' gap='12px'>
            <Button type='secondary' size='small' context='list'>
                Editar
            </Button>
            <Button onClick={(e) => handleDelete(e, agrupador.id)} type='danger' size='small' context='list'>
              <Icon.Feather type='trash-2'/>
            </Button>
          </Row>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={agrupadoresMetas}
        loading={loadingAgrupadoresMetas}
        rowKey="id"
        onRow={(agrupador) => ({ onClick: () => handleOpenEdit(agrupador) })}
      />
      <CadastroAgrupadoresMetas
        visible={visibleAddAgrupadorModalMeta}
        onCancel={handleClose}
        infoAgrupador={infoAgrupador}
      />
    </>
  );
};

export default AgrupadoresMetas;