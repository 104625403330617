import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Column, DateControllerHeader, SearchBar, Text, Avatar, Skeleton, Form, Icon, Tooltip } from '@components-teammove';
import { getMetaAtividades, updateModalConfirmation } from '@ducks/configuracoesMetaAtividades';
import { compareDates, getCurrentMomentStartOfMonth } from '@utils';
import { BodySearch, ContentPanel, DateControllerHeaderStyled, ButtonStyled, VerticalDivider, StyleTable, StyleRow, CellTable, FormItemContainer, IconWrapper, InputNumberStyled, ContainerIcon } from './styles';
import CadastroModal from './Cadastro';
import ChangeMonthModal from './ChangeMonthModal';
import { putMetasAtividadesDispatch } from './rules';

function MetasAtividades({ onChange, modalMetaVisible, setModalMetaVisible, currentParams, typeMetaModal, setTypeMetaModal, form, users, setUsers, savedFormValues, setSavedFormValues, activityKinds, setActivityKinds, allUsersAdd, setAllUsersAdd }) {
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));
  const metaAtividades = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('metaAtividades'));
  const successMetaAtividades = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('success'));
  const loadingMetaAtividades = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('loading'));
  const successSavePerfil = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('successSavePerfil'));
  const successSaveTipoAtividade = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('successSaveTipoAtividade'));
  const successSave = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('successSave'));
  
  const [visibleModalConfirmationChangeMonth, setVisibleModalConfirmationChangeMonth] = useState(false);

  useEffect(() => {
    return () => {
      setUsers([]);
    };
  },[]);

  useEffect(() => {
    if(successMetaAtividades) {
      const { users, activityKindsOrGroups } = metaAtividades;
      setUsers(users);
      setAllUsersAdd(users);
      setActivityKinds(activityKindsOrGroups);
      form.resetFields();
      const formValues = {};

      users.forEach((user) => {
        user.activityKindOrGroupGoals.forEach((activityKindGoal) => {
          const fieldName = `quantidadeAtividade-${user.id}-${activityKindGoal.activityKindId || activityKindGoal.activityKindGroupId}`;
          formValues[fieldName] = activityKindGoal.numberOfActivities;
        });
      });

      form.setFieldsValue(formValues);
      setSavedFormValues(formValues);
    }
  }, [successMetaAtividades]);

  useEffect(() => {
    dispatch(getMetaAtividades(currentParams.initialDate));
  }, [currentParams]);

  useEffect(() => {
    if(successSaveTipoAtividade || successSavePerfil) {
      dispatch(getMetaAtividades(currentParams.initialDate));
    }    
  }, [successSaveTipoAtividade, successSavePerfil]);

  useEffect(() => {
    if(successSave) {
      setVisibleModalConfirmationChangeMonth(false);
    }
  }, [successSave]);
  
  const handleSearch = (event) => {
    const search = event.trim().toLowerCase();

    const filteredUsuarios = allUsersAdd.filter((usuario) => 
      usuario.name.toLowerCase().includes(search) || 
      usuario.profileDescription.toLowerCase().includes(search)
    );

    setUsers(filteredUsuarios);
  };

  const handleCancelConfirmation = () => {
    dispatch(updateModalConfirmation(true));
    setVisibleModalConfirmationChangeMonth(false);
  };

  const handleOkConfirmation = () => {
    putMetasAtividadesDispatch(allUsersAdd, form, dispatch, currentParams.initialDate, setSavedFormValues, activityKinds);
    dispatch(updateModalConfirmation(true));
  };

  const handleModalOpenConfirmation = () => {
    form.validateFields().then((values) => {
      const sortedSavedFormValues = JSON.stringify(savedFormValues, Object.keys(savedFormValues).sort());
      const sortedValues = JSON.stringify(values, Object.keys(values).sort());

      if(savedFormValues && sortedSavedFormValues !== sortedValues) {
        setVisibleModalConfirmationChangeMonth(true);
      } else {
        dispatch(updateModalConfirmation(true));
      }
    });
  };

  const renderTooltipContentGroups = (activityKind) => (
    <Column gap='10px'>
      <Text size='12px' weight='700'>Agrupador de meta de:</Text>
      {activityKind.groupActivityKinds.map((group) => (
        <Row key={group.id} gap='8px' align='center'>
          <ContainerIcon colorBg={group.color}>
            <Icon.MDI type={group.icon}/>
          </ContainerIcon>
          <Text size='16x' weight='400'>{group.name}</Text>
        </Row>
      ))}
    </Column>
  );

  return (
    <Column>
      <ContentPanel>
        <DateControllerHeaderStyled>
          <DateControllerHeader
            defaultPeriod={'MENSAL'}
            defaultOptions={[
              { label: 'Mensal', value: 'MENSAL' },
            ]}
            colorArrowButton='tertiary'
            onChange={onChange}
            typePeriod={currentParams.typePeriod}
            initialDate={currentParams.initialDate}
            formattedTypePeriod={currentParams.formattedRangeDate}
            modalConfirmation={handleModalOpenConfirmation}
            fromMonthTable
          />
        </DateControllerHeaderStyled>
      </ContentPanel>
      <BodySearch>
        <SearchBar
          marginLess
          placeholder="Buscar usuário"
          onSearch={handleSearch}
        />
      </BodySearch>
      <StyleTable>
        {loadingMetaAtividades ? <Skeleton.Map/> 
          : successMetaAtividades && activityKinds && users && (
            <>
              <StyleRow>
                <CellTable justify='center' align='center' color={theme.darkPrimaryColor}>
                  <StyleRow align='center' justify='space-between'>
                    <Text size='16px' weight='700'>Usuários</Text>
                    <ButtonStyled onClick={() => {setModalMetaVisible(true); setTypeMetaModal('addUser');}}>+ Adicionar</ButtonStyled>
                  </StyleRow>
                </CellTable>
                {activityKinds.map((activityKind) => (
                  <CellTable key={activityKind.id} justify='center' align='center' color={theme.darkPrimaryColor}>
                    <Tooltip title={activityKind.groupActivityKinds ? renderTooltipContentGroups(activityKind) : ''}>
                      <Row gap='10px' align='center'>
                        <IconWrapper color={activityKind.color}>
                          <Icon.MDI color='white' type={activityKind.icon}/>
                        </IconWrapper>
                        <Text size='16px' weight='700'>{activityKind.name}</Text>
                      </Row>
                    </Tooltip>
                  </CellTable>
                ))}
                {activityKinds.length < 6 && <CellTable empty color={theme.darkPrimaryColor}/>}
              </StyleRow> 
              <Form form={form}>
                {users
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((user) => (
                    <StyleRow key={user.id}>
                      <CellTable justify='center' align='center' color={theme.darkPrimaryColor}>
                        <Row align='center' gap='12px'>
                          <Avatar size='44px' src={user.picture}/>
                          <VerticalDivider color={user.profileColor}/>
                          <Column >
                            <Text 
                              size='18px' 
                              weight='inherit'
                              textOverflow='ellipsis'
                              whiteSpace='nowrap' 
                              overflow='hidden' 
                              maxWidth='175px'
                            >
                              {user.name}
                            </Text>
                            <Text 
                              size='14px' 
                              weight='400' 
                              lowOpacity
                              textOverflow='ellipsis'
                              whiteSpace='nowrap' 
                              overflow='hidden' 
                              maxWidth='175px'
                            >
                              {user.profileDescription}
                            </Text>
                          </Column>
                        </Row>
                      </CellTable>
                      {activityKinds && activityKinds.map(({ id }) => {
                        const activityKind = user.activityKindOrGroupGoals.find((activityKindGoal) => (activityKindGoal.activityKindId || activityKindGoal.activityKindGroupId) === id);
                        const isUserAllowed = activityKind && activityKind.isUserAllowedToSeeThisActivityKindOrGroup;
                        return (               
                          <CellTable key={id}  justify='center' align='center' color={theme.darkPrimaryColor}>
                            <FormItemContainer name={`quantidadeAtividade-${user.id}-${id}`}>
                              <InputNumberStyled
                                disabled={!compareDates(currentParams.initialDate, getCurrentMomentStartOfMonth()) || !isUserAllowed}
                                placeholder='0'
                                max={99999999}
                                onKeyPress={(e) => {
                                  const isNumber = /[0-9]/.test(e.key);
                                  const numberLenght = e.target.value.length;
                                  if (numberLenght >= 8 && isNumber) {
                                    e.preventDefault();
                                  }
                                }}
                                onPaste={(e) => {
                                  const pasteData = e.clipboardData.getData('text');
                                  const currentValue = e.target.value;
                                  const newValue = currentValue + pasteData;

                                  if (newValue.length > 8) {
                                    e.preventDefault();
                                  }
                                }}
                              />                    
                            </FormItemContainer>
                          </CellTable>
                        );})}    
                      {users.length > 0 && activityKinds.length === 0  && (
                        <CellTable justify='center' align='center' empty color={theme.darkPrimaryColor}>
                          <StyleRow align='center' justify='center'>
                            <Text align-self='center' size='16px' weight='700'>Nenhuma atividade cadastrada</Text>
                          </StyleRow>
                        </CellTable>
                      )}                             
                      {activityKinds.length < 6 && activityKinds.length > 0 && <CellTable empty color={theme.darkPrimaryColor}/>}  
                    </StyleRow>
                  ))}
              </Form>
              <StyleRow>
                {users.length === 0 && (
                  <CellTable justify='center' align='center' color={theme.darkPrimaryColor}>
                    <Text size='16px' weight='700'>Nenhuma meta cadastrada</Text>
                  </CellTable>
                )}     
                {users.length === 0 && activityKinds.length === 0  && (
                  <StyleRow noActivity align='center' justify='center'>
                    <Text align-self='center' size='16px' weight='700'>Nenhuma atividade cadastrada</Text>
                  </StyleRow>
                )}            
              </StyleRow>
            </>
          )}
      </StyleTable>
      {modalMetaVisible && <CadastroModal visible={modalMetaVisible} onCancel={() => setModalMetaVisible(false)} typeMetaModal={typeMetaModal} data={typeMetaModal === 'addUser' ? users : activityKinds}/>}
      {visibleModalConfirmationChangeMonth && ( <ChangeMonthModal visible={visibleModalConfirmationChangeMonth} onCancel={handleCancelConfirmation} onOk={handleOkConfirmation} />)}
    </Column>
  );
}

export default MetasAtividades;