import React from 'react';
import { ColumnStyle, Division, IconWrapper, Label, Line, MainIconWrapper, MainLabel, MainPercentage, MainRow, Percentage, SimpleDivision, SimpleLabel, SimpleLine, SimplePercentage, StatusIndicator, Subtitle } from './styles';
import { Row } from '@components-teammove';

const DividedLine = ({ divisions, subtitleDisabled, ...props }) => {
  return (
    <ColumnStyle gap='8px'>
      <SimpleLine>
        {divisions.filter(({ percentage }) => percentage > 0).map(({ percentage, status, label, useBgColor, colorTheme }) => (
          <SimpleDivision colorTheme={colorTheme} useBgColor={useBgColor} status={status} percentage={percentage} key={label}/>
        ))}
      </SimpleLine>
      {!subtitleDisabled && (
        <Row justify='space-between'>
          {divisions.map(({ total, label, status }) => (
            <Row gap='4px' align='center' key={label}>
              <StatusIndicator status={status}/>
              <Row>
                <SimpleLabel>{label}:</SimpleLabel>
              &nbsp;
                <SimplePercentage>{total}</SimplePercentage>
              </Row>
            </Row>
          ))}
        </Row>
      )}
      
    </ColumnStyle>
  );
};

DividedLine.Large = ({ divisions, ...props }) => {
  const mainDivision = divisions.find(({ main }) => main);
  
  return (
    <>
      <MainRow status={mainDivision.status}>
        <MainIconWrapper>{mainDivision.icon}</MainIconWrapper>
        <MainLabel>{mainDivision.label}:</MainLabel>
        <MainPercentage>{mainDivision.percentage}%</MainPercentage>
      </MainRow>
      <Line>
        {divisions.map(({ percentage, status, label }) => (
          <Division status={status} percentage={percentage} key={label}/>
        ))}
      </Line>
      <Subtitle>
        {divisions.filter(({ main }) => !main).map(({ percentage, label, status, icon }) => (
          <Row key={label} align='center'>
            <IconWrapper status={status}>{icon}</IconWrapper>
            &nbsp;
            <Label>{label}:</Label>
            &nbsp;
            <Percentage>{percentage}%</Percentage>
          </Row>
        ))}
      </Subtitle>
    </>
  );
};

export default DividedLine;