import { dateToAnsi, DATE_FORMATS } from '@utils';

export const formatParams = (params) => {
  const {
    agrupador,
    companies,
    typesCompanies,
    period,
    typesActivities,
    users,
    search,
    limit,
    getPictures, 
  } = params;
  
  const query = [];
  if ((period) && (period.length > 0)) {
    query.push(`initialDate=${dateToAnsi(period[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`finalDate=${dateToAnsi(period[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  const periodParam = query.length === 0 ?  '' : query.join('&');
  const agrupadorParam = agrupador ? `companyAggregator=${agrupador.join(' ')}` : '';
  const companiesParam = companies ? `companyId=${companies.join(' ')}` : '';
  const typeCompaniesParam = typesCompanies ? `companyType=${typesCompanies.join(' ')}` : '';
  const typesActivitiesParam = typesActivities ? `activityType=${typesActivities.join(' ')}` : '';
  const usersParam = users ? `usersId=${users.join(' ')}` : '';
  const limitParam = limit ? `limit=${limit}` : '';
  const searchParam = search ? `search=${search}` : '';
  const getPicturesParam = getPictures ? `getPictures=${getPictures}` : '';
  
  return [
    periodParam,
    agrupadorParam,
    companiesParam,
    typeCompaniesParam,
    typesActivitiesParam,
    usersParam,
    searchParam,
    limitParam,
    getPicturesParam,
  ]
    .filter((param) => param)
    .join('&');
};

export const newFormatParams = (params) => {
  const {
    agrupador,
    companies,
    typesCompanies,
    period,
    typesActivities,
    users,
    search,
    limit,
    statusAprovacao,
    orderByField,
    orderByDirection,
    
  } = params;
  
  const query = [];
  if ((period) && (period.length > 0)) {
    query.push(`initialDate=${dateToAnsi(period[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`finalDate=${dateToAnsi(period[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  const periodParam = query.length === 0 ?  '' : query.join('&');
  const agrupadorParam = agrupador ? `companyAggregator=${agrupador.join(' ')}` : '';
  const companiesParam = companies ? `companyId=${companies.join(' ')}` : '';
  const typeCompaniesParam = typesCompanies ? `companyTypeId=${typesCompanies.join(' ')}` : '';
  const typesActivitiesParam = typesActivities ? `activityType=${typesActivities.join(' ')}` : '';
  const usersParam = users ? `userId=${users.join(' ')}` : '';
  const limitParam = limit ? `limit=${limit}` : '';
  const searchParam = search ? `search=${search}` : '';
  const statusAprovacaoParam = statusAprovacao ? `acceptanceStatus=${statusAprovacao}` : '';
  const orderByFieldParam = orderByField ? `orderByField=${orderByField}` : '';
  const orderByDirectionParam = orderByDirection ? `orderByDirection=${orderByDirection}` : '';

  return [
    periodParam,
    agrupadorParam,
    companiesParam,
    typeCompaniesParam,
    typesActivitiesParam,
    usersParam,
    searchParam,
    limitParam,
    statusAprovacaoParam,
    orderByFieldParam,
    orderByDirectionParam
  ]
    .filter((param) => param)
    .join('&');
};