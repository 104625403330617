import styled from 'styled-components';
import { View } from '@components-teammove';

export const ContainerCard = styled(View)`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};	
`;

export const TitleCard = styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    opacity: 0.5;
`;

export const InfoCard = styled.span`
    font-size: 24px;
    font-weight: 700;
    line-height: 32.68px;
`;