import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { getReportProfiles as getReportProfilesSdk, postReportProfiles as postReportProfilesSdk, putReportProfiles as putReportProfilesSdk } from '@sdk/Report';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_REPORT_PROFILES: 'HOME/GET_REPORT_PROFILES',
  GET_REPORT_PROFILES_SUCCESS: 'HOME/GET_REPORT_PROFILES_SUCCESS',
  GET_REPORT_PROFILES_ERROR: 'HOME/GET_REPORT_PROFILES_ERROR',

  POST_REPORT_PROFILES: 'HOME/POST_REPORT_PROFILES',
  POST_REPORT_PROFILES_SUCCESS: 'HOME/POST_REPORT_PROFILES_SUCCESS',
  POST_REPORT_PROFILES_ERROR: 'HOME/POST_REPORT_PROFILES_ERROR',

  PUT_REPORT_PROFILES: 'HOME/PUT_REPORT_PROFILES',
  PUT_REPORT_PROFILES_SUCCESS: 'HOME/PUT_REPORT_PROFILES_SUCCESS',
  PUT_REPORT_PROFILES_ERROR: 'HOME/PUT_REPORT_PROFILES_ERROR',

  RESET: 'HOME/RESET',
};

//Action Creators
export const getReportProfiles = () => ({ type: Types.GET_REPORT_PROFILES });
export const getReportProfilesSuccess = (configProfiles) => ({ type: Types.GET_REPORT_PROFILES_SUCCESS, configProfiles });
export const getReportProfilesError = (error) => ({ type: Types.GET_REPORT_PROFILES_ERROR, error });

export const postReportProfiles = (profiles) => ({ type: Types.POST_REPORT_PROFILES, profiles });
export const postReportProfilesSuccess = (profiles) => ({ type: Types.POST_REPORT_PROFILES_SUCCESS, profiles });
export const postReportProfilesError = (error) => ({ type: Types.POST_REPORT_PROFILES_ERROR, error });

export const putReportProfiles = (configProfiles) => ({ type: Types.PUT_REPORT_PROFILES, configProfiles });
export const putReportProfilesSuccess = (profiles) => ({ type: Types.PUT_REPORT_PROFILES_SUCCESS, profiles });
export const putReportProfilesError = (error) => ({ type: Types.PUT_REPORT_PROFILES_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetReportProfiles(action) {
  try {
    const configProfiles = yield call(getReportProfilesSdk);
    yield put(getReportProfilesSuccess(configProfiles));
  } catch (err) {
    Notification.error(err.message);
    yield put(getReportProfilesError(err));
  }
}

function* fetchPostReportProfiles(action) {
  try {
    const { profiles } = action;
    const profilesAns = yield call(postReportProfilesSdk, profiles);
    yield put(postReportProfilesSuccess(profilesAns));
  } catch (err) {
    Notification.error(err.message);
    yield put(postReportProfilesError(err));
  }
}

function* fetchPutReportProfiles(action) {
  try {
    const { configProfiles } = action;
    const profiles = yield call(putReportProfilesSdk, configProfiles);
    yield put(postReportProfilesSuccess(profiles));
  } catch (err) {
    Notification.error(err.message);
    yield put(postReportProfilesError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_REPORT_PROFILES, fetchGetReportProfiles),
  takeLatest(Types.POST_REPORT_PROFILES, fetchPostReportProfiles),
  takeLatest(Types.PUT_REPORT_PROFILES, fetchPutReportProfiles),
];

// Reducer
const initialState = Map({
  configProfiles: {},
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetReportProfiles = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};
  
const handleGetReportProfilesSuccess = (state, action) => {
  return state
    .set('configProfiles', action.configProfiles)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};
  
const handleGetReportProfilesError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handlePostReportProfiles = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handlePostReportProfilesSuccess = (state, action) => {
  return state
    .set('configProfiles', action.configProfiles)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};
  
const handlePostReportProfilesError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handlePutReportProfiles = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handlePutReportProfilesSuccess = (state, action) => {
  return state
    .set('configProfiles', action.configProfiles)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};
  
const handlePutReportProfilesError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_REPORT_PROFILES: return handleGetReportProfiles(state, action);
    case Types.GET_REPORT_PROFILES_SUCCESS: return handleGetReportProfilesSuccess(state, action);
    case Types.GET_REPORT_PROFILES_ERROR: return handleGetReportProfilesError(state, action);

    case Types.POST_REPORT_PROFILES: return handlePostReportProfiles(state, action);
    case Types.POST_REPORT_PROFILES_SUCCESS: return handlePostReportProfilesSuccess(state, action);
    case Types.POST_REPORT_PROFILES_ERROR: return handlePostReportProfilesError(state, action);

    case Types.PUT_REPORT_PROFILES: return handlePutReportProfiles(state, action);
    case Types.PUT_REPORT_PROFILES_SUCCESS: return handlePutReportProfilesSuccess(state, action);
    case Types.PUT_REPORT_PROFILES_ERROR: return handlePutReportProfilesError(state, action);

    case Types.RESET:
      return handleReset(state, action);
  
    default: return state;
  }
}