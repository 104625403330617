import styled from 'styled-components';
import { Row } from '@components-teammove';

export const Card = styled(Row)`
  background-color: ${({ theme, cardActive, id }) => cardActive === id ? theme.darkPrimaryColor : theme.darkSecondaryColor};
  border: ${({ cardActive, id }) => cardActive === id ? '1px solid var(--super-success-color)' : 'none'};
  border-radius: 218px;
  padding: 16px 24px;
  width: 100%;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.darkPrimaryColor};
  }

`;
