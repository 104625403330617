import styled from 'styled-components';
import View from '../View';
import { Text } from '@components-teammove';

export const MapStyles = styled.div`
  .gm-style-iw.gm-style-iw-c[role='dialog'] {
    

    button span {
      background-color: ${({ theme }) => theme.textColor};
    }
    span{
      color: ${({ theme }) => theme.textColor};
    }

    +.gm-style-iw-tc::after {
      background-color: ${({ theme }) => theme.darkPrimaryColor};
    }
  }
`;

export const InfoWindowView = styled(View)`
  cursor: pointer;
  padding: 5px 10px 5px 0;

  overflow: hidden !important;
`;

export const Title = styled(Text)`
  font-size: ${({ title }) =>  title ? '16px' : '14px' };
  color: ${({ theme }) => theme.textColor} !important;
  font-weight: ${({ title }) => title ? 800 : 400};
`;