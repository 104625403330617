import styled from 'styled-components';
import View from '../View';
import Title from '../Title';
import Icon from '../Icon';

export const SpaceBetweenColumn = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

export const Go = styled(Icon.Bootstrap)`
  align-self: flex-end;
  position: relative;
  
  transform: scale(2);
`;

export const Total = styled(Title)`
  &.ant-typography {
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 0;
  }
`;

export const StatusCard = styled(View)`
  flex: 1;
  flex-direction: row;
  gap: 12px;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.textColor};
  font-size: 20px;
  font-weight: 700;
  border-radius: 18px;
  padding: ${({ padding })=> padding ? padding : '14px 20px'};
  transition: transform .5s;
  cursor: pointer;
  ${({ gridItem }) => !gridItem && `
    :first-child:last-child {
      font-size: 40px;
      font-weight: 600;

      ${Total} {
        font-size: 64px;
        font-weight: 400;
      }

      ${Go} {
        align-self: flex-end;
        bottom: 33px;
      }
    }
  `}

  ${({ setAtTheBottom }) => setAtTheBottom && `
    ${Go} {
      align-self: flex-end;
      right: 0;
    }
  `}

  transform-origin: center;
  :hover {
    transform: scaleY(1.0725);
  }
`;