import React from 'react';
import { TreeSelect } from 'antd';
import styled from 'styled-components';

const TreeSelectStyled = styled(TreeSelect)`
  width: 600px !important;
  max-width: 100%;
`;

const TreeSelectComponent = ({ strategy = TreeSelect.SHOW_PARENT, ...otherProps }) => (
  <TreeSelectStyled allowClear showCheckedStrategy={strategy} dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} {...otherProps} />
);

export default TreeSelectComponent;
