import React, { useState } from 'react';
import { Column, Row, Input, Tag } from '@components-teammove';

const InputTag = ({ tags, handleDelete, handleAddition, placeholder, onChange }) => {
  const [inputValue, setInputValue] = useState();

  const onAdd = (newTagText) => {
    const newTag = { id: Date.now(), text: newTagText };
    handleAddition(newTag);
    onChange([...tags, newTag]);
    setInputValue('');
  };

  const onDelete = (deletedTag, index) => {
    handleDelete(index);
    onChange(tags.filter(({ id }) => id !== deletedTag.id));
  };

  return(
    <Column gap='12px'>
      <Input value={inputValue} onChange={(evt) => setInputValue(evt.target.value)} onPressEnter={({ target }) => onAdd(target.value)} placeholder={placeholder}/>
      <Row gap='4px'>
        {tags?.map(({ id, text }, index) => (
          <Tag key={id} onClose={() => onDelete({ id, text }, index)} closable dark>
            {text}
          </Tag>
        ))}
      </Row>
    </Column>
  );
};

export default InputTag;