import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Column, Grid, Row } from '@components-teammove';
import { Container, CreatedByAt, CustomerLogo, InfoCard, Label, OrderNumber, RegularText, SimpleText, SubTitle, TotalInfoCard } from './styles';
import { formatDate, formatTime, formatCurrency, formatCnpj } from '@utils';
import { useSelector } from 'react-redux';
import { formatAddressInfo } from './rules';

export default function Print() {
  const history = useHistory();
  const location = useLocation();

  const { detailedOrder } = location.state;

  const theme = useSelector(({ app }) => app.get('theme'));

  useEffect(() => {
    window.print();
    setTimeout(() => history.goBack(), 100);
  }, []);

  return(
    <Container>
      <Column gap='28px'>
        <Column gap='14px'>
          <Column gap='18px'>
            <Column gap='12px'>
              <Row justify='space-between'>
                <OrderNumber>Pedido #{detailedOrder.id}</OrderNumber>
                <CustomerLogo src={theme['@global-url-logo'] || process.env.PUBLIC_URL + '/images/logo.png'}/>
              </Row>
              <Column>
                <SubTitle>Criado por</SubTitle>
                <CreatedByAt>{detailedOrder.userName} em {formatDate(detailedOrder.createdAt)} às {formatTime(detailedOrder.createdAt)}</CreatedByAt>
              </Column>
            </Column>
          </Column>
          <Column gap='12px'>
            <Grid gap='8px' templateColumns='1fr 1fr'>
              <Label>Distribuidor</Label>
              <Label>Prazo de entrega</Label>
              <InfoCard align='center'>
                <Column>
                  <SubTitle>{detailedOrder.distributorName || '-'}</SubTitle>
                  <SimpleText>{formatCnpj(detailedOrder.distributorCnpj) || '-'}</SimpleText>
                </Column>
              </InfoCard>
              <InfoCard align='center'>
                <RegularText>{formatDate(detailedOrder.dueDate)}</RegularText>
              </InfoCard>
            </Grid>

            <Column gap='39px'>
              <Column gap='8px'>
                <Label>Cliente</Label>
                <InfoCard>
                  <Column gap='8px'>
                    <Column>
                      <SimpleText>{detailedOrder.companyShortName}</SimpleText>
                      <SubTitle>{detailedOrder.companyName}</SubTitle>
                      <SimpleText>{formatCnpj(detailedOrder.companyCnpj)}</SimpleText>
                    </Column>
                    <Column>
                      <SimpleText>{detailedOrder.companyAddress}</SimpleText>
                      <SimpleText>{formatAddressInfo(detailedOrder.companyDistrict, detailedOrder.companyCity, detailedOrder.companyState, detailedOrder.companyCep)}</SimpleText>
                    </Column>
                  </Column>
                </InfoCard>
              </Column>
            </Column>
          </Column>
        </Column>

        <Grid templateColumns='.5fr .2fr .1fr .2fr' gap='12px'>
          <Label>Produtos</Label>
          <Label>Preço unitário</Label>
          <Label>Qnt</Label>
          <Label>Total do ítem</Label>
          {detailedOrder.items.map((item) => (
            <>
              <InfoCard align='center'>
                <SimpleText>{item.productName}</SimpleText>
              </InfoCard>
              <InfoCard align='center' justify='flex-end'>
                <SimpleText>{formatCurrency(item.productPrice)}</SimpleText>
              </InfoCard>
              <InfoCard align='center' justify='center'>
                <SimpleText>{item.quantity}</SimpleText>
              </InfoCard>
              <InfoCard align='center' justify='flex-end'>
                <SimpleText>{formatCurrency(item.productPrice * item.quantity)}</SimpleText>
              </InfoCard>
            </>
          ))}
          <div/>
          <Column gap='8px'>
            <Label>Peso total</Label>
            <TotalInfoCard justify='flex-end'>
              <Label>{(detailedOrder.totalWeight.toFixed(2)).replace('.', ',')} Kg</Label>
            </TotalInfoCard>
          </Column>
          <Column gap='8px'>
            <Label>Qnt. total</Label>
            <TotalInfoCard justify='center'>
              <Label>{detailedOrder.totalQuantity}</Label>
            </TotalInfoCard>
          </Column>
          <Column gap='8px'>
            <Label>Total pedido</Label>
            <TotalInfoCard justify='flex-end'>
              <Label>{formatCurrency(detailedOrder.totalPrice)}</Label>
            </TotalInfoCard>
          </Column>
        </Grid>
      </Column>
    </Container>
  );
}