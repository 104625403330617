import * as Containers from '@containers';
import { Icon } from '@components-teammove';
import { InfoUsuario } from '@utils';

const campanhaSubmenu = () => {
  return InfoUsuario.perm('campanhasVendasCadastro') ? [{
    path: '/campanhas/resultados',
    component: Containers.CampanhaLista,
    parent: '/campanhas',
    text: 'Visualização',
    match: /^\/campanhas\/resultados?/,
    exact: true,
    sider: true
  }, {
    path: '/campanhas/itens',
    parent: '/campanhas',
    component: Containers.CampanhasVendas,
    text: 'Cadastro',
    match: /^\/campanhas\/itens?/,
    exact: true,
    sider: true
  }, {
    path: '/campanhas/cadastro/:externalIdCampanha',
    component: Containers.CadastroCampanhasVendas,
    match: /^\/campanhas\/cadastro?/,
    exact: true
  }] : [];
};

export default() => {
  return InfoUsuario.perm('campanhaVendas') ? [
    {
      path: '/campanhas',
      icon: Icon.TrophyOutlined,
      component: Containers.CampanhaLista,
      text: 'Campanhas',
      match: /^\/campanhas?/,
      exact: true,
      sider: true,
      secret: true,
    },
    {
      path: '/campanha/:id',
      match: /^\/campanha\/?/,
      component: Containers.CampanhaDetalhe,
      exact: true,
    },
    ...campanhaSubmenu()
  ] : [];
};