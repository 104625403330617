//param: file -> the input file (e.g. event.target.files[0])

import { getDataFromFile, getFileFromUrl } from '..';
import { getIdTemp } from '@utils';
import * as pdfjsLib from 'pdfjs-dist';

//return: images -> an array of images encoded in base64 
export const generateImagesFromPages = async (pdfURL, namePDF) => {
  const fileDoc = await getFileFromUrl(pdfURL, `${namePDF || getIdTemp()}.pdf`);

  const images = [];
  const data = await getDataFromFile(fileDoc);
  const pdf = await pdfjsLib.getDocument(data).promise;
  const canvas = document.createElement('canvas');
  for (let index = 0; index < pdf.numPages; index++) {
    const page = await pdf.getPage(index + 1);
    const viewport = page.getViewport({ scale: 5 + window.devicePixelRatio });
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL('image/jpeg'));
  }
  canvas.remove();
  return images;
};