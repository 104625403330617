import React, { useEffect, useState } from 'react';
import { View, Text, Row, Animations } from '@components-teammove';
import { InfoUsuario, moment } from '@utils';
import { useHistory } from 'react-router-dom';
import { ButtonProgress, CloseIcon, Description, Title, ViewAnimation, IconContainer, IconActivity, RowContainer, ColumnViewText } from './styles';
// eslint-disable-next-line import/extensions
import animationData from '../../../../../../../../assets/TeamMove_Trofeu.json';

const VIEW_MODE_KEY = 'activities_view_mode';

function Mensal({ onClose, metas }) {
  const history = useHistory();
  const [showAnimation, setShowAnimation] = useState(false);
  const totalActivities = metas.reachedActivitiesKinds.reduce((acc, reachedActivityKind) => acc + reachedActivityKind.numberOfFinishedActivities, 0);
  const { reachedAllActivitiesKindsGoals } = metas;
  moment.locale('pt-br');
  const nameMonth = moment().format('MMMM');

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const handleVerMais = () => {
    localStorage.setItem(VIEW_MODE_KEY, 'Meta de atividades');
    history.push('/atividades/agenda', { viewModeFromNotification: 'MENSAL' });
  };

  return (
    <View width='100%'>
      <CloseIcon type='close' onClick={onClose}/>
      <Row gap='10px'>
        <ViewAnimation>
          {showAnimation && <Animations src={animationData} keepLastFrame />}
        </ViewAnimation>
        <ColumnViewText>
          <Title>Parabéns {InfoUsuario.get('nmUsuario')}</Title>
          <Description>Você bateu sua meta de atividades de {nameMonth} fazendo {totalActivities} Atividades</Description>
          {metas.reachedActivitiesKinds.map((reachedActivityKind) => (
            <RowContainer gap='8px' key={reachedActivityKind.id}>
              <IconContainer colorActivity={reachedActivityKind.color}>
                <IconActivity type={reachedActivityKind.icon}/>
              </IconContainer>
              <Row gap='8px'>
                <Description bold>{reachedAllActivitiesKindsGoals ? `${reachedActivityKind.numberOfFinishedActivities}/${reachedActivityKind.numberOfActivitiesGoal.toFixed(2)}` : reachedActivityKind.numberOfFinishedActivities}</Description>
                <Description>{reachedActivityKind.name}</Description>
              </Row>
            </RowContainer>
          ))}
          <ButtonProgress onClick={handleVerMais}>
            <Text>
                  Veja seu progresso este mês
            </Text>
          </ButtonProgress>
        </ColumnViewText>
      </Row>
    </View>
  );
}

export default Mensal;