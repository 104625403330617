import React from 'react';
import styled from 'styled-components';
import { Avatar, Text, View } from '@components';

const Container = styled(View)`
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || 0}px;
`;

const UsuarioContainer = styled(View)`
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 5px;
  border-left: 3px solid ${(props) => props.bordercolor || '#CCC'};
`;

const ChildrenContainer = styled(View)`
  margin-left: 50px;
`;

const Foto = styled(View)`
  justify-content: center;
`;

const Nome = styled(Text)`
  &.ant-typography {
    padding-left: 5px;
    font-weight: 500;
    font-size: 16px;
    ${({ useTextColor, theme }) => useTextColor && `
      color: ${theme.textColor};
    `}
  }
`;

const Perfil = styled(Text)`
  &.ant-typography {
    padding-left: 5px;
    ${({ useTextColor, theme }) => useTextColor && `
      color: ${theme.textColor};
    `}
  }
`;

export default function WikiUsuario({ usuario, marginTop, useTextColor, children }) {
  return (
    <View>
      <Container marginTop={marginTop}>
        <Foto>
          <Avatar size="large" src={usuario.avatar} />
        </Foto>
        <UsuarioContainer bordercolor={usuario.corPerfil}>
          <Nome useTextColor={useTextColor}>{usuario.nome}</Nome>
          <Perfil useTextColor={useTextColor}>{usuario.perfil}</Perfil>
        </UsuarioContainer>
      </Container>
      <ChildrenContainer>{children}</ChildrenContainer>
    </View>
  );
}
