import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Row, Button, Form, Input, InputTag, Checkbox, Text, Modal, Select, View, Skeleton, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { getCamposCustomizaveis, saveCamposCustomizaveis, reset } from '@ducks/configuracoesUnidadesCamposCustomizaveis';
import { getCamposDinamicos, saveCamposDinamicos, saveCampoDinamico } from '@ducks/configuracoesUnidadesCamposDinamicos';
import { CheckboxContainer, FlexContainer, FlexFormItem, CheckboxesContainer, CenteredButton, FlexContainerWithMargin, ColFormItem, MarginLeft, FixedLabelFormItem, FieldContainer, FieldTitle, ColoredDivider } from './styles';
import { customFieldsWithList } from '../../../../Unidades/rules';
import { useHistory } from 'react-router-dom';
import { TelasNomenclaturas } from '@utils';

export default function CamposCustomizaveisCadastro() {
  const [formCustomizaveis] = Form.useForm();
  const [formDinamicos] = Form.useForm();
  const [formNewDinamico] = Form.useForm();

  const history = useHistory();

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const loading = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('loadingSave'));
  const success = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('successSave'));

  const loadingSaveDinamico = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('loadingSave'));
  const successSaveDinamico = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('successSave'));

  const [tags, setTags] = useState({});
  const [newDynamicFieldModal, setNewDynamicFieldModal] = useState(false);

  const camposDinamicos = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('camposDinamicos'));
  const camposCustomizaveis = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('camposCustomizaveis'));
  const loadingCamposDinamicos = useSelector(({ configuracoesUnidadesCamposDinamicos }) => configuracoesUnidadesCamposDinamicos.get('loading'));
  const loadingCamposCustomizaveis = useSelector(({ configuracoesUnidadesCamposCustomizaveis }) => configuracoesUnidadesCamposCustomizaveis.get('loading'));
      
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    dispatch(getCamposDinamicos());
    dispatch(getCamposCustomizaveis()); 
    
    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    formDinamicos.setFieldsValue(camposDinamicos.reduce((state, dynField) => {
      return { ...state, ...Object.keys(dynField).reduce((keysState, key) => ({ ...keysState, [`${dynField.id}-${key}`]: dynField[key] }), {}) };
    }, {}));
  }, [camposDinamicos]);

  useEffect(() => {
    setTags(
      camposCustomizaveis
        .filter((customField) => customFieldsWithList.includes(customField.field))
        .reduce((state, customField) => ({ ...state, [customField.field]: customField.options?.map((tag) => ({ id: tag, text: tag })) || [] }), {})
    );

    formCustomizaveis.setFieldsValue(camposCustomizaveis.reduce((state, customField) => {
      return { ...state, ...Object.keys(customField).reduce((keysState, key) => ({ ...keysState, [`${customField.field}-${key}`]: customField[key] }), {}) };
    }, {}));
  }, [camposCustomizaveis]);

  useEffect(() => {
    if (successSaveDinamico) {
      dispatch(getCamposDinamicos());
      setNewDynamicFieldModal(false);
    }
  }, [successSaveDinamico]);

  useEffect(() => {
    if (success) {
      history.goBack();
    }
  }, [success]);

  const handleNewDynamicField = () => {
    formNewDinamico.validateFields().then((values) => {
      const updatedCampoDinamico = { ...values, alias: values.title, preview: true };
      if (!updatedCampoDinamico.pos) { 
        updatedCampoDinamico.pos = camposDinamicos.length;
      }
      Object.keys(updatedCampoDinamico).forEach((key) => {
        if (updatedCampoDinamico[key] === undefined) updatedCampoDinamico[key] = false;
      });
      dispatch(saveCampoDinamico(updatedCampoDinamico));

      formNewDinamico.resetFields();
    }).catch(Form.scrollToFirstError);
  };
  
  const handleSalvar = async () => {
    const customFieldsValues = await formCustomizaveis.validateFields();
    const dynamicFieldsValues = await formDinamicos.validateFields();

    const options = Object.keys(tags).reduce((state, key) => ({ ...state, [key]: tags[key] && tags[key].reduce((state, { text }) => [...state, text], []) }), {});

    const customFieldsToSend = camposCustomizaveis.map((customField) => {
      const customFieldKeys = Object.keys(customFieldsValues).filter((value) => value.split('-')[0] === customField.field);
      const customFieldToSend = customFieldKeys.reduce((state, key) => ({ ...camposCustomizaveis.find(({ field }) => field === key.split('-')[0]), ...state, [key.split('-')[1]]: customFieldsValues[key] }), {});

      delete customFieldToSend.options;
      if (tags[customField.field]) {
        customFieldToSend.options = options[customField.field].map((option) => option.trim());
      }

      return customFieldToSend;
    });

    const dynamicFieldsToSend = camposDinamicos.map((dynField) => {
      const dynamicFieldKeys = Object.keys(dynamicFieldsValues).filter((value) => parseInt(value.split('-')[0]) === dynField.id);
      return dynamicFieldKeys.reduce((state, key) => ({ ...camposDinamicos.find(({ id }) => id === parseInt(key.split('-')[0])), ...state, [key.split('-')[1]]: dynamicFieldsValues[key] }), {});
    });

    dispatch(saveCamposCustomizaveis(customFieldsToSend));
    dispatch(saveCamposDinamicos(dynamicFieldsToSend));
  };
    
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Campos Personalizados'
          extra={[
            <Button key='1' type='secondary' size='small' context='header' onClick={() => history.goBack()}>
              Cancelar
            </Button>,
            <Button key='2' type='primary' size='small' context='header' onClick={handleSalvar} loading={loading}>
              Salvar
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: 'configuracoes/unidades', name: 'Configurações' },
                { path: 'configuracoes/unidades', name: 'Configurações de Unidades' },
                { path: 'configuracoes/unidades/campos_personalizados', name: 'Campos Personalizados', current: true }
              ]}
            />
          )}
        >
          <Form.Provider>
            <Column>
              <Column gap='12px'>
                {loadingCamposCustomizaveis && <Skeleton.Form/>}
                <Form layout='vertical' form={formCustomizaveis} name='customFields' initialValue={camposCustomizaveis}>
                  <Column gap='12px'>
                    {camposCustomizaveis.map((customField) => (
                      <FieldContainer key={customField.field}>
                        <Row gap='4px' align='center'>
                          <FieldTitle>{customField.alias}</FieldTitle>
                          <ColoredDivider/>
                        </Row>
                        <Column>
                          <Column gap='1.25rem'>
                            <MarginLeft>
                              <Form.Item label='Nome' name={`${customField.field}-name`} rules={[{ required: true, message: 'Informe o campo personalizado!' }]} colon={false}>
                                <Input placeholder='Informe o campo personalizado'/>
                              </Form.Item>
                            </MarginLeft>

                            {customFieldsWithList.includes(customField.field) && (
                              <MarginLeft>
                                <Form.Item label='Lista de valores' name={`${customField.field}-options`}>
                                  <InputTag 
                                    placeholder='Lista de valores'
                                    tags={tags[customField.field]} 
                                    handleDelete={(index2) => setTags({ ...tags, [customField.field]: tags[customField.field].filter((tag, index) => index !== index2) })} 
                                    handleAddition={(tag) => setTags({ ...tags, [customField.field]: [...tags[customField.field], tag] })}
                                  />
                                </Form.Item>
                              </MarginLeft>
                            )}
                          </Column>
                          <CheckboxesContainer>
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${customField.field}-visible`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Ativo</Text>
                              </MarginLeft>
                            </CheckboxContainer>

                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${customField.field}-required`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Obrigatório</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                  
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${customField.field}-spotlight`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Aparece nas Informações d{companyNomenclature.artigo} {companyNomenclature.nomenclatura}</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                          </CheckboxesContainer>
                        </Column>
                      </FieldContainer>
                    ))}
                  </Column>
                </Form>
              </Column>
              <Column gap='12px'>
                {loadingCamposDinamicos && <Skeleton.Form/>}
                <Form layout='vertical' form={formDinamicos} name='dynamicFields' initialValue={camposDinamicos}>
                  <Column gap='8px'>
                    {camposDinamicos.map((dynField) => (
                      <FieldContainer key={dynField.id}>
                        <Row gap='4px' align='center'>
                          <FieldTitle>{dynField.title}</FieldTitle>
                          <ColoredDivider/>
                        </Row>
                        <Column>
                          <MarginLeft>
                            <Form.Item label='Nome' name={`${dynField.id}-title`} rules={[{ required: true, message: 'Informe o nome do campo!' }]} colon={false}>
                              <Input placeholder='Informe o nome do campo'/>
                            </Form.Item>
                          </MarginLeft>

                          <CheckboxesContainer>
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${dynField.id}-active`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Ativo</Text>
                              </MarginLeft>
                            </CheckboxContainer>

                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${dynField.id}-required`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Obrigatório</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                  
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${dynField.id}-previewDetail`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Aparece nas Informações d{companyNomenclature.artigo} {companyNomenclature.nomenclatura}</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                          </CheckboxesContainer>
                        </Column>
                      </FieldContainer>
                    ))}
                  </Column>

                  <View>
                    <CenteredButton type='secondary' icon={<Icon.PlusOutlined/>} onClick={() => setNewDynamicFieldModal(!newDynamicFieldModal)}>Adicionar campo</CenteredButton>
                  </View>
                  <Modal 
                    visible={newDynamicFieldModal} 
                    onCancel={() => {
                      setNewDynamicFieldModal(false);
                      formNewDinamico.resetFields();
                    }} 
                    onOk={handleNewDynamicField}
                    okButtonProps={{ loading: loadingSaveDinamico }}
                  >
                    <Form layout='vertical' form={formNewDinamico} initialValues={{ kind: 'TEXT', required: true, preview: true, previewDetail: true }}>
                      <FixedLabelFormItem label='Tipo' name='kind' rules={[{ required: true, message: 'Informe o tipo!' }]}>
                        <Select>
                          <Select.Option value='TEXT'>Texto</Select.Option>
                          <Select.Option value='IMAGE'>Imagem</Select.Option>
                        </Select>
                      </FixedLabelFormItem>

                      <FlexContainerWithMargin>
                        <ColFormItem label='Campo Personalizável' name='title' rules={[{ required: true, message: 'Informe o nome do campo!' }]}>
                          <Input placeholder='Informe o nome do campo'/>
                        </ColFormItem>
                      </FlexContainerWithMargin>

                      <Form form={formNewDinamico}>
                        <FlexContainer>
                          <Form.Item name='required' label='Obrigatório' valuePropName='checked' labelSide='right' marginless>
                            <Checkbox/>
                          </Form.Item>
                        </FlexContainer>

                        <FlexContainer>
                          <Form.Item name='previewDetail' label={`Aparece nas Informações  d${(companyNomenclature.artigo)} ${companyNomenclature.nomenclatura}`} valuePropName='checked' labelSide='right'>
                            <Checkbox/>
                          </Form.Item>
                        </FlexContainer>
                      </Form>
                    </Form>
                  </Modal>
                </Form>
              </Column>
            </Column>
          </Form.Provider>
        </PageHeader>
      </Body>
    </App>
  );
}