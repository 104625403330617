import React from 'react';
import { View, UserProfile } from '@components-teammove';
import { formatDateTime, getDiffDays, moment, formaDateToNow, formatDate, useSearchParams, getUrlAvatar } from '@utils';
import { useHistory } from 'react-router-dom';
import { Icone, TableAcessos, ViewDias, ViewUltimoAcesso } from './styles';

export default function TableAcessosUsuarios({ data, loading }) {
  const history = useHistory();
  const { dia } = useSearchParams();

  const getIconUltimoDispositivo = (ultimaPlataforma) => {
    switch (ultimaPlataforma) {
      case 'WEB':
        return (
          <View>
            <Icone type='Cloud' color='#1976d2' />
          </View>
        );
      case 'APP':
        return (
          <View>
            <Icone type='Smartphone' color='#f57c00' />
          </View>
        );
      default:
        return;
    }
  };

  const getPeriodo = (dt) => {
    if (!dt) {
      return;
    } else if (formatDate(dt) === formatDate(moment())) {
      return <View>Hoje às {formaDateToNow(dt)}</View>;
    } else if (formatDate(dt) === formatDate(moment().subtract(1, 'day'))) {
      return <View>{formaDateToNow(dt)}</View>;
    } else {
      return (
        <View>
          <View>{formatDateTime(dt)}</View>
          <ViewDias atrasado={getDiffDays(dt) > 30}>Há {getDiffDays(dt)} dias</ViewDias>
        </View>
      );
    }
  };

  const columns = [
    {
      title: 'Usuário',
      dataIndex: 'nmUsuario',
      key: 'nmUsuario',
      sorter: true,
      render: (text, item) => {
        return (
          <UserProfile
            name={item.nmUsuario + (item?.statusUsuario === 'I' ? ' - Inativo' : '')}
            profile={item.dsPerfil}
            profileColor={item.corPerfil}
            src={getUrlAvatar(item.avatarUsuario)}
            darkened={!item.ultimoAcesso}
          />
        );
      },
    },
    {
      title: 'App',
      dataIndex: 'qtAcessoApp',
      key: 'qtAcessoApp',
      align: 'center',
      sorter: (a, b) => a.qtAcessoApp - b.qtAcessoApp,
      render: (text) => (text === 0 ? '-' : text),
    },
    {
      title: 'Web',
      dataIndex: 'qtAcessoWeb',
      key: 'qtAcessoWeb',
      align: 'center',
      sorter: (a, b) => a.qtAcessoWeb - b.qtAcessoWeb,
      render: (text) => (text === 0 ? '-' : text),
    },
    {
      title: 'Acessos',
      dataIndex: 'qtAcesso',
      key: 'qtAcesso',
      sorter: (a, b) => a.qtAcessoApp + a.qtAcessoWeb - (b.qtAcessoApp + b.qtAcessoWeb),
      align: 'center',
      render: (text, item) => (item.qtAcessoWeb + item.qtAcessoApp === 0 ? '-' : item.qtAcessoWeb + item.qtAcessoApp),
    },
    {
      title: 'Último Acesso',
      dataIndex: 'ultimoAcesso',
      key: 'ultimoAcesso',
      align: 'center',
      sorter: true,
      render: (text, item) => (
        <ViewUltimoAcesso>
          {getPeriodo(text)}
          {getIconUltimoDispositivo(item.ultimaPlataforma)}
        </ViewUltimoAcesso>
      ),
    },
  ];

  const handleSelection = (data) => {
    history.push(`/gestao-equipe/usuario/${data.cdUsuario}${dia ? `?dia=${dia}` : ''}`);
  };

  return (
    <TableAcessos
      rowKey='cdUsuario'
      columns={columns}
      dataSource={data}
      loading={loading}
      hidePagination
      onRow={(data) => {
        return {
          onClick: () => {
            handleSelection(data);
          },
        };
      }}
      rowClassName={(record) => record?.statusUsuario === 'I' ? 'disabled-user-team-management-row' : null}
    />
  );
}
