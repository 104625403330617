import React from 'react';
import { Delete, StyledButton } from './styles';
import { Icon , Row } from '@components-teammove';

const Button = ({ children, type = 'primary', visible = true, ...props }) => {
  if(!visible) return null;
  return (
    <StyledButton type={type} {...props}>{children}</StyledButton>
  );
};

Button.Delete = ({ fixed = true, placement = 'top-right', ...props }) => (
  <Delete fixed={fixed} placement={placement} icon={<Icon.CloseOutlined/>} shape='circle' {...props}/>
);

Button.Group = ({ children }) => (
  <Row gap='.5rem'>{children}</Row>
);

export default Button;