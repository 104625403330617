import styled from 'styled-components';
import { View, Upload, Input } from '@components';

export const ImageContainer = styled(View)`
    margin-top: 15px;
`;

export const UploadView = styled(View)`
  display: flex;
  flex-direction: row;

`;

export const UploadStyled = styled(Upload)`
  margin-right: 15px;
`;

export const UploadInput = styled(Input)``;