import styled from 'styled-components';

export const Padding = styled.div`
  padding: 0 7.5rem;
`;

export const DashTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;