import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, Switch, Skeleton } from '@components-teammove';
import { getTipoUnidade, saveTipoUnidade , resetSuccess , deleteTipoUnidade } from '@ducks/configuracoesUnidadesTiposUnidades';
import { useDispatch, useSelector } from 'react-redux';
import { PaddingContainer } from './styles';
import { TelasNomenclaturas } from '@utils';

export default function TiposCadastro({ onCancel, visible }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const tipoUnidade = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tipoUnidade'));
  const loading = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loading'));
  const success = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('success'));
  const loadingSave = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loadingSave'));
  const successSave = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successDelete'));

  const [originalFieldsValue, setOriginalFieldsValue] = useState();

  const id = location.state?.tipoUnidade.id;

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    if (id) handleGetTipoUnidade();

    return () => {
      dispatch(resetSuccess());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success && id) {
      form.setFieldsValue(tipoUnidade);

      setOriginalFieldsValue(form.getFieldsValue());
    }
  }, [success]);

  useEffect(() => {
    if (successSave || successDelete) {
      onCancel();
    }
  }, [successSave, successDelete]);

  const handleGetTipoUnidade = () => {
    dispatch(getTipoUnidade(id));
  };

  const handleDelete = ({ id }) => {
    dispatch(deleteTipoUnidade(id));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      if (id) values = { ...values, id };
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined) {
          values[key] = false;
        }
      });
      dispatch(saveTipoUnidade(values));
    });
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title={'Configurações / ' +  (companyNomenclature.plural) + ' / Tipos de unidades'}
      numberOfButtons={id ? 2 : 1}
      cancelText='Excluir'
      onBeforeCancel={onBeforeCancel}
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: `Deseja realmente excluir o tipo de ${(companyNomenclature.nomenclatura).toLowerCase()}?`, onConfirm: () => handleDelete(tipoUnidade) }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null, title: tipoUnidade?.inUseByCompanies ? `Tipo em uso por ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ou mais ${(companyNomenclature.plural).toLowerCase()}` : `Excluir tipo de ${(companyNomenclature.nomenclatura).toLowerCase()}`, disabled: tipoUnidade?.inUseByCompanies }}
      onOk={handleSalvar}
      okButtonProps={{ loading: loadingSave }}
    >
      {loading ? (
        <PaddingContainer>
          <Skeleton.Form/>
        </PaddingContainer>
      ) : (
        <Form layout='vertical' form={form}>
          <Form.Item label='Nome' name='name' rules={[{ required: true, message: 'Informe o tipo!' }]}>
            <Input placeholder='Informe o tipo' autoFocus/>
          </Form.Item>
            
          <Form.Item label='Inserir meta manualmente?' name='insertGoalManually' valuePropName='checked'>
            <Switch/>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
