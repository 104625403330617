import React from 'react';

import { Column, Row, Text } from '@components-teammove';

import { ButtonStyle, Cards, DivGround, FinishFlagStyle, IconStyle, ListStyle, LogoGoals, SubText } from './styles';
import { CardActivityGroup } from '../CardActivityGroup';
import { useHistory } from 'react-router-dom';
import { Session } from '@utils';

export const EmptyGoalsCards = () => {
  const history = useHistory();

  return(
    <>
      <Column gap='12px'>
        <Cards gap='42px' padding='20px 0 0 0'>
          <Column gap='16px' width='100%' padding='0 0 0 20px'>
            <Text size='24px' weight='700'>Não encontramos metas de atividades cadastradas para sua equipe :(</Text>
            <SubText size='20px' weight='400' lowOpacity>Cadastre a meta mensal de atividades para sua equipe e acompanhe o desempenho em tempo real</SubText>
          </Column>
         
          <LogoGoals>
            <IconStyle type='run-fast' size='64px'/>
            <DivGround/>
            <FinishFlagStyle row={7}/>
          </LogoGoals>
          <ButtonStyle onClick={() => { Session.set('CONFIG_TICKETS_TAB', 'Metas de atividades');history.push('/configuracoes/atividades');}}>Cadastrar metas de atividades</ButtonStyle> 
          
        </Cards>
        <Row gap='12px'>
          <Cards padding='20px 20px 20px 20px' gap='12px'>
            <Row justify='space-between'>
              <Column width='50%' gap='12px'>
                <Text size='22px' weight='700'>Quais os benefícios de definir metas para as atividades da minha equipe?</Text>
                <ul>
                  <ListStyle><Text size='16px' weight='400' lowOpacity>Aumento na produtividade e eficiência individual e coletiva da sua equipe.</Text></ListStyle>
                  <ListStyle><Text size='16px' weight='400' lowOpacity>Painel de dados individual e coletivo para medir o desempenho dos seus consultores / representantes.</Text></ListStyle>
                  <ListStyle><Text size='16px' weight='400' lowOpacity>Incentivo baseado em dados precisos</Text></ListStyle>
                </ul>
              </Column>
              <CardActivityGroup icon='briefcase' color='#E91E63' isExample />
            </Row>
          </Cards>

          <Cards width='40%' padding='20px 20px 0 20px' gap='15px'>
            <Text size='22px' weight='700'>Configuração simples e rápida!</Text>
            <Column>
              <Text size='16px' weight='700' lowOpacity>Cadastre a meta uma única vez!</Text>
              <Text size='16px' weight='400' lowOpacity>No próximo mês sua meta será preenchida automaticamente com os mesmos números e caso você queira, poderá ajustar a meta da sua equipe individualmente ou através do perfil de usuários de forma dinâmica.</Text>
            </Column>
          </Cards>
        </Row>
       
      </Column>
     
    </>
  );
};