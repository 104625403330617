import React from 'react';
import { View  } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';
import types from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { postComentarioAcao } from '@ducks/wikisComentarioAcao';

const ViewAcoes = styled(View)`
  cursor: pointer;
  color: #aaaaaa;
  :hover {
    color: #000000;
  }
`;

const MDIIcon = styled(Icon.MDI)`
  color: ${({ selected }) => (selected ? '#000000' : '#aaaaaa')};
`;

const Acao = styled(View)`
  color: #aaaaaa;
  margin-right: 10px;
  align-items: center;

  :hover {
    color: #000000;
  }
`;

export default function AcoesUsuario({ data, handlers }) {
  const { row, comentarios } = data;
  const { id, qtLike, qtDislike, acao } = row;
  const dispatch = useDispatch();

  const postingComentarioAcao = useSelector(({ wikisComentarioAcao }) => wikisComentarioAcao.get('postingComentarioAcao'));

  const handleAcaoComentario = (id, tipoAcao) => {
    const comentariosCopy = [...comentarios];
    const comentario = comentariosCopy.filter((cmt) => cmt.id === id)[0];
    const qt = tipoAcao === types.acao.LIKE ? 'qtLike' : 'qtDislike';
    const qtInverso = tipoAcao !== types.acao.LIKE ? 'qtLike' : 'qtDislike';

    if (acao === tipoAcao) {
      comentario.acao = null;
      comentario[qt] -= 1;
      dispatch(postComentarioAcao({ idComentario: id, acao: types.acao.NONE }));
    } else {
      comentario.acao = tipoAcao;
      comentario[qt] += 1;
      if (acao && acao !== null) comentario[qtInverso] -= 1;

      dispatch(postComentarioAcao({ idComentario: id, acao: tipoAcao }));
    }

    handlers.setComentarios(comentariosCopy);
  };

  const IconAcao = ({ qt, acao, id, tipoAcao }) => {
    const selected = acao === tipoAcao;
    const type = tipoAcao === types.acao.LIKE ? 'thumb-up' : 'thumb-down';

    return (
      <Acao horizontal>
        <MDIIcon
          type={type}
          size={0.7}
          selected={selected}
          onClick={(event) => handlers.click(event, () => handleAcaoComentario(id, tipoAcao, acao))}
        />
        &nbsp;
        {qt && qt > 0 ? qt : ''}
      </Acao>
    );
  };

  return (
    <View>
      <ViewAcoes horizontal disabled={postingComentarioAcao}>
        <IconAcao tipoAcao={types.acao.LIKE} qt={qtLike} acao={acao} id={id} />
        <IconAcao tipoAcao={types.acao.DISLIKE} qt={qtDislike} acao={acao} id={id} />
      </ViewAcoes>
    </View>
  );
}
