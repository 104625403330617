import React from 'react';
import { Progress } from '@components-teammove';
import { Container } from './styles';
import { getColorByPercentage, getContentByPercentage } from './rules';
import { useSelector } from 'react-redux';

const Progression = ({ total, achieved = 0, achievedGoal, useStatus = false, width = 60 }) => {
  const theme = useSelector(({ app }) => app.get('theme'));
  
  return(
    <Container>
      <Progress 
        type="circle" 
        useTextColor 
        percent={(achieved / total) * 100} 
        width={width } 
        status={useStatus ? (achievedGoal ? 'success' : 'exception') : 'normal'}
        text={`${achieved}/${total}`}
        format={useStatus ? (() => getContentByPercentage((achieved / total) * 100)) : (() => `${achieved}/${total}`)}
        strokeColor={getColorByPercentage((achieved / total) * 100, theme)}
      />
    </Container>
  );
};

export default Progression;