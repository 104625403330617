import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Tabs, Button } from '@components-teammove';
import { Session } from '@utils';
import TipoDePedidos from './TipoPedidos';

export default function ConfiguracoesPedidos() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_ORDER_TAB') || 'Tipos de pedidos');

  const [visibleOrderKindModal, setVisibleOrderKindModal] = useState(false);

  const NovoPedido = ()=> [
    <Button key="1" type="primary" size="small" context="header" onClick={() => setVisibleOrderKindModal(true)}>Novo tipo de pedido</Button>
  ];

  const handleChangeTab = (newTab) =>{
    setCurrentTab(newTab);
    Session.set('CONFIG_PRODUCT_TAB', newTab);
  };
  
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Pedidos'}
          extra={currentTab === 'Tipos de pedidos' ? <NovoPedido/> : null}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/pedido', name:'Configurações de Pedidos', current: true }
              ]}    
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Tipos de pedidos' }]} current={currentTab} onChange={handleChangeTab}/>
            <TipoDePedidos visibleOrderKindModal={visibleOrderKindModal} setVisibleOrderKindModal={setVisibleOrderKindModal}/>
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}