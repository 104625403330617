import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Button, Form, Input, Body, PageHeader } from '@components-teammove';
import { InfoUsuario } from '@utils';
import { alterarSenha, reset } from '@ducks/alterarSenha';

const layoutForm = {
  xs: { span: 24 },
  md: { span: 18 },
  lg: { span: 14 },
  xl: { span: 10 },
  xxl: { span: 6 },
};

export default function AlterarSenha({ history }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const loading = useSelector(({ alterarSenha }) => alterarSenha.get('loading'));
  const success = useSelector(({ alterarSenha }) => alterarSenha.get('success'));

  useEffect(() => {
    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (success) {
      history.goBack();
    }
  }, [history, success]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      dispatch(alterarSenha(InfoUsuario.get('cdUsuario'), values.oldPassword, values.newPassword));
    });
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Alterar Senha"
          extra={[
            <Button key="1" size='small' type="secondary" onClick={() => history.goBack()}>Cancelar</Button>,
            <Button key="2" size='small' type="primary" onClick={handleSalvar} loading={loading}>Alterar</Button>,
          ]}
        >
          <Form layout="vertical" form={form} wrapperCol={layoutForm}>
            <Form.Item label="Senha Atual" name="oldPassword" rules={[{ required: true, message: 'Informe a senha atual!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item label="Nova Senha" name="newPassword" hasFeedback rules={[{ required: true, message: 'Informe a nova senha!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirmar Nova Senha"
              name="confirmPassword"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                { required: true, message: 'Informe a nova senha novamente!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('A confirmação da senha não corresponde à nova senha!');
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </PageHeader>
      </Body>
    </App>
  );
}
