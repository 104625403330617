import React, { useEffect, useState } from 'react';
import { Table, Button, Text, Grid, Row } from '@components-teammove';
import { getAllUnidades } from '@sdk/Unidades';
import { useHistory } from 'react-router-dom';
import AfastamentoCadastro from './Cadastro';
import { TelasNomenclaturas } from '@utils';

const RegraFolga = () => {
  const [loading, setLoading] = useState(true);
  const [regras, setRegras] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [visibleRegister, setVisibleRegister] = useState(false);
  const history = useHistory();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    load();
  }, []);

  async function load() {
    setLoading(true);
    const unidades = await getAllUnidades();

    const unidadesSimplificadas = unidades.map(
      ({
        cdEmpresa,
        nmReduzido,
        ativo,
        cadastroMetaEscalaRegraDomingo,
        cadastroMetaEscalaRegraFolga,
        cadastroMetaEscalaRegraFeriado,
      }) => ({
        cdEmpresa,
        nome: nmReduzido,
        ativo,
        cadastroMetaEscalaRegraDomingo,
        cadastroMetaEscalaRegraFolga,
        cadastroMetaEscalaRegraFeriado,
      })
    );

    const unidadesComRegras = unidadesSimplificadas.filter(
      (unidade) =>
        unidade.ativo &&
        (!!unidade.cadastroMetaEscalaRegraDomingo || !!unidade.cadastroMetaEscalaRegraFolga || !!unidade.cadastroMetaEscalaRegraFeriado)
    );

    const regrasCadastro = [
      {
        tipo: 1,
        regra: 'Bloqueio domingos consecutivos',
        campo: 'cadastroMetaEscalaRegraDomingo',
        unidades: unidadesComRegras.filter((unidade) => !!unidade.cadastroMetaEscalaRegraDomingo),
      },
      {
        tipo: 2,
        regra: 'Bloqueio dias consecutivos',
        campo: 'cadastroMetaEscalaRegraFolga',
        unidades: unidadesComRegras.filter((unidade) => !!unidade.cadastroMetaEscalaRegraFolga),
      },
      {
        tipo: 3,
        regra: 'Bloqueio feriados consecutivos',
        campo: 'cadastroMetaEscalaRegraFeriado',
        unidades: unidadesComRegras.filter((unidade) => !!unidade.cadastroMetaEscalaRegraFeriado),
      },
    ];

    setUnidades(unidadesSimplificadas);
    setRegras(regrasCadastro);
    setLoading(false);
  }

  const columns = [
    {
      title: 'Regra',
      dataIndex: 'regra',
      sorter: true,
      render: (text, regra) => (
        <Grid numberOfColumns={2} align='center'>
          {regra.regra}
          <Row justify='space-between' align='center'>
            <Text singular={(companyNomenclature.nomenclatura).toLowerCase()} count={regra.unidades.length} zeroText={'Sem ' + (companyNomenclature.plural).toLowerCase()} />
            <Button
              type='secondary'
              size='small'
              context='list'
            >
              Editar
            </Button>
          </Row>
        </Grid>
      ),
    },
  ];

  const handleButtonClick = (regra) => {
    history.push('?regra=' + regra.tipo, { regra: regra, unidades: unidades });
    setVisibleRegister(true);
  };

  return (
    <>
      <Table columns={columns} dataSource={regras} loading={loading} rowKey='tipo' status='ativo' onRow={(regra) => ({ onClick: () => handleButtonClick(regra) })}  />
      {visibleRegister && (
        <AfastamentoCadastro
          visible={visibleRegister}
          onCancel={() => {
            setVisibleRegister(false);
            history.goBack();
          }}
          onSuccessSave={load}
        />
      )}
    </>
  );
};

export default RegraFolga;
