import React, { useState, useEffect } from 'react';
import App from '@app/';
import { Body, View, PageHeader, Button, Radio, Skeleton, ActiveFilters, Icon } from '@components-teammove';

import { getAcessosPerfil, reset, FILTERS_KEY } from '@ducks/configuracoesGestaoEquipe';
import { useDispatch, useSelector } from 'react-redux';
import { moment, getNomeMesData, useSearchParams, Filtros as FiltroStorage, formatDate } from '@utils';
import { ColumnChartStyle , RadioGroupStyled, FilterStyle, ViewFiltroDia, ViewFiltroDiaFechar } from './styles';
import { typeFiltroDado, typeFiltroPlataforma } from '../rules';
import TableAcessosPerfis from './TableAcessosPerfis';
import { getUsuario, reset as resetUsuario } from '@ducks/configuracoesUsuarios';
import { filters } from './rules';
import { useHistory } from 'react-router-dom';

export default function GestaoEquipe() {
  const dispatch = useDispatch();
  const { usuario } = useSearchParams();
  const [filtroTipoPlataforma, setFiltroTipoPlataforma] = useState('');
  const [filtroTipoDado, setFiltroTipoDado] = useState('');
  const [visibleFilters, setVisibleFilters] = useState(false);
  const theme = useSelector(({ app }) => app.get('theme'));

  const history = useHistory();
  const { dia } = useSearchParams();

  const filtrosAcessos = { data: [moment().subtract(6, 'months').startOf('month'), moment()], usuario };

  const acessosPerfil = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('acessosPerfil').toArray());
  const successAcessosPerfil = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('successAcessosPerfil'));

  const usuarioData = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('usuario'));

  const filtros = FiltroStorage.get(FILTERS_KEY);

  const handleGetAcessos = (filtrosPerfil) => {
    dispatch(getAcessosPerfil({ filtros: { ...filtrosPerfil, save: usuario ? false : true } }));
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(resetUsuario());
    handleGetAcessos({ ...filtros, ...filtrosAcessos });

    if (usuario) {
      dispatch(getUsuario({ cdUsuario: usuario }));
    }
  }, []);

  const handleFiltros = () => {
    const filtroPlataforma = localStorage.getItem('gestaoEquipeFiltroTipoPlataforma');
    const filtroDado = localStorage.getItem('gestaoEquipeFiltroTipoDado');

    if (filtroPlataforma) {
      handleChangeFiltroTipoPlataforma(filtroPlataforma);
    } else {
      handleChangeFiltroTipoPlataforma(typeFiltroPlataforma.TODOS);
    }

    if (filtroDado) {
      handleChangeFiltroTipoDado(filtroDado);
    } else {
      handleChangeFiltroTipoDado(typeFiltroDado.ACESSOS);
    }
  };

  useEffect(() => {
    handleFiltros();
  }, []);

  useEffect(() => {
    if (!successAcessosPerfil) return;
    handleFiltros();
  }, [successAcessosPerfil]);

  const handleChangeFiltroTipoPlataforma = (data) => {
    if (data) {
      localStorage.setItem('gestaoEquipeFiltroTipoPlataforma', data);
      setFiltroTipoPlataforma('');
      setFiltroTipoPlataforma(data);
    }
  };

  const handleChangeFiltroTipoDado = (data) => {
    if (data) {
      localStorage.setItem('gestaoEquipeFiltroTipoDado', data);
      setFiltroTipoDado('');
      setFiltroTipoDado(data);
    }
  };

  const getChartData = () => {
    const result = acessosPerfil.map((item) => {
      let result = {
        perfil: item.dsPerfil,
        mes: getNomeMesData(item.date),
        date: item.date,
      };

      switch (filtroTipoDado) {
        case typeFiltroDado.ACESSOS:
          switch (filtroTipoPlataforma) {
            case typeFiltroPlataforma.TODOS:
              result.qtAcesso = item.qtAcessoApp + item.qtAcessoWeb;
              break;
            case typeFiltroPlataforma.APP:
              result.qtAcesso = item.qtAcessoApp;
              break;
            case typeFiltroPlataforma.WEB:
              result.qtAcesso = item.qtAcessoWeb;
              break;
            default:
              return false;
          }
          break;
        case typeFiltroDado.USUARIOS:
          switch (filtroTipoPlataforma) {
            case typeFiltroPlataforma.TODOS:
              result.qtAcesso = item.qtUsuario;
              break;
            case typeFiltroPlataforma.APP:
              result.qtAcesso = item.qtUsuarioApp;
              break;
            case typeFiltroPlataforma.WEB:
              result.qtAcesso = item.qtUsuarioWeb;
              break;
            default:
              return false;
          }
          break;
        default:
          return false;
      }

      return result;
    });

    return result;
  };

  const getColors = () => {
    if (filtroTipoDado === '' || filtroTipoPlataforma === '') return;
    let result = [];

    acessosPerfil.forEach((acesso) => {
      if (result.filter((re) => re.ds === acesso.dsPerfil).length === 0) {
        result.push({ ds: acesso.dsPerfil, cor: acesso.corPerfil });
      }
    });

    return result.map((item) => item.cor);
  };

  const handleSearch = (filtros) => {
    handleGetAcessos({ ...filtros, ...filtrosAcessos });
  };

  const chartParams = {
    height: 350,
    stacked: true,
    forceFit: true,
    yAxis: {
      min: 0,
    },
    label: {
      visible: false,
    },
    color: getColors(),
    xField: 'mes',
    yField: 'qtAcesso',
    stackField: 'perfil',
    legend: {
      visible: true,
      position: 'right-top',
      flipPage: true,
      marker: { symbol: 'circle' },
    }, 
    renderer:'svg',
  };
 
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={
            usuarioData && usuarioData.nm_usuario
              ? `Acessos - Últimos 6 meses - ${usuarioData.nm_usuario}`
              : 'Acessos - Últimos 6 meses'
          }
          extra={
            !usuario && [
              <Button type='secondary' size='small' key='1' icon={<Icon.MDI type='filter-outline'/>} onClick={() => setVisibleFilters(true)}>
                Filtrar
              </Button>,
            ]
          }
          onBack={() => window.history.back()}
        >
          <ActiveFilters filters={filters} filtros={filtros} onSearch={handleSearch} />
          {dia && (
            <ViewFiltroDia>
              <View>Data: {formatDate(dia)}</View>
              <ViewFiltroDiaFechar onClick={() => history.push('/visao-mensal')}>X</ViewFiltroDiaFechar>
            </ViewFiltroDia>
          )}

          <View>
            <View alignItems='center'>

              <RadioGroupStyled value={filtroTipoDado} onChange={(data) => handleChangeFiltroTipoDado(data)}>
                <Radio value={typeFiltroDado.ACESSOS} key={1}>Acessos</Radio>
                <Radio value={typeFiltroDado.USUARIOS} key={2}>Usuários</Radio>
              </RadioGroupStyled>

              <Radio.Group value={filtroTipoPlataforma} onChange={(data) => handleChangeFiltroTipoPlataforma(data)}>
                <Radio value={typeFiltroPlataforma.TODOS}>Todos</Radio>
                <Radio value={typeFiltroPlataforma.WEB}>Web</Radio>
                <Radio value={typeFiltroPlataforma.APP}>App</Radio>
              </Radio.Group>

            </View>

            {successAcessosPerfil && filtroTipoPlataforma !== '' && filtroTipoDado !== '' ? (
              <View>
                <View>
                  <ColumnChartStyle height={350} pointer params={chartParams} data={getChartData()} />
                </View>
                <View>
                  <TableAcessosPerfis data={acessosPerfil} filtroTipoDado={filtroTipoDado} filtroTipoPlataforma={filtroTipoPlataforma} />
                </View>
              </View>
            ) : (
              <Skeleton.Map/>
            )}
          </View>
        </PageHeader>
        <FilterStyle visible={visibleFilters} onClose={() => setVisibleFilters(false)} filters={filters} filtros={filtros} onSearch={handleSearch} />
      </Body>
    </App>
  );
}
