import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Skeleton, Icon, Tooltip, Column, Text } from '@components-teammove';
import { getCards } from '@ducks/pedidosDashboard';
import { ContainerCard, InfoCard, TitleCard } from './styles';
import { formatCurrency, formatDecimal } from '@utils';

const Cards = ({ currentFilters, currentParams }) => {
  const dispatch = useDispatch();

  const cards = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('cards'));
  const loadingCards = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingCards'));
  const successCards = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('successCards'));

  useEffect(() => {
    if(Object.keys(currentFilters).length === 0 && Object.keys(currentParams).length === 0) return;
    dispatch(getCards({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);
  
  return (
    <Row gap='10px' >
      {!loadingCards && successCards ? (
        <>
          <ContainerCard>
            <TitleCard>Faturamento</TitleCard>
            <Row align='center' gap='8px'>
              <InfoCard>{formatCurrency(cards.turnover)}</InfoCard> 
              {currentParams?.typePeriod !== 'TODOS' && (
                <Tooltip title={
                  <Column>
                    <Text>Periodo anterior: {formatCurrency(cards.previousIndicatorTurnover)}</Text>
                    <Text>Diferença: {formatCurrency(cards.direferenceBetweenIndicatorTurnover)}</Text>
                    <Text>Percentual: {cards.percentChangeTurnover}</Text>
                  </Column>                    
                }
                >
                  <Icon.MDI type={cards.indicatorTurnover === 'sustained' ? 'circle' : 'triangle'} size={cards.indicatorTurnover === 'sustained' ? 0.6 : 1} color={cards.indicatorTurnover === 'high' ? 'green' : cards.indicatorTurnover === 'low' ? 'red' : 'yellow'} rotate={cards.indicatorTurnover === 'low' ? 180 : 0}/>
                </Tooltip>
              )}
            </Row>
          </ContainerCard>

          <ContainerCard>
            <TitleCard>Quantidade de pedidos</TitleCard>
            <Row align='center' gap='8px'>
              <InfoCard>{cards.quantityOrders}</InfoCard>
              {currentParams?.typePeriod !== 'TODOS' && (
                <Tooltip title={
                  <Column>
                    <Text>Periodo anterior: {cards.previousQuantityOrders}</Text>
                    <Text>Diferença: {cards.diferenceBetweenQuantityOrders}</Text>
                    <Text>Percentual: {cards.percentChangeQuantityOrders}</Text>
                  </Column>  
                }
                >
                  <Icon.MDI type={cards.indicatorQuantityOrders === 'sustained' ? 'circle' : 'triangle'} size={cards.indicatorQuantityOrders === 'sustained' ? 0.6 : 1} color={cards.indicatorQuantityOrders === 'high' ? 'green' : cards.indicatorQuantityOrders === 'low' ? 'red' : 'yellow'} rotate={cards.indicatorQuantityOrders === 'low' ? 180 : 0}/>
                </Tooltip>
              )}
            </Row>
          </ContainerCard>

          <ContainerCard>
            <TitleCard>Ticket médio</TitleCard>
            <Row align='center' gap='8px'>
              <InfoCard>{formatCurrency(cards.averageTicket)}</InfoCard>
              {currentParams?.typePeriod !== 'TODOS' && (
                <Tooltip title={
                  <Column>
                    <Text>Periodo anterior: {formatCurrency(cards.previousAverageTicket)}</Text>
                    <Text>Diferença: {formatCurrency(cards.diferenceBetweenAverageTicket)}</Text>
                    <Text>Percentual: {cards.percentChangeAverageTicket}</Text>
                  </Column>  
                }
                >
                  <Icon.MDI type={cards.indicatorAverageTicket === 'sustained' ? 'circle' : 'triangle'} size={cards.indicatorAverageTicket === 'sustained' ? 0.6 : 1} color={cards.indicatorAverageTicket === 'high' ? 'green' : cards.indicatorAverageTicket === 'low' ? 'red' : 'yellow'} rotate={cards.indicatorAverageTicket === 'low' ? 180 : 0}/>
                </Tooltip>
              )}
            </Row>
          </ContainerCard>

          <ContainerCard>
            <TitleCard>Peso</TitleCard>
            <Row align='center' gap='8px'>
              <InfoCard>{formatDecimal(cards.totalWeight, 1)} Kg</InfoCard>
              {currentParams?.typePeriod !== 'TODOS' && (
                <Tooltip title={
                  <Column>
                    <Text>Periodo anterior: {cards.previousTotalWeight} Kg</Text>
                    <Text>Diferença: {cards.diferenceBetweenTotalWeight} Kg</Text>
                    <Text>Percentual: {cards.percentChangeTotalWeight}</Text>
                  </Column>  
                }
                >
                  <Icon.MDI type={cards.indicatorTotalWeight === 'sustained' ? 'circle' : 'triangle'} size={cards.indicatorTotalWeight === 'sustained' ? 0.6 : 1} color={cards.indicatorTotalWeight === 'high' ? 'green' : cards.indicatorTotalWeight === 'low' ? 'red' : 'yellow'} rotate={cards.indicatorTotalWeight === 'low' ? 180 : 0}/>
                </Tooltip>
              )}
            </Row>
          </ContainerCard>
        </>
      ) : (
        <>
          <Skeleton.LargeBox/>
          <Skeleton.LargeBox/>
          <Skeleton.LargeBox/>
          <Skeleton.LargeBox/>
        </>
      )}
    </Row>
  );
};

export default Cards;