import styled from 'styled-components';
import { Text, Form, Icon } from '@components-teammove';

export const SubTitle = styled(Text)`
  font-size: 16px;
  opacity: 0.5;
  font-weight: 400;
  line-height: 21.79px;
  margin-top: ${({ marginSubTitle }) => marginSubTitle ? '20px' : '0'};
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  margin-top: ${({ marginTitle }) => marginTitle ? '20px' : '0'};
`;

export const FormContainer = styled(Form)`
    margin-top: 8px;
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FormDialer = styled(Form.Item)`
    margin: 0;
`;

export const FormInputMask = styled(Form.Item)`
  width: 100%;
`;

export const ActionButton = styled.div`
    background-color: ${({ theme, callingButton }) => callingButton ? 'var(--success-color)' : theme.darkSecondaryColor};
    padding: 13px 30px;
    border-radius: 12px;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: ${({ callingButton }) => callingButton ? '10px' : '0'};
    cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
    opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
`;

export const IconMDI = styled(Icon.MDI)`
    color: ${({ theme }) => theme.textColor};
    width: 17px !important;
    height: 17px !important;
`;

export const SwitchFormItem = styled(Form.Item)`
  margin: 0;
  width: 100%;
  flex: 0;
`;