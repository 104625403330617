export const linkify = (inputText, underline = false) => {
  let replacedText = '';

  //URLs starting with http://, https://, or ftp://
  const replacePatternURL = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(replacePatternURL, `<a href="$1" target="_blank" style="text-decoration: ${underline ? 'underline' : 'none'};">$1</a>`);

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePatternOldURL = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePatternOldURL, `$1<a href="http://$2" target="_blank" style="text-decoration: ${underline ? 'underline' : 'none'};">$2</a>`);

  //Change email addresses to mailto:: links.
  const replacePatternEmail = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePatternEmail, '<a href="mailto:$1">$1</a>');

  return replacedText;
};