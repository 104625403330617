import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { InfoUsuario } from '@utils';

const bugsnagClient = bugsnag({
  apiKey: '971c3e679aa3139e55446ef71fd68a9f',
  notifyReleaseStages: ['production'],
});
bugsnagClient.use(bugsnagReact, React);

const notify = (err) => {
  let infoUsuario = {};

  if (InfoUsuario.hasUser()) {
    infoUsuario = InfoUsuario.get();

    bugsnagClient.user = {
      id: infoUsuario.cdUsuario.toString(),
      name: infoUsuario.nmUsuario,
      email: infoUsuario.email,
    };
  }

  return bugsnagClient.notify(err, ({
    beforeSend: (report) => {
      report.metaData = {
        infoUsuario,
      };
    },
  }));
};

const leaveBreadcrumb = (...props) => {
  return bugsnagClient.leaveBreadcrumb(...props);
};

export const BugsnagProvider = bugsnagClient.getPlugin('react');

export default {
  notify,
  leaveBreadcrumb,
};
