import styled from 'styled-components';
import { View, Text } from '@components';
import { Icon, Form } from '@components-teammove';

export const Row = styled(View)`
  flex-direction: row;
`;

export const UploadIcon = styled(Icon.Feather)`
  color: #1890FF;

  * {
    color: #1890FF;
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: #F5F5F5;
    opacity: 50%;
  }
`;

export const TextVencimento = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    color: #F5F5F5;
    margin-left: 8px;
  }
`;

export const MarginBottom = styled(View)`
  margin-bottom: 20px;
`;

export const FormItemSelect = styled(Form.Item)`
   height: ${({ selectOpen, dropdownHeight }) => (selectOpen && dropdownHeight > 0) ? `${dropdownHeight}px` : 'auto'} !important;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme })=> theme.textColor}
`;