import React, { useEffect } from 'react';
import { PickerContainer } from '../styles';
import { ColorOption } from './styles';

const ColorPicker = ({ colors, onChange, onChangeComplete, position }) => {
  const uniqueId = Date.now();
  
  useEffect(() => {
    document.addEventListener('click', outsideClickListener);

    return () => document.removeEventListener('click', outsideClickListener);
  }, []);

  const outsideClickListener = (event) => {
    if (!document.getElementById(uniqueId)?.contains(event.target)) {
      onChangeComplete();
    }
  };
  
  const handleChange = (color) => {
    onChange(color);
    onChangeComplete();
  };

  return(
    <PickerContainer id={uniqueId} position={position}>
      {colors.map((color) => (
        <ColorOption color={color} title={color} key={color} onClick={() => handleChange(color)}/>
      ))}
    </PickerContainer>
  );
};

export default ColorPicker;