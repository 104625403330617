import React, { useEffect, useMemo } from 'react';
import { formatDateTime, useSearchParams, TelasNomenclaturas } from '@utils';
import { View, Spin } from '@components';
import { Image } from '@components-teammove';
import Perguntas from '../Pergunta';
import { useDispatch, useSelector } from 'react-redux';
import { getTicket } from '@ducks/chamadosBriefingReply';
import { getLogoImpressao } from '@ducks/configuracoesLogoImpressao';
import {
  AnswerText,
  BriefingTitle,
  ContrastedBackground,
  MarginLeftAuto,
  PrintableContainer,
  RegularText,
  Row,
  SmallGapView,
  SmallText,
  SpaceBetweenRow,
  StyledDivider,
  ContainerInfo,
  UnboldChild,
  RegularTextTitle,
  Titles,
  ContainerHeader,
  LogoConatiner,
} from './styles';

export default function Impressao() {
  const {
    id: idTicket,
    creationUserName,
    responsible,
    companyName,
    companyShortName,
    tipoQuestionario,
  } = useSearchParams();

  const dispatch = useDispatch();

  const logoImpressao = useSelector(({ configuracoesLogoImpressao }) => configuracoesLogoImpressao.get('logoImpressao'));

  const ticket = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('ticket'));
  const successGetTicket = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successGetTicket'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const ticketQuestionario = useMemo(() => {
    if (ticket && ticket.questionario) {
      return ticket.questionario.find((quest) => quest.tipoQuestionario === tipoQuestionario);
    }
    return null;
  }, [ticket, tipoQuestionario, successGetTicket]);

  const verificaCampoOpcional = useMemo(() => {
    if (ticketQuestionario) {
      const { campoTexto1, campoTexto2, campoTexto3 } = ticketQuestionario;
      return campoTexto1 === '' && campoTexto2 === '' && campoTexto3 === '';
    }
  }, [ticketQuestionario]);

  useEffect(() => {
    const handleDispatchGetTicket = (idTicket) => {
      dispatch(getTicket(idTicket));
    };
    handleDispatchGetTicket(idTicket);
    dispatch(getLogoImpressao());
  }, []);

  return successGetTicket ? (
    <PrintableContainer>
      {(!verificaCampoOpcional || logoImpressao.url) && (
        <>
          <SpaceBetweenRow>
            <LogoConatiner>
              {logoImpressao.url && (
                <Image src={logoImpressao.url}/>
              )}
            </LogoConatiner>
            <ContainerHeader>
              <RegularText>{ticketQuestionario.campoTexto1}</RegularText>
              <RegularText>{ticketQuestionario.campoTexto2}</RegularText>
              <RegularText>{ticketQuestionario.campoTexto3}</RegularText>
            </ContainerHeader>
          </SpaceBetweenRow>

          <StyledDivider />
        </>
      )}
      <BriefingTitle>
        <Titles>
          {
            ticketQuestionario.questionarioTitulo
          }
        </Titles>
      </BriefingTitle>

      <SmallGapView>
        <SpaceBetweenRow>
          <RegularText>
            {tipoQuestionario === 'REPORT' ? 
              <Titles>Finalizado Por:&nbsp;</Titles> 
              : <Titles>Criado Por:&nbsp;</Titles>
            }
            <UnboldChild>
              {tipoQuestionario === 'REPORT' ? responsible : creationUserName} em{' '}
              {formatDateTime(
                ticketQuestionario.dtRealizacao
              )}
            </UnboldChild>
          </RegularText>
        </SpaceBetweenRow>

        <Row>
          <ContainerInfo>
            <RegularTextTitle>Ticket&nbsp;</RegularTextTitle>
            <ContrastedBackground>
              <SpaceBetweenRow>
                <UnboldChild>{ticket?.protocolo}</UnboldChild>
              </SpaceBetweenRow>
              <RegularText>
                <Titles>{ticket?.assunto}</Titles>
              </RegularText>
              <MarginLeftAuto>
                <SmallText>{ticket?.nmCategoriaCompleto}</SmallText>
              </MarginLeftAuto>
            </ContrastedBackground>
          </ContainerInfo>
          <ContainerInfo>
            <RegularTextTitle>
              {companyNomenclature.nomenclatura}&nbsp;
            </RegularTextTitle>
            <ContrastedBackground>
              <SpaceBetweenRow>
                <UnboldChild>{companyShortName}</UnboldChild>
              </SpaceBetweenRow>
              <RegularText>
                <Titles>{companyName}</Titles>
              </RegularText>
              <MarginLeftAuto>
                <SmallText>{ticket?.unidadeEndereco}</SmallText>
              </MarginLeftAuto> 
            </ContrastedBackground>
          </ContainerInfo>
        </Row>

        <StyledDivider />
      </SmallGapView>

      <AnswerText>Informações:</AnswerText>

      <Perguntas
        perguntas={
          ticketQuestionario.respostas
        }
        impressao
        color='black'
      />
    </PrintableContainer>
  ) : (
    <View>
      <Spin />
    </View>
  );
}
