import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from '@components-teammove';

const ModalDiaCopia = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({ diaSemana: visible });
    }
  }, [visible]);

  return(
    <Modal visible={visible ? true : false} title="Copiar horários para" okText="Copiar" onOk={() => form.submit()} onCancel={onCancel}>
      <Form layout="vertical" name="diaCopiaForm" form={form} hideRequiredMark>
        <Form.Item name="diaSemana" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="diaSelecionado" label="Dia" extra="Os dados do dia selecionado serão substituídos ao copiar" rules={[{ required: true, message: 'Selecione o dia' }]}>
          <Select placeholder='Selecione o dia'>
            <Select.Option value={1} disabled={1 === visible}>Segunda-Feira</Select.Option>
            <Select.Option value={2} disabled={2 === visible}>Terça-Feira</Select.Option>
            <Select.Option value={3} disabled={3 === visible}>Quarta-Feira</Select.Option>
            <Select.Option value={4} disabled={4 === visible}>Quinta-Feira</Select.Option>
            <Select.Option value={5} disabled={5 === visible}>Sexta-Feira</Select.Option>
            <Select.Option value={6} disabled={6 === visible}>Sábado</Select.Option>
            <Select.Option value={7} disabled={7 === visible}>Domingo</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalDiaCopia;
