import React from 'react';
import { Tooltip , Row } from '@components-teammove';
import AudienciaOpcoes from './Opcoes';
import { Accesses, AccessTotal, BadgeCount, CaminhoCategoriaView, CaminhoView, CategoriaView, FeatherIcon, ItemView } from './styles';

export default function RankingItem({ pos, item }) {
  const categoriaSplit = item.dsCategoria.split(' / ');
  const categoria = categoriaSplit.pop();
  const caminho = categoriaSplit.join(' / ');

  return (
    <ItemView key={item.id} pos={pos} justify='space-between' align='center'>
      <Row gap='25px' align='center'>
        <BadgeCount>{pos}</BadgeCount>
        <CaminhoCategoriaView>
          <CategoriaView>{categoria}</CategoriaView>
          <CaminhoView>{caminho}</CaminhoView>
        </CaminhoCategoriaView>
      </Row>
      <Row gap='23px' align='center'>
        <Tooltip title="Acessos">
          <Accesses>
            <FeatherIcon type='eye'/>
            <AccessTotal>{item.qtAcesso}</AccessTotal>
          </Accesses>
        </Tooltip>
        <AudienciaOpcoes item={item} />
      </Row>
    </ItemView>
  );
}
