import styled from 'styled-components';
import { Icon, Text, View } from '@components-teammove';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const IconWrapper = styled.div`
  border-radius: 8px;
  padding: 15px 13px;
  background-color: ${({ color }) => color};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActivityIcon = styled(Icon.MDI)`

`;

export const ActivityName = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme })=> theme.textColor};
  }
`;

export const DetailsStyled = styled(View)`
  font-size: 12px;
`;

export const WarningText = styled(Text)`
  &.ant-typography {
    display: flex;
    align-items: center;
    gap: 9.5px;
    padding: 16px;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 20px;
  }
`;

export const WarningIcon = styled(Icon.WarningOutlined)`
  &.anticon {
    font-size: 16px;
  }
`;

// export const ShrinkableSelect = styled(Select)`
//   &.ant-select {
//     width: fit-content !important;
//   }
// `;

// export const ShrinkableFormItem = styled(Form.Item)`
//   flex: none;
// `;

// export const GrowableInputFormItem = styled(Form.Item)`
//   .ant-form-item-control {
//     flex: 1;

//     .ant-form-item-control-input {
//       flex: 1;

//       .ant-form-item-control-input-content {
//         height: 100%;

//         .ant-input-affix-wrapper {
//           height: 100%;
//         }
//       }
//     }
//   }
// `;