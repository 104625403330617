import styled from 'styled-components';
import { View, Text, Icon, Image, Modal, VideoPlayer } from '@components-teammove';

export const AnswerImage = styled(Image)`
  width: auto;
  cursor: pointer;
  transition: all .15s;
  :hover{
    box-shadow: .1rem .1rem 1rem .5rem rgba(0, 0, 0, 0.2);
    border-radius: .25rem;
  }
`;

export const AnswerVideo = styled(VideoPlayer)`
  width: auto;
`;

export const QuestionView = styled(View)`
  margin-bottom: 20px;
`;

export const QuestionContainer = styled.div`
  background-color: ${({ impressao }) => impressao ? '#D9D9D9' : '' };
  border-radius: 10px;
  -webkit-print-color-adjust: exact;
  margin-bottom: 5px;
`;

export const FileName = styled(Text)`
  cursor: pointer;
  margin: 0 1rem;
  color: ${({ color })=> color ? color : ''}!important;
  :hover{
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.2);
  }
`;

export const FileView = styled(View)`
  width: fit-content;
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  justify-content: space-between;
  align-items: center;

  @media print {
    .ant-btn {
      display: none;
    }
  }
`;

export const ImagePreview = styled(Image)`
  cursor: pointer;
  margin: .25rem 0;
  width: auto;
  height: 128px;
  border-radius: .25rem;
  transition: all .125s;
  :hover{
    box-shadow: .1rem .1rem .5rem .1rem rgba(0, 0, 0, 0.2);
  }

  @media print {
    border-radius: 0;
  }
`;

export const ModalMedia = styled(Modal)`
  text-align: center;
`;

export const ContentFiles = styled(View)`
  flex-direction: row;
  gap: 5px;
  overflow-x: auto;

  @media print {
    flex-wrap: wrap;
    overflow-x: hidden;
  }
`;

export const AnswerContainer = styled(View)`
  background-color: ${({ impressao, theme }) => impressao ? '#D9D9D9' : theme.darkPrimaryColor };
  border-radius: 10px;
  padding: 8px;

  @media print {
    background-color: transparent;
  }
`;

export const QuestionText = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    margin-left: 2px;

    @media print {
      font-size: 14px;
      color: black;
      opacity: .75;
      margin-left: 0;
    }
  }
`;

export const TextAnswer = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  white-space: pre-line;
  margin-left: 10px;

  @media print {
    font-size: 14px;
    color: black;
    margin-left: 20px;
  }
`;

export const IconFeather = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor };
  :hover {
    cursor: ${({ hover }) => hover ? 'pointer' : '' }
  }
`;