import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Row, Text, Column, Divider, Button } from '@components-teammove';
import { ButtonStyle, Container, ContentPosition, DotTopLevel, IconFeather, TableStyle, StyleEmpty  } from './styles';

import { Filtros, moment } from '@utils';

import { getCategoryRanking, FILTERS_KEY, PARAMS_KEY } from '@ducks/chamadosDashboard';
import { FILTERS_KEY as TICKETS_FILTERS_KEY } from '@ducks/chamadosTickets';

import { getFinalDateByInitialDateAndPeriod, getOldFiltersWithNewFilters } from '../rules';

export default function CategoriesRanking({ currentFilters, currentParams }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const categoriesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('categoryRanking'));
  const loadingCategoriesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingCategoryRanking'));
  const successCategoriesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successCategoryRanking'));

  const filtersAndParams = { ...Filtros.get(FILTERS_KEY), ...Filtros.get(PARAMS_KEY) };

  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getCategoryRanking({ ...currentFilters, ...currentParams }, { limit:10 }));
    }
  }, [currentFilters, currentFilters]);

  const handleCategoryClick = (category) => {
    const newTicketsPageFilters = getOldFiltersWithNewFilters(
      Filtros.get(TICKETS_FILTERS_KEY), 
      {
        ...filtersAndParams, 
        idCategory: [category.id], 
        creationDate: [moment(filtersAndParams.initialDate), getFinalDateByInitialDateAndPeriod(filtersAndParams.initialDate, filtersAndParams.typePeriod)],
        descFiltros: {
          ...filtersAndParams.descFiltros, 
          idCategory: [category.name],
          creationDate: [moment(filtersAndParams.initialDate).format('DD/MM/YYYY'), getFinalDateByInitialDateAndPeriod(filtersAndParams.initialDate, filtersAndParams.typePeriod).format('DD/MM/YYYY')]
        }
      }
    );
    
    Filtros.set(TICKETS_FILTERS_KEY, newTicketsPageFilters);
    history.push('/chamados/tickets');
  };

  const columns = [
    {
      title: 'Pos',
      dataIndex: 'rankingPosition',
      width: '50px',
      render: (index, item) => (
        (item.rankingPosition < 4 ? (
          <DotTopLevel>
            <Text font='16px' weight='700'>{item.rankingPosition}</Text>
          </DotTopLevel>
        ) : (
          <ContentPosition>
            <Text font='16px' weight='700'>{item.rankingPosition}</Text>
          </ContentPosition>
          
        ))
      )
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      render: (index, item) => (
        <Column>
          <Text size='16px' weight='700'>{item.name}</Text>
          <Text size='12px' weight='400' lowOpacity>{item.subCategoryName}</Text>
        </Column>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width:'150px',
      render: (index, item) => (
        <Row align='center' justify='space-between'>    
          <Text size='16px' weight='700'>{item.quantity}</Text>
          <Button type='icon' size='small' icon={(<IconFeather type='arrow-right' />)} />         
        </Row>
      )
    }
  ];

  return(
    loadingCategoriesRanking ? (
      <Skeleton.Map/>
    ) : successCategoriesRanking && (
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Raking de </Text>
            <Text size='20px' weight='800' line='20px'>Categorias</Text>
          </Column>
          <ButtonStyle size='small' context='header' key="1" type='tertiary' onClick={() => history.push('/chamados/dashboard-chamados/ranking-categorias-details')}>
              Ver Todos
          </ButtonStyle>
        </Row>
        <Divider/>
        {categoriesRanking.categoryRanking.length < 1 ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <TableStyle
              columns={columns}
              rowKey={categoriesRanking?.categoryRanking.rankingPosition}
              dataSource={categoriesRanking.categoryRanking}
              loading={loadingCategoriesRanking}
              hidePagination
              onRow={(tab) => ({ onClick: () => handleCategoryClick(tab) })}
            />
          )}
        
      </Container>
    )
  );
}