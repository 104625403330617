import React from 'react';
import styled from 'styled-components';
import { View } from '@components';
import { hexToRGBA } from '@utils';

const ViewMain = styled(View)`
  border: 1px solid ${({ cor }) => cor};
  border-radius: 3px;
  width: ${({ width }) => width};
  margin: 20px 0;
  padding: 10px;
  background-color: ${({ cor }) => hexToRGBA(cor, 0.1)};
`;

const ViewFeedback = styled(View)`
  overflow-wrap: break-word;
`;

export default function Feedback({ data, width = '100%' }) {
  const { feedback, positivo, cor } = data;

  const corFeedback = positivo === undefined ? cor : positivo ? '#66CC66' : '#CC4444';

  return (
    <ViewMain cor={corFeedback} width={width}>
      <View>
        <strong>Feedback</strong>
      </View>
      <ViewFeedback>{feedback}</ViewFeedback>
    </ViewMain>
  );
}
