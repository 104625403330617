import React from 'react';
import { InfoUsuario } from '@utils';
import { Avatar } from '@components';
import styled from 'styled-components';

const AvatarUsuario = styled(Avatar)`
  background-color: #cccccc !important;

  img {
    object-fit: contain !important;
  }
`;

const UserAvatar = ({ size, cdCliente, cdUsuario, nmUsuario, avatar }) => (
  <AvatarUsuario size={size} src={avatar || InfoUsuario.getAvatar(cdCliente, cdUsuario, false)}>
    {nmUsuario ? nmUsuario[0] : null}
  </AvatarUsuario>
);

const User = ({ size = 'large', cdCliente = InfoUsuario.get('cdCliente'), cdUsuario, nmUsuario, avatar }) => {
  return <UserAvatar cdCliente={cdCliente} cdUsuario={cdUsuario} nmUsuario={nmUsuario} size={size} avatar={avatar} />;
};

const LoggedUser = ({ size = 'large' }) => {
  const { cdCliente, cdUsuario, nmUsuario, avatar } = InfoUsuario.get();

  return <User cdCliente={cdCliente} cdUsuario={cdUsuario} nmUsuario={nmUsuario} size={size} avatar={avatar}/>;
};

export { User, LoggedUser };
