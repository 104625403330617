import styled from 'styled-components';
import { View, Text, Button, Row, Radio } from '@components-teammove';

export const ButtonFilter = styled(Button)`
  border: none !important;
  box-shadow: none !important;
`;

export const ContentHeader = styled(View)`
  flex: 1;
  flex-direction: row;
`;

export const ContentFilter = styled(View)`
  flex: 1;
`;

export const ContentDeadline = styled(View)`
  flex: 1;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  max-width: 50%;
`;

export const LabelDeadline = styled(Text)`
  font-size: 15px;
`;

export const ContentHeaderContainer = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  margin-left: auto;
`;