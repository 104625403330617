import styled from 'styled-components';
import { View, Upload, Image, Text, Button } from '@components';
import { Icon } from '@components-teammove';

export const ContentHeader = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const ContentInputHeader = styled(View)`
  display: flex;
  flex: 1;
`;

export const ContainerUpload = styled(View)`
  padding-left: 15px;
`;

export const ViewUpload = styled(View)`
  border: 1px dashed #d9d9d9;
  width: 223px;
  height: 223px;
  background-color: #fafafa;

  :hover {
    border: 1px dashed #000000;
  }
`;

export const ComponentUpload = styled(Upload)`
  width: 100%;
  height: 100%;
`;

export const ComponentEmptyCover = styled(View)`
  width: 223px;
  height: 223px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;

export const ImageCover = styled(Image)`
  object-fit: contain;
  cursor: pointer;
`;

export const IconRemoveCover = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
`;

export const LabelItemObservation = styled(Text)`
  flex: 1;
`;

export const ContentBody = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const ContentObservation = styled(View)`
  flex: 1;
`;

export const ContentPresentation = styled(View)`
  flex: 1;
  margin-left: 15px;
`;

export const ContentVideoInput = styled(View)`
  flex: 1;
`;

export const ContentVideoPresentation = styled(View)`
  padding-left: 5px;
`;

export const ContentButtonOrderCourses = styled(View)`
  justify-content: center;
`;

export const ButtonOrderCourses = styled(Button)`
  margin: 15px 0px 0px 5px;
`;

export const ContentSpin = styled(View)`
  flex: 1;
`;