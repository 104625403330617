import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, Select, InputNumber, InputMask, Skeleton } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderKind, resetSuccess, putOrderKind, postOrderKind } from '@ducks/settingsOrdersOrdersKinds';
import { initialValues } from './rules';
import { getUsuarios } from '@sdk/Usuarios';

export default function PedidosCadastro({ onCancel, visible }) {
  
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const id = location.state?.tipoPedido.id;
  const dispatch = useDispatch();

  const orderKind = useSelector(({ ordersKinds }) => ordersKinds.get('orderKind'));
  const loadingOrderKind = useSelector(({ ordersKinds }) => ordersKinds.get('loadingOrderKind'));
  const successOrderKind = useSelector(({ ordersKinds }) => ordersKinds.get('successOrderKind'));

  const loadingOrderKindSave = useSelector(({ ordersKinds }) => ordersKinds.get('loadingOrderKindSave'));

  const [originalFieldsValue, setOriginalFieldsValue] = useState();

  const orderExists = !!id;

  useEffect(() => {
    if(orderExists) {
      dispatch(getOrderKind(parseInt(id)));
    }else{
      form.setFieldsValue(initialValues);
    }
  }, []);  

  useEffect(() => {
    return () => {
      history.push('#');
      dispatch(resetSuccess());
    };
  }, []);
  
  useEffect(() => { 
    if(successOrderKind ) {
      form.setFieldsValue(orderKind);
      setOriginalFieldsValue(form.getFieldsValue());
    }
  }, [successOrderKind]);

  const handleSave = () => {
    form.validateFields().then((values) => {

      if(orderExists) {
        values.id = parseInt(id);
        dispatch(putOrderKind(values));
        
      }else {
        values.distributorCnpj = values.distributorCnpj.replace(/[./-]/g, '');
        dispatch(postOrderKind(values));
        
      } 
      onCancel(); 
    });
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return(
    <Modal
      onCancel={onCancel}
      visible={visible}
      title='Novo Tipo de Pedido'
      cancelText='Cancelar'
      onBeforeCancel={onBeforeCancel}
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir este tipo de pedido?' }}
      onOk={handleSave}
      okButtonProps={{ loading:loadingOrderKindSave }}
    >
      <Form layout='vertical' form={form}>
        {loadingOrderKind ? <Skeleton.Form/> :
          (<Form.Item label='Responsável' name='assignedUserId' rules={[{ required: true, message: 'Informe o titulo do pedido' }]}>
            <Select placeholder='Selecione o responsavel pelo pedido' action={getUsuarios}/>
          </Form.Item>)}

        {loadingOrderKind ? <Skeleton.Form/> :
          (<Form.Item label='Numero de dias para o pedido' name='numberOfDays' rules={[{ required: true, message: 'Informe o numero de dias' }]}>
            <InputNumber placeholder='Ex: 12'/>
          </Form.Item>)}
      
        {loadingOrderKind ? <Skeleton.Form/> :
          (<Form.Item label='Distribuidor' name='distributorName' rules={[{ required: true, message: 'Informe o distribuidor do pedido' }]}>
            <Input placeholder='Nome do distribuidor'/>
          </Form.Item>)}
      
        {loadingOrderKind ? <Skeleton.Form/> :
          (<Form.Item label='CNPJ do distribuidor' name='distributorCnpj' rules={[{ required: true, message: 'Informe o cnpj do distribuidor do pedido' }]}>
            <InputMask mask='99.999.999/9999-99' placeholder={'11.111.111/1111-11'}/>
          </Form.Item>)}     
      </Form>
    </Modal>
  );
}