import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import Media from 'react-media';
import { createGlobalStyle } from 'styled-components';
import { propsColumn, responsiveColumns } from './rules';
import Empty from '../../components-teammove/Empty';

const GlobalStyle = createGlobalStyle`
  tbody {
    tr.updated-row {
      &.ant-table-row {
        > td {
          background-color: #F3FFF2 !important;
        }
      }
      &.ant-table-row:hover {
        > td {
          background-color: #e8ffe6 !important; /* darken 5% */
        }
      }
    }

    tr.disabled-row {
      opacity: .5;
    }
  }
`;

const TableComponent = ({ queries = {}, hidePagination, pagination, status, rowClassName, columns, ...otherProps }) => (
  <>
    <GlobalStyle />
    <Media queries={queries}>
      {(matches) => (
        <Table
          pagination={
            // prettier-ignore
            !hidePagination
              ? {
                showSizeChanger: true,
                showQuickJumper: true,
                hideOnSinglePage: true,
                defaultPageSize: 50,
                pageSizeOptions: ['25', '50', '100'],
                ...pagination,
              }
              : false
          }
          rowClassName={(record) => {
            let className = [];
            if (status && record[status] !== undefined && !record[status]) {
              className.push('disabled-row');
            }
            if (rowClassName) {
              className.push(rowClassName(record));
            }

            return className.join(' ');
          }}
          columns={responsiveColumns(columns.map(propsColumn), matches)}
          {...otherProps}
        />
      )}
    </Media>
  </>
);

TableComponent.propTypes = {
  ...Table.propTypes,
  hidePagination: PropTypes.bool,
  status: PropTypes.string,
};

TableComponent.defaultProps = {
  hidePagination: false,
  status: 'status',
  size: 'middle',
  tableLayout: 'auto',
  locale: {
    emptyText: <Empty description="Sem dados" />,
  },
};

export default TableComponent;
