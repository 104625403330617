import React, { useEffect, useState } from 'react';
import { Button } from '@components-teammove';
import { Answer, CopyIcon, DetailView, Label, SpaceBetweenRow } from '../Detalhes/styles';
import { Contact, ContactInfo, ContactName, ContactRole, Container, ContactHeader, CollapseIcon, Row, InfoIconWrapper, InfoList, TextColorFeatherIcon, TextColorMdiIcon, InfoIconLabel, EmptyContactListView, EmptyMessage, StatusTagStyle } from './styles';
import { formatTelephone, openPopup, InfoUsuario } from '@utils';
import { setInterruptRequest } from '@ducks/voip';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContatos, reset } from '@ducks/contatos';

export default function Pessoas({ companyId, setVisibleContactRegister, handleOnClosePopUp }) {
  const history = useHistory();

  const dispatch = useDispatch();
  const contacts = useSelector(({ contatos }) => contatos.get('contatos'));
  const success = useSelector(({ contatos }) => contatos.get('successContatos'));
  const successSave = useSelector(({ contatos }) => contatos.get('successSave'));

  const [contactsState, setContactsState] = useState([]);

  const codCountry = '55';

  useEffect(() => {
    if (success) {
      setContactsState(contacts.map((contact) => ({ ...contact, open: false })));
    }
  }, [success]);
  
  useEffect(() => {
    if (successSave) {
      dispatch(getContatos(companyId));
    }

    return () => dispatch(reset());
  }, [successSave]);

  const handleEditClick = (contactId) => {
    history.push(`?contact=${contactId}`);
    setVisibleContactRegister(true);
  };

  const handleDisc = (e, number, name, id, idUnidade) => {
    e.stopPropagation();
    dispatch(setInterruptRequest(true));
    const popupUrl = `${window.location.origin}/voip?number=${number}&name=${name}&ContactId=${id}&CompanyId=${idUnidade}`;
    openPopup(popupUrl, '_blank', 400, 970, '/voip', handleOnClosePopUp);
  };

  return contacts.length === 0 ? (
    <EmptyContactListView>
      <EmptyMessage>
        Parece que você não possui nenhum contato ainda. <br/>Cadastre agora seu primeiro contato!
      </EmptyMessage>
      <Button type='primary' onClick={() => setVisibleContactRegister(true)}>Adicionar contato</Button>
    </EmptyContactListView>
  ) : (
    <Container>
      {contactsState.map((contato) => (
        <Contact 
          key={contato.id} 
          onClick={!contato.open ? (() => setContactsState(contactsState.map((contact) => contact.id === contato.id ? ({ ...contact, open: !contact.open }) : ({ ...contact })))) : null}
          open={contato.open}
        >
          {contato?.contactClassification?.cor && contato?.contactClassification?.descricao && (
            <StatusTagStyle color={contato?.contactClassification?.cor} text={contato?.contactClassification?.descricao} size='average' />
          )}
          <ContactHeader onClick={() => setContactsState(contactsState.map((contact) => contact.id === contato.id ? ({ ...contact, open: !contact.open }) : ({ ...contact })))}>
            <ContactInfo>
             
              <ContactName>{contato.nome}</ContactName>
              <ContactRole>{contato.cargo}</ContactRole>
              
              <Row justify='center' gap='28px'>
                {InfoUsuario.perm('voip', InfoUsuario.get()) && (
                  <ContactInfo alignCenter>
                    <InfoIconWrapper
                      existsInfo={contato?.telefone}
                      onClick={(e) => { 
                        if (contato?.telefone) {
                          handleDisc(e, contato.telefone, contato.nome, contato.id, contato.idUnidade);
                        }
                      }}
                    >
                      <TextColorFeatherIcon type='phone'/>
                    </InfoIconWrapper>
                    {contato.open && (<InfoIconLabel>Telefone</InfoIconLabel>)}
                  </ContactInfo>     
                )}   
                <ContactInfo alignCenter>
                  <InfoIconWrapper existsInfo={contato?.email} onClick={(e) =>{e.stopPropagation(); contato?.email ? window.open(`mailto:${contato.email}`) : handleEditClick(contato.id);}}>
                    <TextColorFeatherIcon type='mail'/>
                  </InfoIconWrapper>
                  {contato.open && (<InfoIconLabel>E-mail</InfoIconLabel>)}
                </ContactInfo>
                <ContactInfo alignCenter>
                  <InfoIconWrapper existsInfo={contato?.telefone} onClick={(e) => {e.stopPropagation(); contato?.telefone ? window.open(`https://api.whatsapp.com/send?phone=${codCountry}${contato.telefone}`) : handleEditClick(contato.id);}}>
                    <TextColorMdiIcon type='whatsapp'/>
                  </InfoIconWrapper>
                  {contato.open && (<InfoIconLabel>WhatsApp</InfoIconLabel>)}
                </ContactInfo>   
                <ContactInfo alignCenter>
                  <InfoIconWrapper existsInfo={contato?.linkedin} onClick={(e) => {e.stopPropagation(); contato?.linkedin ? window.open(contato.linkedin) : handleEditClick(contato.id);}}>
                    <TextColorFeatherIcon type='linkedin'/>
                  </InfoIconWrapper>
                  {contato.open && (<InfoIconLabel>LinkedIn</InfoIconLabel>)}
                </ContactInfo>         
              </Row>
            </ContactInfo>
              
            <CollapseIcon type={`chevron-${contato.open ? 'up' : 'down'}`}/>
            
          </ContactHeader>

          {contato.open && (
            <>
              <InfoList>
                {contato.telefone && (
                  <SpaceBetweenRow>
                    <DetailView>
                      <Label>Telefone</Label>
                      <Answer>
                        {formatTelephone(contato.telefone)}
                      </Answer>
                    </DetailView>
                    <CopyIcon type='copy' onClick={() => navigator.clipboard.writeText(contato.telefone)}/>
                  </SpaceBetweenRow>
                )}
                {contato.email && (
                  <SpaceBetweenRow>
                    <DetailView>
                      <Label>E-mail</Label>
                      <Answer>
                        {contato.email}
                      </Answer>
                    </DetailView>
                    <CopyIcon type='copy' onClick={() => navigator.clipboard.writeText(contato.email)}/>
                  </SpaceBetweenRow>
                )}
              </InfoList>
              <Button type='primary' fillWidth onClick={() => handleEditClick(contato.id)}>Editar contato</Button>
            </>
          )}
        </Contact>
      ))}
    </Container>
  );
}