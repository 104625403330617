import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Column, Text, Row, Skeleton } from '@components-teammove';
import { capitalizeWordsAndRemoveUnderline } from '@utils';
import { Card, CardSkeleton, ContentGrid, FeatherIcon, GridContainer, RemixIcon } from './styles';

import { RemoveEmptyItemsFromUserActivityTypeGroupsSummary, userActivityTimesSort } from './rules';
import { formatedTime } from '../../rules';

import { getUserActivityTypeGroupsSummary } from '@ducks/atividadesGestaoAgenda';

export const Cards = ({ userId, currentParams }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {  
    dispatch(getUserActivityTypeGroupsSummary(userId, { ...currentParams }));
  }, [currentParams]);

  const userActivityTypeGroupsSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('userActivityTypeGroupsSummary'));
  const loadingUserActivityTypeGroupsSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('loadingUserActivityTypeGroupsSummary'));
  const successUserActivityTypeGroupsSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('successUserActivityTypeGroupsSummary'));
  
  const formattedUserActivityTypeGroups = useMemo(() => {
    const orderedTimes = userActivityTypeGroupsSummary.sort(userActivityTimesSort);
    const removedEmptyItemsFromOrderedTimes = RemoveEmptyItemsFromUserActivityTypeGroupsSummary(orderedTimes);

    return removedEmptyItemsFromOrderedTimes;
  }, [userActivityTypeGroupsSummary]);
  
  return(
    loadingUserActivityTypeGroupsSummary ? ( 
      <Column gap='12px'>
        <Row gap='12px'>
          <CardSkeleton><Skeleton/></CardSkeleton>
          <CardSkeleton><Skeleton/></CardSkeleton>
        </Row>
        <Row gap='12px'>
          <CardSkeleton><Skeleton/></CardSkeleton>
          <CardSkeleton><Skeleton/></CardSkeleton>
          <CardSkeleton><Skeleton/></CardSkeleton>
          <CardSkeleton><Skeleton/></CardSkeleton>
        </Row>
      </Column> 
    ) : successUserActivityTypeGroupsSummary &&
      <>
        <GridContainer>
          {formattedUserActivityTypeGroups.map((item)=> (
            item.id === 'VISITA' &&
            <ContentGrid columns={2}>
              <Card>
                <Column>
                  <Text size='16px' weight='600' line='21px' lowOpacity>
                    Média de visitas por dia
                  </Text>
                  <Text size='24px' weight='700' line='32px'>
                    {item.averageQuantityByDay.toFixed(2)}
                  </Text>
                </Column>
              </Card> 
              <Card>
                <Column>
                  <Text size='16px' weight='600' line='21px' lowOpacity>
                    Total de visitas             
                  </Text>
                  <Text size='24px' weight='700' line='32px'>
                    {item.totalQuantity}
                  </Text>
                </Column>
              </Card> 
            </ContentGrid>
          ))}
          
          {formattedUserActivityTypeGroups?.length > 0 && (
            <ContentGrid columns={formattedUserActivityTypeGroups.length < 3 ? formattedUserActivityTypeGroups.length : 3}>
              {formattedUserActivityTypeGroups.map((item)=> (
                <Card key={item.id} gap='20px' align='center'>
                  {(item.id === 'FOLGA' || item.id === 'DESLOCAMENTO') ? (
                    <RemixIcon size='24px' type={item.icon}/>
                  ) : (
                    <FeatherIcon size='24px' type={item.icon}/>
                  )}
                  <Column>
                    <Text size='16px' weight='600' lowOpacity>{capitalizeWordsAndRemoveUnderline(item.id)}</Text>
                    <Row align='baseline' gap='8px'>
                      <Text size='24px' weight='600'>{formatedTime(item.totalTime, true)}</Text>
                      <Text size='16px' weight='600' lowOpacity>{item.percent.toFixed(2)}%</Text>
                    </Row>
                  </Column>
                </Card>
              ))}
            </ContentGrid>
          )}   
          
        </GridContainer> 
      </>
  );
};