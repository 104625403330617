import { call, put, takeLatest } from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { Notification } from '@utils';
import { getWhitelabels as getWhitelabelsSdk, getWhitelabel as getWhitelabelSdk, saveWhitelabel as saveWhitelabelSdk, updateWhitelabel as updateWhitelabelSdk, setDefaultWhitelabel as setDefaultWhitelabelSdk } from '@sdk/Whitelabel';

//Action Types
export const Types = {
  GET_WHITELABEL: 'CONFIGURACOES_WHITELABEL/GET_WHITELABEL',
  GET_WHITELABEL_SUCCESS: 'CONFIGURACOES_WHITELABEL/GET_WHITELABEL_SUCCESS',
  GET_WHITELABEL_ERROR: 'CONFIGURACOES_WHITELABEL/GET_WHITELABEL_ERROR',

  GET_WHITELABELS: 'CONFIGURACOES_WHITELABEL/GET_WHITELABELS',
  GET_WHITELABELS_SUCCESS: 'CONFIGURACOES_WHITELABEL/GET_WHITELABELS_SUCCESS',
  GET_WHITELABELS_ERROR: 'CONFIGURACOES_WHITELABEL/GET_WHITELABELS_ERROR',

  SAVE_WHITELABEL: 'CONFIGURACOES_WHITELABEL/SAVE_WHITELABEL',
  SAVE_WHITELABEL_SUCCESS: 'CONFIGURACOES_WHITELABEL/SAVE_WHITELABEL_SUCCESS',
  SAVE_WHITELABEL_ERROR: 'CONFIGURACOES_WHITELABEL/SAVE_WHITELABEL_ERROR',

  DUPLICATE_WHITELABEL: 'CONFIGURACOES_WHITELABEL/DUPLICATE_WHITELABEL',
  DUPLICATE_WHITELABEL_SUCCESS: 'CONFIGURACOES_WHITELABEL/DUPLICATE_WHITELABEL_SUCCESS',
  DUPLICATE_WHITELABEL_ERROR: 'CONFIGURACOES_WHITELABEL/DUPLICATE_WHITELABEL_ERROR',

  SET_DEFAULT_WHITELABEL: 'CONFIGURACOES_WHITELABEL/SET_DEFAULT_WHITELABEL',
  SET_DEFAULT_WHITELABEL_SUCCESS: 'CONFIGURACOES_WHITELABEL/SET_DEFAULT_WHITELABEL_SUCCESS',
  SET_DEFAULT_WHITELABEL_ERROR: 'CONFIGURACOES_WHITELABEL/SET_DEFAULT_WHITELABEL_ERROR',

  RESET: 'CONFIGURACOES_WHITELABEL/RESET',
};

//Action Creators
export const getWhitelabels = () => ({ type: Types.GET_WHITELABELS });
export const getWhitelabelsSuccess = (whitelabels) => ({ type: Types.GET_WHITELABELS_SUCCESS, whitelabels });
export const getWhitelabelsError = (error) => ({ type: Types.GET_WHITELABELS_ERROR, error });

export const getWhitelabel = (id) => ({ type: Types.GET_WHITELABEL, id });
export const getWhitelabelSuccess = (whitelabel) => ({ type: Types.GET_WHITELABEL_SUCCESS, whitelabel });
export const getWhitelabelError = (error) => ({ type: Types.GET_WHITELABEL_ERROR, error });

export const saveWhitelabel = (whitelabel) => ({ type: Types.SAVE_WHITELABEL, whitelabel });
export const saveWhitelabelSuccess = (whitelabel) => ({ type: Types.SAVE_WHITELABEL_SUCCESS, whitelabel });
export const saveWhitelabelError = (error) => ({ type: Types.SAVE_WHITELABEL_ERROR, error });

export const duplicateWhitelabel = (whitelabel) => ({ type: Types.DUPLICATE_WHITELABEL, whitelabel });
export const duplicateWhitelabelSuccess = (whitelabel) => ({ type: Types.DUPLICATE_WHITELABEL_SUCCESS, whitelabel });
export const duplicateWhitelabelError = (error) => ({ type: Types.DUPLICATE_WHITELABEL_ERROR, error });

export const setDefaultWhitelabel = (id) => ({ type: Types.SET_DEFAULT_WHITELABEL, id });
export const setDefaultWhitelabelSuccess = (whitelabel) => ({ type: Types.SET_DEFAULT_WHITELABEL_SUCCESS, whitelabel });
export const setDefaultWhitelabelError = (error) => ({ type: Types.SET_DEFAULT_WHITELABEL_ERROR, error });

export const reset = () => ({ type: Types.RESET });

function* fetchGetWhitelabelsSaga(action) {
  try {
    const whitelabels = yield call(getWhitelabelsSdk);
    yield put(getWhitelabelsSuccess(whitelabels));
  } catch (err) {
    Notification.error(err.message);
    yield put(getWhitelabelsError(err));
  }
}

function* fetchGetWhitelabelSaga(action) {
  try {
    const { id } = action;
    const whitelabel = yield call(getWhitelabelSdk, id);
    yield put(getWhitelabelSuccess(whitelabel));
  } catch (err) {
    Notification.error(err.message);
    yield put(getWhitelabelError(err));
  }
}

function* fetchSaveWhitelabelSaga(action) {
  try {
    const { whitelabel } = action;
    let data = null;
    if (whitelabel.id && whitelabel.id > 0) {
      data = yield call(updateWhitelabelSdk, whitelabel);
    } else {
      data = yield call(saveWhitelabelSdk, whitelabel);
    }
    yield put(saveWhitelabelSuccess(data));
    Notification.success('Whitelabel salvo com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveWhitelabelError(err));
  }
}

function* fetchDuplicateWhitelabelSaga(action) {
  try {
    const { whitelabel } = action;
    const data = yield call(saveWhitelabelSdk, whitelabel);
    yield put(duplicateWhitelabelSuccess(data));
    Notification.success('Whitelabel duplicado com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(duplicateWhitelabelError(err));
  }
}

function* fetchSetDefaultWhitelabelSaga(action) {
  try {
    const { id } = action;
    const data = yield call(setDefaultWhitelabelSdk, id);
    yield put(setDefaultWhitelabelSuccess(data));
    Notification.success('Whitelabel definido como padrão!');
  } catch (err) {
    Notification.error(err.message);
    yield put(setDefaultWhitelabelError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_WHITELABELS, fetchGetWhitelabelsSaga),
  takeLatest(Types.GET_WHITELABEL, fetchGetWhitelabelSaga),
  takeLatest(Types.SAVE_WHITELABEL, fetchSaveWhitelabelSaga),
  takeLatest(Types.DUPLICATE_WHITELABEL, fetchDuplicateWhitelabelSaga),
  takeLatest(Types.SET_DEFAULT_WHITELABEL, fetchSetDefaultWhitelabelSaga)
];

// Reducer
const initialState = Map({
  whitelabels: List(),
  loadingGetWhitelabels: false,
  successGetWhitelabels: false,
  errorGetWhitelabels: false,

  whitelabel: null,
  loadingGetWhitelabel: false,
  successGetWhitelabel: false,
  errorGetWhitelabel: false,
  
  loadingSaveWhitelabel: false,
  successSaveWhitelabel: false,
  errorSaveWhitelabel: false,

  loadingDuplicateWhitelabel: false,
  successDuplicateWhitelabel: false,
  errorDuplicateWhitelabel: false,

  loadingSetDefaultWhitelabel: false,
  successSetDefaultWhitelabel: false,
  errorSetDefaultWhitelabel: false
});

const handleGetWhitelabels = (state, action) => {
  return state
    .set('loadingGetWhitelabels', true)
    .set('successGetWhitelabels', false)
    .set('errorGetWhitelabels', false);
};

const handleGetWhitelabelsSuccess = (state, action) => {
  return state
    .set('whitelabels', List(action.whitelabels))
    .set('loadingGetWhitelabels', false)
    .set('successGetWhitelabels', true)
    .set('errorGetWhitelabels', false);
};

const handleGetWhitelabelsError = (state, action) => {
  return state
    .set('loadingGetWhitelabels', true)
    .set('successGetWhitelabels', false)
    .set('errorGetWhitelabels', action.error);
};

const handleGetWhitelabel = (state, action) => {
  return state
    .set('loadingGetWhitelabel', true)
    .set('successGetWhitelabel', false)
    .set('errorGetWhitelabel', false);
};

const handleGetWhitelabelSuccess = (state, action) => {
  return state
    .set('whitelabel', action.whitelabel)
    .set('loadingGetWhitelabel', false)
    .set('successGetWhitelabel', true)
    .set('errorGetWhitelabel', false);
};

const handleGetWhitelabelError = (state, action) => {
  return state
    .set('loadingGetWhitelabel', false)
    .set('successGetWhitelabel', false)
    .set('errorGetWhitelabel', action.error);
};

const handleSaveWhitelabel = (state, action) => {
  return state
    .set('loadingSaveWhitelabel', true)
    .set('successSaveWhitelabel', false)
    .set('errorSaveWhitelabel', false);
};

const handleSaveWhitelabelSuccess = (state, action) => {
  return state
    .set('whitelabel', action.whitelabel)
    .set('loadingSaveWhitelabel', false)
    .set('successSaveWhitelabel', true)
    .set('errorSaveWhitelabel', false);
};

const handleSaveWhitelabelError = (state, action) => {
  return state
    .set('loadingSaveWhitelabel', false)
    .set('successSaveWhitelabel', false)
    .set('errorSaveWhitelabel', action.error);
};

const handleDuplicateWhitelabel = (state, action) => {
  return state
    .set('loadingDuplicateWhitelabel', true)
    .set('successDuplicateWhitelabel', false)
    .set('errorDuplicateWhitelabel', false);
};

const handleDuplicateWhitelabelSuccess = (state, action) => {
  return state
    .set('whitelabel', action.whitelabel)
    .set('loadingDuplicateWhitelabel', false)
    .set('successDuplicateWhitelabel', true)
    .set('errorDuplicateWhitelabel', false);
};

const handleDuplicateWhitelabelError = (state, action) => {
  return state
    .set('loadingDuplicateWhitelabel', false)
    .set('successDuplicateWhitelabel', false)
    .set('errorDuplicateWhitelabel', action.error);
};

const handleSetDefaultWhitelabel = (state, action) => {
  return state
    .set('loadingSetDefaultWhitelabel', true)
    .set('successSetDefaultWhitelabel', false)
    .set('errorSetDefaultWhitelabel', false);
};

const handleSetDefaultWhitelabelSuccess = (state, action) => {
  return state
    .set('whitelabel', action.whitelabel)
    .set('loadingSetDefaultWhitelabel', false)
    .set('successSetDefaultWhitelabel', true)
    .set('errorSetDefaultWhitelabel', false);
};

const handleSetDefaultWhitelabelError = (state, action) => {
  return state
    .set('loadingSetDefaultWhitelabel', false)
    .set('successSetDefaultWhitelabel', false)
    .set('errorSetDefaultWhitelabel', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_WHITELABELS: return handleGetWhitelabels(state, action);
    case Types.GET_WHITELABELS_SUCCESS: return handleGetWhitelabelsSuccess(state, action);
    case Types.GET_WHITELABELS_ERROR: return handleGetWhitelabelsError(state, action);

    case Types.GET_WHITELABEL: return handleGetWhitelabel(state, action);
    case Types.GET_WHITELABEL_SUCCESS: return handleGetWhitelabelSuccess(state, action);
    case Types.GET_WHITELABEL_ERROR: return handleGetWhitelabelError(state, action);

    case Types.SAVE_WHITELABEL: return handleSaveWhitelabel(state, action);
    case Types.SAVE_WHITELABEL_SUCCESS: return handleSaveWhitelabelSuccess(state, action);
    case Types.SAVE_WHITELABEL_ERROR: return handleSaveWhitelabelError(state, action);

    case Types.DUPLICATE_WHITELABEL: return handleDuplicateWhitelabel(state, action);
    case Types.DUPLICATE_WHITELABEL_SUCCESS: return handleDuplicateWhitelabelSuccess(state, action);
    case Types.DUPLICATE_WHITELABEL_ERROR: return handleDuplicateWhitelabelError(state, action);

    case Types.SET_DEFAULT_WHITELABEL: return handleSetDefaultWhitelabel(state, action);
    case Types.SET_DEFAULT_WHITELABEL_SUCCESS: return handleSetDefaultWhitelabelSuccess(state, action);
    case Types.SET_DEFAULT_WHITELABEL_ERROR: return handleSetDefaultWhitelabelError(state, action);

    case Types.RESET: return handleReset();

    default: return state;
  }
}