import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getTeamsManagementUsers as getGestaoEquipesAtividadesSdk, 
  getTeamsManagementUser as getGestaoEquipesAtividadesUsuarioSdk,
  postActivityCheck as postActivityCheckSdk
} from '@sdk/GestaoEquipe';

export const FILTERS_KEY = 'FILTROS/GESTAO_EQUIPES_ATIVIDADES';

//Action Types
export const Types = {
  GET_GESTAO_EQUIPES_ATIVIDADES: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES',
  GET_GESTAO_EQUIPES_ATIVIDADES_SUCCESS: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES_SUCCESS',
  GET_GESTAO_EQUIPES_ATIVIDADES_ERROR: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES_ERROR',

  GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO',
  GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_SUCCESS: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_SUCCESS',
  GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_ERROR: 'GESTAO_EQUIPES_ATIVIDADES/GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_ERROR',

  POST_CONFERIR_ATIVIDADE: 'GESTAO_EQUIPES_ATIVIDADES/POST_CONFERIR_ATIVIDADE',
  POST_CONFERIR_ATIVIDADE_SUCCESS: 'GESTAO_EQUIPES_ATIVIDADES/POST_CONFERIR_ATIVIDADE_SUCCESS',
  POST_CONFERIR_ATIVIDADE_ERROR: 'GESTAO_EQUIPES_ATIVIDADES/POST_CONFERIR_ATIVIDADE_ERROR',

  RESET: 'GESTAO_EQUIPES_ATIVIDADES/RESET',
};

//Action Creators
export const getGestaoEquipesAtividades = (filtros) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES, filtros });
export const getGestaoEquipesAtividadesSuccess = (usuarios) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES_SUCCESS, usuarios });
export const getGestaoEquipesAtividadesError = (error) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES_ERROR, error }); 

export const getGestaoEquipesAtividadesUsuario = (data, idUsuario) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO, data, idUsuario });
export const getGestaoEquipesAtividadesUsuarioSuccess = (usuario) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_SUCCESS, usuario });
export const getGestaoEquipesAtividadesUsuarioError = (error) => ({ type: Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_ERROR, error }); 

export const postConferirAtividade = (taskId, checked) => ({ type: Types.POST_CONFERIR_ATIVIDADE, taskId, checked });
export const postConferirAtividadeSuccess = (taskId, checked) => ({ type: Types.POST_CONFERIR_ATIVIDADE_SUCCESS, taskId, checked });
export const postConferirAtividadeError = (error) => ({ type: Types.POST_CONFERIR_ATIVIDADE_ERROR, error }); 

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetGestaoEquipesAtividades(action) {
  try {
    const { filtros } = action;
    const usuarios = yield call(getGestaoEquipesAtividadesSdk, filtros);
    yield put(getGestaoEquipesAtividadesSuccess(usuarios));
  } catch (err) {
    Notification.error(err.message);
    yield put(getGestaoEquipesAtividadesError(err));
  }
}

function* fetchGetGestaoEquipesAtividadesUsuario(action) {
  try {
    const { data, idUsuario } = action;
    const usuario = yield call(getGestaoEquipesAtividadesUsuarioSdk, data, idUsuario);
    yield put(getGestaoEquipesAtividadesUsuarioSuccess(usuario));
  } catch (err) {
    Notification.error(err.message);
    yield put(getGestaoEquipesAtividadesUsuarioError(err));
  }
}

function* fetchPostConferirAtividade(action) {
  try {
    const { taskId, checked } = action;
    yield call(postActivityCheckSdk, taskId, checked);
    yield put(postConferirAtividadeSuccess(taskId, checked));
  } catch (err) {
    Notification.error(err.message);
    yield put(postConferirAtividadeError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_GESTAO_EQUIPES_ATIVIDADES, fetchGetGestaoEquipesAtividades),
  takeLatest(Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO, fetchGetGestaoEquipesAtividadesUsuario),
  takeLatest(Types.POST_CONFERIR_ATIVIDADE, fetchPostConferirAtividade),
];

// Reducer
const initialState = Map({
  usuarios: List(),
  loading: false,
  success: false,
  error: false,
  usuario: {},
  loadingUsuario: false,
  successUsuario: false,
  errorUsuario: false,
  loadingConfere: false,
  successConfere: false,
  errorConfere: false
});

const handleGetGestaoEquipesAtividades = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetGestaoEquipesAtividadesSuccess = (state, action) => {
  const { usuarios } = action;

  return state.set('usuarios', usuarios).set('loading', false).set('success', true).set('error', false);
};

const handleGetGestaoEquipesAtividadesError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetGestaoEquipesAtividadesUsuarios = (state, action) => {
  return state.set('loadingUsuario', true).set('successUsuario', false).set('errorUsuario', false);
};

const handleGetGestaoEquipesAtividadesUsuariosSuccess = (state, action) => {
  const { usuario } = action;

  return state.set('usuario', usuario).set('loadingUsuario', false).set('successUsuario', true).set('errorUsuario', false);
};

const handleGetGestaoEquipesAtividadesUsuariosError = (state, action) => {
  return state.set('loadingUsuario', false).set('successUsuario', false).set('errorUsuario', action.error);
};

const handlePostConferirAtividade = (state, action) => {
  const { taskId, checked } = action;

  const currentUser = {
    ...state.get('usuario'),
    tasks: state.get('usuario').tasks.map((task) => task.id === taskId ? ({ ...task, checked }) : ({ ...task }))
  };

  return state.set('usuario', currentUser).set('loadingConfere', true).set('successConfere', false).set('errorConfere', false);
};

const handlePostConferirAtividadeSuccess = (state, action) => {
  return state.set('loadingConfere', false).set('successConfere', true).set('errorConfere', false);
};

const handlePostConferirAtividadeError = (state, action) => {
  return state.set('loadingConfere', false).set('successConfere', false).set('errorConfere', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_GESTAO_EQUIPES_ATIVIDADES:
      return handleGetGestaoEquipesAtividades(state, action);
    case Types.GET_GESTAO_EQUIPES_ATIVIDADES_SUCCESS:
      return handleGetGestaoEquipesAtividadesSuccess(state, action);
    case Types.GET_GESTAO_EQUIPES_ATIVIDADES_ERROR:
      return handleGetGestaoEquipesAtividadesError(state, action);

    case Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO:
      return handleGetGestaoEquipesAtividadesUsuarios(state, action);
    case Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_SUCCESS:
      return handleGetGestaoEquipesAtividadesUsuariosSuccess(state, action);
    case Types.GET_GESTAO_EQUIPES_ATIVIDADES_USUARIO_ERROR:
      return handleGetGestaoEquipesAtividadesUsuariosError(state, action);

    case Types.POST_CONFERIR_ATIVIDADE:
      return handlePostConferirAtividade(state, action);
    case Types.POST_CONFERIR_ATIVIDADE_SUCCESS:
      return handlePostConferirAtividadeSuccess(state, action);
    case Types.POST_CONFERIR_ATIVIDADE_ERROR:
      return handlePostConferirAtividadeError(state, action);
    
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}