import styled from 'styled-components';
import { Progress } from 'antd';

export const StyledProgressComponent = styled(Progress)`
  ${({ height }) => height && `
    height: ${height};

    .ant-progress-inner {
      height: 100% !important;
    }
  `}

  ${({ type, width }) => type === 'line' && `
    ${width && `
      width: ${width};
    `}

    display: flex;

    .ant-progress-inner {
      width: 100% !important;
    }
  `}

  .ant-progress-text {
    top: 60%;
    color: ${({ reachedGoal,  percent, successColor, averageColor , dangerColor }) => reachedGoal ? successColor :  percent >= 70 ? averageColor : dangerColor} !important;
    font-weight: 700;
    font-size: ${({ fontSize }) => fontSize};
  }

  .ant-progress-inner {
    background-color: ${({ trailColor }) => trailColor};
  }
  ${({ type }) => !type || type === 'line' ? `
    margin-bottom: 6px;

    .ant-progress-outer {
      display: flex;
      align-items: center;
    }
  ` : (type === 'circle' || type === 'dashboard') && `
    .ant-progress-text {
      top: 60%;
      color: ${({ reachedGoal, percent,  successColor, averageColor, dangerColor }) => reachedGoal ? successColor :  percent >= 70 ? averageColor : dangerColor} !important;
      font-weight: 700;
      font-size: ${({ fontSize }) => fontSize};
    }
    path {
      stroke-width: ${({ strokeWidth }) => strokeWidth};
    }
  `}
`;
