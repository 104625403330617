import styled from 'styled-components';
import { Icon, Row, Text } from '@components-teammove';

export const AnimationContainer = styled.div`
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .lf-player-container {
        width: 40%;
        height: 40%;   
    }
`;

export const ContainerInteractionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    border: 1px solid ${({ theme, microphoneMuted }) => microphoneMuted ? 'var(--warning-color)' : theme.darkPrimaryColor};
    background-color: ${({ theme, color }) => color === 'red' ? 'var(--danger-color)' : theme.darkSecondaryColor};
`;

export const IconsInteraction = styled(Icon.MDI)`
    font-size: 32px;
    color: ${({ theme, microphoneMuted, levelMicrophoneVolume, levelAudioVolume }) => {
  if (levelMicrophoneVolume) {
    if (levelMicrophoneVolume === 'volume-high' || levelMicrophoneVolume === 'volume-medium') {
      return 'var(--success-color)';
    } else if (levelMicrophoneVolume === 'volume-low') {
      return 'var(--warning-color)';
    } else if (levelMicrophoneVolume === 'volume-off') {
      return 'var(--danger-color)';
    }
  }
  if(levelAudioVolume) {
    if (levelAudioVolume === 'volume-high' || levelAudioVolume === 'volume-medium') {
      return 'var(--success-color)';
    } else if (levelAudioVolume === 'volume-low') {
      return 'var(--warning-color)';
    } else if (levelAudioVolume === 'volume-off') {
      return 'var(--danger-color)';
    }
  }
  return microphoneMuted ? 'var(--warning-color)' : theme.textColor;
}};
`;

export const IconFeather = styled(Icon.Feather)`
    font-size: 20px;
    color: var(--warning-color);
`;

export const VolumeControllerContainer = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const AudioText = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
`;

export const ButtonOptionsText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
`;

export const CounterTimer = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 32.68px;
  color: var(--success-color) !important;
`;