import styled from 'styled-components';
import { Button, PopConfirm, Icon } from '@components-teammove';

export const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 85%;
  }
`;

export const DeleteIcon = styled(Icon.Feather)`
  cursor: pointer;
  color: var(--danger-color);

  * {
    color: var(--danger-color);
  }
`;

export const TableHeaderLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
  margin: 0 0 8px 8px;
`;

export const FillHeightButton = styled(Button)`
  &.ant-btn {
    height: 100%;
  }
`;

export const DeleteButton = styled(PopConfirm)`
  height: 56.34px !important; 
`;