import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { Map, OrderedMap } from 'immutable';
import { Notification, dateToAnsi } from '@utils';
import { 
  getChamado as getChamadoSdk, 
  sendMessage as sendMessageSdk, 
  deleteMessage as deleteMessageSdk,
  updateReadStatus as changeReadStatusSdk,
  changeCategoria as changeCategoriaSdk, 
  changeStatus as changeStatusSdk, 
  changeResponsavel as changeResponsavelSdk, 
  changeLido as changeLidoSdk,
  changeAguardandoResposta as changeAguardandoRespostaSdk,
  changeChamadoTitulo as changeChamadoTituloSdk,
  putCompany as putCompanySdk,
} from '@sdk/Chamados';
import { TIPO } from '@containers/Chamados/Chat/rules';
import { STATUS } from '@containers/Chamados/rules';
import { updateChamado } from './chamadosTickets';

//Action Types
export const Types = {
  GET_CHAMADO: 'CHAMADOS_HISTORICO/GET_CHAMADO',
  GET_CHAMADO_SUCCESS: 'CHAMADOS_HISTORICO/GET_CHAMADO_SUCCESS',
  GET_CHAMADO_ERROR: 'CHAMADOS_HISTORICO/GET_CHAMADO_ERROR',

  SEND_MESSAGE: 'CHAMADOS_HISTORICO/SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'CHAMADOS_HISTORICO/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'CHAMADOS_HISTORICO/SEND_MESSAGE_ERROR',

  DELETE_MESSAGE: 'CHAMADOS_HISTORICO/DELETE_MESSAGE',
  DELETE_MESSAGE_SUCCESS: 'CHAMADOS_HISTORICO/DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_ERROR: 'CHAMADOS_HISTORICO/DELETE_MESSAGE_ERROR',
  
  CHANGE_CATEGORIA: 'CHAMADOS_HISTORICO/CHANGE_CATEGORIA',
  CHANGE_CATEGORIA_SUCCESS: 'CHAMADOS_HISTORICO/CHANGE_CATEGORIA_SUCCESS',
  CHANGE_CATEGORIA_ERROR: 'CHAMADOS_HISTORICO/CHANGE_CATEGORIA_ERROR',

  CHANGE_RESPONSAVEL: 'CHAMADOS_HISTORICO/CHANGE_RESPONSAVEL',
  CHANGE_RESPONSAVEL_SUCCESS: 'CHAMADOS_HISTORICO/CHANGE_RESPONSAVEL_SUCCESS',
  CHANGE_RESPONSAVEL_ERROR: 'CHAMADOS_HISTORICO/CHANGE_RESPONSAVEL_ERROR',

  CHANGE_STATUS: 'CHAMADOS_HISTORICO/CHANGE_STATUS',
  CHANGE_STATUS_SUCCESS: 'CHAMADOS_HISTORICO/CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_ERROR: 'CHAMADOS_HISTORICO/CHANGE_STATUS_ERROR',

  CHANGE_AGUARDANDO_RESPOSTA: 'CHAMADOS_HISTORICO/CHANGE_AGUARDANDO_RESPOSTA',
  CHANGE_AGUARDANDO_RESPOSTA_SUCCESS: 'CHAMADOS_HISTORICO/CHANGE_AGUARDANDO_RESPOSTA_SUCCESS',
  CHANGE_AGUARDANDO_RESPOSTA_ERROR: 'CHAMADOS_HISTORICO/CHANGE_AGUARDANDO_RESPOSTA_ERROR',

  CHANGE_STATUS_LIDO: 'CHAMADOS_HISTORICO/CHANGE_STATUS_LIDO',

  CHANGE_READ_STATUS: 'CHAMADOS_HISTORICO/CHANGE_READ_STATUS',

  CHANGE_CHAMADO_TITULO: 'CHAMADOS_HISTORICO/CHANGE_CHAMADO_TITULO',
  CHANGE_CHAMADO_TITULO_SUCCESS: 'CHAMADOS_HISTORICO/CHANGE_CHAMADO_TITULO_SUCCESS',
  CHANGE_CHAMADO_TITULO_ERROR: 'CHAMADOS_HISTORICO/CHANGE_CHAMADO_TITULO_ERROR',

  CHANGE_VISIBLE_BRIEFING_REPLY: 'CHAMADOS_HISTORICO/CHANGE_VISIBLE_BRIEFING_REPLY',
  CHANGE_VISIBLE_BRIEFING_REPLY_ETAPAS: 'CHAMADOS_HISTORICO/CHANGE_VISIBLE_BRIEFING_REPLY_ETAPAS',
  CHANGE_VISIBLE_REPORT_REPLY: 'CHAMADOS_HISTORICO/CHANGE_VISIBLE_REPORT_REPLY',

  PUT_COMPANY: 'CHAMADOS_HISTORICO/PUT_COMPANY',
  PUT_COMPANY_SUCCESS: 'CHAMADOS_HISTORICO/PUT_COMPANY_SUCCESS',
  PUT_COMPANY_ERROR: 'CHAMADOS_HISTORICO/PUT_COMPANY_ERROR',

  RESET: 'CHAMADOS_HISTORICO/RESET',
};

//Action Creators
export const getChamado = (id) => ({ type: Types.GET_CHAMADO, id });
export const getChamadoSuccess = (data) => ({ type: Types.GET_CHAMADO_SUCCESS, data });
export const getChamadoError = (error) => ({ type: Types.GET_CHAMADO_ERROR, error });

export const sendMessage = (message, usuario) => ({ type: Types.SEND_MESSAGE, message, usuario });
export const sendMessageSuccess = (data) => ({ type: Types.SEND_MESSAGE_SUCCESS, data });
export const sendMessageError = (error) => ({ type: Types.SEND_MESSAGE_ERROR, error });

export const deleteMessage = (message) => ({ type: Types.DELETE_MESSAGE, message });
export const deleteMessageSuccess = (data) => ({ type: Types.DELETE_MESSAGE_SUCCESS, data });
export const deleteMessageError = (error) => ({ type: Types.DELETE_MESSAGE_ERROR, error });

export const changeCategoria = (idChamado, categoria) => ({ type: Types.CHANGE_CATEGORIA, idChamado, categoria });
export const changeCategoriaSuccess = (data) => ({ type: Types.CHANGE_CATEGORIA_SUCCESS, data });
export const changeCategoriaError = (error) => ({ type: Types.CHANGE_CATEGORIA_ERROR, error });

export const changeResponsavel = (idChamado, novoResponsavel) => ({ type: Types.CHANGE_RESPONSAVEL, idChamado, novoResponsavel });
export const changeResponsavelSuccess = (data) => ({ type: Types.CHANGE_RESPONSAVEL_SUCCESS, data });
export const changeResponsavelError = (error) => ({ type: Types.CHANGE_RESPONSAVEL_ERROR, error });

export const changeStatus = (idChamado, status) => ({ type: Types.CHANGE_STATUS, idChamado, status });
export const changeStatusSuccess = (data) => ({ type: Types.CHANGE_STATUS_SUCCESS, data });
export const changeStatusError = (error) => ({ type: Types.CHANGE_STATUS_ERROR, error });

export const changeAguardandoResposta = (idChamado, idUsuario, aguardandoResposta, mensagem) => ({ type: Types.CHANGE_AGUARDANDO_RESPOSTA, idUsuario, idChamado, aguardandoResposta, mensagem });
export const changeAguardandoRespostaSuccess = (data) => ({ type: Types.CHANGE_AGUARDANDO_RESPOSTA_SUCCESS, data });
export const changeAguardandoRespostaError = (error) => ({ type: Types.CHANGE_AGUARDANDO_RESPOSTA_ERROR, error });

export const changeStatusLido = (idUsuario, idChamado) => ({ type: Types.CHANGE_STATUS_LIDO, idUsuario, idChamado });

export const changeReadStatus = (idChamado) => ({ type: Types.CHANGE_READ_STATUS, idChamado });

export const changeChamadoTitulo = (idChamado, titulo) => ({ type: Types.CHANGE_CHAMADO_TITULO, idChamado, titulo });
export const changeChamadoTituloSuccess = (data) => ({ type: Types.CHANGE_CHAMADO_TITULO_SUCCESS, data });
export const changeChamadoTituloError = (error) => ({ type: Types.CHANGE_CHAMADO_TITULO_ERROR, error });

export const changeVisibleBriefingReply = (visible) => ({ type: Types.CHANGE_VISIBLE_BRIEFING_REPLY, visible });
export const changeVisibleBriefingReplyEtapas = (visible) => ({ type: Types.CHANGE_VISIBLE_BRIEFING_REPLY_ETAPAS, visible });
export const changeVisibleReportReply = (visible) => ({ type: Types.CHANGE_VISIBLE_REPORT_REPLY, visible });

export const putCompany = ( idChamado, idCompany )=> ({ type: Types.PUT_COMPANY, idChamado, idCompany });
export const putCompanySuccess = (data) => ({ type: Types.PUT_COMPANY_SUCCESS, data });
export const putCompanyError = (error) => ({ type: Types.PUT_COMPANY_ERROR, error });

export const reset = () => ({ type: Types.RESET });
//saga
function* fetchGetChamado(action) {
  try {
    const { id } = action;

    const retorno = yield call(getChamadoSdk, id);

    yield put(getChamadoSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChamadoError(err));
  }
}

function* fetchSendMessage(action) {
  try {
    const { message } = action;

    const retorno = yield call(sendMessageSdk, message);
    yield put(sendMessageSuccess(retorno));

    if (message.tipo === TIPO.AVALIACAO) {
      const retornoStatus = yield call(changeStatusSdk, message.idChamado, STATUS.FECHADO);
      yield put(changeStatusSuccess(retornoStatus));
    }
  } catch (err) {
    Notification.error(err.message);
    yield put(sendMessageError(err));
  }
}

function* fetchDeleteMessage(action) {
  try {
    const { message } = action;

    const retorno = yield call(deleteMessageSdk, message.id);

    yield call(getChamadoSdk, message.idChamado);

    yield put(deleteMessageSuccess(retorno));

  } catch (err) {
    Notification.error(err.message);
    yield put(deleteMessageError(err));
  }
}

function* fetchChangeCategoria(action) {
  try {
    const { idChamado, categoria } = action;

    const retorno = yield call(changeCategoriaSdk, idChamado, categoria);
    yield put(changeCategoriaSuccess(retorno));
    yield put(updateChamado(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(changeCategoriaError(err));
  }
}

function* fetchChangeResponsavel(action) {
  try {
    const { idChamado, novoResponsavel } = action;

    const retorno = yield call(changeResponsavelSdk, idChamado, novoResponsavel);
    yield put(changeResponsavelSuccess(retorno));
    yield put(updateChamado(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(changeResponsavelError(err));
  }
}

function* fetchChangeStatus(action) {
  try {
    const { idChamado, status } = action;

    const retorno = yield call(changeStatusSdk, idChamado, status);
    yield put(changeStatusSuccess(retorno));
    
  } catch (err) {
    if(err.message.includes('Há etapa')) {
      Notification.warning('Há etapas pendentes para serem concluídas.');
    }else if(err.message.includes('Há report')) {
      Notification.warning('Há reports pendentes para serem concluídos.');
    }else{
      Notification.error(err.message);
    }
    
    yield put(changeStatusError(err));
  }
}

function* fetchChangeStatusLido(action) {
  try {
    const { idUsuario, idChamado } = action;

    yield call(changeLidoSdk, idUsuario, idChamado);    
  } catch (err) {
    console.error(err);
  }
}

function* fetchChangeReadStatus(action) {
  try {
    const { idChamado } = action;

    yield call(changeReadStatusSdk, idChamado);    
    yield put(updateChamado({ id: idChamado }, { updateUnseenMessages: true }));
  } catch (err) {
    console.error(err);
  }
}

function* fetchChangeAguardandoResposta(action) {
  try {
    const { idChamado, idUsuario, aguardandoResposta, mensagem } = action;
    const retorno = yield call(changeAguardandoRespostaSdk, idChamado, { idUsuario, aguardandoResposta, mensagem });
    
    yield put(changeAguardandoRespostaSuccess(retorno));
    yield put(updateChamado(retorno));
  } catch(err) {
    Notification.error(err.message);
    yield put(changeAguardandoRespostaError(err));
  }
}

function* fetchChangeChamadoTitulo(action) {
  try {
    const { idChamado, titulo } = action;
    
    const retorno = yield call(changeChamadoTituloSdk, idChamado, titulo);

    yield put(changeChamadoTituloSuccess(retorno));
    yield put(updateChamado(retorno));
  } catch (err) {
    Notification.error(err.message);   
    yield put(changeChamadoTituloError(err));
  }
}

function* fetchPutCompany(action) {
  try{
    const { idChamado, idCompany } = action;
    const chamado = yield call(putCompanySdk, idChamado, idCompany);
    yield put(putCompanySuccess(chamado));
    yield put(updateChamado(chamado));
  }catch(err) {
    Notification.error(err.message);
    yield put(putCompanyError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHAMADO, fetchGetChamado),  
  takeLatest(Types.CHANGE_CATEGORIA, fetchChangeCategoria),
  takeLatest(Types.CHANGE_RESPONSAVEL, fetchChangeResponsavel),
  takeLatest(Types.CHANGE_STATUS, fetchChangeStatus),
  takeLatest(Types.CHANGE_STATUS_LIDO, fetchChangeStatusLido),
  takeLatest(Types.CHANGE_READ_STATUS, fetchChangeReadStatus),
  takeLatest(Types.CHANGE_AGUARDANDO_RESPOSTA, fetchChangeAguardandoResposta),
  takeEvery(Types.SEND_MESSAGE, fetchSendMessage),
  takeEvery(Types.DELETE_MESSAGE, fetchDeleteMessage),
  takeLatest(Types.CHANGE_CHAMADO_TITULO, fetchChangeChamadoTitulo),
  takeLatest(Types.PUT_COMPANY, fetchPutCompany)
];

// Reducer
const initialState = Map({
  chamado: {},
  historicos: OrderedMap(),

  visibleBriefingReply: false,
  visibleBriefingReplyEtapas: false,
  visibleReportReply: false,

  loading: false,
  success: false,
  error: false,

  loadingCategoria: false,
  successCategoria: false,
  errorCategoria: false,

  loadingStatus: false,
  successStatus: false,
  errorStatus: false,

  loadingEnvio: false,
  successEnvio: false,
  errorEnvio: false,

  loadingDelete: false,
  successDelete: false,
  errorDelete: false,

  loadingAguardandoResposta: false,
  successAguardandoResposta: false,
  errorAguardandoResposta: false,

  loadingChamadoTitulo: false,
  successChamadoTitulo: false,
  errorChamadoTitulo: false,
  loadingPutCompany: false, 
  successPutCompany: false,
  errorPutCompany: false,
});

const handleGetChamado = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetChamadoSuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;

  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetChamadoError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleChangeVisibleBriefingReply = (state, action) => {
  const { visible } = action;
  
  return state
    .set('visibleBriefingReply', visible);
};

const handleChangeVisibleBriefingReplyEtapas = (state, action) => {
  const { visible } = action;
  
  return state
    .set('visibleBriefingReplyEtapas', visible);
};

const handleChangeVisibleReportReply = (state, action) => {
  const { visible } = action;
  
  return state
    .set('visibleReportReply', visible);
};

const handleSendMessage = (state, action) => {
  const { message, usuario } = action;

  return state
    .set('loadingEnvio', true)
    .set('successEnvio', false)
    .set('errorEnvio', false)
    .set('historicos', state.get('historicos').set(message.idTemp, {
      ...message,
      dtHistorico: dateToAnsi(new Date()),
      nmUsuario: usuario.nmUsuario,
      status: 'waiting',
    }));
};

const handleSendMessageSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('loadingEnvio', false)
    .set('successEnvio', true)
    .set('errorEnvio', false)
    .set('historicos', state.get('historicos').update(data.idTemp, (message) => ({
      ...data,
      status: 'sent',
    })));
};

const handleSendMessageError = (state, action) => {
  return state
    .set('loadingEnvio', false)
    .set('successEnvio', false)
    .set('errorEnvio', action.error);
};

const handleDeleteMessage = (state, action) => {
  const { message } = action;

  return state
    .set('loadingDelete', true)
    .set('successDelete', false)
    .set('errorDelete', false)
    .set('historicos', state.get('historicos').set(message.idTemp, {
      ...message,
      status: 'waiting',
    }));
};

const handleDeleteMessageSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('loadingDelete', false)
    .set('successDelete', true)
    .set('errorDelete', false)
    .set('historicos', state.get('historicos').update(data.idTemp, (message) => ({
      ...data,
      status: 'sent',
    })));
};

const handleDeleteMessageError = (state, action) => {
  return state
    .set('loadingDelete', false)
    .set('successDelete', false)
    .set('errorDelete', action.error);
};

const handleChangeCategoria = (state, action) => {
  return state
    .set('loadingCategoria', true)
    .set('successCategoria', false)
    .set('errorCategoria', false);
};

const handleChangeCategoriaSuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;

  if (state.get('visibleChat') && chamado.status === STATUS.AGUARDANDO_BRIEFING) {
    state.set('visibleChat', false).set('visibleBriefingReply', true);
  }
  
  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loadingCategoria', false)
    .set('successCategoria', true)
    .set('errorCategoria', false);
};

const handleChangeCategoriaError = (state, action) => {
  return state
    .set('loadingCategoria', false)
    .set('successCategoria', false)
    .set('errorCategoria', action.error);
};

const handleChangeStatus = (state, action) => {
  return state
    .set('loadingStatus', true)
    .set('successStatus', false)
    .set('errorStatus', false);
};

const handleChangeStatusSuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;

  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loadingStatus', false)
    .set('successStatus', true)
    .set('errorStatus', false);
};

const handleChangeStatusError = (state, action) => {
  return state
    .set('loadingStatus', false)
    .set('successStatus', false)
    .set('errorStatus', action.error);
};

const handleChangeResponsavel = (state, action) => {
  return state
    .set('loadingResponsavel', true)
    .set('successResponsavel', false)
    .set('errorResponsavel', false);
};

const handleChangeResponsavelSuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;

  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loadingResponsavel', false)
    .set('successResponsavel', true)
    .set('errorResponsavel', false);
};

const handleChangeResponsavelError = (state, action) => {
  return state
    .set('loadingResponsavel', false)
    .set('successResponsavel', false)
    .set('errorResponsavel', action.error);
};

const handleChangeAguardandoResposta = (state, action) => {
  return state
    .set('loadingAguardandoResposta', true)
    .set('successAguardandoResposta', false)
    .set('errorAguardandoResposta', false);
};

const handleChangeAguardandoRespostaSuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;

  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loadingAguardandoResposta', false)
    .set('successAguardandoResposta', true)
    .set('errorAguardandoResposta', false);
};

const handleChangeAguardandoRespostaError = (state, action) => {
  return state
    .set('loadingAguardandoResposta', false)
    .set('successAguardandoResposta', false)
    .set('errorAguardandoResposta', action.error);
};

const handleChangeChamadoTitulo = (state, action) => {
  return state
    .set('loadingChamadoTitulo', true)
    .set('successChamadoTitulo', false)
    .set('errorChamadoTitulo', false);
};

const handleChangeChamadoTituloSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('chamado', data)
    .set('historicos', OrderedMap(data.historico.map((item) => [item.idTemp, item])))
    .set('loadingChamadoTitulo', false)
    .set('successChamadoTitulo', true)
    .set('errorChamadoTitulo', false);
};

const handleChangeChamadoTituloError = (state, action) => {
  return state
    .set('loadingChamadoTitulo', false)
    .set('successChamadoTitulo', false)
    .set('errorChamadoTitulo', action.error);
};

const handlePutCompany = (state, action) => {
  return state
    .set('loadingPutCompany', true)
    .set('successPutCompany', false)
    .set('errorPutCompany', action.error);
};

const handlePutCompanySuccess = (state, action) => {
  const { data: { historico, ...chamado } } = action;
  return state
    .set('chamado', chamado)
    .set('historicos', OrderedMap(historico.map((item) => [item.idTemp, item])))
    .set('loadingPutCompany', false)
    .set('successPutCompany', true)
    .set('errorPutCompany', action.error);
};

const handlePutCompanyError = (state, action) => {
  return state
    .set('loadingPutCompany', false)
    .set('successPutCompany', false)
    .set('errorPutCompany', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHAMADO: return handleGetChamado(state, action);
    case Types.GET_CHAMADO_SUCCESS: return handleGetChamadoSuccess(state, action);
    case Types.GET_CHAMADO_ERROR: return handleGetChamadoError(state, action);

    case Types.SEND_MESSAGE: return handleSendMessage(state, action);
    case Types.SEND_MESSAGE_SUCCESS: return handleSendMessageSuccess(state, action);
    case Types.SEND_MESSAGE_ERROR: return handleSendMessageError(state, action);

    case Types.DELETE_MESSAGE: return handleDeleteMessage(state, action);
    case Types.DELETE_MESSAGE_SUCCESS: return handleDeleteMessageSuccess(state, action);
    case Types.DELETE_MESSAGE_ERROR: return handleDeleteMessageError(state, action);

    case Types.CHANGE_CATEGORIA: return handleChangeCategoria(state, action);
    case Types.CHANGE_CATEGORIA_SUCCESS: return handleChangeCategoriaSuccess(state, action);
    case Types.CHANGE_CATEGORIA_ERROR: return handleChangeCategoriaError(state, action);

    case Types.CHANGE_RESPONSAVEL: return handleChangeResponsavel(state, action);
    case Types.CHANGE_RESPONSAVEL_SUCCESS: return handleChangeResponsavelSuccess(state, action);
    case Types.CHANGE_RESPONSAVEL_ERROR: return handleChangeResponsavelError(state, action);

    case Types.CHANGE_STATUS: return handleChangeStatus(state, action);
    case Types.CHANGE_STATUS_SUCCESS: return handleChangeStatusSuccess(state, action);
    case Types.CHANGE_STATUS_ERROR: return handleChangeStatusError(state, action);

    case Types.CHANGE_AGUARDANDO_RESPOSTA: return handleChangeAguardandoResposta(state, action);
    case Types.CHANGE_AGUARDANDO_RESPOSTA_SUCCESS: return handleChangeAguardandoRespostaSuccess(state, action);
    case Types.CHANGE_AGUARDANDO_RESPOSTA_ERROR: return handleChangeAguardandoRespostaError(state, action);

    case Types.CHANGE_VISIBLE_BRIEFING_REPLY: return handleChangeVisibleBriefingReply(state, action);
    case Types.CHANGE_VISIBLE_BRIEFING_REPLY_ETAPAS: return handleChangeVisibleBriefingReplyEtapas(state, action);
    case Types.CHANGE_VISIBLE_REPORT_REPLY: return handleChangeVisibleReportReply(state, action);

    case Types.CHANGE_CHAMADO_TITULO: return handleChangeChamadoTitulo(state, action);
    case Types.CHANGE_CHAMADO_TITULO_SUCCESS: return handleChangeChamadoTituloSuccess(state, action);
    case Types.CHANGE_CHAMADO_TITULO_ERROR: return handleChangeChamadoTituloError(state, action);

    case Types.PUT_COMPANY: return handlePutCompany(state, action);
    case Types.PUT_COMPANY_SUCCESS: return handlePutCompanySuccess(state, action);
    case Types.PUT_COMPANY_ERROR: return handlePutCompanyError(state, action);
    
    case Types.RESET: return initialState;

    default: return state;
  }
}
