import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Switch, Skeleton } from '@components-teammove';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, resetSuccess, putCategory, postCategory } from '@ducks/settingsProductsCategories';
import { InitialValues } from './rules';

export default function CategoryRegister({ onCancel, visible }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const id = location.state?.data.id;
  const history = useHistory();
  const dispatch = useDispatch();

  const categories = useSelector(({ categories }) => categories.get('categories'));
  const loadingCategories = useSelector(({ categories }) => categories.get('loadingCategories'));
  const successCategories = useSelector(({ categories }) => categories.get('successCategories'));
  
  const loadingCategorySave = useSelector(({ categories }) => categories.get('loadingCategorySave'));

  const [originalFieldsValue, setOriginalFieldsValue] = useState();
  
  useEffect(()=>{
    if(id !== undefined) {
      dispatch(getCategories(parseInt(id)));
    }else{
      form.setFieldsValue(InitialValues);
    }
  },[]);

  useEffect(() => {
    return () => {
      history.push('#');
      dispatch(resetSuccess());
    };
  }, []);
  
  useEffect(() => { 
    if(successCategories) {
      form.setFieldsValue(categories);
      setOriginalFieldsValue(form.getFieldsValue());
    }
  }, [successCategories]);

  const handleSave = ()=>{
    form.validateFields().then((values) =>{
      if(id !== undefined) {
        values.id = id;
        dispatch(putCategory(values));
      }else{
        dispatch(postCategory(values));
        onCancel();
      }
    });
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return(
    <Modal
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      visible={visible}
      title='Nova Categoria'
      okButtonProps={{ loading: loadingCategorySave }}
      onOk={handleSave}
    >
      <Form layout='vertical' form={form}>
        <Form.Item label='Título da categoria' name='name' rules={[{ required: true, message: 'Informe o titulo!' }]}>
          {loadingCategories ? <Skeleton.Form/> : (
            <Input placeholder='Informe o título da categoria' autoFocus/>
          )}
        </Form.Item>            
        {id !== undefined && (
          <Form.Item label='Ativo' name='active' valuePropName='checked'>
            {loadingCategories ? <Skeleton.Circle/> : (
              <Switch/>)}
          </Form.Item>
        )}
      </Form>

    </Modal>
  );
}