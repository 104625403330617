import React from 'react';
import { View, Form, Input, Button } from '@components';
import styled from 'styled-components';
import { apiEditComentario, setPosting } from '@ducks/wikisComentario';
import { useDispatch } from 'react-redux';

const ViewComentario = styled(View)`
  width: 100%;
`;

const TextoComentario = styled(View)`
  width: 100%;
  white-space: pre;
`;

const BotoesComentario = styled(View)`
  display: ${({ display }) => display};
  margin-top: 10px;
  justify-content: space-between;
  align-items: flex-end;
  width: 160px;
`;

export default function Editar({ data, handlers }) {
  const [form] = Form.useForm();
  const { id, texto, comentarios, edicaoComentario } = data;
  const dispatch = useDispatch();

  const handleEditarComentario = (id) =>
    form.validateFields().then(async (values) => {
      const comentariosCopy = [...comentarios];
      const comentario = comentariosCopy.filter((cmt) => cmt.id === id)[0];
      comentario.comentario = values.comentario;

      dispatch(setPosting(true));

      const result = await apiEditComentario({ comentario: comentario.comentario, id });

      if (result) {
        comentario.dtAlteracao = result.dtAlteracao;

        handlers.setComentarios(comentariosCopy);
        handlers.setEdicaoComentario({ display: 'none' });
      }

      dispatch(setPosting(false));
    });

  const getViewEdicao = () => {
    form.setFieldsValue({ comentario: texto });
    return (
      <ViewComentario>
        <Form form={form}>
          <Form.Item name='comentario'>
            <Input.TextArea autoSize />
          </Form.Item>
          <View alignItems='flex-end'>
            <BotoesComentario display={id === edicaoComentario} horizontal>
              <Button onClick={(event) => handlers.click(event, () => handlers.setEdicaoComentario({ display: 'none' }))}>Cancelar</Button>
              <Button type='primary' htmlType='submit' onClick={(event) => handlers.click(event, () => handleEditarComentario(id))}>
                Editar
              </Button>
            </BotoesComentario>
          </View>
        </Form>
      </ViewComentario>
    );
  };

  return edicaoComentario.display === 'flex' && edicaoComentario.id === id ? getViewEdicao() : <TextoComentario>{texto}</TextoComentario>;
}
