import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button, Form } from '@components-teammove';
import { useHistory } from 'react-router-dom';
import { Session, Filtros, compareDates, getCurrentMomentStartOfMonth, InfoUsuario } from '@utils';
import { PARAMS_KEY } from '@ducks/atividadesGestaoAgenda';
import { useDispatch, useSelector } from 'react-redux';
import TiposAtividades from './TiposAtividades';
import MetasAtividades from './MetasAtividades';
import { putMetasAtividadesDispatch } from './MetasAtividades/rules';
import AgrupadoresMetas from './AgrupadoresMetas';

export default function ConfiguracoesAtividades() {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_TICKETS_TAB') || `${InfoUsuario.perm('atividadesTiposConfiguracoes') ? 'Tipos de atividades' : 'Metas de atividades'}`);
  const [modalMetaVisible ,setModalMetaVisible] = useState(false);
  const [modalTipoVisible, setModalTipoVisible] = useState(false);
  const [typeMetaModal, setTypeMetaModal] = useState('addUser');
  const [currentParams, setCurrentParams] = useState(Filtros.get(PARAMS_KEY));
  const [users, setUsers] = useState([]);
  const [allUsersAdd, setAllUsersAdd] = useState([]);
  const [activityKinds, setActivityKinds] = useState([]);
  const [savedFormValues, setSavedFormValues] = useState(null);
  const [visibleAddAgrupadorModalMeta, setVisibleAddAgrupadorModalMeta] = useState(false);

  const permissaoMetas = InfoUsuario.perm('atividadesMetaCadastro');
  const permissaoTipoAtividades = InfoUsuario.perm('atividadesTiposConfiguracoes');

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_TICKETS_TAB', newTab);
  };

  const ExtraTipoAtividade = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => {history.push('?id=-1'); setModalTipoVisible(true);}}>Novo</Button>,
  ];

  const ExtraMetaAtividade = () => [
    <Button key="2" type="secondary" size='small' context='header' onClick={() => {setModalMetaVisible(true); setTypeMetaModal('addActivity');}}>Adicionar nova atividade</Button>,
    <Button key="2" type="primary" size='small' context='header' disabled={!compareDates(currentParams.initialDate, getCurrentMomentStartOfMonth()) || users.length === 0} onClick={() => handleSaveMetaAtividade()}>Salvar metas de atividade</Button>,
  ];

  const ExtraAgrupadoresMeta = () => [
    <Button key="3" type="primary" size='small' context='header' onClick={() => setVisibleAddAgrupadorModalMeta(true)}>Adicionar agrupador</Button>,
  ];

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    Filtros.set(PARAMS_KEY, { ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  const handleSaveMetaAtividade = () => {
    putMetasAtividadesDispatch(allUsersAdd, form, dispatch, currentParams.initialDate, setSavedFormValues, activityKinds);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Configurações Atividades'}
          extra={currentTab === 'Tipos de atividades' ? <ExtraTipoAtividade/> : currentTab === 'Metas de atividades' ? <ExtraMetaAtividade/> : <ExtraAgrupadoresMeta/>}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/atividades', name: 'Configurações de atividades', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs
              options={[
                permissaoTipoAtividades && { name: 'Tipos de atividades' }, 
                permissaoMetas && { name: 'Metas de atividades' },
                permissaoMetas && { name: 'Agrupadores de metas' }
              ].filter(Boolean)}
              current={currentTab}
              onChange={handleChangeTab}
            />
            {currentTab === 'Tipos de atividades' ? (
              <TiposAtividades setModalTipoVisible={setModalTipoVisible} modalTipoVisible={modalTipoVisible}/>
            ) : currentTab === 'Metas de atividades' ? (
              <MetasAtividades
                setModalMetaVisible={setModalMetaVisible}
                modalMetaVisible={modalMetaVisible}
                currentParams={currentParams} 
                onChange={handleChangePeriod}
                typeMetaModal={typeMetaModal}
                setTypeMetaModal={setTypeMetaModal}
                form={form}
                users={users}
                setUsers={setUsers}
                savedFormValues={savedFormValues}
                setSavedFormValues={setSavedFormValues}
                activityKinds={activityKinds}
                setActivityKinds={setActivityKinds}
                setAllUsersAdd={setAllUsersAdd}
                allUsersAdd={allUsersAdd}
              />
            ) : 
              <AgrupadoresMetas
                visibleAddAgrupadorModalMeta={visibleAddAgrupadorModalMeta}
                setVisibleAddAgrupadorModalMeta={setVisibleAddAgrupadorModalMeta}
              />
            }
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}