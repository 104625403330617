import styled from 'styled-components';
import { Text, Button, Tabs, Row } from '@components-teammove';
import Icon from '../Icon';

import { lighten } from 'polished';

const Event = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 11px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  padding: 7px ${({ view }) => view === 'week' ? 4 : 12}px 0;
  flex: 1;
  min-height: 31px;
  border: 2px solid ${({ theme }) => theme.darkHighlightColor};
  border-left: 2px solid ${({ theme }) => theme.darkHighlightColor};
  ${({ canceled, finished }) => (canceled || finished) && `
    opacity: .5;
  `}
  ${({ canceled }) => canceled && `
    text-decoration: line-through;
  `}
`;

export const WeekDay = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
`;

export const MonthDay = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
`;

export const Container = styled.div`
  .rbc-calendar {
    .rbc-toolbar {
      gap: 10px;

      .rbc-toolbar-label {
        font-size: 18px;
        font-weight: 700;
        color: ${({ theme }) => theme.textColor};
        text-align: start;
      }

      .rbc-btn-group {
        button {
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          border: none;
          padding: 8px 30px;
          background-color: ${({ theme }) => theme.darkPrimaryColor};
          color: ${({ theme }) => theme.textColor};

          &.rbc-active {
            background-color: ${({ theme }) => theme.actionColor};
            color: ${({ theme }) => theme.textContrastColor};
          }
        }
      }

      .rbc-btn-group:first-child {
        order: 2;
        display: flex;
        gap: 4px;
        button {
          border-radius: 12px;
        }
        button:first-child {
          order: 2;
        }
        button:last-child {
          order: 3;
        }
      }

      .rbc-btn-group:last-child {
        order: 1;
        border-radius: 12px;
        button {
          border-radius: 0;
          :first-child {
            border-radius: 12px 0 0 12px;
          }
          :last-child {
            border-radius: 0 12px 12px 0;
          }
        }
      }

      .rbc-toolbar-label {
        order: 3;
      }
    }

    .rbc-time-view {
      border: none;

      .rbc-time-header {
        border: none;
        background-color: ${({ theme }) => theme.darkSecondaryColor};

        &.rbc-overflowing {
          margin-right: unset !important;
        }

        ${({ hasAllDayEvent, view }) => view === 'day' && !hasAllDayEvent && `
          display: none;
        `}

        .rbc-time-header-gutter {
          border-bottom: 2px solid ${({ theme }) => theme.darkPrimaryColor};
        }

        .rbc-time-header-content {
          border-color: ${({ theme }) => theme.darkPrimaryColor};
          border-width: 2px;
          border-bottom-style: solid;
          .rbc-row.rbc-time-header-cell {
            min-height: unset;
            .rbc-header {
              height: fit-content;
              padding-top: 12px;
              padding-bottom: 12px;
              border-bottom: 2px solid ${({ theme }) => theme.darkPrimaryColor};
              .rbc-button-link {
                font-size: 14px;
                font-weight: 400;
                color: ${({ theme }) => theme.textColor};
              }

              + .rbc-header {
                border-color: ${({ theme }) => theme.darkPrimaryColor};
                border-width: 2px;
              }

              &.rbc-today {
                background-color: inherit;
                .rbc-button-link {
                  ${MonthDay}, ${WeekDay} {
                    color: ${({ theme }) => theme.actionColor};
                  }
                }

                /* + .rbc-header {
                  border-left-color: ${({ theme }) => theme.darkHighlightColor};
                } */
              }
            }
          }

          .rbc-allday-cell {
            max-height: 220px;
            overflow-y: scroll;
            height: 160px;
            .rbc-row-bg {
              .rbc-day-bg {
                + .rbc-day-bg {
                  border-left: none;
                  border-color: ${({ theme }) => theme.darkHighlightColor};
                }
                &.rbc-today {
                  background-color: transparent;
                }
              }
            }

            .rbc-row-content {
              .rbc-row {
                min-height: unset;
                padding-bottom: 4px;
                :first-child {
                  padding-top: 4px;
                }
                :empty {
                  display: none;
                }
                .rbc-row-segment {
                  padding: 1px 4px;
                }
              }
            }

            .rbc-event, .rbc-event-content {
              background-color: ${({ theme }) => theme.darkPrimaryColor};
              border-radius: 8px;
              padding: 0;

              ${Event} {
                padding-top: 0;
                justify-content: center;
                border-top: none;
                border-right: none;
                border-bottom: none;
              }
            }

            .rbc-event:focus, .rbc-day-slot.rbc-background-event:focus {
              outline: unset;
            }
          }
        }
      }

      .rbc-time-content {
        background-color: ${({ theme }) => theme.darkSecondaryColor};
        border-top: none;

        .rbc-events-container {
          margin-left: 4px;
          margin-right: 4px;
          border: none;
        }
        
        > * + * > * {
          border-color: ${({ theme }) => theme.darkPrimaryColor};
          border-bottom-color: ${({ theme }) => theme.darkPrimaryColor};
          border-width: 2px;
        }

        .rbc-time-gutter.rbc-time-column {
          padding: 0 9px;
          ${({ view, theme }) => view === 'day' && `
            border-right: 2px solid ${theme.darkPrimaryColor};
          `}
          ${({ isToday, theme }) => isToday && `
            border-color: ${theme.darkHighlightColor};
          `}
          .rbc-timeslot-group {
            border-bottom: none;
            .rbc-time-slot {
              display: flex;
              align-items: flex-start;

              .rbc-label {
                font-size: 12px;
                font-weight: 400;
                color: ${({ theme }) => theme.textColor};
              }

              :last-child {
                display: none;
              }
            }
          }
        }

        .rbc-timeslot-group {
          min-height: 80px;
          .rbc-time-slot {
            border: none;          
          }
        }

        .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
          background-color: inherit;

          .rbc-event {
            background-color: ${({ theme }) => theme.darkPrimaryColor};
          }
        }

        .rbc-event {
          background-color: ${({ theme }) => theme.darkPrimaryColor};
          border: none;
          border-radius: 8px;
          padding: 0;

          .rbc-event-label {
            display: none;
          }

          .rbc-event-content {
            display: flex;
          }
        }

        .rbc-current-time-indicator {
          height: 2px;
          background-color: ${({ theme }) => theme.actionColor};
        }
      }
    }

    .rbc-month-view {
      border: none;
      .rbc-row.rbc-month-header {
        background-color: ${({ theme }) => theme.darkSecondaryColor};
        border-bottom: 2px solid ${({ theme }) => theme.darkPrimaryColor};

        .rbc-header {
          padding: 8px;
          border: none;
          font-size: 14px;
          font-weight: 700;
          color: ${({ theme }) => theme.textColor};
          border-right: 2px solid ${({ theme }) => theme.darkPrimaryColor};

          :last-child {
            border-right: none;
          }
        }
      }

      .rbc-month-row {
        border-color: ${({ theme }) => theme.darkPrimaryColor};
        border-width: 2px;
        .rbc-row-bg {
          .rbc-day-bg {
            border-color: ${({ theme }) => theme.darkPrimaryColor};
            border-width: 2px;
            background-color: ${({ theme }) => theme.darkSecondaryColor};
            &.rbc-off-range-bg {
              background-color: ${({ theme }) => theme.darkSecondaryColor};
            }
            &.rbc-today {
              background-color: ${({ theme }) => theme.darkPrimaryColor};
              border: none;
            }
          }
        }
        .rbc-row-content {
          .rbc-row {
            .rbc-date-cell {
              font-size: 14px;
              font-weight: 400;
              color: ${({ theme }) => theme.textColor};
              padding: 8px 0 12px;
              text-align: center;

              &.rbc-now {
                button {
                  background-color: ${({ theme }) => theme.actionColor};
                  color: ${({ theme }) => theme.textContrastColor};
                  border-radius: 50%;
                  width: 28px;
                  height: 28px;
                  font-weight: 700;
                }
              }
            }

            .rbc-event {
              padding: 0 12px;
              background-color: transparent;

              :focus {
                outline: unset;
              }
            }

            .rbc-button-link.rbc-show-more {
              background-color: ${({ theme }) => theme.darkPrimaryColor};
              color: ${({ theme }) => theme.textColor};
              padding: 4px;
              border-radius: 8px;
              margin: auto;

              :hover {
                background-color: ${({ theme }) => lighten(0.085, theme.darkPrimaryColor)} !important;
                color: ${({ theme }) => theme.textColor};
              }
            }
          }
        }
      }
    }

    ${({ view, isToday, theme }) => view === 'day' && `
      .rbc-label.rbc-time-header-gutter {
        width: auto !important;
        max-width: unset !important;
      }

      .rbc-time-header-content {
        border-left: none;
      }

      .rbc-timeslot-group, .rbc-events-container {
        border-left: none;
      }

      .rbc-time-gutter.rbc-time-column {
        background-color: ${theme.darkSecondaryColor};
      }
    `}
  }
`;

export const IconWrapper = styled.div`
  background-color: ${({ color }) => color};
  padding: 0 32px;
  display: flex;
  align-items: center;
`;

export const ActivityIcon = styled(Icon.MDI)`

`;

export const ForToday = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const MonthEvent = styled(Row)`
  gap: 4px;
  cursor: pointer;
  ${({ canceled, finished }) => (canceled || finished) && `
    opacity: .5;
  `}
  ${({ canceled }) => canceled && `
    text-decoration: line-through;
  `}
`;

export const MonthEventColor = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
`;

export const MonthEventTitle = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};

    strong {
      font-weight: 700;
    }
  }
`;

export const WeekEvent = styled(Event)`
  padding: 8px;
  ${({ allDay, duration }) => !allDay && (duration <= 30) && `
    padding-top: 0;
    padding-bottom: 0;
  `}
  ${({ color }) => color && `
    border-left: 8px solid ${color};
  `}
`;

export const DayEvent = styled(Event)`
  padding: 6px;
  ${({ allDay, duration }) => !allDay && (duration <= 30) && `
    padding-top: 0;
    padding-bottom: 0;
  `}
  ${({ color }) => color && `
    border-top: 10px solid ${color};
  `}
`;

export const ColumnHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const ToolbarRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px 12px 0 0;
  padding: 20px;
  justify-content: space-between;
`;

export const TimePasserRow = styled.div`
  display: flex;
  gap: 12px;
`;

export const TimePasserButton = styled(Button)`
  &.ant-btn {
    padding: 6px;
  }
`;

export const ToolbarDateDescription = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
`;

export const StyledTabs = styled(Tabs)`
  .tab {
    width: 122px;
    text-align: center;

    :not(.current) {
      background-color: ${({ theme }) => theme.darkSecondaryColor};
    }
  }
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

const StyledText = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const SecondaryText = styled(StyledText)`
  font-size: 12px;
  font-weight: 400;
  opacity: ${({ lowOpacity })=> lowOpacity ? 0.5 : 1};
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ isNotAllDay })=> isNotAllDay === true ? '10vh' : ''};
  ${({ noWrap }) => noWrap && `
    white-space: nowrap;
  `}
 
`;

export const SecondaryTextMonth = styled(SecondaryText)`
  max-width: 15vh;
`;

export const EventTitle = styled(StyledText)`
  font-size: 16px;
  font-weight: 700;
`;

export const ContentInfoMonth = styled.div`
  width: 100%;
`;

export const DateText = styled(Text)`
  white-space: nowrap;
`;

export const Frase = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;