import styled from 'styled-components';
import { Text  } from '@components';
import { Icon } from '@components-teammove';

export const IconHome = styled(Icon.HomeOutlined)`
  margin-right: 5px;
`;

export const Title = styled(Text)`
  cursor: pointer;
`;