import { get, post, put, del } from '../api';
import { InfoUsuario } from '@utils';

export const getClassifications = () => {
  return get('/api/classificacoes');
};

export const getClassification = (idClassification) => {
  return get(`/api/classificacoes/${idClassification}`);
};

export const getAllClassificationsWithOptions = () => {
  return get('/api/classificacoes/opcoes');
};

export const saveClassification = (classificacao) => {
  return post('/api/classificacoes', classificacao );
};

export const updateClassification = (classificacao) => {
  return put('/api/classificacoes', classificacao);
};

export const getClassificationsTicket = (idTicket) => {
  return get(`/api/classificacoes/chamados/${idTicket}`);
};

export const postTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => {
  return post(`/api/classificacoes/chamados/${idTicket}/${idClassification}?idUsuario=${InfoUsuario.get('cdUsuario')}`, idClassificationOption);
};

export const putTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => {
  return put(`/api/classificacoes/chamados/${idTicket}/${idClassification}?idUsuario=${InfoUsuario.get('cdUsuario')}`, idClassificationOption);
};

export const delTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => {
  return del(`/api/classificacoes/chamados/${idTicket}/${idClassification}/${idClassificationOption}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};