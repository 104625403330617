import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getTiposAtividades as getTiposAtividadesSdk, 
  getTipoAtividade as getTipoAtividadeSdk, 
  postTipoAtividade as postTipoAtividadeSdk, 
  updateTipoAtividade as updateTipoAtividadeSdk, 
  reorderTiposAtividades as reorderTiposAtividadesSdk, 
  getActionPlansActivitiesTypes as getActionPlansActivitiesTypesSdk, 
  getActivitiesTypesGroup as getActivitiesTypesGroupSdk
} from '@sdk/TiposAtividades';

//Action Types
export const Types = {
  GET_TIPOS_ATIVIDADES: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES',
  GET_TIPOS_ATIVIDADES_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES_SUCCESS',
  GET_TIPOS_ATIVIDADES_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES_ERROR',

  SAVE_TIPO_ATIVIDADE: 'CONFIGURACOES_TIPOS_ATIVIDADES/SAVE_TIPO_ATIVIDADE',
  SAVE_TIPO_ATIVIDADE_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/SAVE_TIPO_ATIVIDADE_SUCCESS',
  SAVE_TIPO_ATIVIDADE_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/SAVE_TIPO_ATIVIDADE_ERROR',

  GET_TIPO_ATIVIDADE: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPO_ATIVIDADE',
  GET_TIPO_ATIVIDADE_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPO_ATIVIDADE_SUCCESS',
  GET_TIPO_ATIVIDADE_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPO_ATIVIDADE_ERROR',

  REORDER_TIPOS_ATIVIDADES: 'CONFIGURACOES_TIPOS_ATIVIDADES/REORDER_TIPOS_ATIVIDADES',
  REORDER_TIPOS_ATIVIDADES_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/REORDER_TIPOS_ATIVIDADES_SUCCESS',
  REORDER_TIPOS_ATIVIDADES_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/REORDER_TIPOS_ATIVIDADES_ERROR',

  GET_TIPOS_ATIVIDADES_PLANOS_ACAO: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES_PLANOS_ACAO',
  GET_TIPOS_ATIVIDADES_PLANOS_ACAO_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES_PLANOS_ACAO_SUCCESS',
  GET_TIPOS_ATIVIDADES_PLANOS_ACAO_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_TIPOS_ATIVIDADES_PLANOS_ACAO_ERROR',

  GET_ACTIVITIES_TYPES_GROUP: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_ACTIVITIES_TYPES_GROUP',
  GET_ACTIVITIES_TYPES_GROUP_SUCCESS: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_ACTIVITIES_TYPES_GROUP_SUCCESS',
  GET_ACTIVITIES_TYPES_GROUP_ERROR: 'CONFIGURACOES_TIPOS_ATIVIDADES/GET_ACTIVITIES_TYPES_GROUP_ERROR',

  RESET: 'CONFIGURACOES_TIPOS_ATIVIDADES/RESET',
  RESET_SINGLE: 'CONFIGURACOES_TIPOS_ATIVIDADES/RESET_SINGLE',
  RESET_FOR_METAS_ATIVIDADES: 'CONFIGURACOES_TIPOS_ATIVIDADES/RESET_FOR_METAS_ATIVIDADES'
};

//Action Creators
export const getTiposAtividades = () => ({ type: Types.GET_TIPOS_ATIVIDADES });
export const getTiposAtividadesSuccess = (tiposAtividades) => ({ type: Types.GET_TIPOS_ATIVIDADES_SUCCESS, tiposAtividades });
export const getTiposAtividadesError = (error) => ({ type: Types.GET_TIPOS_ATIVIDADES_ERROR, error }); 

export const saveTipo = (tipoAtividade) => ({ type: Types.SAVE_TIPO_ATIVIDADE, tipoAtividade });
export const saveTipoSuccess = (tipoAtividade) => ({ type: Types.SAVE_TIPO_ATIVIDADE_SUCCESS, tipoAtividade });
export const saveTipoError = (error) => ({ type: Types.SAVE_TIPO_ATIVIDADE_ERROR, error }); 

export const getTipoAtividade = (id) => ({ type: Types.GET_TIPO_ATIVIDADE, id });
export const getTipoAtividadeSuccess = (tipoAtividade) => ({ type: Types.GET_TIPO_ATIVIDADE_SUCCESS, tipoAtividade });
export const getTipoAtividadeError = (error) => ({ type: Types.GET_TIPO_ATIVIDADE_ERROR, error });

export const reorderTipos = (tiposAtividades) => ({ type: Types.REORDER_TIPOS_ATIVIDADES, tiposAtividades });
export const reorderTiposSuccess = (tiposAtividades) => ({ type: Types.REORDER_TIPOS_ATIVIDADES_SUCCESS, tiposAtividades });
export const reorderTiposError = (error) => ({ type: Types.REORDER_TIPOS_ATIVIDADES_ERROR, error }); 

export const getTiposAtividadesPlanosAcao = () => ({ type: Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO });
export const getTiposAtividadesPlanosAcaoSuccess = (tiposAtividades) => ({ type: Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO_SUCCESS, tiposAtividades });
export const getTiposAtividadesPlanosAcaoError = (error) => ({ type: Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO_ERROR, error }); 

export const getActivitiesTypesGroup = () => ({ type: Types.GET_ACTIVITIES_TYPES_GROUP });
export const getActivitiesTypesGroupSuccess = (activitiesTypesGroup) => ({ type: Types.GET_ACTIVITIES_TYPES_GROUP_SUCCESS, activitiesTypesGroup });
export const getActivitiesTypesGroupError = (error) => ({ type: Types.GET_ACTIVITIES_TYPES_GROUP_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSingle = () => ({ type: Types.RESET_SINGLE });
export const resetForMetasAtividades = () => ({ type: Types.RESET_FOR_METAS_ATIVIDADES });

//saga
function* fetchGetTiposAtividades(action) {
  try {
    const tiposAtividades = yield call(getTiposAtividadesSdk);
    yield put(getTiposAtividadesSuccess(tiposAtividades));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTiposAtividadesError(err));
  }
}

function* fetchGetTipoAtividade(action) {
  try {
    const { id } = action;
    const tipoAtividade = yield call(getTipoAtividadeSdk, id);
    yield put(getTipoAtividadeSuccess(tipoAtividade));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTipoAtividadeError(err));
  }
}

function* fetchSaveTipoAtividade(action) {
  try {
    let { tipoAtividade } = action;

    if (tipoAtividade.id) {
      tipoAtividade = yield call(updateTipoAtividadeSdk, tipoAtividade);
    } else {
      tipoAtividade = yield call(postTipoAtividadeSdk, tipoAtividade);
    }
    yield put(saveTipoSuccess(tipoAtividade));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveTipoError(err));
  }
}

function* fetchReorderTiposAtividades(action) {
  try {
    let { tiposAtividades } = action;

    tiposAtividades = yield call(reorderTiposAtividadesSdk, tiposAtividades);
    yield put(reorderTiposSuccess(tiposAtividades));
  } catch (err) {
    Notification.error(err.message);
    yield put(reorderTiposError(err));
  }
}

function* fetchGetTiposAtividadesPlanosAcao(action) {
  try {
    const tiposAtividades = yield call(getActionPlansActivitiesTypesSdk);
    yield put(getTiposAtividadesPlanosAcaoSuccess(tiposAtividades));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTiposAtividadesPlanosAcaoError(err));
  }
}

function* fetchGetActivitiesTypesGroup(action) {
  try {
    const activitiesTypesGroup = yield call(getActivitiesTypesGroupSdk);
    yield put(getActivitiesTypesGroupSuccess(activitiesTypesGroup));
  }catch (err) {
    Notification.error(err.message);
    yield put(getActivitiesTypesGroupError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TIPOS_ATIVIDADES, fetchGetTiposAtividades),
  takeLatest(Types.GET_TIPO_ATIVIDADE, fetchGetTipoAtividade),
  takeLatest(Types.SAVE_TIPO_ATIVIDADE, fetchSaveTipoAtividade),
  takeLatest(Types.REORDER_TIPOS_ATIVIDADES, fetchReorderTiposAtividades),
  takeLatest(Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO, fetchGetTiposAtividadesPlanosAcao),
  takeLatest(Types.GET_ACTIVITIES_TYPES_GROUP, fetchGetActivitiesTypesGroup)
];

// Reducer
const initialState = Map({
  active: List(),
  inactive: List(),
  loadingTiposAtividades: false,
  successTiposAtividades: false,
  errorTiposAtividades: false,
  tipoAtividade: null,
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  tiposAtividadesPlanosAcao: List(),
  loadingTiposAtividadesPlanosAcao: false,
  successTiposAtividadesPlanosAcao: false,
  errorTiposAtividadesPlanosAcao: false,
  activitiesTypesGroup: List(),
  loadingActivitiesTypesGroup: false,
  successActivitiesTypesGroup: false,
  errorActivitiesTypesGroup: false
});

const handleGetTiposAtividades = (state, action) => {
  return state.set('loadingTiposAtividades', true).set('successTiposAtividades', false).set('errorTiposAtividades', false);
};

const handleGetTiposAtividadesSuccess = (state, action) => {
  const { tiposAtividades } = action;

  return state.set('active', tiposAtividades.active).set('inactive', tiposAtividades.inactive).set('loadingTiposAtividades', false).set('successTiposAtividades', true).set('errorTiposAtividades', false);
};

const handleGetTiposAtividadesError = (state, action) => {
  return state.set('loadingTiposAtividades', false).set('successTiposAtividades', false).set('errorTiposAtividades', action.error);
};

const handleGetTipo = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetTipoSuccess = (state, action) => {
  const { tipoAtividade } = action;

  return state.set('tipoAtividade', tipoAtividade).set('loading', false).set('success', true).set('error', false);
};

const handleGetTipoError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveTipo = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveTipoSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveTipoError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReorderTipos = (state, action) => {
  return state.set('loadingReorder', true).set('successReorder', false).set('errorReorder', false);
};

const handleReorderTiposSuccess = (state, action) => {
  return state.set('loadingReorder', false).set('successReorder', true).set('errorReorder', false);
};

const handleReorderTiposError = (state, action) => {
  return state.set('loadingReorder', false).set('successReorder', false).set('errorReorder', action.error);
};

const handleGetTiposAtividadesPlanosAcao = (state, action) => {
  return state.set('loadingTiposAtividadesPlanosAcao', true).set('successTiposAtividadesPlanosAcao', false).set('errorTiposAtividadesPlanosAcao', false);
};

const handleGetTiposAtividadesPlanosAcaoSuccess = (state, action) => {
  const { tiposAtividades } = action;

  return state.set('tiposAtividadesPlanosAcao', tiposAtividades).set('loadingTiposAtividadesPlanosAcao', false).set('successTiposAtividadesPlanosAcao', true).set('errorTiposAtividadesPlanosAcao', false);
};

const handleGetTiposAtividadesPlanosAcaoError = (state, action) => {
  return state.set('loadingTiposAtividadesPlanosAcao', false).set('successTiposAtividadesPlanosAcao', false).set('errorTiposAtividadesPlanosAcao', action.error);
};

const handleGetActivitiesTypesGroup = (state, action) => {
  return state.set('loadingActivitiesTypesGroup', true).set('successActivitiesTypesGroup', false).set('errorActivitiesTypesGroup', false);
};

const handleGetActivitiesTypesGroupSuccess = (state, action) => {
  const { activitiesTypesGroup } = action;

  return state.set('activitiesTypesGroup', activitiesTypesGroup).set('loadingActivitiesTypesGroup', false).set('successActivitiesTypesGroup', true).set('errorActivitiesTypesGroup', false);
};

const handleGetActivitiesTypesGroupError = (state, action) => {
  return state.set('loadingActivitiesTypesGroup', false).set('successActivitiesTypesGroup', false).set('errorActivitiesTypesGroup', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSingle = (state) => {
  return state.set('tipoAtividade', initialState.tipoAtividade).set('loading', false).set('success', false).set('error', false).set('loadingSave', false).set('successSave', false).set('errorSave', false);
};

const handleResetForMetasAtividades = (state) => {
  return state
    .set('successTiposAtividades', false)
    .set('loadingTiposAtividades', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TIPOS_ATIVIDADES:
      return handleGetTiposAtividades(state, action);
    case Types.GET_TIPOS_ATIVIDADES_SUCCESS:
      return handleGetTiposAtividadesSuccess(state, action);
    case Types.GET_TIPOS_ATIVIDADES_ERROR:
      return handleGetTiposAtividadesError(state, action);
    case Types.GET_TIPO_ATIVIDADE:
      return handleGetTipo(state, action);
    case Types.GET_TIPO_ATIVIDADE_SUCCESS:
      return handleGetTipoSuccess(state, action);
    case Types.GET_TIPO_ATIVIDADE_ERROR:
      return handleGetTipoError(state, action);
    case Types.SAVE_TIPO_ATIVIDADE:
      return handleSaveTipo(state, action);
    case Types.SAVE_TIPO_ATIVIDADE_SUCCESS:
      return handleSaveTipoSuccess(state, action);
    case Types.SAVE_TIPO_ATIVIDADE_ERROR:
      return handleSaveTipoError(state, action);
    case Types.REORDER_TIPOS_ATIVIDADES:
      return handleReorderTipos(state, action);
    case Types.REORDER_TIPOS_ATIVIDADES_SUCCESS:
      return handleReorderTiposSuccess(state, action);
    case Types.REORDER_TIPOS_ATIVIDADES_ERROR:
      return handleReorderTiposError(state, action);
    case Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO:
      return handleGetTiposAtividadesPlanosAcao(state, action);
    case Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO_SUCCESS:
      return handleGetTiposAtividadesPlanosAcaoSuccess(state, action);
    case Types.GET_TIPOS_ATIVIDADES_PLANOS_ACAO_ERROR:
      return handleGetTiposAtividadesPlanosAcaoError(state, action);
    case Types.GET_ACTIVITIES_TYPES_GROUP:
      return handleGetActivitiesTypesGroup(state, action);
    case Types.GET_ACTIVITIES_TYPES_GROUP_SUCCESS:
      return handleGetActivitiesTypesGroupSuccess(state, action);
    case Types.GET_ACTIVITIES_TYPES_GROUP_ERROR:
      return handleGetActivitiesTypesGroupError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SINGLE:
      return handleResetSingle(state);
    case Types.RESET_FOR_METAS_ATIVIDADES:
      return handleResetForMetasAtividades(state);

    default:
      return state;
  }
}
