import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Input, Form, Select } from '@components-teammove';
import { saveNomenclaturas, getNomenclaturas } from '@ducks/configuracoesTelasNomenclaturas';
import { ArticleFormItem, StyleRow } from '../styles';

const TelasNomenclaturas = ({ history }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [requiredAtendimento, setRequiredAtendimento] = useState(true);
  const [requiredEad, setRequiredEad] = useState(true);
  const [requiredUnidade, setRequiredUnidade] = useState(true);

  const theme = useSelector(({ app }) => app.get('theme'));

  const nomenclaturas = useSelector(({ configuracoesTelasNomenclaturas }) => configuracoesTelasNomenclaturas.get('nomenclaturas')).toArray();
  const loading = useSelector(({ configuracoesTelasNomenclaturas }) => configuracoesTelasNomenclaturas.get('loading'));
  const success = useSelector(({ configuracoesTelasNomenclaturas }) => configuracoesTelasNomenclaturas.get('success'));
  const loadingSave = useSelector(({ configuracoesTelasNomenclaturas }) => configuracoesTelasNomenclaturas.get('loadingSave'));
  const successSave = useSelector(({ configuracoesTelasNomenclaturas }) => configuracoesTelasNomenclaturas.get('successSave'));
   
  useEffect(() => {
    dispatch(getNomenclaturas());
  }, []);

  useEffect(() => {
    if (success && nomenclaturas && nomenclaturas.length > 0) {
      const ticketNomenclature = nomenclaturas.find((item) => item.tela === 'CHAMADOS');
      const eadNomenclature = nomenclaturas.find((item) => item.tela === 'EAD');
      const companyNomenclature = nomenclaturas.find((item) => item.tela  === 'UNIDADES');

      form.setFieldsValue({
        chamados: ticketNomenclature?.nomenclatura,
        artigoChamado: ticketNomenclature?.artigo,
        pluralChamado: ticketNomenclature?.plural,
        ead: eadNomenclature?.nomenclatura,
        artigoEad: eadNomenclature?.artigo,
        pluralEad: eadNomenclature?.plural,
        unidades: companyNomenclature?.nomenclatura,
        artigoUnidade: companyNomenclature?.artigo,
        pluralUnidade: companyNomenclature?.plural,
      });
      
      if(!form.getFieldsValue().chamados) setRequiredAtendimento(false);
      if(!form.getFieldsValue().ead) setRequiredEad(false);
      if(!form.getFieldsValue().unidades) setRequiredUnidade(false);
    }
  }, [success]);
  
  useEffect(() => {
    if (successSave) {
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  }, [successSave]);

  const getDefaultValueFromScreen = (screen) => {
    if (!nomenclaturas) return '';
    
    const value = nomenclaturas.filter((item) => item.tela === screen)[0];

    return value ? value.nomenclatura : '';
  };

  const handleChange = (event, nomenclatura) => {
    const setRequired = nomenclatura === 'chamado' ? setRequiredAtendimento : nomenclatura === 'ead' ? setRequiredEad : setRequiredUnidade;
    const valueIsEmpty = !event.target.value;
    setRequired(!valueIsEmpty); 
  };
  
  const handleSalvar = () => {
    form.validateFields().then((values) => {
      const { chamados,  ead, unidades, artigoChamado, pluralChamado, artigoEad, pluralEad, artigoUnidade, pluralUnidade } = values;
      
      const campos = [
        { tela: 'CHAMADOS', nomenclatura: chamados, plural: pluralChamado, artigo: artigoChamado },
        { tela: 'EAD', nomenclatura: ead, plural: pluralEad, artigo: artigoEad },
        { tela: 'UNIDADES', nomenclatura: unidades, plural: pluralUnidade, artigo: artigoUnidade }
      ];

      const nomenclaturas = campos.filter((campo) => campo.nomenclatura);

      dispatch(saveNomenclaturas(nomenclaturas));
    });
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Nomenclatura de telas'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/telasNomenclaturas', name: 'Nomenclatura de telas', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
          extra={[
            <Button key='1' type='primary' size='small' onClick={handleSalvar} loading={loadingSave}>Salvar</Button>,
          ]}
        >
          <Form layout='vertical' form={form}>
            <StyleRow gap='12px'>
              <Form.Item label='Atendimento' name='chamados'  rules={[{ required: false, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input onChange={(event) => handleChange(event, 'chamado')} placeholder='Chamado' />
              </Form.Item>
              <ArticleFormItem label='Artigo' name='artigoChamado' rules={[{ required: requiredAtendimento, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Select placeholder='o/os' options={[{ value: 'o', label: 'o/Os' }, { value: 'a', label: 'a/As' }]}/>
              </ArticleFormItem>
              <Form.Item label='Plural' name='pluralChamado' rules={[{ required: requiredAtendimento, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input placeholder='Chamados' />
              </Form.Item>
            </StyleRow>

            <StyleRow gap='12px'>
              <Form.Item label='EAD' name='ead' rules={[{ required: false, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input onChange={(event) => handleChange(event, 'ead')} placeholder='EAD' defaultValue={getDefaultValueFromScreen('EAD')} />
              </Form.Item>
              <ArticleFormItem label='Artigo' name='artigoEad' rules={[{ required: requiredEad, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Select placeholder='o/os' options={[{ value: 'o', label: 'o/Os' }, { value: 'a', label: 'a/As' }]}/>
              </ArticleFormItem>
              <Form.Item label='Plural' name='pluralEad' rules={[{ required: requiredEad, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input placeholder="EAD's"/>
              </Form.Item>
            </StyleRow>
           
            <StyleRow gap='12px'>
              <Form.Item label='Unidade' name='unidades' rules={[{ required: false, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input onChange={(event) => handleChange(event, 'unidade')} placeholder='Unidade' />
              </Form.Item>
              <ArticleFormItem label='Artigo' name='artigoUnidade' rules={[{ required: requiredUnidade, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Select placeholder='o/os' options={[{ value: 'o', label: 'o/Os' }, { value: 'a', label: 'a/As' }]}/>
              </ArticleFormItem>
              <Form.Item label='Plural' name='pluralUnidade' rules={[{ required: requiredUnidade, message: 'Informação obrigatória!' }]} disabled={loading}>
                <Input placeholder='Unidades' />
              </Form.Item>
            </StyleRow>
            
          </Form>    
        </PageHeader>
      </Body>
    </App>
  );
};

export default TelasNomenclaturas;