import { View, Image, Row , Icon } from '@components-teammove';

import styled from 'styled-components';

export const MarginBottom = styled(View)`
  margin-bottom: 52px;
`;

export const RowWithGap = styled(Row)`
  gap: 12px;
`;

export const FlexFillRow = styled(Row)`
  flex: 1;
`;

export const JustifyCenterRow = styled(FlexFillRow)`
  justify-content: center;
`;

export const TinyMarginBottom = styled(View)`
  margin-bottom: .5rem;
`;  

export const Container = styled(View)`
  margin: 0 20px;
`;

export const CenteredView = styled(View)`
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 18px;
  padding: 15px;
`;

export const ColoredText = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.textColor};

  strong {
    font-weight: 700;
  }
`;

export const Header = styled(Row)`
  background: ${({ theme }) => theme.darkPrimaryColor};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px 20px 20px;
  border-radius: 18px;
`;

export const HeaderText = styled.span`
  color: ${({ theme }) => theme.textColor} !important;
  font-size: 24px;
  font-weight: 700;
`;

export const SecondaryHeaderText = styled(HeaderText)`
  font-size: 16px;
  font-weight: 400;
`;

export const PaddingLeft = styled(View)`
  padding: 20px;
  max-width: 52.5%;
`;

export const Greeting = styled(ColoredText)`
  font-size: 36px;
  font-weight: 700;
`;  

export const GreetingSubtitle = styled(ColoredText)`
  font-size: 24px;
  font-weight: 400;
`;

export const Subtitle = styled(GreetingSubtitle)`
  text-align: center;
  font-size: 16px;
  margin-top: auto;

  strong {
    font-weight: 700;
  }
`;

export const IllustrationImage = styled(Image)`
  margin-left: auto;
`;

export const DayView = styled(View)`
  background: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 14px;
  text-align: center;
  flex: 1;
  height: fit-content;
  position: relative;
`;

export const DayViewBody = styled(View)`
  padding: 14px;
`;

export const DayHeader = styled(View)`
  background: ${({ theme }) => theme.darkHighlightColor};
  border-radius: 14px 14px 0 0;
  padding: 9px 0;

  * {
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ProfileHeader = styled(View)`
  position: relative;
  z-index: 2;
  gap: 4px;
`;

export const ProfileUsersTableTitle = styled(Row)`
  width: 100%;

  >*:first-child {
    width: 40%;
    display: flex;
    align-self: center;
  }
`;

export const CheckIcon = styled(Icon.MDI)`
  color: var(--success-color);
`;

export const CloseIcon = styled(Icon.MDI)`
  color: var(--danger-color);
`;