import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Button, Body, PageHeader, Breadcrumb, Filter, SearchBar, Radio, RangePicker, Icon } from '@components-teammove';

import { InfoUsuario, Notification, moment, DATE_FORMATS } from '@utils';
import { getCampaigns, changeCampaignsKind, changeUserChild, changeFilters } from '@ducks/campanhaLista';
import CampanhaItens from './CampanhaItens';
import ModalImportResults from './ModalImportResults';
import { filter } from './rules';
import { ButtonFilter, ContentHeader, ContentFilter, ContentDeadline, LabelDeadline, ContentHeaderContainer, StyledRadioGroup } from './styles';
import { getCampaignsUsers } from '@sdk/Campanhas';

const TYPE_CAMPAIGNS = {
  current: 'current',
  expired: 'expired'
};

export default function CampanhaLista() {
  const dispatch = useDispatch();
  
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [visibleFilters, setVisibleFilters] = useState(false);
  const user = InfoUsuario.get();
  const permVisualizarTudo = InfoUsuario.perm('campanhasVendasVisualizarTudo');

  const theme = useSelector(({ app }) => app.get('theme'));

  const campaigns = useSelector(({ campanhaLista }) => campanhaLista.get('campaigns'));
  const kind = useSelector(({ campanhaLista }) => campanhaLista.get('kind'));
  const userChild = useSelector(({ campanhaLista }) => campanhaLista.get('userChild'));
  const loading = useSelector(({ campanhaLista }) => campanhaLista.get('loading'));
  const filters = useSelector(({ campanhaLista }) => campanhaLista.get('filters'));
  const [users, setUsers] = useState([]);
  const [searchedUser, setSearchedUser] = useState(undefined);

  const [visibleModalImportResults, setVisibleModalImportResults] = useState(false);
  const handleOpenModalImportResults = () => setVisibleModalImportResults(true);
  const handleCloseModalImportResults = () => setVisibleModalImportResults(false);

  const filterValues = useMemo(() => ({
    userId: searchedUser || userChild?.cd_usuario,
    descFiltros: userChild && {
      userId: userChild?.nm_usuario
    }
  }), [searchedUser, userChild]);
  
  const modalFilters = [
    {
      type: 'SELECT',
      mode: 'single',
      label: 'Usuário',
      name: 'userId',
      placeholder: 'Todos os usuários',
      options: users.filter((usuario) => user.hierarquia?.includes(usuario.cd_usuario))
        .map((user) => ({ label: user.nm_usuario, value: user.idApi }))
    },
  ];

  useEffect(() => {
    const getUsersAsync = async () => {
      const users = await getCampaignsUsers();
      setUsers(users);
    };
    getUsersAsync();
  }, []);

  useEffect(() => {
    handleSearchCampaigns();
  }, []);

  const handleGetCampaigns = (idApiUser) => {
    dispatch(getCampaigns(idApiUser, permVisualizarTudo, filters));
  };

  const handleChangeCampaignsKind = (kind) => {
    dispatch(changeCampaignsKind(kind));
  };

  const handleChangeFilters = (filters) => {
    dispatch(changeFilters(filters));
  };

  const handleSearchCampaigns = () => {
    handleGetCampaigns(userChild?.idApi || user.idApi);
    if (!permVisualizarTudo) {
      handleChangeCampaignsKind(TYPE_CAMPAIGNS.current);
    }
  };

  const handleFilteredSearch = (filters) => {
    if (search) return;

    const { userId } = filters;
    setSearchedUser(userId);

    const userToGetCampaignsFrom = users.find((user) => user.idApi === userId);
    dispatch(changeUserChild(userToGetCampaignsFrom));

    const { idApi } = userToGetCampaignsFrom || {};    
    handleGetCampaigns(idApi);
  };

  const handleItemClick = (campaign) => {
    history.push(`/campanha/${campaign.externalId}`);
  };

  const handleToFilter = () => {
    if (filters?.startAt && filters?.limitAt) {
      handleSearchCampaigns();
    } else {
      Notification.info('Para buscar as campanhas encerradas por período informe uma data válida!');
    }
  };

  const getExtraAsUser = () => {
    const extras = [
      <Button key='filter' type='secondary' size='small' context='header' icon={<Icon.MDI type='filter-outline'/>} onClick={() => setVisibleFilters(true)}>Filtros</Button>
    ];
    if (user.senhaMaster) {
      extras.push(<Button key="1" size='small' context='header' onClick={() => handleOpenModalImportResults()} disabled={loading}>Importar resultados</Button>);
    }
    return extras;
  };

  const handleImportNewResultsSuccess = () => {
    handleCloseModalImportResults();
    handleSearchCampaigns();
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Campanhas'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/campanhas/resultados', name: 'Campanhas', current: true },
                { path: '/campanhas/resultados', name: 'Resultados', current: true }
              ]}
            />
          )}
          extra={getExtraAsUser()}
        > 
          <ContentHeader>
            <ContentFilter>
              <SearchBar 
                initialValue={search}
                onSearch={(value) => setSearch(value)}
                activeFiltersProps={{ filtros: filterValues, onSearch: handleFilteredSearch, filters: modalFilters }}
              />
            </ContentFilter>
          </ContentHeader>
          {permVisualizarTudo &&
            <ContentHeaderContainer>
              {kind === TYPE_CAMPAIGNS.expired && (
                <ContentDeadline>
                  <LabelDeadline>Período</LabelDeadline>
                  <RangePicker
                    defaultValue={[moment(filters.startAt, DATE_FORMATS.DATE), moment(filters.limitAt, DATE_FORMATS.DATE)]}
                    format={DATE_FORMATS.DATE}
                    onChange={(date, dateString) => { handleChangeFilters({ startAt: dateString[0], limitAt: dateString[1] }); }}
                    disabled={loading}
                  />
                  <ButtonFilter type='icon' icon={<Icon.SearchOutlined />} onClick={handleToFilter} loading={loading} />
                </ContentDeadline>
              )}

              <StyledRadioGroup disabled={loading} buttonStyle='solid' value={kind} onChange={(value) => handleChangeCampaignsKind(value)}>
                <Radio.Button size='small' value={TYPE_CAMPAIGNS.current}>Vigentes</Radio.Button>
                <Radio.Button size='small' value={TYPE_CAMPAIGNS.expired}>Encerradas</Radio.Button>
              </StyledRadioGroup>
            </ContentHeaderContainer>
          }
          <CampanhaItens
            campaigns={filter(search, campaigns[kind].toArray())}
            kind={kind}
            loading={loading}
            onClick={handleItemClick}
          />
          <ModalImportResults 
            visible={visibleModalImportResults}
            onCancel={handleCloseModalImportResults}
            onOk={handleImportNewResultsSuccess}
          />

          {visibleFilters && (
            <Filter 
              visible={visibleFilters}
              onClose={() => setVisibleFilters(false)}
              filters={modalFilters} 
              onSearch={handleFilteredSearch} 
              filtros={filterValues}
            />
          )}
        </PageHeader>      
      </Body>  
    </App>  
  );
}