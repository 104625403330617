import React from 'react';
import { Modal } from '@components-teammove';
import { useSelector } from 'react-redux';

export default function ChecklistUploads({ visible, onCancel }) {
  const uploads = useSelector(({ checklists }) => checklists.get('uploads'));
  const loadingUploads = useSelector(({ checklists }) => checklists.get('loadingUploads'));
  const successUploads = useSelector(({ checklists }) => checklists.get('successUploads'));
 
  return (
    successUploads &&
    <Modal.Picture
      onCancel={onCancel}
      pictures={uploads.length > 0 && uploads.map((image) => image.url)}
      initialImageIndex={0}
      emptyText='Sem imagens'
      visible={visible}      
      loading={loadingUploads}
    />
  );
}