import { get, post } from '../../api';
import { InfoUsuario } from '@utils/';

export const getCategorias = async () => {
  const categorias = await get('/api/wiki/ead/categorias/');

  return categorias.map((categoria) => ({
    value: categoria.id,
    key: categoria.titulo,
    title: categoria.titulo,
  }));
};

export const getCategoriasCursos = async (cadastro = false) => {
  const cursos = await get(`/api/wiki/ead/cursos?idUsuario=${InfoUsuario.get('cdUsuario')}&cadastro=${cadastro}`);
  let retorno = [];

  cursos.forEach((categoria) => {
    const { categoriaId, categoriaTitulo, categoriaOrdem } = categoria;
    const categorias = retorno.filter((ret) => categoriaId === ret.id && categoriaTitulo === ret.titulo && categoriaOrdem === ret.ordem);

    let object = { id: categoriaId, titulo: categoriaTitulo, ordem: categoriaOrdem, cursos: [] };

    const {
      cursoId: id,
      cursoTitulo: titulo,
      cursoResumo: resumo,
      cursoImagem: imagem,
      cursoTipo: tipo,
      cursoOrdem: ordem,
      cursoPublicado: publicado,
      qtAula,
      qtAulaAssistida,
    } = categoria;

    if (categorias.length === 0) {
      object.cursos.push({
        id,
        titulo,
        resumo,
        imagem,
        tipo,
        ordem,
        publicado,
        qtAula,
        qtAulaAssistida,
      });
      retorno.push(object);
    } else {
      categorias[0].cursos.push({
        id,
        titulo,
        resumo,
        imagem,
        tipo,
        ordem,
        publicado,
        qtAula,
        qtAulaAssistida,
      });
    }
  });

  return retorno;
};

export const getMateriasAulas = async ({ idCurso }) => {
  const aulas = await get(`/api/wiki/ead/aulas/${idCurso}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
  let retorno = [];

  aulas.forEach((materia) => {
    const { materiaId, materiaTitulo, materiaResumo, materiaOrdem } = materia;
    const materias = retorno.filter(
      (ret) => materiaId === ret.id && materiaTitulo === ret.titulo && materiaOrdem === ret.ordem && materiaResumo === ret.resumo
    );

    let object = { id: materiaId, titulo: materiaTitulo, ordem: materiaOrdem, resumo: materiaResumo, aulas: [] };

    const {
      aulaId: id,
      aulaTitulo: titulo,
      aulaResumo: resumo,
      aulaConteudo: conteudo,
      aulaTipo: tipo,
      aulaOrdem: ordem,
      lido,
      favorito,
      curtido,
      qtCurtido,
      informacaoQuiz,
    } = materia;

    if (materias.length === 0) {
      object.aulas.push({
        id,
        titulo,
        resumo,
        conteudo,
        tipo,
        ordem,
        lido,
        favorito,
        curtido,
        qtCurtido,
      });
      retorno.push(object);
    } else {
      materias[0].aulas.push({
        id,
        titulo,
        resumo,
        conteudo,
        tipo,
        ordem,
        lido,
        favorito,
        curtido,
        qtCurtido,
        informacaoQuiz,
      });
    }
  });

  return retorno;
};

export const getCurso = async ({ id }) => {
  return await get(`/api/wiki/ead/curso/${id}`);
};

export const getCursoCadastrado = async ({ id }) => {
  return await get(`/api/wiki/ead/curso/cadastro/${id}`);
};

export const postCurso = async (data) => {
  return await post('/api/wiki/ead/curso', data);
};

export const deleteCurso = async ({ idCurso }) => {
  return await post(`/api/wiki/ead/curso/inativar/${idCurso}`);
};
