import React, { useState } from 'react';
import { ActionButton, ChangeImgBtnNext, ChangeImgBtnPrev, Close, IconWrapper, MediaView, NoImage, OverlayImage, Row, NoMediaIcon, NoMediaText } from './styles';
import { Overlay, Column, Spin, VideoPlayer } from '@components-teammove';
import { isImage, download, getTypeByFileExtension, getIconByType } from '@utils';

const PicturesOverlay = ({ onCancel, pictures, initialImageIndex, emptyText = 'Sem mídia', loading }) => {
  const [currentImage, setCurrentImage] = useState(initialImageIndex);

  const uniqueId = Date.now();
  const uniqueIdPrev = 'prev-' + Date.now();
  const uniqueIdNext = 'next-' + Date.now();

  const outsideClickListener = (event) => {
    if (!document.getElementById(uniqueId)?.contains(event.target) && !document.getElementById(uniqueIdPrev)?.contains(event.target) && !document.getElementById(uniqueIdNext)?.contains(event.target)) {
      onCancel();
    }
  };

  const prevImage = () => {
    pictures.forEach((img, index) => {
      const isFirstImg = !pictures[index - 1];

      if (index === currentImage) {
        if (isFirstImg) {
          setCurrentImage(pictures.length - 1);
        } else {
          setCurrentImage(index - 1);
        }
      }
    });

  };

  const nextImage = () => {
    pictures.forEach((img, index) => {
      const isLastImg = !pictures[index + 1];

      if (index === currentImage) {
        if (isLastImg) {
          setCurrentImage(0);
        } else {
          setCurrentImage(index + 1);
        }
      }
    });
  };
  
  return (
    <Overlay onClick={outsideClickListener}>
      <MediaView id={uniqueId}>
        {!loading && (
          <Close type='close' onClick={onCancel}/>
        )}
        <Row>
          {pictures.length > 0 && (
            <ChangeImgBtnPrev type='chevron-left' onClick={pictures[currentImage - 1] ? (() => prevImage()) : null} id={uniqueIdPrev} disabled={!pictures[currentImage - 1]}/>
          )}
          {pictures.length > 0 ? (
            
            pictures[currentImage]?.slice(-3) === 'mp4' ? (
              <VideoPlayer 
                url={pictures[currentImage]} 
                controls 
                muted 
                playing
                autoPlay 
              />
            ) : isImage(pictures[currentImage]) ? (
              <OverlayImage src={pictures[currentImage]} alt='Anexo'/>
            ) : (
              <NoImage>
                <IconWrapper>
                  {getIconByType(getTypeByFileExtension(pictures[currentImage].substring(pictures[currentImage].lastIndexOf('.') + 1)), '4rem')}
                </IconWrapper>
              </NoImage>
            )
          ) : loading ? (
            <Column align='center'>
              <Spin/>
            </Column>
          ) : (
            <Column gap='8px' align='center'>
              <NoMediaIcon type='camera' size='8rem'/>
              <NoMediaText>{emptyText}</NoMediaText>
            </Column>
          )}
          {pictures.length > 0 && (
            <ChangeImgBtnNext type='chevron-right' onClick={pictures[currentImage + 1] ? (() => nextImage()) : null} id={uniqueIdNext} disabled={!pictures[currentImage + 1]}/>
          )}
        </Row>
        {pictures.length > 0 && (
          <ActionButton size='small' type='primary' onClick={() => isImage(pictures[currentImage]) ? window.open(pictures[currentImage]) : download(pictures[currentImage])}>
            {isImage(pictures[currentImage]) ? 'Abrir' : 'Baixar'}
          </ActionButton>
        )}
      </MediaView>
    </Overlay>
  );
};

export default PicturesOverlay;