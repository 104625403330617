import styled from 'styled-components';
import { Icon, Row, Image } from '@components-teammove';

export const UploadCard = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 17%;
  position: relative;
`;

export const AddUploadCard = styled(UploadCard)`
  padding: 12px;
  width: fit-content;
`;

export const UploadsContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  gap: 12px;
  padding: 12px 0;
`;

export const UploadImage = styled(Image)`
  max-height: 114px;
  object-fit: contain;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 8px;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textContrastColor};
`;

export const NoImage = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 8px;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 5.5rem;

  * {
    color: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const PlusIconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.actionColor};
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 5.5rem;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const SmallText = styled(Text)`
  font-size: 12px;
  font-weight: 700;
`;

export const SimpleText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
`;

export const RegularText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
`;

export const CreatedBy = styled(RegularText)`
  opacity: .5;
`;

export const TableCell = styled(Row)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  flex: 1;
`;

export const TableBodyCell = styled(TableCell)`
  padding-right: 14px;
`;

export const CompanyInfoCell = styled(TableCell)`
  padding: 12px 21px;
`;

export const InfoCell = styled(TableBodyCell)`
  padding: 12px;
`;

export const ProductInfoCell = styled(TableBodyCell)`
  padding: 2px;
  ${({ hasImage }) => !hasImage && `
    padding-left: 35px;
  `}
`;

export const TotalCell = styled(TableCell)`
  padding: 12px;
`;

export const ImportantText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
`;

export const ProductPictureWrapper = styled.div`
  border-radius: 10px;
`;