import styled from 'styled-components';
import { AnswerContainer, ImagePreview, QuestionText, TextAnswer } from '../Pergunta/styles';
import { Container, FixedText, Label } from '../styles';
import { Divider, Text, View, Column } from '@components-teammove';

export const PrintableContainer = styled(Container)`
  margin: 0;
  padding: 30px 28px !important;
  background-color: #fff !important;
  
  ${AnswerContainer} {
    background-color: transparent;
  }

  ${Label} {
    &.ant-typography {
      font-size: 26px !important;
      color: black;
    }
  }

  ${FixedText} {
    &.ant-typography {
      font-size: 40px !important;
      color: black;
    }
  }

  ${QuestionText} {
    &.ant-typography {
      font-size: 14px !important;
      color: black;
    }
  }

  ${TextAnswer} {
    &.ant-typography {
      font-size: 18px !important;
      color: black;
    }
  }

  ${ImagePreview} {
    width: 256px;
    height: 256px;
  }
`;

export const SmallGapView = styled(View)`
  gap: 10px;
`;

const GeneralText = styled(Text)`
  &.ant-typography {
    color: black;
  }
`;

export const BriefingTitle = styled(GeneralText)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }
`;

export const RegularText = styled(GeneralText)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const UnboldChild = styled.span`
  font-weight: 400;
`;

export const Titles = styled.span`
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  justify-content: start;
  gap: 30px;
  width: 100%;
`;

export const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
  margin: 6px 0;
`;

export const StyledDivider = styled(Divider)`
  border-color: black;
`;

export const TinyDivider = styled(StyledDivider)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ContrastedBackground = styled.div`
  padding: 8px;
  background-color: #D9D9D9;
  -webkit-print-color-adjust: exact;
  border-radius: 10px;
`;

export const SmallText = styled(GeneralText)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const AnswerText = styled(GeneralText)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
  }
  margin: 10px 0;
`;

export const MarginLeftAuto = styled.div`
  margin-left: auto;
`;

export const ContainerInfo = styled.div`
  width: 100%;
`;

export const RegularTextTitle = styled(GeneralText)`
  padding-left: 8px;
  &.ant-typography {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ContainerHeader = styled(Column)`
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LogoConatiner = styled.div`
  height: 100px;
`;