import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { View, PopConfirm  } from '@components';
import { Icon } from '@components-teammove';
import styled, { css } from 'styled-components';
import ModalPreview from './ModalPreview';
import { Link } from 'react-router-dom';

const ViewAula = styled(View)`
  padding: 10px 10px 10px 50px;
  background-color: #fafafa;
  border-radius: 3px;
  border-bottom: 1px solid #f5f5f5;
`;

const Drag = styled(Icon.MDI)`
  cursor: move;
  :hover {
    color: #1890ff !important;
  }
`;

const ViewAcoesAula = styled(View)`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ViewTituloAula = styled(View)`
  font-weight: bold;
  margin-right: 10px;
  margin-top: ${({ top }) => (top ? top : 0)};

  ${(props) =>
  !props.publicado &&
    css`
      text-decoration: line-through;
      opacity: 0.7;
    `};
`;

const Edit = styled(Icon.EditOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const Delete = styled(Icon.DeleteOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const MDI = styled(Icon.MDI)`
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  :hover {
    color: #1890ff !important;
  }
`;

const Icones = styled(View)`
  width: 80px;
  justify-content: space-between;
  font-size: 18px;
`;

const ViewDragIcon = styled(View)`
  width: 40px;
  font-size: 18px;
`;

const ViewAulaValor = styled(View)`
  margin-top: 10px;
  width: 100%;
  white-space: break-spaces;
`;

const ViewData = styled(View)`
  width: 100%;
`;

const LinkStyled = styled(Link)`
  color: #262626 !important;
  transition: 0.2s;
  display: flex;
`;

export default function Aulas({ data, onDragEnd, cancelSort, openModal, openModalQuiz, deleteAula }) {
  const { id: materiaId, titulo: materiaTitulo, aulas } = data;

  const openNewTab = (value) => {
    return value.tipo === 'L' && !value.conteudo.includes('vimeo.com');
  };

  const ItemAula = SortableElement(({ value, row }) => {

    return (
      <ViewAula horizontal>
        <ViewDragIcon>
          <Drag type='drag' id='move-icon' />
        </ViewDragIcon>
        <ViewData>
          {value.quiz ? (
            <ViewAcoesAula>
              <ViewTituloAula publicado={value.quiz.publicado}>[QUIZ]&nbsp;{value.quiz.descricao}</ViewTituloAula>
              <Icones horizontal>
                <LinkStyled to={`/ead/cadastro/quiz/${value.conteudo}`} target='_blank'>
                  <Icon.FileDoneOutlined className={'action-element' /*eslint-disable-line*/} />
                </LinkStyled>
                <Edit onClick={() => openModalQuiz({ index: row, quiz: value.quiz, materiaTitulo, materiaId })} />
                <PopConfirm title="Confirma a exclusão do quiz?" onConfirm={() => deleteAula({ materiaId, aulaId: value.id })} okText="Sim" cancelText="Não">
                  <Delete />
                </PopConfirm>
              </Icones>
            </ViewAcoesAula>
          ) : (
            <ViewAcoesAula>
              <ViewTituloAula publicado={value.publicado}>{value.titulo}</ViewTituloAula>
              <Icones horizontal>
                <MDI
                  size='18px'
                  type='eye'
                  onClick={() =>
                    openNewTab(value) ? window.open(value.conteudo, '_blank') :
                      ModalPreview({
                        materiaTitulo,
                        aulaTitulo: value.titulo,
                        tipo: value.tipo,
                        conteudo: value.tipo === 'L' || !value.upload ? value.conteudo : value.upload.path,
                      })
                  }
                />
                <Edit onClick={() => openModal({ index: row, aula: value, materiaTitulo, materiaId })} />
                <PopConfirm title="Confirma a exclusão da aula?" onConfirm={() => deleteAula({ materiaId, aulaId: value.id })} okText="Sim" cancelText="Não">
                  <Delete />
                </PopConfirm>
              </Icones>
            </ViewAcoesAula>
          )}

          <ViewAulaValor>{value.resumo}</ViewAulaValor>
        </ViewData>
      </ViewAula>
    );
  });

  const ListAulas = SortableContainer(({ items }) => {
    return (
      <View>
        {items.map((value, index) => (
          <ItemAula key={value.titulo} index={index} value={value} row={index} />
        ))}
      </View>
    );
  });

  return <ListAulas items={aulas} onSortEnd={(result) => onDragEnd(result, materiaId)} shouldCancelStart={(event) => cancelSort(event)} />;
}
