import { getIdTemp, dateToAnsi, InfoUsuario } from '@utils';

export const getDadosPersonalizacao = (old, dados) => ({
  idTemp: getIdTemp(),
  idUsuario: InfoUsuario.get('cdUsuario'),
  dtCriacao: dateToAnsi(dados.dtCriacao),
  idCategoria: dados.idCategoria,
  link: dados.link,
  ...old,
  status: dados.status,
  dtAprovacao: dateToAnsi(dados.dtAprovacao),
});
