import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Tabs, Button } from '@components-teammove';
import { Session } from '@utils';
import TabelaPrecos from './TabelaPrecos';
import CategoriasSubcategorias from './CategoriasSubcategorias';
import CamposPersonalizados from './CamposPersonalizados';
import { useHistory } from 'react-router-dom';

export default function ConfiguracoesProdutos() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_PRODUCT_TAB') || 'Categorias e sub categorias');
  const history = useHistory();

  const handleChangeTab = (newTab) =>{
    setCurrentTab(newTab);
    Session.set('CONFIG_PRODUCT_TAB', newTab);
  };

  const TabelaDePrecos = ()=> [
    <Button key="1" type="primary" size="small" context="header" onClick={() => history.push('/configuracoes/mixprodutos/-1')}>Criar Nova Tabela</Button>
  ];
  
  const ExtraGeral = () => [
    <Button id="edit" key="2" size='small' context='header' onClick={() => history.push('/configuracoes/mixprodutos/cadastro')}>Editar</Button>,
  ];

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Cadastro mix de produtos'}
          extra={currentTab === 'Categorias e sub categorias' ? null : currentTab === 'Tabela de preços' ? <TabelaDePrecos/> : <ExtraGeral/> }
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/mixprodutos', name:'Configurações de Mix de Produtos', current: true }
              ]}    
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Categorias e sub categorias' }, { name:'Tabela de preços' }, { name:'Campos personalizados' }]} current={currentTab} onChange={handleChangeTab}/>
            {currentTab === 'Categorias e sub categorias' ? (
              <CategoriasSubcategorias/>
            ) : currentTab === 'Tabela de preços' ? (
              <TabelaPrecos/>
            ) : <CamposPersonalizados/>} 
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}