import styled from 'styled-components';

export const TagAgrupadorStyled = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 6px;
    background-color: ${({ colorBg }) => colorBg};
    display: flex;
    align-items: center;
    justify-content: center;
`;