import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import App from '@app';
import { Icon, Body, List, PageHeader, Filter, Breadcrumb, SearchBar, Button, Tabs } from '@components-teammove';

import { useSearchParams, formatTimeWithSeconds, scrollTop, Filtros, dateToAnsi, DATE_FORMATS, InfoUsuario } from '@utils';
import { getConteudos, getConteudosSuccess } from '@ducks/wikis';
import { apiPostNewAudiencia } from '@ducks/wikisAcoes';
import WikiBreadcrumb from '../Wikis/Conteudos/Breadcrumb';
import WikiEstouCiente from '../Wikis/Conteudos/EstouCiente';
import VisualizacaoOpcoes from './VisualizacaoOpcoes';
import { filters as initialFilters } from './rules';
import { Container, DestaquesIcon, DestaquesTitle, Row } from './styles';
import Item from './Item';
import Audiencias from './Item/InnerLevelItem/Audiencias';
import { changeVisibleAudiencia, changeVisibleConfirmacoes, changeVisibleInformacoes, changeVisibleNewFeedback } from '../../store/ducks/comunicados';
import ConfirmacoesLeitura from './Item/InnerLevelItem/ConfirmacoesLeitura';
import Informacoes from './Item/InnerLevelItem/Informacoes';
import NewFeedback from './Item/InnerLevelItem/NewFeedback';

export default function WikisConteudos() {
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [categoriaPai, setCategoriaPai] = useState(Map());
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterValues, setFilterValues] = useState(Filtros.get('COMUNICADOS'));
  const [pageName, setPageName] = useState('');
  const [pagePath, setPagePath] = useState('');
  const [visibleFilters, setVisibleFilters] = useState();
  const [currentTab, setCurrentTab] = useState('Todos');

  const history = useHistory();
  const { id, descricao, lidos, favoritos, grupos, inicio, fim } = useSearchParams();
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));
  const conteudos = useSelector(({ wikis }) => wikis.get('conteudos').toArray());
  const extras = useSelector(({ wikis }) => wikis.get('extras'));
  const loadingConteudos = useSelector(({ wikis }) => wikis.get('loadingConteudos'));
  const visibleAudiencia = useSelector(({ comunicados }) => comunicados.get('visibleAudiencia'));
  const visibleConfirmacoes = useSelector(({ comunicados }) => comunicados.get('visibleConfirmacoes'));
  const visibleInformacoes = useSelector(({ comunicados }) => comunicados.get('visibleInformacoes'));
  const visibleNewFeedback = useSelector(({ comunicados }) => comunicados.get('visibleNewFeedback'));
  const audienciaParams = useSelector(({ comunicados }) => comunicados.get('audienciaParams'));
  const confirmacoesParams = useSelector(({ comunicados }) => comunicados.get('confirmacoesParams'));
  const informacoesParams = useSelector(({ comunicados }) => comunicados.get('informacoesParams'));
  const newFeedbackParams = useSelector(({ comunicados }) => comunicados.get('newFeedbackParams'));

  const inicioAudiencia = new Date();

  const criarAudiencia = () => {
    //Caso não exista um id de categoria válido não fazer o controle da audiencia
    if (!id) return;

    apiPostNewAudiencia({
      idCategoria: id,
      horaInicio: formatTimeWithSeconds(inicioAudiencia),
      horaFim: formatTimeWithSeconds(new Date()),
    });
  };

  const onUnload = (e) => {
    e.preventDefault();
    criarAudiencia();
  };

  useEffect(() => {
    handleSearch(filterValues);
  }, []);

  useEffect(() => {
    const pagePath = window.location.pathname;
    setPagePath(pagePath);

    if (pageName === '' || !id) {
      setPageName('Comunicados');
    }

    if (!id) {
      dispatch(getConteudosSuccess({}));
      setBreadcrumb({});
      setCategoriaPai(Map());

      history.replace(`${pagePath}?id=-1`);

      return;
    }
  }, [id, descricao, lidos, favoritos, grupos, inicio, fim]);

  useEffect(() => {
    handleSearch(filterValues);
  }, [id]);

  useEffect(() => {
    const comunicados = window.location.pathname.includes('/comunicados');

    const pai = extras.get('pai');

    scrollTop();
    setBreadcrumb(WikiBreadcrumb(extras.get('path'), id, comunicados));
    setCategoriaPai(Map(pai));

    if (pai && pai.comunicado) {
      window.addEventListener('beforeunload', onUnload);
    }
    return () => {
      if (pai && pai.comunicado) {
        criarAudiencia();
      }
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [extras]);

  const handleItemClick = (item) => {
    if (item.tipo === 'HTML' && item.comunicado) {
      return null;
    } else {
      return () => {
        history.push(
          `${item.tipo === 'GRUPO' ? `${pagePath}?id=` : `${pagePath.includes('/comunicados') ? '/comunicado' : pagePath}/`}${item.id}`
        );
      };
    }
  };

  const handleSearch = (filters) => {
    setFilterValues(filters);
    Filtros.set('COMUNICADOS', filters);
    const { text, data, grupos, pesquisar } = filters;
    const existeFiltroFavoritos = pesquisar && pesquisar.includes('fav');
    const existeFiltroLido = pesquisar && pesquisar.includes('lidos');
    const existeFiltroNaoLido = pesquisar && pesquisar.includes('naoLidos');

    const urlFavoritos = existeFiltroFavoritos ? '&favoritos=S' : '';
    const urlLidos = existeFiltroLido && !existeFiltroNaoLido ? '&lidos=S' : !existeFiltroLido && existeFiltroNaoLido ? '&lidos=N' : '';
    const urlData = data
      ? `&inicio=${dateToAnsi(data[0], null, DATE_FORMATS.ANSI_DATE)}&fim=${dateToAnsi(data[1], null, DATE_FORMATS.ANSI_DATE)}`
      : '';
    const urlGrupo = grupos ? `&grupos=${grupos.toString()}` : '';
    const urlDescricao = text ? `&descricao=${text}` : '';
    
    const isFirstPageAndIsNotFiltering = history.location.search === '?id-1';

    if(isFirstPageAndIsNotFiltering) {

      const urlWithNewParams = `${pagePath}?id=${id ? id : -1}${urlDescricao}${urlGrupo}${urlData}${urlFavoritos}${urlLidos}`;
      const haveParamsChanged = urlWithNewParams !== history.location.pathname + history.location.search;
      
      if (haveParamsChanged) {
        history.push(urlWithNewParams);
      }

    }

    setIsFiltering(Object.keys(filters).some((key) => filters[key]));
    dispatch(getConteudos(id, (!id || id === '-1') ? { descricao, lidos: existeFiltroLido ? 'S' : existeFiltroNaoLido ? 'N' : false, favoritos: existeFiltroFavoritos && 'S', comunicados: true, grupos, inicio: data && dateToAnsi(data[0]), fim: data && dateToAnsi(data[1]) } : { comunicados: true }));
  };

  const handleSimpleSearch = (searchParam) => {
    const search = searchParam.trim();
    dispatch(getConteudos(id, { descricao: search, lidos, favoritos, comunicados: true, grupos, inicio, fim }));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={pageName}
          breadcrumb={(
            <Breadcrumb 
              items={id && id !== '-1' && breadcrumb ? [
                { path: '/', name: 'Home' }, 
                ...breadcrumb.routes.map((route) => ({ ...route, name: route.breadcrumbName })),
                { path: `/comunicados?id=${id}`, name: conteudos.find(({ id }) => id === parseInt(id)).tituloPai, current: true }
              ] : [
                { path: '/', name: 'Home' }, 
                { path: '/comunicados', name: 'Comunicados', current: true }
              ]}
            />
          )}
          extra={(id !== '-1') ? (
            <VisualizacaoOpcoes item={categoriaPai}/>
          ) : [
            <Button key='filter' type='secondary' size='small' context='header' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>,
            InfoUsuario.perm('comunicadosCadastro') && <Button key='new' type='primary' size='small' onClick={() => history.push('/comunicado/cadastro')}>Novo comunicado</Button>
          ]}
          onBack={id !== '-1' && (() => history.goBack())}
          padding='1rem'
        >
          {id === '-1' && (
            <SearchBar 
              onSearch={handleSimpleSearch}
              placeholder="Pesquisar"  
              initialValue={descricao}
              activeFiltersProps={{ filtros: filterValues, onSearch: handleSearch, filters: initialFilters }}
            />
          )}

          <Container>
            {id === '-1' && (
              <Tabs options={[{ name: 'Todos' }, { name: 'Favoritos' }].filter(({ name }) => name !== 'Favoritos' || conteudos.filter(({ favorito }) => favorito).length > 0)} current={currentTab} onChange={(newTab) => setCurrentTab(newTab)}/>
            )}

            {extras.get('destaques') && (currentTab === 'Todos' ? extras.get('destaques').length > 0 : extras.get('destaques').filter(({ favorito }) => favorito).length > 0) && (
              <List
                itemLayout='vertical'
                size='large'
                highlightColor
                header={(
                  <Row gap='9px'>
                    <DestaquesIcon type='bs-pin-angle'/>
                    <DestaquesTitle>Destaques</DestaquesTitle>
                  </Row>
                )}
                dataSource={extras.get('destaques')}
                loading={loadingConteudos}
                pagination={false}
                renderItem={(item) => (
                // eslint-disable-next-line react/forbid-component-props
                  <List.Item onClick={handleItemClick(item)} className={item.lido && 'inactive'}>
                    <Item
                      categoriaPai={categoriaPai}
                      item={item}
                      usuario={extras.get('usuario')}
                      isFiltering={isFiltering}
                      firstLevel={id === '-1' && pagePath.includes('/comunicados')}
                    />
                  </List.Item>
                )}
              />
            )}

            {!isFiltering && breadcrumb && categoriaPai && <WikiEstouCiente item={categoriaPai} />}

            {((!extras.get('destaques') || extras.get('destaques').length === 0) || (currentTab === 'Todos' ? conteudos.length > 0 : conteudos.filter(({ favorito }) => favorito).length > 0)) && (
              <List
                itemLayout='vertical'
                size='large'
                dataSource={currentTab === 'Todos' || id !== '-1' ? conteudos : conteudos.filter(({ favorito }) => favorito)}
                loading={loadingConteudos}
                pagination={false}
                renderItem={(item) => (
                  // eslint-disable-next-line react/forbid-component-props
                  <List.Item onClick={handleItemClick(item)} className={[(item.lido && 'inactive'), (!(id === '-1' && pagePath.includes('/comunicados')) && 'transparent'), ((item.tipo === 'HTML' && item.comunicado) && 'hoverless')].filter((item) => item).join(' ')}>
                    <Item
                      categoriaPai={categoriaPai}
                      item={item}
                      usuario={extras.get('usuario')}
                      isFiltering={isFiltering}
                      firstLevel={id === '-1' && pagePath.includes('/comunicados')}
                    />
                  </List.Item>
                )}
              />)}
          </Container>

          <Filter 
            visible={visibleFilters} 
            onClose={() => setVisibleFilters(false)}
            filters={initialFilters}  
            filtros={filterValues} 
            onSearch={handleSearch}
          />

          {visibleAudiencia && (
            <Audiencias
              visible={visibleAudiencia}
              onCancel={() => dispatch(changeVisibleAudiencia(false))}
              ds={audienciaParams.titulo}
              id={audienciaParams.id}
            />
          )}

          {visibleConfirmacoes && (
            <ConfirmacoesLeitura
              visible={visibleConfirmacoes}
              onCancel={() => dispatch(changeVisibleConfirmacoes(false))}
              ds={confirmacoesParams.titulo}
              id={confirmacoesParams.id}
            />
          )}

          {visibleInformacoes && (
            <Informacoes
              visible={visibleInformacoes}
              onCancel={() => dispatch(changeVisibleInformacoes(false))}
              conteudo={informacoesParams.item}
            />
          )}

          {visibleNewFeedback && (
            <NewFeedback
              visible={visibleNewFeedback}
              onCancel={() => dispatch(changeVisibleNewFeedback(false))}
              feedbackGivenCallback={newFeedbackParams.callback}
              id={newFeedbackParams.id}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}