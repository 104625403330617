import React, { useEffect } from 'react';
import App from '@app';
import { Body, PageHeader, View, Image, Tooltip, Spin } from '@components';
import { DashTitle } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriasCursos, resetAula } from '@ducks/wikisEAD';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatDecimal } from '@utils/';
import ProgressBar from './ProgressBar';
import { getImagemCapa } from '../rules';

const ViewCursos = styled(View)`
  display: inline-block;
`;

const LinkStyled = styled(Link)`
  color: #262626 !important;
  transition: 0.2s;

  :hover {
    color: #1890ff !important;
  }
`;

const ViewCurso = styled(View)`
  border: 2px solid #cccccc;
  border-radius: 3px;
  margin: 10px;
  float: left;
  :hover {
    border: 2px solid #1890ff;
  }
`;

const ViewDetalheCurso = styled(View)`
  height: 350px;
  padding: 0px 10px;
`;

const ContentImageCurso = styled(View)`
  display: flex;
  justify-content: center;
  width: 450px;
  height: 300px;
  padding: 10px 0px;
`;

const ImageCurso = styled(Image)`
  border-radius: 3px 3px 0px 0px;
`;

const ViewTitulo = styled(View)`
  font-weight: bold;
  max-width: 250px;
  width: fit-content;
  font-size: 16px;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  line-height: 22px;
`;

const ViewResumo = styled(View)`
  max-width: 250px;
  width: fit-content;
  font-size: 13px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 18px;
`;

const ViewAulas = styled(View)`
  font-size: 12px;
`;

const ViewPrAula = styled(View)`
  line-height: 16px;
  margin-right: 5px;
`;

const ContentProgressBar = styled(View)`
  background-color: #cccccc;
`;

export default function WikisConteudos() {
  const dispatch = useDispatch();

  const categoriasCursos = useSelector(({ wikisEAD }) => wikisEAD.get('categoriasCursos'));
  const successCategoriasCursos = useSelector(({ wikisEAD }) => wikisEAD.get('successCategoriasCursos'));

  useEffect(() => {
    dispatch(resetAula());
    dispatch(getCategoriasCursos());
  }, []);

  const getPrAula = ({ qtAulaAssistida, qtAula }) => (qtAulaAssistida / qtAula) * 100;

  const getCursos = (cursos) => {
    return cursos.map((curso) => (
      <LinkStyled to={`ead/curso/${curso.id}`} key={curso.id}>
        <ViewCurso>
          <ViewDetalheCurso>
            <ContentImageCurso>
              <ImageCurso alt={curso.titulo} src={getImagemCapa(curso.imagem)} />
            </ContentImageCurso>
            <Tooltip title={curso.titulo}>
              <ViewTitulo>{curso.titulo}</ViewTitulo>
            </Tooltip>
            {curso.resumo && <Tooltip title={curso.resumo}>
              <ViewResumo>{curso.resumo}</ViewResumo>
            </Tooltip>}
          </ViewDetalheCurso>
          <ViewAulas>
            <ViewPrAula alignItems='flex-end'>{formatDecimal(getPrAula(curso), 2)}%</ViewPrAula>
            <ContentProgressBar>
              <ProgressBar pr={getPrAula(curso)} />
            </ContentProgressBar>
          </ViewAulas>
        </ViewCurso>
      </LinkStyled>
    ));
  };

  const getVisualizacaoCategorias = (categorias) => {
    return successCategoriasCursos ? (
      categorias.map((categoria) => (
        <View key={categoria.id}>
          <DashTitle>{categoria.titulo}</DashTitle>
          <ViewCursos horizontal>{getCursos(categoria.cursos)}</ViewCursos>
        </View>
      ))
    ) : (
      <View>
        <Spin />
      </View>
    );
  };

  return (
    <App>
      <Body>
        <PageHeader title='Cursos'>
          <View>{getVisualizacaoCategorias(categoriasCursos)}</View>
        </PageHeader>
      </Body>
    </App>
  );
}
