import styled from 'styled-components';
import { View, Text  } from '@components';
import { Icon , FormItem } from '@components-teammove';

export const Row = styled(View)`
  flex-direction: row;
  align-items: ${({ align }) => align || 'center'};
  gap: 13px;
`;

export const RowWithFillChildren = styled(Row)`
  > * {
    flex: 1;
  }
`;

export const RowLowerGap = styled(Row)`
  gap: 7px;
`;

export const Column = styled(View)`
  flex: 1;
  gap: 18px;
`;

export const ColumnLowerGap = styled(Column)`
  gap: 12px;
`;

export const ColumnRow = styled(Row)`
  gap: 28px;
  align-items: center;
`;

export const PermissionIcon = styled(Icon.MDI)`
  color: #F5F5F5;
  transform: scale(.95);
`;

export const ProfileName = styled(Text)`
  color: #F5F5F5 !important;
  font-size: 16px;
`;

export const CommonText = styled(Text)`
  color: #F5F5F5 !important;
  font-size: 12px;
  font-weight: 600;
`;

export const MarginBottom = styled(View)`
  margin-bottom: 24px;
`;

export const LowerMarginBottom = styled(MarginBottom)`
  margin-bottom: 16px;
`;

export const TinyMarginBottom = styled(MarginBottom)`
  margin-bottom: 8px;
`;

export const TinierMarginBottom = styled(TinyMarginBottom)`
  margin-bottom: 4px;
`;

export const AdaptedFormItem = styled(FormItem)`
  flex: 0 1 auto;
  margin-bottom: 0;
`;