import styled from 'styled-components';
import { Progress } from 'antd';
import Icon from '../../Icon';
import Chart from 'react-apexcharts';

export const Row = styled.div`
  display: flex;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const StarIcon = styled(Icon.Bootstrap)`
  --size: ${({ size }) => size === 'small' ? '24px' : size === 'xsmall' ? '20px' : '44px'};
  color: ${({ color, useSuccessColor }) => color ? color : useSuccessColor ? 'var(--success-color)' : 'var(--warning-color)'};
  width: var(--size);
  height: var(--size);
`;

export const StyledProgress = styled(Progress)`
  ${({ gapDegree, height = '100%', size }) => gapDegree && gapDegree >= 147.5 && (size === 3 ? `
    margin-bottom: calc(((${height} / 2) * -1) + 20px);
  ` : size === 2 ? `
    margin-bottom: calc(((${height} / 2) * -1) + calc(${height} / 5));
  ` : `
    margin-bottom: calc(((${height} / 2) * -1) + calc(${height} / 4));
  `)}

  .ant-progress-inner {
    font-size: 19px !important;
    background-color: ${({ trailColor }) => trailColor && trailColor };
  }
  .ant-progress-text {
    color: ${({ useTextColor, reachedGoal, theme }) => useTextColor ? theme.textColor : reachedGoal ? 'var(--success-color)' : 'var(--warning-color)' } !important;
    font-weight: 700;
    ${({ fontSize }) => fontSize && `
      font-size: ${fontSize};
    `}
    display: flex;
    align-content: center;
    justify-content: center;
  }
  path {
    stroke-width: ${({ strokeWidth }) => strokeWidth || '10'};
  }

  ${({ type, width }) => type === 'line' && `
    ${width && `
      width: ${width};
    `}

    display: flex;
    align-items: center;

    .ant-progress-inner {
      width: 100% !important;
    }
  `}

  ${({ height }) => height && `
    height: ${height};

    .ant-progress-inner {
      height: 100% !important;
    }
  `}
`;

export const StyledChart = styled(Chart)`
  ${({ margin }) => margin && `
    margin: ${margin};
  `}

  /* * {
    stroke-linecap: round;
  } */

  svg {
    transform: none;
  }
  
  text {
    fill: ${({ useTextColor, reachedGoal, theme }) => useTextColor ? theme.textColor : reachedGoal ? 'var(--success-color)' : 'var(--warning-color)' } !important;
    font-weight: 700;
    ${({ fontSize }) => fontSize && `
      font-size: ${fontSize};
    `}
  }
`;