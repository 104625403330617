import { get, put } from '../../api';
import { InfoUsuario, dateToAnsi, DATE_FORMATS } from '@utils';

export const getDadosCadastro = () => {
  return get(`/api/wiki/cadastro/comunicado/?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const putCadastro = (wiki, id = -1) => {
  return put(`/api/wiki/cadastro/${id}`, {
    ...wiki,
    dtFim: dateToAnsi(wiki.dtFim, null, DATE_FORMATS.ANSI_DATE),
  });
};

export const putGrupo = (grupo) => {
  return put(`/api/wiki/cadastro/grupo/${grupo.id}`, {
    ...grupo,
    dtCriacao: dateToAnsi(grupo.dtCriacao, null, DATE_FORMATS.ANSI_DATETIME),
    dtUltimaAtualizacao: dateToAnsi(grupo.dtUltimaAtualizacao, null, DATE_FORMATS.ANSI_DATETIME),
    dtInicioVisualizacao: grupo.dtInicioVisualizacao && dateToAnsi(grupo.dtInicioVisualizacao, null, DATE_FORMATS.ANSI_DATETIME),
    dtInicio: grupo.dtInicio && dateToAnsi(grupo.dtInicio, null, DATE_FORMATS.ANSI_DATE),
    dtFim: grupo.dtFim && dateToAnsi(grupo.dtFim, null, DATE_FORMATS.ANSI_DATE),
  });
};
