export const USER_STATE = {
  CONNECTING: 'Connecting',
  READY: 'Ready',
  DISCONNECTED: 'Disconnected',
  RINGING: 'Ringing',
  CALLING: 'Calling',
  ON_CALL: 'On call',
  OFFLINE: 'Offline',
  REJECTED: 'Rejected',
  ERROR: 'Error',
  CANCELED: 'Canceled'
};