import React, { useEffect, useMemo } from 'react';
import App from '@app';
import { Spin } from '@components';
import { Body, PageHeader, Column, Row, Grid, Button, Skeleton } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { CardTitle, CloseIcon, IntegrationDescription, IntegrationEmail, IntegrationPicture, Label, MyIntegrationCard } from './styles';
import { getIntegrations, updateUserIntegration } from '@ducks/integracoes';
import { InfoUsuario, useSearchParams } from '@utils';
import { getToken } from '@sdk/Azure';

export default function Integracoes() {
  const { code, state } = useSearchParams();

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));
  const integrations = useSelector(({ integracoes }) => integracoes.get('integrations'));
  const loadingIntegrations = useSelector(({ integracoes }) => integracoes.get('loadingIntegrations'));
  const loadingAddUserIntegration = useSelector(({ integracoes }) => integracoes.get('loadingAddUserIntegration'));
  const loadingUpdateUserIntegration = useSelector(({ integracoes }) => integracoes.get('loadingUpdateUserIntegration'));
  const successAddUserIntegration = useSelector(({ integracoes }) => integracoes.get('successAddUserIntegration'));
  const successUpdateUserIntegration = useSelector(({ integracoes }) => integracoes.get('successUpdateUserIntegration'));

  const integrationsEnabled = useMemo(() => integrations.filter(({ userValue }) => userValue), [integrations]);

  useEffect(() => {
    handleGetIntegrations();
  }, []);

  useEffect(() => {
    if (successAddUserIntegration || successUpdateUserIntegration) {
      handleGetIntegrations();
    }
  }, [successAddUserIntegration, successUpdateUserIntegration]);

  useEffect(() => {
    if (code) {
      getToken(code).then((values) => {
        dispatch(updateUserIntegration({
          userId: InfoUsuario.get('cdUsuario'), 
          integrationId: state, 
          value: true,
          accessToken: values.accessToken,
          refreshToken: values.refreshToken,
          expiresIn: new Date(new Date().getTime() + (values.expiresIn * 1000)).valueOf(),
          tokenType: values.tokenType

        }));
      }).catch((error) => {
        sessionStorage.removeItem('microsoftToken');
      });
    }
  }, []);

  const handleGetIntegrations = () => {
    dispatch(getIntegrations(InfoUsuario.get('cdUsuario')));
  };

  const handleAddIntegration = (integrationId) => {
    window.location.replace(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
    client_id=a213c1fe-c1ae-41f6-878c-1948b826b9f5
    &response_type=code
    &redirect_uri=${window.location.origin + '/integracoes'}
    &scope=offline_access%20calendars.read%20calendars.readwrite%20user.read
    &state=${integrationId}`);
  };

  const handleRemoveIntegration = (integrationId) => {
    dispatch(updateUserIntegration({ userId: InfoUsuario.get('cdUsuario'), integrationId, value: false }));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Integrações'
        >
          <Column gap='28px'>
            {integrationsEnabled?.length > 0 && (
              <Column gap='12px'>
                <Label>Minhas integrações</Label>
                <Grid numberOfColumns={3}>
                  {integrationsEnabled.map(({ id, name, picture, userEmail }) => (
                    <MyIntegrationCard key={id}>
                      <Row justify='space-between' align='center'>
                        <Row gap='12px'>
                          <IntegrationPicture src={picture}/>
                          <Column>
                            <CardTitle>{name}</CardTitle>
                            <IntegrationEmail>{id === 'OUTLOOK' ? userEmail : ''}</IntegrationEmail>
                          </Column>
                        </Row>
                        {loadingUpdateUserIntegration ? (
                          <Spin/>
                        ) : (
                          <CloseIcon type='x' onClick={() => handleRemoveIntegration(id)}/>
                        )}
                      </Row>
                    </MyIntegrationCard>
                  ))}
                </Grid>
              </Column>
            )}

            <Column gap='12px'>
              <Label>Integrações com a agenda de atividades</Label>
              <Grid numberOfColumns={3} gap='12px'>
                {loadingIntegrations ? (
                  <>
                    <Skeleton height='247px'/> 
                    <Skeleton height='247px'/> 
                    <Skeleton height='247px'/> 
                  </>
                ) : integrations.map(({ id, name, description, picture, userValue }) => (
                  <MyIntegrationCard key={id}>
                    <Column gap='20px'>
                      <Column gap='12px'>
                        <IntegrationPicture src={picture}/>
                        <CardTitle>{name}</CardTitle>
                        <IntegrationDescription>{description}</IntegrationDescription>
                      </Column>
                      <Button 
                        type={userValue ? 'success' : 'primary'} 
                        size='small' 
                        fillWidth
                        onClick={userValue ? (() => '') : (() => handleAddIntegration(id))}
                        loading={loadingAddUserIntegration}
                      >
                        {userValue ? 'Conectado' : 'Vincular conta'}
                      </Button>
                    </Column>
                  </MyIntegrationCard>
                ))}
              </Grid>
            </Column>
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}