import React, { useEffect } from 'react';
import { Modal, Form, Select } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { putStatus , resetSuccessSave } from '@ducks/pedidos';
import { statusOptions } from '../rules';

export default function ChangeStatusModal({ visible, onCancel, order }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const loadingStatusSave = useSelector(({ pedidos }) => pedidos.get('loadingStatusSave'));
  const successStatusSave = useSelector(({ pedidos }) => pedidos.get('successStatusSave'));

  useEffect(() => {
    if (order) {
      form.setFieldsValue({ status: order.status });
    }

    return () => {
      dispatch(resetSuccessSave());
    };
  }, []);

  useEffect(() => {
    if (successStatusSave) {
      onCancel();
    }
  }, [successStatusSave]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      dispatch(putStatus({ orderId: order.id, newStatus: values.status }));
    });
  };

  return (
    <Modal
      title='Alterar status'
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{ loading: loadingStatusSave }}
    >
      <Form form={form} layout='vertical'>
        <Form.Item label='Status' name='status' rules={[{ required: true, message: 'Insira um status' }]}>
          <Select options={statusOptions}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}