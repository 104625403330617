import { getPerfis } from '@sdk/Perfil';

const filters = [
  {
    type: 'SELECT',
    name: 'perfil',
    label: 'Perfil',
    placeholder: 'Selecione os perfis',
    action: getPerfis
  }
];

export { filters };