import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import App from '@app';

import { Icon, Body, PageHeader, Breadcrumb, Filter, Button, ActiveFilters, Table, Text, Column, Row, Skeleton, Tooltip, Modal, Input, Form, PopConfirm, Grid, SearchBar } from '@components-teammove';

import { ButtonStyle, Dot, CardStatus, Tag, IconStatus, DotIcon, IconStyle, IconChecklist } from './styles';
import { Filtros, InfoUsuario, TelasNomenclaturas, formatDate } from '@utils';

import { FILTERS_KEY, getChecklists, getChecklistsForPpt, updateChecklistStatus, getCountAcceptance } from '@ducks/atividadesChecklists';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { getTiposAtividades } from '@ducks/configuracoesAtividadesTipos';
import { getUsuarios } from '@sdk/Usuarios';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getFinishedChecklist } from '@ducks/checklists';

import { convertUrlsToBase64, exportToPPTX, initialFilters } from './rules';

import ChecklistAnswers from '../../GestaoEquipe/ActivitiesControl/UsersList/UserPage/Tasks/Task/ChecklistAnswers';

export default function Checklists() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const [form] = Form.useForm();

  const tiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const successTiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successTiposUnidades'));
  const tiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const successTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('successTiposAtividades'));
  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));

  const powerPoints = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('powerPoints'));
  const totalChecklists = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('totalChecklists'));
  const hasAnyApprovableChecklist = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('hasAnyApprovableChecklist'));
  const successChecklists = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('successChecklists'));
  const loadingChecklists = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('loadingChecklists'));

  const checklistsForPpt = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('checklistsForPpt'));
  const loadingChecklistsForPpt = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('loadingChecklistsForPpt'));
  const successChecklistsForPpt = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('successChecklistsForPpt'));
  
  const countAcceptance = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('countAcceptance'));
  const loadingCountAcceptance = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('loadingCountAcceptance'));
  const successCountAcceptance = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('successCountAcceptance'));

  const successUpdateChecklistStatus = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('successUpdateChecklistStatus'));
  const loadingUpdateChecklistStatus = useSelector(({ atividadesChecklists }) => atividadesChecklists.get('loadingUpdateChecklistStatus'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [currentFilters, setCurrentFilters] = useState(Filtros.get(FILTERS_KEY));
  
  const [ filtersModal, setFiltersModal ] = useState(initialFilters);
  const [ visibleFilters, setVisibleFilters ] = useState(false);

  const [usuarios, setUsuarios] = useState([]);
  const [ limit, setLimit ] = useState(10);

  const [ exportDisabled, setExportDisabled] = useState(false);
  const [ visibleChecklist, setVisibleChecklist] = useState(false);
  const [ activity, setActivity ] = useState(false);
  const [ powerPointToBase64, setPowerPointToBase64 ] = useState([]);
  const [ loadingPowerpoint, setLoadingPowerpoint ] = useState(false);
  const [ visibleAcceptance, setVisibleAcceptance ] = useState(false);
  const [ idChecklistToEdit, setIdChecklistToEdit ] = useState();
  const [ showTooltip, setShowTooltip ] = useState(false);

  const [ statusSelected, setStatusSelected ] = useState('total');

  const [ search, setSearch ] = useState();
  const [ orderByDirection, setOrderByDirection ] = useState();
  const [ orderByField, setOrderByField ] = useState();

  useEffect(() => {
    dispatch(getChecklistsForPpt({ ...currentFilters, search }, { getPictures: true  }));
    dispatch(getCountAcceptance({ ...currentFilters, search }));
  }, [currentFilters, search]);

  useEffect(() => {
    dispatch(getChecklists({ ...currentFilters, search, statusAprovacao: statusSelected === 'total' ? undefined : statusSelected, orderByField, orderByDirection }, { limit: limit }));
  }, [limit, currentFilters, search, orderByField, orderByDirection]);

  useEffect(() => {
    if(successUpdateChecklistStatus) {
      setIdChecklistToEdit();
      form.setFieldsValue({
        observation: undefined
      });
      dispatch(getCountAcceptance({ ...currentFilters, search }));
    
    }
  }, [successUpdateChecklistStatus]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');

    if(id) {
      if(successChecklists) {
        const parsedId = parseInt(id);
        setActivity(powerPoints.find((checklist) => checklist.checklistId === parsedId));
        dispatch(getFinishedChecklist(parsedId));
        setVisibleChecklist(true);
      }
    }
  }, [location.search, successChecklists]);

  useEffect(() => {
    if(successChecklistsForPpt) {
      if(checklistsForPpt.powerPoints.length === 0) {
        setExportDisabled(true);
      }else if(checklistsForPpt.powerPoints.length < 200) {
        setExportDisabled(false);
      }else{
        setExportDisabled(true);
      }
      setLoadingPowerpoint(true);
      setPowerPointToBase64([...checklistsForPpt.powerPoints]);
    }
  }, [successChecklistsForPpt]);
  
  useEffect(() => {
    dispatch(getTiposUnidades());
    dispatch(getTiposAtividades());
    dispatch(getAgrupadores());
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => InfoUsuario.get('hierarquia')?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
    })();

  }, []);

  useEffect(() => {
    if (usuarios && successTiposUnidades && successTiposAtividades) {
      setFiltersModal(filtersModal.map((filter) => {
        if (filter.name === 'agrupador') {
          return { ...filter, options: agrupadores.map((agrupador) => ({ label: agrupador.description, value: agrupador.id })) };
        }
        if (filter.name === 'users') {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'typesCompanies') {
          return { ...filter, options: tiposUnidades.map((tipoUnidade) => ({ label: tipoUnidade.name, value: tipoUnidade.id })) };
        }
        if (filter.name === 'typesActivities') {
          return { ...filter, options: tiposAtividades.map((tipoUnidade) => ({ label: tipoUnidade.nome, value: tipoUnidade.id })) };
        }
        return { ...filter };
      }));
    }
  }, [usuarios, successTiposUnidades, successTiposAtividades]);

  useEffect(() => {
    if(powerPointToBase64.length > 0) {
      convertUrlsToBase64(powerPointToBase64, setLoadingPowerpoint).catch((error) => {
        console.error('Erro ao converter URLs para base64:', error);
      });
    } else {
      setLoadingPowerpoint(false);
    }
  }, [powerPointToBase64]);
  
  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
  };

  const handleSearchChangingNewFilters = (newFilters) => {
    handleSearch(newFilters);
    setCurrentFilters(newFilters);
  };

  const handleViewChecklist = (checklist) => {
    history.replace(`/atividades/checklist?id=${checklist}`);
  };

  const handleChangeStatus = (id, action, observation) => {
    let body = {
      'checklistId': id ,
      'status': action,
      'observation': observation || ''
    };
    
    dispatch(updateChecklistStatus(body));
  };

  const handleChange = (status) => {
    setStatusSelected(status);
    dispatch(getChecklists({ ...currentFilters, search, orderByField, orderByDirection, statusAprovacao: status === 'total' ? undefined : status }, { limit: limit }));
  };

  const handleTableChange = (direction, field) => {
    setOrderByDirection((direction?.replace('end', ''))?.toUpperCase());
    setOrderByField(direction ? field.toUpperCase() : undefined);
  };

  const columns = [
    {
      title: 'Atividade',
      dataIndex: 'atividade',
      defaultSortOrder: orderByField === 'ATIVIDADE' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (item, itemObject) => (
        <Column onClick={(e)=> { e.stopPropagation(); history.push(`/atividades/agenda/${itemObject?.taskId}`);}}>
          <Row gap='22px' align='center'>
            <DotIcon color={itemObject.typeTaskColor || '#E91E63'}>
              <IconStyle type={itemObject.typeTaskIcon || 'briefcase'}/>
            </DotIcon>
            <Row gap='12px' align='center'>
          
              <Column width='130px'>
                <Tooltip title={itemObject.taskName}>
                  <Text 
                    size='18px' 
                    weight='inherit'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap' 
                    overflow='hidden' 
                    maxWidth='130px'
                  >
                    {itemObject.taskName}
                  </Text>
                </Tooltip>
                <Tooltip title={itemObject.typeTaskName}>
                  <Text 
                    size='14px' 
                    weight='400' 
                    lowOpacity
                    textOverflow='ellipsis'
                    whiteSpace='nowrap' 
                    overflow='hidden' 
                    maxWidth='130px' 
                  >
                    {itemObject.typeTaskName}
                  </Text>
                </Tooltip>
              </Column>
              <Button type='icon' transparent size='small' icon={(<IconChecklist type='clipboard' size='24px' />)} onClick={(e) => { e.stopPropagation();handleViewChecklist(itemObject.checklistId);}}/>
            </Row>
          </Row>
        </Column>
      )
    },
    {
      title: companyNomenclature.nomenclatura,
      dataIndex: 'unidade',
      defaultSortOrder: orderByField === 'UNIDADE' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (item, itemObject) => (
        <div>
          <Column width='160px' onClick={(e) => { e.stopPropagation();history.push(`/unidades/${itemObject.companyId}`);}}>
            <Tooltip title={itemObject?.companyName}>
              <Text 
                size='18px' 
                weight='inherit'
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='160px'
              >
                {itemObject?.companyName}
              </Text>
            </Tooltip>
            <Tooltip title={itemObject?.companyShortName}>
              <Text 
                size='14px' 
                weight='400' 
                lowOpacity
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='160px' 
              >
                {itemObject?.companyShortName}
              </Text>
            </Tooltip>
          </Column>
        </div>
      )
    },
    {
      title: 'Conclusão',
      dataIndex: 'conclusao',
      defaultSortOrder: orderByField === 'CONCLUSAO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (item, itemObject) => (
        <Column padding='20px 5px 20px 5px' onClick={(e) => { e.stopPropagation();handleViewChecklist(itemObject.checklistId);}}>
          <Text size='16px' weight='400'>{formatDate(itemObject.checklistFinishingDate)}</Text>
        </Column>
      )
    },
    {
      title: 'Responsável',
      dataIndex: 'responsavel',
      defaultSortOrder: orderByField === 'RESPONSAVEL' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (item, itemObject) => (
        <Column padding='5px 5px 5px 5px' width='140px' onClick={(e) => { e.stopPropagation();handleViewChecklist(itemObject.checklistId);}}>
          <Tooltip title={itemObject?.userName}>
            <Text 
              size='18px' 
              weight='inherit'
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='140px'
            >
              {itemObject?.userName}
            </Text>
          </Tooltip>
          <Tooltip title={itemObject.userProfileDescription}>
            <Text 
              size='14px' 
              weight='400' 
              lowOpacity
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='140px' 
            >
              {itemObject.userProfileDescription}
            </Text>
          </Tooltip>
        </Column>
      )
    },
    !hasAnyApprovableChecklist ?
      {
        title: 'Aprovação',
        dataIndex: 'aprovacao',
        width: '230px',
        defaultSortOrder: orderByField === 'APROVACAO' ? orderByDirection.toLowerCase()  + 'end' : null,
        sorter: true,
        render: (approvation, itemObject) => (
          <Column gap='8px' onClick={(e) => { e.stopPropagation();handleViewChecklist(itemObject.checklistId);}}>
            {!itemObject.isApprovable ? null : (itemObject.statusAcceptance === 'Pendente' || !itemObject.statusAcceptance) ? (
              <Button.Group>
                <ButtonStyle 
                  size='small'
                  type='secondary'
                  context='list'
                  loading={loadingUpdateChecklistStatus && ((itemObject.checklistId + '_aprovado') === idChecklistToEdit)}
                  icon={<Icon.Feather type='check'/>}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIdChecklistToEdit(itemObject.checklistId + '_aprovado');
                    handleChangeStatus(itemObject.checklistId, 'Aprovado');
                  }}
                />
                <ButtonStyle 
                  size='small'
                  type='secondary'
                  context='list'
                  icon={<Icon.Feather type='x'/>}
                  loading={loadingUpdateChecklistStatus && ((itemObject.checklistId + '_reprovado') === idChecklistToEdit)}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIdChecklistToEdit(itemObject.checklistId + '_reprovado');
                    setVisibleAcceptance(true);
                  }}
                />
              </Button.Group>
            ) : (itemObject.statusAcceptance === 'Aprovado' || itemObject.statusAcceptance === 'Reprovado') && (
              <Tooltip title={!showTooltip &&
              <Column>
                <Row align='center' gap='6px'><Dot status={itemObject.statusAcceptance}/> {itemObject.statusAcceptance}</Row>
                {itemObject?.observationAcceptance && <Text>Observação: {itemObject?.observationAcceptance}</Text>}
                {itemObject?.userNameAcceptance && <Text>Por: {itemObject?.userNameAcceptance} em {formatDate(itemObject?.updatedAtAcceptance)}</Text>}
              </Column>}
              >
                <PopConfirm
                  onBlur={() => setShowTooltip(false)} 
                  title= 'Deseja cancelar o status de aprovação?'
                  onClose={()=> setShowTooltip(false)}
                  onCancel={(event)=> {setShowTooltip(false);event.stopPropagation();}}
                  onConfirm={(event) => {setShowTooltip(false);event.stopPropagation();setIdChecklistToEdit(itemObject.checklistId);handleChangeStatus(itemObject.checklistId, 'Pendente');}}
                >
                  <Button
                    size='small'
                    fillWidth='150px'
                    type={itemObject.statusAcceptance === 'Aprovado' ? 'success' : 'danger'}
                    loading={loadingUpdateChecklistStatus && idChecklistToEdit === itemObject.checklistId}
                    onClick={(event) => {
                      setShowTooltip(true);
                      event.stopPropagation();
                    }}
                  >
                    <Icon.Feather type={itemObject.statusAcceptance === 'Aprovado' ? 'check' : 'X'}/>
                    {itemObject.statusAcceptance}
                  </Button>
                </PopConfirm>
              </Tooltip>
            )}
          </Column>
        )
      } : null,
  ].filter(Boolean);

  const handleSimpleSearch = (params) => {
    const searchParam = (params?.search !== undefined && params?.search?.search !== '') ? params.search : search;
    setSearch(searchParam);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Checklists'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades', name: 'Atividades', current: true } ,
                { path: '/atividades/checklist', name: 'Checklists', current: true },
              ]}
            />
          )}
          extra={[
            <Tooltip  key title={exportDisabled === true ? 'Adicione mais filtros para reduzir os resultados de sua pesquisa e permitir criar uma apresentação de slides' : ''}>
              <div>
                <Button key='new' size='small' type='secondary' disabled={exportDisabled} loading={loadingChecklistsForPpt || loadingPowerpoint} icon={<Icon.Feather type='upload' />} onClick={() => {exportToPPTX(powerPointToBase64, setLoadingPowerpoint);}}>Exportar</Button>
              </div>
            </Tooltip>,   
            
            <Button key='filter' size='small' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>
                    
          ]}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          {visibleFilters && (
            <Filter
              visible={visibleFilters}
              onClose={() => setVisibleFilters(false)}
              filtros={currentFilters}
              filters={filtersModal}
              onSearch={handleSearchChangingNewFilters}
            />
          )}

          <Column gap='16px'>
            {loadingCountAcceptance ? 
              <Grid numberOfColumns={4} gap='12px'>
                <Skeleton.Card/> 
                <Skeleton.Card/> 
                <Skeleton.Card/> 
                <Skeleton.Card/> 
              </Grid>
          
              : successCountAcceptance && (
                !hasAnyApprovableChecklist ? (
                  <Grid numberOfColumns={4} gap='12px'>
                    <CardStatus gap='8px' onClick={()=> handleChange('total')} selected={statusSelected === 'total'}>
                      <Tag padding='4px 0 4px 0' align='start' status='total'>
                        <Text size='12px' weight='600'>Total</Text>
                      </Tag>
                      <Row align='baseline' gap='4px'>
                        <Text size='20px' weight='600'>{countAcceptance.Total}</Text>
                        <Text siez='16px' weight='600'>atividades</Text>
                      </Row>
                    </CardStatus>

                    {countAcceptance.Aprovado > 0 && (
                      <CardStatus gap='8px' onClick={()=> handleChange('Aprovado')} selected={statusSelected === 'Aprovado'}>
                        <Tag justify='center' padding='4px 0 4px 0' align='center' status='aprovado'>
                          <IconStatus size='16px' type='check'/>
                          <Text size='12px' weight='600'>Aprovados</Text>
                        </Tag>
                        <Row align='baseline' gap='4px'>
                          <Text size='20px' weight='600'>{countAcceptance.Aprovado}</Text>
                          <Text siez='16px' weight='600'>atividades</Text>
                        </Row>
                      </CardStatus>
                    )}

                    {countAcceptance.Reprovado > 0 && (
                      <CardStatus gap='8px' onClick={()=> handleChange('Reprovado')} selected={statusSelected === 'Reprovado'}>
                        <Tag justify='center' padding='4px 0 4px 0' align='center' status='reprovado'>
                          <IconStatus size='16px' type='x'/>
                          <Text size='12px' weight='600'>Reprovados</Text>
                        </Tag>
                        <Row align='baseline' gap='4px'>
                          <Text size='20px' weight='600'>{countAcceptance.Reprovado}</Text>
                          <Text siez='16px' weight='600'>atividades</Text>
                        </Row>
                      </CardStatus>
                    )}

                    {(countAcceptance.Pendente > 0 || countAcceptance.SemAprovacao > 0) && (
                      <CardStatus gap='8px' onClick={()=> handleChange('Pendente')} selected={statusSelected === 'Pendente'}>
                        <Tag justify='center' padding='4px 0 4px 0' align='center' status='pendente'>
                          <IconStatus size='16px' type='alert-triangle'/>
                          <Text size='12px' weight='600'>Pendentes</Text>
                        </Tag>
                        <Row align='baseline' gap='4px'>
                          <Text size='20px' weight='600'>{(countAcceptance?.SemAprovacao ?? 0) + (countAcceptance?.Pendente ?? 0)}</Text>
                          <Text siez='16px' weight='600'>atividades</Text>
                        </Row>
                      </CardStatus>
                    )}
                  </Grid>
                ) : null
              )}
          
            <SearchBar
              placeholder="Buscar atividades"  
              initialValue={search}
              onSearch={(search) => handleSimpleSearch({ search })}
            />
          </Column>
          <Column gap='12px'>
            <Table
              columns={columns}
              dataSource={powerPoints}
              onChange={((pagination, filters, sorter) => handleTableChange(sorter.order, sorter.field))}
              rowKey='id'
              hidePagination
              loading={loadingChecklists && limit === 10}
            />
            {(limit < totalChecklists) && (
              <Row justify='center'>
                <Button loading={loadingChecklists} type='secondary' size='small' onClick={() =>  setLimit(limit + 10)}>{'Carregar mais'}</Button>
              </Row>
            )}
          </Column>
          {visibleChecklist &&
            <ChecklistAnswers
              visible={visibleChecklist}
              onCancel={() => {
                history.replace('/atividades/checklist');
                setVisibleChecklist(false); 
              }}
              task={{ status:'CONCLUIDA', active: true, checked: true, companyCity: activity.cidade, companyState: activity.estado }}
            />
          }
          {visibleAcceptance &&
            <Modal
              visible={visibleAcceptance}
              onOk={() => {setVisibleAcceptance(false); handleChangeStatus(parseInt(idChecklistToEdit.replace('_reprovado', '')), 'Reprovado', form.getFieldsValue().observation);}}
              okText='Reprovar checklist'
              title='Informe o motivo da reprovação'
              onCancel={() => {setVisibleAcceptance(false);form.setFieldsValue({ observation: undefined });}}
            >
              <Column gap='20px'>
                <Column gap='8px' margin='0px 0xp 20px 0px'>
                  <Text size='12' weight='600'>Observação</Text>
                  <Form form={form} layout='vertical'>
                    <Form.Item name='observation'>
                      <Input.TextArea placeholder='Insira aqui sua observação'/>
                    </Form.Item>
                  </Form>
                </Column>
              </Column> 
            </Modal>
          }
        </PageHeader>
        
      </Body>
    </App>
  );
}