import React, { useEffect, useState } from 'react';
import { getQuiz, postNovaTentativaSuccess } from '@ducks/wikisEADQuiz';
import { useDispatch, useSelector } from 'react-redux';
import { View, Spin } from '@components';
import styled from 'styled-components';
import { estadosQuiz } from './rules';
import Inicio from './Inicio';
import Perguntas from './Perguntas';
import Resultado from './Resultado';

const ViewMain = styled(View)`
  margin: 10px 0;
  height: 100%;

  border: 1px solid #dddddd;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function VisualizacaoQuiz({ conteudo, idCategoria, onInitQuiz }) {
  const id = parseInt(conteudo.get('conteudo'));
  const dispatch = useDispatch();
  const [perguntaAtual, setPerguntaAtual] = useState(0);
  const [estadoAtual, setEstadoAtual] = useState(estadosQuiz.INICIO);
  const [isUltimaPergunta, setUltimaPergunta] = useState(false);

  const quiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('quiz'));
  const loadingQuiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('loadingQuiz'));
  const successQuiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successQuiz'));
  const tentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('tentativa'));

  useEffect(() => {
    if (successQuiz) {
      if (quiz.finalizado !== undefined && quiz.finalizado === false && quiz.idUltimaPerguntaRespondida) {
        const pergAtual = quiz.perguntas.findIndex((perg) => perg.id === quiz.idUltimaPerguntaRespondida);
        dispatch(postNovaTentativaSuccess({ id: quiz.idTentativa, tentativa: quiz.tentativa }));
        setPerguntaAtual(pergAtual);
        handleProximaPergunta(pergAtual);
        setEstadoAtual(estadosQuiz.RESPONDENDO);
      }
    }
  }, [successQuiz]);

  const handleProximaPergunta = (pergAtual) => {
    const proximaPergunta = pergAtual + 1;

    if (quiz.perguntas.length === proximaPergunta) {
      setUltimaPergunta(true);
      return;
    }else{
      setPerguntaAtual(proximaPergunta);
    }
  };

  useEffect(() => {
    goToInicio();
  }, [id]);

  const goToInicio = () => {
    dispatch(getQuiz(id));
    setPerguntaAtual(0);
    setEstadoAtual(estadosQuiz.INICIO);
  };

  const getViewEstado = () => {
    if (loadingQuiz) return <Spin />;

    switch (estadoAtual) {
      case estadosQuiz.INICIO:
        return <Inicio data={{ quiz }} handlers={{ setEstadoAtual, onInitQuiz }} />;
      case estadosQuiz.RESPONDENDO:
        return (
          <Perguntas
            data={{ quiz, perguntaAtual, idCategoria, isUltimaPergunta }}
            handlers={{
              setEstadoAtual,
              setPerguntaAtual,
              removerAndamentoQuiz: onInitQuiz,
              proximaPergunta: handleProximaPergunta,
              setUltimaPergunta,
              goToInicio,
            }}
          />
        );
      case estadosQuiz.FINALIZADO:
        return <Resultado data={{ id: tentativa.id, idQuiz: id }} handlers={{ goToInicio, setAndamentoQuiz: onInitQuiz }} />;
      default:
        return null;
    }
  };

  return <ViewMain>{getViewEstado()}</ViewMain>;
}
