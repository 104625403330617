import { Map } from 'immutable';

//Action Types
export const Types = {
  CHANGE_VISIBLE_AUDIENCIA: 'COMUNICADOS/CHANGE_VISIBLE_AUDIENCIA',
  CHANGE_VISIBLE_CONFIRMACOES: 'COMUNICADOS/CHANGE_VISIBLE_CONFIRMACOES',
  CHANGE_VISIBLE_INFORMACOES: 'COMUNICADOS/CHANGE_VISIBLE_INFORMACOES',
  CHANGE_VISIBLE_NEW_FEEDBACK: 'COMUNICADOS/CHANGE_VISIBLE_NEW_FEEDBACK',
  
  RESET: 'COMUNICADOS/RESET',
};

//Action Creators
export const changeVisibleAudiencia = (visible, id, titulo) => ({ type: Types.CHANGE_VISIBLE_AUDIENCIA, visible, id, titulo });
export const changeVisibleConfirmacoes = (visible, id, titulo) => ({ type: Types.CHANGE_VISIBLE_CONFIRMACOES, visible, id, titulo });
export const changeVisibleInformacoes = (visible, item) => ({ type: Types.CHANGE_VISIBLE_INFORMACOES, visible, item });
export const changeVisibleNewFeedback = (visible, id, callback) => ({ type: Types.CHANGE_VISIBLE_NEW_FEEDBACK, visible, id, callback });
export const reset = () => ({ type: Types.RESET });

// Reducer
const initialState = Map({
  visibleAudiencia: false,
  visibleConfirmacoes: false,
  visibleInformacoes: false,
  visibleNewFeedback: false,
  audienciaParams: {},
  confirmacoesParams: {},
  informacoesParams: {},
  newFeedbackParams: {}
});

const handleChangeVisibleAudiencia = (state, action) => {
  const { visible, id, titulo } = action;

  return state.set('visibleAudiencia', visible).set('audienciaParams', { id, titulo });
};

const handleChangeVisibleConfirmacoes = (state, action) => {
  const { visible, id, titulo } = action;

  return state.set('visibleConfirmacoes', visible).set('confirmacoesParams', { id, titulo });
};

const handleChangeVisibleInformacoes = (state, action) => {
  const { visible, item } = action;

  return state.set('visibleInformacoes', visible).set('informacoesParams', { item });
};

const handleChangeVisibleNewFeedback = (state, action) => {
  const { visible, id, callback } = action;

  return state.set('visibleNewFeedback', visible).set('newFeedbackParams', { id, callback });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CHANGE_VISIBLE_AUDIENCIA: return handleChangeVisibleAudiencia(state, action);
    case Types.CHANGE_VISIBLE_CONFIRMACOES: return handleChangeVisibleConfirmacoes(state, action);
    case Types.CHANGE_VISIBLE_INFORMACOES: return handleChangeVisibleInformacoes(state, action);
    case Types.CHANGE_VISIBLE_NEW_FEEDBACK: return handleChangeVisibleNewFeedback(state, action);
    case Types.RESET: return initialState;

    default: return state;
  }
}
