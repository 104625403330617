import { Checkbox } from 'antd';
import styled from 'styled-components';

const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 2px;
  }
`;

export default CheckboxStyled;
