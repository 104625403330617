import styled from 'styled-components';
import { Text } from '@components-teammove';

export const StyledRow = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom: 8px;
  position: relative;
`;

export const ValueCenter = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 800;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const teste = styled.div`
  background-color: var(--success-color);
`;