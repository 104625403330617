import React from 'react';
import styled from 'styled-components';
import { Text, View } from '@components';

const Item = styled(Text)`
    color: white !important;
`;

const TooltipItem = ({ titulo, descricao }) => (
  <View>
    <Item strong>
      {titulo}
    </Item>
    <Item>
      {descricao}
    </Item>        
  </View>
);

export default TooltipItem;