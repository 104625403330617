import styled from 'styled-components';
import { View, List, Text, Row, Tag, Button } from '@components-teammove';

export const Container = styled(View)`
  gap: 20px;
`;

export const Listagem = styled(List)`
  margin-bottom:25px;
`;

export const Alias = styled(Row)`
  flex: 1;
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const TagLabel = styled(Text)`
  &.ant-typography {
   color: ${(props) => props.theme.textColor};
  }
  font-weight: 600;
  font-size: 12px;
`;

export const TagInUse = styled(Tag)`
  width: 75px;
  text-align: center;
  justify-content: center;
  background-color:${(props) => props.theme.actionColor};  
`;

export const Tables = styled(View)`
  gap: 20px;
`;

export const MenuButton = styled(Button)`
  font-size: 14px !important;
`;

export const RowTable = styled(Row)`
  padding-right: ${({ isDefault })=> isDefault ? '30px' : '0'};
`;