import styled from 'styled-components';
import { Text, Form } from '@components-teammove';

export const SequentialText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
  }
`;

export const SequentialTextLabel = styled(SequentialText)`
  &.ant-typography {
    opacity: .5;
    font-weight: 600;
    margin-left: 8px;
  }
`;

export const ColumnFormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;