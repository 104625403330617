import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getCallList as getCallListSdk,
  getCallDetailed as getCallDetailedSdk,
  putEditCallNote as putEditCallNoteSdk,
} from '@sdk/CallActivity';

export const FILTERS_KEY = 'CALL_ACTIVITY_FILTERS';
export const PARAMS_KEY = 'CALL_ACTIVITY_PARAMS';

export const Types = {
  GET_CALL_LIST: 'CALL_ACTIVITY/GET_CALL_LIST',
  GET_CALL_LIST_SUCCESS: 'CALL_ACTIVITY/GET_CALL_LIST_SUCCESS',
  GET_CALL_LIST_ERROR: 'CALL_ACTIVITY/GET_CALL_LIST_ERROR',

  GET_CALL_DETAILED: 'CALL_ACTIVITY/GET_CALL_DETAILED',
  GET_CALL_DETAILED_SUCCESS: 'CALL_ACTIVITY/GET_CALL_DETAILED_SUCCESS',
  GET_CALL_DETAILED_ERROR: 'CALL_ACTIVITY/GET_CALL_DETAILED_ERROR',

  PUT_EDIT_CALL_NOTE: 'CALL_ACTIVITY/PUT_EDIT_CALL_NOTE',
  PUT_EDIT_CALL_NOTE_SUCCESS: 'CALL_ACTIVITY/PUT_EDIT_CALL_NOTE_SUCCESS',
  PUT_EDIT_CALL_NOTE_ERROR: 'CALL_ACTIVITY/PUT_EDIT_CALL_NOTE_ERROR',
};

//Action Creators
export const getCallList = (params) => ({ type: Types.GET_CALL_LIST, params });
export const getCallListSuccess = (callList) => ({ type: Types.GET_CALL_LIST_SUCCESS, callList });
export const getCallListError = (error) => ({ type: Types.GET_CALL_LIST_ERROR, error });

export const getCallDetailed = (id) => ({ type: Types.GET_CALL_DETAILED, id });
export const getCallDetailedSuccess = (callDetailed) => ({ type: Types.GET_CALL_DETAILED_SUCCESS, callDetailed });
export const getCallDetailedError = (error) => ({ type: Types.GET_CALL_DETAILED_ERROR, error });

export const putEditCallNote = (id, note) => ({ type: Types.PUT_EDIT_CALL_NOTE, id, note });
export const putEditCallNoteSuccess = (newNote) => ({ type: Types.PUT_EDIT_CALL_NOTE_SUCCESS, newNote });
export const putEditCallNoteError = (error) => ({ type: Types.PUT_EDIT_CALL_NOTE_ERROR, error });

//saga
function* fetchCallList(action) {
  try {
    const { params } = action;
    const callList = yield call(getCallListSdk, params);
    yield put(getCallListSuccess(callList));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCallListError(err));
  }
}

function* fetchCallDetailed(action) {
  try {
    const { id } = action;
    const callDetailed = yield call(getCallDetailedSdk, id);
    yield put(getCallDetailedSuccess(callDetailed));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCallDetailedError(err));
  }
}

function* fetchEditCallNote(action) {
  try {
    const { id, note } = action;
    yield call(putEditCallNoteSdk, id, note);
    yield put(putEditCallNoteSuccess(note));
  } catch (err) {
    Notification.error(err.message);
    yield put(putEditCallNoteError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CALL_LIST, fetchCallList),
  takeLatest(Types.GET_CALL_DETAILED, fetchCallDetailed),
  takeLatest(Types.PUT_EDIT_CALL_NOTE, fetchEditCallNote),
];

// Reducer
const initialState = Map({  
  callList: List(),
  loadingCallList: false,
  successCallList: false,
  errorCallList: false,

  callDetailed: Map(),
  loadingCallDetailed: false,
  successCallDetailed: false,
  errorCallDetailed: false,

  loadingEditCallNote: false,
  successEditCallNote: false,
  errorEditCallNote: false,
});

const handleGetCallList = (state, action) => {
  return state
    .set('loadingCallList', true)
    .set('successCallList', false)
    .set('errorCallList', false);
};

const handleGetCallListSuccess = (state, action) => {
  const { callList } = action;
  return state
    .set('callList', callList)
    .set('loadingCallList', false)
    .set('successCallList', true)
    .set('errorCallList', false);
};

const handleGetCallListError = (state, action) => {
  return state
    .set('loadingCallList', false)
    .set('successCallList', false)
    .set('errorCallList', action.error);
};

const handleGetCallDetailed = (state, action) => {
  return state
    .set('loadingCallDetailed', true)
    .set('successCallDetailed', false)
    .set('errorCallDetailed', false);
};

const handleGetCallDetailedSuccess = (state, action) => {
  const { callDetailed } = action;
  return state
    .set('callDetailed', callDetailed)
    .set('loadingCallDetailed', false)
    .set('successCallDetailed', true)
    .set('errorCallDetailed', false);
};

const handleGetCallDetailedError = (state, action) => {
  return state
    .set('loadingCallDetailed', false)
    .set('successCallDetailed', false)
    .set('errorCallDetailed', action.error);
};

const handleEditCallNote = (state, action) => {
  return state
    .set('loadingEditCallNote', true)
    .set('successEditCallNote', false)
    .set('errorEditCallNote', false);
};

const handleEditCallNoteSuccess = (state, action) => {
  const { newNote } = action;
  const callDetailed = state.get('callDetailed');
  
  const updatedCallDetailed = {
    ...callDetailed,
    // eslint-disable-next-line camelcase
    voice_call_record_detail: callDetailed.voice_call_record_detail.map((item, index) => 
      index === 0 ? { ...item, notes: newNote } : item
    )
  };

  return state
    .set('callDetailed', updatedCallDetailed)
    .set('loadingEditCallNote', false)
    .set('successEditCallNote', true)
    .set('errorEditCallNote', false);
};

const handleEditCallNoteError = (state, action) => {
  return state
    .set('loadingEditCallNote', false)
    .set('successEditCallNote', false)
    .set('errorEditCallNote', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CALL_LIST: return handleGetCallList(state, action);
    case Types.GET_CALL_LIST_SUCCESS: return handleGetCallListSuccess(state, action);
    case Types.GET_CALL_LIST_ERROR: return handleGetCallListError(state, action);

    case Types.GET_CALL_DETAILED: return handleGetCallDetailed(state, action);
    case Types.GET_CALL_DETAILED_SUCCESS: return handleGetCallDetailedSuccess(state, action);
    case Types.GET_CALL_DETAILED_ERROR: return handleGetCallDetailedError(state, action);

    case Types.PUT_EDIT_CALL_NOTE: return handleEditCallNote(state, action);
    case Types.PUT_EDIT_CALL_NOTE_SUCCESS: return handleEditCallNoteSuccess(state, action);
    case Types.PUT_EDIT_CALL_NOTE_ERROR: return handleEditCallNoteError(state, action);

    default: 
      return state;
  }
}
