import { get } from '../api';
import { formatParams } from './rules';
import { InfoUsuario } from '@utils';

const useApiNode = true;

export const getDashboard = (params) => {
  return get(`/dash/orderEvolution?${formatParams(params)}`, {}, useApiNode);
};

export const getParticipationsPerFamilies = (params) => {
  return get(`/dash/rankingFamily?${formatParams(params)}`, {}, useApiNode);
};

export const getCards = (params) => {
  return get(`/dash/infoOrder?${formatParams(params)}`, {}, useApiNode);
};

export const getRankingProducts = (params) => {
  return get(`/dash/rankingProduct?${formatParams(params)}`, {}, useApiNode);
};

export const getRankingClients = (params) => {
  return get(`/dash/rankingClient?${formatParams(params)}&loggedUser=${InfoUsuario.get('cdUsuario')}`, {}, useApiNode);
};