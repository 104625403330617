import React, { useState, useEffect } from 'react';
import App from '@app';
import { Body, PageHeader, Spin, View } from '@components';
import Lista from './Lista';
import Detalhes from './Detalhes';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getListaTentativasQuiz } from '@ducks/wikisEADQuiz';

export const TipoVisualizacao = { LISTA: 'LISTA', DETALHES: 'DETALHES' };

export default function ResultadosQuiz() {
  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();

  const [tipoVisualizacao, setTipoVisualizacao] = useState(TipoVisualizacao.LISTA);
  const [idTentativa, setIdTentativa] = useState(-1);
  const [usuario, setUsuario] = useState({});

  const quiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('listaTentativasQuiz'));
  const loadingListaTentativasQuiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('loadingListaTentativasQuiz'));

  useEffect(() => {
    dispatch(getListaTentativasQuiz(id));
  }, [id]);

  const handleVisualizarResultado = (id, usuarioTentativa) => {
    setTipoVisualizacao(TipoVisualizacao.DETALHES);
    setIdTentativa(id);
    setUsuario(usuarioTentativa);
  };

  const goBack = () => {
    setTipoVisualizacao(TipoVisualizacao.LISTA);
    setIdTentativa(-1);
    setUsuario({});
  };

  const getTipoVisualizacao = () => {
    if (loadingListaTentativasQuiz) {
      return (
        <View alignItems='center' justifyContent='center'>
          <Spin />
        </View>
      );
    }

    switch (tipoVisualizacao) {
      case TipoVisualizacao.LISTA:
        return <Lista data={{ usuarios: quiz.usuarios }} handlers={{ handleVisualizarResultado }} />;
      case TipoVisualizacao.DETALHES:
        return <Detalhes data={{ id: idTentativa, usuario }} handlers={{ goBack }} />;

      default:
        break;
    }

    return <></>;
  };

  return (
    <App>
      <Body>
        <PageHeader title={`Resultados do Quiz ${quiz && quiz.titulo ? `- ${quiz.titulo}` : ''}`}>{getTipoVisualizacao()}</PageHeader>
      </Body>
    </App>
  );
}
