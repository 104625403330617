import styled from 'styled-components';

import { 
  Icon, 
  Empty, 
  Text, 
  Row, 
  Column,
  Button 
} from '@components-teammove';

export const Card = styled(Column)`
  gap: 20px;
	border-radius: 28px;
	background-color: ${({ theme })=> theme.darkSecondaryColor};
	padding: 28px 12px 12px 12px;

`;

export const MainTitle = styled(Text)`
  &.ant-typography {
    font-size: ${({ size })=> size ? size : '24px'};
    font-weight: ${({ weight })=> weight ? weight : 700};
	  color: ${({ theme })=> theme.textColor};
  }
`;

export const IconFeather = styled(Icon.Feather)`
  color: ${({ color, theme })=> color === 'ok' ? 'var(--success-color)' : color === 'alert' ? 'var(--warning-color)' : color === 'critical' ? 'var(--danger-color)' : color === 'n/a' ? `${theme.backgroundColor}` : `${theme.textColor}`};
`;

export const OkStatus = styled(Row)`
  gap: 6px;
  align-items: center;
  color: var(--success-color);
  font-size: 28px;
`;

export const OkStatusText = styled(Text)`
  &.ant-typography {
    color: var(--success-color);
    font-weight: ${({ bold })=> bold ? '700' : '400'};
  }
`;

export const OthersStatusText = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: ${({ bold })=> bold ? '700' : '400'};
    opacity: ${({ opacity })=> opacity ? '.5' : '1'};
    color: ${({ color })=> color === 'ok' ? 'var(--success-color)' : color === 'alert' ? 'var(--warning-color)' : color === 'critical' && 'var(--danger-color)'} !important;
  }
`;

export const ProgressBar = styled.div`
  border-top: 14px solid ${({ theme })=> theme.backgroundColor};
  border-radius: 28px;
`;

export const MiniCard = styled(Column)`
  justify-content: space-between;
  border-radius: 18px;
  padding: 16px;
  background: ${({ theme, isNotCluter })=> isNotCluter ? theme.backgroundColor : theme.darkPrimaryColor};
`;

export const MiniCardPrimaryInfo = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
  }  
`;

export const MiniCardSubtitleInfo = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: ${({ isBold }) => isBold ? 700 : 400};
  }
`;

export const ColoredBall = styled.div`
  border-radius: 50%;
  background: ${({ color, theme })=> color === 'ok' ? 'var(--success-color)' : color === 'alert' ? 'var(--warning-color)' : color === 'critical' ? 'var(--danger-color)' : color === 'n/a' ? `${theme.backgroundColor}` : `${theme.textColor}`} !important;
  width: 8px;
  height: 8px;
`;

export const PercentageText = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: ${({ opacity }) => opacity ? '.5' : '1'};
  }
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
`;

export const ButtonAlign = styled(Button)`
  align-self: end;
`;
