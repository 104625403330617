import styled from 'styled-components';
import View from '../../View';
import Text from '../../Text';

export const Container = styled(View)`
  gap: 8px;
  margin-bottom: 18px;
`;

export const TagsView = styled(View)`
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Label = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  
  &.ant-typography {
    color: ${(props) => props.theme.textColor};
  }
`;

export const TagText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${({ strong }) => strong && `
    font-weight: 700;
  `}
  strong {
    font-weight: 700;
  }
`;