import styled from 'styled-components';
import { Form, Image } from '@components-teammove';
import View from '../../View';
import Icon from '../../Icon';

export const Row = styled.div`
  display: flex;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
  ${({ justify }) => justify && `
    justify-content: ${justify};
  `}
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const ContainerTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
`;

export const Container = styled(Row)`
  gap: 8px;
  overflow: auto;
  overflow-y: hidden; 
  max-width: 100%;
  padding: 12px 0;

  --max-upload-height: 200px;
  --upload-padding: 8px;
  --upload-gap: 2px;
  --file-name-line-height: 12px;
`;

export const FormMessage = styled(Form)`
  flex: 1;
  margin-left: 10px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: calc(var(--max-upload-height) - (var(--upload-padding) * 2) - var(--file-name-line-height) - var(--upload-gap));
`;

export const Upload = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;  
`;

export const ContentImageUpload = styled(View)`
  padding: var(--upload-padding);
  gap: var(--upload-gap);
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: var(--max-upload-height);
  flex: none;
  position: relative;
`;

export const IconFileDoneOutlinedUpload = styled(Icon.FileDoneOutlined)`
  font-size: 50px;
  border-radius: 50%;
  margin-top: 10px;
  
  path {
    color: #38344B;
  }
`;

export const FileName = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  line-height: var(--file-name-line-height);
`;