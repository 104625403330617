import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import App from '@app';

import { ContentCard, CardTitle, UserProfile, ClosedPercent, CardSubTitle, CardText, CardSecondaryTitle, Container } from './styles';
import { Body, PageHeader, Breadcrumb, Button, Avatar, Row, Column, DividedLine, Legend } from '@components-teammove';
import { moment, getDownloadUrlAvatar, FiltrosSessao } from '@utils';

import { getCurrentDayLabel, getTotalDistance, getTotalTime } from './rules';

import { getGestaoEquipesAtividadesUsuario, FILTERS_KEY } from '@ducks/gestaoEquipes';
import ActivitiesRoutesMap from './Map';
import Tasks from './Tasks';

export default function UserPage() {
  const { userId } = useParams();

  const dispatch = useDispatch();
  
  const theme = useSelector(({ app }) => app.get('theme'));

  const user = useSelector(({ gestaoEquipes }) => gestaoEquipes.get('usuario'));
  const successConfere = useSelector(({ gestaoEquipes }) => gestaoEquipes.get('successConfere'));
  const [day, setDay] = useState(moment(FiltrosSessao.get(FILTERS_KEY)?.data[0] || moment()));
  
  useEffect(() => {
    dispatch(getGestaoEquipesAtividadesUsuario(day, userId));
  }, [day, successConfere]);
 
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Controle de atividades'
          onBack={() => window.history.back()}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/controle-atividades', name: 'Controle de atividades' },
                { path: '/controle-atividades/' + userId, name: user.userName || '...', current: true }
              ]}
            />
          )}
        >
         
          <Column gap='12px'>
            <ContentCard>
              <Row justify='space-between' align='center'>
                <Row gap='20px' align='center'>
                  <Avatar size='large' src={getDownloadUrlAvatar(user?.userImage)}/>
                  <Column gap='8px'>
                    <CardTitle>{user.userName}</CardTitle>
                    <UserProfile>{user.userProfile}</UserProfile>
                  </Column>
                </Row>
                <Row gap='10px'>
                  <Button type='secondary' size='small' onClick={() => setDay(moment(day.subtract(1, 'days')))}>{'<'}</Button>
                  <Button type='secondary' size='small' disabled>{getCurrentDayLabel(day)}</Button>
                  <Button type='secondary' size='small' onClick={() => setDay(moment(day.add(1, 'days')))}>{'>'}</Button>
                </Row>
              </Row>
            </ContentCard>

            <Container>
              <Column gap='12px'>
                <ContentCard>
                  <Column gap='14px'>
                    <Row justify='space-between' align='center'>
                      <CardTitle>Atividades</CardTitle>
                      <ClosedPercent>{user.closedActivitiesPercent}%</ClosedPercent>
                    </Row>
                    <DividedLine
                      divisions={[
                        { label: 'Concluído', total: user.numberOfClosedActivities, percentage: (user.numberOfClosedActivities / (user.numberOfClosedActivities + user.numberOfCanceledActivities + user.numberOfOnGoingActivities)) * 100, status: 'success' }, 
                        { label: 'Cancelado', total: user.numberOfCanceledActivities, percentage: (user.numberOfCanceledActivities / (user.numberOfClosedActivities + user.numberOfCanceledActivities + user.numberOfOnGoingActivities)) * 100, status: 'danger' },
                        { label: 'Pendente', total: user.numberOfOnGoingActivities, percentage: (user.numberOfOnGoingActivities / (user.numberOfClosedActivities + user.numberOfCanceledActivities + user.numberOfOnGoingActivities)) * 100, status: 'undefined' }
                      ]}
                    />
                  </Column>
                </ContentCard>
                <Tasks tasks={user.tasks}/>
              </Column>

              <Column gap='12px'>
                <ContentCard>
                  <ActivitiesRoutesMap user={user}/>
                </ContentCard>

                <ContentCard>
                  <Row justify='space-between'>
                    <Column gap='12px'>
                      <CardSecondaryTitle>Trajeto</CardSecondaryTitle>
                      <Legend
                        colors={[
                          '#1876D1',
                          '#004B9F',
                          '#8E24AA',
                          '#C158DC'
                        ]}
                        labels={[
                          { label: 'Início', color: '#1876D1' },
                          { label: 'Fim', color: '#C158DC' }
                        ]}
                      />
                    </Column>

                    <Column gap='8px' align='flex-end' justify='center'>
                      <CardSubTitle>
                          Distância percorrida:
                          &nbsp;
                        <CardText>
                          {getTotalDistance(user.userLocations?.map(({ latitude, longitude }) => ({ lat: parseFloat(latitude), lng: parseFloat(longitude) }))).toFixed(2)}km
                        </CardText>
                      </CardSubTitle>
                      <CardSubTitle>
                          Tempo total:
                          &nbsp;
                        <CardText>{getTotalTime(user.userLocations)}</CardText>
                      </CardSubTitle>
                    </Column>
                  </Row>
                </ContentCard>
              </Column>
            </Container>
          </Column>
       
        </PageHeader>
      </Body>
    </App>
  );
}