import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal, Form, Input, Switch, View,  Skeleton } from '@components-teammove';
import { saveAtributo, reset } from '@ducks/configuracoesUsuariosAtributo';

export default function AtributoCadastro({ visible, onCancel }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const loading = useSelector(({ configuracoesUsuariosAtributo }) => configuracoesUsuariosAtributo.get('loading'));
  const success = useSelector(({ configuracoesUsuariosAtributo }) => configuracoesUsuariosAtributo.get('success'));
  const successSave = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('successSave'));

  const initialValues = {
    ativo: true,
    ...location.state,
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      onCancel();
    }
  }, [successSave]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      dispatch(saveAtributo(values));
    });
  };

  return(
    <Modal
      visible={visible}
      title="Configurações / Usuários / Atributo"
      numberOfButtons={1}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      onOk={handleSalvar}
    >
      {loading ? (
        <View>
          <Skeleton.Form/>
        </View>
      ) : (
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label="Atributo" name="atributo" rules={[{ required: true, message: 'Informe o atributo!' }]}>
            <Input placeholder="Informe o atributo" />
          </Form.Item>

          <Form.Item label="Ativo" name="ativo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>)}
    </Modal>
  );
}
