import styled from 'styled-components';
import { Select, Empty } from '@components-teammove';

export const SelectStyle = styled(Select)`
	border: 2px solid ${(props) => props.theme.darkPrimaryColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
  background-color: ${(props) => props.theme.darkSecondaryColor} !important;
	width: 281px !important;
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;