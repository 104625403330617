import React, { createRef, useEffect, useState } from 'react';
import { AudioPlayerContainer, Divider, DropdownItem, DropdownMenu, IconPlayer, OptionsContainer, ProgressBar, ProgressBarContainer, TextOptions, Timer, VolumeContainer, VolumeSlider } from './styles';
import { download } from '@utils';

const AudioPlayer = ({ bgColor, src, downloadFileName, padding, widthContainer }) => {
  const audioRef = createRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1); 
  const [isVolumeSliderVisible, setIsVolumeSliderVisible] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isPlaybackRateMenuVisible, setIsPlaybackRateMenuVisible] = useState(false);
  const [ playerWidth, setPlayerWidth ] = useState(false);
  
  useEffect(() => {
    const audio = audioRef.current;

    const updateCurrentTime = () => {
      setCurrentTime(audio.currentTime);
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    const setAudioDuration = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('timeupdate', updateCurrentTime);
    audio.addEventListener('loadedmetadata', setAudioDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateCurrentTime);
      audio.removeEventListener('loadedmetadata', setAudioDuration);
    };
  }, [audioRef]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error('Erro ao tentar reproduzir o áudio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handlePlaybackRateChange = (rate) => {
    audioRef.current.playbackRate = rate;
    setIsPlaybackRateMenuVisible(false);
    setIsOptionsVisible(true);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleDownloadAudio = () => {
    const audioString = `${audioRef.current.src}.mp3`;
    download(audioString, downloadFileName);
  };

  const handleProgressBarClick = (event) => {
    const audio = audioRef.current;
    const progressBar = event.currentTarget;
    const clickPosition = event.clientX - progressBar.getBoundingClientRect().left;
    const progressBarWidth = progressBar.offsetWidth;
    const clickRatio = clickPosition / progressBarWidth;
    const newTime = clickRatio * audio.duration;
    audio.currentTime = newTime;
  };

  return (
    <AudioPlayerContainer bgColor={bgColor} padding={padding} widthContainer={widthContainer}>
      <audio 
        src={src} 
        ref={audioRef}
        autoPlay={false}
      >
        <track kind="captions" src={src} default/>
      </audio>
      <IconPlayer type={isPlaying ? 'pause' : 'play'} onClick={handlePlayPause}/>
      <Timer>
        {formatTime(currentTime)} / {formatTime(duration)}
      </Timer>
      <ProgressBarContainer  onClick={handleProgressBarClick} width={playerWidth}>
        <ProgressBar progress={progress}/>
      </ProgressBarContainer>
      <VolumeContainer >
        <IconPlayer type='volume-high' onClick={() => setIsVolumeSliderVisible(!isVolumeSliderVisible)}/>
        {isVolumeSliderVisible && (
          <VolumeSlider 
            type="range" 
            min="0" 
            max="1" 
            step="0.01" 
            value={volume} 
            onChange={handleVolumeChange}
            onClick={() => setPlayerWidth(!playerWidth)}
            volume={volume}
          />
        )}
      </VolumeContainer>
      <OptionsContainer>
        <IconPlayer type='dots-vertical' onClick={() => setIsOptionsVisible(!isOptionsVisible)}/>
        {isOptionsVisible && (
          <DropdownMenu menuPlayback={isPlaybackRateMenuVisible}>
            {!isPlaybackRateMenuVisible && (
              <>
                <DropdownItem borderTop onClick={handleDownloadAudio}>
                  <IconPlayer iconOptions type='download'/>
                  <TextOptions>Download</TextOptions>
                </DropdownItem>
                <Divider/>
                <DropdownItem borderBottom onClick={() => setIsPlaybackRateMenuVisible(true)}>
                  <IconPlayer iconOptions type='play-speed'/>
                  <TextOptions>Velocidade de reprodução</TextOptions>
                </DropdownItem>
              </>
            )}
            {isPlaybackRateMenuVisible && (
              <>
                <DropdownItem borderTop onClick={() => setIsPlaybackRateMenuVisible(false)}>Voltar</DropdownItem>
                <DropdownItem onClick={() => handlePlaybackRateChange(0.5)}>0.5x</DropdownItem>
                <DropdownItem onClick={() => handlePlaybackRateChange(1)}>1x</DropdownItem>
                <DropdownItem onClick={() => handlePlaybackRateChange(1.5)}>1.5x</DropdownItem>
                <DropdownItem borderBottom onClick={() => handlePlaybackRateChange(2)}>2x</DropdownItem>
              </>
            )}
          </DropdownMenu>
        )}
      </OptionsContainer>
    </AudioPlayerContainer>
  );
};

export default AudioPlayer;