import { moment } from '@utils';

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Perfis',
    name: 'profiles',
    placeholder: 'Selecione um ou mais perfis',
    options: [],
  },
  
];

export const daysOfWeek = (initialDate) => {
  const diasSemana = [];
  const nomenclatureDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  
  [...Array(5).keys()].forEach(() => {
    const dataMoment = moment(initialDate, 'YYYY-MM-DD').add(diasSemana.length + 1, 'days');
    const dia = dataMoment.format('DD');
    diasSemana.push({
      dia: dia.padStart(2, '0'),
      diaSemana: nomenclatureDays[dataMoment.day()] 
    });
  });
  return diasSemana;
};

export const handleChangeOrder = (value, data, setData) => {
  const newData = [...data.users];
  
  switch(value) {
    case 1:
      newData.sort((a, b) => {
        if(b.totalFillPercent !== a.totalFillPercent) {
          return b.totalFillPercent - a.totalFillPercent;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
      newData.forEach((item)=> {
        if(item.children.length > 0) {
          item.children.sort((a, b) => {
            if(b.totalFillPercent - a.totalFillPercent) {
              return b.totalFillPercent - a.totalFillPercent;
            }else {
              return a.name.localeCompare(b.name);
            }
          });
        }
      });
      break;
    case 2: 
      newData.sort((a, b) => {
        if(a.totalFillPercent - b.totalFillPercent) {
          return a.totalFillPercent - b.totalFillPercent;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
      newData.forEach((item)=> {
        if(item.children.length > 0) {
          item.children.sort((a, b) => {
            if(a.totalFillPercent !== b.totalFillPercent) {
              return a.totalFillPercent - b.totalFillPercent;
            }else {
              return a.name.localeCompare(b.name);
            }
          });
        }
      });
      break;
    case 3:
      newData.sort((a, b) => a.name.localeCompare(b.name));
      newData.forEach((item)=> {
        if(item.children.length > 0) {
          item.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
      break;
    case 4: 
      newData.sort((a, b) => b.name.localeCompare(a.name));
      newData.forEach((item)=> {
        if(item.children.length > 0) {
          item.children.sort((a, b) => b.name.localeCompare(a.name));
        }
      });
      break;
    default:
      break;
  }
  setData({ ...data, users: newData });
};

export function OrderPeriod(items, typePeriod) {
  const orderWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
  const orderMonth = ['S1', 'S2', 'S3', 'S4', 'S5'];

  if(typePeriod === 'SEMANAL') {
    const filteredItems = items.filter((item) =>
      item.momentDescription !== 'SUNDAY' && item.momentDescription !== 'SATURDAY'
    );
    const orderedItems = filteredItems.sort((a, b) =>
      orderWeek.indexOf(a.momentDescription) - orderWeek.indexOf(b.momentDescription)
    );
    return orderedItems;
  }else {
    const orderedItems = items.sort((a, b) =>
      orderMonth.indexOf(a.momentDescription) - orderMonth.indexOf(b.momentDescription)
    );
    return orderedItems;
  }

}

