export const dataExample = {
  labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [12,20,30,45, 50],
      borderColor: '#FFCC00',
      backgroundColor: '#FFCC00',
      borderDash: [10, 10],
    },
    {
      label: 'Dataset 2',
      data: [0,10,20,30,40],
      borderColor: '#1890FF',
      backgroundColor: '#1890FF',
    },
    {
      label: 'Dataset 3',
      data: [0,8,15,7,40],
      borderColor: '#34C759',
      backgroundColor: '#34C759',
    }
  ]
};

export const optionsForChart = (typePeriod) => ({
  maintainAspectRatio: false,
  responsive: true,
  aspectRatio: 2,
  plugins: {
    datalabels:{
      display:false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: 'Atividades',
        font: {
          size: 12,
          weight: 400,
        },
        color: 'rgba(255, 255, 255, 0.5)',
      },
      ticks: {
        font: {
          size: 12,
          weight: 700,
        }
      },
      border: {
        display: false,
      },
      grid: {
        color: '#4C4766',
      },
      
    },
    x: {
      title: {
        display: true,
        text: typePeriod === 'DIARIO' ? 'Horas' : typePeriod === 'SEMANAL' ? 'Dias' : 'Semanas',
        font: {
          size: 12,
          weight: 400,
        },
        color: 'rgba(255, 255, 255, 0.5)',
      },
      grid: {
        display: false,
      },
      ticks:{
        font: {
          size: 12,
          weight: 700,
        }
      }
    },
  }
});