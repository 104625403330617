import styled from 'styled-components';
import { Column, Text, Row, Icon } from '@components-teammove';

export const ViewAnimation = styled(Column)`
  width: ${({ loading }) => (loading ? '70%' : '35%')};	
  height: 135px;
  .lf-player-container {
    transform: ${({ animationPosition }) =>
    `translateY(-25%) translateX(${-animationPosition}%)`};
    transition: transform 0.01s linear;
    width: 350px !important;
  }
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  margin: 8px 0;
`;

export const Description = styled(Text)`
  font-size: 12px;
  font-weight: ${({ typeActivity, activityName }) => typeActivity || activityName ? 700 : 400};
  opacity: ${({ typeActivity, activityName }) => typeActivity || activityName ? 1 : 0.5};
  line-height: 16.34px;
  margin-bottom: ${({ descriptionTitle }) => descriptionTitle ? '12px' : '0'};
`;

export const ButtonProgress = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 6px 16px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

export const ColumnContainerText = styled(Column)`
  opacity: ${({ loading }) => loading ? 0 : 1};
  transition: opacity 0.5s ease-in-out;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ colorActivity }) => colorActivity};
  padding: 4px;
`;

export const RowContainer = styled(Row)`
  margin-bottom: 12px;
  align-items: center;
`;

export const IconActivity = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor} !important;
  font-size: 14.5px;
`;