import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import App from '@app';
import { Body, PageHeader, Button, View, Text, Spin } from '@components';
import { Icon } from '@components-teammove';
import { ListItem, LinkTrail, ViewTrail, ImageTrail } from './styles';
import { getTrails, updateOrderTrails } from '@ducks/wikisEADTrilhas';
import { arrayReorder } from '@utils';

export default function WikisConteudos() {
  const dispatch = useDispatch();
  const history = useHistory();
  const trails = useSelector(({ wikisEADTrilhas }) => wikisEADTrilhas.get('trails'));
  const loading = useSelector(({ wikisEADTrilhas }) => wikisEADTrilhas.get('loading'));

  useEffect(() => {
    handleGetTrails();
  }, []);

  const handleGetTrails = () => {
    dispatch(getTrails());
  };

  const handleUpdateOrderTrails = (newOrderTrails) => {
    dispatch(updateOrderTrails(newOrderTrails));
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    handleUpdateOrderTrails(arrayReorder(trails, oldIndex, newIndex).map((item, index) => ({ ...item, ordem: index })));
  };

  const ContentItem = SortableElement(({ item }) => {
    return (
      <ListItem key={item.id}>
        <LinkTrail key={item.id} to={`/ead/trilha/cadastro/${item.id}`}>
          <ViewTrail horizontal ativo={item.ativo}>
            <View>
              <Text strong>{item.nome}</Text>
              <Text>{item.resumo}</Text>
            </View>
            <ImageTrail alt={item.nome} src={item.capa || 'https://media-teammove.s3-sa-east-1.amazonaws.com/EAD/ead-default.jpg'} />
          </ViewTrail>
        </LinkTrail>
      </ListItem>
    );
  });

  const ContainerItems = SortableContainer(({ items }) => {
    return (
      <View>
        {items.map((item, index) => <ContentItem key={item.id} index={index} item={item} />)}
      </View>
    );
  });

  return (
    <App>
      <Body>
        <PageHeader 
          title='Trilhas'
          extra={[
            <Button
              key='1'
              type='primary'
              icon={<Icon.PlusOutlined />}
              onClick={() => history.push('/ead/trilha/cadastro/-1')}
            >
              Novo
            </Button>,
          ]}
        >
          <View>
            {loading ? <Spin /> : <ContainerItems items={trails} onSortEnd={handleSortEnd} distance={1} lockAxis="y"/>}
          </View>
        </PageHeader>
      </Body>
    </App>
  );
}