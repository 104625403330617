import styled from 'styled-components';
import { Modal, View } from '@components-teammove';

export const ModalImport = styled(Modal)`
  min-width: 700px;
  .ant-upload.ant-upload-select {
    width: 100% !important;
  }
`;

export const LabelModelImport = styled(View)`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const WarningCard = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  padding: 13px 30px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.textColor};
`;

export const ExcelTable = styled.table`
  table-layout: fixed;
  width: 100%;
  th, td {
    width: fit-content;
  }
  th:first-child {
    width: 7%;
  }
  th:nth-child(2) {
    width: 30%;
  }
  th, td:first-child {
    padding: 12px;
    border: 2px solid #595959;
    background: #E6E6E6;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    text-align: center;
  }
  th {
    border-top: 0;
    border-bottom: 0;
  }
  td {
    padding: 14px 12px;
    border: 2px solid #E6E6E6;
    background: #FFF;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
  td:first-child {
    border-right: 0;
    border-left: 0;
  }
  tbody {
    tr:first-child td {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }
  td:last-child, th:last-child {
    border-right: 0;
  }
`;