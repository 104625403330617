import styled from 'styled-components';

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns, templateColumns }) => numberOfColumns ? '1fr '.repeat(numberOfColumns).trimEnd() : templateColumns};
  grid-template-rows: ${({ numberOfRows, templateRows }) => numberOfRows ? '1fr '.repeat(numberOfRows).trimEnd() : templateRows};
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
  ${({ justify }) => justify && `
    justify-content: ${justify};
  `}
  ${({ margin }) => margin && `
    margin: ${margin};
  `}
  ${({ width }) => width && `
    width: ${width};
  `}
`;