import React, { useEffect, useState } from 'react';
import { View, Spin } from '@components';
import { Row } from '@components-teammove';
import { AbsoluteSubtitle, AbsoluteText, CenteredView, CheckIcon, CloseIcon, ColoredText, Container, DayHeader, DayProgress, DayView, FlexFillRow, Greeting, GreetingSubtitle, Header, HeaderText, JustifyCenterRow, LineProgress, LogoImage, MarginfulProgress, PaddingLeft, PrintableContainer, PrintableProgress, PrintContainer, PrintProviderContainer, ProfileHeader, ProfileTitle, ProfileUsersTableTitle, RowWithGap, TinyMarginBottom , PrintableTable, UsagePercent } from './styles';
import { InfoUsuario , moment, formatDate, getNomeDia } from '@utils';

export default function Impressao({ loading, report, reportPeriod, getDataIndex, logo, isOurLogo, setPrinting }) {
  const [loadingLogo, setLoadingLogo] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoadingLogo(false), 100);
  }, []);
  
  useEffect(() => {
    if (!loadingLogo) {
      window.print();
      setTimeout(() => setPrinting(false), 100);
    }
  }, [loadingLogo]);
  
  const getFirstNameOnly = (name) => {
    return name.split(' ')[0];
  };

  const getTableTitle = (profileDays) => (
    <ProfileUsersTableTitle>
      <ColoredText>% de acesso</ColoredText>
      <FlexFillRow>
        {profileDays.map((day) => (
          <JustifyCenterRow key={day.day}>
            <DayProgress
              type='dashboard'
              gapDegree={147.5}
              width={'100%'}
              height={200 + 'px'}
              percent={day.percentageTotalAccess}
              trailColor='#D8D7D7'
            />
          </JustifyCenterRow>
        ))}
      </FlexFillRow>
    </ProfileUsersTableTitle>
  );

  return(
    <PrintProviderContainer>
      {loading ? <View><Spin/></View> : (
        <PrintContainer name='week-report' single>
          <PrintableContainer id='print-container'>
            <Header>
              <View>
                <HeaderText><strong>Daily Active Users</strong> - {InfoUsuario.get('nmCliente')}</HeaderText>
                <HeaderText>Referente ao período de {formatDate(reportPeriod?.start)?.substring(0, 5)} até {formatDate(reportPeriod?.end)?.substring(0, 5)}</HeaderText>
              </View>
              <LogoImage alt="TeamMove" src={logo} isOurLogo={isOurLogo}/>
            </Header>
            <Row>
              <PaddingLeft>
                <Greeting>Olá, {InfoUsuario.get('nmUsuario').split(' ')[0]}!</Greeting>
                <GreetingSubtitle>Este é o seu <strong>report semanal</strong> de uso do aplicativo TeamMove</GreetingSubtitle>
                <ColoredText>Você tem: <strong>{report?.totalUsers} usuários ativos</strong></ColoredText>
              </PaddingLeft>
            </Row>
            <CenteredView>
              <PrintableProgress
                type='dashboard'
                gapDegree={147.5}
                width={312}
                height={240 + 'px'}
                percent={report?.totalUsersPercentage}
                trailColor='#D8D7D7'
              />
              <AbsoluteSubtitle>Essa é a <strong>taxa de uso geral</strong> da sua equipe</AbsoluteSubtitle>
            </CenteredView>
            <Container>
              <TinyMarginBottom>
                <GreetingSubtitle><strong>Total de acessos na semana</strong></GreetingSubtitle>
              </TinyMarginBottom>
              <RowWithGap>
                {report?.days?.map((day) => (
                  <DayView key={day.date}>
                    <DayHeader>
                      <strong>{getNomeDia(moment(day.date))}</strong>
                    </DayHeader>
                    <MarginfulProgress
                      type='dashboard'
                      gapDegree={147.5}
                      width='100%'
                      height={210 + 'px'}
                      percent={day.percentageTotalAccess}
                      trailColor='#D8D7D7'
                      innerFontSize='3em'
                    />
                    <AbsoluteText>{day.totalAccess} de {report.totalUsers}</AbsoluteText>
                  </DayView>
                ))}
              </RowWithGap>

              {report?.profiles?.map((profile) => (
                <>
                  <div style={{ display: 'block' }}>
                    <ProfileHeader>
                      <ProfileTitle><strong>{profile.name}</strong></ProfileTitle>
                      <UsagePercent>% de uso</UsagePercent>
                      <LineProgress type='line' percent={profile.percentProfileAccess} width='30%' strokeWidth={12.5} trailColor='#D8D7D7' innerFontSize='3em'/>
                    </ProfileHeader>
                    {getTableTitle(profile.days.sort((a, b) => moment(a.date).diff(moment(b.date))))} 
                    <PrintableTable>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          {report?.days?.map((day) => (
                            <th key={day.date}>{getNomeDia(moment(day.date))}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {getDataIndex(profile.users).map((row, index) => (
                          <tr key={row.name}>
                            <td><div style={{ display: 'block' }}>{row.name.length > 20 ? getFirstNameOnly(row.name.substring(0, 20)) : row.name}</div></td>
                            {report?.days?.map((day) => (
                              <td key={day.date}>
                                <div style={{ display: 'block' }}>
                                  {row[getNomeDia(moment(day.date))] ? (
                                    <CheckIcon type='check'/>
                                  ) : (
                                    <CloseIcon type='close'/>
                                  )}
                                </div>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </PrintableTable>
                  </div>
                </>
              ))}
            </Container>
          </PrintableContainer>
        </PrintContainer>
      )}
    </PrintProviderContainer>
  );
}