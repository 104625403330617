import styled from 'styled-components';
import { Button, Image, Text } from '@components-teammove';
import { Column } from '../styles';

export const WikiImage = styled(Image)`
  margin-top: 20px;
  max-width: 600px;
`;

export const ButtonPersonalizar = styled(Button)`
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 16px;
`;

export const TextoLista = styled(Text)`
  color: #CC4444 !important;
  font-size: 14px;
  margin-top: -8px;

`;

export const ImageResume = styled.span`
  font-size: 16px;
  font-weight: 400;
  opacity: .5;
  color: ${({ theme }) => theme.textColor};
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  @supports not (-webkit-line-clamp: 1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Container = styled(Column)`
  max-width: 600px;
`;