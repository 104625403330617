import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { StyledRow } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartPie = ({ data, large, noLabel, small }) => {
  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels:{
          font:{
            size: 20,
            family: 'Open Sans',
            weight: 600,
          },
          usePointStyle: true,
          padding: 24,
          pointStyle: 'circle',
          boxWidth:10,
          boxHeight: 10,
        },
        align: 'center',
        display: !noLabel,
      },
      tooltip: {
        enabled: false,
      },
      datalabels:{
        display: false,
      }
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: true,
    
  };

  const { labels, values, colors } = data?.reduce((state, item) => {
    return {
      labels: [...(state.labels || []), item.label],
      values: [...(state.values || []), item.quantity],
      colors: [...(state.colors || []), item.color],
    };
  }, {});

  const formattedData = {
    labels,
    datasets: [{
      data: values,
      backgroundColor: colors,
      borderWidth: 0,
    }]
  };
  
  return(
    <StyledRow large={large} small={small}>
      <Pie radius={50} data={formattedData} options={options}/>
    </StyledRow>
  );
};

export default ChartPie;