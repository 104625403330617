import React from 'react';
import { CheckIcon, DangerIcon, WarningIcon } from './styles';

export const getContentByPercentage = (percent) => {
  if (percent < 50) {
    return <DangerIcon type='x'/>;
  } else if (percent < 70) {
    return <WarningIcon type='exclamation-mark'/>;
  } else {
    return <CheckIcon type='check'/>;
  }
};

export const getColorByPercentage = (percent, theme) => {
  if (percent < 50) {
    return 'var(--danger-color)';
  } else if (percent < 70) {
    return 'var(--warning-color)';
  } else {
    return 'var(--success-color)';
  }
};