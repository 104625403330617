import React from 'react';
import { SimpleDivision, SimpleLine } from './styles';
import { Column } from '@components-teammove';

const VerticalLine = ({ divisions, ...props }) => {
  return (
    <Column gap='8px'>
      <SimpleLine>
        {divisions.filter(({ percentage }) => percentage > 0).map(({ percentage, status, label, useBgColor }) => (
          <SimpleDivision useBgColor={useBgColor} status={status} percentage={percentage} key={label}/>
        ))}
      </SimpleLine>      
    </Column>
  );
};

export default VerticalLine;