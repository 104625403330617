import styled from 'styled-components';
import { Column, Button, Input, Text } from '@components-teammove';

export const QuestionsContainer = styled(Column)`
  /* max-height: 250px;
  overflow-y: scroll; */
`;

export const NewQuestionButton = styled(Button)`
  &.ant-btn {
    margin: 0 auto 1rem;
  }
`;

export const InputText = styled(Input)`
  width: 48.5%;
`;

export const Label = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme && theme.textColor};
  font-weight: 600;
  line-height: 18px;
`;