import React from 'react';
import { LoadingContainer, LoadingHeaderRow, LoadingRow, StyledTable, GlobalStyle } from './styles';
import { Skeleton } from '@components-teammove';
import Media from 'react-media';
import { propsColumn, responsiveColumns } from './rules';
import { Table as TableAntd } from 'antd';
import PropTypes from 'prop-types';
import Empty from '../Empty';

const TableComponent = ({ queries = {}, hidePagination, pagination, status, rowClassName, columns, ...otherProps }) => (
  <>
    <GlobalStyle />
    <Media queries={queries}>
      {(matches) => (
        <StyledTable
          pagination={
            // prettier-ignore
            !hidePagination
              ? {
                showSizeChanger: true,
                showQuickJumper: true,
                hideOnSinglePage: true,
                defaultPageSize: 50,
                pageSizeOptions: ['25', '50', '100'],
                ...pagination,
              }
              : false
          }
          rowClassName={(record) => {
            let className = [];
            if (status && record[status] !== undefined && !record[status]) {
              className.push('disabled-row');
            }
            if (rowClassName) {
              className.push(rowClassName(record));
            }

            return className.join(' ');
          }}
          columns={responsiveColumns(columns.map(propsColumn), matches)}
          {...otherProps}
        />
      )}
    </Media>
  </>
);
TableComponent.propTypes = {
  ...TableAntd.propTypes,
  hidePagination: PropTypes.bool,
  status: PropTypes.string,
};

TableComponent.defaultProps = {
  hidePagination: false,
  status: 'status',
  size: 'middle',
  tableLayout: 'auto',
  locale: {
    emptyText: <Empty description="Sem dados" />,
  },
};

const Table = ({ loading, columns = [], ...otherProps }) => (
  <>
    {loading ? (
      <LoadingContainer>
        <LoadingHeaderRow>
          <Skeleton/>
        </LoadingHeaderRow>
        {[0, 1, 2, 3, 4].map((num) => (
          <LoadingRow key={num}>
            <Skeleton/>
          </LoadingRow>
        ))}
      </LoadingContainer>
    ) : (
      <TableComponent columns={columns} {...otherProps}/>
    )}
  </>
);

export default Table;