import React from 'react';

import { Row, Tooltip } from '@components-teammove';
import { ContentPhases, StepBall, FeatherIcon, InfoDot, StyleText } from './styles';

export default function Progress({ steps }) {
  const numberStepsClosed = steps?.filter((step)=> step.statusDs === 'Fechado').length;
  const numberStepsOpen = steps?.filter((step)=> step.statusDs !== 'Fechado').length;
  
  return(
    <>
      <ContentPhases gap='8px'>
        {steps?.map((step)=>(
          <Tooltip title={step.assunto} key={step.id}>
            <StepBall key={step.id} justify='center' status={step.statusDs} align='center'>
              {(step.statusDs === 'Fechado' || step.bloqueiaChamadoPai) && (
                <FeatherIcon status={step.statusDs} size='16' type={step.bloqueiaChamadoPai && step?.statusDs !== 'Fechado' ? 'lock' : 'check'}/>
              )}     
            </StepBall>
          </Tooltip>
          
        ))}
       
        <Row align='center' gap='12px'>
          <Row align='center' gap='4px'>
            <InfoDot color />
            <StyleText>{numberStepsClosed} concluídas</StyleText>
          </Row>
          <Row align='center' gap='4px'>
            <InfoDot />
            <StyleText>{numberStepsOpen} pendente</StyleText>
          </Row>
        </Row>
      </ContentPhases>
     
    </>
  );

}