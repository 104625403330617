import styled from 'styled-components';
import { View, Table, Icon } from '@components-teammove';

export const ViewUltimoAcesso = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const ViewDias = styled(View)`
  ${(props) => props.atrasado && `
    color: var(--danger-color);
    font-weight: bold;
  `}
`;

export const TableAcessos = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
  .disabled-user-team-management-row {
    opacity: 0.5;
  }
`;

export const Icone = styled(Icon.Feather)`
  color: ${(props) => props.color} !important;
`;