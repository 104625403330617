import React from 'react';
import { Table, StatusTag, Column, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { getAtividades } from '@ducks/atividades';
import { FILTERS_KEY } from '@ducks/agenda';
import { Filtros, formatDate, formatTime, moment } from '@utils';
import { ActivityColumnRow, ActivityIcon, ActivityName, CellPaddingContainer, CellVerticalPaddingContainer, CompanyName, Container, IconView, LoadMoreButton, RegularCellText, SecondaryText } from './styles';
import { Link } from 'react-router-dom';

export default function ActivitiesList({ orderBy, setOrderBy, allowGoals, useFinishedDateOnFinishedActivities }) {
  const dispatch = useDispatch();
  const activities = useSelector(({ atividades }) => atividades.get('atividades'));
  const total = useSelector(({ atividades }) => atividades.get('total'));
  const search = useSelector(({ agenda }) => agenda.get('search'));
  const loadingActivities = useSelector(({ atividades }) => atividades.get('loadingAtividades'));

  const getDateIndex = (activity, useFinishedDateOnFinishedActivities) => {
    if (useFinishedDateOnFinishedActivities && ['CONCLUIDA', 'EXCLUIDA'].includes(activity.status)) {
      return 'finished';
    }
    return 'start';
  };

  const columns = [
    {
      title: 'Atividade',
      dataIndex: 'title',
      defaultSortOrder: orderBy && orderBy.field === 'title' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, activity) => (
        <Link to={`/atividades/agenda/${activity.id}`}>
          <ActivityColumnRow>
            <IconView color={activity.typeColor}>
              <ActivityIcon type={activity.typeIcon}/>
            </IconView>
            <CellVerticalPaddingContainer>
              <Column gap='6px'>
                <Column>
                  <ActivityName lineThrough={activity.status === 'EXCLUIDA'}>{activity.title}</ActivityName>
                  <CompanyName lineThrough={activity.status === 'EXCLUIDA'}>{activity.companyName}</CompanyName>
                </Column>
                <SecondaryText lineThrough={activity.status === 'EXCLUIDA'}>{activity.companyCity} - {activity.companyState}</SecondaryText>
              </Column>
            </CellVerticalPaddingContainer>
          </ActivityColumnRow>
        </Link>
      )
    },
    {
      title: 'Responsável',
      dataIndex: 'userName',
      defaultSortOrder: orderBy && orderBy.field === 'userName' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, activity) => (
        <Link to={`/atividades/agenda/${activity.id}`}>
          <RegularCellText lineThrough={activity.status === 'EXCLUIDA'}>{text}</RegularCellText>
        </Link>
      )
    },
    {
      title: 'Data',
      dataIndex: 'start',
      defaultSortOrder: orderBy && orderBy.field === 'start' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, activity) => {
        const dateIndex = getDateIndex(activity, useFinishedDateOnFinishedActivities);
        const dateText = dateIndex === 'finished' ? activity?.finished : activity?.start;
        const startTime = dateIndex === 'finished' ? activity?.finished : activity?.start;
        
        return (
          <Link to={`/atividades/agenda/${activity.id}`}>
            <Column>
              <RegularCellText lineThrough={activity.status === 'EXCLUIDA'}>{formatDate(dateText)}</RegularCellText>
              {!((startTime?.substring(8).includes('00:00:00') && activity.end.substring(8).includes('00:00:00')) || Math.abs(moment(startTime).diff(moment(activity.end), 'hours')) >= 23) && (
                <SecondaryText lineThrough={activity.status === 'EXCLUIDA'}>{formatTime(startTime)} - {formatTime(activity.end)}</SecondaryText>
              )}
            </Column>
          </Link>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      defaultSortOrder: orderBy && orderBy.field === 'status' ? orderBy.order + 'end' : false,
      sorter: true,
      width: '1%',
      render: (text, activity) => (
        <Link to={`/atividades/agenda/${activity.id}`}>
          <CellPaddingContainer>
            <StatusTag color={activity.statusColor} text={activity.statusDescription} size='average' fixedWidth='105px'/>
          </CellPaddingContainer>
        </Link>
      )
    }
  ];

  return(
    <Container>
      <Table
        spaced
        rowKey="id"
        columns={columns}
        dataSource={activities}
        hidePagination
        loading={loadingActivities}
        onChange={(pagination, filters, { field, order }) => setOrderBy(order ? { field, order: order.replace('end', '') } : false)}
        onRow={(activity, rowIndex) => {
          return {
            style: (activity.status === 'CONCLUIDA' || activity.status === 'EXCLUIDA') && { opacity: 0.5 }
          };
        }}
      />
      {!loadingActivities && activities.length < total && (
        <LoadMoreButton type='secondary' icon={<Icon.ReloadOutlined/>} loading={loadingActivities} onClick={() => dispatch(getAtividades({ filtros: Filtros.get(FILTERS_KEY), search, limit: activities.length + 50, allowGoals }))}>Carregar mais</LoadMoreButton>
      )}
    </Container>
  );
}