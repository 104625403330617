import { TelasNomenclaturas } from '@utils';

const getModalFilters = (agrupadores, companyTypes) => {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  return [
    {
      type: 'SELECT',
      mode: 'multiple',
      label: 'Agrupador',
      name: 'agrupadorId',
      placeholder: 'Selecione o agrupador',
      options: agrupadores?.map(({ description, id }) => ({ label: description, value: id }))
    },
    {
      type: 'SELECT',
      mode: 'multiple',
      label: `Tipo de ${companyNomenclature.nomenclatura}`,
      name: 'companyTypeId',
      placeholder: `Selecione o tipo de ${companyNomenclature.nomenclatura}`,
      options: companyTypes?.map((companyType) => ({ label: companyType.name, value: companyType.id })),
    }
  ];
};

export default getModalFilters;