import styled from 'styled-components';
import { Image, Button } from '@components-teammove';
import Icon from '../../Icon';
import View from '../../View';
import { CloseIcon } from '../styles';

export const OverlayImage = styled(Image)`
  max-height: 75vh;
  max-width: 90vw;
`;

export const Row = styled(View)`
  flex-direction: row;
  align-items: center;
`;

export const ChangeImgBtnPrev = styled(Icon.MDI)`
  margin: 1rem;
  border: none !important;
  height: 2rem !important;
  width: 2rem !important;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
  `}
`;

export const ChangeImgBtnNext = styled(Icon.MDI)`
  margin: 1rem;
  border: none !important;
  height: 2rem !important;
  width: 2rem !important;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
  `}
`;

export const MediaView = styled(View)`
  gap: 12px;
  position: relative;
`;

export const ActionButton = styled(Button)`
  align-self: center;
`;

export const Close = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const NoMediaIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const NoMediaText = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const NoImage = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 8px;
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 5.5rem;

  * {
    color: ${({ theme }) => theme.darkHighlightColor};
  }
`;