import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { saveConfiguracao as saveConfiguracaoSdk, getConfiguracao as getConfiguracaoSdk } from '@sdk/Chamados';

//Action Types
export const Types = {
  SAVE: 'CONFIGURACOES_CHAMADOS_GERAL/SAVE',
  SAVE_SUCCESS: 'CONFIGURACOES_CHAMADOS_GERAL/SAVE_SUCCESS',
  SAVE_ERROR: 'CONFIGURACOES_CHAMADOS_GERAL/SAVE_ERROR',

  GET: 'CONFIGURACOES_CHAMADOS_GERAL/GET',
  GET_SUCCESS: 'CONFIGURACOES_CHAMADOS_GERAL/GET_SUCCESS',
  GET_ERROR: 'CONFIGURACOES_CHAMADOS_GERAL/GET_ERROR',
};

//Action Creators
export const saveConfiguracao = (configuracao) => ({ type: Types.SAVE, configuracao });
export const saveConfiguracaoSuccess = (data) => ({ type: Types.SAVE_SUCCESS, data });
export const saveConfiguracaoError = (error) => ({ type: Types.SAVE_ERROR, error });

export const getConfiguracao = () => ({ type: Types.GET });
export const getConfiguracaoSuccess = (data) => ({ type: Types.GET_SUCCESS, data });
export const getConfiguracaoError = (error) => ({ type: Types.GET_ERROR, error });

//Saga
function* fetchSaveConfiguracao(action) {
  try {
    const { configuracao } = action;

    const response = yield call(saveConfiguracaoSdk, configuracao);    
    yield put(saveConfiguracaoSuccess(response));
    Notification.success('Configuração salva com sucesso!');
  } catch(err) {
    Notification.error(err.message);
    yield put(saveConfiguracaoError(err.message));
  }
}

function* fetchGetConfiguracao(action) {
  try {
    const response = yield call(getConfiguracaoSdk);

    yield put(getConfiguracaoSuccess(response));
  } catch(err) {
    Notification.error(err.message);
    yield put(getConfiguracaoError(err.message));
  }
}

export const saga = [
  takeLatest(Types.SAVE, fetchSaveConfiguracao),
  takeLatest(Types.GET, fetchGetConfiguracao),
];

//Reducer
const initialState = Map({
  configuracao: {},
  loadingSave: false,
  successSave: false,
  errorSave: false,
  
  loadingGet: false,
  successGet: false,
  errorGet: false,
});

const handleSaveConfiguracao = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};

const handleSaveConfiguracaoSuccess = (state, action) => {
  const { data } = action;
    
  return state
    .set('configuracao', data)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleSaveConfiguracaoError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handleGetConfiguracao = (state, action) => {
  return state
    .set('loadingGet', true)
    .set('successGet', false)
    .set('errorGet', false);
};

const handleGetConfiguracaoSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('configuracao', data)
    .set('loadingGet', false)
    .set('successGet', true)
    .set('errorGet', false);
};

const handleGetConfiguracaoError = (state, action) => {
  return state
    .set('loadingGet', false)
    .set('successGet', false)
    .set('errorGet', action.error);
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case Types.SAVE: return handleSaveConfiguracao(state, action);
    case Types.SAVE_SUCCESS: return handleSaveConfiguracaoSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveConfiguracaoError(state, action);

    case Types.GET: return handleGetConfiguracao(state, action);
    case Types.GET_SUCCESS: return handleGetConfiguracaoSuccess(state, action);
    case Types.GET_ERROR: return handleGetConfiguracaoError(state, action);

    default: return state;
  }
}