import Crypto from 'crypto-js';

const SECRET_KEY = 't&@MM0\\/&!@#';

const AES = {
  encrypt: (text) => Crypto.AES.encrypt(text, SECRET_KEY).toString(),
  decrypt: (text) => Crypto.AES.decrypt(text, SECRET_KEY).toString(Crypto.enc.Utf8),
};

const MD5 = (text) => Crypto.MD5(text).toString();
const SHA256 = (text) => Crypto.SHA256(text).toString();

export default {
  AES,
  MD5,
  SHA256,
};
