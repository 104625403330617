import React from 'react';
import { Container, ContentCard, ContentCardSkeleton, ContentImage, ContentSummary, ContentTitle, FavoriteIndicator, ActionIndicatorBox, NoImage, NoImageIconWrapper, ActionsContainer, ToggleReadIndicator, ContentPath } from './styles';
import { Skeleton, Column, Row, List } from '@components-teammove';
import { getIconByType } from './rules';
import { UnreadIndicator } from '../styles';
import { postFavorito, postLido } from '@ducks/wikisAcoes';
import { useDispatch } from 'react-redux';
import { changeFavorite, changeRead } from '@ducks/wikis';

export default function FirstPageListing({ dataSource, onItemClick, loading, isFiltering }) {
  const dispatch = useDispatch();

  return(
    <>
      <Container>
        {loading ? (
          <>
            {new Array(12).fill('').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <ContentCardSkeleton key={index}>
                <Skeleton/>
              </ContentCardSkeleton>
            ))}
          </>
        ) : (
          <>
            {dataSource.map((content) => (
              <ContentCard key={content.id} onClick={() => onItemClick(content)}>
                <ActionsContainer>
                  <ActionIndicatorBox favorite={content.favorito}>
                    <FavoriteIndicator 
                      type={'star' + (content.favorito ? '-fill' : '')} 
                      size='0.75rem'
                      onClick={(evt) => {
                        evt.stopPropagation();
                        dispatch(postFavorito(content.id, !content.favorito));
                        dispatch(changeFavorite(!content.favorito, content.id));
                      }}
                      title='Marcar como favorito'
                    />
                  </ActionIndicatorBox>
                  <ActionIndicatorBox read={content.lido}>
                    <ToggleReadIndicator 
                      type={content.lido ? 'email' : 'email-open'}
                      size='0.75rem'
                      onClick={(evt) => {
                        evt.stopPropagation();
                        dispatch(postLido(content.id, !content.lido));
                        dispatch(changeRead(!content.lido, content.id));
                      }}
                      title={'Marcar como ' + (content.lido ? 'não ' : '') + 'lido'}
                    />
                  </ActionIndicatorBox>
                </ActionsContainer>
                {content.imagem ? (
                  <ContentImage src={content.imagem}/>
                ) : (
                  <NoImage>
                    <NoImageIconWrapper>
                      {getIconByType(content.tipo)}
                    </NoImageIconWrapper>
                  </NoImage>
                )}
                <Column>
                  {isFiltering && content.path ? (
                    <Row>
                      {content.path.map((itemPath) => {
                        const path = itemPath.split('||');
                        return <ContentPath key={path[0]}>{path[0] === content.id.toString() ? '' : path[1] + ' > '}</ContentPath>;
                      })}
                    </Row>
                  ) : null}
                  <Row gap='4px' align='center'>
                    <ContentTitle>{content.titulo}</ContentTitle>
                    {!content.lido && (
                      <UnreadIndicator/>
                    )}
                  </Row>
                  <ContentSummary>{content.resumo}</ContentSummary>
                </Column>
              </ContentCard>
            ))}
          </>
        )}
      </Container>

      {!loading && dataSource.length === 0 && (
        <List/>
      )}
    </>
  );
}