import { get } from '../api';
import { InfoUsuario } from '@utils';
import { queryParamsConteudos } from './rules';

export const getConteudos = (idCategoriaPai, filtros) => {
  return get(`/api/wikis/${idCategoriaPai}?idUsuario=${InfoUsuario.get('cdUsuario')}${queryParamsConteudos(filtros)}`);
};

export const getConteudo = (id) => {
  return get(`/api/wiki/${id}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const getAudiencia = (id) => {
  return get(`/api/wiki/audiencia/v2/${id}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const getFeedback = (id) => {
  return get(`/api/wiki/feedback/${id}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const getConfirmacoesLeitura = (id) => {
  return get(`/api/wiki/usuarioAcao/aceitos/${id}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const getIsComunicado = (id) => {
  return get(`/api/wiki/iscomunicado/${id}`);
};

export const getGruposComunicados = () => {
  return get(`/api/wiki/grupos/comunicados?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const getGruposComunicadosSelect = () => {
  return getGruposComunicados().then((res) => {
    return res.map((item) => ({
      key: item.id,
      value: item.id,
      title: item.titulo,
    }));
  });
};

