import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getAtendimentos as getAtendimentosSdk,
  getAtendimento as getAtendimentoSdk,
  saveAtendimento as saveAtendimentoSdk,
  putAtendimento as putAtendimentosSdk,
  deleteAtendimento as deleteAtendimentoSdk,
  moveProfilesToJourney as moveProfilesToJourneySdk,
} from '@sdk/Atendimento';

export const Types = {  
  GET_ATENDIMENTOS: 'CONFIGURACOES/GET_ATENDIMENTOS',
  GET_ATENDIMENTOS_SUCCESS: 'CONFIGURACOES/GET_ATENDIMENTOS_SUCCESS',
  GET_ATENDIMENTOS_ERROR: 'CONFIGURACOES/GET_ATENDIMENTOS_ERROR',

  GET_ATENDIMENTO: 'CONFIGURACOES/GET_ATENDIMENTO',
  GET_ATENDIMENTO_SUCCESS: 'CONFIGURACOES/GET_ATENDIMENTO_SUCCESS',
  GET_ATENDIMENTO_ERROR: 'CONFIGURACOES/GET_ATENDIMENTO_ERROR',

  SAVE_ATENDIMENTO: 'CONFIGURACOES/SAVE_ATENDIMENTO',
  SAVE_ATENDIMENTO_SUCCESS: 'CONFIGURACOES/SAVE_ATENDIMENTO_SUCCESS',
  SAVE_ATENDIMENTO_ERROR: 'CONFIGURACOES/SAVE_ATENDIMENTO_ERROR',

  PUT_ATENDIMENTO: 'CONFIGURACOES/PUT_ATENDIMENTO',
  PUT_ATENDIMENTO_SUCCESS: 'CONFIGURACOES/PUT_ATENDIMENTO_SUCCESS',
  PUT_ATENDIMENTO_ERROR: 'CONFIGURACOES/PUT_ATENDIMENTO_ERROR',

  DELETE_ATENDIMENTO: 'CONFIGURACOES/DELETE_ATENDIMENTO',
  DELETE_ATENDIMENTO_SUCCESS: 'CONFIGURACOES/DELETE_ATENDIMENTO_SUCCESS',
  DELETE_ATENDIMENTO_ERROR: 'CONFIGURACOES/DELETE_ATENDIMENTO_ERROR',

  PUT_MOVE_PERFIL: 'CONFIGURACOES/PUT_MOVE_PERFIL',
  PUT_MOVE_PERFIL_SUCCESS: 'CONFIGURACOES/PUT_MOVE_PERFIL_SUCCESS',
  PUT_MOVE_PERFIL_ERROR: 'CONFIGURACOES/PUT_MOVE_PERFIL_ERROR',

  RESET: 'CONFIGURACOES_ATENDIMENTOS/RESET',
};

export const getAtendimentos = () => ({ type: Types.GET_ATENDIMENTOS });
export const getAtendimentosSuccess = (atendimentos) => ({ type: Types.GET_ATENDIMENTOS_SUCCESS, atendimentos });
export const getAtendimentosError = (error) => ({ type: Types.GET_ATENDIMENTOS_ERROR, error });

export const getAtendimento = (id) => ({ type: Types.GET_ATENDIMENTO, id });
export const getAtendimentoSuccess = (atendimento) => ({ type: Types.GET_ATENDIMENTO_SUCCESS, atendimento });
export const getAtendimentoError = (error) => ({ type: Types.GET_ATENDIMENTO_ERROR, error });

export const saveAtendimento = (atendimento) => ({ type: Types.SAVE_ATENDIMENTO, atendimento });
export const saveAtendimentoSuccess = (atendimento) => ({ type: Types.SAVE_ATENDIMENTO_SUCCESS, atendimento });
export const saveAtendimentoError = (error) => ({ type: Types.SAVE_ATENDIMENTO_ERROR, error });

export const putAtendimento = (atendimento) => ({ type: Types.PUT_ATENDIMENTO, atendimento });
export const putAtendimentoSuccess = (atendimento) => ({ type: Types.PUT_ATENDIMENTO_SUCCESS, atendimento });
export const putAtendimentoError = (error) => ({ type: Types.PUT_ATENDIMENTO_ERROR, error });

export const deleteAtendimento = (id) => ({ type: Types.DELETE_ATENDIMENTO, id });
export const deleteAtendimentoSuccess = (atendimento) => ({ type: Types.DELETE_ATENDIMENTO_SUCCESS, atendimento });
export const deleteAtendimentoError = (error) => ({ type: Types.DELETE_ATENDIMENTO_ERROR, error });

export const putMovePerfil = (idJornada, id) => ({ type: Types.PUT_MOVE_PERFIL, idJornada, id });
export const putMovePerfilSuccess = (atendimento) => ({ type: Types.PUT_MOVE_PERFIL_SUCCESS, atendimento });
export const putMovePerfilError = (error) => ({ type: Types.PUT_MOVE_PERFIL_ERROR, error });

export const reset = () => ({ type: Types.RESET });

function* fetchGetAtendimentos(action) {
  try {
    const atendimentos = yield call(getAtendimentosSdk);
    yield put(getAtendimentosSuccess(atendimentos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtendimentosError(err));
  }
}

function* fetchGetAtendimento(action) {
  try {
    const { id } = action;
    const atendimento = yield call(getAtendimentoSdk, id);
    yield put(getAtendimentoSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtendimentoError(err));
  }
}

function* fetchSaveAtendimento(action) {
  try {
    const atendimento = yield call(saveAtendimentoSdk, action.atendimento);
    yield put(saveAtendimentoSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveAtendimentoError(err));
  }
}

function* fetchPutAtendimento(action) {
  try { 
    const atendimento = yield call(putAtendimentosSdk, action.atendimento);
    yield put(putAtendimentoSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(putAtendimentoError(err));
  }
}

function* fetchPutMovePerfil(action) {
  try { 
    const atendimento = yield call(moveProfilesToJourneySdk, action.idJornada, action.id);
    yield put(putMovePerfilSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(putMovePerfilError(err));
  }
}

function* fetchDeleteAtendimento(action) {
  try { 
    const atendimento = yield call(deleteAtendimentoSdk, action.id);
    yield put(deleteAtendimentoSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteAtendimentoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ATENDIMENTOS, fetchGetAtendimentos),
  takeLatest(Types.GET_ATENDIMENTO, fetchGetAtendimento),
  takeLatest(Types.SAVE_ATENDIMENTO, fetchSaveAtendimento),
  takeLatest(Types.PUT_ATENDIMENTO, fetchPutAtendimento),
  takeLatest(Types.DELETE_ATENDIMENTO, fetchDeleteAtendimento),
  takeLatest(Types.PUT_MOVE_PERFIL, fetchPutMovePerfil),
];
  
const initialState = Map({
  atendimentos: List(),
  loadingAtendimentos: false,
  successAtendimentos: false,
  errorAtendimentos: false,

  atendimento: {},
  loadingAtendimento: false,
  successAtendimento: false,
  errorAtendimento: false,

  loadingSaveAtendimento: false,
  successSaveAtendimento: false,
  errorSaveAtendimento: false,

  loadingPutAtendimento: false,
  successPutAtendimento: false,
  errorPutAtendimento: false,

  loadingDeleteAtendimento: false,
  successDeleteAtendimento: false,
  errorDeleteAtendimento: false,

  loadingPutMovePerfil: false,
  successPutMovePerfil: false,
  errorPutMovePerfil: false,
});
  
const handleGetAtendimentos = (state, action) => {
  return state
    .set('loadingAtendimentos', true)
    .set('successAtendimentos', false)
    .set('errorAtendimentos', false);
};
  
const handleGetAtendimentosSuccess = (state, action) => {
  const { atendimentos } = action;
  
  return state
    .set('atendimentos', List(atendimentos))
    .set('loadingAtendimentos', false)
    .set('successAtendimentos', true)
    .set('errorAtendimentos', false);
};
  
const handleGetAtendimentosError = (state, action) => {
  return state
    .set('loadingAtendimentos', false)
    .set('successAtendimentos', false)
    .set('errorAtendimentos', action.error);
};

const handleGetAtendimento = (state, action) => {
  return state
    .set('loadingAtendimento', true)
    .set('successAtendimento', false)
    .set('errorAtendimento', false);
};
  
const handleGetAtendimentoSuccess = (state, action) => {
  const { atendimento } = action;

  return state
    .set('atendimento', atendimento)
    .set('loadingAtendimento', false)
    .set('successAtendimento', true)
    .set('errorAtendimento', false);
};
  
const handleGetAtendimentoError = (state, action) => {
  return state
    .set('loadingAtendimento', false)
    .set('successAtendimento', false)
    .set('errorAtendimento', action.error);
};

const handleSaveAtendimento = (state, action) => {
  return state
    .set('loadingSaveAtendimento', true)
    .set('successSaveAtendimento', false)
    .set('errorSaveAtendimento', false);
};
  
const handleSaveAtendimentoSuccess = (state, action) => {
  const { atendimento } = action;
  
  return state
    .set('atendimento', Map(atendimento))
    .set('loadingSaveAtendimento', false)
    .set('successSaveAtendimento', true)
    .set('errorSaveAtendimento', false);
};
  
const handleSaveAtendimentoError = (state, action) => {
  return state
    .set('loadingSaveAtendimento', false)
    .set('successSaveAtendimento', false)
    .set('errorSaveAtendimento', action.error);
};

const handlePutAtendimento = (state, action) => {
  return state
    .set('loadingPutAtendimento', true)
    .set('successPutAtendimento', false)
    .set('errorPutAtendimento', false);
};
  
const handlePutAtendimentoSuccess = (state, action) => {
  const { atendimento } = action;
  
  return state
    .set('atendimento', Map(atendimento))
    .set('loadingPutAtendimento', false)
    .set('successPutAtendimento', true)
    .set('errorPutAtendimento', false);
};
  
const handlePutAtendimentoError = (state, action) => {
  return state
    .set('loadingPutAtendimento', false)
    .set('successPutAtendimento', false)
    .set('errorPutAtendimento', action.error);
};

const handleDeleteAtendimento = (state, action) => {
  return state
    .set('loadingDeleteAtendimento', true)
    .set('successDeleteAtendimento', false)
    .set('errorDeleteAtendimento', false);
};
  
const handleDeleteAtendimentoSuccess = (state, action) => {  
  return state
    .set('loadingDeleteAtendimento', false)
    .set('successDeleteAtendimento', true)
    .set('errorDeleteAtendimento', false);
};
  
const handleDeleteAtendimentoError = (state, action) => {
  return state
    .set('loadingDeleteAtendimento', false)
    .set('successDeleteAtendimento', false)
    .set('errorDeleteAtendimento', action.error);
};

const handlePutMovePerfil = (state, action) => {
  return state
    .set('loadingPutMovePerfil', true)
    .set('successPutMovePerfil', false)
    .set('errorPutMovePerfil', false);
};
  
const handlePutMovePerfilSuccess = (state, action) => {  
  return state
    .set('loadingPutMovePerfil', false)
    .set('successPutMovePerfil', true)
    .set('errorPutMovePerfil', false);
};
  
const handlePutMovePerfilError = (state, action) => {
  return state
    .set('loadingPutMovePerfil', false)
    .set('successPutMovePerfil', false)
    .set('errorPutMovePerfil', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ATENDIMENTOS: return handleGetAtendimentos(state, action);
    case Types.GET_ATENDIMENTOS_SUCCESS: return handleGetAtendimentosSuccess(state, action);
    case Types.GET_ATENDIMENTOS_ERROR: return handleGetAtendimentosError(state, action);

    case Types.GET_ATENDIMENTO: return handleGetAtendimento(state, action);
    case Types.GET_ATENDIMENTO_SUCCESS: return handleGetAtendimentoSuccess(state, action);
    case Types.GET_ATENDIMENTO_ERROR: return handleGetAtendimentoError(state, action);

    case Types.SAVE_ATENDIMENTO: return handleSaveAtendimento(state, action);
    case Types.SAVE_ATENDIMENTO_SUCCESS: return handleSaveAtendimentoSuccess(state, action);
    case Types.SAVE_ATENDIMENTO_ERROR: return handleSaveAtendimentoError(state, action);

    case Types.PUT_ATENDIMENTO: return handlePutAtendimento(state, action);
    case Types.PUT_ATENDIMENTO_SUCCESS: return handlePutAtendimentoSuccess(state, action);
    case Types.PUT_ATENDIMENTO_ERROR: return handlePutAtendimentoError(state, action);

    case Types.DELETE_ATENDIMENTO: return handleDeleteAtendimento(state, action);
    case Types.DELETE_ATENDIMENTO_SUCCESS: return handleDeleteAtendimentoSuccess(state, action);
    case Types.DELETE_ATENDIMENTO_ERROR: return handleDeleteAtendimentoError(state, action);

    case Types.PUT_MOVE_PERFIL: return handlePutMovePerfil(state, action);
    case Types.PUT_MOVE_PERFIL_SUCCESS: return handlePutMovePerfilSuccess(state, action);
    case Types.PUT_MOVE_PERFIL_ERROR: return handlePutMovePerfilError(state, action);

    case Types.RESET: return initialState;
  
    default: return state;
  }
}
  