import React from 'react';
import VisualizacaoOpcoes from '../Visualizacao/Opcoes';

export default function HeaderExtra(mainPage, isFiltering, pai) {

  if (!mainPage && !isFiltering) {
    return <VisualizacaoOpcoes item={pai} />;
  }
  
  return null;
}