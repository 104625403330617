import styled from 'styled-components';
import { Select, Text, Column } from '@components-teammove';

export const InputView = styled(Column)`
  width: 290px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  ${({ theme, relationed })=> relationed && `
    border: 1px solid ${theme.actionColor};
  `};
`;

export const SelectStyle = styled(Select)`
  height: 56px;
  width: 238px !important;
`;

export const Typography = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ maxWidth }) => maxWidth && maxWidth};
`;