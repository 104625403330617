import styled, { createGlobalStyle } from 'styled-components';
import { Row } from '@components';
import { View, Form, Image } from '@components-teammove';

export const GlobalStyles = createGlobalStyle`
  main#main {
    position: relative;
    align-items: center;

    >:first-child {
      min-width: 100vw;
    }

    footer:last-child {
      position: absolute;
      bottom: 0;
    }
  }
`;

export const Content = styled(Row)`
  flex: 1;
  background: ${({ theme }) => theme.backgroundColor};
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  align-items: center;
  justify-content: center;
`;

export const LoginCard = styled(View)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 28px !important;
  border: none;
  text-align: center;
  height: fit-content;
  padding: 49px 64px;
  width: 542px;

  // still doesn't work on chrome (https://bugs.chromium.org/p/chromium/issues/detail?id=46543)
  input {
    :-webkit-autofill, 
    :-webkit-autofill:focus {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.darkPrimaryColor} inset !important;
      -webkit-text-fill-color: ${({ theme }) => theme.textColor};
      font-family: 'Open Sans';
    }
  }
`;

export const ButtonItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
`;

export const FormContent = styled(View)`
  gap: 42px;
`;

export const LogoImage = styled(Image)`
  max-width: 500px;
  max-height: 300px;
  object-fit: contain;
  user-select: none;
  align-self: center;
  margin-bottom: 1.5rem;
`;

export const AtentionCapsLock = styled(View)`
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
  font-size: 12px;

`;