import React from 'react';

import FirstLevelItem from './FirstLevelItem';
import InnerLevelItem from './InnerLevelItem';

export default function Item({ item, usuario, isFiltering, firstLevel = false, categoriaPai }) {
  if (firstLevel) {
    return <FirstLevelItem item={ item }/> ;
  }
  return <InnerLevelItem item={item} usuario={usuario} isFiltering={isFiltering} categoriaPai={categoriaPai} />;
}