import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import { BugsnagProvider } from '@utils';
import { PDFWorker } from '@components';

import Store from './store';
import ptBR from 'antd/es/locale/pt_BR';

import Routes from './routes';
import NotificationService from '@app/NotificationService';
import { getTheme, applyTheme } from '@app/Theme';

const Main = () => {
  useEffect(() => {
    applyTheme(getTheme());
  }, []);

  return (
    <BugsnagProvider>
      <ConfigProvider locale={ptBR}>
        <ReduxProvider store={Store}>
          <PDFWorker>
            <NotificationService />
            <ToastContainer/>
            <Routes />
          </PDFWorker>
        </ReduxProvider>
      </ConfigProvider>
    </BugsnagProvider>
  );
};

export default Main;
