import { moment } from '@utils';

export const getDateBetweenTwoDates = (date1, date2) => {
  const differenceBetween = Math.abs(moment(date1).diff(date2));
  const middleDay = moment(date1).add(differenceBetween / 2, 'ms');
  return moment(middleDay).toDate();
};

export const formatActivitiesToEvents = (activities, filtros) => {
  const formattedActivities = activities.map(({ id, companyAddress, companyCity, title, companyName, companyLongName, start, end, typeIcon, typeColor, status, companyState }) => {
    const allDay = (start.substring(8).includes('00:00:00') && end.substring(8).includes('00:00:00')) || Math.abs(moment(start).diff(moment(end), 'hours')) >= 23;
    return {
      typeIcon,
      typeColor,
      id,
      topLeftInfo: title,
      topRightInfo: `${companyAddress} ${companyCity}`,
      subTitle: `${companyAddress} ${companyCity}`,
      fullName: companyLongName,
      title: companyName,
      canceled: status === 'EXCLUIDA',
      finished: status === 'CONCLUIDA',
      allDay,
      start: new Date(start),
      end: new Date(end),
      address: `${companyAddress || ''} ${companyCity || ''} - ${companyState || ''}`,
    };
  });

  if (filtros?.period) {
    return formattedActivities.filter(({ start }) => (filtros && filtros.period) ? moment(start).isBetween(filtros.period[0], filtros.period[1]) : true);
  } else {
    return formattedActivities;
  }
};

export const getLastSunday = (date) => {
  const day = moment(date);
  switch (day.day()) {
    case 0:
      return day;
    case 1:
      return day.subtract(1, 'days');
    case 2:
      return day.subtract(2, 'days');
    case 3:
      return day.subtract(3, 'days');
    case 4:
      return day.subtract(4, 'days');
    case 5:
      return day.subtract(5, 'days');
    default:
      return day.subtract(6, 'days'); 
  }
};

export const getNextSaturday = (date) => {
  const day = moment(date);
  switch (day.day()) {
    case 0:
      return day.add(6, 'days');
    case 1:
      return day.add(5, 'days');
    case 2:
      return day.add(4, 'days');
    case 3:
      return day.add(3, 'days');
    case 4:
      return day.add(2, 'days');
    case 5:
      return day.add(1, 'days');
    default:
      return day; 
  }
};