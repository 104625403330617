import React from 'react';
import { BreadcrumbRow } from './styles';

const StyledBreadcrumb = ({ items }) => (
  <BreadcrumbRow>
    {items.map((item, index) => (
      <>
        <a  key={item.path} href={item.path} style={item.current && { pointerEvents: 'none' }}>{item.name}</a>
        {index !== items.length - 1 && <span>&gt;</span>}
      </>
    ))}
  </BreadcrumbRow>
);

export default StyledBreadcrumb;