import styled from 'styled-components';
import { Tag } from '@components';
import { Image, Text, View } from '@components-teammove';

export const LabelThemes = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.theme.textColor} !important;
`;

export const ContentLabelThemes = styled(View)`
  padding: 15px 10px;
`;

export const Alias = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.textColor} !important;
  flex: 1;
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const TagLabel = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  font-weight: 600;
  font-size: 12px;
`;

export const ContentCover = styled(View)`
  width: 15rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.darkHighlightColor};
  background: ${(props) => props.background}
`;

export const TagInUse = styled(Tag)`
 width: 75px;
 text-align: center;
`;

export const Logo = styled(Image)`
  width: 160px;
  height: 48px;
  object-fit: contain;
  user-select: none;
`;