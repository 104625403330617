import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, View, Column, Button, Icon } from '@components-teammove';

import { getCategorias, orderCategoria } from '@ducks/configuracoesChamados';
import { mountCategorias } from './rules';
import { useHistory } from 'react-router-dom';

export default function Categorias() {
  const dispatch = useDispatch();
  const history = useHistory();

  const categorias = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('categorias').sortBy((cat) => cat.ordem).toList().toArray());
  const loadingCategorias = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('loadingCategorias'));

  const loadingOrderCategorias = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('loadingOrderCategorias'));
  
  useEffect(() => {
    dispatch(getCategorias());
  }, []);

  const onDrop = (info) => {
    const { dragNode, node, dropToGap } = info;
    
    const dropPos = node.pos.split('-');
    const positionRelative = info.dropPosition - parseInt(dropPos[dropPos.length - 1]);
    const ordemNode = node.ordem;
    
    const newPositionArray = () => {
      const verifyPosition = dragNode.ordem < node.ordem;
      if(verifyPosition) {
        if(positionRelative === 1) {
          return ordemNode;
        }else{
          return ordemNode - 1;
        }
      }else {
        if(positionRelative === 1) {
          return ordemNode + 1;
        }else{
          return ordemNode;
        }
      }
    };
    
    if (!dropToGap) {
      if (node.tipo === 'GRUPO') {
        dispatch(orderCategoria(dragNode.id, node.id, newPositionArray(), true));
      }
    } else {
      dispatch(orderCategoria(dragNode.id, node.idCategoria || null, newPositionArray(), false));
    }
  };
  
  const handleNovo = () => {
    history.push('/configuracoes/chamados/categoria', { idCategoria: null, tipo: 'GRUPO', disabledTipo: true });
  };
  
  return (loadingCategorias) || (categorias.length > 0) || loadingOrderCategorias ? (
    <Column gap='18px'>
      <Tree
        name="configuracoes-categorias"
        treeData={mountCategorias(categorias)}
        loading={loadingCategorias}
        onDrop={onDrop}
        draggable 
      />
      <Button 
        type='secondary'
        size='small'
        rounder
        onClick={handleNovo}
      >
        + Nova Categoria
      </Button>
    </Column>
  ) : (
    <View alignItems="center">
      <Button type="primary" icon={<Icon.PlusOutlined />} onClick={handleNovo}>Adicionar Novo</Button>
    </View>
  );
}
