export const formatCategoriesWithProducts = (categoriesWithProducts) => {
  return categoriesWithProducts
    .map((category) => {
      return {
        ...category,
        children: category.families.map((family) => {
          return ({ ...family, children: family.products });
        })
      };
    });
};

export const getFilteredCategoriesWithProducts = (term, categoriesWithProducts) => term ? categoriesWithProducts.reduce((categories, category) => {    
  const families = category.families.reduce((families, family) => {
    const products = family.products.filter((product) => product.name.trim().toLowerCase().includes(term.toLowerCase()));
    return products.length > 0 || family.name.trim().toLowerCase().includes(term.toLowerCase()) ? [...families, { ...family, products }] : families;
  }, []);

  return families.length > 0 || category.name.trim().toLowerCase().includes(term.toLowerCase()) ? [...categories, { ...category, families }] : categories;
}, []) : categoriesWithProducts;