import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body } from '@components';
import { PageHeader, Breadcrumb, Button, Filter, ContentHeader, ViewControl, SearchBar, Icon } from '@components-teammove';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getClusters } from '@ducks/configuracoesUnidadesClusters';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { getUnidades, FILTERS_KEY, reset, setBusca } from '@ducks/unidades';
import ListaUnidades from './Lista';
import MapaUnidades from './Mapa';
import { InfoUsuario, Filtros, TelasNomenclaturas } from '@utils';
import { initialFilters } from './rules';
import { debounce } from 'debounce';
import EditarUnidade from './Cadastro';
import EditarMultiplas from './EditarMultiplas';

export default function UnidadesLista() {
  const history = useHistory();
  const [search, setSearch] = useState(sessionStorage.getItem('searchGestaoCarteira') || '');
  const [listingBy, setListingBy] = useState((localStorage.getItem('companies_view_mode') !== 'Clusters' && localStorage.getItem('companies_view_mode')) || 'Agrupadores');
  const [filtros, setFiltros] = useState(Filtros.get(FILTERS_KEY));
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [orderBy, setOrderBy] = useState();
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleMultipleRegister, setVisibleMultipleRegister] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const dispatch = useDispatch();
 
  const theme = useSelector(({ app }) => app.get('theme'));

  const unidades = useSelector(({ unidades }) => unidades.get('unidades'));
  const loading = useSelector(({ unidades }) => unidades.get('loadingUnidades'));
  const success = useSelector(({ unidades }) => unidades.get('successUnidades'));
  const totalCompanies = useSelector(({ unidades }) => unidades.get('total'));

  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));
  const successAgrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successAgrupadores'));
  const clusters = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('clusters'));
  const successClusters = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successClusters'));
  const tiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const successTiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successTiposUnidades'));
  
  const clustersByGroup = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('clustersByGroup'));
  const successClustersByGroup = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('successClustersByGroup'));

  useEffect(() => {
    setSelecting(false);
    handleSearchChangingNewFilters(Filtros.get(FILTERS_KEY));
    dispatch(getAgrupadores());
    dispatch(getClusters());
    dispatch(getTiposUnidades());

    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if ((successAgrupadores && successClusters && successTiposUnidades) || successClustersByGroup) {
      setFilters(filters.map((filter) => {
        if (filter.name === 'agrupador') {
          return { ...filter, options: agrupadores.map((agrupador) => ({ label: agrupador.description, value: agrupador.id })) };
        }
        if(filter.name === 'grupoCluster') {
          return { ...filter,
            options:clusters.map((cluster) => ({ label: cluster.nome, value: cluster.id })),
          };
        }
       
        if (filter.name === 'cluster') {
          return { ...filter, 
            options: [...clustersByGroup.map((cluster) => ({ label: cluster.name, value: cluster.id })),
              { label: 'Sem Cluster', value: -1 } 
            ]
          };
        }
        if (filter.name === 'tipoUnidade') {
          filter.label = `Tipo de ${companyNomenclature.nomenclatura}`;
          return { ...filter, options: tiposUnidades.map((tipoUnidade) => ({ label: tipoUnidade.name, value: tipoUnidade.id })) };
        }
        return { ...filter };
      }));
    }
  }, [successAgrupadores, successClusters, successTiposUnidades, successClustersByGroup]);

  useEffect(() => {
    if (success) {
      handleGetUnidadesDispatch(50, orderBy);
    }
  }, [orderBy]);

  useEffect(() => {
    if (success && !search) {
      (debounce(() => {
        if (listingBy === 'Agrupadores') {
          handleGetUnidadesDispatch(50, orderBy, filtros, search);
        } else {
          handleGetUnidadesDispatch(null, null, filtros, search);
        }
      }, 500))();
    }
  }, [search]);

  const handleCancelSelect = () => {
    setSelecting(false);
    history.push('#');
  };

  const handleListingByChange = (listingByToSet) => {
    if (selecting) {
      handleCancelSelect();
    }
    if (listingBy !== 'Agrupadores' && listingByToSet === 'Agrupadores') {
      handleGetUnidadesDispatch(50, orderBy, filtros, search);
    }
    setListingBy(listingByToSet);
    localStorage.setItem('companies_view_mode', listingByToSet);
  };

  const handleSimpleSearch = (searchParam = search) => {
    searchParam = searchParam?.trim();
    dispatch(setBusca(searchParam));
    setSearch(searchParam);
    sessionStorage.setItem('searchGestaoCarteira', searchParam);
    if (listingBy === 'Agrupadores') {
      handleGetUnidadesDispatch(50, orderBy, filtros, searchParam);
    } else {
      handleGetUnidadesDispatch(null, null, filtros, searchParam);
    }
  };

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
    return handleGetUnidadesDispatch(listingBy === 'Mapa' ? null : 50, orderBy, filtros, search);
  };

  const handleSearchChangingNewFilters = (filtros) => {
    
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
  
    if (filtros) {
      const { agrupador, tipoUnidade, descFiltros } = filtros;
      Filtros.set('DASH', {
        agrupadorId: agrupador || undefined,
        companyTypeId: tipoUnidade || undefined,
        descFiltros: {
          agrupadorId: descFiltros?.agrupador || undefined,
          companyTypeId: descFiltros?.tipoUnidade || undefined,
        },
      });
    }
   
    setFiltros(filtros);
    handleSearch(filtros);
  };

  const handleGetUnidadesDispatch = (limit, orderByParam = orderBy, filtrosParam = filtros, searchParam = search) => {
    dispatch(getUnidades(filtrosParam, limit, searchParam, orderByParam, listingBy === 'Agrupadores'));
  };

  const handleUnityClick = (unidade) => {
    history.push(`/unidades/${unidade.id}`);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={companyNomenclature.plural}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/unidades', name: `${companyNomenclature.plural}`, current: true },
                { path: '/unidades', name: `Lista d${companyNomenclature.artigo}s ${(companyNomenclature.plural).toLowerCase()}`, current: true }
              ]}
            />
          )}
          extra={[
            !selecting && <Button size='small' context='header' key="1" type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setFiltersVisible(true)}>Filtros</Button>,
            InfoUsuario.perm('unidadesEditarMultiplas') && listingBy === 'Agrupadores' && <Button size='small' context='header' key='2' type='secondary' onClick={() => selecting ? handleCancelSelect() : setSelecting(true)}>{selecting ? 'Cancelar' : `Selecionar ${companyNomenclature.plural}`}</Button>,
            (selecting ? InfoUsuario.perm('unidadesEditarMultiplas') : InfoUsuario.perm('unidadesCadastro')) && <Button size='small' context='header' key='3' type='primary' onClick={selecting ? () => setVisibleMultipleRegister(true) : () => setVisibleRegister(true)}>{selecting ? `Mover ${companyNomenclature.plural}` : 'Novo'}</Button>
          ]}
        >
          <SearchBar
            placeholder="Pesquisar"
            initialValue={search}
            onSearch={handleSimpleSearch}
            activeFiltersProps={{ filtros, onSearch: handleSearchChangingNewFilters, filters, disabled: selecting }}
            disabled={selecting}
          />
          <ContentHeader
            title={listingBy}
            extra={(
              <ViewControl
                options={[
                  { icon: <Icon.TeamMove type='cluster-outlined' />, name: 'Clusters' },
                  { icon: <Icon.MDI type='format-list-bulleted' />, name: 'Agrupadores' },
                  { icon: <Icon.Feather type='map-pin' />, name: 'Mapa' }
                ]}
                current={listingBy}
                onChange={handleListingByChange}
              />
            )}
          />
          {listingBy === 'Agrupadores' &&
            <ListaUnidades
              unidades={unidades}
              handleDispatch={handleGetUnidadesDispatch}
              total={totalCompanies}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              isFiltering={filtros && Object.keys(filtros).length > 0 && filtros.descFiltros}
              selecting={selecting}
              handleUnityClick={handleUnityClick}
              loading={loading}
              status='active'
              title={companyNomenclature.plural || 'Unidades'}
            />
          }
          {listingBy === 'Clusters' &&
            history.push('/gestaoCarteira')
          }
          {listingBy === 'Mapa' &&
            <MapaUnidades
              unidades={unidades.filter((unity) => unity.active)}
              handleDispatch={handleGetUnidadesDispatch}
              // selectedUnidades={selectedUnidades} 
              // setSelectedUnidades={setSelectedUnidades} 
              // selecting={selecting}
              handleUnityClick={handleUnityClick}
              loading={loading}
              visibleRegister={visibleRegister}
            />
          }
        </PageHeader>
        {filtersVisible && (
          <Filter
            visible={filtersVisible}
            onClose={() => setFiltersVisible(false)}
            filtros={filtros}
            onSearch={handleSearchChangingNewFilters}
            filters={filters}
          />
        )}

        {visibleRegister && (
          <EditarUnidade
            visible={visibleRegister}
            onCancel={() => setVisibleRegister(false)}
            title={companyNomenclature.nomenclatura || 'Unidade'}
          />
        )}

        {visibleMultipleRegister && (
          <EditarMultiplas
            visible={visibleMultipleRegister}
            onCancel={() => setVisibleMultipleRegister(false)}
            onSuccess={() => {
              history.push('#');
              handleSimpleSearch();
              setVisibleMultipleRegister(false);
              setSelecting(false);
            }}
            title={companyNomenclature.plural || 'Unidades'}
          />
        )}
      </Body>
    </App>
  );
}