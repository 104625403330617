import React from 'react';
import { DarkFormItem } from './styles';
import { Row, Tooltip } from '@components-teammove';

const StyledFormItem = ({ tooltip, label, children, ...props }) => (
  <DarkFormItem
    label={tooltip ? (
      <Row align='center' gap='4px'>
        <Tooltip placement='right' overlayStyle title={tooltip}>
          {label}
        </Tooltip>
      </Row>
    ) : label}
    {...props}
  >
    {children}
  </DarkFormItem>
);

export default StyledFormItem;