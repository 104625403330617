import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Button, Filter, ActiveFilters, Icon } from '@components-teammove';

import { getAudiencia, FILTERS_KEY } from '@ducks/wikisAudiencia';
import { Filtros } from '@utils';
import TableCategoria from './TableCategoria';
import { ContentTitle } from '../styles';

export default function WikisConteudos() {
  const dispatch = useDispatch();
  const [visibleFilters, setVisibleFilters] = useState(false);

  const theme = useSelector(({ app }) => app.get('theme'));
  
  const audiencias = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('audiencias').toArray());

  const filters = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('filters'));
  
  const filtros = Filtros.get(FILTERS_KEY);

  const handleSearch = (filtros) => {
    return dispatch(getAudiencia(undefined, filtros));
  };

  useEffect(() => {
    handleSearch(filtros);
  }, []);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Audiência / Dashboard / Categorias"
          onBack={() => window.history.back()}
          extra={[
            <Button key="1" size='small' context='header' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>
              Filtros
            </Button>,
          ]}
        >
          <ContentTitle>Ranking de Categorias</ContentTitle>
          <ActiveFilters filtros={filtros} onSearch={handleSearch} filters={filters}/>
          <TableCategoria data={audiencias} />
        </PageHeader>

        <Filter visible={visibleFilters} onClose={() => setVisibleFilters(false)} filtros={filtros} onSearch={handleSearch} filters={filters}/>
      </Body>
    </App>
  );
}
