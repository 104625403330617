import styled from 'styled-components';
import { Column, Button, Empty } from '@components-teammove';

export const Container = styled(Column)`
  background-color: ${({ theme })=> theme.darkPrimaryColor};
  border-radius: 18px;
  padding: 20px 20px 17px 20px;
  width: 40%;
`;

export const ButtonStyle = styled(Button)`
  &.ant-btn {
    :hover {
      background-color: ${({ theme })=> theme.darkHighlightColor} !important;
    }
    :focus{
      background-color: ${({ theme })=> theme.darkSecondaryColor} !important;
    }
 } 
`;  

export const VerticalLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme })=> theme.darkHighlightColor};
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;