import styled, { createGlobalStyle, css } from 'styled-components';
import { Modal } from 'antd';
import Text from '../Text';
import View from '../View';
import Icon from '../Icon';
import { darken } from 'polished';

export const CloseIcon = styled(Icon.MDI)`
  color: ${(props) => props.theme.textColor} !important;
  font-size: 18px;
  margin-left: auto;

  :hover {
    cursor: pointer;
  }
`;

export const BackIcon = styled(Icon.Feather)`
  color: ${(props) => props.theme.textColor} !important;
  font-size: 18px;

  :hover {
    cursor: pointer;
  }
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  gap: 14px;
  align-items: center;
  >:not(${CloseIcon}):not(${BackIcon}) {
    flex: 1;
  }
  ${CloseIcon}, ${BackIcon} {
    flex: none;
  }
`;

export const DarkModal = styled(Modal)`
  .ant-modal.modal-top {
    top: 20px;
  }
  .ant-modal.no-buttons .ant-modal-confirm-btns {
    display: none;
  }
  ${(props) =>
  props.top &&
      css`
        top: 20px;
      `}
  --padding-horizontal: ${({ type }) => type === 'form' || type === 'message' ? '42px' : '24px'};
  --padding-top-header: ${({ type }) => type === 'form' ? '44px' : type === 'message' ? '40px' : '36px'};
  --padding-bottom-header: ${({ type }) => type === 'form' ? '20px' : '8px'};
  --padding-top-footer: ${({ type }) => type === 'form' ? '20px' : '30px'};
  --padding-side-footer: ${({ type }) => 'var(--padding-horizontal)'};
  --padding-bottom-footer: var(--padding-top-footer);
  --padding-top-body: ${({ contrastHeader }) => contrastHeader ? '20px' : '0'};
  --width: ${({ width }) => width + 'px'};

  &.ant-modal {
    border-radius: 28px;
    ${({ width }) => width && `
      @media screen and (max-width: ${width + 'px'}) {
        width: 90% !important;
      }
    `}

    .ant-modal-content {
      height: 100%;
      background-color: ${(props) => darken(0.0425, props.theme.darkPrimaryColor)};
      border-radius: 28px;
      padding-top: ${({ hasHeader }) => hasHeader ? '0' : 'var(--padding-top-header)'};
      padding-bottom: ${({ hasFooter }) => hasFooter ? '0' : '25px'};
    }

    .ant-modal-header {
      position: relative;
      ${({ stickyHeader }) => stickyHeader && `
        position: sticky;
      `}
      z-index: 1;
      top: 0;
      background-color: ${({ contrastHeader, theme }) => contrastHeader ? theme.darkSecondaryColor : 'inherit'};
      border: none;
      border-radius: 28px 28px 0 0;
      padding: var(--padding-top-header) var(--padding-horizontal) var(--padding-bottom-header);

      .ant-modal-title {
        display: flex;
        flex-direction: column;
        ${({ spaceBetweenTitleAndHeader }) => spaceBetweenTitleAndHeader && `
          gap: 14px;
        `}
        align-items: stretch;
        font-size: 32px !important;
        font-weight: 700 !important;
        color: ${(props) => props.theme.textColor} !important;
        line-height: 1.23;

        >:last-child {
          width: 100%;
        }
      }
    }

    .ant-modal-body {
      padding-top: var(--padding-top-body);
      padding-left: var(--padding-horizontal);
      padding-right: var(--padding-horizontal);
      ${({ hasFooter }) => hasFooter && `
        padding-bottom: 0;
      `}
      max-height: 62vh;
      overflow-y: scroll;
      position: relative;

      ${CloseIcon} {
        position: absolute;
        top: 0;
        right: 24px;
      }
      ${BackIcon} {
        position: absolute;
        top: 0;
        left: 24px;
      }
    }

    .ant-modal-footer {
      border: none;
      ${({ type, theme }) => type !== 'message' && `
        border-top: 2px solid ${theme.darkSecondaryColor};
      `}
      border-radius: 0 0 28px 28px;
      bottom: 0;
      background-color: ${({ contrastFooter, theme }) => contrastFooter ? theme.darkSecondaryColor : 'inherit'};
      padding: ${({ paddingFooter }) => paddingFooter ? paddingFooter : 'var(--padding-top-footer) var(--padding-side-footer) var(--padding-bottom-footer)'};
    }

    @media screen and (max-height: 800px) {
      .ant-modal-body {
        max-height: 55vh;
      }
    }
  }
`;

export const Row = styled(View)`
  flex-direction: row;
  gap: 25px;
`;

export const ModalTitleContainer = styled(View)`
  background-color: ${({ contrastHeader, theme }) => contrastHeader ? theme.darkSecondaryColor : 'inherit'};
  border: none;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  overflow: hidden;
`;

export const ModalTitle = styled(View)`
  display: flex;
  flex: 1;
  align-items: start;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: ${(props) => props.theme.textColor} !important;
  line-height: 1.23;
  max-width: calc(var(--width) - (var(--padding-horizontal) * 2));
`;

export const GeneralBodyText = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    line-height: 27.24px;
  }
`;

export const CollapseDownButton = styled(Icon.DownOutlined)`
  position: absolute;
  bottom: calc(16px * -1);
  z-index: 1;
  align-self: flex-end;
  svg {
    background-color: ${(props) => props.theme.darkSecondaryColor};
    padding: 8px;
    border-radius: 50%;
  }
`;

export const CollapseUpButton = styled(Icon.UpOutlined)`
  position: absolute;
  bottom: calc(16px * -1);
  z-index: 1;
  align-self: flex-end;
  svg {
    background-color: ${(props) => props.theme.darkSecondaryColor};
    padding: 8px;
    border-radius: 50%;
  }
`;

export const GlobalStyles = createGlobalStyle`
  --padding-horizontal: 24px;
  --padding-top-header: 36px;
  --padding-bottom-header: 8px;
  --padding-top-footer: 20px;
  --padding-side-footer: 28px;
  --padding-bottom-footer: 28px;
  --padding-top-body: 0;

  .ant-modal {
    border-radius: 28px;
    ${({ width }) => width && `
      @media screen and (max-width: ${width + 'px'}) {
        width: 90% !important;
      }
    `}

    .ant-modal-content {
      height: 100%;
      background-color: ${(props) => darken(0.0425, props.theme.darkPrimaryColor)};
      border-radius: 28px;
      padding-top: var(--padding-top-header);
      padding-bottom: 25px;
    }

    .ant-modal-header {
      position: relative;
      z-index: 1;
      top: 0;
      background-color: inherit;
      border: none;
      border-radius: 28px 28px 0 0;
      padding: var(--padding-top-header) var(--padding-horizontal) var(--padding-bottom-header);

      .ant-modal-title {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        font-size: 32px !important;
        font-weight: 700 !important;
        color: ${(props) => props.theme.textColor} !important;
        line-height: 1.23;

        >:last-child {
          width: 100%;
        }
      }
    }

    .ant-modal-body {
      padding-top: var(--padding-top-body);
      padding-left: var(--padding-horizontal);
      padding-right: var(--padding-horizontal);
      max-height: 62vh;
      overflow-y: scroll;
      position: relative;

      ::-webkit-scrollbar {
        margin-right: 16px;
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.darkPrimaryColor};
        border-right: 4px solid transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.darkSecondaryColor};
        border-radius: 45px;
        border-right: 4px solid transparent;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.darkSecondaryColor};
        margin-right: 16px;
      }

      ${CloseIcon} {
        position: absolute;
        top: 0;
        right: 24px;
      }
      ${BackIcon} {
        position: absolute;
        top: 0;
        left: 24px;
      }
    }

    .ant-modal-footer {
      border: none;
      border-radius: 0 0 28px 28px;
      bottom: 0;
      background-color: inherit;
      padding: var(--padding-top-footer) var(--padding-side-footer) var(--padding-bottom-footer);
    }
  }
`;