import React, { useEffect, useState } from 'react';
import { Skeleton, GoogleMap } from '@components-teammove';
import { CloseButton, CompaniesView, CompanySkeleton, Container, ContentItem, ContentMap, LegendContainer, LegendContent, LegendHeader, SubText, TitleText } from './styles';
import { TelasNomenclaturas } from '@utils';

export default function MapaUnidades({ unidades, selectedUnidades, setSelectedUnidades, selecting, handleUnityClick, handleDispatch, loading, visibleRegister }) {
  const [markers, setMarkers] = useState();
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(3);
  const [companiesWithoutLatLng, setCompaniesWithoutLatLng] = useState([]);
  const [legendVisible, setLegendVisible] = useState(true);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    handleDispatch();
  }, []);

  useEffect(() => {
    if (!loading && !visibleRegister) {
      const companiesWithoutLatLng = [];
      const markers = unidades.map((unity) => {
        return {
          position: { lat: parseFloat(unity.latitude), lng: parseFloat(unity.longitude) },
          ...unity,
          infoWindowClickHandler: () => handleUnityClick(unity),
          key: unity.id,
        };
      })
        .filter((company) => {
          const { position } = company;
          if (!(position.lat || position.lng)) {
            companiesWithoutLatLng.push(company);
          }
          return position.lat && position.lng;
        });

      setMarkers(markers);
      setCompaniesWithoutLatLng(companiesWithoutLatLng);

      const markersReduce = markers.reduce((state, unity, index, arr) => {
        if (index === arr.length - 1) return { lat: state.lat / index + 1, lng: state.lng / index + 1 };
        return { lat: state.lat + unity.position.lat, lng: state.lng + unity.position.lng };
      }, { lat: 0, lng: 0 });

      const center = isNaN(markersReduce.lat) || isNaN(markersReduce.lng) ? { lat: 0, lng: 0 } : markersReduce;
      setCenter(center);
      setZoom(3);
    }
  }, [unidades]);

  // const handleSeeLocationClick = (unity) => {
  //   setMarkers([{
  //     position: { lat: parseFloat(unity.latitude), lng: parseFloat(unity.longitude) }, 
  //     ...unity,
  //     infoWindowClickHandler: () => handleUnityClick(unity),
  //     key: unity.id, 
  //   }]);
  //   setCenter({ lat: parseFloat(unity.latitude) || 0, lng: parseFloat(unity.longitude) || 0 });
  //   setFocusingCompany(true);
  // };

  // const handleSeeAllClick = () => {
  //   setMarkers(allMarkers);
  //   setCenter(allCenter);
  //   setFocusingCompany(false);
  // };

  return loading ? (
    <Container>
      <CompaniesView>
        <CompanySkeleton />
        <CompanySkeleton />
        <CompanySkeleton />
        <CompanySkeleton />
      </CompaniesView>
      <Skeleton.Map />
    </Container>
  ) : (
    <Container>
      {/*Listagem de unidade com localização - retirado por solicitação do Biava 
      {unidades.length > 0 && (
        <Collapsible 
          defaultCollapsed={false} 
          collapsedView={(
            <CompaniesView>
              {unidades.map((company) => (
                <Company key={company.id} collapsed>
                  <CompanyIdentifier collapsed>{company.identifier}</CompanyIdentifier>
                </Company>
              ))}
            </CompaniesView>
          )}
        >
          <CompaniesView>
            {focusingCompany && (
              <SeeAllButton type='secondary' onClick={handleSeeAllClick}>Ver todas {companyNomenclature.artigo === 'a' ? 'as' : 'os'} {companyNomenclature.plural}</SeeAllButton>
            )}
            {unidades.map((company) => (
              <Company key={company.id}>
                <View>
                  <CompanyIdentifier>{company.identifier}</CompanyIdentifier>
                  <CompanyName>{company.name}</CompanyName>
                  <CompanyAddress>{company.city} - {company.state}</CompanyAddress>
                </View>
                <Button type='highlight' onClick={() => handleSeeLocationClick(company)}>Ver localização</Button>
              </Company>
            ))}
          </CompaniesView>
        </Collapsible>
      )} */}
      <ContentMap>
        <GoogleMap
          center={center} zoom={zoom} mapStyles={{ height: '100%', width: '100%', borderRadius: '12px' }} markers={markers}
        />
      </ContentMap>
      {legendVisible && companiesWithoutLatLng.length > 0 && (
        <LegendContainer id='legend'>
          <CloseButton onClick={() => setLegendVisible(false)} />
          <LegendHeader>
            <TitleText>{companyNomenclature.plural} sem localização cadastrada</TitleText>
            <SubText>Verifique as informações das mesmas para que apareçam no mapa</SubText>
          </LegendHeader>
          <LegendContent>
            {companiesWithoutLatLng.map((company) => (
              <ContentItem onClick={() => handleUnityClick(company)} key={company.id}>{company.identifier}</ContentItem>
            ))}
          </LegendContent>
        </LegendContainer>
      )}
    </Container>
  );

}