import { call, put, takeLatest } from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { Notification } from '@utils';
import { getAllTrails as getAllTrailsSdk, saveAllTrails as saveAllTrailsSdk } from '@sdk/Wikis/EAD/Trilhas';

//Action types
export const Types = {
  GET_TRILHAS: 'WIKI_EAD/GET_TRILHAS',
  GET_TRILHAS_SUCCESS: 'WIKI_EAD/GET_TRILHAS_SUCCESS',
  GET_TRILHAS_ERROR: 'WIKI_EAD/GET_TRILHAS_ERROR',

  UPDATE_ORDER_TRILHAS: 'WIKI_EAD/UPDATE_ORDER_TRILHAS',
  UPDATE_ORDER_TRILHAS_SUCCESS: 'WIKI_EAD/UPDATE_ORDER_TRILHAS_SUCCESS',
  UPDATE_ORDER_TRILHAS_ERROR: 'WIKI_EAD/UPDATE_ORDER_TRILHAS_ERROR',
};

//Action Creators
export const getTrails = () => ({ type: Types.GET_TRILHAS });
export const getTrailsSuccess = (data) => ({ type: Types.GET_TRILHAS_SUCCESS, data });
export const getTrailsError = (error) => ({ type: Types.GET_TRILHAS_ERROR, error });

export const updateOrderTrails = (trails) => ({ type: Types.UPDATE_ORDER_TRILHAS,  trails });
export const updateOrderTrailsSuccess = (trails) => ({ type: Types.UPDATE_ORDER_TRILHAS_SUCCESS, trails });
export const updateOrderTrailsError = (error) => ({ type: Types.UPDATE_ORDER_TRILHAS_ERROR, error });

//Saga
function* getTrailsSaga() {
  try {
    const data = yield call(getAllTrailsSdk);
    yield put(getTrailsSuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTrailsError(err));
  }
}

function* updateOrderTrailsSaga(action) {
  try {
    const { trails } = action;
    yield put(updateOrderTrailsSuccess(trails));
    yield call(saveAllTrailsSdk, trails);
  } catch (err) {
    Notification.error(err.message);
    yield put(updateOrderTrailsError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TRILHAS, getTrailsSaga),
  takeLatest(Types.UPDATE_ORDER_TRILHAS, updateOrderTrailsSaga)
];

// Reducer
const initialState = Map({
  trails: List(),
  loading: false,
  success: false,
  error: false,

  loadingUpdateOrder: false,
  successUpdateOrder: false,
  errorUpdateOrder: false,
});

const handleGetTrails = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetTrailsSuccess = (state, action) => {
  return state
    .set('trails', List(action.data))
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetTrailsError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleUpdateOrderTrails = (state, action) => {
  return state
    .set('loadingUpdateOrder', true)
    .set('successUpdateOrder', false)
    .set('errorUpdateOrder', false);
};

const handleUpdateOrderTrailsSuccess = (state, action) => {
  return state
    .set('trails', List(action.trails))
    .set('loadingUpdateOrder', false)
    .set('successUpdateOrder', true)
    .set('errorUpdateOrder', false);
};

const handleUpdateOrderTrailsError = (state, action) => {
  return state
    .set('loadingUpdateOrder', false)
    .set('successUpdateOrder', false)
    .set('errorUpdateOrder', action.error);
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case Types.GET_TRILHAS: return handleGetTrails(state, action);
    case Types.GET_TRILHAS_SUCCESS: return handleGetTrailsSuccess(state, action);
    case Types.GET_TRILHAS_ERROR: return handleGetTrailsError(state, action);

    case Types.UPDATE_ORDER_TRILHAS: return handleUpdateOrderTrails(state, action);
    case Types.UPDATE_ORDER_TRILHAS_SUCCESS: return handleUpdateOrderTrailsSuccess(state, action);
    case Types.UPDATE_ORDER_TRILHAS_ERROR: return handleUpdateOrderTrailsError(state, action);

    default: return state;
  }
}