import { post } from '../api';

export const createEvent = (tokenObj, event) => {
  return post('/api/azure/event', event, {
    'Authorization': `${tokenObj.tokenType} ${tokenObj.accessToken}`,
  });
};

export const getToken = (code) => {
  return post('/api/azure/common/token', {
    clientId: 'a213c1fe-c1ae-41f6-878c-1948b826b9f5',
    scope: 'offline_access calendars.read calendars.readwrite',
    code,
    redirectUri: window.location.origin + '/integracoes',
    grantType: 'authorization_code',
    clientSecret: '5R_8Q~8RiBfnkkjfschsvZnP.E_~MZtWDbUyBbAo'
  });
};