import React from 'react';
import { Table, Column, Row, Dropdown, Menu, Button, StatusTag, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDate, formatCurrency, InfoUsuario, TelasNomenclaturas } from '@utils';
import { CompanyAddress, CompanyName, NumberOfProducts, ImportantText, CellText, CompanyIdentifier } from './styles';
import { getStatusColor } from '../rules';
import { changeStatusModal } from '@ducks/pedidos';

export default function List() {
  const history = useHistory();

  const dispatch = useDispatch();

  const orders = useSelector(({ pedidos }) => pedidos.get('orders'));
  const loading = useSelector(({ pedidos }) => pedidos.get('loadingOrders'));
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const columns = [
    {
      title: 'N° do pedido',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <ImportantText>{'Pedido #' + text}</ImportantText>
      )
    },
    {
      title: `${companyNomenclature.nomenclatura}`,
      dataIndex: 'companyName',
      sorter: true,
      render: (text, order) => (
        <Column>
          <CompanyIdentifier>{order.companyShortName}</CompanyIdentifier>
          <CompanyName>{order.companyName}</CompanyName>
          <CompanyAddress>{order.companyCity} - {order.companyState}</CompanyAddress>
        </Column>
      )
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      sorter: true,
      render: (text) => (
        <CellText>{formatDate(text)}</CellText>
      )
    },
    {
      title: 'Valor',
      dataIndex: 'totalValue',
      sorter: (a, b) => a.totalValue - b.totalValue,
      render: (text, order) => (
        <Column>
          <NumberOfProducts>{order.numberOfProducts} produto{order.numberOfProducts > 1 ? 's' : ''}</NumberOfProducts>
          <ImportantText>{formatCurrency(order.totalValue)}</ImportantText>
        </Column>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      render: (text, order) => (
        <Row justify='space-between'>
          <StatusTag 
            color={getStatusColor(order.status)} 
            text={(text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace('_', ' ')} 
            size='large'
            fixedWidth='150px'
          />
          {InfoUsuario.perm('pedidosEditar') && (
            <Column
              onClick={(e) => (e.stopPropagation())}
            >
              <Dropdown
                key="more"
                overlay={(
                  <Menu>
                    {(order.status === 'ABERTO' || order.status === 'EM_ANDAMENTO') && (
                      <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); history.push('/pedidos/' + order.id); }}>
                        <Button type="icon">
                      Editar pedido
                        </Button>                    
                      </Menu.Item>
                    )}
                          
                    <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); handleChangeStatusClick(order); }}>
                      <Button type="icon">
                      Alterar status
                      </Button>
                    </Menu.Item>
          
                  </Menu> 
                )}
              >
                <Button type="icon">
                  <Icon.MDI type="dots-vertical" />
                </Button>
              </Dropdown>
            </Column>
          )}
        </Row>
      )
    },
  ];

  const handleChangeStatusClick = (order) => {
    dispatch(changeStatusModal({ visible: true, orderId: order.id }));
  };

  const handleClickOnRow = (order) => {
    history.push(`/pedidos/${order.id}/detalhes`);
  };

  return(
    <Table
      columns={columns}
      dataSource={orders}
      rowKey='id'
      loading={loading}
      hidePagination
      onRow={(order, rowIndex) => {
        return {
          onClick: () => { handleClickOnRow(order); }
        };
      }}
    />
  );
}