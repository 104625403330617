import React, { useState, useEffect } from 'react';
import App from '@app/';
import { Body, PageHeader, View, Radio, Button, CalendarChart, Column, Skeleton, ActiveFilters, Icon } from '@components-teammove';

import { getAcessoDiario, FILTERS_KEY } from '@ducks/configuracoesGestaoEquipe';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { moment, useSearchParams, Filtros as FiltroStorage, formatDecimal, formatDate } from '@utils';
import { getCalendarConfig, typeFiltroPlataforma, getColorArray, filters } from '../rules';
import { getUsuario, reset } from '@ducks/configuracoesUsuarios';
import { IconSmartphone, CalendarColumn, IconCloud, IconEye, CalendarSkeleton, CalendarHeader, CalendarYear, CalendarViews, CalendarViewTotal, CalendarViewApp, CalendarViewWeb, FilterStyle, ViewFiltroDia, ViewFiltroDiaFechar } from './styles';

let years = [];

export default function GestaoEquipe() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { usuario } = useSearchParams();
  const [filtroCalendario, setFiltroCalendario] = useState('');
  const [calendarConfig, setCalendarConfig] = useState({ data: {} });
  const filtrosAcessoDiario = { data: [moment('2015-01-01'), moment()], usuario };
  const [visibleFilters, setVisibleFilters] = useState(false);

  const { dia } = useSearchParams();

  const acessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('acessoDiario').toArray());
  const loadingAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('loadingAcessoDiario'));
  const successAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('successAcessoDiario'));

  const usuarioData = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('usuario'));
  const filtros = FiltroStorage.get(FILTERS_KEY);

  const theme = useSelector(({ app }) => app.get('theme'));

  const handleGetAcessos = (filtrosAcesso) => {
    dispatch(getAcessoDiario({ filtros: { ...filtrosAcesso, save: usuario ? false : true } }));
  };

  useEffect(() => {
    dispatch(reset());
    handleGetAcessos({ ...filtros, ...filtrosAcessoDiario });
    if (usuario) {
      dispatch(getUsuario({ cdUsuario: usuario }));
    }
  }, []);

  useEffect(() => {
    if (!successAcessoDiario) return;
    const filtro = localStorage.getItem('gestaoEquipeFiltroCalendario');
    years = [...new Set(acessoDiario.map((data) => data.date.split('-')[0]))];

    if (filtro) {
      handleChangeFiltroCalendario(filtro);
    } else {
      handleChangeFiltroCalendario(typeFiltroPlataforma.TODOS);
    }
  }, [successAcessoDiario]);

  useEffect(() => {
    if (!successAcessoDiario || filtroCalendario === '') return;
    const config = getCalendarConfig(filtroCalendario, acessoDiario);
    
    if(config?.options?.title) delete config.options.title;
    setCalendarConfig(config);
  }, [filtroCalendario, successAcessoDiario]);

  const handleChangeFiltroCalendario = (data) => {
    
    if (data) {
      localStorage.setItem('gestaoEquipeFiltroCalendario', data);
      setFiltroCalendario('');
      setFiltroCalendario(data);
    }
  };

  const handleSearch = (filtros) => {
    handleGetAcessos({ ...filtros, ...filtrosAcessoDiario });
  };
  
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={
            usuarioData && usuarioData.nm_usuario
              ? `Acessos - Visão Geral - ${usuarioData.nm_usuario}`
              : 'Acessos - Visão Geral'
          }
          extra={
            !usuario && [
              <Button type='secondary' size='small' key='1' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>
                Filtrar
              </Button>,
            ]
          }
          onBack={() => window.history.back()}
        >
          <ActiveFilters filters={filters} filtros={filtros} onSearch={handleSearch} />
          {dia && (
            <ViewFiltroDia>
              <View>Data: {formatDate(dia)}</View>
              <ViewFiltroDiaFechar onClick={() => history.push('/visao-mensal')}>X</ViewFiltroDiaFechar>
            </ViewFiltroDia>
          )}
          {!loadingAcessoDiario ? 
            (<View>
              <Column gap='45px'>
                <Column gap='20px'>
                  <Column align='center'>           
                    <Radio.Group value={filtroCalendario} onChange={(data) => handleChangeFiltroCalendario(data)}>
                      <Radio value={typeFiltroPlataforma.TODOS}>Todos</Radio>
                      <Radio value={typeFiltroPlataforma.WEB}>Web</Radio>
                      <Radio value={typeFiltroPlataforma.APP}>App</Radio>
                    </Radio.Group>
                  </Column>
                </Column>
              </Column>
            </View>
            ) : (
              <Column align='center'>
                <Skeleton.Title/>
              </Column>)
          }
          
          {!loadingAcessoDiario && calendarConfig.data.rows ? (
            <View>
              <Column>       
                <Column>
                  {years.map((year) => {
                    const rows = calendarConfig.data.rows.filter((row) => row.date.getFullYear() === parseInt(year));
                    return rows.length === 0 ? null : (
                       
                      <>
                        <CalendarColumn>
                          <CalendarHeader>
                            <CalendarYear>
                              Acessos {year}
                            </CalendarYear>
                            <CalendarViews>
                              <CalendarViewTotal><IconEye type='eye'/> 
                                Total:{formatDecimal(
                                  acessoDiario
                                    .map((el) => (el.date.split('-')[0] === year ? el.qtAcessoWeb : 0))
                                    .reduce((acc, cur) => acc + cur),
                                  0
                                )}
                              </CalendarViewTotal>
                              <CalendarViewApp><IconSmartphone type='Smartphone'/>
                              Web: {formatDecimal(
                                  acessoDiario
                                    .map((el) => (el.date.split('-')[0] === year ? el.qtAcessoWeb : 0))
                                    .reduce((acc, cur) => acc + cur),
                                  0
                                )}
                              </CalendarViewApp>
                              <CalendarViewWeb><IconCloud type='Cloud'/>
                              App: {formatDecimal(
                                  acessoDiario
                                    .map((el) => (el.date.split('-')[0] === year ? el.qtAcessoApp : 0))
                                    .reduce((acc, cur) => acc + cur),
                                  0
                                )}
                              </CalendarViewWeb>
                            </CalendarViews>
                          </CalendarHeader>

                          <CalendarChart
                            data={rows}
                            colors={getColorArray(filtroCalendario)}
                            year={parseInt(year)}
                            onDateClick={(date) => history.push(`/gestao-equipe?dia=${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`)}
                          />
                        </CalendarColumn>
                      </>   
                    );
                  })}
                </Column>
              </Column>
            </View>
          ) : (
            <CalendarSkeleton justify-content='center' align-items='center'>        
              <Skeleton.Map/>
            </CalendarSkeleton>
          )}
        </PageHeader>
        <FilterStyle visible={visibleFilters} onClose={() => setVisibleFilters(false)} filtros={filtros} filters={filters} onSearch={handleSearch} />
      </Body>
    </App>
  );
}
