import { get, post, put } from '../api';

const useNodeApi = true;

export const getConfigurationVoip = () => {
  return get('/integration/recordOptions', {}, useNodeApi);
};

export const postConfigurationVoip = (configuration) => {
  return post('/integration/save/recordOptions', configuration, {}, useNodeApi);
};

export const getVoipConnection = () => {
  return get('/integration/getVoiceToken', {}, useNodeApi);
};

export const getVoipRecordDetails = (id) => {
  return get(`/integration/call/detail?callSid=${id}`, {}, useNodeApi);
};

export const postLigar = (data) => {
  return post('/integration/save/record', data, {}, useNodeApi);
};

export const postFinishActivity = (data) => {
  return put('/integration/finish', data, {}, useNodeApi);
};

