import styled from 'styled-components';
import { InputNumber } from 'antd';
import Icon from '../Icon';

export const StyledInputNumberComponent = styled(InputNumber)`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 14px;
  width: 100%;

  &.ant-input-number-disabled:hover .ant-input-number-handler-wrap {
    opacity: 0;
  }

  .ant-input-number-handler-wrap {
    background-color: transparent;
    border: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-input-number-handler {
      color: black;
      border: none;
      flex: 1;
      transition: all .1s;

      .ant-input-number-handler-up-inner,
      .ant-input-number-handler-down-inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      
      &.ant-input-number-handler-up:hover,
      &.ant-input-number-handler-down:hover {
        transform: scale(1.2);
      }
    }
  }

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    border: none;
    width: 100%;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
    :focus {
      outline: none;
    }
  }

  &.ant-input-number-disabled {
    opacity: .5;
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }

  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  ::placeholder {
    color: ${(props) => props.theme.textColor} !important;
  }
`;

export const IconsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex-direction: column;
  padding-right: .5rem;
  display: none;
`;

export const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${(props) => props.theme.textColor};
  opacity: .5;
`;

export const InputWrapper = styled.div`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 14px;
  width: 100%;
  position: relative;

  ${({ disabled }) => disabled && `
    opacity: 50%;
    pointer-events: none;
    cursor: not-allowed;
  `};

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    border: none;
    width: 100%;
    
    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
    :focus {
      outline: none;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    appearance: textfield;
    -moz-appearance: textfield;
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }

  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  :hover {
    ${IconsContainer} {
      display: flex;
    }
  }
`;