import { get } from '../../api';
import { queryParamsAudienciaAcessoCategoria } from './rules';
import { ansiToMoment } from '@utils';

export const getAudiencia = async (limit, filtros) => {
  const audiencias = await get(`/api/wiki/audiencia/top/acesso/categoria${queryParamsAudienciaAcessoCategoria(filtros)}`);

  // eslint-disable-next-line
  audiencias.map((audiencia) => {
    audiencia.ultimoAcesso = ansiToMoment(audiencia.ultimoAcesso);
  });

  if (limit && limit > 0) {
    return audiencias.slice(0, limit);
  } else {
    return audiencias;
  }
};

export const getAudienciaUltimosAcessos = async (page, filtros) => {
  const limit = 100;
  const audiencias = await get(
    `/api/wiki/audiencia/ultimos/acessos/categoria${queryParamsAudienciaAcessoCategoria(filtros)}&page=${page}&limit=${limit}`
  );

  // eslint-disable-next-line
  audiencias.data.map((audiencia) => {
    audiencia.dataInicio = ansiToMoment(audiencia.dataInicio);
    audiencia.dataFim = ansiToMoment(audiencia.dataFim);
  });

  return audiencias;
};

export const getAudienciaPorCategoria = async (id, filtros) => {
  if (!filtros) {
    return await get(`/api/wiki/audiencia/ranking/acesso/categoria${id ? `?id=${id}` : ''}`);
  } else {
    return await get(`/api/wiki/audiencia/ranking/acesso/categoria${queryParamsAudienciaAcessoCategoria(filtros)}${id ? `&id=${id}` : ''}`);
  }
};

export const getListasTransmissao = () => {
  return get('/api/wiki/audiencia/lista/transmissao').then((res) => {
    return res.map((listaTransmissao) => ({
      key: listaTransmissao.id,
      value: listaTransmissao.id,
      title: listaTransmissao.nome,
    }));
  });
};

export const getCategorias = async () => {
  const categorias = await get('/api/wiki/audiencia/categorias');
  return categorias.map((categoria) => ({
    key: categoria.key,
    value: categoria.value,
    title: categoria.title,
    children: categoria.children,
  }));
};
