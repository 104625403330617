import styled, { css } from 'styled-components';
import { View, Icon } from '@components-teammove';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 12px;
  border-radius: 12px;
`;

export const MainView = styled(View)`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  ${(props) =>
  props.dadoAntigo &&
    css`
      opacity: 0.7;
    `};
`;

export const ViewDados = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

export const ViewDataHora = styled(View)`
  flex-direction: row;
  align-items: center;
`;

export const ViewAcesso = styled(View)`
  justify-content: center;
  flex: 12;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const ViewData = styled(View)`
  align-items: center;
  width: 90px;
  margin-right: 10px;
  text-align: center;
`;

export const ViewDadosUsuario = styled(View)`
  flex: 1;
  flex-direction: row;
`;

export const ViewDadosConteúdo = styled(View)`
  flex-direction: row;
  gap: 24px;
  flex: 3;
  margin: 5px 0;
`;

export const ViewDadosAcesso = styled(View)`
  align-items: center;
  flex-direction: row;
  flex: 1;
`;

export const UsuarioContainer = styled(View)`
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 5px;
  border-left: 2px solid ${(props) => props.bordercolor};
`;

export const Foto = styled(View)`
  justify-content: center;
`;

export const ViewUsuario = styled(View)`
  flex-direction: row;
  margin: 5px 10px;
`;

export const ViewHoje = styled(View)`
  background-color: var(--success-color);
  padding: 5px;
  color: ${({ theme }) => theme.textColor};
  border-radius: 16px;
`;

export const ViewOntem = styled(View)`
  background-color: var(--warning-color);
  padding: 5px;
  color: ${({ theme }) => theme.textColor};
  border-radius: 16px;
`;

export const StyledText = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const Date = styled(StyledText)`
  font-size: 16px;
  font-weight: 700;
`;

export const DateInfo = styled(StyledText)`
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
`;

export const Hour = styled(StyledText)`
  font-size: 16px;
  font-weight: 400;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const Category = styled(StyledText)`
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-width: 250px;
  overflow: hidden;
`;

export const Path = styled(StyledText)`
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
`;

export const Name = styled(StyledText)`
  padding-left: 12px;
  font-weight: 400;
  font-size: 16px;
`;

export const Profile = styled(StyledText)`
  padding-left: 12px;
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
`;