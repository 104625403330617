import React, { useEffect } from 'react';
import App from '@app';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row, Body, PageHeader, Breadcrumb, } from '@components-teammove';
import { getAtendimentos } from '@ducks/configuracoesAtendimento';
import { useHistory } from 'react-router-dom';

const Atendimento = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useSelector(({ app }) => app.get('theme'));
  const atendimentos = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('atendimentos').toArray());
  const loadingAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingAtendimento'));

  useEffect(() => {
    dispatch(getAtendimentos());
  }, []);

  const NovoAtendimento = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => history.push('/configuracoes/atendimento/cadastro')}>Novo</Button>,
  ];

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'nome',
      sorter: true,
      render: (text, atendimento) => (
        <Row justify='space-between' align='center'>
          {atendimento.description}
          <Button.Group>
            <Button 
              type='secondary'
              size='small'
              context='list'
              onClick={(event) => {
                event.stopPropagation();
                history.push('/configuracoes/atendimento/cadastro', { id: atendimento.id, inUseByProfiles: atendimento.inUseByProfiles });
              }}
            >
              Editar
            </Button>
          </Button.Group>  
        </Row>
      )
    },
  ];

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Jornada'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/jornada', name: 'Jornada', current: true }
              ]}
            />
          )}
          extra={<NovoAtendimento/>}
          onBack={() => window.history.back()}
        >
          <Table
            columns={columns}
            dataSource={atendimentos}
            loading={loadingAtendimento}
            rowKey="id"
            status="ativo"
            onRow={(atendimento) => ({ onClick: () => history.push('/configuracoes/atendimento/cadastro', { id: atendimento.id, inUseByProfiles: atendimento.inUseByProfiles }) })}
          />
        </PageHeader>
      </Body>
    </App>
  );
};

export default Atendimento;
