import styled from 'styled-components';
import { List } from 'antd';
import { lighten } from 'polished';

export const StyledList = styled(List)`
  &.ant-list {
    background-color: ${({ highlightColor, theme }) => highlightColor ? theme.darkHighlightColor : theme.darkPrimaryColor};
    border-radius: 12px;

    .ant-list-header {
      padding: ${({ headerPadding }) => headerPadding ? headerPadding : '20px'};
      border-bottom-color: ${({ theme }) => theme.backgroundColor};
      ${({ contrastHeader, theme }) => contrastHeader && `
        background-color: ${theme.darkSecondaryColor};
      `}
    }

    .ant-list-item {
      padding: 14px 20px;
      border-bottom-color: ${({ theme }) => theme.backgroundColor};

      :last-child {
        border-bottom-color: ${({ theme }) => theme.backgroundColor};
        border-radius: 0 0 12px 12px;
      }
      :first-child:last-child {
        border-radius: 12px;
      }

      &.inactive {
        background-color: ${({ highlightColor, theme }) => highlightColor ? theme.darkPrimaryColor : theme.darkSecondaryColor};
      }
      &.transparent {
        background-color: ${({ theme }) => theme.backgroundColor};
      }
      &.hoverless {
        pointer-events: none;
      }

      :hover {
        background-color: ${({ highlightColor, theme }) => highlightColor ? lighten(0.085, theme.darkHighlightColor) : theme.darkHighlightColor};
      }
    }

    .ant-list-empty-text {
      color: ${({ theme }) => theme.textColor};
      .ant-empty {
        .ant-empty-description {
          color: ${({ theme }) => theme.textColor};
        }
      }
    }
  }
`;
