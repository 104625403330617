import { Text, View, Button } from '@components';
import { Icon , Button as StyledButton, Table, Menu, Row } from '@components-teammove';

import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled(View)`
  gap: 12px;
`;

export const StyledTable = styled(Table)`
  &.ant-table .ant-table-content tbody > tr:not(:last-child) td {
    max-width: 500px;
  }
`;

export const RowSpaceBetween = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FillView = styled(View)`
  height: 100%;
  justify-content: center;
  position: relative;
  max-width: 170px !important;
  /* > :first-child {
    position: absolute;
    margin: auto;
    transform: translateY(-50%);
    top: 50%;
  } */
`;

export const DetalheText = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  opacity: .5;
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AssuntoText = styled(Text)`
  font-weight: 700;
  font-size: 18px;
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}

`;

export const AssuntoDetalheText = styled(DetalheText)`
  font-size: 14;
  font-weight: 400;
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}

`;

export const DetailProtocolText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  opacity: .7;
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}
`;

export const IconWarning = styled(Icon.WarningFilled)`
  color: #ff9800 !important;
`;

export const MenuButton = styled(Button)`
  font-size: 14px !important;
`;

export const ContentTitle = styled(View)`
  flex-direction: row !important;
  gap: 24px;
`;

export const UnseenMessagesIndicator = styled(Text)`
  height: fit-content;
  align-self: center;
  font-size: 10px;
  font-weight: 400;
  border-radius: 27px;
  background-color: #1890FF;
  padding: 6px 12px;
`;

export const LoadMoreButton = styled(StyledButton)`
  align-self: center;
`;

export const NumberStepsTag = styled.div`
  font-weight: 600;
  border-radius: 27px;
  padding: 0 8px 0 8px;
  background-color: ${({ theme })=> theme.backgroundColor};
  width: 74px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VerticalLine = styled.div`
  border-left: 4px solid ${({ theme })=>theme.darkHighlightColor};
  height: 60px;
  border-radius: 4px;
`;

export const ContainerButton = styled(Menu.Item)`
  &.ant-dropdown-menu-item {
    color: ${(props) => props.theme.textColor} !important;

    .ant-btn {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 1;

      :hover {
        background-color: transparent !important;
        opacity: 1;
      }
    }

    :hover {
      background-color: transparent;
    }
  }
`;

export const HoverableContainer  = styled.div`
  background-color: var(--danger-color);
  border-radius: 4px;
  padding-left: 3px;
  :hover {
    background-color: ${darken(0.085, '#FF3B30')} !important;
  }
`;

export const TextTable = styled(Text)`
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}
`;

export const RowClassificacao = styled(Row)`
  flex-wrap: wrap;
`;

export const RowAssunto = styled(Row)`
  width: 380px !important;
`;