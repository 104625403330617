import styled from 'styled-components';
import { View, Form, Icon } from '@components-teammove';

export const FlexRow = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const FlexRowMinorGap = styled(FlexRow)`
  gap: 5px;
`;

export const FormWithGap = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 72px;
`;

export const FormItemFill = styled(Form.Item)`
  flex: 1;
`;

export const HalfWidthFormItem = styled(Form.Item)`
  width: 50%;
`;

export const SelfAlignEndFormItem = styled(Form.Item)`
  margin-left: auto;
`;

export const DollarIcon = styled(Icon.DollarOutlined)`
  margin-top: 4px;
  margin-left: 5px;
  font-size: 16px;
`;

export const LikeIcon = styled(Icon.LikeOutlined)`
  margin-top: 4px;
  margin-left: 5px;
  font-size: 16px;
`;

export const TitleLabel = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 20px;
`;

export const InfoIcon = styled(Icon.Feather)`

`;