/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Text, View, Collapse } from '@components';
import { Icon } from '@components-teammove';

const CollapseSmall = styled(Collapse)`
  .ant-collapse-header {
    padding: 5px 16px !important;
    padding-left: 40px !important;
  }
`;

export default async function UsuariosPermissaoCurso(usuarios, unidades = [], perfis = [], usuariosEspecificos = []) {
  function subStrAfterChars(str, char, pos) {
    if (pos === 'b') return str.substring(str.indexOf(char) + 1);
    else if (pos === 'a') return str.substring(0, str.indexOf(char));
    else return str;
  }

  const usuariosEnvio =
    (unidades.length !== 0 || perfis.length !== 0)
      ? usuarios.filter(
        (usuario) =>
          (perfis.length === 0 || perfis.includes(usuario.idPerfil)) &&
          (unidades.length === 0 || unidades.some((unidade) => usuario.unidades && usuario.unidades.includes(unidade.toString())))
      )
      : (usuariosEspecificos.length !== 0)
        ? usuarios.filter((usuario) => usuariosEspecificos.includes(usuario.key))
        : [];

  const usuariosEnvioOrdenado = usuariosEnvio.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

  const perfisDuplicados = usuariosEnvioOrdenado.map((usuario) => ({
    idPerfil: usuario.idPerfil,
    nmPerfil: subStrAfterChars(usuario.title, '- ', 'b'),
  }));

  const perfisUnicos = perfisDuplicados.filter(
    (perfil, index, self) => index === self.findIndex((perfil2) => perfil2.idPerfil === perfil.idPerfil)
  );

  const perfisUnicosComUsuarios = perfisUnicos.map((perfil) => ({
    ...perfil,
    usuarios: usuariosEnvio.filter((usuario) => usuario.idPerfil === perfil.idPerfil),
  }));

  const perfisUnicosComUsuariosOrdenados = perfisUnicosComUsuarios.sort((a, b) =>
    a.nmPerfil > b.nmPerfil ? 1 : b.nmPerfil > a.nmPerfil ? -1 : 0
  );

  const modal = Modal.info({
    title: (
      <View horizontal alignItems='center' justifyContent='space-between'>
        <Text>Quem pode ver o curso?</Text>
        <Button
          type='icon'
          icon={<Icon.CloseOutlined />}
          onClick={() => {
            close();
          }}
        />
      </View>
    ),
    content: (
      <View>
        <CollapseSmall defaultActiveKey={perfisUnicos.map((perfil) => perfil.idPerfil)}>
          {perfisUnicosComUsuariosOrdenados.map((perfil) => (
            <Collapse.Panel header={`${perfil.nmPerfil} (${perfil.usuarios.length})`} key={perfil.idPerfil}>
              {perfil.usuarios.map((usuario) => (
                <View key={usuario.key}>
                  <Text>{subStrAfterChars(usuario.title, ' -', 'a')}</Text>
                </View>
              ))}
            </Collapse.Panel>
          ))}
        </CollapseSmall>
      </View>
    ),
    maskClosable: true,
    className: 'modal-top no-buttons',
    icon: null,
    onCancel() {
      modal.destroy();
    },
  });

  const close = () => {
    modal.destroy();
  };
}
