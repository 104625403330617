import React, { useState, useEffect } from 'react';
import App from '@app/';
import { Body, PageHeader, Breadcrumb, ActiveFilters, View, Radio, Button, Row, Column, ContentTitle, Skeleton, CalendarChart, Tag, Icon } from '@components-teammove';

import TableAcessoUsuarios from './TableAcessosUsuarios';
import { getAcessoDiario, getAcessosUltimos30Dias, FILTERS_KEY } from '@ducks/configuracoesGestaoEquipe';
import CardResumo from './CardResumo';
import { filters, getCalendarConfig, getColor, getColorArray, typeFiltroPlataforma } from './rules';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { moment, useSearchParams, formatDate, Filtros as FiltroStorage } from '@utils';
import { Padding, FilterStyle, Container, Label, TagsView, TagText, StyleEmpty } from './styles';

let year = 0;

export default function GestaoEquipe() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dia } = useSearchParams();
  const [filtroCalendario, setFiltroCalendario] = useState('');
  const [calendarConfig, setCalendarConfig] = useState({ data: {} });
  const filtrosAcessoDiario = { data: [moment().startOf('year'), moment()] };
  const filtrosAcessoPeriodo = { data: [moment().subtract(30, 'days'), moment()] };
  const [visibleFilters, setVisibleFilters] = useState(false);

  const theme = useSelector(({ app }) => app.get('theme'));

  const acessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('acessoDiario').toArray());
  const loadingAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('loadingAcessoDiario'));
  const successAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('successAcessoDiario'));

  const acessosUltimos30Dias = useSelector(({ configuracoesGestaoEquipe }) =>
    configuracoesGestaoEquipe.get('acessosUltimos30Dias').toArray()
  );
  const loadingAcessosUltimos30Dias = useSelector(({ configuracoesGestaoEquipe }) =>
    configuracoesGestaoEquipe.get('loadingAcessosUltimos30Dias')
  );

  const [filtros, setFiltros] = useState(FiltroStorage.get(FILTERS_KEY));

  const handleGetAcessoDiario = (filtros) => {
    dispatch(getAcessoDiario({ filtros: { ...filtros, save: true } }));
  };

  const handleGetAcessoUltimosDias = (filtros) => {
    dispatch(getAcessosUltimos30Dias({ filtros }));
  };

  useEffect(() => {
    if (dia) {
      filtrosAcessoDiario.data = [moment(dia), moment(dia)];
      filtrosAcessoPeriodo.data = [moment(dia), moment(dia)];
    }

    handleGetAcessoDiario({ ...filtros, ...filtrosAcessoDiario });
    handleGetAcessoUltimosDias({ ...filtros, ...filtrosAcessoPeriodo });
  }, [dia]);

  useEffect(() => {
    if (!successAcessoDiario) return;
    const filtro = localStorage.getItem('gestaoEquipeFiltroCalendario');
    year = [...new Set(acessoDiario.map((data) => data.date.split('-')[0]))][0];
    if (filtro) {
      handleChangeFiltroCalendario(filtro);
    } else {
      handleChangeFiltroCalendario(typeFiltroPlataforma.TODOS);
    }
  }, [successAcessoDiario]);

  useEffect(() => {
    if (!successAcessoDiario || filtroCalendario === '') return;
    setCalendarConfig(getCalendarConfig(filtroCalendario, acessoDiario));
  }, [filtroCalendario, successAcessoDiario]);

  const handleChangeFiltroCalendario = (data) => {
    if (data) {
      localStorage.setItem('gestaoEquipeFiltroCalendario', data);
      setFiltroCalendario(data);
    }
  };

  const handleSearch = (filtros) => {
    handleGetAcessoDiario({ ...filtros, ...filtrosAcessoDiario });
    handleGetAcessoUltimosDias({ ...filtros, ...filtrosAcessoPeriodo });
    setFiltros(filtros);
  };
 
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Acessos'
          onBack={() => window.history.back()}
          extra={[
            <Button size='small' type='secondary' key='1' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>
              Filtrar
            </Button>,
            <Button size='small' type='secondary' key='2' onClick={() => history.push('/gestao-equipe/visao-mensal')}>Visão mensal</Button>,
            <Button size='small' type='secondary' key='3' onClick={() => history.push('/gestao-equipe/visao-geral')}>Visão geral</Button>
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/gestao-equipe', name: 'Acessos', current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filters} filtros={filtros} onSearch={handleSearch} />
          {dia && (
            <Container>
              <Label>Filtro: </Label>
              <TagsView>
                <Tag>
                  <TagText>Data: {formatDate(dia)}</TagText>
                </Tag>
                <Button 
                  bordered 
                  transparent 
                  coloredContent
                  context='round'
                  size='small'
                  type='danger' 
                  icon={<Icon.Feather type='fi-trash2'/>} 
                  onClick={() => history.push('/gestao-equipe')}
                >
                 Limpar filtros
                </Button>
              </TagsView>
            </Container>
          )}
          {loadingAcessoDiario ? (
            <Padding>
              <Column gap='45px'>
                <Row gap='10px'>
                  <Skeleton height='115px' borderRadius='18px'/>
                  <Skeleton height='115px' borderRadius='18px'/>
                  <Skeleton height='115px' borderRadius='18px'/>
                </Row>
              </Column>
            </Padding>
          ) : acessoDiario.length === 0 ? (
            <StyleEmpty description="Sem dados" /> 
            
          ) : (
            <Column gap='45px'>
              <Row gap='10px'>
                <CardResumo
                  title='Acessos'
                  cor={getColor(typeFiltroPlataforma.TODOS)}
                  value={acessoDiario.map((el) => el.qtAcessoWeb + el.qtAcessoApp).reduce((acc, cur) => acc + cur)}
                />
                <CardResumo
                  title='Acessos Web'
                  cor={getColor(typeFiltroPlataforma.WEB)}
                  value={acessoDiario.map((el) => el.qtAcessoWeb).reduce((acc, cur) => acc + cur)}
                />
                <CardResumo
                  title='Acessos App'
                  cor={getColor(typeFiltroPlataforma.APP)}
                  value={acessoDiario.map((el) => el.qtAcessoApp).reduce((acc, cur) => acc + cur)}
                />
              </Row>
              <Column gap='20px' align='center'>
                <Radio.Group value={filtroCalendario} onChange={(data) => handleChangeFiltroCalendario(data)}>
                  <Radio value={typeFiltroPlataforma.TODOS}>Todos</Radio>
                  <Radio value={typeFiltroPlataforma.WEB}>Web</Radio>
                  <Radio value={typeFiltroPlataforma.APP}>App</Radio>
                </Radio.Group>
                <CalendarChart
                  data={calendarConfig.data.rows}
                  colors={getColorArray(filtroCalendario)}
                  year={parseInt(year)}
                  onDateClick={(date) => history.push(`/gestao-equipe?dia=${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`)}
                />
              </Column>
            </Column>
          )}
          <View>
            <ContentTitle>{dia ? formatDate(dia) : 'Últimos 30 dias'}</ContentTitle>
            <TableAcessoUsuarios data={acessosUltimos30Dias} loading={loadingAcessosUltimos30Dias}/>
          </View>
        </PageHeader>
        <FilterStyle visible={visibleFilters} filters={filters} onClose={() => {setVisibleFilters(false); history.push('/gestao-equipe');}} filtros={filtros} onSearch={handleSearch} />
      </Body>
    </App>
  );
}
