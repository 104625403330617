import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Text, View, Button, Row } from '@components-teammove';

const ViewTitulo = styled(View)`
  ${(props) => (!props.ativo) && css`
    span {
      color: ${({ theme }) => theme.textColor};
      opacity: 0.50      
    }
  `}
`;

const ButtonSmall = styled(Button)`
  &.ant-btn {
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    border-radius: 50%;
  }
`;

export default function Titulo(categoria) {
  const history = useHistory();
  
  const handleNovo = () => {
    history.push('/configuracoes/chamados/categoria', { idCategoria: categoria.id });
  };

  const handleEditar = () => {
    history.push('/configuracoes/chamados/categoria', categoria);
  };
 
  return (
    <Row align='center' gap='8px'>
      <ViewTitulo onClick={handleEditar} ativo={categoria.ativo}>
        <Text>{categoria.titulo}</Text>
      </ViewTitulo>
      <ButtonSmall shape="circle" type="primary" onClick={handleNovo} visible={(categoria.ativo) && (categoria.tipo === 'GRUPO')}>+</ButtonSmall>
    </Row>
  );
}
