import { get, post, put, del } from '../api';

export const getExcelTemplate = (getCompanyData) => {
  const useApiNode = true;
  return get(`/integration/company/template?getCompanyData=${getCompanyData}`, {}, useApiNode);
};

export const uploadExcel = (excel) => {
  const useApiNode = true;
  return post('/integration/company/', { fileUrl: excel }, {}, useApiNode);
};

export const putRelationship = (modifiedCell) => {
  const useApiNode = true;
  return put('/integration/company/relationship', modifiedCell, {}, useApiNode);
};

export const getTableValidation = (id, onlyErrors, rowsByPage, pageToGo) => {
  const useApiNode = true;
  return get(`/integration/company/?importCompanyId=${id}&pagination=${pageToGo}&take=${rowsByPage}&onlyErrors=${onlyErrors}`, {}, useApiNode);
};

export const putEditValueFromTableValidation = (body) => {
  const useApiNode = true;
  return put('/integration/company/values', body, {}, useApiNode);
};

export const postSaveAllCompanies = (idTable) => {
  const useApiNode = true;
  return post(`/integration/company/${idTable}`, {}, {}, useApiNode);
};

export const deleteCompanyImport = (idTable) => {
  const useApiNode = true;
  return del(`/integration/company/${idTable}`, {}, {}, useApiNode);
};