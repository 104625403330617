import moment from 'moment';
import { InfoUsuario } from '@utils';

export const CurrentDay = () => {
  moment.locale('en');
  return moment().format('dddd').toUpperCase();
};

export const findCurrentJourney = () => {
  const workingJourney = InfoUsuario.get('workingJourney');

  const object = workingJourney?.workingDays?.find((item)=> item.day === CurrentDay());
  
  return object;
};

export const VerifyIfJourneyIsRunning = () => {
  const currentJourney = findCurrentJourney();
  const now = moment().format('HH:mm:ss');

  function isCurrentTimeInShift(currentTime, start, end) {
    const now = moment(currentTime, 'HH:mm:ss');
    const startTime = moment(start, 'HH:mm:ss');
    const endTime = moment(end, 'HH:mm:ss');

    if (endTime.isBefore(startTime)) {
      return now.isBetween(startTime, moment('23:59:59', 'HH:mm:ss'), null, '[]') ||
             now.isBetween(moment('00:00:00', 'HH:mm:ss'), endTime, null, '[]');
    } else {
      return now.isBetween(startTime, endTime, null, '[]');
    }
  }

  let isRunning = false;

  if (currentJourney) {
    isRunning = currentJourney.shifts.some((item) => {
      return isCurrentTimeInShift(now, item.start, item.end);
    });
  }

  return isRunning;
};