import { Text  } from '@components';
import { Icon, Modal, Select } from '@components-teammove';

import styled from 'styled-components';

export const IconStar = styled(Icon.StarFilled)`
  color: var(--warning-color) !important;
`;

export const ContentTitleExtra = styled(Text)`
  &.ant-typography {
    height: fit-content;
    align-self: center;
    font-size: 12px;
    font-weight: 400;
    border-radius: 27px;
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    color: ${({ theme }) => theme.textColor};
    padding: 6px 12px;
  }
`;

export const ModalConfirm = styled(Modal.Confirm)`
  .ant-modal-body {
    padding-bottom: 12px !important;
`;

export const SelectPeriod = styled(Select)`
	border: 2px solid ${(props) => props.theme.darkPrimaryColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
	width: 200px !important;
`;
