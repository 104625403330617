import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Text, View } from '@components';

const FormParams = styled(Form)`
  width: 100%;
`;

export default function PersonalizacaoParametros({ parametros, onChangeText, next, previous }) {
  const [form] = Form.useForm();
  const [title, setTitle] = useState(false);
  const [titleLP, setTitleLP] = useState(false);

  useEffect(() => {
    if (!parametros) return;

    const objectValuesFromArrayOfFields = parametros.reduce((values, { id, texto }) => ({ ...values, [`param${id}`]: texto }), {});
    form.setFieldsValue(objectValuesFromArrayOfFields);
    handleTitle(parametros);
  }, [parametros]);

  const handleOnContinue = () => {

    form.validateFields().then((values) => {
      if (next) {
        next();
      }
    });
  };

  const handleOnCancel = () => {
    if (previous) {
      previous();
    }
  };

  const handleTitle = (parametros) => {
    if (parametros) {
      parametros.forEach((el) => {
        if (el.type === 'LP') {
          setTitle(true);
        } else {
          setTitleLP(true);
        }
      });
    }
  };

  return (
    <>
      {(!title || titleLP) && (
        <>
          <Text>{'Informe os parâmetros para continuar'}</Text>
          <FormParams layout='vertical' form={form}>
            <>
              {parametros.map(({ id, label, required, editable, type }, index) => (
                <View key={id}>
                  {type !== 'LP' &&
                    <Form.Item key={id} label={label} name={`param${id}`} rules={[{ required, message: `${label} é obrigatório` }]}>
                      <Input disabled={!editable} onChange={(e) => onChangeText(index, e.target.value)} />
                    </Form.Item>
                  }
                </View>
              )
              )
              }
            </>
          </FormParams>
        </>
      )
      }
      <View horizontal width={250} justifyContent='space-evenly'>
        <Button size='large' danger onClick={handleOnCancel}>
          Cancelar
        </Button>
        <Button size='large' type='success' onClick={handleOnContinue}>
          Continuar
        </Button>
      </View>
    </>
  );
}
