import React from 'react';
import { StyleContainerNotification } from './styles';
import { Overlay } from '@components-teammove';

function NotificationMetas({ onClose, type, children }) {

  const handleOverlayClick = () => {
    onClose();
  };
    
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Overlay noBackground onClick={handleOverlayClick}>
      <StyleContainerNotification onClick={handleModalClick} type={type}>
        {children}
      </StyleContainerNotification>
    </Overlay>
  );
}

export default NotificationMetas;