import React, { useEffect, useState } from 'react';
import { Form, Column } from '@components-teammove';
import { postFinishActivity } from '@ducks/voip';
import { ButtonFooter, ObservationBox, RowButton, FormContainer } from './styles';
import { SubTitle, Title } from '../PreparationCall/styles';
import Calling from './Calling';
import AfterCall from './AfterCall';
import { USER_STATE } from '../Types';
import { useDispatch, useSelector } from 'react-redux';

const Call = ({ info, mediaStream, changeStatus, setChangeStatus, userState, finishCallConnection, connection, warningText }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { number, name } = info;
  const [classification, setClassification] = useState(null);

  const successPostFinishActivity = useSelector(({ voip }) => voip.get('successPostFinishActivity'));
  const loadingPostFinishActivity = useSelector(({ voip }) => voip.get('loadingPostFinishActivity'));

  const errorCall = useSelector(({ voip }) => voip.get('errorCall'));

  const recordDetails = useSelector(({ voip }) => voip.get('recordDetails'));

  useEffect(() => {
    if(!successPostFinishActivity) return;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    window.close();
  }, [successPostFinishActivity]);

  const onFinish = () => {
    const values = form.getFieldsValue();
    const allValuesToSend = { ...values, classification, finishActivity: true, voiceCallRecordId: recordDetails.VoiceCallRecordId, userId: recordDetails.UserId };
    dispatch(postFinishActivity(allValuesToSend));
  };

  const handleFinishCall = () => {
    finishCallConnection();
    setChangeStatus('afterCall');
  };

  useEffect(() => {
    if(info.unfinished) return;

    if(errorCall && errorCall.code === 31005) {
      setChangeStatus('preparation');
    } else if(userState === USER_STATE.DISCONNECTED) {
      setChangeStatus('afterCall');
    } else if (userState === USER_STATE.READY || userState === USER_STATE.REJECTED) {
      setChangeStatus('preparation');
    }
  }, [userState]);

  return (
    <Column align='center' width='100%' gap='12px'>
      <Title marginTitle>{name}</Title>
      <SubTitle>{number}</SubTitle>
      {changeStatus === 'calling' ? <Calling mediaStream={mediaStream} finishCall={handleFinishCall} userState={userState} connection={connection} warningText={warningText}/> : <AfterCall info={info} setClassification={setClassification} classification={classification}/> }
      <FormContainer form={form} layout='vertical'>
        <Form.Item name='notes' label='Observações'>
          <ObservationBox placeholder='Escreva suas observações aqui'/>
        </Form.Item>
      </FormContainer>
      <RowButton gap='20px' align='center' justify='center' width='100%'>
        <ButtonFooter type='primary' loading={loadingPostFinishActivity} disabled={changeStatus === 'calling' || (changeStatus !== 'calling' && !classification)} onClick={onFinish}>Finalizar</ButtonFooter>
      </RowButton>
    </Column>
  );
};

export default Call;