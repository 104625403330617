export const getColorDefault = (slicedFamilies) => {
  const colors = [
    '#673AB7', 
    '#3F51B5', 
    '#4CAF50',
    '#FFC107',
    '#FF5722',
  ];

  if(!slicedFamilies) return [];
  return slicedFamilies.map((familie, index) => {
    return {
      ...familie,
      color: colors[index],
    };
  });
};