import styled from 'styled-components';
import { PageHeader, Column, Image } from '@components-teammove';

export const Header = styled(PageHeader)`
  .ant-page-header-content{
    padding-top: 25px;
  }
`;

const Text = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const SimpleText = styled(Text)`
  font-size: 18px;
  font-weight: 400;
`;

export const ImportantText = styled(Text)`
  font-size: 32px;
  font-weight: 700;
`;

export const SubtitleText = styled(Text)`  
  font-size: 24px;
  font-weight: 700;
  opacity: 0.5;
`;

export const SubtitleValue = styled(SubtitleText)`
  font-weight: 400;
`;

export const Banner = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 28px;
  padding: 16px 0 16px 16px;
  display: flex;
  flex-direction: row;
  gap: 28px;
  align-items: center;
`;

export const Logo = styled.div`
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ color }) => color};
  font-size: 60px; 
  display: flex;
  align-items: center;
  width: 188px;
  height: 188px;
`;

export const DescriptionBody = styled(Column)`
  margin-top: 36px;
  margin-left: 16px;
`;

export const DescriptionTitle = styled(Text)`
  font-size: 24px;
  font-weight: 700;
`;

export const DescriptionName = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
`;

export const DescriptionText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
`;

export const Block = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProductImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ImageBanner = styled(Image)`
  border-radius: 10px !important;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const DetailedColumn = styled(Column)`
  justify-content: center;
`;