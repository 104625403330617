import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import App from '@app';

import { Icon, Body, PageHeader, Breadcrumb, Button, ActiveFilters, Column } from '@components-teammove';

import { Filtros } from '@utils';

import { initialFilters } from './rules';

import { FILTERS_KEY, PARAMS_KEY } from '@ducks/atividadesGestaoAgenda';
import { TeamsSummaryCards } from './TeamsSummaryCard';
import { WeekTable } from './WeekTable';
import { FilterStyle } from './styles';

export default function GestaoAgenda() {
  const theme = useSelector(({ app }) => app.get('theme'));

  const [ visibleFiltersModal, setVisibleFiltersModal ] = useState();
  const [ currentFilters, setCurrentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));

  const handleChangingNewFilters = (newFilters) => {
    Filtros.set(FILTERS_KEY, { ...newFilters });
    setCurrentFilters({ ...newFilters });
  };

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    Filtros.set(PARAMS_KEY, { ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Gestão da Agenda'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades', name: 'Atividades', current: true },
                { path: '/atividades/agenda-management', name: 'Gestão Agenda', current: true },
              ]}
            />
          )}
          extra={[
            <Button key='filter' size='small' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFiltersModal(true)}>Filtros</Button>,
          ]}
        >
          <ActiveFilters filters={initialFilters} filtros={currentFilters} onSearch={handleChangingNewFilters}/>
          <Column gap='18px'>
            <TeamsSummaryCards currentFilters={currentFilters} currentParams={currentParams} />
            <WeekTable 
              currentFilters={currentFilters} 
              currentParams={currentParams} 
              onChange={handleChangePeriod}
            />
          </Column>
          
        </PageHeader>

        {visibleFiltersModal && (
          <FilterStyle
            visible={visibleFiltersModal}
            onClose={() => setVisibleFiltersModal(false)}
            filtros={currentFilters}
            filters={initialFilters}
            onSearch={handleChangingNewFilters}
          />
        )}

      </Body>
    </App>
  );
}