import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Filtros, moment } from '@utils';
import { Skeleton, DashboardCard, Row } from '@components-teammove';
import { Container, GapView, StatusCard, TitleCards } from './styles';

import { getTicketsByStatus } from '@ducks/chamadosDashboard';
import { FILTERS_KEY as TICKETS_FILTERS_KEY } from '@ducks/chamadosTickets';
import { getFinalDateByInitialDateAndPeriod, getOldFiltersWithNewFilters } from '../rules';

export default function Cards({ currentFilters, currentParams }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const filtersAndParams = { ...currentFilters, ...currentParams, descFiltros: { ...currentParams.descFiltros, ...currentFilters.descFiltros } };

  const ticketsByStatusDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('ticketsByStatusDash'));
  const loadingTicketsByStatusDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingTicketsByStatusDash'));

  const formattedDashboard = ticketsByStatusDash.reduce((state, statusObj) => ({ ...state, [statusObj.id]: statusObj }), {});

  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getTicketsByStatus({ ...currentFilters, ...currentParams }));
    }
  }, [currentFilters, currentParams]);

  const handleRedirectToTickets = (status) => {
    const newTicketsPageFilters = getOldFiltersWithNewFilters(
      Filtros.get(TICKETS_FILTERS_KEY), 
      {
        ...filtersAndParams, 
        ticketsStatus: [status.id], 
        creationDate: [moment(filtersAndParams.initialDate), getFinalDateByInitialDateAndPeriod(filtersAndParams.initialDate, filtersAndParams.typePeriod)],
        descFiltros: {
          ...filtersAndParams.descFiltros, 
          ticketsStatus: [status.description],
          creationDate: [moment(filtersAndParams.initialDate).format('DD/MM/YYYY'), getFinalDateByInitialDateAndPeriod(filtersAndParams.initialDate, filtersAndParams.typePeriod).format('DD/MM/YYYY')]
        }
      }
    );
    
    Filtros.set(TICKETS_FILTERS_KEY, newTicketsPageFilters);
    history.push('/chamados/tickets');
  };
  
  return(
    loadingTicketsByStatusDash ? (
      <GapView>
        <Row padding='0 0 0 20px'>
          <Skeleton.ContentTitle/>
        </Row>
        
        <Container loading>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
        </Container>
        <Container loading>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
        </Container>

        <Row padding='0 0 0 20px'>
          <Skeleton.ContentTitle/>
        </Row>
        <Container loading>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
        </Container>
      </GapView>
    ) : (
      <GapView>
        
        {(formattedDashboard?.BR || formattedDashboard?.AR || formattedDashboard?.RE || formattedDashboard?.AB || formattedDashboard?.EA) && (
          <>
            <TitleCards>
              Pendentes
              ({Object.values(formattedDashboard).filter((item) => item.id !== 'AA' && item.id !== 'FE')
                .reduce((acc, curr) => acc + curr.quantity, 0)})
            
            </TitleCards>
            {(formattedDashboard?.RE || formattedDashboard?.AB) && (
              <Row gap='12px'>
                {formattedDashboard?.RE && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.RE} color={formattedDashboard?.RE?.color} description={formattedDashboard?.RE?.description} total={formattedDashboard?.RE?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {formattedDashboard?.AB && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.AB} color={formattedDashboard?.AB?.color} description={formattedDashboard?.AB?.description} total={formattedDashboard?.AB?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Row>)}
           
            {(formattedDashboard?.BR || formattedDashboard?.AR || formattedDashboard?.EA) && (
              <Row gap='12px'>
                {formattedDashboard?.BR && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.BR} color={formattedDashboard?.BR?.color} description={formattedDashboard?.BR?.description} total={formattedDashboard?.BR?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {formattedDashboard?.AR && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.AR} color={formattedDashboard?.AR?.color} description={formattedDashboard?.AR?.description} total={formattedDashboard?.AR?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {formattedDashboard?.EA && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.EA} color={formattedDashboard?.EA?.color} description={formattedDashboard?.EA?.description} total={formattedDashboard?.EA?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Row>)}
          </>
        )}

        {(formattedDashboard?.FE || formattedDashboard?.AA) && (
          <>
            <TitleCards>
              Concluídos
              ({Object.values(formattedDashboard).filter((item) =>item.id === 'FE' || item.id === 'AA')
                .reduce((acc, curr) => acc + curr.quantity, 0)})
                
            </TitleCards>
           
            {(formattedDashboard?.AA || formattedDashboard?.FE) && (
              <Row gap='12px'>
                {formattedDashboard?.AA && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.AA} color={formattedDashboard?.AA?.color} description={formattedDashboard?.AA?.description} total={formattedDashboard?.AA?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {formattedDashboard?.FE && (
                  <DashboardCard gridItem padding='20px' statusItem={formattedDashboard?.FE} color={formattedDashboard?.FE?.color} description={formattedDashboard?.FE?.description} total={formattedDashboard?.FE?.quantity} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Row>)}
          </>
        )}
      </GapView>
    )
  );
}