import { del, get, post, put } from '../api';
import { dateToAnsi, DATE_FORMATS, InfoUsuario } from '@utils';
import { getQueryString } from './rules';

export const getCampaigns = (idApi = '-1') => {
  return get(`/api/campaigns/v2?userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}&visualizeAll=${InfoUsuario.perm('campanhasVendasVisualizarTudo') || false}&userId=${InfoUsuario.get('cdUsuario')}`);
};

export const getCampaignsExpired = (idApi = '-1', filters) => {
  return get(`/api/campaigns/v2/expired?&userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}&visualizeAll=${InfoUsuario.perm('campanhasVendasVisualizarTudo') || false}&startAt=${dateToAnsi(filters.startAt, DATE_FORMATS.DATE, DATE_FORMATS.ANSI_DATE)}&limitAt=${dateToAnsi(filters.limitAt, DATE_FORMATS.DATE, DATE_FORMATS.ANSI_DATE)}&userId=${InfoUsuario.get('cdUsuario')}`);
};

export const getCampaign = (externalId, userId = '-1', idApi = '-1') => {
  return get(`/api/campaigns/rankings/v2/${externalId}?&userId=${userId}&userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}`);
};

export const getCampaignsUsers = () => {
  return get('/api/campaigns/users');
};

export const getAllCampaignsRegisters = ({ search, limit }) => {
  const queryString = getQueryString({ search, limit: limit || 50 });
  return get(`/api/campaigns/register${queryString ? `?${queryString}` : ''}`);
};

export const getCampaignRegister = (externalId) => {
  return get(`/api/campaigns/register/${externalId}`);
};

export const postCampaign = (campaign) => {
  return post('/api/campaigns/register', campaign);
};

export const putCampaign = (campaign) => {
  return put('/api/campaigns/register', campaign);
};

export const deleteCampaign = (id) => {
  return del(`/api/campaigns/register/${id}`);
};

export const updateResultsCampaignsByExcel = (results) => {
  return post('/api/campaigns/import/excel', results);
};

export const notifyResultsCampaigns = () => {
  return post('/api/campaigns/notifications/notify');
};