import React, { useEffect } from 'react';
import { Form, Modal, Input } from '@components';

export default function ModalObservacao({ visible, onCancel, itemObservation }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && itemObservation) {
      form.resetFields();
      form.setFieldsValue(itemObservation);
    }
  }, [visible]);

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal forceRender visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form form={form} layout="vertical" name="formItemObservation">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="ordem" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="titulo" label="Título do campo" rules={[{ required: true, message: 'Informe o título do campo' }]}>
          <Input placeholder="Título do campo" />
        </Form.Item>
        <Form.Item name="resumo" label="Valor padrão" rules={[{ required: true, message: 'Informe o valor padrão do campo' }]}>
          <Input placeholder="Valor padrão" />
        </Form.Item>
      </Form>
    </Modal>  
  );
}