import React, { useMemo } from 'react';
import { Column, Row, Modal } from '@components-teammove';
import { TextStyle } from './styles';
import { useSelector } from 'react-redux';
import { STATUS } from '@containers/Chamados/rules';
import { getActions } from '@containers/Chamados/Chat/rules';
import { formatDateTime } from '@utils';

export default function HistoricModal({ data, visible, onCancel }) {
  const chamado = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));

  const isAguardandoPendencia = chamado.aguardandoResposta === true;
  const isAguardandoAvaliacao = chamado.status === STATUS.AGUARDANDO_AVALIACAO;
  const isAguardandoBriefing = chamado.status === STATUS.AGUARDANDO_BRIEFING;

  const actions = useMemo(() => {
    const actions = data?.length > 0 ? getActions(data, { isAguardandoBriefing, isAguardandoPendencia, isAguardandoAvaliacao }) : [];
    return actions;
  }, [data, isAguardandoBriefing, isAguardandoPendencia, isAguardandoAvaliacao]);

  return (
    <Modal
      visible={visible}
      width={720}
      onCancel={onCancel}
      numberOfButtons={1}
      okText={'Fechar'}
      onOk={onCancel}
      okButtonProps={{ type: 'secondary' }}
      title={'Histórico do ticket'}
    >
      {actions.map((action) => (
        <Row key={action.label} align='center' justify='space-between' margin='0 0 10px 0'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>    
            <Column>
              <TextStyle font='14px' weight='700' opacity='.5'>{action.nmUsuario}</TextStyle>
              <Row align='center' gap='6px'>
                <TextStyle font='14px' weight='400'>{action.label}</TextStyle>
              </Row>
            </Column>
          </div>
          <Column>
            <TextStyle font='12px' weight='400' opacity='.5'>{formatDateTime(action.date)}</TextStyle>
          </Column>
        </Row>
      ))}
    </Modal>
  );
}
