import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Switch } from '@components-teammove';
import { selectRespostas, typesRespostas } from '../rules';
import styled from 'styled-components';
import CreateOptions from './Options';

const StyledSelect = styled(Select)`
  width: 100% !important;
`;

const StyledSwitch = styled(Switch)`
  margin: .5rem;
`;

export default function Pergunta ({ pergunta, setPergunta, form }) {
  const [question, setQuestion] = useState({ ...pergunta });
  const [options, setOptions] = useState(pergunta.opcoes?.filter((opt) => opt.ativo) || []);

  useEffect(() => {
    setQuestion({ ...pergunta });

    const optionsNames = options.reduce((obj, opt) => {
      return { ...obj, ['opcao' + (opt.id || opt.tempId)]: opt.titulo };
    }, {});

    form.setFieldsValue({ ...question, ...optionsNames });

    return () => setPergunta({});
  }, []);

  useEffect(() => {
    setPergunta({ ...question, opcoes: options });
  }, [question, options]);

  useEffect(() => {
    setQuestion({ ...question, opcoes: options });
  }, [options]);
  
  const handleQuestionChange = (e) => {
    const text = e.target.value;
    setQuestion(() => {
      const newQuestion = { ...question, titulo: text };
      return newQuestion;
    });
  };

  const handleAnsTypeChange = (value) => {
    setQuestion(() => {
      const newQuestion = { ...question, tipo: value };
      return newQuestion;
    });
  };

  const handleSwitch = (value) => {
    setQuestion(() => {
      const newQuestion = { ...question, obrigatorio: value };
      return newQuestion;
    });
  };

  return (
    <Form layout='vertical' form={form} name='perguntaForm' id={question.id || question.tempId}>
      <Form.Item
        name='titulo'
        label='Pergunta'
        rules={[{ required: true, message: 'Faça a sua pergunta' }]}
      >
        <Input type='text' value={question.titulo} onChange={(e) => handleQuestionChange(e)} autoFocus/>
      </Form.Item>
      <Form.Item
        name='obrigatorio'
      >
        Obrigatório?<StyledSwitch checked={question.obrigatorio} onChange={(value) => handleSwitch(value)}/>
      </Form.Item>
      <Form.Item
        name='tipo'
        label='Tipo de Resposta'
        rules={[{ required: true, message: 'Selecione um tipo de resposta' }]}
      >
        <StyledSelect
          action={async () => selectRespostas}
          placeholder='Selecione um tipo de resposta'
          onChange={(value) => handleAnsTypeChange(value)}
          value={question.tipo}
        />
      </Form.Item>

      {(question.tipo === typesRespostas.MULTIPLA_ESCOLHA || question.tipo === typesRespostas.SOMATORIA) && (
        <CreateOptions options={options} setOptions={setOptions}/>
      ) }
    </Form>
  );
}