import React from 'react';
import { Container, Description, Date, ActionIcon } from './styles';
import { formatDateTime } from '@utils';

const Item = ({ icon, message, date, details }) => (
  <Container>
    <ActionIcon type={icon} size={1.5} />
    <Description dangerouslySetInnerHTML={{ __html: message }} />
    <Date>{formatDateTime(date)}</Date>
    {details ? details : null}
  </Container>  
);

export default Item;