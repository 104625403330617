import View from '../View';
import Icon from '../Icon';
import styled, { css } from 'styled-components';
import { Select } from 'antd';

export const StyledSelectComponent = styled(Select)`
  width: 600px !important;
  max-width: 100%;
  ${(props) => props.error && css`
    border-color: red;
    border-width: 1.1px;
    border-style: solid;
  `}
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  width: 100% !important;

  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
  
  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
  }

  :invalid {
    border: 2px solid var(--danger-color);
  }

  .ant-select-selector {
    padding: 14px !important;
    height: fit-content !important;
    border: none !important;
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    align-items: center;

    .ant-select-selection-placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
    
    .ant-select-selection-search {
      display: flex;
      align-items: center;
      height: fit-content;
      margin: auto;
      flex: 1;

      .ant-select-selection-search-input {
        height: auto;
        padding: 0;
        display: flex;
      }
    }

    .ant-select-selection-placeholder {
      height: auto;
      display: flex;
      align-items: center;
      left: auto;
      right: auto;
      line-height: unset;
    }

    ::after {
      height: 22px;
    }
  }

  &.ant-select-multiple .ant-select-selection-item {
    background-color: ${(props) => props.theme.darkSecondaryColor};
    border: none;
    display: flex;
    align-items: center;
    height: fit-content;

    .ant-select-selection-item-content {
      padding: 4px;
    }

    .ant-select-selection-item-remove {
      color: ${(props) => props.theme.textColor};
    }
  }

	.ant-select-arrow {	
		color: ${(props) => props.theme.textColor};
    width: fit-content;
    height: fit-content;
    top: 40%;
    opacity: .5;
    transition: all .25s;
    ${({ value }) => value?.length > 0 && `
      pointer-events: auto;
    `}
	}

  .ant-select-clear {
    background-color: transparent;
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }
`;

export const DropdownStyles = styled.div`
  right: 10px;

  .ant-select-item-option {
    background-color: ${(props) => props.theme.darkPrimaryColor};
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    padding: 16px;

    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props.theme.actionColor};
    
    .ant-select-item-option-content {
      color: ${({ theme }) => theme.textContrastColor} !important;
    }

    .select-details > div {
      color: ${({ theme }) => theme.textContrastColor};
      > b {
        color: ${({ theme }) => theme.textContrastColor};
      }
    }
  }

  .ant-select-item-option-selected {
    background-color: ${(props) => props.theme.actionColor} !important;
    
    .ant-select-item-option-content {
      color: ${({ theme }) => theme.textContrastColor} !important;
    }
  }

  .ant-select-item-empty {
    background-color: ${(props) => props.theme.darkPrimaryColor};
  }
`;

export const ContainerStyles = styled.div`
  .ant-select + div {
    position: absolute !important;
    z-index: 1;
  }

  .ant-select-dropdown {
    /* top: 0 !important; */
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    border: 1px solid ${({ theme }) => theme.darkHighlightColor};
    outline: 2px solid ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const StyledDropdown = styled(View)`
  position: fixed;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.darkHighlightColor};
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 8px;
  width: 100%;
`;

export const DetailsStyled = styled(View)`
  font-size: 12px;

  b {
    font-size: 16px;
  }
`;

export const Option = styled(View)`
  padding: 16px;
`;

export const FixedContainer = styled(View)`
  transform: scale(1);
  width: 100%;
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;

export const LoadingIcon = styled(Icon.LoadingOutlined)`
  font-size: 24;
`;