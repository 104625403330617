import { Dropdown } from 'antd';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  &.ant-btn {
    color: ${(props) => props.theme.textColor} !important;
    opacity: 1;

    :hover {
      opacity: .5;
      background-color: transparent !important;
    }
    :focus {
      background-color: transparent !important;
    }
    :active {
      background-color: transparent !important;
    }
  }
`;