import React, { useEffect } from 'react';
import App from '@app';
import { Body, Breadcrumb, Button, Grid, Skeleton } from '@components-teammove';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header, ImportantText, SimpleText, Banner, SubtitleValue, SubtitleText, DescriptionBody, DescriptionTitle, DescriptionText, DescriptionName, Block, Logo, ProductImageWrapper, ImageBanner, DetailedColumn } from './styles';
import { formatCurrency, InfoUsuario } from '@utils';
import { getDetailedProduct } from '@ducks/produtos';

export default function ProductDetalhes() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const dispatch = useDispatch();

  const { idProduct } = useParams();
  const detailedProduct = useSelector(({ produtos }) => produtos.get('detailedProduct'));
  const loadingDetailedProduct = useSelector(({ produtos }) => produtos.get('loadingDetailedProduct'));
  const successDetailedProduct = useSelector(({ produtos }) => produtos.get('successDetailedProduct'));

  useEffect(() => {
    dispatch(getDetailedProduct(parseInt(idProduct)));
  }, []);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <Header
          title='Catálogo de produtos'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/mixprodutos', name: 'Mix de Produtos' },
                { path: `mixprodutos/${idProduct}/detalhes`, name: `${detailedProduct.name}`, current: true }
              ]}
            />
          )}
          onBack={() => history.goBack()}
          extra={(
            <Button visible={InfoUsuario.perm('produtosCadastro')} key='new' size='small' context='header' type='primary' onClick={() => history.push(`/mixprodutos/cadastro/${idProduct}`)}>Editar produto</Button>
          )}
        >
          {loadingDetailedProduct ?
            (<Skeleton.LargeBox />
            ) : (
              successDetailedProduct &&
              <Banner color={(detailedProduct.color ? detailedProduct.color : theme['@global-dark-primary-color'])}>
                {detailedProduct.picture &&
                  <Logo>
                    <ProductImageWrapper>
                      <ImageBanner src={detailedProduct.picture} />
                    </ProductImageWrapper>
                  </Logo>}
                <DetailedColumn>
                  <SimpleText>{detailedProduct.families?.map(({ name }) => name).join(', ')}</SimpleText>
                  <ImportantText>{detailedProduct.name}</ImportantText>
                  <SubtitleText>Peso:
                    <SubtitleValue> {(detailedProduct.weight).toString().replace('.', ',')} Kg</SubtitleValue>
                  </SubtitleText>
                  {detailedProduct.price &&
                    <SubtitleText>Valor:
                      <SubtitleValue> {formatCurrency(detailedProduct.price)}</SubtitleValue>
                    </SubtitleText>}
                </DetailedColumn>
              </Banner>)}

          <DescriptionBody gap='28px'>
            <DescriptionTitle>Descrição</DescriptionTitle>

            <Grid numberOfColumns='3' gap='42px'>
              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct &&
                <Block>
                  <DescriptionName>Produto</DescriptionName>
                  <DescriptionText>{detailedProduct.name}</DescriptionText>
                </Block>}

              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct && detailedProduct.price &&
                <Block>
                  <DescriptionName>Valor</DescriptionName>
                  <DescriptionText>{formatCurrency(detailedProduct.price)}</DescriptionText>
                </Block>}

              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct &&
                <Block>
                  <DescriptionName>Categoria</DescriptionName>
                  <DescriptionText>{detailedProduct.categories?.map(({ name }) => name).join(', ')}</DescriptionText>
                </Block>}

              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct &&
                <Block>
                  <DescriptionName>Familia</DescriptionName>
                  <DescriptionText>{detailedProduct.families?.map(({ name }) => name).join(', ')}</DescriptionText>
                </Block>}

              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct &&
                <Block>
                  <DescriptionName>Peso</DescriptionName>
                  <DescriptionText>{(detailedProduct.weight).toString().replace('.', ',')} Kg</DescriptionText>
                </Block>}

              {loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct &&
                <Block>
                  <DescriptionName>Descrição</DescriptionName>
                  <DescriptionText>{detailedProduct.description}</DescriptionText>
                </Block>}

              {detailedProduct.extraFields?.map(({ id, name, value }) => (
                loadingDetailedProduct ? <Skeleton.Form /> : successDetailedProduct && value &&
                  <Block key={id}>
                    <DescriptionName>{name}</DescriptionName>
                    <DescriptionText>{value}</DescriptionText>
                  </Block>
              ))}
            </Grid>
          </DescriptionBody>
        </Header>
      </Body>
    </App>
  );
}