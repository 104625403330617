import React, { useEffect, useState } from 'react';
import { ChromePicker, View } from '@components-teammove';
import { ContainerColor, ContentPopover, ContentCover } from './styles';

const DEFAULT_COLOR = '#FFF';

export default function InputChromePicker({ color = DEFAULT_COLOR, onChange }) {
  const [selectedColor, setSelectedColor] = useState(color);
  const [visibleChromePicker, setVisibleChromePicker] = useState(false);

  useEffect(() => {
    setSelectedColor(color || DEFAULT_COLOR);
  }, [color]);

  const handleOpenChromePicker = () => {
    setVisibleChromePicker(true);
  };

  const handleCloseChromePicker = () => {
    setVisibleChromePicker(false);
    if (onChange) {
      onChange(selectedColor);
    }
  };

  const handleOnChange = ({ hex }) => {
    setSelectedColor(hex);
  };

  return (
    <View>
      <ContainerColor color={selectedColor} onClick={() => handleOpenChromePicker()} />
      {visibleChromePicker && 
        <ContentPopover>
          <ContentCover onClick={() => handleCloseChromePicker()} />
          <ChromePicker color={selectedColor} onChange={handleOnChange} />
        </ContentPopover>}
    </View>
  );
}