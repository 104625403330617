import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, Filtros } from '@utils';
import { getDashboard as getDashboardSdk } from '@sdk/Chamados';
import {
  getOpenTickets as getOpenTicketsSdk,
  getTicketsByStatus as getTicketsByStatusSdk,
  getConclusionOnTime as getConclusionOnTimeSdk,
  getClassificationInformation as getClassificationInformationSdk,
  getCategoryRanking as getCategoryRankingSdk,
  getSatisfactionIndex as getSatisfactionIndexSdk,
  getSatisfactionIndexSummary as getSatisfactionIndexSummarySdk,
  getSatisfactionIndexTickets as getSatisfactionIndexTicketsSdk,
  getExcelData as getExcelDataSdk,
  getCompaniesRanking as getCompaniesRankingSdk  
} from '@sdk/DashboardChamados';

export const FILTERS_KEY = 'FILTROS/DASHBOARD_CHAMADOS';
export const PARAMS_KEY = 'FILTROS/DASHBOARD_CHAMADOS_PARAMS';

//Action Types
export const Types = {
  GET_DASHBOARD: 'CHAMADOS_DASHBOARD/GET_DASHBOARD',
  GET_DASHBOARD_SUCCESS: 'CHAMADOS_DASHBOARD/GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_ERROR: 'CHAMADOS_DASHBOARD/GET_DASHBOARD_ERROR',

  GET_OPEN_TICKETS_DASH: 'CHAMADOS_DASHBOARD/GET_OPEN_TICKETS_DASH',
  GET_OPEN_TICKETS_DASH_SUCCESS: 'CHAMADOS_DASHBOARD/GET_OPEN_TICKETS_DASH_SUCCESS',
  GET_OPEN_TICKETS_DASH_ERROR: 'CHAMADOS_DASHBOARD/GET_OPEN_TICKETS_DASH_ERROR',

  GET_TICKETS_BY_STATUS_DASH: 'CHAMADOS_DASHBOARD/GET_TICKETS_BY_STATUS_DASH',
  GET_TICKETS_BY_STATUS_DASH_SUCCESS: 'CHAMADOS_DASHBOARD/GET_TICKETS_BY_STATUS_DASH_SUCCESS',
  GET_TICKETS_BY_STATUS_DASH_ERROR: 'CHAMADOS_DASHBOARD/GET_TICKETS_BY_STATUS_DASH_ERROR',

  GET_CONCLUSION_ON_TIME_DASH: 'CHAMADOS_DASHBOARD/GET_CONCLUSION_ON_TIME_DASH',
  GET_CONCLUSION_ON_TIME_DASH_SUCCESS: 'CHAMADOS_DASHBOARD/GET_CONCLUSION_ON_TIME_DASH_SUCCESS',
  GET_CONCLUSION_ON_TIME_DASH_ERROR: 'CHAMADOS_DASHBOARD/GET_CONCLUSION_ON_TIME_DASH_ERROR',

  GET_CLASSIFICATION_INFORMATION_DASH: 'CHAMADOS_DASHBOARD/GET_CLASSIFICATION_INFORMATION_DASH',
  GET_CLASSIFICATION_INFORMATION_DASH_SUCCESS: 'CHAMADOS_DASHBOARD/GET_CLASSIFICATION_INFORMATION_DASH_SUCCESS',
  GET_CLASSIFICATION_INFORMATION_DASH_ERROR: 'CHAMADOS_DASHBOARD/GET_CLASSIFICATION_INFORMATION_DASH_ERROR',

  GET_CATEGORY_RANKING: 'CHAMADOS_DASHBOARD/GET_CATEGORY_RANKING',
  GET_CATEGORY_RANKING_SUCCESS: 'CHAMADOS_DASHBOARD/GET_CATEGORY_RANKING_SUCCESS',
  GET_CATEGORY_RANKING_ERROR: 'CHAMADOS_DASHBOARD/GET_CATEGORY_RANKING_ERROR',

  GET_COMPANIES_RANKING: 'CHAMADOS_DASHBOARD/GET_COMPANIES_RANKING',
  GET_COMPANIES_RANKING_SUCCESS: 'CHAMADOS_DASHBOARD/GET_COMPANIES_RANKING_SUCCESS',
  GET_COMPANIES_RANKING_ERROR: 'CHAMADOS_DASHBOARD/GET_COMPANIES_RANKING_ERROR',

  GET_CATEGORIES_GRAPHIC: 'DASHBOARD_CHAMADOS/GET_CATEGORIES_GRAPHIC',
  GET_CATEGORIES_GRAPHIC_SUCCESS: 'DASHBOARD_CHAMADOS/GET_CATEGORIES_GRAPHIC_SUCCESS',
  GET_CATEGORIES_GRAPHIC_ERROR: 'DASHBOARD_CHAMADOS/GET_CATEGORIES_GRAPHIC_ERROR',
  
  GET_COMPANIES_GRAPHIC: 'DASHBOARD_CHAMADOS/GET_COMPANIES_GRAPHIC',
  GET_COMPANIES_GRAPHIC_SUCCESS: 'DASHBOARD_CHAMADOS/GET_COMPANIES_GRAPHIC_SUCCESS',
  GET_COMPANIES_GRAPHIC_ERROR: 'DASHBOARD_CHAMADOS/GET_COMPANIES_GRAPHIC_ERROR',
  
  GET_SATISFACTION_INDEX_DASH: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_DASH',
  GET_SATISFACTION_INDEX_DASH_SUCCESS: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_DASH_SUCCESS',
  GET_SATISFACTION_INDEX_DASH_ERROR: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_DASH_ERROR',

  GET_SATISFACTION_INDEX_SUMMARY: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_SUMMARY',
  GET_SATISFACTION_INDEX_SUMMARY_SUCCESS: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_SUMMARY_SUCCESS',
  GET_SATISFACTION_INDEX_SUMMARY_ERROR: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_SUMMARY_ERROR',

  GET_SATISFACTION_INDEX_TICKETS: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_TICKETS',
  GET_SATISFACTION_INDEX_TICKETS_SUCCESS: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_TICKETS_SUCCESS',
  GET_SATISFACTION_INDEX_TICKETS_ERROR: 'CHAMADOS_DASHBOARD/GET_SATISFACTION_INDEX_TICKETS_ERROR',

  GET_EXCEL_DATA: 'CHAMADOS_DASHBOARD/GET_EXCEL_DATA',
  GET_EXCEL_DATA_SUCCESS: 'CHAMADOS_DASHBOARD/GET_EXCEL_DATA_SUCCESS',
  GET_EXCEL_DATA_ERROR: 'CHAMADOS_DASHBOARD/GET_EXCEL_DATA_ERROR',

};

//Action Creators
export const getDashboard = (idUsuario, filtros = {}, busca, options = { updateFiltros: true }) => ({ type: Types.GET_DASHBOARD, idUsuario, filtros, busca, options });
export const getDashboardSuccess = (data) => ({ type: Types.GET_DASHBOARD_SUCCESS, data });
export const getDashboardError = (error) => ({ type: Types.GET_DASHBOARD_ERROR, error });

export const getOpenTicketsDash = (params) => ({ type: Types.GET_OPEN_TICKETS_DASH, params });
export const getOpenTicketsDashSuccess = (data) => ({ type: Types.GET_OPEN_TICKETS_DASH_SUCCESS, data });
export const getOpenTicketsDashError = (error) => ({ type: Types.GET_OPEN_TICKETS_DASH_ERROR, error });

export const getTicketsByStatus = (params) => ({ type: Types.GET_TICKETS_BY_STATUS_DASH, params });
export const getTicketsByStatusSuccess = (data) => ({ type: Types.GET_TICKETS_BY_STATUS_DASH_SUCCESS, data });
export const getTicketsByStatusError = (error) => ({ type: Types.GET_TICKETS_BY_STATUS_DASH_ERROR, error });

export const getConclusionOnTimeDash = (params) => ({ type: Types.GET_CONCLUSION_ON_TIME_DASH, params });
export const getConclusionOnTimeDashSuccess = (data) => ({ type: Types.GET_CONCLUSION_ON_TIME_DASH_SUCCESS, data });
export const getConclusionOnTimeDashError = (error) => ({ type: Types.GET_CONCLUSION_ON_TIME_DASH_ERROR, error });

export const getClassificationInformationDash = (params) => ({ type: Types.GET_CLASSIFICATION_INFORMATION_DASH, params });
export const getClassificationInformationDashSuccess = (data) => ({ type: Types.GET_CLASSIFICATION_INFORMATION_DASH_SUCCESS, data });
export const getClassificationInformationDashError = (error) => ({ type: Types.GET_CLASSIFICATION_INFORMATION_DASH_ERROR, error });

export const getCategoryRanking = (params, loaders) => ({ type: Types.GET_CATEGORY_RANKING, params, loaders });
export const getCategoryRankingSuccess = (data) => ({ type: Types.GET_CATEGORY_RANKING_SUCCESS, data });
export const getCategoryRankingError = (error) => ({ type: Types.GET_CATEGORY_RANKING_ERROR, error });

export const getCompaniesRanking = (params, loaders) => ({ type: Types.GET_COMPANIES_RANKING, params, loaders });
export const getCompaniesRankingSuccess = (data) => ({ type: Types.GET_COMPANIES_RANKING_SUCCESS, data });
export const getCompaniesRankingError = (error) => ({ type: Types.GET_COMPANIES_RANKING_ERROR, error });

export const getCategoriesGraphic = (params, loaders) => ({ type:Types.GET_CATEGORIES_GRAPHIC, params, loaders });
export const getCategoriesGraphicSuccess = (categoriesGraphic) => ({ type:Types.GET_CATEGORIES_GRAPHIC_SUCCESS, categoriesGraphic });
export const getCategoriesGraphicError = (error) => ({ type:Types.GET_CATEGORIES_GRAPHIC_ERROR, error });

export const getCompaniesGraphic = (params, loaders) => ({ type:Types.GET_COMPANIES_GRAPHIC, params, loaders });
export const getCompaniesGraphicSuccess = (companiesGraphic) => ({ type:Types.GET_COMPANIES_GRAPHIC_SUCCESS, companiesGraphic });
export const getCompaniesGraphicError = (error) => ({ type:Types.GET_COMPANIES_GRAPHIC_ERROR, error });

export const getSatisfactionIndexDash = (params) => ({ type: Types.GET_SATISFACTION_INDEX_DASH, params });
export const getSatisfactionIndexDashSuccess = (data) => ({ type: Types.GET_SATISFACTION_INDEX_DASH_SUCCESS, data });
export const getSatisfactionIndexDashError = (error) => ({ type: Types.GET_SATISFACTION_INDEX_DASH_ERROR, error });

export const getSatisfactionIndexSummary = (params) => ({ type: Types.GET_SATISFACTION_INDEX_SUMMARY, params });
export const getSatisfactionIndexSummarySuccess = (data) => ({ type: Types.GET_SATISFACTION_INDEX_SUMMARY_SUCCESS, data });
export const getSatisfactionIndexSummaryError = (error) => ({ type: Types.GET_SATISFACTION_INDEX_SUMMARY_ERROR, error });

export const getSatisfactionIndexTickets = (params, loaders) => ({ type: Types.GET_SATISFACTION_INDEX_TICKETS, params, loaders });
export const getSatisfactionIndexTicketsSuccess = (data) => ({ type: Types.GET_SATISFACTION_INDEX_TICKETS_SUCCESS, data });
export const getSatisfactionIndexTicketsError = (error) => ({ type: Types.GET_SATISFACTION_INDEX_TICKETS_ERROR, error });

export const getExcelData = (params) => ({ type: Types.GET_EXCEL_DATA, params });
export const getExcelDataSuccess = (data) => ({ type: Types.GET_EXCEL_DATA_SUCCESS, data });
export const getExcelDataError = (error) => ({ type: Types.GET_EXCEL_DATA_ERROR, error });

//saga
function* fetchDashboardSaga(action) {
  try {
    const { idUsuario, filtros, busca, options } = action;    
    if (options.updateFiltros) {
      Filtros.set(FILTERS_KEY, filtros);
    }
    
    const resp = yield call(getDashboardSdk, idUsuario, filtros, busca);
    yield put(getDashboardSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDashboardError(err));
  }
}

function* fetchOpenTicketsDashSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getOpenTicketsSdk, params);
    yield put(getOpenTicketsDashSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getOpenTicketsDashError(err));
  }
}

function* fetchTicketsByStatusDashSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getTicketsByStatusSdk, params);
    yield put(getTicketsByStatusSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    console.error(err);
    yield put(getTicketsByStatusError(err));
  }
}

function* fetchConclusionOnTimeDashSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getConclusionOnTimeSdk, params);
    yield put(getConclusionOnTimeDashSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getConclusionOnTimeDashError(err));
  }
}

function* fetchClassificationInformationDashSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getClassificationInformationSdk, params);
    yield put(getClassificationInformationDashSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClassificationInformationDashError(err));
  }
}

function* fetchCategoryRankingSaga(action) {
  try {
    const { params, loaders } = action;
    
    const resp = yield call(getCategoryRankingSdk, params, loaders);
    yield put(getCategoryRankingSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoryRankingError(err));
  }
}

function* fetchCompaniesRankingSaga(action) {
  try {
    const { params, loaders } = action;
    
    const resp = yield call(getCompaniesRankingSdk, params, loaders);
    yield put(getCompaniesRankingSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCompaniesRankingError(err));
  }
}

function* fetchGetCategoriesGraphic(action) {
  try {
    const { params, loaders } = action;
    const categories = yield call(getCategoryRankingSdk, params, loaders);
    yield put(getCategoriesGraphicSuccess(categories));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriesGraphicError(err));
  }
} 

function* fetchGetCompaniesGraphic(action) {
  try {
    const { params, loaders } = action;
    const companies = yield call(getCompaniesRankingSdk, params, loaders);
    yield put(getCompaniesGraphicSuccess(companies));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCompaniesGraphicError(err));
  }
} 

function* fetchSatisfactionIndexDashSaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getSatisfactionIndexSdk, params);
    yield put(getSatisfactionIndexDashSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getSatisfactionIndexDashError(err));
  }
}

function* fetchSatisfactionIndexSummarySaga(action) {
  try {
    const { params } = action;
    const resp = yield call(getSatisfactionIndexSummarySdk, params);
    yield put(getSatisfactionIndexSummarySuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getSatisfactionIndexSummaryError(err));
  }
}

function* fetchSatisfactionIndexTicketsSaga(action) {
  try {
    const { params, loaders } = action;
    const resp = yield call(getSatisfactionIndexTicketsSdk, params, loaders);
    yield put(getSatisfactionIndexTicketsSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getSatisfactionIndexTicketsError(err));
  }
}

function* fetchGetExcelData(action) {
  try {
    const { params } = action;
    const resp = yield call(getExcelDataSdk, params);
    yield put(getExcelDataSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getExcelDataError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_DASHBOARD, fetchDashboardSaga),
  takeLatest(Types.GET_OPEN_TICKETS_DASH, fetchOpenTicketsDashSaga),
  takeLatest(Types.GET_TICKETS_BY_STATUS_DASH, fetchTicketsByStatusDashSaga),
  takeLatest(Types.GET_CONCLUSION_ON_TIME_DASH, fetchConclusionOnTimeDashSaga),
  takeLatest(Types.GET_CLASSIFICATION_INFORMATION_DASH, fetchClassificationInformationDashSaga),
  takeLatest(Types.GET_CATEGORY_RANKING, fetchCategoryRankingSaga),
  takeLatest(Types.GET_COMPANIES_RANKING, fetchCompaniesRankingSaga),
  takeLatest(Types.GET_CATEGORIES_GRAPHIC, fetchGetCategoriesGraphic),
  takeLatest(Types.GET_COMPANIES_GRAPHIC, fetchGetCompaniesGraphic),
  takeLatest(Types.GET_SATISFACTION_INDEX_DASH, fetchSatisfactionIndexDashSaga),
  takeLatest(Types.GET_SATISFACTION_INDEX_SUMMARY, fetchSatisfactionIndexSummarySaga),
  takeLatest(Types.GET_SATISFACTION_INDEX_TICKETS, fetchSatisfactionIndexTicketsSaga),
  takeLatest(Types.GET_EXCEL_DATA, fetchGetExcelData),
];

// Reducer
const initialState = Map({  
  status: List(),
  rankingCategorias: List(),

  loading: false,
  success: false,
  error: false,

  openTicketsDash: List(),
  loadingOpenTicketsDash: false,
  successOpenTicketsDash: false,
  errorOpenTicketsDash: false,

  ticketsByStatusDash: List(),
  loadingTicketsByStatusDash: false,
  successTicketsByStatusDash: false,
  errorTicketsByStatusDash: false,

  conclusionOnTimeDash: List(),
  loadingConclusionOnTimeDash: false,
  successConclusionOnTimeDash: false,
  errorConclusionOnTimeDash: false,

  classificationInformationDash: List(),
  loadingClassificationInformationDash: false,
  successClassificationInformationDash: false,
  errorClassificationInformationDash: false,

  categoryRanking: List(),
  loadingCategoryRanking: false,
  successCategoryRanking: false,
  errorCategoryRanking: false,

  companiesRanking: List(),
  loadingCompaniesRanking: false,
  successCompaniesRanking: false,
  errorCompaniesRanking: false,

  categoriesGraphic: List(),
  loadingCategoriesGraphic: false,
  successCategoriesGraphic: false,
  errorCategoriesGraphic: false,

  companiesGraphic: List(),
  loadingCompaniesGraphic: false,
  successCompaniesGraphic: false,
  errorCompaniesGraphic: false,

  satisfactionIndexDash: List(),
  loadingSatisfactionIndexDash: false,
  successSatisfactionIndexDash: false,
  errorSatisfactionIndexDash: false,

  satisfactionIndexSummary: List(),
  loadingSatisfactionIndexSummary: false,
  successSatisfactionIndexSummary: false,
  errorSatisfactionIndexSummary: false,

  satisfactionIndexTickets: List(),
  loadingSatisfactionIndexTickets: false,
  successSatisfactionIndexTickets: false,
  errorSatisfactionIndexTickets: false,

  excelData: List(),
  loadingExcelData: false,
  successExcelData: false,
  errorExcelData: false,
});

const handleGetDashboard = (state, action) => {
  return state    
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetDashboardSuccess = (state, action) => {
  const { chamados, categorias } = action.data;

  return state
    .set('status', List(chamados))
    .set('rankingCategorias', List(categorias))
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetDashboardError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleGetOpenTicketsDash = (state, action) => {
  return state    
    .set('loadingOpenTicketsDash', true)
    .set('successOpenTicketsDash', false)
    .set('errorOpenTicketsDash', false);
};

const handleGetOpenTicketsDashSuccess = (state, action) => {
  return state
    .set('openTicketsDash', action.data)
    .set('loadingOpenTicketsDash', false)
    .set('successOpenTicketsDash', true)
    .set('errorOpenTicketsDash', false);
};

const handleGetOpenTicketsDashError = (state, action) => {
  return state
    .set('loadingOpenTicketsDash', false)
    .set('successOpenTicketsDash', false)
    .set('errorOpenTicketsDash', action.error);
};

const handleGetTicketsByStatusDash = (state, action) => {
  return state
    .set('loadingTicketsByStatusDash', true)
    .set('successTicketsByStatusDash', false)
    .set('errorTicketsByStatusDash', false);
};

const handleGetTicketsByStatusDashSuccess = (state, action) => {
  return state
    .set('ticketsByStatusDash', action.data)
    .set('loadingTicketsByStatusDash', false)
    .set('successTicketsByStatusDash', true)
    .set('errorTicketsByStatusDash', false);
};

const handleGetTicketsByStatusDashError = (state, action) => {
  return state
    .set('loadingTicketsByStatusDash', false)
    .set('successTicketsByStatusDash', false)
    .set('errorTicketsByStatusDash', action.error);
};

const handleGetConclusionOnTimeDash = (state, action) => {
  return state    
    .set('loadingConclusionOnTimeDash', true)
    .set('successConclusionOnTimeDash', false)
    .set('errorConclusionOnTimeDash', false);
};

const handleGetConclusionOnTimeDashSuccess = (state, action) => {
  return state
    .set('conclusionOnTimeDash', action.data)
    .set('loadingConclusionOnTimeDash', false)
    .set('successConclusionOnTimeDash', true)
    .set('errorConclusionOnTimeDash', false);
};

const handleGetConclusionOnTimeDashError = (state, action) => {
  return state
    .set('loadingConclusionOnTimeDash', false)
    .set('successConclusionOnTimeDash', false)
    .set('errorConclusionOnTimeDash', action.error);
};

const handleGetClassificationInformationDash = (state, action) => {
  return state    
    .set('loadingClassificationInformationDash', true)
    .set('successClassificationInformationDash', false)
    .set('errorClassificationInformationDash', false);
};

const handleGetClassificationInformationDashSuccess = (state, action) => {
  return state
    .set('classificationInformationDash', action.data)
    .set('loadingClassificationInformationDash', false)
    .set('successClassificationInformationDash', true)
    .set('errorClassificationInformationDash', false);
};

const handleGetClassificationInformationDashError = (state, action) => {
  return state
    .set('loadingClassificationInformationDash', false)
    .set('successClassificationInformationDash', false)
    .set('errorClassificationInformationDash', action.error);
};

const handleGetCategoryRanking = (state, action) => {
  return state    
    .set('loadingCategoryRanking', true)
    .set('successCategoryRanking', false)
    .set('errorCategoryRanking', false);
};

const handleGetCategoryRankingSuccess = (state, action) => {
  return state
    .set('categoryRanking', action.data)
    .set('loadingCategoryRanking', false)
    .set('successCategoryRanking', true)
    .set('errorCategoryRanking', false);
};

const handleGetCategoryRankingError = (state, action) => {
  return state
    .set('loadingCategoryRanking', false)
    .set('successCategoryRanking', false)
    .set('errorCategoryRanking', action.error);
};

const handleGetCompaniesRanking = (state, action) => {
  return state    
    .set('loadingCompaniesRanking', true)
    .set('successCompaniesRanking', false)
    .set('errorCompaniesRanking', false);
};

const handleGetCompaniesRankingSuccess = (state, action) => {
  return state
    .set('companiesRanking', action.data)
    .set('loadingCompaniesRanking', false)
    .set('successCompaniesRanking', true)
    .set('errorCompaniesRanking', false);
};

const handleGetCompaniesRankingError = (state, action) => {
  return state
    .set('loadingCompaniesRanking', false)
    .set('successCompaniesRanking', false)
    .set('errorCompaniesRanking', action.error);
};

const handleGetCategoriesGraphic = (state) => {
  return state
    .set('loadingCategoriesGraphic', true)
    .set('successCategoriesGraphic', false)
    .set('errorCategories', false);
};

const handleGetCategoriesGraphicSuccess = (state, action) => {
  const { categoriesGraphic } = action;
  return state
    .set('categoriesGraphic', categoriesGraphic)
    .set('loadingCategoriesGraphic', false)
    .set('successCategoriesGraphic', true)
    .set('errorCategories', false);
};

const handleGetCategoriesGraphicError = (state, action) => {
  return state
    .set('loadingCategoriesGraphic', false)
    .set('successCategoriesGraphic', false)
    .set('errorCategories', action.error);
};

const handleGetCompaniesGraphic = (state) => {
  return state
    .set('loadingCompaniesGraphic', true)
    .set('successCompaniesGraphic', false)
    .set('errorCompanies', false);
};

const handleGetCompaniesGraphicSuccess = (state, action) => {
  const { companiesGraphic } = action;
  return state
    .set('companiesGraphic', companiesGraphic)
    .set('loadingCompaniesGraphic', false)
    .set('successCompaniesGraphic', true)
    .set('errorCompanies', false);
};

const handleGetCompaniesGraphicError = (state, action) => {
  return state
    .set('loadingCompaniesGraphic', false)
    .set('successCompaniesGraphic', false)
    .set('errorCompanies', action.error);
};

const handleGetSatisfactionIndexDash = (state, action) => {
  return state    
    .set('loadingSatisfactionIndexDash', true)
    .set('successSatisfactionIndexDash', false)
    .set('errorSatisfactionIndexDash', false);
};

const handleGetSatisfactionIndexDashSuccess = (state, action) => {
  return state
    .set('satisfactionIndexDash', action.data)
    .set('loadingSatisfactionIndexDash', false)
    .set('successSatisfactionIndexDash', true)
    .set('errorSatisfactionIndexDash', false);
};

const handleGetSatisfactionIndexDashError = (state, action) => {
  return state
    .set('loadingSatisfactionIndexDash', false)
    .set('successSatisfactionIndexDash', false)
    .set('errorSatisfactionIndexDash', action.error);
};

const handleGetSatisfactionIndexSummary = (state, action) => {
  return state    
    .set('loadingSatisfactionIndexSummary', true)
    .set('successSatisfactionIndexSummary', false)
    .set('errorSatisfactionIndexSummary', false);
};

const handleGetSatisfactionIndexSummarySuccess = (state, action) => {
  return state
    .set('satisfactionIndexSummary', action.data)
    .set('loadingSatisfactionIndexSummary', false)
    .set('successSatisfactionIndexSummary', true)
    .set('errorSatisfactionIndexSummary', false);
};

const handleGetSatisfactionIndexSummaryError = (state, action) => {
  return state
    .set('loadingSatisfactionIndexSummary', false)
    .set('successSatisfactionIndexSummary', false)
    .set('errorSatisfactionIndexSummary', action.error);
};

const handleGetSatisfactionIndexTickets = (state, action) => {
  return state    
    .set('loadingSatisfactionIndexTickets', true)
    .set('successSatisfactionIndexTickets', false)
    .set('errorSatisfactionIndexTickets', false);
};

const handleGetSatisfactionIndexTicketsSuccess = (state, action) => {
  return state
    .set('satisfactionIndexTickets', action.data)
    .set('loadingSatisfactionIndexTickets', false)
    .set('successSatisfactionIndexTickets', true)
    .set('errorSatisfactionIndexTickets', false);
};

const handleGetSatisfactionIndexTicketsError = (state, action) => {
  return state
    .set('loadingSatisfactionIndexTickets', false)
    .set('successSatisfactionIndexTickets', false)
    .set('errorSatisfactionIndexTickets', action.error);
};

const handleGetExcelData = (state, action) => {
  return state    
    .set('loadingExcelData', true)
    .set('successExcelData', false)
    .set('errorExcelData', false);
};

const handleGetExcelDataSuccess = (state, action) => {
  return state
    .set('excelData', action.data)
    .set('loadingExcelData', false)
    .set('successExcelData', true)
    .set('errorExcelData', false);
};

const handleGetExcelDataError = (state, action) => {
  return state
    .set('loadingExcelData', false)
    .set('successExcelData', false)
    .set('errorExcelData', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DASHBOARD: return handleGetDashboard(state, action);
    case Types.GET_DASHBOARD_SUCCESS: return handleGetDashboardSuccess(state, action);
    case Types.GET_DASHBOARD_ERROR: return handleGetDashboardError(state, action);

    case Types.GET_OPEN_TICKETS_DASH: return handleGetOpenTicketsDash(state, action);
    case Types.GET_OPEN_TICKETS_DASH_SUCCESS: return handleGetOpenTicketsDashSuccess(state, action);
    case Types.GET_OPEN_TICKETS_DASH_ERROR: return handleGetOpenTicketsDashError(state, action);

    case Types.GET_TICKETS_BY_STATUS_DASH: return handleGetTicketsByStatusDash(state, action);
    case Types.GET_TICKETS_BY_STATUS_DASH_SUCCESS: return handleGetTicketsByStatusDashSuccess(state, action);
    case Types.GET_TICKETS_BY_STATUS_DASH_ERROR: return handleGetTicketsByStatusDashError(state, action);

    case Types.GET_CONCLUSION_ON_TIME_DASH: return handleGetConclusionOnTimeDash(state, action);
    case Types.GET_CONCLUSION_ON_TIME_DASH_SUCCESS: return handleGetConclusionOnTimeDashSuccess(state, action);
    case Types.GET_CONCLUSION_ON_TIME_DASH_ERROR: return handleGetConclusionOnTimeDashError(state, action);

    case Types.GET_CLASSIFICATION_INFORMATION_DASH: return handleGetClassificationInformationDash(state, action);
    case Types.GET_CLASSIFICATION_INFORMATION_DASH_SUCCESS: return handleGetClassificationInformationDashSuccess(state, action);
    case Types.GET_CLASSIFICATION_INFORMATION_DASH_ERROR: return handleGetClassificationInformationDashError(state, action);

    case Types.GET_CATEGORY_RANKING: return handleGetCategoryRanking(state, action);
    case Types.GET_CATEGORY_RANKING_SUCCESS: return handleGetCategoryRankingSuccess(state, action);
    case Types.GET_CATEGORY_RANKING_ERROR: return handleGetCategoryRankingError(state, action);

    case Types.GET_COMPANIES_RANKING: return handleGetCompaniesRanking(state, action);
    case Types.GET_COMPANIES_RANKING_SUCCESS: return handleGetCompaniesRankingSuccess(state, action);
    case Types.GET_COMPANIES_RANKING_ERROR: return handleGetCompaniesRankingError(state, action);

    case Types.GET_CATEGORIES_GRAPHIC: return handleGetCategoriesGraphic(state);
    case Types.GET_CATEGORIES_GRAPHIC_SUCCESS: return handleGetCategoriesGraphicSuccess(state, action);
    case Types.GET_CATEGORIES_GRAPHIC_ERROR: return handleGetCategoriesGraphicError(state, action);

    case Types.GET_COMPANIES_GRAPHIC: return handleGetCompaniesGraphic(state);
    case Types.GET_COMPANIES_GRAPHIC_SUCCESS: return handleGetCompaniesGraphicSuccess(state, action);
    case Types.GET_COMPANIES_GRAPHIC_ERROR: return handleGetCompaniesGraphicError(state, action);

    case Types.GET_SATISFACTION_INDEX_DASH: return handleGetSatisfactionIndexDash(state, action);
    case Types.GET_SATISFACTION_INDEX_DASH_SUCCESS: return handleGetSatisfactionIndexDashSuccess(state, action);
    case Types.GET_SATISFACTION_INDEX_DASH_ERROR: return handleGetSatisfactionIndexDashError(state, action);

    case Types.GET_SATISFACTION_INDEX_SUMMARY: return handleGetSatisfactionIndexSummary(state, action);
    case Types.GET_SATISFACTION_INDEX_SUMMARY_SUCCESS: return handleGetSatisfactionIndexSummarySuccess(state, action);
    case Types.GET_SATISFACTION_INDEX_SUMMARY_ERROR: return handleGetSatisfactionIndexSummaryError(state, action);

    case Types.GET_SATISFACTION_INDEX_TICKETS: return handleGetSatisfactionIndexTickets(state, action);
    case Types.GET_SATISFACTION_INDEX_TICKETS_SUCCESS: return handleGetSatisfactionIndexTicketsSuccess(state, action);
    case Types.GET_SATISFACTION_INDEX_TICKETS_ERROR: return handleGetSatisfactionIndexTicketsError(state, action);

    case Types.GET_EXCEL_DATA: return handleGetExcelData(state, action);
    case Types.GET_EXCEL_DATA_SUCCESS: return handleGetExcelDataSuccess(state, action);
    case Types.GET_EXCEL_DATA_ERROR: return handleGetExcelDataError(state, action);
    
    default: return state;
  }
}
