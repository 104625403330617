import styled from 'styled-components';

export const Title = styled.div`
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
  textAlign: center;
`;

export const ContentGraphic = styled.div`
  position: relative;
`;

export const Percentage0 = styled.div`
  position: absolute;
  top: 102%;
  left: 3%;
`;
export const Percentage100 = styled.div`
  position: absolute;
  top: 102%;
  left: 78%;
  
`;