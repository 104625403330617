import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  postComentario as postComentarioSdk,
  editComentario as editComentarioSdk,
  deleteComentario as deleteComentarioSdk,
  getComentariosConteudo as getComentariosConteudoSdk,
} from '@sdk/Wikis/Comentario';

export const Types = {
  POSTING: 'WIKI_COMENTARIOS/POSTING',

  GET_COMENTARIOS_CONTEUDO: 'WIKI_COMENTARIOS/GET_COMENTARIOS_CONTEUDO',
  GET_COMENTARIOS_CONTEUDO_SUCCESS: 'WIKI_COMENTARIOS/GET_COMENTARIOS_CONTEUDO_SUCCESS',
  GET_COMENTARIOS_CONTEUDO_ERROR: 'WIKI_COMENTARIOS/GET_COMENTARIOS_CONTEUDO_ERROR',
};

export const setPosting = (value) => ({ type: Types.POSTING, value });

export const getComentariosConteudo = ({ idCategoria }) => ({ type: Types.GET_COMENTARIOS_CONTEUDO, idCategoria });
export const getComentariosConteudoSuccess = (data) => ({ type: Types.GET_COMENTARIOS_CONTEUDO_SUCCESS, data });
export const getComentariosConteudoError = (error) => ({ type: Types.GET_COMENTARIOS_CONTEUDO_ERROR, error });

export const apiPostComentario = async (data) => {
  try {
    const response = await postComentarioSdk(data);
    return response;
  } catch (err) {
    Notification.error(err.message);
  }
};

export const apiEditComentario = async (data) => {
  try {
    const response = await editComentarioSdk(data);
    return response;
  } catch (err) {
    Notification.error(err.message);
  }
};

export const apiDeleteComentario = async (data) => {
  try {
    const response = await deleteComentarioSdk(data);
    return response;
  } catch (err) {
    Notification.error(err.message);
  }
};

function* fetchGetComentariosConteudo(action) {
  try {
    const { idCategoria } = action;

    const response = yield call(getComentariosConteudoSdk, { idCategoria });
    yield put(getComentariosConteudoSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(getComentariosConteudoError(err));
  }
}

export const saga = [takeLatest(Types.GET_COMENTARIOS_CONTEUDO, fetchGetComentariosConteudo)];

// Reducer
const initialState = Map({
  posting: false,

  comentarios: List(),
  loadingComentarios: false,
  successComentarios: false,
  errorComentarios: false,
});

const handlePosting = (state, action) => {
  return state.set('posting', action.value);
};

const handleGetComentarios = (state, action) => {
  return state.set('loadingComentarios', true).set('successComentarios', false).set('errorComentarios', false);
};

const handleGetComentariosSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('comentarios', List(data))
    .set('loadingComentarios', false)
    .set('successComentarios', true)
    .set('errorComentarios', false);
};

const handleGetComentariosError = (state, action) => {
  return state.set('loadingComentarios', false).set('successComentarios', false).set('errorComentarios', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.POSTING:
      return handlePosting(state, action);

    case Types.GET_COMENTARIOS_CONTEUDO:
      return handleGetComentarios(state, action);
    case Types.GET_COMENTARIOS_CONTEUDO_SUCCESS:
      return handleGetComentariosSuccess(state, action);
    case Types.GET_COMENTARIOS_CONTEUDO_ERROR:
      return handleGetComentariosError(state, action);

    default:
      return state;
  }
}
