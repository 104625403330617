import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, OrderedMap } from 'immutable';
import { marcarLida as marcarLidaSdk } from '@sdk/Notificacoes';

//Action Types
export const Types = {
  SET_NOTIFICACOES: 'NOTIFICACOES/SET',
  SET_NOTIFICACAO: 'NOTIFICACOES/SET_NEW',

  MARCAR_LIDA: 'NOTIFICACOES/MARCAR_LIDA',
  MARCAR_LIDA_SUCCESS: 'NOTIFICACOES/MARCAR_LIDA_SUCCESS',
  MARCAR_LIDA_ERROR: 'NOTIFICACOES/MARCAR_LIDA_ERROR',
};

//Action Creators
export const setNotificacoes = (notificacoes) => ({ type: Types.SET_NOTIFICACOES, notificacoes });
export const setNotificacao = (notificacao) => ({ type: Types.SET_NOTIFICACAO, notificacao });

export const marcarLida = (id) => ({ type: Types.MARCAR_LIDA, id });
export const marcarLidaSuccess = (notificacao) => ({ type: Types.MARCAR_LIDA_SUCCESS, notificacao });
export const marcarLidaError = (error) => ({ type: Types.MARCAR_LIDA_ERROR, error });

//saga
function* fetchMarcarLida(action) {
  try {
    const { id } = action;

    yield call(marcarLidaSdk, id);
    yield put(marcarLidaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(marcarLidaError(err));
  }
}

export const saga = [
  takeLatest(Types.MARCAR_LIDA, fetchMarcarLida),
];

// Reducer
const initialState = Map({
  notificacoes: OrderedMap(),

  loadingMarcarLida: false,
  successMarcarLida: false,
  errorMarcarLida: false,
});

const handleSetNotificacoes = (state, action) => {
  return state
    .set('notificacoes', OrderedMap(action.notificacoes.map((notif) => [notif.id, notif])));
};

const handleSetNotificacao = (state, action) => {
  const { notificacao } = action;
  const notificacoes = state.get('notificacoes');

  return state
    .set('notificacoes', notificacoes.set(notificacao.id, notificacao));
};

const handleMarcarLida = (state, action) => {
  const notificacoes = state.get('notificacoes').update(action.id, (notif) => ({
    ...notif,
    lida: true,
  }));

  return state
    .set('notificacoes', notificacoes)
    .set('loadingMarcarLida', true)
    .set('successMarcarLida', false)
    .set('errorMarcarLida', false);
};

const handleMarcarLidaSuccess = (state, action) => {
  return state
    .set('loadingMarcarLida', false)
    .set('successMarcarLida', true)
    .set('errorMarcarLida', false);
};

const handleMarcarLidaError = (state, action) => {
  return state
    .set('loadingMarcarLida', false)
    .set('successMarcarLida', false)
    .set('errorMarcarLida', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_NOTIFICACOES: return handleSetNotificacoes(state, action);
    case Types.SET_NOTIFICACAO: return handleSetNotificacao(state, action);

    case Types.MARCAR_LIDA: return handleMarcarLida(state, action);
    case Types.MARCAR_LIDA_SUCCESS: return handleMarcarLidaSuccess(state, action);
    case Types.MARCAR_LIDA_ERROR: return handleMarcarLidaError(state, action);

    default: return state;
  }
}
