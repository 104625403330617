import React from 'react';
import { CloseIcon, ErrorIcon, StyledInputComponent, StyledInputPasswordComponent, TextArea as StyledTextArea } from './styles';

const StyledInput = ({ form, formFieldName, onChange, ...props }) => {
  return (
    <StyledInputComponent 
      autocomplete={false} 
      allowClear={false} 
      suffix={props.value?.length > 0 && !props.disabled ? <CloseIcon type='X' onClick={() => onChange({ target: { value: '' } })}/> : form?.getFieldError(formFieldName).length > 0 ? <ErrorIcon type='alert-circle'/> : <span/>} 
      onChange={onChange}
      {...props}
    />
  );
};

const StyledPasswordInput = ({ ...props }) => {
  return (
    <StyledInputPasswordComponent 
      {...props}
    />
  );
};

const TextArea = ({ form, formFieldName, ...props })  => (
  <StyledTextArea autoSize autocomplete={false} allowClear={false} suffix={form?.getFieldError(formFieldName).length > 0 ? <ErrorIcon type='alert-circle'/> : <span/>} {...props}/>
);

StyledInput.Search = StyledInputComponent.Search;
StyledInput.Password = StyledPasswordInput;
StyledInput.TextArea = TextArea;

export default StyledInput;