import React, { useEffect, useRef, useState } from 'react';
import { Form, Tooltip } from '@components-teammove';
import { InputStyle } from '../styles';
import { DivStyle, FormItem, TdStyle } from './styles';

export const CellValueWrapper = ({ value, children }) => (
  <DivStyle>{children || value}</DivStyle>
);

export const EditableCell = ({ setWasChanged, handleOnBlur, dataIndex, lastColumn, rowFormatted, form, record, title, index, children, ...props }) => {
  const columnKey = dataIndex.replace('_value','');
  const [ editing, setEditing ] = useState(false);
  const oldValueRef = useRef(null);

  useEffect(() => {
    const dataDestructure = rowFormatted.reduce((acc, obj) => {
      return { ...acc, [obj.importCompanyRow + '_' + dataIndex]: obj[dataIndex] };
    }, {});
    form.setFieldsValue(dataDestructure);

    oldValueRef.current = record[columnKey + '_value'];
  }, [rowFormatted]);

  const handleBlur = (e) => {
    const newValue = e.target.value;
    const oldValue = oldValueRef.current;
    setEditing(false);
    handleOnBlur(e, { ...record, [columnKey + '_value']: newValue }, columnKey, oldValue);
  };

  const handleClick = (record, dataIndex) => {
    setEditing(dataIndex);
  };

  return (
    editing && editing === dataIndex ? (
      <TdStyle cellWithError={!(record[dataIndex.replace('value', 'importCompanyError')] === null)} lastColumn={!lastColumn}  {...props}>
        <Form form={form}>
          <FormItem
            name={record.importCompanyRow + '_' + dataIndex}
          >
            <InputStyle autoFocus onBlur={(e)=> {setEditing(false);handleBlur(e);}} />
          </FormItem>
        </Form>
      </TdStyle>
    ) : (
      <Tooltip title={record[dataIndex.replace('value', 'importCompanyError')]?.description || undefined}>
        <TdStyle
          onClick={()=> handleClick(record, dataIndex)} 
          cellWithError={!(record[dataIndex.replace('value', 'importCompanyError')] === null)} lastColumn={!lastColumn}  {...props}
        >
          <Form form={form}>
            <FormItem name={record.importCompanyRow + '_' + dataIndex}>
              <CellValueWrapper />            
            </FormItem>
          </Form>
        
        </TdStyle>
      </Tooltip>
    )
    
  );
};