import styled from 'styled-components';
import { Button } from '@components-teammove';
import Text from '../Text';
import View from '../View';

export const Row = styled(View)`
  flex-direction: row;
`;

export const StyledRadioButton = styled(Button)`

`;

export const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};
  gap: ${({ vertical, buttonStyle, gap }) => buttonStyle === 'solid' ? '0' : gap || (vertical ? '20px' : '24px')};

  ${({ buttonStyle }) => buttonStyle === 'solid' && `
    >:not(:first-child):not(:last-child) ${StyledRadioButton} {
      border-radius: 0;
    }
    >:first-child ${StyledRadioButton} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    >:last-child ${StyledRadioButton} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `}

  ${({ bordered, theme }) => bordered && `
    >:not(:last-child) ${StyledRadioButton} {
      border-right: 1px solid ${theme.darkSecondaryColor};
    }
  `}
`;

export const StyledRadio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.darkPrimaryColor};
  border: 1px solid ${(props) => props.theme.darkHighlightColor};
  border-radius: 32px;
  padding: 1px;
  width: 24px;
  height: 24px;
  flex: none !important;
  cursor: pointer;
`;

export const CheckIcon = styled.div`
  background-color: ${(props) => props.theme.actionColor};
  border-radius: 32px;
  width: 12px;
  height: 12px;
`;

export const Children = styled(Text)`
  margin-left: 16px;
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
  }
`;