import React, { useEffect, useState } from 'react';
import { getTentativaConcluida, getQuiz } from '@ducks/wikisEADQuiz';
import { View, Spin, Collapse } from '@components';
import { Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { typesPergunta } from '@containers/Wikis/EAD/Quiz/rules';
import { formatDateTime, formatDecimal, prettyTimeDifference } from '@utils';
import Feedback from '@containers/Wikis/Conteudos/Visualizacao/Quiz/Feedback';
import MultiplaEscolha from './MultiplaEscolha';
import Somatoria from './Somatoria';

const MainView = styled(View)`
  height: 100%;
  width: 100%;
  padding: 10px;
`;

const VersaoETentativa = styled(View)`
  font-size: 10px;
  color: #999999;
  align-items: flex-end;
`;

const QuizRespondido = styled(View)`
  justify-content: center;
  align-items: center;
`;

const Descricao = styled(View)`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`;

const Nota = styled(View)`
  font-size: 18px;
`;

const ViewRespostas = styled(View)`
  width: 80%;
`;

const IconAcertou = styled(Icon.CheckOutlined)`
  color: #66cc66 !important;
`;

const IconErrou = styled(Icon.CloseOutlined)`
  color: #cc4444 !important;
`;

const ViewResposta = styled(View)``;

const ViewRespostasMarcadas = styled(View)`
  pointer-events: none;
`;

export default function Resultado({ data, handlers }) {
  const { id, idQuiz } = data;
  const { goToInicio, setAndamentoQuiz } = handlers;
  const dispatch = useDispatch();
  const [respostas, setRespostas] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [taxaAcerto, setTaxaAcerto] = useState(null);
  const [defaultActiveKey, setDefaultActiveKey] = useState([]);
  const [showCollapse, setShowCollapse] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const tentativaConcluida = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('tentativaConcluida'));
  const loadingTentativaConcluida = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('loadingTentativaConcluida'));
  const successTentativaConcluida = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successTentativaConcluida'));

  const successFinalizarTentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successFinalizarTentativa'));

  const quiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('quiz'));
  const successQuiz = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successQuiz'));

  useEffect(() => {
    dispatch(getTentativaConcluida(id));
  }, []);

  useEffect(() => {
    if (successFinalizarTentativa) {
      if (firstTime) {
        setFirstTime(false);
        dispatch(getQuiz(idQuiz));
      }
    }
  }, [idQuiz, successFinalizarTentativa]);

  useEffect(() => {
    successTentativaConcluida && calcularNotasEFeedback();
  }, [successTentativaConcluida]);

  useEffect(() => {
    respostas.length > 0 &&
      setDefaultActiveKey(
        tentativaConcluida.respostas &&
          tentativaConcluida.respostas.map((resp) => {
            if (respostas.length < 1) return null;

            const acertouPergunta = respostas.find((rp) => rp.id === resp.id).acertou;
            return `${resp.id}-${acertouPergunta.toString()}`;
          })
      );
  }, [respostas]);

  useEffect(() => {
    if (successQuiz) {
      if (quiz.tentativas?.length > 0) {
        const { desistencia, nota, total } = quiz.tentativas[0];
        setAndamentoQuiz({ finalizado: true, desistencia, nota, total });
      }
    }
  }, [successQuiz]);

  useEffect(() => {
    defaultActiveKey?.length > 0 && setShowCollapse(true);
  }, [defaultActiveKey]);

  const calcularNotasEFeedback = () => {
    let total = 0;
    let nota = 0;
    const computarRespostas = [];

    tentativaConcluida.respostas.forEach((resp) => {
      const { id: idResposta } = resp;
      total += resp.peso;

      if (resp.tp === typesPergunta.MULTIPLA_ESCOLHA) {
        const idRespondido = parseInt(resp.respostaMultiplaEscolha);

        if (resp.opcoes.find((op) => op.id === idRespondido).correta) {
          nota += resp.peso;
          computarRespostas.push({ id: idResposta, acertou: true });
        } else {
          computarRespostas.push({ id: idResposta, acertou: false });
        }
      } else {
        let acertou = true;
        resp.respostaSomatoria.forEach((respSomatoria) => {
          if (resp.opcoes.find((op) => op.id === parseInt(respSomatoria.id)).correta !== respSomatoria.correta) {
            acertou = false;
          }
        });

        if (acertou) {
          computarRespostas.push({ id: idResposta, acertou: true });
          nota += resp.peso;
        } else {
          computarRespostas.push({ id: idResposta, acertou: false });
        }
      }
    });

    const notaFinal = (nota / total) * 100;
    let feedbackGeral = null;

    if (tentativaConcluida.feedbacks) {
      tentativaConcluida.feedbacks
        .sort((a, b) => b.prMaximo - a.prMaximo)
        .forEach((fb) => {
          if (notaFinal <= fb.prMaximo) {
            feedbackGeral = fb;
          }
        });
    }

    setFeedback(feedbackGeral);
    setTaxaAcerto(notaFinal);
    setRespostas(computarRespostas);
  };

  return loadingTentativaConcluida ? (
    <View justifyContent='center' alignItems='center'>
      <Spin />
    </View>
  ) : (
    successTentativaConcluida && (
      <MainView>
        <View horizontal justifyContent='space-between'>
          <Icon.ArrowLeftOutlined onClick={goToInicio} />
          <VersaoETentativa>
            <View>Versão do quiz: {tentativaConcluida.versao}</View>
            <View>Tentativa: {tentativaConcluida.tentativa}</View>
          </VersaoETentativa>
        </View>
        <QuizRespondido>
          <Nota>Resultados do quiz: </Nota>
          <Descricao>{tentativaConcluida.descricao}</Descricao>
          <Nota>Taxa de acerto:&nbsp;{formatDecimal(taxaAcerto, 2)}%</Nota>{' '}
          <View>Início: {formatDateTime(tentativaConcluida.dtInicio)}</View>
          <View>Fim: {formatDateTime(tentativaConcluida.dtFim)}</View>
          <View>Duração: {prettyTimeDifference(tentativaConcluida.dtInicio, tentativaConcluida.dtFim).extenseDifference}</View>
          {feedback && <Feedback width='80%' data={{ feedback: feedback.feedback, cor: feedback.cor }} />}
          <h3>Respostas </h3>
          <ViewRespostas>
            {showCollapse && (
              <Collapse
                expandIcon={({ panelKey }) => (panelKey.split('-')[1] === 'true' ? <IconAcertou /> : <IconErrou />)}
                defaultActiveKey={defaultActiveKey}
              >
                {tentativaConcluida.respostas.map((resp, index) => {
                  if (respostas.length < 1) return null;

                  const acertouPergunta = respostas.find((rp) => rp.id === resp.id).acertou;
                  const isMultiplaEscolha = resp.tp === typesPergunta.MULTIPLA_ESCOLHA;

                  return (
                    <Collapse.Panel
                      header={<strong>{`${index + 1}. ${resp.pergunta}`}</strong>}
                      key={`${resp.id}-${acertouPergunta.toString()}`}
                    >
                      <ViewResposta>
                        <ViewRespostasMarcadas>
                          {isMultiplaEscolha ? (
                            <MultiplaEscolha
                              data={{
                                opcoes: resp.opcoes,
                                valor: parseInt(resp.respostaMultiplaEscolha),
                              }}
                            />
                          ) : (
                            <Somatoria data={{ opcoes: resp.opcoes, valores: resp.respostaSomatoria }} />
                          )}
                          <Feedback
                            width='95%'
                            data={{ positivo: acertouPergunta, feedback: acertouPergunta ? resp.feedbackPositivo : resp.feedbackNegativo }}
                          />
                        </ViewRespostasMarcadas>
                      </ViewResposta>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            )}
          </ViewRespostas>
        </QuizRespondido>
      </MainView>
    )
  );
}
