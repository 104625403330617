import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from '@components-teammove';

const ModalDiaCopia = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({ diaSemana: visible });
    }
  }, [visible]);

  return(
    <Modal visible={visible ? true : false} title="Copiar horários para" okText="Copiar" onOk={() => form.submit()} onCancel={onCancel}>
      <Form layout="vertical" name="diaCopiaForm" form={form} hideRequiredMark>
        <Form.Item name="diaSemana" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="diaSelecionado" label="Dia" extra="Os dados do dia selecionado serão substituídos ao copiar" rules={[{ required: true, message: 'Selecione o dia' }]}>
          <Select placeholder='Selecione o dia'>
            <Select.Option value={'Segunda-Feira'} disabled={'Segunda-Feira' === visible}>Segunda-Feira</Select.Option>
            <Select.Option value={'Terça-Feira'} disabled={'Terça-Feira' === visible}>Terça-Feira</Select.Option>
            <Select.Option value={'Quarta-Feira'} disabled={'Quarta-Feira' === visible}>Quarta-Feira</Select.Option>
            <Select.Option value={'Quinta-Feira'} disabled={'Quinta-Feira' === visible}>Quinta-Feira</Select.Option>
            <Select.Option value={'Sexta-Feira'} disabled={'Sexta-Feira' === visible}>Sexta-Feira</Select.Option>
            <Select.Option value={'Sábado'} disabled={'Sábado' === visible}>Sábado</Select.Option>
            <Select.Option value={'Domingo'} disabled={'Domingo' === visible}>Domingo</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalDiaCopia;
