import * as moment from 'moment';
// eslint-disable-next-line import/no-unassigned-import
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const DATE_FORMATS = {
  DATE: 'DD/MM/YYYY',
  TIME: 'HH:mm',
  TIME_WITH_SECONDS: 'HH:mm:ss',
  DATETIME: 'DD/MM/YYYY HH:mm',
  TIMESTAMP: 'DD/MM/YYYY HH:mm:ss',
  ANSI: 'YYYY-MM-DD HH:mm:ss.SSSS',
  ANSI_DATE: 'YYYY-MM-DD',
  ANSI_DATETIME: 'YYYY-MM-DDTHH:mm:ss-0300',
  MINUTES: 'mm:ss',
  DATE_STRING: 'LL'
};

export const formatDate = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.DATE);
};

export const formatTime = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.TIME);
};

export const formatTimeWithSeconds = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.TIME_WITH_SECONDS);
};

export const formatDateTime = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.DATETIME);
};

export const formatTimestamp = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.TIMESTAMP);
};

export const formatDateString = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).format(DATE_FORMATS.DATE_STRING);
};

export const formatDateStringWithTime = (date) => {
  if (!date) return null;
  return `${moment(date, DATE_FORMATS.ANSI).date()} de ${moment(date, DATE_FORMATS.ANSI).format('MMM')} às ${moment(date, DATE_FORMATS.ANSI).format('HH:mm')}`;
};

export const dateToAnsi = (date, format, formatAnsi = DATE_FORMATS.ANSI) => {
  if (!date) return null;
  return moment(date, format).format(formatAnsi);
};

export const dateToAnsiDate = (date, format, formatAnsi = DATE_FORMATS.ANSI_DATE) => {
  if (!date) return null;
  return moment(date, format).format(formatAnsi);
};

export const ansiToDate = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI).toDate();
};

export const ansiToMoment = (date) => {
  if (!date) return null;
  return moment(date, DATE_FORMATS.ANSI);
};

export const getMeses = () => {
  return moment.monthsShort();
};

export const getNomeMes = (month) => {
  return month[0].toUpperCase() + month.slice(1);
};

export const getMonthNameByNumber = (monthNumber) => {
  return moment().month(monthNumber - 1).format('MMM').charAt(0).toUpperCase() + moment().month(monthNumber - 1).format('MMM').slice(1);
};

export const getCurrentMonth = () => {
  return moment().get('month');
};

export const getNomeMesAtual = () => {
  return getNomeMes(moment().format('MMMM'));
};

export const getNomeMesData = (data) => {
  return getNomeMes(moment(data).format('MMMM'));
};

export const getNomeDia = (date) => {
  return date.format('ddd');
};

export const getNomeDiaCompleto = (date) => {
  return date.format('dddd');
};

export const getNomeDiaSemiCompleto = (date) => {
  return date.format('dddd').split('-')[0];
};

export const getDiffDays = (date) => {
  return moment().diff(date, 'days') + 1;
};

export const formatDateExtenso = (date, format = DATE_FORMATS.ANSI) => {
  if (!date) return null;

  const data = moment(date, format);
  const semHora = data.format('HH:mm') === '00:00';

  return semHora ? data.format('dddd, DD [de] MMMM [de] YYYY') : data.format('dddd, DD [de] MMMM [de] YYYY [às] HH:mm');
};

export const formaDateToNow = (date, format = DATE_FORMATS.ANSI, extenso = false) => {
  if (!date) return null;
  const data = moment(date, format);
  const semHora = data.format('HH:mm') === '00:00';

  if (data.isSame(moment(), 'day')) {
    return semHora ? 'Hoje' : data.format('HH:mm');
  } else if (data.isSame(moment().subtract(1, 'day'), 'day')) {
    return semHora ? 'Ontem' : data.format('[Ontem às] HH:mm');
  } else if (data.isSame(moment(), 'week')) {
    return semHora ? data.format('dddd') : data.format('dddd [às] HH:mm');
  } else {
    if (!extenso) {
      return semHora ? data.format('DD/MM/YYYY') : data.format('DD/MM/YYYY [às] HH:mm');
    } else {
      return formatDateExtenso(date, format);
    }
  }
};

export const getCurrentMomentStartOfMonth  = (format = DATE_FORMATS.ANSI_DATE) => {
  return moment().startOf('month').format(format);
};

export const compareDates = (date1, date2) => {
  return moment(date1).isSame(date2);
};

export const lastDayMonth = (ano, mes) => {
  return moment(new Date (ano, mes, 0)).endOf('month').date();
};

export const fromNow = (date) => {
  return moment(date).fromNow();
};

export const getCurrentYear = () => {
  return moment().format('YYYY');
};

export const prettyTimeDifference = (dt1, dt2 = moment().toDate()) => {
  if (typeof dt1 === 'string') {
    dt1 = new Date(dt1);
  }

  if (typeof dt2 === 'string') {
    dt2 = new Date(dt2);
  }

  let difference = dt2.getTime() - dt1.getTime();

  const yearsDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 30 / 12);
  difference -= yearsDifference * 1000 * 60 * 60 * 24 * 30 * 12;

  const monthsDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 30);
  difference -= monthsDifference * 1000 * 60 * 60 * 24 * 30;

  const weeksDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 7);

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);

  difference = [];

  if (yearsDifference > 0) {
    difference.push(`${yearsDifference} ${yearsDifference > 1 ? 'anos' : 'ano'}`);
  }

  if (monthsDifference > 0) {
    difference.push(`${monthsDifference} ${monthsDifference > 1 ? 'meses' : 'mês'}`);
  }

  if (weeksDifference > 0) {
    difference.push(`${weeksDifference} ${weeksDifference > 1 ? 'semanas' : 'semana'}`);
  }

  if (daysDifference > 0) {
    difference.push(`${daysDifference} ${daysDifference > 1 ? 'dias' : 'dia'}`);
  }

  if (hoursDifference > 0) {
    difference.push(`${hoursDifference} ${hoursDifference > 1 ? 'hr' : 'hrs'}`);
  }

  if (minutesDifference > 0) {
    difference.push(`${minutesDifference}min`);
  }

  if (secondsDifference > 0) {
    difference.push(`${secondsDifference}seg`);
  }

  let extenseDifference = difference.join(', ');
  const index = extenseDifference.lastIndexOf(', ');
  extenseDifference = extenseDifference.slice(0, index) + extenseDifference.slice(index).replace(', ', ' e ');

  return {
    extenseDifference,
    difference,
    yearsDifference,
    monthsDifference,
    weeksDifference,
    daysDifference,
    hoursDifference,
    minutesDifference,
    secondsDifference,
  };
};

export default moment;
