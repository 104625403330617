import styled from 'styled-components';

export const CardStyled = styled.div`
  flex: 1;
  border-radius: 18px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${(props) => props.bgColor} !important;
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const Total = styled.span`
  font-size: 32px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;