const { REACT_APP_API } = window.env;

const SRV_FOLDER = '/opt/jboss/wildfly/standalone/data';

export const getUrlAvatar = (url) => {
  return url && url?.startsWith('https') ? url : getDefaultAvatar();
};

export const getDownloadUrlAvatar = (avatar, image = 'S') => {
  if (!avatar) return getDefaultAvatar();
  
  if (avatar.startsWith('https')) return avatar;

  return `${REACT_APP_API}/download?caminhoArquivo=${SRV_FOLDER}${avatar}&isImage=${image}`;
};

export const getDefaultAvatar = () => 'https://media-teammove.s3-sa-east-1.amazonaws.com/Users/ab15ec02-9c1a-4fee-a16d-cb1bdb0dd4e8.png';