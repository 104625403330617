export const TYPE_ASK = {
  TEXTO: 'TEXTO',
  ESCOLHA_UNICA: 'ESCOLHA_UNICA',
  CAIXA_SELECAO: 'CAIXA_SELECAO',
  ANEXO: 'ANEXO',
  DATA: 'DATA',
  NUMERICO: 'NUMERICO',
  PARAGRAFO: 'PARAGRAFO'
};

export const getIconFromExtension = (item) => {
  const slicedFile = item.slice(-3);

  if(slicedFile === 'mp4') {
    return 'film';
  } else if(slicedFile === 'mp3') {
    return 'mic';
  }else {
    return 'file';
  }
};