import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@components-teammove';
import { postAceito, postAceitoSuccess, apiPostNewAudiencia, postLido } from '@ducks/wikisAcoes';
import { formatTimeWithSeconds } from '@utils/';
import { ButtonConfirmacao, Container } from './styles';
import { Row } from '../styles';

export default function VisualizacaoOpcoes({ item }) {
  const dispatch = useDispatch();
  const [bloqueia, setBloqueia] = useState(false);

  const aceito = useSelector(({ wikisAcoes }) => wikisAcoes.get('aceito'));
  const loadingAceito = useSelector(({ wikisAcoes }) => wikisAcoes.get('loadingAceito'));

  const inicioAudiencia = new Date();

  const criarAudiencia = (id) => {
    apiPostNewAudiencia({
      idCategoria: id,
      horaInicio: formatTimeWithSeconds(inicioAudiencia),
      horaFim: formatTimeWithSeconds(new Date()),
      tp: 'C',
    });
  };

  useEffect(() => {
    if (item && item.size > 0) {
      const permissoes = item.get('permissoes');
      if (!permissoes) return;
      setBloqueia(permissoes.bloqueiaApp);

      dispatch(postAceitoSuccess(item.get('aceito')));
    }
  }, [item]);

  const handleConfirmacao = (id) => {
    dispatch(postAceito(id, true));
    dispatch(postLido(id, true));
    criarAudiencia(id);
  };

  return (
    <>
      {bloqueia && !aceito && (
        <Container>
          <Row align='center' gap='4px'>
            <Icon.Feather type='alert-triangle' />
            Este comunicado exige confirmação de leitura
          </Row>
          <ButtonConfirmacao type='primary' size='small' onClick={() => handleConfirmacao(item.get('id'))} disabled={loadingAceito}>
            Estou ciente
          </ButtonConfirmacao>
        </Container>
      )}
    </>
  );
}
