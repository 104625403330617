import React, { useMemo, useState } from 'react';
import { Map, View } from '@components-teammove';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { median } from '@utils';
import { Container } from '../../../UsersMap/styles';
import { TextInfo } from './styles';

export default function ActivitiesRoutesMap({ user }) {
  const [activeMarker, setActiveMarker] = useState();

  const mapMarkers = useMemo(() => {
    const userPlace = user.userPlace && { id: user.userPlace?.id, latitude: user.userPlace?.latitude, longitude: user.userPlace?.longitude, companyName: user.userPlace?.name, companyCity: user.userPlace?.city, companyState: user.userPlace?.state };

    return user && [userPlace, ...(user.tasks || [])].filter((location) => location)?.map(({ id, latitude, longitude, status, active, companyName, companyShortName, companyCity, companyState }, index) => {
      const icon = index === 0 && userPlace ? 
        'https://res.cloudinary.com/teammove/image/upload/v1566858204/mapa/home-account.png' : 
        (() => {
          if (status === 'CONCLUIDA' && active) {
            return 'https://res.cloudinary.com/teammove/image/upload/v1568060801/mapa/map-marker-check-green-40x40.png';
          } else if (!active) {
            return 'https://res.cloudinary.com/teammove/image/upload/v1568059928/mapa/map-marker-remove-variant-red-40x40.png';
          } else {
            return 'https://res.cloudinary.com/teammove/image/upload/v1568059928/mapa/map-marker-alert-grey-40x40.png';
          }
        })();

      return {
        position: { lat: parseFloat(latitude) || 0, lng: parseFloat(longitude) || 0 },
        icon,
        identifier: companyShortName,
        name: companyName,
        city: companyCity,
        state: companyState,
        key: id
      };
    });
  }, [user]);

  const userLocations = useMemo(() => {
    let tamLocalizacoes = user?.userLocations?.length || 0;
    let refFirstIcon = tamLocalizacoes / 4;
    let refSecondIcon = refFirstIcon * 2;
    let refThirdIcon = refFirstIcon * 3;
    
    if (tamLocalizacoes < 4) {
      refFirstIcon = 4;
    }

    return user.userLocations && user.userLocations.map((location, index) => {
      let icon = '';
      if (index <= refFirstIcon) {
        icon = 'https://res.cloudinary.com/teammove/image/upload/v1593006214/mapa/brightness-blue-medium-004ba0.png';
      } else if (index <= refSecondIcon) {
        icon = 'https://res.cloudinary.com/teammove/image/upload/v1593006215/mapa/brightness-blue-medium-1976d2.png';
      } else if (index <= refThirdIcon) {
        icon = 'https://res.cloudinary.com/teammove/image/upload/v1593006214/mapa/brightness-purple-medium-8e24aa.png';                    
      } else {
        icon = 'https://res.cloudinary.com/teammove/image/upload/v1593006215/mapa/brightness-purple-medium-c158dc.png';                    
      }

      const dateString = location.date;
      const formattedTime = new Date(dateString).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
      
      return {
        position: {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude)
        },
        icon,
        key: location.id,
        date: formattedTime
      };
    });
  }, [user]);
  
  const mapCenter = useMemo(() => {
    return mapMarkers && {
      lat: median([...mapMarkers, ...(userLocations && userLocations?.length > 0 ? userLocations : [])].map(({ position }) => parseFloat(position.lat))),
      lng: median([...mapMarkers, ...(userLocations && userLocations?.length > 0 ? userLocations : [])].map(({ position }) => parseFloat(position.lng)))
    };
  }, [mapMarkers, userLocations]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  
  return (
    <Container>
      <Map
        center={isNaN(mapCenter?.lat) || isNaN(mapCenter?.lng) ? { lat: 0, lng: 0 } : mapCenter} 
        zoom={10} 
        mapStyles={{ height: '450px', width: '100%', borderRadius: '12px', marginBottom: '12px' }} 
        markers={mapMarkers}
        showMarkersRoutes={user.userPlace && user.tasks?.length > 0}
        extraMapItem={user.userLocations?.length > 0 && (
          <>
            {userLocations.map((marker) => (
              <Marker 
                key={marker.key} 
                position={{ lat: marker.position.lat, lng: marker.position.lng }}
                options={{ icon: marker.icon }}
                onClick={() => handleActiveMarker(marker.key)}
              >
                {activeMarker === marker.key ? (
                  <InfoWindow>
                    <View onClick={marker.infoWindowClickHandler && (() => marker.infoWindowClickHandler())}>
                      {marker.identifier ? <>
                        <TextInfo strong>{marker.identifier}</TextInfo>
                        <br/><TextInfo>{marker.name}</TextInfo>
                        <br/><TextInfo>{marker.city}, {marker.state}</TextInfo> </> : <TextInfo>Localização das {marker.date}</TextInfo>
                      }
                    </View>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </>
        )}
        // extraMapItem={user.userLocations?.length > 0 && (
        //   <>
        //     <DirectionsService
        //       // required
        //       options={{ 
        //         destination: `${String(user.userLocations[user.userLocations.length - 1].position.lat + 1)}, ${String(user.userLocations[user.userLocations.length - 1].position.lng + 1)}`,
        //         origin: `${String(user.userLocations[0].position.lat + 1)}, ${String(user.userLocations[0].position.lng + 1)}`,
        //         travelMode: 'DRIVING',
        //         waypoints: user.userLocations
        //           .slice(1, user.userLocations.length - 1)
        //           .map(({ position }) => ({ location: `${String(position.lat + 1)}, ${String(position.lng + 1)}` }))
        //       }}
        //       // required
        //       callback={directionsCallback}
        //     />

      //     {directionsResponse !== null && (
      //       <DirectionsRenderer
      //       // required
      //         options={{ 
      //           directions: directionsResponse,
      //           suppressMarkers: true,
      //           polylineOptions: {
      //             strokeColor: '#FF00FF'
      //           }
      //         }}
      //       />
      //     )}
      //   </>
      // )}
      />
    </Container>
  );
}