import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Skeleton, Form, Input } from '@components-teammove';
import { getAgrupador, saveAgrupador, resetSuccess, deleteAgrupador } from '@ducks/configuracoesUnidadesAgrupadores';
import { useDispatch, useSelector } from 'react-redux';
import { TelasNomenclaturas } from '@utils';

export default function AgrupadoresCadastro({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const agrupador = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupador'));
  const loading = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loading'));
  const success = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('success'));
  const loadingSave = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loadingSave'));
  const successSave = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successDelete'));

  const [originalFieldsValue, setOriginalFieldsValue] = useState();

  const id = location.state?.agrupador.id;

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    if (id) handleGetAgrupador();

    return () => {
      dispatch(resetSuccess());
      if (id) {
        history.goBack();
      }
    };
  }, []);

  useEffect(() => {
    if (success && id) {
      form.setFieldsValue(agrupador);
      setOriginalFieldsValue(form.getFieldsValue());
    }
  }, [success]);

  useEffect(() => {
    if (successSave || successDelete) {
      onCancel();
    }
  }, [successSave, successDelete]);

  const handleGetAgrupador = () => {
    dispatch(getAgrupador(id));
  };

  const handleDelete = ({ id }) => {
    dispatch(deleteAgrupador(id));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      if (id) values = { ...values, id };
      dispatch(saveAgrupador(values));
    });
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Configurações / ' + (companyNomenclature.plural) + ' / Agrupadores'}
      numberOfButtons={id ? 2 : 1}
      cancelText='Excluir'
      onBeforeCancel={onBeforeCancel}
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir o agrupador?', onConfirm: () => handleDelete(agrupador) }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null, title: agrupador?.inUseByCompanies ? `Agrupador em uso por ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ou mais ${companyNomenclature.plural}` : 'Excluir agrupador', disabled: agrupador?.inUseByCompanies }}
      onOk={handleSalvar}
      okButtonProps={{ loading: loadingSave }}
    >
      {loading ?
        <Skeleton.Form />
        : (
          <Form layout='vertical' form={form}>
            <Form.Item label='Nome do agrupador' name='description' rules={[{ required: true, message: 'Informe o agrupador!' }]}>
              <Input placeholder='Informe o agrupador' autoFocus/>
            </Form.Item>

          </Form>
        )}
    </Modal>
  );
}