import { Form } from 'antd';
import View from '../View';
import styled from 'styled-components';

const FormProvider = Form.Provider;

const FormItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    max-width: 100%;
  }
`;

const Field = styled(FormItem)`
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
  .ant-input-number {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-right: 15px;
  flex: ${({ flex }) => (flex ? flex : 1)};
`;

const GroupField = styled(View)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  div:first-child {
    margin-left: 0px;
  }
`;

Form.Item = FormItem;
Form.Provider = FormProvider;

export { Form, Field, GroupField };
