import React, { useEffect, useState } from 'react';
import App from '@app'; 
import { useDispatch, useSelector } from 'react-redux';
import { Body, PageHeader, Button, Breadcrumb, Form, Column, Row, Input, Text, Checkbox, Select, Modal, View, Skeleton, Icon } from '@components-teammove';
import { useHistory } from 'react-router-dom';
import { CenteredButton, CheckboxContainer, CheckboxesContainer, ColFormItem, ColoredDivider, FieldContainer, FieldTitle, FixedLabelFormItem, FlexContainer, FlexContainerWithMargin, FlexFormItem, MarginLeft } from './styles';

import { getExtraFields, postExtraFields, putExtraFields, reset } from '@ducks/settingsProductsExtraFields';
import { initialValues } from './rules';

export default function ConfiguracoesCamposPersonalizadosCadastro() {
  const [formExtraFields] = Form.useForm();
  const [formNewExtraField] = Form.useForm();
  
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useSelector(({ app })=> app.get('theme'));

  const [newExtraFieldModal, setNewExtraFieldModal] = useState(false);

  const extraFields = useSelector(({ extraFields }) => extraFields.get('extraFields'));
  const loadingExtraFields = useSelector(({ extraFields }) => extraFields.get('loadingExtraFields'));
  const successExtraFields = useSelector(({ extraFields }) => extraFields.get('successExtraFields'));

  const loadingExtraFieldsSave = useSelector(({ extraFields }) => extraFields.get('loadingExtraFieldsSave'));
  const successExtraFieldsSave = useSelector(({ extraFields }) => extraFields.get('successExtraFieldsSave'));

  useEffect(() => {
    dispatch(getExtraFields());
  },[]);

  useEffect(() => {
    formNewExtraField.setFieldsValue(initialValues);
    if(successExtraFields) {
      formExtraFields.setFieldsValue(extraFields.reduce((state, extraField) => {
        return { ...state, ...Object.keys(extraField).reduce((keysState, key) => ({ ...keysState, [`${extraField.id}-${key}`]: extraField[key] }), {}) };
      }, {}));
    }
    
  }, [successExtraFields]);

  useEffect(() => {
    if(successExtraFieldsSave) {
      dispatch(getExtraFields());
      setNewExtraFieldModal(false);
      history.goBack();
      dispatch(reset());
    }
    
  },[successExtraFieldsSave]);

  const handleNewDynamicField = () => { 
    
    formNewExtraField.validateFields().then((values) => {
      const updatedCampoDinamico = { ...values };
      
      Object.keys(updatedCampoDinamico).forEach((key) => {
        if (updatedCampoDinamico[key] === undefined) updatedCampoDinamico[key] = false;
      });
      
      dispatch(postExtraFields(updatedCampoDinamico));
      formNewExtraField.resetFields();
      formNewExtraField.setFieldsValue(initialValues);
      
    }).catch(Form.scrollToFirstError);
  };

  const handleSave = async () => {
    const extraFieldsValues = await formExtraFields.validateFields();
    
    const extraFieldsToSend = extraFields.map((extraField) => {
      const extraFieldKeys = Object.keys(extraFieldsValues).filter((value) => parseInt(value.split('-')[0]) === extraField.id);
      return extraFieldKeys.reduce((state, key) => ({ ...extraFields.find(({ id }) => id === parseInt(key.split('-')[0])), ...state, [key.split('-')[1]]: extraFieldsValues[key] }), {});
    });

    dispatch(putExtraFields(extraFieldsToSend));
  };
  
  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Campos Personalizados"
          extra={[
            <Button key='1' type='secondary' size='small' context='header' onClick={() => history.goBack()}>
                Cancelar
            </Button>,
            <Button key='2' type='primary' size='small' context='header'  onClick={handleSave} loading={loadingExtraFieldsSave}>
                Salvar
            </Button>
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: 'configuracoes/mixprodutos', name: 'Configurações' },
                { path: 'configuracoes/mixprodutos', name: 'Configurações de Mix de Produtos' },
                { path: 'configuracoes/mixprodutos/cadastro', name: 'Campos Personalizados', current: true }
              ]}
            />
          )}
        >
          <Form.Provider>
            <Column>
              <Column gap='12px'>
                {loadingExtraFields && <Skeleton.Form/>}
                <Form layout='vertical' form={formExtraFields} name='customFields'>
                  <Column gap='12px'>
                    {extraFields.map((extraField)=>(
                      <FieldContainer key={extraField.id}>
                        <Row gap='4px' align='center'>
                          <FieldTitle>{extraField.name}</FieldTitle>
                          <ColoredDivider/>
                        </Row>
                        <Column>
                          <Column gap='1.25rem'>
                            <MarginLeft>
                              <Form.Item label='Nome' name={`${extraField.id}-name`} rules={[{ required: true, message: 'Informe o campo personalizado!' }]} colon={false}>
                                <Input placeholder='Informe o campo personalizado'/>
                              </Form.Item>
                            </MarginLeft>
                          </Column>
                         
                          <CheckboxesContainer>
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${extraField.id}-active`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Ativo</Text>
                              </MarginLeft>
                            </CheckboxContainer>

                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${extraField.id}-required`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Obrigatório</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                  
                            <CheckboxContainer>
                              <MarginLeft>
                                <FlexFormItem name={`${extraField.id}-highlight`} valuePropName='checked'>
                                  <Checkbox/>
                                </FlexFormItem>
                                <Text>Aparece nas Informações de Produto</Text>
                              </MarginLeft>
                            </CheckboxContainer>
                            
                          </CheckboxesContainer>
                        </Column>
                      </FieldContainer>
                    ))}
                  </Column>
                  <View>
                    <CenteredButton type='secondary' icon={<Icon.PlusOutlined/>} onClick={() => setNewExtraFieldModal(!newExtraFieldModal)}>Adicionar campo</CenteredButton>
                  </View>
                  <Modal
                    visible={newExtraFieldModal}
                    onCancel={()=>{
                      setNewExtraFieldModal(false);
                    }}
                    onOk={handleNewDynamicField}
                    okButtonProps={{ loading: loadingExtraFieldsSave }}
                  >
                    <Form layout='vertical' form={formNewExtraField}>
                      <FixedLabelFormItem label='Tipo' name='kind' rules={[{ required:true, message:'Informe o tipo!' }]}>
                        <Select>
                          <Select.Option value='TEXT'>Texto</Select.Option>
                          <Select.Option value='PARAGRAPH'>Parágrafo</Select.Option>
                        </Select>
                      </FixedLabelFormItem>

                      <FlexContainerWithMargin>
                        <ColFormItem label='Campo Personalizável' name='name' rules={[{ required: true, message:'Informe o nome do campo!' }]}>
                          <Input placeholder='Informe o nome do campo'/>
                        </ColFormItem>
                      </FlexContainerWithMargin>        

                      <Form form={formNewExtraField}>
                        <FlexContainer>
                          <Form.Item name='required' label='Obrigatório' valuePropName='checked' labelSide='right' marginless>
                            <Checkbox/> 
                          </Form.Item>
                        </FlexContainer>

                        <FlexContainer>
                          <Form.Item name='highlight' label='Aparece nas Informações de Produto' valuePropName='checked' labelSide='right'>
                            <Checkbox/>
                          </Form.Item>
                        </FlexContainer>
                      </Form>
                    </Form>
                  </Modal>
                </Form>
              </Column>
            </Column>
          </Form.Provider>
        </PageHeader>
      </Body>
    </App>
  );
}