import React from 'react';
import { StyledCollapse } from './styles';

const Collapse = ({ children, ...props }) => (
  <StyledCollapse {...props}>
    {children}
  </StyledCollapse>
);

Collapse.Panel = ({ children, ...props }) => (
  <StyledCollapse.Panel {...props}>
    {children}
  </StyledCollapse.Panel>
);

export default Collapse;