import styled from 'styled-components';

export const ContentNumber = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  ${({ active })=> active && 'border: 2px solid var(--success-color)'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  width: 100px;
  height: 2px;
  background-color: ${({ theme }) => theme.darkHighlightColor};
`;