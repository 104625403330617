export const capitalize = (string)=> {
  
  if (string.length > 0) {
    const stringInToLowerCase = string.toLowerCase();
    return stringInToLowerCase.charAt(0).toUpperCase() + stringInToLowerCase.slice(1);
  } else {
    return string;
  }
};

export const capitalizeWordsAndRemoveUnderline = (str) => {
  let words = str.toLowerCase().replace(/_/g, ' ').split(' ');

  for (let index = 0; index < words.length; index++) {
    words[index] = words[index].charAt(0).toUpperCase() + words[index].slice(1);
  }
  
  return words.join(' ');
};