import styled, { css } from 'styled-components';
import { View, Text } from '@components';

export const UsuarioContainer = styled(View)`
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 5px;
  border-left: 3px solid ${(props) => props.bordercolor || '#CCC'};
`;

export const Foto = styled(View)`
  justify-content: center;
`;

export const ViewUsuario = styled(View)`
  flex-direction: row;
  margin: 5px 10px;
  flex: 2;
  ${(props) =>
  !props.acessado &&
    css`
      opacity: 0.7;
    `};
`;

export const Nome = styled(Text)`
  padding-left: 5px;
  font-weight: 500;
  font-size: 16px;
`;

export const Perfil = styled(Text)`
  padding-left: 5px;
`;

export const ViewAcessoDados = styled(View)`
  align-items: center;
`;

export const ViewDadoAcesso = styled(View)`
  flex: 1;
  justify-content: center;
`;

export const ViewAcessos = styled(View)`
  flex-direction: row;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.darkPrimaryColor};
`;

export const CaminhoCategoriaView = styled(Text)`
  &.ant-typography {
    flex: 1;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const CategoriaView = styled(View)`
  font-size: 20px;
  color: ${({ theme }) => theme.textColor};
`;

export const CaminhoView = styled(View)`
  font-size: 12px;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const ViewResumo = styled(View)`
  flex-direction: row;
  margin-bottom: 10px;
`;

export const ViewResumoTotalAcesso = styled(View)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.darkPrimaryColor};
`;
export const ViewResumoAcessados = styled(View)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;
export const ViewResumoTitulo = styled(View)`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;
export const ViewResumoValor = styled(View)`
  font-size: 40px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;
export const ViewResumoPercentual = styled(View)`
  font-size: 16px;
  font-weight: 400;
  margin-top: -10px;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;