import React from 'react';
import { Modal, Form, Input } from '@components-teammove';

export default function ModalAguardandoResposta ({ visible, onCancel, loading, onOk }) {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onOk(values);
      resetFields();
    }).catch((err) => console.error(err.message));
  };

  const handleCancel = () => {
    resetFields();
    onCancel();
  };

  const resetFields = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      title="Pendência"
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Mensagem" name="mensagem" rules={[ { required: true, message: 'Informe uma mensagem' } ]}>
          <Input.TextArea autoFocus/>
        </Form.Item>    
      </Form>   

    </Modal>    
  );
}