import styled from 'styled-components';
import { Text } from '@components-teammove';

export const NumberContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    border: 2px solid ${({ theme }) => theme.darkPrimaryColor};
    opacity: ${({ disabledSpecialNumbers }) => disabledSpecialNumbers ? 0.5 : 1};
    cursor: ${({ disabledSpecialNumbers }) => disabledSpecialNumbers ? 'auto' : 'pointer'};
`;

export const Numbers = styled(Text)`
    font-size: 20px;
    font-weight: 700;
    line-height: 27.24px;
    color: ${({ theme }) => theme.textColor};
`;

export const Letters = styled(Text)`
    color: ${({ theme }) => theme.textColor};
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    opacity: 0.5;
`;