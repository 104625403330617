import { InfoUsuario } from '@utils';

export const queryParamsConteudos = (filtros) => {
  if (!filtros) return '';

  const query = [];
  const { descricao, lidos, favoritos, comunicados, grupos, inicio, fim } = filtros;

  if (descricao) query.push(`descricao=${descricao}`);
  if (lidos && lidos !== 'SN') query.push(`lidos=${lidos}`);
  if (favoritos) query.push(`favoritos=${favoritos}`);
  if (grupos) query.push(`grupos=${grupos}`);
  if (inicio) query.push(`inicio=${inicio}`);
  if (fim) query.push(`fim=${fim}`);
  if (InfoUsuario.permExtras('gruposComunicado')) {
    query.push(`comunicados=${comunicados ? 'S' : 'N'}`);
  }
  if (InfoUsuario.permExtras('gruposEAD')) {
    query.push('ead=N');
  }

  if (query.length === 0) return '';
  return '&' + query.join('&');
};
