import { get, post } from '../api';
import { formatParams, newFormatParams } from './rules';
import { InfoUsuario } from '@utils';

export const getAtividadesChecklists = (params, loaders) => {
  return get(`/api/checklist/ppt/?${formatParams({ ...params,...loaders })}`);
};

export const getChecklists = (params, loaders) => {
  const useNode = true;
  return get(`/api/checklist?searchWithPhotosOnly=false&loggedUser=${InfoUsuario.get('cdUsuario')}&${newFormatParams({ ...params,...loaders })}`, {}, useNode);
};

export const getCountChecklist = (params) => {
  const useNode = true;
  return get(`/api/checklist/count?searchWithPhotosOnly=false&loggedUser=${InfoUsuario.get('cdUsuario')}&${newFormatParams(params)}`, {}, useNode);
};

export const getCountAcceptance = (params) => {
  const useNode = true;
  return get(`/api/checklist/count/acceptance?loggedUser=${InfoUsuario.get('cdUsuario')}&${newFormatParams(params)}`, {}, useNode);
};

export const updateChecklistStatus = (body) => {
  const useNode = true;
  return post(`/api/checklist/acceptance/${InfoUsuario.get('cdUsuario')}`, body, {}, useNode);
};