export const getSearchParams = (search) => {
  return new URL(location.href).searchParams.get(search);
};

export const useSearchParams = () => {
  const params = {};
  const entries = new URL(location.href).searchParams.entries();

  for (const entrie of entries) {
    params[entrie[0]] = entrie[1];
  }

  return params;
};
