import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const { TextArea, Search, Password } = Input;

// Input
const InputComponent = React.forwardRef(({ ...otherProps }, ref) => <Input ref={ref} {...otherProps} />);

InputComponent.propTypes = {
  ...Input.propTypes,
};

InputComponent.defaultProps = {
  allowClear: true,
};

// TextArea
const InputTextAreaComponent = React.forwardRef(({ ...otherProps }, ref) => <TextArea ref={ref} {...otherProps} />);

InputTextAreaComponent.propTypes = {
  ...TextArea.propTypes,
  rows: PropTypes.number,
};

InputTextAreaComponent.defaultProps = {
  rows: 5,
  allowClear: true,
};

// Password
const InputPasswordComponent = React.forwardRef(({ ...otherProps }, ref) => <Password ref={ref} {...otherProps} />);

InputPasswordComponent.propTypes = {
  ...Password.propTypes,
};

//Search
const InputSearchComponent = React.forwardRef(({ ...otherProps }, ref) => <Search ref={ref} {...otherProps} />);

InputSearchComponent.propTypes = {
  ...Search.propTypes,
};

InputSearchComponent.defaultProps = {
  allowClear: true,
};

// Export
InputComponent.TextArea = InputTextAreaComponent;
InputComponent.Password = InputPasswordComponent;
InputComponent.Search = InputSearchComponent;

export default InputComponent;
