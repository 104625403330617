import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import App from '@app';
import { TelasNomenclaturas, Filtros } from '@utils';
import { 
  Icon,
  Body,
  PageHeader,
  Breadcrumb,
  Button,
  Filter,
  ActiveFilters,
  SearchBar
} from '@components-teammove';

import CardDashboard from './CardDashboard';

import { getDashCluster } from '@ducks/gestaoCarteira';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import getModalFilters from './rules';

export default function GestaoCarteira() {
  const dispatch = useDispatch();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const theme = useSelector(({ app }) => app.get('theme'));

  const dashCluster = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('dash'));
  const loadingDash = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingDash'));
  const successDash = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('successDash'));
  
  const companyTypes = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));

  const modalFilters = getModalFilters(agrupadores, companyTypes);
  
  const [filters, setFilters] = useState(Filtros.get('DASH'));
  const [visibleFilters, setVisibleFilters] = useState(false);

  const [search, setSearch] = useState(sessionStorage.getItem('searchGestaoCarteira') || '');

  useEffect(() => {
    dispatch(getAgrupadores());
    dispatch(getTiposUnidades());
  },[]);

  useEffect(() => {
    dispatch(getDashCluster({ ...filters }, search));
  },[filters]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
    Filtros.set('DASH', filters);
  };
  
  const handleSearch = (term) => {
    setSearch(term);
    sessionStorage.setItem('searchGestaoCarteira', term);
    dispatch(getDashCluster({ ...filters }, term));
  };
  
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Gestão da carteira de ' + ((companyNomenclature.plural)?.toLowerCase() || 'unidades')}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { name: `${companyNomenclature.plural}`, current: true },
                { path: '/gestaoCarteira', name: `Gestão da carteira d${companyNomenclature.artigo}s ${((companyNomenclature.plural)?.toLowerCase() || 'unidades')}`, current: true }
              ]}
            />
          )}
          extra={[
            <Button size='small' context='header' key="1" type='secondary' onClick={() => setVisibleFilters(true)} icon={<Icon.MDI type='filter-outline' />}>Filtros</Button>,
          ]}
        >
          <ActiveFilters filters={modalFilters} filtros={filters} onSearch={handleFiltersChange}/>
          <SearchBar
            placeholder="Pesquisar"
            initialValue={search}
            onSearch={(term)=>handleSearch(term)}
          />
          
          <CardDashboard loading={loadingDash} data={dashCluster } success={successDash} filters={filters}/>
          
          {visibleFilters && (
            <Filter 
              visible={visibleFilters}
              filters={modalFilters} 
              filtros={filters}
              onSearch={handleFiltersChange} 
              onClose={() => setVisibleFilters(false)}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}