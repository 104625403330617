import styled from 'styled-components';
import { View, Filter, Text, Empty } from '@components-teammove';

export const Padding = styled.div`
  padding: 0 7.5rem;
`;

export const FilterStyle = styled(Filter)`
    &.ant-modal {
    .ant-modal-body {
      :focus-within {
        height: 250px;
      }
    }
  }
`;

export const Container = styled(View)`
  gap: 8px;
  margin-bottom: 18px;
`;

export const TagsView = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Label = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  
  &.ant-typography {
    color: ${(props) => props.theme.textColor};
  }
`;

export const TagText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${({ strong }) => strong && `
    font-weight: 700;
  `}
  strong {
    font-weight: 700;
  }
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 150px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;