import React from 'react';
import { CloseIcon, StyledUpload, UploadDragger, UploadIcon, UploadText } from './styles';
import { authorization } from '@sdk/api';
import { download } from '@utils';
import Uploads from './Uploads';
import { Column } from './Uploads/styles';

const getAction = (URI, folder) => `${URI}/api/upload/${encodeURIComponent(folder)}`;

const Upload = ({ showUploadList, uploadsListProps,...props }) => {
  const handleDelete = (deletedFileSource) => {
    const file = props.fileList.find(({ response }) => response === deletedFileSource);
    props.onChange({ file, fileList: props.fileList.filter(({ uid }) => uid !== file.uid) });
  };
  
  return(
    <Column gap='16px'>
      <StyledUpload showUploadList={false} action={getAction(props.URI, props.folder)} { ...props }/>
      {showUploadList && props.fileList && (
        <Uploads 
          uploads={props.fileList.filter(({ status }) => status === 'done').map(({ response, name }) => ({ path: response, name }))}
          onDelete={handleDelete}
          {...uploadsListProps}
        />
      )}
    </Column>
  );
};

const { REACT_APP_API } = window.env;

Upload.Dragger = ({ children, ...props }) => (
  <UploadDragger 
    action={getAction(props.URI || REACT_APP_API, props.folder)}
    listType='picture' 
    showUploadList={{ removeIcon: <CloseIcon/> }} 
    headers={authorization()} 
    URI={REACT_APP_API} 
    onDownload={(file) => download(file.response)} 
    {...props}
  >
    {children || (
      <>
        <UploadIcon type='upload'/>
        <UploadText>Clique ou arraste o arquivo para essa área para fazer upload</UploadText>
      </>
    )}
  </UploadDragger>
);

export default Upload;