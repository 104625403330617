export const formatParams = (params) => {
  const {
    initialDate,
    typePeriod,
    typeValue,
    categoriaProduto,
    agrupadorUnidade,
    idCompanies,
    tipoUnidade,
    familia,
    produto,
  } = params;

  const initialDateParam = initialDate ? `initialDate=${initialDate}` : '';
  const typePeriodParam = typePeriod ? `typePeriod=${ typePeriod === 'DIARIO' ? 'daily' : typePeriod === 'SEMANAL' ? 'weekly' : typePeriod === 'MENSAL' ? 'monthly' : typePeriod === 'TRIMESTRAL' ? 'quarterly' : typePeriod === 'SEMESTRAL' ? 'semiannually' : typePeriod === 'ANUAL' ? 'annually' : 'allTime'}` : '';
  const companiesParam = idCompanies ? `companies=${idCompanies.join(',')}` : '';
  const groupersParam = agrupadorUnidade ? `groupers=${agrupadorUnidade.join(',')}` : '';
  const categoriesParam = (categoriaProduto || categoriaProduto?.length > 0) ? `categories=${categoriaProduto.join(',')}` : '';
  const companiesTypesParam = tipoUnidade ? `companyTypes=${tipoUnidade.join(',')}` : '';
  const familiesParam = familia ? `families=${familia.join(',')}` : '';
  const productsParam = produto ? `products=${produto.join(',')}` : '';
  const dashTypeValueParam = typeValue ? `dashType=${typeValue}` : '';
  
  return [
    initialDateParam,
    typePeriodParam,
    categoriesParam,
    companiesParam,
    groupersParam,
    companiesTypesParam,
    familiesParam,
    productsParam,
    dashTypeValueParam
  ].filter((param) => param).join('&');
};
  