export const hexToRGBA = (hex, opacity = 1) => {
  return (
    'rgba(' +
    (hex = hex.replace('#', ''))
      .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
      .map((hx) => parseInt(hex.length % 2 ? hx + hx : hx, 16))
      .concat(isFinite(opacity) ? opacity : 1)
      .join(',') +
    ')'
  );
};
