import React, { useState } from 'react';
import { PDFViewer, View } from '@components';
import { Icon } from '@components-teammove';
import VisualizacaoPersonalizacao from '../Personalizacao';
import VisualizacaoLista from '../Lista';
import {
  ButtonLista,
  ButtonPersonalizar,
  TextoLista,
  Container, 
} from './styles';

export default function VisualizacaoPDF({ conteudo, blockCustomization, loadingBlockCustomization }) {
  const [personaliza, setPersonaliza] = useState(false);
  const [personalizaLista, setPersonalizaLista] = useState(false);
  const parametros = conteudo.get('parametrosImagem') ? JSON.parse(conteudo.get('parametrosImagem')) : null;
  
  return (
    <View>
      {conteudo.get('parametrosImagem') && (
        <View horizontal>
          <Container>
            <ButtonPersonalizar loading={loadingBlockCustomization} type={blockCustomization ? 'disabled' : 'success'} disabled={blockCustomization} icon={<Icon.MDI type='brush' />} onClick={() => setPersonaliza(true)} >
            Personalizar
            </ButtonPersonalizar>
            {blockCustomization && <TextoLista>* Preencha os valores na lista de produtos.</TextoLista>}
          </Container>
          {parametros.textos?.some((item) => item.productItemList > 0) &&
            <ButtonLista icon={<Icon.MDI type='file-document-edit-outline' />} onClick={() => setPersonalizaLista(true)}>
              Lista de Produtos
            </ButtonLista>}
        </View>
      )}
      {personaliza && <VisualizacaoPersonalizacao conteudo={conteudo} onClose={() => setPersonaliza(false)} />}
      {personalizaLista && <VisualizacaoLista conteudo={conteudo} onClose={() => setPersonalizaLista(false)} />}
      <PDFViewer url={conteudo.get('conteudo')} />
    </View>
  );
}
