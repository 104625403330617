import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'debounce';
import App from '@app';
import { View } from '@components';
import { Body, Button, Form, PageHeader, Input, InputChromePicker, Skeleton, Switch, Tooltip, Icon } from '@components-teammove';
import { download } from '@utils';
import { authorization } from '@sdk/api';
import { getWhitelabel, saveWhitelabel, duplicateWhitelabel, setDefaultWhitelabel, reset } from '@ducks/configuracoesWhitelabel';
import { setTheme } from '@ducks/app';
import Preview from './Preview';
import { ContainerName, ContentName, LabelColors, ContentListColors, ContainerColor, LabelFormItem, ContentColor, NameHexColor, TitleHeader, StatusRegistration, TagStatusRegistration, ContentLogo, UploadFill, ComponentEmptyCover, IconRemoveLogo, LabelDimensions, ContainerPreview, ContentPreview, Logo } from './styles';

const { REACT_APP_API } = window.env;

const INITIAL_WHITELABEL = {
  id: -1,
  alias: null,
  foregroundColor: null,
  backgroundColor: null,
  darkPrimaryColor: null,
  darkSecondaryColor: null,
  darkHighlightColor: null,
  actionColor: null,
  textColor: null,
  textContrastColor: null,
  textForegroundColor: null,
  logo: null,
  logoHeader: null
};

export default function Whitelabel() {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [whitelabel, setWhitelabel] = useState();
  const [loadingUploadLogo, setLoadingUploadLogo] = useState(false);
  const [loadingUploadLogoHeader, setLoadingUploadLogoHeader] = useState(false);

  const theme = useSelector(({ app }) => app.get('theme'));

  const whitelabelOriginal = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('whitelabel'));
  
  const loadingGetWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('loadingGetWhitelabel'));
  const successGetWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('successGetWhitelabel'));
  
  const loadingSaveWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('loadingSaveWhitelabel'));
  const successSaveWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('successSaveWhitelabel'));
  
  const loadingDuplicateWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('loadingDuplicateWhitelabel'));
  const successDuplicateWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('successDuplicateWhitelabel'));
  
  const loadingSetDefaultWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('loadingSetDefaultWhitelabel'));
  const successSetDefaultWhitelabel = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('successSetDefaultWhitelabel'));

  useEffect(() => {
    if (id > 0) {
      handleGetWhitelabel(id);
    } else {
      handleNewWhitelabel();
    }
    return () => {
      handleReset();
    };
  }, [id]);

  useEffect(() => {
    if (successGetWhitelabel) {
      handleChangeWhitelabel(whitelabelOriginal);
    }
  }, [successGetWhitelabel]);

  useEffect(() => {
    if (successSaveWhitelabel) {
      if (whitelabelOriginal?.defaultTemplate && whitelabelOriginal?.theme) {
        handleUpdateTheme(whitelabelOriginal?.theme);
      }
      setTimeout(() => {
        history.push('/configuracoes/whitelabels');
      }, [1000]);
    }
  }, [successSaveWhitelabel]);

  useEffect(() => {
    if (successDuplicateWhitelabel) {
      setTimeout(() => {
        history.push(`/configuracoes/whitelabel/${whitelabelOriginal.id}`);
      }, 500);
    }
  }, [successDuplicateWhitelabel]);

  useEffect(() => {
    if (successSetDefaultWhitelabel) {
      handleChangeWhitelabel(whitelabelOriginal);
      handleUpdateTheme(whitelabelOriginal?.theme);
    }
  }, [successSetDefaultWhitelabel]);

  const handleReset = () => {
    dispatch(reset());
  };

  const handleUpdateTheme = (theme) => {
    dispatch(setTheme(theme));
  };

  const handleGetWhitelabel = (id) => {
    dispatch(getWhitelabel(id));
  };

  const handleSaveWhitelabel = (whitelabel) => {
    dispatch(saveWhitelabel(whitelabel));
  };

  const handleSetDefaultWhitelabel = (id) => {
    dispatch(setDefaultWhitelabel(id));
  };

  const handleDuplicateWhitelabel = (whitelabel) => {
    dispatch(duplicateWhitelabel(whitelabel));
  };

  const handleChangeWhitelabel = (whitelabel) => {
    setWhitelabel(whitelabel);
    form.setFieldsValue(whitelabel);
  };

  const handleNewWhitelabel = () => {
    handleChangeWhitelabel(INITIAL_WHITELABEL);
  };

  const handleSaveConfiguration = () => {
    form.validateFields().then((values) => {
      handleSaveWhitelabel({ ...values, id: id });
    }).catch(Form.scrollToFirstError);
  };

  const handleChangeColor = (field, color, updateFormToo = true) => {
    const whitelabelModified = { ...handleGetWhitelabelValuesFromForm(), [field]: color };
    setWhitelabel(whitelabelModified);
    if (updateFormToo) {
      form.setFieldsValue(whitelabelModified);
    }
  };

  const handleGetWhitelabelValuesFromForm = () => {
    return form.getFieldsValue();
  };

  const handleUploadRemove = (field) => {
    handleChangeWhitelabel({ ...handleGetWhitelabelValuesFromForm(), [field]: null });
  };

  const handleUploadLogoChange = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        setLoadingUploadLogo(true);
        break;
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        setLoadingUploadLogo(false);
        break;
      }
      case 'done': {
        handleChangeWhitelabel({ ...handleGetWhitelabelValuesFromForm(), logo: file.response });
        setLoadingUploadLogo(false);
        break;
      }
      default: {
        setLoadingUploadLogo(false);
      }
    }
  };

  const handleUploadLogoHeaderChange = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        setLoadingUploadLogoHeader(true);
        break;
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        setLoadingUploadLogoHeader(false);
        break;
      }
      case 'done': {
        handleChangeWhitelabel({ ...handleGetWhitelabelValuesFromForm(), logoHeader: file.response });
        setLoadingUploadLogoHeader(false);
        break;
      }
      default: {
        setLoadingUploadLogoHeader(false);
      }
    }
  };

  const handleChangeChromePickerColor = debounce((field, color) => {
    if (!color) return;

    const containSpecialCaracter = color.startsWith('#');
    if (!containSpecialCaracter) {
      color = '#' + color;
    }
    const colorWhitoutTag = color.replace('#', '');
    if (colorWhitoutTag.length === 3 || colorWhitoutTag.length === 6) {
      handleChangeColor(field, color, containSpecialCaracter === false);
    }
  }, 500);

  const getHeader = () => (
    <View>
      <TitleHeader>Configuração de White Label</TitleHeader>
      {whitelabel?.defaultTemplate && <TagStatusRegistration color='#1890FF'>
        <StatusRegistration>Em uso</StatusRegistration>
      </TagStatusRegistration>}
    </View>
  );

  const getExtras = () => {
    const options = [];
    if (whitelabel?.id > 0) {
      options.push(<Button key='duplicate' type='secondary' onClick={() => handleDuplicateWhitelabel({ ...handleGetWhitelabelValuesFromForm(), alias: whitelabel.alias + ' - Cópia ', id: -1  })} loading={loadingDuplicateWhitelabel}>Duplicar tema</Button>);
    }
    if (whitelabel?.defaultTemplate === false) {
      options.push(<Button key='preview' type='secondary' onClick={() => handleSetDefaultWhitelabel(whitelabel?.id)} loading={loadingSetDefaultWhitelabel} disabled={whitelabel?.id < 0}>Selecionar tema</Button>);
    }
    options.push(<Button key='save' type='primary' onClick={() => handleSaveConfiguration()} loading={loadingSaveWhitelabel}>Salvar White Label</Button>);
    return options;
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={getHeader()}
          extra={getExtras()}
          onBack={() => history.push('/configuracoes/whitelabels')}
        >
          <Form form={form} layout='vertical'>
            <Form.Item name="id" hidden />
            <Form.Item name="defaultTemplate" hidden />
            <ContainerName>
              <ContentName loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>alias:</LabelFormItem>}
                <Form.Item name='alias' rules={[{ required: true, message: 'Informe um alias para o white label!' }]}>
                  {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <Input />}
                </Form.Item>
              </ContentName>
              <View>
                <LabelFormItem>{loadingGetWhitelabel ? <Skeleton.MinorLine/> : 'upar logo:'}</LabelFormItem>
                <ContentLogo backgroundColor={whitelabel?.darkSecondaryColor} textColor={whitelabel?.textColor}>
                  <Form.Item name='logo' rules={[{ required: true, message: 'Logo obrigatória!' }]}>
                    {loadingGetWhitelabel ? (
                      <Skeleton.MediumLine width='100%' height='100px'/>
                    ) : (
                      <UploadFill
                        folder='Whitelabel'
                        URI={REACT_APP_API}
                        headers={authorization()}
                        onDownload={(file) => download(file.response)}
                        showUploadList={false}
                        onChange={(info) => handleUploadLogoChange(info)}
                        accept={'image/*'}
                      >
                        {whitelabel?.logo && !loadingUploadLogo ?
                          <View>
                            <Logo src={whitelabel?.logo} alt='Logo' />
                            <IconRemoveLogo
                              textColor={whitelabel?.textForegroundColor}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleUploadRemove('logo');
                              }}
                            />
                          </View>
                          : 
                          <ComponentEmptyCover>
                            {loadingUploadLogo ? <Icon.LoadingOutlined /> : <Icon.UploadOutlined />}
                            <View textAlign='center'>Clique para anexar a logo</View>
                          </ComponentEmptyCover>}
                      </UploadFill>
                    )}
                  </Form.Item>
                </ContentLogo>
                <LabelDimensions>Dimensões sugeridas: 160x48px</LabelDimensions>
              </View>
              <View>
                <LabelFormItem>{loadingGetWhitelabel ? <Skeleton.MinorLine/> : 'upar header:'}</LabelFormItem>
                <ContentLogo backgroundColor={whitelabel?.foregroundColor} textColor={whitelabel?.textForegroundColor}>
                  <Form.Item name='logoHeader' rules={[{ required: true, message: 'Logo do header obrigatória!' }]}>
                    {loadingGetWhitelabel ? (
                      <Skeleton.MediumLine width='100%' height='100px'/>
                    ) : (
                      <UploadFill
                        folder='Whitelabel'
                        URI={REACT_APP_API}
                        headers={authorization()}
                        onDownload={(file) => download(file.response)}
                        showUploadList={false}
                        onChange={(info) => handleUploadLogoHeaderChange(info)}
                        accept={'image/*'}
                      >
                        {whitelabel?.logoHeader && !loadingUploadLogoHeader ?
                          <View>
                            <Logo src={whitelabel?.logoHeader} alt='Logo' />
                            <IconRemoveLogo
                              textColor={whitelabel?.textColor}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleUploadRemove('logoHeader');
                              }}
                            />
                          </View>
                          : 
                          <ComponentEmptyCover>
                            {loadingUploadLogoHeader ? <Icon.LoadingOutlined /> : <Icon.UploadOutlined />}
                            <View textAlign='center'>Clique para anexar o header</View>
                          </ComponentEmptyCover>}
                      </UploadFill>
                    )}
                  </Form.Item>
                </ContentLogo>
                <LabelDimensions>Dimensões sugeridas: 160x48px</LabelDimensions>
              </View>
            </ContainerName>
            {whitelabel?.id > 0 && <View>
              {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <>{!whitelabel?.defaultTemplate && <LabelFormItem>ativo:</LabelFormItem>}
                <Form.Item name='active' valuePropName='checked' hidden={whitelabel?.defaultTemplate}>
                  <Switch />
                </Form.Item>
              </>
              }
            </View>}
            <LabelColors>Escolha as cores:</LabelColors>
            <ContentListColors>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>foreground <Tooltip title="Cor do topo"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='foregroundColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('foregroundColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.foregroundColor} onChange={(color) => handleChangeColor('foregroundColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>background <Tooltip title="Cor do fundo"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='backgroundColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('backgroundColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.backgroundColor} onChange={(color) => handleChangeColor('backgroundColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>dark-primary <Tooltip title="Cor de fundo dos componentes (input, select...)"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='darkPrimaryColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('darkPrimaryColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.darkPrimaryColor} onChange={(color) => handleChangeColor('darkPrimaryColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>dark-secondary <Tooltip title="Cor de fundo do menu lateral, cards e modais"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='darkSecondaryColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('darkSecondaryColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.darkSecondaryColor} onChange={(color) => handleChangeColor('darkSecondaryColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>dark-highlight <Tooltip title="Cor da borda dos inputs"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='darkHighlightColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('darkHighlightColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.darkHighlightColor} onChange={(color) => handleChangeColor('darkHighlightColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>action <Tooltip title="Cor de fundo dos botões de ações"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='actionColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('actionColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.actionColor} onChange={(color) => handleChangeColor('actionColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>text-color <Tooltip title="Cor para os textos gerais do sistema"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='textColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('textColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.textColor} onChange={(color) => handleChangeColor('textColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>text-contrast <Tooltip title="Cor para os textos onde é usado o ACTION como fundo"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='textContrastColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('textContrastColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.textContrastColor} onChange={(color) => handleChangeColor('textContrastColor', color)} />
                </ContentColor>}
              </ContainerColor>
              <ContainerColor loading={loadingGetWhitelabel}>
                {loadingGetWhitelabel ? <Skeleton.MinorLine /> : <LabelFormItem>text-foreground <Tooltip title="Cor para os textos onde é usado o FOREGROUND como fundo (Ex: TOPO)"><Icon.InfoCircleOutlined /></Tooltip></LabelFormItem>}
                {loadingGetWhitelabel ? <Skeleton.MediumLine width='250px' /> : <ContentColor>
                  <NameHexColor>Hex:</NameHexColor>
                  <Form.Item name='textForegroundColor' rules={[{ required: true, message: 'Cor obrigatória!' }]}>
                    <Input maxLength='7' onChange={(e) => handleChangeChromePickerColor('textForegroundColor', e?.target?.value)} />
                  </Form.Item>
                  <InputChromePicker color={whitelabel?.textForegroundColor} onChange={(color) => handleChangeColor('textForegroundColor', color)} />
                </ContentColor>}
              </ContainerColor>
            </ContentListColors>
          </Form>
          {!loadingGetWhitelabel && <ContainerPreview>
            <LabelColors>Preview</LabelColors>
            <ContentPreview>
              <Preview whitelabel={whitelabel} />
            </ContentPreview>
          </ContainerPreview>}
        </PageHeader>
      </Body>
    </App>
  );
}