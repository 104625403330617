import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import App from '@app';

import { Body, PageHeader, Breadcrumb, Filter, Button, Column, Text, Avatar, Row } from '@components-teammove';
import { Filtros, FiltrosSessao, getDownloadUrlAvatar, moment, formatDate } from '@utils';

import { initialFilters } from './rules';

import { FILTERS_KEY, PARAMS_KEY } from '@ducks/atividadesGestaoAgenda';
import { FILTERS_KEY as FILTERS_KEY_GESTAO_EQUIPES } from '@ducks/gestaoEquipes';
import { reset } from '@ducks/configuracoesAtendimento';

import { TeamsSummaryCards } from '../TeamsSummaryCard';
import { Cards } from './Cards';
import { CalendarComponent } from './CalendarComponent';
import Cadastro from '../../Agenda/Cadastro';

export default function GestaoAgenda() {
  const history = useHistory();
  const param = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = parseInt(param.id);
  const { userName, userDesc, userPicture, workingJourneyId } = location.state;
  const theme = useSelector(({ app }) => app.get('theme'));

  const [ visibleFiltersModal, setVisibleFiltersModal ] = useState();
  const [ currentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));
  const [visibleActivityRegister, setVisibleActivityRegister] = useState(false);

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    Filtros.set(PARAMS_KEY, { ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });

    FiltrosSessao.set(FILTERS_KEY_GESTAO_EQUIPES, {
      data:[moment(currentParams.initialDate), moment(currentParams.initialDate)],
      descFiltros: { 
        data: [formatDate(moment(currentParams.initialDate)), formatDate(moment(currentParams.initialDate))] 
      } 
    });
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Gestão da Agenda'
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades', name: 'Atividades', current: true },
                { path: '/atividades/gestao-agenda', name: 'Gestão Agenda' },
                { path: `/atividades/gestao-agenda/${param}`, name: 'Gestão Agenda', current: true },
              ]}
            />
          )}
          extra={[
            <Button key='filter' size='small' type='secondary' onClick={() => history.push('/controle-atividades/' + userId)}>Conferir atividades</Button>,
            <Button key='add' size='small' type='primary' onClick={() =>  setVisibleActivityRegister(true)}>Adicionar atividade</Button>,
          ]}
        >
          <Column gap='24px'>
            <Row align='center' gap='28px'>
              <Avatar size='72px' src={getDownloadUrlAvatar(userPicture)}/>
              <Column>
                <Text size='24px' weight='700' line='32px'>{userName}</Text>
                <Text size='16px' weight='400' line='21px'>{userDesc}</Text>
              </Column>
            </Row>
            
            <Column gap='8px'>
              <Column gap='12px'>
                <TeamsSummaryCards currentParams={currentParams} idUser={userId} removeUnrecordedTime/>
                <Cards userId={userId} currentParams={currentParams} />
              </Column>
              <CalendarComponent 
                userId={userId}
                currentParams={currentParams} 
                onChange={handleChangePeriod}
                workingJourneyId={workingJourneyId}
              />
            </Column>
          </Column>
        </PageHeader>

        {visibleFiltersModal && (
          <Filter
            visible={visibleFiltersModal}
            onClose={() => setVisibleFiltersModal(false)}
            filtros={currentFilters}
            filters={initialFilters}
          />
        )}

        {visibleActivityRegister && (
          <Cadastro visible={visibleActivityRegister} onCancel={() => setVisibleActivityRegister(false)} idUser={userId}/>
        )}
      </Body>
    </App>
  );
}