import styled from 'styled-components';
import { Icon, Column, Button, Table, Empty } from '@components-teammove';

export const Container = styled(Column)`
  padding: 22px 20px 20px 20px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  height: 100%;
`;

export const ButtonStyle = styled(Button)`
  &.ant-btn {
    :hover {
      background-color: ${({ theme })=> theme.darkHighlightColor} !important;
    }
    :focus{
      background-color: ${({ theme })=> theme.darkSecondaryColor} !important;
    }
 } 
`;  

export const IconFeather = styled(Icon.Feather)`
  color: ${({ color, theme })=> color === 'ok' ? 'var(--success-color)' : color === 'alert' ? 'var(--warning-color)' : color === 'critical' ? 'var(--danger-color)' : color === 'n/a' ? `${theme.backgroundColor}` : `${theme.textColor}`};
`;

export const DotTopLevel = styled.div`
	border-radius: 50%;
	width: 26px;
	height: 26px;
  background-color: var(--super-success-color);
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContentPosition = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableStyle = styled(Table)`
  &.ant-table {
    .ant-table-content {
      thead {
        > tr {
          > th {
            background-color: ${({ theme })=> theme.darkSecondaryColor} !important;
          }
        }
      }
      tbody {
        > tr {
          :hover {
            td {
              background-color: ${({ theme })=> theme.darkHighlightColor} !important;
            }
          }
        }
      }
    }
  }       
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;