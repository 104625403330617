import styled from 'styled-components';
import { Slider } from 'antd';
import { lighten } from 'polished';

export const StyledSlider = styled(Slider)`
  &.ant-slider {
    .ant-slider-rail {
      height: 6px;
      background-color: ${({ theme }) => theme.darkSecondaryColor};
    }
    .ant-slider-track {
      height: 6px;
      background-color: ${({ theme }) => theme.actionColor};
    }
    .ant-slider-step {
      height: 6px;
    }
    .ant-slider-handle {
      background-color: ${({ theme }) => theme.textContrastColor};
      border: 4px solid ${({ theme }) => theme.actionColor};
    }

    :hover {
      .ant-slider-rail {
        background-color: ${({ theme }) => lighten(0.085, theme.darkSecondaryColor)};
      }
      .ant-slider-track {
        background-color: ${({ theme }) => lighten(0.085, theme.actionColor)};
      }
      .ant-slider-handle, .ant-slider-handle:not(.tooltip-open) {
        background-color: ${({ theme }) => lighten(0.085, theme.textContrastColor)};
        border: 4px solid ${({ theme }) => lighten(0.085, theme.actionColor)};
      }
    }
  }
`;