import styled from 'styled-components';
import Icon from '../Icon';
import * as ColorPicker from 'react-color';

export const PickerContainer = styled.div`
  position: ${({ position }) => position ? position : 'absolute'};
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 2px solid ${(props) => props.theme.actionColor};
  border-radius: 8px;
  padding: 16px;
  gap: 14px;
  max-height: 150px;
  overflow-y: scroll;
`;

export const PickerInactive = styled.div`
	background-color: ${(props) => props.theme.darkPrimaryColor} !important;
	border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
	border-radius: 8px;
	padding: 16px;
	height: fit-content;
  min-height: 56px;
  min-width: 56px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
`;

export const PickedColor = styled.div`
	background-color: ${({ color, theme }) => color || theme.darkPrimaryColor};
	width: 22px;
	height: 22px;
	border-radius: 50%;
`;

export const PickedIcon = styled(Icon.MDI)`
  width: 22px !important;
  height: 22px !important;
  color: ${(props) => props.theme.textColor};
`;

export const ColorName = styled.span`
	color: ${(props) => props.theme.textColor};
	font-size: 16px;
  ${({ isPlaceholder }) => isPlaceholder && `
    opacity: 50%;
  `}
`;

export const SelectArrow = styled(Icon.DownOutlined)`
	font-size: 12px;
	color: ${(props) => props.theme.textColor} !important;
	margin-left: auto;
`;

export const BorderedColorPicker = styled(ColorPicker.CirclePicker)`
  border: 2px solid ${(props) => props.theme.actionColor};
  border-radius: 8px;
  padding: 14px;
  gap: 14px;
  margin-right: 0 !important;
  width: fit-content !important;
  justify-content: flex-start;
  /* padding-left: 32.25px; */

  > span > div {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
`;