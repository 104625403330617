/* eslint-disable */
import React from 'react';
import { Dropdown, Menu, View, Modal } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { apiDeleteComentario, setPosting } from '@ducks/wikisComentario';

const ViewAcoes = styled(View)`
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
  width: fit-content;
`;

export default function AcoesAutor({ data, handlers }) {
  const { row, comentarios, edicaoComentario } = data;

  const posting = useSelector(({ wikisComentario }) => wikisComentario.get('posting'));

  const handleExcluirComentario = (id) => {
    Modal.confirm({
      title: 'Você deseja excluir o comentário?',
      okText: 'Sim',
      cancelText: 'Não',
      icon: <Icon.QuestionCircleOutlined />,
      onOk: async () => {
        setPosting(true);
        await apiDeleteComentario({ id });
        handlers.setComentarios([...comentarios.filter((comentario) => comentario.id !== id)]);
        setPosting(false);
      },
    });
  };

  const handleEditModeComentario = ({ id }) => {
    handlers.setEdicaoComentario({ display: 'flex', id });
    setTimeout(() => {
      const input = document.getElementById('comentario');
      const len = input.value.length;
      input.focus();
      input.setSelectionRange(len, len);
    }, 1);
  };

  const MenuAcoes = ({ data, rowId }) => (
    <Menu>
      <Menu.Item onClick={() => handleEditModeComentario(data, rowId)}>
        <Icon.EditOutlined /> Editar
      </Menu.Item>
      <Menu.Item onClick={() => handleExcluirComentario(data.id)}>
        <Icon.DeleteOutlined /> Excluir
      </Menu.Item>
    </Menu>
  );

  return row.usuarioLogado &&
    ((edicaoComentario.display === 'flex' && edicaoComentario.id !== row.id) || edicaoComentario.display === 'none') &&
    !posting ? (
    <ViewAcoes>
      <Dropdown overlay={<MenuAcoes data={row} rowId={row.id} />}>
        <Icon.MDI type='dots-vertical' />
      </Dropdown>
    </ViewAcoes>
  ) : null;
}
