import React from 'react';
import { Button, View } from '@components';
import { ExternalPage } from '@components-teammove';

const SHORT_URL_YOUTUBE = 'https://youtu.be/';
const PREVIEW_URL_YOUTUBE = 'https://www.youtube.com/embed/';

export default function VisualizacaoLink({ conteudo }) {
  let link = conteudo.get('conteudo');

  //Workaroud para fazer funcionar links do google drive dentro do teammove
  if (link.includes('drive.google.com') && link.includes('/folders/')) {
    const folderTextPosition = link.indexOf('/folders/');
    if (folderTextPosition > -1) {
      const folderId = link.substring(folderTextPosition + 9);
      link = `https://drive.google.com/embeddedfolderview?id=${folderId}#grid`;
    }
  }

  //TODO - Desenvolver um método para avaliar se o site permite abrir como um webview. A fim de evitar o erro de connect refused.
  if (link.includes('.google.com') || link.includes('escolavirtual.gov.br')) {
    return (
      <>
        <View>Não é possível abrir este conteúdo diretamente no TeamMove</View>
        <Button href={link} target='_blank' type='primary'>
          Abrir Externamente
        </Button>
      </>
    );
  }

  if (link.startsWith(SHORT_URL_YOUTUBE)) {
    link = PREVIEW_URL_YOUTUBE + link.substring(link.lastIndexOf('/') + 1, link.length);
  }

  return <ExternalPage src={link} width='100%' height='100%' />;
}
