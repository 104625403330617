import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Form, Input, Switch, Column, Skeleton } from '@components-teammove';
import { SmallFormItem } from '../../../styles';
import { saveAtendimento, reset, getAtendimento } from '@ducks/configuracoesChamadosAtendimento';
import ModalDiaCopia from './ModalDiaCopia';
import ModalTimePicker from './ModalTimePicker';
import DiaSemana from './DiaSemana';
import { mountHorario, copiarHorarios, excluirHorario } from './rules';
import { TelasNomenclaturas } from '@utils';

const initialValues = {
  ativo: true,
  ignoraFeriadoNacional: true,
};

const validateArray = (rule, value) => {
  if ((value) && (value.horarios) && (value.horarios.some((item) => (item.intervalos) && (item.intervalos.length > 0)))) {
    return Promise.resolve();
  }
  return Promise.reject('Informe ao menos um horário');
};

const AtendimentoCadastro = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const theme = useSelector(({ app }) => app.get('theme'));

  const atendimento = useSelector((state) => state.configuracoesChamadosAtendimento.get('atendimento'));
  const loading = useSelector((state) => state.configuracoesChamadosAtendimento.get('loading'));
  const success = useSelector((state) => state.configuracoesChamadosAtendimento.get('success'));
  const loadingSave = useSelector((state) => state.configuracoesChamadosAtendimento.get('loadingSave'));
  const successSave = useSelector((state) => state.configuracoesChamadosAtendimento.get('successSave'));

  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const [visibleDiaCopia, setVisibleDiaCopia] = useState(false);
  const [objCadastro, setObjCadastro] = useState(false);
  const [visibleCadastro, setVisibleCadastro] = useState(false);
  const handleShowModalCadastro = (obj) => {
    setVisibleCadastro(true);
    setObjCadastro(obj);
  };
  const handleHideModalCadastro = () => {
    setVisibleCadastro(false);
    setObjCadastro(false);
  };

  useEffect(() => {
    if ((location.state) && (location.state.id)) {
      dispatch(getAtendimento(location.state.id));
    }
    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (success) {
      form.setFieldsValue(atendimento);
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      history.goBack();
    }
  }, [successSave]);

  const handleExcluirHorario = (item) => {
    const horarios = excluirHorario(item, form.getFieldValue('horarios') || []);
    form.setFieldsValue({ horarios });
    handleHideModalCadastro();
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Configurações / Atendimentos"
          extra={[
            <Button key="1" size='small' context='header' type='secondary' onClick={() => history.goBack()}>Cancelar</Button>,
            <Button key="2" size='small' context='header' type="primary" onClick={() => form.submit()} loading={loadingSave}>Salvar</Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes/chamados', name: 'Configurações' },
                { path: '/configuracoes/chamados', name: `Configurações de ${ticketNomenclatures.plural}` },
                { path: '/configuracoes/chamados/atendimento', name: 'Configurações de Horários', current: true }
              ]}
            />
          )}
        >
          {loading ? (
            <Skeleton.Form/>
          ) : (
            <Form.Provider
              onFormFinish={(name, { values, forms }) => {
                if (name === 'cadForm') {
                  const horarios = mountHorario(values, form.getFieldValue('horarios') || []);
                  form.setFieldsValue({ horarios });
                  handleHideModalCadastro();
                } else if (name === 'diaCopiaForm') {
                  const horarios = copiarHorarios(values, form.getFieldValue('horarios') || []);
                  form.setFieldsValue({ horarios });
                  setVisibleDiaCopia(false);
                } else if (name === 'mainForm') {
                  dispatch(saveAtendimento(values));
                }
              }}
            >
              <Form layout="vertical" name="mainForm" form={form} initialValues={initialValues}>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>

                <SmallFormItem name="nome" label="Descrição" rules={[{ required: true, message: 'Informe a descrição!' }]}>
                  <Input />
                </SmallFormItem>

                <Form.Item label="Horários" shouldUpdate rules={[{ validator: validateArray }]}>
                  {({ getFieldValue }) => {
                    const horarios = getFieldValue('horarios') || [];
                    return (
                      <Column gap='18px'>
                        <DiaSemana dia="Segunda-Feira" horarios={horarios} diaSemana={1} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Terça-Feira" horarios={horarios} diaSemana={2} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Quarta-Feira" horarios={horarios} diaSemana={3} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Quinta-Feira" horarios={horarios} diaSemana={4} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Sexta-Feira" horarios={horarios} diaSemana={5} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Sábado" horarios={horarios} diaSemana={6} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Domingo" horarios={horarios} diaSemana={7} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                      </Column>
                    );
                  }}
                </Form.Item>

                <Form.Item label="Ignora feriado nacional" name="ignoraFeriadoNacional" valuePropName="checked">
                  <Switch />
                </Form.Item>

                <Form.Item label="Ativo" name="ativo" valuePropName="checked">
                  <Switch />
                </Form.Item>

                <ModalTimePicker visible={visibleCadastro} onCancel={handleHideModalCadastro} objCadastro={objCadastro} handleDelete={handleExcluirHorario}/>
                <ModalDiaCopia visible={visibleDiaCopia} onCancel={() => setVisibleDiaCopia(false)} />
              </Form>
            </Form.Provider>
          )}
        </PageHeader>
      </Body>
    </App>
  );
};

export default AtendimentoCadastro;
