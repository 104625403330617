import React from 'react';
import { Marker } from './styles';

export const findColorByPercentage = (percentage, goalPercentage) => {
  let colorMarker = null;
  let typeIcon = null;
  let opacity = 1;

  const percentOfGoal = (percentage * 100) / goalPercentage;
  if(percentage === 0) {
    colorMarker = '#fff';
    opacity = 0.5;
    typeIcon = 'walk';
  } else if (percentOfGoal < 80 || goalPercentage === 0 ) {
    colorMarker = '#FF3B30';
    typeIcon = 'walk';
  } else if (percentOfGoal < 100) {
    colorMarker = '#FD832B';
    typeIcon = 'run';
  } else if (percentOfGoal < 110) {
    colorMarker = '#80C971';
    typeIcon = 'run';
  } else {
    colorMarker = '#1890FF';
    typeIcon = 'run-fast';
  }

  return(
    <Marker type={typeIcon} size={0.7} color={colorMarker} opacity={opacity}/>
  );

};