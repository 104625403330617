import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';

import { Icon, Body, PageHeader, Breadcrumb, Filter, Button, ActiveFilters, DateControllerHeader, SearchBar, Column } from '@components-teammove';
import { Filtros, moment, InfoUsuario } from '@utils';

import { FILTERS_KEY, PARAMS_KEY, getCallList } from '@ducks/callActivity';

import { initialFilters } from './rules';
import GridCards from './GridCards';
import { TableComponent } from './TableComponent';
import { getUsuarios } from '@sdk/Usuarios';
import { AlignedCenterButton } from './styles';

export default function CallActivity() {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  
  const callList = useSelector(({ callActivity }) => callActivity.get('callList'));
  const loadingCallList = useSelector(({ callActivity }) => callActivity.get('loadingCallList'));
  const successCallList = useSelector(({ callActivity }) => callActivity.get('successCallList'));

  const [ currentParams, setCurrentParams ] = useState({ ...Filtros.get(PARAMS_KEY), typePeriod:Filtros.get(PARAMS_KEY).typePeriod || 'DIARIO', initialDate: Filtros.get(PARAMS_KEY).initialDate || moment().format('YYYY-MM-DD') });
  const [currentFilters, setCurrentFilters] = useState(Filtros.get(FILTERS_KEY));
  const [ visibleFilters, setVisibleFilters ] = useState(false);
  const [ filtersModal, setFiltersModal ] = useState(initialFilters);
  const [ search, setSearch ] = useState();
  const [ limit, setLimit ] = useState(50);

  const [ orderByDirection, setOrderByDirection ] = useState();
  const [ orderByField, setOrderByField ] = useState();

  const [usuarios, setUsuarios] = useState([]);
  
  useEffect(() => {
    dispatch(getCallList({ ...currentParams, ...currentFilters, limit, search, orderByDirection, orderByField }));
  }, [search, limit, currentFilters, currentParams, orderByField, orderByDirection]);

  useEffect(() => {
    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsuarios(resp.filter((item) => InfoUsuario.get('hierarquia')?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
    })();
  }, []);

  useEffect(() => {
    if (usuarios ) {
      setFiltersModal(filtersModal.map((filter) => {
        if (filter.name === 'users' && usuarios) {
          return { ...filter, options: usuarios };
        }
        return { ...filter };
      }));
    }
  }, [usuarios]);

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
  };

  const handleSearchChangingNewFilters = (newFilters) => {
    handleSearch(newFilters);
    setCurrentFilters(newFilters);
  };

  const handleSimpleSearch = (params) => {
    const searchParam = (params?.search !== undefined && params?.search?.search !== '') ? params.search : search;
    setSearch(searchParam);
  };

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    Filtros.set(PARAMS_KEY, { ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod });
  };

  const handleTableChange = (direction, field) => {
    setOrderByDirection((direction?.replace('end', ''))?.toUpperCase());
    setOrderByField(direction ? field.toUpperCase() : undefined);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Ligações'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades', name: 'Atividades', current: true } ,
                { path: '/atividades/ligacoes', name: 'Ligações', current: true },
              ]}
            />
          )}
          extra={[          
            <Button key='filter' size='small' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>         
          ]}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          {visibleFilters && (
            <Filter
              visible={visibleFilters}
              onClose={() => setVisibleFilters(false)}
              filtros={currentFilters}
              filters={filtersModal}
              onSearch={handleSearchChangingNewFilters}
            />
          )}
          <Column gap='28px'>
            <Column gap='12px'>
              <DateControllerHeader
                onChange={handleChangePeriod}
                typePeriod={currentParams.typePeriod}
                initialDate={currentParams.initialDate}
                formattedTypePeriod={currentParams.formattedRangeDate}
                defaultOptions={[
                  { label: 'Diário', value: 'DIARIO' },
                  { label: 'Semanal', value: 'SEMANAL' },
                  { label: 'Mensal', value: 'MENSAL' },
                ]}
              />
              <GridCards />

            </Column>
            
            <Column gap='16px'>
              <SearchBar
                placeholder="Buscar Ligações"  
                initialValue={search}
                onSearch={(search) => handleSimpleSearch({ search })}
              />

              <TableComponent onSorter={handleTableChange} orderByDirection={orderByDirection} orderByField={orderByField}/>
              {limit < callList.totalCall && (
                <AlignedCenterButton
                  type='secondary' 
                
                  onClick={() => setLimit(limit + 50)}
                >
                  Carregar mais
                </AlignedCenterButton>
              )}
              
            </Column>

          </Column>
        </PageHeader>
          
      </Body>
    </App>
  );

}