import styled from 'styled-components';
import { View } from '@components';
import { Form, Upload, Input, Button, Icon } from '@components-teammove';

import { Row } from '../styles';

export const Container = styled(View)`
  gap: 8px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 24px 24px;
  position: sticky;
  bottom: 0;
  width: 100%;
`;

export const RowFill = styled(Row)`
  flex: 1;
  gap: 16px;
  align-items: center;
`;

export const AlignCenterForm = styled(Form)`
  &.ant-form-inline {
    align-items: center;

    .ant-form-item {
      margin-right: 0;
    }
  }
`;

export const MessageItem = styled(Form.Item)`
  flex: 1 !important;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload {
    display: flex;
    align-items: center;
  }
`;

export const UploadIcon = styled(Icon.Feather)`
  cursor: pointer;
  margin: auto;

  path {
    color: ${({ theme }) => theme.darkPrimaryColor};
  }
`;

export const MessageBox = styled(Input.TextArea)`
  &.ant-input {
    height: 44px;
    padding: 6px 16px;
    resize: none;
    overflow: hidden;
  }
`;

export const SendButton = styled(Button)`
  max-height: 44px;
`;