import styled from 'styled-components';
import { Button, Text } from '@components-teammove';

export const ButtonPersonalizar = styled(Button)`
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 16px;
`;

export const TextoLista = styled(Text)`
  color: var(--danger-color) !important;
  font-size: 14px;
  margin-top: -8px;
`;