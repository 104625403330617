import styled from 'styled-components';
import { PageHeader, Image, Empty } from '@components-teammove';

export const ProductItem = styled.div`
  border-radius: 12px;
  ${({ selected, theme }) => selected && `
    border: 2px solid ${theme.actionColor};
  `}
  gap: 17px;
  display: flex;
  padding-left: ${({ hasImage }) => hasImage ? '0px' : '17px'};
  align-items: center;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  min-height: 85px;
`;

export const ProductImageWrapper = styled.div`
  width: 80px;
  height: 85px;
`;

export const ProductName = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const Header = styled(PageHeader)`
  .ant-page-header-content{
    padding-top: 24px;
  }
`;

export const ImageRow = styled(Image)`
  border-radius: 12px !important;
  height: 100%;
  width: 100%;
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
`;