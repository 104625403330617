import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getOrders as getOrdersSdk,
  postOrder as postOrderSdk,
  putOrder as putOrderSdk,
  deleteOrderItem as deleteOrderItemSdk,
  getOrderRegister as getOrderRegisterSdk,
  getDetailedOrder as getDetailedOrderSdk,
  putStatus as putStatusSdk,
  postUploads as postUploadsSdk,
  deleteUpload as deleteUploadSdk,
} from '@sdk/Pedidos';

export const FILTERS_KEY = 'FILTROS/ORDERS';

//Action Types
export const Types = {
  GET_ORDERS: 'ORDERS/GET_ORDERS',
  GET_ORDERS_SUCCESS: 'ORDERS/GET_ORDERS_SUCCESS',
  GET_ORDERS_ERROR: 'ORDERS/GET_ORDERS_ERROR',

  POST_ORDER: 'ORDERS/POST_ORDER',
  POST_ORDER_SUCCESS: 'ORDERS/POST_ORDER_SUCCESS',
  POST_ORDER_ERROR: 'ORDERS/POST_ORDER_ERROR',

  PUT_ORDER: 'ORDERS/PUT_ORDER',
  PUT_ORDER_SUCCESS: 'ORDERS/PUT_ORDER_SUCCESS',
  PUT_ORDER_ERROR: 'ORDERS/PUT_ORDER_ERROR',

  DELETE_ORDER_ITEM: 'ORDERS/DELETE_ORDER_ITEM',
  DELETE_ORDER_ITEM_SUCCESS: 'ORDERS/DELETE_ORDER_ITEM_SUCCESS',
  DELETE_ORDER_ITEM_ERROR: 'ORDERS/DELETE_ORDER_ITEM_ERROR',

  GET_ORDER_REGISTER: 'ORDERS/GET_ORDER_REGISTER',
  GET_ORDER_REGISTER_SUCCESS: 'ORDERS/GET_ORDER_REGISTER_SUCCESS',
  GET_ORDER_REGISTER_ERROR: 'ORDERS/GET_ORDER_REGISTER_ERROR',

  GET_DETAILED_ORDER: 'ORDERS/GET_DETAILED_ORDER',
  GET_DETAILED_ORDER_SUCCESS: 'ORDERS/GET_DETAILED_ORDER_SUCCESS',
  GET_DETAILED_ORDER_ERROR: 'ORDERS/GET_DETAILED_ORDER_ERROR',

  PUT_STATUS: 'ORDERS/PUT_STATUS',
  PUT_STATUS_SUCCESS: 'ORDERS/PUT_STATUS_SUCCESS',
  PUT_STATUS_ERROR: 'ORDERS/PUT_STATUS_ERROR',

  PUT_UPLOADS: 'ORDERS/PUT_UPLOADS',
  PUT_UPLOADS_SUCCESS: 'ORDERS/PUT_UPLOADS_SUCCESS',
  PUT_UPLOADS_ERROR: 'ORDERS/PUT_UPLOADS_ERROR',

  DELETE_UPLOAD: 'ORDERS/DELETE_UPLOAD',
  DELETE_UPLOAD_SUCCESS: 'ORDERS/DELETE_UPLOAD_SUCCESS',
  DELETE_UPLOAD_ERROR: 'ORDERS/DELETE_UPLOAD_ERROR',

  CHANGE_STATUS_MODAL: 'ORDERS/CHANGE_STATUS_MODAL',

  RESET_SUCCESS_SAVE: 'ORDERS/RESET_SUCCESS_SAVE',
  RESET_REGISTER: 'ORDERS/RESET_REGISTER',
  RESET: 'ORDERS/RESET',
};

//Action Creators
export const getOrders = (filters) => ({ type: Types.GET_ORDERS, filters });
export const getOrdersSuccess = (orders) => ({ type: Types.GET_ORDERS_SUCCESS, orders });
export const getOrdersError = (error) => ({ type: Types.GET_ORDERS_ERROR, error }); 

export const postOrder = (order) => ({ type: Types.POST_ORDER, order });
export const postOrderSuccess = (order) => ({ type: Types.POST_ORDER_SUCCESS, order });
export const postOrderError = (error) => ({ type: Types.POST_ORDER_ERROR, error });

export const putOrder = (order) => ({ type: Types.PUT_ORDER, order });
export const putOrderSuccess = (order) => ({ type: Types.PUT_ORDER_SUCCESS, order });
export const putOrderError = (error) => ({ type: Types.PUT_ORDER_ERROR, error }); 

export const deleteOrderItem = (id) => ({ type: Types.DELETE_ORDER_ITEM, id });
export const deleteOrderItemSuccess = (deletedItem) => ({ type: Types.DELETE_ORDER_ITEM_SUCCESS, deletedItem });
export const deleteOrderItemError = (error) => ({ type: Types.DELETE_ORDER_ITEM_ERROR, error });

export const getOrderRegister = (id) => ({ type: Types.GET_ORDER_REGISTER, id });
export const getOrderRegisterSuccess = (order) => ({ type: Types.GET_ORDER_REGISTER_SUCCESS, order });
export const getOrderRegisterError = (error) => ({ type: Types.GET_ORDER_REGISTER_ERROR, error });

export const getDetailedOrder = (id) => ({ type: Types.GET_DETAILED_ORDER, id });
export const getDetailedOrderSuccess = (order) => ({ type: Types.GET_DETAILED_ORDER_SUCCESS, order });
export const getDetailedOrderError = (error) => ({ type: Types.GET_DETAILED_ORDER_ERROR, error });

export const putStatus = ({ orderId, newStatus }) => ({ type: Types.PUT_STATUS, statusChange: { orderId, newStatus } });
export const putStatusSuccess = (statusChange) => ({ type: Types.PUT_STATUS_SUCCESS, statusChange });
export const putStatusError = (error) => ({ type: Types.PUT_STATUS_ERROR, error }); 

export const postUploads = (orderId, uploads) => ({ type: Types.PUT_UPLOADS, orderId, uploads });
export const postUploadsSuccess = (uploads) => ({ type: Types.PUT_UPLOADS_SUCCESS, uploads });
export const postUploadsError = (error) => ({ type: Types.PUT_UPLOADS_ERROR, error }); 

export const deleteUpload = (uploadId) => ({ type: Types.DELETE_UPLOAD, uploadId });
export const deleteUploadSuccess = (deletedUpload) => ({ type: Types.DELETE_UPLOAD_SUCCESS, deletedUpload });
export const deleteUploadError = (error) => ({ type: Types.DELETE_UPLOAD_ERROR, error }); 

export const changeStatusModal = ({ visible, orderId }) => ({ type: Types.CHANGE_STATUS_MODAL, statusModal: { visible, orderId } });

export const resetSuccessSave = () => ({ type: Types.RESET_SUCCESS_SAVE });
export const resetRegister = () => ({ type: Types.RESET_REGISTER });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetOrders(action) {
  try {
    const { filters } = action;
    const orders = yield call(getOrdersSdk, filters);
    yield put(getOrdersSuccess(orders));
  } catch (err) {
    Notification.error(err.message);
    yield put(getOrdersError(err));
  }
}

function* fetchPostOrder(action) {
  try {
    const { order } = action;
    const savedOrder = yield call(postOrderSdk, order);
    yield put(postOrderSuccess(savedOrder));
  } catch (err) {
    Notification.error(err.message);
    yield put(postOrderError(err));
  }
}

function* fetchPutOrder(action) {
  try {
    const { order } = action;
    const savedOrder = yield call(putOrderSdk, order);
    yield put(putOrderSuccess(savedOrder));
  } catch (err) {
    Notification.error(err.message);
    yield put(putOrderError(err));
  }
}

function* fetchDeleteOrderItem(action) {
  try {
    const { id } = action;
    const deleted = yield call(deleteOrderItemSdk, id);
    yield put(deleteOrderItemSuccess(deleted));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteOrderItemError(err));
  }
}

function* fetchGetOrderRegister(action) {
  try {
    const { id } = action;
    const order = yield call(getOrderRegisterSdk, id);
    yield put(getOrderRegisterSuccess(order));
  } catch (err) {
    Notification.error(err.message);
    yield put(getOrderRegisterError(err));
  }
}

function* fetchGetDetailedOrder(action) {
  try {
    const { id } = action;
    const order = yield call(getDetailedOrderSdk, id);
    yield put(getDetailedOrderSuccess(order));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDetailedOrderError(err));
  }
}

function* fetchPutStatus(action) {
  try {
    let { statusChange } = action;

    const savedStatusChange = yield call(putStatusSdk, statusChange);
    yield put(putStatusSuccess(savedStatusChange));
  } catch (err) {
    Notification.error(err.message);
    yield put(putStatusError(err));
  }
}

function* fetchPutUploads(action) {
  try {
    let { orderId, uploads } = action;

    const savedUploadsChange = yield call(postUploadsSdk, orderId, uploads);
    yield put(postUploadsSuccess(savedUploadsChange));
  } catch (err) {
    Notification.error(err.message);
    yield put(postUploadsError(err));
  }
}

function* fetchDeleteUpload(action) {
  try {
    let { uploadId } = action;

    const deletedUpload = yield call(deleteUploadSdk, uploadId);
    yield put(deleteUploadSuccess(deletedUpload));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteUploadError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ORDERS, fetchGetOrders),
  takeLatest(Types.POST_ORDER, fetchPostOrder),
  takeLatest(Types.PUT_ORDER, fetchPutOrder),
  takeLatest(Types.DELETE_ORDER_ITEM, fetchDeleteOrderItem),
  takeLatest(Types.GET_ORDER_REGISTER, fetchGetOrderRegister),
  takeLatest(Types.GET_DETAILED_ORDER, fetchGetDetailedOrder),
  takeLatest(Types.PUT_STATUS, fetchPutStatus),
  takeLatest(Types.PUT_UPLOADS, fetchPutUploads),
  takeLatest(Types.DELETE_UPLOAD, fetchDeleteUpload),
];

// Reducer
const initialState = Map({
  orders: List(),
  loadingOrders: false,
  successOrders: false,
  errorOrders: false,
  total: 0,
  orderRegister: {},
  loadingOrderRegister: false,
  successOrderRegister: false,
  errorOrderRegister: false,
  detailedOrder: {},
  loadingDetailedOrder: false,
  successDetailedOrder: false,
  errorDetailedOrder: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
  loadingStatusSave: false,
  successStatusSave: false,
  errorStatusSave: false,
  loadingUploadsSave: false,
  successUploadsSave: false,
  errorUploadsSave: false,
  loadingUploadDelete: false,
  successUploadDelete: false,
  errorUploadDelete: false,
  statusModal: {},
});

const handleGetOrders = (state, action) => {
  return state.set('loadingOrders', true).set('successOrders', false).set('errorOrders', false);
};

const handleGetOrdersSuccess = (state, action) => {
  const { orders } = action;

  return state.set('orders', orders).set('loadingOrders', false).set('successOrders', true).set('errorOrders', false);
};

const handleGetOrdersError = (state, action) => {
  return state.set('loadingOrders', false).set('successOrders', false).set('errorOrders', action.error);
};

const handleGetOrderRegister = (state, action) => {
  return state.set('loadingOrderRegister', true).set('successOrderRegister', false).set('errorOrderRegister', false);
};

const handleGetOrderRegisterSuccess = (state, action) => {
  const { order } = action;

  return state.set('orderRegister', order).set('loadingOrderRegister', false).set('successOrderRegister', true).set('errorOrderRegister', false);
};

const handleGetOrderRegisterError = (state, action) => {
  return state.set('loadingOrderRegister', false).set('successOrderRegister', false).set('errorOrderRegister', action.error);
};

const handleGetDetailedOrder = (state, action) => {
  return state.set('loadingDetailedOrder', true).set('successDetailedOrder', false).set('errorDetailedOrder', false);
};

const handleGetDetailedOrderSuccess = (state, action) => {
  const { order } = action;

  return state.set('detailedOrder', order).set('loadingDetailedOrder', false).set('successDetailedOrder', true).set('errorDetailedOrder', false);
};

const handleGetDetailedOrderError = (state, action) => {
  return state.set('loadingDetailedOrder', false).set('successDetailedOrder', false).set('errorDetailedOrder', action.error);
};

const handleDeleteOrderItem = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteOrderItemSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteOrderItemError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleStatusSave = (state, action) => {
  return state.set('loadingStatusSave', true).set('successStatusSave', false).set('errorStatusSave', false);
};

const handleStatusSaveSuccess = (state, action) => {
  return state.set('loadingStatusSave', false).set('successStatusSave', true).set('errorStatusSave', false)
    .set(
      'orders', 
      state.get('orders')
        .map((order) => order.id === state.get('statusModal').orderId ? ({ ...order, status: action.statusChange.newStatus }) : ({ ...order }))
    );
};

const handleStatusSaveError = (state, action) => {
  return state.set('loadingStatusSave', false).set('successStatusSave', false).set('errorStatusSave', action.error);
};

const handleUploadsSave = (state, action) => {
  return state.set('loadingUploadsSave', true).set('successUploadsSave', false).set('errorUploadsSave', false);
};

const handleUploadsSaveSuccess = (state, action) => {
  const detailedOrder = state.get('detailedOrder');
  return state.set('loadingUploadsSave', false).set('successUploadsSave', true).set('errorUploadsSave', false)
    .set(
      'detailedOrder', 
      { ...detailedOrder, uploads: [...detailedOrder.uploads, ...action.uploads] }
    );
};

const handleUploadsSaveError = (state, action) => {
  return state.set('loadingUploadsSave', false).set('successUploadsSave', false).set('errorUploadsSave', action.error);
};

const handleDeleteOrderUpload = (state, action) => {
  return state.set('loadingUploadDelete', true).set('successUploadDelete', false).set('errorUploadDelete', false);
};

const handleDeleteOrderUploadSuccess = (state, action) => {
  const detailedOrder = state.get('detailedOrder');
  return state.set('loadingUploadDelete', false).set('successUploadDelete', true).set('errorUploadDelete', false)
    .set(
      'detailedOrder', 
      { ...detailedOrder, uploads: detailedOrder.uploads.filter(({ id }) => id !== action.deletedUpload.id) }
    );
};

const handleDeleteOrderUploadError = (state, action) => {
  return state.set('loadingUploadDelete', false).set('successUploadDelete', false).set('errorUploadDelete', action.error);
};

const handleChangeStatusModal = (state, action) => {
  return state.set('statusModal', action.statusModal);
};

const handleResetSuccessSave = (state, action) => {
  return state.set('successSave', false).set('successStatusSave', false).set('successUploadsSave', false);
};

const handleResetRegister = (state, action) => {
  return state.set('orderRegister', initialState.get('orderRegister')).set('loadingOrderRegister', false).set('successOrderRegister', false).set('errorOrderRegister', false);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ORDERS:
      return handleGetOrders(state, action);
    case Types.GET_ORDERS_SUCCESS:
      return handleGetOrdersSuccess(state, action);
    case Types.GET_ORDERS_ERROR:
      return handleGetOrdersError(state, action);

    case Types.GET_ORDER_REGISTER:
      return handleGetOrderRegister(state, action);
    case Types.GET_ORDER_REGISTER_SUCCESS:
      return handleGetOrderRegisterSuccess(state, action);
    case Types.GET_ORDER_REGISTER_ERROR:
      return handleGetOrderRegisterError(state, action);

    case Types.GET_DETAILED_ORDER:
      return handleGetDetailedOrder(state, action);
    case Types.GET_DETAILED_ORDER_SUCCESS:
      return handleGetDetailedOrderSuccess(state, action);
    case Types.GET_DETAILED_ORDER_ERROR:
      return handleGetDetailedOrderError(state, action);

    case Types.DELETE_ORDER_ITEM:
      return handleDeleteOrderItem(state, action);
    case Types.DELETE_ORDER_ITEM_SUCCESS:
      return handleDeleteOrderItemSuccess(state, action);
    case Types.DELETE_ORDER_ITEM_ERROR:
      return handleDeleteOrderItemError(state, action);

    case Types.POST_ORDER:
      return handleSave(state, action);
    case Types.POST_ORDER_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_ORDER_ERROR:
      return handleSaveError(state, action);

    case Types.PUT_ORDER:
      return handleSave(state, action);
    case Types.PUT_ORDER_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_ORDER_ERROR:
      return handleSaveError(state, action);

    case Types.PUT_STATUS:
      return handleStatusSave(state, action);
    case Types.PUT_STATUS_SUCCESS:
      return handleStatusSaveSuccess(state, action);
    case Types.PUT_STATUS_ERROR:
      return handleStatusSaveError(state, action);

    case Types.PUT_UPLOADS:
      return handleUploadsSave(state, action);
    case Types.PUT_UPLOADS_SUCCESS:
      return handleUploadsSaveSuccess(state, action);
    case Types.PUT_UPLOADS_ERROR:
      return handleUploadsSaveError(state, action);

    case Types.DELETE_UPLOAD:
      return handleDeleteOrderUpload(state, action);
    case Types.DELETE_UPLOAD_SUCCESS:
      return handleDeleteOrderUploadSuccess(state, action);
    case Types.DELETE_UPLOAD_ERROR:
      return handleDeleteOrderUploadError(state, action);

    case Types.CHANGE_STATUS_MODAL:
      return handleChangeStatusModal(state, action);

    case Types.RESET_SUCCESS_SAVE:
      return handleResetSuccessSave(state, action);
    case Types.RESET_REGISTER:
      return handleResetRegister(state, action);
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}