import React, { useEffect, useMemo, useState } from 'react';

import { Skeleton, Tooltip, Row, Column, Button, Text } from '@components-teammove';
import { 
  ActivityIcon, 
  Container, 
  ForToday, 
  IconWrapper, 
  MonthEvent,
  MonthEventColor, 
  ColumnHeaderView, 
  WeekDay, 
  MonthDay, 
  ToolbarRow, 
  TimePasserRow, 
  ToolbarDateDescription, 
  TimePasserButton, 
  StyledTabs, 
  FeatherIcon, 
  WeekEvent, 
  SecondaryText, 
  EventTitle, 
  DayEvent, 
  SecondaryTextMonth, 
  ContentInfoMonth, 
  DateText 
} from './styles';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import MonthDayView from './MonthDayView';

import { formatTime, InfoUsuario } from '@utils';
import moment, { getNomeMesData } from '@utils/Datas';
import { getDayViewDescription } from './rules';
import { useSelector } from 'react-redux';

const localizer = momentLocalizer(moment);

const Schedule = ({ view, setView, date, setDate, min, max, loading, customOptions, setAction, ...props }) => {
  const [visibleMonthDayView, setVisibleMonthDayView] = useState(false);
  const [workingDaysUser, setWorkingDaysUser] = useState(InfoUsuario.get('workingJourney')?.workingDays);

  const atendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('atendimento'));
  const successAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successAtendimento'));
  
  const prevViewDate = useMemo(() => moment(date).subtract(1, view + 's').toDate(), [date, view]);
  const nextViewDate = useMemo(() => moment(date).add(1, view + 's').toDate(), [date, view]);
  const isPrevButtonDisabled = useMemo(() => min && moment(prevViewDate).endOf(view).isBefore(moment(min)), [prevViewDate, min, view]);
  const isNextButtonDisabled = useMemo(() => max && moment(nextViewDate).startOf(view).isAfter(moment(max)), [nextViewDate, max, view]);
  const isCurrentButtonDisabled = useMemo(() => (min && moment().isBefore(min)) || (max && moment().isAfter(max)), [min, max]);
  const hasAllDayEvent = useMemo(() => props.events.some(({ allDay, start, end }) => {
    const { min, max } =  view === 'day' ? { min: moment(date).startOf('day'), max: moment(date).endOf('day') } : 
      view === 'week' ? { min: moment(date).startOf('week'), max: moment(date).endOf('week') } : 
        { min: moment(date).startOf('month'), max: moment(date).endOf('month') };
    
    return allDay && (moment(start).isSameOrAfter(moment(min)) && moment(end).isSameOrBefore(moment(max)));
  }), [props.events]);

  useEffect(() => {
    if(successAtendimento) {
      setWorkingDaysUser(atendimento.workingDays);
    }
  },[successAtendimento]);

  const getWorkHoursForWeek = () => {
    const dayMapping = {
      'SUNDAY': 0,
      'MONDAY': 1,
      'TUESDAY': 2,
      'WEDNESDAY': 3,
      'THURSDAY': 4,
      'FRIDAY': 5,
      'SATURDAY': 6,
    };
  
    const defaultMinTime = moment().startOf('day').set({ hour: 8, minute: 0, second: 0 });
    const defaultMaxTime = moment().endOf('day').set({ hour: 18, minute: 0, second: 0 });
  
    let minTime = defaultMinTime;
    let maxTime = defaultMaxTime;
    if(!workingDaysUser) return ({ min: minTime.toDate(), max: maxTime.toDate() });

    for (let index = 0; index < 7; index++) {
      const dayName = Object.keys(dayMapping).find((key) => dayMapping[key] === index);
  
      const workingDay = workingDaysUser.find((workingDayUser) => workingDayUser.day === dayName);
  
      if (workingDay) {
        const { shifts } = workingDay;
        const startTimes = shifts.map((shift) => moment(shift.start, 'HH:mm'));
        const endTimes = shifts.map((shift) => moment(shift.end, 'HH:mm'));
  
        const minStartTime = moment.min(startTimes);
        const maxEndTime = moment.max(endTimes);

        if (minStartTime.isBefore(minTime)) {
          minTime = minStartTime;
        }

        if (maxEndTime.isAfter(maxTime)) {         
          maxTime = maxEndTime;
        }
      }
    }

    return {
      min: minTime.toDate(),
      max: maxTime.toDate(),
    };
  };
  
  const minAndMaxWorkingTime = getWorkHoursForWeek();

  return loading ? (
    <Column>
      <ToolbarRow>
        <Row gap='24px' align='center'>
          <Button type='primary' size='smaller' context='list' onClick={() => setDate(moment().toDate())} disabled>
            {view === 'day' ? 'Hoje' : view === 'week' ? 'Semana atual' : 'Mês atual'}
          </Button>
          <TimePasserRow>
            <TimePasserButton type='tertiary' onClick={() => setDate(moment(date).subtract(1, view + 's').toDate())} disabled icon={<FeatherIcon type='chevron-left'/>}/>
            <TimePasserButton type='tertiary' onClick={() => setDate(moment(date).add(1, view + 's').toDate())} disabled icon={<FeatherIcon type='chevron-right'/>}/>
          </TimePasserRow>
          <ToolbarDateDescription>
            {
              view === 'day' ? getDayViewDescription(date) : 
                view === 'week' ? (getNomeMesData(moment(date).startOf('week')) + ' ' + moment(date).startOf('week').format('DD') + ' - ' + getNomeMesData(moment(date).endOf('week')) + ' ' + moment(date).endOf('week').format('DD')) : 
                  (getNomeMesData(moment(date)) + ' ' + new Date(date).getFullYear())
            }
          </ToolbarDateDescription>
        </Row>
        <StyledTabs   
          options={customOptions || [{ name: 'Dia' }, { name: 'Semana' }, { name: 'Mês' }]} 
          current={view === 'day' ? 'Dia' : view === 'week' ? 'Semana' : 'Mês'} 
          disabled 
          layout='inline'
        />
      </ToolbarRow>
      <Skeleton height='1000px'/>
    </Column>
  ) : (
    <>
      <Container view={view} isToday={moment(date).isSame(moment(), 'day')} hasAllDayEvent={hasAllDayEvent}>
        <Calendar
          min={minAndMaxWorkingTime.min}
          max={minAndMaxWorkingTime.max}
          localizer={localizer}
          scrollToTime={date}
          date={date}
          messages={{
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            today: 'atual',
            next: '>',
            previous: '<',
            tomorrow: 'Amanhã',
            yesterday: 'Ontem',
            // eslint-disable-next-line camelcase
            work_week: 'Semana útil',
            showMore: (total) => 'Mostrar mais ' + total
          }}
          startAccessor="start"
          endAccessor="end"
          views={['month', 'week', 'day']}
          view={view}
          onView={(newView) => setView(newView)}
          // eslint-disable-next-line react/forbid-component-props
          style={{ height: 1000 }}
          components={{
            timeGutterHeader: view === 'day' && props.events.some(({ start, allDay }) => moment(start).isSame(date, 'day') && allDay) && (() => (
              <ForToday>Para hoje:</ForToday>
            )),
            day: {
              event: ({ event }) => (
                <Row flex={1} title={`${event?.allDay ? '' : `${formatTime(event?.start)} - ${formatTime(event?.end)}: `}${event?.address}`}>
                  <IconWrapper color={event?.typeColor}>
                    <ActivityIcon type={event?.typeIcon}/>
                  </IconWrapper>
                  <DayEvent allDay={event?.allDay} duration={Math.abs(moment(event?.start).diff(event?.end, 'minutes'))} canceled={event?.canceled} finished={event?.finished}>
                    <Column justify='space-between' flex={1}>
                      <Column gap='4px'>
                        <Row align='center' gap='4px'>
                          <SecondaryText noWrap>{event?.topLeftInfo}</SecondaryText>
                          {!event?.allDay && <DateText size='12px' weight='400' line='16px' lowOpacity>{formatTime(event?.start)} - {formatTime(event?.end)}</DateText>}
                        </Row>
                        <Column>
                          <EventTitle>{event?.title}</EventTitle>
                          <SecondaryText lowOpacity>{event?.fullName}</SecondaryText>
                          <SecondaryText>{event?.address}</SecondaryText>
                        </Column>
                      </Column>
                    </Column>
                  </DayEvent>
                </Row>
              ),
            },
            week: {
              header: ({ date }) => {
                const disabled = moment(date).isBefore(moment(min)) || moment(date).isAfter(moment(max));
                return (
                  <Tooltip title={disabled && 'Dia está fora do período selecionado'}>
                    <ColumnHeaderView>
                      <MonthDay disabled={disabled}>{new Date(date).getDate()}</MonthDay>
                      <WeekDay disabled={disabled}>{['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][new Date(date).getDay()]}</WeekDay>
                    </ColumnHeaderView>
                  </Tooltip>
                );
              },
              event: ({ event }) => (
                <WeekEvent title={`${event?.allDay ? '' : `${formatTime(event?.start)} - ${formatTime(event?.end)}: `}${event?.address}`} allDay={event?.allDay} duration={Math.abs(moment(event?.start).diff(event?.end, 'minutes'))} color={event?.typeColor} view={view} canceled={event?.canceled} finished={event?.finished}>
                  <Column justify='space-between' flex={1}>
                    <Column gap='4px'>
                     
                      <Row align='center'>
                        <SecondaryText isNotAllDay={!event?.allDay} noWrap>{event?.topLeftInfo}</SecondaryText>
                        {!event?.allDay && <DateText size='12px' weight='400' lowOpacity>{formatTime(event?.start)} - {formatTime(event?.end)}</DateText>}
                      </Row>
                      <Column>
                        <EventTitle>{event?.title}</EventTitle>
                        <SecondaryText lowOpacity>{event?.fullName}</SecondaryText>
                        <SecondaryText>{event?.address}</SecondaryText>
                      </Column>
                    </Column>
                  </Column>
                </WeekEvent>  
              ),
            },
            month: {
              header: ({ date }) => {
                const disabled = moment(date).isBefore(moment(min)) || moment(date).isAfter(moment(max));
                return (
                  <Tooltip title={disabled && 'Dia está fora do período selecionado'}>
                    <ColumnHeaderView>
                      <WeekDay disabled={disabled}>{['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][new Date(date).getDay()]}</WeekDay>
                    </ColumnHeaderView>
                  </Tooltip>
                );
              },
              event: ({ event }) => (
                <MonthEvent 
                  title={`${event?.allDay ? '' : `${formatTime(event?.start)} - ${formatTime(event?.end)}: `}${event?.subTitle}`}
                  canceled={event?.canceled}
                  finished={event?.finished}
                  onClick={() => {
                    view === 'month' ? setVisibleMonthDayView(event?.start) : props.onSelectEvent(event);
                  }}
                >
                  <Tooltip title={
                    <Column>
                      <Text size='12px'>{event?.topLeftInfo}</Text>
                      <Text size='12px'>{event?.title}</Text>
                      <Text size='12px'>{event?.topRightInfo}</Text>
                    </Column>
                  }
                  >
                    <ContentInfoMonth >
                      <Row justify='space-between' align='center'>
                        <Row gap='4px' align='center' >                  
                          <MonthEventColor color={event?.typeColor}/>
                          <SecondaryTextMonth>{event?.topLeftInfo}</SecondaryTextMonth>
                        </Row>      
                        {moment(event?.start).format('HH:mm') !== '00:00' && 
                          <SecondaryText>{moment(event?.start).format('HH:mm')}</SecondaryText>
                        }        
                      </Row>
                      
                    </ContentInfoMonth>
                  </Tooltip>  
                </MonthEvent>              
              ),
            },
            toolbar: () => (
              <ToolbarRow>
                <Row gap='24px' align='center'>
                  <Tooltip title={isCurrentButtonDisabled && (view === 'day' ? 'Dia' : view === 'week' ? 'Semana' : 'Mês') + ' atual está fora do período selecionado'}>
                    <div>
                      <Button 
                        type='primary' 
                        size='smaller'
                        context='list'
                        onClick={() => setDate(moment().toDate())} 
                        disabled={isCurrentButtonDisabled}
                      >
                        {view === 'day' ? 'Hoje' : view === 'week' ? 'Semana atual' : 'Mês atual'}
                      </Button>
                    </div>
                  </Tooltip>
                  <TimePasserRow>
                    <Tooltip title={isPrevButtonDisabled && (view === 'day' ? 'Dia' : view === 'week' ? 'Semana' : 'Mês') + ' anterior está fora do período selecionado'}>
                      <div>
                        <TimePasserButton 
                          type='tertiary' 
                          onClick={() => {setDate(prevViewDate); setAction && setAction('regredir');}} 
                          disabled={isPrevButtonDisabled}
                          icon={<FeatherIcon type='chevron-left'/>}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={isNextButtonDisabled && (view === 'day' ? 'Dia' : view === 'week' ? 'Semana' : 'Mês') + ' posterior está fora do período selecionado'}>
                      <div>
                        <TimePasserButton 
                          type='tertiary' 
                          onClick={() => {setDate(nextViewDate); setAction && setAction('progredir');}} 
                          disabled={isNextButtonDisabled}
                          icon={<FeatherIcon type='chevron-right'/>}
                        />
                      </div>
                    </Tooltip>
                  </TimePasserRow>
                  <ToolbarDateDescription>
                    {
                      view === 'day' ? getDayViewDescription(date) : 
                        view === 'week' ? (getNomeMesData(moment(date).startOf('week')) + ' ' + moment(date).startOf('week').format('DD') + ' - ' + getNomeMesData(moment(date).endOf('week')) + ' ' + moment(date).endOf('week').format('DD')) : 
                          (getNomeMesData(moment(date)) + ' ' + new Date(date).getFullYear())
                    }
                  </ToolbarDateDescription>
                </Row>
                <StyledTabs 
                  options={
                    customOptions ||
                    [{ name: 'Dia' }, { name: 'Semana' }, { name: 'Mês' }]
                  } 
                  current={view === 'day' ? 'Dia' : view === 'week' ? 'Semana' : 'Mês'} 
                  onChange={(newValue) => setView(newValue === 'Dia' ? 'day' : newValue === 'Semana' ? 'week' : 'month')}
                  layout='inline'
                />
              </ToolbarRow>
            )
          }}
          selectable={view === 'month'}
          onSelectSlot={({ start: slotStart }) => props.events.filter(({ start }) => moment(start).isSame(slotStart, 'day')).length > 0 && setVisibleMonthDayView(slotStart)}
          {...props}
        />
      </Container>
      {visibleMonthDayView && (
        <MonthDayView 
          date={visibleMonthDayView} 
          events={props.events.filter(({ start }) => moment(start).isSame(visibleMonthDayView, 'day'))} 
          onCancel={() => setVisibleMonthDayView(false)}
        />
      )}
    </>
  );
};

export default Schedule;