import { InfoUsuario, Bugsnag } from '@utils';

const { REACT_APP_API, REACT_APP_API_NODE } = window.env;

const verificaErro = async (response, headers, body) => {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/pdf')) {
    const responseBlob = await response.blob();

    try {
      if (response.ok) return responseBlob;
      reportError(headers, body);
      throw new Error(responseBlob);
    } catch (e) {
      throw new Error(e.message);
    }
  } else {
    const responseText = await response.text();

    try {
      let responseObject = responseText ? JSON.parse(responseText) : null;

      if (response.ok) return responseObject !== null ? responseObject : true;
      reportError(headers, body);
      if (responseObject === null) responseObject = {};
      throw new Error(responseObject.message || responseObject.mensagem || 'Erro Desconhecido');
    } catch (e) {
      let responseJson = {};
      try {
        responseJson = JSON.parse(responseText);
      } catch (jsonError) {
        responseJson = { message: responseText };
      }
      reportError(headers, body);
      if (responseJson.message) {
        throw new Error(responseJson.message);
      } else if (responseJson.error) {
        throw new Error(responseJson.error);
      } else {
        throw new Error(e.message);
      }
    }
  }
};

const reportError = (headers, body) => {
  Bugsnag.leaveBreadcrumb('[API] - Headers', headers);
  Bugsnag.leaveBreadcrumb('[API] - Body', body);
};

const getHeaders = (headers = {}) => ({
  // prettier-ignore
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  ...authorization(),
  ...headers,
});

export const post = (api, body, adicionalHeaders = {}, useApiNode) => {
  const headers = getHeaders(adicionalHeaders);
  return fetch(getPath(api, useApiNode), {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((response) => {
    return verificaErro(response, headers, body);
  });
};

export const get = (api, adicionalHeaders = {}, useApiNode) => {
  const headers = getHeaders(adicionalHeaders);
  return fetch(getPath(api, useApiNode), {
    method: 'GET',
    headers,
  }).then((response) => {
    return verificaErro(response, headers);
  });
};

export const del = (api, adicionalHeaders = {}, useApiNode) => {
  const headers = getHeaders(adicionalHeaders);

  return fetch(getPath(api, useApiNode), {
    method: 'DELETE',
    headers,
  }).then((response) => {
    return verificaErro(response, headers);
  });
};

export const put = (api, body, adicionalHeaders = {}, useApiNode) => {
  const headers = getHeaders(adicionalHeaders);
  return fetch(getPath(api, useApiNode), {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  }).then((response) => {
    return verificaErro(response, headers, body);
  });
};

export const authorization = () => {
  if (!InfoUsuario.hasUser()) return {};

  const infoUsuario = InfoUsuario.get();
  return {
    key: infoUsuario.key,
    token: infoUsuario.token,
  };
};

const getPath = (api, useApiNode) => {
  if (useApiNode) return REACT_APP_API_NODE + api;
  if (api.toLowerCase().startsWith('http')) {
    return api;
  }
  return REACT_APP_API + api;
};

export const postFormData = (api, body, adicionalHeaders = {}) => {
  const headers = {
    ...authorization(),
    ...adicionalHeaders,
  };

  return fetch(getPath(api), {
    method: 'POST',
    headers,
    body: body,
  }).then((response) => {
    return verificaErro(response, headers, body);
  });
};
