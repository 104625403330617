import styled from 'styled-components';
import { Select } from '@components-teammove';

export const SelectPeriod = styled(Select)`
	border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
	width: 200px !important;
`;

export const TagDate = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
	background-color: ${({ theme })=> theme.actionColor};
	border-radius: 8px;
	padding: 6px 15px 6px 15px;
	&:hover{
		cursor: pointer;
	}
`;

export const SelectPeriodButton = styled.div`
	width: 122px;
	border-radius: ${({ radius })=> radius ? radius : 0};
	background-color: ${({ theme, active })=> active ? theme.actionColor : theme.darkPrimaryColor};
	padding: 6px 6px 6px 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
`;