import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Icon } from '@components-teammove';

import WikiAudiencias from '@containers/Wikis/Conteudos/Audiencias';

export default function VisualizacaoOpcoes({ item }) {
  const history = useHistory();
  const iconSizeDefault = 0.7;

  const [visibleAudiencias, setVisibleAudiencias] = useState(false);

  return (
    <>
      <Dropdown
        key="more"
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => {
                setVisibleAudiencias(true);
              }}
            >
              <Button type="icon" icon={<Icon.MDI type="account-check" size={iconSizeDefault} />}>
              Audiência
              </Button>
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                if(item.comunicado) {
                  history.push(`/comunicados?id=${item.id}`);
                } else {
                  history.push(`/conteudos/visualizacao/${item.id}`);
                }
              }}
            >
              <Button type="icon" icon={<Icon.MDI type="text-box-multiple-outline" size={iconSizeDefault} />}>
              Visualizar Conteúdo
              </Button>
            </Menu.Item>
          </Menu>
        }
      >
        <Button type="icon">
          <Icon.MDI type="dots-vertical" />
        </Button>
      </Dropdown>

      {visibleAudiencias && (
        <WikiAudiencias 
          visible={visibleAudiencias} 
          onCancel={() => setVisibleAudiencias(false)} 
          id={item.id} 
          ds={item.dsCategoria}
        />
      )}
    </>
  );
}
