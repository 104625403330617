import styled from 'styled-components';
import { Modal, View, Form, Text, Input, InputMoney, Button } from '@components-teammove';

export const ModalPersonalization = styled(Modal)`
  min-width: 700px;
`;

export const Container = styled(View)``;

export const FormParams = styled(Form)`
width: 100%;
margin-top: 35px;
`;

export const FormContainer = styled(View)`
    display: flex;
`;

export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`;

export const ItemRow = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
`;

export const NumberRound = styled(View)`
    min-width: 30px;
    min-height: 30px;
    display: flex;
    border: 1px solid #C4C4C4;
    border-radius: 50%;
    margin-right: 10px;
`;

export const ItemNumber = styled(Text)`
    &.ant-typography {
        font-size: 12px;
        font-weight: bold; 
        padding: 2px !important;
        color: ${({ theme }) => theme.textColor};
    }
`;
export const ItemTitle = styled(Text)`
    &.ant-typography {
        margin-right: 25px;
        color: ${({ theme }) => theme.textColor};
    }
`;

export const SugestaoTag = styled(View)`
    display: flex;
    background-color: #9cbdff;
    align-items: center;
    border-radius: 5px;
    margin-left: 7px;
`;

export const SugestaoTagText = styled(Text)`
    padding-left: 2px;
    padding-right: 2px;
`;

export const PriceInput = styled(InputMoney)`
    width: 150px;
    text-align: right;
`;

export const SpecialView = styled(View)`
    input {
        text-align: right;
    }
`;

export const SpecialPriceInput = styled(Input)`
    width: 150px;
    height: 30px;
    padding: 5px !important;
`;

export const ListaTitle = styled(Text)`
    &.ant-typography {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.textColor};
    }
`;

export const SearchView = styled(View)`
    flex:1;
    width: 100%;
`;

export const DropdownButton = styled(Button)`
    &.ant-btn {
        margin: auto 0 auto 3px;
        height: 100%;
    }
`;