import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Text, View } from '@components';
import { Icon } from '@components-teammove';
import { Visualizacao } from '@containers/Wikis/Conteudos/Visualizacao';
import { Map } from 'immutable';

const Titulo = styled(Text)`
  flex: 1;
  padding-bottom: 10px;
`;

const Aula = styled(View)`
  font-size: 20px;
`;

const Materia = styled(View)`
  font-size: 12px;
  color: #777777;
`;

const Conteudo = styled(View)`
  max-height: 780px;
`;

export default async function EADPreviewAula({ tipo, conteudo, materiaTitulo, aulaTitulo }) {
  const modal = Modal.info({
    title: (
      <View horizontal alignItems='center' justifyContent='space-between'>
        <Titulo>
          <Aula>{aulaTitulo}</Aula>
          <Materia>{materiaTitulo}</Materia>
        </Titulo>
        <Button
          type='icon'
          icon={<Icon.CloseOutlined />}
          onClick={() => {
            close();
          }}
        />
      </View>
    ),
    content: (
      <Conteudo>
        <Visualizacao
          conteudo={Map({ conteudo, tipo: tipo === 'I' ? 'IMAGEM' : tipo === 'P' ? 'PDF' : tipo === 'V' ? 'VIDEO' : 'LINK' })}
          maxHeight='780px'
          height='780px'
        />
      </Conteudo>
    ),
    maskClosable: true,
    className: 'modal-top no-buttons',
    icon: null,
    onCancel() {
      modal.destroy();
    },
    width: 520,
  });

  const close = () => {
    modal.destroy();
  };
}
