import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { getDadosCadastro as getDadoscadastroSdk, putCadastro as putCadastroSdk, putGrupo as putGrupoSdk } from '@sdk/Wikis/Cadastro';

export const Types = {
  GET_DADOSCADASTRO: 'WIKI/GET_DADOSCADASTRO',
  GET_DADOSCADASTRO_SUCCESS: 'WIKI/GET_DADOSCADASTRO_SUCCESS',
  GET_DADOSCADASTRO_ERROR: 'WIKI/GET_DADOSCADASTRO_ERROR',

  PUT_CADASTRO: 'WIKI/PUT_CADASTRO',
  PUT_CADASTRO_SUCCESS: 'WIKI/PUT_CADASTRO_SUCCESS',
  PUT_CADASTRO_ERROR: 'WIKI/PUT_CADASTRO_ERROR',

  PUT_GRUPO: 'WIKI/PUT_GRUPO',
  PUT_GRUPO_SUCCESS: 'WIKI/PUT_GRUPO_SUCCESS',
  PUT_GRUPO_ERROR: 'WIKI/PUT_GRUPO_ERROR',

  RESET: 'WIKI_CADASTRO/RESET',
  RESET_SUCCESS_SAVE: 'WIKI_CADASTRO/RESET_SUCCESS_SAVE',
};

export const getDadosCadastro = () => ({ type: Types.GET_DADOSCADASTRO });
export const getDadosCadastroSuccess = (dadosCadastro) => ({ type: Types.GET_DADOSCADASTRO_SUCCESS, dadosCadastro });
export const getDadosCadastroError = (error) => ({ type: Types.GET_DADOSCADASTRO_ERROR, error });

export const putCadastro = (wiki, id) => ({ type: Types.PUT_CADASTRO, wiki, id });
export const putCadastroSuccess = (status) => ({ type: Types.PUT_CADASTRO_SUCCESS, status });
export const putCadastroError = (error) => ({ type: Types.PUT_CADASTRO_ERROR, error });

export const putGrupo = (grupo) => ({ type: Types.PUT_GRUPO, grupo });
export const putGrupoSuccess = (status) => ({ type: Types.PUT_GRUPO_SUCCESS, status });
export const putGrupoError = (error) => ({ type: Types.PUT_GRUPO_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccessSave = () => ({ type: Types.RESET_SUCCESS_SAVE });

function* fetchGetDadosCadastro(action) {
  try {
    const dadosCadastro = yield call(getDadoscadastroSdk);
    yield put(getDadosCadastroSuccess(dadosCadastro));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDadosCadastroError(err));
  }
}

function* fetchPutCadastro(action) {
  try {
    const { wiki, id } = action;

    const status = yield call(putCadastroSdk, wiki, id);
    yield put(putCadastroSuccess(status));
  } catch (err) {
    Notification.error(err.message);
    yield put(putCadastroError(err));
  }
}

function* fetchPutGrupo(action) {
  try {
    const { grupo } = action;

    const satus = yield call(putGrupoSdk, grupo);
    yield put(putGrupoSuccess(satus));
  } catch (err) {
    Notification.error(err.message);
    yield put(putGrupoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_DADOSCADASTRO, fetchGetDadosCadastro),
  takeLatest(Types.PUT_CADASTRO, fetchPutCadastro),
  takeLatest(Types.PUT_GRUPO, fetchPutGrupo),
];

const initialState = Map({
  dadosCadastro: {},

  loadingDadosCadastro: false,
  successDadosCadastro: false,
  errorDadosCadastro: false,

  loadingPutCadastro: false,
  successPutCadastro: false,
  errorPutCadastro: false,

  loadingPutGrupo: false,
  successPutGrupo: false,
  errorPutGrupo: false,
});

const handleGetDadosCadastro = (state, action) => {
  return state.set('loadingDadosCadastro', true).set('successDadosCadastro', false).set('errorDadosCadastro', false);
};

const handleGetDadosCadastroSuccess = (state, action) => {
  const { dadosCadastro } = action;

  return state
    .set('dadosCadastro', dadosCadastro)
    .set('loadingDadosCadastro', false)
    .set('successDadosCadastro', true)
    .set('errorDadosCadastro', false);
};

const handleGetDadosCadastroError = (state, action) => {
  return state.set('loadingGetDadosCadastro', false).set('successGetDadosCadastro', false).set('errorGetDadosCadastro', action.error);
};

const handlePutCadastro = (state, action) => {
  return state.set('loadingPutCadastro', true).set('successPutCadastro', false).set('errorPutCadastro', false);
};

const handlePutCadastroSuccess = (state, action) => {
  return state.set('loadingPutCadastro', false).set('successPutCadastro', true).set('errorPutCadastro', false);
};

const handlePutCadastroError = (state, action) => {
  return state.set('loadingPutCadastro', false).set('successPutCadastro', false).set('errorPutCadastro', action.error);
};

const handlePutGrupo = (state, action) => {
  return state.set('loadingPutGrupo', true).set('successPutGrupo', false).set('errorPutGrupo', false);
};

const handlePutGrupoSuccess = (state, action) => {
  return state.set('loadingPutGrupo', false).set('successPutGrupo', true).set('errorPutGrupo', false);
};

const handlePutGrupoError = (state, action) => {
  return state.set('loadingPutGrupo', false).set('successPutGrupo', false).set('errorPutGrupo', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccessSave = (state, action) => {
  return state.set('successPutCadastro', initialState.get('successPutCadastro')).set('successPutGrupo', initialState.get('successPutGrupo'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DADOSCADASTRO:
      return handleGetDadosCadastro(state, action);
    case Types.GET_DADOSCADASTRO_SUCCESS:
      return handleGetDadosCadastroSuccess(state, action);
    case Types.GET_DADOSCADASTRO_ERROR:
      return handleGetDadosCadastroError(state, action);

    case Types.PUT_CADASTRO:
      return handlePutCadastro(state, action);
    case Types.PUT_CADASTRO_SUCCESS:
      return handlePutCadastroSuccess(state, action);
    case Types.PUT_CADASTRO_ERROR:
      return handlePutCadastroError(state, action);
    case Types.PUT_GRUPO:
      return handlePutGrupo(state, action);
    case Types.PUT_GRUPO_SUCCESS:
      return handlePutGrupoSuccess(state, action);
    case Types.PUT_GRUPO_ERROR:
      return handlePutGrupoError(state, action);
    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SUCCESS_SAVE:
      return handleResetSuccessSave(state, action);

    default:
      return state;
  }
}
