import React, { useMemo, useState } from 'react';
import { Column, Container, PageIndicator, Row } from './styles';

const HorizontalContainer = ({ items = [], itemsPerPage = 4 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const dividedItems = useMemo(() => {
    return items.reduce((state, item) => {
      return state.index === itemsPerPage - 1 ? ({ index: 0, arr: [...state.arr, [item]] }) : ({ index: state.index + 1, arr: (state.arr.length > 0 ? state.arr.map((arr) => arr.length < itemsPerPage ? [...arr, item] : [...arr]) : [[item]]) });
    }, { index: -1, arr: [] }).arr;
  }, [items, itemsPerPage]);

  return (
    <Column>
      <Container itemsPerPage={itemsPerPage}>
        {dividedItems[currentPage]}
      </Container>
      <Row>
        {items.length >= itemsPerPage && new Array(Math.ceil(items.length / itemsPerPage)).fill('').map((elem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PageIndicator key={index} current={index === currentPage} onClick={() => setCurrentPage(index)}/>
        ))}
      </Row>
    </Column>
  );
};

export default HorizontalContainer;