import React from 'react';
import MaskedInput from 'antd-mask-input';

const InputMask = ({ ...props }) => (
  <MaskedInput {...props} />
);

InputMask.defaultProps = {
  allowClear: true,
};

export default InputMask;
