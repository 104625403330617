import styled from 'styled-components';
import { Text  } from '@components';
import { Icon , Upload, Image, View } from '@components-teammove';

export const UploadIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};
  align-items: center;
  justify-content: center;
  display: flex;
  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
  }
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ContainerBody = styled.div`
  width: 450px;
`;

export const UploadEdge = styled(Upload.Dragger)`
  border: 2px dashed ${(props) => props.theme.darkHighlightColor};
  height: 100px;
  border-radius:12px;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  .ant-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ImageCover = styled(Image)`
  object-fit: cover;
`;

export const Div = styled.div`
  margin-top: 20px;
  border-radius: 12px;
  ${({ theme }) =>  `
    background-color: ${theme.darkPrimary};
    border-color: ${theme.darkHighlight};
  `}
  border-width: 1px;
  width: 100%;
  height: 86px;
`;

export const IconRemoveCover = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  * {
    color: ${({ theme }) => theme.actionColor} !important;
  }
`;

export const ComponentEmptyCover = styled(View)`
  width: 223px;
  height: 180px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;

export const IconLoading = styled(Icon.LoadingOutlined)`
 * {
  color: ${({ theme }) => theme.actionColor};
  font-size: 25px;
 }
`;