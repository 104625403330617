import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Row, Column, DividedLine, Skeleton } from '@components-teammove';
import { Card, CardSkeleton } from './styles';

import { addPointToTotalHours, formatedTime, formatTimeInTotalHours, fullFilledPercentage } from '../rules';

import { getAgendaManagementSummary } from '@ducks/atividadesGestaoAgenda';

export const TeamsSummaryCards = ({ currentParams, idUser, removeUnrecordedTime }) => {
  const dispatch = useDispatch();
  
  const gestaoAgendaManagementSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('gestaoAgendaManagementSummary'));
  const successGestaoAgendaManagementSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('successGestaoAgendaManagementSummary'));
  const loadingGestaoAgendaManagementSummary = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('loadingGestaoAgendaManagementSummary'));

  useEffect(() => {
    if(idUser) {
      dispatch(getAgendaManagementSummary({ ...currentParams }, idUser));
    }else {
      dispatch(getAgendaManagementSummary({ ...currentParams }));
    }
  }, [currentParams]);
  
  return(
    loadingGestaoAgendaManagementSummary ? 
      <>
        <Text size='20px' weight='700'>Resumo</Text>
        <Row gap='12px'>
          <CardSkeleton><Skeleton/></CardSkeleton> 
          <CardSkeleton><Skeleton/></CardSkeleton> 
          <CardSkeleton><Skeleton/></CardSkeleton> 
        </Row>
      </>
      : successGestaoAgendaManagementSummary && (
        <Column gap='12px'>
          <Text size='20px' weight='700'>Resumo</Text>
          <Row gap='12px'>
            <Card>
              <Text size='16px' weight='600' lowOpacity>Aproveitamento da agenda</Text>
              <Row align='baseline' gap='12px' >
                <Text size='24px' weight='700'>{(gestaoAgendaManagementSummary.fillPercent).toFixed(2)}%</Text>  
                <Text size='16px' weight='400' lowOpacity>
                  {addPointToTotalHours(formatTimeInTotalHours(gestaoAgendaManagementSummary.filledTime))} de {addPointToTotalHours(formatTimeInTotalHours(gestaoAgendaManagementSummary.totalTime))} horas
                </Text>  
              </Row>
            </Card>

            <Card>
              <Row justify='space-between'>
                <Text size='16px' weight='600' lowOpacity>Realizado</Text>
                <Text size='16px' weight='600' lowOpacity>Planejado</Text>
              </Row>
              <Row justify='space-between'>
                <Text size='16px' weight='700' color='var(--warning-color)'>{formatedTime(gestaoAgendaManagementSummary.fulfilledTime, true)}</Text>
                <Text size='16px' weight='700' >{formatedTime(gestaoAgendaManagementSummary.filledTime, true)}</Text>
              </Row>
              <DividedLine
                subtitleDisabled
                divisions={[
                  { label: 'Realizado', 
                    percentage: (
                      fullFilledPercentage(formatTimeInTotalHours(gestaoAgendaManagementSummary.filledTime), formatTimeInTotalHours(gestaoAgendaManagementSummary.fulfilledTime))
                    ), 
                    status: 'warning' 
                  },  
                  { label: 'Planejado', 
                    percentage: (
                      100 - fullFilledPercentage(formatTimeInTotalHours(gestaoAgendaManagementSummary.filledTime), formatTimeInTotalHours(gestaoAgendaManagementSummary.fulfilledTime))
                    ),            
                    useBgColor: true 
                  }, 
                ]}
              /> 
            </Card>
        
            {!removeUnrecordedTime && (
              <Card>
                <Text size='16px' weight='600' lowOpacity>Tempo sem registro</Text>
                <Row gap='4px' align='baseline'>
                  <Row>
                    <Text size='24px' weight='700'>
                      {formatTimeInTotalHours(gestaoAgendaManagementSummary.totalTime) === 0 ? '00:00:00' : addPointToTotalHours(formatTimeInTotalHours(gestaoAgendaManagementSummary.unregisteredTime))} horas
                    </Text>
                  </Row>
                 
                </Row>
              </Card>
            )}
            
          </Row>
        </Column>
      )
  );
};