import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getAfastamentos as getAfastamentosSdk, putAfastamento as putAfastamentoSdk } from '@sdk/Afastamento';

export const Types = {
  GET_AFASTAMENTOS: 'AFASTAMENTO/GET_AFASTAMENTOS',
  GET_AFASTAMENTOS_SUCCESS: 'AFASTAMENTO/GET_AFASTAMENTOS_SUCCESS',
  GET_AFASTAMENTOS_ERROR: 'AFASTAMENTO/GET_AFASTAMENTOS_ERROR',

  PUT_AFASTAMENTO: 'AFASTAMENTO/PUT_AFASTAMENTO',
  PUT_AFASTAMENTO_SUCCESS: 'AFASTAMENTO/PUT_AFASTAMENTO_SUCCESS',
  PUT_AFASTAMENTO_ERROR: 'AFASTAMENTO/PUT_AFASTAMENTO_ERROR',
  PUT_AFASTAMENTO_RESET: 'AFASTAMENTO/PUT_AFASTAMENTO_RESET',
};

export const getAfastamentos = () => ({ type: Types.GET_AFASTAMENTOS });
export const getAfastamentosSuccess = (afastamentos) => ({ type: Types.GET_AFASTAMENTOS_SUCCESS, afastamentos });
export const getAfastamentosError = (error) => ({ type: Types.GET_AFASTAMENTOS_ERROR, error });

export const putAfastamento = (afastamento) => ({ type: Types.PUT_AFASTAMENTO, afastamento });
export const putAfastamentoSuccess = (afastamento) => ({ type: Types.PUT_AFASTAMENTO_SUCCESS, afastamento });
export const putAfastamentoError = (error) => ({ type: Types.PUT_AFASTAMENTO_ERROR, error });
export const putAfastamentoReset = () => ({ type: Types.PUT_AFASTAMENTO_RESET });

function* fetchGetAfastamentos(action) {
  try {
    const afastamentos = yield call(getAfastamentosSdk);
    yield put(getAfastamentosSuccess(afastamentos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAfastamentosError(err));
  }
}

function* fetchPutAfastamento(action) {
  try {
    const { afastamento } = action;

    const afastamentoRetorno = yield call(putAfastamentoSdk, afastamento);
    yield put(putAfastamentoSuccess(afastamentoRetorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(putAfastamentoError(err));
  }
}

export const saga = [takeLatest(Types.GET_AFASTAMENTOS, fetchGetAfastamentos), takeLatest(Types.PUT_AFASTAMENTO, fetchPutAfastamento)];

const initialState = Map({
  afastamentos: List(),

  loadingAfastamentos: false,
  successAfastamentos: false,
  errorAfastamentos: false,

  loadingPutAfastamento: false,
  successPutAfastamento: false,
  errorPutAfastamento: false,
});

const handleGetAfastamentos = (state, action) => {
  return state.set('loadingAfastamentos', true).set('successAfastamentos', false).set('errorAfastamentos', false);
};

const handleGetAfastamentosSuccess = (state, action) => {
  return state.set('afastamentos', List(action.afastamentos)).set('loadingAfastamentos', false).set('successAfastamentos', true).set('errorAfastamentos', false);
};

const handleGetAfastamentosError = (state, action) => {
  return state.set('loadingAfastamentos', false).set('successAfastamentos', false).set('errorAfastamentos', action.error);
};

const handlePutAfastamento = (state, action) => {
  return state.set('loadingPutAfastamento', true).set('successPutAfastamento', false).set('errorPutAfastamento', false);
};

const handlePutAfastamentoSuccess = (state, action) => {
  return state.set('loadingPutAfastamento', false).set('successPutAfastamento', true).set('errorPutAfastamento', false);
};

const handlePutAfastamentoError = (state, action) => {
  return state.set('loadingPutAfastamento', false).set('successPutAfastamento', false).set('errorPutAfastamento', action.error);
};

const handlePutAfastamentoReset = (state, action) => {
  return state.set('loadingPutAfastamento', false).set('successPutAfastamento', false).set('errorPutAfastamento', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_AFASTAMENTOS:
      return handleGetAfastamentos(state, action);
    case Types.GET_AFASTAMENTOS_SUCCESS:
      return handleGetAfastamentosSuccess(state, action);
    case Types.GET_AFASTAMENTOS_ERROR:
      return handleGetAfastamentosError(state, action);

    case Types.PUT_AFASTAMENTO:
      return handlePutAfastamento(state, action);
    case Types.PUT_AFASTAMENTO_SUCCESS:
      return handlePutAfastamentoSuccess(state, action);
    case Types.PUT_AFASTAMENTO_ERROR:
      return handlePutAfastamentoError(state, action);
    case Types.PUT_AFASTAMENTO_RESET:
      return handlePutAfastamentoReset(state, action);

    default:
      return state;
  }
}
