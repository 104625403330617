import React from 'react';
import { View, Checkbox, Tag } from '@components';
import { Icon, Badge } from '@components-teammove';

import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { postLido, postFavorito } from '@ducks/wikisAcoes';
import { tiposConteudo } from '@containers/Wikis/EAD/rules';
import { formatDecimal } from '@utils';
import { EmAndamento } from '@containers/Wikis/Conteudos/Visualizacao/Quiz/rules';

const ViewAula = styled(View)`
  padding: ${(props) => (props.quiz ? '5px 16px' : '16px')};
  cursor: pointer;
  align-items: center;

  :hover {
    background-color: #fafafa;
  }
`;

const ViewTituloAula = styled(View)`
  font-size: 18px;
  align-items: flex-start;
`;

const ViewPlay = styled(View)`
  margin: 0 10px;
  justify-content: center;
  color: #66cc66;
  font-size: 20px;
`;

const FavoriteIcon = styled(Icon.MDI)`
  ${(props) =>
  props.gold &&
    css`
      path {
        fill: #f5b800 !important;
      }
    `}
`;

const ViewAulaInfo = styled(View)`
  flex: 1;
  align-items: center;
`;

const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  padding-right: 10px;
`;

const TagStyled = styled(Tag)`
  margin-top: 4px;
  height: fit-content;
  margin-left: 25px;
`;

const Resultado = styled(View)`
  font-size: 12px;
  white-space: nowrap;
`;

const Desistencia = styled(View)`
  font-weight: bold;
  color: #cc4444;
`;

export default function Aulas({ data, handleSelection }) {
  const dispatch = useDispatch();

  const currentAula = useSelector(({ wikisEAD }) => wikisEAD.get('currentAula'));
  const theme = useSelector(({ app }) => app.get('theme'));

  const loadingLido = useSelector(({ wikisAcoes }) => wikisAcoes.get('loadingLido'));
  const loadingFavorito = useSelector(({ wikisAcoes }) => wikisAcoes.get('loadingFavorito'));

  const getTituloQuiz = (data) => {
    if (data) {
      const { finalizado, nota, total, desistencia } = data;

      if (desistencia === true) {
        return (
          <Resultado horizontal>
            Resultado do quiz:&nbsp; <Desistencia>não finalizado</Desistencia>
          </Resultado>
        );
      } else if (finalizado === true && nota !== undefined && total !== undefined) {
        return (
          <Resultado horizontal>
            Resultado do quiz:&nbsp;<strong>{formatDecimal((nota / total) * 100, 2)}%</strong>&nbsp;de acerto
          </Resultado>
        );
      } else if (finalizado === false) {
        return (
          <Resultado>
            <EmAndamento>Em andamento</EmAndamento>
          </Resultado>
        );
      }
    }

    return <></>;
  };

  const getAulas = () => {
    return data.aulas.map((row) => {
      const isQuiz = row.tipo === tiposConteudo.QUIZ;

      return isQuiz ? (
        <ViewAula
          horizontal
          key={row.id}
          quiz={isQuiz}
          onClick={() => handleSelection({ aula: row, materiaId: data.id, materiaTitulo: data.titulo })}
        >
          <ViewAulaInfo horizontal>
            <ViewTituloAula horizontal alignItems='center'>
              <TagStyled color={theme['@primary-color']}>quiz</TagStyled>
              <View>
                <View>{row.titulo}</View> {getTituloQuiz(row.informacaoQuiz)}
              </View>
            </ViewTituloAula>
            {currentAula.get('id') === row.id && (
              <ViewPlay alignItems='flex-start'>
                <Badge status='processing' />
              </ViewPlay>
            )}
          </ViewAulaInfo>
        </ViewAula>
      ) : (
        <ViewAula horizontal key={row.id} onClick={() => handleSelection({ aula: row, materiaId: data.id, materiaTitulo: data.titulo })}>
          <ViewAulaInfo horizontal>
            <CheckboxStyled
              checked={row.lido}
              disabled={loadingLido}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(postLido(row.id, !row.lido));
                row.lido = !row.lido;
              }}
            />
            <ViewTituloAula horizontal alignItems='center'>
              {row.titulo}
            </ViewTituloAula>
            {currentAula.get('id') === row.id && (
              <ViewPlay alignItems='flex-start'>
                <Badge status='processing' />
              </ViewPlay>
            )}
          </ViewAulaInfo>

          <View>
            <FavoriteIcon
              type={row.favorito ? 'star' : 'star-outline'}
              gold={row.favorito ? 1 : 0}
              disabled={loadingFavorito}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(postFavorito(row.id, !row.favorito));
                row.favorito = !row.favorito;
              }}
            />
          </View>
        </ViewAula>
      );
    });
  };

  return <View>{getAulas()}</View>;
}
