import { createGlobalStyle } from 'styled-components';

export const Style = createGlobalStyle`
  .ant-popover .ant-popover-inner {
    background-color: ${({ theme }) => theme.darkSecondaryColor};

    .ant-popover-message-title {
      color: ${({ theme }) => theme.textColor};
    }

    .ant-popover-buttons {
      > * {
        border-radius: 12px;
        border: none;
      }
      >:first-child {
        background-color: ${({ theme }) => theme.darkPrimaryColor};
        color: ${({ theme }) => theme.textColor};
      }
      >:last-child {
        background-color: ${({ theme }) => theme.actionColor};
        color: ${({ theme }) => theme.textContrastColor};
      }
    }

    .ant-popover-arrow {
      border-right-color: ${({ theme }) => theme.darkSecondaryColor};
      border-bottom-color: ${({ theme }) => theme.darkSecondaryColor};
    }
  }
`;