import React from 'react';
import { VideoPlayer } from '@components-teammove';
import styled from 'styled-components';

const VideoPlayerStyled = styled(VideoPlayer)`
  video {
    max-height: ${({ maxheight }) => maxheight};
  }
`;

export default function VisualizacaoVideo({ conteudo, height = '100%', maxheight = '100vh', autoplay = true }) {
  return (
    <VideoPlayerStyled
      url={conteudo.get('conteudo')}
      playing={autoplay}
      controls
      width='100%'
      height={height}
      maxheight={maxheight}
      id='visualizacao-video'
      config={{
        vimeo: {
          playerOptions: { speed: true },
          autoplay
        },
      }}
      onReady={() => {
        const view = document.getElementById('visualizacao-video');
        const iframe = view.querySelector('iframe');

        if (iframe) iframe.focus();
      }}
    />
  );
}
