import React, { useMemo } from 'react';
import { Map, Skeleton, Column, ContentHeader, ViewControl, Icon } from '@components-teammove';

import { moment, median } from '@utils';
import { Container } from './styles';

export default function UsersMap({ handleViewByChange, viewBy, users, loading, filtros }) {
  const mapMarkers = useMemo(() => {
    return users.map(({ lastCheckinLatitude: latitude, lastCheckinLongitude: longitude, companyShortName, companyName, companyCity, companyState, userId, lastCheckinDate }, index) => {
      const icon = (() => {
        if (!lastCheckinDate) {
          return 'https://teammove.app/icones/maps-clusters/marker_grey.png';
        } else if (moment(lastCheckinDate).isBetween(moment(filtros.data[0]), moment(filtros.data[1]))) {
          return 'https://teammove.app/icones/maps-clusters/marker_green.png';
        } else {
          return 'https://teammove.app/icones/maps-clusters/marker_red.png';
        }
      })();

      return {
        position: { lat: parseFloat(latitude) || 0, lng: parseFloat(longitude) || 0 },
        icon,
        identifier: companyShortName,
        name: companyName,
        city: companyCity,
        state: companyState,
        key: userId
      };
    });
  }, [users]);

  const mapCenter = useMemo(() => {
    return mapMarkers && {
      lat: median(mapMarkers.map(({ position }) => parseFloat(position.lat))),
      lng: median(mapMarkers.map(({ position }) => parseFloat(position.lng)))
    };
  }, [mapMarkers]);

  return loading ? (
    <Skeleton.Map/>
  ) : (
   
    <Column>
      <ContentHeader 
        title='Mapa' 
        extra={(
          <ViewControl 
            options={[
              { icon: <Icon.MDI type='format-list-bulleted'/>, name: 'Cards de usuários' }, 
              { icon: <Icon.Feather type='map-pin'/>, name: 'Mapa' },
            ]} 
            current={viewBy} 
            onChange={handleViewByChange}
          />
        )}
      />
    
      <Container>
        <Map
          center={isNaN(mapCenter?.lat) || isNaN(mapCenter?.lng) ? { lat: 0, lng: 0 } : mapCenter} 
          zoom={5} 
          mapStyles={{ height: '600px', width: '100%', borderRadius: '12px', marginBottom: '12px' }} 
          markers={mapMarkers}
        />
      </Container>
    </Column>
  );
}