import React, { useEffect, useState } from 'react';
import { Table, Button, SearchBar, Column, Row } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getClusters } from '@ducks/configuracoesUnidadesClusters';
import { useHistory } from 'react-router-dom';
import ClustersCadastro from './Cadastro';

const Clusters = ({ visibleRegister, setVisibleRegister }) => {
  const [search, setSearch] = useState();
  const history = useHistory();

  const dispatch = useDispatch();
  const clusters = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('clusters'));
  const loading = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingClusters'));

  const successSave = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successDelete'));

  useEffect(() => {
    history.replace({
      search: new URLSearchParams().toString()
    });
    dispatch(getClusters());
  }, []);

  useEffect(() => {
    if (successDelete || successSave) dispatch(getClusters());
  }, [successDelete, successSave]);

  const handleButtonClick = (cluster) => {
    history.push('?cluster=' + cluster.id, { cluster: cluster });
    setVisibleRegister(true);
  };

  const columns = [
    {
      title: 'Nome do Cluster',
      dataIndex: 'name',
      sorter: true,
      render: (text, cluster) => (
        <Row justify="space-between" align='center'>
          {cluster.nome}
          <Button
            type='secondary'
            size='small'
            context='list'
            title='Editar cluster'
          >Editar
          </Button>
        </Row>
      )
    },
  ];

  const handleSearch = (text) => {
    setSearch(text);
  };

  const filterTypes = () => {
    if (!search) return clusters;
    return clusters.filter(({ name, qtDays }) => name.trim().toLowerCase().includes(search.trim().toLowerCase()) || String(qtDays).trim().toLowerCase().includes(search.trim().toLowerCase()));
  };

  return (
    <Column>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar' />
      <Table columns={columns} dataSource={filterTypes()} loading={loading || loadingDelete} rowKey='id' status='ativo' onRow={(cluster) => ({ onClick: () => handleButtonClick(cluster) })} />

      {visibleRegister && (
        <ClustersCadastro
          visible={visibleRegister}
          onCancel={() => setVisibleRegister(false)}
        />
      )}
    </Column>
  );
};

export default Clusters;