import styled from 'styled-components';
import Icon from '../Icon';

export const ParentCard = styled.div`
  padding: 8px;
  border-radius: 18px;
  background-color: ${({ theme, subParent }) => subParent ? theme.darkSecondaryColor : theme.darkPrimaryColor};
`;

export const ParentCardHeader = styled.div`
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const EmptyText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const ArrowIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const ParentCardSkeletonWrapper = styled(ParentCard)`
  padding: 0;
`;