import React from 'react';
import { InfoUsuario } from '@utils';
import { AvatarLoggedUser } from '@components';
import pkg from '@package';
import { GrayText, Row } from '../styles';
import { DownIcon, DropdownStyled, InfoItem, AvatarMenu, TextName, TextSmall, Divider, MenuItem, LinkText, MenuExit, LinkExit } from './styles';

const AvatarDropdown = ({ infoUsuario }) => {
  if (!InfoUsuario.hasUser(infoUsuario)) return null;

  const menu = (
    <AvatarMenu>
      <InfoItem disabled>
        <TextName name='nome'>{infoUsuario.nmUsuario}</TextName>
        <TextSmall name='small'>{infoUsuario.email}</TextSmall>
        <TextSmall name='small'>{pkg.version}</TextSmall>
      </InfoItem>
      <Divider/>
      <MenuItem>
        <LinkText to='/configuracoes'>Configurações</LinkText>
      </MenuItem>
      <MenuItem>
        <LinkText to='/integracoes'>Integrações</LinkText>
      </MenuItem>
      <MenuItem>
        <LinkText to='/perfil'>Perfil</LinkText>
      </MenuItem>
      <MenuItem>
        <LinkText to='/alterar-senha'>Alterar senha</LinkText>
      </MenuItem>
      <Divider/>
      <MenuExit>
        <LinkExit to='/logout'>Sair</LinkExit>
      </MenuExit>
    </AvatarMenu>
  );

  return (
    <DropdownStyled overlay={menu} trigger={['click']}>
      <Row gap='12px'>
        <Row gap='12px'>
          <GrayText>{infoUsuario.nmUsuario}</GrayText>
          <AvatarLoggedUser />
        </Row>
        <DownIcon />
      </Row>
    </DropdownStyled>
  );
};

export default AvatarDropdown;
