import styled from 'styled-components';
import { Row, Icon } from '@components-teammove';

export const ContainerPieChart = styled.div`
    border-radius: 50%;
    padding: 10px;
    border: 2px solid ${({ theme }) => theme.textColor}; 
    height: 16px;
    width: 16px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;    
`;

export const RowContainer = styled(Row)`
    margin: 0 40px;
    cursor: pointer;
`;

export const IconTrophy = styled(Icon.MDI)`
    color: ${({ theme }) => theme.textColor};
`;