import styled from 'styled-components';
import { Column, Row } from '@components-teammove';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 20px;
  border-radius: 12px;
  width: 100%;
`;

export const MonthColumn = styled(Column)`
  :not(:last-child) {
    border-right: 2px solid ${({ theme }) => theme.darkHighlightColor};
    padding-right: 10px;
  }
  
  :not(:first-child) {
    padding-left: 10px;
  }
`;

export const YearRow = styled(Row)`
  overflow-x: scroll;
  max-width: calc(calc(calc(20px * 7) + 10px) * 11);
  padding-bottom: 10px;
`;

export const MonthName = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  text-transform: capitalize;
`;

export const DayCell = styled.div`
  background-color: ${({ theme, color, outOfMonth }) => outOfMonth ? 'transparent' : color || theme.darkHighlightColor};
  border: 1px solid ${({ theme, outOfMonth }) => outOfMonth ? theme.darkHighlightColor : 'transparent'};
  border-radius: 2px;
  width: 20px;
  height: 20px;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const WeekDayLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
  text-align: center;
`;