import React, { useState } from 'react';
import { Column, Row, Button, Tooltip } from '@components-teammove';
import { CheckIcon, FeatherIcon, TaskCard, TaskLabel, TaskText, TypeIcon, TypeIconWrapper, DistanceToCompany, RowStyle } from './styles';
import { CardSubTitle } from '../../styles';
import { moment, formatTime } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { postConferirAtividade } from '@ducks/gestaoEquipes';
import ChecklistAnswers from './ChecklistAnswers';
import { getChecklistUploads, getFinishedChecklist } from '@ducks/checklists';
import ChecklistUploads from './ChecklistUploads';

export default function Task({ task, ...props }) {
  const dispatch = useDispatch();

  const loadingCheck = useSelector(({ gestaoEquipes }) => gestaoEquipes.get('loadingConfere'));

  const [visibleChecklist, setVisibleChecklist] = useState(false);
  const [visibleUploads, setVisibleUploads] = useState(false);

  const replaceData = moment(task?.checkedAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');

  const handleActivityCheck = (checked) => {
    dispatch(postConferirAtividade(task.id, checked));
  };
  
  const handleChecklistIconClick = (id) => { 
    dispatch(getFinishedChecklist(id));

    setVisibleChecklist(true);
  };
  
  const handleImagesIconClick = () => {
    dispatch(getChecklistUploads(task.checklistId));
    setVisibleUploads(true);
  };

  const getCheckedInfo = (name, date) => {
    return task?.checkedBy ? `Atividade conferida por ${name} em ${date}` : '' ;
  };
  
  return (
    <TaskCard key={task.id} typeColor={task.typeColor} {...props}>
      <Column justify='space-between'>
        <Row justify='space-between'>
          <Row gap='8px'>
            <TypeIconWrapper typeColor={task.typeColor}>
              <TypeIcon type={task.typeIcon}/>
            </TypeIconWrapper>
            <Column gap='8px'>
              <Row gap='20px'>
                <TaskText>{task.name}</TaskText>

                <Row gap='14px'>
                  {(!!(task.cancellationChecklistId || task.checklistId) && (
                    <>
                      <Button type='icon' transparent size='small' icon={(<FeatherIcon type='clipboard'/>)} onClick={() =>handleChecklistIconClick(task.active ? task.checklistId : task.cancellationChecklistId)}/>
                      {task.active && task.uploads.length > 0 && (
                        <Button type='icon' transparent size='small' icon={(<FeatherIcon type='camera'/>)} onClick={handleImagesIconClick}/>
                      )}
                    </>
                  ))}

                </Row>

              </Row>
              <Column gap='4px'>
                <CardSubTitle {...props}>{task.companyShortName} - {task.companyName}</CardSubTitle>
                <Row gap='18px'>
                  <TaskText>{task.companyAddress}</TaskText> 
                  <TaskText>{task.companyCity} - {task.companyState}</TaskText>
                </Row>
              </Column>
            </Column>
          </Row>

          {task.status === 'CONCLUIDA' && task.active && (
            <Tooltip title= {getCheckedInfo(task?.checkedBy?.name, replaceData)}>
              <Button 
                type={task.checked ? 'success' : 'tertiary'} 
                icon={task.checked && <FeatherIcon size='1rem' type='check'/>} 
                size='smaller' 
                context='list'
                loading={loadingCheck}
                onClick={() => handleActivityCheck(!task.checked)}
             
              >
                {task.checked ? 'Conferido' : 'Conferir'}
              
              </Button>
            </Tooltip>
          )}
        </Row>

        {!props.isCanceled && (
          <RowStyle justify='space-between'>
            <Row gap='25px'>
              <Row gap='12px' align='center'>
                {task.checkin && (
                  <>
                    {task.checkinDistanceToCompany && task.checkinDistanceToCompany >= 1 ? (
                      <CheckIcon color={'var(--danger-color)'} type='arrow-right'/>
                    ) : (
                      <CheckIcon color={'var(--success-color)'} type='arrow-right'/>
                    )}
                  
                    <Column>
                      <TaskLabel>Check-in</TaskLabel>
                      <TaskText>{formatTime(task.checkin)}</TaskText>
                      {task.checkinDistanceToCompany >= 1 && (
                        <DistanceToCompany>{task.checkinDistanceToCompany} Km</DistanceToCompany>
                      )}
                    </Column>
                  </>
                )}
              </Row>
              <Row gap='12px' align='center'>
                {task.checkout && (
                  <>
                    {task.checkoutDistanceToCompany && task.checkinDistanceToCompany >= 1 ? (
                      <CheckIcon color={'var(--danger-color)'}  type='arrow-left'/>
                    ) : (
                      <CheckIcon color={'var(--success-color)'}  type='arrow-left'/>
                    )}
                    <Column>
                      <TaskLabel>Check-out</TaskLabel>
                      <TaskText>{formatTime(task.checkout)}</TaskText>
                      {task.checkoutDistanceToCompany >= 1 && (
                        <DistanceToCompany>{task.checkoutDistanceToCompany} Km</DistanceToCompany>
                      )}
                    </Column>
                  </>
                )}
              </Row>
            </Row>

            <Row gap='10px' align='center'>
              {task.checkin && task.checkout && (
                <>
                  <FeatherIcon type='clock'/>
                  <Column>
                    <TaskLabel>Tempo</TaskLabel>
                    <TaskText>{new Date(moment(task.checkout).diff(moment(task.checkin))).toISOString().slice(11,19)}</TaskText>
                  </Column>
                </>
              )}
            </Row>
          </RowStyle>
        )}
        
      </Column>

      {visibleChecklist && (
        <ChecklistAnswers
          visible={visibleChecklist}
          onCancel={() => setVisibleChecklist(false)}
          task={task}
          handleActivityCheck={handleActivityCheck}
        />
      )}

      {visibleUploads && (
        <ChecklistUploads
          visible={visibleUploads}
          onCancel={() => setVisibleUploads(false)}
        />
      )}
    </TaskCard>
  );
}