import React, { useMemo } from 'react';
import { Container, DayCell, MonthColumn, MonthName, WeekDayLabel, YearRow } from './styles';
import { Grid, Tooltip, View } from '@components-teammove';
import { moment, formatDate } from '@utils';
import { addColorsToData, getLastSundayFromMoment, getNextSaturdayFromMoment } from './rules';

const CalendarChart = ({ data, colors = [], onDateClick, year }) => {
  let startYear = 0;
  let endYear = 0;
  if(year) {
    startYear = moment('01/01/' + year).startOf('year');
    endYear = moment('01/01/' + year).endOf('year');
  } else {
    startYear = moment().startOf('year');
    endYear = moment().endOf('year');
  }
  
  const allMonths = useMemo(() => {
    const months = [];
    for (let month = moment(startYear); month.isSameOrBefore(endYear); month = moment(month).add(1, 'months')) {
      months.push(month);
    }
    return months;
  }, [startYear, endYear]);

  const allDays = useMemo(() => {
    const days = [];
    for (let day = getLastSundayFromMoment(moment(startYear)); day.isSameOrBefore(getNextSaturdayFromMoment(moment(endYear))); day = moment(day).add(1, 'days')) {
      days.push(day);
    }
    return days;
  }, [startYear, endYear]);

  const formattedDataWithColors = useMemo(() => {
    const formattedData = data?.map(({ date, value }) => ({ date: moment(date), value }));
    return addColorsToData(formattedData, colors);
  }, [data, colors]);

  return (
    <Container>
      <YearRow gap='2px'>
        {allMonths?.map((month) => (
          <MonthColumn gap='8px' key={month.month()}>
            <MonthName>{month.format('MMMM')}</MonthName>
            <Grid numberOfColumns={7} gap='4px'>
              <WeekDayLabel>D</WeekDayLabel>
              <WeekDayLabel>S</WeekDayLabel>
              <WeekDayLabel>T</WeekDayLabel>
              <WeekDayLabel>Q</WeekDayLabel>
              <WeekDayLabel>Q</WeekDayLabel>
              <WeekDayLabel>S</WeekDayLabel>
              <WeekDayLabel>S</WeekDayLabel>
              {allDays?.filter((day) => day.isBetween(getLastSundayFromMoment(moment(month).startOf('month')), getNextSaturdayFromMoment(moment(month).endOf('month')), undefined, '[]')).map((day) => {
                const formattedDataForDay = formattedDataWithColors?.find(({ date }) => date.isSame(day, 'day'));
                return (
                  <View key={formatDate(day)}>
                    <Tooltip title={`${formatDate(day)}: ${formattedDataForDay?.value}`}>
                      <DayCell 
                        dayWithData={formattedDataForDay} 
                        outOfMonth={!day.isBetween(moment(month).startOf('month'), moment(month).endOf('month'), undefined, '[]')}  
                        color={formattedDataForDay?.color}
                        onClick={() => onDateClick?.(day.toDate())}
                        clickable={Boolean(onDateClick)}
                      />
                    </Tooltip>
                  </View>
                );
              })}
            </Grid>
          </MonthColumn>
        ))}
      </YearRow>
    </Container>
  );
};

export default CalendarChart;