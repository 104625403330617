import React, { useState } from 'react';
import { Modal, SearchBar, FixedCascader, Row, Form } from '@components-teammove';
import { useSelector } from 'react-redux';
import { CheckIcon, ProductImage, ProductImageWrapper, ProductItem, ProductName } from './styles';
import { formatCategoriesWithProducts, getFilteredCategoriesWithProducts, getProductsFromChange } from './rules';

export default function ProductSelection({ visible, onCancel, selectedProducts : initialSelectedProducts, onOk }) {
  const categoriesWithProducts = useSelector(({ produtos }) => produtos.get('categoriesWithProducts'));

  const [selectedProducts, setSelectedProducts] = useState(initialSelectedProducts || []);
  const [search, setSearch] = useState('');

  const handleOk = () => {
    onOk(selectedProducts);
    onCancel();
  };

  const filterActiveData = (dataArray) => {
    return dataArray
      .filter((dataItem) => dataItem.active)
      .map((dataItem) => ({
        ...dataItem,
        families: dataItem.families
          .filter((family) => family.active)
          .map((family) => ({
            ...family,
            products: family.products.filter((product) => product.active)
          }))
      }));
  };
  
  const renderCascaderItem = (item) => (
    <ProductItem hasImage={!!item.picture} selected={selectedProducts.find(({ id }) => id === item.id)}>
      <Row gap='16px' align='center'>
        {item.picture && (
          <ProductImageWrapper>
            <ProductImage src={item.picture}/>
          </ProductImageWrapper>
        )}
        <ProductName>{item.name}</ProductName>
      </Row>
      {selectedProducts.find(({ id }) => id === item.id) && (
        <CheckIcon type='check' />
      )}
    </ProductItem>
  );

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      title='Produtos'
      onOk={handleOk}
      okText='Selecionar produtos'
    >
      <SearchBar 
        placeholder='Buscar produto'
        onSearch={(term) => setSearch(term)}
      />
      <Form.Item>
        <FixedCascader
          allOpen={!!search.trim()}
          value={selectedProducts.map(({ id }) => id)}
          options={formatCategoriesWithProducts(search ? getFilteredCategoriesWithProducts(search, filterActiveData(categoriesWithProducts)) : filterActiveData(categoriesWithProducts))}
          onChange={(value) => setSelectedProducts(getProductsFromChange(value, filterActiveData(categoriesWithProducts)))}
          renderItem={renderCascaderItem}
        />
      </Form.Item>
    </Modal>
  );
}