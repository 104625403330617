import { Modal, SearchBar, Column, Row, Text, Skeleton } from '@components-teammove';
import React, { useEffect, useState } from 'react';
import { FeatherIcon } from '../styles';
import { useSelector } from 'react-redux';

export default function SelectCategoryModal({ 
  form,
  visible,
  onCancel, 
  loading,  
  unidade, 
  setCategoria, 
  setIsVisibleRegisterModal, 
  isVisibleSelectCategoryModal, 
  setIsVisibleSelectCategoryModal,  
  isChangeCategoryAgain, 
  handleSetValueForm,
  setCategoryHasBriefing, 
  handleChangeUsuarios 
}) {
    
  const categoriesForOptions = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('categoriesForOptions'));
  const successCategorias = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('successCategorias'));
  const loadingCategorias = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('loadingCategorias'));

  const [ selectableDepartment, setSelectableDepartment ] = useState([]);
  const [ categoriesFiltered, setCategoriesFiltered] = useState();

  useEffect(() => {
    if(successCategorias) {
      setCategoriesFiltered(categoriesForOptions?.filter((item)=> !item.idCategoria && item.ativo));
    }
  }, [successCategorias, isVisibleSelectCategoryModal]);

  const onBeforeCancel = () => {
    return form.isFieldsTouched() || Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
  };
  const handleChangeCategoria = (value) => {

    const findPath = (group) => {
      const newGroupForAddAtPath = categoriesForOptions.find((item)=> item.id === group.idCategoria);
      if(newGroupForAddAtPath.idCategoria) {
        findPath(newGroupForAddAtPath);
      }
      selectableDepartment.unshift(newGroupForAddAtPath);
      setSelectableDepartment(selectableDepartment);
    };
    
    if(value.idCategoria && selectableDepartment.length <= 0) {
      findPath(value);
    }

    selectableDepartment.push(value);
    setSelectableDepartment(selectableDepartment);
    
    if(value.tipo === 'ITEM') {
      if(setIsVisibleSelectCategoryModal) setIsVisibleSelectCategoryModal(false);
      if(setIsVisibleRegisterModal) setIsVisibleRegisterModal(true);

      if(handleSetValueForm) {
        handleSetValueForm('categoria', selectableDepartment.map((item) => item.id));
        handleSetValueForm(
          'categoriaPath',
          selectableDepartment.map((item, index) => 
            index !== selectableDepartment.length - 1 ? item.titulo + ' / ' : item.titulo
          ).join('')
        );
      }
      
      handleSelectCategory(value);
      setSelectableDepartment([]);
    }
  
    setCategoriesFiltered(categoriesForOptions.filter((item)=> item.ativo && item.idCategoria === value.id));
  };

  const handleChangeList = (term) => {
    if(term === '' || term === ' ') {
      setCategoriesFiltered(categoriesForOptions?.filter((item)=> !item.idCategoria && item.ativo));
    }
    else{
      setCategoriesFiltered(categoriesForOptions.filter((item) => item.ativo && item.titulo.toLowerCase().includes(term.toLowerCase())));
    }
    
    setSelectableDepartment([]);
  };

  const handleCategoryModalOk = () => {
    if(selectableDepartment.length > 1) {
      setCategoriesFiltered(
        categoriesForOptions
          .filter((item)=> item.ativo 
        && item.idCategoria === selectableDepartment[selectableDepartment.length - 2].id 
          ));
      selectableDepartment.pop();
      setSelectableDepartment(selectableDepartment);
    }else if(selectableDepartment.length === 1) {
      setCategoriesFiltered(
        categoriesForOptions?.filter((item)=> !item.idCategoria && item.ativo)
      );
      setSelectableDepartment([]);
    }
    else if(isChangeCategoryAgain) {
      if(setIsVisibleSelectCategoryModal) setIsVisibleSelectCategoryModal(false);
      
      if(setIsVisibleRegisterModal) setIsVisibleRegisterModal(true);
    }else{
      onCancel();
    }
  };

  const handleSelectCategory = (value) => {
    if(setCategoria) setCategoria(value); 

    if(setCategoryHasBriefing) {
      if (value?.contemBriefing) {
        setCategoryHasBriefing(value?.contemBriefing);
      } else {
        setCategoryHasBriefing(false);
      }
    }
    if(handleChangeUsuarios) handleChangeUsuarios(value, unidade > 0 ? unidade : form.getFieldValue('unidade') || -1);
    if(handleSetValueForm) handleSetValueForm('idUsuarioExecutar', value.idUsuarioExecutar);
    
  };

  return (
    <Modal
      numberOfButtons={1}
      visible={visible}
      title='Selecione um Departamento/Categoria'
      okText={`${selectableDepartment.length > 0 ? 'Voltar' : 'Cancelar'}`}
      onCancel={onCancel}
      onOk={handleCategoryModalOk}
      okButtonProps={{ loading, type: 'secondary' }}
      onBeforeCancel={onBeforeCancel}
    >
      <SearchBar
        placeholder="Buscar Tickets..."
        onSearch={(term)=>handleChangeList(term)}
      />
      <Column gap='13px'>
        {!selectableDepartment?.length > 0 ? 
          <Text size='12px' weight='600'>Selecione o departamento</Text> 
          : <Column gap='8px'>
            <Text size='12px' weight='600'>Você está em:</Text>
            <Text lowOpacity size='16px' weight='400'>{selectableDepartment?.map(({ titulo })=> titulo).join(' / ')}</Text>
          </Column>
        }
           
        <Column margin='0 0 12px 0'>
          { loadingCategorias ?
            <Column>
              <Row gap='4px' align='center'>
                <Skeleton.Circle/><Skeleton.MinorLine /> 
              </Row>
              <Row gap='4px' align='center'>
                <Skeleton.Circle/><Skeleton.MinorLine /> 
              </Row>
              <Row gap='4px' align='center'>
                <Skeleton.Circle/><Skeleton.MinorLine /> 
              </Row>
            </Column>
            : successCategorias && categoriesFiltered?.length > 0 ? categoriesFiltered?.map((item)=> (
              <>
                <Row
                  align='center' gap='12px' onClick={() => {
                    handleChangeCategoria(item);
                  }}
                >
                  <FeatherIcon type={item.tipo === 'GRUPO' ? 'folder' : 'arrow-right' }/>
                  <Text size='18px' weight='400' key={item.id} cursor='pointer'>{item.titulo}</Text>
                </Row>
              </>
            )) : (
              <Row>
                <Text size='16px' weight='400' lowOpacity>Nenhum departamento encontrado</Text>
              </Row>
            )}
        </Column>
      </Column>
    </Modal> 
  );

}