import { get, post, put } from '../api';

export const getCamposDinamicos = () => {
  return get('/api/companies/dynamic/fields');
};

export const postCampoDinamico = (campoDinamico) => {
  return post('/api/companies/dynamic/fields', campoDinamico);
};

export const updateCampoDinamico = (campoDinamico) => {
  return put(`/api/companies/dynamic/fields/${campoDinamico.id}`, campoDinamico);
};

export const updateCamposDinamicos = (camposDinamicos) => {
  return put('/api/companies/dynamic/fields/list', camposDinamicos);
};