import { get, post, put } from '../api';
import { InfoUsuario } from '@utils';

export const getReport = (startDate, endDate) => {
  return get(`/api/report?idUser=${InfoUsuario.get('cdUsuario')}&start=${startDate}&end=${endDate}`);
};

export const getReportProfiles = () => {
  return get('/api/report/profiles');
};

export const postReportProfiles = (profiles) => {
  return post('/api/report/profiles', profiles);
};

export const putReportProfiles = (configProfiles) => {
  return put('/api/report/profiles', configProfiles);
};