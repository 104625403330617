import View from '../View';
import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker } from 'antd';

export const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const Separator = styled.span`
  opacity: .5;
  margin: 0 12px;
  font-size: 16px;
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  min-width: 200px !important;
  max-width: 100%;
  &.ant-picker {
    width: 100%;
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    border-color: ${(props) => props.theme.darkHighlightColor} !important;
    border-width: 2px;
    color: ${(props) => props.theme.textColor} !important;
    padding: 14px;

    .ant-picker-separator {
      color: ${(props) => props.theme.textColor};
      ${({ value }) => !value && `
        opacity: .5;
      `}
    }

    .ant-picker-input {
      > input {
        color: ${(props) => props.theme.textColor};
        font-size: 16px;
        text-align: center;

        ::placeholder {
          color: ${(props) => props.theme.textColor};
          opacity: .5;
        }
      }
    }

  .ant-picker-suffix {
    color: ${({ theme }) => theme.textColor};
    ${({ value }) => !value && `
      opacity: .5;
    `}
  }

    &.ant-picker-range .ant-picker-active-bar {
      background: ${(props) => props.theme.actionColor};
    }

    :focus-within, .ant-picker-focused {
      border: 2px solid ${(props) => props.theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }
  }
`;

export const DropdownContainerStyles = createGlobalStyle`
  .ant-picker-dropdown {
    background-color: transparent;

    .ant-picker-panel-container {
      background-color: ${(props) => props.theme.darkPrimaryColor};
      color: ${(props) => props.theme.textColor};

      .ant-picker-header {
        color: ${(props) => props.theme.textColor};

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: ${(props) => props.theme.textColor};
        }
      }

      .ant-picker-content th {
        color: ${(props) => props.theme.textColor};
      }

      .ant-picker-time-panel {
        .ant-picker-time-panel-column {
          > .ant-picker-time-panel-cell {
            > .ant-picker-time-panel-cell-inner {
              color: ${(props) => props.theme.textColor};

              :hover {
                background-color: ${(props) => props.theme.actionColor};
                color: ${(props) => props.theme.textContrastColor};
              }
            }

            &.ant-picker-time-panel-cell-selected {
              > .ant-picker-time-panel-cell-inner {
                background-color: ${(props) => props.theme.actionColor};
                color: ${(props) => props.theme.textContrastColor};
              }
            }
          }
        }
      }

      .ant-picker-cell {
        color: ${(props) => props.theme.textColor};

        &.ant-picker-cell-disabled {
          background-color: ${(props) => props.theme.darkPrimaryColor};
          opacity: .4;

          :before {
            background-color: ${(props) => props.theme.darkPrimaryColor};
          }

          .ant-picker-cell-inner {
            color: ${(props) => props.theme.textColor};
          }
        }

        :hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
        :hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
          background-color: ${(props) => props.theme.actionColor};
        }

        &.ant-picker-cell-in-view {
          &.ant-picker-cell-in-range,
          &.ant-picker-cell-range-start,
          &.ant-picker-cell-range-end {

            &.ant-picker-cell-range-start-single {
              :before {
                background-color: transparent;
              }
            }

            &.ant-picker-cell-range-hover-start,
            &.ant-picker-cell-range-hover-end {
              .ant-picker-cell-inner {
                :not(.ant-picker-cell-range-end) {
                  ::after {
                    background-color: ${(props) => props.theme.actionColor} !important;
                  }
                }
              }
            }
            :before {
              background-color: ${(props) => props.theme.darkPrimaryColor};
            }
          }
        }

        :not(.ant-picker-cell-in-view):not(.ant-picker-cell-disabled) {
          opacity: .4;
        }
      }

      .ant-picker-footer .ant-picker-ranges {
        .ant-picker-preset .ant-tag {
          color: ${(props) => props.theme.textColor};
          background: transparent;
          border-color: ${(props) => props.theme.textColor};

          :hover {
            background-color: ${(props) => props.theme.actionColor};
            border: none;
          }
        }
      }

      .ant-picker-ok {
        .ant-btn-primary-disabled, .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:hover, .ant-btn-primary.disabled:hover, .ant-btn-primary[disabled]:hover, .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active {
          background-color: ${({ theme }) => theme.actionColor};
          color: ${({ theme }) => theme.textContrastColor};
          border: none;
          opacity: .5;
        }
      }
    }

    .ant-picker-cell-in-view {
      .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: ${(props) => props.theme.actionColor} !important;
      }

      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${(props) => props.theme.actionColor};
      }

      &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: ${(props) => props.theme.actionColor};
        }
      }

      &.ant-picker-range-hover, &.ant-picker-range-hover-end {
        border-color: ${(props) => props.theme.actionColor};

        ::after {
          background-color: ${(props) => props.theme.actionColor};
          border-color: ${(props) => props.theme.actionColor};
        }
      }
    }
  }
`;