import styled from 'styled-components';
import { Form } from 'antd';
import Icon from '../Icon';

export const DarkFormItem = styled(Form.Item)`
  --margin-label: 8px;

  flex: 1;
  column-gap: 11px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: ${({ marginless }) => marginless ? '0' : '20px'};
  position: relative;

  &.ant-row {
    .ant-form-item-label, .ant-form-item-explain {
      ${({ valuePropName }) => valuePropName !== 'checked' && `
        margin-left: var(--margin-label);
      `}
    }
  }

  .ant-input {
    padding: 0;
  }
  
  .ant-form-item-label {
    --line--height: 18px;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0;
    ${({ absoluteLabel }) => absoluteLabel && `
      position: absolute;
      top: calc(calc(var(--line--height) + 8px) * -1);
    `}
    ${({ valuePropName, absoluteLabel }) => valuePropName !== 'checked' && !absoluteLabel && `
      margin-bottom: 8px !important;
    `}
    line-height: var(--line--height);

    > label {
      height: fit-content;
      font-size: 12px !important;
      color: ${(props) => props.theme.textColor};
    }

    > label.ant-form-item-required {
      ::before {
        display: none;
      }

      ::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f !important;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }

    ${({ labelSide }) => labelSide === 'right' && `
      order: 2;
    `}
  }

  &.ant-form-item-has-error .ant-input-affix-wrapper, 
  &.ant-form-item-has-error .ant-select, 
  &.ant-form-item-has-error .ant-cascader-picker, 
  &.ant-form-item-has-error textarea.ant-input {
    border: 2px solid var(--danger-color) !important;
    border-right-width: 2px !important;
    background-color: ${(props) => props.theme.darkPrimaryColor};

    :focus {
      border-right-width: 2px !important;
    }

    .ant-cascader-picker, .ant-cascader-picker:focus-within {
      border: none !important;
    }
  }

  .ant-form-item-explain {
    display: flex;
    align-items: center;
    min-height: none;

    > * {
      color: var(--danger-color) !important;
    }
  }

  .ant-form-item-extra {
    color: ${({ theme }) => theme.textColor};
    margin-left: var(--margin-label);
    margin-top: var(--margin-label);
  }
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${(props) => props.theme.textColor};
`;