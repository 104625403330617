import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import App from '@app';
import { Button, Body, PageHeader } from '@components';
import { EmailEditor, Icon } from '@components-teammove';
import { getTemplate, saveTemplate } from '@ducks/wikisEditor';

const Content = styled(Body)`
  display: flex;
`;

export default function WikisEditor() {
  const { idCategoria } = useParams();
  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);
  const editorRef = useRef(null);

  const template = useSelector(({ wikisEditor }) => wikisEditor.get('template'));
  const loadingSave = useSelector(({ wikisEditor }) => wikisEditor.get('loadingSave'));

  useEffect(() => {
    dispatch(getTemplate(idCategoria));
  }, []);

  useEffect(() => {
    if ((template) && (ready)) {
      editorRef.current.loadDesign(template);
    }
  }, [template, ready]);

  const handleSave = () => {
    editorRef.current.exportHtml(({ design, html }) => {
      dispatch(saveTemplate(idCategoria, design, html));
    });
  };

  return (
    <App siderProps={{ width: 0 }}>
      <Content>
        <PageHeader
          title="Wiki / Editor"
          extra={[
            <Button key="1" type="success" icon={<Icon.SaveOutlined />} onClick={handleSave} loading={loadingSave}>Salvar</Button>,
          ]}
        >
          <EmailEditor
            ref={editorRef}
            projectId={3611}
            minHeight="100%"
            locale="pt-BR"
            onLoad={() => setReady(true)}
          />
        </PageHeader>
      </Content>
    </App>
  );
}
