import React from 'react';
import { Row } from '@components-teammove';
import { ContentTitle, Dot, TextStyle } from './styles';

export const TooltipTitle = ({ data }) => {

  return (
    <>
      <ContentTitle gap='7px'>
    
        <Row gap='4px' align='center'>
          <Dot status={data?.status.status} />
          <TextStyle weight='700'>
            {data.status.status === 'OK' ? 'Ok' : data.status.status === 'ATENCAO' ? 'Alerta' : data.status.status === 'CRITICO' && 'Crítico'}
          </TextStyle>
        </Row>
        <TextStyle>
            Última atividade realizada há {data?.status.daysSinceLastActivity} {data?.status.daysSinceLastActivity > 1 ? 'dias, ' : 'dia, '}
          {data.status.status === 'OK' ? 'atendendo à frequência desejada.' : data.status.status === 'ATENCAO' ? `próxima à frequência ideal de ${data?.frequencyQtdDays} ${data?.frequencyQtdDays > 1 ? 'dias.' : 'dia.'}` : data.status.status === 'CRITICO' && `a frequência ideal é de ${data?.frequencyQtdDays} ${data?.frequencyQtdDays > 1 ? 'dias.' : 'dia.'}`}
        </TextStyle>
      </ContentTitle>
    </>
  );
};

export default TooltipTitle;