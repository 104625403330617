import { TelasNomenclaturas } from '@utils';
import { getUnidades } from '@sdk/Unidades';
import { getProducts } from '@ducks/produtos';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const initialModalFilters = [
  {
    type: 'SELECT',
    name: 'idCompanies',
    label: companyNomenclature.plural,
    placeholder: `Selecionar ${companyNomenclature.artigo}s ${companyNomenclature.plural?.toLowerCase()} `,
    optionLabelProp: 'title',
    action: getUnidades,
  },
  {
    type: 'SELECT',
    label: 'Agrupador de unidade',
    name: 'agrupadorUnidade',
    placeholder: 'Selecionar agrupador',
    options: []
  }, 
  {
    type: 'SELECT',
    mode: 'multiple',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'tipoUnidade',
    placeholder: `Selecione o tipo de ${companyNomenclature.nomenclatura}`,
    options: []
  },
  {
    type: 'SELECT',
    name: 'categoriaProduto',
    label: 'Categoria de produto',
    placeholder: 'Selecionar categoria de produto',
    options: [],
    isToChangeOtherTypeOptions: true,
  },
  {
    type: 'SELECT',
    name: 'familia',
    label: 'Família',
    placeholder: 'Selecionar família de um produto',
    options: [],
    request: getProducts,
    toRequest: 'categoriaProduto',
    isToChangeOtherTypeOptions: true,
  },
  {
    type: 'SELECT',
    name: 'produto',
    label: 'Produto',
    placeholder: 'Selecionar produto',
    options: [],
    request: getProducts,
    toRequest: 'familia',
    isToChangeOtherTypeOptions: true,
  }, 
];

export const dynamicFilters = (typeField, options, filtrosState, updateFormFields, defaultCategoriesProducts, defaultFamilies, defaultCategoriesProductsFormated, defaultFamiliesFormated, defaultProductsFormated, setCategoriaProduto, setProdutos, setFamilias, filtersModal) => {
  if (typeField === 'categoriaProduto') {
    if(!options || options?.length === 0) {
      if(filtrosState?.familia?.length <= 0 || !filtrosState?.familia) setProdutos(defaultProductsFormated);
      if(filtrosState?.produto?.length <= 0 || !filtrosState?.produto) setFamilias(defaultFamiliesFormated); 
      if((filtrosState?.familia?.length <= 0 || !filtrosState?.familia) && (filtrosState?.produto?.length <= 0 || !filtrosState?.produto)) setCategoriaProduto(defaultCategoriesProductsFormated);
      return;
    }
    const filteredCategories = defaultCategoriesProducts.filter((defaultCategorieProduct) => 
      options.some((option) => option.value === defaultCategorieProduct.id)
    );

    const listFilteredFamilies = filteredCategories.flatMap((res) => res.families || []).map((res) => ({ label: res.name, value: res.id }));
    const listFilteredProducts = filteredCategories.flatMap((res) => res.families || []).map((res) => res.products || []).flat().map((res) => ({ label: res.name, value: res.id }));

    const filterForFunctionFamilie = filtersModal.find((filterModal) => filterModal.name === 'familia');
    const filterForFunctionProduct = filtersModal.find((filterModal) => filterModal.name === 'produto');

    if(filtrosState?.familia && filtrosState?.familia?.length > 0 && filtrosState?.categoriaProduto && filtrosState?.categoriaProduto?.length > 0) {
      let familyValues = [];

      filteredCategories.forEach((category) => category.families.forEach((family) => 
      filtrosState?.familia.forEach((familia) => {
        if(familia.value === family.id) {
          familyValues.push(familia.value);
        }
      })
      ));
      updateFormFields(filterForFunctionFamilie, familyValues);    
    }

    if(filtrosState?.produto && filtrosState?.produto?.length > 0) {
      const filteredProductsIds = filteredCategories.flatMap((category) =>
        category.families.flatMap((family) =>
          family.products
            .filter((product) =>
              filtrosState?.produto.some((produto) => (produto.value ? produto.value : produto) === product.id)
            )
            .map((product) => product.id)
        )
      );

      updateFormFields(filterForFunctionProduct, filteredProductsIds);    
    }
    
    setFamilias(listFilteredFamilies);
    setProdutos(listFilteredProducts);      
  }

  if (typeField === 'familia') {
    if(!options || options?.length === 0) {
      if(filtrosState?.categoriaProduto?.length <= 0 || !filtrosState?.categoriaProduto) {
        setProdutos(defaultProductsFormated);
      } else {       
        const filterCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) =>
          filtrosState?.categoriaProduto.some((option) => 
            (option.value ? option.value : option) === defaultCategoryProduct.id
          )
        );

        const familias = filterCategories.flatMap((category) =>
          category.families.map((family) => ({ label: family.name, value: family.id }))
        );

        setFamilias(familias);      
      }
      if(filtrosState?.produto?.length <= 0 || !filtrosState?.produto) {
        setCategoriaProduto(defaultCategoriesProductsFormated);
      } else {
        const produtoIds = filtrosState?.produto.map((option) => option.value ? option.value : option);
        const filterFamilies = defaultFamilies.filter((defaultFamily) =>
          defaultFamily.products.some((defaultProduct) =>
            produtoIds.includes(defaultProduct.id)
          )
        );
        const familias = filterFamilies.map((family) => ({
          label: family.name,
          value: family.id
        }));
      
        setFamilias(familias);
      }
      return;
    }  

    const filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) =>
      defaultCategoryProduct.families.some((defaultFamily) =>
        options.some((option) => option.value === defaultFamily.id)
      )
    );

    const filteredFamilies = defaultFamilies.filter((defaultFamilie) =>
      options.some((option) => option.value === defaultFamilie.id)
    );
  
    const listFilteredCategories = filteredCategories.map((res) => ({ label: res.name, value: res.id }));
    const listFilteredProducts = filteredFamilies.flatMap((res) => res.products || []).map((res) => ({ label: res.name, value: res.id }));

    if(filtrosState?.categoriaProduto && filtrosState?.categoriaProduto?.length > 0) {
      const filteredCategoriesInFilter = defaultCategoriesProducts.filter((defaultCategorieProduct) => 
        filtrosState?.categoriaProduto.some((option) => (option.value ? option.value : option) === defaultCategorieProduct.id)
      );

      filteredCategoriesInFilter.forEach((category) => {
        const existingId = listFilteredCategories.findIndex((item) => item.value === category.id);
        if (existingId === -1) {
          listFilteredCategories.push({ label: category.name, value: category.id });
        } 
      });
    }
    if(filtrosState?.produto && filtrosState?.produto?.length > 0 && filtrosState?.familia && filtrosState?.familia?.length > 0) {
      let productValues = [];
      const filteredFamiliesState = defaultFamilies.filter((defaultFamily) => 
        filtrosState?.familia.some((option) => (option.value ? option.value : option) === defaultFamily.id)
      );
      setFamilias(filteredFamiliesState.map((res) => ({ label: res.name, value: res.id })));
      filteredFamiliesState.forEach((family) => {
        const produtoIds = filtrosState?.produto.map((produto) => (produto.value ? produto.value : produto));
        const filteredProducts = family.products.filter((product) => produtoIds.includes(product.id));
        const filteredProductIds = filteredProducts.map((product) => product.id);

        productValues.push(...filteredProductIds);
        
      });
      if(filtrosState?.categoriaProduto && filtrosState?.categoriaProduto?.length > 0 && productValues.length === 0) {
        const categoriesSelected = defaultCategoriesProducts.filter((category) => 
          filtrosState?.categoriaProduto.some((option) => (option.value ? option.value : option) === category.id)
        );

        const familiesInSelect = categoriesSelected.flatMap((categorySelected) => 
          categorySelected.families.map((family) =>           
            ({ label: family.name, value: family.id })
          )
        );
        setFamilias(familiesInSelect);
      }   

      const filterForFunction = filtersModal.find((filterModal) => filterModal.name === 'produto');

      updateFormFields(filterForFunction,  productValues);
    }

    setCategoriaProduto(listFilteredCategories);
    setProdutos(listFilteredProducts);
  }

  if(typeField === 'produto') {
    if(!options || options?.length === 0) {
      if(filtrosState?.categoriaProduto?.length <= 0 || !filtrosState?.categoriaProduto) { 
        setFamilias(defaultFamiliesFormated);
      } else {
        const filterCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) =>
          filtrosState?.categoriaProduto.some((option) => 
            (option.value ? option.value : option) === defaultCategoryProduct.id
          )
        );

        const families = filterCategories.flatMap((category) =>
          category.families.map((family) => ({ label: family.name, value: family.id }))
        );
        setFamilias(families);
      } 
      if(filtrosState?.familia?.length <= 0 || !filtrosState?.familia) setCategoriaProduto(defaultCategoriesProductsFormated);
      return;
    }

    const filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
      defaultCategoryProduct.families.some((defaultFamily) => 
        defaultFamily.products.some((defaultProduct) =>     
          options.some((option) => option.value === defaultProduct.id)
        )
      )
    );
    const filteredFamilies = defaultFamilies.filter((defaultFamily) =>
      defaultFamily.products.some((defaultProduct) =>
        options.some((option) => option.value === defaultProduct.id)
      )
    );

    const listFiteredCategories = filteredCategories.map((res) => ({ label: res.name, value: res.id }));
    const listFiteredFamilies = filteredFamilies.map((res) => ({ label: res.name, value: res.id }));

    if(filtrosState?.categoriaProduto && filtrosState?.categoriaProduto?.length > 0) {
      const filteredCategoriesInFilter = defaultCategoriesProducts.filter((defaultCategorieProduct) => 
        filtrosState?.categoriaProduto.some((option) => (option.value ? option.value : option) === defaultCategorieProduct.id)
      );

      filteredCategoriesInFilter.forEach((category) => {
        const existingId = listFiteredCategories.findIndex((item) => item.value === category.id);
        if (existingId === -1) {
          listFiteredCategories.push({ label: category.name, value: category.id });
        } 
      });
    }
    if(filtrosState?.familia && filtrosState?.familia?.length > 0) {
      const filteredFamilies = defaultFamilies.filter((defaultFamily) => 
        filtrosState?.familia.some((option) => (option.value ? option.value : option) === defaultFamily.id)
      );
      filteredFamilies.forEach((family) => {
        const existingId = listFiteredFamilies.findIndex((item) => item.value === family.id);
        if (existingId === -1) {
          listFiteredFamilies.push({ label: family.name, value: family.id });
        } 
      });
    }
    setFamilias(listFiteredFamilies);
    setCategoriaProduto(listFiteredCategories);
  }    

};