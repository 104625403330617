import React from 'react';
import { ContentFinishFlag, HorizontalLine, Marker, VerticalMarker } from './styles';
import { Row, Column, Tooltip, FinishFlag } from '@components-teammove';

const Goal = ({ goal, newOneHundredPosition, maxPercentage }) => {
  return(
    <VerticalMarker color={goal.color} percent={configurePostionByPercentage(goal.percent, newOneHundredPosition, maxPercentage)}/>
  );
};

const CurrentMarker = ({ typeIcon, colorMarker, defaultPos }) => {  
  return(
    <Marker type={typeIcon} color={colorMarker} defaultPos={defaultPos}/>  
  );
};

const Line = ({ data, finishedActivitiesLine, goal, newOneHundredPosition, maxPercentage, finishedData, scheduleData }) => {
  
  let colorMarker = null;
  let typeIcon = null;

  const percentOfGoal = (data.percent * 100) / goal.percent;
  
  if (percentOfGoal < 80 || goal.percent === 0 ) {
    colorMarker = '#FF3B30';
    typeIcon = 'walk';
  } else if (percentOfGoal < 100) {
    colorMarker = '#FD832B';
    typeIcon = 'run';
  } else if (percentOfGoal < 110) {
    colorMarker = '#80C971';
    typeIcon = 'run';
  } else {
    colorMarker = '#1890FF';
    typeIcon = 'run-fast';
  }

  const sizeOfFinishedLine = configurePostionByPercentage(finishedData.percent, newOneHundredPosition, maxPercentage);
  const sizeRemaining = 100 - sizeOfFinishedLine;
  const sizeOfSchedule = () => {
    if(sizeRemaining <= configurePostionByPercentage(scheduleData.percent, newOneHundredPosition, maxPercentage)) {
      return sizeRemaining;
    }else {
      return configurePostionByPercentage(scheduleData.percent, newOneHundredPosition, maxPercentage);
    }
  };

  return(
    finishedActivitiesLine ? (
      <>
        <Column width={`${configurePostionByPercentage(data.percent, newOneHundredPosition, maxPercentage)}%`} gap='1px' align='end'>
          <CurrentMarker colorMarker={colorMarker} typeIcon={typeIcon}/>
          <HorizontalLine typeLine={data.typeLine} color={colorMarker} percent={100}/>
        </Column>
      </>
    ) : (
      !(sizeOfFinishedLine >= 100) && (
        <HorizontalLine 
          typeLine={data.typeLine} 
          color='#FFCC00' 
          percent={sizeOfSchedule()}
        />
      )
      
    )
  );
};

function allPercentsAreZero(arr) {
  return arr.every((obj) => obj.percent === 0);
}

function calculateOneHundredPercentByMaxPercentage(maxPercentage) {
  return (10000 / maxPercentage);
} 

function configurePostionByPercentage(percentage, newOneHundredPosition, maxPercentage) {
  if (percentage < 0) {
    return 0;
  }
  if (percentage <= 100) {
    return (percentage / 100) * newOneHundredPosition;
  } else if (percentage <= maxPercentage) {
    const remainingPercentage = maxPercentage - 100;
    const remainingPosition = 100 - newOneHundredPosition;
    return newOneHundredPosition + ((percentage - 100) / remainingPercentage) * remainingPosition;
  } else {
    return 100; 
  }
}

const RaceLineProgress = ({ data, goal, info, maxPercentage, showFinishFlag, ...props }) => {

  const newHundredPercentPosition = calculateOneHundredPercentByMaxPercentage(maxPercentage);

  return(
    <Tooltip title={info}>
      <Row width='100%' align='end' height= '75px'>
        
        {goal.percent !== 0 && <Goal goal={goal} newOneHundredPosition={newHundredPercentPosition} maxPercentage={maxPercentage}/>}
        {showFinishFlag && (
          <ContentFinishFlag left={newHundredPercentPosition}>
            <FinishFlag rows={10} height={75}/>
          </ContentFinishFlag>
        )}
        {allPercentsAreZero(data) ? (
          <Column width={'100%'} gap='1px' align='start'>
            <CurrentMarker typeIcon='walk' defaultPos/>
            <HorizontalLine typeLine='solid' percent={100} {...props} />
          </Column>
        ) : (
          data.map((item, index)=> (
            <> 
              <Line finishedData={data[0]} scheduleData={data[1]} maxPercentage={maxPercentage} newOneHundredPosition={newHundredPercentPosition} data={item} finishedActivitiesLine={index === 0 && true} goal={goal} />
            </>
          ))
        )}
        
      </Row>
    </Tooltip>
  );
};

export default RaceLineProgress;