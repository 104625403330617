import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, View, Form, Skeleton, Select, Input, Switch } from '@components-teammove';
import { getTipo, saveTipo, resetSuccessSave } from '@ducks/configuracoesCampanhasTipos';
import { useDispatch, useSelector } from 'react-redux';
import { getPerfis } from '@sdk/Perfil';
import { FlexRowMinorGap, SelfAlignEndFormItem, TitleLabel } from './styles';
import { getAgrupadores } from '@sdk/AgrupadoresCampanhas';

export default function TiposCadastro({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [perfis, setPerfis] = useState();
  const [allRoles, setAllRoles] = useState();
  const [originalFormValue, setOriginalFormValue] = useState(null);

  const dispatch = useDispatch();
  const tipo = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('tipo'));
  const loading = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('loading'));
  const success = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('success'));
  const loadingSave = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('loadingSave'));
  const successSave = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('loadingSave'));
  const successDelete = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('successSave'));

  const { externalId } = location.state || {};

  useEffect(() => {
    form.setFieldsValue({ format: 'MONEY', type: 'SALE' });
    if (externalId) handleGetTipo();

    (async () => {
      const perfisAsync = await getPerfis();
      setPerfis(perfisAsync.map(({ title, value }) => ({ label: title, value })));
    })();
    (async () => {
      const roles = await getAgrupadores();
      setAllRoles(roles.map(({ id, name }) => ({ label: name, value: id })));
    })();

    return () => {
      dispatch(resetSuccessSave());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success && externalId) {
      form.setFieldsValue(tipo);
      setOriginalFormValue(form.getFieldsValue());
    }
  }, [success]);

  useEffect(() => {
    if (successDelete) {
      onCancel();
    }
  }, [successDelete]);
  
  useEffect(() => {
    if (successSave) {
      onCancel();
    }
  }, [successSave]);

  const handleGetTipo = () => {
    dispatch(getTipo(externalId));
  };

  const handleDelete = (tipo) => {
    dispatch(saveTipo({ ...tipo, active: false }));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      if (values.type === 'POS') values.format = 'INTEGER';
      else values.format = 'MONEY';

      if (externalId) {
        values = { 
          ...values,
          createdAt: tipo.createdAt,
          updatedAt: tipo.updatedAt,
          id: tipo.id, 
          externalId 
        };
      }
      dispatch(saveTipo(values));
    });
  };

  const onBeforeCancel = () => {
    if(originalFormValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFormValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      visible={visible}
      title='Configurações / Campanhas / Tipos'
      numberOfButtons={externalId ? 2 : 1}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      cancelText='Excluir'
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir este tipo?', onConfirm: () => handleDelete(tipo),  cancelText: 'Cancelar', okText: 'Confirmar' }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null }}
      okButtonProps={{ loading: loadingSave }}
      onOk={handleSalvar}
    >
      {loading ? (
        <View>
          <Skeleton.Form/>
        </View>
      ) : (
        <Form layout='vertical' form={form}>
                
          {externalId && (
            <SelfAlignEndFormItem name='active' label='Ativo' valuePropName='checked'>
              <Switch/>
            </SelfAlignEndFormItem>
          )}
          <Form.Item name='type' label='Tipo' rules={[{ required: true, message: 'Insira o tipo' }]}>
            <Select options={[{ label: 'Vendas', value:'SALE' }, { label: 'Positivação', value: 'POS' }]} placeholder='Selecione o tipo'/>
          </Form.Item>
                
          <Form.Item label='Nome' name='description' rules={[{ required: true, message: 'Informe o nome do tipo!' }]}>
            <Input placeholder='Informe o nome do tipo' autoFocus/>
          </Form.Item>
          <View>
            <TitleLabel>Perfis Para Notificações</TitleLabel>
            <Form.Item 
              name='participants' 
              label={(<FlexRowMinorGap>Participantes</FlexRowMinorGap>)} 
            >
              <Select options={perfis} mode='multiple' placeholder='Selecione um ou mais participantes'/>
            </Form.Item>
            <Form.Item 
              name='managers' 
              label={(<FlexRowMinorGap>Gestores</FlexRowMinorGap>)} 
            >
              <Select options={perfis} mode='multiple' placeholder='Selecione um ou mais gestores'/>
            </Form.Item>
            <Form.Item name='roles' label='Agrupadores'>
              <Select placeholder='Agrupadores' mode='multiple' options={allRoles}/>
            </Form.Item>
            <Form.Item 
              name='subscribers' 
              label={(<FlexRowMinorGap>Inscritos</FlexRowMinorGap>)} 
            >
              <Select options={perfis} mode='multiple' placeholder='Selecione um ou mais inscritos'/>
            </Form.Item>
          </View>
        </Form>
      )}
    </Modal>
  );
}