import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row } from '@components-teammove';
import { getAtendimentos } from '@ducks/configuracoesChamados';
import { useHistory } from 'react-router-dom';

const Atendimento = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const atendimentos = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('atendimentos').toArray());
  const loadingAtendimento = useSelector(({ configuracoesChamados }) => configuracoesChamados.get('loadingAtendimento'));

  useEffect(() => {
    dispatch(getAtendimentos());
  }, []);

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'nome',
      sorter: true,
      render: (text, atendimento) => (
        <Row justify='space-between' align='center'>
          {text}
          <Button.Group>
            <Button 
              type='secondary'
              size='small'
              context='list'
              onClick={(event) => {
                event.stopPropagation();
                history.push('/configuracoes/chamados/atendimento', { id: atendimento.id });
              }}
            >
              Editar
            </Button>
          </Button.Group>  
        </Row>
      )
    },
  ];

  return(
    <Table
      columns={columns}
      dataSource={atendimentos}
      loading={loadingAtendimento}
      rowKey="id"
      status="ativo"
      onRow={(atendimento) => ({ onClick: () => history.push('/configuracoes/chamados/atendimento', { id: atendimento.id }) })}
    />
  );
};

export default Atendimento;
