import React, { useMemo } from 'react';
import { Skeleton, Column, Row, ContentTitle, Tooltip } from '@components-teammove';
import { useSelector } from 'react-redux';
import { FeatherIcon } from '../styles';
import { ActivityTypeIcon, HorizontalLine, SignatureUpload, Hyphen, ContentLocation, NameUnit, NumberUnit, ContentNumberAndNameUnit, ContentUnitData, DataRow, ContentDatas, IconHome, DataPlataform, ContentUserInfo, DataFillingOut, ProgressStars, PercentNumber, ContentProgress, Resume, ActivityTypeIconWrapper, AnswerExtraInfoContainer, AnswerExtraInfoTitle, AnswerExtraInfoValue, AnswerUpload, ContentWeight, QuestionAnswer, QuestionTitle, RowStyle, ContentDivider, VerticalLine, ContentConsultant, ContentDigitalSignature, LegendDigitalSignature, ModalStyle, QuestionNumber, NumberText } from './styles';
import { formatDate, formatDateTime, TelasNomenclaturas } from '@utils';

export default function ChecklistAnswers({ visible, onCancel, task, handleActivityCheck }) {
  
  const checklist = useSelector(({ checklists }) => checklists.get('finishedChecklist'));
  const loadingFinishedChecklist = useSelector(({ checklists }) => checklists.get('loadingFinishedChecklist'));
  const successFinishedChecklist = useSelector(({ checklists }) => checklists.get('successFinishedChecklist'));

  const isTaskClosed = useMemo(() => task.status === 'CONCLUIDA' && task.active, [task]);
  
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  return (
    <ModalStyle
      visible={visible}
      onCancel={onCancel}
      cancelText='Fechar'
      okText={handleActivityCheck && isTaskClosed ? 'Conferir' : 'Fechar'}
      onOk={handleActivityCheck && isTaskClosed ? (() => handleActivityCheck(!task.checked)) : onCancel}
      okButtonProps={handleActivityCheck && isTaskClosed ? task.checked &&  { type: 'primary', icon: <FeatherIcon type='check'/> } : { type: 'secondary' }}
      numberOfButtons={handleActivityCheck && isTaskClosed ? 2 : 1}
    >
      {loadingFinishedChecklist && !successFinishedChecklist ? (
        <Skeleton.Form/>
      ) : (
        <Column gap='28px'>
          <Resume comPeso={checklist.prRisk > 0}>
            {checklist.prRisk > 0 && (
              <>
                <ContentProgress>             
                  <ProgressStars percent={checklist.prRisk}/>
                  <PercentNumber>{checklist.prRisk}%</PercentNumber>       
                </ContentProgress>
                <ContentDivider>
                  <VerticalLine/>
                </ContentDivider>
              </>
            )}
            <DataFillingOut>
              <ContentTitle>{checklist.nameTypeActivity}</ContentTitle>
              <ContentDatas>
                <DataRow>
                  <IconHome type='user'/>
                  <ContentUserInfo>{checklist.nameUser}</ContentUserInfo>
                </DataRow>
                <DataRow>
                  <IconHome type='clipboard'/>
                  <ContentUserInfo>{checklist.nameTemplate}</ContentUserInfo>
                </DataRow>
                
                <DataRow>
                  <ContentUserInfo>Realizado em: {formatDate(checklist?.dateFinished)}</ContentUserInfo>{checklist.dateFinished && (<Hyphen>-</Hyphen>)}
                  
                  <DataPlataform>{checklist.originFinished === 'A' ? 'APP' : 'WEB'}</DataPlataform>
                </DataRow>
                
              </ContentDatas>
            </DataFillingOut>
          </Resume>

          <ContentUnitData>
            <ContentTitle>Dados d{companyNomenclature.artigo} {companyNomenclature.nomenclatura}</ContentTitle>
            <ContentNumberAndNameUnit>
              <DataRow>
                <NumberUnit>{checklist.shortNameCompany}</NumberUnit>{' '}
                <NameUnit>{checklist.nameCompany}</NameUnit>
              </DataRow>
              <Column>
                <ContentUserInfo>{checklist.addressCompany}</ContentUserInfo>
                <ContentLocation>{task.companyCity} - {task.companyState}</ContentLocation>
              </Column>
            </ContentNumberAndNameUnit>
          </ContentUnitData>

          <ContentDivider>
            <HorizontalLine/>
          </ContentDivider>
          {checklist?.groups?.map((group) => (
            <Column gap='12px' key={group.idGroup}>
              <RowStyle>
                <ContentTitle>{group.name}</ContentTitle>
                <ContentWeight color={group.riskColor}>
                  {group.answeredWeight}/{group.weight}
                </ContentWeight>
              </RowStyle>
              <HorizontalLine/>
              <Column gap='28px'>
                {group?.asks?.map(({ id, title, answered, answeredWeight, weight, answers, type, order, observation, riskColor }) => (

                  <Column gap='8px' key={id}>
                    <RowStyle>
                      <Tooltip title={observation}>
                        
                        <QuestionTitle align='center' gap='4px'>
                          <QuestionNumber>
                            <NumberText>
                              {order}
                            </NumberText>
                          </QuestionNumber>
                          {title}
                        </QuestionTitle>
                      </Tooltip>
                      
                      {riskColor && (
                        <ContentWeight key={id} color={riskColor}>
                          {answeredWeight}/{weight}
                        </ContentWeight>
                      )}

                    </RowStyle>
                    {answers.map(({ answer, value, idAnswer, idAskOption, observation, nameActivity, colorTypeActivity, iconTypeActivity, responsibleActivity, dateActivity, uploads }) => (
                      
                      <Column gap='8px' key={id}>
                        {(answer || (type === 'TEXTO')) && (
                          <QuestionAnswer key={idAnswer}>
                            {answer}
                          </QuestionAnswer>
                        )}
                        {observation && (
                          <AnswerExtraInfoContainer>
                            <AnswerExtraInfoTitle>Observação</AnswerExtraInfoTitle>
                            <AnswerExtraInfoValue>{observation}</AnswerExtraInfoValue>
                          </AnswerExtraInfoContainer>
                        )}
                        {nameActivity && (
                          <>
                            <AnswerExtraInfoContainer>
                              <Column gap='12px'>
                                <Column gap='8px'>
                                  <AnswerExtraInfoTitle>Plano de ação</AnswerExtraInfoTitle>
                                  <Row gap='12px' align='center'>
                                    <ActivityTypeIconWrapper color={colorTypeActivity} size='small'>
                                      <ActivityTypeIcon type={iconTypeActivity}/>
                                    </ActivityTypeIconWrapper>
                                    <AnswerExtraInfoValue>{nameActivity}</AnswerExtraInfoValue>
                                  </Row>
                                </Column>
                                <Column gap='8px'>
                                  <AnswerExtraInfoTitle>Quem</AnswerExtraInfoTitle>
                                  <AnswerExtraInfoValue>{responsibleActivity}</AnswerExtraInfoValue>
                                </Column>
                                <Column gap='8px'>
                                  <AnswerExtraInfoTitle>Quando</AnswerExtraInfoTitle>
                                  <AnswerExtraInfoValue>{formatDate(dateActivity)}</AnswerExtraInfoValue>
                                </Column>
                              </Column>
                            </AnswerExtraInfoContainer>
                          </>
                        )}
                        {uploads?.map(({ idUpload, url }, index) => (
                          <AnswerUpload src={url} key={idUpload}/>
                        ))}
                      </Column>

                    ))}
                  </Column>
                ))}
              </Column>
            </Column>
          ))}

          {checklist.observationChecklist &&
            (<>
              <ContentDivider>
                <HorizontalLine/>
              </ContentDivider> 
            </>)}

          {checklist.observationChecklist && (
            <ContentConsultant>
              <ContentTitle>Visão do consultor</ContentTitle>
              <QuestionAnswer>{checklist.observationChecklist}</QuestionAnswer>
            </ContentConsultant>       
          )}
          
          {checklist.signatureUserChecklist && (
            <ContentDivider>
              <HorizontalLine/>
            </ContentDivider> 
          )}
        
          {checklist.signatureUserChecklist && (
            <ContentDigitalSignature>
              <ContentTitle>Assinatura digital</ContentTitle>
              <SignatureUpload src={checklist.signatureUserChecklist} />
              <Column>
                <LegendDigitalSignature>{checklist.signatureUserNameChecklist}</LegendDigitalSignature>
                <LegendDigitalSignature>{formatDateTime(checklist.signatureMomentChecklist)}</LegendDigitalSignature>
              </Column>
            </ContentDigitalSignature>
          )}
        </Column>
      )}
    </ModalStyle>
  );
}