import { View, Text } from '@components';
import { Skeleton, Button, Icon } from '@components-teammove';
import styled from 'styled-components';

export const Container = styled(View)`
  flex-direction: row;
  gap: 12px;
`;

export const ContentMap = styled.div`
  background-color: #fff;
  height: 680px;
  width: 100%;
  border-radius: 12px;
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-t::after {
    background: ${({ theme }) => theme.darkSecondaryColor};	
    min-width: 200px !important;
  }
  .gm-style-iw-tc::after {
    background: ${({ theme }) => theme.darkSecondaryColor};	
  }

  .gm-ui-hover-effect > span {
    background-color: ${({ theme }) => theme.textColor};
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }
`;

export const CompaniesView = styled(View)`
  gap: 12px;
  max-height: calc(570px - 24px);
  overflow-y: scroll;
  padding-right: 8px;

  ::-webkit-scrollbar {
    margin-left: 16px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-left: 4px solid transparent;
    padding-left: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.darkPrimaryColor};
    border-radius: 45px;
    border-left: 4px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.darkPrimaryColor};
    margin-left: 16px;
  }
`;

export const Company = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  ${({ collapsed }) => collapsed && `
    padding: 20px 0;
    justify-content: center;
  `}
`;

export const CompanyIdentifier = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .7;
    ${({ collapsed }) => collapsed && `
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    `}
  }
`;

export const CompanyName = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const CompanyAddress = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
  }
`;

export const LegendContainer = styled(View)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 25rem;
  padding: .5rem;
  border-radius: 8px;
  border: 1px solid gray;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  * {
    color: ${({ theme }) => theme.textColor} !important;
  }
`;

export const LegendHeader = styled(View)`
  padding: .25rem;
`;

export const TitleText = styled(Text)`
  &.ant-typography {
    font-size: 13pt;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const SubText = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    opacity: .8;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const LegendContent = styled(View)`
  max-height: 206px;
  overflow-y: scroll;
  flex: 1;
`;

export const ContentItem = styled(Text)`
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
  color: ${({ theme }) => theme.textColor};
`;

export const CloseButton = styled(Icon.CloseOutlined)`
  align-self: flex-end;
`;

export const CompanySkeleton = styled(Skeleton)`
  border-radius: 12px;
`;

export const SeeAllButton = styled(Button)`
  &.ant-btn {
    width: 100%;
  }
`;