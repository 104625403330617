import { InfoUsuario } from '@utils';
import { STATUS_AGUARDANDO_RESPOSTA, STATUS_FECHADO } from '../rules';

export const getLabelStatus = (status, periodo) => {
  switch (status) {
    case STATUS_FECHADO.NO_PRAZO:
      return periodo > 0 ? ('Fechado ' + getLabelPeriodo(periodo) + ' antes do prazo') : ('Fechado no prazo');
    default: 
      return 'Fechado ' + getLabelPeriodo(periodo) + ' depois do prazo';    
  } 
};

const getLabelPeriodo = (periodo) => {
  const dias = Math.abs(periodo);
  
  return dias > 1 ? (dias + ' dias ') : (dias + ' dia ');
};

export const getColor = (chamado) => {
  const usuarioAtual = InfoUsuario.get('cdUsuario');
  
  return chamado.aguardandoResposta && usuarioAtual === chamado.idUsuario
    ? chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO
      ? '#F44336'
      : '#E65100'
    : chamado.aguardandoResposta
      ? '#E65100'
      : chamado.statusCor;
};

export const getText = (chamado, texto) => {
  const usuarioAtual = InfoUsuario.get('cdUsuario');

  return chamado.aguardandoResposta
    ? usuarioAtual === chamado.idUsuario
      ? chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO
        ? 'Pendência atrasada'
        : 'Pendência aberta'
      : 'Aguardando pendência'
    : texto;
};

