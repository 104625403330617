import styled from 'styled-components';
import Icon from '../Icon';

export const CollapseButton = styled(Icon.Feather)`
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
`;

export const CollapsibleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ collapsed }) => collapsed && `
    width: 45px;
    overflow: hidden;
  `}
  ${CollapseButton} {
    ${({ collapsed }) => collapsed ? `
      align-self: center;
      transform: rotate(-90deg);
    ` : `
      align-self: flex-end;
    `}
  }
`;