import styled from 'styled-components';
import { Form, Row } from '@components-teammove';

export const ArticleFormItem = styled(Form.Item)`
  flex: 0.4;
`;

export const StyleRow = styled(Row)`
  padding-right: 210px;
`;

export const SmallFormItem = styled(Form.Item)`
  width: 40%;
`;