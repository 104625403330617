import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { recuperarSenha as recuperarSenhaSdk } from '@sdk/Login';

//Action Types
export const Types = {
  RECUPERAR_SENHA: 'RECUPERAR_SENHA',
  RECUPERAR_SENHA_SUCCESS: 'RECUPERAR_SENHA_SUCCESS',
  RECUPERAR_SENHA_ERROR: 'RECUPERAR_SENHA_ERROR',

  RESET: 'RECUPERAR_SENHA/RESET',
};

//Action Creators
export const recuperarSenha = (username) => ({ type: Types.RECUPERAR_SENHA, username });
export const recuperarSenhaSuccess = () => ({ type: Types.RECUPERAR_SENHA_SUCCESS });
export const recuperarSenhaError = (error) => ({ type: Types.RECUPERAR_SENHA_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchRecuperarSenha(action) {
  try {
    const { username } = action;

    yield call(recuperarSenhaSdk, username);
    yield put(recuperarSenhaSuccess());
    Notification.success('Email enviado com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(recuperarSenhaError(err));
  }
}

export const saga = [
  takeLatest(Types.RECUPERAR_SENHA, fetchRecuperarSenha),
];

// Reducer
const initialState = Map({
  loading: false,
  success: false,
  error: false,
});

const handleRecuperarSenha = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleRecuperarSenhaSuccess = (state, action) => {
  return state
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleRecuperarSenhaError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.RECUPERAR_SENHA: return handleRecuperarSenha(state, action);
    case Types.RECUPERAR_SENHA_SUCCESS: return handleRecuperarSenhaSuccess(state, action);
    case Types.RECUPERAR_SENHA_ERROR: return handleRecuperarSenhaError(state, action);

    case Types.RESET: return initialState;

    default: return state;
  }
}
