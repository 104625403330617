import React, { useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Map } from 'immutable';
import { getTrail, saveTrail, resetTrail } from '@ducks/wikisEADTrilha';
import { authorization } from '@sdk/api';
import App from '@app';
import { Form, Body, PageHeader, Button, Input, View, List, PopConfirm, Text, Upload, Select, Spin, Switch } from '@components';
import { Icon } from '@components-teammove';
import { InfoUsuario, download, getValueFromFile, Notification } from '@utils';
import { ContentHeader, ContentInputHeader, ContainerUpload, ViewUpload, ComponentUpload, ImageCover, IconRemoveCover, ComponentEmptyCover, LabelItemObservation, ContentBody, ContentObservation, ContentPresentation, ContentVideoInput, ContentVideoPresentation, ContentButtonOrderCourses, ButtonOrderCourses, ContentSpin } from './styles';
import ModalObservacao from './ModalObservacao';
import ModalCursos from './ModalCursos';
import { getInitialValuesToItemObservation, handleRemoveValueToItemObservation, handleChangeOrderItemObservation } from './rules';
import { getAllCoursesToOptions } from '@sdk/Wikis/EAD/Trilhas';

const { REACT_APP_API } = window.env;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function WikisConteudos() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [cover, setCover] = useState(null);
  const [loadingCover, setLoadingCover] = useState(false);
  const [loadingVideoPresentation, setLoadingVideoPresentation] = useState(false);

  const [visibleModalItemObservation, setVisibleModalItemObservation] = useState();
  const [itemObservation, setItemObservation] = useState();

  const [visibleModalCourses, setVisibleModalCourses] = useState();

  const [courses, setCourses] = useState([]);
  const [coursesChoosed, setCoursesChoosed] = useState([]);

  const trail = useSelector(({ wikisEADTrilha }) => wikisEADTrilha.get('trail'));
  const loadingSearch = useSelector(({ wikisEADTrilha }) => wikisEADTrilha.get('loadingSearch'));
  const successSearch = useSelector(({ wikisEADTrilha }) => wikisEADTrilha.get('successSearch'));
  const loadingSave = useSelector(({ wikisEADTrilha }) => wikisEADTrilha.get('loadingSave'));
  const successSave = useSelector(({ wikisEADTrilha }) => wikisEADTrilha.get('successSave'));

  useEffect(() => {
    handleSearchCourses();
    handleSearchTrail();
    return () => handleResetTrail();
  }, []);

  useEffect(() => {
    if (successSearch && parseInt(id) > 0) {
      handleSetInititalValues();
    }
  }, [successSearch]);

  useEffect(() => {
    if (successSave) {
      window.history.back();
    }
  }, [successSave]);

  const handleResetTrail = () => {
    dispatch(resetTrail());
  };

  const handleSearchTrail = () => {
    dispatch(getTrail(parseInt(id)));
  };

  const handleSaveTrail = (trail) => {
    dispatch(saveTrail(trail));
  };

  const handleSetInititalValues = () => {
    form.setFieldsValue({ ...trail,  tituloApresentacao: trail?.apresentacao?.titulo, videoApresentacao: trail?.apresentacao?.video });
    setCover(trail?.capa);
    setCoursesChoosed(trail?.trilhaCursos?.map((item) => item.idCurso));
  };

  const handleSearchCourses = () => {
    getAllCoursesToOptions().then((res) => setCourses(res));
  };

  const handleShowModalItemObservation = (item = {}) => {
    setVisibleModalItemObservation(true);
    setItemObservation(item);
  };
  
  const handleHideModalItemObservation = () => {
    setVisibleModalItemObservation(false);
    setItemObservation(false);
  };

  const handleChangeCover = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingCover(true);
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        form.setFieldsValue({ capa: info.file.response });
        setCover(url);
        setLoadingCover(false);
      });
    }
  };

  const handleRemoveCover = () => {
    setCover(null);
    form.setFieldsValue({ capa: null });
    form.validateFields(['capa']).then();
  };

  const handleChangeVideoPresentation = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingVideoPresentation(true);
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        form.setFieldsValue({ videoApresentacao: info.file.response });
        setLoadingVideoPresentation(false);
      });
    }
  };

  const handleSelectCourse = (items) => {
    setCoursesChoosed(items);
    form.setFieldsValue({ trilhaCursos: items.map((item, index) => ({ idTrilha: trail?.id, idCurso: item, ordem: index })) });
  };

  return (
    <App>
      <Body>
        <PageHeader 
          title='Trilha'
          onBack={() => window.history.back()}
          extra={[
            <Button
              key='1'
              type='success'
              icon={<Icon.SaveOutlined />}
              onClick={() => form.submit()}
              loading={loadingSave}
            >
              Salvar
            </Button>,
          ]}
        >
          {loadingSearch ? <ContentSpin><Spin /></ContentSpin> : 
            <View>
              <Form.Provider
                onFormFinish={(name, { values, forms }) => {
                  if (name === 'formItemObservation') {
                    let observacao = form.getFieldValue('observacao') || [];
                    observacao = Map(observacao.map((item, index) => [item.id, { ...item, ordem: index }])).set(values.id, values).toList().toArray();
                    form.setFieldsValue({ observacao });
                    handleHideModalItemObservation();
                  } else if (name === 'formTrail') {
                    const valuesFinal = { ...values, observacao: JSON.stringify(values.observacao || []), apresentacao: JSON.stringify({ titulo: values.tituloApresentacao, video: values.videoApresentacao }) };
                    delete valuesFinal.tituloApresentacao;
                    delete valuesFinal.videoApresentacao;
                    if (valuesFinal.trilhaCursos?.length > 0 || (!valuesFinal.ativo && parseInt(id) > 0)) {
                      handleSaveTrail(valuesFinal);
                    } else {
                      Notification.error('Informe pelo menos um curso para a trilha!');
                    }
                  }
                }}
              >
                <Form layout='vertical' form={form} name="formTrail">
                  <ContentHeader layout='horizontal'>
                    <ContentInputHeader>
                      <Form.Item label='Título' name='nome' rules={[{ required: true, message: 'Informe o título' }]}>
                        <Input placeholder='Informe o título' />
                      </Form.Item>
                      <Form.Item label='Resumo' name='resumo' rules={[{ required: true, message: 'Informe o resumo' }]}>
                        <Input.TextArea placeholder='Informe o resumo' />
                      </Form.Item>
                    </ContentInputHeader>
                    <ContainerUpload>
                      <Form.Item label='Capa' name='capa' valuePropName='fileList' getValueFromEvent={getValueFromFile}>
                        <ViewUpload>
                          <ComponentUpload
                            headers={authorization()}
                            folder={`EAD/${InfoUsuario.get('cdCliente')}`}
                            URI={REACT_APP_API}
                            onDownload={(file) => download(file.response)}
                            accept={'image/*'}
                            showUploadList={false}
                            disabled={loadingCover}
                            onChange={handleChangeCover}
                          >
                            {cover && !loadingCover ? (
                              <View>
                                <ImageCover width={220} height={220} src={cover} alt='Capa' />
                                <IconRemoveCover
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveCover();
                                  }}
                                />
                              </View>
                            ) : (
                              <ComponentEmptyCover>
                                {loadingCover ? <Icon.LoadingOutlined /> : <Icon.UploadOutlined />}
                                <View textAlign='center'>Clique ou arraste para<br /> anexar a imagem</View>
                              </ComponentEmptyCover>
                            )}
                          </ComponentUpload>
                        </ViewUpload>
                      </Form.Item>
                    </ContainerUpload>
                  </ContentHeader>
                  <ContentBody>
                    <ContentObservation>
                      <Form.Item label="Observações" shouldUpdate={(prevValues, curValues) => prevValues.observacao !== curValues.observacao}>
                        {({ getFieldValue }) => {
                          const observacao = getFieldValue('observacao') || [];
                          return (observacao.length > 0) ? (
                            <List
                              dataSource={observacao.sort(({ ordem: ordemPrimeira }, { ordem: ordemSegunda }) => ordemPrimeira - ordemSegunda)}
                              renderItem={(item, index) => (
                                <List.Item
                                  key={index}
                                  actions={[
                                    <Button key="moveUp" icon={<Icon.UpOutlined />} onClick={() => handleChangeOrderItemObservation(form, index, index - 1)} disabled={index === 0} />,
                                    <Button key="moveDown" icon={<Icon.DownOutlined />} onClick={() => handleChangeOrderItemObservation(form, index, index + 1)} disabled={index === observacao.length - 1 } />,
                                    <PopConfirm key="remove" title="Confirma a exclusão?" okText="Sim" cancelText="Não" onConfirm={() => handleRemoveValueToItemObservation(form, item)}>
                                      <Button icon={<Icon.DeleteOutlined />} />
                                    </PopConfirm>,
                                    <Button key="edit" type="primary" icon={<Icon.EditOutlined />} onClick={() => handleShowModalItemObservation(item)}/>,
                                  ]}
                                >
                                  <LabelItemObservation>{index + 1} - {item.titulo}</LabelItemObservation>
                                </List.Item>
                              )}
                            />
                          ) : <Text>Sem itens de observação</Text>;
                        }}
                      </Form.Item>
                      <Form.Item>
                        <Button type="dashed" onClick={() => handleShowModalItemObservation(getInitialValuesToItemObservation(form))}>
                          <Icon.PlusOutlined /> Adicionar novo item
                        </Button>
                      </Form.Item>
                    </ContentObservation>
                    <ContentPresentation>
                      <Text>Apresentação</Text>
                      <Form.Item label='Título' name='tituloApresentacao'>
                        <Input placeholder='Informe o título' />
                      </Form.Item>
                      <View horizontal>
                        <ContentVideoInput>
                          <Form.Item label='Vídeo' name='videoApresentacao'>
                            <Input placeholder='Informe o vídeo' />
                          </Form.Item>
                        </ContentVideoInput>
                        <ContentVideoPresentation>
                          <Form.Item label='Novo anexo'>
                            <Upload
                              headers={authorization()}
                              folder={`EAD/${InfoUsuario.get('cdCliente')}`}
                              URI={REACT_APP_API}
                              onDownload={(file) => download(file.response)}
                              accept="video/*"
                              disabled={loadingVideoPresentation}
                              onChange={handleChangeVideoPresentation}
                            >
                              <Button>
                                <Icon.UploadOutlined /> Clique aqui
                              </Button>
                            </Upload>
                          </Form.Item>
                        </ContentVideoPresentation>
                      </View>
                    </ContentPresentation>
                  </ContentBody>
                  {courses?.length > 0 &&
                  <View horizontal>
                    <Form.Item label='Cursos'>
                      <Select
                        value={coursesChoosed}
                        mode='multiple'
                        onChange={handleSelectCourse}
                        placeholder='Escolha os cursos'
                        optionFilterProp='label'
                      >
                        {courses?.map((item) => (<Select.Option key={item.cursoId} value={item.cursoId} label={item.cursoTitulo}>{item.cursoTitulo}</Select.Option>))}
                      </Select>
                    </Form.Item>
                    {coursesChoosed.length > 1 && 
                    <ContentButtonOrderCourses>
                      <ButtonOrderCourses icon={<Icon.OrderedListOutlined />} onClick={() => setVisibleModalCourses(true)} />
                    </ContentButtonOrderCourses>}
                  </View>}
                  {parseInt(id) > 0 &&
                    <Form.Item label='Ativo' name='ativo' valuePropName="checked">
                      <Switch />
                    </Form.Item>}
                </Form>
                <ModalObservacao visible={visibleModalItemObservation} onCancel={handleHideModalItemObservation} itemObservation={itemObservation} />
                <ModalCursos visible={visibleModalCourses} onCancel={() => setVisibleModalCourses(false)} courses={courses} coursesChoosed={coursesChoosed} handleNewOrder={(items) => handleSelectCourse(items)}/>
              </Form.Provider>
            </View>}
        </PageHeader>
      </Body>
    </App>
  );  
}