import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio, Form, Select, Column, Modal } from '@components-teammove';
import { getSearchParams, Notification, TelasNomenclaturas } from '@utils';
import { ClusterColumnsTitle, ClusterTexts, ClustersColumn, FormItem, GroupLabel, RadioGroup, Row } from './styles';

import { updateAllUnidades, updateUnidades, resetSuccessSave, getMultipleClustersOptions } from '@ducks/unidades';

const EditarMultiplas = ({ visible, onCancel, onSuccess, title }) => {
  const unidadesIds = useMemo(() => getSearchParams('selecting')?.split(',')?.map((unityId) => parseInt(unityId)) || [], []);
  const dispatch = useDispatch();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const unidades = useSelector(({ unidades }) => unidades.get('unidades'));
  const loadingSave = useSelector(({ unidades }) => unidades.get('loadingSave'));
  const successSave = useSelector(({ unidades }) => unidades.get('successSave'));
  const search = useSelector(({ unidades }) => unidades.get('busca'));

  const multipleClustersOptions = useSelector(({ unidades }) => unidades.get('multipleClustersOptions'));
  const loadingMultipleClustersOptions = useSelector(({ unidades }) => unidades.get('loadingMultipleClustersOptions'));

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  
  const [newIdCluster, setNewIdCluster] = useState();
  
  const [currentIdGroup, setCurrentIdGroup] = useState();
  const [clustersByGroup, setClustersByGroup] = useState([]);

  const [arrayToSave, setArrayToSave] = useState([]);

  useEffect(() => {
    if(selectedCompanies.length > 0) {
      dispatch(getMultipleClustersOptions(selectedCompanies));
    }
    return () => dispatch(resetSuccessSave());
  }, [selectedCompanies]);
  
  useEffect(() => {
    setSelectedCompanies(unidades.filter(({ id }) => unidadesIds.includes(id)).map(({ id }) => id));
    const allClustersAreEqual = unidades.filter(({ id }) => unidadesIds.includes(id)).every(({ idCluster }) => idCluster === unidades[0].idCluster);
    if (unidadesIds?.length === 1 || allClustersAreEqual) {
      setNewIdCluster(unidades.find(({ id }) => unidadesIds[0] === id)?.idCluster);
    }
  }, [unidadesIds]);

  useEffect(() => {
    if (successSave) {
      Notification.success('Dados alterados com sucesso');
      onSuccess();
    }
  }, [successSave]);

  const handleSave = () => {
    const arrayFilteredToSave = arrayToSave.filter((item) => item.chosenCluster !== 0 && item.chosenCluster !== -1);
    
    if (selectedCompanies.length === unidades.length) {
      dispatch(updateAllUnidades(search, arrayFilteredToSave));
    }else {
      dispatch(updateUnidades(selectedCompanies, arrayFilteredToSave));
    }
  };

  const handleChangeGroup = async(group) => {
    const clusters = await multipleClustersOptions.find(({ id })=> id === group);
    setClustersByGroup(clusters.clusters);

    setCurrentIdGroup(group);
    const findGroupExistsInArray =  arrayToSave.find(({ id })=> id === clusters.id);
    if(!findGroupExistsInArray) {
      setArrayToSave([...arrayToSave, { 
        id:clusters.id, 
        name: clusters.name, 
        companies:clusters.companies, 
        chosenCluster: 0 
      }]);
    }
  };

  const handleChangeCluster = (cluster) =>{
    setNewIdCluster(cluster);
    const updatedArray = arrayToSave.map((obj) => {
      if (obj.id === currentIdGroup) {
        return { ...obj, chosenCluster: parseInt(cluster) };
      }
      return obj;
    });
    setArrayToSave(updatedArray);
  };

  const findClusterValue = () => {
    if (arrayToSave && arrayToSave.length > 0) {
      const foundItem = arrayToSave.find((item) => item.id === currentIdGroup);
      if (foundItem && foundItem.chosenCluster) {
        return foundItem.chosenCluster;
      }
    }
    return 0;
  };
  
  return(
    <Modal
      visible={visible}
      title={'Mover ' + title}
      onCancel={onCancel}
      okButtonProps={{ loading: loadingSave, disabled: !newIdCluster }}
      okText='Mover'
      onOk={handleSave}
    >
      <Column gap='20px'>
        <span>
          <GroupLabel weight='400'>Foram {companyNomenclature.artigo === 'a' ? 'selecionadas' : 'selecionado'}</GroupLabel>
          <GroupLabel> {selectedCompanies.length} {companyNomenclature.plural.toLowerCase()}</GroupLabel>
        </span>
        
        <Form layout='vertical'>
          <FormItem name='clusterGroup' rules={[{ required: true, message: 'Informe o tipo de grupo de cluster' }]} label={'Tipo de grupo de cluster'}>
            <Select
              onChange={(group) => handleChangeGroup(group)}
              placeholder='Selecione o grupo de cluters' 
              options={multipleClustersOptions.map((clusterGroup) => ({ label: `${clusterGroup.name} - ${clusterGroup.companies.length} ${(companyNomenclature.plural.toLowerCase())}`, value: clusterGroup.id }))}
              fitContent
              disabled={loadingMultipleClustersOptions}
            />
          </FormItem>
          
          <Column>
            {clustersByGroup.length > 0 && (
              <>
                <ClusterColumnsTitle>Selecione o cluster</ClusterColumnsTitle>
                <Row>
                    
                  <ClustersColumn>
                    <RadioGroup value={findClusterValue()} onChange={(value) => handleChangeCluster(value)} vertical>
                      
                      {clustersByGroup.map((cluster) => (
                        <Radio value={cluster.id} key={cluster.id}>
                          <Column>
                            <ClusterTexts bold>
                              {cluster.name}
                            </ClusterTexts>
                            <ClusterTexts lowOpacity small>
                            Frequência {cluster.qtDays} dias
                            </ClusterTexts>
                          </Column>
                     
                        </Radio>
                      ))}
                      
                    </RadioGroup>
                    
                  </ClustersColumn>
                  <Radio value={parseInt(-1)} key={-1} checked={findClusterValue() === -1} onChange={() => handleChangeCluster(parseInt(-1))}>Sem cluster</Radio>
                  
                </Row>
              </>
            )}
          </Column>
        </Form>
        
      </Column>
     
    </Modal>
  );
};

export default EditarMultiplas;