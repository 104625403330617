import styled from 'styled-components';
import { View, Image , List } from '@components';
import { Icon, List as TeammoveList, Button } from '@components-teammove';

export const FlexRow = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const GapRow = styled(FlexRow)`
  gap: 10px;
`;

export const CampaignImage = styled(Image)`
  max-width: 97px;
  max-height: 62px;
  border-radius: 8px;
  margin-left: auto;
`;

export const FillListItem = styled(List.Item)`
  ${({ active }) => !active && `
    opacity: 0.5;
  `}
  height: 94px;
`;

export const Fill = styled(View)`
  flex: 1;
`;

export const GapListItem = styled(List.Item)`
  gap: 10px;
`;

export const Padding = styled(View)`
  padding: 0 10px 10px;
`;

export const PositivationIcon = styled(Icon.Feather)`
  width: 18px;
  font-size: 18px;
`;

export const SalesIcon = styled(Icon.DollarOutlined)`
  width: 24px;
  font-size: 24px;
`;

export const CampaignName = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const PeriodText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const ListStyle = styled(TeammoveList)`
  &.ant-list {
    background-color: ${({ theme }) => theme.darkSecondaryColor};
  }
`;

export const AlignedCenterButton = styled(Button)`
  align-self: center;
`;