import React, { useEffect } from 'react';
import { getIconNameAlias } from '../rules';
import { PickerContainer } from '../styles';
import { IconOption } from './styles';

const IconPicker = ({ icons, onChange, onChangeComplete }) => {
  const uniqueId = Date.now();

  useEffect(() => {
    document.addEventListener('click', outsideClickListener);

    return () => document.removeEventListener('click', outsideClickListener);
  }, []);

  const outsideClickListener = (event) => {
    if (!document.getElementById(uniqueId)?.contains(event.target)) {
      onChangeComplete();
    }
  };
  
  const handleChange = (icon) => {
    onChange(icon);
    onChangeComplete();
  };

  return(
    <PickerContainer id={uniqueId}>
      {icons.map((icon) => (
        <IconOption type={icon} title={getIconNameAlias(icon)} key={icon} onClick={() => handleChange(icon)}/>
      ))}
    </PickerContainer>
  );
};

export default IconPicker;