import React, { act, useEffect, useState } from 'react';
import App from '@app';
import { PageHeader, Body, Breadcrumb, Button, Tag, Progress, Skeleton, Modal, Image, Avatar, Row, Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from '../styles';
import { ActivityTypeIcon, ActivityTypeIconWrapper } from '../../styles';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { AverageText, StyledFeatherIcon, CompanyCard, Container, ContentCard, ContentTitle, PercentText, ProgressColumn, SummaryGridRow, TemplateName, CompanyInfoColumn, LabelText, InfoText, ContentTitleRow, AnswerExtraInfoContainer, AnswerExtraInfoTitle, AnswerExtraInfoValue, AnswerUpload, QuestionRow, QuestionColumn, QuestionTag, GridRow, ChecklistObservationText, UploadFileName, IconWrapper, UploadFileContainer, CompanyDataRow, ShortNameCompanyText, CompanyNameText, CompanyAdress, CompanyLocation, QuestionNumber, NumberText, QuestionNumberRow, HorizontalLine, ContentAnswer, ContentAverageHistory, ContentHistoric, ClockButton, Historic, HistoricData, HistoricHeaderRow, DataColumn } from './styles';
import { getFinishedChecklist, reset } from '@ducks/checklists';
import { reset as resetMetas, getNotificationMeta } from '@ducks/metas';
import { getDownloadUrlAvatar, formatDate, formatDateTime, isImage, getTypeByFileExtension, getIconByType, TelasNomenclaturas, InfoUsuario } from '@utils';

import EmailModal from './EmailModal';
import Notifications from '../Finish/Notifications';

export default function Finished() {
  const history = useHistory();
  const { idActivity, idChecklist } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const hasFinishNow = location?.state?.fromFillChecklist;
  
  const urlParams = new URLSearchParams(location.search);
  const hasParamsInUrl = urlParams.size > 0;
  const urlHeaders = {
    token: urlParams.get('clienteToken'),
    key: urlParams.get('clienteKey'),
  };

  const theme = useSelector(({ app }) => app.get('theme'));
  const checklist = useSelector(({ checklists }) => checklists.get('finishedChecklist'));
  const loading = useSelector(({ checklists }) => checklists.get('loadingFinishedChecklist'));

  const notificationMetas = useSelector(({ metas }) => metas.get('notificationMetas'));
  const successNotificationMetas = useSelector(({ metas }) => metas.get('successNotificationMetas'));
  const successFinishChecklist = useSelector(({ checklists }) => checklists.get('successFinishChecklist')); 

  const [visibleUploadsModal, setVisibleUploadsModal] = useState(false);
  const [visibleEmailModal, setVisibleEmailModal] = useState(false);
  const [currentUploadImage, setCurrentUploadImage] = useState('');
  const [currentUploadsList, setCurrentUploadsList] = useState([]);
  const [visibleHistoric, setVisibleHistoric] = useState();
  const [visibleNotifications, setVisibleNotifications] = useState(false);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    dispatch(getFinishedChecklist(idChecklist, hasParamsInUrl ? urlHeaders : {}));
    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if(successFinishChecklist) {
      dispatch(getNotificationMeta(idActivity, InfoUsuario.get('cdUsuario')));
    }
  }, [successFinishChecklist]);

  useEffect(() => {
    if(successNotificationMetas) {
      if(!notificationMetas || notificationMetas.length === 0) return;
      setVisibleNotifications(true);
    }
  }, [successNotificationMetas]);

  const handleUploadImageClick = (uploadsList, imageIndex) => {
    setCurrentUploadsList(uploadsList.map((upload) => upload.url));
    setCurrentUploadImage(imageIndex);
    setVisibleUploadsModal(true);
  };

  const handleSendEmail = () => {
    setVisibleEmailModal(true);
  };

  const handleCloseNotificationMetaModal = () => {
    setVisibleNotifications(false);
    dispatch(resetMetas());
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={(
            <Row gap='14px' align='center'>
              {checklist?.nameActivity}
              <ActivityTypeIconWrapper color={checklist?.colorTypeActivity}>
                <ActivityTypeIcon type={checklist?.iconTypeActivity}/>
              </ActivityTypeIconWrapper>
            </Row>
          )}
          onBack={!hasParamsInUrl && (() => !hasFinishNow ? history.goBack() : history.push(`/atividades/agenda/${idActivity}`))}
          breadcrumb={!hasParamsInUrl && (
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades/agenda', name: 'Agenda' },
                { path: `/atividades/agenda/${idActivity}`, name: checklist?.nameActivity || '...' },
                { path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}`, name: 'Checklist', current: true },
              ]}
            />
          )}
          extra={[
            !hasParamsInUrl && <Button size='small' key='send' type='primary' onClick={() => handleSendEmail()}>Enviar por e-mail</Button>
          ]}
        >
          <Container>
            {loading ? (
              <>
                <ContentCard paddingless>
                  <Skeleton height='350px'/>
                </ContentCard>
                <ContentCard paddingless>
                  <Skeleton height='350px'/>
                </ContentCard>
                <ContentCard paddingless>
                  <Skeleton height='350px'/>
                </ContentCard>
              </>
            ) : (
              <>
                <ContentCard>
                  <SummaryGridRow>
                    <Column gap='32px'>
                      <Row gap='8px' align='center'>
                        {checklist.profilePhotoUser && <Avatar src={getDownloadUrlAvatar(checklist.profilePhotoUser)}/>}
                        <ContentTitle>{checklist.nameUser}</ContentTitle>
                      </Row>
                      <Column gap='18px'>
                        <Row gap='17px'>
                          <StyledFeatherIcon type='clipboard'/>
                          <TemplateName>{checklist.nameTemplate}</TemplateName>
                        </Row>
                        <AverageText>Realizado em: {formatDate(checklist.dateFinished)} - <strong>{checklist.originFinished === 'W' ? 'Web' : 'App'}</strong></AverageText>
                      </Column>
                    </Column>
                    {checklist.prRisk > 0 && (
                      <ProgressColumn>
                        <Progress.Star percent={checklist.prRisk} color={checklist.riskColor}/>
                        <PercentText>{checklist.prRisk}%</PercentText>
                      </ProgressColumn>
                    )}
                  </SummaryGridRow>
                </ContentCard>
                
                <Column gap='12px'>
                  <ContentTitleRow justify='space-between'>
                    <ContentTitle>Dados d{companyNomenclature.artigo} {(companyNomenclature.nomenclatura).toLowerCase()}</ContentTitle>
                  </ContentTitleRow>
                  <CompanyCard>
                    <CompanyDataRow>
                      <Column gap='20px'>
                      
                        <CompanyInfoColumn>                           
                          <ShortNameCompanyText>{checklist.shortNameCompany}  <CompanyNameText>{checklist.nameCompany}</CompanyNameText></ShortNameCompanyText>
              
                          <Column gap='3px'>
                            <CompanyAdress>{checklist.addressCompany || '-'}</CompanyAdress>
                            <CompanyLocation>{checklist.districtCompany} - {checklist.cityCompany} - {checklist.stateCompany} </CompanyLocation>
                          </Column>
                        </CompanyInfoColumn>
                      </Column>
                      {checklist.idCompany && !hasParamsInUrl && (
                        <Button type='primary' size='small' onClick={() => history.push(`/unidades/${checklist.idCompany}`)}>+ Info</Button>
                      )}
                    </CompanyDataRow>
                  </CompanyCard>

                </Column>

                {checklist.groups?.map(({ name, asks, idGroup, showWeight, riskColor, weight, answeredWeight }) => (
                  <Column gap='12px' key={idGroup}>
                    
                    <ContentTitleRow justify='space-between'>
                      <ContentTitle>{name}</ContentTitle>
                      {showWeight && (
                        <Tag bgColor={riskColor}>
                          <AverageText>
                            <strong>{answeredWeight}/{weight}</strong>
                          </AverageText>
                        </Tag>
                      )}
                    </ContentTitleRow>
                    <ContentCard>
                      <Column gap='20px'>
                        {asks.map(({ answers, id, title, riskColor, answered, weight, answeredWeight, type, order, historic }, index) => (
                          <>
                            <QuestionRow justify='space-between' key={id}>
                              <QuestionColumn gap='8px'>
                              
                                <QuestionNumberRow>
                                  <QuestionNumber>
                                    <NumberText>
                                      {order}
                                    </NumberText>
                                  </QuestionNumber>
                                  <LabelText>{title}</LabelText>
                                </QuestionNumberRow>
                              
                                {answers.length === 0 && (
                                  <InfoText>-</InfoText>
                                )}
                                {answers.map(({ answer, observation, uploads, nameActivity, responsibleActivity, dateActivity, colorTypeActivity, iconTypeActivity }) => (
                                
                                  <Column gap='12px' key={id}>
                                  
                                    {(answer || (type === 'TEXTO')) && (
                                      <ContentAnswer>
                                        <InfoText>{answer || '-'}</InfoText>
                                      </ContentAnswer>
                                    )}
                                    {observation && (
                                      <AnswerExtraInfoContainer>
                                        <AnswerExtraInfoTitle>Observação</AnswerExtraInfoTitle>
                                        <AnswerExtraInfoValue>{observation}</AnswerExtraInfoValue>
                                      </AnswerExtraInfoContainer>
                                    )}
                                    {nameActivity && (
                                      <>
                                        <AnswerExtraInfoContainer>
                                          <Column gap='12px'>
                                            <Column gap='8px'>
                                              <AnswerExtraInfoTitle>Plano de ação</AnswerExtraInfoTitle>
                                              <Row gap='12px' align='center'>
                                                <ActivityTypeIconWrapper color={colorTypeActivity} size='small'>
                                                  <ActivityTypeIcon type={iconTypeActivity}/>
                                                </ActivityTypeIconWrapper>
                                                <AnswerExtraInfoValue>{nameActivity}</AnswerExtraInfoValue>
                                              </Row>
                                            </Column>
                                            <Column gap='8px'>
                                              <AnswerExtraInfoTitle>Quem</AnswerExtraInfoTitle>
                                              <AnswerExtraInfoValue>{responsibleActivity}</AnswerExtraInfoValue>
                                            </Column>
                                            {/* <AnswerExtraInfoTitle>Objetivo</AnswerExtraInfoTitle>
                                      <AnswerExtraInfoValue>{goalActivity}</AnswerExtraInfoValue> */}
                                            <Column gap='8px'>
                                              <AnswerExtraInfoTitle>Quando</AnswerExtraInfoTitle>
                                              <AnswerExtraInfoValue>{formatDate(dateActivity)}</AnswerExtraInfoValue>
                                            </Column>
                                            {/* <AnswerExtraInfoTitle>Como</AnswerExtraInfoTitle>
                                      <AnswerExtraInfoValue>{observationActivity}</AnswerExtraInfoValue> */}
                                          </Column>
                                        </AnswerExtraInfoContainer>
                                      </>
                                    )}
                                    {uploads.map(({ idUpload, url }, index) => (
                                      <>
                                        {isImage(url) ? (
                                          <AnswerUpload src={url} key={idUpload} onClick={() => handleUploadImageClick(uploads, index)}/>
                                        ) : (
                                          <Row justify='flex-start' align='center' gap='4px'>
                                            <IconWrapper>
                                              {getIconByType(getTypeByFileExtension(url.substring(url.lastIndexOf('.') + 1)), '1.5rem', { title: url, onClick: () => handleUploadImageClick(uploads, index) })}
                                            </IconWrapper>
                                            <UploadFileContainer>
                                              <Row>
                                                <UploadFileName onClick={() => handleUploadImageClick(uploads, index)} title={url}>{url.substring(url.lastIndexOf('/') + 1)}</UploadFileName>
                                              </Row>
                                            </UploadFileContainer>
                                          </Row>
                                        )}
                                      </>
                                    ))}
                                    {visibleUploadsModal && (
                                      <Modal.Picture 
                                        onCancel={() => setVisibleUploadsModal(false)}
                                        pictures={currentUploadsList}
                                        initialImageIndex={currentUploadImage}
                                      />
                                    )}
                                  
                                  </Column>
                                ))}
                              </QuestionColumn>
                              {(type === 'MULTIPLA_ESCOLHA' || type === 'CAIXA_SELECAO') && (
                                <ContentAverageHistory>
                                  <Row align='center' gap='6px'>
                                    <ClockButton
                                      onBlur={() => setVisibleHistoric()}
                                      type='link' size='small'
                                      focus blur  
                                      icon={<Icon.Feather type='clock' size='24px'/>}
                                      onClick={() => visibleHistoric === id ? setVisibleHistoric() : setVisibleHistoric(id)}
                                    >
                                      {visibleHistoric === id && (
                                        <ContentHistoric >
                                          <HistoricHeaderRow>
                                            <InfoText>Histórico de respostas</InfoText>
                                            <Icon.Feather type='clock' size='24px'/>
                                          </HistoricHeaderRow>
                                        
                                          {historic.length > 0 ? (
                                            <Historic>
                                              {historic.map(({ answer, date }) => (
                                                <>
                                                  <DataColumn>
                                                    <HistoricData>
                                                      {formatDate(date)}
                                                    </HistoricData>
                                                    <InfoText>
                                                      {answer}
                                                    </InfoText>
                                                  </DataColumn>                                        
                                                </>
                                              ))} 
                                            </Historic>
                                          ) : (
                                            <HistoricHeaderRow>
                                              <InfoText>Sem histórico de respostas</InfoText>
                                            </HistoricHeaderRow>
                                          )}
                                        </ContentHistoric>
                                      )}

                                    </ClockButton>
                                 
                                    <QuestionTag bgColor={riskColor}>
                                      <AverageText>
                                        <strong>{answered ? `${(answeredWeight > weight ? weight : answeredWeight)}/${weight}` : `0/${weight}`}</strong>
                                      </AverageText>
                                    </QuestionTag>
                                  </Row>
                                </ContentAverageHistory>
                                
                              )}
                            
                            </QuestionRow>
                            {(asks.length !== 1 && asks.length !== index + 1 )  && (
                              <HorizontalLine />
                            )}
                          </>
                        ))}
                      </Column>
                    </ContentCard>
                  </Column>
                ))}

                {checklist.actionPlans?.length > 0 && (
                  <Column gap='12px'>
                    <ContentTitleRow justify='space-between'>
                      <ContentTitle>Planos de ação</ContentTitle>
                    </ContentTitleRow>
                    <Column gap='20px'>
                      {checklist.actionPlans.map(({ name, objective, responsible, date, observation, colorTypeActivity, iconTypeActivity }, index) => (
                        <ContentCard key={name + date}>
                          <Column gap='8px'>
                            <LabelText>O quê</LabelText>
                            <Row gap='14px' align='center'>
                              <InfoText>{name || '-'}</InfoText>
                              <ActivityTypeIconWrapper color={colorTypeActivity} size='small'>
                                <ActivityTypeIcon type={iconTypeActivity}/>
                              </ActivityTypeIconWrapper>
                            </Row>
                            <GridRow>
                              <Column gap='8px'>
                                <LabelText>Quem</LabelText>
                                <InfoText>{responsible || '-'}</InfoText>
                              </Column>
                              <Column gap='8px'>
                                <LabelText>Quando</LabelText>
                                <InfoText>{formatDate(date) || '-'}</InfoText>
                              </Column>
                            </GridRow>
                            <LabelText>Como</LabelText>
                            <InfoText>{observation || '-'}</InfoText>
                          </Column>
                        </ContentCard>
                      ))}
                    </Column>
                  </Column>
                )}

                {checklist.observationChecklist && !hasParamsInUrl && (
                  <Column gap='12px'>
                    <ContentTitleRow justify='space-between'>
                      <ContentTitle>{checklist.observationLabel || 'Visão do consultor'}</ContentTitle>
                    </ContentTitleRow>
                    <ContentCard>
                      <ChecklistObservationText>{checklist.observationChecklist}</ChecklistObservationText>
                    </ContentCard>
                  </Column>
                )}

                {checklist.signatureUserChecklist && (
                  <Column gap='12px'>
                    <ContentTitleRow justify='space-between'>
                      <ContentTitle>Assinatura digital</ContentTitle>
                    </ContentTitleRow>
                    <ContentCard alignCenter>
                      <Column gap='8px'>
                        <div>
                          <Image src={checklist.signatureUserChecklist}/>
                        </div>
                        <Column>
                          <InfoText>{checklist.signatureUserNameChecklist}</InfoText>
                          <InfoText>{formatDateTime(checklist.signatureMomentChecklist)}</InfoText>
                        </Column>
                      </Column>
                    </ContentCard>
                  </Column>
                )}
              </>
            )}
            {visibleEmailModal && (
              <EmailModal
                visible={visibleEmailModal}
                onCancel={() => setVisibleEmailModal(false)}
                checklist={checklist}
                idChecklist={idChecklist}
              />
            )}
            {visibleNotifications && <Notifications onClose={handleCloseNotificationMetaModal} notificationMetas={notificationMetas}/>}
          </Container>
        </PageHeader>
      </Body>
    </App>
  );
}