import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { 
  getConfigurationVoip as getConfigurationVoipSdk, 
  postConfigurationVoip as postConfigurationVoipSdk
} from '@sdk/Voip';

//Action Types
export const Types = {
  GET_CONFIGURATION_VOIP: 'CONFIGURACOES_VOIP/GET_CONFIGURATION_VOIP',
  GET_CONFIGURATION_VOIP_SUCCESS: 'CONFIGURACOES_VOIP/GET_CONFIGURATION_VOIP_SUCCESS',
  GET_CONFIGURATION_VOIP_ERROR: 'CONFIGURACOES_VOIP/GET_CONFIGURATION_VOIP_ERROR',

  POST_CONFIGURATION_VOIP: 'CONFIGURACOES_VOIP/POST_CONFIGURATION_VOIP',
  POST_CONFIGURATION_VOIP_SUCCESS: 'CONFIGURACOES_VOIP/POST_CONFIGURATION_VOIP_SUCCESS',
  POST_CONFIGURATION_VOIP_ERROR: 'CONFIGURACOES_VOIP/POST_CONFIGURATION_VOIP_ERROR',

  RESET: 'CONFIGURACOES_VOIP/RESET',
};

//Action Creators
export const getConfigurationVoip = () => ({ type: Types.GET_CONFIGURATION_VOIP });
export const getConfigurationVoipSuccess = (configuration) => ({ type: Types.GET_CONFIGURATION_VOIP_SUCCESS, configuration });
export const getConfigurationVoipError = (error) => ({ type: Types.GET_CONFIGURATION_VOIP_ERROR, error });

export const postConfigurationVoip = (config) => ({ type: Types.POST_CONFIGURATION_VOIP, config });
export const postConfigurationVoipSuccess = (data) => ({ type: Types.POST_CONFIGURATION_VOIP_SUCCESS, data });
export const postConfigurationVoipError = (error) => ({ type: Types.POST_CONFIGURATION_VOIP_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetConfigurationVoip(action) {
  try {
    const configs = yield call(getConfigurationVoipSdk);
    yield put(getConfigurationVoipSuccess(configs));
  } catch (err) {
    Notification.error(err.message);
    yield put(getConfigurationVoipError(err));
  }
}

function* fetchPostConfigurationVoip(action) {
  try {
    const { config } = action;
    const resp = yield call(postConfigurationVoipSdk, config);
    yield put(postConfigurationVoipSuccess(resp));
    Notification.success('Configuração salva com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(postConfigurationVoipError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CONFIGURATION_VOIP, fetchGetConfigurationVoip), 
  takeLatest(Types.POST_CONFIGURATION_VOIP, fetchPostConfigurationVoip), 
];

// Reducer
const initialState = Map({
  configurationVoip: Map(),
  loadingConfigurationVoip: false,
  successConfigurationVoip: false,
  errorConfigurationVoip: false,

  loadingSaveConfigurationVoip: false,
  successSaveConfigurationVoip: false,
  errorSaveConfigurationVoip: false,
});

const handleGetConfigurationVoip = (state, action) => {
  return state.set('loadingConfigurationVoip', true).set('successConfigurationVoip', false).set('errorConfigurationVoip', false);
};

const handleGetConfigurationVoipSuccess = (state, action) => {
  const { configuration } = action;

  return state.set('configurationVoip', configuration).set('loadingConfigurationVoip', false).set('successConfigurationVoip', true).set('errorConfigurationVoip', false);
};

const handleGetConfigurationVoipError = (state, action) => {
  return state.set('loadingConfigurationVoip', false).set('successConfigurationVoip', false).set('errorConfigurationVoip', action.error);
};

const handlePostConfigurationVoip = (state, action) => {
  return state.set('loadingSaveConfigurationVoip', true).set('successSaveConfigurationVoip', false).set('errorSaveConfigurationVoip', false);
};

const handlePostConfigurationVoipSuccess = (state, action) => {
  return state.set('loadingSaveConfigurationVoip', false).set('successSaveConfigurationVoip', true).set('errorSaveConfigurationVoip', false);
};

const handlePostConfigurationVoipError = (state, action) => {
  return state.set('loadingSaveConfigurationVoip', false).set('successSaveConfigurationVoip', false).set('errorSaveConfigurationVoip', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONFIGURATION_VOIP:
      return handleGetConfigurationVoip(state, action);
    case Types.GET_CONFIGURATION_VOIP_SUCCESS:
      return handleGetConfigurationVoipSuccess(state, action);
    case Types.GET_CONFIGURATION_VOIP_ERROR:
      return handleGetConfigurationVoipError(state, action);

    case Types.POST_CONFIGURATION_VOIP:
      return handlePostConfigurationVoip(state, action);
    case Types.POST_CONFIGURATION_VOIP_SUCCESS:
      return handlePostConfigurationVoipSuccess(state, action);
    case Types.POST_CONFIGURATION_VOIP_ERROR:
      return handlePostConfigurationVoipError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
