import React from 'react';
import { FeatherIcon, IconWrapper, IconsContainer, InputWrapper } from './styles';

const StyledInputNumber = ({ value, onChange, precision, prefix, suffix, step = 1, ...props }) => {

  const handleChange = (incomingValue, sumOrSubtraction) => {
    if(isNaN(incomingValue) && typeof incomingValue !== 'string') {
      if(!incomingValue) return 0;
      return sumOrSubtraction === 'sum' ? 0 + step : 0 - step;
    }
    const stringValue = parseFloat(String(incomingValue || '').replaceAll(',', '.') || '0').toFixed(precision);
    return precision ? stringValue : parseFloat(stringValue);
  };

  return (
    <InputWrapper disabled={props.disabled}>
      <IconsContainer>
        <IconWrapper>
          <FeatherIcon type='chevron-up' size='1rem' onClick={() => onChange(handleChange(String(parseFloat(String(value)?.replace(',', '.')) + step), 'sum'))} />
        </IconWrapper>
        <IconWrapper>
          <FeatherIcon type='chevron-down' size='1rem' onClick={() => onChange(handleChange(String(parseFloat(String(value)?.replace(',', '.')) - step), 'subtraction'))} />
        </IconWrapper>
      </IconsContainer>
      <input
        type={prefix || suffix || precision ? 'text' : 'number'}
        value={`${prefix || ''}${String((value) || '')?.replaceAll('.', ',') || ''}${suffix || ''}`}
        onChange={(evt) => onChange((prefix || suffix) ? evt.target.value?.replace(prefix || suffix, '').replace(/[a-zA-Z]/g, '') : evt.target.value)}
        onBlur={() => onChange(handleChange(value))}
        {...props}
      />
    </InputWrapper>
  );
};

export default StyledInputNumber;