import styled, { css } from 'styled-components';
import { Table, Text, View } from '@components-teammove';

export const TableItens = styled(Table)`
  cursor: pointer;

  ${(props) =>
  props.kind === 'expired' &&
    css`
      opacity: 0.8;
    `}
    &.ant-table {
      .ant-table-content {
        tbody {
          > tr {
            td {
              padding: 8px 8px 8px 14px;
            }
          }
        }
      }
    }
`;

export const Expired = styled(Text)`
  width: 65px;
  font-size: 12px;
  padding: 1px 5px 1px 5px;
  background: #DD2C00;
  color: white !important;
  border-radius: 5px;
`;

export const Name = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const ContentUserRanking = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const PositionUserRanking = styled(Text)`
  &.ant-typography {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const PositionUserRankingHighlighted = styled(Text)`
  background-color: var(--success-color) !important;
  color: ${({ theme }) => theme.textColor} !important;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
`;

export const Prize = styled(Text)`
  &.ant-typography {
    margin-top: 5px;
    color: var(--success-color) !important;
  }
`;

export const ContentImage = styled(View)`
  width: 90px;
  height: 90px;
  margin: 0px;
  justify-content: center;
  align-items: center;
`;

export const ContainerCampaign = styled(View)`
  flex-direction: row;
  align-items: center;
`;

export const ContentName = styled(View)`
  flex: 1;
`;

export const ContentProgress = styled(View)`
  align-items: center;
`;

export const BoldTableText = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const RegularTableText = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
  }
`;