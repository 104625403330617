import styled from 'styled-components';
import { Column, Button, Row, Text, Form, InputNumber } from '@components-teammove';

export const ContentPanel = styled(Column)`
  width: 100%;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const DateControllerHeaderStyled = styled(Column)`
  padding: 17px !important;
`;

export const BodySearch = styled(Column)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 12px;
  border-left: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  border-right: 2px solid ${({ theme }) => theme.darkPrimaryColor};
`;

export const StyleTable = styled(Column)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-top: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  border-left: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  overflow-x: auto;
`;

export const StyleRow = styled(Row)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  width: 100%;
  ${({ noActivity, theme }) => noActivity && `
    border-bottom: 2px solid ${theme.darkPrimaryColor};
    border-right: 2px solid ${theme.darkPrimaryColor};
  `}
`;

export const CellTable = styled(Column)`
  max-width: ${({ empty }) => !empty && '290px' };
  min-width: ${({ empty }) => !empty && '290px' };
  padding: 14px;
  padding-left: '14px';
  width: ${({ width })=> width || '100%'};
  background-color: ${({ theme, color }) => color || theme.darkSecondaryColor};
  
  border-right: ${({ theme, color }) => `2px solid ${color ? theme.darkSecondaryColor : theme.darkPrimaryColor}`};
  border-bottom: 2px solid ${({ theme, color }) => color ? theme.darkSecondaryColor : theme.darkPrimaryColor};
  
  :hover {
    background-color: ${({ theme, color }) => color || theme.darkSecondaryColor};
    cursor: ${({ hover })=> hover ? 'pointer' : 'default'};
    font-weight: ${({ hover })=> hover ? '700' : 'default'} !important;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 6px 12px !important;
  margin-right: 11px;
`;

export const RowTitleTable = styled(Row)`
  flex: 1;
`;

export const VerticalDivider = styled.div`
  border-left: 2px solid ${({ color })=> color || '#D9D9D9' };
  height: 44px;
`;

export const ViewConatiner = styled(Column) `
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const TextModal = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const FormItemContainer = styled(Form.Item)`
  margin-bottom: 0 !important;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputNumberStyled = styled(InputNumber)`
  text-align: right;
  padding-right: 14px;
`;

export const ContainerIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ colorBg }) => colorBg};
  padding: 6px;
`;