import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Button, Form, Input, View } from '@components-teammove';
import { InfoUsuario } from '@utils';
import { login, reset } from '@ducks/login';
import { setTheme } from '@ducks/app';
import { ButtonItem, Content, FormContent, GlobalStyles, LoginCard, LogoImage, AtentionCapsLock } from './styles';

export default function Login({ history }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useSelector(({ app }) => app.get('theme'));
  const loading = useSelector(({ login }) => login.get('loading'));
  const success = useSelector(({ login }) => login.get('success'));
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const checkCapsLock = (event) => {
    if(event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };
  
  useEffect(() => {
   
    if (InfoUsuario.hasUser()) {
      history.replace('/');
    }
    return () => dispatch(reset());
  }, []);
  
  useEffect(() => {
    if (success) {
      dispatch(setTheme(InfoUsuario.get('theme')));
      if (location.state && location.state.pathname) {
        history.replace(location.state.pathname, location.state);
      } else {
        history.replace('/');
      }
    }
  }, [success]);

  const handleLogin = ({ username, password }) => {
    dispatch(login(username, password));
  };

  return (
    <App bgColor={theme['@global-background-color']} hideSider hideHeader>
      <GlobalStyles/>
      <Content>
        <LoginCard>
          <LogoImage alt="TeamMove" src={theme['@global-url-logo'] || process.env.PUBLIC_URL + '/images/logo.png'} />
          <Form form={form} hideRequiredMark layout='vertical' onFinish={handleLogin}>
            <FormContent>
              <View>
                <Form.Item label='Usuário' name='username' rules={[{ required: true, message: 'Informe o usuário!' }]}>
                  <Input autoFocus placeholder='Endereço de e-mail ou nome de usuário'/>
                </Form.Item>

                <Form.Item label='Senha' name='password'  rules={[{ required: true, message: 'Informe a senha!' }]}>
                  <Input.Password 
                    placeholder='Senha de acesso'
                    onKeyUp={checkCapsLock}
                  />
                
                </Form.Item>
                {isCapsLockOn && <AtentionCapsLock>Atenção: CapsLock Ativado</AtentionCapsLock>}
              </View>

              <ButtonItem marginless>
                <Button type='primary' htmlType='submit' block loading={loading} fillWidth>
                  Entrar
                </Button>
                <Button type='link' onClick={() => history.push('/recuperar-senha', { username: form.getFieldValue('username') })}>
                  Esqueci minha senha
                </Button>
              </ButtonItem>
            </FormContent>
          </Form>
        </LoginCard>
      </Content>
    </App>
  );
}
