import styled from 'styled-components';
import { Select, Row, Empty } from '@components-teammove';

export const ContentCategoriesGraphic = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 18px;
`;

export const SelectPeriod = styled(Select)`
  background-color: ${(props) => props.theme.darkSecondaryColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
	width: 200px !important;
`;

export const RowStyle = styled(Row)`
  width: 100%;
`;

export const ContentGraphic = styled.div`
  height: ${({ heightGraphic })=> heightGraphic ? `${heightGraphic}px` : 'auto'};
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;