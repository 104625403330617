import styled from 'styled-components';
import { Button, Row } from '@components-teammove';

export const ButtonSmall = styled(Button)`
  &.ant-btn {
    width: 34px !important;
    height: 34px !important;
    min-width: 34px !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    border-radius: 50%;
  }
`;

export const StyledRow = styled(Row)`
  opacity: ${({ active }) => active ? 1 : 0.5};
`;