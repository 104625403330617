import React from 'react';
import { StyledList } from './styles';
import { Skeleton } from '@components-teammove';

const List = ({ loading, children, ...props }) => loading ? (
  <Skeleton.LargeBox/>
) : (
  <StyledList {...props}>
    {children}
  </StyledList>
);

List.Item = ({ children, ...props }) => (
  <StyledList.Item {...props}>
    {children}
  </StyledList.Item>
);

export default List;