import React, { useEffect, useMemo, useState } from 'react';
import { Modal, View, Avatar } from '@components-teammove';
import { formaDateToNow } from '@utils';
import { getConfirmacoesLeitura } from '@sdk/Wikis';
import { CaminhoCategoriaView, CaminhoView, CategoriaView, CheckIcon, Foto, Nome, Perfil, UsuarioContainer, ViewAcessoDados, ViewAcessos, ViewDadoAcesso, ViewResumo, ViewResumoAcessados, ViewResumoPercentual, ViewResumoTitulo, ViewResumoTotalAcesso, ViewResumoValor, ViewUsuario } from './styles';

export default function ConfirmacoesLeitura({ visible, onCancel, id, ds }) {
  const categoriaSplit = useMemo(() => ds.split(' / '), [ds]);
  const categoria = useMemo(() => categoriaSplit.pop(), []);
  const caminho = useMemo(() => categoriaSplit.join(' / '), []);

  const [confirmacoesLeitura, setConfirmacoesLeitura] = useState([]);

  useEffect(() => {
    (async () => {
      const confirmations = await getConfirmacoesLeitura(id);
      setConfirmacoesLeitura(confirmations);
    })();
  }, [id]);

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      title={(
        <CaminhoCategoriaView>
          <CategoriaView>{categoria}</CategoriaView>
          <CaminhoView>{caminho}</CaminhoView>
        </CaminhoCategoriaView>
      )}
    >
      <View>
        <ViewResumo>
          <ViewResumoTotalAcesso>
            <ViewResumoTitulo>Deveriam Acessar</ViewResumoTitulo>
            <ViewResumoValor>{confirmacoesLeitura?.length} </ViewResumoValor>
          </ViewResumoTotalAcesso>
          <ViewResumoAcessados>
            <ViewResumoTitulo>Acessaram</ViewResumoTitulo>
            <ViewResumoValor>{confirmacoesLeitura?.filter((item) => item.aceito).length}</ViewResumoValor>
            <ViewResumoPercentual>
              {confirmacoesLeitura?.length > 0
                ? ((confirmacoesLeitura?.filter((item) => item.aceito).length / confirmacoesLeitura?.length) * 100).toFixed(2)
                : '0.00'}
              %
            </ViewResumoPercentual>
          </ViewResumoAcessados>
        </ViewResumo>
        <View>
          {confirmacoesLeitura?.map((item) => (
            <ViewAcessos key={item.cdUsuario}>
              <ViewUsuario acessado={item.aceito}>
                <Foto>
                  <Avatar size='large' src={item.avatarURL} />
                </Foto>
                <View>
                  <UsuarioContainer bordercolor={item.corPerfil}>
                    <Nome>{item.nmUsuario}</Nome>
                    <Perfil>{item.descricaoPerfil}</Perfil>
                  </UsuarioContainer>
                </View>
              </ViewUsuario>
              {item.aceito && (
                <ViewDadoAcesso>
                  <ViewAcessoDados>
                    <CheckIcon type='check-all'/>
                    {formaDateToNow(item.aceitoData, null, true)}
                  </ViewAcessoDados>
                </ViewDadoAcesso>
              )}
            </ViewAcessos>
          ))}
        </View>
      </View>
    </Modal>
  );
}
