import styled from 'styled-components';
import { Icon, Row, Column, Select, Empty } from '@components-teammove';

import { lighten } from 'polished';

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme })=> theme.textColor};
`;

export const ContentPanel = styled(Column)`
  width: 100%;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 17px;
`;

export const BodySearch = styled(Column)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 12px;
  border-left: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  border-right: 2px solid ${({ theme }) => theme.darkPrimaryColor};
`;

export const SelectStyle = styled(Select)`
	border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
	width: 279px !important;
`;

export const StyleTable = styled(Column)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-top: 2px solid ${({ theme }) => theme.darkPrimaryColor};
  border-left: 2px solid ${({ theme }) => theme.darkPrimaryColor};
`;

export const CellTable = styled(Column)`
  max-width: 332px;
  min-width: ${({ cellType })=> cellType === 'total' ? '315px' : ''};
  padding: 14px;
  padding-left: ${({ cellType })=> cellType === 'total' ? '14px' : cellType === 'group' ? '32px' : '14px' };
  width: ${({ width })=> width || '100%'};
  background-color: ${({ theme, color, cellType }) => color  || (cellType === 'itemGroup' && theme.backgroundColor) || theme.darkSecondaryColor};
  
  border-right: ${({ theme, color, cellType }) =>
  cellType === 'group' || cellType === 'itemGroup' ? 'none' : `2px solid ${color ? theme.darkSecondaryColor : theme.darkPrimaryColor}`};
  border-bottom: 2px solid ${({ theme, color }) => color ? theme.darkSecondaryColor : theme.darkPrimaryColor};
  
  :hover {
    background-color: ${({ theme, cellType, hover, color }) => color || (hover && cellType === 'itemGroup' && lighten(0.085, theme.backgroundColor)) || (hover && cellType === 'group' && lighten(0.085, theme.backgroundColor)) || theme.darkSecondaryColor};
    cursor: ${({ hover })=> hover ? 'pointer' : 'default'};
    font-weight: ${({ hover })=> hover ? '700' : 'default'} !important;
  }
`;

export const StyleRow = styled(Row)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  width: 100%;
`;

export const VerticalDivider = styled.div`
  border-left: 2px solid ${({ color })=> color || '#D9D9D9' };
  height: 44px;
`;

export const ButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  // height: 34px;
  padding: 6px;
  color: ${({ theme })=> theme.textColor};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 2px solid;
  border-color: ${({ active, theme })=> active ? 'var(--super-success-color)' : theme.darkPrimaryColor };
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }

`;

export const ColumnStyle = styled(Column)`
  white-space: nowrap;
  overflow: hidden;
`;

export const EmptyStyle = styled(Empty)`
  color: ${({ theme }) => theme.textColor} !important;
  padding: 12px;
`;