import React from 'react';
import { DarkForm } from './styles';
import { FormItem } from '@components-teammove';

const StyledForm = ({ footer, children, ...props }) => (
  <DarkForm {...props}>
    {children}
  </DarkForm>
);

StyledForm.useForm = DarkForm.useForm;
StyledForm.Provider = DarkForm.Provider;

StyledForm.scrollToFirstError = ({ errorFields }) => {
  const element = document.querySelector(`[id="${errorFields[0].name[0]}"]`) || document.querySelector('.ant-form-item-has-error');
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
};
StyledForm.Item = ({ children, ...props }) => <FormItem {...props}>{children}</FormItem>;

export default StyledForm;