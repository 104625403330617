import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import {
  getActivityCheckInOut as getActivityCheckInOutSdk,
  postCheckInOut as postCheckInOutSdk, 
} from '@sdk/AtividadesCheckInOut';

export const FILTERS_KEY = 'ACITIVITIES_CHECK_IN_OUT';

//Action Types
export const Types = {
  GET_CHECK_IN_OUT: 'ATIVIDADES/GET_CHECK_IN_OUT',
  GET_CHECK_IN_OUT_SUCCESS: 'ATIVIDADES/GET_CHECK_IN_OUT_SUCCESS',
  GET_CHECK_IN_OUT_ERROR: 'ATIVIDADES/GET_CHECK_IN_OUT_ERROR',

  POST_CHECK_IN_OUT: 'ATIVIDADES/POST_CHECK_IN_OUT',
  POST_CHECK_IN_OUT_SUCCESS: 'ATIVIDADES/POST_CHECK_IN_OUT_SUCCESS',
  POST_CHECK_IN_OUT_ERROR: 'ATIVIDADES/POST_CHECK_IN_OUT_ERROR',
};

//Action Creators
export const getCheckInOut = () => ({ type: Types.GET_CHECK_IN_OUT });
export const getCheckInOutSuccess = (response) => ({ type: Types.GET_CHECK_IN_OUT_SUCCESS, response });
export const getCheckInOutError = (error) => ({ type: Types.GET_CHECK_IN_OUT_ERROR, error });

export const postCheckInOut = (isCheckInOut, idActivityTemp, body, isCheckoutAutomatic) => ({ type: Types.POST_CHECK_IN_OUT, isCheckInOut, idActivityTemp, body, isCheckoutAutomatic });
export const postCheckInOutSuccess = (resp) => ({ type: Types.POST_CHECK_IN_OUT_SUCCESS, resp });
export const postCheckInOutError = (error) => ({ type: Types.POST_CHECK_IN_OUT_ERROR, error });
//saga
function* fetchGetCheckInOut(action) {
  try {
    const response = yield call(getActivityCheckInOutSdk);
    yield put(getCheckInOutSuccess(response));
    
  } catch (err) {
    // Notification.error(err.message);
    yield put(getCheckInOutError(err));
  }
}

function* fetchPostCheckInOut(action) {
  try {
    let { isCheckInOut, idActivityTemp, body, isCheckoutAutomatic } = action;

    const response = yield call(postCheckInOutSdk, isCheckInOut, idActivityTemp, body);
    
    yield put(postCheckInOutSuccess(response));
    if(isCheckoutAutomatic) {
      Notification.success('Sua jornada chegou ao fim! O checkout automático foi concluído com sucesso.');
    }
  } catch (err) {
    Notification.error(err.message);
    yield put(postCheckInOutError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECK_IN_OUT, fetchGetCheckInOut),
  takeLatest(Types.POST_CHECK_IN_OUT, fetchPostCheckInOut),
];

// Reducer
const initialState = Map({
  activityCheckInOut: Map(),
  loadingActivityCheckInOut: false,
  successActivityCheckInOut: false,
  errorActivityCheckInOut: false,

  loadingCheckInOut: false,
  successCheckInOut: false,
  errorCheckInOut: false,
});

const handleGetCheckInOut = (state, action) => {
  return state.set('loadingActivityCheckInOut', true).set('successActivityCheckInOut', false).set('errorActivityCheckInOut', false);
};

const handleGetCheckInOutSuccess = (state, action) => {
  const { response } = action;
  
  return state.set('activityCheckInOut', response).set('loadingActivityCheckInOut', false).set('successActivityCheckInOut', true).set('errorActivityCheckInOut', false);
};

const handleGetCheckInOutError = (state, action) => {
  return state.set('loadingActivityCheckInOut', false).set('successActivityCheckInOut', false).set('errorActivityCheckInOut', action.error);
};

const handlePostCheckInOut = (state, action) => {
  return state.set('loadingCheckInOut', true).set('successCheckInOut', false).set('errorCheckInOut', false);
};

const handlePostCheckInOutSuccess = (state, action) => {
  return state.set('loadingCheckInOut', false).set('successCheckInOut', true).set('errorCheckInOut', false);
};

const handlePostCheckInOutError = (state, action) => {
  return state.set('loadingCheckInOut', false).set('successCheckInOut', false).set('errorCheckInOut', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECK_IN_OUT:
      return handleGetCheckInOut(state, action);
    case Types.GET_CHECK_IN_OUT_SUCCESS:
      return handleGetCheckInOutSuccess(state, action);
    case Types.GET_CHECK_IN_OUT_ERROR:
      return handleGetCheckInOutError(state, action);

    case Types.POST_CHECK_IN_OUT:
      return handlePostCheckInOut(state, action);
    case Types.POST_CHECK_IN_OUT_SUCCESS:
      return handlePostCheckInOutSuccess(state, action);
    case Types.POST_CHECK_IN_OUT_ERROR:
      return handlePostCheckInOutError(state, action);

    default:
      return state;
  }
}