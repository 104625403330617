import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { Icon, Button, Form, Input, Select, View, Text, Image } from '@components-teammove';

import { DropdownStyles } from '@components-teammove/Select/styles';

const DEFAULT_THEME = {
  FOREGROUND_COLOR: '#38344b',
  BACKGROUND_COLOR: '#2a2837',
  DARK_SECONDARY_COLOR: '#38344b',
  DARK_PRIMARY_COLOR: '#4c4766',
  DARK_HIGHLIGHT_COLOR : '#736b99',
  ACTION_COLOR: '#1890ff',
  TEXT_COLOR: '#f5f5f5',
  TEXT_CONTRAST_COLOR: '#f5f5f5',
  TEXT_FOREGROUND_COLOR: '#f5f5f5'
};

export const Container = styled(View)`
  flex-direction: column;
  flex: 1;
`;

export const Header = styled(View)`
  background: ${(props) => props?.themePreview?.foregroundColor || DEFAULT_THEME.FOREGROUND_COLOR};
  color: ${(props) => props?.themePreview?.textContrastColor || DEFAULT_THEME.TEXT_CONTRAST_COLOR};
  flex-direction: row;
  height: 64px;
  padding: 0 25px;
  line-height: 64px;
  margin-top: 15px;
`;

export const ContentImageHeader = styled(View)`
  flex: 1;
  justify-content: center;
`;

export const ContentInfoHeader = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const UserNameHeader = styled(Text)`
  font-size: 16px;
  line-height: 64px;
  color: ${(props) => props?.themePreview?.textForegroundColor || DEFAULT_THEME.TEXT_FOREGROUND_COLOR} !important;
`;

export const UserPhotoHeader = styled(Image)`
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
`;

export const IconDropDown = styled(Icon.DownOutlined)`
  color: ${(props) => props?.themePreview?.textForegroundColor || DEFAULT_THEME.TEXT_FOREGROUND_COLOR} !important;
`;

export const ContentBody = styled(View)`
  flex-direction: row;
`;

export const ContentMenu = styled(View)`
  flex: 0 0 200px;
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  background: ${(props) => props?.themePreview?.darkSecondaryColor || DEFAULT_THEME.DARK_SECONDARY_COLOR};
  color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR};
`;

export const MenuItem = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1rem !important;
  padding: 6px 24px;
  margin: 4px 0px;
  cursor: pointer;
`;

export const ContainerPreview = styled(View)`
  flex-direction: row;
  flex: 1;
  background: ${(props) => props?.themePreview?.backgroundColor || DEFAULT_THEME.BACKGROUND_COLOR} !important;
  padding: 40px 20px;
`;

export const ContainerPreviewLogin = styled(View)`
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
`;

export const ContentImage = styled(View)`
  width: 15rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Logo = styled(Image)`
  width: 160px;
  max-height: 48px;
  object-fit: contain;
  user-select: none;
`;

export const CardContainer = styled(View)`
  border-radius: 18px;
  padding: 25px;
  min-width: 400px;
  background: ${(props) => props?.themePreview?.darkSecondaryColor || DEFAULT_THEME.DARK_SECONDARY_COLOR}
`;

export const FormItemInput = styled(Form.Item)`
  .ant-form-item-label > label {
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR};
  }
`;

export const ItemInput = styled(Input)`
  background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
  color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
  border: 1px solid ${(props) => props?.themePreview?.darkHighlightColor || DEFAULT_THEME.DARK_HIGHLIGHT_COLOR} !important;
  input {
    background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
    ::placeholder {
      color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
    }
  }
  :focus-within {
    border: 2px solid ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
  }
`;

export const ItemPasswordInput = styled(Input.Password)`
    background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
    border: 1px solid ${(props) => props?.themePreview?.darkHighlightColor || DEFAULT_THEME.DARK_HIGHLIGHT_COLOR} !important;

    input {
      background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
      color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
      ::placeholder {
        color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
      }
    }
    :focus-within {
      border: 2px solid ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
    }
`;

export const ItemTextAreaInput = styled(Input.TextArea)`
  &.ant-input {
    background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
    border: 1px solid ${(props) => props?.themePreview?.darkHighlightColor || DEFAULT_THEME.DARK_HIGHLIGHT_COLOR} !important;
    :focus-within {
      border: 2px solid ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
    }
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
      ::placeholder {
      color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
    }
  }
`;

export const SelectItem = styled(Select)`
  background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
  border: 1px solid ${(props) => props?.themePreview?.darkHighlightColor || DEFAULT_THEME.DARK_HIGHLIGHT_COLOR} !important;

  :focus-within {
    border: 2px solid ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
  }

  input.ant-select-selection-search-input {
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
  }

  div > span {
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
  }
`;

export const PrimaryButton = styled(Button)`
  width: 100% !important;
  border-radius: 12px;
  margin-top: 5px;
  ${(props) => props?.themePreview?.actionColor && `
    &.ant-btn {
      background-color: ${props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
      :focus, :hover {
        background-color: ${lighten(0.085, props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR)} !important;
      }
      :active {
        background-color: ${darken(0.085, props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR)} !important;
      }
    }
  `}
  ${(props) => props?.themePreview?.textContrastColor && `
    span {
      color: ${props?.themePreview?.textContrastColor || DEFAULT_THEME.TEXT_CONTRAST_COLOR} !important;
    }
  `}
`;

export const SecondaryButton = styled(Button)`
  width: 100% !important;
  border-radius: 12px;
  margin-top: 5px;
  ${(props) => props?.themePreview?.darkPrimaryColor && `
    &.ant-btn {
      background-color: ${props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
      :focus, :hover {
        background-color: ${lighten(0.085, props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR)} !important;
      }
      :active {
        background-color: ${darken(0.085, props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR)} !important;
      }
    }
  `}
  ${(props) => props?.themePreview?.textColor && `
    span {
      color: ${props?.themePreview?.textColor || DEFAULT_THEME.TEXT_CONTRAST_COLOR} !important;
    }
  `}
`;

export const RecoverPassword = styled(Text)`
  display: flex;
  text-decoration-line: underline;
  margin-top: 10px;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
`;

export const UploadContent = styled(View)`
  flex: 1;
  height: 115px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
  border: 1px dashed ${(props) => props?.themePreview?.darkHighlightColor || DEFAULT_THEME.DARK_HIGHLIGHT_COLOR} !important;
`;

export const UploadLabel = styled(Text)`
  opacity: 0.6;
  color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
`;

export const UploadIcon = styled(Icon.Feather)`
  color: ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR};
  
  * {
    color: ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR};
  }
`;

export const PreviewModalContainer = styled(View)`
  flex: 1;
`;

export const ModalContainer = styled(View)`
  border-radius: 28px;
  width: 670px;
  height: 800px;
  padding: 30px 25px;
  background: ${(props) =>  props?.themePreview?.darkSecondaryColor || DEFAULT_THEME.DARK_SECONDARY_COLOR};
`;

export const ModalHeader = styled(View)`
  flex-direction: row;
`;

export const ModalTitle = styled(Text)`
  flex: 1;
  font-weight: 700;
  font-size: 32px;
  color: ${(props) => props.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR} !important;
`;

export const ModalBody = styled(View)`
  padding: 10px 0px;
`;

export const ModalFooter = styled(View)`
  flex-direction: row;
  gap: 10px;
`;

export const DropdownItem = styled(DropdownStyles)`
  div.ant-select-item-option {
    background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
  }

  div.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
    * {
      color: ${(props) => props?.themePreview?.textContrastColor || DEFAULT_THEME.TEXT_CONTRAST_COLOR};
    }
  }

  div.ant-select-item-option-selected {
    background-color: ${(props) => props?.themePreview?.actionColor || DEFAULT_THEME.ACTION_COLOR} !important;
    * {
      color: ${(props) => props?.themePreview?.textContrastColor || DEFAULT_THEME.TEXT_CONTRAST_COLOR};
    }
  }

  div.ant-select-item-empty {
    background-color: ${(props) => props?.themePreview?.darkPrimaryColor || DEFAULT_THEME.DARK_PRIMARY_COLOR} !important;
  }

  * {
    color: ${(props) => props?.themePreview?.textColor || DEFAULT_THEME.TEXT_COLOR};
  }
`;