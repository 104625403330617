import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row } from '@components-teammove';
import { getAtributos } from '@ducks/configuracoesUsuarios';

const Atributos = ({ handleEditClick }) => {
  const dispatch = useDispatch();

  const atributos = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('atributos').toArray());
  const loadingAtributos = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('loadingAtributos'));
  const successSave = useSelector(({ configuracoesUsuariosAtributo }) => configuracoesUsuariosAtributo.get('success'));

  useEffect(() => {
    dispatch(getAtributos());
  }, []);

  useEffect(() => {
    if (successSave) dispatch(getAtributos());
  }, [successSave]);

  const columns = [
    {
      title: 'Atributo',
      dataIndex: 'atributo',
      sorter: true,
      render: (text, atributo) => (
        <Row justify='space-between' align='center'>
          {text}
          <Button type='secondary' size='small' context='list'>
            Editar
          </Button>
        </Row>
      ),
    },
  ];

  return(
    <Table
      columns={columns}
      dataSource={atributos}
      loading={loadingAtributos}
      rowKey="id"
      status="ativo"
      onRow={(atributo) => ({ onClick: () => handleEditClick(atributo) })}
    />
  );
};

export default Atributos;
