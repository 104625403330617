import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '@ducks/login';

export default function Logout({ history }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    history.replace('/');
  });

  return null;
}
