import { stateOptions } from '../../utils/Geography';
import { TelasNomenclaturas } from '@utils';

import { getClustersByGroup } from '@ducks/gestaoCarteira';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const customFields = {
  CNPJ: 'cnpj',
  IE: 'stateRegistration',
  RAZAO_SOCIAL: 'fullName',
  TELEFONE_1: 'telephone',
  TELEFONE_2: 'telephoneSecond',
  CELULAR_1: 'cell',
  CELULAR_2: 'cellSecond',
  LICENCIADO: 'licensed',
  LOCALIZACAO: 'location',
  PROJETO: 'typeProject',
  SEGMENTO: 'segment',
  INAUGURACAO: 'dtOpening',
  MEDIDAS_VITRINE: 'showcaseMeasures',
  METRAGEM: 'totalFootage',
  PARTICIPACAO: 'participation',
  SISTEMA: 'system',
};

export const customFieldsWithList = [
  'SISTEMA', 'LOCALIZACAO', 'PROJETO', 'SEGMENTO'
];

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Agrupador',
    name: 'agrupador',
    placeholder: 'Selecione os agrupadores',
    options: []
  }, 
  {
    type: 'SELECT',
    label: 'Estado',
    name: 'state',
    placeholder: 'Selecione os Estados',
    options: stateOptions
  },
  {
    type: 'SELECT',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'tipoUnidade',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.nomenclatura}`,
    options: []
  },
  {
    type: 'SELECT',
    label: 'Grupo',
    name: 'grupoCluster',
    placeholder:'Selecione um grupo de cluster',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Cluster',
    name: 'cluster',
    placeholder:'Selecione os clusters',
    options: [],
    dependsOn:'grupoCluster',
    request: getClustersByGroup,
    hasParam: true,
  },
];