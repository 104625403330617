import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getProductsByCompany as getProductsSdk,
  getProductRegister as getProductRegisterSdk,
  getDetailedProduct as getDetailedProductSdk,
  getExtraFields as getExtraFieldsSdk,
  getCategoriesFamilies as getCategoriesFamiliesSdk,
  postProductRegister as postProductRegisterSdk,
  putProductRegister as putProductRegisterSdk,
  changeOrdemCategoriesProducts as changeOrdemCategoriesProdutoSdk,
  changeOrdemCategoriesFamilies as changeOrdemCategoriesFamiliesSdk
} from '@sdk/Produtos';

//Action Types
export const Types = {
  GET_PRODUCTS: 'PRODUCTS/GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'PRODUCTS/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_ERROR: 'PRODUCTS/GET_PRODUCTS_ERROR',

  GET_PRODUCT_REGISTER: 'PRODUCTS/GET_PRODUCT_REGISTER',
  GET_PRODUCT_REGISTER_SUCCESS: 'PRODUCTS/GET_PRODUCT_REGISTER_SUCCESS',
  GET_PRODUCT_REGISTER_ERROR: 'PRODUCTS/GET_PRODUCT_REGISTER_ERROR',

  GET_DETAILED_PRODUCT: 'PRODUCTS/GET_DETAILED_PRODUCT',
  GET_DETAILED_PRODUCT_SUCCESS: 'PRODUCTS/GET_DETAILED_PRODUCT_SUCCESS',
  GET_DETAILED_PRODUCT_ERROR: 'PRODUCTS/GET_DETAILED_PRODUCT_ERROR',

  GET_EXTRA_FIELDS: 'PRODUCTS/GET_EXTRA_FIELDS',
  GET_EXTRA_FIELDS_SUCCESS: 'PRODUCTS/GET_EXTRA_FIELDS_SUCCESS',
  GET_EXTRA_FIELDS_ERROR: 'PRODUCTS/GET_EXTRA_FIELDS_ERROR',

  GET_CATEGORIES_FAMILIES: 'PRODUCTS/GET_CATEGORIES_FAMILIES',
  GET_CATEGORIES_FAMILIES_SUCCESS: 'PRODUCTS/GET_CATEGORIES_FAMILIES_SUCCESS',
  GET_CATEGORIES_FAMILIES_ERROR: 'PRODUCTS/GET_CATEGORIES_FAMILIES_ERROR',

  POST_PRODUCT_REGISTER: 'PRODUCTS/POST_PRODUCT_REGISTER',
  POST_PRODUCT_REGISTER_SUCCESS: 'PRODUCTS/POST_PRODUCT_REGISTER_SUCCESS',
  POST_PRODUCT_REGISTER_ERROR: 'PRODUCTS/POST_PRODUCT_REGISTER_ERROR',

  PUT_PRODUCT_REGISTER: 'PRODUCTS/PUT_PRODUCT_REGISTER',
  PUT_PRODUCT_REGISTER_SUCCESS: 'PRODUCTS/PUT_PRODUCT_REGISTER_SUCCESS',
  PUT_PRODUCT_REGISTER_ERROR: 'PRODUCTS/PUT_PRODUCT_REGISTER_ERROR',

  ORDER_PRODUCTS_CATEGORIES: 'PRODUCTS/ORDER_PRODUCTS_CATEGORIES',
  ORDER_PRODUCTS_CATEGORIES_SUCCESS: 'PRODUCTS/ORDER_PRODUCTS_CATEGORIES_SUCCESS',
  ORDER_PRODUCTS_CATEGORIES_ERROR: 'PRODUCTS/ORDER_PRODUCTS_CATEGORIES_ERROR',

  ORDER_PRODUCTS_FAMILIES: 'PRODUCTS/ORDER_PRODUCTS_FAMILIES',
  ORDER_PRODUCTS_FAMILIES_SUCCESS: 'PRODUCTS/ORDER_PRODUCTS_FAMILIES_SUCCESS',
  ORDER_PRODUCTS_FAMILIES_ERROR: 'PRODUCTS/ORDER_PRODUCTS_FAMILIES_ERROR',

  RESET: 'PRODUCTS/RESET',

  RESET_SUCCESS: 'PRODUCTS/RESET_SUCCESS',
};

//Action Creators
export const getProducts = (companyId) => ({ type: Types.GET_PRODUCTS, companyId });
export const getProductsSuccess = (categoriesWithProducts) => ({ type: Types.GET_PRODUCTS_SUCCESS, categoriesWithProducts });
export const getProductsError = (error) => ({ type: Types.GET_PRODUCTS_ERROR, error }); 

export const getProductRegister = (productId) => ({ type: Types.GET_PRODUCT_REGISTER, productId });
export const getProductRegisterSuccess = (productRegister) => ({ type: Types.GET_PRODUCT_REGISTER_SUCCESS, productRegister });
export const getProductRegisterError = (error) => ({ type: Types.GET_PRODUCT_REGISTER_ERROR, error }); 

export const getDetailedProduct = (productId) => ({ type: Types.GET_DETAILED_PRODUCT, productId });
export const getDetailedProductSuccess = (detailedProduct) => ({ type: Types.GET_DETAILED_PRODUCT_SUCCESS, detailedProduct });
export const getDetailedProductError = (error) => ({ type: Types.GET_DETAILED_PRODUCT_ERROR, error }); 

export const getExtraFields = () => ({ type: Types.GET_EXTRA_FIELDS });
export const getExtraFieldsSuccess = (extraFields) => ({ type: Types.GET_EXTRA_FIELDS_SUCCESS, extraFields });
export const getExtraFieldsError = (error) => ({ type: Types.GET_EXTRA_FIELDS_ERROR, error }); 

export const getCategoriesFamilies = () => ({ type: Types.GET_CATEGORIES_FAMILIES });
export const getCategoriesFamiliesSuccess = (categoriesFamilies) => ({ type: Types.GET_CATEGORIES_FAMILIES_SUCCESS, categoriesFamilies });
export const getCategoriesFamiliesError = (error) => ({ type: Types.GET_CATEGORIES_FAMILIES_ERROR, error });
 
export const postProduct = (product) => ({ type: Types.POST_PRODUCT_REGISTER, product });
export const postProductSuccess = (product) => ({ type: Types.POST_PRODUCT_REGISTER_SUCCESS, product });
export const postProductError = (error) => ({ type: Types.POST_PRODUCT_REGISTER_ERROR, error });

export const putProduct = (product) => ({ type: Types.PUT_PRODUCT_REGISTER, product });
export const putProductSuccess = (product) => ({ type: Types.PUT_PRODUCT_REGISTER_SUCCESS, product });
export const putProductError = (error) => ({ type: Types.PUT_PRODUCT_REGISTER_ERROR, error }); 

export const orderProductCategory = (id, idParent, position) => ({ type: Types.ORDER_PRODUCTS_CATEGORIES, id, idParent, position });
export const orderProductCategorySuccess = () => ({ type: Types.ORDER_PRODUCTS_CATEGORIES_SUCCESS });
export const orderProductCategoryError = (error) => ({ type: Types.ORDER_PRODUCTS_CATEGORIES_ERROR, error });

export const orderProductFamilies = (id, idParent, idNode, position) => ({ type: Types.ORDER_PRODUCTS_FAMILIES, id, idParent, idNode, position });
export const orderProductFamiliesSuccess = () => ({ type: Types.ORDER_PRODUCTS_FAMILIES_SUCCESS });
export const orderProductFamiliesError = (error) => ({ type: Types.ORDER_PRODUCTS_FAMILIES_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetProducts(action) {
  try {
    const { companyId } = action;
    const categoriesWithProducts = yield call(getProductsSdk, companyId);
    yield put(getProductsSuccess(categoriesWithProducts));
  } catch (err) {
    Notification.error(err.message);
    yield put(getProductsError(err));
  }
}

function* fetchGetProductRegister(action) {
  try {
    const { productId } = action;
    const productRegister = yield call(getProductRegisterSdk, productId);
    yield put(getProductRegisterSuccess(productRegister));
  } catch (err) {
    Notification.error(err.message);
    yield put(getProductRegisterError(err));
  }
}

function* fetchGetDetailedProduct(action) {
  try {
    const { productId } = action;
    const detailedProduct = yield call(getDetailedProductSdk, productId);
    yield put(getDetailedProductSuccess(detailedProduct));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDetailedProductError(err));
  }
}

function* fetchGetExtraFields(action) {
  try {
    const extraFields = yield call(getExtraFieldsSdk);
    yield put(getExtraFieldsSuccess(extraFields));
  } catch (err) {
    Notification.error(err.message);
    yield put(getExtraFieldsError(err));
  }
}

function* fetchGetCategoriesFamilies(action) {
  try{
    const categoriesFamilies = yield call(getCategoriesFamiliesSdk);
    yield put(getCategoriesFamiliesSuccess(categoriesFamilies));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriesFamiliesError(err));
  }
}

function* fetchPostProduct(action) {
  try {
    let { product } = action;
    product = yield call(postProductRegisterSdk, product);
    yield put(postProductSuccess(product));
  } catch (err) {
    Notification.error(err.message);
    yield put(postProductError(err));
  }
}

function* fetchPutProduct(action) {
  try {
    let { product } = action;
    product = yield call(putProductRegisterSdk, product);
    yield put(putProductSuccess(product));
  } catch (err) {
    Notification.error(err.message);
    yield put(putProductError(err));
  }
}

function* fetchOrderProductCategory(action) {
  try {
    const { id, position } = action;
    yield call(changeOrdemCategoriesProdutoSdk, id, position);
    yield put(orderProductCategorySuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(orderProductCategoryError(err));
  }
}

function* fetchOrderProductFamilies(action) {
  try {
    const { id, idParent, position } = action;
    yield call(changeOrdemCategoriesFamiliesSdk, id, idParent, position);
    yield put(orderProductFamiliesSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(orderProductFamiliesError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_PRODUCTS, fetchGetProducts),
  takeLatest(Types.GET_PRODUCT_REGISTER, fetchGetProductRegister),
  takeLatest(Types.GET_DETAILED_PRODUCT, fetchGetDetailedProduct),
  takeLatest(Types.GET_EXTRA_FIELDS, fetchGetExtraFields),
  takeLatest(Types.GET_CATEGORIES_FAMILIES, fetchGetCategoriesFamilies),
  takeLatest(Types.POST_PRODUCT_REGISTER, fetchPostProduct),
  takeLatest(Types.PUT_PRODUCT_REGISTER, fetchPutProduct),
  takeLatest(Types.ORDER_PRODUCTS_CATEGORIES, fetchOrderProductCategory),
  takeLatest(Types.ORDER_PRODUCTS_FAMILIES, fetchOrderProductFamilies),
];

// Reducer
const initialState = Map({
  categoriesWithProducts: List(),
  loadingProducts: false,
  successProducts: false,
  errorProducts: false,

  productRegister: Map(),
  loadingProductRegister: false,
  successProductRegister: false,
  errorProductRegister: false,

  loadingProductRegisterSave:false,
  successProductRegisterSave:false,
  errorProductRegisterSave:false,

  detailedProduct: Map(),
  loadingDetailedProduct: false,
  successDetailedProduct: false,
  errorDetailedProduct: false,

  extraFields: List(),
  loadingExtraFields: false,
  successExtraFields: false,
  errorExtraFields: false,

  categoriesFamilies: List(),
  loadingCategoriesFamilies: false,
  successCategoriesFamilies: false,
  errorCategoriesFamilies: false,
});

const handleGetProducts = (state, action) => {
  return state.set('loadingProducts', true).set('successProducts', false).set('errorProducts', false);
};

const handleGetProductsSuccess = (state, action) => {
  const { categoriesWithProducts } = action;

  return state.set('categoriesWithProducts', categoriesWithProducts).set('loadingProducts', false).set('successProducts', true).set('errorProducts', false);
};

const handleGetProductsError = (state, action) => {
  return state.set('loadingProducts', false).set('successProducts', false).set('errorProducts', action.error);
};

const handleGetProductRegister = (state, action) => {
  return state.set('loadingProductRegister', true).set('successProductRegister', false).set('errorProductRegister', false);
};

const handleGetProductRegisterSuccess = (state, action) => {
  const { productRegister } = action;

  return state.set('productRegister', productRegister).set('loadingProductRegister', false).set('successProductRegister', true).set('errorProductRegister', false);
};

const handleGetProductRegisterError = (state, action) => {
  return state.set('loadingProductRegister', false).set('successProductRegister', false).set('errorProductRegister', action.error);
};

const handleGetDetailedProduct = (state, action) => {
  return state.set('loadingDetailedProduct', true).set('successDetailedProduct', false).set('errorDetailedProduct', false);
};

const handleGetDetailedProductSuccess = (state, action) => {
  const { detailedProduct } = action;

  return state.set('detailedProduct', detailedProduct).set('loadingDetailedProduct', false).set('successDetailedProduct', true).set('errorDetailedProduct', false);
};

const handleGetDetailedProductError = (state, action) => {
  return state.set('loadingDetailedProduct', false).set('successDetailedProduct', false).set('errorDetailedProduct', action.error);
};

const handleGetExtraFields = (state, action) => {
  return state.set('loadingExtraFields', true).set('successExtraFields', false).set('errorExtraFields', false);
};

const handleGetExtraFieldsSuccess = (state, action) => {
  const { extraFields } = action;

  return state.set('extraFields', extraFields).set('loadingExtraFields', false).set('successExtraFields', true).set('errorExtraFields', false);
};

const handleGetExtraFieldsError = (state, action) => {
  return state.set('loadingExtraFields', false).set('successExtraFields', false).set('errorExtraFields', action.error);
};

const handleGetCategoriesFamilies = (state, action) => {
  return state.set('loadingCategoriesFamilies', true).set('successCategoriesFamilies', false).set('errorCategoriesFamilies', false);
};

const handleGetCategoriesFamiliesSuccess = (state, action) => {
  
  return state.set('categoriesFamilies', action.categoriesFamilies).set('loadingCategoriesFamilies', false).set('successCategoriesFamilies', true).set('errorCategoriesFamilies', false);
};

const handleGetCategoriesFamiliesError = (state, action) => {
  return state.set('loadingCategoriesFamilies', false).set('successCategoriesFamilies', false).set('errorCategoriesFamilies', action.error);
};

const handleOrderProductFamilies = (state, action) => {
  const { id, idParent, idNode, position } = action;

  const categories = state.get('categoriesFamilies');

  const parentToMove = categories.find((item) => item.families.some((family) => family.id === id));
  const itemToMove = parentToMove.families.find((item) => item.id === id);
  const parentOfReference = categories.find((item) => item.id === idParent); 
  const aux = [...categories];

  if(parentOfReference.families.length  < 1) {
    parentToMove.families.splice(parentToMove.families.indexOf(itemToMove), 1); 
    parentOfReference.families.splice(position, 0, itemToMove);
    parentToMove.families.forEach((item, index) => {
      item.order = index;
    });
    const parentToMoveIndex = aux.findIndex((item) => item.id === parentToMove.id);
    aux[parentToMoveIndex] = parentToMove;
    parentOfReference.families.forEach((item, index) => {
      item.order = index;
    });
    const parentOfReferenceIndex = aux.findIndex((item) => item.id === parentOfReference.id); 
    aux[parentOfReferenceIndex] = parentOfReference;
    return state.set('categoriesFamilies', aux);
  }
  const referenceNode = parentOfReference.families.find((item) => item.id === idNode);

  if (itemToMove && referenceNode) {
    parentToMove.families.splice(parentToMove.families.indexOf(itemToMove), 1); 
    parentOfReference.families.splice(position, 0, itemToMove);
    parentToMove.families.forEach((item, index) => {
      item.order = index;
    });
    const parentToMoveIndex = aux.findIndex((item) => item.id === parentToMove.id);
    aux[parentToMoveIndex] = parentToMove;
    if(parentToMove.id !== parentOfReference.id) {
      parentOfReference.families.forEach((item, index) => {
        item.order = index;
      });
      const parentOfReferenceIndex = aux.findIndex((item) => item.id === parentOfReference.id); 
      aux[parentOfReferenceIndex] = parentOfReference;
      return state.set('categoriesFamilies', aux);
    }
    return state.set('categoriesFamilies', aux);
  }
  
  return state;
};

const handleOrderProductCategories = (state, action) => {
  const { id, idParent, position } = action;

  const categories = state.get('categoriesFamilies');

  const parentToMove = categories.find((item) => item.id === id);
  const parentOfReference = categories.find((item) => item.id === idParent); 

  if (parentToMove && parentOfReference) {
    const aux = [...categories];
    const indexToMove = aux.indexOf(parentToMove);
    const indexOfReference = aux.indexOf(parentOfReference);

    if (indexToMove !== -1 && indexOfReference !== -1) {
      const [movedParent] = aux.splice(indexToMove, 1);

      aux.splice(position, 0, movedParent);
      aux.forEach((item, index) => {
        item.order = index;
      });
      return state.set('categoriesFamilies', aux);
    }
  }
};

const handleSave = (state, action) => {
  return state.set('loadingProductRegisterSave', true).set('successProductRegisterSave', false).set('errorProductRegisterSave', false);
};

const handleSaveSuccess = (state, action) => {
  return state.set('loadingProductRegisterSave', false).set('successProductRegisterSave', true).set('errorProductRegisterSave', false);
};

const handleSaveError = (state, action) => {
  return state.set('loadingProductRegisterSave', false).set('successProductRegisterSave', false).set('errorProductRegisterSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PRODUCTS:
      return handleGetProducts(state, action);
    case Types.GET_PRODUCTS_SUCCESS:
      return handleGetProductsSuccess(state, action);
    case Types.GET_PRODUCTS_ERROR:
      return handleGetProductsError(state, action);

    case Types.GET_PRODUCT_REGISTER:
      return handleGetProductRegister(state, action);
    case Types.GET_PRODUCT_REGISTER_SUCCESS:
      return handleGetProductRegisterSuccess(state, action);
    case Types.GET_PRODUCT_REGISTER_ERROR:
      return handleGetProductRegisterError(state, action);

    case Types.GET_DETAILED_PRODUCT:
      return handleGetDetailedProduct(state, action);
    case Types.GET_DETAILED_PRODUCT_SUCCESS:
      return handleGetDetailedProductSuccess(state, action);
    case Types.GET_DETAILED_PRODUCT_ERROR:
      return handleGetDetailedProductError(state, action);

    case Types.GET_EXTRA_FIELDS:
      return handleGetExtraFields(state, action);
    case Types.GET_EXTRA_FIELDS_SUCCESS:
      return handleGetExtraFieldsSuccess(state, action);
    case Types.GET_EXTRA_FIELDS_ERROR:
      return handleGetExtraFieldsError(state, action);

    case Types.GET_CATEGORIES_FAMILIES:
      return handleGetCategoriesFamilies(state, action);
    case Types.GET_CATEGORIES_FAMILIES_SUCCESS:
      return handleGetCategoriesFamiliesSuccess(state, action);
    case Types.GET_CATEGORIES_FAMILIES_ERROR:
      return handleGetCategoriesFamiliesError(state, action);

    case Types.POST_PRODUCT_REGISTER:
      return handleSave(state, action);
    case Types.POST_PRODUCT_REGISTER_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_PRODUCT_REGISTER_ERROR:
      return handleSaveError(state, action);

    case Types.PUT_PRODUCT_REGISTER:
      return handleSave(state, action);
    case Types.PUT_PRODUCT_REGISTER_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_PRODUCT_REGISTER_ERROR:
      return handleSaveError(state, action);

    case Types.ORDER_PRODUCTS_CATEGORIES: 
      return handleOrderProductCategories(state, action);
    case Types.ORDER_PRODUCTS_FAMILIES: 
      return handleOrderProductFamilies(state, action); 

    case Types.RESET:
      return handleReset(state, action);
      
    default:
      return state;
  }
}