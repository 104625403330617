import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import App from '@app';
import { Body, PageHeader, Breadcrumb, View, ContentHeader, ContentTitle, Button, Skeleton } from '@components-teammove';
import { getIndicadores } from '@sdk/Indicadores';
import { getDashboard } from '@ducks/chamadosDashboard';
import { InfoUsuario, Filtros, TelasNomenclaturas } from '@utils';
import Detalhes from './Detalhes';
import { useDispatch, useSelector } from 'react-redux';
import { getUnidade, reset, resetSuccessSave, getPendingActivities, getCompleteActivities } from '@ducks/unidades';
import { setInterruptRequest } from '@ducks/voip';
import EditarUnidade from '../Cadastro';
import { getContatos } from '@ducks/contatos';
import { PageContainer, GridRow, InfoContainerView, LargerInfoContainerView, Row } from './styles';
import Indicadores from './Detalhes/Indicadores';
import Pessoas from './Pessoas';
import HistoricoTickets from './HistoricoTickets';
import { Container as ContactsContainer } from './Pessoas/styles';
import CadastroContato from './CadastroContato';
import ActivityHistory from './ActivityHistory';
import Cadastro from '../../Atividades/Agenda/Cadastro';

export const FILTERS_KEY = 'CHAMADOS_TICKETS';

export default function Unidade() {
  const { idUnidade } = useParams();
  const history = useHistory();
  const user = InfoUsuario.get();
  const [indicadores, setIndicadores] = useState();
  const [loadingIndicadores, setLoadingIndicadores] = useState(true);
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleContactRegister, setVisibleContactRegister] = useState(false);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const unidade = useSelector(({ unidades }) => unidades.get('unidade'));
  const loading = useSelector(({ unidades }) => unidades.get('loading'));
  const success = useSelector(({ unidades }) => unidades.get('success'));
  const successSave = useSelector(({ unidades }) => unidades.get('successSave'));
  const contatos = useSelector(({ contatos }) => contatos.get('contatos'));
  const loadingContatos = useSelector(({ contatos }) => contatos.get('loadingContatos'));
  const dashboard = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('status')).toArray();
  const loadingDashboard = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loading'));
  
  const [visibleActivityRegister, setVisibleActivityRegister] = useState(false);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    dispatch(getUnidade(parseInt(idUnidade), true));
    dispatch(getContatos(idUnidade));
    dispatch(getDashboard(InfoUsuario.get('cdUsuario'), { unidade: [idUnidade] }, null, { updateFiltros: false }));

    const asyncGetIndicadores = async () => {
      const indicadoresAns = await getIndicadores(user, 'UNIDADE', parseInt(idUnidade));
      setLoadingIndicadores(false);
      setIndicadores(indicadoresAns);
    };
    asyncGetIndicadores();

    return () => {
      dispatch(setInterruptRequest(false));
      reset();
    };
  }, []);

  useEffect(() => {
    if (successSave) {
      dispatch(getUnidade(parseInt(idUnidade), true));
      dispatch(resetSuccessSave());
    }
  }, [successSave]);

  const handleRedirectToTickets = (status) => {
    localStorage.setItem('tickets_view_mode', 'Listagem');
    Filtros.set(FILTERS_KEY, { ...Filtros.get(FILTERS_KEY), unidade: [parseInt(idUnidade)], status: status && [status.id], descFiltros: { ...Filtros.get(FILTERS_KEY).descFiltros, unidade: [unidade.identifier], status: status && [status.descricao] } });
    history.push('/chamados/tickets');
  };

  const handleOnClosePopUp = () => {
    if(unidade.id) {
      dispatch(getCompleteActivities(unidade.id, user.cdUsuario, 'FINALIZADA CANCELADA', 'DESC', limit));
      dispatch(getPendingActivities(unidade.id, user.cdUsuario, 'PENDENTE', 'ASC'));
    }
  };
 
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={loading ? <Skeleton.MediumLine/> : unidade?.identifier} 
          subTitle={loading ? <Skeleton.Title/> : unidade?.name} 
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/listaUnidades', name: `${companyNomenclature.plural}` },
                { path: `/listaUnidades/${idUnidade}`, name: loading ? '...' : unidade?.name, current: true }
              ]}
            />
          )}
          onBack={() => history.goBack()}
          extra={[
            InfoUsuario.perm('atividadesEditar') &&
            <Button size='small' context='header' key='2' type='secondary' onClick={() => setVisibleActivityRegister(true)}>Criar atividade</Button>,
            
            InfoUsuario.perm('unidadesCadastro') && [
              <Button size='small' context='header' key='edit-company-btn' onClick={() => setVisibleRegister(true)} disabled={loading}>
                Editar
              </Button>
            ]
          ]}
        >
          <PageContainer>
            <>
              {loadingIndicadores ? (
                <InfoContainerView>
                  {loading ? <Skeleton.Title/> : <ContentTitle>Indicadores</ContentTitle>}
                  <Row>
                    <Skeleton.LargeBox/>
                    <Skeleton.LargeBox/>
                  </Row>
                </InfoContainerView>
              ) : indicadores && indicadores.length > 0 && (
                <InfoContainerView>
                  {loading ? <Skeleton.Title/> : <ContentTitle>Indicadores</ContentTitle>}
                  <Indicadores indicadores={indicadores}/>
                </InfoContainerView>
              )}
              <GridRow>
                {success && (
                  <>
                    <InfoContainerView preventGrowOnOverflow>
                      {loading ? <Skeleton.Title/> : <ContentTitle>Dados básicos</ContentTitle>}
                      {loading ? (
                        <Skeleton.LargeBox/>
                      ) : (
                        <Detalhes company={unidade} handleOnClosePopUp={handleOnClosePopUp}/>
                      )}
                    </InfoContainerView>
                    <InfoContainerView preventGrowOnOverflow>
                      {loading ? <Skeleton.Title/> : <ContentTitle>Histórico de atividades</ContentTitle>}
                      <ActivityHistory user={user} handleOnClosePopUp={handleOnClosePopUp} limit={limit} setLimit={setLimit}/>
                    </InfoContainerView>
                  </>
                )}
              </GridRow>

              <View>
                <ContentHeader title='Pessoas' extra={!loading && contatos.length > 0 && <Button type='secondary' size='small' onClick={() => setVisibleContactRegister(true)}>Adicionar contato</Button>}/>
                {loadingContatos ? (
                  <ContactsContainer>
                    <Skeleton.LargeBox/>
                    <Skeleton.LargeBox/>
                    <Skeleton.LargeBox/>
                  </ContactsContainer>
                ) : (<Pessoas companyId={idUnidade} setVisibleContactRegister={setVisibleContactRegister} handleOnClosePopUp={handleOnClosePopUp}/>)}
              </View>

              {loadingDashboard ? (
                <LargerInfoContainerView>
                  <InfoContainerView>
                    <ContentHeader title='Histórico de tickets' marginless/>  
                    <LargerInfoContainerView>
                      <Skeleton.LargeBox/>
                      <Skeleton.LargeBox/>
                      <Skeleton.LargeBox/>
                      <Skeleton.LargeBox/>
                      <Skeleton.LargeBox/>
                    </LargerInfoContainerView>
                  </InfoContainerView>
                  <Skeleton.SearchBar/>
                </LargerInfoContainerView>
              ) : dashboard.length > 0 && (
                <LargerInfoContainerView>
                  <InfoContainerView>
                    <ContentHeader title='Histórico de tickets' marginless/>  
                    <HistoricoTickets dashboard={dashboard} company={unidade} handleRedirectToTickets={handleRedirectToTickets}/>
                  </InfoContainerView>
                  <Button type='primary' fillWidth onClick={() => handleRedirectToTickets()}>Ver todos os tickets</Button>
                </LargerInfoContainerView>
              )}
            </>
          </PageContainer>
        </PageHeader>
        {visibleRegister && (
          <EditarUnidade
            visible={visibleRegister}
            onCancel={() => setVisibleRegister(false)}
            id={idUnidade}
            title={companyNomenclature.nomenclatura}
          />
        )}

        {visibleContactRegister && (
          <CadastroContato
            visible={visibleContactRegister}
            onCancel={() => setVisibleContactRegister(false)}
          />
        )}

        {visibleActivityRegister && (
          <Cadastro visible={visibleActivityRegister} onCancel={() => setVisibleActivityRegister(false)} idCompany={parseInt(idUnidade)}/>
        )}
      </Body>
    </App>
  );
}