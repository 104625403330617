import React, { useEffect, useState } from 'react';
import { Modal, View, Form, Input, Checkbox, Field, Switch } from '@components';
import { Icon } from '@components-teammove';
import Feedbacks from './Feedbacks';
import Perguntas from './Perguntas';
import { useSelector } from 'react-redux';
import { Notification } from '@utils';
import { Select } from 'antd';
import styled from 'styled-components';

const Edit = styled(Icon.EditOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const ViewMateriaTitle = styled(View)`
  font-size: 14px;
  font-weight: normal;
  flex-direction: row;
  align-items: center;
  svg{
    margin: .2rem .2rem 0;
  }
`;

export default function ModalQuiz({ data, materias = [], visible, onCancel, loading, onOk }) {
  const { quiz, materiaId, materiaTitulo } = data;
  const [form] = Form.useForm();
  const [isChangingMateria, setIsChangingMateria] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ feedbackParcial: false, ...quiz });
  }, []);

  const opcoesPerguntas = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('opcoes').toArray());

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (values.feedbacks && values.feedbacks.length > 0) {
        let feedbackIndex = 0;
        for (let feedback of values.feedbacks) {
          // eslint-disable-next-line
          if (values.feedbacks.filter((fb, index) => index !== feedbackIndex && fb.prMaximo === feedback.prMaximo).length !== 0) {
            notificacaoErro(`Há mais de um feedback com o percentual máximo ${feedback.prMaximo}`);
            return;
          }
          feedbackIndex++;
        }
      }

      if (!values.perguntas || values.perguntas.length === 0) {
        notificacaoErro('Informe ao menos uma pergunta');
        return;
      } else {
        for (let pergunta of values.perguntas) {
          const index = values.perguntas.indexOf(pergunta) + 1;

          const perguntaRepetida = values.perguntas.filter((perg) => perg.id !== pergunta.id && perg.pergunta === pergunta.pergunta)[0];

          if (perguntaRepetida) {
            const indexRepetido = values.perguntas.indexOf(perguntaRepetida) + 1;
            notificacaoErro(`A Pergunta ${index} é igual à Pergunta ${indexRepetido}`);
            return;
          }

          const opcoesPergunta = opcoesPerguntas.filter((opcoes) => opcoes.idPergunta === pergunta.id)[0];
          if (opcoesPerguntas.length === 0 || !opcoesPergunta) {
            notificacaoErro('Todas as perguntas devem ter opções');
            return;
          }

          pergunta.opcoes = opcoesPergunta.opcoes;

          if (!pergunta.opcoes || pergunta.opcoes.length < 2) {
            notificacaoErro(`Informe ao menos duas opções na Pergunta ${index}`);
            return;
          }

          let qtCorreta = 0;

          for (let opcao of pergunta.opcoes) {
            qtCorreta += opcao.correta ? 1 : 0;

            if (pergunta.opcoes.filter((op) => op.opcao === opcao.opcao && op.id !== opcao.id).length !== 0) {
              notificacaoErro(`Pergunta ${index} tem duas opções iguais`);
              return;
            }
          }

          if (qtCorreta < 1) {
            notificacaoErro(`Informe ao menos uma opção correta na Pergunta ${index}`);
            return;
          }
        }
      }

      onOk({
        index: data.index,
        quiz: {
          ...quiz,
          ...values,
        },
        materiaId,
      });
    });
  };

  const notificacaoErro = (msg) => {
    Notification.error(msg);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal 
      visible={visible} 
      onCancel={handleCancel} 
      onOk={handleOk} 
      okButtonProps={{ loading }} 
      maskClosable={false} 
      width='80vw'
      title={
        <View>
          <View>{quiz ? 'Editar quiz' : 'Novo quiz'}</View>
          {!isChangingMateria && 
            <ViewMateriaTitle>Matéria: {materiaTitulo} <Edit onClick={() => setIsChangingMateria(true)}/></ViewMateriaTitle>
          }
        </View>
      }
    >
      <Form form={form} vertical='true' initialValues={{ ...quiz, newMateriaId: materiaTitulo }}>
        {isChangingMateria &&
          <Form.Item label='Matéria' name='newMateriaId' rules={[{ required: true, message: 'Informe uma matéria' }]}>
            <Select options={materias.map((materia) => ({ label: materia.titulo, value: materia.id }))} defaultValue={materiaId}/>
          </Form.Item>
        }
        <Field label='Descrição' name='descricao' rules={[{ required: true, message: 'Informe a descrição' }]}>
          <Input autoFocus />
        </Field>
        <Field name='feedbackParcial' valuePropName='checked' labelAlign='left'>
          <Checkbox>Mostrar feedback após finalizar cada pergunta</Checkbox>
        </Field>
        <Perguntas formRef={form} perguntas={quiz?.perguntas} />
        <br />
        <Feedbacks />
        {data.quiz && <Field label="Ativo" name="ativo" valuePropName="checked">
          <Switch />
        </Field>}
      </Form>
    </Modal>
  );
}
