import { Map } from 'immutable';

export const FILTERS_KEY = 'AGENDA';

//Action Types
export const Types = {
  CHANGE_SEARCH: 'AGENDA/CHANGE_SEARCH',
};

//Action Creators
export const changeSearch = (search) => ({ type: Types.CHANGE_SEARCH, search });

//saga

// Reducer
const initialState = Map({
  search: ''
});

const handleChangeSearch = (state, action) => {
  return state.set('search', action.search);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.CHANGE_SEARCH:
      return handleChangeSearch(state, action);
    
    default: return state;
  }
}
