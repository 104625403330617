import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View } from '@components';
import { Bar, Skeleton } from '@components-teammove';
import { FILTERS_KEY } from '@ducks/wikisAudiencia';
import { Filtros } from '@utils';
import { useHistory, useParams } from 'react-router-dom';

export default function WikisConteudos({ search, chartHeight = 640 }) {
  const history = useHistory();
  const { id } = useParams();

  const theme = useSelector(({ app }) => app.get('theme'));
  
  const audienciasCategoria = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('audienciasCategoria').toArray());
  const loadingCategoria = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('loadingCategoria'));
  
  const chartParams = {
    xField: 'qtAcesso',
    yField: 'categoria',
    groupField: 'tipo',
    events: {
      onColumnClick: ({ data }) => history.push(data.link),
    },
    renderer: 'svg',
    height: chartHeight,
    width: audienciasCategoria.length > 10 ? 100 * audienciasCategoria.length : undefined,
    forceFit: audienciasCategoria.length > 10 ? false : true,
    xAxis: {
      title: {
        visible: false,
      },
      label: {
        visible: audienciasCategoria.length > 10 ? false : true,
      },
    },
    yAxis: {
      label: {
        formatter: (original) => original.length > 4 ? original.substring(0, 4) + '...' : original,
      },
      // visible: false
    },
    barStyle: {
      fill: theme['@global-action-color'],
      cursor: 'pointer',
    }
  };

  const filtros = Filtros.get(FILTERS_KEY);

  useEffect(() => {
    search(filtros);
  }, [id]);

  return (
    <View>
      {loadingCategoria ? (
        <Skeleton.Map/>
      ) : (
        <View>
          <Bar
            height={chartHeight}
            pointer
            params={{
              ...chartParams,
            }}
            data={audienciasCategoria.map((item) => {
              return {
                tipo: 'Acessos',
                categoria: item.dsCategoria,
                qtAcesso: item.qtAcesso,
                link: item.ultimaCategoria ? `/conteudos/visualizacao/${item.id}` : `/conteudos/audiencia/acesso/categorias/${item.id}`,
              };
            }).reduce((state, elem, index, array) => {
              const numberOfElemsWithSameCategoryName = array.filter(({ categoria, link }) => categoria === elem.categoria && state.find(({ link: linkInState }) => linkInState === link)).length;
              const newName = (elem.categoria) + ` (${numberOfElemsWithSameCategoryName + 2})`;
              return [...state, { ...elem, categoria: newName }];
            }, [])}
          />
        </View>
      )}
    </View>
  );
}
