import { dateToAnsi, DATE_FORMATS } from '@utils';

export const queryParamsAudienciaAcessoCategoria = (filtros) => {
  if (!filtros) return '';

  const query = [];
  const { categoria, perfil, listaTransmissao, usuario, data } = filtros;

  if (data && data.length > 0) {
    query.push(`dt_inicio=${dateToAnsi(data[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`dt_fim=${dateToAnsi(data[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  if (categoria && categoria.length > 0) query.push(`categoria=${categoria.join('+')}`);
  if (perfil && perfil.length > 0) query.push(`perfil=${perfil.join('+')}`);
  if (listaTransmissao && listaTransmissao.length > 0) query.push(`lista_transmissao=${listaTransmissao.join('+')}`);
  if (usuario && usuario.length > 0) query.push(`usuario=${usuario.join('+')}`);

  if (query.length === 0) return '?';
  return `?${query.join('&')}`;
};
