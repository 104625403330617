export const getDetailsHTML = (item) => {
  return (
    '<div style="line-height: 1.2;">' +
      '<b>' + (item?.nmReduzido ? item?.nmReduzido.toUpperCase() : '') + '</b> <br/>' + 
      '<b>' + (item?.nmEmpresa ? item?.nmEmpresa.toUpperCase() : '') + '</b> <br/>' + 
      (item?.endereco ? item?.endereco.toUpperCase() : '') + '<br>' + 
      (item?.cidade ? item?.cidade.toUpperCase() : '') + ' - ' + (item?.estado ? item?.estado.toUpperCase() : '') + 
    '</div>'
  );
};

export const getQueryParamsUnidades = (filtros) => {
  if (!filtros) return '';
  
  const query = [];
  const { agrupador, cluster, grupoCluster, tipoUnidade, state } = filtros;

  if ((agrupador) && (agrupador.length > 0)) query.push(`agrupador=${agrupador.join('+')}`);
  if ((cluster) && (cluster.length > 0)) query.push(`cluster=${cluster.join('+')}`);
  if((grupoCluster) && (grupoCluster.length > 0)) query.push(`clusterGroup=${grupoCluster.join('+')}`);
  if ((tipoUnidade) && (tipoUnidade.length > 0)) query.push(`tipoUnidade=${tipoUnidade.join('+')}`);
  if ((state) && (state.length > 0)) query.push(`state=${state.join('+')}`);

  if (query.length === 0) return '';
  return '&' + query.join('&');
};