import React from 'react';
import { Grid, Column } from '@components-teammove';
import { Letters, NumberContainer, Numbers } from './styles';

const Dialer = ({ disabledSpecialNumbers, inCall, onChange, letters, value }) => {
  const numericPad = [
    { number: 1, letters: ' ' },
    { number: 2, letters: 'abc' },
    { number: 3, letters: 'def' },
    { number: 4, letters: 'ghi' },
    { number: 5, letters: 'jkl' },
    { number: 6, letters: 'mno' },
    { number: 7, letters: 'pqrs' },
    { number: 8, letters: 'tuv' },
    { number: 9, letters: 'wxyz' },
    { number: '*', letters: ' ' },
    { number: 0, letters: ' ' },
    { number: '#', letters: ' ' },
  ];

  const handleDisc = (number) => {
    if(inCall) return;
    const currentNumber = value || '';
    const unmaskedValue = currentNumber.replace(/\D/g, ''); 
    const newNumber = unmaskedValue + number;

    onChange(newNumber);
  };

  return (
    <Grid numberOfColumns={3} numberOfRows={4} gap='12px' align='center' justify='center'>
      {numericPad.map((numPad) => (
        <NumberContainer key={numPad.number} disabledSpecialNumbers={disabledSpecialNumbers && (numPad.number === '#' || numPad.number === '*')} onClick={() => handleDisc(numPad.number)}>
          <Column gap='4px' align='center' justify={letters ? 'flex-start' : 'center'} margin={letters ? '12px 0 0 0' : '0'}>
            <Numbers>{numPad.number}</Numbers>
            {letters && (
              <Letters>{numPad.letters}</Letters>
            )}
          </Column>
        </NumberContainer>
      ))}
    </Grid>
  );
};

export default Dialer;