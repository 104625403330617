import { get, post, put, del } from '../api';

const useApiNode = true;

export const getAtendimento = (id) => {
  return get(`/api/workingJourneys/${id}`);
};

export const getAtendimentos = () => {
  return get('/api/workingJourneys');
};

export const saveAtendimento = (atendimento) => {
  return post('/api/workingJourneys', atendimento);
};

export const putAtendimento = (atendimento) => {
  return put('/api/workingJourneys', atendimento);
};

export const deleteAtendimento = (id) => {
  return del(`/api/workingJourney?id=${id}`, {}, useApiNode);
};

export const moveProfilesToJourney = (idJornada, id) => {
  return put(`/api/workingJourney/moveProfiles?id=${id}&idJourney=${idJornada}`, {}, {}, useApiNode);
};