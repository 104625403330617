import styled from 'styled-components';
import { Table } from '@components-teammove';

export const InputStyle = styled.input`
  width: ${({ width }) => `${width}px` || '100%'};
  background-color: transparent;
  border: none; /* Remove as bordas */
  outline: none; /* Remove o contorno quando selecionado */
  
  &:focus {
    outline: none; /* Remove o contorno quando selecionado */
  }
`;

export const TableStyle = styled(Table)`
&.ant-table {
  .ant-table-content, .ant-table-container {
    tbody {
      > tr {
        td { 
          padding: 2px 6px !important; 
        }
        :hover {
          td {
            background-color: ${(props) => props.theme.darkSecondaryColor};
          }
        }
      }
    }
  }
}
`;
