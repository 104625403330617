import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importCampaignsResults } from '@ducks/campanhaImportacaoResultadosExcel';
import { Upload, Column } from '@components-teammove';
import { Notification } from '@utils';
import { ModalImport, LabelModelImport, WarningCard, ExcelTable } from './styles';
import { getObjectFromFile } from './rules';

export default function ModalImportResults({ visible, onCancel, onOk }) {
  const dispatch = useDispatch();

  const loading = useSelector(({ campanhaImportacaoResultadosExcel }) => campanhaImportacaoResultadosExcel.get('loading'));
  const success = useSelector(({ campanhaImportacaoResultadosExcel }) => campanhaImportacaoResultadosExcel.get('success'));

  const [fileList, setFileList] = useState([]);
  const [readingExcel, setReadingExcel] = useState(false);

  useEffect(() => {
    if (success) {
      onOk();
      setFileList([]);
    }
  }, [success]);
  
  const handleOk = async () => {
    try {
      if (fileList.length === 0) {
        throw Error('Anexe um arquivo para a importação dos resultados das campanhas vigentes.');
      }
      if (fileList.lenght > 1) {
        throw Error('Anexe somente um arquivo para a importação dos resultados das campanhas vigentes.');
      }
      setReadingExcel(true);
      let results = [];
      for(const file of fileList) {
        results = await getObjectFromFile(file);
      }
      handleImportResults(results);
    } catch(e) {
      Notification.error(e.toString());
    } finally {
      setReadingExcel(false);
    }
  };

  const handleCancel = () => {
    setFileList([]);
    onCancel();
  };

  const handleImportResults = (results) => {
    dispatch(importCampaignsResults(results));
  };

  return (
    <ModalImport
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading: loading || readingExcel }}
      okText="Importar"
      title="Importar resultados das campanhas vigentes"
      width={900}
    >
      <Column gap='8px'>
        <Column gap='16px'>
          <LabelModelImport>Modelo para importar resultados</LabelModelImport>
          <ExcelTable>
            <thead>
              <tr>
                <th/>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Campanha</td>
                <td>Meta</td>
                <td>Realizado</td>
                <td>Agrupador 1</td>
                <td>Agrupador 2</td>
              </tr>
              <tr>
                <td>2</td>
                <td>CAMPANHA1-012022</td>
                <td>1000.00</td>
                <td>500.00</td>
                <td>100</td>
                <td>101</td>
              </tr>
              <tr>
                <td>3</td>
                <td>CAMPANHA2-012022</td>
                <td>2000.00</td>
                <td>1000.00</td>
                <td>100</td>
                <td>101</td>
              </tr>
            </tbody>
          </ExcelTable>
        </Column>

        <Column gap='20px'>
          <WarningCard>ATENÇÃO: para importar corretamente seu arquivo precisa estar no formato .xlsx</WarningCard>

          <Upload.Dragger
            onRemove={(file) => {
              setFileList([]);
            }}
            beforeUpload={(file) => {
              setFileList([file]);
              return false;
            }}
            fileList={fileList}
          />
        </Column>
      </Column>
    </ModalImport>
  );
}