const typesRespostas = {
  MULTIPLA_ESCOLHA: 'ESCOLHA_UNICA',
  SOMATORIA: 'CAIXA_SELECAO',
  TEXT: 'TEXTO',
  ANEXO: 'ANEXO',
  DATA: 'DATA',
  NUMERICO: 'NUMERICO',
  PARAGRAFO: 'PARAGRAFO'
};

const selectRespostas = [
  { key: typesRespostas.MULTIPLA_ESCOLHA, value: typesRespostas.MULTIPLA_ESCOLHA, title: 'Escolha única' },
  { key: typesRespostas.SOMATORIA, value: typesRespostas.SOMATORIA, title: 'Caixa de seleção' },
  { key: typesRespostas.TEXT, value: typesRespostas.TEXT, title: 'Texto' },
  { key: typesRespostas.ANEXO, value: typesRespostas.ANEXO, title: 'Anexo' },
  { key: typesRespostas.DATA, value: typesRespostas.DATA, title: 'Data' },
  { key: typesRespostas.NUMERICO, value: typesRespostas.NUMERICO, title: 'Numérico' },
  { key: typesRespostas.PARAGRAFO, value: typesRespostas.PARAGRAFO, title: 'Parágrafo' },
];

const getNewId = (items) => ((items && items.length > 0 ? Math.max.apply(Math, items.map((item) => item.id)) : 0) + 1);

export { typesRespostas, selectRespostas, getNewId };