import React from 'react';
import { View, Dropdown, Menu } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';
import types from '../types';

const ViewQtComentario = styled(View)`
  padding: 10px 20px 30px 0;
  font-size: 18px;
`;

const ViewOrdenacao = styled(View)`
  padding: 10px 0 20px 30px;
  font-size: 18px;
`;

const ViewOrdenacaoMenu = styled(View)`
  cursor: pointer;
`;

const ViewOrdenacaoLabel = styled(View)`
  padding-left: 5px;
`;

export default function Header({ data, handlers }) {
  const { comentarios } = data;

  const MenuOrdenacao = () => (
    <Menu onClick={handlers.tipoOrdenacao}>
      <Menu.Item key={types.ordenacao.RELEVANTE}>Principais primeiro</Menu.Item>
      <Menu.Item key={types.ordenacao.DATA}>Mais recentes primeiro</Menu.Item>
    </Menu>
  );

  return (
    <View horizontal>
      <ViewQtComentario>
        {comentarios.length > 0 ? (comentarios.length > 1 ? `${comentarios.length} comentários` : '1 comentário') : 'Sem comentários'}
      </ViewQtComentario>

      <ViewOrdenacao>
        <Dropdown overlay={<MenuOrdenacao />}>
          <ViewOrdenacaoMenu horizontal>
            <Icon.MDI type='sort-variant' size={1.2} />
            <ViewOrdenacaoLabel>Classificar por</ViewOrdenacaoLabel>
          </ViewOrdenacaoMenu>
        </Dropdown>
      </ViewOrdenacao>
    </View>
  );
}
