import { Filtros } from '@utils';

export const changeFilterTickets = (filtros, item) => {
  const filter = filtros || Filtros.get('CHAMADOS_TICKETS');
  const descFiltros = { ...filter.descFiltros, status:[item.descricao] };

  return {
    ...filter,
    status: [item.id],
    descFiltros,      
  };
};