import styled from 'styled-components';

export const BreadcrumbRow = styled.div`
  display: flex;
  gap: 8px;

  > * {
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
    font-weight: 600;
  }
`;