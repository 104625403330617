import { View, Text } from '@components';
import { Modal, Icon } from '@components-teammove';

import styled from 'styled-components';

export const ModalQuestionario = styled(Modal)`
  &.bTsTiq.ant-modal .ant-modal-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`;

export const Container = styled(View)`
  padding: 0;

  * .ant-typography {
    color: #F5F5F5;
  }

  @media print {
    gap: 10px;
  }
`;

export const Row = styled(View)`
  flex-direction: row;
  gap: 10px;
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const FillView = styled(Row)`
  flex: 1;
`;

export const Margin = styled(View)`
  margin: 40px 0 20px;
`;

export const Label = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
    color: #F5F5F5;
    opacity: .5;
  }
`;

export const ModalTitle = styled(Text)`
  &.ant-typography {
    font-weight: 400;
    color: #F5F5F5;
  }
`;

export const FixedText = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const FlexView = styled(View)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  width: fit-content;
  white-space: pre-line;
  word-break: break-all;
  min-width: 100%;
`;

export const ViewWithMargin = styled(View)`
  margin: .5rem 0;
`;

export const HighContrastText = styled(Label)`
  &.ant-typography {
    font-weight: 400;
  }

  @media print {
    &.ant-typography {
      color: black;
      opacity: 1;
    }
  }
`;

export const InfoText = styled(HighContrastText)`
  &.ant-typography {
    opacity: 1;
    word-break: break-word;
  }
`;

export const TinyMarginText = styled(Text)`
  margin: 0 .25rem;
`;

export const BiggerMarginText = styled(Text)`
  margin: 0 .5rem;
`;

export const PrintIcon = styled(Icon.PrinterOutlined)`
  font-size: 20px;
  cursor: pointer;
`;

export const TitleContainer = styled(View)`
  width: 100%;
  margin-left: 10px;
`;

export const Divider = styled.div`
  border-top: 2px solid ${({ theme })=> theme.darkSecondaryColor};
  position: absolute;
  right: 50px;
  left: 0;
  top: 20px;
  width: 105%;
`;

export const DividerContainer = styled.div`
  position: relative;
  right: 40px;
  width: 104%;
`;

export const HighContrastTextContent = styled(Text)`
  &.ant-typography {
    font-weight: 400;
    font-size: 14px;
    color: #F5F5F5;
    opacity: .5;
    word-break: break-word;
  }

  @media print {
    &.ant-typography {
      color: black;
      opacity: 1;
    }
  }
`;

export const BackIcon = styled(Icon.Feather)`
  color: ${(props) => props.theme.textColor} !important;
  font-size: 18px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const ContainerInfo = styled.div`
  width: 50%;
`;