import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, Filtros } from '@utils';
import {
  getAudiencia as getAudienciaSdk,
  getAudienciaUltimosAcessos as getAudienciaUltimosAcessosSdk,
  getAudienciaPorCategoria as getAudienciaPorCategoriaSdk,
} from '@sdk/Wikis/Audiencia';
import { initialFilters } from '../../containers/Wikis/Audiencia/rules';

export const FILTERS_KEY = 'WIKI_AUDIENCIA';

//Action Types
export const Types = {
  GET_AUDIENCIA: 'WIKI_AUDIENCIA/GET_AUDIENCIA',
  GET_AUDIENCIA_SUCCESS: 'WIKI_AUDIENCIA/GET_AUDIENCIA_SUCCESS',
  GET_AUDIENCIA_ERROR: 'WIKI_AUDIENCIA/GET_AUDIENCIA_ERROR',

  GET_AUDIENCIA_ULTIMOS_ACESSOS: 'WIKI_AUDIENCIA/GET_AUDIENCIA_ULTIMOS_ACESSOS',
  GET_AUDIENCIA_ULTIMOS_ACESSOS_SUCCESS: 'WIKI_AUDIENCIA/GET_AUDIENCIA_ULTIMOS_ACESSOS_SUCCESS',
  GET_AUDIENCIA_ULTIMOS_ACESSOS_ERROR: 'WIKI_AUDIENCIA/GET_AUDIENCIA_ULTIMOS_ACESSOS_ERROR',

  GET_AUDIENCIA_CATEGORIA: 'WIKI_AUDIENCIA/GET_AUDIENCIA_CATEGORIA',
  GET_AUDIENCIA_CATEGORIA_SUCCESS: 'WIKI_AUDIENCIA/GET_AUDIENCIA_CATEGORIA_SUCCESS',
  GET_AUDIENCIA_CATEGORIA_ERROR: 'WIKI_AUDIENCIA/GET_AUDIENCIA_CATEGORIA_ERROR',

  SET_FILTRO_CATEGORIAS_SELECIONADAS: 'WIKI_AUDIENCIA/SET_FILTRO_CATEGORIAS_SELECIONADAS',
  SET_FILTERS_WITH_OPTIONS: 'WIKI_AUDIENCIA/SET_FILTERS_WITH_OPTIONS'
};

//Action Creators
export const getAudiencia = (limit, filtros = {}) => ({ type: Types.GET_AUDIENCIA, limit, filtros });
export const getAudienciaSuccess = (data) => ({ type: Types.GET_AUDIENCIA_SUCCESS, data });
export const getAudienciaError = (error) => ({ type: Types.GET_AUDIENCIA_ERROR, error });

export const getAudienciaUltimosAcessos = (page = 1, filtros = {}) => ({ type: Types.GET_AUDIENCIA_ULTIMOS_ACESSOS, page, filtros });
export const getAudienciaUltimosAcessosSuccess = (data) => ({ type: Types.GET_AUDIENCIA_ULTIMOS_ACESSOS_SUCCESS, data });
export const getAudienciaUltimosAcessosError = (error) => ({ type: Types.GET_AUDIENCIA_ULTIMOS_ACESSOS_ERROR, error });

export const getAudienciaCategoria = (id, filtros = {}) => ({ type: Types.GET_AUDIENCIA_CATEGORIA, id, filtros });
export const getAudienciaCategoriaSuccess = (data) => ({ type: Types.GET_AUDIENCIA_CATEGORIA_SUCCESS, data });
export const getAudienciaCategoriaError = (error) => ({ type: Types.GET_AUDIENCIA_CATEGORIA_ERROR, error });

export const setFiltroCategoriasSelecionadas = (filtroCategoriasSelecionadas) => ({
  type: Types.SET_FILTRO_CATEGORIAS_SELECIONADAS,
  filtroCategoriasSelecionadas,
});

export const setFiltersWithOptions = (filters) => ({ type: Types.SET_FILTERS_WITH_OPTIONS, filters });

export const apiGetAudienciaUltimosAcessos = async (page, filtros) => {
  try {
    const audiencias = await getAudienciaUltimosAcessosSdk(page, filtros);
    return audiencias;
  } catch (err) {
    Notification.error(err.message);
  }
};

//saga
function* fetchAudienciaSaga(action) {
  try {
    const { limit, filtros } = action;
    Filtros.set(FILTERS_KEY, filtros);

    const resp = yield call(getAudienciaSdk, limit, filtros);
    yield put(getAudienciaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAudienciaError(err));
  }
}

function* fetchAudienciaUltimosAcessosSaga(action) {
  try {
    const { page, filtros } = action;

    const resp = yield call(getAudienciaUltimosAcessosSdk, page, filtros);
    yield put(getAudienciaUltimosAcessosSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAudienciaUltimosAcessosError(err));
  }
}

function* fetchAudienciaCategoriaSaga(action) {
  try {
    const { id, filtros } = action;
    Filtros.set(FILTERS_KEY, filtros);

    const resp = yield call(getAudienciaPorCategoriaSdk, id, filtros);
    yield put(getAudienciaCategoriaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAudienciaCategoriaError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_AUDIENCIA, fetchAudienciaSaga),
  takeLatest(Types.GET_AUDIENCIA_ULTIMOS_ACESSOS, fetchAudienciaUltimosAcessosSaga),
  takeLatest(Types.GET_AUDIENCIA_CATEGORIA, fetchAudienciaCategoriaSaga),
];

// Reducer
const initialState = Map({
  filtros: Filtros.get(FILTERS_KEY),
  audiencias: List(),
  audienciasUltimosAcessos: List(),
  audienciasCategoria: List(),

  xCurrentPageUltimosAcessos: 0,
  xTotalPageUltimosAcessos: 0,
  hasMoreUltimosAcessos: false,

  filtroCategoriasSelecionadas: [],
  filters: initialFilters,

  loading: false,
  success: false,
  error: false,

  loadingUltimosAcessos: false,
  successUltimosAcessos: false,
  errorUltimosAcessos: false,

  loadingCategoria: false,
  successCategoria: false,
  errorCategoria: false,
});

const handleGetAudiencia = (state, action) => {
  return state.set('filtros', action.filtros).set('loading', true).set('success', false).set('error', false);
};

const handleGetAudienciaSuccess = (state, action) => {
  return state.set('audiencias', List(action.data)).set('loading', false).set('success', true).set('error', false);
};

const handleGetAudienciaError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetAudienciaCategoria = (state, action) => {
  return state.set('filtros', action.filtros).set('loadingCategoria', true).set('successCategoria', false).set('errorCategoria', false);
};

const handleGetAudienciaCategoriaSuccess = (state, action) => {
  return state
    .set('audienciasCategoria', List(action.data))
    .set('loadingCategoria', false)
    .set('successCategoria', true)
    .set('errorCategoria', false);
};

const handleGetAudienciaCategoriaError = (state, action) => {
  return state.set('loadingCategoria', false).set('successCategoria', false).set('errorCategoria', action.error);
};

const handleGetAudienciaUltimosAcessos = (state, action) => {
  return state
    .set('filtros', action.filtros)
    .set('loadingUltimosAcessos', true)
    .set('successUltimosAcessos', false)
    .set('errorUltimosAcessos', false);
};

const handleGetAudienciaUltimosAcessosSuccess = (state, action) => {
  const { data, xCurrentPage, xTotalPage } = action.data;

  let todasAudiencias = [];

  if (xCurrentPage === 1) {
    todasAudiencias = List(data);
  } else {
    todasAudiencias = List([...state.get('audienciasUltimosAcessos').toArray(), ...data]);
  }

  return state
    .set('audienciasUltimosAcessos', todasAudiencias)
    .set('xCurrentPageUltimosAcessos', xCurrentPage)
    .set('xTotalPageUltimosAcessos', xTotalPage)
    .set('hasMoreUltimosAcessos', xTotalPage !== xCurrentPage)
    .set('loadingUltimosAcessos', false)
    .set('successUltimosAcessos', true)
    .set('errorUltimosAcessos', false);
};

const handleGetAudienciaUltimosAcessosError = (state, action) => {
  return state.set('loadingUltimosAcessos', false).set('successUltimosAcessos', false).set('errorUltimosAcessos', action.error);
};

const handleFiltroCategoriasSelecionadas = (state, action) => {
  const { filtroCategoriasSelecionadas } = action;
  return state.set('filtroCategoriasSelecionadas', filtroCategoriasSelecionadas);
};

const handleSetFilters = (state, action) => {
  const { filters } = action;
  return state.set('filters', filters);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_AUDIENCIA:
      return handleGetAudiencia(state, action);
    case Types.GET_AUDIENCIA_SUCCESS:
      return handleGetAudienciaSuccess(state, action);
    case Types.GET_AUDIENCIA_ERROR:
      return handleGetAudienciaError(state, action);

    case Types.GET_AUDIENCIA_CATEGORIA:
      return handleGetAudienciaCategoria(state, action);
    case Types.GET_AUDIENCIA_CATEGORIA_SUCCESS:
      return handleGetAudienciaCategoriaSuccess(state, action);
    case Types.GET_AUDIENCIA_CATEGORIA_ERROR:
      return handleGetAudienciaCategoriaError(state, action);

    case Types.GET_AUDIENCIA_ULTIMOS_ACESSOS:
      return handleGetAudienciaUltimosAcessos(state, action);
    case Types.GET_AUDIENCIA_ULTIMOS_ACESSOS_SUCCESS:
      return handleGetAudienciaUltimosAcessosSuccess(state, action);
    case Types.GET_AUDIENCIA_ULTIMOS_ACESSOS_ERROR:
      return handleGetAudienciaUltimosAcessosError(state, action);

    case Types.SET_FILTRO_CATEGORIAS_SELECIONADAS:
      return handleFiltroCategoriasSelecionadas(state, action);
    case Types.SET_FILTERS_WITH_OPTIONS:
      return handleSetFilters(state, action);

    default:
      return state;
  }
}
