import * as Containers from '@containers';
import { Icon } from '@components-teammove';
import { InfoUsuario } from '@utils';

export default () => {
  let routes = [
    {
      path: '/atividades/agenda/:idActivity/checklist/:idChecklist/finished',
      component: Containers.ChecklistFinished,
      exact: true,
    }
  ];

  if(InfoUsuario.perm('agenda')) {
    routes = [ ...routes, ...[
      {
        path: '/atividades/agenda',
        parent:'/atividades',
        icon: Icon.CalendarOutlined,
        text: 'Agenda',
        match: /^\/atividades\/agenda?/,
        component: Containers.Agenda,
        exact: true,
        secret: true,
        sider: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/agenda/:idActivity',
        component: Containers.Atividade,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/agenda/:idActivity/checklist/:idChecklist',
        component: Containers.ChecklistPreview,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/agenda/:idActivity/checklist/:idChecklist/answer',
        component: Containers.ChecklistFill,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/agenda/:idActivity/checklist/:idChecklist/finish',
        component: Containers.ChecklistFinish,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/agenda/:idActivity/checkin-details',
        component: Containers.AtividadeCheckin,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      }
    ]];
  }

  if (InfoUsuario.perm('agenda') && (InfoUsuario.perm('checklist') || InfoUsuario.perm('gestaoEquipes'))) {
    routes = [ ...routes, ...[
      {
        path: '/atividades',
        icon: Icon.CalendarOutlined,
        text: 'Atividades',
        match: /^\/atividades?/,
        exact: true,
        sider: true,
        secret: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
      {
        path: '/atividades/checklist',
        parent:'/atividades',
        text: 'Checklists',
        match: /^\/atividades\/checklist?/,
        component: Containers.Checklists,
        exact: true,
        secret: true,
        sider: InfoUsuario.perm('checklist'),
        permissions: (infoUsuario) => InfoUsuario.perm('checklist', infoUsuario),
      },
      {
        path: '/atividades/gestao-agenda',
        parent:'/atividades',
        text: 'Gestão Agenda',
        match: /^\/atividades\/gestao-agenda?/,
        component: Containers.GestaoAgenda,
        exact: true,
        secret: true,
        sider: InfoUsuario.perm('gestaoEquipes'),
        permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
      },
      {
        path: '/atividades/gestao-agenda/:id',
        component: Containers.GestaoAgendaUserActivity,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
      },
    ]];
  }

  if (InfoUsuario.perm('agenda') && (InfoUsuario.perm('voip'))) {
    routes = [ ...routes, ...[
      {
        path: '/atividades/ligacoes',
        parent:'/atividades',
        component: Containers.CallActivity,
        text: 'Ligações',
        match: /^\/atividades\/ligacoes?/,
        exact: true,
        secret: true,
        sider: InfoUsuario.perm('voip'),
        permissions: (infoUsuario) => InfoUsuario.perm('voip', infoUsuario),
      },
      {
        path: '/atividades/ligacoes/detalhes/:id',
        component: Containers.CallActivityDetailed,
        exact: true,
        permissions: (infoUsuario) => InfoUsuario.perm('agenda', infoUsuario),
      },
    ]];
  }

  return routes;
};