import React, { useEffect, useState } from 'react';
import { Form, Select, Modal } from '@components-teammove';
import { ColumnContainer } from './styles';
import { sendEmail } from '@sdk/Checklists';
import { getUsuarios } from '@sdk/Usuarios';
import { InfoUsuario } from '@utils';

const EmailModal = ({ visible, onCancel, checklist, idChecklist }) => {
  const [form] = Form.useForm();

  const [heightModal, setHeightModal] = useState(false);
  const [usuarios, setUsuarios] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingFinished, setLoadingFinished] = useState(true);
  
  useEffect(() => {
    getUsuarios().then((usuariosData) => {
      setLoadingFinished(false);
      const options = usuariosData.map((usuario) => ({
        value: usuario.email,
        label: usuario.title
      }));
      setUsuarios(options);
    });
  }, []);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoadingFinished(true);
      sendEmail(idChecklist, { emails: values.email, userName: checklist?.nameUser, userEmail: InfoUsuario.get('email'), companyId: checklist.idCompany }).then(() => {
        setLoadingFinished(false);
        onCancel();
      });
    });
  };

  const validateEmail = (date, value) => {
    const emails = Array.isArray(value) ? value : [value];
    const invalidEmail = emails.find((email) => !email || email.indexOf('@') === -1);
    if (!invalidEmail) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Por favor, insira um e-mail válido'));
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Enviar checklist para'}
      cancelText='Retornar'
      okText={'Enviar'}
      onOk={handleOk}
      okButtonProps={{ loading: loadingFinished }}
      getContainer={false}  
    >
      <ColumnContainer
        heightModal={heightModal}
      >
        <Form layout='vertical' form={form}>
          <Form.Item
            name='email' 
            label='E-mail'
            rules={[
              { required: true, message: 'Por favor, insira um e-mail' },
              { validator: validateEmail }
            ]}
          >
            <Select
              mode="tags"
              placeholder="selecione ou insira um email"
              onDropdownVisibleChange={(open) => { 
                setHeightModal(open); 
                setDropdownOpen(open);
              }}
              options={usuarios ? usuarios : []}
              loading={!usuarios}
              open={dropdownOpen}
              onSelect={() => { 
                setDropdownOpen(false);
                setHeightModal(false);
              }}
              autoFocus
            />
          </Form.Item>
        </Form>
      </ColumnContainer>
    </Modal>
  );
};

export default EmailModal;