import React from 'react';
import { Icon, Table, View } from '@components-teammove';

import { formatDateExtenso } from '@utils';
import styled, { css } from 'styled-components';

const TableAcessosStyled = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;

const Icone = styled(Icon.MDI)`
  ${(props) =>
  css`
      color: ${(props) => props.color} !important;
    `}
`;

export default function TableAcessos({ data, loading }) {
  const getIconTp = (tp) => {
    switch (tp) {
      case 'WEB':
        return (
          <View>
            <Icone type='apple-icloud' color='#1976d2' />
          </View>
        );
      case 'APP':
        return (
          <View>
            <Icone type='cellphone-iphone' color='#f57c00' />
          </View>
        );
      default:
        return;
    }
  };

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (text) => <View>{formatDateExtenso(text)}</View>,
    },
    {
      title: '',
      dataIndex: 'tp',
      key: 'tp',
      align: 'right',
      sorter: true,
      render: (text) => getIconTp(text),
    },
  ];

  return <TableAcessosStyled rowKey='date' columns={columns} dataSource={data} hidePagination loading={loading} />;
}
