import React from 'react';
import { formaDateToNow } from '@utils';
import { View } from '@components';
import { StatusTag } from '@components-teammove';
import {
  Meta,
  FillView,
  IconStar,
  ComunicadoSequencial,
  ComunicadoTitulo,
  TextSmall,
  Description,
  ComunicadoResumo,
  ComunicadoTagsContainer,
  UnreadIndicator
} from './styles';

export default function ItemComunicado({ item }) {

  return (
    <>
      <Meta>
        <FillView horizontal alignItems='center'>
          <FillView gap='4px'>
            <ComunicadoTagsContainer>
              <StatusTag size='large' color={item.tagCorPai} text={item.tituloPai}/>
              <ComunicadoSequencial publicado={item.publicado}>
                {item.sequencialPrefixo}
                {item.sequencial}
              </ComunicadoSequencial>
            </ComunicadoTagsContainer>
            <ComunicadoTitulo publicado={item.publicado} >
              {!item.lido && (
                <UnreadIndicator/>
              )}
              {item.titulo}
              <IconStar invisible={item.favorito ? 0 : 1} />
            </ComunicadoTitulo>
          </FillView>
        </FillView>
        <View horizontal justifyContent='space-between'>
          <Description>
            {item.resumo && (
              <ComunicadoResumo ellipsis={{ rows: 1 }} publicado={item.publicado}>
                {item.resumo}
              </ComunicadoResumo>
            )}
          </Description>
          <TextSmall>{formaDateToNow(item.dtCriacao, null, true)}</TextSmall>
        </View>
      </Meta>

    </>
  );
}