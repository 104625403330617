import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Column, Text, Divider, Progress, DividedLine, Skeleton } from '@components-teammove';
import { CardSummary, Container, StyleEmpty } from './styles';

import { getSatisfactionIndexDash, getSatisfactionIndexSummary } from '@ducks/chamadosDashboard';

export default function AssessmentSummary({ currentFilters, currentParams }) {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const satisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('satisfactionIndexDash'));
  const loadingSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingSatisfactionIndexDash'));
  const successSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successSatisfactionIndexDash'));
  
  const totalPercentQuantity = satisfactionIndexDash?.quantitiesByGrade?.reduce((acc, cur)=> acc + cur.quantity, 0);

  const satisfactionIndexSummary = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('satisfactionIndexSummary'));
  const successSatisfactionIndexSummary = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successSatisfactionIndexSummary'));
  
  const totalPercentTickets = successSatisfactionIndexSummary && Object.values(satisfactionIndexSummary).reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    dispatch(getSatisfactionIndexDash({ ...currentFilters, ...currentParams }));
    dispatch(getSatisfactionIndexSummary({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  return(
    loadingSatisfactionIndexDash ? (
      <Skeleton.Map/>
    ) : successSatisfactionIndexDash && (
      <Column gap='12px'>
        <Column>
          <Container gap='16px'>
            <Row>
              <Text size='20px' weight='800' line='20px'>Resumo de avalizações</Text>
            </Row>
            <Divider/>
            {satisfactionIndexDash.quantitiesByGrade.every((item)=> item.quantity === 0) ? <StyleEmpty description="Sem dados" /> : (
              <>
                <Row justify='center' align='center' gap='18px'>
                  <Progress.Star percent={(satisfactionIndexDash?.averageSatisfaction * 100) / 5}/>
                  <Text size='42px' weight='700'>{satisfactionIndexDash?.averageSatisfaction.toFixed(1)}</Text>
                </Row>

                <Column gap='9px'> 
                  {satisfactionIndexDash?.quantitiesByGrade.slice().reverse().map((item)=>(
                    <Row gap='28px' align='center' key={item.numberOfStars}>
                      <DividedLine
                        subtitleDisabled
                        divisions={[
                          { label: 'Crítico', percentage: (item.quantity * 100) / totalPercentQuantity, status: 'warning' },
                          { label: 'Sem Histórico', percentage: 100 - (item.quantity * 100) / totalPercentQuantity, useBgColor: true },
                        ]}
                      />
                      <Row gap='5px' align='center'>
                        <Text size='24px' weight='400'>{item.numberOfStars}</Text>
                        <Progress.Star totalStars='1' size='small' color={theme['@global-dark-highlight-color']} percent={(item.quantity * 100) / totalPercentQuantity}/>
                      </Row>
                    </Row>
                  ))}
                  
                </Column>
              </>
            )}
          </Container>
        </Column>
        {(successSatisfactionIndexSummary && !(satisfactionIndexDash.quantitiesByGrade.every((item)=> item.quantity === 0))) && (
          <Row gap='12px'>
            <CardSummary>
              <Text size='16px' weight='700' lowOpacity>Total de tickets avaliados</Text>
              <Row gap='6px'>
                <Text size='24px' weight='700'>{satisfactionIndexSummary.numberOfEvaluatedTickets}</Text>
                <Text size='24px' weight='400' lowOpacity>({((satisfactionIndexSummary?.numberOfEvaluatedTickets * 100) / totalPercentTickets).toFixed(0)}%)</Text>
              </Row>
            </CardSummary>
            <CardSummary>
              <Text size='16px' weight='700' lowOpacity>Ticket com avaliação pendente</Text>
              <Row gap='6px'>
                <Text size='24px' weight='700'>{satisfactionIndexSummary.numberOfTicketsWithPendingEvaluation}</Text>
                <Text size='24px' weight='400' lowOpacity>({((satisfactionIndexSummary?.numberOfTicketsWithPendingEvaluation * 100) / totalPercentTickets).toFixed(0)}%)</Text>
              </Row>
            </CardSummary>
            <CardSummary>
              <Text size='16px' weight='700' lowOpacity>Tickets não avaliados</Text>
              <Row gap='6px'>
                <Text size='24px' weight='700'>{satisfactionIndexSummary.numberOfNonEvaluatedTickets}</Text>
                <Text size='24px' weight='400' lowOpacity>({((satisfactionIndexSummary?.numberOfNonEvaluatedTickets * 100) / totalPercentTickets).toFixed(0)}%)</Text>
              </Row>
            </CardSummary>
          </Row>
        )}
      </Column>
    )
  );
}