import styled from 'styled-components';
import View from '../View';

export const ContentHeaderContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ marginless }) => marginless ? '0' : '20px'};
  align-items: center;
`;

export const GapRow = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;