import styled from 'styled-components';
import { PageHeader, Upload, View, Image, Skeleton, Icon } from '@components-teammove';
import { Text } from '@components';

export const Container = styled.div`
  width: 40%;
`;

export const Atachments = styled.div`
  width: 60%;
  padding-left: 12px;
`;

export const Header = styled(PageHeader)`
  .ant-page-header-content{
    padding-top: 37px;
  }
`;

export const Forms = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UploadIcon = styled(Icon.Feather)`
  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
  }
`;

export const UploadEdge = styled(Upload)`
  border: 2px dashed ${(props) => props.theme.darkHighlightColor};
  height: 180px;
  border-radius:12px;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  .ant-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

`;

export const DynamicFieldImageContainer = styled(View)`
  width: fit-content;
  flex-direction: row;
  gap: 10px;
  > *{
    flex: 1;
  }
`;

export const ImageCover = styled(Image)`
  object-fit: contain;
  cursor: pointer;
`;

export const ComponentEmptyCover = styled(View)`
  width: 223px;
  height: 180px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;

`;

export const IconRemoveCover = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  * {
    color: ${({ theme }) => theme.actionColor} !important;
  }
`;

export const IconLoading = styled(Icon.LoadingOutlined)`
 * {
  color: ${({ theme }) => theme.actionColor};
  font-size: 25px;
 }
`;

export const SkeletonLoad = styled(Skeleton.LargeBox)`
  margin-left:12px;
`;

