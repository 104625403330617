import styled, { css } from 'styled-components';

const ExternalPage = styled.iframe`
  border: none;
  ${(props) => props.height && css`
    height: ${(props.height)};
  `}
  ${(props) => props.width && css`
    width: ${(props.width)};
  `}
`;

export default ExternalPage;