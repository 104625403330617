import React from 'react';
import { List } from 'antd';
import styled, { css } from 'styled-components';
import Empty from '../../components-teammove/Empty';

const ListItem = styled(List.Item)`
  display: flex;
  padding: 10px;
  border-radius: 3px;
  ${(props) =>
  props.onClick && 
    css`
      cursor: pointer;
      &:hover {
        background: rgba(60, 90, 100, 0.04);
      }
    `}
`;

const ListComponent = ({ ...props }) => <List {...props} />;

ListComponent.propTypes = {
  ...List.propTypes,
};

ListComponent.defaultProps = {
  locale: {
    emptyText: <Empty description="Sem dados" />,
  },
};

ListComponent.Item = ListItem;
export default ListComponent;
