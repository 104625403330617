import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker } from 'antd';

export const StyledDatePicker = styled(DatePicker)`
  min-width: 200px !important;
  max-width: 100%;
  &.ant-picker {
    width: 100%;
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border-color: ${({ theme }) => theme.darkHighlightColor} !important;
    border-width: 2px;
    color: ${({ theme }) => theme.textColor} !important;
    padding: 14px;

    .ant-picker-separator {
      color: ${({ theme }) => theme.textColor};
      ${({ value }) => !value && `
        opacity: .5;
      `}
    }

    .ant-picker-input {
      > input {
        color: ${({ theme }) => theme.textColor};
        font-size: 16px;

        ::placeholder {
          color: ${({ theme }) => theme.textColor};
          opacity: .5;
        }
      }

        .ant-picker-clear {
          display: ${({ allowClear }) => !allowClear && 'none'};
          background-color: ${({ allowClear }) => allowClear && 'transparent'};
          color: ${({ allowClear }) => allowClear && 'transparent'};
          transition: ${({ allowClear }) => allowClear && 'color 0.3s ease'};
      }
    }

    .ant-picker-suffix {
      ${({ value }) => !value && `
        opacity: .5;
      `}
    }

    &.ant-picker-range .ant-picker-active-bar {
      background: ${({ theme }) => theme.actionColor};
    }

    :focus-within, .ant-picker-focused {
      border: 2px solid ${({ theme }) => theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }
  }
`;

export const DropdownContainerStyles = createGlobalStyle`
  .ant-picker-dropdown {
    background-color: transparent;

    .ant-picker-panel-container {
      background-color: ${({ theme }) => theme.darkPrimaryColor};
      color: ${({ theme }) => theme.textColor};

      .ant-picker-header {
        color: ${({ theme }) => theme.textColor};

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn {
          color: ${({ theme }) => theme.textColor};
        }
      }

      .ant-picker-content th {
        color: ${({ theme }) => theme.textColor};
      }

      .ant-picker-cell {
        color: ${({ theme }) => theme.textColor};

        &.ant-picker-cell-disabled {
          background-color: ${({ theme }) => theme.darkSecondary};
          opacity: .4;

          :before {
            background-color: ${({ theme }) => theme.darkSecondary};
          }

          .ant-picker-cell-inner {
            color: ${({ theme }) => theme.textColor};
          }
        }

        :hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
        :hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
          background-color: ${({ theme }) => theme.actionColor};
        }

        &.ant-picker-cell-in-view {
          &.ant-picker-cell-in-range,
          &.ant-picker-cell-range-start,
          &.ant-picker-cell-range-end {

            &.ant-picker-cell-range-start-single {
              :before {
                background-color: transparent;
              }
            }

            &.ant-picker-cell-range-hover-start,
            &.ant-picker-cell-range-hover-end {
              .ant-picker-cell-inner {
                :not(.ant-picker-cell-range-end) {
                  ::after {
                    background-color: ${({ theme }) => theme.actionColor} !important;
                  }
                }
              }
            }
            :before {
              background-color: ${({ theme }) => theme.darkSecondary};
            }
          }
        }

        :not(.ant-picker-cell-in-view):not(.ant-picker-cell-disabled) {
          opacity: .4;
        }
      }

      .ant-picker-footer .ant-picker-ranges .ant-picker-preset .ant-tag {
        color: ${({ theme }) => theme.textColor};
        background: transparent;
        border-color: ${({ theme }) => theme.textColor};

        :hover {
          background-color: ${({ theme }) => theme.actionColor};
          border: none;
        }
      }

      .ant-picker-time-panel {
        .ant-picker-time-panel-column {
          > .ant-picker-time-panel-cell {
            > .ant-picker-time-panel-cell-inner {
              color: ${(props) => props.theme.textColor};

              :hover {
                background-color: ${(props) => props.theme.actionColor};
                color: ${(props) => props.theme.textContrastColor};
              }
            }

            &.ant-picker-time-panel-cell-selected {
              > .ant-picker-time-panel-cell-inner {
                background-color: ${(props) => props.theme.actionColor};
                color: ${(props) => props.theme.textContrastColor};
              }
            }
          }
        }
      }
    }

    .ant-picker-cell-in-view {
      .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: ${({ theme }) => theme.actionColor} !important;
      }

      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${({ theme }) => theme.actionColor};
      }

      &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: ${({ theme }) => theme.actionColor};
        }
      }

      &.ant-picker-range-hover, &.ant-picker-range-hover-end {
        border-color: ${({ theme }) => theme.actionColor};

        ::after {
          background-color: ${({ theme }) => theme.actionColor};
          border-color: ${({ theme }) => theme.actionColor};
        }
      }
    }
  }
`;