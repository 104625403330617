import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'debounce';
import App from '@app';
import { Body, Breadcrumb, Button, PageHeader, SearchBar, Skeleton, Table } from '@components-teammove';
import { getWhitelabels } from '@ducks/configuracoesWhitelabel';
import { Alias, TagLabel, ContentCover, ContentLabelThemes, LabelThemes, TagInUse, Logo } from './styles';
import { useHistory } from 'react-router-dom';

export default function Whitelabels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const inputSearch = useRef(null);

  const [whitelabelsFiltered, setWhitelabelsFiltered] = useState([]);

  const theme = useSelector(({ app }) => app.get('theme'));
  const whitelabels = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('whitelabels').toArray());
  const loading = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('loadingGetWhitelabels'));
  const success = useSelector(({ configuracoesWhitelabel }) => configuracoesWhitelabel.get('successGetWhitelabels'));

  useEffect(() => {
    handleGetWhitelabels();
  }, []);

  useEffect(() => {
    if (success && !loading) {
      if (whitelabels.length === 0) {
        handleNewWhitelabel();
      } else {
        setWhitelabelsFiltered(whitelabels);
      }
    }
  }, [success]);

  const handleGetWhitelabels = () => {
    dispatch(getWhitelabels());
  };

  const handleNewWhitelabel = () => {
    history.push('/configuracoes/whitelabel/-1');
  };

  const handleFilterWhitelabels = debounce((value) => {
    setWhitelabelsFiltered(value.length > 0 ? whitelabels.filter((item) => item.alias.toLowerCase().includes(value.toLowerCase())) : whitelabels);
  }, 500);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'alias',
      sorter: false,
      priority: 1,
      render: (text, whitelabel) => (
        <Alias>{whitelabel.alias} {whitelabel.defaultTemplate && <TagInUse color={'#1E80F0'}>
          <TagLabel>Em uso</TagLabel>
        </TagInUse>}</Alias>
      ),
    },
    {
      title: 'Capa',
      dataIndex: 'cover',
      sorter: false,
      width: '18%',
      render: (text, whitelabel) => (
        <ContentCover background={whitelabel.foregroundColor} darkHighlightColor={whitelabel.darkHighlightColor}>
          <Logo src={whitelabel.logoHeader} />
        </ContentCover>
      ),
    }
  ];

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='White Labels'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/whitelabels', name: 'Configurações de White Labels', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
          extra={
            success && <Button key='new' type='primary' size='small' onClick={() => handleNewWhitelabel()}>Criar novo white label</Button>
          }
        >
          {loading ? <Skeleton.MediumLine width='100%' /> : 
            <SearchBar
              ref={inputSearch}
              onChange={(e) => handleFilterWhitelabels(e.target.value)}
              placeholder='Buscar template...'
            />}

          <ContentLabelThemes>
            {loading ? <Skeleton.MediumLine width='20%' /> : <LabelThemes>Listagem de temas</LabelThemes>}
          </ContentLabelThemes>

          <Table
            rowKey='id'
            columns={columns}
            dataSource={whitelabelsFiltered}
            hidePagination
            loading={loading}
            queries={{ 0: '(max-width: 599px)', 1: '(min-width: 600px) and (max-width: 740px)', 2: '(min-width: 741px) and (max-width: 1070px)', 3: '(min-width: 1071px)' }}
            onRow={(whitelabel) => {
              return {
                onClick: () => { history.push(`/configuracoes/whitelabel/${whitelabel.id}`); }
              };
            }}
          />
        </PageHeader>
      </Body>
    </App>
  );
}