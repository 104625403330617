import React from 'react';
import { View } from '@components';
import { Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styled from 'styled-components';
import './styles.css';

const Container = styled(View)`
  height: 100%;
  width: 100%;
`;

const PDFViewer = ({ url, onDocumentLoad }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        keyword: ['document', 'PDF'],
      },
    },
  });
  
  return(
    <Container>
      <Viewer 
        plugins={[
          toolbarPlugin,
          defaultLayoutPluginInstance
        ]}
        fileUrl={url} 
        onDocumentLoad={onDocumentLoad}
        theme='dark'
      />
    </Container>
  );
};

export default PDFViewer;
