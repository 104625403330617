import React from 'react';
import styled from 'styled-components';
import { Text, View } from '@components';
import { download } from '@utils';
import ConteudoIcone from '../../Icone';

const Container = styled(View)`
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: #EEE;
  }
`;

export default function VisualizacaoArquivo({ conteudo }) {
  return (
    <Container onClick={ () => { download(conteudo.get('conteudo')); } } >
      <ConteudoIcone item={{
        tipo: conteudo.get('tipo'),
        arquivoTipo: conteudo.get('arquivoTipo'),
        size: 1.5,
      }}
      />
      <Text align='center' >{conteudo.get('conteudo')}</Text>
    </Container>
  );
}