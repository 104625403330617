export const getLastSundayFromMoment = (moment) => {
  const date = moment;

  while (date.day() !== 0) {
    date.subtract(1, 'days');
  }

  return date;
};

export const getNextSaturdayFromMoment = (moment) => {
  const date = moment;

  while (date.day() !== 6) {
    date.add(1, 'days');
  }

  return date;
};

export const getMaxValue = (formattedData = []) => {
  return formattedData.reduce((max, { value }) => Math.max(max, value), 0) + 1;
};

export const getColorLimits = (maxValue, colors = []) => {
  return Math.ceil(maxValue / colors.length);
};

export const addColorsToData = (formattedData, colors) => {
  const maxValue = getMaxValue(formattedData);
  const colorLimits = getColorLimits(maxValue, colors);

  return formattedData?.map((obj) => {
    return {
      ...obj,
      color: colors[Math.floor(obj.value / colorLimits)]
    };
  });
};