import React, { useEffect, useRef, useState } from 'react';
import { StyledBar } from './styles';
import { GroupedBar as BarClass } from '@antv/g2plot';

const DEFAULT_PARAMS = {
  forceFit: true,
};

const Bar = ({ data, params, ...props }) => {
  const chartRef = useRef(null);
  const [plot, setPlot] = useState();

  useEffect(() => {
    if (plot) {
      plot.changeData(data);
    } else {
      const plotFinal = new BarClass(chartRef.current, {
        ...DEFAULT_PARAMS,
        ...params,
        data,
      });

      plotFinal.render();
      setPlot(plotFinal);
    }
  }, [data]);

  return <StyledBar ref={chartRef} {...props}/>;
};

export default Bar;