import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getConteudos as getConteudosSdk,
  getConteudo as getConteudoSdk,
  getAudiencia as getAudienciaSdk,
  getFeedback as getFeedbackSdk,
} from '@sdk/Wikis';

//Action Types
export const Types = {
  GET_CONTEUDOS: 'WIKI/GET_CONTEUDOS',
  GET_CONTEUDOS_SUCCESS: 'WIKI/GET_CONTEUDOS_SUCCESS',
  GET_CONTEUDOS_ERROR: 'WIKI/GET_CONTEUDOS_ERROR',

  GET_CONTEUDO: 'WIKI/GET_CONTEUDO',
  GET_CONTEUDO_SUCCESS: 'WIKI/GET_CONTEUDO_SUCCESS',
  GET_CONTEUDO_ERROR: 'WIKI/GET_CONTEUDO_ERROR',

  GET_AUDIENCIA: 'WIKI/GET_AUDIENCIA',
  GET_AUDIENCIA_SUCCESS: 'WIKI/GET_AUDIENCIA_SUCCESS',
  GET_AUDIENCIA_ERROR: 'WIKI/GET_AUDIENCIA_ERROR',

  GET_FEEDBACK: 'WIKI/GET_FEEDBACK',
  GET_FEEDBACK_SUCCESS: 'WIKI/GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_ERROR: 'WIKI/GET_FEEDBACK_ERROR',

  CHANGE_VISIBLE_AUDIENCIA: 'WIKIS/CHANGE_VISIBLE_AUDIENCIA',
  CHANGE_VISIBLE_CONFIRMACOES: 'WIKIS/CHANGE_VISIBLE_CONFIRMACOES',
  CHANGE_VISIBLE_INFORMACOES: 'WIKIS/CHANGE_VISIBLE_INFORMACOES',
  CHANGE_VISIBLE_NEW_FEEDBACK: 'WIKIS/CHANGE_VISIBLE_NEW_FEEDBACK',
  CHANGE_FAVORITE: 'WIKIS/CHANGE_FAVORITE',
  CHANGE_READ: 'WIKIS/CHANGE_READ',
};

//Action Creators
export const getConteudos = (idCategoriaPai = -1, filtros) => ({ type: Types.GET_CONTEUDOS, idCategoriaPai, filtros });
export const getConteudosSuccess = (conteudos) => ({ type: Types.GET_CONTEUDOS_SUCCESS, conteudos });
export const getConteudosError = (error) => ({ type: Types.GET_CONTEUDOS_ERROR, error });

export const getConteudo = (id) => ({ type: Types.GET_CONTEUDO, id });
export const getConteudoSuccess = (conteudo) => ({ type: Types.GET_CONTEUDO_SUCCESS, conteudo });
export const getConteudoError = (error) => ({ type: Types.GET_CONTEUDO_ERROR, error });

export const getAudiencia = (id) => ({ type: Types.GET_AUDIENCIA, id });
export const getAudienciaSuccess = (audiencias) => ({ type: Types.GET_AUDIENCIA_SUCCESS, audiencias });
export const getAudienciaError = (error) => ({ type: Types.GET_AUDIENCIA_ERROR, error });

export const getFeedback = (id) => ({ type: Types.GET_FEEDBACK, id });
export const getFeedbackSuccess = (feedbacks) => ({ type: Types.GET_FEEDBACK_SUCCESS, feedbacks });
export const getFeedbackError = (error) => ({ type: Types.GET_FEEDBACK_ERROR, error });

export const changeVisibleAudiencia = (visible, id, titulo) => ({ type: Types.CHANGE_VISIBLE_AUDIENCIA, visible, id, titulo });
export const changeVisibleConfirmacoes = (visible, id, titulo) => ({ type: Types.CHANGE_VISIBLE_CONFIRMACOES, visible, id, titulo });
export const changeVisibleInformacoes = (visible, item) => ({ type: Types.CHANGE_VISIBLE_INFORMACOES, visible, item });
export const changeVisibleNewFeedback = (visible, id, callback) => ({ type: Types.CHANGE_VISIBLE_NEW_FEEDBACK, visible, id, callback });
export const changeFavorite = (favorite, id) => ({ type: Types.CHANGE_FAVORITE, favorite, id });
export const changeRead = (read, id) => ({ type: Types.CHANGE_READ, read, id });

//async await functions
export const apiGetAudiencia = async (id) => {
  try {
    const audiencias = await getAudienciaSdk(id);
    return audiencias;
  } catch (err) {
    Notification.error(err.message);
  }
};

export const apiGetFeedback = async (id) => {
  try {
    const feedbacks = await getFeedbackSdk(id);
    return feedbacks;
  } catch (err) {
    Notification.error(err.message);
  }
};

//saga
function* fetchGetConteudos(action) {
  try {
    const { idCategoriaPai, filtros } = action;

    const conteudos = yield call(getConteudosSdk, idCategoriaPai, filtros);
    yield put(getConteudosSuccess(conteudos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getConteudosError(err));
  }
}

function* fetchGetConteudo(action) {
  try {
    const { id } = action;

    const conteudo = yield call(getConteudoSdk, id);
    yield put(getConteudoSuccess(conteudo));
  } catch (err) {
    Notification.error(err.message);
    yield put(getConteudoError(err));
  }
}

function* fetchGetAudiencia(action) {
  try {
    const { id } = action;

    const audiencias = yield call(getAudienciaSdk, id);
    yield put(getAudienciaSuccess(audiencias));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAudienciaError(err));
  }
}

function* fetchGetFeedback(action) {
  try {
    const { id } = action;

    const feedbacks = yield call(getFeedbackSdk, id);
    yield put(getFeedbackSuccess(feedbacks));
  } catch (err) {
    Notification.error(err.message);
    yield put(getFeedbackError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CONTEUDOS, fetchGetConteudos),
  takeLatest(Types.GET_CONTEUDO, fetchGetConteudo),
  takeLatest(Types.GET_AUDIENCIA, fetchGetAudiencia),
  takeLatest(Types.GET_FEEDBACK, fetchGetFeedback),
];

// Reducer
const initialState = Map({
  conteudos: List(),
  extras: Map(),
  conteudo: Map(),
  audiencias: List(),
  feedbacks: List(),

  loadingConteudos: false,
  successConteudos: false,
  errorConteudos: false,

  loadingConteudo: false,
  successConteudo: false,
  errorConteudo: false,

  loadingAudiencia: false,
  successAudiencia: false,
  errorAudiencia: false,

  loadingFeedback: false,
  successFeedback: false,
  errorFeedback: false,

  visibleAudiencia: false,
  visibleConfirmacoes: false,
  visibleInformacoes: false,
  visibleNewFeedback: false,
  audienciaParams: {},
  confirmacoesParams: {},
  informacoesParams: {},
  newFeedbackParams: {}
});

const handleGetConteudos = (state, action) => {
  return state.set('loadingConteudos', true).set('successConteudos', false).set('errorConteudos', false);
};

const handleGetConteudosSuccess = (state, action) => {
  const { list, ...extras } = action.conteudos;

  return state
    .set('conteudos', List(list))
    .set('extras', Map(extras))
    .set('loadingConteudos', false)
    .set('successConteudos', true)
    .set('errorConteudos', false);
};

const handleGetConteudosError = (state, action) => {
  return state.set('loadingConteudos', false).set('successConteudos', false).set('errorConteudos', action.error);
};

const handleGetConteudo = (state, action) => {
  return state.set('loadingConteudo', true).set('successConteudo', false).set('errorConteudo', false);
};

const handleGetConteudoSuccess = (state, action) => {
  return state.set('conteudo', Map(action.conteudo)).set('loadingConteudo', false).set('successConteudo', true).set('errorConteudo', false);
};

const handleGetConteudoError = (state, action) => {
  return state.set('loadingConteudo', false).set('successConteudo', false).set('errorConteudo', action.error);
};

const handleGetAudiencia = (state, action) => {
  return state.set('loadingAudiencia', true).set('successAudiencia', false).set('errorAudiencia', false);
};

const handleGetAudienciaSuccess = (state, action) => {
  return state
    .set('audiencias', List(action.audiencias))
    .set('loadingAudiencia', false)
    .set('successAudiencia', true)
    .set('errorAudiencia', false);
};

const handleGetAudienciaError = (state, action) => {
  return state.set('loadingAudiencia', false).set('successAudiencia', false).set('errorAudiencia', action.error);
};

const handleGetFeedback = (state, action) => {
  return state.set('loadingFeedback', true).set('successFeedback', false).set('errorFeedback', false);
};

const handleGetFeedbackSuccess = (state, action) => {
  return state
    .set('feedbacks', List(action.feedbacks))
    .set('loadingFeedback', false)
    .set('successFeedback', true)
    .set('errorFeedback', false);
};

const handleGetFeedbackError = (state, action) => {
  return state.set('loadingFeedback', false).set('successFeedback', false).set('errorFeedback', action.error);
};

const handleChangeVisibleAudiencia = (state, action) => {
  const { visible, id, titulo } = action;

  return state.set('visibleAudiencia', visible).set('audienciaParams', { id, titulo });
};

const handleChangeVisibleConfirmacoes = (state, action) => {
  const { visible, id, titulo } = action;

  return state.set('visibleConfirmacoes', visible).set('confirmacoesParams', { id, titulo });
};

const handleChangeVisibleInformacoes = (state, action) => {
  const { visible, item } = action;

  return state.set('visibleInformacoes', visible).set('informacoesParams', { item });
};

const handleChangeVisibleNewFeedback = (state, action) => {
  const { visible, id, callback } = action;

  return state.set('visibleNewFeedback', visible).set('newFeedbackParams', { id, callback });
};

const handleChangeFavorite = (state, action) => {
  const { favorite, id } = action;

  return state.set('conteudos', state.get('conteudos').map((item) => item.id === id ? ({ ...item, favorito: favorite }) : ({ ...item })));
};

const handleChangeRead = (state, action) => {
  const { read, id } = action;

  return state.set('conteudos', state.get('conteudos').map((item) => item.id === id ? ({ ...item, lido: read }) : ({ ...item })));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONTEUDOS:
      return handleGetConteudos(state, action);
    case Types.GET_CONTEUDOS_SUCCESS:
      return handleGetConteudosSuccess(state, action);
    case Types.GET_CONTEUDOS_ERROR:
      return handleGetConteudosError(state, action);

    case Types.GET_CONTEUDO:
      return handleGetConteudo(state, action);
    case Types.GET_CONTEUDO_SUCCESS:
      return handleGetConteudoSuccess(state, action);
    case Types.GET_CONTEUDO_ERROR:
      return handleGetConteudoError(state, action);

    case Types.GET_AUDIENCIA:
      return handleGetAudiencia(state, action);
    case Types.GET_AUDIENCIA_SUCCESS:
      return handleGetAudienciaSuccess(state, action);
    case Types.GET_AUDIENCIA_ERROR:
      return handleGetAudienciaError(state, action);

    case Types.GET_FEEDBACK:
      return handleGetFeedback(state, action);
    case Types.GET_FEEDBACK_SUCCESS:
      return handleGetFeedbackSuccess(state, action);
    case Types.GET_FEEDBACK_ERROR:
      return handleGetFeedbackError(state, action);

    case Types.CHANGE_VISIBLE_AUDIENCIA: return handleChangeVisibleAudiencia(state, action);
    case Types.CHANGE_VISIBLE_CONFIRMACOES: return handleChangeVisibleConfirmacoes(state, action);
    case Types.CHANGE_VISIBLE_INFORMACOES: return handleChangeVisibleInformacoes(state, action);
    case Types.CHANGE_VISIBLE_NEW_FEEDBACK: return handleChangeVisibleNewFeedback(state, action);
    case Types.CHANGE_FAVORITE: return handleChangeFavorite(state, action);
    case Types.CHANGE_READ: return handleChangeRead(state, action);

    default:
      return state;
  }
}
