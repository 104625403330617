import styled from 'styled-components';
import { Tree } from 'antd';

export const StyledTree = styled(Tree)`
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
  }
  &.ant-tree {
    background-color: transparent;

    .ant-tree-treenode {
      align-items: center;
      gap: 19px;
      padding-bottom: 9px;

      .ant-tree-node-content-wrapper {
        gap: 10px;
        padding: 0;
        :hover {
          background-color: transparent;
        }
      }
    }

    .ant-tree-switcher, .ant-tree-iconEle, .ant-tree-title {
      color: ${({ theme }) => theme.textColor};

      &.ant-tree-switcher_close {
        transform: rotate(-90deg);
      }
    }
  }
`;