import React from 'react';
import { StyledStatusTag, StyledText } from './styles';

const StatusTag = ({ text, icon, size = 'small', ...props }) => (
  <StyledStatusTag size={size} {...props}>
    <StyledText size={size}>{text}</StyledText>
    {icon}
  </StyledStatusTag>
);

export default StatusTag;