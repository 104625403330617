import styled from 'styled-components';
import { Button, Form, Row } from '@components-teammove';

export const FlexDivRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

export const QuestionActionButton = styled(Button)`
  &.ant-btn {
    padding: 20px;
  }
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const NewQuestionButton = styled(Button)`
  &.ant-btn {
    margin: 0 auto 1rem;
  }
`;

export const DivPicker = styled(Form.Item)`
  margin: auto 0;
`;

export const ActionContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  gap: 5px;
`;

export const ColorFormItem = styled(Form.Item)`
  max-width: 56px;
`;

export const SelfAlignEndFormItem = styled(Form.Item)`
  margin-left: auto;
`;

export const RedStar = styled.span`
  color: #ff4d4f;
`;

export const RowDescription = styled(Row)`
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  margin-bottom: 5px;
`;