import styled from 'styled-components';
import { Row, Text, Select, Button, Form, Skeleton, Empty, Icon } from '@components-teammove';

export const Header = styled(Row)`
	padding: 0 12px 0 10px;
	background-color: ${({ theme })=> theme.darkHighlightColor};
	height: 60px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`;

export const Panel = styled(Row)`
	align-items: center;	
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	padding: 20px 17px 20px 17px;
	background-color: ${({ theme })=> theme.darkSecondaryColor};
	justify-content: space-between;
`;

export const FeatherIcon = styled(Icon.Feather)`
	color: #fff !important;
`;

export const SelectStyle = styled(Select)`
	border: 2px solid ${(props) => props.theme.darkPrimaryColor} !important;
	.ant-select-selector {
		height: 37px !important;
	}
	width: 281px !important;
`;

export const YearText = styled(Text)`
	font-size: 16px;
	font-weight: 600;
`;

export const YearButton = styled(Button)`
	padding: 6px 6px !important;
`;

export const FormItemStyles = styled(Form.Item)`
	padding-top: 17px;
`;

export const StyleEmpty = styled(Empty)`
  border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 459px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const SkeletonStyle = styled(Skeleton.Map)`
	height: 10px;
`;