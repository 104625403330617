import styled from 'styled-components';
import { View, Text } from '@components';
import { Icon } from '@components-teammove';

export const MinorGap = styled(View)`
  gap: 12px;
`;

export const GapView = styled(View)`
  gap: 40px;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const Row = styled(View)`
  flex-direction: row;
`;

export const Container = styled(Row)`
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
`;

export const ActivityView = styled(Row)`
  user-select: none;
  border-radius: 12px;
  align-items: center;
  background: ${(props) => props.theme.darkPrimaryColor};
  width: calc((100% / 3) - 10px);
  height: 85px;
  gap: 12px;
  cursor: pointer;

  :active {
    position: relative;
    z-index: 1;
    cursor: grabbing;
  }

  ${({ inactive }) => inactive && `
    opacity: .5;
  `}

  @media screen and (max-width: 1220px) {
    width: calc((100% / 2) - 10px);
  }
  @media screen and (max-width: 760px) {
    width: 100%;
  }
`;

export const ActivityIconView = styled(ActivityView)`
  background-color: ${({ bgColor }) => bgColor};
  width: 85px;
  height: 85px;
  justify-content: center;
`;

export const ActivityIcon = styled(Icon.MDI)`
  color: ${(props) => props.theme.textColor};
  width: 40px !important;
  height: 40px !important;
`;

export const ActivityName = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.textColor} !important;
`;

export const ActivityStatusText = styled(ActivityName)`
  font-size: 16px;
`;