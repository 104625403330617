export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Usuários',
    name: 'users',
    placeholder: 'Selecione os Usuários',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Classificações',
    name: 'classifications',
    placeholder: 'Selecione as classificações',
    options: [
      { value: 'Relevante', label: 'Relevante' },
      { value: 'Nao_Relevante', label: 'Não Relevante' },
      { value: 'Sem_Contato', label: 'Sem Contato' },
    ],
  }
];
