export const createDatasource = (tableValidation) => {
  return tableValidation.map((row) => {
    const rowData = { importCompanyRow: row.importCompanyRow };
    row.values.forEach((value) => {
      const key = value.importCompanyKey;
      rowData[`${key}_value`] = value.value;
      rowData[`${key}_importCompanyError`] = value.importCompanyError;
      rowData[`${key}_importCompanyValueId`] = value.importCompanyValueId;
    });
    return rowData;
  });
};