import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Filler, ArcElement, Legend } from 'chart.js';

const LineChart = ({ data, options, ...props }) => {
  Chart.register(Filler, ArcElement, Legend);

  return (
    <Line data={data} options={options} />
  );
};

export default LineChart;