import React from 'react';
import { View, Avatar, Skeleton, Column } from '@components-teammove';
import { InfiniteScroll } from '@components';
import { FILTERS_KEY } from '@ducks/wikisAudiencia';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { moment, formatDate, getDiffDays, Filtros } from '@utils';
import { Container, Date, DateInfo, Foto, MainView, Name, Profile, UsuarioContainer, ViewAcesso, Path, Category, ViewDados, ViewDadosAcesso, ViewDadosConteúdo, ViewDadosUsuario, ViewData, ViewDataHora, ViewHoje, Hour, ViewOntem, ViewUsuario, FeatherIcon } from './styles';

export default function Timeline({ search }) {
  const history = useHistory();

  const audienciasUltimosAcessos = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('audienciasUltimosAcessos').toArray());
  const loadingUltimosAcessos = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('loadingUltimosAcessos'));
  const hasMoreUltimosAcessos = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('hasMoreUltimosAcessos'));
  const xCurrentPageUltimosAcessos = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('xCurrentPageUltimosAcessos'));

  const filtros = Filtros.get(FILTERS_KEY);

  const handleInfiniteOnLoad = () => {
    if (!hasMoreUltimosAcessos) {
      Notification.info('Todos os acessos foram retornados');
    } else {
      search(xCurrentPageUltimosAcessos + 1, filtros);
    }
  };

  const getPeriodo = (item, prevItem) => {
    if (prevItem) {
      if (formatDate(item.dataFim) === formatDate(prevItem.dataFim)) {
        return;
      }
    }

    if (formatDate(item.dataFim) === formatDate(moment())) {
      return <ViewHoje>Hoje</ViewHoje>;
    } else if (formatDate(item.dataFim) === formatDate(moment().subtract(1, 'day'))) {
      return <ViewOntem>Ontem</ViewOntem>;
    } else {
      return (
        <Column align='flex-start'>
          <Date>{formatDate(item.dataFim)}</Date>
          <DateInfo>Há {getDiffDays(item.dataFim)} dias</DateInfo>
        </Column>
      );
    }
  };

  const getUsuario = (item, prevItem) => {
    if (!prevItem || item.cdUsuario !== prevItem.cdUsuario || formatDate(item.dataFim) !== formatDate(prevItem.dataFim)) {
      return (
        <ViewUsuario>
          <Foto>
            <Avatar src={item.avatarURL} />
          </Foto>
          <View>
            <UsuarioContainer bordercolor={item.corPerfil}>
              <Name>{item.nmUsuario}</Name>
              <Profile>{item.dsPerfil}</Profile>
            </UsuarioContainer>
          </View>
        </ViewUsuario>
      );
    }
  };

  return (
    <Container>
      {loadingUltimosAcessos && xCurrentPageUltimosAcessos === 0 ? (
        <Skeleton.Map/>
      ) : (
        <InfiniteScroll
          threshHold={0.75}
          dataLength={audienciasUltimosAcessos.length}
          next={handleInfiniteOnLoad}
          hasMore={!loadingUltimosAcessos && hasMoreUltimosAcessos}
        >
          {audienciasUltimosAcessos.map((item, index, arr) => {
            const previousItem = arr[index - 1];
            const categoriaSplit = item.dsCategoria.split(' / ');
            const categoria = categoriaSplit.pop();
            const caminho = categoriaSplit.join(' / ');

            return (
              <MainView key={item.idAudiencia} dadoAntigo={getDiffDays(item.dataFim) > 90}>
                <ViewDados>
                  <ViewDadosUsuario>
                    <ViewDataHora>
                      <ViewData>{getPeriodo(item, previousItem)}</ViewData>
                    </ViewDataHora>
                    {getUsuario(item, previousItem)}
                  </ViewDadosUsuario>
                  <ViewDadosConteúdo>
                    <ViewDadosAcesso>
                      <FeatherIcon type='clock'/> &nbsp;
                      <Hour>{item.dataFim.format('HH:mm')}</Hour>
                    </ViewDadosAcesso>
                    <ViewAcesso onClick={() => history.push(`/conteudos/visualizacao/${item.idCategoria}`)}>
                      <Category>{categoria}</Category>
                      <Path>{caminho}</Path>
                    </ViewAcesso>
                  </ViewDadosConteúdo>
                </ViewDados>
              </MainView>
            );
          })}
        </InfiniteScroll>
      )}
    </Container>
  );
}
