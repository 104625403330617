import styled from 'styled-components';
import { Text, Row, View } from '@components-teammove';

export const ContainerItem = styled(Row)`
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 17px 12px 12px;
    width: 100%;
    margin-bottom: ${({ lastItem }) => lastItem ? 0 : '12px'};
`;

export const TitleText = styled(Text)`
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: ${({ opacity }) => opacity ? 0.5 : 1};
`;

export const NumberText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: 0.5;
`;

export const CallButton = styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.actionColor};
    border-radius: 8px;
    padding: 6px 16px;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.actionColor};
    }
`;

export const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.textColor};
`;

export const TagLabel = styled(View)`
    background-color: ${({ tagCor }) => tagCor};
    border-radius: 8px;
    padding: 4px 8px;
    width: fit-content;
    align-items: center;
    justify-content: center;
`;

export const ContainerGrid = styled(View)`
    width: ${({ callButton }) => callButton ? 30 : 25}%;
    align-items: ${({ flexStart, flexEnd }) => flexStart ? 'flex-start' : flexEnd ? 'flex-end' : 'center'};
    justify-content: center;
`;

export const EmptyDiv = styled(View)`
    width: 25%;
`;

export const LabelText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.textColor};
`;