/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Form, Input, Field, InputNumber, GroupField, View, Collapse, Select } from '@components';
import { Icon } from '@components-teammove';
import styled from 'styled-components';
import { selectPergunta } from '@containers/Wikis/EAD/Quiz/rules';
import TipoRespostas from './TipoRespostas';
import { getMinId } from '@utils/Array';
import { setPerguntasOpcoes } from '@ducks/wikisEADQuiz';
import { useDispatch, useSelector } from 'react-redux';

const MainView = styled(View)`
  border-bottom: 2px solid #aaa;
  margin-bottom: 10px;
`;

const IconDelete = styled(Icon.DeleteOutlined)`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const AdicionarFeedback = styled(View)`
  width: fit-content;
  padding: 10px 10px 10px 0;
`;

const StyledField = styled(Field)`
  margin-bottom: 0;
`;

export default function Perguntas({ formRef, perguntas }) {
  const dispatch = useDispatch();
  const [tpResposta, setTpResposta] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (!perguntas) return;

    let tpRespostasArray = [];

    perguntas.forEach((pergunta) => {
      tpRespostasArray = [...tpRespostasArray, pergunta.tp];
    });

    if (isMounted) setTpResposta(tpRespostasArray);

    return () => { isMounted = false }
  }, [perguntas]);

  const opcoesPerguntas = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('opcoes').toArray());

  const handleChangeTpReposta = (tp, index) => {
    const tpRespostas = [...tpResposta];
    tpRespostas[index] = tp;
    setTpResposta(tpRespostas);
  };

  const handleAddPergunta = (add) => {
    add();
    setTimeout(() => {
      const quiz = formRef.getFieldsValue();

      const minId = getMinId(quiz.perguntas);

      quiz.perguntas[quiz.perguntas.length - 1].id = minId;

      formRef.setFieldsValue(quiz);
    }, 10);
  };

  const handleRemovePergunta = (remove, index) => {
    const id = getIdPergunta(index);
    const opcoesCopy = [...opcoesPerguntas].filter((op) => op.idPergunta !== undefined && op.idPergunta !== id);

    dispatch(setPerguntasOpcoes(opcoesCopy));
    remove();
  };

  const getIdPergunta = (index) => {
    const perguntas = formRef.getFieldsValue()?.perguntas;

    return perguntas?.length > 0 ? perguntas[index - 1]?.id : -1;
  };

  return (
    <Collapse defaultActiveKey='perguntas-2'>
      <Collapse.Panel header={<strong>Perguntas</strong>} key='perguntas-2'>
        <Form.List name='perguntas'>
          {(fields, { add, remove }) => {
            let index = 0;
            return (
              <>
                {fields.map((field) => {
                  index += 1;
                  return (
                    <MainView key={`pergunta-${field.fieldKey}`}>
                      <View>
                        <StyledField>
                          <strong>Pergunta {index}</strong>
                        </StyledField>
                      </View>

                      <View horizontal>
                        <View flex={1}>
                          <GroupField>
                            <Field
                              label='Pergunta'
                              name={[field.name, 'pergunta']}
                              flex={8}
                              rules={[{ required: true, message: 'Informe a pergunta' }]}
                            >
                              <Input />
                            </Field>
                            <Field
                              label='Peso'
                              name={[field.name, 'peso']}
                              rules={[{ required: true, message: 'Informe o peso da pergunta' }]}
                            >
                              <InputNumber min={0.01} max={999.99} step={0.01} />
                            </Field>
                          </GroupField>
                          <Field
                            label='Tipo de resposta'
                            name={[field.name, 'tp']}
                            rules={[{ required: true, message: 'Selecione um tipo de resposta' }]}
                          >
                            <Select
                              form={formRef}
                              name='tp'
                              action={async () => selectPergunta}
                              placeholder='Selecione um tipo de resposta'
                              onChange={(event) => handleChangeTpReposta(event, field.fieldKey)}
                            />
                          </Field>
                          <TipoRespostas
                            tp={tpResposta[field.fieldKey]}
                            idPergunta={getIdPergunta(index)}
                            setTpResposta={setTpResposta}
                            opcoesCadastradas={perguntas ? perguntas[field.fieldKey]?.opcoes : undefined}
                          />
                          <GroupField>
                            <Field label='Feedback positivo' name={[field.name, 'feedbackPositivo']}>
                              <Input.TextArea rows={2} />
                            </Field>
                            <Field label='Feedback negativo' name={[field.name, 'feedbackNegativo']}>
                              <Input.TextArea rows={2} />
                            </Field>
                          </GroupField>
                        </View>
                        <View justifyContent='center'>
                          <IconDelete onClick={() => handleRemovePergunta(() => remove(field.name), index)} className='action-element' />
                        </View>
                      </View>
                    </MainView>
                  );
                })}
                <StyledField>
                  <AdicionarFeedback
                    className='action-element'
                    onClick={() => handleAddPergunta(() => add())}
                    horizontal
                    alignItems='center'
                  >
                    <Icon.PlusOutlined />
                    &nbsp;Pergunta
                  </AdicionarFeedback>
                </StyledField>
              </>
            );
          }}
        </Form.List>
      </Collapse.Panel>
    </Collapse>
  );
}
