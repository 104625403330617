import React, { useEffect, useMemo, useState } from 'react';
import { Schedule, Checkbox } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getAtividades } from '@ducks/atividades';
import { getAtendimento } from '@ducks/configuracoesAtendimento';
import { moment } from '@utils';
import { useHistory } from 'react-router-dom';
import { formatActivitiesToEvents, getDateBetweenTwoDates, getLastSunday, getNextSaturday } from './rules';
import { ContainerCheckbox } from './styles';

const VIEW_MODE_KEY = 'schedule_view_mode';
const DATE_KEY = 'schedule_date';

export default function ScheduleView({ filtros, changeParams, customOptions, baseDate, baseView, workingJourneyId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const activities = useSelector(({ atividades }) => atividades.get('atividades'));
  const loading = useSelector(({ atividades }) => atividades.get('loadingAtividades'));
  const success = useSelector(({ atividades }) => atividades.get('successAtividades'));
  const search = useSelector(({ agenda }) => agenda.get('search'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const [date, setDate] = useState(baseDate || localStorage.getItem(DATE_KEY) || new Date());
  const [view, setView] = useState(baseView || localStorage.getItem(VIEW_MODE_KEY) || 'month');
  const [ action, setAction] = useState();
  
  const [periodFilterActive, setPeriodFilterActive] = useState(false);

  const [visibleAllActivities, setVisibleAllActivities] = useState(
    localStorage.getItem('visibleAllActivities') === 'true'
  );

  const activitiesFiltered = useMemo(() => {
    if(success) {
      return visibleAllActivities ? activities : activities.filter((activity) => activity.status !== 'EXCLUIDA');
    }
    return [];
  }, [success, visibleAllActivities]);

  useEffect(() => {
    localStorage.setItem('visibleAllActivities', visibleAllActivities);
  }, [visibleAllActivities]);

  useEffect(() => {
    const newFilters = {
      period: view === 'week' ? [
        moment(date).startOf('week'), moment(date).endOf('week')
      ] : view === 'month' ? [
        getLastSunday(moment(date).startOf('month')), getNextSaturday(moment(date).endOf('month'))
      ] : [
        moment(date).startOf('day'), moment(date).endOf('day')
      ],
      ...(filtros && Object.keys(filtros).some((key) => filtros[key]) ? filtros : {}), 
    };
    localStorage.setItem(DATE_KEY, date);
    if(changeParams) changeParams({ ...newFilters, view, action, date });
    dispatch(getAtividades({ filtros: newFilters, search }));

    return () => {
      localStorage.removeItem(DATE_KEY);
    };
  }, [view, date, filtros, search, successSave]);

  useEffect(() => {
    if(filtros && filtros.period) {
      setDate(getDateBetweenTwoDates(filtros.period[0], filtros.period[1]));
      setPeriodFilterActive(true);
    } else {
      if(periodFilterActive) {
        setPeriodFilterActive(false);
        setDate(new Date());
      }
    }
  }, [filtros]);

  useEffect(() => {
    if(workingJourneyId) {
      dispatch(getAtendimento(workingJourneyId));
    }
  }, [workingJourneyId]);

  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem(VIEW_MODE_KEY, newView);
  };

  return(
    <>
      <Schedule 
        events={formatActivitiesToEvents(activitiesFiltered)} 
        date={date}
        setDate={setDate}
        min={new Date(filtros?.period?.at(0))}
        max={new Date(filtros?.period?.at(1))}
        onNavigate={(newDate) => setDate(newDate)}
        view={view}
        setView={handleViewChange}
        loading={loading}
        onSelectEvent={(event) => history.push(`/atividades/agenda/${event.id}`)}
        customOptions={customOptions}
        setAction={setAction}
      />
      <ContainerCheckbox>
        <Checkbox checked={visibleAllActivities} onChange={(checked) => setVisibleAllActivities(checked)}>Vizualizar atividades canceladas</Checkbox>
      </ContainerCheckbox>
    </>
  );
}