import { get, post } from '../../api';
import { ansiToMoment, InfoUsuario } from '@utils';

const mainPath = '/api/wiki/comentarios';

const postComentario = async ({ id, idCategoria, cdUsuario, comentario, status = 'A' }) => {
  const postObject = {
    id,
    idCategoria,
    cdUsuario,
    comentario,
    status,
    dtCriacao: new Date(),
    dtAlteracao: new Date(),
  };

  const result = await post(`${mainPath}/salvar`, postObject);

  postObject.id = result.id;
  return postObject;
};

const editComentario = async ({ id, comentario }) => {
  return await postComentario({ id, comentario });
};

const deleteComentario = async ({ id }) => {
  return await postComentario({ id, status: 'I' });
};

const getComentariosConteudo = async ({ idCategoria }) => {
  const { cdUsuario } = InfoUsuario.get();
  const comentarios = await get(`${mainPath}/${idCategoria}?usuario=${cdUsuario}`);

  // eslint-disable-next-line
  comentarios.map((comentario) => {
    comentario.dtCriacao = ansiToMoment(comentario.dtCriacao);
    comentario.dtAlteracao = ansiToMoment(comentario.dtAlteracao);
    comentario.usuarioLogado = cdUsuario === comentario.cdUsuario;
  });

  return comentarios;
};

export { postComentario, editComentario, deleteComentario, getComentariosConteudo };
