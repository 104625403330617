import { Button } from '@components-teammove';
import Icon from '../../Icon';
import styled from 'styled-components';

export const DirectionButton = styled(Button)`
	padding: 6px 6px !important;
`;

export const FeatherIcon = styled(Icon.Feather)`
	color: #fff !important;
`;