import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { setInfoUsuario } from '@ducks/login';
import {
  getPerfil as getPerfilSdk,
  savePerfil as savePerfilSdk,
} from '@sdk/Perfil';

//Action Types
export const Types = {
  GET_PERFIL: 'PERFIL/GET_PERFIL',
  GET_PERFIL_SUCCESS: 'PERFIL/GET_PERFIL_SUCCESS',
  GET_PERFIL_ERROR: 'PERFIL/GET_PERFIL_ERROR',

  UPDATE_PERFIL: 'PERFIL/UPDATE_PERFIL',
  UPDATE_PERFIL_SUCCESS: 'PERFIL/UPDATE_PERFIL_SUCCESS',
  UPDATE_PERFIL_ERROR: 'PERFIL/UPDATE_PERFIL_ERROR',

  RESET: 'PERFIL/RESET',
};

//Action Creators
export const getPerfil = (idUsuario, headers) => ({ type: Types.GET_PERFIL, idUsuario, headers });
export const getPerfilSuccess = (perfil) => ({ type: Types.GET_PERFIL_SUCCESS, perfil });
export const getPerfilError = (error) => ({ type: Types.GET_PERFIL_ERROR, error });

export const updatePerfil = (idUsuario, perfil, headers) => ({ type: Types.UPDATE_PERFIL, idUsuario, perfil, headers });
export const updatePerfilSuccess = (data) => ({ type: Types.UPDATE_PERFIL_SUCCESS, data });
export const updatePerfilError = (error) => ({ type: Types.UPDATE_PERFIL_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetPerfil(action) {
  try {
    const { idUsuario, headers } = action;

    const perfil = yield call(getPerfilSdk, idUsuario, headers);
    yield put(getPerfilSuccess(perfil));
  } catch (err) {
    Notification.error(err.message);
    yield put(getPerfilError(err));
  }
}

function* fetchUpdatePerfil(action) {
  try {
    const { idUsuario, perfil, headers } = action;

    const retorno = yield call(savePerfilSdk, idUsuario, perfil, headers);
    yield put(setInfoUsuario(retorno));
    yield put(updatePerfilSuccess(perfil));
  } catch (err) {
    Notification.error(err.message);
    yield put(updatePerfilError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_PERFIL, fetchGetPerfil),
  takeLatest(Types.UPDATE_PERFIL, fetchUpdatePerfil),
];

// Reducer
const initialState = Map({
  perfil: {},

  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetPerfil = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetPerfilSuccess = (state, action) => {
  return state
    .set('perfil', action.perfil)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetPerfilError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleUpdatePerfil = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};

const handleUpdatePerfilSuccess = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleUpdatePerfilError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PERFIL: return handleGetPerfil(state, action);
    case Types.GET_PERFIL_SUCCESS: return handleGetPerfilSuccess(state, action);
    case Types.GET_PERFIL_ERROR: return handleGetPerfilError(state, action);

    case Types.UPDATE_PERFIL: return handleUpdatePerfil(state, action);
    case Types.UPDATE_PERFIL_SUCCESS: return handleUpdatePerfilSuccess(state, action);
    case Types.UPDATE_PERFIL_ERROR: return handleUpdatePerfilError(state, action);

    case Types.RESET: return initialState;

    default: return state;
  }
}
