import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Text, View, Collapse } from '@components-teammove';

const CollapseSmall = styled(Collapse)`
  .ant-collapse-header {
    padding: 5px 16px !important;
    padding-left: 40px !important;
  }
`;

export default function UsuariosEnvio({ visible, onCancel, usuarios, unidades = [], perfis = [], usuariosEspecificos = [] }) {
  
  function subStrAfterChars(str, char, pos) {
    if (pos === 'b') return str.substring(str.indexOf(char) + 1);
    else if (pos === 'a') return str.substring(0, str.indexOf(char));
    else return str;
  }

  const usuariosEnvio = useMemo(() => {
    if (unidades.length !== 0 || perfis.length !== 0) {
      return usuarios.filter(
        (usuario) =>
          (perfis.length === 0 || perfis.includes(usuario.idPerfil)) &&
        (unidades.length === 0 || unidades.some((unidade) => usuario.unidades && usuario.unidades.includes(unidade.toString())))
      );
    } else if (usuariosEspecificos.length !== 0) {
      return usuarios.filter((usuario) => usuariosEspecificos.includes(usuario.key));
    } else {
      return [];
    }
  }, [usuarios, perfis, unidades, usuariosEspecificos]);
  
  const perfisUnicos = useMemo(() => {
    const usuariosEnvioOrdenado = usuariosEnvio.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

    const perfisDuplicados = usuariosEnvioOrdenado.map((usuario) => ({
      idPerfil: usuario.idPerfil,
      nmPerfil: subStrAfterChars(usuario.title, '- ', 'b'),
    }));

    return perfisDuplicados.filter(
      (perfil, index, self) => index === self.findIndex((perfil2) => perfil2.idPerfil === perfil.idPerfil)
    );
  }, [usuariosEnvio]);

  const perfisUnicosComUsuariosOrdenados = useMemo(() => {
    const perfisUnicosComUsuarios = perfisUnicos.map((perfil) => ({
      ...perfil,
      usuarios: usuariosEnvio.filter((usuario) => usuario.idPerfil === perfil.idPerfil),
    }));

    return perfisUnicosComUsuarios.sort((a, b) =>
      a.nmPerfil > b.nmPerfil ? 1 : b.nmPerfil > a.nmPerfil ? -1 : 0
    );
  }, [perfisUnicos]);

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      title='Quem está recebendo?'
    >
      <View>
        <CollapseSmall defaultActiveKey={perfisUnicos.map((perfil) => perfil.idPerfil)}>
          {perfisUnicosComUsuariosOrdenados.map((perfil) => (
            <Collapse.Panel header={`${perfil.nmPerfil} (${perfil.usuarios.length})`} key={perfil.idPerfil}>
              {perfil.usuarios.map((usuario) => (
                <View key={usuario.key}>
                  <Text>{subStrAfterChars(usuario.title, ' -', 'a')}</Text>
                </View>
              ))}
            </Collapse.Panel>
          ))}
        </CollapseSmall>
      </View>
    </Modal>
  );
}
