import styled from 'styled-components';
import { Text, Row, View, Column, Icon } from '@components-teammove';

import { Empty } from 'antd';
import { darken } from 'polished';

export const ContentActivityHistory = styled(View)`
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`;

export const ContentPending = styled(View)`
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100%;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 12px;
`;

export const ContentCompleted = styled(View)`
  width: 100%;
  height: 200px;
  gap: 8px;
  padding-right: 16px;
`;

export const PendingTitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding-left: 12px;
`;

export const CardStyle = styled(Row)`
  overflow-y: ellipsis;
  background-color: ${({ theme }) => theme.darkPrimaryColor}; 
  color: ${({ theme }) => theme.textColor};
  opacity: ${({ concluded }) => concluded ? '0.7' : '1'};
  border-radius: 12px;
  border-left: 12px solid ${({ color }) => (color ? color : '#fff')};
  padding: 12px 12px 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  justify-content: flex-end;

`;

export const PerfilName = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
`;

export const PerfilReponsability = styled(Text)`
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
`;

export const AvatarStyle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 19px;
`;

export const EventType = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

export const ContentDateDay = styled(Column)`  
  text-decoration: ${({ canceled }) => canceled === 'cancelada' ? 'line-through' : 'unset'};  
  gap: 4px;
  width: 152px;
  align-items: end;
  position: absolute;
`;

export const DateDay = styled(Text)`
  align-self: flex-end;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding-right: 14px;
`;

export const ActivityStatus = styled(View)`
  padding: 6px 14px 6px 14px;
  background-color: ${({ color }) => (color ? color : '#000')};
  border-radius: 12px;
`;

export const TextStyle = styled(Text)`
   font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: ${({ theme }) => theme.textColor};
`;

export const DateCompleted = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
`;

export const DayCompleted = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: .5;
`;

export const CardsCompleted = styled(Column)`
  border-left: 2px solid ${({ theme }) => theme.darkHighlightColor};
  padding-left: 16px;
  gap: 10px;
`;

export const DescriptionQuestion = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-right: 4px;
`;

export const DescriptionAtachmentAnswer = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const DescriptionAnswer = styled(DescriptionAtachmentAnswer)`
  opacity: .5;
  margin-right: 4px;
`;

export const CardLeftColumn = styled(Column)`
  gap: 8px;
  width: 100%;
`;

export const ContentQuestions = styled.span`
  text-decoration: ${({ canceled }) => canceled === 'cancelada' ? 'line-through' : 'unset'};
`;

export const PerfilRow = styled(Row)`
  gap: 8px;
  align-items: center;
  display: flex;
`;

export const ContentButtonLimit = styled(Row)`
  justify-content: center;
`;

export const FeatherIcon = styled(Icon.Feather)`
  *{
    color: ${({ theme, color }) => color ? color : theme.actionColor} !important;
  }
`;

export const ContentNameChecklist = styled(Row)`
  text-decoration: ${({ canceled }) => canceled === 'cancelada' ? 'line-through' : 'unset'};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyleRow = styled.div`
  flex-direction: row;
  gap: 4px;
`;

export const StyleEmpty = styled(Empty)`
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BadgeInfo = styled(Row)`
  font-size: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme })=> theme.backgroundColor};
  width: 90px;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  gap: 8px;
`;

export const PhoneIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  
  cursor: ${({ disabledClick })=> disabledClick ? '' : 'pointer'};
`;

export const InfoIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};
  cursor: pointer;
`;

export const LikeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid ${({ theme, color }) => color ? color : theme.textColor} !important;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const RowStyle = styled(Row)`
  :hover{
    cursor: ${({ disabledClick }) => disabledClick ? 'default' : 'pointer'};
  }
`;

export const CallButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px; 
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.actionColor};
  margin-left: 16px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.actionColor};
  }
  :active {
    background-color: ${({ theme }) => darken(0.085, theme.actionColor)} !important;
    border-color: ${({ theme }) => darken(0.085, theme.actionColor)} !important;
  }
`;
