import React, { useState } from 'react';
import { Modal, Form, Input, Rate } from '@components-teammove';
import styled from 'styled-components';

const RateFormItem = styled(Form.Item)`
  text-align: center;
`;

export default function ModalAvaliacao({ visible, onCancel, onAvaliar, loading }) {
  const [form] = Form.useForm();
  const [avaliation, setAvaliation] = useState({ note: 0, comment: '' });

  const handleOk = () => {
    form.validateFields().then((values) => {
      onAvaliar(values);
    }).catch((err) => err);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      okText="Avaliar"
      title="Avaliar atendimento"
      afterClose={() => form.resetFields()}
    >
      <Form layout="vertical" form={form}>
        <RateFormItem
          name="nota" 
          rules={[
            { required: true, message: 'Informe a nota!' },
            ({ getFieldValue }) => ({
              validator(param, value) {
                if (getFieldValue('nota') === 0) {
                  return Promise.reject('Avaliação não pode ser 0. Preencha a avaliação corretamente.');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Rate value={avaliation.note} onChange={(note) => setAvaliation({ ...avaliation, note: note })}/>
        </RateFormItem>

        <Form.Item 
          name="comentario" 
          rules={[{ required: avaliation.note < 4, message: 'Avaliações abaixo de 4 estrelas exigem um comentário' }]}
        >
          <Input.TextArea placeholder="Informe seu comentário..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
