import styled from 'styled-components';
import { Form, Text, InputMoney, View, Tag } from '@components-teammove';

export const SmallFormItem = styled(Form.Item)`
  display: flex !important;
  .ant-col {
    width: 70%;
  }
`;

export const SwitchForm = styled(Form.Item)`
  align-items: end;
  padding: 16px;
`;

export const InputValue = styled(InputMoney)`
  text-align: right !important;
  padding: 16px;
`;

export const StatusRegistration = styled(Text)`
  &.ant-typography {
   color: ${(props) => props.theme.textColor};
  }
`;

export const TagStatusRegistration = styled(Tag)`
  width: fit-content;
  margin-top: 5px;
  text-align: center;
  padding: 5px 10px;
  width: 75px;
  justify-content: space-between !important;
  background-color:${(props) => props.theme.actionColor};
`;

export const Header = styled(View)`
  flex: 1;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: row;
  padding-bottom: 24px;
`;

export const TitleHeader = styled(Text)`
  &.ant-typography {
   color: ${(props) => props.theme.textColor};
  }
`;

