import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  border: 10px solid ${({ theme }) => theme.actionColor};
  border-radius: 50%;
  border-top: 10px solid transparent;
  width: ${(props) => props.radius}px;
  height: ${(props) => props.radius}px;
  animation: ${rotate} 1s linear infinite;
`;