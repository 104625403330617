import styled from 'styled-components';

export const ColorOption = styled.div`
  background: ${({ color, theme }) => color || theme.darkPrimaryColor};
  cursor: pointer;
  position: relative;
  outline: currentcolor none medium;
  border-radius: 50%;
  box-shadow: ${({ color, theme }) => color || theme.darkPrimaryColor} 0px 0px 0px 15px inset;
  transition: box-shadow 100ms ease 0s;
  width: 29px;
  height: 29px;
  transform: scale(1);
  transition: transform 100ms ease 0s;

  :hover {
    transform: scale(1.2);
  }
`;
