import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification, InfoUsuario, TelasNomenclaturas } from '@utils';
import { login as loginSdk } from '@sdk/Login';
import { setRoutes } from '@ducks/app';

//Action Types
export const Types = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  INFO_USUARIO: 'LOGIN/SET_INFO_USUARIO',
  RESET: 'LOGIN/RESET',
};

//Action Creators
export const login = (username, password) => ({ type: Types.LOGIN, username, password });
export const loginSuccess = () => ({ type: Types.LOGIN_SUCCESS });
export const loginError = (error) => ({ type: Types.LOGIN_ERROR, error });

export const logout = () => ({ type: Types.LOGOUT });
export const logoutSuccess = () => ({ type: Types.LOGOUT_SUCCESS });
export const logoutError = (error) => ({ type: Types.LOGOUT_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const setInfoUsuario = (infoUsuario) => ({ type: Types.INFO_USUARIO, infoUsuario });

//saga
function* fetchLogin(action) {
  try {
    const { username, password } = action;

    const retorno = yield call(loginSdk, username, password);
    yield put(setInfoUsuario(retorno));
    yield put(setRoutes(retorno));
    yield put(loginSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(loginError(err));
  }
}

function* fetchLogout(action) {
  try {
    InfoUsuario.remove();
    localStorage.removeItem('version');
    TelasNomenclaturas.remove();
    yield put(logoutSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(logoutError(err));
  }
}

function* fetchSetInfoUsuario(action) {
  const { infoUsuario } = action;
  
  yield InfoUsuario.set(infoUsuario);
  yield TelasNomenclaturas.set(infoUsuario.telasNomenclaturas);
}

export const saga = [
  takeLatest(Types.LOGIN, fetchLogin),
  takeLatest(Types.LOGOUT, fetchLogout),
  takeLatest(Types.INFO_USUARIO, fetchSetInfoUsuario),
];

// Reducer
const initialState = Map({
  infoUsuario: InfoUsuario.get(),
  loading: false,
  success: false,
  error: false,

  loadingLogout: false,
  successLogout: false,
  errorLogout: false,
});

const handleLogin = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleLoginSuccess = (state, action) => {
  return state
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleLoginError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleReset = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', false);
};

const handleSetInfoUsuario = (state, action) => {
  const { infoUsuario } = action;
  
  return state
    .set('infoUsuario', infoUsuario);
};

const handleLogout = (state, action) => {
  return state
    .set('loadingLogout', true)
    .set('successLogout', false)
    .set('errorLogout', false);
};

const handleLogoutSuccess = (state, action) => {
  return state
    .set('infoUsuario', InfoUsuario.defaultUser)
    .set('loadingLogout', false)
    .set('successLogout', true)
    .set('errorLogout', false);
};

const handleLogoutError = (state, action) => {
  return state
    .set('loadingLogout', false)
    .set('successLogout', false)
    .set('errorLogout', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN: return handleLogin(state, action);
    case Types.LOGIN_SUCCESS: return handleLoginSuccess(state, action);
    case Types.LOGIN_ERROR: return handleLoginError(state, action);

    case Types.LOGOUT: return handleLogout(state, action);
    case Types.LOGOUT_SUCCESS: return handleLogoutSuccess(state, action);
    case Types.LOGOUT_ERROR: return handleLogoutError(state, action);

    case Types.RESET: return handleReset(state, action);
    case Types.INFO_USUARIO: return handleSetInfoUsuario(state, action);

    default: return state;
  }
}
