import JSZip from 'jszip';
import { downloadBlob } from '@utils/File';
import { getIdTemp } from '@utils';

export const downloadZipFromImages = (images, nameZIP) => {
  let index = 0;
  const zip = new JSZip();

  function loadAsArrayBuffer(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function() {
      if (xhr.status === 200) {callback(xhr.response, url);}
    };
    xhr.send();
  }
  
  // loading process. Here it will load one and one image.
  // You can modify it to load several at once but some browsers put
  // a cap on how many XHR connections can be open at the same time..
  (function load() {
    if (index < images.length) {
      loadAsArrayBuffer(images[index++], function(buffer, url) {
        const filename = index;
        zip.file(`${filename}.jpg`, buffer); // image has loaded, add it to archive
        load();                     // load next image
      });
    }
    else {                          // done! present archive somehow
      zip.generateAsync({ type:'blob' }).then(function(content) {
        downloadBlob(content, `${nameZIP || getIdTemp()}.zip`);
      });
    }
  })();
};