import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@components-teammove';

const IconHome = styled(Icon.HomeOutlined)`
  margin-right: 5px;
`;

export default function WikiBreadcrumb(path, currentId, comunicados = false) {
  let pagePath = '/conteudos/visualizacao';
  let pageName = 'Conteúdos';
  let useFirstPath = true;

  if (comunicados) {
    pagePath = '/comunicados';
    pageName = 'Comunicados';
    if (path) {
      path = [];
    }
  }

  if (path) {
    const pathBreadcrumb = path.map((item) => {
      const path = item.split('||');
      return {
        path: `${pagePath}?id=${path[0]}`,
        breadcrumbName: path[0] === currentId ? '' : path[1],
      };
    });

    if (useFirstPath) {
      pathBreadcrumb.unshift({ path: `${pagePath}?id=-1`, breadcrumbName: pageName });
    } else {
      if (pathBreadcrumb.length < 2) return null;
    }

    return {
      separator: '>',
      itemRender: (route, params, routes, paths) => (
        <Link to={route.path}>
          {routes.indexOf(route) === 0 && <IconHome />}
          {route.breadcrumbName}
        </Link>
      ),
      routes: [...pathBreadcrumb],
    };
  } else {
    return null;
  }
}
