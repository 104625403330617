export const openPopup = (url, target, width, height, urlMask = '/', handleOnClosePopUp) => {
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (height / 2);
    
  const features = `
      width=${width},
      height=${height},
      top=${top},
      left=${left},
      resizable=no,
      status=no,
      toolbar=no,
      menubar=no,
      scrollbars=no,
      location=no
    `;
    
  const newWindow = window.open(url, target, features);

  newWindow.onload = () => {
    newWindow.history.replaceState({ path: urlMask }, '', urlMask);
  };

  if(!handleOnClosePopUp) return;

  const interval = setInterval(() => {
    if (newWindow.closed) {
      clearInterval(interval);
      handleOnClosePopUp();
    }
  }, 1000);
};