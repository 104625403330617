import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Filter, Button, SearchBar, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders , changeStatusModal } from '@ducks/pedidos';
import { Filtros, InfoUsuario } from '@utils';
import { filterFields } from './rules';
import { useHistory } from 'react-router-dom';
import List from './List';
import ChangeStatusModal from './ChangeStatusModal';

export default function Pedidos() {
  const history = useHistory();

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const orders = useSelector(({ pedidos }) => pedidos.get('orders'));

  const statusModal = useSelector(({ pedidos }) => pedidos.get('statusModal'));

  const [filters, setFilters] = useState(Filtros.get('PEDIDOS'));
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getOrders({ ...filters, search }));
  }, [filters, search]);

  const handleFiltersChange = (filters, search) => {
    setSearch(search);
    setFilters(filters);
    Filtros.set('PEDIDOS', filters);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Pedidos'
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/pedidos', name: 'Pedidos', current: true },
              ]}
            />
          )}
          extra={[
            <Button key='filter' size='small' context='header' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>,
            <Button visible={InfoUsuario.perm('pedidosInserir')} key='new' size='small' context='header' type='primary' onClick={() => history.push('/pedidos/-1')}>Realizar novo pedido</Button>,
          ]}
        >
          <SearchBar
            placeholder='Pesquisar'
            initialValue={search}
            onSearch={(value) => handleFiltersChange(filters, value)}        
            activeFiltersProps={{ filtros: filters, onSearch: handleFiltersChange, filters: filterFields }}
          />

          <List/>

          {visibleFilters && (
            <Filter
              visible={visibleFilters}
              filters={filterFields}
              filtros={filters}
              onSearch={handleFiltersChange}
              onClose={() => setVisibleFilters(false)}
            />
          )}

          {statusModal.visible && (
            <ChangeStatusModal
              visible={statusModal.visible}
              onCancel={() => dispatch(changeStatusModal({ visible: false }))}
              order={orders.find((order) => order.id === statusModal.orderId)}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}