import React from 'react';
import { ContentChartProgress, MonthText } from './styles';
import { getMonthNameByNumber } from '@utils';
import { VerticalLine } from '@components-teammove';

export const ChartProgress = ({ data, trueMonth, active }) => {
  return(
    <>
      <ContentChartProgress align='center' gap='23px' active={active}>
        <VerticalLine
          divisions={[
            { label: 'Sem Histórico', percentage: (data?.criticalPercent + data?.warningPercent + data?.okPercent === 0 ? '100' : data?.noDataPercent), useBgColor: true },
            { label: 'Crítico', percentage: data?.criticalPercent, status: 'danger' },
            { label: 'Alerta', percentage: data?.warningPercent, status: 'warning' },
            { label: 'Concluído', percentage: data?.okPercent, status: 'success' },
          ]}
        />
        <MonthText active={active}>{getMonthNameByNumber(trueMonth)}</MonthText>
      </ContentChartProgress>
    </>
  );
};

export default ChartProgress;