import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Row, Column, Text, Progress, DividedLine } from '@components-teammove';
import { ButtonStyle, Container, VerticalLine, StyleEmpty } from './styles';

import { getSatisfactionIndexDash } from '@ducks/chamadosDashboard';

export default function SatisfactionIndex({ currentFilters, currentParams }) {
  const history = useHistory();
  const theme = useSelector(({ app }) => app.get('theme'));
  const dispatch = useDispatch();

  const satisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('satisfactionIndexDash'));
  const loadingSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingSatisfactionIndexDash'));
  const successSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successSatisfactionIndexDash'));
  
  const totalPercentQuantity = satisfactionIndexDash?.quantitiesByGrade?.reduce((acc, cur)=> acc + cur.quantity, 0);
 
  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getSatisfactionIndexDash({ ...currentFilters, ...currentParams }));
    }
  }, [currentFilters, currentParams]);
  
  return(
    loadingSatisfactionIndexDash ? (
      <Skeleton.Map/>
    ) : successSatisfactionIndexDash && (
      <Container gap='20px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>índice de </Text>
            <Text size='20px' weight='800' line='20px'>Satisfação</Text>
          </Column>
          <ButtonStyle size='small' context='header' key="1" type='tertiary' onClick={() => history.push('/chamados/dashboard-chamados/indice-satisfacao')}>
              Ver mais
          </ButtonStyle>
        </Row>
        <VerticalLine/>
        {satisfactionIndexDash.quantitiesByGrade.every((item)=> item.quantity === 0) ? <StyleEmpty description="Sem dados" /> : (
          <>
            <Row justify='space-between' align='center'>
              <Progress.Star percent={(satisfactionIndexDash?.averageSatisfaction * 100) / 5}/>
              <Text size='42px' weight='700'>{satisfactionIndexDash?.averageSatisfaction.toFixed(1)}</Text>
            </Row>

            <Column gap='3px'> 
          
              {satisfactionIndexDash?.quantitiesByGrade.slice().reverse().map((item)=>(
                <Row gap='28px' align='center' key={item.numberOfStars}>
                  <DividedLine
                    subtitleDisabled
                    divisions={[
                      { label: 'Crítico', percentage: (item.quantity * 100) / totalPercentQuantity, status: 'warning' },
                      { label: 'Sem Histórico', percentage: 100 - (item.quantity * 100) / totalPercentQuantity, useBgColor: true },
                    ]}
                  />
                  <Row gap='5px' align='center'>
                    <Text size='24px' weight='400'>{item.numberOfStars}</Text>
                    <Progress.Star totalStars='1' size='small' color={theme['@global-dark-highlight-color']} percent={(item.quantity * 100) / totalPercentQuantity}/>
                  </Row>
                </Row>
              ))}
            </Column>
          </>
        )}
      </Container>
    )
  );
}