import React from 'react';
import styled, { css } from 'styled-components';
import { List, Text, View } from '@components';
import { Badge } from '@components-teammove';
import { fromNow } from '@utils';

const ListItem = styled(List.Item)`
  padding: 6px;
  .ant-list-item-action {
    margin-left: 0px;
  }
`;

const DadosView = styled(View)`
  ${(props) =>
  props.lida &&
    css`
      opacity: 0.5;
    `}
`;

const DataText = styled(Text)`
  font-size: 11px;
`;

const NotificacaoItem = ({ item, onMarcarLida }) => {
  return (
    <ListItem onClick={onMarcarLida}>
      <DadosView lida={item.lida}>
        <View horizontal>
          {!item.lida && <Badge status='processing' />}
          <Text strong={!item.lida} ellipsis>
            {item.title}
          </Text>
        </View>
        <DataText type='secondary'>{fromNow(item.datetime)}</DataText>
        <Text>{item.body}</Text>
      </DadosView>
    </ListItem>
  );
};

export default NotificacaoItem;
