import { GoogleMap, InfoWindow, MarkerClusterer, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate , moment } from '@utils';
import Text from '../Text';
import { Info, InfoWindowView, SubText, TagStatus, Title, ViewContainer, ViewTag } from './styles';
import { Row, Column, Tag, Icon } from '@components-teammove';
import { ThemeContext } from 'styled-components';

const API_KEY = 'AIzaSyBgvAQ2f4AcfkV0cERdTvpou9h2E-675So' ;

const GoogleMapComponent = ({ markers, marker, zoom, center, mapStyles, info, showMarkersRoutes, dashClustersScreen, checkinContext }) => {
  const history = useHistory();
  const [activeMarker, setActiveMarker] = useState(null);
  const [map, setMap] = useState(null);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (map && markers) {
      map.setCenter(center);
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach(({ position }) => {
        bounds.extend(position);
      });
      map.fitBounds(bounds);
    }
  }, [center, markers, map]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    if (info) {
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('legend'));
    }
    setMap(map);
  };
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY
  });

  const clustererOptions = {
    maxZoom: 4, 
    imagePath: 'https://teammove.app/icones/maps-clusters/m' 
  };

  const handleSchedule = (id, nextActivity) => {
    if(nextActivity) {
      history.push(`/atividades/agenda/${id}`);
    } else {
      history.push('/atividades/agenda', { idCompany: id });
    }
  };

  const handleMoreInfo = (id) => {
    history.push(`/unidades/${id}`);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyles}
      center={center}
      zoom={zoom}
      onLoad={handleOnLoad}
    >
      {markers ? (
        <MarkerClusterer options={clustererOptions}>
          {(clusterer) => markers.map((marker) => (
            <Marker 
              key={marker.key} 
              position={new window.google.maps.LatLng(marker.position.lat, marker.position.lng)} 
              options={
                marker.icon ? { icon: marker.icon } : marker.disabledColor ? { icon: 'https://teammove.app/icones/maps-clusters/marker_grey.png' } :
                  marker.clusterStatus || marker.status.status === 'OK'
                    ? { icon: 'https://teammove.app/icones/maps-clusters/marker_green.png' }
                    : marker.clusterStatus || marker.status.status === 'CRITICO'
                      ? { icon: 'https://teammove.app/icones/maps-clusters/marker_red.png' }
                      : marker.clusterStatus || marker.status.status === 'ATENCAO'
                        ? { icon: 'https://teammove.app/icones/maps-clusters/marker_yellow.png' }
                        : marker.clusterStatus || marker.status.status === 'NADA'
                          ? { icon: 'https://teammove.app/icones/maps-clusters/marker_grey.png' }
                          : { icon: 'https://teammove.app/icones/maps-clusters/marker_grey.png' }
              }
              clusterer={clusterer} 
              onClick={() => handleActiveMarker(marker.key)}
            >
              {activeMarker === marker.key ? (
                <InfoWindow onCloseClick={() => setActiveMarker()}>
                  <InfoWindowView>
                    <Column gap='10px'>
                      <Row justify='space-between' align='center' margin='0 12px 12px 0'>
                        <Column>
                          <Title>{marker.identifier || marker.shortName}</Title>
                          <SubText>{marker.name}</SubText>
                          <SubText opacity>{marker.city}/{marker.state}</SubText>
                        </Column> 
                        {checkinContext && marker.checkin && marker.checkout ? (
                          <Column margin='0 12px 12px 0'>
                            <Text weight='400'>Inicio</Text>
                            <Text lowOpacity weight='400'>{moment(marker?.checkin).format('DD/MM/YYYY HH:mm:ss')}</Text>
                            <Text weight='400'>Fim</Text>
                            <Text lowOpacity weight='400'>{moment(marker?.checkout).format('DD/MM/YYYY HH:mm:ss')}</Text>
                          </Column>
                        ) : (
                          <Tag bgColor={theme.actionColor} onClick={() => handleMoreInfo(marker.key)}>
                            <Text>+ info</Text>
                          </Tag>
                        )}           
                      </Row >
                      {dashClustersScreen && (
                        <Column gap='10px'>
                          <Row justify='space-between' align='center'>
                            <SubText opacity>{marker.nameCluster} - {marker.frequencyQtdDays} {marker.status.daysSinceLastActivity < 1 ? 'dia' : 'dias'}</SubText>               
                            <TagStatus colorStatus={marker.status.status} align='center' justify='center'>
                              <Icon.Feather color='white' size='18' type={(marker?.status.status === 'OK' ? 'thumbs-up' : marker?.status.status === 'ATENCAO' ? 'alert-triangle' : marker?.status.status === 'CRITICO' ? 'thumbs-down' : 'thumbs-up')}/>
                              <Info size='12px' weight='600'>{marker.status.daysSinceLastActivity >= 0 && marker.status.daysSinceLastActivity} {marker.status.daysSinceLastActivity < 1 ? 'dia' : 'dias'} </Info>
                            </TagStatus>               
                          </Row>
                          <ViewContainer>
                            {marker.nextActivity ? (
                              <Row justify='space-between' align='center' onClick={() => handleSchedule(marker.nextActivity.id, true)}>
                                <Column>
                                  <Title activity>{marker.nextActivity.name}</Title>
                                  <SubText opacity>{formatDate(marker.nextActivity.date)}</SubText>
                                </Column>
                                <ViewTag nextActivity>
                                  <SubText>Agendada</SubText>
                                </ViewTag>
                              </Row>
                            ) : (
                              <ViewTag onClick={() => handleSchedule(marker.id)}>
                                <Title fromBottom>Agendar atividade</Title>
                              </ViewTag>
                            )}
                          </ViewContainer>
                        </Column>
                      )}
                    </Column>
                  </InfoWindowView>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </MarkerClusterer>
      ) : marker ? (
        <Marker position={new window.google.maps.LatLng(marker.position.lat, marker.position.lng)}/>
      ) : null}
      {showMarkersRoutes && (
        <Polyline
          options={{
            strokeColor: '#1890ff',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1890ff',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 30000,
            paths: markers?.map(({ position }) => position),
            zIndex: 1
          }}
          path={markers?.map(({ position }) => position)}
        />
      )}
      {info && <InfoWindow position={center}>{info}</InfoWindow>}
    </GoogleMap>
  ) : <></>;
};

export default GoogleMapComponent;