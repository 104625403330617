import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getCategories as getCategoriesSdk,
  postCategory as postCategorySdk,
  putCategory as putCategorySdk,
} from '@sdk/Categories';

//Action Types
export const Types = {
  GET_CATEGORIES: 'PRODUCTS/GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'PRODUCTS/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR: 'PRODUCTS/GET_CATEGORIES_ERROR',

  POST_CATEGORY: 'PRODUCTS/POST_CATEGORY',
  POST_CATEGORY_SUCCESS: 'PRODUCTS/POST_CATEGORY_SUCCESS',
  POST_CATEGORY_ERROR: 'PRODUCTS/POST_CATEGORY_ERROR',
  
  PUT_CATEGORY: 'PRODUCTS/PUT_CATEGORY',
  PUT_CATEGORY_SUCCESS: 'PRODUCTS/PUT_CATEGORY_SUCCESS',
  PUT_CATEGORY_ERROR: 'PRODUCTS/PUT_CATEGORY_ERROR',

  RESET: 'PRODUCTS/RESET',

  RESET_SUCCESS: 'PRODUCTS/RESET_SUCCESS',
};

//Action Creators
export const getCategories = (categoryId) => ({ type: Types.GET_CATEGORIES, categoryId });
export const getCategoriesSuccess = (categories) => ({ type: Types.GET_CATEGORIES_SUCCESS, categories });
export const getCategoriesError = (error) => ({ type: Types.GET_CATEGORIES_ERROR, error }); 

export const postCategory = (category) => ({ type: Types.POST_CATEGORY, category });
export const postCategorySuccess = (category) => ({ type: Types.POST_CATEGORY_SUCCESS, category });
export const postCategoryError = (error) => ({ type: Types.POST_CATEGORY_ERROR, error });

export const putCategory = (category) => ({ type: Types.PUT_CATEGORY, category });
export const putCategorySuccess = (category) => ({ type: Types.PUT_CATEGORY_SUCCESS, category });
export const putCategoryError = (error) => ({ type: Types.PUT_CATEGORY_ERROR, error });

export const reset = () => ({ type: Types.RESET });

export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

//saga
function* fetchGetCategories(action) {
  try {
    const { categoryId } = action;
    const categories = yield call(getCategoriesSdk, categoryId);
    yield put(getCategoriesSuccess(categories));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriesError(err));
  }
}

function* fetchPostCategory(action) {
  try{
    let { category } = action;
    category = yield call(postCategorySdk, category);
    yield put(postCategorySuccess(category));
  } catch (err) {
    Notification.error(err.message);
    yield put(postCategoryError(err));
  }
}

function* fetchPutCategory(action) {
  try{
    let { category } = action;
    category = yield call(putCategorySdk, category);
    yield put(putCategorySuccess(category)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(putCategoryError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CATEGORIES, fetchGetCategories),
  takeLatest(Types.POST_CATEGORY, fetchPostCategory),
  takeLatest(Types.PUT_CATEGORY, fetchPutCategory),
];

// Reducer
const initialState = Map({

  categories: List(),
  loadingCategories: false,
  successCategories: false,
  errorCategories: false,

  loadingCategorySave: false,
  successCategorySave: false,
  errorCategorySave: false,

});

const handleGetCategories = (state, action) => {
  return state.set('loadingCategories', true).set('successCategories', false).set('errorCategories', false);
};
  
const handleGetCategoriesSuccess = (state, action) => {
  const { categories } = action;
  
  return state.set('categories', categories).set('loadingCategories', false).set('successCategories', true).set('errorCategories', false);
};
  
const handleGetCategoriesError = (state, action) => {
  return state.set('loadingCategories', false).set('successCategories', false).set('errorCategories', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingCategorySave', true).set('successCategorySave', false).set('errorCategorySave', false);
};
  
const handleSaveSuccess = (state, action) => {
  return state.set('loadingCategorySave', false).set('successCategorySave', true).set('errorCategorySave', false);
};
  
const handleSaveError = (state, action) => {
  return state.set('loadingCategorySave', false).set('successCategorySave', false).set('errorCategorySave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccess = (state, action) => {
  return state.set('successCategories', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CATEGORIES:
      return handleGetCategories(state, action);
    case Types.GET_CATEGORIES_SUCCESS:
      return handleGetCategoriesSuccess(state, action);
    case Types.GET_CATEGORIES_ERROR:
      return handleGetCategoriesError(state, action);
    
    case Types.POST_CATEGORY:
      return handleSave(state, action);
    case Types.POST_CATEGORY_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_CATEGORY_ERROR:
      return handleSaveError(state, action);
    
    case Types.PUT_CATEGORY:
      return handleSave(state, action);
    case Types.PUT_CATEGORY_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_CATEGORY_ERROR:
      return handleSaveError(state, action);
  
    case Types.RESET:
      return handleReset(state, action);

    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);
        
    default:
      return state;
  }
}