import { InfoUsuario } from '@utils';
import { routes } from '../routes';

export const generateRoutes = (infoUsuario) => {
  if (!infoUsuario) {
    infoUsuario = InfoUsuario.get();
  }

  return routes().map((route) => {
    return {
      ...route,
      allowed: ((!route.permissions) || ((route.permissions) && (route.permissions(infoUsuario)))),
    };
  });
};