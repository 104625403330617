import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPerfis } from '@sdk/Perfil';
import { Button, Table, SearchBar, Row } from '@components-teammove';
import { getAgrupadores } from '@ducks/configuracoesCampanhasAgrupadores';

const Agrupadores = ({ handleEditClick }) => {
  const [search, setSearch] = useState();
  const [perfis, setPerfis] = useState([]);

  const dispatch = useDispatch();
  const agrupadores = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('agrupadores'));
  const loading = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('loadingAgrupadores'));

  const successSave = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('successSave'));
  const successDelete = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('successDelete'));

  useEffect(() => {
    dispatch(getAgrupadores());
    (async () => {
      const perfisAsync = await getPerfis();
      setPerfis(perfisAsync);
    })();
  }, []);

  useEffect(() => {
    if (successDelete || successSave) {
      dispatch(getAgrupadores());
    }
  }, [successDelete, successSave]);

  const columns = [
    {
      title: 'Nome do Agrupador',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Perfis',
      dataIndex: 'roles',
      sorter: true,
      render: (text, agrupador) => {
        return (
          <Row justify='space-between' align='center'>
            <Row>
              {text.map((role) => {
                const perfil = perfis.find(({ value }) => value === role);
                return perfil?.title || role;
              }).join(', ')}
            </Row>
            <Button.Group onClick={(evt) => evt.stopPropagation()}>
              <Button
                type='secondary'
                size='small'
                context='list'
                title='Editar agrupador'
                onClick={() => handleEditClick(agrupador)}
              >
                Editar
              </Button>
            </Button.Group>
          </Row>
        );
      }
    },
  ];

  const handleSearch = (text) => {
    setSearch(text);
  };

  const filterRoles = () => {
    if (!search) return agrupadores;
    return agrupadores.filter(({ name, roles }) => {
      return name.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        perfis.filter(({ value }) => roles.includes(value)).some(({ title }) => title.trim().toLowerCase().includes(search.trim().toLowerCase()));
    });
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar'/>
      <Table 
        columns={columns} 
        dataSource={filterRoles()} 
        loading={loading} 
        rowKey='id' 
        onRow={(agrupador) => ({ onClick: () => handleEditClick(agrupador) })} 
        status='active' 
      />
    </>
  );
};

export default Agrupadores;