import styled from 'styled-components';
import { Image, Column, Icon } from '@components-teammove';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const ActionsContainer = styled(Column)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  gap: 8px;
`;

export const FavoriteIndicator = styled(Icon.Bootstrap)`
  
`;

export const ToggleReadIndicator = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const ActionIndicatorBox = styled.div`
  padding: 6px 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  height: 28px;
  display: ${({ favorite, read }) => (favorite) ? 'flex' : 'none'};
  align-items: center;

  ${FavoriteIndicator} {
    color: ${({ favorite, theme }) => favorite ? 'var(--warning-color)' : theme.textColor};

    :hover {
      color: var(--warning-color);
    }
  }
`;

export const ContentCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
  transition: background-color .25s;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.darkHighlightColor};

    ${ActionIndicatorBox} {
      display: flex;
    }
  }
`;

export const ContentCardSkeleton = styled(ContentCard)`
  height: 230px;
  pointer-events: none;
  background-color: transparent;
  padding: 0;
`;

export const ContentImage = styled(Image)`
  border-radius: 8px;
  max-width: 100%;
  max-height: 163px;
  height: 163px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const NoImage = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 163px;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoImageIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  * {
    color: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const ContentTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 255px;
  text-overflow: ellipsis;
`;

export const ContentSummary = styled(ContentTitle)`
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  opacity: .5;
`;

export const ContentPath = styled(ContentSummary)`
  opacity: .5;
`;