import React, { useEffect } from 'react';
import App from '@app';
import { Body, PageHeader, View, Image, Spin, List, Text, Button } from '@components';
import { DashTitle, Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriasCursos, resetAula } from '@ducks/wikisEAD';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { getImagemCapa } from '../rules';

const ViewCursos = styled(View)``;

const LinkStyled = styled(Link)`
  color: #262626 !important;
  transition: 0.2s;
  width: 100%;
`;

const ListItem = styled(List.Item)`
  color: #262626 !important;
  transition: 0.2s;
  width: 100%;
  padding-top: 15px !important;
  padding-bottom: 15px !important;

  :hover {
    color: #1890ff !important;
    background-color: #f7f9f9 !important;
  }
`;

const ViewCurso = styled(View)`
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
  !props.publicado &&
    css`
      opacity: 0.7;
      text-decoration: line-through;
    `};
`;

const ImageCurso = styled(Image)`
  width: 100px;
  height: 80px;
  border-radius: 3px 3px 0px 0px;
  margin-right: 10px;
`;

const ListStyled = styled(List)`
  width: 100%;
`;

export default function WikisConteudos() {
  const dispatch = useDispatch();
  const history = useHistory();

  const categoriasCursos = useSelector(({ wikisEAD }) => wikisEAD.get('categoriasCursos'));
  const successCategoriasCursos = useSelector(({ wikisEAD }) => wikisEAD.get('successCategoriasCursos'));

  useEffect(() => {
    dispatch(resetAula());
    dispatch(getCategoriasCursos(true));
  }, []);

  const getCursos = (cursos) => {
    return (
      <ListStyled
        itemLayout='vertical'
        size='small'
        dataSource={cursos}
        renderItem={(curso) => (
          <ListItem>
            <LinkStyled to={`/ead/cadastro/${curso.id}`} key={curso.id}>
              <ViewCurso horizontal publicado={curso.publicado}>
                <View>
                  <Text strong>{curso.titulo}</Text>
                  <Text ellipsis={{ rows: 2 }}>{curso.resumo}</Text>
                </View>
                <ImageCurso alt={curso.titulo} src={getImagemCapa(curso.imagem)} />
              </ViewCurso>
            </LinkStyled>
          </ListItem>
        )}
      />
    );
  };

  const getVisualizacaoCategorias = (categorias) => {
    return successCategoriasCursos ? (
      categorias.map((categoria) => (
        <View key={categoria.id}>
          <DashTitle>{categoria.titulo}</DashTitle>
          <ViewCursos horizontal>{getCursos(categoria.cursos)}</ViewCursos>
        </View>
      ))
    ) : (
      <View>
        <Spin />
      </View>
    );
  };

  return (
    <App>
      <Body>
        <PageHeader
          title='Cursos'
          extra={[
            <Button
              key='4'
              type='primary'
              icon={<Icon.PlusOutlined />}
              onClick={() => {
                history.push('/ead/cadastro/-1');
              }}
            >
              Novo
            </Button>,
          ]}
        >
          <View>{getVisualizacaoCategorias(categoriasCursos)}</View>
        </PageHeader>
      </Body>
    </App>
  );
}
