import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Column, Text, Progress, Table, Avatar, Button } from '@components-teammove';
import { VerticalDivider } from './styles';

import { TelasNomenclaturas, getDownloadUrlAvatar } from '@utils';

import { getSatisfactionIndexTickets } from '@ducks/chamadosDashboard';

export default function AssessmentTable({ currentFilters, currentParams, limit, setLimit }) {
  const dispatch = useDispatch();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const satisfactionIndexTickets = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('satisfactionIndexTickets'));
  const loadingSatisfactionIndexTickets = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingSatisfactionIndexTickets'));
  
  useEffect(() => {
    dispatch(getSatisfactionIndexTickets({ ...currentFilters, ...currentParams }, { limit: 10 }));
  }, [currentFilters, currentParams]);
  
  const columns = [
    {
      title:'Assunto',
      dataIndex:'subject',
      render:(text, item)=>(
        <Column >
          <Text font='14px' weight='400' lowOpacity>{item.protocol}</Text>
          <Text font='18px' weight='700'>{item.title}</Text>
          <Text font='12px' weight='400'>{item.categories.map((category, index) => index !== item.categories.length - 1 ? category.name + ' / ' : category.name).join('')}</Text>
        </Column>
      )
    },
    {
      title:`${companyNomenclature.nomenclatura}`,
      dataIndex:'company',
      render:(text, item)=>(
        <Row>
          <Text font='12px' weigth='400'>{item.companyShortName}</Text>
        </Row>
      )
    },
    {
      title:'Avaliação',
      dataIndex:'assessment',
      render:(text, item)=>(
        (item.status === 'AGUARDANDO_AVALIACAO' || !item.avaliation) ? (
          <Column>
            <Text font='16px' weigth='400'>{item.status === 'AGUARDANDO_AVALIACAO' ? 'Aguardando Avaliação' : 'Ticket não avaliado'}</Text>
          </Column>
        ) : (
          <Column >
            <Progress.Star size='small' percent={(item.avaliation * 100) / 5}/>
            <Text font='16px' weigth='400'>{item.avaliationComment}</Text>
          </Column>
        )
      )
    },
    {
      title:'Usuário',
      dataIndex:'user',
      render:(text, item)=>(
        <Row align='center' gap='12px'>
          <Avatar src={getDownloadUrlAvatar(item.creatorUserPicture)}/>
          <VerticalDivider/>
          <Column>
            <Text font='18px' weight='400'>{item.creatorUserName}</Text>
            <Text font='14px' weight='400' lowOpacity>{item.creatorUserProfileDescription}</Text>
          </Column>
        </Row>
      )
    },
  ];

  const handleSizeRanking = () => {
    setLimit(limit + 10);
    dispatch(getSatisfactionIndexTickets({ ...currentFilters, ...currentParams },{ limit:(limit + 10) }));
  };

  return(
    <>
      <Column gap='12px'>
        <Table 
          columns={columns}
          dataSource={satisfactionIndexTickets.tickets}
          hidePagination
          rowKey='id'
          loading={loadingSatisfactionIndexTickets}
        />
        {limit < satisfactionIndexTickets.total && (
          <Row justify='center'>
            <Button loading={loadingSatisfactionIndexTickets} type='secondary' size='small' onClick={() => handleSizeRanking()}>{'Carregar mais'}</Button>
          </Row>
        )}
      </Column>
    </>
  );
}