import React from 'react';
import { Worker } from '@react-pdf-viewer/core';

const PDFViewer = ({ url, onDocumentLoad, children }) => (
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
    {children}
  </Worker>
);

export default PDFViewer;
