import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';
import { View, Image }from '@components';
import AvatarDropdown from './AvatarDropdown';
import Notificacoes from './Notificacoes';
import CurrentActivityHeader from './CurrentActivityHeader';
import MetasHeader from './Metas';

const HeaderLayout = styled(Layout.Header)`
  padding-left: 0px;
  padding-right: 15px;
  display: flex;

  background-color: ${({ theme }) => theme.foregroundColor} !important;
`;

const LeftView = styled(View)`
  overflow: hidden;
  height: 64px;
  line-height: 64px;
  width: 200px;
`;

const LogoImage = styled(Image)`
  width: 160px;
  height: 48px;
  object-fit: contain;
  user-select: none;
`;

const MarginLeft = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const RightView = styled(View)`
  margin: auto;
  float: right;
  height: 100%;
  overflow: hidden;
  flex-direction: row;
  display: flex;
  align-items: center;

  * {
    color: ${({ theme }) => theme.textForegroundColor} !important;
  }
`;

const BodyView = styled(View)`
  flex: 1;
  user-select: none;
`;

const Header = ({ infoUsuario, theme }) => (
  <HeaderLayout>
    <LeftView>
      <Link to="/">
        <MarginLeft>
          <LogoImage alt="TeamMove" src={theme['@global-url-logo-header'] || process.env.PUBLIC_URL + '/images/logo.png'} />
        </MarginLeft>
      </Link>
    </LeftView>

    <BodyView />

    <RightView>
      <CurrentActivityHeader/>
      <MetasHeader/>
      <Notificacoes infoUsuario={infoUsuario} />
      <AvatarDropdown infoUsuario={infoUsuario} />
      
    </RightView>

  </HeaderLayout>
);

export default Header;
