import React from 'react';
import View from '@components/View';
import Text from '@components/Text';
import styled from 'styled-components';
import { AvatarUser } from '@components';

const UsuarioContainer = styled(View)`
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 5px;
  border-left: 3px solid ${(props) => props.bordercolor || '#CCC'};
`;

const Foto = styled(View)`
  justify-content: center;
`;

const ViewUsuario = styled(View)`
  flex-direction: row;
  margin: 5px 10px;
  width: 15%;
`;

const Nome = styled(Text)`
  padding-left: 5px;
  font-weight: 500;
  font-size: 16px;
`;

const Perfil = styled(Text)`
  padding-left: 5px;
`;

export default function User({ cdUsuario, nmUsuario, corPerfil, dsPerfil }) {
  return (
    <ViewUsuario>
      <Foto>
        <AvatarUser cdUsuario={cdUsuario} nmUsuario={nmUsuario} />
      </Foto>
      <View>
        <UsuarioContainer bordercolor={corPerfil}>
          <Nome>{nmUsuario}</Nome>
          <Perfil>{dsPerfil}</Perfil>
        </UsuarioContainer>
      </View>
    </ViewUsuario>
  );
}
