import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getCamposCustomizaveis as getCamposCustomizaveisSdk, updateCamposCustomizaveis as updateCamposCustomizaveisSdk } from '@sdk/CamposCustomizaveis';

//Action Types
export const Types = {
  GET_CAMPOS_CUSTOMIZAVEIS: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_CUSTOMIZAVEIS',
  GET_CAMPOS_CUSTOMIZAVEIS_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_CUSTOMIZAVEIS_SUCCESS',
  GET_CAMPOS_CUSTOMIZAVEIS_ERROR: 'CONFIGURACOES_UNIDADES/GET_CAMPOS_CUSTOMIZAVEIS_ERROR',

  SAVE_CAMPOS_CUSTOMIZAVEIS: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_CUSTOMIZAVEIS',
  SAVE_CAMPOS_CUSTOMIZAVEIS_SUCCESS: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_CUSTOMIZAVEIS_SUCCESS',
  SAVE_CAMPOS_CUSTOMIZAVEIS_ERROR: 'CONFIGURACOES_UNIDADES/SAVE_CAMPOS_CUSTOMIZAVEIS_ERROR',

  DELETE_CAMPO_PERSONALIZADO: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_PERSONALIZADO',
  DELETE_CAMPO_PERSONALIZADO_SUCCESS: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_PERSONALIZADO_SUCCESS',
  DELETE_CAMPO_PERSONALIZADO_ERROR: 'CONFIGURACOES_UNIDADES/DELETE_CAMPO_PERSONALIZADO_ERROR',

  GET_CAMPO_PERSONALIZADO: 'CONFIGURACOES_UNIDADES/GET_CAMPO_PERSONALIZADO',
  GET_CAMPO_PERSONALIZADO_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CAMPO_PERSONALIZADO_SUCCESS',
  GET_CAMPO_PERSONALIZADO_ERROR: 'CONFIGURACOES_UNIDADES/GET_CAMPO_PERSONALIZADO_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES/RESET',
};

//Action Creators
export const getCamposCustomizaveis = () => ({ type: Types.GET_CAMPOS_CUSTOMIZAVEIS });
export const getCamposCustomizaveisSuccess = (camposCustomizaveis) => ({ type: Types.GET_CAMPOS_CUSTOMIZAVEIS_SUCCESS, camposCustomizaveis });
export const getCamposCustomizaveisError = (error) => ({ type: Types.GET_CAMPOS_CUSTOMIZAVEIS_ERROR, error }); 

export const saveCamposCustomizaveis = (camposCustomizaveis) => ({ type: Types.SAVE_CAMPOS_CUSTOMIZAVEIS, camposCustomizaveis });
export const saveCamposCustomizaveisSuccess = (camposCustomizaveis) => ({ type: Types.SAVE_CAMPOS_CUSTOMIZAVEIS_SUCCESS, camposCustomizaveis });
export const saveCamposCustomizaveisError = (error) => ({ type: Types.SAVE_CAMPOS_CUSTOMIZAVEIS_ERROR, error }); 

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetCamposCustomizaveis(action) {
  try {
    const camposCustomizaveis = yield call(getCamposCustomizaveisSdk);
    yield put(getCamposCustomizaveisSuccess(camposCustomizaveis));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCamposCustomizaveisError(err));
  }
}

function* fetchSaveCamposCustomizaveis(action) {
  try {
    let { camposCustomizaveis } = action;

    yield put(getCamposCustomizaveisSuccess(camposCustomizaveis));
    camposCustomizaveis = yield call(updateCamposCustomizaveisSdk, camposCustomizaveis);
    
    yield put(saveCamposCustomizaveisSuccess(camposCustomizaveis));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCamposCustomizaveisError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CAMPOS_CUSTOMIZAVEIS, fetchGetCamposCustomizaveis),
  takeLatest(Types.SAVE_CAMPOS_CUSTOMIZAVEIS, fetchSaveCamposCustomizaveis),
];

// Reducer
const initialState = Map({
  camposCustomizaveis: List(),
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false
});

const handleGetCamposCustomizaveis = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCamposCustomizaveisSuccess = (state, action) => {
  const { camposCustomizaveis } = action;

  return state.set('camposCustomizaveis', camposCustomizaveis).set('loading', false).set('success', true).set('error', false);
};

const handleGetCamposCustomizaveisError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveCamposCustomizaveis = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveCamposCustomizaveisSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveCamposCustomizaveisError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CAMPOS_CUSTOMIZAVEIS:
      return handleGetCamposCustomizaveis(state, action);
    case Types.GET_CAMPOS_CUSTOMIZAVEIS_SUCCESS:
      return handleGetCamposCustomizaveisSuccess(state, action);
    case Types.GET_CAMPOS_CUSTOMIZAVEIS_ERROR:
      return handleGetCamposCustomizaveisError(state, action);
    case Types.SAVE_CAMPOS_CUSTOMIZAVEIS:
      return handleSaveCamposCustomizaveis(state, action);
    case Types.SAVE_CAMPOS_CUSTOMIZAVEIS_SUCCESS:
      return handleSaveCamposCustomizaveisSuccess(state, action);
    case Types.SAVE_CAMPOS_CUSTOMIZAVEIS_ERROR:
      return handleSaveCamposCustomizaveisError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
