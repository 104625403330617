import React from 'react';
import { NotificationMetas } from '@components-teammove';
import Diario from '@containers/Atividades/Agenda/Atividade/Checklist/Finish/Notifications/Diario';

const Dropdown = ({ onClose }) => {
  return (
    <NotificationMetas onClose={onClose} type='daily'>
      <Diario fromHeader/>
    </NotificationMetas>
  
  );
};

export default Dropdown;