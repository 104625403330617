import React from 'react';
import CurrencyInput from 'react-currency-input';
import styled from 'styled-components';

const CurrencyInputStyled = styled(CurrencyInput)`
    text-align: right;
`;

const defaultProps = {
  className: 'ant-input'
};

const InputMoney = ({ ...props }) => (
  <CurrencyInputStyled {...props} {...defaultProps} />
);

export default InputMoney;