import { moment } from '@utils';

export const getDayViewDescription = (day) => {
  if (moment(day).isSame(moment(), 'day')) {
    return 'hoje';
  } else if (moment(day).isSame(moment().add(1, 'day'), 'day')) {
    return 'amanhã';
  } else if (moment(day).isSame(moment().subtract(1, 'day'), 'day')) {
    return 'ontem';
  } else {
    return `${moment(day).format('DD')} de ${moment(day).format('MMMM')}, ${moment(day).format('yyyy')}`;
  }
};