import React from 'react';
import { Icon } from '@components-teammove';

export const getIconByType = (type, size = '2.5rem', { ...props }) => {
  switch(type) {
    case 'GRUPO':
      return <Icon.Feather type='folder' size={size} {...props}/>;
    case 'VIDEO':
      return <Icon.Feather type='film' size={size} {...props}/>;
    case 'PDF':
      return <Icon.Simple type='adobeacrobatreader' size={size} {...props}/>;
    case 'IMAGEM':
      return <Icon.Feather type='image' size={size} {...props}/>;
    case 'LINK':
      return <Icon.MDI type='link' size={size} {...props}/>;
    case 'HTML':
      return <Icon.Feather type='code' size={size} {...props}/>;
    case 'ARQUIVO':
      return <Icon.Feather type='file' size={size} {...props}/>;
    case 'CAMPANHA':
      return <Icon.Feather type='calendar' size={size} {...props}/>;
    case 'TEXTO':
      return <Icon.Feather type='type' size={size} {...props}/>;
    default: 
      return <Icon.Feather type='help-circle' size={size} {...props}/>;
  }
};