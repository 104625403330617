import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import {
  getTemplate as getTemplateSdk,
  saveTemplate as saveTemplateSdk,
} from '@sdk/Wikis/Editor';

//Action Types
export const Types = {
  GET_TEMPLATE: 'WIKIS_EDITOR/GET_TEMPLATE',
  GET_TEMPLATE_SUCCESS: 'WIKIS_EDITOR/GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_ERROR: 'WIKIS_EDITOR/GET_TEMPLATE_ERROR',

  SAVE_TEMPLATE: 'WIKIS_EDITOR/SAVE_TEMPLATE',
  SAVE_TEMPLATE_SUCCESS: 'WIKIS_EDITOR/SAVE_TEMPLATE_SUCCESS',
  SAVE_TEMPLATE_ERROR: 'WIKIS_EDITOR/SAVE_TEMPLATE_ERROR',
};

//Action Creators
export const getTemplate = (idCategoria) => ({ type: Types.GET_TEMPLATE, idCategoria });
export const getTemplateSuccess = (template) => ({ type: Types.GET_TEMPLATE_SUCCESS, template });
export const getTemplateError = (error) => ({ type: Types.GET_TEMPLATE_ERROR, error });

export const saveTemplate = (idCategoria, json, html) => ({ type: Types.SAVE_TEMPLATE, idCategoria, json, html });
export const saveTemplateSuccess = (data) => ({ type: Types.SAVE_TEMPLATE_SUCCESS, data });
export const saveTemplateError = (error) => ({ type: Types.SAVE_TEMPLATE_ERROR, error });

//saga
function* fetchGetTemplate(action) {
  try {
    const { idCategoria } = action;

    const template = yield call(getTemplateSdk, idCategoria);
    yield put(getTemplateSuccess(template));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTemplateError(err));
  }
}

function* fetchSaveTemplate(action) {
  try {
    const { idCategoria, json, html } = action;

    yield call(saveTemplateSdk, idCategoria, json, html);
    yield put(saveTemplateSuccess());
    Notification.success('Template salvo');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveTemplateError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TEMPLATE, fetchGetTemplate),
  takeLatest(Types.SAVE_TEMPLATE, fetchSaveTemplate),
];

// Reducer
const initialState = Map({
  template: false,

  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetTemplate = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetTemplateSuccess = (state, action) => {
  return state
    .set('template', action.template)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetTemplateError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleSaveTemplate = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};

const handleSaveTemplateSuccess = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleSaveTemplateError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TEMPLATE: return handleGetTemplate(state, action);
    case Types.GET_TEMPLATE_SUCCESS: return handleGetTemplateSuccess(state, action);
    case Types.GET_TEMPLATE_ERROR: return handleGetTemplateError(state, action);

    case Types.SAVE_TEMPLATE: return handleSaveTemplate(state, action);
    case Types.SAVE_TEMPLATE_SUCCESS: return handleSaveTemplateSuccess(state, action);
    case Types.SAVE_TEMPLATE_ERROR: return handleSaveTemplateError(state, action);

    default: return state;
  }
}
