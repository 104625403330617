import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import App from '@app';
import { TelasNomenclaturas, Filtros } from '@utils';
import { 
  Breadcrumb, 
  Body, 
  PageHeader,
  Column,
  Button,
  Filter,
  ActiveFilters,
  SearchBar,
  Icon,
  ContentHeader,
  ViewControl
} from '@components-teammove';

import PanelDashboard  from './PanelDashboard';
import TableDashBoard from './TableDashboard';
import Cards from './Cards';

import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import getModalFilters from './rules';
import MapCluster from './MapCluster';

export default function DashBoardCluster() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const dispatch = useDispatch();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const companyTypes = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));

  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));

  const loadingCompanyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingCompanyClusterSituation'));

  const modalFilters = getModalFilters(agrupadores, companyTypes);
  
  const [filters, setFilters] = useState(Filtros.get('DASH'));
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [activeSearch, setActiveSearch] = useState(sessionStorage.getItem('searchGestaoCarteira') || '');

  const status = localStorage.getItem('STATUS');
  const [ activeStatus, setActiveStatus] = useState(status);
  const [ viewBy, setViewBy ] = useState('Listagem');
  const [ activeLimit, setActiveLimit ] = useState(20);

  useEffect(() => {
    dispatch(getAgrupadores());
    dispatch(getTiposUnidades());
  },[]);

  const handleFiltersChange = (filters, search) => {
    if(typeof search === 'string') {
      sessionStorage.setItem('searchGestaoCarteira', search);
      setActiveSearch(search);
    }
    
    setFilters(filters);
    Filtros.set('DASH', filters);
  };

  const handleViewByChange = (viewByName) => {
    setViewBy(viewByName);
  };
  
  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Dashboard de cluster'}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { name: `${(companyNomenclature.plural || 'Unidades').toLowerCase()}`, current: true },
                { path: '/gestaoCarteira', name: `Gestão da carteira d${companyNomenclature.artigo}s ${(companyNomenclature.plural || 'Unidades').toLowerCase()}` },
                { path: '/gestaoCarteira/dashboardCluster', name: 'Dashboard de cluster', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
          extra={[
            <Button size='small' context='header' key="1" type='secondary' onClick={() => setVisibleFilters(true)} icon={<Icon.MDI type='filter-outline' />}>Filtros</Button>,
          ]}
        >
          <ActiveFilters filters={modalFilters} filtros={filters} onSearch={handleFiltersChange}/>
          <Column gap='12px'>
            <PanelDashboard filters={filters} status={activeStatus} search={activeSearch} setLimit={setActiveLimit}/>
            <Column gap='28px'>
              <Cards filters={filters} search={activeSearch} activeStatus={activeStatus} setActiveStatus={setActiveStatus} setLimit={setActiveLimit}/>

              <ContentHeader 
                marginless
                title={viewBy} 
                extra={(
                  <ViewControl 
                    disabled={loadingCompanyClusterSituation}
                    options={[                   
                      { icon: <Icon.MDI type='format-list-bulleted'/>, name: 'Listagem' }, 
                      { icon: <Icon.MDI type='map-marker-outline'/>, name: 'Mapa' },
                    ]}
                    current={viewBy} 
                    onChange={handleViewByChange}
                  />
                )}
              />

              <SearchBar
                marginLess
                placeholder="Pesquisar"
                initialValue={activeSearch}
                onSearch={(value)=>handleFiltersChange(filters, value)}
              />
            </Column>
           
            {viewBy === 'Listagem' ? (
              <TableDashBoard filters={filters} search={activeSearch} status={activeStatus} limit={activeLimit} setLimit={setActiveLimit}/>
            ) : (
              <MapCluster filters={filters} search={activeSearch} status={activeStatus}/>
            )}
            {visibleFilters && (
              <Filter 
                visible={visibleFilters}
                filters={modalFilters} 
                filtros={filters}
                onSearch={handleFiltersChange} 
                onClose={() => setVisibleFilters(false)}
              />
            )}
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}