import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { 
  Skeleton, 
  Column, 
  Row, 
  Button, 
  Grid,
  DividedLine,
  ChartDonut,
  View,
} from '@components-teammove';
import { TelasNomenclaturas, Filtros } from '@utils';

import { 
  ButtonAlign,
  Card, 
  ColoredBall, 
  IconFeather, 
  MainTitle, 
  MiniCard, 
  MiniCardPrimaryInfo, 
  MiniCardSubtitleInfo, 
  OkStatus, 
  OkStatusText, 
  OthersStatusText, 
  PercentageText, 
  StyleEmpty 
} from './styles';

import { getYearSummary, getClustersByGroup } from '@ducks/gestaoCarteira';
import { FILTERS_KEY } from '@ducks/unidades';

const CardDashboard = ({ loading, data, success, filters, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const year = parseInt(localStorage.getItem('YEAR'));
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const handleViewByCluster = (groupId, clusterId) => {
    dispatch(getYearSummary(groupId, year, filters, clusterId));
    dispatch(getClustersByGroup(groupId));
    history.push('/gestaoCarteira/dashboardCluster');
  };
  
  const handleViewCompaniesWithoutCluster = ({ id, name }) => {
    
    const filtros = { 
      tipoUnidade: (filters?.companyTypeId?.length > 0) ? filters.companyTypeId : [],
      agrupador: (filters?.agrupadorId?.length > 0) ? filters.agrupadorId : [],
      cluster: [-1], 
      grupoCluster: [id],
      descFiltros: { 
        ...(Filtros.get(FILTERS_KEY)?.descFiltros || {}), 
        'agrupador': (filters?.descFiltros?.agrupadorId?.length > 0) ? filters.descFiltros.agrupadorId : [],
        'tipoUnidade': (filters?.descFiltros?.companyTypeId?.length > 0) ? filters.descFiltros.companyTypeId : [],
        'cluster': ['Sem Cluster'],
        'grupoCluster': [name],
      }
    };

    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    
    Filtros.set(FILTERS_KEY, filtros);
    history.push('/listaUnidades');
  };

  return(
    loading ? <Skeleton.LargeBox /> : success && (
      <Column gap='20px'>
        {data.map((group)=>( 
          group.numberOfCompanies > 0 && (
            <Card key={group.id}>
              <Column padding='0 12px 0 16px' gap='16px'>
                <Row justify='space-between'>
                  <MainTitle>{(group.name.charAt(0)).toUpperCase() + group.name.slice(1)}</MainTitle>
                  <Row gap='4px' align='baseline'>
                    <MainTitle>{group.numberOfCompanies}</MainTitle>
                    <MainTitle size='18px' weight='400'>{((companyNomenclature.plural)?.toLowerCase() || 'unidades')}</MainTitle>
                  </Row>
                </Row>
              
                <OkStatus>
                  <IconFeather color='ok' size='26' type='thumbs-up'/> 

                  <OkStatusText>Ok: </OkStatusText>
                  <OkStatusText bold>{group.okQuantity}</OkStatusText>
                  <OkStatusText>({(group.okPercent).toFixed()}%)</OkStatusText>

                </OkStatus>

                <DividedLine
                  subtitleDisabled
                  divisions={[
                    { label: 'Concluído', percentage: group.okPercent, status: 'success' }, 
                    { label: 'Alerta', percentage: group.warningPercent, status: 'warning' },
                    { label: 'Crítico', percentage: group.criticalPercent, status: 'danger' },
                    { label: 'Sem Histórico', percentage: group.noDataPercent, useBgColor: true },
                  ]}
                />  

                <Row justify='space-between'>
                  <Row align='flex-start' gap= '24px'>
          
                    <Row align='center' gap='8px'>
                      <IconFeather color='alert' size='24' type='thumbs-up'/> 
                      <Row gap='4px'>
                        <OthersStatusText color='alert'>Alerta:</OthersStatusText>
                        <OthersStatusText bold>{group.warningQuantity}</OthersStatusText>
                        <OthersStatusText opacity>({(group.warningPercent).toFixed()}%)</OthersStatusText>
                      </Row>
                    </Row>
            
                    <Row align='center' gap='8px'>
                      <IconFeather color='critical' size='24' type='thumbs-down'/> 
                      <Row gap='4px'>
                        <OthersStatusText color='critical'>Crítico:</OthersStatusText>
                        <OthersStatusText bold>{group.criticalQuantity}</OthersStatusText>
                        <OthersStatusText opacity>({(group.criticalPercent).toFixed()}%)</OthersStatusText>
                      </Row>
                    </Row>

                    <Row align='center' gap='8px'>
                      <IconFeather color='n/a' size='24' type='x-circle'/> 
                      <Row gap='4px'>
                        <OthersStatusText opacity>Sem Histórico:</OthersStatusText>
                        <OthersStatusText bold>{group.noDataQuantity}</OthersStatusText>
                        <OthersStatusText opacity>({(group.noDataPercent).toFixed()}%)</OthersStatusText>
                      </Row>
                    </Row>

                  </Row>
                  <Button size='small' context='header' type='secondary' icon={<IconFeather type='bar-chart' />} onClick={() =>handleViewByCluster(group.id)}>Ver Progresso</Button>
                </Row>

              </Column>
              <Grid numberOfColumns={4} gap='12px'>
                {group.clusters.map((cluster)=>(
                  (cluster.numberOfCompanies > 0 && (
                    <MiniCard key={cluster.id}>
                      <Column gap='20px'>
                        <Column>
                          <Row justify='space-between' align='center'>
                            <MiniCardPrimaryInfo>{(cluster.name.charAt(0)).toUpperCase() + cluster.name.slice(1)}</MiniCardPrimaryInfo>
                            <MiniCardPrimaryInfo>{cluster.numberOfCompanies}</MiniCardPrimaryInfo>
                          </Row>
                          <Row justify='space-between' align='center'>
                            <MiniCardSubtitleInfo>Frequência: {cluster.numberOfDays} dias</MiniCardSubtitleInfo>
                            <MiniCardSubtitleInfo>{((companyNomenclature.plural)?.toLowerCase() || 'unidades')}</MiniCardSubtitleInfo>
                          </Row>
                        </Column>
              
                        <Row align= 'flex-end' justify='space-between'>
                
                          <Row gap='8px' align='center'>
                            <ChartDonut 
                              values ={[
                                cluster.okPercent, cluster.warningPercent, cluster.criticalPercent, cluster.noDataPercent
                              ]}
                              successNumber={cluster.okQuantity}
                              successPercent={(cluster.okPercent).toFixed()}
                            />
                            <View>
                              <Row gap='4px' align='center'>
                                <ColoredBall color='ok'/>
                                <Row gap='4px'>
                                  <PercentageText>{cluster.okQuantity}</PercentageText>
                                  <PercentageText opacity>({(cluster.okPercent).toFixed()}%)</PercentageText>
                                </Row>
                              </Row>
                              <Row gap='4px' align='center'>
                                <ColoredBall color='critical'/>
                                <Row gap='4px'>
                                  <PercentageText>{cluster.criticalQuantity}</PercentageText>
                                  <PercentageText opacity>({(cluster.criticalPercent).toFixed()}%)</PercentageText>
                                </Row>
                              </Row>
                              <Row gap='4px' align='center'>
                                <ColoredBall color='alert'/>
                                <Row gap='4px'>
                                  <PercentageText>{cluster.warningQuantity}</PercentageText>
                                  <PercentageText opacity>({(cluster.warningPercent).toFixed()}%)</PercentageText>
                                </Row>
                              </Row>
                              <Row gap='4px' align='center'>
                                <ColoredBall color='n/a'/>
                                <Row gap='4px'>
                                  <PercentageText>{cluster.noDataQuantity}</PercentageText>
                                  <PercentageText opacity>({(cluster.noDataPercent).toFixed()}%)</PercentageText>
                                </Row>
                              </Row>
                            </View>
                          </Row>
                          <Button type='icon' size='small' icon={(<IconFeather type='arrow-right' />)} onClick={() => handleViewByCluster(group.id,cluster.id)}/>
                        </Row>

                      </Column>
                    </MiniCard>
                  ))
                ))}
                {group.totalClusterGroupCompaniesWithoutCluster > 0 && (
                  <MiniCard isNotCluter>
                    <Column gap='12px'>
                      <Column>
                        <Row justify='space-between' align='center'>
                          <MiniCardPrimaryInfo>Sem Cluster</MiniCardPrimaryInfo>
                          <MiniCardPrimaryInfo>{group.totalClusterGroupCompaniesWithoutCluster}</MiniCardPrimaryInfo>
                        </Row>
                        <Row justify='end' align='center'>
                          <MiniCardSubtitleInfo>{((companyNomenclature.plural)?.toLowerCase() || 'unidades')}</MiniCardSubtitleInfo>
                        </Row>
                      </Column>
                      <Row>
                        <MiniCardSubtitleInfo>
                        Encontramos {group.totalClusterGroupCompaniesWithoutCluster} {((companyNomenclature.plural)?.toLowerCase() || 'unidades')} sem o cluster para {' '}   
                          <MiniCardSubtitleInfo isBold>
                            {group.name}
                          </MiniCardSubtitleInfo>
                        </MiniCardSubtitleInfo>
                      </Row>     
                    </Column>
                    <ButtonAlign type='icon' size='small' icon={(<IconFeather type='arrow-right' />)} onClick={() => handleViewCompaniesWithoutCluster(group)}/> 
                  </MiniCard>
                )}
                
              </Grid>
            </Card>
          )))} 
        {!data.length === 0 && (
          <StyleEmpty description="Sem dados" />
        )}
      </Column> 
    )
  );
};

export default CardDashboard;