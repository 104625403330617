import styled from 'styled-components';
import { Filter } from '@components-teammove';

export const FilterStyle = styled(Filter)`
    &.ant-modal {
    .ant-modal-body {
      :focus-within {
        height: 250px;
      }
    }
  }
`;