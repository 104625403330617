import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Modal, Row, Icon } from '@components-teammove';
import { getPerfis } from '@sdk/Perfil';
import { getTiposAtividades, resetForMetasAtividades } from '@ducks/configuracoesAtividadesTipos';
import { putPerfisMeta, putTipoAtividadeMeta, resetVariablesAdd } from '@ducks/configuracoesMetaAtividades';
import { getAgrupadoresMetasAtividades } from '@ducks/configuracoesAgrupadoresMetasAtividades';
import { ActivityName, ColumnContainer, IconWrapper } from './styles';

export default function CadastroModal({ visible, onCancel, typeMetaModal, data }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const active = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const loadingTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingTiposAtividades'));
  const successTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('successTiposAtividades'));
  const successSavePerfil = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('successSavePerfil'));
  const loadingSavePerfil = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('loadingSavePerfil'));
  const successSaveTipoAtividade = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('successSaveTipoAtividade'));
  const loadingSaveTipoAtividade = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('loadingSaveTipoAtividade'));
  
  const successAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('successAgrupadoresMetas'));
  const agrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('agrupadoresMetas'));
  const loadingAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('loadingAgrupadoresMetas'));

  const [heightModal, setHeightModal] = useState(false);
  const [options, setOptions] = useState(null);
  const [grouperOptions, setGrouperOptions] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loadingFinished, setLoadingFinished] = useState(true);

  const userModal = typeMetaModal === 'addUser';
  
  useEffect(() => {
    if(userModal) {
      getPerfis().then((perfisData) => {
        const profileIdData = data.map((user) => user.profileId);
        const selectedProfilesId = [...new Set(profileIdData)];

        const options = perfisData.map((perfil) => ({
          value: perfil.value,
          label: perfil.title
        }));
        setOptions(options);

        form.setFieldsValue({ options: selectedProfilesId });
        setLoadingFinished(false);
      });
    } else {
      dispatch(getTiposAtividades());
      dispatch(getAgrupadoresMetasAtividades());
    }

    return () => {
      dispatch(resetVariablesAdd());
      dispatch(resetForMetasAtividades());
    };
  }, []);

  useEffect(() => {
    if(successTiposAtividades) {
      const activityIdData = data.filter((active) => !active.groupActivityKinds).map((user) => user.id);
      const selectedActivitiesId = activityIdData.filter((id, index) => activityIdData.indexOf(id) === index);
      const options = active.map((activityType) => ({
        value: activityType.id,
        title: activityType.nome,
        label: renderActivityTypeOption({ name: activityType.nome, color: activityType.cor, icon: activityType.icone })
      }));
      setOptions(options);
      form.setFieldsValue({ options: selectedActivitiesId });
      setLoadingFinished(false);
    }
    if(successAgrupadoresMetas) {
      const activityIdData = data.filter((active) => active.groupActivityKinds).map((user) => user.id);
      const selectedActivitiesId = activityIdData.filter((id, index) => activityIdData.indexOf(id) === index);
      const optionsGrouper = agrupadoresMetas.map((agrupador) => ({
        value: agrupador.id,
        title: agrupador.name,
        label: renderActivityTypeOption(agrupador)
      }));
      setGrouperOptions(optionsGrouper);
      form.setFieldsValue({ groups: selectedActivitiesId });
    }
  }, [successTiposAtividades, successAgrupadoresMetas]);

  useEffect(() => {
    if(successSavePerfil || successSaveTipoAtividade) {
      onCancel();
    }
  }, [successSavePerfil, successSaveTipoAtividade]);

  const renderActivityTypeOption = ({ name, color, icon }) => (
    <Row gap='15px' align='center'>
      <IconWrapper color={color}>
        <Icon.MDI type={icon}/>
      </IconWrapper>
      <ActivityName>{name}</ActivityName>
    </Row>
  );

  const handleOk = () => {
    form.validateFields().then((values) => {
      dispatch(userModal ? putPerfisMeta(values.options) : putTipoAtividadeMeta({ activityKinds: values.options || [], groups: values.groups || [] }));
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={userModal ? 'Adicionar perfil usuário' : 'Adicionar atividade'}
      cancelText='Cancelar'
      okText='Adicionar'
      onOk={handleOk}
      okButtonProps={{ loading: loadingFinished || loadingTiposAtividades || loadingSavePerfil || loadingSaveTipoAtividade }}  
    >
      <ColumnContainer
        heightModal={heightModal}
      >
        <Form layout='vertical' form={form}>
          <Form.Item
            name='options' 
            label={userModal ? 'Perfil' : 'Atividade'}
            rules={[
              { required: userModal, message: `Por favor, insira um${!userModal ? 'a' : ''} ${userModal ? 'perfil' : 'atividade'}` },
            ]}
          >
            <Select
              mode='multiple'
              placeholder={`Selecione ${!userModal ? 'as atividades para adicionar na tabela de metas' : 'o perfil de usuários'}`}
              onDropdownVisibleChange={(open) => { 
                setHeightModal(open); 
                setDropdownOpen(open);
              }}
              options={options ? options : []}
              loading={loadingFinished || loadingTiposAtividades || loadingAgrupadoresMetas}
              open={dropdownOpen}
              onSelect={() => { 
                setDropdownOpen(false);
                setHeightModal(false);
              }}
              autoFocus
            />
          </Form.Item>
          {!userModal && (
            <Form.Item
              name='groups' 
              label='Agrupador de metas'
            >
              <Select
                mode='multiple'
                placeholder='Selecione as atividades do agrupador'
                options={grouperOptions ? grouperOptions : []}
                loading={loadingFinished || loadingTiposAtividades || loadingAgrupadoresMetas}
              />
            </Form.Item>
          )}
        </Form>
      </ColumnContainer>
    </Modal>
  );
}
