import React from 'react';
import { Container, Label, LikeIcon, RateContainer, Grid } from './styles';
import { View  } from '@components';
import { Icon , Button, Progress } from '@components-teammove';

import { useSelector } from 'react-redux';
import { formatDateTime } from '@utils';

export default function MessageCompleted({ collapsed, idChamado, isClosed, rate, handleOpenAvaliacao, handleReabrirAtendimento, endedTicketAt, ...props }) {
  const loadingStatus = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingStatus'));
  const loadingEnvio = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingEnvio'));

  return(
    <Container collapsed={collapsed}>
      <Grid>
        <LikeIcon/>
        <View>
          <Label>Este atendimento foi finalizado!</Label>
          <Label>{formatDateTime(endedTicketAt)}</Label>
        </View>
        <span/>
        {!isClosed ? <Button type="primary" loading={loadingStatus || loadingEnvio} onClick={handleOpenAvaliacao}>Avaliar Atendimento</Button> : (
          <RateContainer>
            <Label>Avaliação:&nbsp;</Label>
            <Progress.Star percent={(rate / 5) * 100} size='small'/>
          </RateContainer>
        )}
      </Grid>
      {!isClosed && <Button type="secondary" icon={<Icon.ReloadOutlined />} loading={loadingStatus || loadingEnvio} onClick={handleReabrirAtendimento}>Reabrir Atendimento</Button>}
    </Container>
  );
}