import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { List  } from '@components';
import { Icon , Badge, Drawer, Empty } from '@components-teammove';

import { InfoUsuario } from '@utils';
import { marcarLida } from '@ducks/notificacoes';
import { getIsComunicado } from '@sdk/Wikis';
import NotificacaoItem from './NotificacaoItem';
import { ContainerNotification, Row } from '../styles';

const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding: 6px;
  }
`;

const BellIcon = styled(Icon.MDI)`
  font-size: 1.3em !important;
  color: ${({ theme }) => theme.textColor} !important;
`;

const Notificacoes = ({ infoUsuario }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const handleOpenModal = () => setVisible(true);
  const handleCloseModal = () => setVisible(false);

  const notificacoes = useSelector((state) => state.notificacoes.get('notificacoes').reverse().toList());
  const numNaoLidas = notificacoes.count((item) => !item.lida);

  const handleMarcarLida = (notificacao) => {
    const acceptedPages = {
      WIKI(notificacao) {
        const { id } = notificacao.data;
        getIsComunicado(id).then((isComunicado) => {
          if (isComunicado) {
            history.push(`/comunicado/${id}`);
          } else {
            history.push(`/conteudos/visualizacao/${id}`);
          }
        });
      },
      CHAMADO(notificacao) {
        const { id } = notificacao.data;
        history.push(`/chamados/tickets/${id}`);
      },
    };

    dispatch(marcarLida(notificacao.id));

    const { tipo } = notificacao.data;
    const moveToPage = acceptedPages[tipo];
    if (moveToPage) {
      moveToPage(notificacao);
      handleCloseModal();
    }
  };

  return InfoUsuario.hasUser(infoUsuario) ? (
    <ContainerNotification>
      <Badge count={numNaoLidas} dot offset={[-3, 3]} onClick={handleOpenModal}>
        <Row gap='15px'>
          <BellIcon type='bell-outline'/>
        </Row>
      </Badge>

      <DrawerStyled
        title={numNaoLidas > 0 ? `Notificações (${numNaoLidas})` : 'Notificações'}
        visible={visible}
        onClose={handleCloseModal}
        width={350}
      >
        <List
          size='small'
          itemLayout='vertical'
          dataSource={notificacoes}
          renderItem={(item, index) => <NotificacaoItem item={item} onMarcarLida={() => handleMarcarLida(item)} />}
          locale={{
            emptyText: <Empty description='Sem notificações para exibir' />,
          }}
        />
      </DrawerStyled>
    </ContainerNotification>
  ) : null;
};

export default Notificacoes;
