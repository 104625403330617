import React from 'react';
import { Modal, Form, FormItem, Select, Input } from '@components-teammove';
import { getUnidades } from '@sdk/Unidades';
import { TelasNomenclaturas } from '@utils';

export default function ModalCompany({ visible, loading, onCancel, onChangeCompany, currentCompany, idTicket }) {
  const [form] = Form.useForm();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const handleOk = () => {
    form.validateFields().then((values) => {
      onChangeCompany(values.company);
    }).catch((err) => console.error(err.message));
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      okText="Alterar"
      title={`Alterar ${companyNomenclature.nomenclatura} `}
      closable={false}
    >
      <Form layout="vertical" form={form}>
        <FormItem label="De">
          <Input disabled value={currentCompany} />
        </FormItem>

        <FormItem
          label="Para"
          name="company"
          rules={[
            { required: true, message: `Informe um${companyNomenclature.artigo === 'a' && 'a'} ${(companyNomenclature.nomenclatura).toLowerCase()}!` },
          ]}
        >
          <Select
            action={getUnidades}
            placeholder={`Selecione uma ${companyNomenclature.nomenclatura} `}
            autoFocus
          />
        </FormItem>
      </Form>
    </Modal>
  );
}
