import styled, { css } from 'styled-components';
import View from '../View';

export const ContainerBody = styled(View)`
  background-color: ${(props) => props.theme.backgroundColor} !important;
  flex: 1;
  width: 100%;

  ${(props) =>
  props.fixed
    ? css`
          overflow: initial;
        `
    : css`
          overflow: auto;
        `}
`;