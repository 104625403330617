import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { getTrail as getTrailSdk, saveTrail as saveTrailSdk } from '@sdk/Wikis/EAD/Trilhas';

//Action types
export const Types = {
  GET_TRILHA: 'WIKI_EAD/GET_TRILHA',
  GET_TRILHA_SUCCESS: 'WIKI_EAD/GET_TRILHA_SUCCESS',
  GET_TRILHA_ERROR: 'WIKI_EAD/GET_TRILHA_ERROR',
  
  SAVE_TRILHA: 'WIKI_EAD/SAVE_TRILHA',
  SAVE_TRILHA_SUCCESS: 'WIKI_EAD/SAVE_TRILHA_SUCCESS',
  SAVE_TRILHA_ERROR: 'WIKI_EAD/SAVE_TRILHA_ERROR',

  RESET_TRILHA: 'WIKI_EAD/RESET_TRILHA'
};

//Action Creators
export const getTrail = (idTrail) => ({ type: Types.GET_TRILHA, idTrail });
export const getTrailSuccess = (trail) => ({ type: Types.GET_TRILHA_SUCCESS, trail });
export const getTrailError = (error) => ({ type: Types.GET_TRILHA_ERROR, error });

export const saveTrail = (trail) => ({ type: Types.SAVE_TRILHA,  trail });
export const saveTrailSuccess = (trail) => ({ type: Types.SAVE_TRILHA_SUCCESS, trail });
export const saveTrailError = (error) => ({ type: Types.SAVE_TRILHA_ERROR, error });

export const resetTrail = () => ({ type: Types.RESET_TRILHA });

function* getTrailSaga(action) {
  try {
    const { idTrail } = action;
    
    if (parseInt(idTrail) < 1) {
      yield put(getTrailSuccess({}));
    } else {
      const trail = yield call(getTrailSdk, idTrail);
      yield put(getTrailSuccess({ ...trail, observacao: trail.observacao ? JSON.parse(trail.observacao) : [], apresentacao: trail.apresentacao ? JSON.parse(trail.apresentacao) : {} }));
    }
  } catch (err) {
    Notification.error(err.message);
    yield put(getTrailError(err));
  }
}

function* saveTrailSaga(action) {
  try {
    const { trail } = action;
    const trailSdk = yield call(saveTrailSdk, trail);
    yield put(saveTrailSuccess(trailSdk));
    Notification.success('Trilha salva com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveTrailError(err));
  }
}
  
export const saga = [
  takeLatest(Types.GET_TRILHA, getTrailSaga),
  takeLatest(Types.SAVE_TRILHA, saveTrailSaga)
];

// Reducer
const initialState = Map({
  trail: {},
  loadingSearch: false,
  successSearch: false,
  errorSearch: false,
  
  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetTrail = (state, action) => {
  return state
    .set('loadingSearch', true)
    .set('successSearch', false)
    .set('errorSearch', false);
};

const handleGetTrailSuccess = (state, action) => {
  return state
    .set('trail', action.trail)
    .set('loadingSearch', false)
    .set('successSearch', true)
    .set('errorSearch', false);
};

const handleGetTrailError = (state, action) => {
  return state
    .set('loadingSearch', false)
    .set('successSearch', false)
    .set('errorSearch', action.error);
};

const handleSaveTrail = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handleSaveTrailSuccess = (state, action) => {
  return state
    .set('trail', action.trail)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleSaveTrailError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handleResetTrail = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case Types.GET_TRILHA: return handleGetTrail(state, action);
    case Types.GET_TRILHA_SUCCESS: return handleGetTrailSuccess(state, action);
    case Types.GET_TRILHA_ERROR: return handleGetTrailError(state, action);
  
    case Types.SAVE_TRILHA: return handleSaveTrail(state, action);
    case Types.SAVE_TRILHA_SUCCESS: return handleSaveTrailSuccess(state, action);
    case Types.SAVE_TRILHA_ERROR: return handleSaveTrailError(state, action);

    case Types.RESET_TRILHA: return handleResetTrail(state, action);
  
    default: return state;
  }
}