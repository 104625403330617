import React, { useEffect, useState } from 'react';
import { Text, Skeleton, Row } from '@components-teammove';
import { moment } from '@utils';

const Accountant = ({ time, ...props }) => {
  const [contador, setContador] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
      const newTime = moment.utc(moment(currentDate).diff(moment(time))).format('HH:mm:ss');
      setContador(newTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {!contador ? <Row width='53px'><Skeleton.MinorLine/></Row> : <Text {...props}>{contador}</Text> }
      
    </div>
  );
};

export default Accountant;