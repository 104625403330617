import { Map, List } from 'immutable';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getQuiz as getQuizSdk,
  postNovaTentativa as postNovaTentativaSdk,
  postResponder as postResponderSdk,
  postFinalizarTentativa as postFinalizarTentativaSdk,
  getTentativaConcluida as getTentativaConcluidaSdk,
  getListaTentativasQuiz as getListaTentativasQuizSdk,
} from '@sdk/Wikis/EAD/Quiz';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_QUIZ: 'WIKI_EAD_QUIZ/GET_QUIZ',
  GET_QUIZ_SUCCESS: 'WIKI_EAD_QUIZ/GET_QUIZ_SUCCESS',
  GET_QUIZ_ERROR: 'WIKI_EAD_QUIZ/GET_QUIZ_ERROR',

  POST_NOVA_TENTATIVA: 'WIKI_EAD_QUIZ/POST_NOVA_TENTATIVA',
  POST_NOVA_TENTATIVA_SUCCESS: 'WIKI_EAD_QUIZ/POST_NOVA_TENTATIVA_SUCCESS',
  POST_NOVA_TENTATIVA_ERROR: 'WIKI_EAD_QUIZ/POST_NOVA_TENTATIVA_ERROR',

  POST_RESPONDER: 'WIKI_EAD_QUIZ/POST_RESPONDER',
  POST_RESPONDER_SUCCESS: 'WIKI_EAD_QUIZ/POST_RESPONDER_SUCCESS',
  POST_RESPONDER_ERROR: 'WIKI_EAD_QUIZ/POST_RESPONDER_ERROR',

  POST_FINALIZAR_TENTATIVA: 'WIKI_EAD_QUIZ/POST_FINALIZAR_TENTATIVA',
  POST_FINALIZAR_TENTATIVA_SUCCESS: 'WIKI_EAD_QUIZ/POST_FINALIZAR_TENTATIVA_SUCCESS',
  POST_FINALIZAR_TENTATIVA_ERROR: 'WIKI_EAD_QUIZ/POST_FINALIZAR_TENTATIVA_ERROR',

  GET_TENTATIVA_CONCLUIDA: 'WIKI_EAD_QUIZ/GET_TENTATIVA_CONCLUIDA',
  GET_TENTATIVA_CONCLUIDA_SUCCESS: 'WIKI_EAD_QUIZ/GET_TENTATIVA_CONCLUIDA_SUCCESS',
  GET_TENTATIVA_CONCLUIDA_ERROR: 'WIKI_EAD_QUIZ/GET_TENTATIVA_CONCLUIDA_ERROR',

  SET_PERGUNTAS_OPCOES: 'WIKI_EAD_QUIZ/SET_PERGUNTAS_OPCOES',

  GET_LISTA_TENTATIVAS_QUIZ: 'WIKI_EAD_QUIZ/GET_LISTA_TENTATIVAS_QUIZ',
  GET_LISTA_TENTATIVAS_QUIZ_SUCCESS: 'WIKI_EAD_QUIZ/GET_LISTA_TENTATIVAS_QUIZ_SUCCESS',
  GET_LISTA_TENTATIVAS_QUIZ_ERROR: 'WIKI_EAD_QUIZ/GET_LISTA_TENTATIVAS_QUIZ_ERROR',

  RESET: 'WIKI_EAD_QUIZ/RESET',
};

export const getQuiz = (id) => ({ type: Types.GET_QUIZ, id });
export const getQuizSuccess = (data) => ({ type: Types.GET_QUIZ_SUCCESS, data });
export const getQuizError = (error) => ({ type: Types.GET_QUIZ_ERROR, error });

export const postNovaTentativa = (data) => ({ type: Types.POST_NOVA_TENTATIVA, data });
export const postNovaTentativaSuccess = (data) => ({ type: Types.POST_NOVA_TENTATIVA_SUCCESS, data });
export const postNovaTentativaError = (error) => ({ type: Types.POST_NOVA_TENTATIVA_ERROR, error });

export const postResponder = (data) => ({ type: Types.POST_RESPONDER, data });
export const postResponderSuccess = (data) => ({ type: Types.POST_RESPONDER_SUCCESS, data });
export const postResponderError = (error) => ({ type: Types.POST_RESPONDER_ERROR, error });

export const postFinalizarTentativa = (id, desistencia) => ({ type: Types.POST_FINALIZAR_TENTATIVA, id, desistencia });
export const postFinalizarTentativaSuccess = () => ({ type: Types.POST_FINALIZAR_TENTATIVA_SUCCESS });
export const postFinalizarTentativaError = (error) => ({ type: Types.POST_FINALIZAR_TENTATIVA_ERROR, error });

export const getTentativaConcluida = (id) => ({ type: Types.GET_TENTATIVA_CONCLUIDA, id });
export const getTentativaConcluidaSuccess = (data) => ({ type: Types.GET_TENTATIVA_CONCLUIDA_SUCCESS, data });
export const getTentativaConcluidaError = (error) => ({ type: Types.GET_TENTATIVA_CONCLUIDA_ERROR, error });

export const setPerguntasOpcoes = (data) => ({ type: Types.SET_PERGUNTAS_OPCOES, data });

export const getListaTentativasQuiz = (id) => ({ type: Types.GET_LISTA_TENTATIVAS_QUIZ, id });
export const getListaTentativasQuizSuccess = (data) => ({ type: Types.GET_LISTA_TENTATIVAS_QUIZ_SUCCESS, data });
export const getListaTentativasQuizError = (error) => ({ type: Types.GET_LISTA_TENTATIVAS_QUIZ_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetQuizSaga(action) {
  try {
    const { id } = action;
    const resp = yield call(getQuizSdk, id);
    yield put(getQuizSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getQuizError(err));
  }
}

function* fetchPostNovaTentativaSaga(action) {
  try {
    const { data } = action;
    const resp = yield call(postNovaTentativaSdk, data);
    yield put(postNovaTentativaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(postNovaTentativaError(err));
  }
}

function* fetchPostResponderSaga(action) {
  try {
    const { data } = action;
    const resp = yield call(postResponderSdk, data);
    yield put(postResponderSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(postResponderError(err));
  }
}

function* fetchPostFinalizarTentativaSaga(action) {
  try {
    const { id, desistencia } = action;
    yield call(postFinalizarTentativaSdk, id, desistencia);
    yield put(postFinalizarTentativaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(postFinalizarTentativaError(err));
  }
}

function* fetchGetTentativaConcluidaSaga(action) {
  try {
    const { id } = action;
    const resp = yield call(getTentativaConcluidaSdk, id);
    yield put(getTentativaConcluidaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTentativaConcluidaError(err));
  }
}

function* fetchGetListaTentativasQuizSaga(action) {
  try {
    const { id } = action;
    const resp = yield call(getListaTentativasQuizSdk, id);
    yield put(getListaTentativasQuizSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getListaTentativasQuizError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_QUIZ, fetchGetQuizSaga),
  takeLatest(Types.POST_NOVA_TENTATIVA, fetchPostNovaTentativaSaga),
  takeLatest(Types.POST_RESPONDER, fetchPostResponderSaga),
  takeLatest(Types.POST_FINALIZAR_TENTATIVA, fetchPostFinalizarTentativaSaga),
  takeLatest(Types.GET_TENTATIVA_CONCLUIDA, fetchGetTentativaConcluidaSaga),
  takeLatest(Types.GET_LISTA_TENTATIVAS_QUIZ, fetchGetListaTentativasQuizSaga),
];

// Reducer
const initialState = Map({
  quiz: {},
  loadingQuiz: false,
  successQuiz: false,
  errorQuiz: false,

  tentativa: {},
  loadingTentativa: false,
  successTentativa: false,
  errorTentativa: false,

  loadingResponder: false,
  successResponder: false,
  errorResponder: false,

  loadingFinalizarTentativa: false,
  successFinalizarTentativa: false,
  errorFinalizarTentativa: false,

  tentativaConcluida: {},
  loadingTentativaConcluida: false,
  successTentativaConcluida: false,
  errorTentativaConcluida: false,

  opcoes: List(),

  listaTentativasQuiz: {},
  loadingListaTentativasQuiz: false,
  successListaTentativasQuiz: false,
  errorListaTentativasQuiz: false,
});

const handleGetQuiz = (state, action) => {
  return state.set('loadingQuiz', true).set('successQuiz', false).set('errorQuiz', false);
};

const handleGetQuizSuccess = (state, action) => {
  return state.set('quiz', action.data).set('loadingQuiz', false).set('successQuiz', true).set('errorQuiz', false);
};

const handleGetQuizError = (state, action) => {
  return state.set('loadingQuiz', false).set('successQuiz', false).set('errorQuiz', action.error);
};

const handlePostNovaTentativa = (state, action) => {
  return state.set('loadingTentativa', true).set('successTentativa', false).set('errorTentativa', false);
};

const handlePostNovaTentativaSuccess = (state, action) => {
  return state.set('tentativa', action.data).set('loadingTentativa', false).set('successTentativa', true).set('errorTentativa', false);
};

const handlePostNovaTentativaError = (state, action) => {
  return state.set('loadingTentativa', false).set('successTentativa', false).set('errorTentativa', action.error);
};

const handlePostResponder = (state, action) => {
  return state.set('loadingResponder', true).set('successResponder', false).set('errorResponder', false);
};

const handlePostResponderSuccess = (state, action) => {
  return state.set('loadingResponder', false).set('successResponder', true).set('errorResponder', false);
};

const handlePostResponderError = (state, action) => {
  return state.set('loadingResponder', false).set('successResponder', false).set('errorResponder', action.error);
};

const handlePostFinalizarTentativa = (state, action) => {
  return state.set('loadingFinalizarTentativa', true).set('successFinalizarTentativa', false).set('errorFinalizarTentativa', false);
};

const handlePostFinalizarTentativaSuccess = (state, action) => {
  return state.set('loadingFinalizarTentativa', false).set('successFinalizarTentativa', true).set('errorFinalizarTentativa', false);
};

const handlePostFinalizarTentativaError = (state, action) => {
  return state.set('loadingFinalizarTentativa', false).set('successFinalizarTentativa', false).set('errorFinalizarTentativa', action.error);
};

const handleGetTentativaConcluida = (state, action) => {
  return handleReset(state, action)
    .set('loadingTentativaConcluida', true)
    .set('successTentativaConcluida', false)
    .set('errorTentativaConcluida', false);
};

const handleGetTentativaConcluidaSuccess = (state, action) => {
  return state
    .set('tentativaConcluida', action.data)
    .set('loadingTentativaConcluida', false)
    .set('successTentativaConcluida', true)
    .set('errorTentativaConcluida', false);
};

const handleGetTentativaConcluidaError = (state, action) => {
  return state.set('loadingTentativaConcluida', false).set('successTentativaConcluida', false).set('errorTentativaConcluida', action.error);
};

const handleSetPerguntasOpcoes = (state, action) => {
  const { data } = action;
  let opcoes = state.get('opcoes').toArray();

  if (Array.isArray(data)) {
    opcoes = data;
  } else {
    if (data.idPergunta === undefined) return state;

    const opcao = opcoes.filter((op) => op.idPergunta === data.idPergunta)[0];

    if (opcao) {
      opcao.opcoes = data.opcoes;
    } else {
      opcoes.push({ idPergunta: data.idPergunta, opcoes: data.opcoes });
    }
  }
  return state.set('opcoes', List(opcoes));
};

const handleReset = (state, action) => {
  return state
    .set('tentativaConcluida', {})
    .set('loadingTentativaConcluida', false)
    .set('successTentativaConcluida', false)
    .set('errorTentativaConcluida', false)
    .set('successFinalizarTentativa', false)
    .set('loadingTentativa', false)
    .set('successTentativa', false)
    .set('errorTentativa', false);
};

const handleGetListaTentativasQuiz = (state, action) => {
  return state.set('loadingListaTentativasQuiz', true).set('successListaTentativasQuiz', false).set('errorListaTentativasQuiz', false);
};

const handleGetListaTentativasQuizSuccess = (state, action) => {
  return state
    .set('listaTentativasQuiz', action.data)
    .set('loadingListaTentativasQuiz', false)
    .set('successListaTentativasQuiz', true)
    .set('errorListaTentativasQuiz', false);
};

const handleGetListaTentativasQuizError = (state, action) => {
  return state
    .set('loadingListaTentativasQuiz', false)
    .set('successListaTentativasQuiz', false)
    .set('errorListaTentativasQuiz', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_PERGUNTAS_OPCOES:
      return handleSetPerguntasOpcoes(state, action);

    case Types.GET_QUIZ:
      return handleGetQuiz(state, action);
    case Types.GET_QUIZ_SUCCESS:
      return handleGetQuizSuccess(state, action);
    case Types.GET_QUIZ_ERROR:
      return handleGetQuizError(state, action);

    case Types.POST_NOVA_TENTATIVA:
      return handlePostNovaTentativa(state, action);
    case Types.POST_NOVA_TENTATIVA_SUCCESS:
      return handlePostNovaTentativaSuccess(state, action);
    case Types.POST_NOVA_TENTATIVA_ERROR:
      return handlePostNovaTentativaError(state, action);

    case Types.POST_RESPONDER:
      return handlePostResponder(state, action);
    case Types.POST_RESPONDER_SUCCESS:
      return handlePostResponderSuccess(state, action);
    case Types.POST_RESPONDER_ERROR:
      return handlePostResponderError(state, action);

    case Types.POST_FINALIZAR_TENTATIVA:
      return handlePostFinalizarTentativa(state, action);
    case Types.POST_FINALIZAR_TENTATIVA_SUCCESS:
      return handlePostFinalizarTentativaSuccess(state, action);
    case Types.POST_FINALIZAR_TENTATIVA_ERROR:
      return handlePostFinalizarTentativaError(state, action);

    case Types.GET_TENTATIVA_CONCLUIDA:
      return handleGetTentativaConcluida(state, action);
    case Types.GET_TENTATIVA_CONCLUIDA_SUCCESS:
      return handleGetTentativaConcluidaSuccess(state, action);
    case Types.GET_TENTATIVA_CONCLUIDA_ERROR:
      return handleGetTentativaConcluidaError(state, action);

    case Types.GET_LISTA_TENTATIVAS_QUIZ:
      return handleGetListaTentativasQuiz(state, action);
    case Types.GET_LISTA_TENTATIVAS_QUIZ_SUCCESS:
      return handleGetListaTentativasQuizSuccess(state, action);
    case Types.GET_LISTA_TENTATIVAS_QUIZ_ERROR:
      return handleGetListaTentativasQuizError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}
