import React from 'react';
import { 
  Grid
} from '@components-teammove';
import { Square } from './styles';

const FinishFlag = ({ rows = 7, height, ...props }) => {

  const FirstGrid = () => (
    <>
      <Square height={(height / rows)} />
      <Square height={(height / rows)} color='1'/>
    </>
  );

  const SecondGrid = () => (
    <>
      <Square color='1' height={(height / rows)}/>
      <Square height={(height / rows)}/>
    </>
  );

  const mountFlag = () => {
    const numberOfRepeats = Math.ceil(rows / 2);
    const flagRows = [];
    for (let index = 0; index < numberOfRepeats; index++) {
      if(rows % 2 !== 0 && index === numberOfRepeats - 1) {
        flagRows.push(
          <React.Fragment key={index}>
            <FirstGrid />
           
          </React.Fragment>
        );
      }else{
        flagRows.push(
          <React.Fragment key={index}>
            <FirstGrid />
            <SecondGrid />
          </React.Fragment>
        );
      }
      
    }
    return flagRows;
  };

  return (
    <Grid width={ height ? (height / rows) * 2 + 'px' : '30px'} numberOfColumns={2} {...props}>
      {mountFlag()}
     
    </Grid>
  );
  
};

export default FinishFlag;