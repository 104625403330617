import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Input, Rate, Form } from '@components-teammove';
import { Spin } from '@components';
import { apiPostNewFeedback } from '@ducks/wikisAcoes';

const FormContainer = styled(Form)`
  align-items: center;
  > * {
    margin-top: 10px;
  }

  .ant-form-item-control-input-content {
    text-align: center;
  }
`;

export default function NewFeedback({ visible, onCancel, id, feedbackGivenCallback }) {
  const [content, setContent] = useState();
  const [nota, setNota] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    setContent(createContent(false));
  }, []);

  const createContent = (loading, nota, texto) => (
    <Spin spinning={loading}>
      <FormContainer form={form}>
        <Form.Item name='nota'>
          <Rate 
            defaultValue={nota} 
            onChange={(value) => {
              setNota(value);
              setContent(
                createContent(false, value, texto)
              );
            }} 
          />
        </Form.Item>
        <Form.Item name='texto'>
          <Input placeholder="Deixe um comentário..." defaultValue={texto} />
        </Form.Item>
      </FormContainer>
    </Spin>
  );

  const handleSubmit = async function() {
    try {
      const data = await form.validateFields();
      setContent(
        createContent(true, data.nota, data.texto),
      );

      const retorno = await apiPostNewFeedback({
        ...data,
        idCategoria: id,
      });
      if (retorno) {
        if (feedbackGivenCallback) {
          feedbackGivenCallback();
        }
        onCancel();
      } else {
        setContent(
          createContent(false, data.nota, data.texto),
        );
      }
    } catch(err) {
      console.error(err);
    }
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      title='Feedback'
      okButtonText='Enviar'
      okButtonProps={{ disabled: !nota }}
      onOk={() => handleSubmit()}
    >
      {content}
    </Modal>
  );
}
