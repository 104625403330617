import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Modal } from '@components-teammove';
import { Spin } from '@components';
import { Input, Rate, Form } from '@components/Unform';
import { apiPostNewFeedback } from '@ducks/wikisAcoes';

const FormContainer = styled(Form)`
  align-items: center;
  > * {
    margin-top: 10px;
  }
`;

const ButtonSubmit = styled(Button)`
  ${({ disabled }) => disabled && 
    css`
      filter: brightness(80%);
    `}
`;

export default function NewFeedback({ visible, onCancel, id, feedbackGivenCallback }) {
  const [content, setContent] = useState();

  const createContent = (loading, nota, texto) => (
    <Spin spinning={loading}>
      <FormContainer onSubmit={handleSubmit}>
        <Rate 
          name="nota" 
          defaultValue={nota} 
          onChange={(value) => setContent(
            createContent(false, value, texto)
          )} 
        />
        <Input name="texto" placeholder="Deixe um comentário..." defaultValue={texto} />
        <ButtonSubmit type="success" htmlType="submit" disabled={!nota} block>
          Enviar
        </ButtonSubmit>
      </FormContainer>
    </Spin>
  );

  const handleSubmit = async function(data) {
    setContent(
      createContent(true, data.nota, data.texto),
    );

    const retorno = await apiPostNewFeedback({
      ...data,
      idCategoria: id,
    });
    if (retorno) {
      close();
      if (feedbackGivenCallback) {
        feedbackGivenCallback();
      }
    } else {
      setContent(
        createContent(false, data.nota, data.texto),
      );
    }
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      title='Feedback'
    >
      {content}
    </Modal>
  );
}
