import React, { useState, useEffect } from 'react';
import { Modal, Form, Picker, Input, Skeleton, Switch, Icon } from '@components-teammove';

import { useLocation, useHistory } from 'react-router-dom';
import { TelasNomenclaturas, arrayReorder } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { FlexDivRow, QuestionActionButton, DivRow, NewQuestionButton, ColorFormItem, SelfAlignEndFormItem, RedStar, RowDescription } from './styles';
import { saveClassification, getClassification, resetSuccesClassificacao } from '@ducks/classificacoes';
import { initialValues, getNewId } from './rules';

export default function ClassificacaoModal({ visible, onCancel }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const id = location.state?.tipoClassificacao.id;

  const ticketsNomenclature = TelasNomenclaturas.getNomenclatures('CHAMADOS') || { nomenclatura: 'Classificação', plural: 'Classificações', artigo: 'o' };

  const loadingSaveClassification = useSelector(({ classificacoes }) => classificacoes.get('loadingSaveClassification'));
  const successClassification = useSelector(({ classificacoes }) => classificacoes.get('successClassification'));
  const classification = useSelector(({ classificacoes }) => classificacoes.get('classification'));
  const loadingClassification = useSelector(({ classificacoes }) => classificacoes.get('loadingClassification'));

  const [classificacao, setClassificacao] = useState({});
  const [idList, setIdList] = useState([]);
  const [opcoesAtivas, setOpcoesAtivas] = useState(classificacao?.opcoes?.filter((data) => data.ativo));
  const [classificacaoOriginal, setClassificacaoOriginal] = useState(null);

  const classificationExists = !!id;

  useEffect(() => {
    if(classificationExists) {
      dispatch(getClassification(parseInt(id)));
    }else{
      form.setFieldsValue(initialValues);
    }

    return () => {
      history.push('#');
      dispatch(resetSuccesClassificacao());
    };
  }, []); 

  useEffect(() => { 
    if(successClassification) {
      const options = classification.opcoes.reduce((obj, opcao) => ({ ...obj, [`name${opcao.id}`]: opcao.titulo, [`cor${opcao.id}`]: opcao.cor }), {});
      const optionsWithTempId = classification.opcoes.map((opcao, index) => ({ ...opcao, tempId: opcao.id, ordem: index }));
      form.setFieldsValue({ ...classification, ...options });
      setClassificacao({ ...classification, opcoes: optionsWithTempId });
      setIdList(classification.opcoes.map((opcao) => opcao.id));
      setClassificacaoOriginal({ ...classification, opcoes: optionsWithTempId });
    }
  }, [successClassification]);

  useEffect(() => {
    if (classificacao?.opcoes?.filter((data) => data.ativo).length !== opcoesAtivas?.length) {
      setOpcoesAtivas(classificacao?.opcoes?.filter((data) => data.ativo).map((data, index) => ({ ...data, ordem: index })));
    }
  }, [classificacao]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      const opcoesMapeadas = classificacao.opcoes.map((opcao, index) => {
        return {
          id: opcao.id,
          ativo: opcao.ativo,
          titulo: opcao.titulo,
          cor: opcao.cor,
          ordem: index,
        };
      });

      const editedData = {
        id: classificacao.id,
        ativo: values.ativo,
        titulo: values.titulo,
        opcoes: opcoesMapeadas,
      };
      dispatch(saveClassification(editedData));
      onCancel();
      
    }).catch(Form.scrollToFirstError);
  };

  const handleNew = () => {
    const id = getNewId(idList);
    setIdList([...idList, id]);

    const opcoesArray = classificacao?.opcoes || [];
    const novaOpcao = {
      ativo: true,
      cor: '',
      titulo: '',
      ordem: opcoesArray.length,
      tempId: id,
    };
    setClassificacao({ ...classificacao, opcoes: [...opcoesArray, novaOpcao] });
  };

  const handleDelete = (itemToDelete) => { 
    setClassificacao((prevClassificacoes) => {
      const updatedOpcoes = prevClassificacoes.opcoes.filter((opc) => opc.ativo).map((item, index) =>
        index === itemToDelete ? { ...item, ativo: false } : item
      );
      return { ...prevClassificacoes, opcoes: updatedOpcoes };
    });
  };

  const handleReorder = (oldOrder, newOrder) => {
    setClassificacao(() => {
      const newOpcoes =
        arrayReorder(classificacao.opcoes, oldOrder, newOrder)
          .map((item, index) => ({ ...item, ordem: index }));
      return { ...classificacao, opcoes: newOpcoes };
    });
  };

  const handleInputChange = (id, field, value) => {
    setClassificacao((prevClassificacoes) => {
      const updatedOpcoes = prevClassificacoes.opcoes.map((item) =>
        item.tempId === id ? { ...item, [field]: value } : item
      );
      return { ...prevClassificacoes, opcoes: updatedOpcoes };
    });
  };

  const onBeforeCancel = () => {
    if(classificacaoOriginal) {
      const currentFormValues = classificacao;
      const valuesChanged = !Object.entries(classificacaoOriginal).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Configuração/ ' + (ticketsNomenclature.plural) + ' / Classificações'}
      cancelText='Cancelar'
      onBeforeCancel={onBeforeCancel}
      onOk={handleSalvar}
      okButtonProps={{ loading: loadingSaveClassification }}
    >
      <Form layout='vertical' form={form}>
          
        {loadingClassification ? <Skeleton.Form /> : (
          <>
            {classificationExists && (
              <SelfAlignEndFormItem name='ativo' label='Ativo' valuePropName='checked'>
                <Switch/>
              </SelfAlignEndFormItem>
            )}
            <Form.Item label='Descrição' name='titulo' rules={[{ required: true, message: 'Informe o tipo!' }]}>
              <Input placeholder='Informe o nome da classificação' autoFocus />
            </Form.Item>
          </>
        )}

        <Form.Provider>
          {classificacao?.opcoes?.filter((opcao) => opcao.ativo).map((item, index) => (
            loadingClassification ? <Skeleton.Form /> : (
              <>
                {index === 0 && (
                  <RowDescription>
                  Classificação
                    <RedStar>*</RedStar>
                  </RowDescription>
                )}
                <FlexDivRow  key={item.tempId}>
                  <Form.Item name={`name${item.tempId}`} rules={[{ required: true, whitespace: true, message: 'Informe o tipo!' }]}>
                    <Input
                      onChange={(e) => handleInputChange(item.tempId, 'titulo', e.target.value)}
                      placeholder='Informe o nome'
                      autoFocus
                    />
                  </Form.Item>

                  <DivRow>
                    <ColorFormItem
                      name={`cor${item.tempId}`}
                      valuePropName='color'
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues[`cor${item.tempId}`] !== currentValues[`cor${item.tempId}`]
                      }
                      rules={[{ required: true, message: 'Insira uma cor' }]}
                    >
                      <Picker
                        onChange={(cor) => handleInputChange(item.tempId, 'cor', cor)}
                        size='small'
                      />
                    </ColorFormItem>
                    <QuestionActionButton type='secondary' size='small' icon={<Icon.UpOutlined />} title='Mover para cima' disabled={index === 0} onClick={() => handleReorder(item.ordem, item.ordem - 1)} />
                    <QuestionActionButton type='secondary' size='small' icon={<Icon.DownOutlined />} title='Mover para baixo' disabled={index === opcoesAtivas?.length - 1} onClick={() => handleReorder(item.ordem, item.ordem + 1)} />
                    <QuestionActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} title='Excluir' onClick={() => handleDelete(index)} />
                  </DivRow>
                </FlexDivRow>
              </>
            )           
          ))}
          <NewQuestionButton type='link' onClick={() => handleNew()}>
            <Icon.PlusOutlined />
              Adicionar classificação
          </NewQuestionButton>
        </Form.Provider>
      </Form>
    </Modal>
  );
}