import styled from 'styled-components';
import { Icon, Row } from '@components-teammove';

export const VerticalMarker = styled.div`
  width: 2px;
  z-index: 3;
  height: 75px;
  background-color: ${({ color })=> color ? color : ''};
  position: relative;
  left: ${({ percent }) => `${percent}%`};
`;

export const HorizontalLine = styled.div`
  border: none;
  border-top: 2px ${({ typeLine })=> typeLine ? typeLine : 'solid'} ${({ color, theme }) => color ? color : theme.textColor} };
  opacity: ${({ color })=> color ? '1' : '0.5'};
  width: ${({ percent }) => `${percent}%`};
  padding-bottom: 19px;
`;

export const Marker = styled(Icon.MDI)`
  transform: scale(1.34);
  z-index: 1;
  color: ${({ color, theme })=> color ? color : theme.textColor};
  opacity: ${({ color })=> color ? '1' : '0.5'};
  position: relative;
  left: ${({ type, defaultPos })=> defaultPos ? '-13px' : type && type === 'walk' ? '8px' : type === 'run' ? '7px' : '6px' };
`;

export const ContentFinishFlag = styled(Row)`
  position: relative;
  left: ${({ left })=> left ? `${left}%` : '100%'}; 
  z-index: 1;
`;