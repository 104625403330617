import React from 'react';
import { Modal, Form, Input, Switch } from '@components';

export default function ModalMateria({ data, visible, onCancel, loading, onOk }) {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onOk({ index: data.index, materia: { ...data.materia, ...values } });
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      maskClosable={false}
      title={data.materia ? 'Editar matéria' : 'Nova matéria'}
    >
      <Form layout='vertical' form={form} initialValues={data.materia}>
        <Form.Item label='Título' name='titulo' rules={[{ required: true, message: 'Informe um título' }]}>
          <Input autoFocus />
        </Form.Item>
        {data.materia && <Form.Item label="Publicado" name="publicado" valuePropName="checked">
          <Switch />
        </Form.Item>}
      </Form>
    </Modal>
  );
}
