import styled from 'styled-components';
import { Text } from '@components-teammove';

export const LateText = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};

    strong {
      font-weight: 700;
    }
  }
`;

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ lowOpacity })=> lowOpacity && '.5'};
`;