import styled from 'styled-components';
import { Row } from '@components-teammove';

export const Container = styled(Row)`
  width: 300px;
`;

export const Division = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  flex: 1;
  height: 10px;
`;

export const ColorIndicator = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  width: 7px;
  height: 7px;
  border-radius: 50%;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;