import styled from 'styled-components';
import { View, Text, Button, Column, Row } from '@components-teammove';

export const ContainerRanking = styled(Column)`
    width: 100%;
`;

export const ContanierRow = styled(Row)`
    margin: 0 20px;
    min-height: 48px;
`;

export const ContainerList = styled(View)`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};	
`;

export const TitleRanking = styled(Text)`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor} !important;
`;

export const ButtonConatiner = styled(Button)`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    padding: 6px 30px !important;
`;

export const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.textColor} !important;
`;

export const TableList = styled(View)`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: ${({ theme }) => theme.textColor};	
`;

export const TableSmallText = styled(Text)`
    font-size: ${({ valorCliente }) => valorCliente ? '14px' : '12px'};
    font-weight: 400;
    ${({ theme }) => theme.textColor};
    ${({ descricao }) => descricao && 'opacity: 0.5;'};
    ${({ pedidos }) => pedidos && 'text-align: right;'};
`;

export const TableTallText = styled(Text)`
    font-size: 16px;
    font-weight: 600;
    ${({ theme }) => theme.textColor};
`;

export const ContainerPrice = styled(View)`
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border-radius: 12px;
    padding: 4px 12px;
    height: fit-content;
`;