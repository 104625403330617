import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Form, Input, Select, View, Skeleton, Slider, Radio, DatePicker, MultiDatePicker, Switch } from '@components-teammove';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCampanha, getCampanha, saveCampanha, reset } from '@ducks/campanhasCadastro';
import { getTipos, reset as resetTypes } from '@ducks/configuracoesCampanhasTipos';
import moment, { ansiToMoment  , formatDate , dateToAnsiDate } from '@utils/Datas';
import { download, getIdTemp, Notification, InfoUsuario } from '@utils';
import { authorization } from '@sdk/api';
import { initialWorkingDays } from './rules';
import { ContentSizeButton, FixedTextArea, FlexRow, FormItemFill, IconRemoveCover, ImageCover, MarginBottom, MarginfulButton, SelfAlignEndFormItem, SpaceBetweenRow, TrophyIcon, UploadFill, UploadIcon, UploadText } from './styles';
import ModalPremios from './Premios';
import ModalSubcampanhas from './Subcampanhas/Cadastro';
import ListaSubcampanhas from './Subcampanhas';
import { getAgrupadores } from '@sdk/AgrupadoresCampanhas';
import { PositivationIcon, SalesIcon } from '../styles';

const { REACT_APP_API } = window.env;

export default function CadastroCampanha() {
  const history = useHistory();
  const { externalIdCampanha } = useParams();
  const [form] = Form.useForm();
  const [subcampaignForm] = Form.useForm();

  const [allRoles, setAllRoles] = useState();
  const [allTypes, setAllTypes] = useState();
  const [isPositivation, setIsPositivation] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameSuffix, setCampaignNameSuffix] = useState();
  const [subcampaignName, setSubcampaignName] = useState('');
  const [imageCover, setImageCover] = useState();
  const [currentRelatedImageCover, setCurrentRelatedImageCover] = useState();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [calendarRange, setCalendarRange] = useState();
  const [workingDays, setWorkingDays] = useState([]);
  const [numberOfWorkingDays, setNumberOfWorkingDays] = useState();
  const [isGroupedCampaign, setIsGroupedCampaign] = useState(false);
  const [modalPrizeFormVisible, setModalPrizeFormVisible] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [modalSubcampaignFormVisible, setModalSubcampaignFormVisible] = useState(false);
  const [subcampaigns, setSubcampaigns] = useState([]);

  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const campaign = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('campanha'));
  const loading = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('loading'));
  const success = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('success'));
  const campaignTypes = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('tipos'));
  const successTypes = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('successTipos'));
  const loadingTypes = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('loadingTipos'));
  const loadingSave = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('loadingSave'));
  const successSave = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('successSave'));
  const loadingDeleteRelated = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('loadingDelete'));
  const successDeleteRelated = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('successDelete'));

  const [ isDuplication, setIsDuplication ] = useState();

  useEffect(() => {
    if (externalIdCampanha !== '-1') {
      const idCampaign = externalIdCampanha.includes('duplicar')
        ? externalIdCampanha.replace('-duplicar', '')
        : externalIdCampanha;
    
      setIsDuplication(externalIdCampanha.includes('duplicar'));
      dispatch(getCampanha(idCampaign));
    }

    (async () => {
      const roles = await getAgrupadores();
      setAllRoles(roles.map(({ id, name }) => ({ label: name, value: id })));

      dispatch(getTipos());
    })();

    return () => {
      dispatch(reset());
      dispatch(resetTypes());
    };
  }, []);

  useEffect(() => {
    if (successTypes) {
      const typesFiltered = campaignTypes.filter(({ active }) => active).map((type) => ({ ...type, label: type.description, value: type.id }));
      
      if (typesFiltered.length === 0) {
        Notification.info('É necessário cadastrar ao menos um tipo de campanha. Você será redirecionado para o cadastro de tipos de campanha.');
        setTimeout(() => history.push('/configuracoes/campanhas'), 5000);
      }
      setAllTypes(typesFiltered);

      if (allTypes && allTypes.length > 0 && !campaign?.idType) {
        form.setFieldsValue({ idType: allTypes[0].id });
        setIsPositivation(allTypes[0].type === 'POS');
      }
    }
  }, [successTypes]);
  useEffect(() => {
    if (success && Object.keys(campaign).length > 0) {
      setImageCover(campaign.image);
      setPrizes(campaign.prizes);
      if (campaign.relatedCampaignsLabel) setIsGroupedCampaign(true);
      setSubcampaigns(campaign.relatedCampaigns);
      
      let initialValues = {
        ...campaign,
        createdAt: !isDuplication ? ansiToMoment(campaign.createdAt) : undefined,
        endAt: !isDuplication ? ansiToMoment(campaign.endAt) : undefined,
        limitAt: !isDuplication ? ansiToMoment(campaign.limitAt) : undefined,
        startAt: !isDuplication ? ansiToMoment(campaign.startAt) : undefined,
        workingDays: isDuplication ? undefined : campaign.workingDays,
      };
      
      if (!isDuplication) {
        setCalendarRange({
          startAt: ansiToMoment(campaign.startAt),
          endAt: ansiToMoment(campaign.endAt),
        });
      }

      const customIdEnd = initialValues.customId.slice(-7);
      if(externalIdCampanha !== '-1' && !externalIdCampanha.includes('duplicar')) {
        setCampaignNameSuffix(customIdEnd);
      } else {
        const today = moment();
        const isAfter20th = today.date() >= 20;
        const monthYear = isAfter20th ? today.clone().add(1, 'month').format('MMYYYY') : today.format('MMYYYY');
        setCampaignNameSuffix('-' + monthYear);
      }
      
      if (campaign.type === 'POS') {
        setIsPositivation(true);
        initialValues.customId = initialValues.customId.slice(4);
        setSubcampaigns(campaign.relatedCampaigns.map((subcamp) => ({ ...subcamp, customId: subcamp.customId.slice(4) })));
      }

      initialValues.customId = initialValues.customId.slice(0, -7);

      if(!isDuplication) {
        if (campaign.workingDays) {
          const formattedWorkingDays = campaign.workingDays.map((day) => formatDate(day));
          setWorkingDays(formattedWorkingDays);
          setNumberOfWorkingDays(campaign.workingDays.length);
          initialValues.workingDays = formattedWorkingDays;
        }
      } 

      if (campaign.relatedCampaignsGoal) {
        initialValues.relatedCampaignsGoal = (campaign.relatedCampaignsGoal / (campaign.relatedCampaigns.length || 0)) * 100;
      }

      form.setFieldsValue(initialValues);
    }
  }, [campaign, success]);

  useEffect(() => {
    if (campaignName) {
      form.setFieldsValue({ customId: campaignName.toUpperCase().replaceAll(' ', '-') });
    }
  }, [campaignName]);
  
  useEffect(() => {
    if (subcampaignName) {
      subcampaignForm.setFieldsValue({ customId: subcampaignName.toUpperCase().replaceAll(' ', '-') });
    }
  }, [subcampaignName]);

  useEffect(() => {
    if (successDeleteRelated) {
      setModalSubcampaignFormVisible(false);
      subcampaignForm.resetFields();
    }
  }, [successDeleteRelated]);

  useEffect(() => {
    if (successSave) {
      window.history.back();
    }
  }, [successSave]);

  const handleWorkingDaysChange = (values) => {
    setWorkingDays(values.map((date) => date.format()));
    setNumberOfWorkingDays(values.length);
  };

  const handleUploadChange = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        setLoadingUpload(true);
        break;
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        setLoadingUpload(false);
        break;
      }
      case 'done': {        
        form.setFieldsValue({ image: file.response });
        setImageCover(file.response);
        setLoadingUpload(false);
        break;
      }
      default: {
        setLoadingUpload(false);
      }
    }
  };
  
  const handleRemoveCover = () => {
    setImageCover('');
    form.setFieldsValue({ image: null });
    form.validateFields(['image']).then();
  };

  const handlePrizesClick = () => {
    if (form.getFieldValue('roles')?.length > 0) {
      setModalPrizeFormVisible(true);
    } else {
      Notification.warning('Selecione ao menos um agrupador para a campanha');
    }
  };

  const handleAddSubcampaignClick = () => {
    if (!campaignNameSuffix) return Notification.warning('Insira a data de início da campanha para poder cadastrar subcampanhas');
    setCurrentRelatedImageCover('');
    setModalSubcampaignFormVisible(true);
  };
    
  const handleEditSubcampaignClick = (subcampaign) => {
    setCurrentRelatedImageCover(subcampaign.image);
    subcampaignForm.setFieldsValue({ ...subcampaign, customId: subcampaign.customId.slice(0, -7) });
    setModalSubcampaignFormVisible(true);
  };

  const handleSaveSubcampaign = () => {
    subcampaignForm.validateFields().then((values) => {
      setSubcampaigns(
        values.id || values.tempId ? 
          subcampaigns.map(
            (subcampaign) => {
              if (!((subcampaign.id && subcampaign.id === values.id) || (subcampaign.tempId && subcampaign.tempId === values.tempId))) return subcampaign;

              values.customId = values.customId + (subcampaign.id ? subcampaign.customId.slice(-7) : campaignNameSuffix);

              return { ...subcampaign, ...values, customId: values.customId };
            }
          ) :
          [...subcampaigns, { ...values, tempId: getIdTemp(), customId: values.customId + campaignNameSuffix }]
      );
      setModalSubcampaignFormVisible(false);
      subcampaignForm.resetFields();
    });
  };

  const handleDeleteSubcampaign = () => {
    const { id, tempId } = subcampaignForm.getFieldsValue();
    if (id) dispatch(deleteCampanha(id));
    setSubcampaigns(subcampaigns.filter((subcampaign) => subcampaign.id !== id || subcampaign.tempId !== tempId));
    setModalSubcampaignFormVisible(false);
    subcampaignForm.resetFields();
  };
  
  const handleSave = () => {
    if (isGroupedCampaign && subcampaigns.length === 0) {
      Notification.warning('Necessário cadastrar ao menos uma subcampanha para campanha agrupada');
      return;
    }
    form.validateFields().then((values) => {  
      if(externalIdCampanha !== '-1' && !externalIdCampanha.includes('duplicar')) {
        values.customId = campaign.customId.slice(-7);
      } else {
        const isAfter20th = values.startAt.date() >= 20;
        const monthYear = isAfter20th ? values.startAt.clone().add(1, 'month').format('MMYYYY') : values.startAt.format('MMYYYY');
        values.customId = `${values.customId}-${monthYear}`;
      }
      values.startAt = dateToAnsiDate(values.startAt);
      values.limitAt = dateToAnsiDate(values.limitAt);
      values.endAt = dateToAnsiDate(values.endAt);
      values.workingDays = workingDays.map((date) => dateToAnsiDate(date, 'DD-MM-YYYY'));

      const { type, format } = allTypes.find(({ value }) => value === values.idType);
      values.type = type;
      values.format = format;
      
      if (values.type === 'POS') {
        values.customId = 'POS-' + values.customId;
      }
      
      if (isGroupedCampaign) {
        values.relatedCampaignsGoal = Math.round((values.relatedCampaignsGoal / 100) * subcampaigns.length);

        values.relatedCampaigns = subcampaigns.map((subcampaign) => {
          delete subcampaign.tempId;
          delete subcampaign.prizes;

          if (isPositivation && subcampaign.customId.substr(0, 4) !== 'POS-') {
            subcampaign.customId = 'POS-' + subcampaign.customId;
          }
          
          return {
            ...subcampaign,
            startAt: values.startAt,
            endAt: values.endAt,
            limitAt: values.limitAt,
            roles: values.roles,
            format: values.format,
            type: values.type,
            idType: values.idType,
            workingDays: values.workingDays
          };
        });

        if (values.relatedCampaigns.length === 0) values.relatedCampaigns = null;
      }

      values.prizes = prizes.map((prize) => {
        delete prize.tempId;
        return prize;
      });

      dispatch(saveCampanha({ ...campaign, ...values, ...(isDuplication ? { id: 0 } : {}) }));
    });
  };

  const handleChangeInitialEndDate = (date, value) => {
    const newCalendarRange = { ...calendarRange, [value]: date };
    setCalendarRange(newCalendarRange);

    const filteredWorkingDays = initialWorkingDays(newCalendarRange.startAt, newCalendarRange.endAt);
    setWorkingDays(filteredWorkingDays);
    form.setFieldsValue({ workingDays: filteredWorkingDays });
    setNumberOfWorkingDays(filteredWorkingDays.length);
    setCampaignNameSuffix('-' + newCalendarRange.startAt.format('MMYYYY'));
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader 
          title='Cadastro de Campanha de Venda'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/campanhas/itens', name: 'Campanhas' },
                { path: '/campanhas/cadastro/' + externalIdCampanha, name: 'Cadastro', current: true }
              ]}
            />
          )}
          extra={[
            <Button key='1' type='secondary' size='small' context='header' onClick={() => window.history.back()}>
              Cancelar
            </Button>,
            <Button key='2' type='primary' disabled={loadingUpload} size='small' context='header' onClick={handleSave} loading={loadingSave}>
              Salvar
            </Button>,
          ]}
        >
          {loading ? <Skeleton.Form/> : (
            <>
              <Form form={form} layout='vertical'>
                <FlexRow>
                  <Form.Item name='idType' label='Tipo' rules={[{ required: true, message: 'Insira o tipo' }]}>
                    {loadingTypes ? (
                      <Skeleton.MediumLine/>
                    ) : (
                      <Radio.Group buttonStyle='solid' bordered onChange={(newValue) => setIsPositivation(allTypes.find(({ value }) => value === newValue).type === 'POS')}>
                        {allTypes?.map((type) => (
                          <Radio.Button value={type.id} key={type.id}>
                            {type.description}
                            {type.type === 'POS' ? <PositivationIcon type='thumbs-up'/> : <SalesIcon type='dollar-sign'/>}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  </Form.Item>
                  {externalIdCampanha !== '-1' && (<SelfAlignEndFormItem label='Ativa' name='active' valuePropName='checked'>
                    <Switch/>
                  </SelfAlignEndFormItem>)}
                </FlexRow>
                <FlexRow>
                  <Form.Item name='name' label='Nome' rules={[{ required: true, message: 'Insira o título' }]}>
                    <Input placeholder='Título' onChange={(evt) => setCampaignName(evt.target.value)}/>
                  </Form.Item>
                  <Form.Item name='customId' label='ID Customizado' rules={[{ required: true, message: 'Insira o ID customizado' }]} normalize={(value) => value.toUpperCase().replaceAll(' ', '-')}>
                    <Input placeholder='ID Customizado' addonBefore={isPositivation && 'POS-'} addonAfter={campaignNameSuffix}/>
                  </Form.Item>
                </FlexRow>
                <FlexRow>
                  <Form.Item name='description' label='Descrição' rules={[{ required: true, message: 'Insira a descrição' }]}>
                    <FixedTextArea placeholder='Descrição'/>
                  </Form.Item>
                  <Form.Item name='image' label='Capa' rules={[{ required: true, message: 'Insira a capa' }]}>
                    <UploadFill
                      headers={authorization()}
                      URI={REACT_APP_API}
                      folder={`/Campanhas/${InfoUsuario.get('cdCliente')}`}
                      onDownload={(file) => download(file.response)}
                      showUploadList={false}
                      onChange={(info) => handleUploadChange(info)}
                      accept={'image/*'}
                    >
                      {imageCover && !loadingUpload ? (
                        <View>
                          <ImageCover width={220} height={159} src={imageCover} alt='Capa' />
                          <IconRemoveCover
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRemoveCover();
                            }}
                          />
                        </View>
                      ) : (
                        <>
                          <UploadIcon type='upload'/>
                          <UploadText>Clique ou arraste um arquivo para anexar ao ticket</UploadText>
                        </>
                      )}
                    </UploadFill>
                  </Form.Item>
                </FlexRow>

                <SpaceBetweenRow>
                  <Form.Item name='startAt' label='Data Início' rules={[{ required: true, message: 'Insira a data de início' }]}>
                    <DatePicker format='DD/MM/YYYY' placeholder='Data de Início' onChange={(date) => handleChangeInitialEndDate(date, 'startAt')}/>
                  </Form.Item>
                  <Form.Item 
                    name='endAt' 
                    label='Data Fim' 
                    rules={[{ required: true, message: 'Insira a data de fim' }, ({ getFieldValue }) => ({ validator(rule, value) {
                      if (!moment(value).isBefore(getFieldValue('startAt'))) return Promise.resolve();
                      else return Promise.reject(new Error('Data de fim deve ser após a data de início'));
                    } })]}
                  >
                    <DatePicker format='DD/MM/YYYY' placeholder='Data de Fim' onChange={(date) => handleChangeInitialEndDate(date, 'endAt')}/>
                  </Form.Item>
                  <Form.Item 
                    name='limitAt' 
                    label='Data Fim Visualização' 
                    rules={[{ required: true, message: 'Insira a data de limite' }, ({ getFieldValue }) => ({ validator(rule, value) {
                      if (!moment(value).isBefore(getFieldValue('startAt'))) return Promise.resolve();
                      else return Promise.reject('Data de limite deve ser após a data de início');
                    } })]}
                  >
                    <DatePicker format='DD/MM/YYYY' placeholder='Visível até'/>
                  </Form.Item>
                  <Form.Item name='workingDays' label={`Dias úteis${numberOfWorkingDays ? ': ' + numberOfWorkingDays : ''}`} rules={[{ required: true, message: 'Insira os dias úteis' }]} normalize={(value) => value.map((date) => date.format().replaceAll('/', '-'))}>
                    <MultiDatePicker minDate={calendarRange?.startAt} maxDate={calendarRange?.endAt} placeholder='Dias Úteis' onChange={handleWorkingDaysChange}/>
                  </Form.Item>
                </SpaceBetweenRow>
          
                <Form.Item name='roles' label='Agrupadores' rules={[{ required: true, message: 'Insira os agrupadores' }]}>
                  <Select placeholder='Agrupadores' mode='multiple' options={allRoles}/>
                </Form.Item>

                <MarginfulButton type='secondary' onClick={handlePrizesClick} icon={<TrophyIcon/>}>Definir premiações</MarginfulButton>

                <ModalPremios 
                  visible={modalPrizeFormVisible}
                  setVisible={setModalPrizeFormVisible}
                  rolesOptions={(allRoles && form.getFieldValue('roles')?.map((role) => allRoles.find(({ value }) => value === role))) || []}
                  prizes={prizes}
                  setPrizes={setPrizes}
                />

                {externalIdCampanha === '-1' && (<Form.Item label='Campanha agrupada?'>
                  <Switch checked={isGroupedCampaign} onChange={(value) => setIsGroupedCampaign(value)}/>
                </Form.Item>)}

                {isGroupedCampaign && (
                  <>
                    <FlexRow>
                      <FormItemFill label='Descrição Agrupador de Campanhas' name='relatedCampaignsLabel' rules={[{ required: true, message: 'Informe a label' }]}>
                        <Input placeholder='Agrupador de subcampanhas'/>
                      </FormItemFill>
                      <FormItemFill label='Objetivo % subcampanhas alcançadas' name='relatedCampaignsGoal'>
                        <Slider tipFormatter={(value) => value + '%'}/>
                      </FormItemFill>
                    </FlexRow>

                    <ListaSubcampanhas handleClick={handleEditSubcampaignClick} subcampaigns={subcampaigns} isPositivation={isPositivation}/>
                    <MarginBottom>
                      <ContentSizeButton type='primary' size='small' onClick={handleAddSubcampaignClick}>Adicionar subcampanha</ContentSizeButton>
                    </MarginBottom>

                    <ModalSubcampanhas 
                      form={subcampaignForm} 
                      initialImage={currentRelatedImageCover}
                      nameSuffix={campaignNameSuffix}
                      isPositivation={isPositivation}
                      visible={modalSubcampaignFormVisible} 
                      setVisible={setModalSubcampaignFormVisible}
                      setName={setSubcampaignName}
                      handleOk={handleSaveSubcampaign}
                      handleDelete={handleDeleteSubcampaign}
                      loadingDelete={loadingDeleteRelated}
                    />
                  </>
                )}

              </Form>
            </>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}