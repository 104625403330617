import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { postComentarioAcao as postComentarioAcaoSdk } from '@sdk/Wikis/Comentario/Acao';

export const Types = {
  POST_COMENTARIO_ACAO: 'WIKI_COMENTARIOS_ACAO/POST_COMENTARIO_ACAO',
  POST_COMENTARIO_ACAO_SUCCESS: 'WIKI_COMENTARIOS_ACAO/POST_COMENTARIO_ACAO_SUCCESS',
  POST_COMENTARIO_ACAO_ERROR: 'WIKI_COMENTARIOS_ACAO/POST_COMENTARIO_ACAO_ERROR',
};

export const postComentarioAcao = ({ idComentario, acao }) => ({ type: Types.POST_COMENTARIO_ACAO, idComentario, acao });
export const postComentarioAcaoSuccess = () => ({ type: Types.POST_COMENTARIO_ACAO_SUCCESS });
export const postComentarioAcaoError = (error) => ({ type: Types.POST_COMENTARIO_ACAO_ERROR, error });

function* fetchPostComentarioAcao(action) {
  try {
    const { idComentario, acao } = action;

    yield call(postComentarioAcaoSdk, { idComentario, acao });
    yield put(postComentarioAcaoSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(postComentarioAcaoError(err));
  }
}

export const saga = [takeLatest(Types.POST_COMENTARIO_ACAO, fetchPostComentarioAcao)];

// Reducer
const initialState = Map({
  postingComentarioAcao: false,
  successPostComentarioAcao: false,
  errorPostComentarioAcao: false,
});

const handlePostComentarioAcao = (state, action) => {
  return state.set('postingComentarioAcao', true).set('successPostComentarioAcao', false).set('errorPostComentarioAcao', false);
};

const handlePostComentarioAcaoSuccess = (state, action) => {
  return state.set('postingComentarioAcao', false).set('successPostComentarioAcao', true).set('errorPostComentarioAcao', false);
};

const handlePostComentarioAcaoError = (state, action) => {
  return state.set('postingComentarioAcao', false).set('successPostComentarioAcao', false).set('errorPostComentarioAcao', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.POST_COMENTARIO_ACAO:
      return handlePostComentarioAcao(state, action);
    case Types.POST_COMENTARIO_ACAO_SUCCESS:
      return handlePostComentarioAcaoSuccess(state, action);
    case Types.POST_COMENTARIO_ACAO_ERROR:
      return handlePostComentarioAcaoError(state, action);

    default:
      return state;
  }
}
