import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import {
  getAtendimento as getAtendimentoSdk,
  saveAtendimento as saveAtendimentoSdk,
} from '@sdk/Chamados';

//Action Types
export const Types = {
  GET_ATENDIMENTO: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/GET_ATENDIMENTO',
  GET_ATENDIMENTO_SUCCESS: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/GET_ATENDIMENTO_SUCCESS',
  GET_ATENDIMENTO_ERROR: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/GET_ATENDIMENTO_ERROR',

  SAVE: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/SAVE',
  SAVE_SUCCESS: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/SAVE_SUCCESS',
  SAVE_ERROR: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/SAVE_ERROR',

  RESET: 'CONFIGURACOES_CHAMADOS_ATENDIMENTOS/RESET',
};

//Action Creators
export const getAtendimento = (id) => ({ type: Types.GET_ATENDIMENTO, id });
export const getAtendimentoSuccess = (atendimento) => ({ type: Types.GET_ATENDIMENTO_SUCCESS, atendimento });
export const getAtendimentoError = (error) => ({ type: Types.GET_ATENDIMENTO_ERROR, error });

export const saveAtendimento = (atendimento) => ({ type: Types.SAVE, atendimento });
export const saveAtendimentoSuccess = () => ({ type: Types.SAVE_SUCCESS });
export const saveAtendimentoError = (error) => ({ type: Types.SAVE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchSaveAtendimento(action) {
  try {
    const { atendimento } = action;

    yield call(saveAtendimentoSdk, atendimento);
    yield put(saveAtendimentoSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(saveAtendimentoError(err));
  }
}

function* fetchGetAtendimento(action) {
  try {
    const { id } = action;

    const atendimento = yield call(getAtendimentoSdk, id);
    yield put(getAtendimentoSuccess(atendimento));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtendimentoError(err));
  }
}

export const saga = [
  takeLatest(Types.SAVE, fetchSaveAtendimento),
  takeLatest(Types.GET_ATENDIMENTO, fetchGetAtendimento),
];

// Reducer
const initialState = Map({
  atendimento: {},
  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,
});

const handleGetAtendimento = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetAtendimentoSuccess = (state, action) => {
  return state
    .set('atendimento', action.atendimento)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetAtendimentoError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleSaveAtendimento = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};

const handleSaveAtendimentoSuccess = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleSaveAtendimentoError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ATENDIMENTO: return handleGetAtendimento(state, action);
    case Types.GET_ATENDIMENTO_SUCCESS: return handleGetAtendimentoSuccess(state, action);
    case Types.GET_ATENDIMENTO_ERROR: return handleGetAtendimentoError(state, action);

    case Types.SAVE: return handleSaveAtendimento(state, action);
    case Types.SAVE_SUCCESS: return handleSaveAtendimentoSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveAtendimentoError(state, action);

    case Types.RESET: return initialState;

    default: return state;
  }
}