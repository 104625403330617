import { moment } from '@utils';

export const filters = [
  {
    type: 'RANGE',
    label: 'Data',
    name: 'data',
    format: 'DD/MM/YYYY',
    ranges: {
      Hoje: [moment().startOf('day'), moment().endOf('day')],
      'Este Mês': [moment().startOf('month'), moment().endOf('month')],
      'Este Ano': [moment().startOf('year'), moment().endOf('year')],
    },
  },
  {
    type: 'SELECT',
    label: 'Status',
    name: 'pesquisar',
    mode: 'multiple',
    placeholder: 'Todos os conteúdos',
    options: [
      {
        label: 'Favoritos',
        value: 'fav',
      },
      {
        label: 'Lidos',
        value: 'lidos',
      },
      {
        label: 'Não Lidos',
        value: 'naoLidos',
      },
    ],
  },
];