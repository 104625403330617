/* eslint-disable react/forbid-component-props */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Select } from 'antd';
import { View } from '@components';
import { selectFirstOption } from './rules';

const SelectStyled = styled(Select)`
  width: 600px !important;
  max-width: 100%;
  ${(props) => props.error && css`
    border-color: red;
    border-width: 1.1px;
    border-style: solid;
  `}
`;

const DetailsStyled = styled(View)`
  font-size: 12px;

  b {
    font-size: 16px;
  }
`;

const SelectComponent = ({ action, children, disabled, onRenderOption, optionLabelProp, defaultActiveFirstOption, form, name, filterOptions, ...otherProps }) => {
  const [loading, setLoading] = useState((children) ? false : true);
  const [error, setError] = useState();
  const [options, setOptions] = useState(children || []);

  useEffect(() => {
    const fetchData = async () => {
      return action().then((res) => {
        setLoading(false);
        setOptions(res);

        if (defaultActiveFirstOption) {
          selectFirstOption(res, form, name);
        }        
      }).catch((err) => {
        setLoading(false);
        setError(err);
      });
    };

    if ((action) && (!children)) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const renderOption = (item) => {
    if (onRenderOption) {
      if (!optionLabelProp) console.warn('optionLabelProp not found for', options);
      return onRenderOption(item);
    }
    return <>
      {item.details ? <><DetailsStyled className='select-details' dangerouslySetInnerHTML={{ __html: item.details }}/></> : item.title || item.value}
    </>;
  };

  return (
    <SelectStyled
      allowClear
      showSearch
      loading={loading}
      error={error}
      disabled={(loading) ? true : (options.length === 1) ? true : disabled}
      optionLabelProp={optionLabelProp}
      filterOption={(input, option) => (option[option.title ? 'title' : 'label']?.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.details?.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
      {...otherProps}
    >
      {(children) ? children : options.filter(filterOptions).map((item) => (
        <Select.Option key={item.key} {...item}>{renderOption(item)}</Select.Option>
      ))}
    </SelectStyled>
  );
};

SelectComponent.propTypes = {
  action: PropTypes.func,
  children : PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  disabled: PropTypes.bool,
  onRenderOption: PropTypes.func,
  optionLabelProp: PropTypes.string,
  defaultActiveFirstOption: PropTypes.bool,
  form: PropTypes.object,
  name: PropTypes.string,
  filterOptions: PropTypes.func,
};

SelectComponent.defaultProps = {
  defaultActiveFirstOption: true,
  filterOptions: (item) => true,
};

SelectComponent.Option = Select.Option;
export default SelectComponent;
