import styled, { css } from 'styled-components';
import { Text, View, Divider, Icon } from '@components-teammove';
import { Paragraph, Rate  } from '@components';

export const Meta = styled(View)`
flex-direction: column;
justify-content: center;
flex: 1;
padding-right: 20px;
`;

export const Title = styled(View)``;

export const Description = styled(View)`
max-width: 600px;
`;

export const DescriptionHtml = styled(View)`
  margin-top: 20px;
  color: ${({ theme }) => theme.textColor};
`;

export const ContainerFeedback = styled(View)`
align-items: center;
cursor: pointer;
`;

export const Actions = styled(View)`
flex-direction: row;

> * {
  margin: 5px 5px 5px 0px;
}
`;

export const Extra = styled(View)`
align-items: center;
justify-content: center;
`;

export const TextResumo = styled(Paragraph)`
  &.ant-typography {
    margin-top: 2px;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const NotaFeedback = styled(Rate)`
  color: #e7711b !important;
  > li:not(:last-child) {
    margin-right: 2px;
  }
  > li {
    cursor: pointer !important;
  }
`;

export const TextNota = styled(Text)`
  &.ant-typography {
    color: #e7711b !important;
    padding: 4px 8px 0px 0px;
  }
`;

export const TextSmall = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
    ${(props) => props.margin &&
      css`
        margin-bottom: ${props.margin}px;
      `}

  &:first-letter {
    text-transform: capitalize;
  }
  }
`;

export const IconStar = styled(Icon.StarFilled)`
font-size: 20px;
color: var(--warning-color) !important;
${(props) =>
  props.invisible !== undefined &&
  css`
    visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
    margin-right: 5px;
  `}
`;

export const ComunicadoTagsContainer = styled(View)`
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const ComunicadoTag = styled(View)`
  ${(props) =>
  props.background &&
    css`
      background-color: ${props.background};
    `}
  border-radius: 20px;
  margin-right: 10px;
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;
  display: flex;

`;

export const ComunicadoTagText = styled(Text)`
  &.ant-typography {
    font-size: 11px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 500;
  }
`;

export const ComunicadoSequencial = styled(View)`
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    font-weight: 400;
    opacity: .5;
    ${(props) =>
  props.publicado !== undefined && props.publicado === false &&
    css`
        text-decoration: line-through;
      `}
`;

export const ItemInfoRow = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 32px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 20px;
`;

export const CommonText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
  }
`;