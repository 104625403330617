export const extractProductsData = (categories) => {
  const extractedProducts = [];
  categories.forEach((category) => {
    category.families.forEach((family) => {
      family.products.forEach((product) => {
        extractedProducts.push({
          productId: product.id,
          name: product.name,
        });
      });
    });
  });
  return extractedProducts;
};

export const InitialValues =  {
  name:'',
  active:true,
  isDefault:false,
  items:[]
};

