import styled from 'styled-components';
import { View } from '@components-teammove';

export const ViewTotais = styled(View)`
  font-weight: bold;
  color: ${(props) => props.theme.textColor};
  padding-left: 14px;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  border-bottom-right-radius: 8px;
  height: 50px; 
  display: flex;
  justify-content: center;
`;
