import styled from 'styled-components';
import Title from '../Title';

export const ContentHeaderTitle = styled(Title)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;
    color: ${(props) => props.theme.textColor};
    margin-bottom: 0;
    height: fit-content;
  }
`;