import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Column, Icon } from '@components-teammove';

import { getNewId } from '../../rules';

export default function CreateOptions({ options, setOptions }) {
  useEffect(() => {
    if (options.length < 1) {
      handleNew();
    }
  }, []);

  const [idList, setIdList] = useState(options.map((opt) => ({ id: opt.id || opt.tempId })));

  const generateNewId = () => {
    const id = getNewId(idList);
    setIdList([...idList, { id }]);
    return id;
  };

  const handleNew = () => {
    setOptions([...options, { ativo: true, titulo: '', tempId: generateNewId() }]);
  };
  
  const handleChange = (e, optionToChange) => {
    const hasId = !!optionToChange.id;
    setOptions(options.map((opt) => {
      if (hasId) {
        return opt.id === optionToChange.id ? { ...opt, titulo: e.target.value } : { ...opt };
      }else{
        return opt.tempId === optionToChange.tempId ? { ...opt, titulo: e.target.value } : { ...opt };
      }
        
    }));
  };

  const handleDelete = (optionToDelete) => {
    if (optionToDelete.id) {
      setOptions(options.map((opt) => opt.id === optionToDelete.id ? ({ ...opt, ativo: false }) : ({ ...opt })));
    }
    else{
      setOptions(options.filter((opt) => opt.tempId !== optionToDelete.tempId));
    }
  };

  return(
    <Column gap='1.25rem'>
      <Column gap='12px'>
        {options.filter((opt) => opt.ativo).map((option, index) => (
          <Row gap='4px' align='center' key={option.id || option.tempId}>
            <Form.Item
              marginless 
              onChange={(e) => handleChange(e, option)} 
              key={option.id || option.tempId} 
              rules={[{ required: true, message: 'Insira um título para essa opção' }]}
            >
              <Input type='text' placeholder={`${index + 1}° opção`} value={option.titulo}/>
            </Form.Item>
            <Button type='danger' icon={<Icon.DeleteOutlined/>} onClick={() => handleDelete(option)}/>
          </Row>
        ))}
      </Column>
      <Form.Item>
        <Button onClick={handleNew} type='link'>
          <Icon.PlusOutlined/>
        Adicionar opção
        </Button>
      </Form.Item>
    </Column>
  );
}