import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Circle, FeatherIcon, FooterCards, NumberFooterCards, TitleFooterCards } from './styles';
import { Row, Column, Skeleton } from '@components-teammove';
import { getCompanyClusterSituation } from '@ducks/gestaoCarteira';
import { verifyDate } from '../rules';

export const CardsDashBoard = ({ filters, search, activeStatus, setActiveStatus, setLimit }) => {
  const dispatch = useDispatch();

  const year = parseInt(localStorage.getItem('YEAR'));
  const month = parseInt(localStorage.getItem('MONTH'));
  const cluster = parseInt(localStorage.getItem('CLUSTER'));
  const group = parseInt(localStorage.getItem('GROUP'));
  const limit = 20;
  
  const yearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('yearSummary'));
  const loadingYearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingYearSummary'));
  const successYearSummary = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('successYearSummary'));

  const handleChangeStatus = (status) => {
    setActiveStatus(status);
    setLimit(limit);
    dispatch(getCompanyClusterSituation(group, `${verifyDate(year, month)}`, search, filters, cluster, limit, status ));
  };
  
  return (
    <>
      {loadingYearSummary ? 
        (<Column>
          <Skeleton.SearchBar/>
          <Skeleton.SearchBar/>
        </Column>
        ) : successYearSummary && (
          <Column gap='12px'>
            <FooterCards activeStatus={!activeStatus} onClick={()=> handleChangeStatus()}>
              <Row align='center' gap='12px'>
                <Circle>
                  <FeatherIcon type='home' />
                </Circle>
                <TitleFooterCards>
              Total
                </TitleFooterCards>
              </Row>
              <Column justify='center'>
                <NumberFooterCards bold>{(yearSummary[month].okQuantity + yearSummary[month].warningQuantity + yearSummary[month].criticalQuantity + yearSummary[month].noDataQuantity)}</NumberFooterCards>
              </Column>
            </FooterCards>

            <Row justify='space-between' gap='13px'> 
              { ['OK', 'ATENCAO', 'CRITICO', 'NADA'].map((status) => (
                <FooterCards key={status} activeStatus={activeStatus === status} onClick={()=> handleChangeStatus(status)}>
                  <Row align='center' gap='12px'>
                    <Circle color={status}>
                      <FeatherIcon type={status === 'OK' ? 'thumbs-up' : status === 'ATENCAO' ? 'alert-triangle' : status === 'CRITICO' ? 'thumbs-down' : 'x-circle'} />
                    </Circle>
                    <TitleFooterCards>
                      {status === 'OK' ? 'Ok' : status === 'ATENCAO' ? 'Alerta' : status === 'CRITICO' ? 'Crítico' : 'Sem histórico'}
                    </TitleFooterCards>
                  </Row>
                  <Row align='center' justify='center' gap='6px' >
                    <NumberFooterCards bold>{yearSummary[month][
                      status === 'OK'
                        ? 'okQuantity'
                        : status === 'ATENCAO'
                          ? 'warningQuantity'
                          : status === 'CRITICO'
                            ? 'criticalQuantity'
                            : 'noDataQuantity'
                    ]}</NumberFooterCards>
                    <NumberFooterCards font='14px' lowOpacity>({(yearSummary[month][
                      status === 'OK'
                        ? 'okPercent'
                        : status === 'ATENCAO'
                          ? 'warningPercent'
                          : status === 'CRITICO'
                            ? 'criticalPercent'
                            : 'noDataPercent'
                    ])?.toFixed()}%)</NumberFooterCards>
                  </Row>
                </FooterCards>
              ))
              }
            </Row>
          </Column>
        )} 
    </>
  );
};

export default CardsDashBoard;