import styled from 'styled-components';
import { Icon, Button, Text } from '@components-teammove';

export const MessageContainer = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border: 2px solid;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;

  strong {
    font-weight: 700;
  }

  p {
    margin-bottom: 0;
  }
`;

export const WarningContainer = styled(MessageContainer)`
  border-color: var(--warning-color);
  gap: 10px;
`;

export const PendentContainer = styled(MessageContainer)`
  border-color: var(--danger-color);
  line-height: 24px;
  justify-content: space-between;
`;

export const WarningIcon = styled(Icon.Feather)`
  color: var(--warning-color);
`;

export const ErrorIcon = styled(Icon.Feather)`
  color: var(--danger-color);
`;

export const PendentAsk = styled.p`
  margin-bottom: 0;
  ::after {
    content: ';';
  }
`;

export const SubcontentTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const AddActionButton = styled(Button)`
  margin: auto;
`;

export const ActionPlanIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionPlanIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const ActivityTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const ActionPlanRowText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const StarIconRed = styled.div`
  color: var(--danger-color);
`;

export const TextStategicAction = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: var(--danger-color) !important;
`;