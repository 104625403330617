import { get, put } from '../api';
import { formatParams } from './rules';
import { InfoUsuario } from '@utils';

const useApiNode = true;

export const getActivityGoalDash = (params, filters) => {
  return get(`/api/activityGoal/dash/users?${formatParams({ ...params, ...filters })}&user=${InfoUsuario.get('cdUsuario')}`, {}, useApiNode);
};

export const getActivitiesKinds = (params, filters) => {
  return get(`/api/activityGoal/dash/activitiesKinds?${formatParams({ ...params, ...filters })}&user=${InfoUsuario.get('cdUsuario')}`, {}, useApiNode);
};

export const getCheckIfAnyGoal = () => {
  return get(`/api/activityGoal/checkIfAny?user=${InfoUsuario.get('cdUsuario')}`, {}, useApiNode);
};

export const getMetaAtividades = (date) => {
  return get(`/api/activityGoal?date=${date}`, {}, useApiNode);
};

export const putMetaAtividades = (metaAtividade) => {
  return put('/api/activityGoal', metaAtividade, {}, useApiNode);
};

export const putPerfisMeta = (perfis) => {
  return put('/api/profile/goalsAllowedPermission', perfis, {}, useApiNode);
};

export const putTipoAtividadeMeta = (tipoAtvidades) => {
  return put('/api/activityGoal/activityKindsAndGroups', tipoAtvidades, {}, useApiNode);
};

export const getMetasDiarias = (userId) => {
  return get(`/api/activityGoal/dash/dailyMissions?user=${userId}`, {}, useApiNode);
};

export const getNotificationMeta = (idAtividade, idUser) => {
  return get(`/api/activity/${idAtividade}/reachedGoalsByUser/${idUser}`, {}, useApiNode);
};