import styled from 'styled-components';
import { Text, View } from '@components';
import { Icon } from '@components-teammove';

export const Description = styled(View)`
  font-size: 18px;
  font-weight: 400;
`;

export const ActionIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.darkHighlightColor};
`;

export const Container = styled(View)`
  align-items: center;
  padding: 10px 0px;

  ${Description} {
    color: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const Date = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    color: ${({ theme }) => theme.darkHighlightColor};
    padding: 6px 12px;
  }
`;