import React from 'react';
import { Badge } from '@components-teammove';

export default function BadgePercentageGoal({ percent }) {
  let color = '';

  if (percent < 80) {
    color = 'var(--danger-color)';
  } else if (percent < 100) {
    color = 'var(--warning-color)';
  } else if (percent < 110) {
    color = 'var(--success-color)';
  } else {
    color = 'var(--super-success-color)';
  }

  return <Badge color={color} />;
}