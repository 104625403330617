import { post } from '../../api';
import { InfoUsuario } from '@utils';

export const postLido = (id, lido) => {
  return post(`/api/wiki/${id}/lido/${lido}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const postFavorito = (id, favorito) => {
  return post(`/api/wiki/${id}/favorito/${favorito}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const postCurtido = (id, curtido) => {
  return post(`/api/wiki/${id}/curtido/${curtido}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const postAceito = (id, aceito) => {
  return post(`/api/wiki/${id}/aceito/${aceito}?idUsuario=${InfoUsuario.get('cdUsuario')}`);
};

export const postNewFeedback = ({ idCategoria, nota, texto }) => {
  return post('/api/wiki/feedback/salvar', {
    idCategoria,
    nota,
    texto,
    idUsuario: InfoUsuario.get('cdUsuario'),
  });
};

export const postNewAudiencia = ({ idCategoria, horaInicio, horaFim, tp }) => {
  if (InfoUsuario.get('senhaMaster')) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  return post('/api/wiki/audiencia/salvar', {
    idCategoria,
    horaInicio,
    horaFim,
    tp: tp ? tp : 'A',
    idUsuario: InfoUsuario.get('cdUsuario'),
  });
};
