import React from 'react';
import { CardStyled, Label, Total } from './styles';

export default function CardResumo({ cor, title, value }) {
  return (
    <CardStyled bgColor={cor}>
      <Label>{title}</Label>
      <Total>{value}</Total>
    </CardStyled>
  );
}
