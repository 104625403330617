import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Text, Row, Column, DateControllerHeader, SearchBar, DividedLine, Avatar, Tooltip, Skeleton } from '@components-teammove';
import { BodySearch, ButtonStyle, CellTable, ContentPanel, FeatherIcon, SelectStyle, StyleRow, StyleTable, VerticalDivider, EmptyStyle } from './styles';

import { moment, getDownloadUrlAvatar } from '@utils';
import { getAgendaManagementUsers } from '@ducks/atividadesGestaoAgenda';
import { OrderPeriod, daysOfWeek, handleChangeOrder } from './rules';

export const WeekTable = ({ currentFilters, currentParams, onChange }) => {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const OPEN_USER_IDS_KEY = 'GESTAO_AGENDA/openUserIds';

  const gestaoAgendaManagementUsers = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('gestaoAgendaManagementUsers'));
  const loadingGestaoAgendaManagementUsers = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('loadingGestaoAgendaManagementUsers'));
  const successGestaoAgendaManagementUsers = useSelector(({ atividadesGestaoAgenda }) => atividadesGestaoAgenda.get('successGestaoAgendaManagementUsers'));

  const currentDay = moment().format('DD');

  const sessionStorageCardActive = JSON.parse(sessionStorage.getItem(OPEN_USER_IDS_KEY));
  const [ openUserIds, setOpenUserIds ] = useState(sessionStorageCardActive || []);
  const [ periodMarkers, setPeriodMarkers] = useState([]);
  const [ dataUsers, setDataUsers ] = useState({});
  const typePeriod = useMemo(() => currentParams.typePeriod, [currentParams.typePeriod]);
  
  useEffect(() => {
    if(currentParams.initialDate && currentParams.typePeriod) {
      dispatch(getAgendaManagementUsers({ ...currentFilters, ...currentParams }));
      setDataUsers({});
    }
  }, [currentParams, currentFilters]);

  useEffect(() => { 
    if(dataUsers) {
      if(currentParams.initialDate && currentParams.typePeriod === 'SEMANAL') {
        setPeriodMarkers(daysOfWeek(currentParams.initialDate));
      }else if(currentParams.initialDate && currentParams.typePeriod === 'MENSAL' && Object.keys(dataUsers).length !== 0) {
       
        const periodMarkers = dataUsers.totalsByPeriod.map(({ momentDescription }) => ({ dia: momentDescription }));
        setPeriodMarkers(periodMarkers);
      }
    }
  }, [dataUsers]);

  useEffect(() => {
    if(successGestaoAgendaManagementUsers) {
      setDataUsers(gestaoAgendaManagementUsers);
      handleChangeOrder(1, gestaoAgendaManagementUsers, setDataUsers);
    }
    
  }, [successGestaoAgendaManagementUsers]);

  const groupIsOpen = (id) => {
    return openUserIds.find((item)=> item === id);
  };

  const handleChangeActive = (id) => {
    if(id === 0) {
      setOpenUserIds([]);
      sessionStorage.setItem(OPEN_USER_IDS_KEY, JSON.stringify([]));
    } else{
      const isCascaderOpen = groupIsOpen(id);
    
      if(isCascaderOpen) {
        const newOpenUserIds = openUserIds.filter((item)=> item !== id);
        setOpenUserIds(newOpenUserIds);   
        sessionStorage.setItem(OPEN_USER_IDS_KEY, JSON.stringify(newOpenUserIds));
      } else {
        setOpenUserIds([...openUserIds, id ]);
        sessionStorage.setItem(OPEN_USER_IDS_KEY, JSON.stringify([...openUserIds, id ]));
      }

    }
  };

  const handleSearch = (event) => {
    const search = event.trim().toLowerCase();
  
    let newList = gestaoAgendaManagementUsers.users.slice();
  
    if (search !== '') {
      newList = newList.filter((user) => {
        if (user.name.toLowerCase().includes(search)) {
          return true;
        } else {
          const matchingChildren = user.children.filter((child) =>
            child.name.toLowerCase().includes(search)
          );
          if (matchingChildren.length > 0) {
            if(!groupIsOpen(user.id)) {
              handleChangeActive(user.id);
            }
            return true;
          }
        }
        return false;
      });
    }else {
      handleChangeActive(0);
    }
  
    setDataUsers({ ...gestaoAgendaManagementUsers, users: newList });
  };

  return(
    <Column gap='12px'>
      <Row align='center' justify='space-between'>
        <Text size='20px' weight='700'>Aproveitamento da agenda {typePeriod?.toLowerCase()}</Text>
        <Row gap='13px' align='center'>
          <FeatherIcon type='list'/>
          <SelectStyle
            defaultValue={1}
            options={[
              { label: 'Maior % de aproveitamento', value: 1 },
              { label: 'Menor % de aproveitamento', value: 2 },
              { label: 'Ordem alfabética A-Z', value: 3 },
              { label: 'Ordem alfabética Z-A', value: 4 }
            ]}
            fitContent
            onChange={(value)=>handleChangeOrder(value, gestaoAgendaManagementUsers, setDataUsers)}
          />
        </Row>
      </Row>
      <Column>
        <ContentPanel>
          <DateControllerHeader
            defaultPeriod={'MENSAL'}
            defaultOptions={[
              { label: 'Semanal', value: 'SEMANAL' },
              { label: 'Mensal', value: 'MENSAL' },
            ]}
            colorArrowButton='tertiary'
            onChange={onChange}
            typePeriod={currentParams.typePeriod}
            initialDate={currentParams.initialDate}
            formattedTypePeriod={currentParams.formattedRangeDate}
          />
        </ContentPanel>
      
        <BodySearch>
          <SearchBar
            marginLess
            placeholder="Buscar usuário"
            onSearch={handleSearch}
          />
        </BodySearch>
            
        <StyleTable>
          {loadingGestaoAgendaManagementUsers || Object.keys(dataUsers).length === 0 ? <Skeleton.Map/> 
            : successGestaoAgendaManagementUsers ? (
              <>
                <StyleRow justify='space-between'>
                  <CellTable width='250%' cellType='group'/>
                  <CellTable justify='center' align='center' color={`${theme.darkPrimaryColor}`}>
                    <Text size='16px' weight='700'>% da semana</Text>
                  </CellTable>
            
                  {periodMarkers && periodMarkers?.length > 0 && (
                    periodMarkers.map((item)=>(
                      <CellTable justify='center' align='center' key={item.momentDescription}>
                        <Text size='16px' weight='400' color={`${item.dia === currentDay ? 'var(--super-success-color)' : ''}`}>
                          {item.dia}
                        </Text>
                        {item.diaSemana && (
                          <Text size='16px' weight='700' color={`${item.dia === currentDay ? 'var(--super-success-color)' : ''}`}>
                            {item.diaSemana}
                          </Text>
                        )}
                  
                      </CellTable>
                    ))
                  )}
          
                </StyleRow>

                <StyleRow justify='space-between'>
                  <CellTable width='250%' cellType='total' color={`${theme.darkPrimaryColor}`}>
                    <Text size='18px' weight='400'>Total</Text>
                  </CellTable>
            
                  <CellTable color={`${theme.darkPrimaryColor}`} gap='8px'>
                    <Text size='18px' weight='700'>{dataUsers.totalFillPercent.toFixed(2)}%</Text>
                    <DividedLine
                      subtitleDisabled
                      divisions={[
                        { 
                          label: 'Alerta', 
                          percentage: dataUsers.totalFillPercent,
                          status: `${
                      (dataUsers.totalFillPercent) < 33 ? 'danger'
                        : (dataUsers.totalFillPercent) < 66 ? 'warning' 
                          : 'success'
                    }` 
                        },   
                        { 
                          label: 'Sem Histórico', 
                          percentage:100 - dataUsers.totalFillPercent,
                          useBgColor: true 
                        },
                      ]}
                    /> 
                  </CellTable>
                  {OrderPeriod(dataUsers.totalsByPeriod, typePeriod).map((item)=>(
                    <CellTable gap='8px' key={item.momentDescription}>
                      <Text size='18px'>{item.fillPercent.toFixed(2)}%</Text>
                      <DividedLine
                        subtitleDisabled
                        divisions={[
                          { 
                            label: 'Alerta', 
                            percentage: item.fillPercent, 
                            status: `${
                        (item.fillPercent) < 33 ? 'danger'
                          : (item.fillPercent) < 66 ? 'warning' 
                            : 'success'
                      }` 
                          },   
                          { 
                            label: 'Sem Histórico', 
                            percentage: 100 - item.fillPercent, 
                            useBgColor: true 
                          },
                        ]}
                      /> 
                    </CellTable>
                  ))}
            
                </StyleRow>
        
                {dataUsers.users.map((user)=> (
                  <>
                    <StyleRow>
                      <CellTable onClick={() => history.push(`/atividades/gestao-agenda/${user.id}`, { userName: user.name, userDesc: user.profileDescription, userPicture: user.picture, workingJourneyId: user.workingJourneyId })} hover width='250%' cellType='group' key={user.id}>
                        <Row align='center' gap='12px'>
                          <Avatar size='44px' src={getDownloadUrlAvatar(user.picture)}/>
                          <VerticalDivider color={user.profileColor}/>
                          <Column >
                            <Tooltip title={user?.name}>
                              <Text 
                                size='18px' 
                                weight='inherit'
                                textOverflow='ellipsis'
                                whiteSpace='nowrap' 
                                overflow='hidden' 
                                maxWidth={`${user.children?.length > 0 ? '150px' : '175px' }`}
                              >
                                {user.name}
                              </Text>
                            </Tooltip>
                            <Tooltip title={user.profileDescription}>
                              <Text 
                                size='14px' 
                                weight='400' 
                                lowOpacity
                                textOverflow='ellipsis'
                                whiteSpace='nowrap' 
                                overflow='hidden' 
                                maxWidth={`${user.children?.length > 0 ? '150px' : '175px' }`} 
                              >
                                {user.profileDescription}
                              </Text>
                            </Tooltip>
                          </Column>
                          {user.children?.length > 0 && (
                            <ButtonStyle id={user.id} onClick={(e)=> {e.stopPropagation(); handleChangeActive(user.id);}} active={openUserIds?.length > 0 && openUserIds?.find((item)=> item === user.id)} type='bg' size='smaller'>{user.children.length}</ButtonStyle>
                          )}
                        </Row>
                      </CellTable>
                      <CellTable color={`${theme.darkPrimaryColor}`} gap='8px'>
                        <Text size='18px' weight='700'>{user.totalFillPercent.toFixed(2)}%</Text>
                        <DividedLine
                          subtitleDisabled
                          divisions={[
                            { 
                              label: 'Alerta', 
                              percentage: user.totalFillPercent,
                              status: `${
                            (user.totalFillPercent) < 33 ? 'danger'
                              : (user.totalFillPercent) < 66 ? 'warning' 
                                : 'success'
                            }` 
                            },   
                            { 
                              label: 'Sem Histórico', 
                              percentage:100 - user.totalFillPercent,
                              useBgColor: true 
                            },
                          ]}
                        /> 
                      </CellTable>
                      {OrderPeriod(user.periods, typePeriod).map((item)=>(
                        <CellTable gap='8px' key={item.momentDescription}>
                          <Text size='18px'>{item.fillPercent.toFixed(2)}%</Text>
                          <DividedLine
                            subtitleDisabled
                            divisions={[
                              { 
                                label: 'Alerta', 
                                percentage: item.fillPercent, 
                                status: `${
                              (item.fillPercent) < 33 ? 'danger'
                                : (item.fillPercent) < 66 ? 'warning' 
                                  : 'success'
                              }` 
                              },   
                              { 
                                label: 'Sem Histórico', 
                                percentage: 100 - item.fillPercent, 
                                useBgColor: true 
                              },
                            ]}
                          /> 
                        </CellTable>           
                      ))}
                    </StyleRow>
              
                    {openUserIds.length > 0 && openUserIds.find((item)=> item === user.id) && (
                      user.children.map((item)=>(
                        <StyleRow id={user.id} key={item.id}>
                          <CellTable onClick={() => history.push(`/atividades/gestao-agenda/${item.id}`, { userName: item.name, userDesc: item.profileDescription, userPicture: item.picture })}  hover width='250%' cellType='itemGroup'>
                            <Row align='center' gap='12px' margin='0 0 0 44px'>
                              <Avatar size='44px' src={getDownloadUrlAvatar(item.picture)}/>
                              <VerticalDivider color={item.profileColor}/>
                              <Column>
                                <Tooltip title={item?.name}>
                                  <Text 
                                    textOverflow='ellipsis'
                                    whiteSpace='nowrap' 
                                    overflow='hidden' 
                                    maxWidth='175px' 
                                    size='18px' 
                                    weight='inherit'
                                  >
                                    {item.name}
                                  </Text>
                                </Tooltip>
                                <Text size='14px' weight='400' lowOpacity>{item.profileDescription}</Text>
                              </Column>
                            </Row>
                          </CellTable>

                          <CellTable color={`${theme.darkPrimaryColor}`} gap='8px'>
                            <Text size='18px' weight='700'>{item.totalFillPercent.toFixed(2)}%</Text>
                            <DividedLine
                              subtitleDisabled
                              divisions={[
                                { 
                                  label: 'Alerta', 
                                  percentage: item.totalFillPercent,
                                  status: `${
                                (item.totalFillPercent) < 33 ? 'danger'
                                  : (item.totalFillPercent) < 66 ? 'warning' 
                                    : 'success'
                                }` 
                                },   
                                { 
                                  label: 'Sem Histórico', 
                                  percentage:100 - item.totalFillPercent,
                                  useBgColor: true 
                                },
                              ]}
                            /> 
                          </CellTable>

                          {OrderPeriod(item.periods, typePeriod).map((item)=>(
                            <CellTable gap='8px' key={item.momentDescription}>
                              <Text size='18px'>{item.fillPercent.toFixed(2)}%</Text>
                              <DividedLine
                                subtitleDisabled
                                divisions={[
                                  { 
                                    label: 'Alerta', 
                                    percentage: item.fillPercent, 
                                    status: `${
                                  (item.fillPercent) < 33 ? 'danger'
                                    : (item.fillPercent) < 66 ? 'warning' 
                                      : 'success'
                                  }` 
                                  },   
                                  { 
                                    label: 'Sem Histórico', 
                                    percentage: 100 - item.fillPercent, 
                                    useBgColor: true 
                                  },
                                ]}
                              /> 
                            </CellTable>           
                          ))}
                        </StyleRow>
                      ))
                    )}
                  </>
                ))}
              </>
            ) : (
              <EmptyStyle description='Sem Dados' />
            )}

        </StyleTable>
      </Column>
    </Column>
    
  );
};