import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon, Row, Button, Skeleton, Column,Tooltip, View, Dropdown, Menu, Table } from '@components-teammove';

import { Typography, VerticalLine, FeatherIcon, Info, TagScheduled, TagStatus, MenuButton, RowStyle } from './styles';

import { TelasNomenclaturas, formatDate } from '@utils';
import { getCompanyClusterSituation } from '@ducks/gestaoCarteira';

import Cadastro from '../../../../Atividades/Agenda/Cadastro';
import { verifyDate } from '../rules';
import { TooltipTitle } from './TooltipTitle';

export const TableDashBoard = ({ filters, search, status, limit, setLimit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const year = parseInt(localStorage.getItem('YEAR'));
  const month = parseInt(localStorage.getItem('MONTH'));
  const cluster = parseInt(localStorage.getItem('CLUSTER'));
  const group = parseInt(localStorage.getItem('GROUP'));

  const companyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('companyClusterSituation'));
  const loadingCompanyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingCompanyClusterSituation'));

  const activity = useSelector(({ atividades }) => atividades.get('atividade'));
  const registerData = useSelector(({ atividades }) => atividades.get('dadosCadastro'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const [ visibleActivityRegister, setVisibleActivityRegister ] = useState(false);
  const [ idCompany, setIdCompany ] = useState(null);
  const initialLimit = 20;
  
  useEffect(() => {
    if(successSave) {
      const currentActivityType = registerData.activityTypes.find(({ id }) => id === activity.idTypeActivity);
      const isFastActivity  = currentActivityType && currentActivityType.fastFilling;

      if(!isFastActivity || !activity?.idChecklist) {
        if(limit !== initialLimit) setLimit(initialLimit);
        dispatch(getCompanyClusterSituation(group, `${verifyDate(year, month)}`, search, filters, cluster, initialLimit, status)); 
      } 
    }
  },[successSave]);

  useEffect(() => {
    if(limit !== initialLimit) setLimit(initialLimit);
    dispatch(getCompanyClusterSituation(group, `${verifyDate(year, month)}`, search, filters, cluster, initialLimit, status)); 
    
  },[search, filters]);

  useEffect(() => {
    if(limit !== 20) {
      dispatch(getCompanyClusterSituation(group, `${verifyDate(year, month)}`, search, filters, cluster, limit, status));  
    } 
  },[limit]);

  const handleSetActivityRegister = (idCompany) => {
    setIdCompany(idCompany);
    setVisibleActivityRegister(true);
  };

  const handleChangeLimit = () => {
    setLimit(limit + 10);
  };

  const priorityMap = {
    'OK': 1,
    'ATENCAO': 2,
    'CRITICO': 3,
    'NADA': 4
  };

  const columns = [
    {
      title: 'Unidade',
      dataIndex: 'name',
      width: '20%',
      sorter: true,
      render: (text, company) => (
        <Column onClick={() => history.push(`/unidades/${company?.id}`)}>
          <Tooltip title={company?.name}>
            <Typography maxWidth='270px' type='medium'>{company?.name}</Typography>
          </Tooltip>
          <Typography type='large'>{company?.shortName}</Typography>
          <Typography type='small'>{company?.city} / {company?.state}</Typography>
        </Column>
      )
    },
    {
      title: 'Última atividade',
      dataIndex: 'lastActivity',
      sorter: (a, b) => {
        const dateA = a.lastActivity ? new Date(a.lastActivity.date) : null;
        const dateB = b.lastActivity ? new Date(b.lastActivity.date) : null;
        return dateA - dateB || (a.lastActivity ? -1 : 1);
      },    
      width: '200px',
      render: (text, company) => (
        <Row>
          {company?.lastActivity ? (
            <Row gap='24px' align='center' onClick={() => history.push(`/atividades/agenda/${company?.lastActivity?.id}`)}>
              <VerticalLine/>
              <Column>
                <Typography type='medium'>{company?.lastActivity?.assigneeName}</Typography>
                <Tooltip title={company?.lastActivity?.name}>
                  <Typography maxWidth='170px' type='large'>{company?.lastActivity?.name}</Typography>
                </Tooltip>
                <Typography type='small'>{formatDate(company?.lastActivity?.date)}</Typography>
              </Column>
            </Row>
          ) : (
            <Row align='center' justify='center'>
              <Info size='14px' weight= '400'>Sem histórico de última atividade</Info>
            </Row>
          )}
        </Row>
      )
    },
    {
      title: 'Próxima atividade',
      dataIndex: 'nextActivity',
      sorter: (a, b) => {
        const dateA = a.nextActivity ? new Date(a.nextActivity.date) : null;
        const dateB = b.nextActivity ? new Date(b.nextActivity.date) : null;
        return dateA - dateB || (a.nextActivity ? -1 : 1);
      }, 
      width: '20%',
      render: (text, company) => (
        <Row align='center'>
          {company?.nextActivity ? (
            <Row gap='24px' align='center' onClick={() => history.push(`/atividades/agenda/${company?.nextActivity?.id}`)}>
              <VerticalLine/>
              <Row gap='8px'>
                <Column gap='2px'>
                  <Tooltip title={company?.nextActivity.name}>
                    <Typography maxWidth='200px' type='large'>{company?.nextActivity.name}</Typography>
                  </Tooltip>
                  <Row align='center' gap='6px'>
                    
                    <Typography type='medium'>{formatDate(company?.nextActivity.date)}</Typography>
                    {company?.nextActivity.statusDescription && (
                      <TagScheduled color={company?.nextActivity.statusColor} align='center' justify='center'><Info weight= '400' size='12px'>{company?.nextActivity.statusDescription}</Info></TagScheduled> 
                    )}
                  </Row>
                </Column>
                <TagScheduled align='center' justify='center'><Info weight= '400' size='12px'>Agendada</Info></TagScheduled> 
              </Row>
            </Row>
          ) : (
            <RowStyle align='center' justify='center'>
              <Button size='small' context='header' key="1" type='secondary' onClick={() => handleSetActivityRegister(company?.id)}>Agendar Atividade</Button>
            </RowStyle>
            
          )}

        </Row>
        
      )
    },
    {
      title: 'Cluster',
      dataIndex: 'nameCluster',
      sorter: true,
      width: '190px', 
      render: (text, company) => (
        <Column>
          <Tooltip title={company.nameCluster}>
            <Typography maxWidth='170px'>{company.nameCluster}</Typography>
          </Tooltip>
          <Typography type='medium'>Frequência: {company?.frequencyQtdDays} {company?.frequencyQtdDays > 1 ? 'dias.' : 'dia.'}</Typography>
        </Column>
      )
    },
    {
      title: 'Status do cluster',
      dataIndex: 'status',
      sorter: (a, b) => {
        
        const priorityA = priorityMap[a.status.status];
        const priorityB = priorityMap[b.status.status];
    
        if (priorityA < priorityB) return -1;
        if (priorityA > priorityB) return 1;
       
        if ((a.status.daysSinceLastActivity) < (b.status.daysSinceLastActivity)) return -1;
        if ((a.status.daysSinceLastActivity) > (b.status.daysSinceLastActivity)) return 1;
    
        return 0;
      },
      width: '180px', 
      render: (text, company) => (
        <Row gap='15px' align='center' justify='flex-end'>
          {company?.lastActivity ? (
            <Tooltip title={<TooltipTitle data={company}/>}>
              <TagStatus colorStatus={company?.status.status} align='center' justify='center'>
                <FeatherIcon size='24' type={(company?.status.status === 'OK' ? 'thumbs-up' : company?.status.status === 'ATENCAO' ? 'alert-triangle' : company?.status.status === 'CRITICO' ? 'thumbs-down' : 'thumbs-up')}/>
                <Info size='12px' weight='600'>{company?.status.daysSinceLastActivity >= 0 && company?.status.daysSinceLastActivity} { company?.status.daysSinceLastActivity < 1 ? 'dia' : 'dias'} </Info>
              </TagStatus>
            </Tooltip>
          ) : ('')} 
          <View onClick={(e) => (e.stopPropagation())}>
            <Dropdown
              key="more"
              overlay={(
                <Menu>
                  <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); handleSetActivityRegister(company?.id);}}>
                    <MenuButton type="icon">Agendar Atividade</MenuButton>                    
                  </Menu.Item>

                  <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); history.push(`/unidades/${company?.id}`);}} >
                    <MenuButton type="icon">Visualizar {companyNomenclature.plural}</MenuButton>                    
                  </Menu.Item>

                  {company?.lastActivity && (
                    <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); history.push(`/atividades/agenda/${company?.lastActivity?.id}`);}} >
                      <MenuButton type="icon">Visualizar Ult. Atividade</MenuButton>                    
                    </Menu.Item>
                  )}
                  
                  {company.nextActivity && (
                    <Menu.Item onClick={(e) => { e.domEvent.stopPropagation(); history.push(`/atividades/agenda/${company?.nextActivity?.id}`);}} >
                      <MenuButton type="icon">Visualizar Próx. Atividade</MenuButton>                    
                    </Menu.Item>
                  )}
                </Menu> 
              )}
            >
              <Button type="icon">
                <Icon.MDI type="dots-vertical" />
              </Button>
            </Dropdown>
          </View>
        </Row>
      )
    },
  ];
  
  return (
    <>
      {(loadingCompanyClusterSituation && limit === 20) ? <Skeleton.Map /> : (
        <Column>
          <Table 
            columns={columns} 
            rowKey='id' 
            status='ativo' 
            dataSource={companyClusterSituation?.companies?.sort((a,b)=> (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))} 
            pagination={false} 
            hidePagination
          />
          {visibleActivityRegister && (
            <Cadastro visible={visibleActivityRegister} onCancel={() => setVisibleActivityRegister(false)} idCompany={idCompany}/>
          )}
        </Column>
      )}
      {(limit < companyClusterSituation?.total) && (
        <Row justify='center'>
          <Button loading={loadingCompanyClusterSituation} type='secondary' size='small' onClick={() => handleChangeLimit()}>{'Carregar mais'}</Button>
        </Row>
      )}
    </>
  );
};

export default TableDashBoard;