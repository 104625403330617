import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Progress, Column } from '@components-teammove';
import { formatCurrency } from '@utils';
import { CardTitle, ChartInnerContent, ContentWrapper, CurrentValue, ScrollableRow, StyledButton, StyledCard } from './styles';
import { useSelector } from 'react-redux';

const Indicadores = ({ indicadores }) => {
  const { idUnidade } = useParams();
  const history = useHistory();
  const [isProgressIndicator, setIsProgressIndicator] = useState({});

  const theme = useSelector(({ app }) => app.get('theme'));

  useEffect(() => {
    indicadores.forEach((indicador) => {
      if (indicador.retornoVelocimetro) {
        setIsProgressIndicator({ ...isProgressIndicator, [indicador.ordemGeral]: true });
      }
    });
  }, []);
  
  return(
    <ScrollableRow gap='50px' justify={indicadores?.length < 4 && 'center'}>
      {indicadores.map((indicador) => {
        let content = <></>;

        if (indicador.retornoTexto) {
          content = <div dangerouslySetInnerHTML={{ __html: indicador.resultado.registrosTabela[0].valores[0].valor?.replaceAll('color:black', 'color:' + theme['@global-text-color']).replaceAll('color: red', 'color: var(--danger-color)') }}/>;
        }
        else if (indicador.retornoVelocimetro) {
          const { value, valuePercentual } = indicador.retornoVelocimetro;

          content = (
            <Column align='center'>
              <Progress.HalfCircle 
                percent={valuePercentual.toFixed(2)}  
                strokeColor='var(--success-color)'
                useTextColor
                format={() => (
                  <ChartInnerContent>
                    <span style={{ fontSize: 16 }}>
                      {valuePercentual.toFixed(2)}%
                    </span>
                  
                  </ChartInnerContent>
                )}
                width='300px'
              />
              <CurrentValue level={4}>{formatCurrency(value)}</CurrentValue>
            </Column>
          );
        }

        return (
          <StyledCard 
            isProgress={isProgressIndicator[indicador.ordemGeral]}
            key={indicador.ordemGeral} 
          >
            <CardTitle>{indicador.visualizacao.nomeOriginal}</CardTitle>
            {isProgressIndicator[indicador.ordemGeral] ? content : <ContentWrapper>{content}</ContentWrapper>}
            <StyledButton type='secondary' fillWidth onClick={() => history.push(`/unidades/${idUnidade}/detalhes?idVisualizacao=${indicador.visualizacao.id}`)}>Ver Detalhes</StyledButton>
          </StyledCard>
        );

      })}
    </ScrollableRow>
  );
};

export default Indicadores;