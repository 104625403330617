import styled from 'styled-components';
import { Icon , Column } from '@components-teammove';

export const FeatherIcon = styled(Icon.Feather)`
  opacity: 0.5;
`;

export const Card = styled(Column)`
  width: 100%;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
  padding: 20px;
`;

export const CardSkeleton = styled.div`
  width: 100%;
  border-radius: 12px;
  height: 100px;
`;