import styled from 'styled-components';
import { Text, Form } from '@components-teammove';

export const JourneyColumnsTitle = styled.div`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: ${({ textAlignCenter }) => textAlignCenter ? 'center' : 'left'};
`;

export const FormItem = styled(Form.Item)`
  margin: 20px 0 !important;
  ${({ name }) => name === 'clusterGroup' && 'margin-top: 20px !important;'};
  height: ${({ selectOpen, dropdownHeight }) => (selectOpen && dropdownHeight > 0) ? `${dropdownHeight}px` : 'auto'};
`;

export const DescriptionDeleteModal = styled(Text)`
   &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    line-height: 27.24px;
    margin-bottom: 20px !important;
  }
`;