import styled from 'styled-components';

export const ExtraInfoContainer = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const ExtraInfoLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span.required-mark {
    color: var(--danger-color);
    background-color: transparent;
  }
`;

export const ExtraInfoSubLabel = styled(ExtraInfoLabel)`
  font-weight: 400;
  opacity: .5;
`;