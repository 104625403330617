import React from 'react';
import { View, User } from '@components';
import styled from 'styled-components';
import { formatDateTime, formatDecimal, prettyTimeDifference } from '@utils';
import { Desistencia, EmAndamento } from '@containers/Wikis/Conteudos/Visualizacao/Quiz/rules';

const TaxaAcerto = styled(View)`
  font-weight: bold;
`;

const Resultado = styled(View)`
  width: 40%;
`;

const VerResultado = styled(View)`
  width: 45%;
`;

const Tentativa = styled(View)`
  margin-right: 30px;
`;

const Group = styled(View)`
  justify-content: center;
`;

const StyledView = styled(View)`
  width: 100%;
`;

export default function CollapseHeader({ data, handlers }) {
  const { usuario } = data;
  const { handleVisualizarResultado } = handlers;
  const { id, dtInicio, desistencia, dtFim, nota, total, tentativa } = usuario.tentativas[0];

  return (
    <View horizontal>
      <User cdUsuario={usuario.cdUsuario} nmUsuario={usuario.nmUsuario} corPerfil={usuario.corPerfil} dsPerfil={usuario.dsPerfil} />

      <Resultado horizontal justifyContent='space-between'>
        {dtFim ? (
          <>
            {desistencia ? (
              <StyledView horizontal>
                <Group>
                  <Tentativa>Tentativa: {tentativa}</Tentativa>
                </Group>
                <Group>
                  <View>Início: {formatDateTime(dtInicio)}</View>
                  <Desistencia>Não finalizado</Desistencia>
                </Group>
              </StyledView>
            ) : (
              <StyledView horizontal justifyContent='space-between'>
                <Group>
                  <TaxaAcerto>Taxa de Acerto: {formatDecimal((nota / total) * 100, 2)}%</TaxaAcerto>
                  <Tentativa>Tentativa: {tentativa}</Tentativa>
                </Group>
                <Group>
                  <View>Início: {formatDateTime(dtInicio)}</View>
                  <View>Fim: {formatDateTime(dtFim)}</View>
                </Group>
                <Group>
                  <View>Duração: {prettyTimeDifference(dtInicio, dtFim).extenseDifference}</View>
                </Group>
              </StyledView>
            )}
          </>
        ) : (
          <StyledView horizontal>
            <Group>
              <Tentativa>Tentativa: {tentativa}</Tentativa>
            </Group>
            <Group>
              <View>Início: {formatDateTime(dtInicio)}</View>
              <EmAndamento>Em andamento</EmAndamento>
            </Group>
          </StyledView>
        )}
      </Resultado>
      {dtFim && !desistencia && (
        <VerResultado justifyContent='center'>
          <View
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleVisualizarResultado(id, usuario);
            }}
            className={'action-element' /* eslint-disable-line */}
            horizontal
            justifyContent='flex-end'
            fontSize='12px'
          >
            Visualizar Resultado
          </View>
        </VerResultado>
      )}
    </View>
  );
}
