import React from 'react';
import { Go, SpaceBetweenColumn, StatusCard as Container, Total } from './styles';

export default function DashboardCard({ statusItem, color, description, total, onClick, ...props }) {
  return(
    <Container color={color} onClick={() => onClick(statusItem)} {...props}>
      <SpaceBetweenColumn>
        {description}
        <Total>{total}</Total>
      </SpaceBetweenColumn>
      <Go type='arrow-right-short'/>
    </Container>
  );
}