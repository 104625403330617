import React, { useState } from 'react';
import { View, Spin  } from '@components';
import { Icon , Button } from '@components-teammove';

import VisualizacaoPersonalizacao from '../Personalizacao';
import VisualizacaoLista from '../Lista';
import { 
  ButtonPersonalizar,
  WikiImage,
  TextoLista,
  Container,
  ImageResume
} from './styles';
import { useSelector } from 'react-redux';

export default function VisualizacaoImagem({ conteudo, blockCustomization, loadingBlockCustomization }) {
  const [personaliza, setPersonaliza] = useState(false);
  const [personalizaLista, setPersonalizaLista] = useState(false);
  const [loading, setLoading] = useState(true);
  const parametros = conteudo.get('parametrosImagem') ? JSON.parse(conteudo.get('parametrosImagem')) : null;
  const resumo = conteudo.get('resumo');
  const loadingSaveValuesListProduct = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('loadingSaveValuesListProduct'));

  const handleOnLoad = () => setLoading(false);
  
  return (
    <View>
      {conteudo.get('parametrosImagem') && (
        <View horizontal>
          <Container>
            <ButtonPersonalizar loading={loadingBlockCustomization || loadingSaveValuesListProduct} type={blockCustomization ? 'disabled' : 'success'} disabled={blockCustomization} icon={<Icon.MDI type='brush' />} onClick={() => setPersonaliza(true)}>
             Personalizar
            </ButtonPersonalizar>
            {blockCustomization && <TextoLista>* Preencha os valores na lista de produtos.</TextoLista>}
          </Container>
          {parametros.textos?.some((item) => item.productItemList > 0) &&
            <Button type='secondary' size='small' fillWidth icon={<Icon.MDI type='file-document-edit-outline' />} onClick={() => setPersonalizaLista(true)}>
              Lista de Produtos
            </Button>
          }
        </View>
      )}
      {personaliza && <VisualizacaoPersonalizacao conteudo={conteudo} onClose={() => setPersonaliza(false)} />}
      {personalizaLista && <VisualizacaoLista conteudo={conteudo} onClose={() => setPersonalizaLista(false)} />}

      {loading && (
        <View alignItems='center'>
          <Spin />
        </View>
      )}

      <Container gap='8px'>
        <ImageResume>{resumo}</ImageResume>
        <WikiImage src={conteudo.get('conteudo')} alt='imagem' onLoad={handleOnLoad} onError={handleOnLoad} />
        {conteudo.get('parametrosImagem') && (
          <View>
            <View horizontal>
              <ButtonPersonalizar loading={loadingBlockCustomization} fillWidth type='primary' size='small' disabled={blockCustomization} icon={<Icon.MDI type='brush' />} onClick={() => setPersonaliza(true)}>
                Personalizar
              </ButtonPersonalizar>
              {parametros.textos?.some((item) => item.productItemList > 0) &&
                <Button type='secondary' size='small' fillWidth icon={<Icon.MDI type='file-document-edit-outline' />} onClick={() => setPersonalizaLista(true)}>
                  Lista de Produtos
                </Button>
              }
            </View>
            {blockCustomization && <TextoLista>* Preencha os valores na lista de produtos.</TextoLista>}
          </View>
        )}
      </Container>
    </View>
  );
}
