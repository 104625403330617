import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import ThemeContext from './Theme';
import getTheme from './Theme/styles';
import Header from './Header';
import Footer from './Footer';
import Sider from './Sider';

// const GlobalStyles = createGlobalStyle`
//   #root {
//     ${({ theme }) => theme && `
//       background-color: ${theme.backgroundColor};
//     `}
//   }
// `;

const Container = styled(Layout)`
  height: 100%;
`;

const Content = styled(Layout)`
  background-color: ${({ theme }) => theme?.backgroundColor || '#fff'};
`;

const Body = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
`;

const Children = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const GlobalStyles = createGlobalStyle`
  /* Firefox */
  * {
    scrollbar-color: ${({ theme }) => theme.darkHighlightColor} ${({ theme }) => theme.backgroundColor};
  }
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  main, body {
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundColor};
    }
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.darkHighlightColor};
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.backgroundColor};
  }
`;

const App = ({ children, siderProps, bgColor, hideFooter, hideHeader, hideSider }) => {
  const theme = useSelector(({ app }) => app.get('theme'));
  const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));
  const routes = useSelector(({ app }) => app.get('routes'));

  return(
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={getTheme(theme)}>
        <GlobalStyles/>
        <Container>
          {!hideHeader && <Header {...{ infoUsuario, theme }} />}
          <Content>
            {!hideSider && <Sider {...{ siderProps, infoUsuario, routes }}/>}
            <Body id='main'>
              <Children>
                {children}
              </Children>
              {!hideFooter && <Footer bgColor={bgColor}/>}
            </Body>
          </Content>
        </Container>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
