import React, { useEffect, useState } from 'react';
import App from '@app';
import { Icon, Body, PageHeader, Breadcrumb, View, Text, Row, Column, StatusTag, Skeleton, GoogleMap } from '@components-teammove';
import { TelasNomenclaturas, moment, getDiffLocation } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { AbsoluteStatusTag, ActivityTitleWrapper, ActivityTypeIcon, ActivityTypeIconWrapper , CompanyName, ContentMap, FillView, InfoContainer, NameTypography, SecondaryTypography, Subtitle, TimeMarker } from './styles';
import { useParams , useHistory } from 'react-router-dom';
import { getAtividadeDetalhe, reset } from '@ducks/atividades';

export default function Agenda() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idActivity } = useParams();
  const theme = useSelector(({ app }) => app.get('theme'));
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const activity = useSelector(({ atividades }) => atividades.get('atividadeDetalhe'));
  const loading = useSelector(({ atividades }) => atividades.get('loadingDetalhe'));
  const success = useSelector(({ atividades }) => atividades.get('successDetalhe'));

  const [checkinDistance, setCheckinDistance] = useState();
  const [checkoutDistance, setCheckoutDistance] = useState();
 
  const [ colorCheckin, setColorCheckin ] = useState();
  const [ colorCheckout, setcolorCheckout ] = useState();

  useEffect(() => {
    dispatch(getAtividadeDetalhe(parseInt(idActivity)));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if(success && activity?.useCheckin === 'G') {
      setCheckinDistance(getDiffLocation(activity.companyLatitude, activity.companyLongitude, activity.latitudeCheckin, activity.longitudeCheckin));
      setCheckoutDistance(getDiffLocation(activity.companyLatitude, activity.companyLongitude, activity.latitudeCheckout, activity.longitudeCheckout));
    }else{
      setColorCheckin('#43A047') ;
      setcolorCheckout('#43A047');
    }
  }, [success]);

  useEffect(() => {
    if(checkinDistance || checkoutDistance) {
      setColorCheckin(checkinDistance < 2  ? '#43A047' : '#E53935') ;
      setcolorCheckout(checkoutDistance < 2  ? '#43A047' : '#E53935');
    }
  }, [checkinDistance, checkoutDistance]);
  
  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={activity?.idCompany ? (
            <Row gap='14px' align='center'>
              <ActivityTitleWrapper>{activity?.title}</ActivityTitleWrapper>
              <ActivityTypeIconWrapper color={activity?.typeColor}>
                <ActivityTypeIcon type={activity?.typeIcon}/>
              </ActivityTypeIconWrapper>
              <StatusTag color={activity.statusColor} text={activity.statusDescription} size='average' fixedWidth='105px'/>
            </Row>
          ) : 'Atividade'}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades', name: 'Atividades', current: true },
                { path: '/atividades/agenda', name: 'Agenda', current: true },
              ]}
            />
          )}
          onBack={() => history.goBack()}
        >
          {loading ? 
            <>
              <Row gap='12px'>
                <FillView>
                  <Subtitle>
                    <Icon.Feather type='home'/>
                    <Skeleton.MinorLine/>
                  </Subtitle>
                  <InfoContainer>
                    <AbsoluteStatusTag text='+ info' disabled/>
                    <Row gap='13px' align='flex-end'>
                      <CompanyName><Skeleton.MinorLine/></CompanyName>
                      <NameTypography><Skeleton.MinorLine/></NameTypography>
                    </Row>
                    <View>
                      <SecondaryTypography><Skeleton.MinorLine/></SecondaryTypography>
                      <SecondaryTypography>
                        <strong><Skeleton.MinorLine/></strong>
                      </SecondaryTypography>
                    </View>
                  </InfoContainer>
                </FillView>

                <FillView>
                  <Subtitle>
                    <Icon.Feather type='map-pin'/>
                    <Skeleton.MinorLine/>
                  </Subtitle>
                  <InfoContainer>
                    <AbsoluteStatusTag text='+ info' disabled/>
                    <Row gap='13px' align='flex-end'>
                      <CompanyName><Skeleton.MinorLine/></CompanyName>
                      <NameTypography><Skeleton.MinorLine/></NameTypography>
                    </Row>
                    <View>
                      <SecondaryTypography><Skeleton.MinorLine/></SecondaryTypography>
                      <SecondaryTypography>
                        <strong><Skeleton.MinorLine/></strong>
                      </SecondaryTypography>
                    </View>
                  </InfoContainer>
                </FillView>
              </Row>
            </> 
            : success && (
              <Column gap='20px'>
                <Row gap='20px' width='100%'>
                  <FillView>
                    <Subtitle>
                      <Icon.Feather type='home'/>
                      {companyNomenclature.nomenclatura}
                    </Subtitle>
                    <InfoContainer>
                      <AbsoluteStatusTag text='+ info' onClick={() => history.push(`/unidades/${activity?.idCompany}`)}/>
                      <Row gap='13px' align='flex-end'>
                        <CompanyName>{activity?.companyName}</CompanyName>
                        <NameTypography>{activity?.companyIdentifier}</NameTypography>
                      </Row>
                      <View>
                        <SecondaryTypography>{activity?.companyAddress} - {activity?.companyDistrict}</SecondaryTypography>
                        <SecondaryTypography>
                          <strong>{activity?.companyCity} - {activity?.companyState} - {activity?.companyCep?.includes('-') ? activity?.companyCep : (activity?.companyCep?.substring(0, 5) + '-' + activity?.companyCep?.substring(5))}</strong>  
                        </SecondaryTypography>
                      </View>
                    </InfoContainer>
                  </FillView>
                  <FillView>
                    <Subtitle>
                      <Icon.Feather type='map-pin'/>
                    Check-in
                    </Subtitle>
                    <InfoContainer>
                      <Row justify='space-between' width='100%' gap='12px'>
                        <Column width='100%' gap='8px'>
                          <Text size='12px' weight='700'>Início</Text>
                          <TimeMarker color={colorCheckin}>
                            <Text size='16px' weight='400'>{moment(activity?.checkin).format('HH:mm:ss')}</Text>
                          </TimeMarker>
                          {checkinDistance > 2 && (
                            <Text>{checkinDistance.toString().replace('.' , ',')} km da localização</Text>
                          )}
                        </Column>
                        <Column width='100%' gap='8px'>
                          <Text size='12px' weight='700'>Fim</Text>
                          <TimeMarker color={colorCheckout}>
                            <Text size='16px' weight='400'>{moment(activity?.checkout).format('HH:mm:ss')}</Text>
                          </TimeMarker>
                          {checkoutDistance > 2 && (                           
                            <Text>{checkoutDistance.toString().replace('.' , ',')} km da localização</Text>
                          )}
                        </Column>
                        <Column width='100%' gap='8px'>
                          <Text size='12px' weight='700'>Duração</Text>
                          <TimeMarker borderDefault>
                            <Text size='16px' weight='400'>{moment.utc(moment(activity?.checkout).diff(moment(activity?.checkin))).format('HH:mm:ss')}</Text>
                          </TimeMarker>
                        </Column>
                      </Row>
                    </InfoContainer>
                  </FillView>
                </Row>
                {activity?.checkin && activity?.checkout && (activity?.useCheckin === 'G' || activity?.useCheckin === 'O') && (
                  <ContentMap>
                    <GoogleMap
                      center={{ lat: parseFloat(activity?.latitudeCheckin) || 0, lng:parseFloat(activity?.longitudeCheckin) || 0 }} 
                      zoom={15} 
                      mapStyles={{ height: '570px', width: '100%', borderRadius: '12px' }} 
                      checkinContext
                      markers={
                        [
                          activity?.latitudeCheckin && activity?.latitudeCheckout ? (
                            { position: { lat: parseFloat(activity?.latitudeCheckin) || 0, lng:parseFloat(activity?.longitudeCheckin) || 0 },
                              key: 1, 
                              icon: `${checkinDistance > 2 ? 'https://teammove.app/icones/maps-clusters/marker_red.png' : 'https://teammove.app/icones/maps-clusters/marker_green.png'}`,
                              identifier: activity?.companyIdentifier,
                              name:activity?.companyName,
                              city:activity?.companyCity,
                              state:activity?.companyState,
                              checkin: activity?.checkin,
                              checkout: activity?.checkout,
                            },
                            { position: { lat: parseFloat(activity?.latitudeCheckout) || 0, lng:parseFloat(activity?.longitudeCheckout) || 0 },
                              key: 2, 
                              icon: `${checkoutDistance > 2 ? 'https://teammove.app/icones/maps-clusters/marker_red.png' : 'https://teammove.app/icones/maps-clusters/marker_green.png'}`,
                              identifier: activity?.companyIdentifier,
                              name:activity?.companyName,
                              city:activity?.companyCity,
                              state:activity?.companyState,
                              checkin: activity?.checkin,
                              checkout: activity?.checkout,
                            }) : {
                            position: { lat: parseFloat(activity?.companyLatitude) || 0, lng:parseFloat(activity?.companyLongitude) || 0 },
                            key: activity?.idCompany,
                            icon: 'https://teammove.app/icones/maps-clusters/marker_green.png',
                            identifier: activity?.companyIdentifier,
                            name:activity?.companyName,
                            city:activity?.companyCity,
                            state:activity?.companyState,
                          },
                        ]
                      }
                    />
                  </ContentMap>
                )}
                
              </Column>
            )}
        </PageHeader>
      
      </Body>
    </App>
  );
}