import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { updateResultsCampaignsByExcel as updateResultsCampaignsByExcelSdk } from '@sdk/Campanhas';
import { Notification } from '@utils';

//Action Types
export const Types = {
  IMPORT_CAMPAIGNS_RESULTS: 'CAMPANHAS/IMPORT_CAMPAIGNS_RESULTS',
  IMPORT_CAMPAIGNS_RESULTS_SUCCESS: 'CAMPANHAS/IMPORT_CAMPAIGNS_RESULTS_SUCCESS',
  IMPORT_CAMPAIGNS_RESULTS_ERROR: 'CAMPANHAS/IMPORT_CAMPAIGNS_RESULTS_ERROR',
};

//Action Creators
export const importCampaignsResults = (results) => ({ type: Types.IMPORT_CAMPAIGNS_RESULTS, results });
export const importCampaignsResultsSuccess = () => ({ type: Types.IMPORT_CAMPAIGNS_RESULTS_SUCCESS });
export const importCampaignsResultsError = (error) => ({ type: Types.IMPORT_CAMPAIGNS_RESULTS_ERROR, error });

//saga
function* fetchImportCampaignsResultsSaga(action) {
  try {
    const { results } = action;
    yield call(updateResultsCampaignsByExcelSdk, results);
    yield put(importCampaignsResultsSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(importCampaignsResultsError(err));
  }
}

export const saga = [takeLatest(Types.IMPORT_CAMPAIGNS_RESULTS, fetchImportCampaignsResultsSaga)];

// Reducer
const initialState = Map({
  loading: false,
  success: false,
  error: false
});

const handleImportCampaignsResults = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleImportCampaignsResultsSuccess = (state, action) => {
  return state.set('loading', false).set('success', true).set('error', false);
};

const handleImportCampaignsResultsError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.IMPORT_CAMPAIGNS_RESULTS:
      return handleImportCampaignsResults(state, action);
    case Types.IMPORT_CAMPAIGNS_RESULTS_SUCCESS:
      return handleImportCampaignsResultsSuccess(state, action);
    case Types.IMPORT_CAMPAIGNS_RESULTS_ERROR:
      return handleImportCampaignsResultsError(state, action);
    default: return state;
  }
}