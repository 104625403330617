import React from 'react';
import { useSelector } from 'react-redux';

import { getCustomColor } from '@utils';
import { rgba } from 'polished';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
ChartJS.defaults.color = '#fff';

export const HorizontalBarChart = ({ data }) => {
  const theme = useSelector(({ app }) => app.get('theme'));
  
  const values = data.map((category)=> category.value);
  const labels = data.map((category)=> category.label);

  const FindMaxNumber = Math.max(...values);

  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: { 
        clamp: true,
        font:{
          size:12,
          weight: 'bold',
        },
        backgroundColor: theme['@global-dark-secondary-color'],
        padding: {
          top: 5,
          bottom: 5,
          right: 10,
          left: 10
        },
        borderRadius: 5,
        color: '#fff', 
        anchor: 'end', 
        align: function(context) {
          const value = context.dataset.data[context.dataIndex];
          return value > (FindMaxNumber * 0.80) ? 'start' : 'end'; 
        },
        formatter: function(value, context) {
          return value;
        },        
      }
    },
    scales: {
      x: {
        position: 'top',
        border:{
          color: rgba(255, 255, 255, 0.5),
          dash:[6],
        },
        ticks: {
          font: {
            weight: '400',
            size: 16,
          },
          color: rgba(255, 255, 255, 0.5),
        },
        grid:{
          color: rgba(255, 255, 255, 0.2),
        }
      },
      y:{
        display: true,
        position: 'left',
        ticks:{  
          callback: function(value, index, values) {
            let dataName = this.getLabelForValue(value);
            let firstHalf = '';
            let secondHalf = '';
        
            if (dataName.length <= 20) {
              firstHalf = dataName; 
              return firstHalf;
            } else {
              let spaceIndex = dataName.lastIndexOf(' ', 20);
              if (spaceIndex === -1) {
                firstHalf = dataName.substring(0, 20);
                secondHalf = dataName.substring(20);
              } else {
                firstHalf = dataName.substring(0, spaceIndex);
                secondHalf = dataName.substring(spaceIndex + 1);
              }
              return [firstHalf, secondHalf];
            }
            
          },
          font: {
            weight: '400',
            size: 14,
          },
          color: theme.textColor,
          align: 'left',
        },
        grid: {
          display: false,
        },
      }
    },
  };

  const formattedData = {
    labels:labels,
    datasets: [
      {
        borderRadius: 10,
        data: values,
        borderColor: getCustomColor('--super-success-color'),
        backgroundColor: getCustomColor('--super-success-color'),
        barPercentage: 0.6,
        categoryPercentage: 1
      },
    ],
  };

  return (
    <>
      <Bar data={formattedData} options={options}/>
    </>
  );
};

export default HorizontalBarChart;
