import styled from 'styled-components';
import { Text, View } from '@components';

export const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => gap};
`;

export const GrayText = styled(Text)`
font-size: 16px;
color: ${({ theme }) => theme.textColor};
`;

export const ContainerNotification = styled(View)`
  margin: 0 40px 0 0;
  cursor: pointer;
`;