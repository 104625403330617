import React from 'react';

import { Grid, Text, Skeleton, Column } from '@components-teammove';

import { Card } from './styles';
import { useSelector } from 'react-redux';
import { formatCurrency } from '@utils';

export default function GridCards() {

  const callList = useSelector(({ callActivity }) => callActivity.get('callList'));
  const loadingCallList = useSelector(({ callActivity }) => callActivity.get('loadingCallList'));
  const successCallList = useSelector(({ callActivity }) => callActivity.get('successCallList'));

  return(
    <>
      {loadingCallList ? 
        <Column gap='12px'>
          <Grid numberOfColumns={2} gap='12px'>
            <Skeleton.Card/>
            <Skeleton.Card/>
          </Grid>
          <Grid numberOfColumns={4} gap='12px'>
            <Skeleton.Card/>
            <Skeleton.Card/>
            <Skeleton.Card/>
            <Skeleton.Card/>
          </Grid>
        </Column> 
        : successCallList && (
          <>
            <Grid numberOfColumns={2} gap='12px'>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Custo total de ligações</Text>
                <Text size='24px' weight='700' >{formatCurrency(callList.totalCostCalls) || 0}</Text>
              </Card>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Custo médio de ligações</Text>
                <Text size='24px' weight='700' >{formatCurrency(callList.averageCostPerCall) || 0}</Text>
              </Card>
            </Grid>
            <Grid numberOfColumns={4} gap='12px'>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Tempo total em ligação</Text>
                <Text size='24px' weight='700' >{(callList.totalTimeCall / 60 || 0).toFixed(2).replace('.', ',')}  <Text lowOpacity size='16px'>min</Text></Text>
              </Card>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Tempo médio de ligação</Text>
                <Text size='24px' weight='700' >{(callList.averageTimeCall / 60 || 0).toFixed(2).replace('.', ',')} <Text lowOpacity size='16px'>min</Text></Text>
              </Card>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Total de ligações</Text>
                <Text size='24px' weight='700' >{(callList.totalCall || 0)}</Text>
              </Card>
              <Card>
                <Text size='16px' weight='600' lowOpacity>Média de ligações por dia</Text>
                <Text size='24px' weight='700' >{(callList.averageCallsPerDay / 60 || 0).toFixed(2).replace('.', ',')} <Text lowOpacity size='16px'>min</Text></Text>
              </Card>
            </Grid>
          </>
        )}
      
    </>
  );
}