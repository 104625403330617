import React from 'react';
import styled from 'styled-components';
import { Button, List, View, Text, Row } from '@components-teammove';
import { moment } from '@utils';

const ListaHorario = styled(List)`
  margin-bottom: 8px;

  .ant-list-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
`;

const HeaderLista = styled(View)`
  align-items: center;
`;

const DiaSemanaText = styled(Text)`
  flex: 1;
`;

const DiaSemana = ({ dia, horarios, onPicker, onCopy, onExcluir }) => {
  const horario = horarios.find((x) => x.diaSemana === dia);
  const intervalos = (horario) && (horario.intervalos) ? horario.intervalos : [];

  return(
    <ListaHorario
      header={(
        <HeaderLista horizontal>
          <DiaSemanaText strong>{dia}</DiaSemanaText>
          <Row gap='8px'>
            <Button type='secondary' size='small' context='list' visible={intervalos.length} onClick={() => onCopy(dia)}>Copiar para</Button>
            <Button type='secondary' size='small' context='list' onClick={() => onPicker({ diaSemana: dia })}>Novo</Button>
          </Row>
        </HeaderLista>
      )}
      contrastHeader
      headerPadding='5px 20px'
      dataSource={intervalos}
      locale={{
        emptyText: 'Sem horários cadastrados',
      }}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button key="2" type="secondary" size='small' context='list' onClick={() => onPicker({ ...item, diaSemana: dia })}>Editar</Button>,
          ]}
        >
          <Text>{moment(item.inicio, 'HH:mm:ss').format('HH:mm')} - {moment(item.fim, 'HH:mm:ss').format('HH:mm')}</Text>
        </List.Item>
      )}
    />
  );
};

export default DiaSemana;
