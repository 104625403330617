import { get, post, del } from '../api';

export const getLogoImpressao = () => {
  return get('/api/logo');
};

export const postLogoImpressao = (url, empresa) => {
  return post(`/api/logo/salvar?url=${url}&description=${empresa}`);
};

export const deleteLogoImpressao = () => {
  return del('/api/logo/delete');
};