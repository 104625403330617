import { getUnidades } from '@sdk/Unidades';
import { TelasNomenclaturas } from '@utils';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const removableFilters = [
  {
    type: 'CHECKBOX',
    label: 'Atribuídas por Mim',
    name: 'createdByMyself'
  },
  {
    type: 'RANGE',
    label: 'Período',
    name: 'period'
  },
];

export const initialFilters = [
  {
    type: 'SELECT',
    label: 'Status',
    name: 'status',
    placeholder: 'Selecione os Status',
    options: [
      { value:'CANCELADA', label: 'Cancelada' },
      { value:'FINALIZADA', label: 'Finalizada' },
      { value:'PENDENTE', label: 'Pendente' },
    ],
  },
  {
    type: 'SELECT',
    label: 'Tipo de Atividade',
    name: 'typesActivities',
    placeholder: 'Selecione os Tipos de Atividade',
    options: [],
  },
  {
    type: 'SELECT',
    label: 'Usuários',
    name: 'users',
    placeholder: 'Selecione os Usuários',
    options: [],
  },
  {
    type: 'SELECT',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'typesCompanies',
    placeholder: `Selecione os Tipos de ${companyNomenclature.plural}`,
    options: [],
  },
  {
    type: 'SELECT',
    label: `${companyNomenclature.nomenclatura}`,
    name: 'companies',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.plural}`,
    action: getUnidades
  },
  ...removableFilters
];

export const sortFilters = (filters) => {
  const order = ['period', 'status', 'typesActivities', 'users', 'typesCompanies', 'createdByMyself', 'companies'];

  const sortedFilters = filters.sort((a, b) => {
    const indexA = order.indexOf(a.name);
    const indexB = order.indexOf(b.name);
    return indexA - indexB;
  });

  return sortedFilters;

};