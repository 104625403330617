import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row, Column } from '@components-teammove';
import { getClassifications, reset } from '@ducks/classificacoes';
import { Notification } from '@utils';
import ClassificacaoModal from './Cadastro';

export default function Classificacao({ visibleRegister, setVisibleRegister }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const classifications = useSelector(({ classificacoes }) => classificacoes.get('classifications'));
  const loadingClassifications = useSelector(({ classificacoes }) => classificacoes.get('loadingClassifications'));
  const successSaveClassification = useSelector(({ classificacoes }) => classificacoes.get('successSaveClassification'));

  useEffect(() => {
    dispatch(getClassifications());
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if(successSaveClassification) {
      Notification.success('Classificação salva com sucesso!');
      dispatch(getClassifications());
    }
  }, [successSaveClassification]);

  const editClassificacao = (classificacaoSelecionada) => {
    if(classificacaoSelecionada) {
      history.push('?tipo=' + classificacaoSelecionada.id, { tipoClassificacao: classificacaoSelecionada });
    }
    setVisibleRegister(true);
  };

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'titulo',
      sorter: true,
      render: (titulo, classificacao) => (
        <Row justify='space-between' align='center'>
          {titulo}
          <Button.Group>
            <Button
              type='secondary'
              size='small'
              context='list'
              onClick={(event) => {
                event.stopPropagation();
                editClassificacao(classificacao);
              }}
            >
              Editar
            </Button>
          </Button.Group>
        </Row>
      )
    },
  ];
  return (
    <>
      <Column>
        <Table
          columns={columns}
          dataSource={classifications}
          loading={loadingClassifications}
          rowKey="id"
          status="ativo"
          onRow={(classificacao) => ({
            onClick: () => editClassificacao(classificacao)
          })}
        />
      </Column>

      {visibleRegister && (
        <ClassificacaoModal
          visible={visibleRegister}
          onCancel={() => setVisibleRegister(false)}
        />
      )}
    </>
  );
}
