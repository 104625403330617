import React from 'react';
import { Modal, Form, FormItem, Select, Input } from '@components-teammove';

export default function ModalResponsavel({ possibleAssignees, chamado, visible, loading, onCancel, onChangeResponsavel }) {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onChangeResponsavel(values.responsavel);
    }).catch((err) => console.error(err.message));
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading }}
      okText="Alterar"
      closable={false}
      title="Alterar responsável"
    >
      <Form layout="vertical" form={form}>

        {chamado.nmUsuarioAtendimento && 
          <FormItem label="De">
            <Input disabled value={chamado.nmUsuarioAtendimento} />
          </FormItem>
        }

        <FormItem
          label="Para"
          name="responsavel"
          rules={[
            { required: true, message: 'Informe um responsável!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || chamado.idUsuarioAtendimento !== value) {
                  return Promise.resolve();
                }
                return Promise.reject('Informe um responsável diferente!');
              },
            }),
          ]}
        >
          <Select
            options={possibleAssignees}
            placeholder="Selecione um responsável"
            autoFocus
            defaultActiveFirstOption
            form={form}
            name="responsavel"
          />
        </FormItem>
      </Form>
    </Modal>
  );
}
