import { View, Image } from '@components';
import { Button, Text } from '@components-teammove';
import styled from 'styled-components';

export const ImageContainer = styled(View)`
  margin-bottom: 26px;
`;

export const ImageWrapped = styled(Image)`
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 5px;
`;

export const ModalFooter = styled(View)`
  gap: 20px;
  flex: 1;
`;

export const ButtonConfirmRelease = styled(Button)`
  width: 100% !important;
  border-radius: 8px !important;
`;

export const TitleModal = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0px;
  color: ${(props) => props.theme.textColor} !important;
`;

export const TitleRelease = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
  color: ${(props) => props.theme.textColor} !important;
`;

export const SubText = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  opacity: 0.5;
  font-weight: 400;
  font-size: 12px;
`;