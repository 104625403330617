import styled from 'styled-components';
import { Column, Row, Text } from '@components-teammove';

export const StyledRow = styled(Row)`
  position: relative;
  width: 100%;
`;

export const GapFill = styled.div`
  border: 1px solid ${({ state }) => `var(--${state || 'success'}-color)`};
  height: 0;
  background-color: var(--success-color);
  flex: 1;
  margin: 24px 0;
  ${({ visible }) => !visible && `
    visibility: hidden;
  `}
`;

export const IconCircle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ state }) => state === 'warning' ? 'transparent' : 'var(--success-color)'};
  ${({ state }) => state === 'warning' && `
    border: 1px solid var(--warning-color);
  `}
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 18px;
    
    * {
      ${({ state, theme }) => state === 'warning' ? `
        color: var(--warning-color);
      ` : `
        color: ${theme.textColor};
      `}
    }
  }
`;

export const StyledColumn = styled(Column)`
  
`;

export const Scrollable = styled(Row)`
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  height: fit-content;
  justify-content: center;
`;

export const LabelsRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

export const LabelText = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    width: min-content;
    text-align: center;
    line-height: 1.5;
  }
`;