import React from 'react';
import { Row, Tab, TabName } from './styles';

const Tabs = ({ options, current, onChange, ...props }) => (
  <Row {...props}>
    {options.map((option) => (
      // eslint-disable-next-line react/forbid-component-props
      <Tab current={current === option.name} className={'tab' + (current === option.name ? ' current' : '')} key={option.name} title={option.name} onClick={() => onChange(option.name)}>
        <TabName>{option.name}</TabName>
      </Tab>
    ))}
  </Row>
);

export default Tabs;