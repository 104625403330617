import React, { useRef, useState } from 'react';
import { AvatarLoggedUser, Button, Input, View } from '@components';
import styled from 'styled-components';
import { InfoUsuario } from '@utils';
import { apiPostComentario, setPosting } from '@ducks/wikisComentario';
import { useDispatch, useSelector } from 'react-redux';

const NovoComentario = styled(View)`
  width: 100%;
`;

const Comentario = styled(View)`
  padding: 10px;
  width: 100%;
`;

const BotoesComentario = styled(View)`
  display: ${({ display }) => display};
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonComentar = styled(Button)`
  margin-left: 10px;
`;

export default function Novo({ handlers, data }) {
  const comentarioInputRef = useRef(null);
  const [displayBotoesComentario, setDisplayBotoesComentario] = useState('none');
  const { comentarios, idCategoria } = data;
  const dispatch = useDispatch();

  const posting = useSelector(({ wikisComentario }) => wikisComentario.get('posting'));

  const resetComentario = () => {
    comentarioInputRef.current.state.value = '';
    setDisplayBotoesComentario('none');
  };

  const handleComentar = async () => {
    const comentarioTexto = comentarioInputRef?.current?.state?.value;

    if (!comentarioTexto) {
      comentarioInputRef.current.focus();
      return;
    }

    let minId = -1;
    if (comentarios.length > 0) minId = Math.abs(Math.min(...comentarios.map((comentario) => comentario.id))) * -1 - 1;
    const { cdUsuario, nmUsuario } = InfoUsuario.get();

    const comentario = {
      comentario: comentarioTexto,
      id: minId,
      idCategoria,
      cdUsuario,
      nmUsuario,
      usuarioLogado: true,
      qtLike: 0,
      qtDislike: 0,
      acao: null,
    };

    dispatch(setPosting(true));

    const result = await apiPostComentario(comentario);

    if (result) {
      comentario.id = result.id;
      comentario.dtCriacao = result.dtCriacao;
      comentario.dtAlteracao = result.dtAlteracao;

      resetComentario();
      handlers.setComentarios([comentario, ...comentarios]);
    }
    dispatch(setPosting(false));
  };

  return (
    <NovoComentario horizontal>
      <AvatarLoggedUser size={48} />
      <Comentario>
        <Input.TextArea
          ref={comentarioInputRef}
          placeholder='Adicione um comentário'
          autoSize
          onFocus={() => setDisplayBotoesComentario('flex')}
        />
        <View alignItems='flex-end'>
          <BotoesComentario display={displayBotoesComentario} horizontal>
            <Button onClick={(event) => handlers.click(event, () => setDisplayBotoesComentario('none'))} visible={!posting}>
              Cancelar
            </Button>
            <ButtonComentar type='primary' onClick={(event) => handlers.click(event, () => handleComentar())} loading={posting}>
              Comentar
            </ButtonComentar>
          </BotoesComentario>
        </View>
      </Comentario>
    </NovoComentario>
  );
}
