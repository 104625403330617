import { get, put } from '../api';
import { ansiToMoment, dateToAnsi, DATE_FORMATS } from '@utils';

export const getAfastamentos = () => {
  return get('/api/afastamento').then((res) => {
    return res.map((afastamento) => ({
      ...afastamento,
      inicio: ansiToMoment(afastamento.inicio),
      fim: ansiToMoment(afastamento.fim),
    }));
  });
};

export const getAfastamento = (id) => {
  return get(`/api/afastamento/${id}`).then((afastamento) => {
    return {
      ...afastamento,
      inicio: ansiToMoment(afastamento.inicio),
      fim: ansiToMoment(afastamento.fim),
    };
  });
};

export const putAfastamento = (afastamento) => {
  return put('/api/afastamento', {
    ...afastamento,
    inicio: dateToAnsi(afastamento.inicio, null, DATE_FORMATS.ANSI_DATETIME),
    fim: dateToAnsi(afastamento.fim, null, DATE_FORMATS.ANSI_DATETIME),
  });
};
