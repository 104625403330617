import styled from 'styled-components';
import { Icon, Column, Text, FinishFlag, Button } from '@components-teammove';

export const Cards = styled(Column)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 16px;
`;

export const SubText = styled(Text)`
  width: 50%;
`;

export const DivGround = styled.div`
  background-color: #1890FF;
  width: 60%;
  height: 4px;
`;

export const LogoGoals = styled.div`
  height: 95px;
`;

export const FinishFlagStyle = styled(FinishFlag)`
  position: relative;
  top: -85px;
  left: 45%;
`;

export const Square = styled.div`
  background-color: ${({ color })=> color === '1' ? '#FFFFFF' : '#000000'};
  width: 15px;
  height: 15px;
`;

export const IconStyle = styled(Icon.MDI)`
  position: relative;
  top: 9px;
  left: 55%;
  color: #1890FF;
`; 

export const ButtonStyle = styled(Button)`
  position: absolute;
  right: 40px;
  top: 415px;
`;

export const ListStyle = styled.li`
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
`; 
