import styled from 'styled-components';
import { Column, Image, View, Row, Progress, Modal, Icon } from '@components-teammove';

export const QuestionTitle = styled(Row)`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.textColor};
  width: 500px;
`;

export const QuestionAnswer = styled.span`
  font-size: 14px;
  font-weight: 700;
  white-space: pre-line;
  color: ${({ theme }) => theme.textColor};
  padding-left: 26px;
`;

export const AnswerExtraInfoContainer = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 26px;
  gap: 8px;
`;

export const AnswerExtraInfoTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
`;

export const AnswerExtraInfoValue = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const ActivityTypeIconWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  padding: ${({ size }) => (size === 'small' ? '8px' : '16px')};
`;

export const ActivityTypeIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const AnswerUpload = styled(Image)`
  max-height: 400px;
  max-width: 525px;
  border: 2px solid ${({ theme }) => theme.darkHighlightColor};
  border-radius: 9px;
  align-self: center;
  cursor: pointer;
`;

export const ContentWeight = styled(View)`
  justify-content: space-around;
  background: ${({ color }) => color};
  border-radius: 16px;
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
  font-weight: 700;
  width: 60px;
  height: 30px;
  align-items: center;
`;

export const RowStyle = styled(Row)`
  justify-content: space-between;
`;

export const Resume = styled(Row)`
  ${({ comPeso }) => comPeso && 'justify-content: space-around;'}
`;

export const ContentProgress = styled(Column)`
  justify-content: center;
`;

export const ProgressStars = styled(Progress.Star)`
  color: #34c759;
  --size: 35px;
`;

export const PercentNumber = styled(View)`
  align-items: center;
  padding-top: 12px;
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: 0.5;
`;

export const DataFillingOut = styled(Column)`
  gap: 13px;
`;

export const ContentUserInfo = styled(Row)`
  font-size: 16px;
  color: ${({ theme }) => theme.textColor};
  font-weight: 400;
  line-height: 22px;
`;

export const DataPlataform = styled(ContentUserInfo)`
  font-size: 16px;
  font-weight: 700;
`;

export const VerticalLine = styled.div`
  border-left: 3px solid ${({ theme }) => theme.darkPrimaryColor};
  height: 130px;
`;

export const IconHome = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const ContentDatas = styled(Column)`
  gap: 8px;
`;

export const DataRow = styled(Row)`
  gap: 8px;
  align-items: center;
`;

export const Hyphen = styled(ContentUserInfo)``;

export const ContentUnitData = styled(Column)`
  gap: 8px;
`;

export const ContentNumberAndNameUnit = styled(Column)`
  gap: 20px;
`;

export const NumberUnit = styled(ContentUserInfo)`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

export const NameUnit = styled(ContentUserInfo)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
`;

export const ContentLocation = styled(ContentUserInfo)`
  font-weight: 600;
`;

export const ContentDivider = styled(Column)`
  justify-content: center;
`;

export const HorizontalLine = styled.div`
  border-top: 3px solid ${({ theme }) => theme.darkPrimaryColor};
  width: 100%;
`;

export const ContentConsultant = styled(Column)`
  gap: 12px;
`;

export const ContentDigitalSignature = styled(Column)`
  gap: 12px;
  margin-bottom: 8px;
`;

export const LegendDigitalSignature = styled(ContentUserInfo)`
  justify-content: center;
`;

export const SignatureUpload = styled(AnswerUpload)`
  max-height: 350px;
`;

export const ModalStyle = styled(Modal)`
  &.ant-modal {
    .ant-modal-body {
      max-height: 60vh; 
    }
  }
`;

export const QuestionNumber = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  align-items: center;
  justify-content: center
`;

export const NumberText = styled.span`
  color: ${({ theme }) => theme.textColor};
  word-wrap: anywhere;
  font-size: 14px;
  font-weight: 300;
  opacity: 1;
`;

