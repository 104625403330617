import React from 'react';

import { Row, Text, Button } from '@components-teammove';
import { DirectionButton, FeatherIcon } from './styles';
import { getInitialDateByPeriod } from '../../../containers/Chamados/DashboardChamados/rules';

const LeftRightGroupButton = ({ initialDate, currentDateFormatted, handleDateChange, typePeriod, colorArrowButton }) => {
  
  return (
    <Row align='center' gap='16px'>
      <Row align='center' gap='12px'>
        <Button.Group>
          <DirectionButton
            removeFocus
            type={`${colorArrowButton || 'secondary'}` }
            size='smaller'
            context='list'
            icon={<FeatherIcon type='chevron-left'/>} 
            title='Regredir'
            onClick={() => handleDateChange(initialDate, 'regredir', typePeriod)} 
          />
          <DirectionButton
            removeFocus
            type={`${colorArrowButton || 'secondary'}` } 
            size='smaller'
            context='list'
            icon={<FeatherIcon type='chevron-right'/>} 
            title='Progredir'
            onClick={() => handleDateChange(initialDate, 'progredir', typePeriod)} 
            disabled={getInitialDateByPeriod(typePeriod) === initialDate}
          />
        </Button.Group>
      </Row>
      <Text font='16px' weight='600'>{currentDateFormatted}</Text>
    </Row>
  );
};

export default LeftRightGroupButton;