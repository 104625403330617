import { MessageList } from 'react-chat-elements';
import styled from 'styled-components';

const MessageListStyled = styled(MessageList)`
  div[class*=rce-avatar-container] {
    border-radius: 100%;
  }
  div[class*=rce-mbox-text] {
    white-space: pre-line;
  }
  div[class*=rce-smsg] {
    background-color: #f2f2f2;
    color: gray;
    font-size: 14px;
  }
  .rce-mbox {
    background-color: #f0f0f0;
    box-shadow: none;
  }
  .rce-mbox-left-notch {
    fill: #f0f0f0;
  }
  .rce-mbox-right {
    background-color: #0084ff;
  }
  .rce-mbox-right-notch {
    fill: #0084ff;
  }
  .rce-mbox-photo {
    background-color: #fff;
  }

  div[class*=rce-mbox-right] {
    .rce-mbox-title, .rce-mbox-text, .rce-mbox-time, .ant-typography {
      color: white;
    }
  }  

  div[class*=date-msg] {
    div[class*=rce-smsg] {
      font-size: 12px;
      padding: 2px 3px;
    }
  }
  .rce-avatar-container.default {
    width: 40px;
    height: 40px;
  }
`;

export default MessageListStyled;
