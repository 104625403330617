const language = 'pt-BR';

const instance = (format) => {
  return new Intl.NumberFormat(language, format);
};

export const NUMERIC_FORMATS = {
  CURRENCY: { style: 'currency', currency: 'BRL' },
  DECIMAL: { style: 'decimal' },
};

export const format = (value, format) => {
  return instance(format).format(value);
};

export const formatCurrency = (value) => {
  return format(value, NUMERIC_FORMATS.CURRENCY);
};

export const formatDecimal = (value, dec) => {
  return format(value, { ...NUMERIC_FORMATS.DECIMAL, minimumFractionDigits: dec, maximumFractionDigits: dec });
};