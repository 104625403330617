import styled from 'styled-components';
import { View, Text, Form, Radio, Column } from '@components-teammove';

export const Row = styled(View)`
  flex-direction: row;
  
  > * {
    flex: 1;
  }
`;

export const GroupLabel = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: ${({ weight })=> weight ? weight : '700'};
    color: ${({ theme }) => theme.textColor};
  }
`;

export const ClustersColumn = styled(Column)`
  margin-bottom: 1.25rem;
`;

export const ClusterColumnsTitle = styled.div`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;

export const RadioStyle = styled(Radio)`
  margin-bottom: 1.25rem;
`;

export const ClusterTexts = styled(Text)`
  &.ant-typography {
    font-size: ${({ small })=> small ? '12px' : '16px'};
    font-weight: ${({ bold })=> bold ? '700' : '400'};
    opacity: ${({ lowOpacity })=> lowOpacity ? '.5' : '1'};
    color: ${({ theme }) => theme.textColor};
  }
`;

export const  RadioGroup = styled(Radio.Group)`
  gap: 12px;
`;