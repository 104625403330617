import React, { useEffect, useState } from 'react';
import { Body, PageHeader, Skeleton, Button, Row, Column, Progress, Table, Icon } from '@components-teammove';
import App from '@app';

import moment, { formatDate, getNomeDia, getNomeDiaCompleto, getNomeDiaSemiCompleto } from '@utils/Datas';
import { InfoUsuario } from '@utils';
import { Subtitle, CenteredView, CheckIcon, CloseIcon, ColoredText, Container, DayHeader, DayView, FlexFillRow, Greeting, GreetingSubtitle, Header, HeaderText, JustifyCenterRow, MarginBottom, PaddingLeft, ProfileHeader, ProfileUsersTableTitle, RowWithGap, SecondaryHeaderText, TinyMarginBottom, DayViewBody } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getColorByPercentage, getReportPeriod } from './rules';
import Impressao from './Impressao';
import { getReport } from '@ducks/report';

export default function ReportSemanal() {
  const [reportPeriod, setReportPeriod] = useState();
  const [printing, setPrinting] = useState(false);

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const report = useSelector(({ report }) => report.get('report'));
  const loading = useSelector(({ report }) => report.get('loading'));

  useEffect(() => {
    const { start, end } = getReportPeriod();
    setReportPeriod({ start, end });
  }, []);

  useEffect(() => {
    if (reportPeriod) {
      dispatch(getReport(formatDate(reportPeriod.start).replaceAll('/', '-'), formatDate(reportPeriod.end).replaceAll('/', '-')));
    }
  }, [reportPeriod]);

  const columns = Object.keys(report).length > 0 ? [
    {
      title: 'Nome',
      dataIndex: 'name',
      width: '40%',
      render: (text) => (
        <ColoredText>{text}</ColoredText>
      )
    },
    ...(report?.days?.map((day) => ({
      title: getNomeDiaCompleto(moment(day.date)).split('-')[0],
      dataIndex: getNomeDia(moment(day.date)),
      align: 'center',
      width: '12%',
      render: (text) => text ? (
        <CheckIcon type='check'/>
      ) : <CloseIcon type='close'/>
    })) || {})
  ] : [];

  const getDataIndex = (profileUsers) => {
    return profileUsers.sort((a, b) => a.name.localeCompare(b.name)).map(({ name, days }) => {
      return {
        name,
        ...days.reduce((state, day) => ({
          ...state,
          [getNomeDia(moment(day.day))]: day.accessed
        }), {})
      };
    });
  };

  const getTableTitle = (profile) => () => {
    const profileDays = profile.days.sort((a, b) => moment(a.date).diff(moment(b.date)));

    return(
      <>
        <ProfileUsersTableTitle align='flex-end'>
          <ProfileHeader>
            <GreetingSubtitle><strong>{profile.name}</strong></GreetingSubtitle>
            <ColoredText>% de uso</ColoredText>
            <Progress 
              type='line' 
              percent={profile.percentProfileAccess} 
              width='50%' 
              strokeWidth={20} 
              strokeColor={getColorByPercentage(profile.percentProfileAccess, theme)}
              trailColor={theme['@global-dark-lowlight-color']} 
              useTextColor
              innerFontSize='3em'
            />
          </ProfileHeader>
          <FlexFillRow>
            {profileDays.map((day) => (
              <JustifyCenterRow key={day.day}>
                <Progress.HalfCircle
                  percent={day.percentageTotalAccess}
                  strokeColor={getColorByPercentage(day.percentageTotalAccess, theme)}
                  useTextColor
                />
              </JustifyCenterRow>
            ))}
          </FlexFillRow>
        </ProfileUsersTableTitle>
      </>
    );
  };

  const handlePrint = () => {
    setPrinting(true);
  };

  return printing ? (
    <Impressao 
      loading={loading} 
      report={report} 
      reportPeriod={reportPeriod} 
      columns={columns} 
      getDataIndex={getDataIndex} 
      logo={theme['@logo'] || process.env.PUBLIC_URL + '/images/logo.png'}
      isOurLogo={!theme['@logo']}
      setPrinting={setPrinting}
    />
  ) : (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Report Semanal'
          onBack={() => window.history.back()}
          extra={InfoUsuario.get('senhaMaster') && [
            <Button key='press' size='small' context='header' onClick={() => handlePrint()} disabled={loading}>Imprimir</Button>
          ]}
        >
          {loading ? (
            <Column gap='28px'>
              <Column gap='12px'>
                <Skeleton height='105px'/>
                <Row justify='space-between' align='center'>
                  <PaddingLeft>
                    <Column gap='8px'>
                      <Skeleton.Title/>
                      <Skeleton.MinorLine/>
                      <Skeleton.MinorLine/>
                    </Column>
                  </PaddingLeft>
                  <Skeleton height='250px' width='400px'/>
                </Row>
              </Column>
              <Column gap='.5rem'>
                <Skeleton.Title/>
                <Row gap='12px'>
                  <Skeleton height='180px'/>
                  <Skeleton height='180px'/>
                  <Skeleton height='180px'/>
                  <Skeleton height='180px'/>
                  <Skeleton height='180px'/>
                </Row>
              </Column>
              <Column gap='8px'>
                <Skeleton.Title/>
                <Skeleton.MinorLine/>
                <Skeleton.MinorLine/>
              </Column>
              <Table loading/>
            </Column>
          ) : (
            <Column gap='28px'>
              <Column gap='12px'>
                <Header align='center' justify='space-between'>
                  <Column gap='8px'>
                    <HeaderText><strong>Daily Active Users</strong> - {InfoUsuario.get('nmCliente')}</HeaderText>
                    <SecondaryHeaderText>Referente ao período de {formatDate(reportPeriod?.start)?.substring(0, 5)} até {formatDate(reportPeriod?.end)?.substring(0, 5)}</SecondaryHeaderText>
                  </Column>
                  <RowWithGap>
                    <Button
                      icon={<Icon.Feather type='chevron-left'/>}
                      onClick={() => setReportPeriod({ start: reportPeriod.start.subtract(7, 'days'), end: reportPeriod.end.subtract(7, 'days') })} 
                      title='Voltar uma semana'
                      type='highlight'
                      size='small'
                      context='header'
                    >
                    Semana Anterior
                    </Button>
                    <Button
                      onClick={() => setReportPeriod({ start: reportPeriod.start.add(7, 'days'), end: reportPeriod.end.add(7, 'days') })} 
                      title='Avançar uma semana'
                      type='highlight'
                      size='small'
                      context='header'
                      disabled={getNomeDia(moment()) === 'sex' ? moment().isBetween(reportPeriod?.start, reportPeriod?.end) : moment().subtract(7, 'days').isBetween(reportPeriod?.start, reportPeriod?.end)}
                    >
                    Próxima Semana
                      <Icon.Feather type='chevron-right'/>
                    </Button>
                  </RowWithGap>
                </Header>
                <Row justify='space-between'>
                  <PaddingLeft>
                    <Greeting>Olá, {InfoUsuario.get('nmUsuario').split(' ')[0]}!</Greeting>
                    <GreetingSubtitle>Este é o seu <strong>report semanal</strong> de uso do aplicativo TeamMove</GreetingSubtitle>
                    <ColoredText>Você tem: <strong>{report?.totalUsers} usuários ativos</strong></ColoredText>
                  </PaddingLeft>
                  <CenteredView>
                    <Progress.HalfCircle
                      width={500}
                      fontSize='36px'
                      percent={report?.totalUsersPercentage}
                      useTextColor
                      strokeColor={getColorByPercentage(report?.totalUsersPercentage, theme)}
                      margin='auto'
                    />
                    <Subtitle>Essa é a <strong>taxa de uso geral</strong> da sua equipe</Subtitle>
                  </CenteredView>
                </Row>
              </Column>
              <Container>
                <TinyMarginBottom>
                  <GreetingSubtitle><strong>Total de acessos na semana</strong></GreetingSubtitle>
                </TinyMarginBottom>
                <RowWithGap>
                  {report?.days?.map((day) => (
                    <DayView key={day.date}>
                      <DayHeader>
                        <strong>{getNomeDiaSemiCompleto(moment(day.date))}</strong>
                      </DayHeader>
                      <DayViewBody>
                        <Progress.HalfCircle
                          width='100%'
                          percent={day.percentageTotalAccess}
                          useTextColor
                          strokeColor={getColorByPercentage(day.percentageTotalAccess, theme)}
                          fontSize='24px'
                          margin='auto'
                        />
                        <ColoredText>{day.totalAccess} de {report.totalUsers}</ColoredText>
                      </DayViewBody>
                    </DayView>
                  ))}
                </RowWithGap>

                <MarginBottom/>

                {report?.profiles?.map((profile) => (
                  <>
                    <Table 
                      columns={columns} 
                      dataSource={getDataIndex(profile.users)} 
                      title={getTableTitle(profile)} 
                      hidePagination
                    />
                  </>
                ))}
              </Container>
            </Column>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}