import styled from 'styled-components';
import { View, Image, Text } from '@components';

export const Row = styled(View)`
  flex-direction: row;
  gap: 14px;
`;

export const Container = styled(Row)`
  overflow-x: scroll;
`;

export const RowWithRelativeGap = styled(Row)`
  gap: 15%;
`;

export const Column = styled(View)`
  gap: 4px;
`;  

export const TagInnerText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
`;

export const Ticket = styled(Row)`
  background-color: #4C4766;
  border-radius: 18px;
  padding: 17px 16px;
  gap: 16px;
`;

export const TicketBody = styled(View)`
  gap: 8px;
`;

export const AvatarImg = styled(Image)`
  width: 48px;
  height: 48px;
  border-radius: 100%;
`;

export const UserName = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 700;
    color: #F5F5F5;
    opacity: .5;
  }
`;

export const InfoWithIcon = styled(Row)`
  gap: 6px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #F5F5F5;
  opacity: .5;
`;

export const Subject = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
    color: #F5F5F5;
    line-height: 24.51px;
  }
`;

export const Protocol = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 700;
    color: #F5F5F5;
    opacity: .5;
  }
`;

export const Category = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: #F5F5F5;
    opacity: .5;
  }
`;