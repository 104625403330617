import React from 'react';
import { StyledMenu, StyledMenuItem } from './styles';

const Menu = ({ children, ...props }) => (
  <StyledMenu {...props}>
    {children}
  </StyledMenu>
);

Menu.Item = StyledMenuItem;

export default Menu;