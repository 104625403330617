import React from 'react';
import { Overlay } from '@components-teammove';
import { CloseButton, Container, Column, JustifyCenter, EventsContainer, DayOfWeek, DayOfMonth } from './styles';
import { MonthEvent, MonthEventColor, MonthEventTitle } from '../styles';

const MonthDayView = ({ date, events, onCancel }) => {
  const uniqueId = Date.now();

  const outsideClickListener = (event) => {
    if (!document.getElementById(uniqueId)?.contains(event.target)) {
      onCancel();
    }
  };

  return(
    <Overlay onClick={outsideClickListener}>
      <Container id={uniqueId}>
        <CloseButton type='close' onClick={onCancel}/>
        <JustifyCenter>
          <Column align='center'>
            <DayOfWeek>{['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'][new Date(date).getDay()]}</DayOfWeek>
            <DayOfMonth>{new Date(date).getDate()}</DayOfMonth>
          </Column>
        </JustifyCenter>
        <EventsContainer>
          {events.map((event) => (
            <MonthEvent
              key={event.id} onClick={() => ''}
            >
              <MonthEventColor color={event.typeColor}/>
              <MonthEventTitle>
                <strong>{new Date(event.start).toLocaleTimeString().substring(0, 5)} - </strong>
                {event.title}
              </MonthEventTitle>
            </MonthEvent>
          ))}
        </EventsContainer>
      </Container>
    </Overlay>
  );
};

export default MonthDayView;