import React from 'react';
import { FeatherIcon } from './styles';

export const getIcon = (uploads) => {
  const type = uploads?.length > 0 && uploads.substring(uploads.lastIndexOf('.') + 1);

  if(type === 'jpeg' || type === 'jpg' || type === 'png') return <FeatherIcon type='camera' size='14px'/>;
  
  else return <FeatherIcon type='file' size='14px'/>;
  
};

