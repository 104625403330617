import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Text, Column, Button, SearchBar, Skeleton } from '@components-teammove';
import { ContentPosition, DotTopLevel, IconFeather, TableStyle  } from './styles';

import { Filtros, moment, TelasNomenclaturas } from '@utils';

import { FILTERS_KEY, PARAMS_KEY } from '@ducks/chamadosDashboard';
import { FILTERS_KEY as TICKETS_FILTERS_KEY } from '@ducks/chamadosTickets';

import { getFinalDateByInitialDateAndPeriod, getOldFiltersWithNewFilters } from '../../../rules';

export default function CompaniesRakingTable({ companies, companiesTotal, loadingCompanies, resum, limit, setLimit }) {
  const history = useHistory();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const filters = { ...Filtros.get(FILTERS_KEY), ...Filtros.get(PARAMS_KEY) };
  if (Filtros.get(FILTERS_KEY)?.descFiltros || Filtros.get(PARAMS_KEY)?.descFiltros) {
    filters.descFiltros = { ...Filtros.get(FILTERS_KEY)?.descFiltros, ...Filtros.get(PARAMS_KEY)?.descFiltros };
  }
 
  const handleCategoryClick = (company) => {
    const newTicketsPageFilters = getOldFiltersWithNewFilters(
      Filtros.get(TICKETS_FILTERS_KEY), 
      {
        ...filters, 
        idCompanies: [company.id], 
        creationDate: [moment(filters.initialDate), getFinalDateByInitialDateAndPeriod(filters.initialDate, filters.typePeriod)],
        descFiltros: {
          ...filters.descFiltros, 
          idCompanies: [company.name],
          creationDate: [moment(filters.initialDate).format('DD/MM/YYYY'), getFinalDateByInitialDateAndPeriod(filters.initialDate, filters.typePeriod).format('DD/MM/YYYY')]
        }
      }
    );

    Filtros.set(TICKETS_FILTERS_KEY, newTicketsPageFilters);
    history.push('/chamados/tickets');
  };
  
  const columns = [
    {
      title: 'Pos',
      width: '50px',
      render: (items, item, index) => (
        ((index + 1) < 4 ? (
          <DotTopLevel>
            <Text font='16px' weight='700'>{index + 1}</Text>
          </DotTopLevel>
        ) : (
          <ContentPosition>
            <Text font='16px' weight='700'>{index + 1}</Text>
          </ContentPosition>
          
        ))
      )
    },
    {
      title: `${companyNomenclature.plural}`,
      dataIndex: 'name',
      render: (index, item) => (
        <Column>
          <Text size='16px' weight='700'>{item.shortName}</Text>
          <Text size='12px' weight='400' lowOpacity>{item.name}</Text>
        </Column>
      )
    },
    {
      title: 'Endereço',
      dataIndex: 'state',
      render: (index, item) => (
        <Column>
          <Text size='14px' weight='400'>{item.address}</Text>
          <Text size='12px' weight='400' lowOpacity>{item.city}{item.state ? ` - ${item.state}` : ''}{item.cep ? ` - ${item.cep}` : ''}</Text>
        </Column>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width:'150px',
      render: (index, item) => (
        <Row align='center' justify='space-between'>    
          <Text size='16px' weight='700'>{item.numberOfTickets}</Text>       
        </Row>
      )
    },
    {
      title: 'Ver lista',
      dataIndex: 'ver lista',
      width:'50px',
      align: 'end',
      render: (index, item) => (
        <Row align='center' justify='flex-end'>    
          <Button type='icon' size='small' icon={(<IconFeather type='arrow-right' />)} />         
        </Row>
      )
    }
  ];

  const [search, setSearch] = useState('');
  
  const handleSearch = (term) => {
    setSearch(term);
  };

  const filteredCompanies = useMemo(() => {
    if (!search) {
      return companies; 
    }
  
    const filtered = companies.filter((company) => {
      
      return Object.values(company).some((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return value.toString().toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });
  
    return filtered;
  }, [companies, search]);
  
  return(
    (loadingCompanies) ? (
      <Skeleton.Map/>
    ) : (
      <>
        <Column>
          {!resum && 
          <Column gap='10px'>
            <Row padding='0 0 0 20px'>
              <Text size='20px' weight='700'>Ranking de {companyNomenclature.plural}</Text>
            </Row>
            
            <SearchBar
              placeholder="Buscar"  
              onSearch={(term)=>handleSearch(term)}
            />
            
          </Column>
          }
          <Column gap='12px'>
            <TableStyle
              resum={resum}
              columns={columns}
              dataSource={filteredCompanies}
              rowKey='id'
              hidePagination
              onRow={(tab) => ({ onClick: () => handleCategoryClick(tab) })}
            />
            {!resum && limit < companiesTotal && (
              <Row justify='center'>
                <Button loading={loadingCompanies} type='secondary' size='small' onClick={() => setLimit(limit + 100)}>{'Carregar mais'}</Button>
              </Row>
            )}
          </Column>
        </Column>
        
      </>
    )
  );
}