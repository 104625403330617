import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import {
  postLido as postLidoSdk,
  postFavorito as postFavoritoSdk,
  postAceito as postAceitoSdk,
  postCurtido as postCurtidoSdk,
  postNewFeedback as postNewFeedbackSdk,
  postNewAudiencia as postNewAudienciaSdk,
} from '@sdk/Wikis/Acoes';

//Action Types
export const Types = {
  POST_LIDO: 'WIKI/POST_LIDO',
  POST_LIDO_SUCCESS: 'WIKI/POST_LIDO_SUCCESS',
  POST_LIDO_ERROR: 'WIKI/POST_LIDO_ERROR',

  POST_FAVORITO: 'WIKI/POST_FAVORITO',
  POST_FAVORITO_SUCCESS: 'WIKI/POST_FAVORITO_SUCCESS',
  POST_FAVORITO_ERROR: 'WIKI/POST_FAVORITO_ERROR',

  POST_ACEITO: 'WIKI/POST_ACEITO',
  POST_ACEITO_SUCCESS: 'WIKI/POST_ACEITO_SUCCESS',
  POST_ACEITO_ERROR: 'WIKI/POST_ACEITO_ERROR',

  POST_NEWFEEDBACK: 'WIKI/POST_NEWFEEDBACK',
  POST_NEWFEEDBACK_SUCCESS: 'WIKI/POST_NEWFEEDBACK_SUCCESS',
  POST_NEWFEEDBACK_ERROR: 'WIKI/POST_NEWFEEDBACK_ERROR',

  POST_NEWAUDIENCIA: 'WIKI/POST_NEWAUDIENCIA',
  POST_NEWAUDIENCIA_SUCCESS: 'WIKI/POST_NEWAUDIENCIA_SUCCESS',
  POST_NEWAUDIENCIA_ERROR: 'WIKI/POST_NEWAUDIENCIA_ERROR',

  POST_CURTIDO: 'WIKI/POST_CURTIDO',
  POST_CURTIDO_SUCCESS: 'WIKI/POST_CURTIDO_SUCCESS',
  POST_CURTIDO_ERROR: 'WIKI/POST_CURTIDO_ERROR',
};

//Action Creators
export const postLido = (id, lido) => ({ type: Types.POST_LIDO, id, lido });
export const postLidoSuccess = (lido) => ({ type: Types.POST_LIDO_SUCCESS, lido });
export const postLidoError = (error) => ({ type: Types.POST_LIDO_ERROR, error });

export const postFavorito = (id, favorito) => ({ type: Types.POST_FAVORITO, id, favorito });
export const postFavoritoSuccess = (favorito) => ({ type: Types.POST_FAVORITO_SUCCESS, favorito });
export const postFavoritoError = (error) => ({ type: Types.POST_FAVORITO_ERROR, error });

export const postAceito = (id, aceito) => ({ type: Types.POST_ACEITO, id, aceito });
export const postAceitoSuccess = (aceito) => ({ type: Types.POST_ACEITO_SUCCESS, aceito });
export const postAceitoError = (error) => ({ type: Types.POST_ACEITO_ERROR, error });

export const postNewFeedback = (feedback) => ({ type: Types.POST_NEWFEEDBACK, feedback });
export const postNewFeedbackSuccess = () => ({ type: Types.POST_NEWFEEDBACK_SUCCESS });
export const postNewFeedbackError = (error) => ({ type: Types.POST_NEWFEEDBACK_ERROR, error });

export const postNewAudiencia = (audiencia) => ({ type: Types.POST_NEWAUDIENCIA, audiencia });
export const postNewAudienciaSuccess = () => ({ type: Types.POST_NEWAUDIENCIA_SUCCESS });
export const postNewAudienciaError = (error) => ({ type: Types.POST_NEWAUDIENCIA_ERROR, error });

export const postCurtido = (id, curtido) => ({ type: Types.POST_CURTIDO, id, curtido });
export const postCurtidoSuccess = (curtido) => ({ type: Types.POST_CURTIDO_SUCCESS, curtido });
export const postCurtidoError = (error) => ({ type: Types.POST_CURTIDO_ERROR, error });

//async await functions
export const apiPostNewFeedback = async (feedback) => {
  try {
    const response = await postNewFeedbackSdk(feedback);
    return response;
  } catch (err) {
    Notification.error(err.message);
  }
};

export const apiPostNewAudiencia = async (audiencia) => {
  try {
    const response = await postNewAudienciaSdk(audiencia);
    return response;
  } catch (err) {
    Notification.error(err.message);
  }
};

//saga
function* fetchPostLido(action) {
  try {
    const { id, lido } = action;

    const retorno = yield call(postLidoSdk, id, lido);
    yield put(postLidoSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(postLidoError(err));
  }
}

function* fetchPostCurtido(action) {
  try {
    const { id, curtido } = action;

    const retorno = yield call(postCurtidoSdk, id, curtido);
    yield put(postCurtidoSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(postCurtidoError(err));
  }
}

function* fetchPostFavorito(action) {
  try {
    const { id, favorito } = action;

    const retorno = yield call(postFavoritoSdk, id, favorito);
    yield put(postFavoritoSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(postFavoritoError(err));
  }
}

function* fetchPostAceito(action) {
  try {
    const { id, aceito } = action;

    const retorno = yield call(postAceitoSdk, id, aceito);
    yield put(postAceitoSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(postAceitoError(err));
  }
}

function* fetchPostNewFeedback(action) {
  try {
    const { feedback } = action;

    yield call(postNewFeedbackSdk, feedback);
    yield put(postNewFeedbackSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(postNewFeedbackError(err));
  }
}

function* fetchPostNewAudiencia(action) {
  try {
    const { audiencia } = action;

    yield call(postNewAudienciaSdk, audiencia);
    yield put(postNewAudienciaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(postNewAudienciaError(err));
  }
}

export const saga = [
  takeLatest(Types.POST_LIDO, fetchPostLido),
  takeLatest(Types.POST_FAVORITO, fetchPostFavorito),
  takeLatest(Types.POST_ACEITO, fetchPostAceito),
  takeLatest(Types.POST_NEWFEEDBACK, fetchPostNewFeedback),
  takeLatest(Types.POST_NEWAUDIENCIA, fetchPostNewAudiencia),
  takeLatest(Types.POST_CURTIDO, fetchPostCurtido),
];

// Reducer
const initialState = Map({
  lido: false,
  loadingLido: false,
  successLido: false,
  errorLido: false,

  favorito: false,
  loadingFavorito: false,
  successFavorito: false,
  errorFavorito: false,

  aceito: false,
  loadingAceito: false,
  successAceito: false,
  errorAceito: false,

  curtido: false,
  loadingCurtido: false,
  successCurtido: false,
  errorCurtido: false,

  loadingNewFeedback: false,
  successNewFeedback: false,
  errorNewFeedback: false,

  loadingNewAudiencia: false,
  successNewAudiencia: false,
  errorNewAudiencia: false,
});

const handlePostLido = (state, action) => {
  return state.set('loadingLido', true).set('successLido', false).set('errorLido', false);
};

const handlePostLidoSuccess = (state, action) => {
  return state.set('lido', action.lido).set('loadingLido', false).set('successLido', true).set('errorLido', false);
};

const handlePostLidoError = (state, action) => {
  return state.set('loadingLido', false).set('successLido', false).set('errorLido', action.error);
};

const handlePostCurtido = (state, action) => {
  return state.set('loadingCurtido', true).set('successCurtido', false).set('errorCurtido', false);
};

const handlePostCurtidoSuccess = (state, action) => {
  return state.set('curtido', action.curtido).set('loadingCurtido', false).set('successCurtido', true).set('errorCurtido', false);
};

const handlePostCurtidoError = (state, action) => {
  return state.set('loadingCurtido', false).set('successCurtido', false).set('errorCurtido', action.error);
};

const handlePostFavorito = (state, action) => {
  return state.set('loadingFavorito', true).set('successFavorito', false).set('errorFavorito', false);
};

const handlePostFavoritoSuccess = (state, action) => {
  return state.set('favorito', action.favorito).set('loadingFavorito', false).set('successFavorito', true).set('errorFavorito', false);
};

const handlePostFavoritoError = (state, action) => {
  return state.set('loadingFavorito', false).set('successFavorito', false).set('errorAceito', action.error);
};

const handlePostAceito = (state, action) => {
  return state.set('loadingAceito', true).set('successAceito', false).set('errorAceito', false);
};

const handlePostAceitoSuccess = (state, action) => {
  return state.set('aceito', action.aceito).set('loadingAceito', false).set('successAceito', true).set('errorAceito', false);
};

const handlePostAceitoError = (state, action) => {
  return state.set('loadingAceito', false).set('successAceito', false).set('errorAceito', action.error);
};

const handlePostNewFeedback = (state, action) => {
  return state.set('loadingNewFeedback', true).set('successNewFeedback', false).set('errorNewFeedback', false);
};

const handlePostNewFeedbackSuccess = (state, action) => {
  return state.set('loadingNewFeedback', false).set('successNewFeedback', true).set('errorNewFeedback', false);
};

const handlePostNewFeedbackError = (state, action) => {
  return state.set('loadingNewFeedback', false).set('successNewFeedback', false).set('errorNewFeedback', action.error);
};

const handlePostNewAudiencia = (state, action) => {
  return state.set('loadingNewAudiencia', true).set('successNewAudiencia', false).set('errorNewAudiencia', false);
};

const handlePostNewAudienciaSuccess = (state, action) => {
  return state.set('loadingNewAudiencia', false).set('successNewAudiencia', true).set('errorNewAudiencia', false);
};

const handlePostNewAudienciaError = (state, action) => {
  return state.set('loadingNewAudiencia', false).set('successNewAudiencia', false).set('errorNewAudiencia', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.POST_LIDO:
      return handlePostLido(state, action);
    case Types.POST_LIDO_SUCCESS:
      return handlePostLidoSuccess(state, action);
    case Types.POST_LIDO_ERROR:
      return handlePostLidoError(state, action);

    case Types.POST_FAVORITO:
      return handlePostFavorito(state, action);
    case Types.POST_FAVORITO_SUCCESS:
      return handlePostFavoritoSuccess(state, action);
    case Types.POST_FAVORITO_ERROR:
      return handlePostFavoritoError(state, action);

    case Types.POST_ACEITO:
      return handlePostAceito(state, action);
    case Types.POST_ACEITO_SUCCESS:
      return handlePostAceitoSuccess(state, action);
    case Types.POST_ACEITO_ERROR:
      return handlePostAceitoError(state, action);

    case Types.POST_NEWFEEDBACK:
      return handlePostNewFeedback(state, action);
    case Types.POST_NEWFEEDBACK_SUCCESS:
      return handlePostNewFeedbackSuccess(state, action);
    case Types.POST_NEWFEEDBACK_ERROR:
      return handlePostNewFeedbackError(state, action);

    case Types.POST_NEWAUDIENCIA:
      return handlePostNewAudiencia(state, action);
    case Types.POST_NEWAUDIENCIA_SUCCESS:
      return handlePostNewAudienciaSuccess(state, action);
    case Types.POST_NEWAUDIENCIA_ERROR:
      return handlePostNewAudienciaError(state, action);

    case Types.POST_CURTIDO:
      return handlePostCurtido(state, action);
    case Types.POST_CURTIDO_SUCCESS:
      return handlePostCurtidoSuccess(state, action);
    case Types.POST_CURTIDO_ERROR:
      return handlePostCurtidoError(state, action);

    default:
      return state;
  }
}
