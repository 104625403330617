import React, { useEffect, useState } from 'react';
import { Column, Text, Button, Row, LoadingCircle, Icon, Modal, PopConfirm, Pagination } from '@components-teammove';
import { StepProgress } from './StepProgress';
import { ModalStyle, ValidationImportMessage } from './styles';
import { RelateColumns } from './RelateColumns';
import { ValidationTable } from './ValidationTable';
import { useDispatch, useSelector } from 'react-redux';
import { postSaveAllCompanies , putRelationship, putEditValueFromTableValidation, delCompanyImport } from '@ducks/companyImport';
import { useHistory } from 'react-router-dom';
import { Notification } from '@utils';

export const CompanyImportModals = ({ onClose }) => {
  const dispatch = useDispatch();
  const [ currentStep, setCurrentStep ] = useState(1);
  const history = useHistory();

  const loadingPutRelationship = useSelector(({ companyImport }) => companyImport.get('loadingPutRelationship'));
  const successPutRelationship = useSelector(({ companyImport }) => companyImport.get('successPutRelationship'));

  const uploadExcelResponse = useSelector(({ companyImport }) => companyImport.get('uploadExcelResponse'));

  const successUploadExcel = useSelector(({ companyImport }) => companyImport.get('successUploadExcel'));
  const errorUploadExcel = useSelector(({ companyImport }) => companyImport.get('errorUploadExcel'));

  const loadingSaveAllCompanies = useSelector(({ companyImport }) => companyImport.get('loadingSaveAllCompanies'));
  const successSaveAllCompanies = useSelector(({ companyImport }) => companyImport.get('successSaveAllCompanies'));
  const errorSaveAllCompanies = useSelector(({ companyImport }) => companyImport.get('errorSaveAllCompanies'));

  const loadingDelCompanyImport = useSelector(({ companyImport }) => companyImport.get('loadingDelCompanyImport'));
  const successDelCompanyImport = useSelector(({ companyImport }) => companyImport.get('successDelCompanyImport'));
  const errorDelCompanyImport = useSelector(({ companyImport }) => companyImport.get('errorDelCompanyImport'));
  
  const loadingEditValueFromTableValidation = useSelector(({ companyImport }) => companyImport.get('loadingEditValueFromTableValidation'));
  const successEditValueFromTableValidation = useSelector(({ companyImport }) => companyImport.get('successEditValueFromTableValidation'));

  const tableValidation = useSelector(({ companyImport }) => companyImport.get('tableValidation'));
  const successTableValidation = useSelector(({ companyImport }) => companyImport.get('successTableValidation'));

  const idTable = uploadExcelResponse?.importCompany?.importCompanyId;
  
  const [ relationshipData, setRelationshipData] = useState();
  const [ countError, setCountError] = useState(1);
  const [ visibleAttencionOnClose, setVisibleAttencionOnClose ] = useState(false);
  const [ objectRelationshipIsEmpty, setObjectRelationshipIsEmpty ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ hasChanges, setHasChanges ] = useState(false);
  const numberOfRowsPerPage = 25;

  useEffect(() => {
    if(successUploadExcel) {
      setCurrentStep(2);
      setRelationshipData(uploadExcelResponse.teamMoveColumns);
    }else if(errorUploadExcel) { 
      onClose();
    }
  }, [successUploadExcel, errorUploadExcel]);

  useEffect(() => {
    if(relationshipData) {
      const verifyEmptyObjects = relationshipData.some((objeto) => objeto.importCompanyColumnId === 0 || objeto.importCompanyColumnId === null);
      setObjectRelationshipIsEmpty(verifyEmptyObjects);
    }
  }, [relationshipData]);

  useEffect(() => {
    if(successPutRelationship) {
      setCurrentStep(currentStep + 1);
    }
  }, [successPutRelationship]);

  useEffect(() => {
    if(successEditValueFromTableValidation) {
      sessionStorage.setItem('VALIDATION', JSON.stringify([]));
    }
  }, [successEditValueFromTableValidation]);

  useEffect(() => {
    if(countError !== 0 && successEditValueFromTableValidation) {
      Notification.error('Suas alterações foram salvas mas ainda há linhas com erros');
    }
  }, [countError, successEditValueFromTableValidation]);

  const handleOk = () => { 
    if(currentStep === 3) {
      const objectToSend = JSON.parse(sessionStorage.getItem('VALIDATION')); 
      if(objectToSend.length > 0 || countError > 0) {
        dispatch(putEditValueFromTableValidation(objectToSend));
      }else if(objectToSend.length === 0 || countError === 0) {
        setCurrentStep(currentStep + 1);
        dispatch(postSaveAllCompanies(idTable));
      }

    }else if(currentStep === 4) {
      onClose();
    }else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOnClose = () => {
    setVisibleAttencionOnClose(true);
  };

  const handleAbortCompanyImport = () => {
    dispatch(delCompanyImport(idTable));
    history.go(-1);
    onClose();
  };

  useEffect(() => {
    if(errorDelCompanyImport) {
      dispatch(delCompanyImport(idTable));
    }
  }, [successDelCompanyImport]);

  useEffect(() => {
    const beforeUnloadHandler = (e) => {
      const confirmationMessage = 'Tem certeza de que deseja sair desta página?';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  useEffect(() => {
    const handleHistory = (e) => {
      e.preventDefault();
      window.history.pushState(null, null, window.location.href);
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener('popstate', handleHistory);

    return () => {
      window.removeEventListener('popstate', handleHistory);
    };
  }, []); 

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <ModalStyle
      currentStep={currentStep}
      visible
      onCancel={handleOnClose}
      width={1300}
      title={<StepProgress currentStep={currentStep}/>}
      footer={currentStep === 1  ? null :
        (currentStep === 4) ?
          (successSaveAllCompanies ? (
            <Row>
              <Button key={4} context='header' type="primary" fillWidth onClick={onClose}>Continuar</Button>
            </Row>
          ) : errorSaveAllCompanies ? (
            <Row>
              <Button key={1} context='header' type="secondary" fillWidth onClick={() => setCurrentStep(currentStep - 1)}>Voltar</Button>
            </Row>
            
          ) : null)
          :
          <Row width='100%' gap='20px' >
            <Button key={1} context='header' type="secondary" disabled={currentStep === 2} fillWidth onClick={() => setCurrentStep(currentStep - 1)}>Voltar</Button>
            
            {currentStep === 2 ? (
              objectRelationshipIsEmpty ? (
                <PopConfirm 
                  title= 'Deseja continuar com campos não relacionados?'
                  onConfirm={() => dispatch(putRelationship(relationshipData.filter((objeto) => objeto.importCompanyColumnId !== 0)))}
                >
                  <Button key={2} context='header' type="primary" loading={loadingPutRelationship} fillWidth >Avançar</Button>
                </PopConfirm>
              ) : (
                <Button key={2} onClick={()=>  dispatch(putRelationship(relationshipData.filter((objeto) => objeto.importCompanyColumnId !== 0)))} context='header' type="primary" loading={loadingPutRelationship} fillWidth >Avançar</Button>
              )
            ) : (
              <Column width='100%'>
                <Button key={2} context='header' type="primary" loading={(currentStep === 3 && loadingEditValueFromTableValidation)} fillWidth onClick={handleOk}>{currentStep === 3 ? countError === 0 && !hasChanges ? 'Importar' : 'Salvar / Validar'  : 'Avançar'}</Button>
                {countError === 0 && successEditValueFromTableValidation && (
                  <ValidationImportMessage><Text color='var(--success-color)'>Os dados estão prontos para a importação</Text></ValidationImportMessage>
                )}
              </Column>
              
            )}
          </Row>
      }

      okText='Avançar'
      cancelButtonProps={ currentStep === 2 && { disabled: true }}
      cancelText='Voltar'
    >
      
      {currentStep === 1 && (
        <Column width='100%' height='340px' align='center' justify='center' gap='16px'>
          <LoadingCircle loading radius={80} />
          <Text size='20px' weight='400'>Carregando planilha...</Text>
        </Column>
      )}
      {currentStep === 2 && (
        <RelateColumns 
          onChange={setRelationshipData}
          relationshipData={relationshipData}
        />
      )}
      {currentStep === 3 && (
        <> 
          <ValidationTable setHasChanges={setHasChanges} currentPage={currentPage} numberOfRowsPerPage={numberOfRowsPerPage} idTable={idTable} setCountError={setCountError}/>
          {(tableValidation?.totalImportedRows > 25 && tableValidation?.rows?.length > 0) && successTableValidation && (
            <Pagination
              showSizeChanger={false}
              onChange={handleChangePage} 
              currentPageNumber={currentPage} 
              defaultPageSize={numberOfRowsPerPage} 
              total={tableValidation?.totalImportedRows}
            />
          )} 

        </>
      )}

      {currentStep === 4 && (
        <Column width='100%' height='340px' align='center' justify='center' gap='16px'>
          {loadingSaveAllCompanies ? (
            <>
              <LoadingCircle loading radius={80} />
              <Text size='20px' weight='400'>Importando unidades...</Text>
            </>
          ) : successSaveAllCompanies ? (
            <>
              <Icon.MDI size='100px' type='check-circle-outline' color='var(--success-color)'/>
              <Text size='20px' weight='400'>Unidades importadas</Text>
            </>
          ) : errorSaveAllCompanies && 
            <>
              <Icon.MDI size='100px' type='close-circle-outline' color='var(--danger-color)'/>
              <Text size='20px' weight='400'>Não foi possível importar as unidades</Text>
            </>}
          
        </Column>
      )}
      {visibleAttencionOnClose && (
        <Modal
          visible
          onCancel={() => setVisibleAttencionOnClose(false)}
          title='Atenção: Você tem certeza que deseja sair?'
          footer={
            <Row>
              <Button key={1} context='header' type="secondary" fillWidth onClick={() => setVisibleAttencionOnClose(false)}>Cancelar</Button>
              <Button key={2} context='header' type="primary" loading={loadingDelCompanyImport} fillWidth onClick={handleAbortCompanyImport}>Sair</Button>
            </Row>
          }
        />
      )}

    </ModalStyle>

  );

};