import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { 
  getMetaAtividades as getMetaAtividadesSdk, 
  putMetaAtividades as putMetaAtividadesSdk, 
  putPerfisMeta as putPerfisMetaSdk,
  putTipoAtividadeMeta as putTipoAtividadeMetaSdk
} from '@sdk/MetaAtividade';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_META_ATIVIDADES: 'CONFIGURACOES_META_ATIVIDADES/GET_META_ATIVIDADES',
  GET_META_ATIVIDADES_SUCCESS: 'CONFIGURACOES_META_ATIVIDADES/GET_META_ATIVIDADES_SUCCESS',
  GET_META_ATIVIDADES_ERROR: 'CONFIGURACOES_META_ATIVIDADES/GET_META_ATIVIDADES_ERROR',

  PUT_META_ATIVIDADES: 'CONFIGURACOES_META_ATIVIDADES/PUT_META_ATIVIDADES',
  PUT_META_ATIVIDADES_SUCCESS: 'CONFIGURACOES_META_ATIVIDADES/PUT_META_ATIVIDADES_SUCCESS',
  PUT_META_ATIVIDADES_ERROR: 'CONFIGURACOES_META_ATIVIDADES/PUT_META_ATIVIDADES_ERROR',

  PUT_PERFIS_META: 'CONFIGURACOES_META_ATIVIDADES/PUT_PERFIS_META',
  PUT_PERFIS_META_SUCCESS: 'CONFIGURACOES_META_ATIVIDADES/PUT_PERFIS_META_SUCCESS',
  PUT_PERFIS_META_ERROR: 'CONFIGURACOES_META_ATIVIDADES/PUT_PERFIS_META_ERROR',

  PUT_TIPO_ATIVIDADE_META: 'CONFIGURACOES_META_ATIVIDADES/PUT_TIPO_ATIVIDADE_META',
  PUT_TIPO_ATIVIDADE_META_SUCCESS: 'CONFIGURACOES_META_ATIVIDADES/PUT_TIPO_ATIVIDADE_META_SUCCESS',
  PUT_TIPO_ATIVIDADE_META_ERROR: 'CONFIGURACOES_META_ATIVIDADES/PUT_TIPO_ATIVIDADE_META_ERROR',

  UPDATE_MODAL_CONFIRMATION: 'CONFIGURACOES_META_ATIVIDADES/UPDATE_MODAL_CONFIRMATION',

  RESET_VARIABLES_ADD: 'CONFIGURACOES_META_ATIVIDADES/RESET_VARIABLES_ADD',
  RESET: 'CONFIGURACOES_META_ATIVIDADES/RESET',
};

//Action Creators
export const getMetaAtividades = (date) => ({ type: Types.GET_META_ATIVIDADES, date });
export const getMetaAtividadesSuccess = (metaAtividades) => ({ type: Types.GET_META_ATIVIDADES_SUCCESS, metaAtividades });
export const getMetaAtividadesError = (error) => ({ type: Types.GET_META_ATIVIDADES_ERROR, error });

export const putMetaAtividades = (metaAtividade) => ({ type: Types.PUT_META_ATIVIDADES, metaAtividade });
export const putMetaAtividadesSuccess = (resp) => ({ type: Types.PUT_META_ATIVIDADES_SUCCESS, resp });
export const putMetaAtividadesError = (error) => ({ type: Types.PUT_META_ATIVIDADES_ERROR, error });

export const putPerfisMeta = (perfis) => ({ type: Types.PUT_PERFIS_META, perfis });
export const putPerfisMetaSuccess = (resp) => ({ type: Types.PUT_PERFIS_META_SUCCESS, resp });
export const putPerfisMetaError = (error) => ({ type: Types.PUT_PERFIS_META_ERROR, error });

export const putTipoAtividadeMeta = (tipoAtividades) => ({ type: Types.PUT_TIPO_ATIVIDADE_META, tipoAtividades });
export const putTipoAtividadeMetaSuccess = (resp) => ({ type: Types.PUT_TIPO_ATIVIDADE_META_SUCCESS, resp });
export const putTipoAtividadeMetaError = (error) => ({ type: Types.PUT_TIPO_ATIVIDADE_META_ERROR, error });

export const updateModalConfirmation = (modalConfirmation) => ({ type: Types.UPDATE_MODAL_CONFIRMATION, modalConfirmation });

export const resetVariablesAdd = () => ({ type: Types.RESET_VARIABLES_ADD });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetMetaAtividades(action) {
  try {
    const metaAtividades = yield call(getMetaAtividadesSdk, action.date);
    yield put(getMetaAtividadesSuccess(metaAtividades));
  } catch (err) {
    Notification.error(err.message);
    yield put(getMetaAtividadesError(err));
  }
}

function* fetchPutMetaAtividades(action) {
  try {
    const resp = yield call(putMetaAtividadesSdk, action.metaAtividade);
    yield put(putMetaAtividadesSuccess(resp));
    Notification.success('Meta de atividade atualizada com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(putMetaAtividadesError(err));
  }
}

function* fetchPutPerfisMeta(action) {
  try {
    const resp = yield call(putPerfisMetaSdk, action.perfis);
    yield put(putPerfisMetaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(putPerfisMetaError(err));
  }
}

function* fetchPutTipoAtividadesMeta(action) {
  try {
    const resp = yield call(putTipoAtividadeMetaSdk, action.tipoAtividades);
    yield put(putTipoAtividadeMetaSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(putTipoAtividadeMetaError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_META_ATIVIDADES, fetchGetMetaAtividades),
  takeLatest(Types.PUT_META_ATIVIDADES, fetchPutMetaAtividades),
  takeLatest(Types.PUT_PERFIS_META, fetchPutPerfisMeta),
  takeLatest(Types.PUT_TIPO_ATIVIDADE_META, fetchPutTipoAtividadesMeta),
];

// Reducer
const initialState = Map({
  metaAtividades: {},
  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,

  loadingSaveTipoAtividade: false,
  successSaveTipoAtividade: false,
  errorSaveTipoAtividade: false,

  loadingSavePerfil: false,
  successSavePerfil: false,
  errorSavePerfil: false,

  updateModalConfirmation: false,
});

const handleGetMetaAtividades = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};
  
const handleGetMetaAtividadesSuccess = (state, action) => {
  return state
    .set('metaAtividades', action.metaAtividades)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};
  
const handleGetMetaAtividadesError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handlePutMetaAtividades = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handlePutMetaAtividadesSuccess = (state, action) => {
  return state
    .set('metaAtividades', action.resp)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};
  
const handlePutMetaAtividadesError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handlePutPerfisMeta = (state, action) => {
  return state
    .set('loadingSavePerfil', true)
    .set('successSavePerfil', false)
    .set('errorSavePerfil', false);
};
  
const handlePutPerfisMetaSuccess = (state, action) => {
  return state
    .set('loadingSavePerfil', false)
    .set('successSavePerfil', true)
    .set('errorSavePerfil', false);
};
  
const handlePutPerfisMetaError = (state, action) => {
  return state
    .set('loadingSavePerfil', false)
    .set('successSavePerfil', false)
    .set('errorSavePerfil', action.error);
};

const handlePutTipoAtividadeMeta = (state, action) => {
  return state
    .set('loadingSaveTipoAtividade', true)
    .set('successSaveTipoAtividade', false)
    .set('errorSaveTipoAtividade', false);
};

const handlePutTipoAtividadeMetaSuccess = (state, action) => {
  return state
    .set('loadingSaveTipoAtividade', false)
    .set('successSaveTipoAtividade', true)
    .set('errorSaveTipoAtividade', false);
};

const handlePutTipoAtividadeMetaError = (state, action) => {
  return state
    .set('loadingSaveTipoAtividade', false)
    .set('successSaveTipoAtividade', false)
    .set('errorSaveTipoAtividade', action.error);
};

const handleModalConfirmation = (state, action) => {
  return state
    .set('updateModalConfirmation', action.modalConfirmation);
};

const handleResetVariablesAdd = (state, action) => {
  return state
    .set('loadingSaveTipoAtividade', false)
    .set('successSaveTipoAtividade', false)
    .set('errorSaveTipoAtividade', false)
    .set('loadingSavePerfil', false)
    .set('successSavePerfil', false)
    .set('errorSavePerfil', false);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_META_ATIVIDADES: return handleGetMetaAtividades(state, action);
    case Types.GET_META_ATIVIDADES_SUCCESS: return handleGetMetaAtividadesSuccess(state, action);
    case Types.GET_META_ATIVIDADES_ERROR: return handleGetMetaAtividadesError(state, action);

    case Types.PUT_META_ATIVIDADES: return handlePutMetaAtividades(state, action);
    case Types.PUT_META_ATIVIDADES_SUCCESS: return handlePutMetaAtividadesSuccess(state, action);
    case Types.PUT_META_ATIVIDADES_ERROR: return handlePutMetaAtividadesError(state, action);

    case Types.PUT_PERFIS_META: return handlePutPerfisMeta(state, action);
    case Types.PUT_PERFIS_META_SUCCESS: return handlePutPerfisMetaSuccess(state, action);
    case Types.PUT_PERFIS_META_ERROR: return handlePutPerfisMetaError(state, action);

    case Types.PUT_TIPO_ATIVIDADE_META: return handlePutTipoAtividadeMeta(state, action);
    case Types.PUT_TIPO_ATIVIDADE_META_SUCCESS: return handlePutTipoAtividadeMetaSuccess(state, action);
    case Types.PUT_TIPO_ATIVIDADE_META_ERROR: return handlePutTipoAtividadeMetaError(state, action);

    case Types.UPDATE_MODAL_CONFIRMATION: return handleModalConfirmation(state, action);

    case Types.RESET_VARIABLES_ADD: return handleResetVariablesAdd(state, action);
    case Types.RESET: return handleReset(state, action);
  
    default: return state;
  }
}