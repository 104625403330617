import React from 'react';
import { Icon } from '@components-teammove';
import { formatDate, formatTime } from '@utils';

export const getSteps = (detailedOrder) => {
  return (detailedOrder.status === 'ENTREGUE' ?
    detailedOrder.statusChanges : [...detailedOrder.statusChanges, { date: detailedOrder.dueDate }]
  ).map((statusChange) => {
    const step = {
      message: '',
      date: `${formatDate(statusChange.date)} às ${formatTime(statusChange.date)}`,
    };
    
    switch (statusChange.newStatus) {
      case 'ABERTO':
        step.message = `Aberto por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-send'/>;
        break;
      case 'EM_ANDAMENTO':
        step.message = `Colocado em andamento por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-settings'/>;
        break;
      case 'FATURADO':
        step.message = `Faturado por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-dollar-sign'/>;
        break;
      case 'ENVIADO':
        step.message = `Enviado por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-truck'/>;
        break;
      case 'ENTREGUE':
        step.message = `Entregue por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-check'/>;
        break;
      case 'CANCELADO':
        step.message = `Cancelado por ${statusChange.userName}`;
        step.icon = <Icon.Feather type='fi-x'/>;
        break;
      default:
        step.message = 'Prazo para entrega:';
        step.icon = <Icon.Feather type='fi-clock'/>;
        step.state = 'warning';
    }
  
    return {
      label: `${step.message} em ${step.date}`,
      icon: step.icon,
      state: step.state
    };
  });
};