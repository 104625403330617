import styled from 'styled-components';
import { Text } from '@components-teammove';

export const LateText = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};

    strong {
      font-weight: 700;
    }
  }

  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}

`;

export const TextPrazo = styled(Text)`
  ${({ cancelado }) => cancelado && 'text-decoration-line: line-through;'}
`;