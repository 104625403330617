import styled from 'styled-components';
import { Upload, View, Table, Icon } from '@components-teammove';
import { Text } from '@components';

export const UploadEdge = styled(Upload)`
  border: 2px dashed ${(props) => props.theme.darkHighlightColor};
  height: 180px;
  border-radius:12px;
  background-color: ${(props) => props.theme.darkPrimaryColor};
  .ant-upload {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ComponentEmptyCover = styled(View)`
  width: 100%;
  height: 180px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;

export const IconLoading = styled(Icon.LoadingOutlined)`
 * {
  color: ${({ theme }) => theme.actionColor};
  font-size: 25px;
 }
`;

export const UploadIcon = styled(Icon.Feather)`
  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
  }
`;

export const TableStyle = styled(Table)`
  &.ant-table {
    .ant-table-content {
      thead {
        > tr {
          > th {
            :hover {
              background-color: ${({ theme })=> theme.darkPrimaryColor} !important;
            }
          }
        }
      }
      tbody {
        > tr {
          :hover {
            td {
              background-color: ${(props) => props.theme.darkSecondaryColor};
            }
          }
        }
      }
    }
  }
`;