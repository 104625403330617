import { del, get, post, put } from '../api';

export const getTiposUnidades = () => {
  return get('/api/companies/types');
};

export const postTipoUnidade = (tipoUnidade) => {
  return post('/api/companies/types', tipoUnidade);
};

export const updateTipoUnidade = (tipoUnidade) => {
  return put(`/api/companies/types/${tipoUnidade.id}`, tipoUnidade);
};

export const deleteTipoUnidade = (id) => {
  return del(`/api/companies/types/${id}`);
};

export const getTipoUnidade = (id) => {
  return get(`/api/companies/types/${id}`);
};