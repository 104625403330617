import styled from 'styled-components';

export const Label = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  font-family: 'Roboto', sans-serif;
`;

export const MyIntegrationCard = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 20px;
  border-radius: 16px;
  min-height: 133px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.darkPrimaryColor};
  }
`;

export const CardTitle = styled(Label)`
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
`;

export const IntegrationDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;
  color: ${({ theme }) => theme.textColor};
`;

export const ContainerNoPermission = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
