import React from 'react';
import { StylesMultiDatePicker } from './styles';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

const locale = {
  name:'gregorian_pt',
  months:[
    ['Janeiro','Jan'],
    ['Fevereiro','Fev'],
    ['Março','Mar'],
    ['Abril','Abr'],
    ['Maio','Mai'],
    ['Junho','Jun'],
    ['Julho','Jul'],
    ['Agosto','Ago'],
    ['Setembro','Set'],
    ['Outubro','Out'],
    ['Novembro','Nov'],
    ['Dezembro','Dez']
  ],
  weekDays:[['Sábado','Sáb'],['Domingo','Dom'],['Segunda-feira','Seg'],['Terça-feira','Ter'],['Quarta-feira','Qua'],['Quinta-feira','Qui'],['Sexta-feira','Sex']],
  digits:['0','1','2','3','4','5','6','7','8','9'],
  meridiems:[['AM','am'],['PM','pm']]
};

function MultiDatePickerComponent({ ...props }) {
  return(
    <DatePicker
      multiple
      plugins={[
        <DatePanel key='date-panel'/>
      ]}
      locale={locale}
      format='DD/MM/YYYY'
      {...props}
    />
  );
}

const MultiDatePicker = ({ ...props }) => (
  <>
    <StylesMultiDatePicker>
      <MultiDatePickerComponent {...props}/>
    </StylesMultiDatePicker>
  </>
);

export default MultiDatePicker;