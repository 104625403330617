import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import App from '@app';
import { setTheme } from '@ducks/app';
import { Icon, Body, PageHeader, Upload, Switch, Button, Avatar, Form, Input, InputMask, DatePicker, Grid, View, Tooltip, Skeleton } from '@components-teammove';

import { Notification, InfoUsuario } from '@utils';
import { getPerfil, updatePerfil, reset } from '@ducks/perfil';
import { authorization } from '@sdk/api';
import { AvatarItem, HelpText, EditBlock, ButtonContainer, InputContainer } from './style';

const { REACT_APP_API } = window.env;

const beforeUpload = (file) => {
  const isJpgOrPng = (file.type === 'image/jpeg') || (file.type === 'image/png');
  if (!isJpgOrPng) {
    Notification.error('Extensões não permitida!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    Notification.error('Excedido tamanho máximo permitido!');
  }
  return isJpgOrPng && isLt2M;
};

export default function Perfil({ history }) {
  const [form] = Form.useForm();
  const usuario = InfoUsuario.get();
  const location = useLocation();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(location.search);
  const hasParamsInUrl = urlParams.size > 0;
  const id = urlParams.get('id');
  const urlHeaders = {
    token: urlParams.get('clienteToken'),
    key: urlParams.get('clienteKey'),
  };

  const theme = useSelector(({ app }) => app.get('theme'));

  const perfil = useSelector(({ perfil }) => perfil.get('perfil'));
  const loading = useSelector(({ perfil }) => perfil.get('loading'));
  const success = useSelector(({ perfil }) => perfil.get('success'));
  const loadingSave = useSelector(({ perfil }) => perfil.get('loadingSave'));
  const successSave = useSelector(({ perfil }) => perfil.get('successSave'));

  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [visibleFields, setVisibleFields] = useState(false);
  const [idUsuario, setIdUsuario] = useState(usuario?.cdUsuario);

  useEffect(() => {
    if (hasParamsInUrl && id) {
      setIdUsuario(parseInt(id));
    }
    dispatch(getPerfil(id ? parseInt(id) : idUsuario, hasParamsInUrl ? urlHeaders : {}));
    return () => dispatch(reset());
  }, []);

  useEffect(() => { 
    if (hasParamsInUrl && id) {
      setVisibleFields(true);
    }

  }, [location.search]);

  useEffect(() => {
    if (success) {
      form.setFieldsValue(perfil);
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      if(hasParamsInUrl) {
        dispatch(setTheme(InfoUsuario.get('theme')));
        history.replace('/');
      } else {
        history.goBack();
      }
    }
  }, [history, successSave]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      dispatch(updatePerfil(idUsuario, values, hasParamsInUrl ? urlHeaders : {}));
    });
  };

  const handleChangeAvatar = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        return setLoadingAvatar(true);
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        return setLoadingAvatar(false);
      }
      case 'done': {
        form.setFieldsValue({
          avatar: file.response,
        });
        return setLoadingAvatar(false);
      }
      default: {
        return setLoadingAvatar(false);
      }
    }
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body >
        <PageHeader
          title="Editar Perfil"
          extra={[
            <Button key="1" size='small' type='secondary' onClick={() => history.goBack()}>Cancelar</Button>,
            <Button key="2" size='small' type='primary' onClick={handleSalvar} loading={loadingSave}>Salvar</Button>,
          ]}
        >
          <Form layout="vertical" form={form} >
              
            <EditBlock>
              {loading ? (
                <Skeleton.Circle width='124px' height='124px'/>
              ) : (
                <AvatarItem
                  valuePropName="src"
                  shouldUpdate={(prev, current) => prev.avatar !== current.avatar}
                >
                  {({ getFieldValue }) => (
                    <Upload
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                      folder="Users"
                      onChange={handleChangeAvatar}
                      URI={REACT_APP_API}
                      headers={authorization()}
                    >
                      <Tooltip title="Editar foto">
                        <Avatar
                          size={124}
                          src={(!loadingAvatar) ? getFieldValue('avatar') : null}
                          icon={(loadingAvatar) ? <Icon.LoadingOutlined /> : null}
                        />
                      </Tooltip>
                    </Upload>
                  )}
                </AvatarItem>
              )}
              <ButtonContainer>
                <Upload
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  folder="Users"
                  onChange={handleChangeAvatar}
                  URI={REACT_APP_API}
                  headers={authorization()}
                >
                  <Button key="3" size='small' type='secondary' icon={<Icon.Feather type='upload'/>} onClick={()=>handleChangeAvatar}>Editar Foto</Button>
                </Upload>
                <View>
                  <HelpText>Tamanho máximo permitido: 2mb.</HelpText>
                  <HelpText>Extensões permitidas: .jpg ou .png.</HelpText>
                </View>
              </ButtonContainer>

            </EditBlock>
              
            <Grid numberOfColumns={2} gap='12px'>
              
              <InputContainer label="Nome" name="nome" rules={[{ required: true, message: 'Informe o nome!' }]}>
                {loading ? (
                  <Skeleton.SearchBar/>
                ) : (
                  <Input />
                )}
              </InputContainer>
              <InputContainer label="Sobrenome" name="sobrenome">
                {loading ? (
                  <Skeleton.SearchBar/>
                ) : (
                  <Input />
                )}
              </InputContainer>
              <InputContainer label="Email" name="email">
                {loading ? (
                  <Skeleton.SearchBar/>
                ) : (
                  <Input />
                )}
              </InputContainer>
              <InputContainer label="Telefone" name="telefone">
                {loading ? (
                  <Skeleton.SearchBar/>
                ) : (
                  <InputMask mask="(99) 9 9999-9999" placeholder='(99) 9 9999-9999'/>
                )}
              </InputContainer>
              <InputContainer label="Data de nascimento" name="dtNascimento">
                {loading ? (
                  <Skeleton.SearchBar/>
                ) : (
                  <DatePicker format="DD/MM/YYYY" />
                )}
              </InputContainer>
              <View/>
              {visibleFields && (
                <>
                  <InputContainer
                    rules={[
                      { required: true, message: 'Informe a sua senha!' },
                    ]}
                    label="Senha" 
                    name="senha"
                  >
                    {loading ? (
                      <Skeleton.SearchBar/>
                    ) : (
                      <Input.Password />
                    )}
                  </InputContainer>
                  <InputContainer 
                    label="Confirmar senha" 
                    name="senhaConfirmacao"
                    rules={[
                      { required: true, message: 'Informe a sua senha!' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('senha') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('As senhas não coincidem');
                        },
                      }),
                    ]}
                  >
                    {loading ? (
                      <Skeleton.SearchBar/>
                    ) : (
                      <Input.Password />
                    )}
                  </InputContainer>
                </>
              )}
            </Grid>

            <InputContainer extraMarginTop  label="Receber atividades por e-mail" name="novidades" valuePropName="checked">
              {loading ? (
                <Skeleton.MinorLine/>
              ) : (
                <Switch />
              )}
            </InputContainer>
              
          </Form>
        </PageHeader>
      </Body>
    </App>
  );
}
