import { get, post } from '../../../api';
import { InfoUsuario } from '@utils';

export const getAllTrails = () => {
  return get('/api/wiki/ead/trilhas/cadastro');
};

export const getTrail = (idTrail) => {
  return get(`/api/wiki/ead/trilha/cadastro/${idTrail}`);
};

export const saveAllTrails = (trails) => {
  return post('/api/wiki/ead/trilhas/cadastro', trails);
};

export const saveTrail = (trail) => {
  return post('/api/wiki/ead/trilha/cadastro', trail);
};

export const getAllCoursesToOptions = () => {
  return get(`/api/wiki/ead/cursos?idUsuario=${InfoUsuario.get('cdUsuario')}&cadastro=true`);
};