import React from 'react';
import Titulo from './Titulo';
import { IconFeather } from './styles';

export const formatCategory = (item) => {
  const categories = item.map((item) => ({
    title: <Titulo {...item} />,
    selectable: false,
    key: String(item.id),
    dragOver: item.active,
    children: item.families.map((subItem) => ({
      title: <Titulo {...subItem} />,
      selectable: false,
      key: String(item.id) + String(subItem.id),
      icon: (<IconFeather type='file' active={subItem.active} />),
      dragOver: subItem.active
    })),
    icon: (<IconFeather type='folder' active={item.active} />),
  }));

  return categories;
};
