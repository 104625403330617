import React from 'react';
import App from '@app';
import { Result, Body } from '@components';

export default function NotFound() {
  return(
    <App>
      <Body>
        <Result
          status="404"
          title="Página não encontrada."
        />
      </Body>
    </App>
  );
}
