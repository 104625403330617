import React from 'react';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

const ButtonStyled = styled(Button)`
  ${(props) =>
  props.type === 'success' &&
    css`
      background-color: #00c851 !important;
      border-color: #00c851 !important;
      color: ${(props) => (props.ghost ? '#00C851' : '#fff')} !important;
      &:hover {
        background-color: #00e05a !important;
        border-color: #00e05a !important;
      }
    `}
  ${(props) =>
      props.type === 'warning' &&
    css`
      background-color: #ffbb33 !important;
      border-color: #ffbb33 !important;
      color: ${(props) => (props.ghost ? '#ffbb33' : '#fff')} !important;
      &:hover {
        background-color: #ffc34d !important;
        border-color: #ffc34d !important;
      }
    `}
  ${(props) =>
      props.type === 'icon' &&
    css`
      opacity: 0.54;
      border-radius: 8px;
      padding: 5px 3px;
      color: black !important;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      border: none !important;
      background-color: transparent !important;
      &:hover {
        opacity: 0.7;
        background-color: #f2f2f2 !important;
      }

      > span {
        margin-left: 5px;
      }
    `}
  ${(props) =>
      props.opacity &&
    css`
      opacity: ${props.opacity};
    `}
`;

const ButtonComponent = React.forwardRef(({ children, visible, ...otherProps }, ref) => {
  if (!visible) return null;

  return (
    <ButtonStyled {...otherProps} ref={ref}>
      {children}
    </ButtonStyled>
  );
});

ButtonComponent.defaultProps = {
  visible: true,
};

ButtonComponent.Group = Button.Group;
export default ButtonComponent;
