import styled from 'styled-components';
import { Icon } from '@components-teammove';

export const WarningText = styled.span`
  display: flex;
  align-items: center;
  gap: 9.5px;
  padding: 16px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.textColor};
  margin-bottom: 20px;

  .danger-color {
    color: var(--danger-color);
  }
`;

export const WarningIcon = styled(Icon.WarningOutlined)`
  &.anticon {
    font-size: 16px;
  }
`;