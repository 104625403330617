import styled from 'styled-components';
import { Image, Icon } from '@components-teammove';

export const ProductItem = styled.div`
  border-radius: 12px;
  ${({ selected, theme }) => selected && `
    border: 2px solid ${theme.actionColor};
  `}
  padding: 2px 35px 2px ${({ hasImage }) => hasImage ? '2px' : '35px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  min-height: 85px;
`;

export const ProductImageWrapper = styled.div`
  border-radius: 10px;
  width: 5.0625rem;
  height: 5.0625rem;
`;

export const ProductImage = styled(Image)`
  
`;

export const ProductName = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const CheckIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};
`;