import React, { useEffect, useState } from 'react';
import { Row, Column, Text, Animations } from '@components-teammove';
import { ButtonProgress, ColumnContainerText, Description, IconActivity, IconContainer, RowContainer, Title, ViewAnimation } from './styles';
import animationData from '@assets/TeamMove_Alvo.json';
import { InfoUsuario } from '@utils';
import { useHistory } from 'react-router-dom';

const VIEW_MODE_KEY = 'activities_view_mode';

function Semanal({ metas }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [animationPosition, setAnimationPosition] = useState(0);

  useEffect(() => {
    let timeoutId = 0;
    let intervalId = 0;

    if (loading) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(() => {
          setAnimationPosition((prev) => (prev < 35 ? prev + 0.8 : prev));
        }, 30);
      }, 300);
    }

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [loading]);

  const handleVerMais = () => {
    localStorage.setItem(VIEW_MODE_KEY, 'Meta de atividades');
    history.push('/atividades/agenda', { viewModeFromNotification: 'SEMANAL' });
  };

  const totalQuantidadeAtividadesFinalizadas = metas.reachedActivitiesKinds.reduce((sum, reachedActivityKind) => {
    return sum + reachedActivityKind.numberOfFinishedActivities;
  }, 0);

  return (
    <Row width='100%' gap='10px'>
      <ViewAnimation
        loading={loading}
        animationPosition={animationPosition}
        reachFinalGoal={metas.reachedAllActivitiesKindsGoals}
        numberOfActivities={metas.reachedActivitiesKinds.length}
      >
        <Animations
          src={animationData}
          keepLastFrame
          onEvent={(event) => {
            if(event === 'complete') {
              setLoading(false);
            }
          }}
        />
      </ViewAnimation>
      {!loading && (
        <ColumnContainerText loading={loading} gap='12px'>
          <Column>
            <Title>Parabéns {InfoUsuario.get('nmUsuario')}</Title>
            <Description descriptionTitle>Você bateu sua meta da semana de {totalQuantidadeAtividadesFinalizadas} atividade{totalQuantidadeAtividadesFinalizadas > 1 ? 's' : ''}</Description>
            {metas.reachedActivitiesKinds.map((reachedActivityKind) => (
              <RowContainer gap='8px' key={reachedActivityKind.id}>
                <IconContainer colorActivity={reachedActivityKind.color}>
                  <IconActivity type={reachedActivityKind.icon}/>
                </IconContainer>
                <Row gap='8px'>
                  <Description typeActivity>{reachedActivityKind.numberOfFinishedActivities}/{reachedActivityKind.numberOfActivitiesGoal.toFixed(2)}</Description>
                  <Description activityName>{reachedActivityKind.name}</Description>
                </Row>
              </RowContainer>
            ))}       
          </Column>
          <ButtonProgress onClick={handleVerMais}>
            <Text>
                  Veja mais
            </Text>
          </ButtonProgress>
        </ColumnContainerText>
      )}
    </Row>
  );
}

export default Semanal;