import React from 'react';
import { StyledTag } from './styles';

const Tag = ({ closable, children, onClose, ...props }) => (
  <StyledTag {...props}>
    {children}
    {closable && <span className='close-icon' onClick={onClose} onKeyPress={() => ''} role='button' tabIndex={0}>X</span>}
  </StyledTag>
);

export default Tag;