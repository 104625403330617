import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';
import { getAtributosUsuario } from '@sdk/Usuarios';
import { generatePreview, salvarPersonalizacao } from '@sdk/Wikis/Personalizacao';
import { Spin, Modal, View } from '@components';
import { Notification } from '@utils';
import { getDadosPersonalizacao } from './utils';
import Aprovacao from './Aprovacao';
import Compartilhar from './Compartilhar';
import Parametros from './Parametros';

const ModalPersonalization = styled(Modal)`
  min-width: 700px;
`;

export default function VisualizacaoPersonalizacao({ conteudo, onClose }) {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [dadosPersonalizacao, setDadosPersonalizacao] = useState();
  const [parametros, setParametros] = useState(getParametrosImagem(conteudo.get('parametrosImagem')));
  const [step, setStep] = useState(0);
  const [paramsReadOnly, setParamsReadOnly] = useState(false);
  const [specialPrice, setSpecialPrice] = useState(false);

  const listProduct = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('listaProdutos'));
  const tipo = conteudo.get('tipo');

  useEffect(() => {
    getAtributosUsuario()
      .then((atributos) => {
        //Replace params by user atribute
        if (parametros && parametros.textos && parametros.textos.length > 0 && parametros.textos.some((texto) => texto.atributo)) {
          for (let index = 0; index < parametros.textos.length; index++) {
            const item = parametros.textos[index];
            if (item.atributo) {
              const atributo = atributos.find((atbr) => atbr.idAtributo === item.atributo);

              if (atributo && atributo.valor) {
                item.texto = atributo.valor;
              }
            }
          }
        }
        //Replace params by list product
        if (parametros && parametros.textos && parametros.textos.length > 0 && parametros.textos.some((item) => item.type === 'LP' && item.productList > 0 && item.productItemList > 0)) {
          for (let index = 0; index < parametros.textos.length; index++) {
            const item = parametros.textos[index];
            if (item.type === 'LP' && item.productList > 0 && item.productItemList > 0) {
              const product = listProduct.find((product) => product.id === item.productList);
              if (product) {
                const itemProduct = product.itens.find((itemProduct) => itemProduct.id === item.productItemList);
                if (!itemProduct?.itemUsuario?.precoEspecial) {   
                  item.hasImage = false;   
                  switch (item?.productItemListFormat) {
                    case 'FR': {
                      let itemString = itemProduct.itemUsuario.valor.toString(); 
                      if(itemString.lastIndexOf('.') > 0) {
                        item.texto = itemString.substring(0, itemString.lastIndexOf('.'));
                      }
                      else {
                        item.texto = itemString;
                      }
                                   
                      break;
                    }
                    case 'FC': {
                      let itemString = itemProduct.itemUsuario.valor.toString();
                      if(itemString.lastIndexOf('.') > 0) {
                        itemString = itemString.substring(itemString.lastIndexOf('.') + 1 , itemString.length);
                        if(itemString.length === 1) {
                          itemString += '0';
                        }
                        item.texto = itemString;
                      } else {
                        item.texto = '00';
                      }
                      
                      break;
                    }
                    default: {
                      let itemString = itemProduct.itemUsuario.valor.toString();
                      if(itemString.lastIndexOf('.') < 0) {
                        item.texto = itemString + ',00';
                      } else {
                        if(itemString.substring(itemString.lastIndexOf('.') + 1, itemString.length).length === 1) { 
                          itemString += '0';
                        }
                        itemString = itemString.replace('.', ',');
                        item.texto = itemString;
                      }
                      break;
                    }
                  }
                }

                else {
                  if(tipo === 'PDF' || tipo === 'IMAGEM') {
                    setSpecialPrice(true);
                  }
                  item.texto = ' ';
                }

              }
            }
          }
        }
        if (parametros && parametros.textos) {
          const existsEditable = parametros.textos.find((item) => item.editable);
          if (!existsEditable) {
            setParamsReadOnly(true);
            criarPersonalizacao();
            return;
          }
        }

        setStep(1);
        setLoading(false);
      })
      .catch((err) => {
        Notification.error('Não foi possível carregar a personalização');
        setLoading(false);
      });
  }, []);

  function getParametrosImagem(parametros) {
    try {
      if (!parametros) throw new Error();
      const parametrosObject = JSON.parse(parametros);
      return {
        original: parametrosObject.original,
        textos: parametrosObject.textos.map((item, index) => ({
          ...item,
          texto: item.texto || ' ',
          order: item.order || index,
          type: item.type || 'SI',
          productList: item.productList || null,
          productItemList: item.productItemList || null,
          productItemListFormat: item.productItemListFormat || null
        }))
      };
    } catch (err) {
      return {
        textos: [],
      };
    }
  }

  function criarPersonalizacao() {
    setLoading(true);
    generatePreview(parametros.original, parametros.textos, true, specialPrice)
      .then((file) => {
        const dados = getDadosPersonalizacao(
          {},
          {
            dtCriacao: new Date(),
            idCategoria: conteudo.get('id'),
            link: file.outputPath || file.original,
          }
        );
        setDadosPersonalizacao(dados);
        salvarPersonalizacao(dados).catch((err) => Notification.warning('Não foi possível salvar o histórico'));

        setStep(2);
        setFile(file);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleTextChange(index, texto) {
    setParametros({
      ...parametros,
      textos: OrderedMap(parametros.textos.map((item, index) => [index, item]))
        .update(index, (item) => ({
          ...item,
          texto,
        }))
        .toList()
        .toArray(),

    });
  }

  function handleNextParams() {
    if (!paramsReadOnly) {
      criarPersonalizacao();
      return;
    }

    setStep(2);
  }

  return (
    <ModalPersonalization title='Personalização' visible onOk={onClose} onCancel={onClose} footer={null}>
      <View alignItems='center'>
        {loading && <Spin />}

        {step === 1 && parametros && !loading ? (
          <Parametros parametros={parametros.textos} onChangeText={handleTextChange} next={handleNextParams} previous={onClose} />
        ) : null}

        {step === 2 && file && !loading ? (
          <Aprovacao
            conteudo={conteudo}
            file={file}
            dados={dadosPersonalizacao}
            next={() => setStep(3)}
            previous={() => (paramsReadOnly ? onClose() : setStep(1))}
          />
        ) : null}

        {step === 3 && file && !loading ? <Compartilhar conteudo={conteudo} file={file} onClose={onClose} /> : null}
      </View>
    </ModalPersonalization>
  );
}