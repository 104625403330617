import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { 
  getChamado as getChamadoSdk,
  saveQuestionarioReply as saveQuestionarioReplySdk,
} from '@sdk/Chamados';
import { updateChamado } from './chamadosTickets';

//Action Types
export const Types = {
  GET_TICKET: 'CHAMADOS_BRIEFING_REPLY/GET_TICKET',
  GET_TICKET_SUCCESS: 'CHAMADOS_BRIEFING_REPLY/GET_TICKET_SUCCESS',
  GET_TICKET_ERROR: 'CHAMADOS_BRIEFING_REPLY/GET_TICKET_ERROR',

  SAVE_BRIEFING_REPLY: 'CHAMADOS_BRIEFING_REPLY/SAVE_BRIEFING_REPLY',
  SAVE_BRIEFING_REPLY_SUCCESS: 'CHAMADOS_BRIEFING_REPLY/SAVE_BRIEFING_REPLY_SUCCESS',
  SAVE_BRIEFING_REPLY_ERROR: 'CHAMADOS_BRIEFING_REPLY/SAVE_BRIEFING_REPLY_ERROR',

  FINISH_BRIEFING_REPLY: 'CHAMADOS_BRIEFING_REPLY/FINISH_BRIEFING_REPLY',
  FINISH_BRIEFING_REPLY_SUCCESS: 'CHAMADOS_BRIEFING_REPLY/FINISH_BRIEFING_REPLY_SUCCESS',
  FINISH_BRIEFING_REPLY_ERROR: 'CHAMADOS_BRIEFING_REPLY/FINISH_BRIEFING_REPLY_ERROR',

  SAVE_REPORT_REPLY: 'CHAMADOS_BRIEFING_REPLY/SAVE_REPORT_REPLY',
  SAVE_REPORT_REPLY_SUCCESS: 'CHAMADOS_BRIEFING_REPLY/SAVE_REPORT_REPLY_SUCCESS',
  SAVE_REPORT_REPLY_ERROR: 'CHAMADOS_BRIEFING_REPLY/SAVE_REPORT_REPLY_ERROR',

  FINISH_REPORT_REPLY: 'CHAMADOS_BRIEFING_REPLY/FINISH_REPORT_REPLY',
  FINISH_REPORT_REPLY_SUCCESS: 'CHAMADOS_BRIEFING_REPLY/FINISH_REPORT_REPLY_SUCCESS',
  FINISH_REPORT_REPLY_ERROR: 'CHAMADOS_BRIEFING_REPLY/FINISH_REPORT_REPLY_ERROR',

  RESET: 'CHAMADOS_BRIEFING_REPLY/RESET',

  RESET_SUCCESS_BRIEFING: 'CHAMADOS_BRIEFING_REPLY/RESET_SUCCESS_BRIEFING',
};

//Action Creators
export const getTicket = (id) => ({ type: Types.GET_TICKET, id });
export const getTicketSuccess = (data) => ({ type: Types.GET_TICKET_SUCCESS, data });
export const getTicketError = (error) => ({ type: Types.GET_TICKET_ERROR, error });

export const saveBriefingReply = (briefing) => ({ type: Types.SAVE_BRIEFING_REPLY, briefing });
export const saveBriefingReplySuccess = (data) => ({ type: Types.SAVE_BRIEFING_REPLY_SUCCESS, data });
export const saveBriefingReplyError = (error) => ({ type: Types.SAVE_BRIEFING_REPLY_ERROR, error });

export const finishBriefingReply = (briefing, userModificationID) => ({ type:  Types.FINISH_BRIEFING_REPLY, briefing, userModificationID });
export const finishBriefingReplySuccess = (data) => ({ type:  Types.FINISH_BRIEFING_REPLY_SUCCESS, data });
export const finishBriefingReplyError = (error) => ({ type:  Types.FINISH_BRIEFING_REPLY_ERROR, error });

export const saveReportReply = (report) => ({ type: Types.SAVE_REPORT_REPLY, report });
export const saveReportReplySuccess = (data) => ({ type: Types.SAVE_REPORT_REPLY_SUCCESS, data });
export const saveReportReplyError = (error) => ({ type: Types.SAVE_REPORT_REPLY_ERROR, error });

export const finishReportReply = (report, userModificationID) => ({ type:  Types.FINISH_REPORT_REPLY, report, userModificationID });
export const finishReportReplySuccess = (data) => ({ type:  Types.FINISH_REPORT_REPLY_SUCCESS, data });
export const finishReportReplyError = (error) => ({ type:  Types.FINISH_REPORT_REPLY_ERROR, error });

export const reset = () => ({ type: Types.RESET });

export const resetSuccesBriefing = () => ({ type: Types.RESET_SUCCESS_BRIEFING });

//saga
function* fetchGetTicket(action) {
  try {
    const { id } = action;

    const ticket = yield call(getChamadoSdk, id);

    //Remove fields not usage
    const briefing = ticket.questionario.filter((quest) => quest.tipoQuestionario === 'BRIEFING');
    const report = ticket.questionario.filter((quest) => quest.tipoQuestionario === 'REPORT');

    if (briefing.length > 0) {
      briefing[0].respostas = briefing[0].respostas.map((answer) => ({ ...answer, dtCriacao: new Date(answer.dtCriacao.valueOf()), dtManutencao: new Date(), pergunta: { ...answer.pergunta, dtCriacao: new Date(answer.pergunta.dtCriacao.valueOf()), dtManutencao: null, opcoes: answer.pergunta.opcoes ? answer.pergunta.opcoes.map((option) => ({ ...option,  dtCriacao: new Date(option.dtCriacao.valueOf()), dtManutencao: null })) : null } }));
      briefing[0].dtCriacao = new Date(briefing[0].dtCriacao.valueOf());
      delete briefing[0].dtManutencao;
      if (briefing.dtRealizacao) {
        briefing[0].dtRealizacao = new Date(briefing[0].dtRealizacao?.valueOf());
      } 
    }
    if (report.length > 0) {
      report[0].respostas = report[0].respostas.map((answer) => ({ ...answer, dtCriacao: new Date(answer.dtCriacao.valueOf()), dtManutencao: new Date(), pergunta: { ...answer.pergunta, dtCriacao: new Date(answer.pergunta.dtCriacao.valueOf()), dtManutencao: null, opcoes: answer.pergunta.opcoes ? answer.pergunta.opcoes.map((option) => ({ ...option,  dtCriacao: new Date(option.dtCriacao.valueOf()), dtManutencao: null })) : null } }));
      report[0].dtCriacao = new Date(report[0].dtCriacao.valueOf());
      delete report[0].dtManutencao;

      if (report[0].dtRealizacao) {
        report[0].dtRealizacao = new Date(report[0].dtRealizacao?.valueOf());
      } else {
        delete report[0].dtRealizacao;
      }
    }
    const ticketAtualizado = {
      ...ticket,
      questionario: [
        ...(briefing.length > 0 ? briefing : []),
        ...(report.length > 0 ? report : []),
      ],
    };
    yield put(getTicketSuccess(ticketAtualizado));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTicketError(err));
  }
}

function* fetchSaveBriefingReply(action) {
  try {
    const { briefing } = action;
    const data = yield call(saveQuestionarioReplySdk, briefing, true);
    yield put(saveBriefingReplySuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveBriefingReplyError(err));
  }
}

function* fetchFinishBriefingReply(action) {
  try {
    const { briefing, userModificationID } = action;
    const data = yield call(saveQuestionarioReplySdk, briefing, false, userModificationID);

    yield put(finishBriefingReplySuccess(data));
    yield put(updateChamado({ id: data.idChamado }, { updateAwaitingBriefing: true }));
  } catch(err) {
    Notification.error(err.message);
    yield put(finishBriefingReplyError(err));
  }
}

function* fetchSaveReportReply(action) {
  try {
    const { report } = action;
    const data = yield call(saveQuestionarioReplySdk, report, true);
    yield put(saveReportReplySuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveReportReplyError(err));
  }
}

function* fetchFinishReportReply(action) {
  try {
    const { report, userModificationID } = action;
    const data = yield call(saveQuestionarioReplySdk, report, false, userModificationID);
    yield put(finishReportReplySuccess(data));
    yield put(updateChamado({ id: data.idChamado }, { updateAwaitingBriefing: true }));
  } catch(err) {
    Notification.error(err.message);
    yield put(finishReportReplyError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TICKET, fetchGetTicket),
  takeLatest(Types.SAVE_BRIEFING_REPLY, fetchSaveBriefingReply),
  takeLatest(Types.FINISH_BRIEFING_REPLY, fetchFinishBriefingReply),
  takeLatest(Types.SAVE_REPORT_REPLY, fetchSaveReportReply),
  takeLatest(Types.FINISH_REPORT_REPLY, fetchFinishReportReply)
];

// Reducer
const initialState = Map({
  ticket: {},
  
  loadingGetTicket: false,
  successGetTicket: false,
  errorGetTicket: false,

  loadingBriefingReply: false,
  successBriefingReply: false,
  errorBriefingReply: false,

  loadingFinishBriefingReply: false,
  successFinishBriefingReply: false,
  errorFinishBriefingReply: false,

  loadingReportReply: false,
  successReportReply: false,
  errorBrReportply: false,

  loadingFinishReportReply: false,
  successFinishReportReply: false,
  errorFinishReportReply: false,
});

const handleGetTicket = (state, action) => {
  return state
    .set('loadingGetTicket', true)
    .set('successGetTicket', false)
    .set('errorGetTicket', false);
};

const handleGetTicketSuccess = (state, action) => {
  const { data: { ...chamado } } = action;

  return state
    .set('ticket', chamado)
    .set('loadingGetTicket', false)
    .set('successGetTicket', true)
    .set('errorGetTicket', false);
};

const handleGetTicketError = (state, action) => {
  return state
    .set('loadingGetTicket', false)
    .set('successGetTicket', false)
    .set('errorGetTicket', action.error);
};

const handleSaveBriefingReply = (state, action) => {
  return state
    .set('loadingBriefingReply', true)
    .set('successBriefingReply', false)
    .set('errorBriefingReply', false);
};

const handleSaveBriefingReplySuccess = (state, action) => {
  return state
    .set('loadingBriefingReply', false)
    .set('successBriefingReply', true)
    .set('errorBriefingReply', false);
};

const handleSaveBriefingReplyError = (state, action) => {
  return state
    .set('loadingBriefingReply', false)
    .set('successBriefingReply', false)
    .set('errorBriefingReply', action.error);
};

const handleFinishBriefingReply = (state, action) => {
  return state
    .set('loadingFinishBriefingReply', true)
    .set('successFinishBriefingReply', false)
    .set('errorFinishBriefingReply', false);
};

const handleFinishBriefingReplySuccess = (state, action) => {
  return state
    .set('loadingFinishBriefingReply', false)
    .set('successFinishBriefingReply', true)
    .set('errorFinishBriefingReply', false);
};

const handleFinishBriefingReplyError = (state, action) => {
  return state
    .set('loadingFinishBriefingReply', false)
    .set('successFinishBriefingReply', false)
    .set('errorFinishBriefingReply', action.error);
};

const handleSaveReportReply = (state, action) => {
  return state
    .set('loadingReportReply', true)
    .set('successReportReply', false)
    .set('errorReportReply', false);
};

const handleSaveReportReplySuccess = (state, action) => {
  return state
    .set('loadingReportReply', false)
    .set('successReportReply', true)
    .set('errorReportReply', false);
};

const handleSaveReportReplyError = (state, action) => {
  return state
    .set('loadingReportReply', false)
    .set('successReportReply', false)
    .set('errorReportReply', action.error);
};

const handleFinishReportReply = (state, action) => {
  return state
    .set('loadingFinishReportReply', true)
    .set('successFinishReportReply', false)
    .set('errorFinishReportReply', false);
};

const handleFinishReportReplySuccess = (state, action) => {
  return state
    .set('loadingFinishReportReply', false)
    .set('successFinishReportReply', true)
    .set('errorFinishReportReply', false);
};

const handleFinishReportReplyError = (state, action) => {
  return state
    .set('loadingFinishReportReply', false)
    .set('successFinishReportReply', false)
    .set('errorFinishReportReply', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TICKET: return handleGetTicket(state, action);
    case Types.GET_TICKET_SUCCESS: return handleGetTicketSuccess(state, action);
    case Types.GET_TICKET_ERROR: return handleGetTicketError(state, action);

    case Types.SAVE_BRIEFING_REPLY: return handleSaveBriefingReply(state, action);
    case Types.SAVE_BRIEFING_REPLY_SUCCESS: return handleSaveBriefingReplySuccess(state, action);
    case Types.SAVE_BRIEFING_REPLY_ERROR: return handleSaveBriefingReplyError(state, action);

    case Types.FINISH_BRIEFING_REPLY: return handleFinishBriefingReply(state, action);
    case Types.FINISH_BRIEFING_REPLY_SUCCESS: return handleFinishBriefingReplySuccess(state, action);
    case Types.FINISH_BRIEFING_REPLY_ERROR: return handleFinishBriefingReplyError(state, action);

    case Types.SAVE_REPORT_REPLY: return handleSaveReportReply(state, action);
    case Types.SAVE_REPORT_REPLY_SUCCESS: return handleSaveReportReplySuccess(state, action);
    case Types.SAVE_REPORT_REPLY_ERROR: return handleSaveReportReplyError(state, action);

    case Types.FINISH_REPORT_REPLY: return handleFinishReportReply(state, action);
    case Types.FINISH_REPORT_REPLY_SUCCESS: return handleFinishReportReplySuccess(state, action);
    case Types.FINISH_REPORT_REPLY_ERROR: return handleFinishReportReplyError(state, action);
    
    case Types.RESET: return initialState;

    case Types.RESET_SUCCESS_BRIEFING: return state.set('successFinishBriefingReply', initialState.get('successFinishBriefingReply'));

    default: return state;
  }
}
