import React, { useEffect, useMemo, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Divider, Table, Input, Checkbox, Select, Form, Column, Row } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityTypeIcon, ActivityTypeIconWrapper } from '../../styles';
import { useHistory, useParams } from 'react-router-dom';
import { finishChecklist, getChecklistFinishPreview } from '@ducks/checklists';
import { ActionPlanIcon, ActionPlanRowText, ActivityTitle, AddActionButton, ErrorIcon, PendentAsk, PendentContainer, StarIconRed, SubcontentTitle, TextStategicAction, WarningContainer, WarningIcon } from './styles';
import ActionPlanRegister from './ActionPlanRegister';
import { formatDate, formatTime, moment, dateToAnsi, InfoUsuario } from '@utils';
import { sendEmail } from '@sdk/Checklists';
import { getCheckInOut } from '@ducks/atividadesCheckInOut';

export default function FinishChecklist() {
  const history = useHistory();
  const { idActivity, idChecklist } = useParams();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const [form] = Form.useForm();

  const preview = useSelector(({ checklists }) => checklists.get('checklistFinishPreview'));
  const loadingPreview = useSelector(({ checklists }) => checklists.get('loadingChecklistFinishPreview'));
  const successSavePreview = useSelector(({ checklists }) => checklists.get('successSaveChecklistFinishPreview'));
  const loadingFinishChecklist = useSelector(({ checklists }) => checklists.get('loadingFinishChecklist')); 
  const successFinishChecklist = useSelector(({ checklists }) => checklists.get('successFinishChecklist')); 

  const [visibleRegister, setVisibleRegister] = useState(false);
  const [currentActionPlan, setCurrentActionPlan] = useState();
  const [sendEmailOnFinish, setSendEmailOnFinish] = useState(false);
  const [emails, setEmails] = useState([]);
  const [errorTextActionActivitiesChecklist, setErrorTextActionActivitiesChecklist] = useState(null);

  const responsibleName = useMemo(() => {
    return preview?.usersList?.find(({ id }) => id === preview?.idUserChecklist)?.name;
  }, [preview]);

  useEffect(() => {
    const consultingVisionField = form.getFieldValue('consultingVision');

    if(preview?.strategicAction === 'S' && preview.actionActivitiesChecklist.length > 0) {
      setErrorTextActionActivitiesChecklist(null);
    }
    if (preview?.statusChecklist === 'CONCLUIDO') {
      history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/finished`, { idTypeActivity: preview.idTypeActivity });
    } else if(!consultingVisionField) {
      form.setFieldsValue({ consultingVision: preview?.observationChecklist });
    }
  }, [preview]);

  useEffect(() => {
    dispatch(getChecklistFinishPreview(idChecklist));
  }, []);

  useEffect(() => {
    if (successSavePreview) {
      setVisibleRegister(false);
    }
  }, [successSavePreview]);

  useEffect(() => {
    if (successFinishChecklist) {
      history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/finished`, { fromFillChecklist: true, idTypeActivity: preview.idTypeActivity });
      dispatch(getCheckInOut());
      if (sendEmailOnFinish) {
        sendEmail(idChecklist, { emails, userName: user?.name, userEmail: user?.email, companyId: preview.idCompany });
      }
    }
  }, [successFinishChecklist]);

  const user = useMemo(() => {
    return preview?.usersList?.find(({ id }) => id === InfoUsuario.get('cdUsuario'));
  }, [preview]);

  const columns = [
    {
      title: 'Tipo de Atividade',
      dataIndex: 'idTypeActivity',
      sorter: true,
      render: (text, actionPlan) => (
        <Row gap='12px' align='center'>
          <ActivityTypeIconWrapper color={actionPlan.colorTypeActivity}>
            <ActionPlanIcon type={actionPlan.iconTypeActivity}/>
          </ActivityTypeIconWrapper>
          <ActivityTitle>{actionPlan.title}</ActivityTitle>
        </Row>
      )
    },
    {
      title: 'Responsável',
      dataIndex: 'responsible',
      sorter: true,
      render: () => <ActionPlanRowText>{responsibleName || '-'}</ActionPlanRowText>
    },
    {
      title: 'Data',
      dataIndex: 'date',
      sorter: true,
      render: (text) => <ActionPlanRowText>{text ? `${formatDate(text)} às ${formatTime(text)}` : '-'}</ActionPlanRowText>
    },
  ];

  const handleAddActionClick = (actionPlan) => {
    setVisibleRegister(true);
    if (actionPlan) {
      setCurrentActionPlan(actionPlan);
    }
  };

  const handleAddActionCancel = () => {
    setVisibleRegister(false);
    setCurrentActionPlan();
  };

  const handleFinishChecklist = () => {
    form.validateFields().then((values) => {
      if(preview?.strategicAction === 'S' && preview.actionActivitiesChecklist.length === 0) {
        setErrorTextActionActivitiesChecklist('Você deve adicionar ao menos uma ação estratégica');
        return;
      }
      dispatch(finishChecklist(idActivity, idChecklist, { date: dateToAnsi(moment()), idUserFinish: InfoUsuario.get('cdUsuario'), observation: values.consultingVision }));
    }).catch(Form.scrollToFirstError);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={(
            <Row gap='14px' align='center'>
              {preview?.nameActivity}
              <ActivityTypeIconWrapper color={preview?.colorTypeActivity}>
                <ActivityTypeIcon type={preview?.iconTypeActivity}/>
              </ActivityTypeIconWrapper>
            </Row>
          )}
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades/agenda', name: 'Agenda' },
                { path: `/atividades/agenda/${idActivity}`, name: preview?.nameActivity || '...' },
                { path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}`, name: 'Checklist', current: true },
              ]}
            />
          )}
          extra={[
            <Button size='small' key='finish' loading={loadingFinishChecklist} disabled={preview?.pendingAsks?.length > 0} type='primary' onClick={() => handleFinishChecklist()}>Finalizar Checklist</Button>,
          ]}
        >
          <Column gap='28px'>
            <Column gap='12px'>
              <WarningContainer>
                <WarningIcon type='alert-circle'/>
              *Você está prestes a finalizar este checklist. Confira as ações estratégicas e preencha o laudo de conclusão antes de prosseguir.
              </WarningContainer>
              {preview?.pendingAsks?.length > 0 && (
                <PendentContainer>
                  <Row gap='43px' align='center'>
                    <ErrorIcon type='alert-triangle' size='3rem'/>
                    <Column>
                      <strong>Há itens pendentes no checklist:</strong>
                      {preview.pendingAsks.map(({ ask }) => (
                        <PendentAsk key={ask}>{ask}</PendentAsk>
                      ))}
                    </Column>
                  </Row>
                  <Button type='primary' onClick={() => history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}`)}>Continuar preenchimento</Button>
                </PendentContainer>
              )}
            </Column>

            <Divider/>

            {preview?.strategicAction !== 'N' && (
              <>
                <Column gap='16px'>
                  <Row gap='4px'>
                    <SubcontentTitle>Ações estratégicas</SubcontentTitle>
                    {preview?.strategicAction === 'S' && <StarIconRed>*</StarIconRed>}
                  </Row>
                  <Table
                    columns={columns} 
                    dataSource={preview?.actionActivitiesChecklist}
                    loading={loadingPreview}
                    hidePagination
                    rowKey='id'
                    onRow={(actionPlan) => {
                      return {
                        onClick: () => handleAddActionClick(actionPlan)
                      };
                    }}
                  />
                  {errorTextActionActivitiesChecklist && <TextStategicAction>{errorTextActionActivitiesChecklist}</TextStategicAction>}
                </Column>
            
                <AddActionButton type='secondary' onClick={() => handleAddActionClick()}>Adicionar ação estratégica</AddActionButton>

                <Divider/>
              </>
            )}
            <Row gap='12px'>
              {preview?.typeConsultingVision !== 'NAO_PERMITE' && (
                <Column gap='38px' flex={1}>
                  <Column gap='12px'>
                    <SubcontentTitle>
                      {preview?.consultingVisionAlias || 'Visão do consultor'}
                    </SubcontentTitle>  
                  </Column>
                  <Form form={form} layout='vertical'>
                    <Form.Item name='consultingVision' label='Notas' absoluteLabel rules={preview?.typeConsultingVision === 'OBRIGATORIO' && [{ required: true, message: 'Insira uma nota sobre esta atividade' }]}>
                      <Input.TextArea placeholder='Escreva uma nota sobre esta atividade'/>
                    </Form.Item>
                  </Form>
                </Column>
              )}

              {preview?.sendEmail !== 'N' && (
                <Column gap='38px' flex={1}>
                  <Column gap='12px'>
                    <Checkbox checked={sendEmailOnFinish} onChange={(value) => setSendEmailOnFinish(value)}>Enviar checklist por E-mail</Checkbox>
                  </Column>
                  {/* <Select 
                  value={emails} 
                  onChange={(selectedEmails) => setEmails(selectedEmails)} 
                  placeholder='Informe o usuário ou digite um E-mail' 
                  filterOption={(input, { name, email }) => name?.toLowerCase().includes(input.toLowerCase()) || email?.toLowerCase().includes(input.toLowerCase())}
                  multiple
                >
                  {preview?.usersList?.map(({ id, name, email }) => (
                    <Select.Option key={id} value={email} name={name} email={email}>
                      {name}
                      <UserOptionEmail>{email}</UserOptionEmail>
                    </Select.Option>
                  ))}
                </Select> */}
                  {/* <InputTag 
                  placeholder='Informe o usuário ou digite um E-mail' 
                  tags={emails} 
                  handleDelete={(index2) => setEmails([ emails.filter((tag, index) => index !== index2) ])} 
                  handleAddition={(tag) => setEmails([ ...emails, tag ])}
                /> */}
                  <Select 
                    value={emails} 
                    onChange={(selectedEmails) => setEmails(selectedEmails)} 
                    placeholder='Informe o usuário ou digite um E-mail' 
                    filterOption={(input, { name, email }) => name?.toLowerCase().includes(input.toLowerCase()) || email?.toLowerCase().includes(input.toLowerCase())}
                    mode='tags'
                    disabled={!sendEmailOnFinish}
                  >
                    {preview?.usersList?.map(({ id, name, email }) => (
                      <Select.Option key={id} value={email} name={name} email={email}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Column>
              )}
            </Row>
          </Column>

          {visibleRegister && (
            <ActionPlanRegister
              visible={visibleRegister}
              onCancel={() => handleAddActionCancel()}
              actionPlan={currentActionPlan}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}