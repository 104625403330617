import React, { useEffect, useState } from 'react';
import { Text, Row, Button, SearchBar, ContentTitle, Table, Icon } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { arrayReorder } from '@utils';
import { getExtraFields, updateOrder } from '@ducks/settingsProductsExtraFields';

export default function CamposPersonalizados() {
  const [search, setSearch] = useState();
  const dispatch = useDispatch();

  const extraFields = useSelector(({ extraFields }) => extraFields.get('extraFields'));
  const loadingExtraFields = useSelector(({ extraFields }) => extraFields.get('loadingExtraFields'));

  useEffect(()=>{
    dispatch(getExtraFields());
  },[]);

  const handleReorderExtraFields = (currentIndex, newIndex) => {
    const newExtraFields = arrayReorder(extraFields, currentIndex, newIndex)
      .map((extraField, index) => ({ ...extraField, order: index }));

    dispatch(updateOrder(newExtraFields));
  };
  
  const columns = [
    {
      title:'Título',
      dataIndex:'name',
      sorter: true,
      width: '25%',
    },
    {
      title: 'Nome do Campo',
      dataIndex: 'name',
      sorter:true,
      width: '25%',
    },
    {
      title: 'Aparece nas Informações do produto',
      dataIndex: 'highlight',
      sorter: true,
      width: '25%',
      render:(text, campoPersonalizavel)=>(
        <Text>{campoPersonalizavel.highlight ? 'Sim' : 'Não'}</Text>
      )
    },
    {
      title:'Visível',
      align: 'center',
      dataIndex:'active',
      sorter: true,
      width: '25%',
      render:(text, campoPersonalizavel, index)=>(
        <Row justify="space-between" align="center">
          <Text>{campoPersonalizavel.active ? 'Sim' : 'Não'}</Text>
          <Button.Group>
            <Button 
              type='secondary'
              size='smaller'
              context='list'
              icon={<Icon.UpOutlined/>}
              title='Mover pra cima'
              disabled={index === 0}
              onClick={() => handleReorderExtraFields(index, index - 1)}
            />
            <Button 
              type='secondary'
              size='smaller'
              context='list'
              icon={<Icon.DownOutlined/>}
              title='Mover pra baixo'
              disabled={index === campoPersonalizavel.length - 1} 
              onClick={() => handleReorderExtraFields(index, index + 1)} 
            />
          </Button.Group>
        </Row>
      )
    },
  ];
  const handleSearch = (text) => {
    setSearch(text);
  };
  const filterTypes = () => {
    if (!search) return extraFields;
    return extraFields.filter(({ name }) => name.trim().toLowerCase().includes(search.trim().toLowerCase()));
  };
  
  return(
    <>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar' />
      <ContentTitle>Campos Customizáveis</ContentTitle>
      <Table columns={columns} dataSource={filterTypes()} rowKey='id' status='active' loading={loadingExtraFields}/>
    </>
  );
}