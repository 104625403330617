import { get, post } from '../api';
import { queryParamsGestaoEquipe } from './rules';
import { ansiToMoment, dateToAnsi, DATE_FORMATS, InfoUsuario } from '@utils';

export const getAcessoDiario = async (filtros) => {
  return await get(`/api/gestao/equipe/acesso/diario${queryParamsGestaoEquipe(filtros)}`);
};

export const getAcessosUltimos30Dias = async (filtros) => {
  return await get(`/api/gestao/equipe/acesso/periodo${queryParamsGestaoEquipe(filtros)}`);
};

export const getAcessosPerfil = async (filtros) => {
  return await get(`/api/gestao/equipe/acesso/perfil${queryParamsGestaoEquipe(filtros)}`);
};

export const getAcessosUsuario = async (page, filtros) => {
  const limit = 100;
  const acessos = await get(`/api/gestao/equipe/acesso/usuario${queryParamsGestaoEquipe(filtros)}&page=${page}&limit=${limit}`);

  // eslint-disable-next-line
  acessos.data.map((acesso) => {
    acesso.date = ansiToMoment(acesso.date);
  });

  return acessos;
};

export const getTeamsManagementUsers = async (filtros) => {
  return await get(`/api/teamsManagement/users${queryParamsGestaoEquipe(filtros)}`);
};

export const getTeamsManagementUser = async (date, userId) => {
  return await get(`/api/teamsManagement/users/${userId}?dt=${dateToAnsi(date, null, DATE_FORMATS.ANSI_DATE)}`);
};

export const postActivityCheck = async (taskId, checked) => {
  return await post(`/api/teamsManagement/tasks/${taskId}/check?userId=${InfoUsuario.get('cdUsuario')}`, checked);
  
};