import React from 'react';
import { DropdownContainerStyles, StyledDatePicker } from './styles';

const DatePicker = ({ ...props }) => (
  <>
    <StyledDatePicker {...props}/>
    <DropdownContainerStyles/>
  </>
);

export default DatePicker;