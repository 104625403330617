import styled from 'styled-components';
import { Icon, View, Text, StatusTag } from '@components-teammove';

import { GapFlexCol, SpaceBetweenRow } from '../Detalhes/styles';

export const Container = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px 13px;
`;

export const Row = styled(SpaceBetweenRow)`
  justify-content: space-evenly;
`;

export const InfoIconWrapper = styled.div`
  padding: 14px;
  border-radius: 50%;
  border: ${({ existsInfo, theme }) => existsInfo && `1px solid ${theme.actionColor}`};
  background-color: ${({ theme }) => theme.backgroundColor};
  opacity: ${({ existsInfo }) => existsInfo ? 1 : .5};
  cursor: pointer;
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoIconLabel = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    letter-spacing: .5px;
  }
`;

export const ContactName = styled(Text)`
  &.ant-typography {
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
    letter-spacing: .5px;
  }
`;

export const ContactRole = styled(Text)`
margin-bottom: 16px;
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    letter-spacing: .5px;
  }
`;

export const Contact = styled(View)`
  border-radius: 12px;
  padding: 17px 16px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  height: fit-content;
  gap: 40px;
  position: relative;
  ${({ open }) => !open && `
    justify-content: center;
    cursor: pointer;

    :hover {
      transform: scale(1.0125);
    }

    ${ContactName} {
      &.ant-typography {
        font-size: 18px;
        font-weight: 700;
        color: ${({ theme }) => theme.textColor};
        letter-spacing: .5px;
      }
    }

    ${ContactRole} {
      &.ant-typography {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.textColor};
        letter-spacing: .5px;
      }
    }
  `}
  transition: transform .5s;

  :nth-child(3n-2) {
    transform-origin: left top;
  }
  :nth-child(3n-1) {
    transform-origin: center center;
  }
  :nth-child(3n+0) {
    transform-origin: right top;
  }
`;

export const ContactHeader = styled(SpaceBetweenRow)`
  align-items: center;
  cursor: pointer;
`;

export const ContactInfo = styled(View)`
  gap: 4px;
  ${({ alignCenter }) => alignCenter && `
    align-items: center;
  `}
`;

export const CollapseIcon = styled(Icon.Box)`
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
`;

export const InfoList = styled(GapFlexCol)`
  >:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 2px solid ${({ theme }) => theme.backgroundColor};
  }
`;

export const TextColorFeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const TextColorMdiIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const EmptyContactListView = styled(View)`
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
`;

export const EmptyMessage = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
  }
`;

export const StatusTagStyle = styled(StatusTag)`
    position: absolute;
    z-index: 1;
    align-self: end;
    display: flex;
    top: 17px;
`;