import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, View, Skeleton, Form, Select, Input } from '@components-teammove';
import { getAgrupador, saveAgrupador, resetSuccessSave, deleteAgrupador } from '@ducks/configuracoesCampanhasAgrupadores';
import { useDispatch, useSelector } from 'react-redux';
import { getPerfis } from '@sdk/Perfil';

export default function AgrupadoresCadastro({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [perfis, setPerfis] = useState();
  const [originalFormValue, setOriginalFormValue] = useState(null);

  const dispatch = useDispatch();
  const agrupador = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('agrupador'));
  const loading = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('loading'));
  const success = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('success'));
  const loadingSave = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('loadingSave'));
  const successSave = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesCampanhasAgrupadores }) => configuracoesCampanhasAgrupadores.get('successDelete'));

  const { id } = location.state || {};

  useEffect(() => {
    form.setFieldsValue({ format: 'MONEY', type: 'SALE' });
    if (id) handleGetAgrupador();

    (async () => {
      const perfisAsync = await getPerfis();
      setPerfis(perfisAsync.map(({ title, value }) => ({ label: title, value })));
    })();

    return () => {
      dispatch(resetSuccessSave());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success && id) {
      form.setFieldsValue(agrupador);
      setOriginalFormValue(form.getFieldsValue());
    }
  }, [success]);

  useEffect(() => {
    if (successDelete) {
      onCancel();
    }
  }, [successDelete]);
  
  useEffect(() => {
    if (successSave) {
      onCancel();
    }
  }, [successSave]);

  const handleGetAgrupador = () => {
    dispatch(getAgrupador(id));
  };

  const handleDelete = (id) => {
    dispatch(deleteAgrupador(id));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      if (id) {
        values = { 
          ...values,
          createdAt: new Date(agrupador.createdAt),
          updatedAt: new Date(agrupador.updatedAt),
          id 
        };
      }
      dispatch(saveAgrupador(values));
    });
  };

  const onBeforeCancel = () => {
    if(originalFormValue) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFormValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched();
  }; 

  return (
    <Modal
      visible={visible}
      title='Configurações / Campanhas / Agrupadores'
      numberOfButtons={id ? 2 : 1}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      cancelText='Excluir'
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir este agrupador?', onConfirm: () => handleDelete(agrupador.id),  cancelText: 'Cancelar', okText: 'Confirmar' }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null, title: agrupador?.inUseByCampaigns ? 'Agrupador em uso por 1 ou mais campanha(s)' : 'Excluir agrupador', disabled: agrupador?.inUseByCampaigns }}
      okButtonProps={{ loading: loadingSave }}
      onOk={handleSalvar}
    >
      {loading ? (
        <Skeleton.Form/>
      ) : (
        <Form layout='vertical' form={form}>
                
          <View>
            <Form.Item label='Nome' name='name' rules={[{ required: true, message: 'Informe o nome do agrupador' }]}>
              <Input placeholder='Informe o agrupador' autoFocus/>
            </Form.Item>
          </View>
          <View>
            <Form.Item 
              name='roles' 
              label='Perfis Para Agrupar'
              rules={[{ required: true, message: 'Informe ao menos um perfil' }]}
            >
              <Select options={perfis} mode='multiple' placeholder='Selecione um ou mais perfis'/>
            </Form.Item>
          </View>
        </Form>
      )}
    </Modal>
  );
}