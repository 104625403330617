import styled from 'styled-components';
import { Image, Tag, Button, Column, Row, Icon } from '@components-teammove';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 10.0625rem;
`;

export const ContentCard = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 28px;
  ${({ paddingless }) => !paddingless && `
    padding: 24px;
  `};
  ${({ alignCenter }) => alignCenter && `
    text-align: center;
  `}
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SummaryGridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const ContentTitleRow = styled(Row)`
  padding: 0 24px;
`;

export const ContentTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  height: fit-content;
  line-height: 27px;
`;

export const AverageText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};

  strong {
    font-weight: 700;
  }
`;

export const LabelText = styled(AverageText)`
  font-weight: 300;
`;

export const InfoText = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  word-wrap: anywhere;
  white-space: pre-wrap;
`;

export const TemplateName = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const PercentText = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const CompanyCard = styled(ContentCard)`
  padding: 24px;
`;

export const CompanyInfoColumn = styled(Column)`
  gap: 20px;
`;

export const ProgressColumn = styled(Column)`
  border-left: 3px solid ${({ theme }) => theme.darkPrimaryColor};
  padding: 0 48px;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const StyledFeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const AnswerExtraInfoContainer = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 26px;
  gap: 8px;
`;

export const AnswerExtraInfoTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const AnswerExtraInfoValue = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const AnswerUpload = styled(Image)`
  max-height: 400px;
  max-width: 525px;
  border: 2px solid ${({ theme }) => theme.darkHighlightColor};
  border-radius: 9px;
  align-self: center;
  cursor: pointer;
`;

export const QuestionRow = styled(Row)`
  position: relative;
`;

export const QuestionColumn = styled(Column)`
  flex: 1;
`;

export const QuestionTag = styled(Tag)`
  right: 0;
  top: 0;
`;

export const ChecklistObservationText = styled(InfoText)`
  font-weight: 400;
  white-space: pre-line;
`;

export const UploadFileName = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 250px;

  @media screen and (min-width: 900px) {
    max-width: 400px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 600px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 800px;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  display: flex;
`;

export const UploadFileContainer = styled.div`
  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.darkHighlightColor};
`;

export const CompanyDataRow = styled(Row)`
  justify-content: space-between;
`;

export const ShortNameCompanyText = styled(InfoText)`
  font-size: 24px;
  line-height: 29px;
`;

export const CompanyNameText = styled(InfoText)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  opacity: .5;
`;

export const CompanyAdress = styled(InfoText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

export const CompanyLocation = styled(CompanyAdress)`
  font-weight: 600;
`;

export const QuestionNumber = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  align-items: center;
  justify-content: center
`;

export const NumberText = styled(InfoText)`
  font-size: 14px;
  font-weight: 300;
`;

export const QuestionNumberRow = styled(Row)`
  align-items: center;
  gap: 8px;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-bottom: 2px solid ${({ theme }) => theme.darkHighlightColor};
`;

export const ContentAnswer = styled.div`
  padding-left: 26px;
`;

export const ContentAverageHistory = styled(Row)`
  gap: 16px;
  align-items: start;
`;

export const ClockButton = styled(Button)`
  display: flex;
  position: relative;  
`;

export const ContentHistoric = styled.div`
  gap: 12px;  
  padding: 12px 12px 13px 12px;
  position: absolute;
  right: -12px;
  top: -12px;
  width: 304px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index:1;
`;

export const Historic = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.darkPrimaryColor};;
  gap: 2px;
  border-radius:10px;
`;

export const HistoricData = styled(InfoText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: .5;
`;

export const HistoricHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  align-items: start;
  padding: 8px 0 8px 8px;
  :first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;