import { get, put, post } from '../api';

export const getFamilies = (familyId) => {
  return get(`/api/products/families/${familyId !== undefined ? familyId : ''}`);
};
  
export const postFamily = (family) => {
  return post('/api/products/families/', family);
}; 
  
export const putFamily = (family) => {
  return put('/api/products/families', family);
};
  