import React, { useEffect, useState } from 'react';
import { Button, View, Form, PopConfirm } from '@components';
import styled, { css } from 'styled-components';
import Somatoria from './Somatoria';
import MultiplaEscolha from './MultiplaEscolha';
import { Notification } from '@utils';
import Feedback from '../Feedback';
import { estadosQuiz } from '../rules';
import { useDispatch, useSelector } from 'react-redux';
import { postFinalizarTentativa, postResponder, reset } from '@ducks/wikisEADQuiz';
import { postLido } from '@ducks/wikisAcoes';
import { alterCurrentAula } from '@ducks/wikisEAD';

const ViewMain = styled(View)`
  height: 100%;
  width: 100%;
  padding: 50px;
`;

const ViewHeadPergunta = styled(View)`
  margin-bottom: 10px;
  font-size: 14px;
  justify-content: space-between;
`;

const ViewPergunta = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const ViewOpcoes = styled(View)`
  * {
    font-size: 16px !important;
    ${({ disabled }) =>
      disabled && // eslint-disable-line
      css`
        pointer-events: none;
      `}
  }
`;

const ViewFeedback = styled(View)`
  align-items: flex-end;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
`;

const ViewAcao = styled(View)`
  align-items: flex-end;
  height: 100%;
  justify-content: flex-end;
`;

const ButtonResponder = styled(Button)`
  width: 200px;
  margin-left: 10px;
`;

export default function Perguntas({ data, handlers }) {
  const [form] = Form.useForm();
  const { quiz, perguntaAtual, idCategoria, isUltimaPergunta } = data;
  const { setPerguntaAtual, setEstadoAtual, removerAndamentoQuiz, setUltimaPergunta, goToInicio } = handlers;
  const pergunta = quiz.perguntas[perguntaAtual];
  const isMultiplaEscolha = pergunta.tp === 'M';
  const isFeedbackParcial = quiz.feedbackParcial;
  const [acertouResposta, setAcertouResposta] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [hasFeedback, setHasFeedback] = useState(false);
  const [desistencia, setDesistencia] = useState(false);
  const [dtComecoResposta, setDtComecoResposta] = useState(new Date());
  const dispatch = useDispatch();

  const tentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('tentativa'));
  const successFinalizarTentativa = useSelector(({ wikisEADQuiz }) => wikisEADQuiz.get('successFinalizarTentativa'));

  useEffect(() => {
    if (quiz.perguntas.length === perguntaAtual + 1) {
      setUltimaPergunta(true);
    }
  }, []);

  useEffect(() => {
    if (successFinalizarTentativa && desistencia) {
      goToInicio();
    }
  }, [successFinalizarTentativa]);

  const handleReset = () => {
    form.resetFields();
    setFeedback(null);
    setAcertouResposta(false);
    setDesistencia(false);
    setDtComecoResposta(new Date());
  };

  const handleProximaPergunta = (pergAtual = perguntaAtual) => {
    const proximaPergunta = pergAtual + 1;

    if (!isUltimaPergunta) {
      if (quiz.perguntas.length - 1 === proximaPergunta) {
        setUltimaPergunta(true);
      }

      setPerguntaAtual(proximaPergunta);
      handleReset();
    } else {
      handleFinalizarQuiz();
    }
  };

  useEffect(() => {
    setHasFeedback(feedback !== null);
  }, [feedback]);

  const handleResponder = async () => {
    const values = await form.validateFields();

    try {
      let respostas = { ...values };
      if (isMultiplaEscolha) {
        if (!respostas.radio) {
          throw new Error('Selecione uma opção para responder a pergunta');
        }
      } else {
        let error = true;
        for (let key in respostas) {
          if (respostas[key]) {
            error = false;
          } else {
            respostas[key] = false;
          }
        }

        if (error) {
          throw new Error('Selecione ao menos uma opção para responder a pergunta');
        }
      }

      let acertou = true;
      let resposta = [];

      if (isMultiplaEscolha) {
        acertou = pergunta.opcoes.find((op) => op.id === respostas.radio).correta;
        resposta = respostas.radio;
      } else {
        for (let key in respostas) {
          const idResposta = parseInt([key.replace('check-', '')]);

          resposta.push({ id: idResposta, correta: respostas[key] });

          if (respostas[key] !== pergunta.opcoes.find((op) => op.id === idResposta).correta) {
            acertou = false;
          }
        }

        resposta = JSON.stringify(resposta);
      }

      dispatch(
        postResponder({
          idTentativa: tentativa.id,
          idPergunta: pergunta.id,
          resposta,
          dtFim: new Date(),
          dtInicio: dtComecoResposta,
          acertou,
        })
      );

      if (isFeedbackParcial) {
        setFeedback(acertou ? pergunta.feedbackPositivo : pergunta.feedbackNegativo);
        setAcertouResposta(acertou);
      } else if (isUltimaPergunta) {
        handleFinalizarQuiz();
      } else {
        handleProximaPergunta(perguntaAtual);
      }
    } catch (e) {
      Notification.error(e.message);
    }
  };

  const handlePostFinalizarTentativa = (desistiu) => {
    setDesistencia(desistiu);
    dispatch(postFinalizarTentativa(tentativa.id, desistiu));
  };

  const handleFinalizarQuiz = () => {
    handlePostFinalizarTentativa();
    dispatch(postLido(idCategoria, true));
    dispatch(alterCurrentAula({ lido: true }));
    removerAndamentoQuiz && removerAndamentoQuiz({ desistencia: false, finalizado: true, nota: undefined, total: undefined });
    setEstadoAtual(estadosQuiz.FINALIZADO);
  };

  const handleEncerrarQuiz = () => {
    handlePostFinalizarTentativa(true);
    removerAndamentoQuiz({ desistencia: true, nota: undefined, total: undefined });
    dispatch(reset());
  };

  const EncerrarQuizBtn = () => (
    <PopConfirm
      key='1'
      title='Deseja encerrar o quiz sem responder as demais perguntas?'
      onConfirm={handleEncerrarQuiz}
      okText='Encerrar'
      cancelText='Cancelar'
    >
      <Button type='link' danger>
        Encerrar quiz
      </Button>
    </PopConfirm>
  );

  const ResponderBtn = ({ click = handleResponder, type = 'success', children }) => (
    <ButtonResponder type={type} onClick={click}>
      {children}
    </ButtonResponder>
  );

  return (
    <ViewMain>
      <ViewHeadPergunta horizontal>
        <View>
          Pergunta {perguntaAtual + 1}/{quiz.perguntas.length}
        </View>
      </ViewHeadPergunta>
      <ViewPergunta>{pergunta.pergunta}&nbsp;</ViewPergunta>
      <Form form={form}>
        <ViewOpcoes disabled={hasFeedback}>
          {isMultiplaEscolha ? (
            <MultiplaEscolha data={{ opcoes: pergunta.opcoes, isMultiplaEscolha }} />
          ) : (
            <Somatoria data={{ opcoes: pergunta.opcoes, isMultiplaEscolha }} />
          )}
        </ViewOpcoes>
      </Form>

      {isFeedbackParcial ? (
        <ViewFeedback>
          {hasFeedback ? (
            <ViewFeedback>
              <Feedback data={{ feedback, positivo: acertouResposta }} />
              <ViewAcao>
                {isUltimaPergunta ? (
                  <ResponderBtn click={() => handleProximaPergunta()}>Finalizar quiz</ResponderBtn>
                ) : (
                  <View horizontal>
                    <EncerrarQuizBtn />
                    <ResponderBtn click={() => handleProximaPergunta()} type='default'>
                      Próxima pergunta
                    </ResponderBtn>
                  </View>
                )}
              </ViewAcao>
            </ViewFeedback>
          ) : (
            <View>
              <ViewAcao horizontal>
                <EncerrarQuizBtn />
                <ResponderBtn>Responder</ResponderBtn>
              </ViewAcao>
            </View>
          )}
        </ViewFeedback>
      ) : (
        <ViewAcao>
          {isUltimaPergunta ? (
            <ResponderBtn>Finalizar quiz</ResponderBtn>
          ) : (
            <View horizontal>
              <EncerrarQuizBtn />
              <ResponderBtn>Responder</ResponderBtn>
            </View>
          )}
        </ViewAcao>
      )}
    </ViewMain>
  );
}
