import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ButtonConatiner, ButtonText, ContainerList, TitleRanking, ContainerRanking, ContanierRow, TableList, TableSmallText, TableTallText, ContainerPrice } from './styles';
import { Column, Divider, Row, Skeleton } from '@components-teammove';
import { getRankingProducts, getRankingClients } from '@ducks/pedidosDashboard';
import { formatCurrency, formatDate, formatDecimal } from '@utils';

const Ranking = ({ tipo, currentFilters, currentParams }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const rankingProducts = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('rankingProducts'));
  const loadingRankingProducts = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingRankingProducts'));

  const rankingClients = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('rankingClients'));
  const loadingRankingClients = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingRankingClients'));

  useEffect(() => {
    if(Object.keys(currentFilters).length === 0 && Object.keys(currentParams).length === 0) return;
    dispatch(getRankingProducts({ ...currentFilters, ...currentParams }));
    dispatch(getRankingClients({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  const data = tipo === 'Produto' ? rankingProducts?.slice(0, 5) : rankingClients?.slice(0, 5);

  const handleSeeAll = () => {
    history.push('/produtos/dashboard-pedidos/ranking', { tipo, currentFiltersState: currentFilters, currentParamsState: currentParams });
  };

  return (
    !loadingRankingProducts && !loadingRankingClients ? (
      data.length > 0 ? (
        <ContainerRanking gap='8px'>
          <ContanierRow justify='space-between' align='center'>
            <TitleRanking>Ranking de {tipo}</TitleRanking>
            <ButtonConatiner onClick={() => handleSeeAll()} disabled={loadingRankingProducts || loadingRankingClients}>
              <ButtonText>Ver todos</ButtonText>
            </ButtonConatiner>
          </ContanierRow>
          <ContainerList>
            <TableList>
              {data.map((item, index) => (
                <>
                  <ContanierRow key={item.product_id || index} justify='space-between' align='center'>
                    <Row gap='25px' align='center'>
                      <TableTallText>{index + 1}</TableTallText>
                      <Column>
                        <TableTallText>{tipo === 'Produto' ? item.name : item.companies.name}</TableTallText>
                        {tipo === 'Produto' ? (
                          <div>
                            {item.families.map((family) => (
                              <TableSmallText descricao key={`${family.group}-${family.names}`}>{`${family.group} > ${family.names}`}</TableSmallText>
                            ))}
                          </div>
                        ) : (
                          <TableSmallText descricao>Ultimo pedido: {formatDate(item.lastOrder)}</TableSmallText>
                        )}
                      </Column>
                    </Row>
                    <Row gap='10px' align='center'>
                      <ContainerPrice>
                        <TableSmallText>{`${formatDecimal(tipo === 'Produto' ? item.totalWeight : item.weight, 1)} kg`}</TableSmallText>
                      </ContainerPrice>
                      <Column>
                        {tipo === 'Produto' ? (
                          <ContainerPrice>
                            <TableSmallText>{formatCurrency(item.totalValue)}</TableSmallText>
                          </ContainerPrice>
                        ) : (
                          <>
                            <TableSmallText descricao pedidos>{`${item.quantityOrders} ${item.quantityOrders > 1 ? 'Pedidos' : 'Pedido'}`}</TableSmallText>
                            <TableSmallText valorCliente>{formatCurrency(item.value)}</TableSmallText>
                          </>
                        )}
                      </Column>
                    </Row>
                  </ContanierRow>
                  {index !== data.length - 1 && <Divider margin/>}
                </>
              ))}
            </TableList>
          </ContainerList>
        </ContainerRanking>
      ) : null) : <Skeleton.LargeBox/>);
};

export default Ranking;