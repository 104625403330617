import { putMetaAtividades } from '@ducks/configuracoesMetaAtividades';

export const putMetasAtividadesDispatch = (users, form, dispatch, initialDate, setSavedFormValues, activityKinds) => {
  form.validateFields().then((values) => {
    const updatedUsers = users.map((user) => {
      const updatedUser = { ...user };
      activityKinds.forEach((activityKind) => {
        const key = `quantidadeAtividade-${user.id}-${activityKind.id}`;
        const numberOfActivities = values[key];
        const activityKindGoal = updatedUser.activityKindOrGroupGoals.find((goal) => (goal.activityKindId || goal.activityKindGroupId) === activityKind.id);
        activityKindGoal ? activityKindGoal.numberOfActivities = numberOfActivities : updatedUser.activityKindOrGroupGoals.push({ activityKindId: activityKind.id, numberOfActivities });

      });
      updatedUser.activityKindOrGroupGoals = updatedUser.activityKindOrGroupGoals.filter((goal) =>
        activityKinds.some((activityKind) => activityKind.id === (goal.activityKindId || goal.activityKindGroupId))
      );
      return updatedUser;
    });

    const dataToSend = {
      startsOn: initialDate,
      users: updatedUsers
    };

    setSavedFormValues(values);
    dispatch(putMetaAtividades(dataToSend));
  });
};