import React from 'react';
import { DropdownContainerStyles, StyledRangePicker, Separator } from './styles';
import { moment } from '@utils';

const RangePicker = ({ onChange, ...props }) => (
  <>
    <StyledRangePicker 
      allowClear={false} 
      separator={<Separator>até</Separator>} 
      ranges={{
        'Hoje': [moment().startOf('day'), moment().endOf('day')],
        'Sem. passada': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
        'Esta Semana': [moment().startOf('week'), moment().endOf('week')],
        'Mês passado': [moment().startOf('month').subtract(1, 'months'), moment().endOf('month').subtract(1, 'months')],
        'Este Mês': [moment().startOf('month'), moment().endOf('month')],
        'Este Ano': [moment().startOf('year'), moment().endOf('year')],
      }}
      onChange={(date, dateString) => {
        if (!props.showTime) {
          date = [date[0].startOf('day'), date[1].endOf('day')];
        }
        onChange(date, dateString);
      }}
      {...props}
    />
    <DropdownContainerStyles/>
  </>
);

export default RangePicker;