import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { 
  getLogoImpressao as getLogoImpressaoSdk, 
  postLogoImpressao as postLogoImpressaoSdk, 
  deleteLogoImpressao as deleteLogoImpressaoSdk
} from '@sdk/LogoImpressao';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_LOGO_IMPRESSAO: 'HOME/GET_LOGO_IMPRESSAO',
  GET_LOGO_IMPRESSAO_SUCCESS: 'HOME/GET_LOGO_IMPRESSAO_SUCCESS',
  GET_LOGO_IMPRESSAO_ERROR: 'HOME/GET_LOGO_IMPRESSAO_ERROR',

  POST_LOGO_IMPRESSAO: 'HOME/POST_LOGO_IMPRESSAO',
  POST_LOGO_IMPRESSAO_SUCCESS: 'HOME/POST_LOGO_IMPRESSAO_SUCCESS',
  POST_LOGO_IMPRESSAO_ERROR: 'HOME/POST_LOGO_IMPRESSAO_ERROR',

  DELETE_LOGO_IMPRESSAO: 'HOME/DELETE_LOGO_IMPRESSAO',
  DELETE_LOGO_IMPRESSAO_SUCCESS: 'HOME/DELETE_LOGO_IMPRESSAO_SUCCESS',
  DELETE_LOGO_IMPRESSAO_ERROR: 'HOME/DELETE_LOGO_IMPRESSAO_ERROR',
};

//Action Creators
export const getLogoImpressao = () => ({ type: Types.GET_LOGO_IMPRESSAO });
export const getLogoImpressaoSuccess = (logoImpressao) => ({ type: Types.GET_LOGO_IMPRESSAO_SUCCESS, logoImpressao });
export const getLogoImpressaoError = (error) => ({ type: Types.GET_LOGO_IMPRESSAO_ERROR, error });

export const postLogoImpressao = (url, empresa) => ({ type: Types.POST_LOGO_IMPRESSAO, url, empresa });
export const postLogoImpressaoSuccess = (logo) => ({ type: Types.POST_LOGO_IMPRESSAO_SUCCESS, logo });
export const postLogoImpressaoError = (error) => ({ type: Types.POST_LOGO_IMPRESSAO_ERROR, error });

export const deleteLogoImpressao = (logoImpressao) => ({ type: Types.DELETE_LOGO_IMPRESSAO, logoImpressao });
export const deleteLogoImpressaoSuccess = (logo) => ({ type: Types.DELETE_LOGO_IMPRESSAO_SUCCESS, logo });
export const deleteLogoImpressaoError = (error) => ({ type: Types.DELETE_LOGO_IMPRESSAO_ERROR, error });

//saga
function* fetchGetLogoImpressao(action) {
  try {
    const logoImpressao = yield call(getLogoImpressaoSdk);
    yield put(getLogoImpressaoSuccess(logoImpressao));
  } catch (err) {
    Notification.error(err.message);
    yield put(getLogoImpressaoError(err));
  }
}

function* fetchPostLogoImpressao(action) {
  try {
    const { url, empresa } = action;
    const logoImpressao = yield call(postLogoImpressaoSdk, url, empresa);
    yield put(postLogoImpressaoSuccess(logoImpressao));
  } catch (err) {
    Notification.error(err.message);
    yield put(postLogoImpressaoError(err));
  }
}

function* fetchDeleteLogoImpressao(action) {
  try {
    yield call(deleteLogoImpressaoSdk);
    yield put(deleteLogoImpressaoSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteLogoImpressaoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_LOGO_IMPRESSAO, fetchGetLogoImpressao),
  takeLatest(Types.POST_LOGO_IMPRESSAO, fetchPostLogoImpressao),
  takeLatest(Types.DELETE_LOGO_IMPRESSAO, fetchDeleteLogoImpressao),
];

// Reducer
const initialState = Map({
  logoImpressao: {},
  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,

  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
});

const handleGetLogoImpressao = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};
  
const handleGetLogoImpressaoSuccess = (state, action) => {
  const { logoImpressao } = action;
  return state
    .set('logoImpressao', logoImpressao)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};
  
const handleGetLogoImpressaoError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handlePostLogoImpressao = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};
  
const handlePostLogoImpressaoSuccess = (state, action) => {
  const { logoImpressao } = action;
  return state
    .set('logoImpressao', logoImpressao)
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};
  
const handlePostLogoImpressaoError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handleDeleteLogoImpressao = (state, action) => {
  return state
    .set('loadingDelete', true)
    .set('successDelete', false)
    .set('errorDelete', false);
};
  
const handleDeleteLogoImpressaoSuccess = (state, action) => {
  return state
    .set('loadingDelete', false)
    .set('successDelete', true)
    .set('errorDelete', false);
};
  
const handleDeleteLogoImpressaoError = (state, action) => {
  return state
    .set('loadingDelete', false)
    .set('successDelete', false)
    .set('errorDelete', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_LOGO_IMPRESSAO: return handleGetLogoImpressao(state, action);
    case Types.GET_LOGO_IMPRESSAO_SUCCESS: return handleGetLogoImpressaoSuccess(state, action);
    case Types.GET_LOGO_IMPRESSAO_ERROR: return handleGetLogoImpressaoError(state, action);

    case Types.POST_LOGO_IMPRESSAO: return handlePostLogoImpressao(state, action);
    case Types.POST_LOGO_IMPRESSAO_SUCCESS: return handlePostLogoImpressaoSuccess(state, action);
    case Types.POST_LOGO_IMPRESSAO_ERROR: return handlePostLogoImpressaoError(state, action);

    case Types.DELETE_LOGO_IMPRESSAO: return handleDeleteLogoImpressao(state, action);
    case Types.DELETE_LOGO_IMPRESSAO_SUCCESS: return handleDeleteLogoImpressaoSuccess(state, action);
    case Types.DELETE_LOGO_IMPRESSAO_ERROR: return handleDeleteLogoImpressaoError(state, action);
  
    default: return state;
  }
}