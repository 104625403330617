import React from 'react';
import { TypeAnimationStyled } from './styles';

const TypeAnimation = ({ text, repeat, wraper, maxHeight, ...props }) => {
  return (
    <TypeAnimationStyled
      sequence={[text, 1000]}
      wrapper={wraper || 'div'}
      repeat={repeat || 0}
      maxHeight={maxHeight}
      {...props}
    />
  );
};

export default TypeAnimation;