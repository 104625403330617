import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import App from '@app';

import { 
  Icon, 
  Body, 
  PageHeader, 
  Breadcrumb, 
  Form, 
  Input, 
  Button, 
  View, 
  Skeleton, 
  Tooltip, 
  StatusTag, 
  Column, 
  Avatar,
  Text,
  Row,
  Accountant 
} from '@components-teammove';

import { 
  AbsoluteStatusTag, 
  ActivityDueDateTimeContainer, 
  ActivityTitleWrapper, 
  ActivityTypeIcon, 
  ActivityTypeIconWrapper, 
  ChecklistContainer, 
  ChecklistIcon, 
  ChecklistName, 
  NameTypography, 
  InfoContainer, 
  SecondaryTypography, 
  CompanyName, 
  Container, 
  ContentContainer, 
  ContentSummaryContainer, 
  ContentText, 
  FillView, 
  Go, 
  InfoText, 
  MarginleftSubtitle, 
  StartButton,
  Subtitle, 
  UpdateContainer, 
  UpdateDate, 
  UpdateDescription, 
  UpdateDescriptionLabel, 
  UpdatesContainer, 
  UpdateTitle, 
  TimeMarker,
  ButtonStyle,
  ButtonFinish
} from './styles';

import { formatDate, formatTime, formatDateStringWithTime, InfoUsuario, TelasNomenclaturas, getDownloadUrlAvatar, moment, getCurrentLatLng, Notification } from '@utils';
import { getAtividadeDetalhe, finishAtividade, reset } from '@ducks/atividades';
import { getUserIntegrations, reset as resetIntegrations } from '@ducks/integracoes';
import { postCheckInOut , getCheckInOut } from '@ducks/atividadesCheckInOut';
import ActionModal from './ActionModal';
import Cadastro from '../Cadastro';
import { VerifyIfJourneyIsRunning } from './rules';

export default function Activity() {
  const { idActivity } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  
  const dispatch = useDispatch();
  
  const theme = useSelector(({ app }) => app.get('theme'));

  const activity = useSelector(({ atividades }) => atividades.get('atividadeDetalhe'));
  const loading = useSelector(({ atividades }) => atividades.get('loadingDetalhe'));
  const success = useSelector(({ atividades }) => atividades.get('successDetalhe'));
  const loadingFinish = useSelector(({ atividades }) => atividades.get('loadingFinish'));
  const successFinish = useSelector(({ atividades }) => atividades.get('successFinish'));
  const successCancel = useSelector(({ atividades }) => atividades.get('successCancel'));
  const successReopen = useSelector(({ atividades }) => atividades.get('successReopen'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const successCheckInOut = useSelector(({ atividadesCheckInOut }) => atividadesCheckInOut.get('successCheckInOut'));
  const loadingCheckInOut = useSelector(({ atividadesCheckInOut }) => atividadesCheckInOut.get('loadingCheckInOut'));
  const activityCheckInOut = useSelector(({ atividadesCheckInOut }) => atividadesCheckInOut.get('activityCheckInOut'));

  const [visibleActionModal, setVisibleActionModal] = useState(false);
  const [visibleActivityRegister, setVisibleActivityRegister] = useState(false);

  const finishedActivity = useMemo(() => activity?.status === 'CONCLUIDA', [activity]);
  const finishedChecklist = useMemo(() => activity?.checklistStatus === 'CONCLUIDO', [activity]);
  const cancelChecklist = useMemo(() => activity?.status === 'EXCLUIDA', [activity]);
  const allowChecklistFilling = useMemo(() => activity?.allowChecklistFilling, [activity]);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const workingJourney = InfoUsuario.get('workingJourney');

  useEffect(() => {
    dispatch(getAtividadeDetalhe(parseInt(idActivity)));
    dispatch(getUserIntegrations(InfoUsuario.get('cdUsuario')));

    return () => {
      dispatch(reset());
      dispatch(resetIntegrations());
    };
  }, [idActivity]);

  useEffect(() => {
    if (successCancel || successReopen || successFinish || successSave || successCheckInOut) {
      dispatch(getAtividadeDetalhe(parseInt(idActivity)));
      if(successCheckInOut) {
        dispatch(getCheckInOut());
      }
    }
  }, [successCancel, successReopen, successFinish, successSave, successCheckInOut]);

  const handleCancelClick = () => {
    if (activity?.idChecklistCancel) {
      sessionStorage.setItem('toRedirectToRespond', true);
      history.push(`/atividades/agenda/${idActivity}/checklist/${activity?.idChecklistCancel}`);
    } else {
      setVisibleActionModal('cancel');
    }
  };

  const handleChecklistClick = () => {
    if (finishedChecklist) {
      history.push(`/atividades/agenda/${idActivity}/checklist/${activity.statusDescription === 'cancelada' ? activity.idChecklistCancel : activity.idChecklist}/finished`);
    } else {
      history.push(`/atividades/agenda/${idActivity}/checklist/${activity?.idChecklist}`);
    }
  };

  const handleFinishClick = () => {
    form.validateFields().then(({ reason }) => {
      dispatch(finishAtividade(idActivity, reason));
    }).catch(Form.scrollToFirstError);
  };

  const handleChangeCheckInOut = (input) => {
    const getCheckInOutData = (coords) => {
      const data = {
        'data': moment().format('YYYY-MM-DD HH:mm:ss.SSS')
      };

      if (activity && activity.useCheckin === 'G') {
        data.latitude = coords.latitude;
        data.longitude = coords.longitude;
      }

      return data;
    };
    
    const apiCalls = (coords) => {
      if(activity?.useCheckout === 'N') {
        dispatch(postCheckInOut('in', activity.id, getCheckInOutData(coords)));
        dispatch(postCheckInOut('out', activity.id, getCheckInOutData(coords)));
      }else {
        dispatch(postCheckInOut(input, activity.id, getCheckInOutData(coords)));
      }
    };

    if (activity && activity.useCheckin === 'G') {
      getCurrentLatLng()
        .then((coords) => {
          apiCalls(coords);
        })
        .catch((error) => {
          Notification.error('Verifique se as permissões de localização estão ativas');
        });
    } else {
      apiCalls();
    }
    
  };
  
  const handleChangeViewCheckinDetails = () => {
    history.push(`/atividades/agenda/${idActivity}/checkin-details`, { idActivity } );
  };
  
  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={activity?.idCompany ? (
            <Row gap='14px' align='center'>
              <ActivityTitleWrapper>{activity?.title}</ActivityTitleWrapper>
              <ActivityTypeIconWrapper color={activity?.typeColor}>
                <ActivityTypeIcon type={activity?.typeIcon}/>
              </ActivityTypeIconWrapper>
              <StatusTag color={activity.statusColor} text={activity.statusDescription} size='average' fixedWidth='105px'/>
            </Row>
          ) : 'Atividade'}
          onBack={() => history.goBack()}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades/agenda', name: 'Agenda' },
                { path: `/ativididades/agenda/${idActivity}`, name: activity?.title || '...', current: true },
              ]}
            />
          )}
          extra={[
            (!loading && success && <Button size='small' disabled={!success} visible={activity?.canEdit && (finishedActivity || activity?.status === 'EXCLUIDA')} key='reopen' type='secondary' onClick={() => setVisibleActionModal('reopen')}>Reabrir</Button>),
            <Button size='small' disabled={!success} visible={activity?.canEdit && (activity?.status === 'NAO_INICIADO' || activity?.status === 'EM_ANDAMENTO')} key='cancel' type='secondary' onClick={() => handleCancelClick()}>Cancelar</Button>,
            <Button size='small' disabled={!success} visible={activity?.canEdit && (activity?.status === 'NAO_INICIADO' || activity?.status === 'EM_ANDAMENTO')} key='edit' type='secondary' onClick={() => setVisibleActivityRegister(true)}>Editar</Button>,
          ]}
        >
          <Container>
            {loading ? (
              <>
                <Row gap='12px'>
                  <FillView>
                    <Subtitle>
                      <Icon.Feather type='home'/>
                      <Skeleton.MinorLine/>
                    </Subtitle>
                    <InfoContainer>
                      <AbsoluteStatusTag text='+ info' disabled/>
                      <Row gap='13px' align='flex-end'>
                        <CompanyName><Skeleton.MinorLine/></CompanyName>
                        <NameTypography><Skeleton.MinorLine/></NameTypography>
                      </Row>
                      <View>
                        <SecondaryTypography><Skeleton.MinorLine/></SecondaryTypography>
                        <SecondaryTypography>
                          <strong><Skeleton.MinorLine/></strong>  
                        </SecondaryTypography>
                      </View>
                    </InfoContainer>
                  </FillView>

                  <View gap='10px'>
                    <Subtitle noneMargin>Prazo de conclusão</Subtitle>
                  
                    <Column gap='15px'>
                      <ContentContainer>
                        <InfoText>
                          <strong>
                            <Icon.Feather type='calendar'/>
                            Data: <Skeleton.MinorLine/>
                          </strong>
                        </InfoText>
                      </ContentContainer>
                      <ContentContainer>
                        <InfoText>
                          <strong>
                            <Icon.Feather type='clock'/>
                            Horário: <Skeleton.MinorLine/>
                          </strong>
                        </InfoText>
                      </ContentContainer>
                    </Column>
                  </View>
                </Row>
              </>
            ) : (
              <>
                {activity?.idCompany ? (
                  <Column gap='12px'>
                    <Row gap='20px' width='100%'>
                      <FillView>
                        <Subtitle>
                          <Icon.Feather type='home'/>
                          {companyNomenclature.nomenclatura}
                        </Subtitle>
                        <InfoContainer>
                          <AbsoluteStatusTag text='+ info' onClick={() => history.push(`/unidades/${activity?.idCompany}`)}/>
                          <Row gap='13px' align='flex-end'>
                            <CompanyName>{activity?.companyName}</CompanyName>
                            <NameTypography>{activity?.companyIdentifier}</NameTypography>
                          </Row>
                          <View>
                            <SecondaryTypography>{activity?.companyAddress} - {activity?.companyDistrict}</SecondaryTypography>
                            <SecondaryTypography>
                              <strong>{activity?.companyCity} - {activity?.companyState} - {activity?.companyCep ? activity?.companyCep?.includes('-') ? activity?.companyCep : (activity?.companyCep?.substring(0, 5) + '-' + activity?.companyCep?.substring(5)) : ''}</strong>  
                            </SecondaryTypography>
                          </View>
                        </InfoContainer>
                      </FillView>

                      <Column>
                        <Subtitle>Agendado para</Subtitle>
                        <Column gap='15px'>
                          <ContentContainer>
                            <InfoText>
                              <strong>
                                <Icon.Feather type='calendar'/>
                            Data: {formatDate(activity?.deadline)}
                              </strong>
                            </InfoText>
                          </ContentContainer>
                          <ContentContainer>
                            <InfoText>
                              <strong>
                                <Icon.Feather type='clock'/>
                                {activity?.isAllDay ? 'Sem horário' : `Horário: ${formatTime(activity?.start)} às ${formatTime(activity?.deadline)}`}
                              </strong>
                            </InfoText>
                          </ContentContainer>
                        </Column>
                      </Column>
                    </Row>
                    
                    {activity.creatorUserId !== InfoUsuario.get('cdUsuario') && (
                      <Row>
                        <FillView>
                          <Subtitle>
                            <Icon.Feather type='user'/>
                          Responsável
                          </Subtitle>
                          <InfoContainer>
                            <Row gap='12px'>
                              <Row align='center'>
                                <Avatar src={getDownloadUrlAvatar(activity?.creatorAvatarUrl)}/>
                              </Row>
                              <Column>
                                <NameTypography>
                                  {activity.creatorUserName}
                                </NameTypography>
                                <SecondaryTypography>
                                  {activity.creatorProfileDescription}
                                </SecondaryTypography>
                              </Column>
                            </Row>
                          </InfoContainer>
                        </FillView>
                      </Row>
                    )}
                    
                  </Column>
                ) : (
                  <ContentSummaryContainer>
                    <Row justify='space-between' align='center'>
                      <Row gap='14px' align='center'>
                        <CompanyName>{activity?.title}</CompanyName>
                        <ActivityTypeIconWrapper color={activity?.typeColor}>
                          <ActivityTypeIcon type={activity?.typeIcon}/>
                        </ActivityTypeIconWrapper>
                        <StatusTag color={activity.statusColor} text={activity.statusDescription} size='average' fixedWidth='105px'/>
                      </Row>

                      <Column gap='8px'>
                        <MarginleftSubtitle>{finishedActivity ? 'Realizado em' : 'Quando'}</MarginleftSubtitle>
                        <ActivityDueDateTimeContainer>
                          <Row gap='16px'>
                            <InfoText>
                              <strong>
                                <Icon.Feather type='calendar'/>
                                Data: {formatDate(finishedActivity ? activity?.finishedAt : activity?.deadline)}
                              </strong>
                            </InfoText>
                            <InfoText>
                              <strong>
                                <Icon.Feather type='clock'/>
                                {finishedActivity ? `Horário: ${formatTime(activity?.start)} às ${formatTime(activity?.finishedAt)}` : activity?.isAllDay ? 'Sem horário' : `Horário: ${formatTime(activity?.start)} às ${formatTime(activity?.deadline)}`}
                              </strong>
                            </InfoText>
                          </Row>
                        </ActivityDueDateTimeContainer>
                      </Column>
                    </Row>
                  </ContentSummaryContainer>
                )}
                {((activity?.useCheckin !== 'N' && activity?.statusDescription !== 'cancelada' ) || activity?.typeMetric === 'CHECKLIST' ) && (
                  
                  <Row width='100%' gap='12px'>
                    {(activity?.useCheckin !== 'N' &&  activity?.statusDescription !== 'cancelada' ) && (
                      <Column width='100%'>
                        <Subtitle>
                          <Icon.Feather type='map-pin'/>
                          Check-in
                        </Subtitle>

                        {!finishedChecklist && activity?.useCheckin !== 'N' && !activity?.checkin ? (
                          <ChecklistContainer height='100%'>
                            <Tooltip title={workingJourney && !VerifyIfJourneyIsRunning() ? 'Não é possível iniciar checkin fora da jornada' : activityCheckInOut?.checkinDate && 'finalize o checkin que está em andamento para iniciar outro'} >
                              <Row align='center' justify='center' width='100%'>
                                <ButtonStyle 
                                  fullWidth
                                  onClick={() => {
                                    (!workingJourney || (workingJourney && VerifyIfJourneyIsRunning())) 
                                  && !activityCheckInOut?.checkinDate && handleChangeCheckInOut('in');
                                  }
                                  } 
                                  disabled={(workingJourney && !VerifyIfJourneyIsRunning()) || activityCheckInOut?.checkinDate}
                                  loading={loadingCheckInOut}
                                >Iniciar Checkin</ButtonStyle>
                              </Row>
                            
                            </Tooltip>
                            
                          </ChecklistContainer>
                        ) : (
                          <ChecklistContainer height='100%' padding='unset unset unset 12px'>
                            {!activity?.checkout && activity?.checkin ? (
                              <Row align='center' justify='space-between'>
                                <Column gap='8px'>
                                  <Text size='12px' weight='700'>Em andamento</Text>
                                  <TimeMarker>
                                    <Accountant time={activity?.checkin} size='16px' weight='400' color='var(--success-color)'/>
                                  </TimeMarker>
                                </Column>
                                <ButtonFinish 
                                  type='danger'
                                  loading={loadingCheckInOut}
                                  onClick={()=>handleChangeCheckInOut('out')}
                                >
                                  Encerrar
                                </ButtonFinish>
                              </Row> 
                            ) : activity?.checkin && activity?.checkout && (
                              <Row align='center' justify='space-between'>
                                <Column gap='8px'>
                                  <Text size='12px' weight='700'>Duração</Text>
                                  <TimeMarker>
                                    <Text size='16px' weight='400'>{moment.utc(moment(activity?.checkout).diff(moment(activity?.checkin))).format('HH:mm:ss')}</Text>
                                  </TimeMarker>
                                </Column>
                                
                                <ButtonStyle onClick={handleChangeViewCheckinDetails}>
                                  <Icon.Feather type='map-pin' />
                                  <Text size='16px' weight='600'>Ver detalhes</Text>
                                </ButtonStyle>
                              </Row> 
                            )}
                        
                          </ChecklistContainer> 
                        )}
                        
                      </Column>
                    )}
                    {activity?.typeMetric === 'CHECKLIST' && (
                      <Column width='100%'>
                        <Subtitle>
                          <Icon.Feather type='check-square'/>
                        Checklists
                        </Subtitle>
                        <ChecklistContainer height='100%'>
                          <Row justify='space-between' align='center'>
                            <Row gap='20px' align='center'>
                              <ChecklistIcon type={activity?.typeIcon}/>
                              <ChecklistName>{activity?.templateName}</ChecklistName>
                            </Row>
                            <Tooltip title={!finishedChecklist && !allowChecklistFilling && 'Atividade com check-in. Inicie o check-in antes de responder'}>
                              <div>
                                <StartButton type='primary' onClick={() => handleChecklistClick()} disabled={(!finishedChecklist && !allowChecklistFilling) || (cancelChecklist && !finishedChecklist)}>
                                  {(finishedChecklist || cancelChecklist) ? 'Visualizar' : 'Preencher'}
                                  <Go type='arrow-right-short'/>
                                </StartButton>
                              </div>
                            </Tooltip>
                          </Row>
                        </ChecklistContainer>
                      </Column>
                    )}
                  </Row>
                )}
                
                {activity?.notes && (
                  <View>
                    <Subtitle>
                      <Icon.Feather type='info'/>
                        Observações
                    </Subtitle>
                    <ContentContainer usePrimaryColor>
                      <ContentText>{activity?.notes}</ContentText>
                    </ContentContainer>
                  </View>
                )}
            
                <Row gap='12px'>
                  {(activity?.status === 'EM_ANDAMENTO' || activity?.status === 'NAO_INICIADO') && activity?.typeMetric !== 'CHECKLIST' && (
                    <FillView>
                      <Subtitle>
                        <Icon.Feather type='check-square'/>
                        {activity?.typeMetric === 'PLANO_DE_ACAO' ? 'Plano de Ação' : 'Atividade'}
                      </Subtitle>
                      <ContentContainer>
                        <Form form={form} layout='vertical'>
                          <Form.Item name='reason' label={activity?.typeMetric === 'PLANO_DE_ACAO' ? 'Plano de Ação' : 'Atividade'}>
                            <Input.TextArea placeholder='Escreva sua observação aqui'/>
                          </Form.Item>
                          <Button size='small' type='primary' loading={loadingFinish} onClick={() => handleFinishClick()}>{activity?.typeMetric === 'PLANO_DE_ACAO' ? 'Responder' : 'Finalizar'}</Button>
                        </Form>
                      </ContentContainer>
                    </FillView>
                  )}
                  {activity?.historic?.length > 0 && (
                    <FillView>
                      <Subtitle>
                        <Icon.Feather type='info'/>
                        Atualizações
                      </Subtitle>
                      <UpdatesContainer>
                        {activity?.historic.map(({ id, status, date, description }) => (
                          <UpdateContainer key={id}>
                            <Row justify='space-between' align='center'>
                              <UpdateTitle>{status}</UpdateTitle>
                              <UpdateDate>{formatDateStringWithTime(date)}</UpdateDate>
                            </Row>
                            {description && (
                              <View>
                                <UpdateDescriptionLabel>Descrição</UpdateDescriptionLabel>
                                <UpdateDescription>{description}</UpdateDescription>
                              </View>
                            )}
                          </UpdateContainer>
                        ))}
                      </UpdatesContainer>
                    </FillView>
                  )}
                </Row>
              </>
            )}
          </Container>
        </PageHeader>

        {visibleActionModal && (
          <ActionModal
            visible={!!visibleActionModal}
            onCancel={() => setVisibleActionModal(false)}
            actionType={visibleActionModal}
          />
        )}

        {visibleActivityRegister && (
          <Cadastro visible={visibleActivityRegister} onCancel={() => setVisibleActivityRegister(false)}/>
        )}
      </Body>
    </App>
  );
}