import React, { useEffect, useState } from 'react';
import { Column, Text, Row, Switch, Form, Skeleton, LoadingCircle, Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getTableValidation } from '@ducks/companyImport';
import { EditableCell } from './EditableCell';
import { createDatasource } from './rules';
import { TableStyle } from './styles';
import { TelasNomenclaturas } from '@utils';

export const ValidationTable = ({ setHasChanges, currentPage, numberOfRowsPerPage, idTable, setCountError }) => {
  const dispatch = useDispatch();
  
  const [form] = Form.useForm();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const tableValidation = useSelector(({ companyImport }) => companyImport.get('tableValidation'));
  const loadingTableValidation = useSelector(({ companyImport }) => companyImport.get('loadingTableValidation'));
  const successTableValidation = useSelector(({ companyImport }) => companyImport.get('successTableValidation'));

  const [ rowFormatted, setRowFormatted ] = useState([]);
  
  const [ showOnlyErrors, setShowOnlyErrors ] = useState(false) ;

  useEffect(() => {
    dispatch(getTableValidation(idTable, showOnlyErrors, numberOfRowsPerPage, currentPage));
  },[showOnlyErrors, numberOfRowsPerPage, currentPage]);

  useEffect(() => {
    if(successTableValidation || tableValidation?.rows?.length > 0) {
      setCountError(tableValidation?.cellWithErrors);
      setRowFormatted(createDatasource(tableValidation.rows));
      sessionStorage.setItem('VALIDATION', JSON.stringify([]));
      setHasChanges(false);
    }
  },[successTableValidation,tableValidation]);

  const handleOnBlur = (e,  dataRow, importCompanyKey, oldValue) => {
  
    const filteredByCompanyKeyData = Object.fromEntries(
      Object.entries(dataRow).filter(([key, value]) => key.startsWith(importCompanyKey + '_'))
    );
    if(filteredByCompanyKeyData[importCompanyKey + '_value'] !== oldValue) {
      const formattedObject = {
        value: filteredByCompanyKeyData[importCompanyKey + '_value'],
        importCompanyValueId: filteredByCompanyKeyData[importCompanyKey + '_importCompanyValueId' ],
      };
  
      const itensValidated = () => {
        const validateObject = JSON.parse(sessionStorage.getItem('VALIDATION'));
        if(!validateObject || validateObject.length === 0) {
          return [formattedObject];
        }else {
          const verifyObjectExists = validateObject.some((item) => item.importCompanyValueId === formattedObject.importCompanyValueId);
          if(verifyObjectExists) {
            return validateObject.map((item)=> item.importCompanyValueId === formattedObject.importCompanyValueId ? { ...item, value: formattedObject.value } : item);
           
          }else {
            return [...validateObject, formattedObject];
          }
        }
      };
      sessionStorage.setItem('VALIDATION', JSON.stringify(itensValidated()));
      setHasChanges(true);
    }
    
  };

  const columns = tableValidation.headers?.map(({ columnName, importCompanyKey }, index, array) => ({
    title: columnName,
    dataIndex: `${importCompanyKey}_value`,
    sorter: false,
    lastColumn: array.length - 1 === index, 
    editable: true,
    width: '200px',
    key:`${importCompanyKey}_value`
  }));

  const mergedColumns = columns?.map((col)=> {
    if(!col.editable || !(rowFormatted?.length > 0)) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        key: col.dataIndex,
        form: form,
        dataIndex: col.dataIndex,
        title: col.title,
        lastColumn: col.lastColumn,
        rowFormatted: rowFormatted,
        handleOnBlur,
      }),
    };
  });

  return(
    <Column gap='20px' padding='0 8px 20px 8px'>
      <Column gap='8px'>
        <Text size='24px' weight='700'>Validação dos dados</Text>
        <Column>
          <Row gap='12px' align='center'>
            <Text size='16px' weight='400'>Total de {companyNomenclature.plural} </Text>
            {loadingTableValidation ? <Skeleton.MinorLine/> : <Text size='16px' weight='400'>{tableValidation?.totalImportedRows}</Text>}
              
            {tableValidation?.cellWithErrors > 0 && <Text size='16px' weight='400' color='var(--danger-color)'>{tableValidation?.cellWithErrors} linhas com erros</Text>}
          </Row>
          
          <Row align='center' justify='space-between'>
            <Text size='16px' weight='400' lowOpacity>Confira os dados e faça os ajustes necessários para a importação</Text>
            
            {!loadingTableValidation && (
              !!tableValidation?.cellWithErrors && (
                <Row align='center' gap='16px'>
                  <Switch loading={loadingTableValidation} checked={showOnlyErrors} onChange={()=> {dispatch(getTableValidation(idTable, !showOnlyErrors, numberOfRowsPerPage, currentPage));setShowOnlyErrors(!showOnlyErrors);}}/>
                  <Text size='16px' weight='400' lowOpacity>Mostrar somente linhas com erros</Text>
                </Row>
              )
            )}

          </Row>
        </Column>
      </Column>
      {loadingTableValidation ? 
        <Column width='100%' height='340px' align='center' justify='center' gap='16px'>
          <LoadingCircle loading radius={80} />
          <Text size='20px' weight='400'>Validando dados da planilha. Isso pode demorar um pouco...</Text>
        </Column>
        : successTableValidation && tableValidation?.rows?.length > 0 ? (

          columns.length > 0 && rowFormatted.length > 0 && mergedColumns.length > 0 ?
            (
              <>
                <TableStyle
                  hidePagination
                  components={{
                    body:{
                      cell: EditableCell,
                    }
                  }}
                  scroll={{ x: 1300, y: 250 }}
                  columns={mergedColumns}
                  dataSource={rowFormatted}
                  loading={loadingTableValidation}
                />
              </>
            ) : (
              <Column align='center'>
                <Icon.MDI size='100px' type='close-circle-outline' color='var(--danger-color)'/>
                <Text size='20px' weight='400'>Não foi possível importar as unidades1</Text>
              </Column>
            )
        ) : (
          <Column align='center'>
            <Icon.MDI size='100px' type='close-circle-outline' color='var(--danger-color)'/>
            <Text size='20px' weight='400'>Sem dados</Text>
          </Column>
        )
          
      }
    </Column>

  );
};