const { REACT_APP_API } = window.env;

const defaultUser = {
  permissoes: {},
  permissoesExtras: {},
};

const set = (infoUsuario) => {
  return localStorage.setItem('infoUsuario', JSON.stringify(infoUsuario));
};

const get = (field) => {
  let infoUsuario = localStorage.getItem('infoUsuario');

  try {
    infoUsuario = JSON.parse(infoUsuario);
    if (field) {
      return infoUsuario[field];
    }
    infoUsuario = infoUsuario || defaultUser;
    infoUsuario.permissoes = infoUsuario.permissoes || {};
    infoUsuario.permissoesExtras = infoUsuario.permissoesExtras || {};
    return infoUsuario;
  } catch (err) {
    return defaultUser;
  }
};

const remove = () => {
  for (const item in localStorage) {
    if (item.includes('FILTROS/')) {
      localStorage.removeItem(item);
    }
  }
  return localStorage.removeItem('infoUsuario');
};

const hasUser = (infoUsuario) => {
  if (!infoUsuario) {
    infoUsuario = get();
  }

  return !!((infoUsuario) && (infoUsuario.key) && (infoUsuario.token));
};

const getAvatar = (cliente, usuario, showDefault = true) => {
  if ((!cliente) || (!usuario)) {
    const infoUsuario = get();
    cliente = infoUsuario.cdCliente;
    usuario = infoUsuario.cdUsuario;
  }

  return cliente && usuario ? `${REACT_APP_API}/images/users?cliente=${cliente}&usuario=${usuario}&default=${showDefault}` : '';
};

const perm = (field, infoUsuario) => {
  if (!infoUsuario) {
    infoUsuario = get();
  }
  return !!infoUsuario.permissoes[field];
};

const permExtras = (field, infoUsuario) => {
  if (!infoUsuario) {
    infoUsuario = get();
  }
  return !!infoUsuario.permissoesExtras[field];
};

const permExtrasArray = (field, infoUsuario) => {
  if (!infoUsuario) {
    infoUsuario = get();
  }
  const extra = infoUsuario.permissoesExtras[field];
  return extra && extra.split(',');
};

export default {
  set,
  get,
  remove,
  hasUser,
  getAvatar,
  perm,
  permExtras,
  permExtrasArray,
  defaultUser,
};
