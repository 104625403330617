import styled from 'styled-components';
import { Text, Column } from '@components-teammove';

export const Card = styled.div`
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  width: 100%;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: ${({ percent }) => percent ? 400 : 700};
  opacity: ${({ percent }) => percent ? 0.5 : 1};
  line-height: 21.79px;
  margin: ${({ titleDaily }) => titleDaily ? '12px 0' : '0'};
  margin-right: ${({ titleActivity }) => titleActivity ? '10px' : '0'};
  color: ${({ theme }) => theme.textColor};
  width: ${({ titleDaily }) => titleDaily ? '100%' : 'auto'};
  padding-left: ${({ titleDaily }) => titleDaily ? '12px' : '0'};
`;

export const Description = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
`;

export const IconContainer = styled.div`
  margin-right: 12px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerProgress = styled.div`
  margin-top: 12px;
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const ProgressText = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.textColor};
  height: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColumnCongratsText = styled(Column)`
 
`;