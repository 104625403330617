import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: ${({ itemsPerPage }) => '1fr'.repeat(itemsPerPage).replaceAll('1fr', '1fr ').trim()};
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  gap: 12px;
`;

export const PageIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  ${({ current, theme }) => current ? `
    background-color: ${theme.darkHighlightColor};
  ` : `
    border: 1px solid ${theme.darkHighlightColor};
  `}
`;