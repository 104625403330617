import React from 'react';
import { View } from '@components';
import styled from 'styled-components';
import { formatDateTime, formatDecimal, prettyTimeDifference } from '@utils';
import { Desistencia } from '@containers/Wikis/Conteudos/Visualizacao/Quiz/rules';

const Field = styled(View)`
  width: ${({ width = '100%' }) => width};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: center;
  margin: 0 3px;
  white-space: initial;
  text-align: center;
`;

const Header = styled(Field)`
  font-weight: bold;
`;

const Row = styled(View)`
  width: 100%;
  align-items: center;
`;

const HeaderGroup = styled(Row)`
  border-bottom: 1px solid #dddddd;
`;

const DataRow = styled(Row)`
  padding: 5px 0px;
  cursor: pointer;

  :hover {
    background: #eeeeee;
  }
`;

export default function CollapsePanel({ data, handlers }) {
  const { usuario } = data;
  const { tentativas } = usuario;
  const { handleVisualizarResultado } = handlers;

  return (
    tentativas?.length > 0 && (
      <View>
        <HeaderGroup horizontal>
          <Header width='20%'>Tentativa</Header>
          <Header horizontal width='80%'>
            <Header width='25%'>Início</Header>
            <Header width='25%'>Fim</Header>
            <Header width='25%'>Duração</Header>
            <Header width='25%'>Taxa de Acerto</Header>
          </Header>
        </HeaderGroup>
        {tentativas.map((tentativa) => {
          const { id, tentativa: nrTentativa, desistencia, dtInicio, dtFim, total, nota } = tentativa;

          return (
            <DataRow
              key={id}
              horizontal
              onClick={(event) => {
                if (!desistencia && dtFim) {
                  event.preventDefault();
                  event.stopPropagation();
                  handleVisualizarResultado(id, usuario);
                }
              }}
            >
              <Field width='20%'>{nrTentativa}</Field>
              <Field horizontal width='80%'>
                {desistencia ? (
                  <Field horizontal width='100%'>
                    <Field width='25%'>{formatDateTime(dtInicio)}</Field>
                    <Field width='25%'>
                      <Desistencia>Não finalizada</Desistencia>
                    </Field>
                    <Field width='50%' />
                  </Field>
                ) : (
                  <Field horizontal>
                    <Field width='25%'>{formatDateTime(dtInicio)}</Field>
                    <Field width='25%'>{formatDateTime(dtFim)}</Field>
                    <Field width='25%'>{prettyTimeDifference(dtInicio, dtFim).extenseDifference}</Field>
                    <Field width='25%'>{formatDecimal((nota / total) * 100, 2)}%</Field>
                  </Field>
                )}
              </Field>
            </DataRow>
          );
        })}
      </View>
    )
  );
}
