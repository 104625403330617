import { del, get, post, put } from '../api';
import { formatFilters } from './rules';
import { InfoUsuario } from '@utils';

export const getOrders = (filters) => {
  return get(`/api/orders?${formatFilters(filters)}&userId=${InfoUsuario.get('cdUsuario')}`);
};

export const postOrder = (order) => {
  return post(`/api/orders?userId=${InfoUsuario.get('cdUsuario')}`, order);
};

export const putOrder = (order) => {
  return put(`/api/orders?userId=${InfoUsuario.get('cdUsuario')}`, order);
};

export const deleteOrderItem = (orderItemId) => {
  return del(`/api/orders/${orderItemId}`);
};

export const getOrderRegister = (orderId) => {
  return get(`/api/orders/${orderId}`);
};

export const getDetailedOrder = (orderId) => {
  return get(`/api/orders/${orderId}/detailed`);
};

export const putStatus = ({ orderId, newStatus }) => {
  return put(`/api/orders/${orderId}/status`, { orderId, newStatus, userId: InfoUsuario.get('cdUsuario') });
};

export const postUploads = (orderId, uploads) => {
  return post(`/api/orders/${orderId}/uploads?userId=${InfoUsuario.get('cdUsuario')}`, uploads);
};

export const deleteUpload = (uploadId) => {
  return del(`/api/orders/uploads/${uploadId}?userId=${InfoUsuario.get('cdUsuario')}`);
};