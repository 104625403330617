import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getOrdersKinds as getOrdersKindsSdk,
  getOrderKind as getOrderKindSdk,
  postOrderKind as postOrderKindSdk,
  putOrderKind as putOrderKindSdk,
} from '@sdk/OrdersKinds';

//Action Types
export const Types = {
  GET_ORDERS_KINDS: 'ORDERS/GET_ORDERS_KINDS',
  GET_ORDERS_KINDS_SUCCESS: 'ORDERS/GET_ORDERS_KINDS_SUCCESS',
  GET_ORDERS_KINDS_ERROR: 'ORDERS/GET_ORDERS_KINDS_ERROR',

  GET_ORDER_KIND: 'ORDERS/GET_ORDER_KIND',
  GET_ORDER_KIND_SUCCESS: 'ORDERS/GET_ORDER_KIND_SUCCESS',
  GET_ORDER_KIND_ERROR: 'ORDERS/GET_ORDER_KIND_ERROR',

  POST_ORDER_KIND: 'ORDER/POST_ORDER_KIND',
  POST_ORDER_KIND_SUCCESS: 'ORDER/POST_ORDER_KIND_SUCCESS',
  POST_ORDER_KIND_ERROR: 'ORDER/POST_ORDER_KIND_ERROR',
  
  PUT_ORDER_KIND: 'ORDER/PUT_ORDER_KIND',
  PUT_ORDER_KIND_SUCCESS: 'ORDER/PUT_ORDER_KIND_SUCCESS',
  PUT_ORDER_KIND_ERROR: 'ORDER/PUT_ORDER_KIND_ERROR',
  
  RESET: 'ORDERS/RESET',

  RESET_SUCCESS: 'ORDERS/RESET_SUCCESS',
};

//Action Creators
export const getOrdersKinds = () => ({ type: Types.GET_ORDERS_KINDS });
export const getOrdersKindsSuccess = (ordersKinds) => ({ type: Types.GET_ORDERS_KINDS_SUCCESS, ordersKinds });
export const getOrdersKindsError = (error) => ({ type: Types.GET_ORDERS_KINDS_ERROR, error }); 

export const getOrderKind = (orderKindId) => ({ type: Types.GET_ORDER_KIND, orderKindId });
export const getOrderKindSuccess = (orderKind) => ({ type: Types.GET_ORDER_KIND_SUCCESS, orderKind });
export const getOrderKindError = (error) => ({ type: Types.GET_ORDER_KIND_ERROR, error }); 

export const postOrderKind = (orderKind) => ({ type: Types.POST_ORDER_KIND, orderKind });
export const postOrderKindSuccess = (orderKind) => ({ type: Types.POST_ORDER_KIND_SUCCESS, orderKind });
export const postOrderKindError = (error) => ({ type: Types.POST_ORDER_KIND_ERROR, error });

export const putOrderKind = (orderKind) => ({ type: Types.PUT_ORDER_KIND, orderKind });
export const putOrderKindSuccess = (orderKind) => ({ type: Types.PUT_ORDER_KIND_SUCCESS, orderKind });
export const putOrderKindError = (error) => ({ type: Types.PUT_ORDER_KIND_ERROR, error });

export const reset = () => ({ type: Types.RESET });

export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

//saga
function* fetchGetOrdersKinds(action) {
  try{
      
    const ordersKinds = yield call(getOrdersKindsSdk);
    yield put(getOrdersKindsSuccess(ordersKinds));
  } catch(err) {
    Notification.error(err.message);
    yield put(getOrdersKindsError(err));
  }
}

function* fetchGetOrderKind(action) {
  try {
    const { orderKindId } = action;
    const orderKind = yield call(getOrderKindSdk, orderKindId);
    yield put(getOrderKindSuccess(orderKind));
  } catch (err) {
    Notification.error(err.message);
    yield put(getOrderKindError(err));
  }
}

function* fetchPostOrderKind(action) {
  try{
    let { orderKind } = action;
    orderKind = yield call(postOrderKindSdk, orderKind);
    yield put(postOrderKindSuccess(orderKind));
  } catch (err) {
    Notification.error(err.message);
    yield put(postOrderKindError(err));
  }
}

function* fetchPutOrderKind(action) {
  try{
    let { orderKind } = action;
    orderKind = yield call(putOrderKindSdk, orderKind);
    yield put(putOrderKindSuccess(orderKind)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(putOrderKindError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ORDERS_KINDS, fetchGetOrdersKinds),
  takeLatest(Types.GET_ORDER_KIND, fetchGetOrderKind),
  takeLatest(Types.POST_ORDER_KIND, fetchPostOrderKind),
  takeLatest(Types.PUT_ORDER_KIND, fetchPutOrderKind),
];

// Reducer
const initialState = Map({
  ordersKinds: List(),
  loadingOrdersKinds: false,
  successOrdersKinds: false,
  errorOrdersKinds: false,

  orderKind: List(),
  loadingOrderKind: false,
  successOrderKind: false,
  errorOrderKind: false,

  loadingOrderKindSave: false,
  successOrderKindSave: false,
  errorOrderKindSave: false,
  
});

const handleGetOrdersKinds = (state, action) => {
  return state.set('loadingOrdersKinds', true).set('successOrdersKinds', false).set('errorOrdersKinds', false);
};
      
const handleGetOrdersKindsSuccess = (state, action) => {
  const { ordersKinds } = action;
  return state.set('ordersKinds', ordersKinds).set('loadingOrdersKinds', false).set('successOrdersKinds', true).set('errorOrdersKinds', false);
};
      
const handleGetOrdersKindsError = (state, action) => {
  return state.set('loadingOrdersKinds', false).set('successOrdersKinds', false).set('errorOrdersKinds', action.error);
};

const handleGetOrderKind = (state, action) => {
  return state.set('loadingOrderKind', true).set('successOrderKind', false).set('errorOrderKind', false);
};
  
const handleGetOrderKindSuccess = (state, action) => {
  const { orderKind } = action;
  
  return state.set('orderKind', orderKind).set('loadingOrderKind', false).set('successOrderKind', true).set('errorOrderKind', false);
};
  
const handleGetOrderKindError = (state, action) => {
  return state.set('loadingOrderKind', false).set('successOrderKind', false).set('errorOrderKind', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingOrderKindSave', true).set('successOrderKindSave', false).set('errorOrderKindSave', false);
};
  
const handleSaveSuccess = (state, action) => {
  return state.set('loadingOrderKindSave', false).set('successOrderKindSave', true).set('errorOrderKindSave', false);
};
  
const handleSaveError = (state, action) => {
  return state.set('loadingOrderKindSave', false).set('successOrderKindSave', false).set('errorOrderKindSave', action.error);
};

const handleReset = (action, state) => {
  return initialState;
};

const handleResetSuccess = (state, action) => {
  return state.set('successOrderKind', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ORDERS_KINDS:
      return handleGetOrdersKinds(state, action);
    case Types.GET_ORDERS_KINDS_SUCCESS:
      return handleGetOrdersKindsSuccess(state, action);
    case Types.GET_ORDERS_KINDS_ERROR:
      return handleGetOrdersKindsError(state, action);
  
    case Types.GET_ORDER_KIND:
      return handleGetOrderKind(state, action);
    case Types.GET_ORDER_KIND_SUCCESS:
      return handleGetOrderKindSuccess(state, action);
    case Types.GET_ORDER_KIND_ERROR:
      return handleGetOrderKindError(state, action);

    case Types.POST_ORDER_KIND:
      return handleSave(state, action);
    case Types.POST_ORDER_KIND_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_ORDER_KIND_ERROR:
      return handleSaveError(state, action);
      
    case Types.PUT_ORDER_KIND:
      return handleSave(state, action);
    case Types.PUT_ORDER_KIND_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_ORDER_KIND_ERROR:
      return handleSaveError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);
      
    default:
      return state;
  }
}
  