import React, { useEffect, useState } from 'react';
import { Modal, Column, Row } from '@components-teammove';
import { TYPE_ASK, getIconFromExtension } from './rules';
import { isImage, download } from '@utils';
import { isImageUrl } from 'antd/lib/upload/utils';
import {
  QuestionView,
  FileName,
  FileView,
  ImagePreview,
  ContentFiles,
  AnswerContainer,
  QuestionText,
  TextAnswer,
  QuestionContainer,
  IconFeather,
} from './styles';
import moment from 'moment';

const Pergunta = ({
  pergunta,
  id,
  respostaString,
  imagesLoaded,
  onImageLoaded,
  impressao,
  color
}) => {
  const getOptionTitle = (idOption) =>

    pergunta.opcoes.find((opt) => opt.id === idOption).titulo;

  const [respostaElement, setRespostaElement] = useState(respostaString);
  const [selectedFile, setSelectedFile] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const handleLoad = (item) => {
    onImageLoaded(item);
  };

  useEffect(() => {
    if(!respostaString) {
      setRespostaElement(
        null
      );
    }
    else if (pergunta.opcoes) {
      if (respostaString?.includes(',')) {
        setRespostaElement(
          <AnswerContainer impressao={impressao}>
            <TextAnswer
              impressao={impressao}
              key={respostaString}
              strong
            >
              {respostaString
                .split(',')
                .map((ans) => getOptionTitle(parseInt(ans)))
                .join(', ')}
            </TextAnswer>
          </AnswerContainer>
        );
      } else {
        
        setRespostaElement(
          <AnswerContainer impressao={impressao}>
            <TextAnswer impressao={impressao} strong>
              {getOptionTitle(parseInt(respostaString))}
            </TextAnswer>
          </AnswerContainer>
        );
      }
    } else if (pergunta.tipo === TYPE_ASK.ANEXO) {
      const uploads = respostaString.split(',');
      const { images, videos, files } = uploads.reduce(
        (state, upload) => {
          const getUploadType = (upload) => {
            if (isImage(upload) && isImageUrl(upload)) {
              return 'images';
            } else if (upload.slice(-3) === 'mp4') {
              return 'videos';
            } else {
              return 'files';
            }
          };

          return {
            ...state,
            [getUploadType(upload)]: [...state[getUploadType(upload)], upload],
          };
        },
        { images: [], videos: [], files: [] }
      );

      videos.forEach((video) => handleLoad(video));

      setRespostaElement(() => (
        <AnswerContainer impressao={impressao}>
          <ContentFiles>
            {images.map((item) => (
              <a href={item} key={item} target="_blank" rel="noopener noreferrer">
                <ImagePreview                 
                  src={item}
                  alt=''
                  onClick={(e) => handleOpenModal(item, e)}
                  onLoad={() => {
                    handleLoad(item);
                  }}
                />
              </a>
            ))}
          </ContentFiles>

          <Column gap='5px'>
            {videos.map((item) => (
              <a href={item} key={item} target="_blank" rel="noopener noreferrer">
                <FileView key={item}>
                  <Row align='center' justify='center' padding='12px 0 12px 0'>
                    <IconFeather hover type={getIconFromExtension(item)} onClick={(e)=> handleOpenModal(item, e)}/>
                    <FileName
                      color={color}
                      strong
                      onClick={() => download(item)}
                    >
                      {getFileName(item)}
                    </FileName>
                  </Row>
                </FileView>
              </a>
            ))}
          </Column>

          <Column gap='12px'>
            {files?.map((item) => (
              <>
                {item === '' ? null : (
                  <a href={item} key={item} target="_blank" rel="noopener noreferrer">
                    <FileView key={item}>
                      <Row align='center' justify='center'>
                        <IconFeather type={getIconFromExtension(item)}/>
                        <FileName
                          color={color}
                          strong
                          onClick={(e) => {e.preventDefault(); download(item);}}
                        >
                          {item.substring(getFileName(item)).match(/\/([^/]+)$/)[1]}
                        </FileName>
                      </Row>
                  
                    </FileView>
                  </a>
                )}
              </>
            ))}
          </Column>

        </AnswerContainer>
      ));
    } else if(pergunta.tipo === TYPE_ASK.DATA) {
      const data = moment(respostaString);
      const dataFormatada = data.format('DD/MM/YYYY');
      setRespostaElement(
        <AnswerContainer impressao={impressao}>
          <TextAnswer impressao={impressao} strong>{dataFormatada}</TextAnswer>
        </AnswerContainer>
      );
    }
    else {
      setRespostaElement(
        <AnswerContainer impressao={impressao}>
          <TextAnswer impressao={impressao} strong>{respostaString}</TextAnswer>
        </AnswerContainer>
      );
    }
  }, []);

  const handleOpenModal = (file, event) => {
    event.preventDefault();
    setModalVisible(true);
    setSelectedFile(file);
  };

  const getFileName = (file) => {
    return file.substring(file.lastIndexOf('/') + 1);
  };

  return (
    <>
      {respostaString && (
        <QuestionView key={id}>
          <QuestionContainer>
            <QuestionText>{`${pergunta.ordem + 1}. ${
          pergunta.titulo
        }`}</QuestionText>
          </QuestionContainer>
          <QuestionContainer impressao={impressao}>
            {respostaElement}
            {respostaString && modalVisible && (
              <Modal.Picture
                onCancel={() => setModalVisible(false)}
                pictures={imagesLoaded.map((img) => img)}
                initialImageIndex={imagesLoaded.findIndex(
                  (img) => img === selectedFile
                )}
              />
            )}
          </QuestionContainer>
        </QuestionView>
      )}
    </>
  );
};

const Perguntas = ({ perguntas, onImagesLoad, impressao, color }) => {
  const [imagesLoaded, setImagesLoaded] = useState([]);

  useEffect(() => {
    if (perguntas && onImagesLoad) {
      if (
        perguntas?.some(
          (pergunta) =>
            pergunta.pergunta.tipo === TYPE_ASK.ANEXO &&
            pergunta.resposta
              .split(',')
              .some((pic) => isImage(pic) && isImageUrl(pic))
        )
      ) {
        if (
          perguntas
            ?.filter(({ pergunta }) => pergunta.tipo === TYPE_ASK.ANEXO)
            .reduce(
              (state, pergunta) =>
                state &&
                pergunta.resposta
                  .split(',')
                  .filter((picture) => isImage(picture) && isImageUrl(picture))
                  .every((picture) => imagesLoaded.includes(picture)),
              true
            )
        ) {
          onImagesLoad();
        }
      } else {
        onImagesLoad();
      }
    }
  }, [imagesLoaded]);

  return (
    <>
      {perguntas?.map(({ pergunta, id, resposta }) => (
        <Pergunta
          key={id}
          pergunta={pergunta}
          id={id}
          impressao={impressao}
          respostaString={resposta}
          imagesLoaded={imagesLoaded}
          onImageLoaded={(item) => setImagesLoaded((value) => [...value, item])}
          color={color}
        />
      ))}
    </>
  );
};

export default Perguntas;
