import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getTiposUnidades as getTiposUnidadesSdk, getTipoUnidade as getTipoUnidadeSdk, postTipoUnidade as postTipoUnidadeSdk, updateTipoUnidade as updateTipoUnidadeSdk, deleteTipoUnidade as deleteTipoUnidadeSdk } from '@sdk/TiposUnidades';

//Action Types
export const Types = {
  GET_TIPOS_UNIDADES: 'CONFIGURACOES_UNIDADES/GET_TIPOS_UNIDADES',
  GET_TIPOS_UNIDADES_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_TIPOS_UNIDADES_SUCCESS',
  GET_TIPOS_UNIDADES_ERROR: 'CONFIGURACOES_UNIDADES/GET_TIPOS_UNIDADES_ERROR',

  SAVE_TIPO_UNIDADE: 'CONFIGURACOES_UNIDADES/SAVE_TIPO_UNIDADE',
  SAVE_TIPO_UNIDADE_SUCCESS: 'CONFIGURACOES_UNIDADES/SAVE_TIPO_UNIDADE_SUCCESS',
  SAVE_TIPO_UNIDADE_ERROR: 'CONFIGURACOES_UNIDADES/SAVE_TIPO_UNIDADE_ERROR',

  DELETE_TIPO_UNIDADE: 'CONFIGURACOES_UNIDADES/DELETE_TIPO_UNIDADE',
  DELETE_TIPO_UNIDADE_SUCCESS: 'CONFIGURACOES_UNIDADES/DELETE_TIPO_UNIDADE_SUCCESS',
  DELETE_TIPO_UNIDADE_ERROR: 'CONFIGURACOES_UNIDADES/DELETE_TIPO_UNIDADE_ERROR',

  GET_TIPO_UNIDADE: 'CONFIGURACOES_UNIDADES/GET_TIPO_UNIDADE',
  GET_TIPO_UNIDADE_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_TIPO_UNIDADE_SUCCESS',
  GET_TIPO_UNIDADE_ERROR: 'CONFIGURACOES_UNIDADES/GET_TIPO_UNIDADE_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES/RESET',
  RESET_SUCCESS: 'CONFIGURACOES_UNIDADES/RESET_SUCCESS',
};

//Action Creators
export const getTiposUnidades = () => ({ type: Types.GET_TIPOS_UNIDADES });
export const getTiposUnidadesSuccess = (tiposUnidades) => ({ type: Types.GET_TIPOS_UNIDADES_SUCCESS, tiposUnidades });
export const getTiposUnidadesError = (error) => ({ type: Types.GET_TIPOS_UNIDADES_ERROR, error }); 

export const saveTipoUnidade = (tipoUnidade) => ({ type: Types.SAVE_TIPO_UNIDADE, tipoUnidade });
export const saveTipoUnidadeSuccess = (tipoUnidade) => ({ type: Types.SAVE_TIPO_UNIDADE_SUCCESS, tipoUnidade });
export const saveTipoUnidadeError = (error) => ({ type: Types.SAVE_TIPO_UNIDADE_ERROR, error }); 

export const deleteTipoUnidade = (id) => ({ type: Types.DELETE_TIPO_UNIDADE, id });
export const deleteTipoUnidadeSuccess = () => ({ type: Types.DELETE_TIPO_UNIDADE_SUCCESS });
export const deleteTipoUnidadeError = (error) => ({ type: Types.DELETE_TIPO_UNIDADE_ERROR, error });

export const getTipoUnidade = (id) => ({ type: Types.GET_TIPO_UNIDADE, id });
export const getTipoUnidadeSuccess = (tipoUnidade) => ({ type: Types.GET_TIPO_UNIDADE_SUCCESS, tipoUnidade });
export const getTipoUnidadeError = (error) => ({ type: Types.GET_TIPO_UNIDADE_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

//saga
function* fetchGetTiposUnidades(action) {
  try {
    const tiposUnidades = yield call(getTiposUnidadesSdk);
    yield put(getTiposUnidadesSuccess(tiposUnidades));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTiposUnidadesError(err));
  }
}

function* fetchDeleteTipoUnidade(action) {
  try {
    const { id } = action;
    const tipoUnidade = yield call(deleteTipoUnidadeSdk, id);

    yield call(getTiposUnidadesSdk);

    yield put(deleteTipoUnidadeSuccess(tipoUnidade));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteTipoUnidadeError(err));
  }
}

function* fetchGetTipoUnidade(action) {
  try {
    const { id } = action;
    const tipoUnidade = yield call(getTipoUnidadeSdk, id);
    yield put(getTipoUnidadeSuccess(tipoUnidade));
  } catch (err) {
    Notification.error(err.message);
    yield put(getTipoUnidadeError(err));
  }
}

function* fetchSaveTipoUnidade(action) {
  try {
    let { tipoUnidade } = action;

    if (tipoUnidade.id) {
      tipoUnidade = yield call(updateTipoUnidadeSdk, tipoUnidade);
    } else {
      tipoUnidade = yield call(postTipoUnidadeSdk, tipoUnidade);
    }
    yield put(saveTipoUnidadeSuccess(tipoUnidade));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveTipoUnidadeError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_TIPOS_UNIDADES, fetchGetTiposUnidades),
  takeLatest(Types.GET_TIPO_UNIDADE, fetchGetTipoUnidade),
  takeLatest(Types.SAVE_TIPO_UNIDADE, fetchSaveTipoUnidade),
  takeLatest(Types.DELETE_TIPO_UNIDADE, fetchDeleteTipoUnidade)
];

// Reducer
const initialState = Map({
  tiposUnidades: List(),
  loadingTiposUnidades: false,
  successTiposUnidades: false,
  errorTiposUnidades: false,
  tipoUnidade: null,
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false
});

const handleGetTiposUnidades = (state, action) => {
  return state.set('loadingTiposUnidades', true).set('successTiposUnidades', false).set('errorTiposUnidades', false);
};

const handleGetTiposUnidadesSuccess = (state, action) => {
  const { tiposUnidades } = action;

  return state.set('tiposUnidades', tiposUnidades).set('loadingTiposUnidades', false).set('successTiposUnidades', true).set('errorTiposUnidades', false);
};

const handleGetTiposUnidadesError = (state, action) => {
  return state.set('loadingTiposUnidades', false).set('successTiposUnidades', false).set('errorTiposUnidades', action.error);
};

const handleGetTipoUnidade = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetTipoUnidadeSuccess = (state, action) => {
  const { tipoUnidade } = action;

  return state.set('tipoUnidade', tipoUnidade).set('loading', false).set('success', true).set('error', false);
};

const handleGetTipoUnidadeError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleDeleteTipoUnidade = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteTipoUnidadeSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteTipoUnidadeError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSaveTipoUnidade = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveTipoUnidadeSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveTipoUnidadeError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccess = (state, action) => {
  return state.set('successSave', initialState.get('successSave')).set('success', initialState.get('success')).set('successDelete', initialState.get('successDelete'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TIPOS_UNIDADES:
      return handleGetTiposUnidades(state, action);
    case Types.GET_TIPOS_UNIDADES_SUCCESS:
      return handleGetTiposUnidadesSuccess(state, action);
    case Types.GET_TIPOS_UNIDADES_ERROR:
      return handleGetTiposUnidadesError(state, action);
    case Types.GET_TIPO_UNIDADE:
      return handleGetTipoUnidade(state, action);
    case Types.GET_TIPO_UNIDADE_SUCCESS:
      return handleGetTipoUnidadeSuccess(state, action);
    case Types.GET_TIPO_UNIDADE_ERROR:
      return handleGetTipoUnidadeError(state, action);
    case Types.DELETE_TIPO_UNIDADE:
      return handleDeleteTipoUnidade(state, action);
    case Types.DELETE_TIPO_UNIDADE_SUCCESS:
      return handleDeleteTipoUnidadeSuccess(state, action);
    case Types.DELETE_TIPO_UNIDADE_ERROR:
      return handleDeleteTipoUnidadeError(state, action);
    case Types.SAVE_TIPO_UNIDADE:
      return handleSaveTipoUnidade(state, action);
    case Types.SAVE_TIPO_UNIDADE_SUCCESS:
      return handleSaveTipoUnidadeSuccess(state, action);
    case Types.SAVE_TIPO_UNIDADE_ERROR:
      return handleSaveTipoUnidadeError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);

    default:
      return state;
  }
}
