import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import App from '@app';
import { PageHeader, Body, Button, Breadcrumb, Form, Input, Row, Table, Skeleton, Switch } from '@components-teammove';
import { Header, InputValue, SmallFormItem, StatusRegistration, TagStatusRegistration, TitleHeader, SwitchForm } from './styles';
import { getPriceTable, putPriceTable, postPriceTable, reset, resetSuccess } from '@ducks/settingsProductsPriceTables';
import { getProducts, reset as resetProduct } from '@ducks/produtos';
import { InitialValues, extractProductsData } from './rules';

export default function EditarTabela() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const priceTable = useSelector(({ priceTables }) => priceTables.get('priceTable'));
  const loadingPriceTable = useSelector(({ priceTables }) => priceTables.get('loadingPriceTable'));
  const successPriceTable = useSelector(({ priceTables }) => priceTables.get('successPriceTable'));

  const [items, setItems] = useState();

  const loadingPriceTableSave = useSelector(({ priceTables }) => priceTables.get('loadingPriceTableSave'));
  const successPriceTableSave = useSelector(({ priceTables }) => priceTables.get('successPriceTableSave'));

  const categoriesWithProducts = useSelector(({ produtos }) => produtos.get('categoriesWithProducts'));
  const loadingProducts = useSelector(({ produtos }) => produtos.get('loadingProducts'));
  const successProducts = useSelector(({ produtos }) => produtos.get('successProducts'));

  useEffect(() => {
    if(parseInt(id) > 0) {
      dispatch(getPriceTable(parseInt(id)));
    }else{
      dispatch(getProducts());
      form.setFieldsValue(InitialValues);
    }
  },[]);

  useEffect(() => {
    if(successPriceTable) {
      form.setFieldsValue(priceTable);
      setItems(priceTable.items);
    }
    if(successProducts) {
      setItems(extractProductsData(categoriesWithProducts)); 
    }
  },[successPriceTable,successProducts]);

  useEffect(()=>{
    if(successPriceTableSave) {
      dispatch(reset());
      history.goBack();
    }

  },[successPriceTableSave]);

  const handleSave = () => {
   
    form.validateFields().then((values) => {
      values.active = form.getFieldsValue().active;
      values.isDefault = form.getFieldsValue().isDefault;
      values.items = items.map((item)=>(typeof item.price === 'string' ? { ...item, price: parseFloat(item.price.replace('R$', '').replace(',', '.').trim()) } : { ...item }));
      if(parseInt(id) > 0) {
        dispatch(putPriceTable(values));
      }else{
        delete values.id;
        dispatch(postPriceTable(values));
      }  
    });
  };

  const handleCancel = () =>{
    dispatch(reset());
    dispatch(resetSuccess());
    dispatch(resetProduct());
    history.goBack();
  };

  const handlePrice = (tab, newPrice) => {
    setItems(items.map((item) => {
      return item.productId === tab.productId ? 
        { ...item, price: newPrice } :
        { ...item };
    }));
  };

  const columns = [
    {
      title: 'Produto',
      dataIndex:'name',
      sorter:true,
    },
    {
      title:'Valor',
      dataIndex:'price',
      sorter:true,
      width: '246px',
      render:(text, tab) => (
        <Row justify='space-between' align="center">
          <InputValue
            prefix={'R$'}
            decimalSeparator=","
            thousandSeparator="."
            precision={2}
            value={tab.price ? tab.price : '0'}
            onChange={(value) => handlePrice(tab, value)}
          />
        </Row>
      )
    }
  ];

  const getHeader = () => (
    <Header>
      {parseInt(id) === -1 ? (
        <TitleHeader>Nova tabela de preços</TitleHeader>
      ) : (
        <TitleHeader>{priceTable?.name}</TitleHeader>)}

      {priceTable?.isDefault &&  
      <TagStatusRegistration>
        <StatusRegistration>Padrão</StatusRegistration>
      </TagStatusRegistration>}
    </Header>
  );

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={getHeader()}
          extra={[
            <Button key="1" size='small' context='header' type='secondary' onClick={handleCancel}>Cancelar</Button>,
            <Button key="2" size='small' context='header' type="primary" loading={loadingPriceTableSave} onClick={handleSave}>Salvar</Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes/mixprodutos', name: 'Configurações' },
                { path: '/configuracoes/mixprodutos', name: 'Configurações de Mix de Produtos' },
                { path: `/configuracoes/mixprodutos/${id}`, name: 'Edição de Tabela de preços', current: true }
              ]}
            />
          )}
        >
          <Form layout="vertical" form={form} >
            <Form.Item name="id" hidden/>
            <Form.Item name="active" hidden/>
            <Form.Item name="isDefault" hidden/>
           
            <Header>
              <SmallFormItem name="name" label="Nome da tabela" rules={[{ required: true, message: 'Informe o nome da tabela!' }]}>
                {loadingPriceTable ? <Skeleton/> :
                  <Input placeholder='Digite um nome para a tabela'/>}
              </SmallFormItem>

              <SwitchForm name='active' label='Ativos' valuePropName='checked'>
                <Switch  />
              </SwitchForm>
            </Header>
            
          </Form>
          <Table
            columns={columns}
            dataSource={items}
            loading={parseInt(id) !== -1 ? loadingPriceTable : loadingProducts}
            rowKey='productId'
            status='active'
            hidePagination
          />  
        </PageHeader>
      </Body>
    </App>
  );
}