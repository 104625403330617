import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import App from '@app';
import {
  Checkbox,
  PageHeader,
  Breadcrumb,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Body,
  Radio,
  Upload,
  Tooltip,
  EmailEditor,
  Text,
  Switch,
  View,
  Icon 
} from '@components-teammove';

import { InfoUsuario, download, getValueFromFile, ansiToMoment, TelasNomenclaturas } from '@utils';
import { getPerfis } from '@sdk/Perfil';
import { getUsuarios } from '@sdk/Usuarios';
import { getUnidades } from '@sdk/Unidades';
import { authorization } from '@sdk/api';
import { getDadosCadastro, putCadastro, reset } from '@ducks/wikisCadastro';
import UsuariosEnvio from '../../Wikis/Cadastro/UsuariosEnvio';
import { MarginBottom, SendToContainer, SmallText } from './styles';

const { REACT_APP_API } = window.env;

const CheckGroupOptions = styled(Checkbox.Group)`
  label {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const RadioGroupWithSpace = styled(Radio.Group)`
  margin-bottom: 12px;
`;

const InfoIcon = styled(Icon.QuestionCircleOutlined)`
  color: rgba(0, 0, 0, 0.45);
  margin-left: 10px;
`;

export default function CadastroComunicado({ history }) {
  const storageUltimoFiltro = 'FILTROS/wiki/ultimo_tipo';

  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const editorRef = useRef(null);

  const locationValues = location.state;

  const [comunicado, setComunicado] = useState(false);
  const [editando, setEditando] = useState(false);
  const [utilizaDestaque, setUtilizaDestaque] = useState(() => locationValues && !!locationValues.dtFim);
  const [utilizaBloqueio, setUtilizaBloqueio] = useState(false);
  const [editorReady, setEditorReady] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [primeiraMudancaTipo, setPrimeiraMudancaTipo] = useState(true);
  const [temUnidadeEnvio, setTemUnidadeEnvio] = useState(false);
  const [temPerfilEnvio, setTemPerfilEnvio] = useState(false);
  const [tipoEnvio, setTipoEnvio] = useState(1);
  const [grupoAtual, setGrupoAtual] = useState({});
  const [listasSelecionadas, setListasSelecionadas] = useState([]);
  const [bloqueiaAppOptions, setBloqueiaAppOptions] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [tipo, setTipo] = useState(null);
  const [uploadListLength, setUploadListLength] = useState(0);
  const [visibleUsuariosEnvio, setVisibleUsuariosEnvio] = useState(false);

  const theme = useSelector(({ app }) => app.get('theme'));

  const dadosCadastro = useSelector(({ wikisCadastro }) => wikisCadastro.get('dadosCadastro'));
  const loadingDados = useSelector(({ wikisCadastro }) => wikisCadastro.get('loadingDadosCadastro'));

  const successCadastro = useSelector(({ wikisCadastro }) => wikisCadastro.get('successPutCadastro'));
  const loadingCadastro = useSelector(({ wikisCadastro }) => wikisCadastro.get('loadingPutCadastro'));
  const tiposComunicados = ['C', 'I', 'P']; //Texto, Imagem e PDF.
  const userTM = InfoUsuario.get('senhaMaster');

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const initialValues = locationValues
    ? {
      titulo: locationValues.titulo,
      conteudo: locationValues.tipoChave === 'C' && (locationValues.conteudo || locationValues.resumo),
      idCategoria: locationValues.idCategoria,
      idUsuario: locationValues.idUsuario,
      dtFim: locationValues.dtFim ? ansiToMoment(locationValues.dtFim) : null,
      idListaTransmissao: locationValues.idListaTransmissao && locationValues.idListaTransmissao.split(',').map(Number),
      usuarios: locationValues.usuarios,
      unidades: locationValues.unidades && locationValues.unidades.split(',').map(Number),
      perfisEnvio: locationValues.perfisEnvio && locationValues.perfisEnvio.split(',').map(Number),
      publicado: locationValues.publicado,
    }
    : {};

  useEffect(() => {
    const loadUsuarios = async () => {
      const usuariosApi = await getUsuarios();
      setListaUsuarios(usuariosApi);
    };

    const isComunicado = window.location.pathname.includes('/comunicado');
    const ultimoTipo = localStorage.getItem(storageUltimoFiltro);
    const tipoInicial = (locationValues && locationValues.tipoChave) || ultimoTipo;

    setComunicado(isComunicado);
    setEditando(locationValues && locationValues.id);
    dispatch(getDadosCadastro());
    setTipo(tipoInicial);

    if (locationValues) {
      setTipoEnvio(locationValues.unidades ? 3 : locationValues.usuarios ? 2 : 1);
      setTemPerfilEnvio(!!locationValues.perfisEnvio);
      setTemUnidadeEnvio(!!locationValues.unidades);
    }
    loadUsuarios();

    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (listasSelecionadas.length < 1) {
      setBloqueiaAppOptions([]);
      return;
    }

    createOptionsFromListasTransmissao(listasSelecionadas);
  }, [listasSelecionadas]);

  useEffect(() => {
    if (tipo) {
      const limparConteudo = primeiraMudancaTipo ? {} : { conteudo: null };
      form.resetFields(['upload']);
      setUploadListLength(0);
      form.setFieldsValue({
        tipo,
        upload: null,
        ...limparConteudo,
      });
      localStorage.setItem(storageUltimoFiltro, tipo);

      setPrimeiraMudancaTipo(false);
    }
  }, [tipo]);

  useEffect(() => {
    if (dadosCadastro && dadosCadastro.grupos && dadosCadastro.grupos.length === 1) {
      setGrupoAtual(dadosCadastro.grupos[0]);
    }
  }, [dadosCadastro]);

  useEffect(() => {
    if (successCadastro) {
      history.goBack();
    }
  }, [history, successCadastro]);

  useEffect(() => {
    if (editorRef && editorRef.current && editorLoaded) {
      handleEditorOnLoad();
    }
  }, [editorRef.current, editorLoaded]);

  useEffect(() => {
    const loadUnidades = async () => {
      const unidades = await getUnidades();
      setListaUnidades(unidades);
    };

    loadUnidades();
  }, []);

  async function createOptionsFromListasTransmissao(listasSelecionadas) {
    const listaPerfisSelecionados = dadosCadastro.listasCargos.filter(
      (listaPerfil) => listasSelecionadas.includes(listaPerfil.idListaTransmissao) && listaPerfil.envia
    );

    const bloquiaAppSelectOptionsDuplicados = listaPerfisSelecionados.map((listaPerfil) => ({
      value: listaPerfil.idPerfil,
      label: listaPerfil.nmPerfil,
    }));

    const bloqueiaAppSelectOptions = bloquiaAppSelectOptionsDuplicados.filter(
      (option, index, self) => self.findIndex((item) => item.value === option.value) === index
    );

    setBloqueiaAppOptions(bloqueiaAppSelectOptions);

    form.setFieldsValue({
      opcoesBloqueiaApp: bloqueiaAppSelectOptions.map((item) => item.value),
    });
  }

  function getUploadTypeSpecifier() {
    switch (tipo) {
      case 'I':
        return 'image/*';
      case 'V':
        return 'video/*';
      case 'P':
        return '.pdf';
      default:
        return null;
    }
  }

  function handleUsuariosEnvio() {
    setVisibleUsuariosEnvio(true);
  }

  function handleEditorOnLoad() {
    if (!editorReady) {
      if (locationValues && locationValues.htmlJson && editorRef.current) {
        setEditorReady(true);
        editorRef.current.loadDesign(JSON.parse(locationValues.htmlJson));
      }
    }
  }

  function handleSalvar() {
    form.validateFields().then(async (values) => {
      const {
        tipo,
        idCategoria,
        titulo,
        conteudo,
        upload,
        idUsuario,
        idListaTransmissao,
        dtFim,
        opcoesBloqueiaApp,
        usuarios,
        unidades,
        perfisEnvio,
        publicado
      } = values;

      const getTypeFromFile = (type) => {
        if (type.includes('pdf')) {
          return 'P';
        }
        if (type.includes('png') || type.includes('jpeg') || type.includes('jpg')) {
          return 'I';
        }
        if (type.includes('mp4') || type.includes('aiv') || type.includes('wmv')) {
          return 'V';
        }
        return 'A';
      };

      const wiki = {
        tipo: tipo ? tipo : dadosCadastro.tipos[0].chave,
        idCategoria: idCategoria ? idCategoria : dadosCadastro.grupos[0].id,
        titulo,
        conteudo,
        uploads:
          upload && upload.map((item) => ({ tipo: getTypeFromFile(item.type), path: item.response, name: item.name, size: item.size })),
        idUsuario: idUsuario ? idUsuario : InfoUsuario.get('cdUsuario'),
        idListaTransmissao,
        dtFim: utilizaDestaque ? dtFim : null,
        perfis: bloqueiaAppOptions.map((item) => item.value),
        opcoesBloqueiaApp: utilizaBloqueio ? (tipoEnvio === 2 ? usuarios : tipoEnvio === 3 ? unidades : opcoesBloqueiaApp) : null,
        usuarios: tipoEnvio === 2 ? usuarios : null,
        unidades: tipoEnvio === 3 ? unidades : null,
        perfisEnvio: tipoEnvio === 3 ? perfisEnvio : null,
        publicado: publicado !== undefined ? publicado : true
      };

      if (['A', 'I', 'P', 'V'].includes(tipo) && wiki.uploads) {
        wiki.conteudo = wiki.uploads[0].path;
        delete wiki.uploads;
      }

      const callApiCadastro = () => {
        const id = editando ? locationValues.id : -1;
        dispatch(putCadastro(wiki, id));
      };

      if (tipo === 'H') {
        editorRef.current.exportHtml(({ design, html }) => {
          wiki.conteudo = html;
          wiki.templateHtml = JSON.stringify(design);

          callApiCadastro();
        });
      } else {
        callApiCadastro();
      }
    }).catch(Form.scrollToFirstError);
  }

  const onChangeUpload = ({ file, fileList, event }) => {
    setUploadListLength(fileList.length);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/comunicados', name: 'Comunicados' },
                { path: '/comunicado/cadastro', name: 'Novo Comunicado', current: true }
              ]}
            />
          )}
          title={`${!editando ? 'Novo' : 'Alteração de'} Comunicado`}
          extra={[
            <Button key='1' size='small' context='header' type='secondary' onClick={() => history.goBack()}>
              Cancelar
            </Button>,
            <Button key='2' size='small' context='header' type='primary' onClick={handleSalvar} loading={loadingCadastro}>
              Salvar
            </Button>,
          ]}
        >
          <Form layout='vertical' form={form} initialValues={initialValues}>
            {editando && (
              <Form.Item label='Publicado' name='publicado' valuePropName='checked'>
                <Switch />
              </Form.Item>
            )}
            <View horizontal gap='12px'>
              <View flex={1}>
                {dadosCadastro.tipos && dadosCadastro.tipos.length > 1 && (
                  <Form.Item label='Tipo' name='tipo' rules={[{ required: true, message: 'Informe o tipo!' }]}>
                    <Select 
                      value={tipo} 
                      placeholder='Tipo'
                      onChange={(value) => setTipo(value)} 
                      disabled={editando} 
                      options={dadosCadastro.tipos &&
                        dadosCadastro.tipos.filter((tipo) => {
                          return !(comunicado && !tiposComunicados.includes(tipo.chave));
                        }).map((tipo) => {
                          return { label: tipo.descricao, value: tipo.chave };
                        })
                      }
                    />
                  </Form.Item>
                )}
              </View>
              <View flex={1} gap='8px'>
                {dadosCadastro.grupos && dadosCadastro.grupos.length > 1 && (
                  <>
                    <Form.Item label='Grupo' name='idCategoria' rules={[{ required: true, message: 'Informe o grupo!' }]} marginless>
                      <Select
                        onChange={(value) => setGrupoAtual(dadosCadastro.grupos.find((grupo) => grupo.id === value))}
                        disabled={editando}
                        placeholder='Grupo'
                        options={dadosCadastro.grupos &&
                      dadosCadastro.grupos.map((grupo) => ({ label: grupo.titulo, value: grupo.id }))
                        }
                      />
                    </Form.Item>
                    {grupoAtual && grupoAtual.sequencialNrDigitos > 0 && grupoAtual.sequencialAtivo && (
                      <SmallText>
                        Identificador do comunicado:
                        <strong>
                          {grupoAtual.sequencialPrefixo}
                          {'0'.repeat(grupoAtual.sequencialNrDigitos - 1)}X
                        </strong>
                      </SmallText>
                    )}
                    <MarginBottom/>
                  </>
                )}
              </View>
            </View>

            <Form.Item label={'Título'} name='titulo' rules={[{ required: true, message: 'Informe o título!' }, { max: 80, message: 'Número de caracteres ultrapassou o limite permitido', type: 'string' }]}>
              <Input placeholder='Insira o título do comunicado aqui'/>
            </Form.Item>

            {['C', 'L'].includes(tipo) && (
              <Form.Item label='Conteúdo' name='conteudo' rules={[{ required: true, message: 'Informe o conteudo!' }]}>
                {tipo !== 'L' ? <Input.TextArea placeholder='Insira o conteúdo do comunicado aqui'/> : <Input placeholder='Insira o conteúdo do comunicado aqui'/>}
              </Form.Item>
            )}

            {editando && ['A', 'I', 'V', 'P', 'C'].includes(tipo) && (
              <View>
                <Text strong>* Não é permitido alterar os anexos </Text>
                <br />
              </View>
            )}

            {!editando && ['A', 'I', 'V', 'P', 'C'].includes(tipo) && (
              <Form.Item
                name='upload'
                valuePropName='fileList'
                getValueFromEvent={getValueFromFile}
                rules={[{ required: tipo !== 'C', message: 'Informe o anexo!' }]}
                help={['I', 'P'].includes(tipo) ? 'Permitido somente 1 arquivo' : null}
              >
                <Upload
                  listType='picture'
                  headers={authorization()}
                  folder={`Comunicados/${InfoUsuario.get('cdCliente')}`}
                  URI={REACT_APP_API}
                  onDownload={(file) => download(file.response)}
                  accept={getUploadTypeSpecifier()}
                  multiple={tipo === 'C'}
                  onChange={onChangeUpload}
                  showUploadList
                  uploadsListProps={{ label: 'Arquivos anexados' }}
                  fill
                >
                  <Button disabled={['I', 'P'].includes(tipo) && uploadListLength > 0} fillWidth>
                    Anexar arquivo
                  </Button>
                </Upload>
              </Form.Item>
            )}

            {userTM && (
              <Form.Item
                label='Enviado Por'
                name='idUsuario'
                rules={[{ required: !editando, message: 'Informe o usuário que vai enviar o comunicado!' }]}
              >
                <Select form={form} name='idUsuario' action={getUsuarios} placeholder='Selecione um usuário' />
              </Form.Item>
            )}

            <SendToContainer>
              <RadioGroupWithSpace onChange={(value) => setTipoEnvio(value)} value={tipoEnvio} disabled={editando && !userTM}>
                <Radio value={1}>Lista de transmissão</Radio>
                <Radio value={2}>Usuários</Radio>
                <Radio value={3}>Unidades/Perfis</Radio>
              </RadioGroupWithSpace>

              <Button type='link' onClick={handleUsuariosEnvio}>
              Quem está recebendo?
              </Button>
            </SendToContainer>

            {tipoEnvio === 1 && (
              <Form.Item label='' name='idListaTransmissao' rules={[{ required: true, message: 'Informe uma lista de transmissão!' }]}>
                <Select
                  mode='multiple'
                  form={form}
                  name='idListaTransmissao'
                  placeholder='Selecione as listas de transmissões'
                  value={listasSelecionadas}
                  onChange={(items) => setListasSelecionadas(items)}
                  loading={loadingDados}
                  options={dadosCadastro.listas && dadosCadastro.listas.map((lista) => ({ value: lista.id, label: lista.nome }))}
                  disabled={editando && !userTM}
                />
              </Form.Item>
            )}

            {tipoEnvio === 2 && (
              <Form.Item label='' name='usuarios' rules={[{ required: true, message: 'Informe os usuários que irão receber!' }]}>
                <Select 
                  form={form} name='usuarios' mode='multiple' placeholder='Escolha os usuários' disabled={editando && !userTM}
                  options={listaUsuarios.map((user) => {
                    if (!user.unidades) return { label: user.title, value: user.key };
                    
                    const unidades = user.unidades.map((userUnity) => {
                      return listaUnidades.find((unity) => unity.key === parseInt(userUnity));
                    }).filter((unity) => !!unity);

                    if (unidades.length > 3) return { label: user.title, value: user.key };
                    if (unidades.length === 3) {
                      const unidadesString = unidades.reduce((state, unity, index) => {
                        return index === 0 ? 
                          `${unity.title}` : index === 1 ? 
                            `${state}, ${unity.title}` : index === 2 ? 
                              `${state} e ${unity.title}` : '';
                      }, '');
                      return { label: `${user.title} (${unidadesString})`, value: user.key };
                    }
                    if (unidades.length === 2) {
                      const unidadesString = unidades.reduce((state, unity, index) => index === 0 ? `${unity.title}` : index === 1 ? `${state} e ${unity.title}` : '', '');
                      return { label: `${user.title} (${unidadesString})`, value: user.key };
                    }
                    if (unidades.length === 1) {
                      return { label: `${user.title} (${unidades[0].title})`, value: user.key };
                    }

                    return { label: user.title, value: user.key };
                    
                  })}
                />
              </Form.Item>
            )}

            {tipoEnvio === 3 && (
              <>
                <Form.Item
                  label={companyNomenclature.plural}
                  name='unidades'
                  rules={[{ required: !temUnidadeEnvio && !temPerfilEnvio, message: `Informe ${(companyNomenclature.artigo)}s ${(companyNomenclature.plural).toLowerCase()} que irão receber!` }]}
                >
                  <Select
                    form={form}
                    name='unidades'
                    mode='multiple'
                    onChange={(items) => setTemUnidadeEnvio(items.length > 0)}
                    action={getUnidades}
                    placeholder={`Escolha ${companyNomenclature.artigo}s ${(companyNomenclature.plural).toLowerCase()}`}
                    disabled={editando && !userTM}
                    optionLabelProp='title'
                  />
                </Form.Item>
                <Form.Item
                  label='Perfis'
                  name='perfisEnvio'
                  rules={[{ required: true, message: 'Informe os perfis que irão receber!' }]}
                >
                  <Select
                    form={form}
                    name='perfisEnvio'
                    mode='multiple'
                    onChange={(items) => setTemPerfilEnvio(items.length > 0)}
                    action={getPerfis}
                    placeholder='Escolha os perfis'
                    disabled={editando && !userTM}
                  />
                </Form.Item>
              </>
            )}

            <View gap={utilizaDestaque && '12px'}>
              <Checkbox checked={utilizaDestaque} onChange={(checked) => setUtilizaDestaque(checked)}>
                Destacar comunicado
                <Tooltip title='Faz com que apareça no início dos comunicados até a data escolhida'>
                  <InfoIcon />
                </Tooltip>
              </Checkbox>

              {utilizaDestaque ? (
                <Form.Item name='dtFim'>
                  <DatePicker format="DD/MM/YYYY" placeholder='Destacar até' />
                </Form.Item>
              ) : <MarginBottom/>}
            </View>

            {(bloqueiaAppOptions.length > 0 || tipoEnvio > 1) && (
              <MarginBottom>
                <Checkbox checked={utilizaBloqueio} onChange={(checked) => setUtilizaBloqueio(checked)}>
                  Confirmação de Leitura / Bloqueio App
                </Checkbox>
              </MarginBottom>
            )}

            {utilizaBloqueio && tipoEnvio === 1 && (
              <Form.Item name='opcoesBloqueiaApp'>
                <CheckGroupOptions name='opcoesBloqueiaApp'>
                  {bloqueiaAppOptions.map(({ value, label }) => (
                    <Checkbox value={value} key={value}>{label}</Checkbox>
                  ))}
                </CheckGroupOptions>
              </Form.Item>
            )}

            {tipo === 'H' && (
              <EmailEditor ref={editorRef} projectId={3611} minHeight='800px' locale='pt-BR' onLoad={() => setEditorLoaded(true)} />
            )}
          </Form>
        </PageHeader>
        <UsuariosEnvio 
          visible={visibleUsuariosEnvio}
          onCancel={() => setVisibleUsuariosEnvio(false)}
          usuarios={listaUsuarios} 
          unidades={tipoEnvio === 3 ? form.getFieldValue('unidades') : []} 
          perfis={(tipoEnvio === 3) ? form.getFieldValue('perfisEnvio') : (tipoEnvio === 1) ? bloqueiaAppOptions.map((perfil) => perfil.value) : []} 
          usuariosEspecificos={tipoEnvio === 2 ? form.getFieldValue('usuarios') : []}
        />
      </Body>
    </App>
  );
}
