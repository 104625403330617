import React, { useEffect, useState } from 'react';
import { Table, Button, SearchBar, Row, Column, Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { useHistory } from 'react-router-dom';
import AgrupadoresCadastro from './Cadastro';
import { LoadMoreButton } from './styles';

const Agrupadores = ({ visibleRegister, setVisibleRegister }) => {
  const [search, setSearch] = useState();
  const [limit, setLimit] = useState(50);

  const history = useHistory();
  const dispatch = useDispatch();
  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));
  const loading = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loadingAgrupadores'));

  const loadingDelete = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successDelete'));
  const successSave = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successSave'));

  useEffect(() => {
    dispatch(getAgrupadores());
  }, []);

  useEffect(() => {
    if (successDelete || successSave) dispatch(getAgrupadores());
  }, [successDelete, successSave]);

  const handleButtonClick = (agrupador) => {
    history.push('?agrupador=' + agrupador.id, { agrupador: agrupador });
    setVisibleRegister(true);
  };

  const columns = [
    {
      title: 'Nome do Agrupador',
      dataIndex: 'description',
      sorter: true,
      render: (text, agrupador) => (
        <Row justify='space-between' align='center'>
          {text}
          <Button
            title='Editar agrupador'
            type='secondary'
            size='small'
            context='header'
          >
            Editar
          </Button>
        </Row>
      ),
    },
  ];

  const handleSearch = (text) => {
    setSearch(text);
  };

  const filterTypes = () => {
    if (!search) return agrupadores.slice(0, limit);
    return agrupadores.filter(({ description }) => description.trim().toLowerCase().includes(search.trim().toLowerCase())).slice(0, limit);
  };

  const loadMore = () => {
    setLimit((prevLimit) => prevLimit + 50);
  };

  return (
    <Column>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar'/>
      <Table
        columns={columns}
        dataSource={filterTypes()}
        loading={loading || loadingDelete}
        rowKey='id'
        status='ativo' 
        onRow={(agrupador) => ({ onClick: () => handleButtonClick(agrupador) })} 
        hidePagination
      />

      {limit < agrupadores.length && (
        <LoadMoreButton
          type="secondary"
          icon={<Icon.ReloadOutlined />}
          loading={loading}
          onClick={loadMore}
        >
        Carregar mais
        </LoadMoreButton>
      )}

      {visibleRegister && (
        <AgrupadoresCadastro
          visible={visibleRegister}
          onCancel={() => setVisibleRegister(false)}
        />
      )}
    </Column>
  );
};

export default Agrupadores;