import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, InfoUsuario, formatTimeWithSeconds } from '@utils';
import {
  getCategoriasCursos as getCategoriasCursosSdk,
  getMateriasAulas as getMateriasAulasSdk,
  getCurso as getCursoSdk,
  getCursoCadastrado as getCursoCadastradoSdk,
  postCurso as postCursoSdk,
  deleteCurso as deleteCursoSdk,
} from '@sdk/Wikis/EAD';
import { apiPostNewAudiencia } from './wikisAcoes';

//Action Types
export const Types = {
  GET_CATEGORIAS_CURSOS: 'WIKI_EAD/GET_CATEGORIAS_CURSOS',
  GET_CATEGORIAS_CURSOS_SUCCESS: 'WIKI_EAD/GET_CATEGORIAS_CURSOS_SUCCESS',
  GET_CATEGORIAS_CURSOS_ERROR: 'WIKI_EAD/GET_CATEGORIAS_CURSOS_ERROR',

  GET_MATERIAS_AULAS: 'WIKI_EAD/GET_MATERIAS_AULAS',
  GET_MATERIAS_AULAS_SUCCESS: 'WIKI_EAD/GET_MATERIAS_AULAS_SUCCESS',
  GET_MATERIAS_AULAS_ERROR: 'WIKI_EAD/GET_MATERIAS_AULAS_ERROR',

  GET_CURSO: 'WIKI_EAD/GET_CURSO',
  GET_CURSO_SUCCESS: 'WIKI_EAD/GET_CURSO_SUCCESS',
  GET_CURSO_ERROR: 'WIKI_EAD/GET_CURSO_ERROR',

  GET_CURSO_CADASTRADO: 'WIKI_EAD/GET_CURSO_CADASTRADO',
  GET_CURSO_CADASTRADO_SUCCESS: 'WIKI_EAD/GET_CURSO_CADASTRADO_SUCCESS',
  GET_CURSO_CADASTRADO_ERROR: 'WIKI_EAD/GET_CURSO_CADASTRADO_ERROR',

  POST_CURSO: 'WIKI_EAD/POST_CURSO',
  POST_CURSO_SUCCESS: 'WIKI_EAD/POST_CURSO_SUCCESS',
  POST_CURSO_ERROR: 'WIKI_EAD/POST_CURSO_ERROR',

  DELETE_CURSO: 'WIKI_EAD/DELETE_CURSO',
  DELETE_CURSO_SUCCESS: 'WIKI_EAD/DELETE_CURSO_SUCCESS',
  DELETE_CURSO_ERROR: 'WIKI_EAD/DELETE_CURSO_ERROR',

  RESET_AULA: 'WIKI_EAD/RESET_AULA',

  SELECT_AULA: 'WIKI_EAD/SELECT_AULA',
  ALTER_CURRENT_AULA: 'WIKI_EAD/ALTER_CURRENT_AULA',

  SET_MATERIAS_CADASTRO: 'WIKI_EAD/SET_MATERIAS_CADASTRO',

  SET_ANDAMENTO_QUIZ: 'WIKI_EAD/SET_ANDAMENTO_QUIZ',
};

//Action Creators
export const getCategoriasCursos = (cadastro) => ({ type: Types.GET_CATEGORIAS_CURSOS, cadastro });
export const getCategoriasCursosSuccess = (data) => ({ type: Types.GET_CATEGORIAS_CURSOS_SUCCESS, data });
export const getCategoriasCursosError = (error) => ({ type: Types.GET_CATEGORIAS_CURSOS_ERROR, error });

export const getMateriasAulas = ({ idCurso }) => ({ type: Types.GET_MATERIAS_AULAS, idCurso });
export const getMateriasAulasSuccess = (data) => ({ type: Types.GET_MATERIAS_AULAS_SUCCESS, data });
export const getMateriasAulasError = (error) => ({ type: Types.GET_MATERIAS_AULAS_ERROR, error });

export const getCurso = ({ id }) => ({ type: Types.GET_CURSO, id });
export const getCursoSuccess = (data) => ({ type: Types.GET_CURSO_SUCCESS, data });
export const getCursoError = (error) => ({ type: Types.GET_CURSO_ERROR, error });

export const getCursoCadastrado = ({ id }) => ({ type: Types.GET_CURSO_CADASTRADO, id });
export const getCursoCadastradoSuccess = (data) => ({ type: Types.GET_CURSO_CADASTRADO_SUCCESS, data });
export const getCursoCadastradoError = (error) => ({ type: Types.GET_CURSO_CADASTRADO_ERROR, error });

export const postCurso = (data) => ({ type: Types.POST_CURSO, data });
export const postCursoSuccess = () => ({ type: Types.POST_CURSO_SUCCESS });
export const postCursoError = (error) => ({ type: Types.POST_CURSO_ERROR, error });

export const deleteCurso = (idCurso) => ({ type: Types.DELETE_CURSO, idCurso });
export const deleteCursoSuccess = () => ({ type: Types.DELETE_CURSO_SUCCESS });
export const deleteCursoError = (error) => ({ type: Types.DELETE_CURSO_ERROR, error });

export const selectAula = (data) => ({ type: Types.SELECT_AULA, data });

export const resetAula = () => ({ type: Types.RESET_AULA });

export const alterCurrentAula = (data) => ({ type: Types.ALTER_CURRENT_AULA, data });

export const setMateriasCadastro = (data) => ({ type: Types.SET_MATERIAS_CADASTRO, data });

export const setAndamentoQuiz = (data) => ({ type: Types.SET_ANDAMENTO_QUIZ, data });

export const getLastSelectedAula = ({ cursoId }) => {
  return localStorage.getItem(`usuario-${InfoUsuario.get('cdUsuario')}-curso-${cursoId}`);
};

//saga
function* fetchGetCategoriasCursosSaga(action) {
  try {
    const { cadastro } = action;
    const resp = yield call(getCategoriasCursosSdk, cadastro);
    yield put(getCategoriasCursosSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriasCursosError(err));
  }
}

function* fetchGetMateriasAulasSaga(action) {
  try {
    const { idCurso } = action;
    const resp = yield call(getMateriasAulasSdk, { idCurso });
    yield put(getMateriasAulasSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getMateriasAulasError(err));
  }
}

function* fetchGetCursoSaga(action) {
  try {
    const { id } = action;
    const resp = yield call(getCursoSdk, { id });
    yield put(getCursoSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCursoError(err));
  }
}

function* fetchGetCursoCadastradoSaga(action) {
  try {
    const { id } = action;
    const resp = yield call(getCursoCadastradoSdk, { id });
    yield put(getCursoCadastradoSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCursoCadastradoError(err));
  }
}

function* fetchPostCursoSaga(action) {
  try {
    const { data } = action;
    const resp = yield call(postCursoSdk, data);
    yield put(postCursoSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(postCursoError(err));
  }
}

function* fetchDeleteCursoSaga(action) {
  try {
    const resp = yield call(deleteCursoSdk, action);
    yield put(deleteCursoSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteCursoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CATEGORIAS_CURSOS, fetchGetCategoriasCursosSaga),
  takeLatest(Types.GET_MATERIAS_AULAS, fetchGetMateriasAulasSaga),
  takeLatest(Types.GET_CURSO, fetchGetCursoSaga),
  takeLatest(Types.GET_CURSO_CADASTRADO, fetchGetCursoCadastradoSaga),
  takeLatest(Types.POST_CURSO, fetchPostCursoSaga),
  takeLatest(Types.DELETE_CURSO, fetchDeleteCursoSaga),
];

// Reducer
const initialState = Map({
  categoriasCursos: List(),
  materiasAulas: List(),
  curso: {},
  materiasCadastro: List(),
  cursoCadastrado: {},

  currentAula: Map({}),
  currentAulaInicio: null,
  aulaAnterior: false,
  proximaAula: false,

  loadingCategoriasCursos: false,
  successCategoriasCursos: false,
  errorCategoriasCursos: false,

  loadingMateriasAulas: false,
  successMateriasAulas: false,
  errorMateriasAulas: false,

  loadingCurso: false,
  successCurso: false,
  errorCurso: false,

  loadingCursoCadastrado: false,
  successCursoCadastrado: false,
  errorCursoCadastrado: false,

  postingCurso: false,
  successPostCurso: false,
  errorPostCurso: false,

  deletingCurso: false,
  successDeleteCurso: false,
  errorDeleteCurso: false,
});

const handleGetCategoriasCursos = (state, action) => {
  return state.set('loadingCategoriasCursos', true).set('successCategoriasCursos', false).set('errorCategoriasCursos', false);
};

const handleGetCategoriasCursosSuccess = (state, action) => {
  return state
    .set('categoriasCursos', List(action.data))
    .set('loadingCategoriasCursos', false)
    .set('successCategoriasCursos', true)
    .set('errorCategoriasCursos', false);
};

const handleGetCategoriasCursosError = (state, action) => {
  return state.set('loadingCategoriasCursos', false).set('successCategoriasCursos', false).set('errorCategoriasCursos', action.error);
};

const handleGetMateriasAulas = (state, action) => {
  return state.set('loadingMateriasAulas', true).set('successMateriasAulas', false).set('errorMateriasAulas', false);
};

const handleGetMateriasAulasSuccess = (state, action) => {
  return state
    .set('materiasAulas', List(action.data))
    .set('loadingMateriasAulas', false)
    .set('successMateriasAulas', true)
    .set('errorMateriasAulas', false);
};

const handleGetMateriasAulasError = (state, action) => {
  return state.set('loadingMateriasAulas', false).set('successMateriasAulas', false).set('errorMateriasAulas', action.error);
};

const handleGetCurso = (state, action) => {
  return state.set('loadingCurso', true).set('successCurso', false).set('errorCurso', false);
};

const handleGetCursoSuccess = (state, action) => {
  return state.set('curso', action.data).set('loadingCurso', false).set('successCurso', true).set('errorCurso', false);
};

const handleGetCursoError = (state, action) => {
  return state.set('loadingCurso', false).set('successCurso', false).set('errorCurso', action.error);
};

const handleGetCursoCadastrado = (state, action) => {
  return state.set('loadingCursoCadastrado', true).set('successCursoCadastrado', false).set('errorCursoCadastrado', false);
};

const handleGetCursoCadastradoSuccess = (state, action) => {
  return state
    .set('cursoCadastrado', action.data)
    .set('loadingCursoCadastrado', false)
    .set('successCursoCadastrado', true)
    .set('errorCursoCadastrado', false);
};

const handleGetCursoCadastradoError = (state, action) => {
  return state.set('loadingCursoCadastrado', false).set('successCursoCadastrado', false).set('errorCursoCadastrado', action.error);
};

const handlePostCurso = (state, action) => {
  return state.set('postingCurso', true).set('successPostCurso', false).set('errorPostCurso', false);
};

const handlePostCursoSuccess = (state, action) => {
  return state.set('postingCurso', false).set('successPostCurso', true).set('errorPostCurso', false);
};

const handlePostCursoError = (state, action) => {
  return state.set('postingCurso', false).set('successPostCurso', false).set('errorPostCurso', action.error);
};

const handleDeleteCurso = (state, action) => {
  return state.set('deletingCurso', true).set('successDeleteCurso', false).set('errorDeleteCurso', false);
};

const handleDeleteCursoSuccess = (state, action) => {
  return state.set('deletingCurso', false).set('successDeleteCurso', true).set('errorDeleteCurso', false);
};

const handleDeleteCursoError = (state, action) => {
  return state.set('deletingCurso', false).set('successDeleteCurso', false).set('errorDeleteCurso', action.error);
};

const handleEndAula = ({ currentAula, currentAulaInicio }) => {
  if (currentAula.get('tipo') === 'GRUPO') return;

  apiPostNewAudiencia({
    idCategoria: currentAula.get('id'),
    horaInicio: formatTimeWithSeconds(currentAulaInicio),
    horaFim: formatTimeWithSeconds(new Date()),
  });
};

const handleSelectAula = (state, action) => {
  const { aula, cursoId, materiaId, materiaTitulo } = action.data;
  const materias = state.get('materiasAulas').toArray();

  if (aula) {
    localStorage.setItem(`usuario-${InfoUsuario.get('cdUsuario')}-curso-${cursoId}`, JSON.stringify({ aulaId: aula.id, materiaId }));
  }

  if (state.get('currentAulaInicio') === null) {
    state = state.set('currentAulaInicio', new Date());
  } else {
    handleEndAula({ currentAula: state.get('currentAula'), currentAulaInicio: state.get('currentAulaInicio') });
    state = state.set('currentAulaInicio', new Date());
  }

  const posMateria = materias.findIndex((materia) => materia.id === materiaId);
  const posAula = materias[posMateria].aulas.findIndex((row) => row.id === aula.id);

  let aulaAnterior = false;
  let proximaAula = false;

  if (posMateria === 0 && posAula === 0) {
    aulaAnterior = false;
  } else {
    if (posAula > 0) {
      const mat = materias[posMateria];
      aulaAnterior = { aula: mat.aulas[posAula - 1], materiaId: mat.id, materiaTitulo: mat.titulo };
    } else {
      const mat = materias[posMateria - 1];
      aulaAnterior = { aula: mat.aulas[mat.aulas.length - 1], materiaId: mat.id, materiaTitulo: mat.titulo };
    }
  }

  if (posMateria === materias.length - 1 && posAula === materias[posMateria].aulas.length - 1) {
    proximaAula = false;
  } else {
    if (posAula === materias[posMateria].aulas.length - 1) {
      const mat = materias[posMateria + 1];
      proximaAula = { aula: mat.aulas[0], materiaId: mat.id, materiaTitulo: mat.titulo };
    } else {
      const mat = materias[posMateria];
      proximaAula = { aula: mat.aulas[posAula + 1], materiaId: mat.id, materiaTitulo: mat.titulo };
    }
  }

  return state
    .set('currentAula', Map({ ...aula, materiaTitulo, materiaId }))
    .set('aulaAnterior', aulaAnterior)
    .set('proximaAula', proximaAula);
};

const handleResetAula = (state, action) => {
  localStorage.removeItem('ead-currentAula');
  localStorage.removeItem('ead-currentAulaInicio');
  return state
    .set('currentAula', Map({}))
    .set('currentAulaInicio', null)
    .set('materiasAulas', List())
    .set('curso', {})
    .set('cursoCadastrado', {})
    .set('materiasCadastro', List())
    .set('successPostCurso', false)
    .set('successDeleteCurso', false);
};

const handleAlterCurrentAula = (state, action) => {
  const { lido, curtido } = action.data;
  let currentAula = state.get('currentAula');
  const materias = state.get('materiasAulas').toArray();

  const materia = materias.filter((row) => currentAula.get('materiaId') === row.id)[0];
  let aula = materia.aulas.filter((row) => currentAula.get('id') === row.id)[0];

  if (lido !== undefined) {
    aula.lido = lido;
    currentAula = currentAula.set('lido', lido);
  }

  if (curtido !== undefined) {
    aula.curtido = curtido;
    currentAula = currentAula.set('curtido', curtido);

    const qtCurtido = currentAula.get('qtCurtido');

    if (curtido) {
      aula.qtCurtido = qtCurtido + 1;
    } else {
      aula.qtCurtido = qtCurtido - 1;
    }

    currentAula = currentAula.set('qtCurtido', aula.qtCurtido);
  }

  return state.set('currentAula', currentAula).set('materiasAulas', List(materias));
};

const handleSetAndamentoQuiz = (state, action) => {
  let currentAula = state.get('currentAula');
  const materias = state.get('materiasAulas').toArray();

  const materia = materias.find((row) => currentAula.get('materiaId') === row.id);
  let aula = materia.aulas.find((row) => currentAula.get('id') === row.id);

  aula.informacaoQuiz = action.data;

  return state.set('materiasAulas', List(materias));
};

const handleSetMateriasCadastro = (state, action) => {
  const { data } = action;
  return state.set('materiasCadastro', List(data));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CATEGORIAS_CURSOS:
      return handleGetCategoriasCursos(state, action);
    case Types.GET_CATEGORIAS_CURSOS_SUCCESS:
      return handleGetCategoriasCursosSuccess(state, action);
    case Types.GET_CATEGORIAS_CURSOS_ERROR:
      return handleGetCategoriasCursosError(state, action);

    case Types.GET_MATERIAS_AULAS:
      return handleGetMateriasAulas(state, action);
    case Types.GET_MATERIAS_AULAS_SUCCESS:
      return handleGetMateriasAulasSuccess(state, action);
    case Types.GET_MATERIAS_AULAS_ERROR:
      return handleGetMateriasAulasError(state, action);

    case Types.GET_CURSO:
      return handleGetCurso(state, action);
    case Types.GET_CURSO_SUCCESS:
      return handleGetCursoSuccess(state, action);
    case Types.GET_CURSO_ERROR:
      return handleGetCursoError(state, action);

    case Types.GET_CURSO_CADASTRADO:
      return handleGetCursoCadastrado(state, action);
    case Types.GET_CURSO_CADASTRADO_SUCCESS:
      return handleGetCursoCadastradoSuccess(state, action);
    case Types.GET_CURSO_CADASTRADO_ERROR:
      return handleGetCursoCadastradoError(state, action);

    case Types.POST_CURSO:
      return handlePostCurso(state, action);
    case Types.POST_CURSO_SUCCESS:
      return handlePostCursoSuccess(state, action);
    case Types.POST_CURSO_ERROR:
      return handlePostCursoError(state, action);

    case Types.DELETE_CURSO:
      return handleDeleteCurso(state, action);
    case Types.DELETE_CURSO_SUCCESS:
      return handleDeleteCursoSuccess(state, action);
    case Types.DELETE_CURSO_ERROR:
      return handleDeleteCursoError(state, action);      

    case Types.SELECT_AULA:
      return handleSelectAula(state, action);

    case Types.RESET_AULA:
      return handleResetAula(state, action);

    case Types.ALTER_CURRENT_AULA:
      return handleAlterCurrentAula(state, action);

    case Types.SET_MATERIAS_CADASTRO:
      return handleSetMateriasCadastro(state, action);

    case Types.SET_ANDAMENTO_QUIZ:
      return handleSetAndamentoQuiz(state, action);

    default:
      return state;
  }
}

export { handleEndAula };
