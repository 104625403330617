import { InfoUsuario } from '@utils';
import { get, post } from '../../api';

const { REACT_APP_IMAGES } = window.env;

export const generatePreview = (original, textos, retornaJson = true, useImagePreview = false) => {
  let queryParams = '';
  if(retornaJson) {
    queryParams = '?json=true';
  }
  if(useImagePreview) {
    queryParams += queryParams === '' ? '?' : '&';
    queryParams += 'useImagePreview=true';
  }
  return post(
    `${REACT_APP_IMAGES}/localized/proccess${queryParams}`, {
      textos: JSON.stringify(textos),
      original
    }
  );
};

export const getCategoria = (idCategoria) => {
  return get(`/api/wiki/imagem/${idCategoria}`).then((categoria) => {
    if (!categoria.parametrosImagem) return {
      original: categoria.conteudo,
    };

    try {
      const parametros = JSON.parse(categoria.parametrosImagem);

      if (parametros && parametros.textos) {
        parametros.textos = parametros.textos.map((item, index) => ({ ...item, id: item.id || index }));
      }

      return parametros;
    } catch (e) {
      return {};
    }
  });
};

export const saveCategoria = (idCategoria, original, textos) => {
  const body = {
    parametrosImagem: JSON.stringify({
      original,
      textos,
    }),
    conteudo: original,
  };

  return post(`/api/wiki/imagem/${idCategoria}`, body);
};

export const salvarPersonalizacao = (dadosPersonalizacao) => {
  if (InfoUsuario.get('senhaMaster')) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  return post('/api/wiki/usuarioPersonalizacao/salvar', dadosPersonalizacao);
};

export const getListaProdutosCadastro = () => {
  return get('/api/wiki/personalization/list/product');
};

export const getListaProdutos = (idsItemsListaProdutos, idUser) => {
  return get(`/api/wiki/personalization/list/product/user/${idUser}?items=${idsItemsListaProdutos}`);
};

export const getListaProdutosSelect = () => {
  return getListaProdutosCadastro().then((res) => {
    return res
      .map((item) => ({
        key: item.id,
        value: item.id,
        title: item.nome
      }));
  });
};

export const getListaProdutosItem = (idList) => {
  return get(`/api/wiki/personalization/list/product/items/${idList}`);
};

export const getListaProdutosItemSelect = (idList) => {
  return getListaProdutosItem(idList).then((res) => {
    return res
      .map((item) => ({
        key: item.id,
        value: item.id,
        title: item.nome
      }));
  });
};

export const saveValuesListProduct = (data) => {
  return post('/api/wiki/personalization/list/product/items', data);
};

export const getListaPersonalizacaoBloqueio = (items, idUser) => {
  return get(`/api/wiki/personalization/list/product/items/saved/correctly?idUser=${idUser}&items=${items}`);
};