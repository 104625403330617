import React from 'react';

import { rgba } from 'polished';

import { Chart as ChartJS, LineController, PointElement, LineElement, LinearScale, BarElement, CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';

ChartJS.register(LineController, PointElement, LineElement, LinearScale, BarElement, CategoryScale, ChartDataLabels);

export const VerticalBarWithAverageLineChart = ({ data, datasets }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  const arrayQuantity = data.map((item)=> item.totalValue);

  const FindMaxNumber = Math.max(...arrayQuantity);

  const formattedData = {
    labels: data.map(({ label })=>label),
    datasets: datasets ,
  };

  const options = {
    indexAxis: 'x', 
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        onClick: null,
        position: 'bottom',
        display: true,
        align: 'start',
        labels: {
          font:{
            size:14,
            family: 'Open Sans',
            weight: 400,
          },
          usePointStyle: true,
          pointStyle: 'rect',
        }
      },
      tooltip: {
        enabled: true,
      },
      datalabels:{
        display: function(context) {
          return context.dataset.label !== 'Média acumulada';
        },
        formatter: function(value, context) {
          const datasetArray = [];
          
          context.chart.data.datasets.forEach((dataset)=>{ 
            if(dataset.label !== 'Média acumulada') {
              if(dataset.data[context.dataIndex] !== undefined) {
                  
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            }
          });
          
          function totalSum(total, datapoint) {
            return total + datapoint;
          }

          let sum = datasetArray.reduce(totalSum, 0);
          
          if(context.datasetIndex === datasetArray.length) {
            if(sum > 0) {
              return sum;
            }else {
              return '';
            }
            
          } else {
            return '';
          }
        },
        anchor: 'end',
        align: function(context) {
          const value = data[context.dataIndex].totalValue;
          return value > (FindMaxNumber * 0.80) ? 'start' : 'end'; 
        },
        font:{
          size:12,
          weight: 'bold',
        },
        backgroundColor: function(context) {
          const datasetArray = [];
          context.chart.data.datasets.forEach((dataset)=>{
            if(dataset.label !== 'Média acumulada') {
              if(dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            }
          });
          function totalSum(total, datapoint) {
            return total + datapoint;
          }

          let sum = datasetArray.reduce(totalSum, 0);

          if(context.datasetIndex === datasetArray.length ) {
            
            if(sum > 0) {
              return theme['@global-dark-secondary-color'];
            }else {
              return ;
            }
          } else {
            return ;
          }
        },
        padding: {
          top: 5,
          bottom: 5,
          right: 10,
          left: 10
        },
        color: '#fff', 
        borderRadius: 5,
        
      }
    },
    aspectRatio: 3,
    scales: {
      y:{
        stacked: true,
        beginAtZero: true,
        position: 'top',
        border:{
          color: rgba(255, 255, 255, 0.5),
          dash:[6],
        },
        ticks: {
          font: {
            weight: '400',
            size: 16,
          },
          color: rgba(255, 255, 255, 0.5),
        },
        grid:{
          color: rgba(255, 255, 255, 0.2),
        }
      },
      x:{
        stacked: true,
        beginAtZero: true,
        border:{
          color: rgba(255, 255, 255, 0.5),
        },
        ticks: {
          font: {
            weight: '400',
            size: 16,
          },
          color: rgba(255, 255, 255, 0.5),

        },
        grid: {
          display: false,
        },
      }
    }
  };

  return(
    <>
      <Bar data={formattedData} options={options}/>
      
    </>
  );
};

export default VerticalBarWithAverageLineChart;