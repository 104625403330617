import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getFamilies as getFamiliesSdk,
  postFamily as postFamilySdk,
  putFamily as putFamilySdk,
} from '@sdk/Families';

//Action Types
export const Types = {
  GET_FAMILIES: 'PRODUCTS/GET_FAMILIES',
  GET_FAMILIES_SUCCESS: 'PRODUCTS/GET_FAMILIES_SUCCESS',
  GET_FAMILIES_ERROR: 'PRODUCTS/GET_FAMILIES_ERROR',
  
  POST_FAMILY: 'PRODUCTS/POST_FAMILY',
  POST_FAMILY_SUCCESS: 'PRODUCTS/POST_FAMILY_SUCCESS',
  POST_FAMILY_ERROR: 'PRODUCTS/POST_FAMILY_ERROR',
  
  PUT_FAMILY: 'PRODUCTS/PUT_FAMILY',
  PUT_FAMILY_SUCCESS: 'PRODUCTS/PUT_FAMILY_SUCCESS',
  PUT_FAMILY_ERROR: 'PRODUCTS/PUT_FAMILY_ERROR',
  
  RESET: 'PRODUCTS/RESET',
  
  RESET_SUCCESS: 'PRODUCTS/RESET_SUCCESS',
};
  
//Action Creators
export const getFamilies = (familyId) => ({ type: Types.GET_FAMILIES, familyId });
export const getFamiliesSuccess = (families) => ({ type: Types.GET_FAMILIES_SUCCESS, families });
export const getFamiliesError = (error) => ({ type: Types.GET_FAMILIES_ERROR, error }); 

export const postFamily = (family) => ({ type: Types.POST_FAMILY, family });
export const postFamilySuccess = (family) => ({ type: Types.POST_FAMILY_SUCCESS, family });
export const postFamilyError = (error) => ({ type: Types.POST_FAMILY_ERROR, error });

export const putFamily = (family) => ({ type: Types.PUT_FAMILY, family });
export const putFamilySuccess = (family) => ({ type: Types.PUT_FAMILY_SUCCESS, family });
export const putFamilyError = (error) => ({ type: Types.PUT_FAMILY_ERROR, error });

export const reset = () => ({ type: Types.RESET });

export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

//saga
function* fetchGetFamilies(action) {
  try {
    const { familyId } = action;
    const families = yield call(getFamiliesSdk, familyId);
    yield put(getFamiliesSuccess(families));
  } catch (err) {
    Notification.error(err.message);
    yield put(getFamiliesError(err));
  }
}

function* fetchPostFamily(action) {
  try{
    let { family } = action;
    family = yield call(postFamilySdk, family);
    yield put(postFamilySuccess(family));
  } catch (err) {
    Notification.error(err.message);
    yield put(postFamilyError(err));
  }
}

function* fetchPutFamily(action) {
  try{
    let { family } = action;
    family = yield call(putFamilySdk, family);
    yield put(putFamilySuccess(family)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(putFamilyError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_FAMILIES, fetchGetFamilies),
  takeLatest(Types.POST_FAMILY, fetchPostFamily),
  takeLatest(Types.PUT_FAMILY, fetchPutFamily),
];
  
// Reducer
const initialState = Map({
  families: List(),
  loadingFamilies: false,
  successFamilies: false,
  errorFamilies: false,

  loadingFamilySave:false,
  successFamilySave:false,
  errorFamilySave:false,
});

const handleGetFamilies = (state, action) => {
  return state.set('loadingFamilies', true).set('successFamilies', false).set('errorFamilies', false);
};
  
const handleGetFamiliesSuccess = (state, action) => {
  const { families } = action;
  
  return state.set('families', families).set('loadingFamilies', false).set('successFamilies', true).set('errorFamilies', false);
};
  
const handleGetFamiliesError = (state, action) => {
  return state.set('loadingFamilies', false).set('successFamilies', false).set('errorFamilies', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingFamilySave', true).set('successFamilySave', false).set('errorFamilySave', false);
};
  
const handleSaveSuccess = (state, action) => {
  return state.set('loadingFamilySave', false).set('successFamilySave', true).set('errorFamilySave', false);
};
  
const handleSaveError = (state, action) => {
  return state.set('loadingFamilySave', false).set('successFamilySave', false).set('errorFamilySave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccess = (state, action) => {
  return state.set('successFamilies', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_FAMILIES:
      return handleGetFamilies(state, action);
    case Types.GET_FAMILIES_SUCCESS:
      return handleGetFamiliesSuccess(state, action);
    case Types.GET_FAMILIES_ERROR:
      return handleGetFamiliesError(state, action);

    case Types.POST_FAMILY:
      return handleSave(state, action);
    case Types.POST_FAMILY_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_FAMILY_ERROR:
      return handleSaveError(state, action);
  
    case Types.PUT_FAMILY:
      return handleSave(state, action);
    case Types.PUT_FAMILY_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_FAMILY_ERROR:
      return handleSaveError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);

    default:
      return state;
  }
}