import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

export const StyledInputMask = styled(ReactInputMask)`
  background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
  border: 2px solid ${({ theme }) => theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 14px;
  width: 100% !important;

  &::placeholder {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 0.5 !important;
  }

  :focus-within {
    border: 2px solid ${({ theme }) => theme.actionColor} !important;
    outline: none !important;
  }

  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;