const COLOR_SCHEME_TYPE = {
  SUCCESS: '#34C759',
  WARNING: '#FFCC00',
  DANGER: '#FF3B30',
  DEFAULT: '#1890FF'
};

export const getColorSchemeByType = (type, theme) => {
  switch (type) {
    case 'success':
      return { idle: COLOR_SCHEME_TYPE.SUCCESS, idleLabel: '#FFF' };
    case 'primary':
      return { idle: theme?.actionColor || COLOR_SCHEME_TYPE.DEFAULT, idleLabel: theme?.textContrastColor || '#FFF' };
    case 'secondary':
      return { idle: theme?.darkPrimaryColor || '#4c4766', idleLabel: theme?.textColor || '#FFF' };
    case 'tertiary':
      return { idle: theme?.darkSecondaryColor || '#38344B', idleLabel: theme?.textColor || '#FFF' };
    case 'highlight':
      return { idle: theme?.darkHighlightColor || '#736b99', idleLabel: theme?.textColor || '#FFF' };
    case 'danger':
      return { idle: COLOR_SCHEME_TYPE.DANGER, idleLabel: '#FFF' };
    case 'warning':
      return { idle: COLOR_SCHEME_TYPE.WARNING, idleLabel: '#FFF' };
    case 'ghost':
      return { idle: theme?.darkHighlightColor || '#736b99', idleLabel: theme?.textColor || '#FFF' };
    case 'icon':
      return { idle: '#00000000', idleLabel: theme?.textColor || '#FFF' };
    default:
      return { idle: COLOR_SCHEME_TYPE.DEFAULT, idleLabel: '#FFF' };
  }
};