import { get, put, post } from '../api';

export const getCategories = (categoryId) =>{
  return get(`/api/products/categories/${categoryId !== undefined ? categoryId : '' }`);
};

export const postCategory = (category) => {
  return post('/api/products/categories/', category);
};

export const putCategory = (category) => {
  return put('/api/products/categories', category);
};