import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table, ContentTitle, View, Dropdown, Menu, Skeleton, Icon } from '@components-teammove';
import { Alias, TagInUse, TagLabel, Tables, MenuButton, RowTable } from './styles';
import { useDispatch, useSelector } from 'react-redux';

import { getPriceTables, putPriceTable } from '@ducks/settingsProductsPriceTables';

export default function TabelaPrecos() {
  const history = useHistory();
  const dispatch = useDispatch();

  const priceTables = useSelector(({ priceTables }) => priceTables.get('priceTables'));
  const loadingPriceTables = useSelector(({ priceTables }) => priceTables.get('loadingPriceTables'));

  const loadingPriceTableSave = useSelector(({ priceTables }) => priceTables.get('loadingPriceTableSave'));
  const successPriceTableSave = useSelector(({ priceTables }) => priceTables.get('successPriceTableSave'));

  useEffect(() => {
    dispatch(getPriceTables());
  }, []);

  useEffect(() => {
    if (successPriceTableSave) {
      dispatch(getPriceTables());
    }

  }, [successPriceTableSave]);

  const handleChangeDefaultPriceTables = (tableId) => {
    dispatch(putPriceTable({
      id: tableId,
      isDefault: true
    }));
  };

  const columns = [
    {
      title: 'Tabela de Preços',
      dataIndex: 'name',
      sorter: true,
      render: (text, tab) => (
        <RowTable isDefault={tab.isDefault} justify='space-between' align='center'>
          <Alias>
            {text}
            {tab.isDefault &&
              <TagInUse>
                <TagLabel>Padrão</TagLabel>
              </TagInUse>}
          </Alias>
          <Button
            isDefault
            title
            type='secondary'
            size='small'
            context='list'
          >
            Editar
          </Button>
          {!tab.isDefault && (
            <View
              onClick={(e) => (e.stopPropagation())}
            >
              <Dropdown
                key="more"
                overlay={(
                  <Menu>
                    <Menu.Item
                      onClick={(e) => { e.domEvent.stopPropagation(); handleChangeDefaultPriceTables(tab.id); }}
                    >
                      <MenuButton type="icon">
                        Alterar Padrão
                      </MenuButton>
                    </Menu.Item>
                  </Menu>
                )}
              >
                <Button type="icon">
                  <Icon.MDI type="dots-vertical" />
                </Button>
              </Dropdown>

            </View>
          )}
        </RowTable>
      )
    }];

  return (
    <>
      <>
        {loadingPriceTables || loadingPriceTableSave ? (<Skeleton.Map />) : (
          <Table
            columns={columns}
            dataSource={priceTables && priceTables.filter((tab) => tab.active)}
            rowKey='id'
            status='active'
            loading={loadingPriceTables}
            onRow={(tab) => ({ onClick: () => history.push(`/configuracoes/mixprodutos/${tab.id}`) })}
          />
        )}
        {loadingPriceTables || loadingPriceTableSave ? <Skeleton.Map /> : (
          <Tables>
            <ContentTitle>Inativos</ContentTitle>
            <Table
              columns={columns}
              dataSource={priceTables && priceTables.filter((tab) => !tab.active)}
              rowKey='id'
              status='active'
              loading={loadingPriceTables}
              onRow={(tab) => ({ onClick: () => history.push(`/configuracoes/mixprodutos/${tab.id}`) })}
            />
          </Tables>
        )}
      </>
    </>
  );
}