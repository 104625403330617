import React from 'react';
import { Table, Row, Tooltip, Avatar, Text, Column } from '@components-teammove';
import { TelasNomenclaturas, getDownloadUrlAvatar, formatDate, formatTime, formatCurrency, formatTelephone } from '@utils';
import { FeatherIcon, ImportanceIcon, VerticalDivider } from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const TableComponent = ({ onSorter, orderByField, orderByDirection }) => {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const history = useHistory();
  
  const callList = useSelector(({ callActivity }) => callActivity.get('callList'));
  const loadingCallList = useSelector(({ callActivity }) => callActivity.get('loadingCallList'));

  function formatSecondsToHMS(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  const formatContactImporation = (text) => {
    switch (text) {
      case 'Nao_Relevante':
        return 'Nao Relevante';
      case 'Relevante':
        return 'Relevante';
      default:
        return 'Sem Contato';
    }
  };

  const columns = [
    {
      title: companyNomenclature.nomenclatura,
      defaultSortOrder: orderByField === 'UNIDADE' ? orderByDirection.toLowerCase()  + 'end' : null,
      dataIndex: 'companyName',
      sorter: true,
      width: '200px',
      render: (text, itens) => (
        <Column>
          <Tooltip title={text?.nm_reduzido}>
            <Text 
              size='18px' 
              weight='400' 
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{itens.companyShortName}</Text>
          </Tooltip>
          <Tooltip title={text?.nm_empresa}>
            <Text 
              size='14px' 
              weight='400' 
              lowOpacity
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{text}</Text>
          </Tooltip>
        </Column>
      )
    },
    {
      title: 'Contato',
      dataIndex: 'contactName',
      defaultSortOrder: orderByField === 'CONTATO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      width: '200px',
      render: (text, itens) => (
        <Column>
          <Tooltip title={text}>
            <Text 
              size='18px' 
              weight='400' 
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{text}</Text>
          </Tooltip>
          
          <Tooltip title={text}>
            <Text 
              size='14px' 
              weight='400' 
              lowOpacity
              textOverflow='ellipsis'
              whiteSpace='nowrap' 
              overflow='hidden' 
              maxWidth='200px'
            >{formatTelephone(itens.numberCalled.substring(3))}</Text>
          </Tooltip>
        </Column>
      )
    },
    {
      title: 'Responsável',
      dataIndex: 'userName',
      defaultSortOrder: orderByField === 'RESPONSAVEL' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      width: '200px',
      render: (text, itens) => (
        <Row align='center' gap='12px'>
          <Avatar size='44px' src={getDownloadUrlAvatar(itens.avatarUrl)}/>
          <VerticalDivider />
          <Column>
            <Tooltip title={text}>
              <Text 
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='175px' 
                size='18px' 
                weight='inherit'
              >
                {text}
              </Text>
            </Tooltip>

            <Tooltip title={itens.userRole}>
              <Text 
                size='14px' 
                weight='400' 
                lowOpacity
                textOverflow='ellipsis'
                whiteSpace='nowrap' 
                overflow='hidden' 
                maxWidth='200px'
              >
                {itens.userRole}
              </Text>
            </Tooltip>
          </Column>
        </Row>
      )
    },
    {
      title: 'Data',
      dataIndex: 'recordStartTime',
      defaultSortOrder: orderByField === 'DATA' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Text size='18px' weight='400'>{formatDate(text)}</Text>
          <Text size='14px' weight='400' lowOpacity>{formatTime(text)}</Text>
        </Column>
      )
    },
    {
      title: 'Duração',
      dataIndex: 'recordDuration',
      defaultSortOrder: orderByField === 'DURACAO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Text size='18px' weight='400'>
            {formatSecondsToHMS(text)}
          </Text>
        </Column>
      )
    },
    {
      title: 'Custo',
      dataIndex: 'price',
      defaultSortOrder: orderByField === 'CUSTO' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Text size='18px' weight='400'>{formatCurrency(text)}</Text>
        </Column>
      )
    },
    {
      title: 'Importância',
      dataIndex: 'classification',
      defaultSortOrder: orderByField === 'IMPORTANCIA' ? orderByDirection.toLowerCase()  + 'end' : null,
      sorter: true,
      render: (text, itens) => (
        <Column >
          <Row align='center' gap='12px'>
            <ImportanceIcon importance={text}>
              <FeatherIcon
                size='24px' type={text === 'Relevante' ? 'thumbs-up' : text === 'Nao_Relevante' ? 'thumbs-down' : 'user-x'} 
                importance={text}
              />
            </ImportanceIcon>
            <Text size='18px' weight='400'>{formatContactImporation(text) || 'Sem Contato'}</Text>
          </Row>
         
        </Column>
      ) },
  ];

  return(
    <Table
      columns={columns} 
      dataSource={callList.listVoiceCallRecords}
      loading={loadingCallList}
      hidePagination
      onChange={((pagination, filters, sorter) => onSorter(sorter.order, sorter.field))}
      onRow={(actionPlan) => {
        return {
          onClick: () => actionPlan?.voiceCallRecordId && history.push(`/atividades/ligacoes/detalhes/${actionPlan?.voiceCallRecordId}`)
        };
      }}
    />
  );

};