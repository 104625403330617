import styled from 'styled-components';
import { Text, View } from '@components';
import { Icon } from '@components-teammove';

export const Container = styled(View)`
  flex-direction: row;
  align-items: center;
`;

export const Label = styled(Text)`
  width: 50px;
  padding: 10px;
  font-size: 15px;
  opacity: 0.8;
`;

export const CheckIcon = styled(Icon.Feather)`
  color: var(--success-color);
`;

export const WarningIcon = styled(Icon.ExclamationOutlined)`
  &.anticon {
    color: var(--warning-color);
  }
`;

export const DangerIcon = styled(Icon.Feather)`
  color: var(--danger-color);
`;