import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { View, PopConfirm  } from '@components';
import { Icon } from '@components-teammove';
import { arrayReorder } from '@utils';
import styled, { css } from 'styled-components';
import ModalMateria from './ModalMateria';
import ModalAula from './Aulas/ModalAula';
import Aulas from './Aulas';
import ModalQuiz from '@containers/Wikis/EAD/Quiz/Novo/Modal';
import { getMinId } from '@utils/Array';

const ViewMateria = styled(View)`
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 3px;
  ${({ mt }) =>
  mt
    ? css`
          margin-top: ${mt};
        `
    : css``}
`;

const ViewAula = styled(View)`
  padding: 10px 10px 10px 50px;
  background-color: #fafafa;
  border-radius: 3px;
  border-bottom: 1px solid #f5f5f5;
`;

const Icones = styled(View)`
  width: 50px;
  justify-content: space-between;
  font-size: 18px;
`;

const ViewDragIcon = styled(View)`
  width: 40px;
  font-size: 18px;
`;

const Add = styled(Icon.PlusOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const Edit = styled(Icon.EditOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const Delete = styled(Icon.DeleteOutlined)`
  cursor: pointer;
  :hover {
    color: #1890ff !important;
  }
`;

const Drag = styled(Icon.MDI)`
  cursor: move;
  :hover {
    color: #1890ff !important;
  }
`;

const Empty = styled(View)`
  cursor: pointer;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  :hover {
    color: #1890ff !important;
  }
`;

const ViewAcoesMateria = styled(View)`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ViewTituloMateria = styled(View)`
  font-weight: bold;
  margin-right: 10px;

  ${(props) =>
  !props.publicado &&
    css`
      text-decoration: line-through;
      opacity: 0.7;
    `};
`;

const Content = styled(View)`
  margin: 15px 0;
`;

const ViewData = styled(View)`
  width: 100%;
`;

export default function Materias({ materias, setMaterias, categoriasDelete, setCategoriasDelete }) {
  const [visibleModalMateria, setVisibleModalMateria] = useState(false);
  const [dataModalMateria, setDataModalMateria] = useState({});
  const [handleOkModalMateria, setHandleOkModalMateria] = useState(() => { });

  const [visibleModalAula, setVisibleModalAula] = useState(false);
  const [dataModalAula, setDataModalAula] = useState({});
  const [handleOkModalAula, setHandleOkModalAula] = useState(() => { });

  const [visibleModalQuiz, setVisibleModalQuiz] = useState(false);
  const [dataModalQuiz, setDataModalQuiz] = useState({});
  const [handleOkModalQuiz, setHandleOkModalQuiz] = useState(() => { });

  const addMateria = ({ materia }) => {
    const materiasClone = [...materias];
    const minId = materiasClone.length ? Math.abs(Math.min(...materiasClone.map((aula) => aula.id))) * -1 - 1 : -1;
    materiasClone.push({ ...materia, id: minId, publicado: true, aulas: [] });
    setMaterias(materiasClone);
    handleCloseModalMateria();
  };

  const editMateria = ({ index, materia }) => {
    const materiasClone = [...materias];
    materiasClone[index] = materia;
    setMaterias(materiasClone);
    handleCloseModalMateria();
  };

  const handleOpenModalMateria = (data) => {
    setHandleOkModalMateria(data.materia ? () => editMateria : () => addMateria);
    setDataModalMateria(data);
    setVisibleModalMateria(true);
  };

  const handleCloseModalMateria = () => setVisibleModalMateria(false);

  const addAula = ({ aula, materiaId }) => {
    const materiasClone = [...materias];
    const materia = materiasClone.find((materia) => materia.id === (aula.newMateriaId ? aula.newMateriaId : materiaId));
    const minId = materia?.aulas?.length ? Math.abs(Math.min(...materia.aulas.map((aula) => aula.id))) * -1 - 1 : -1;
    materia.aulas.push({ ...aula, id: minId, publicado: true });
    setMaterias(materiasClone);
    handleCloseModalAula();
  };

  const editAula = ({ index, aula, materiaId }) => {
    if (aula.newMateriaId && aula.newMateriaId !== materiaId) {
      const oldMateria = materias.find((materia) => materia.id === materiaId);
      const oldMateriaUpdated = { ...oldMateria, 
        aulas: oldMateria.aulas.filter(({ titulo, resumo }) => titulo !== aula.titulo || resumo !== aula.resumo) 
      };

      addAula({ aula });
      handleDeleteAula({ materiaId: oldMateria.id, aulaId: aula.id });

      setMaterias(materias.map((materia) => {
        if (materia.id === materiaId) return oldMateriaUpdated; 
        else return materia;
      }));

      handleCloseModalAula();
    }
    else{
      const materiasClone = [...materias];
      const materia = materiasClone.find((materia) => materia.id === materiaId);
      materia.aulas[index] = aula;
      setMaterias(materiasClone);
      handleCloseModalAula();
    }
  };

  const handleOpenModalAula = (data) => {
    setHandleOkModalAula(data.aula ? () => editAula : () => addAula);
    setDataModalAula(data);
    setVisibleModalAula(true);
  };

  const handleCloseModalQuiz = () => setVisibleModalQuiz(false);

  const addQuiz = ({ quiz, materiaId }) => {
    const materiasClone = [...materias];
    const materia = materiasClone.find((materia) => materia.id === (quiz.newMateriaId ? quiz.newMateriaId : materiaId));
    const minIdAula = getMinId(materia.aulas);
    materia.aulas.push({ id: minIdAula, quiz: { ...quiz, id: -1, publicado: true } });

    setMaterias(materiasClone);
    handleCloseModalQuiz();
  };

  const editQuiz = ({ index, quiz, materiaId }) => {
    if (quiz.newMateriaId && quiz.newMateriaId !== materiaId) {
      const oldMateria = materias.find((materia) => materia.id === materiaId);
      const oldMateriaUpdated = { ...oldMateria, 
        aulas: oldMateria.aulas.filter((aula) => aula.quiz?.descricao !== quiz.descricao || aula.quiz?.perguntas !== quiz.perguntas)
      };

      addQuiz({ quiz });

      setMaterias(materias.map((materia) => {
        if (materia.id === materiaId) return oldMateriaUpdated; 
        else return materia;
      }));

      handleCloseModalQuiz();
    }
    else{
      const materiasClone = [...materias];
      const materia = materiasClone.filter((materia) => materia.id === materiaId)[0];
      materia.aulas[index].quiz = quiz;
      setMaterias(materiasClone);
      handleCloseModalQuiz();
    }
  };

  const handleOpenModalQuiz = (data) => {
    setHandleOkModalQuiz(data.quiz ? () => editQuiz : () => addQuiz);
    setDataModalQuiz(data);
    setVisibleModalQuiz(true);
  };

  const handleCloseModalAula = () => setVisibleModalAula(false);

  const onDragEndMateria = ({ oldIndex, newIndex }) => {
    setMaterias(arrayReorder(materias, oldIndex, newIndex));
  };

  const onDragEndAula = ({ oldIndex, newIndex }, materiaId) => {
    const materiasClone = [...materias];
    const materia = materiasClone.filter((mat) => mat.id === materiaId)[0];

    materia.aulas = arrayReorder(materia.aulas, oldIndex, newIndex);

    setMaterias(materiasClone);
  };

  const cancelSort = ({ path }) => {
    if ([path[0].id, path[1].id, path[2].id].includes('move-icon')) return false;
    return true;
  };

  const handleDeleteMateria = (id) => {
    if (id > 0) setCategoriasDelete([...categoriasDelete, id]);

    const materiasClone = [...materias.filter((materia) => materia.id !== id)];
    setMaterias(materiasClone);
  };

  const handleDeleteAula = ({ materiaId, aulaId }) => {
    if (aulaId > 0) setCategoriasDelete([...categoriasDelete, aulaId]);

    const materiasClone = [...materias];
    const materia = materiasClone.filter((materia) => materia.id === materiaId)[0];
    const aulas = materia.aulas.filter((aula) => aula.id !== aulaId);
    materia.aulas = aulas;

    setMaterias(materiasClone);
  };

  const ItemMateria = SortableElement(({ value, row }) => (
    <Content>
      <ViewMateria horizontal>
        <ViewDragIcon horizontal>
          <Drag type='drag' id='move-icon' />
        </ViewDragIcon>
        <ViewData>
          <ViewAcoesMateria>
            <ViewTituloMateria publicado={value.publicado}>{value.titulo}</ViewTituloMateria>
            <Icones horizontal>
              <Edit onClick={() => handleOpenModalMateria({ materia: value, index: row })} />
              <PopConfirm title="Confirma a exclusão da matéria?" onConfirm={() => handleDeleteMateria(value.id)} okText="Sim" cancelText="Não">
                <Delete />
              </PopConfirm>
            </Icones>
          </ViewAcoesMateria>
        </ViewData>
      </ViewMateria>
      <View>
        {value.aulas.length > 0 && (
          <Aulas
            data={value}
            onDragEnd={onDragEndAula}
            cancelSort={cancelSort}
            openModal={handleOpenModalAula}
            openModalQuiz={handleOpenModalQuiz}
            deleteAula={handleDeleteAula}
          />
        )}
        <ViewAula>
          <Empty onClick={() => handleOpenModalAula({ index: value?.aulas?.length || 0, materiaTitulo: value.titulo, materiaId: value.id })}>
            <Add />
            <b>&nbsp;Aula</b>
          </Empty>
          <Empty onClick={() => handleOpenModalQuiz({ index: 0, materiaTitulo: value.titulo, materiaId: value.id })}>
            <Add />
            <b>&nbsp;Quiz</b>
          </Empty>
        </ViewAula>
      </View>
    </Content>
  ));

  const ListMaterias = SortableContainer(({ items }) => {
    return (
      <View>
        {items?.length > 0 && (
          <View>
            {items.map((value, index) => (
              <ItemMateria key={value.titulo} index={index} row={index} value={value} />
            ))}
          </View>
        )}
        <ViewMateria mt='15px'>
          <Empty onClick={() => handleOpenModalMateria({ index: materias?.length || 0 })}>
            <Add />
            <b>&nbsp;Matéria</b>
          </Empty>
        </ViewMateria>
      </View>
    );
  });

  return (
    <View>
      <ListMaterias items={materias} onSortEnd={onDragEndMateria} shouldCancelStart={(event) => cancelSort(event)} />
      {visibleModalMateria && (
        <ModalMateria
          visible={visibleModalMateria}
          onCancel={handleCloseModalMateria}
          onOk={handleOkModalMateria}
          loading={false}
          data={dataModalMateria}
        />
      )}
      {visibleModalAula && (
        <ModalAula
          materias={materias}
          visible={visibleModalAula}
          onCancel={handleCloseModalAula}
          onOk={handleOkModalAula}
          loading={false}
          data={dataModalAula}
        />
      )}
      {visibleModalQuiz && (
        <ModalQuiz
          materias={materias}
          visible={visibleModalQuiz}
          onCancel={handleCloseModalQuiz}
          onOk={handleOkModalQuiz}
          loading={false}
          data={dataModalQuiz}
        />
      )}
    </View>
  );
}
