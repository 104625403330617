import styled from 'styled-components';
import { Icon, Text, View } from '@components-teammove';

export const Row = styled(View)`
  flex-direction: row;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const Container = styled(View)`
  gap: 20px;
`;

export const DestaquesTitle = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const DestaquesIcon = styled(Icon.Bootstrap)`
  color: ${({ theme }) => theme.textColor};
`;