import React from 'react';
import styled from 'styled-components';
import { formatDate, InfoUsuario } from '@utils';
import { View, Text } from '@components';
import { Icon , StatusTag, Tooltip } from '@components-teammove';

import { getLabelStatus } from './rules';
import { STATUS_AGUARDANDO_RESPOSTA, STATUS_FECHADO } from '../rules';

const Content = styled(View)`
  width: max-content;  
`;

const WhiteText = styled(Text)`
  &.ant-typography {
    color: #F5F5F5;
  }
`;

const Status = ({ chamado, texto }) => {
  const { text, color } = (() => {

    const isAwaitingResponse = chamado.aguardandoResposta; // aguardando_resposta

    if (isAwaitingResponse) {

      const isUserAssignee = InfoUsuario.get('cdUsuario') === chamado.idUsuario; // idUsuario = c.id_usuario

      if (isUserAssignee) {

        const isResponseDelayed = chamado.aguardandoRespostaStatus === STATUS_AGUARDANDO_RESPOSTA.ATRASADO; // dt_aguardando_resposta

        if (isResponseDelayed) {
          return {
            color: '#F44336',
            text: 'Pendência atrasada'
          };
        } else {
          return {
            color: '#E65100',
            text: 'Pendência aberta'
          };
        }
      } else {
        return {
          color: '#E65100',
          text: 'Aguardando pendência' 
        };
      }
    } else {
      return {
        color: chamado.statusCor,
        text: texto
      };
    }
  })();
  
  return( 
    <Content>
      <StatusTag 
        color={color} 
        text={text} 
        size='large'
        fixedWidth='150px'
        icon={chamado.statusFechado && (
          <Tooltip title={<TooltipStatus chamado={chamado}/>}>
            {chamado.statusFechado === STATUS_FECHADO.NO_PRAZO ? <Icon.LikeOutlined /> : <Icon.DislikeOutlined />}
          </Tooltip>
        )}
      />
    </Content>    
  );};

const TooltipStatus = ({ chamado }) => {
  const { statusFechado, statusFechadoPeriodo, prazo, dtManutencaoStatus } = chamado;

  return (
    <View>
      <WhiteText>Prazo: {formatDate(prazo)}</WhiteText>
      <WhiteText>Fechado: {formatDate(dtManutencaoStatus)}</WhiteText>
      <WhiteText>{getLabelStatus(statusFechado, statusFechadoPeriodo)}</WhiteText>
    </View>  
  );
};

export default Status;