import styled from 'styled-components';
import { Icon, Row } from '@components-teammove';

export const TaskCard = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  opacity: ${({ isCanceled })=> isCanceled && '.5'};
  border-radius: 8px;
  border-left: 8px solid ${({ typeColor }) => typeColor};
  padding: 8px;
`;

export const TypeIconWrapper = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${({ typeColor }) => typeColor};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
`;

export const TypeIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const TaskText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const TaskLabel = styled(TaskText)`
  font-weight: 700;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const CheckIcon = styled(Icon.Feather)`
  color: ${({ color })=> color};
  margin-right: 4px;
`;

export const DistanceToCompany = styled(TaskText)`
  color: var(--danger-color);
`;

export const RowStyle = styled(Row)`
  margin-top: 20px;
`;