import { get, post } from '../api';
import { Crypto } from '@utils';

export const login = (username, password) => {
  return post('/rest/login/v2', {
    login: username,
    senha: Crypto.SHA256(password),
  });
};

export const recuperarSenha = (username) => {
  return get(`/rest/login/lembrarSenha?email=${username}`);
};