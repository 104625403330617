export const getCategoria = (values) => {
  return {
    ...values,
    perfis: values.perfis && values.perfis.length > 0 ? values.perfis.map((idPerfil) => ({ idPerfil })) : null,
    configuracaoPerfis: values.configuracaoPerfis && values.configuracaoPerfis.length > 0 ? values.configuracaoPerfis.map((idPerfil) => ({ idPerfil })) : null,
  };
};

export const getInitialValues = (state) => {
  return {
    ativo: true,
    ...state,
    perfis: state.perfis && state.perfis.length > 0 ? state.perfis.map((perfil) => (perfil.idPerfil)) : [], 
    configuracaoPerfis: state.configuracaoPerfis && state.configuracaoPerfis.length > 0 ? state.configuracaoPerfis.map((perfil) => (perfil.idPerfil)) : [],
  };
};