import React, { useEffect, useState } from 'react';
import { Row } from '@components-teammove';
import { IconTrophy, RowContainer } from './styles';
import Dropdown from './Dropdown';
import { getMetasDiarias } from '@ducks/metas';
import { useDispatch, useSelector } from 'react-redux';

const MetasHeader = () => {
  const dispatch = useDispatch();
  const [ visibleDropdown, setVisibleDropdown ] = useState(false);
  const [ filteredMetas, setFilteredMetas ] = useState([]);

  const metasDiarias = useSelector(({ metas }) => metas.get('metasDiarias'));
  const loadingMetasDiarias = useSelector(({ metas }) => metas.get('loadingMetasDiarias'));
  const successMetasDiarias = useSelector(({ metas }) => metas.get('successMetasDiarias'));
  const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));

  useEffect(() => {
    if(infoUsuario?.cdUsuario) {
      dispatch(getMetasDiarias(infoUsuario.cdUsuario));
    }
  }, []);

  useEffect(() => {
    if(!successMetasDiarias) return;
    setFilteredMetas(metasDiarias.filter((metaDiaria) => metaDiaria.numberOfActivitiesGoal > 0));
  }, [successMetasDiarias]);

  return (
    loadingMetasDiarias ? null :
      filteredMetas.length > 0 &&
    <>
      <RowContainer align='center' gap='12px' onClick={() => setVisibleDropdown(true)}>
        <Row gap='4px' align='center'>
          {/* 
              Deixado para depois, Gráfico de pizza das metas diárias
          <ContainerPieChart>
            <ChartPie data={data} noLabel small/>
          </ContainerPieChart> */}
          <IconTrophy type='trophy-outline'/>
        </Row>
      </RowContainer>
      {visibleDropdown && <Dropdown onClose={() => setVisibleDropdown(false)}/>}
    </>
  );
};

export default MetasHeader;