import React, { useEffect } from 'react';
import { getDashboard } from '@ducks/chamadosDashboard';
import { InfoUsuario } from '@utils';
import { Skeleton, ContentTitle, DashboardCard } from '@components-teammove';
import { Column, Container, GapView, StatusCard, TotalClosed } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterTickets } from './rules';

export default function Dashboard({ busca,  filtros, setViewBy, setFiltros, typeTicketForFiltrosParams, ...props }) {
  const dispatch = useDispatch();
  const dashboard = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('status')).toArray()
    .reduce((state, status) => ({ ...state, [status.id]: { ...status } }), {});
  const loading = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loading'));

  useEffect(() => {
    dispatch(getDashboard(InfoUsuario.get('cdUsuario'), { ...filtros, ...typeTicketForFiltrosParams }, busca));
  }, []);

  const handleRedirectToTickets = (item, extraFilter) => {
    setFiltros(changeFilterTickets(extraFilter ? { ...filtros, descFiltros: { ...filtros?.descFiltros, [extraFilter]: true }, [extraFilter]: true } : filtros, item));
    setViewBy('Listagem');
  };

  return(
    loading ? (
      <GapView>
        <Container loading noFlex>
          <Column>
            <StatusCard>
              <Skeleton/>
            </StatusCard>
            <StatusCard>
              <Skeleton/>
            </StatusCard>
          </Column>
          <Column>
            <StatusCard>
              <Skeleton/>
            </StatusCard>
            <StatusCard>
              <Skeleton/>
            </StatusCard>
          </Column>
          <Column>
            <StatusCard>
              <Skeleton/>
            </StatusCard>
          </Column>
        </Container>

        <Skeleton.ContentTitle/>
        <Container loading>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
          <StatusCard>
            <Skeleton/>
          </StatusCard>
        </Container>
      </GapView>
    ) : (
      <GapView>
        {(dashboard.BR || dashboard.AR || dashboard.AA || dashboard.RE || dashboard.AB || dashboard.EA) && (
          <Container fixHeight>
            {(dashboard.BR || dashboard.AR) && (
              <Column>
                {dashboard.BR && (
                  <DashboardCard statusItem={dashboard.BR} color={dashboard.BR?.cor} description={dashboard.BR?.descricao} total={dashboard.BR?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {dashboard.AR && (
                  <DashboardCard statusItem={dashboard.AR} color={dashboard.AR?.cor} description={dashboard.AR?.descricao} total={dashboard.AR?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Column>)}
            {(dashboard.RE || dashboard.AB) && (
              <Column>
                {dashboard.RE && (
                  <DashboardCard statusItem={dashboard.RE} color={dashboard.RE?.cor} description={dashboard.RE?.descricao} total={dashboard.RE?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {dashboard.AB && (
                  <DashboardCard statusItem={dashboard.AB} color={dashboard.AB?.cor} description={dashboard.AB?.descricao} total={dashboard.AB?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Column>)}
            {(dashboard.EA || dashboard.AA) && (
              <Column>
                {dashboard.EA && (
                  <DashboardCard statusItem={dashboard.EA} color={dashboard.EA?.cor} description={dashboard.EA?.descricao} total={dashboard.EA?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
                {dashboard.AA && (
                  <DashboardCard statusItem={dashboard.AA} color={dashboard.AA?.cor} description={dashboard.AA?.descricao} total={dashboard.AA?.total} onClick={(item) => handleRedirectToTickets(item)}/>
                )}
              </Column>)}
          </Container>
        )}

        {dashboard.FE && (
          <>
            <ContentTitle>Tickets fechados</ContentTitle>
            <StatusCard color={dashboard.FE?.cor} onClick={() => handleRedirectToTickets(dashboard.FE)}>
              {dashboard.FE?.descricao}
              <TotalClosed>{dashboard.FE?.total}</TotalClosed>
            </StatusCard>
            <Container>
              <DashboardCard statusItem={dashboard.FE} color={dashboard.FE?.cor} description='Fechado no prazo' total={dashboard.FE?.totalFechadoNoPrazo} onClick={(item) => handleRedirectToTickets(item, 'fechadoNoPrazo')}/>
              <DashboardCard statusItem={dashboard.FE} color={dashboard.FE?.cor} description='Fechado fora do prazo' total={dashboard.FE?.totalFechadoForaPrazo} onClick={(item) => handleRedirectToTickets(item, 'fechadoForaPrazo')}/>
            </Container>
          </>
        )}
      </GapView>
    )
  );
}