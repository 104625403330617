import styled from 'styled-components';
import { PageHeader, View, Text } from '@components';
import { Icon } from '@components-teammove';

export const StyledPageHeader = styled(PageHeader)`
  flex: 0 1 auto;

  .ant-page-header-content{
    flex: 0 1 auto;
  }
`;

export const ButtonsView = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

export const Row = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;  

export const SearchInputWrapper = styled(View)`
  width: 50%;
  max-width: 600px;
  background: #f1f3f4;
  margin: 10px 0;
  border-radius: 10px;
  > span {
    background: none;
    padding: 0px 15px;
    border: none;
    align-items: center;
    box-shadow: none;

    &:hover {
      background: none;
      box-shadow: none;
    }

    > input {
      background: none;
      padding: 8px !important;
      font-size: 16px;

      &::placeholder {
        color: #737c85;
      }
    }

    .ant-input-suffix {
      display: flex;
      align-items: center;

      .ant-input-clear-icon {
        font-size: 16px;
      }
    }
  }
`;

export const SizedPreffixIcon = styled(Icon.SearchOutlined)`
  font-size: 16px;
`;

export const CellText = styled(Text)`
  cursor: pointer !important;
`;