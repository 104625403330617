import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    background-color: ${({ theme }) => theme.darkSecondaryColor};

    * {
      color: ${({ theme }) => theme.textColor};
    }

    .ant-collapse-item .ant-collapse-header {
      color: ${({ theme }) => theme.textColor};
    }

    .ant-collapse-content {
      background-color: ${({ theme }) => theme.darkSecondaryColor};
    }
  }
`;