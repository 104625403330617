import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, View } from '@components-teammove';
import { download, InfoUsuario } from '@utils';
import { authorization } from '@sdk/api';
import { IconRemoveCover, ImageCover, UploadFill, UploadIcon, UploadText } from '../../styles';
import { debounce } from 'debounce';

const { REACT_APP_API } = window.env;

export default function ModalSubcampanhas({ form, initialImage = '', nameSuffix, isPositivation, visible, setVisible, setName, handleOk, handleDelete, loadingDelete }) {
  const [imageCover, setImageCover] = useState();
  const [loadingUpload, setLoadingUpload] = useState(false);
  
  useEffect(() => {
    setImageCover(initialImage);
  }, [visible]);
  
  const beforeOk = () => {
    form.validateFields().then(() => {
      setImageCover('');
      handleOk();
    }).catch((err) => console.error(err));
  };
  
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleUploadChange = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        setLoadingUpload(true);
        break;
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        setLoadingUpload(false);
        break;
      }
      case 'done': {        
        form.setFieldsValue({ image: file.response });
        setImageCover(file.response);
        setLoadingUpload(false);
        break;
      }
      default: {
        setLoadingUpload(false);
      }
    }
  };

  const handleRemoveCover = () => {
    setImageCover('');
    form.setFieldsValue({ image: null });
    form.validateFields(['image']).then().catch((err) => console.error(err));
  };

  const handleDebounce = debounce((value) => setName(value), 500);

  return(
    <Modal 
      forceRender
      width={800}
      visible={visible} 
      onCancel={handleCancel} 
      onOk={beforeOk}
      okButtonProps={{ loading: loadingDelete, disabled: loadingUpload }}
      cancelText={(form.getFieldValue('id') || form.getFieldValue('tempId')) && 'Excluir'}
      cancelButtonConfirm={(form.getFieldValue('id') || form.getFieldValue('tempId'))}
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir esta subcampanha?', onConfirm: () => handleDelete(),  cancelText: 'Cancelar', okText: 'Confirmar' }}
      cancelButtonProps={(form.getFieldValue('id') || form.getFieldValue('tempId')) && { transparent: true, bordered: true, coloredContent: true, loading: loadingDelete, type: 'danger', onClick: null, title: 'Excluir subcampanha' }}
    >
      <Form form={form} layout='vertical'>
        <Form.Item name='id' hidden/>
        <Form.Item name='tempId' hidden/>
        <Form.Item name='name' label='Nome' rules={[{ required: true, message: 'Insira o título' }]}>
          <Input placeholder='Título' onChange={(evt) => handleDebounce(evt.target.value)}/>
        </Form.Item>
        <Form.Item name='customId' label='ID Customizado' rules={[{ required: true, message: 'Insira o ID customizado' }]} normalize={(value) => value.toUpperCase().replaceAll(' ', '-')}>
          <Input placeholder='ID Customizado' addonBefore={isPositivation && 'POS-'} addonAfter={nameSuffix}/>
        </Form.Item>
        <Form.Item name='image' label='Capa' rules={[{ required: true, message: 'Insira a capa' }]}>
          <UploadFill
            headers={authorization()}
            URI={REACT_APP_API}
            folder={`/Campanhas/${InfoUsuario.get('cdCliente')}`}
            onDownload={(file) => download(file.response)}
            onChange={(info) => handleUploadChange(info)}
            showUploadList={false}
            accept={'image/*'}
          >
            {imageCover && !loadingUpload ? (
              <View>
                <ImageCover src={imageCover} alt='Capa' />
                <IconRemoveCover
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRemoveCover();
                  }}
                />
              </View>
            ) : (
              <>
                <UploadIcon type='upload'/>
                <UploadText>Clique ou arraste um arquivo para anexar ao ticket</UploadText>
              </>
            )}
          </UploadFill>
        </Form.Item>
        <Form.Item name='description' label='Descrição' rules={[{ required: true, message: 'Insira a descrição' }]}>
          <Input.TextArea placeholder='Descrição'/>
        </Form.Item>
      </Form>
    </Modal>
  );
}