import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { getRelease as getReleaseSdk, postUserAlreadySaw as postUserSdk } from '@sdk/Novidades';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_RELEASE: 'HOME/GET_RELEASE',
  GET_RELEASE_SUCCESS: 'HOME/GET_RELEASE_SUCCESS',
  GET_RELEASE_ERROR: 'HOME/GET_RELEASE_ERROR',

  POST_USER: 'HOME/POST_USER',
  POST_USER_SUCCESS: 'HOME/POST_USER_SUCCESS',
  POST_USER_ERROR: 'HOME/POST_USER_ERROR',

  RESET: 'HOME/RESET',
};

//Action Creators
export const getRelease = () => ({ type: Types.GET_RELEASE });
export const getReleaseSuccess = (release) => ({ type: Types.GET_RELEASE_SUCCESS, release });
export const getReleaseError = (error) => ({ type: Types.GET_RELEASE_ERROR, error });

export const postUser = () => ({ type: Types.POST_USER });
export const postUserSuccess = (version) => ({ type: Types.POST_USER_SUCCESS, version });
export const postUserError = (error) => ({ type: Types.POST_USER_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetRelease(action) {
  try {
    const release = yield call(getReleaseSdk);
    yield put(getReleaseSuccess(release));
  } catch (err) {
    Notification.error(err.message);
    yield put(getReleaseError(err));
  }
}

function* fetchPostUser(action) {
  try {
    const updatedInfoUsuario = yield call(postUserSdk);
    yield put(postUserSuccess(updatedInfoUsuario));
  } catch (err) {
    Notification.error(err.message);
    yield put(postUserError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_RELEASE, fetchGetRelease),
  takeLatest(Types.POST_USER, fetchPostUser),
];

// Reducer
const initialState = Map({
  release: {},
  loadingRelease: false,
  successRelease: false,
  errorRelease: false,
  loadingPostUser: false,
  successPostUser: false,
  errorPostUser: false,
});

const handleGetRelease = (state, action) => {
  return state
    .set('loadingRelease', true)
    .set('successRelease', false)
    .set('errorRelease', false);
};
  
const handleGetReleaseSuccess = (state, action) => {
  return state
    .set('release', action.release)
    .set('loadingRelease', false)
    .set('successRelease', true)
    .set('errorRelease', false);
};
  
const handleGetReleaseError = (state, action) => {
  return state
    .set('loadingRelease', false)
    .set('successRelease', false)
    .set('errorRelease', action.error);
};

const handlePostUser = (state, action) => {
  return state
    .set('loadingPostUser', true)
    .set('successPostUser', false)
    .set('errorPostUser', false);
};
  
const handlePostUserSuccess = (state, action) => {
  localStorage.setItem('version', action.version);
  return state
    .set('release', action.release)
    .set('loadingPostUser', false)
    .set('successPostUser', true)
    .set('errorPostUser', false);
};
  
const handlePostUserError = (state, action) => {
  return state
    .set('loadingPostUser', false)
    .set('successPostUser', false)
    .set('errorPostUser', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_RELEASE: return handleGetRelease(state, action);
    case Types.GET_RELEASE_SUCCESS: return handleGetReleaseSuccess(state, action);
    case Types.GET_RELEASE_ERROR: return handleGetReleaseError(state, action);

    case Types.POST_USER: return handlePostUser(state, action);
    case Types.POST_USER_SUCCESS: return handlePostUserSuccess(state, action);
    case Types.POST_USER_ERROR: return handlePostUserError(state, action);

    case Types.RESET:
      return handleReset(state, action);
  
    default: return state;
  }
}