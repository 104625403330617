import styled from 'styled-components';
import { Icon, Row, Button, Text } from '@components-teammove';

export const Typography = styled(Text)`
  font-size: ${({ type }) => type === 'medium' ? '14px' : type === 'large' ? '18px' : type === 'small' ? '12px' : '18px' };
  font-weight: ${({ type }) => type === 'medium' ? '400' : type === 'large' ? '700' : '400' };
  opacity: ${({ type }) => type === 'medium' || type === 'small' ? '.5' : type === 'large' && '1'};
  line-height: ${({ type }) => type === 'medium' ? '19.07px' : type === 'large' ? '24.51px' : type === 'small' && '16.34px' };
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ maxWidth }) => maxWidth && maxWidth};
`;

export const Info = styled(Text)`
  font-size: ${({ size })=> size};
  font-weight: ${({ weight })=> weight};
  line-height: 19.07px;
`;

export const VerticalLine = styled.div`
  height: 44px;
  border-right: 2px solid #FFC008;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;

export const TagScheduled = styled(Row)`
  padding: 4px 8px 4px 8px;
  background-color: ${({ theme, color }) => color ? color : theme.backgroundColor};
  border-radius: 27px;
  height: 24px;
  align-content: center;
`;

export const TagStatus = styled(Row)`
  padding: 9px;
  border-radius: 8px;
  width: 117px; 
  align-items: end;
  gap: 8px;
  background-color: ${({ colorStatus }) => colorStatus === 'OK' ? 'var(--success-color)' : colorStatus === 'ATENCAO' ? 'var(--warning-color)' : colorStatus === 'CRITICO' ? 'var(--danger-color)' : 'var(--success-color)'};
`;

export const MenuButton = styled(Button)`
  font-size: 14px !important;
`;

export const RowStyle = styled(Row)`
  margin-left: 24px;
`;
