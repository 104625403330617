import { WikisEAD, WikisEADCadastro, WikisEADCadastroForm, WikisEADCurso, WikisEADCadastroResultadosQuiz, WikisEADTrilhas, WikisEADTrilhasCadastro } from '@containers';
import { Icon } from '@components-teammove';
import { InfoUsuario, TelasNomenclaturas } from '@utils';

const permissions = (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario) && InfoUsuario.permExtras('gruposEAD', infoUsuario);

export default () => {
  return InfoUsuario.perm('conteudosEAD') ? [
    {
      path: '/ead-menu',
      icon: Icon.ReadOutlined,
      text: TelasNomenclaturas.getNomenclatures('EAD')?.nomenclatura,
      match: /^\/ead-menu?/,
      secret: true,
      exact: true,
      sider: InfoUsuario.perm('conteudosEAD'),
      permissions,
    },
    {
      path:'/ead/trilhas',
      parent: '/ead-menu',
      text: 'Trilhas',
      match:/^\/ead\/trilhas?/,
      component: WikisEADTrilhas,
      exact: true,
      sider: InfoUsuario.perm('conteudoCadastro'),
      permissions: () => permissions() && InfoUsuario.perm('conteudoCadastro')
    },
    {
      path: '/ead/cadastro',
      parent: '/ead-menu',
      text: 'Cursos',
      match: /^\/ead\/cadastro?/,
      component: WikisEADCadastro,
      exact: true,
      sider: InfoUsuario.perm('conteudoCadastro'),
      permissions: () => permissions() && InfoUsuario.perm('conteudoCadastro'),
    },
    {
      path: '/ead/cadastro/:id',
      component: WikisEADCadastroForm,
      exact: true,
      permissions: () => permissions() && InfoUsuario.perm('conteudoCadastro'),
    },
    {
      path: '/ead/cadastro/quiz/:id',
      component: WikisEADCadastroResultadosQuiz,
      exact: true,
      permissions,
    },
    {
      path: '/ead',
      parent: '/ead-menu',
      text: TelasNomenclaturas.getNomenclatures('EAD')?.nomenclatura,
      component: WikisEAD,
      exact: true,
      sider: InfoUsuario.perm('conteudosEAD'),
      permissions,
    },
    {
      path: '/ead/curso/:id',
      match: /^\/ead?/,
      component: WikisEADCurso,
      exact: true,
      permissions,
    },
    {
      path: '/ead/trilha/cadastro/:id',
      component: WikisEADTrilhasCadastro,
      exact: true,
      permissions: () => permissions() && InfoUsuario.perm('conteudoCadastro'),
    } 
  ] : [
    {
      path: '/ead',
      icon: Icon.LaptopOutlined,
      text: TelasNomenclaturas.getNomenclatures('EAD')?.nomenclatura,
      match: /^\/ead?/,
      component: WikisEAD,
      exact: true,
      sider: InfoUsuario.perm('conteudosEAD'),
      permissions,
    },
    {
      path: '/ead/curso/:id',
      match: /^\/ead?/,
      component: WikisEADCurso,
      exact: true,
      permissions,
    }
  ];
};
