import styled from 'styled-components';
import { Text, Radio, Checkbox, Button, Icon } from '@components-teammove';
import { SmallFormItem } from '../../../styles';

export const TextObrigatorio = styled(Text)`
  color: var(--danger-color) !important;
  margin-right: 4px;
`;

export const RadioResponsavel = styled(Radio.Group)`
  padding: 5px;
`;

export const FormItemPerfil = styled(SmallFormItem)`
  margin-bottom: 0px;
`;

export const CheckboxFull = styled(Checkbox)`
  margin-bottom: 5px;
`;

export const ButtonContainer = styled(Button.Group)`
  align-items: center !important;
`;

export const ButtonCadastro = styled(Button)`
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 135px !important;
  }
  @media (min-width:1024px) and (max-width: 1439px) {
    width: 173px !important;
  }
  width: 245px !important;
`;

export const InfoIcon = styled(Icon.QuestionCircleOutlined)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  &.ant-switch-checked {
    color: ${({ theme }) => theme.actionColor};
  }
`;
