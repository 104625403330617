import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Form, Input, Column, Skeleton, Tag } from '@components-teammove';
import { SmallFormItem } from '../../styles';
import { saveAtendimento, reset, getAtendimento, putAtendimento, deleteAtendimento, putMovePerfil } from '@ducks/configuracoesAtendimento';
import ModalDiaCopia from './ModalDiaCopia';
import ModalTimePicker from './ModalTimePicker';
import DiaSemana from './DiaSemana';
import { mountHorario, copiarHorarios, excluirHorario, workingDaysEnum, formattedWorkingDayForm } from './rules';
import ModalMoverPerfis from './ModalMoverPerfis';

const validateArray = (rule, value) => {
  if ((value) && (value.horarios) && (value.horarios.some((item) => (item.intervalos) && (item.intervalos.length > 0)))) {
    return Promise.resolve();
  }
  return Promise.reject('Informe ao menos um horário');
};

const AtendimentoCadastro = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const theme = useSelector(({ app }) => app.get('theme'));

  const atendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('atendimento'));
  const loadingAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingAtendimento'));
  const successAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successAtendimento'));
  const loadingSaveAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingSaveAtendimento'));
  const successSaveAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successSaveAtendimento'));
  const successPutAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successPutAtendimento'));
  const loadingPutAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingPutAtendimento'));
  const successPutMovePerfil = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successPutMovePerfil'));
  const loadingPutMovePerfil = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingPutMovePerfil'));
  const successDeleteAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('successDeleteAtendimento'));
  const loadingDeleteAtendimento = useSelector(({ configuracoesAtendimento }) => configuracoesAtendimento.get('loadingDeleteAtendimento'));

  const [visibleDiaCopia, setVisibleDiaCopia] = useState(false);
  const [objCadastro, setObjCadastro] = useState(false);
  const [visibleCadastro, setVisibleCadastro] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [openConfirmationExcluir, setOpenConfirmationExcluir] = useState(false);

  const handleShowModalCadastro = (horario) => {
    setVisibleCadastro(true);
    setObjCadastro(horario);
  };
  const handleHideModalCadastro = () => {
    setVisibleCadastro(false);
    setObjCadastro(false);
  };

  useEffect(() => {
    if ((location.state) && (location.state.id)) {
      dispatch(getAtendimento(location.state.id));
    }
   
    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (successAtendimento) {
      const horarios = formattedWorkingDayForm(atendimento.workingDays);
      sumHours(horarios);
      setProfiles(atendimento.profiles);
      form.setFieldsValue({ ...atendimento, horarios });
    }
  }, [successAtendimento]);

  useEffect(() => {
    if (successSaveAtendimento || successPutAtendimento) {
      history.goBack();
    }
  }, [successSaveAtendimento, successPutAtendimento]);

  useEffect(() => {
    if (successPutMovePerfil) {
      dispatch(deleteAtendimento(location.state.id));
    }
  }, [successPutMovePerfil]);

  useEffect(() => {
    if (successDeleteAtendimento) {
      setOpenConfirmationExcluir(false);
      history.goBack();
    }
  }, [successDeleteAtendimento]);

  const handleExcluirHorario = (item) => {
    const horarios = excluirHorario(item, form.getFieldValue('horarios') || []);
    sumHours(horarios);
    form.setFieldsValue({ horarios });
    handleHideModalCadastro();
  };

  const sumHours = (horarios) => {
    let totalHoras = 0;
    horarios.forEach((horario) => {
      horario.intervalos.forEach((intervalo) => {
        const inicio = intervalo.inicio.split(':');
        const fim = intervalo.fim.split(':');
        totalHoras += (fim[0] - inicio[0]) + ((fim[1] - inicio[1]) / 60);
      });
    });
    setTotalHours(totalHoras);
  };

  const handleExcluir = () => {
    location.state.inUseByProfiles ? setOpenConfirmationExcluir(true) : dispatch(deleteAtendimento(location.state.id));
  };

  const handleCloseMoverPerfis = (idJornada) => {
    if (!idJornada) {
      setOpenConfirmationExcluir(false);
      return;
    }
    dispatch(putMovePerfil(idJornada, location.state.id));
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Configurações / Jornada"
          extra={[
            <Button key="1" size='small' context='header' type='secondary' loading={loadingDeleteAtendimento || loadingPutMovePerfil} onClick={() => history.goBack()}>Cancelar</Button>,
            location?.state?.id && (<Button key="2" size='small' context='header' type='danger' loading={loadingDeleteAtendimento || loadingPutMovePerfil} onClick={() => handleExcluir()}>Excluir</Button>),
            <Button key="3" size='small' context='header' type="primary" onClick={() => form.submit()} loading={loadingSaveAtendimento || loadingPutAtendimento || loadingDeleteAtendimento || loadingPutMovePerfil}>Salvar</Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/jornada', name: 'Configurações de Horários', current: true }
              ]}
            />
          )}
        >
          {loadingAtendimento ? (
            <Skeleton.Form/>
          ) : (
            <Form.Provider
              onFormFinish={(name, { values, forms }) => {
                if (name === 'cadForm') {
                  const horarios = mountHorario(values, form.getFieldValue('horarios') || []);             
                  form.setFieldsValue({ horarios });
                  sumHours(horarios);
                  handleHideModalCadastro();             
                } else if (name === 'diaCopiaForm') {                 
                  const horarios = copiarHorarios(values, form.getFieldValue('horarios') || []);
                  form.setFieldsValue({ horarios });
                  sumHours(horarios);
                  setVisibleDiaCopia(false);
                } else if (name === 'mainForm') {        
                  const formattedHorarios = values.horarios.map((horario) => ({
                    
                    day: workingDaysEnum[horario.diaSemana],
                    shifts: horario.intervalos.map((intervalo) => ({
                      start: intervalo.inicio,
                      end: intervalo.fim
                    }))
                  }));
            
                  let valueToSend = {
                    description: values.description,
                    workingDays: formattedHorarios,    
                    profiles: profiles       
                  };
                  if ((location.state) && (location.state.id)) {
                    valueToSend = { ...valueToSend, id: location.state.id };
                    dispatch(putAtendimento(valueToSend));
                  } else {
                    dispatch(saveAtendimento(valueToSend));
                  }
                }
              }}
            >
              <Form layout="vertical" name="mainForm" form={form} scrollToFirstError>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>

                <SmallFormItem name="description" label="Descrição" rules={[{ required: true, message: 'Informe a descrição!' }]}>
                  <Input 
                    addonAfter={<Tag dark>{totalHours % 1 === 0 ? totalHours.toFixed(0) : totalHours.toFixed(1)} horas</Tag>}
                  />
                </SmallFormItem>

                <Form.Item label="Horários" shouldUpdate rules={[{ validator: validateArray }]}>
                  {({ getFieldValue }) => {
                    const horarios = getFieldValue('horarios') || [];
                    return (
                      <Column gap='18px'>
                        <DiaSemana dia="Segunda-Feira" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Terça-Feira" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Quarta-Feira" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Quinta-Feira" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Sexta-Feira" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Sábado" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                        <DiaSemana dia="Domingo" horarios={horarios} onPicker={handleShowModalCadastro} onCopy={setVisibleDiaCopia} onExcluir={handleExcluirHorario} />
                      </Column>
                    );
                  }}
                </Form.Item>

                <ModalTimePicker shifts={form.getFieldValue('horarios')} visible={visibleCadastro} onCancel={handleHideModalCadastro} objCadastro={objCadastro} handleDelete={handleExcluirHorario}/>
                <ModalDiaCopia visible={visibleDiaCopia} onCancel={() => setVisibleDiaCopia(false)}/>
              </Form>
            </Form.Provider>
          )}
        </PageHeader>
        {openConfirmationExcluir && (
          <ModalMoverPerfis visible={openConfirmationExcluir} onClose={handleCloseMoverPerfis} idJornada={location.state.id}/>
        )}
      </Body>
    </App>
  );
};

export default AtendimentoCadastro;
