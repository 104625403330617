import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Text, Column, Button, SearchBar, Skeleton } from '@components-teammove';
import { ContentPosition, DotTopLevel, IconFeather, TableStyle  } from './styles';

import { Filtros, moment } from '@utils';

import { FILTERS_KEY, PARAMS_KEY } from '@ducks/chamadosDashboard';
import { FILTERS_KEY as TICKETS_FILTERS_KEY } from '@ducks/chamadosTickets';

import { getFinalDateByInitialDateAndPeriod, getOldFiltersWithNewFilters } from '../../../rules';

export default function CategoriesRakingTable({ categories, loadingCategories }) {
  const history = useHistory();

  const filters = { ...Filtros.get(FILTERS_KEY), ...Filtros.get(PARAMS_KEY) };
  if (Filtros.get(FILTERS_KEY)?.descFiltros || Filtros.get(PARAMS_KEY)?.descFiltros) {
    filters.descFiltros = { ...Filtros.get(FILTERS_KEY)?.descFiltros, ...Filtros.get(PARAMS_KEY)?.descFiltros };
  }

  const handleCategoryClick = (category) => {
    const newTicketsPageFilters = getOldFiltersWithNewFilters(
      Filtros.get(TICKETS_FILTERS_KEY), 
      {
        ...filters, 
        idCategory: [category.id], 
        creationDate: [moment(filters.initialDate), getFinalDateByInitialDateAndPeriod(filters.initialDate, filters.typePeriod)],
        descFiltros: {
          ...filters.descFiltros, 
          idCategory: [category.name],
          creationDate: [moment(filters.initialDate).format('DD/MM/YYYY'), getFinalDateByInitialDateAndPeriod(filters.initialDate, filters.typePeriod).format('DD/MM/YYYY')]
        }
      }
    );

    Filtros.set(TICKETS_FILTERS_KEY, newTicketsPageFilters);
    history.push('/chamados/tickets');
  };
  
  const columns = [
    {
      title: 'Pos',
      dataIndex: 'position',
      width: '50px',
      render: (index, item) => (
        (item.rankingPosition < 4 ? (
          <DotTopLevel>
            <Text font='16px' weight='700'>{item.rankingPosition}</Text>
          </DotTopLevel>
        ) : (
          <ContentPosition>
            <Text font='16px' weight='700'>{item.rankingPosition}</Text>
          </ContentPosition>
          
        ))
      )
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      render: (index, item) => (
        <Column>
          <Text size='16px' weight='700'>{item.name}</Text>
          <Text size='12px' weight='400' lowOpacity>{item.subCategoryName}</Text>
        </Column>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width:'150px',
      render: (index, item) => (
        <Row align='center' justify='space-between'>    
          <Text size='16px' weight='700'>{item.quantity}</Text>       
        </Row>
      )
    },
    {
      title: 'Ver lista',
      dataIndex: 'ver lista',
      width:'50px',
      align: 'end',
      render: (index, item) => (
        <Row align='center' justify='flex-end'>    
          <Button type='icon' size='small' icon={(<IconFeather type='arrow-right' />)} />         
        </Row>
      )
    }
  ];

  const [search, setSearch] = useState('');
  
  const handleSearch = (term) => {
    setSearch(term);
  };

  const filteredCategories = useMemo(() => {
    if (!search) {
      return categories; 
    }
  
    const filtered = categories.filter((category) => {
      
      return Object.values(category).some((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          return value.toString().toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });
  
    return filtered;
  }, [categories, search]);
  
  return(
    (loadingCategories) ? (
      <Skeleton.Map/>
    ) : (
      <>
        <Column>
          <Column gap='10px'>
            <Row padding='0 0 0 20px'>
              <Text size='20px' weight='700'>Ranking de categorias</Text>
            </Row>
      
            <SearchBar
              placeholder="Buscar"  
              onSearch={(term)=>handleSearch(term)}
            />
          </Column>
          <TableStyle
            columns={columns}
            dataSource={filteredCategories}
            rowKey='id'
            hidePagination
            onRow={(tab) => ({ onClick: () => handleCategoryClick(tab) })}
          />
        </Column>
        
      </>
    )
  );
}