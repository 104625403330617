import styled from 'styled-components';

export const StyledBar = styled.div`
  svg g g {
    // left label
    :first-child g:nth-child(2) g:first-child g text {
      fill: ${({ theme }) => theme.textColor};
      opacity: .5;
      /* transform: translate(50px, -30px); */
    }

    // bottom label
    :first-child g:nth-child(2) g:last-child g text {
      fill: ${({ theme }) => theme.textColor};
      opacity: .5;
    }

    // bars
    /* :nth-child(2) path {
      fill: ${({ theme }) => theme.actionColor};
    } */

    // bars text
    :last-child text {
      stroke-width: 0;
      fill: ${({ theme }) => theme.textColor};
      font-size: 12px;
      font-weight: 400;
      font-family: inherit;
    }
  }

  > .g2-tooltip {
    background-color: ${({ theme }) => theme.backgroundColor} !important;
    color: ${({ theme }) => theme.textColor} !important;
    box-shadow: none !important;
  }
`;