import React, { useEffect, useRef } from 'react';
import { Checkbox, Form } from '@components';
import styled from 'styled-components';

const CheckboxStlyed = styled(Checkbox)`
  margin-left: 0 !important;
  margin-bottom: 10px;
  white-space: initial;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export default function Somatoria({ data }) {
  const checkboxRef = useRef(null);
  const { opcoes, isMultiplaEscolha } = data;

  useEffect(() => {
    if (!isMultiplaEscolha) setTimeout(() => checkboxRef.current?.focus(), 1);
  }, [isMultiplaEscolha]);

  return opcoes.map((opcao, index) => (
    <FormItem label='' name={`check-${opcao.id}`} valuePropName='checked' key={opcao.id}>
      <CheckboxStlyed ref={index === 0 ? checkboxRef : null}>{opcao.opcao}</CheckboxStlyed>
    </FormItem>
  ));
}
