import React, { useEffect, useState } from 'react';
import { View, Radio, Input } from '@components';
import { Icon } from '@components-teammove';
import styled, { css } from 'styled-components';
import { getMinIdFromArray } from '@utils';
import { getMaxOrdem } from '@containers/Wikis/EAD/Quiz/rules';

const ViewOpcao = styled(View)`
  margin-bottom: 10px;
  padding: 10px;
  padding-bottom: 0;
`;

const AdicionarOpcao = styled(View)`
  width: fit-content;
  padding: 5px 10px;
`;

const IconDelete = styled(Icon.DeleteOutlined)`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 10px;
`;

const ViewTip = styled(View)`
  padding: 10px 0 0 10px;
  font-size: 11px;
`;

const InputStyled = styled(Input)`
  ${({ selecionado }) => {
  if (selecionado) {
    return css`
        border: 2px solid #66cc66 !important;
      `;
  }
}}
`;

export default function MultiplaEscolha({ data, handlers }) {
  const { opcoes, idPergunta, opcoesCadastradas } = data;
  const { setOpcoes } = handlers;
  const [defaultOpcao, setDefaultOpcao] = useState(null);
  const [visibleOpcoes, setVisibleOpcoes] = useState(true);
  const [inputDefaultValue, setInputDefaultValue] = useState([]);

  useEffect(() => {
    if (!opcoesCadastradas) return;

    setVisibleOpcoes(false);

    setTimeout(() => {
      let idCorreta = null;
      let inputValues = [];

      opcoesCadastradas.forEach((opCad) => {
        if (opCad.correta) idCorreta = opCad.id;

        inputValues = [...inputValues, { id: opCad.id, value: opCad.opcao }];

        updateOpcoes(opCad.opcao, opCad.id);
      });

      setDefaultOpcao(idCorreta);
      setInputDefaultValue(inputValues);
      handleOnChangeRadio(idCorreta);
      setVisibleOpcoes(true);
    }, 10);
  }, [opcoesCadastradas]);

  const idPrefix = `pergunta-${idPergunta}-opcao`;

  const handleAdicionarOpcao = (event, idCadastrado) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const id = idCadastrado ? idCadastrado : getMinIdFromArray(opcoes);
    const ordem = getMaxOrdem(opcoes);
    setOpcoes([...opcoes, { id, ordem, correta: false }]);
    return id;
  };

  const handleDelete = (id) => {
    const opcoesCopy = [...opcoes.filter((op) => op.id !== id)];
    setOpcoes(opcoesCopy);
    if (defaultOpcao === id) setDefaultOpcao(null);
    setInputDefaultValue(inputDefaultValue?.filter((idv) => idv.id !== id));

    setTimeout(() => {
      const index = opcoesCopy.length;
      if (index > 0) document.getElementById(`${idPrefix}${opcoesCopy[index - 1].id}-input`).focus();
    }, 1);
  };

  const updateOpcoes = (value, id) => {
    const opcoesCopy = [...opcoes];
    const opcao = opcoesCopy.filter((op) => op.id === id)[0];

    opcao.opcao = value;

    setOpcoes([...opcoesCopy]);
  };

  const handleInputOnBlur = (event, id) => {
    if (event?.target?.value) {
      updateOpcoes(event?.target?.value, id);
    }
  };

  const handleOnChangeRadio = (id) => {
    const opcoesCopy = [...opcoes];
    // eslint-disable-next-line
    opcoesCopy.map((opc) => {
      opc.correta = opc.id === id;
    });

    setOpcoes([...opcoesCopy]);
  };

  const onEnter = (event, index) => {
    if (event.key === 13 || event.key === 'Enter') {
      if (opcoes.length === index + 1) {
        if (event?.target?.value) {
          const idCadastrado = handleAdicionarOpcao();

          setTimeout(() => {
            document.getElementById(`${idPrefix}${idCadastrado}-input`).focus();
          }, 10);
        }
      } else {
        document.getElementById(`${idPrefix}${opcoes[index + 1].id}-input`).focus();
      }
    }
  };

  return visibleOpcoes ? (
    <View>
      <ViewTip>Selecione a opção correta</ViewTip>
      <View>
        <Radio.Group defaultValue={defaultOpcao}>
          {opcoes.map((opcaoMap, index) => {
            let { id, correta } = { ...opcaoMap };

            return (
              <ViewOpcao key={id} horizontal alignItems='center'>
                <Radio id={`${idPrefix}${id}-radio`} value={id} onChange={() => handleOnChangeRadio(id)} />
                <InputStyled
                  id={`${idPrefix}${id}-input`}
                  defaultValue={inputDefaultValue?.filter((idv) => idv.id === id)[0]?.value}
                  onBlur={(event) => handleInputOnBlur(event, id)}
                  onKeyUp={(event) => onEnter(event, index)}
                  selecionado={correta}
                />
                {/*eslint-disable-next-line*/}
                <IconDelete className='action-element' onClick={() => handleDelete(id)} />
              </ViewOpcao>
            );
          })}
        </Radio.Group>
      </View>
      {/*eslint-disable-next-line*/}
      <AdicionarOpcao horizontal onClick={(event) => handleAdicionarOpcao(event)} alignItems='center' className='action-element'>
        <Icon.PlusOutlined />
        &nbsp;Opção
      </AdicionarOpcao>
    </View>
  ) : (
    <></>
  );
}
