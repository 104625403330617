import { getCurrentMonth, getCurrentYear, dateToAnsiDate, lastDayMonth, moment } from '@utils';

const getModalFilters = (agrupadores, companyTypes) => {
  return [
    {
      type: 'SELECT',
      mode: 'multiple',
      label: 'Agrupador',
      name: 'agrupadorId',
      placeholder: 'Selecione o agrupador',
      options: agrupadores?.map(({ description, id }) => ({ label: description, value: id }))
    },
    {
      type: 'SELECT',
      mode: 'multiple',
      label: 'Tipo de Unidade',
      name: 'companyTypeId',
      placeholder: 'Selecione o tipo de unidade',
      options: companyTypes?.map((companyType) => ({ label: companyType.name, value: companyType.id })),
    }
  ];
};

export default getModalFilters;

export const verifyDate = (year, month) => {
  if(year === parseInt(getCurrentYear()) && month === getCurrentMonth()) {

    const date = dateToAnsiDate(moment());
    return date;
  }else{
    const date = `${year}-${month + 1}-${lastDayMonth(year, month)}`;
    return date;
  }
};