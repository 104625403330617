import { Dropdown } from '@components';
import styled from 'styled-components';
import { Row, Column, Menu } from '@components-teammove';

export const DropdownStyled = styled(Dropdown)`
  cursor: pointer;
  align-items: center;
`;

export const ClassificacaoMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
  width: 279px;
  border-radius:18px !important;
  border: 1px solid ${({ theme }) => theme.darkHighlightColor} !important;
  padding: 0 0 0 20px;
`;

export const Divider = styled.div`
  border-top: 0.2px solid ${({ theme }) => theme.darkHighlightColor};
`;

export const Color = styled.div`
  border-radius: 100%;
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color};
`;

export const OptionRow = styled(Row)`
  border-radius: 8px;
  padding: 2px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const ContentContainer = styled(Column)`
  padding: 5px 0;
`;

export const ContainerTitle = styled.div`
  padding: 8px 0;
`;