import React, { useEffect } from 'react';
import { Image, Spin, PDFViewer } from '@components';
import { isImage } from '@utils';

export default function PersonalizacaoAprovacao({ conteudo, file, dados, next, previous, loading, setLoading, handleOnDisapproved, handleOnAproved }) {
  useEffect(() => {
    setLoading(true);
  }, [file]);

  const handleOnLoad = () => setLoading(false);

  return (
    <>
      {loading && <Spin />}

      {isImage(file.outputPath || file.compressed) ? <Image width={300} src={file.compressed} onLoad={handleOnLoad} onError={handleOnLoad} alt='Imagem personalizada' /> : <PDFViewer url={file.outputPath} onDocumentLoad={() => handleOnLoad()} />}
    </>
  );
}
