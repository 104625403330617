import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getAgrupadores as getAgrupadoresSdk, getAgrupador as getAgrupadorSdk, postAgrupador as postAgrupadorSdk, updateAgrupador as updateAgrupadorSdk, deleteAgrupador as deleteAgrupadorSdk } from '@sdk/AgrupadoresCampanhas';

//Action Types
export const Types = {
  GET_AGRUPADORES: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADORES',
  GET_AGRUPADORES_SUCCESS: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADORES_SUCCESS',
  GET_AGRUPADORES_ERROR: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADORES_ERROR',

  SAVE_AGRUPADOR: 'CONFIGURACOES_AGRUPADORES/SAVE_AGRUPADOR',
  SAVE_AGRUPADOR_SUCCESS: 'CONFIGURACOES_AGRUPADORES/SAVE_AGRUPADOR_SUCCESS',
  SAVE_AGRUPADOR_ERROR: 'CONFIGURACOES_AGRUPADORES/SAVE_AGRUPADOR_ERROR',

  DELETE_AGRUPADOR: 'CONFIGURACOES_AGRUPADORES/DELETE_AGRUPADOR',
  DELETE_AGRUPADOR_SUCCESS: 'CONFIGURACOES_AGRUPADORES/DELETE_AGRUPADOR_SUCCESS',
  DELETE_AGRUPADOR_ERROR: 'CONFIGURACOES_AGRUPADORES/DELETE_AGRUPADOR_ERROR',

  GET_AGRUPADOR: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADOR',
  GET_AGRUPADOR_SUCCESS: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADOR_SUCCESS',
  GET_AGRUPADOR_ERROR: 'CONFIGURACOES_AGRUPADORES/GET_AGRUPADOR_ERROR',

  RESET: 'CONFIGURACOES_AGRUPADORES/RESET',
  RESET_SUCCESS_SAVE: 'CONFIGURACOES_AGRUPADORES/RESET_SUCCESS_SAVE',
};

//Action Creators
export const getAgrupadores = () => ({ type: Types.GET_AGRUPADORES });
export const getAgrupadoresSuccess = (agrupadores) => ({ type: Types.GET_AGRUPADORES_SUCCESS, agrupadores });
export const getAgrupadoresError = (error) => ({ type: Types.GET_AGRUPADORES_ERROR, error }); 

export const saveAgrupador = (agrupador) => ({ type: Types.SAVE_AGRUPADOR, agrupador });
export const saveAgrupadorSuccess = (agrupador) => ({ type: Types.SAVE_AGRUPADOR_SUCCESS, agrupador });
export const saveAgrupadorError = (error) => ({ type: Types.SAVE_AGRUPADOR_ERROR, error }); 

export const deleteAgrupador = (id) => ({ type: Types.DELETE_AGRUPADOR, id });
export const deleteAgrupadorSuccess = () => ({ type: Types.DELETE_AGRUPADOR_SUCCESS });
export const deleteAgrupadorError = (error) => ({ type: Types.DELETE_AGRUPADOR_ERROR, error });

export const getAgrupador = (id) => ({ type: Types.GET_AGRUPADOR, id });
export const getAgrupadorSuccess = (agrupador) => ({ type: Types.GET_AGRUPADOR_SUCCESS, agrupador });
export const getAgrupadorError = (error) => ({ type: Types.GET_AGRUPADOR_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccessSave = () => ({ type: Types.RESET_SUCCESS_SAVE });

//saga
function* fetchGetAgrupadores(action) {
  try {
    const agrupadores = yield call(getAgrupadoresSdk);
    yield put(getAgrupadoresSuccess(agrupadores));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAgrupadoresError(err));
  }
}

function* fetchDeleteAgrupador(action) {
  try {
    const { id } = action;
    const agrupador = yield call(deleteAgrupadorSdk, id);

    yield put(deleteAgrupadorSuccess(agrupador));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteAgrupadorError(err));
  }
}

function* fetchGetAgrupador(action) {
  try {
    const { id } = action;
    const agrupador = yield call(getAgrupadorSdk, id);
    yield put(getAgrupadorSuccess(agrupador));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAgrupadorError(err));
  }
}

function* fetchSaveAgrupador(action) {
  try {
    let { agrupador } = action;

    if (agrupador.id) {
      agrupador = yield call(updateAgrupadorSdk, agrupador);
    } else {
      agrupador = yield call(postAgrupadorSdk, agrupador);
    }
    yield put(saveAgrupadorSuccess(agrupador));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveAgrupadorError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_AGRUPADORES, fetchGetAgrupadores),
  takeLatest(Types.GET_AGRUPADOR, fetchGetAgrupador),
  takeLatest(Types.SAVE_AGRUPADOR, fetchSaveAgrupador),
  takeLatest(Types.DELETE_AGRUPADOR, fetchDeleteAgrupador)
];

// Reducer
const initialState = Map({
  agrupadores: List(),
  loadingAgrupadores: false,
  successAgrupadores: false,
  errorAgrupadores: false,
  agrupador: null,
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false
});

const handleGetAgrupadores = (state, action) => {
  return state.set('loadingAgrupadores', true).set('successAgrupadores', false).set('errorAgrupadores', false);
};

const handleGetAgrupadoresSuccess = (state, action) => {
  const { agrupadores } = action;

  return state.set('agrupadores', agrupadores).set('loadingAgrupadores', false).set('successAgrupadores', true).set('errorAgrupadores', false);
};

const handleGetAgrupadoresError = (state, action) => {
  return state.set('loadingAgrupadores', false).set('successAgrupadores', false).set('errorAgrupadores', action.error);
};

const handleGetAgrupador = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetAgrupadorSuccess = (state, action) => {
  const { agrupador } = action;

  return state.set('agrupador', agrupador).set('loading', false).set('success', true).set('error', false);
};

const handleGetAgrupadorError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleDeleteAgrupador = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteAgrupadorSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteAgrupadorError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSaveAgrupador = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveAgrupadorSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveAgrupadorError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccessSave = (state, action) => {
  return state.set('successSave', initialState.get('successSave')).set('successDelete', initialState.get('successDelete'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_AGRUPADORES:
      return handleGetAgrupadores(state, action);
    case Types.GET_AGRUPADORES_SUCCESS:
      return handleGetAgrupadoresSuccess(state, action);
    case Types.GET_AGRUPADORES_ERROR:
      return handleGetAgrupadoresError(state, action);
    case Types.GET_AGRUPADOR:
      return handleGetAgrupador(state, action);
    case Types.GET_AGRUPADOR_SUCCESS:
      return handleGetAgrupadorSuccess(state, action);
    case Types.GET_AGRUPADOR_ERROR:
      return handleGetAgrupadorError(state, action);
    case Types.DELETE_AGRUPADOR:
      return handleDeleteAgrupador(state, action);
    case Types.DELETE_AGRUPADOR_SUCCESS:
      return handleDeleteAgrupadorSuccess(state, action);
    case Types.DELETE_AGRUPADOR_ERROR:
      return handleDeleteAgrupadorError(state, action);
    case Types.SAVE_AGRUPADOR:
      return handleSaveAgrupador(state, action);
    case Types.SAVE_AGRUPADOR_SUCCESS:
      return handleSaveAgrupadorSuccess(state, action);
    case Types.SAVE_AGRUPADOR_ERROR:
      return handleSaveAgrupadorError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SUCCESS_SAVE:
      return handleResetSuccessSave(state, action);

    default:
      return state;
  }
}
