import React, { useEffect, useState } from 'react';
import { View, Checkbox, Input } from '@components';
import { Icon } from '@components-teammove';
import styled, { css } from 'styled-components';
import { getMinIdFromArray } from '@utils/';
import { getMaxOrdem } from '@containers/Wikis/EAD/Quiz/rules';

const ViewOpcao = styled(View)`
  margin-bottom: 10px;
  padding: 10px;
  padding-bottom: 0;
  height: 42px;
`;

const AdicionarOpcao = styled(View)`
  width: fit-content;
  padding: 5px 10px;
`;

const IconDelete = styled(Icon.DeleteOutlined)`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-left: 10px;
`;

const ViewTip = styled(View)`
  padding: 10px 0 0 10px;
  font-size: 11px;
`;

const InputStyled = styled(Input)`
  ${({ selecionado }) => {
  if (selecionado) {
    return css`
        border: 2px solid #66cc66 !important;
      `;
  }
}}
`;

export default function Somatoria({ data, handlers }) {
  const { opcoes, idPergunta, opcoesCadastradas } = data;
  const { setOpcoes } = handlers;
  const [inputDefaultValue, setInputDefaultValue] = useState([]);
  const [checkDefaultValue, setCheckDefaultValue] = useState([]);
  const [visibleOpcoes, setVisibleOpcoes] = useState(true);

  const idPrefix = `pergunta-${idPergunta}-opcao`;

  useEffect(() => {
    if (!opcoesCadastradas) return;

    setVisibleOpcoes(false);

    setTimeout(() => {
      let inputValues = [];
      let checkValues = [];

      opcoesCadastradas.forEach((opCad) => {
        inputValues = [...inputValues, { id: opCad.id, value: opCad.opcao }];
        checkValues = [...checkValues, { id: opCad.id, value: opCad.correta }];

        updateOpcoesOpcao(opCad.opcao, opCad.id);
        updateOpcoesCorreta(opCad.correta, opCad.id);
      });

      setInputDefaultValue(inputValues);
      setCheckDefaultValue(checkValues);
      setVisibleOpcoes(true);
    }, 10);
  }, [opcoesCadastradas]);

  const handleAdicionarOpcao = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const id = getMinIdFromArray(opcoes);
    const ordem = getMaxOrdem(opcoes);
    setOpcoes([...opcoes, { id, ordem, correta: false }]);

    return id;
  };

  const handleDelete = (id) => {
    const opcoesCopy = [...opcoes.filter((op) => op.id !== id)];
    setOpcoes(opcoesCopy);
    setCheckDefaultValue(checkDefaultValue?.filter((idv) => idv.id !== id));
    setInputDefaultValue(inputDefaultValue?.filter((idv) => idv.id !== id));

    setTimeout(() => {
      const index = opcoesCopy.length;
      if (index > 0) document.getElementById(`${idPrefix}${opcoesCopy[index - 1].id}-input`).focus();
    }, 1);
  };
  const updateOpcoesOpcao = (value, id) => {
    const opcoesCopy = [...opcoes];
    const opcao = opcoesCopy.filter((op) => op.id === id)[0];

    opcao.opcao = value;

    setOpcoes([...opcoesCopy]);
  };

  const handleInputOnBlur = (event, id) => {
    if (event?.target?.value) {
      updateOpcoesOpcao(event?.target?.value, id);
    }
  };

  const updateOpcoesCorreta = (value, id) => {
    const opcoesCopy = [...opcoes];

    opcoesCopy.filter((opc) => opc.id === id)[0].correta = value;

    setOpcoes([...opcoesCopy]);
  };

  const handleOnChangeCheckbox = (event, id) => {
    updateOpcoesCorreta(event.target.checked, id);
  };

  const onEnter = (event, index) => {
    if (event.key === 13 || event.key === 'Enter') {
      if (opcoes.length === index + 1) {
        if (event?.target?.value) {
          const idCadastrado = handleAdicionarOpcao();

          setTimeout(() => {
            document.getElementById(`${idPrefix}${idCadastrado}-input`).focus();
          }, 10);
        }
      } else {
        document.getElementById(`${idPrefix}${opcoes[index + 1].id}-input`).focus();
      }
    }
  };

  return visibleOpcoes ? (
    <View>
      <ViewTip>Selecione a(s) opção(ões) correta(s)</ViewTip>
      <View>
        {opcoes.map((opcaoMap, index) => {
          let { id, correta } = { ...opcaoMap };

          return (
            <ViewOpcao key={`p${idPergunta}o${id}`} horizontal alignItems='center'>
              <Checkbox
                id={`${idPrefix} checkbox ${id}`}
                defaultChecked={checkDefaultValue?.filter((idv) => idv.id === id)[0]?.value}
                onChange={(event) => handleOnChangeCheckbox(event, id)}
              />
              &nbsp; &nbsp;
              <InputStyled
                id={`${idPrefix}${id}-input`}
                onBlur={(event) => handleInputOnBlur(event, id)}
                defaultValue={inputDefaultValue?.filter((idv) => idv.id === id)[0]?.value}
                onKeyUp={(event) => onEnter(event, index)}
                selecionado={correta}
              />
              {/*eslint-disable-next-line*/}
              <IconDelete className='action-element' onClick={() => handleDelete(id)} />
            </ViewOpcao>
          );
        })}
      </View>
      {/*eslint-disable-next-line*/}
      <AdicionarOpcao horizontal onClick={(event) => handleAdicionarOpcao(event)} alignItems='center' className='action-element'>
        <Icon.PlusOutlined />
        &nbsp;Opção
      </AdicionarOpcao>
    </View>
  ) : (
    <></>
  );
}
