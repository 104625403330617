import styled from 'styled-components';

const Text = styled.span`
  color: ${({ theme }) => theme.textColor};
`;

export const CompanyName = styled(Text)`
  font-size: 18px;
  font-weight: 400;
`;

export const CompanyAddress = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  opacity: .5;
`;

export const NumberOfProducts = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
`;

export const CellText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

export const ImportantText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
`;

export const CompanyIdentifier = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  opacity: .5;
`;