import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, moment, DATE_FORMATS } from '@utils';
import { getCampaigns as getCampaignsSdk, getCampaignsExpired as getCampaignsExpiredSdk } from '@sdk/Campanhas';

//Action Types
export const Types = {
  GET_CAMPANHAS: 'CAMPANHAS/GET_CAMPANHAS',
  GET_CAMPANHAS_SUCCESS: 'CAMPANHAS/GET_CAMPANHAS_SUCCESS',
  GET_CAMPANHAS_ERROR: 'CAMPANHAS/GET_CAMPANHAS_ERROR',

  CHANGE_KIND_CAMPANHAS: 'CAMPANHAS/CHANGE_KIND_CAMPANHAS',
  CHANGE_USER_CHILD: 'CAMPANHAS/CHANGE_USER_CHILD',
  CHANGE_FILTERS: 'CAMPANHAS/CHANGE_FILTERS'
};

//Action Creators
export const getCampaigns = (idApi, searchExpired = false, filters) => ({ type: Types.GET_CAMPANHAS, idApi, searchExpired, filters });
export const getCampaignsSuccess = (data) => ({ type: Types.GET_CAMPANHAS_SUCCESS, data });
export const getCampaignsError = (error) => ({ type: Types.GET_CAMPANHAS_ERROR, error });

export const changeCampaignsKind = (kind) => ({ type: Types.CHANGE_KIND_CAMPANHAS, kind });
export const changeUserChild = (userChild) => ({ type: Types.CHANGE_USER_CHILD, userChild });
export const changeFilters = (filters) => ({ type: Types.CHANGE_FILTERS, filters });

//saga
function* fetchCampaignsSaga(action) {
  try {
    const { idApi, searchExpired, filters } = action;
    const current = yield call(getCampaignsSdk, idApi);//Campanhas dentro do período vigente
    const expired = searchExpired ? yield call(getCampaignsExpiredSdk, idApi, filters) : [];//Campanhas encerradas
    yield put(getCampaignsSuccess({ current: List(current), expired: List(expired) }));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCampaignsError(err));
  }
}

export const saga = [takeLatest(Types.GET_CAMPANHAS, fetchCampaignsSaga)];

// Reducer
const initialFilters = {
  startAt: moment().subtract(1,'months').startOf('month').format(DATE_FORMATS.DATE),
  limitAt: moment().subtract(1,'months').endOf('month').format(DATE_FORMATS.DATE),
};

const initialState = Map({
  campaigns: {
    current: List(),
    expired: List()
  },
  loading: false,
  success: false,
  error: false,
  kind: 'current',
  userChild: undefined,
  filters: initialFilters,
});

const handleGetCampaigns = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCampaignsSuccess = (state, action) => {
  if (action.data) {
    return state.set('campaigns', action.data).set('loading', false).set('success', true).set('error', false);
  } else {
    return state;
  }
};

const handleGetCampaignsError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleChangeCampaignsKind = (state, action) => {
  return state.set('kind', action.kind);
};

const handleChangeUserChild = (state, action) => {
  return state.set('userChild', action.userChild);
};

const handleChangeFilters = (state, action) => {
  return state.set('filters', action.filters);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case Types.GET_CAMPANHAS:
      return handleGetCampaigns(state, action);
    case Types.GET_CAMPANHAS_SUCCESS:
      return handleGetCampaignsSuccess(state, action);
    case Types.GET_CAMPANHAS_ERROR:
      return handleGetCampaignsError(state, action);
    case Types.CHANGE_KIND_CAMPANHAS:
      return handleChangeCampaignsKind(state, action);
    case Types.CHANGE_USER_CHILD:
      return handleChangeUserChild(state, action);
    case Types.CHANGE_FILTERS:
      return handleChangeFilters(state, action);

    default: return state;
  }
}