import React, { useEffect, useState } from 'react';
import { Button, SearchBar, Table, Row, Column } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getTipos } from '@ducks/configuracoesCampanhasTipos';
import { PositivationIcon, SalesIcon } from './styles';
import { FlexRow } from './Cadastro/styles';

const Tipos = ({ handleEditClick }) => {
  const [search, setSearch] = useState();

  const dispatch = useDispatch();
  const tipos = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('tipos'));
  const loading = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('loadingTipos'));
  const successSave = useSelector(({ configuracoesCampanhasTipos }) => configuracoesCampanhasTipos.get('successSave'));

  useEffect(() => {
    dispatch(getTipos());
  }, []);

  useEffect(() => {
    
    if (successSave) dispatch(getTipos());

  }, [successSave]);

  const columns = [
    {
      title: 'Nome do Tipo',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      sorter: true,
      render: (text, tipo) => (
        <Row justify='space-between'>
          <FlexRow>
            {text === 'SALE' ? (
              <SalesIcon/>
            ) : (
              <PositivationIcon/>
            )}
          </FlexRow>
          <Row gap='8px' onClick={(evt) => evt.stopPropagation()}>
            <Button
              type='secondary'
              size='small'
              context='list'
              onClick={() => handleEditClick(tipo)}
            >
              Editar
            </Button>
          </Row>
        </Row>
      )
    },
  ];

  const handleSearch = (text) => {
    setSearch(text);
  };

  const filterTypes = () => {
    if (!search) return tipos;
    return tipos.filter(({ description }) => description.trim().toLowerCase().includes(search.trim().toLowerCase()));
  };

  return (
    <Column>
      <SearchBar onSearch={handleSearch} placeholder='Pesquisar'/>
      <Table 
        columns={columns} 
        dataSource={filterTypes()} 
        loading={loading} 
        rowKey='id' 
        onRow={(tipo) => ({ onClick: () => handleEditClick(tipo) })} 
        status='active' 
      />
    </Column>
  );
};

export default Tipos;