import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getClusters as getClustersSdk,
  getAtividadesOfCluster as getAtividadesOfClusterSdk,
  getCluster as getClusterSdk,
  postCluster as postClusterSdk,
  updateCluster as updateClusterSdk,
  deleteCluster as deleteClusterSdk,
  getClustersByCompany as getClustersByCompanySdk,
  moveCompaniesCluster as moveCompaniesClusterSdk

} from '@sdk/Clusters';

//Action Types
export const Types = {
  GET_CLUSTERS: 'CONFIGURACOES_UNIDADES/GET_CLUSTERS',
  GET_CLUSTERS_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CLUSTERS_SUCCESS',
  GET_CLUSTERS_ERROR: 'CONFIGURACOES_UNIDADES/GET_CLUSTERS_ERROR',

  GET_ATIVIDADES_OF_CLUSTER: 'CONFIGURACOES_UNIDADES/GET_ATIVIDADES_OF_CLUSTER',
  GET_ATIVIDADES_OF_CLUSTER_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_ATIVIDADES_OF_CLUSTER_SUCCESS',
  GET_ATIVIDADES_OF_CLUSTER_ERROR: 'CONFIGURACOES_UNIDADES/GET_ATIVIDADES_OF_CLUSTER_ERROR',

  GET_CLUSTERS_BY_COMPANY: 'CONFIGURACOES_UNIDADES/GET_CLUSTER_BY_COMPANY',
  GET_CLUSTERS_BY_COMPANY_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CLUSTER_BY_COMPANY_SUCCESS',
  GET_CLUSTERS_BY_COMPANY_ERROR: 'CONFIGURACOES_UNIDADES/GET_CLUSTER_BY_COMPANY_ERROR',

  SAVE_CLUSTER: 'CONFIGURACOES_UNIDADES/SAVE_CLUSTER',
  SAVE_CLUSTER_SUCCESS: 'CONFIGURACOES_UNIDADES/SAVE_CLUSTER_SUCCESS',
  SAVE_CLUSTER_ERROR: 'CONFIGURACOES_UNIDADES/SAVE_CLUSTER_ERROR',

  DELETE_CLUSTER: 'CONFIGURACOES_UNIDADES/DELETE_CLUSTER',
  DELETE_CLUSTER_SUCCESS: 'CONFIGURACOES_UNIDADES/DELETE_CLUSTER_SUCCESS',
  DELETE_CLUSTER_ERROR: 'CONFIGURACOES_UNIDADES/DELETE_CLUSTER_ERROR',

  MOVE_COMPANIES_CLUSTER: 'CONFIGURACOES_UNIDADES/MOVE_COMPANIES_CLUSTER',
  MOVE_COMPANIES_CLUSTER_SUCCESS: 'CONFIGURACOES_UNIDADES/MOVE_COMPANIES_CLUSTER_SUCCESS',
  MOVE_COMPANIES_CLUSTER_ERROR: 'CONFIGURACOES_UNIDADES/MOVE_COMPANIES_CLUSTER_ERROR',

  GET_CLUSTER: 'CONFIGURACOES_UNIDADES/GET_CLUSTER',
  GET_CLUSTER_SUCCESS: 'CONFIGURACOES_UNIDADES/GET_CLUSTER_SUCCESS',
  GET_CLUSTER_ERROR: 'CONFIGURACOES_UNIDADES/GET_CLUSTER_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES/RESET',
  RESET_SUCCESS: 'CONFIGURACOES_UNIDADES/RESET_SUCCESS',
  RESET_CLUSTER: 'CONFIGURACOES_UNIDADES/RESET_CLUSTER'
};

//Action Creators
export const getClusters = () => ({ type: Types.GET_CLUSTERS });
export const getClustersSuccess = (clusters) => ({ type: Types.GET_CLUSTERS_SUCCESS, clusters });
export const getClustersError = (error) => ({ type: Types.GET_CLUSTERS_ERROR, error });

export const getAtividadesOfCluster = (id) => ({ type: Types.GET_ATIVIDADES_OF_CLUSTER, id });
export const getAtividadesOfClusterSuccess = (atividades) => ({ type: Types.GET_ATIVIDADES_OF_CLUSTER_SUCCESS, atividades });
export const getAtividadesOfClusterError = (error) => ({ type: Types.GET_ATIVIDADES_OF_CLUSTER_ERROR, error });

export const getClustersByCompany = (companyId) => ({ type: Types.GET_CLUSTERS_BY_COMPANY, companyId });
export const getClustersByCompanySuccess = (clustersByCompany) => ({ type: Types.GET_CLUSTERS_BY_COMPANY_SUCCESS, clustersByCompany });
export const getClustersByCompanyError = (error) => ({ type: Types.GET_CLUSTERS_BY_COMPANY_ERROR, error });

export const saveCluster = (grupoClusters) => ({ type: Types.SAVE_CLUSTER, grupoClusters });
export const saveClusterSuccess = (grupoClusters) => ({ type: Types.SAVE_CLUSTER_SUCCESS, grupoClusters });
export const saveClusterError = (error) => ({ type: Types.SAVE_CLUSTER_ERROR, error });

export const deleteCluster = (id) => ({ type: Types.DELETE_CLUSTER, id });
export const deleteClusterSuccess = () => ({ type: Types.DELETE_CLUSTER_SUCCESS });
export const deleteClusterError = (error) => ({ type: Types.DELETE_CLUSTER_ERROR, error });

export const moveCompanieCluster = (allClustersToMove) => ({ type: Types.MOVE_COMPANIES_CLUSTER, allClustersToMove });
export const moveCompanieClusterSuccess = () => ({ type: Types.MOVE_COMPANIES_CLUSTER_SUCCESS });
export const moveCompanieClusterError = (error) => ({ type: Types.MOVE_COMPANIES_CLUSTER_ERROR, error });

export const getCluster = (id) => ({ type: Types.GET_CLUSTER, id });
export const getClusterSuccess = (grupoClusters) => ({ type: Types.GET_CLUSTER_SUCCESS, grupoClusters });
export const getClusterError = (error) => ({ type: Types.GET_CLUSTER_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });
export const resetCluster = () => ({ type: Types.RESET_CLUSTER });

//saga
function* fetchGetClusters(action) {
  try {
    const clusters = yield call(getClustersSdk);
    yield put(getClustersSuccess(clusters));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClustersError(err));
  }
}

function* fetchGetAtividadesOfCluster(action) {
  try {
    const { id } = action;
    const atividades = yield call(getAtividadesOfClusterSdk, id);
    yield put(getAtividadesOfClusterSuccess(atividades));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtividadesOfClusterError(err));
  }
}

function* fetchGetClusterByCompany(action) {
  try {
    const { companyId } = action;
    const clustersByCompany = yield call(getClustersByCompanySdk, companyId);
    yield put(getClustersByCompanySuccess(clustersByCompany));
  } catch (err) {
    if (err.message.includes('T')) {
      Notification.info('Este tipo de unidade não possui clusters relacionados.');
    } else {
      Notification.error(err.message);
    }

    yield put(getClustersByCompanyError(err));
  }
}

function* fetchDeleteCluster(action) {
  try {
    const { id } = action;
    const grupoClusters = yield call(deleteClusterSdk, id);

    yield call(getClustersSdk);

    yield put(deleteClusterSuccess(grupoClusters));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteClusterError(err));
  }
}

function* fetchMoveCompanieCluster(action) {
  try {
    const { allClustersToMove } = action;

    const resp = yield call(moveCompaniesClusterSdk, allClustersToMove);

    yield put(moveCompanieClusterSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(moveCompanieClusterError(err));
  }
}

function* fetchGetCluster(action) {
  try {
    const { id } = action;
    const grupoClusters = yield call(getClusterSdk, id);
    yield put(getClusterSuccess(grupoClusters));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClusterError(err));
  }
}

function* fetchSaveCluster(action) {
  try {
    let { grupoClusters } = action;

    if (grupoClusters.id) {
      grupoClusters = yield call(updateClusterSdk, grupoClusters);
    } else {
      grupoClusters = yield call(postClusterSdk, grupoClusters);
    }
    yield put(saveClusterSuccess(grupoClusters));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveClusterError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CLUSTERS, fetchGetClusters),
  takeLatest(Types.GET_ATIVIDADES_OF_CLUSTER, fetchGetAtividadesOfCluster),
  takeLatest(Types.GET_CLUSTERS_BY_COMPANY, fetchGetClusterByCompany),
  takeLatest(Types.GET_CLUSTER, fetchGetCluster),
  takeLatest(Types.SAVE_CLUSTER, fetchSaveCluster),
  takeLatest(Types.DELETE_CLUSTER, fetchDeleteCluster),
  takeLatest(Types.MOVE_COMPANIES_CLUSTER, fetchMoveCompanieCluster)
];

// Reducer
const initialState = Map({
  clusters: List(),
  loadingClusters: false,
  successClusters: false,
  errorClusters: false,
  atividades: null,
  loadingAtividades: false,
  successAtividades: false,
  errorAtividades: false,
  grupoClusters: null,
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,
  clustersByCompany: List(),
  loadingClustersByCompany: false,
  successClustersByCompany: false,
  errorClustersByCompany: false,
  loadingMoveCompanies: false,
  successMoveCompanies: false,
  errorMoveCompanies: false

});

const handleGetClusters = (state, action) => {
  return state.set('loadingClusters', true).set('successClusters', false).set('errorClusters', false);
};

const handleGetClustersSuccess = (state, action) => {
  const { clusters } = action;

  return state.set('clusters', clusters).set('loadingClusters', false).set('successClusters', true).set('errorClusters', false);
};

const handleGetClustersError = (state, action) => {
  return state.set('loadingClusters', false).set('successClusters', false).set('errorClusters', action.error);
};

const handleGetAtividadesOfCluster = (state, action) => {
  return state.set('loadingAtividades', true).set('successAtividades', false).set('errorAtividades', false);
};

const handleGetAtividadesOfClusterSuccess = (state, action) => {
  const { atividades } = action;

  return state.set('atividades', atividades).set('loadingAtividades', false).set('successAtividades', true).set('errorAtividades', false);
};

const handleGetAtividadesOfClusterError = (state, action) => {
  return state.set('loadingAtividades', false).set('successAtividades', false).set('errorAtividades', action.error);
};

const handleGetCluster = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetClusterSuccess = (state, action) => {
  const { grupoClusters } = action;

  return state.set('grupoClusters', grupoClusters).set('loading', false).set('success', true).set('error', false);
};

const handleGetClusterError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleDeleteCluster = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleGetClustersByCompany = (state, action) => {
  return state.set('loadingClustersByCompany', true).set('successClustersByCompany', false).set('errorClustersByCompany', false);
};

const handleGetClustersByCompanySuccess = (state, action) => {
  const { clustersByCompany } = action;
  return state.set('clustersByCompany', clustersByCompany).set('loadingClustersByCompany', false).set('successClustersByCompany', true).set('errorClustersByCompany', false);
};

const handleGetClustersByCompanyError = (state, action) => {
  return state.set('loadingClustersByCompany', false).set('successClustersByCompany', false).set('errorClustersByCompany', true);
};

const handleDeleteClusterSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteClusterError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleMoveCompanieCluster = (state, action) => {
  return state.set('loadingMoveCompanies', true).set('successMoveCompanies', false).set('errorMoveCompanies', false);
};

const handleMoveCompanieClusterSuccess = (state, action) => {
  return state.set('loadingMoveCompanies', false).set('successMoveCompanies', true).set('errorMoveCompanies', false);
};

const handleMoveCompanieClusterError = (state, action) => {
  return state.set('loadingMoveCompanies', false).set('successMoveCompanies', false).set('errorMoveCompanies', action.error);
};

const handleSaveCluster = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveClusterSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveClusterError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetSuccess = (state, action) => {
  return state.set('success', initialState.get('success')).set('successSave', initialState.get('successSave')).set('successDelete', initialState.get('successDelete'));
};
const handleResetCluster = (state, action) => {
  return state.set('grupoClusters', initialState.get('grupoClusters')).set('loading', initialState.get('loading')).set('success', initialState.get('success'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CLUSTERS:
      return handleGetClusters(state, action);
    case Types.GET_CLUSTERS_SUCCESS:
      return handleGetClustersSuccess(state, action);
    case Types.GET_CLUSTERS_ERROR:
      return handleGetClustersError(state, action);
    case Types.GET_ATIVIDADES_OF_CLUSTER:
      return handleGetAtividadesOfCluster(state, action);
    case Types.GET_ATIVIDADES_OF_CLUSTER_SUCCESS:
      return handleGetAtividadesOfClusterSuccess(state, action);
    case Types.GET_ATIVIDADES_OF_CLUSTER_ERROR:
      return handleGetAtividadesOfClusterError(state, action);
    case Types.GET_CLUSTER:
      return handleGetCluster(state, action);
    case Types.GET_CLUSTER_SUCCESS:
      return handleGetClusterSuccess(state, action);
    case Types.GET_CLUSTER_ERROR:
      return handleGetClusterError(state, action);
    case Types.GET_CLUSTERS_BY_COMPANY:
      return handleGetClustersByCompany(state, action);
    case Types.GET_CLUSTERS_BY_COMPANY_SUCCESS:
      return handleGetClustersByCompanySuccess(state, action);
    case Types.GET_CLUSTERS_BY_COMPANY_ERROR:
      return handleGetClustersByCompanyError(state, action);
    case Types.DELETE_CLUSTER:
      return handleDeleteCluster(state, action);
    case Types.DELETE_CLUSTER_SUCCESS:
      return handleDeleteClusterSuccess(state, action);
    case Types.DELETE_CLUSTER_ERROR:
      return handleDeleteClusterError(state, action);
    case Types.MOVE_COMPANIES_CLUSTER:
      return handleMoveCompanieCluster(state, action);
    case Types.MOVE_COMPANIES_CLUSTER_SUCCESS:
      return handleMoveCompanieClusterSuccess(state, action);
    case Types.MOVE_COMPANIES_CLUSTER_ERROR:
      return handleMoveCompanieClusterError(state, action);
    case Types.SAVE_CLUSTER:
      return handleSaveCluster(state, action);
    case Types.SAVE_CLUSTER_SUCCESS:
      return handleSaveClusterSuccess(state, action);
    case Types.SAVE_CLUSTER_ERROR:
      return handleSaveClusterError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_SUCCESS:
      return handleResetSuccess(state, action);
    case Types.RESET_CLUSTER:
      return handleResetCluster(state, action);
    default:
      return state;
  }
}
