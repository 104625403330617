import React, { useState } from 'react';
import { Row } from '@components-teammove';
import { FeatherIcon, TextStyle, ContentDataGroup } from '../../styles';
import { ClassificacaoMenu, DropdownStyled, Divider, Color, OptionRow, ContainerTitle, ContentContainer } from './styles';
import { useDispatch } from 'react-redux';
import { getClassification, postTicketClassificationOption, putTicketClassificationOption } from '@ducks/classificacoes';

export default function ClassificacoessDropdown({ classification, ticketId, options, statusCancelado }) {
  const dispatch = useDispatch();

  const [classificationOptions, setClassificationOptions] = useState({});

  const handleOptionChange = (item) => {
    if(item.id !== classification.idOpcaoEscolhida) {
      if(classification.idOpcaoEscolhida) {
        dispatch(putTicketClassificationOption(ticketId,classification.id, item.id));
      } else{
        dispatch(postTicketClassificationOption(ticketId,classification.id, item.id));
      }
    }
  };

  const handleShowDropDown = (classificationId) => {
    const selectedOption = options.find((item) => item.id === classificationId);
    setClassificationOptions(selectedOption || {});
    dispatch(getClassification(classificationId));
  };
  
  const menu = (
    <ClassificacaoMenu>
      <ContainerTitle>
        <TextStyle>{classification.nome}</TextStyle>
      </ContainerTitle>
      <Divider classification/>
      <ContentContainer>
        {classificationOptions && classificationOptions?.opcoes?.filter((option) => option.ativo)
          .map((activeOption)=>(
            <ContentDataGroup key={activeOption?.id} onClick={() => handleOptionChange(activeOption)}>
              <OptionRow margin='10px 0 0 0' gap='10px' align='center'>
                <Color color={activeOption?.cor}/>
                <TextStyle >{activeOption?.titulo}</TextStyle>
              </OptionRow>
            </ContentDataGroup>
          ))}
      </ContentContainer>
    </ClassificacaoMenu>
  );

  return (
    <DropdownStyled overlay={menu} trigger={['click']}>
      <Row gap='12px'>
        <Row gap='12px'>
          {!statusCancelado && (
            <FeatherIcon type='plus' cursor='pointer' onClick={()=> handleShowDropDown(classification.id)}/>
          )}
        </Row>
      </Row>
    </DropdownStyled>
  );
}
