import styled from 'styled-components';
import { Tag  } from '@components';
import { Icon , Upload, View, Image, Text } from '@components-teammove';

export const ContainerName = styled(View)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ContentName = styled(View)`
  ${(props) => props.loading && 'gap: 10px;'}
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContainerUpload = styled(View)`
  padding: 15px 0px;
`;

export const LabelColors = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-top: 15px;
`;

export const ContentListColors = styled(View)`
  margin-top: 20px;
  display: grid;
  grid-gap: 16px 16px;
  grid-template-columns: repeat(4, min(350px));
`;

export const ContainerColor = styled(View)`
  ${(props) => props.loading && 'gap: 20px;'}
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const LabelFormItem = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 15px 0px;
`;

export const ContentColor = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 10px;

  div.ant-form-item {
    margin: 0px;
    max-width: 150px;
  }
`;

export const NameHexColor = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const TitleHeader = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
`;

export const StatusRegistration = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
`;

export const TagStatusRegistration = styled(Tag)`
  width: fit-content;
  margin-top: 5px;
  text-align: center;
  padding: 5px 10px;
  width: 75px;
`;

export const ContentLogo = styled(View)`
  border: 1px dashed #d9d9d9;
  width: 15rem;
  height: 3.75rem;
  border-radius: 5px;
  cursor: pointer;
  * {
    justify-content: center;
    color: ${(props) => props?.textColor || '#000'};
  }

  > * {
    height: 100%;
    margin: 0px;
  }

  :hover {
    border: 1px dashed #000000;
  }
  
  background-color: ${(props) => props?.backgroundColor || '#FFF'};
`;

export const UploadFill = styled(Upload)`
  width: 100%;
  line-height: 100%;
  background-color: red;

  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
    height: 100%;
  }

  div.ant-upload {
    display: flex;
    align-items: center;
    margin: auto;
  }
`;

export const ComponentEmptyCover = styled(View)`
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;

export const IconRemoveLogo = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 5px;
  top: 0px;
  cursor: pointer;
  
  path {
    color: ${(props) => props?.textColor || '#000'} !important;
  }
`;

export const LabelDimensions = styled(Text)`
  color: ${(props) => props.theme.textColor} !important;
  opacity: 0.5;
  margin-left: 5px;
  margin-top: 4px;
`;

export const ContainerPreview = styled(View)`
  padding: 15px 0px;
`;

export const ContentPreview = styled(View)`
  flex-direction: row;
`;

export const Logo = styled(Image)`
  width: 160px;
  height: 48px;
  object-fit: contain;
  user-select: none;
`;