import { get, post, put, del } from '../api';

export const getContactListByCompany = (idCompany) => {
  return get(`/api/companies/contacts?idCompany=${idCompany}`);
};

export const getContactById = (idContact) => {
  return get(`/api/companies/contacts/${idContact}`);
};

export const createContact = (contact) => {
  return post('/api/companies/contacts', contact);
};

export const updateContact = (contact) => {
  return put('/api/companies/contacts', contact);
};

export const getClassificationContacts = () => {
  const useApiNode = true;
  return get('/api/contactClassification', {}, useApiNode);
};

export const getClassificationContactById = (id) => {
  const useApiNode = true;
  return get(`/api/contactClassification/?id=${id}`, {}, useApiNode);
};

export const putClassificationContact = (classification) => {
  const useApiNode = true;
  return put('/api/contactClassification', classification, {}, useApiNode);
};

export const delClassificationContact = (id) => {
  const useApiNode = true;
  return del(`/api/contactClassification?id=${id}`, {}, useApiNode);
};