import { View } from '@components';
import styled from 'styled-components';

export const estadosQuiz = { INICIO: 'INICIO', RESPONDENDO: 'RESPONDENDO', FINALIZADO: 'FINALIZADO' };

export const Desistencia = styled(View)`
  font-weight: ${({ weight = 'bold' }) => weight};
  color: #cc4444;
`;

export const EmAndamento = styled(View)`
  color: #ff6f00;
  font-weight: ${({ weight = 'bold' }) => weight};
`;
