import styled, { keyframes } from 'styled-components';
import { Row, Text } from '@components-teammove';

export const ContentCurrentActivity = styled(Row)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: 12px;
  border: 1px solid ${({ color })=> color && color};
  height: 40px;
  padding: 8px 16px 8px 16px;
  align-items: center;
  margin-right: 18px;
  gap: 12px;
  :hover {
    cursor: pointer;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const IconActivity = styled.div`
  background-color: ${({ color })=> color && color};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  animation: ${pulseAnimation} 1s infinite;
`;

export const Typography = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;