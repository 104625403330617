import Sorter from './sorter';

export const propsColumn = (column) => {
  const getSorter = () => {
    switch (column.sorter) {
      case 'integer': return (a, b) => Sorter.integer(a, b, column.dataIndex);
      case 'datetime': return (a, b) => Sorter.datetime(a, b, column.dataIndex);
      default: return (a, b) => Sorter.string(a, b, column.dataIndex);
    }
  };

  if ((column.sorter) && ((column.sorter === true) || (typeof column.sorter !== 'function'))) {
    column.sorter = getSorter();
  }
  if (column.ellipsis === undefined) {
    column.ellipsis = true;
  }
  return column;
};

export const responsiveColumns = (columns, matches) => {

  if (!matches) return columns;

  return columns.filter((col) =>  !col.priority || col.priority <= Object.values(matches).findIndex((size) => size));
};
