import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import styled, { css } from 'styled-components';

const Text = styled(Typography.Text)`
  ${(props) =>
  props.align &&
    css`
      text-align: ${props.align};
    `}
`;

const pluralize = (singular, plural, count, zeroText) => {
  if (count === 0 && zeroText) return zeroText;

  let output = singular;
  if (count !== 1) {
    output = plural || `${singular}s`;
  }

  return `${count} ${output}`;
};

const TextComponent = ({ singular, plural, count, zeroText, children, ...props }) => <Text {...props}>{singular && count !== undefined ? pluralize(singular, plural, count, zeroText) : children}</Text>;

TextComponent.propTypes = {
  ...Typography.Text.propTypes,
  singular: PropTypes.string,
  plural: PropTypes.string,
  count: PropTypes.number,
  zeroText: PropTypes.string,
};

export default TextComponent;
