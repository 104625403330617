import styled from 'styled-components';
import { Icon, Button, Column, Text, Modal } from '@components-teammove';

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ opacity })=> opacity && opacity};
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme, help })=> help ? 'var(--super-success-color)' : theme.textColor};
  opacity: ${({ opacity })=> opacity ? '.5' : '1'};
`;

export const StyleButton = styled(Button)`
  &.ant-btn {
   :focus {
     background-color: ${({ theme, transparent })=> transparent ? 'unset' : theme.darkPrimaryColor} !important;
    }
  } 
  height:${({ height })=> height && '48px' } !important;
  width: ${({ width })=> width ? width : '' } !important;
  background-color: ${({ transparent })=> transparent && 'unset'} !important;
  border: ${({ transparent, theme })=> transparent && `1px solid ${theme.darkPrimaryColor}`} !important;
`;

export const ViewStepsButton = styled(Button)`
&.ant-btn {
  :focus {
    background-color: unset !important;
   }
 } 
 height:${({ height })=> height && '48px' } !important;
 width: ${({ width })=> width ? width : '' } !important;
 background-color: unset !important;
 border: ${({ theme })=> `1px solid ${theme.darkPrimaryColor}`} !important;
`;

export const ContentDataGroup = styled(Column)`
  gap: 12px;
  padding-left: 20px;
`;

export const Divider = styled.div`
  border-top: 2px solid ${({ theme })=> theme.darkSecondaryColor};
`;

export const ModalConfirm = styled(Modal.Confirm)`
  .ant-modal-body {
    padding-bottom: 12px !important;
  }
`;