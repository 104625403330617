import React, { useRef, useState } from 'react';
import { AlignCenterForm, Container, MessageBox, MessageItem, RowFill, SendButton, StyledUpload, UploadIcon } from './styles';
import { Spin  } from '@components';
import { Icon , Form, UploadsList } from '@components-teammove';

import { getIdTemp, InfoUsuario, download, Notification } from '@utils';
import { TIPO } from '../rules';
import { useDispatch } from 'react-redux';
import { sendMessage } from '@ducks/chamadosHistorico';
import { authorization } from '@sdk/api';

const { REACT_APP_API } = window.env;
const MAX_UPLOAD_SIZE = 209715200;

export default function MessageForm({ idChamado, scrollToBottom, ...props }) {
  const [form] = Form.useForm();

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const [message, setMessage] = useState();
  const [uploads, setUploads] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [print, setPrint] = useState({});
  const [visiblePrint, setVisiblePrint] = useState(false);

  const handleClosePrint = () => {
    setPrint({});
    setVisiblePrint(false);
  };

  const sendMessageParams = (tipo, mensagem) => {
    dispatch(sendMessage({
      idTemp: getIdTemp(),
      idUsuario: InfoUsuario.get('cdUsuario'),
      idChamado,
      tipo,
      mensagem,
    }, InfoUsuario.get('cdCliente')));
    scrollToBottom();
  };

  const handleSendMessage = (message) => {
    if (message) {
      sendMessageParams(TIPO.MENSAGEM, message);
    } else {
      form.validateFields().then((values) => {

        if (values.mensagem) {
          sendMessageParams(TIPO.MENSAGEM, values.mensagem);
        }

        if (uploads.length > 0) {
          handleSendUploads();
        }

        form.resetFields();
      }).catch(console.error);
    }
    inputRef.current && inputRef.current.focus();
  };
  
  const handleSendUpload = (upload) => {
    sendMessageParams(TIPO.ANEXO, upload.path);
  };
  
  const handleSendUploads = () => {
    uploads.map((upload) => handleSendUpload(upload));
    setUploads([]);
  };

  const handlePushUpload = ({ response, name }) => {
    setUploads([...uploads, { path: response, name }]);
    setVisiblePrint(true);
  };

  const handleChangeUpload = ({ file }) => {
    const { status, size, error } = file;

    if (size > MAX_UPLOAD_SIZE) {
      if (status === 'error') {
        return Notification.error('Tamanho máximo permitido para upload é 200mb.');
      }
    } else {
      switch (status) {
        case 'uploading': {
          return setLoadingUpload(true);
        }
        case 'error': {
          Notification.error('Erro ao fazer upload. ' + error);
          return setLoadingUpload(false);
        }
        case 'done': {
          handlePushUpload(file);
          return setLoadingUpload(false);
        }
        default: {
          return setLoadingUpload(false);
        }
      }
    }
  };

  const handleDeleteUpload = (response) => {
    setUploads(uploads.filter(({ path }) => path !== response));
  };

  const handlePaste = (event) => {
    const { clipboardData } = event;

    if (clipboardData && clipboardData.files.length > 0) {
      handleOpenPrint(clipboardData.files[0]);
    }

  };

  const handleOpenPrint = (print) => {
    setPrint(print);
    setVisiblePrint(true);
  };

  const handleSendPrint = (upload) => {

    if (message) {
      handleSendMessage(message);
    }

    if (upload) {
      handleSendUpload(upload);
    }

    handleClosePrint();
  };

  const handlePressEnter = (evt) => {
    if (!evt.shiftKey) {
      evt.preventDefault();
      handleSendMessage(null);
    }
  };

  return(
    <Container>
      {visiblePrint && (
        <UploadsList
          print={print}
          folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
          onCancel={handleClosePrint}
          onOk={handleSendPrint}
          uploads={uploads}
          onDelete={handleDeleteUpload}
          onPush={handlePushUpload}
          setLoadingUpload={setLoadingUpload}
        />
      )}
      <AlignCenterForm layout="inline" form={form}>
        <RowFill>
          <StyledUpload
            multiple
            showUploadList={false}
            folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
            onChange={handleChangeUpload}
            URI={REACT_APP_API}
            headers={authorization()}
            onDownload={(upload) => download(upload.response)}
          >
            {loadingUpload ? <Spin/> : <UploadIcon type='fi-paperclip' size='2rem'/>}
          </StyledUpload>

          <MessageItem
            name="mensagem"
          >
            <MessageBox
              onPaste={handlePaste} 
              onChange={(value) => setMessage(value)} 
              autoFocus 
              ref={inputRef} 
              onPressEnter={handlePressEnter} 
              autoSize
            />
          </MessageItem>

          <Form.Item shouldUpdate={(prev, current) => prev.mensagem !== current.mensagem}>
            {({ getFieldValue }) => {
              const mensagem = getFieldValue('mensagem') || '';
  
              return (
                <SendButton htmlType="submit" type="primary" onClick={() => handleSendMessage(null)} disabled={(loadingUpload) || ((mensagem.length === 0) && (uploads.length === 0))}>
                  Enviar
                  <Icon.Feather type="fi-send" onClick={() => handleSendMessage(null)} disabled={(loadingUpload) || ((mensagem.length === 0) && (uploads.length === 0))}/>
                </SendButton>
              );
            }}
          </Form.Item>
        </RowFill>
      </AlignCenterForm>
    </Container>
  );
}