import { formatDate, formatTime } from '@utils';

export const formatValueByType = (value, { type }) => {
  switch(type) {
    case 'ANEXO':
      return null;
    case 'DATA':
      return formatDate(value);
    case 'HORARIO':
      return formatTime(value);
    case 'NUMERICO':
      return String(value)?.replaceAll(',', '.');
    default:
      return String(value);
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};