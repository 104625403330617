import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Button, Filter, ActiveFilters, Icon } from '@components-teammove';

import { getAudienciaCategoria } from '@ducks/wikisAudiencia';
import Visualizacao from './Visualizacao';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function WikisConteudos() {
  const [visibleFilters, setVisibleFilters] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const filtros = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('filtros'));

  const theme = useSelector(({ app }) => app.get('theme'));

  const filters = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('filters'));

  const handleSearch = (filtros) => {
    dispatch(getAudienciaCategoria(id, filtros));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Dashboard de audiência'
          onBack={() => window.history.back()}
          extra={[
            <Button key="1" size='small' context='header' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>
              Filtrar
            </Button>,
          ]}
        >
          <ActiveFilters filtros={filtros} onSearch={handleSearch} filters={filters}/>

          <Visualizacao search={handleSearch} />
        </PageHeader>

        <Filter visible={visibleFilters} onClose={() => setVisibleFilters(false)} filtros={filtros} filters={filters} onSearch={handleSearch} />
      </Body>
    </App>
  );
}
