import { Map } from 'immutable';
import { getTheme, setTheme as setThemeApp } from '@app/Theme';
import { generateRoutes } from '@app/rules';
import { darken } from 'polished';

//Action Types
export const Types = {
  SET_THEME: 'APP/SET_THEME',
  SET_ROUTES: 'APP/SET_ROUTES',
};

//Action Creators
export const setTheme = (theme) => ({ type: Types.SET_THEME, theme });
export const setRoutes = (infoUsuario) => ({ type: Types.SET_ROUTES, infoUsuario });

// Reducer
const initialState = Map({
  theme: getTheme(),
  routes: generateRoutes(),
});

const handleSetTheme = (state, action) => {
  const theme = action.theme || getTheme();

  const themeWithExtraVariables = { ...theme, '@global-dark-lowlight-color': darken(0.0425, theme['@global-background-color']) };

  setThemeApp(themeWithExtraVariables);

  return state
    .set('theme', themeWithExtraVariables);
};

const handleSetRoutes = (state, action) => {
  const { infoUsuario } = action;

  return state
    .set('routes', generateRoutes(infoUsuario));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_THEME: return handleSetTheme(state, action);
    case Types.SET_ROUTES: return handleSetRoutes(state, action);

    default: return state;
  }
}
