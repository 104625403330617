import styled from 'styled-components';
import { View, Modal, Button } from '../';
import Icon from '../../components-teammove/Icon';

export const Container = styled(View)`
  margin: 10px 0;
  background: #f1f3f4;
  border-radius: 10px;
  max-width: 600px;

  > span {
    background: none;
    padding: 0px 15px;
    border: none;
    align-items: center;
    box-shadow: none;

    &:hover {
      background: none;
      box-shadow: none;
    }

    > input {
      background: none;
      padding: 8px !important;
      font-size: 16px;

      &::placeholder {
        color: #737c85;
      }
    }

    .ant-input-suffix {
      display: flex;
      align-items: center;

      .ant-input-clear-icon {
        font-size: 16px;
      }
    }
  }
`;

export const IconSearch = styled(Icon.SearchOutlined)`
  font-size: 16px;
  cursor: pointer;
`;

export const IconAdvanced = styled(Icon.CaretDownFilled)`
  font-size: 14px;
`;

export const FiltersModal = styled(Modal)`
  top: 20px;
  left: 20px;
  text-align: ${({ textAlign }) => textAlign};
`;

export const CenteredButton = styled(Button)`
  display: flex;
  margin: auto;
`;

export const TagsView = styled(View)`
  display: inline;
  .ant-tag {
    margin-bottom: 5px;
  }
`;
