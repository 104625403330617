import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Form, Input, View, Button, Select, Upload, Image, Radio, Spin, Switch, PopConfirm, Text } from '@components';
import { Icon } from '@components-teammove';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getCategorias } from '@sdk/Wikis/EAD';
import { download, InfoUsuario, getValueFromFile, Notification, TelasNomenclaturas } from '@utils';
import { authorization } from '@sdk/api';
import { getPerfis } from '@sdk/Perfil';
import { getUsuarios } from '@sdk/Usuarios';
import { getUnidades } from '@sdk/Unidades';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Materias from './Materias';
import { postCurso, deleteCurso, resetAula, getCursoCadastrado } from '@ducks/wikisEAD';
import { getDadosCadastro } from '@ducks/wikisCadastro';
import UsuariosEnvio from '../../../EAD/Cadastro/Novo/UsuariosPermissaoCurso';

const { REACT_APP_API } = window.env;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ViewUpload = styled(View)`
  border: 1px dashed #d9d9d9;
  width: 223px;
  height: 223px;
  background-color: #fafafa;

  :hover {
    border: 1px dashed #000000;
  }
`;

const ViewUpload2 = styled(View)`
  width: 223px;
  height: 223px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5;
`;

const UploadStyled = styled(Upload)`
  width: 100%;
  height: 100%;
`;

const ImageCapa = styled(Image)`
  object-fit: contain;
  cursor: pointer;
`;

const IconRemoverCapa = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
`;

const InputsCurso = styled(View)`
  flex: 1;
  padding-right: 10px;
`;

const RadioGroupWithSpace = styled(Radio.Group)`
  margin-bottom: 12px;
`;

const ButtonQuemRecebe = styled(Button)`
  margin-bottom: 12px;
`;

const ViewSpin = styled(View)`
  height: 100%;
  justify-content: center;
`;

const ContentOptions = styled(View)`
  flex-direction: row;
`;

const Options = styled(View)`
  flex: 1;
`;

const LabelResolutionCover = styled(Text)`
  color: #ff4d4f !important;
`;

export default function Formulario() {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  id = parseInt(id);

  const [loadingCapa, setLoadingCapa] = useState(false);
  const [urlCapa, setUrlCapa] = useState();
  const [tipoEnvio, setTipoEnvio] = useState(1);
  const [listasSelecionadas, setListasSelecionadas] = useState([]);
  const [bloqueiaAppOptions, setBloqueiaAppOptions] = useState([]);
  const [temUnidadeEnvio, setTemUnidadeEnvio] = useState(false);
  const [temPerfilEnvio, setTemPerfilEnvio] = useState(false);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [categoriasDelete, setCategoriasDelete] = useState([]);

  const location = useLocation();

  const locationValues = location.state;

  const dadosCadastro = useSelector(({ wikisCadastro }) => wikisCadastro.get('dadosCadastro'));
  const loadingDados = useSelector(({ wikisCadastro }) => wikisCadastro.get('loadingDadosCadastro'));

  const successPostCurso = useSelector(({ wikisEAD }) => wikisEAD.get('successPostCurso'));
  const successDeleteCurso = useSelector(({ wikisEAD }) => wikisEAD.get('successDeleteCurso'));
  const cursoCadastrado = useSelector(({ wikisEAD }) => wikisEAD.get('cursoCadastrado'));
  const loadingCursoCadastrado = useSelector(({ wikisEAD }) => wikisEAD.get('loadingCursoCadastrado'));
  const successCursoCadastrado = useSelector(({ wikisEAD }) => wikisEAD.get('successCursoCadastrado'));
  const deletingCurso = useSelector(({ wikisEAD }) => wikisEAD.get('deletingCurso'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(() => {
    if (id && -1 !== id) {
      dispatch(getCursoCadastrado({ id }));
    }

    const loadUsuarios = async () => {
      const usuariosApi = await getUsuarios();
      setListaUsuarios(usuariosApi);
    };

    dispatch(getDadosCadastro());

    if (locationValues) {
      setTipoEnvio(locationValues.unidades ? 3 : locationValues.usuarios ? 2 : 1);
      setTemPerfilEnvio(!!locationValues.perfisEnvio);
      setTemUnidadeEnvio(!!locationValues.unidades);
    }

    loadUsuarios();
  }, [id]);

  useEffect(() => {
    if (listasSelecionadas?.length < 1) {
      setBloqueiaAppOptions([]);
      return;
    }

    createOptionsFromListasTransmissao(listasSelecionadas);
  }, [listasSelecionadas]);

  useEffect(() => {
    if (successPostCurso || successDeleteCurso) {
      dispatch(resetAula());
      window.history.back();
    }
  }, [successPostCurso, successDeleteCurso]);

  useEffect(() => {
    if (successCursoCadastrado) {
      const { materias, imagem, idCategoria, titulo, resumo, idListaTransmissao, perfis, unidades, usuarios, publicado } = cursoCadastrado;
      setMaterias(materias);
      setUrlCapa(imagem);
      setListasSelecionadas(idListaTransmissao);
      setTemPerfilEnvio(perfis ? perfis.length > 0 : false);
      setTemUnidadeEnvio(unidades ? unidades.length > 0 : false);

      setTipoEnvio(idListaTransmissao && idListaTransmissao.length > 0 ? 1 : perfis && perfis.length > 0 ? 3 : 2);

      form.setFieldsValue({
        idCategoria,
        titulo,
        resumo,
        capaCurso: { path: imagem },
        idListaTransmissao,
        usuarios,
        unidades,
        perfisEnvio: perfis,
        publicado,
      });
    }
  }, [successCursoCadastrado]);

  async function createOptionsFromListasTransmissao(listasSelecionadas) {
    const listaPerfisSelecionados = dadosCadastro.listasCargos?.filter(
      (listaPerfil) => listasSelecionadas?.includes(listaPerfil.idListaTransmissao) && listaPerfil.envia
    );

    const bloquiaAppSelectOptionsDuplicados = listaPerfisSelecionados?.map((listaPerfil) => ({
      value: listaPerfil.idPerfil,
      label: listaPerfil.nmPerfil,
    }));

    const bloqueiaAppSelectOptions = bloquiaAppSelectOptionsDuplicados?.filter(
      (option, index, self) => self.findIndex((item) => item.value === option.value) === index
    );

    setBloqueiaAppOptions(bloqueiaAppSelectOptions);

    form.setFieldsValue({
      opcoesBloqueiaApp: bloqueiaAppSelectOptions?.map((item) => item.value),
    });
  }

  const getTypeFromFile = (type) => {
    if (type.includes('pdf')) {
      return 'P';
    }
    if (type.includes('png') || type.includes('jpeg') || type.includes('jpg')) {
      return 'I';
    }
    if (type.includes('mp4') || type.includes('aiv') || type.includes('wmv')) {
      return 'V';
    }
    return 'A';
  };

  const handleChange = (info) => {
    form.setFieldsValue({
      capaCurso: { tipo: getTypeFromFile(info.file.type), path: info.file.response, name: info.file.name, size: info.file.size },
    });
    if (info.file.status === 'uploading') {
      setLoadingCapa(true);
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setUrlCapa(imageUrl);
        setLoadingCapa(false);
      });
    }
  };

  const uploadCapaButton = (
    <ViewUpload2>
      {loadingCapa ? <Icon.LoadingOutlined /> : <Icon.UploadOutlined />}
      <View textAlign='center'>
        Clique ou arraste para
        <br /> anexar a imagem
      </View>
    </ViewUpload2>
  );

  const handleRemoverImagem = () => {
    setUrlCapa(undefined);
    form.setFieldsValue({
      capaCurso: undefined,
    });
    form.validateFields(['capaCurso']).then();
  };

  const handleUsuariosEnvio = () => {
    let perfisEnvio = [];

    const usuariosEspecificos = tipoEnvio === 2 ? form.getFieldValue('usuarios') : [];
    const unidades = tipoEnvio === 3 ? form.getFieldValue('unidades') : [];

    if (tipoEnvio === 3) {
      perfisEnvio = form.getFieldValue('perfisEnvio');
    }
    if (tipoEnvio === 1) {
      perfisEnvio = bloqueiaAppOptions.map((perfil) => perfil.value);
    }

    UsuariosEnvio(listaUsuarios, unidades, perfisEnvio, usuariosEspecificos);
  };

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      const curso = { ...values };

      curso.upload = curso.capaCurso;
      curso.materias = [];
      if (!curso.unidades) delete curso.unidades;

      if (listasSelecionadas?.length > 0) {
        curso.perfis = dadosCadastro.listasCargos
          .filter((listaPerfil) => listasSelecionadas.includes(listaPerfil.idListaTransmissao) && listaPerfil.envia)
          .map((perfil) => perfil.idPerfil);
      }

      if (id !== -1) curso.id = id;

      delete curso.capaCurso;

      if (materias.length === 0) {
        Notification.error('É necessário inserir ao menos uma matéria');
        return;
      }

      materias.forEach((materia) => {
        if (materia.aulas.length === 0) {
          Notification.error('É necessário inserir ao menos uma aula por matéria');
          return;
        }

        if (materia.id < 0) delete materia.id;

        materia.aulas = materia.aulas.map((aula) => {
          const aulaClone = { ...aula };

          if (aulaClone.upload === false) delete aulaClone.upload;

          if (aulaClone.id < 0) delete aulaClone.id;

          if (!aulaClone.duracao) delete aulaClone.duracao;

          delete aulaClone.newMateriaId;

          return aulaClone;
        });

        const { aulas: aulasSend, ...materiaSend } = materia;
        curso.materias.push({ materia: materiaSend, aulas: aulasSend });
      });

      const { materias: materiasSend, ...cursoSend } = curso;

      dispatch(postCurso({ materias: materiasSend, curso: cursoSend, categoriasDelete }));
    });
  };

  const handleDeleteCurso = () => {
    dispatch(deleteCurso(id));
  };

  return (
    <App>
      <Body fixed>
        <PageHeader
          title='Curso'
          fixed
          onBack={() => window.history.back()}
          extra={[
            <Button key='1' icon={<Icon.CloseOutlined />} onClick={() => history.goBack()}>
              Cancelar
            </Button>,
            <Button key='2' type='success' icon={<Icon.SaveOutlined />} onClick={handleSalvar} loading={loadingCursoCadastrado}>
              Salvar
            </Button>,
            id > 0 &&
            <PopConfirm
              key='3'
              title='Deseja realmente excluir o curso?'
              okText='Sim'
              cancelText='Não'
              onConfirm={handleDeleteCurso}
            >
              <Button type='danger' icon={<Icon.DeleteOutlined />} loading={deletingCurso}>
                Excluir
              </Button>
            </PopConfirm>
          ]}
        >
          {loadingCursoCadastrado ? (
            <ViewSpin>
              <Spin />
            </ViewSpin>
          ) : (
            <View>
              <Form layout='vertical' form={form}>
                <View>
                  <View horizontal>
                    <InputsCurso>
                      <Form.Item label='Categoria' name='idCategoria' rules={[{ required: true, message: 'Informe a categoria do curso' }]}>
                        <Select form={form} name='idCategoria' action={getCategorias} placeholder='Escolha a categoria' />
                      </Form.Item>

                      <Form.Item label='Título' name='titulo' rules={[{ required: true, message: 'Informe o título' }]}>
                        <Input placeholder='Informe o título' />
                      </Form.Item>

                      <Form.Item label='Resumo' name='resumo'>
                        <Input placeholder='Informe o resumo' />
                      </Form.Item>
                    </InputsCurso>
                    <View>
                      <Form.Item label='Capa' name='capaCurso' valuePropName='fileList' getValueFromEvent={getValueFromFile}>
                        <ViewUpload>
                          <UploadStyled
                            headers={authorization()}
                            folder={`EAD/${InfoUsuario.get('cdCliente')}`}
                            URI={REACT_APP_API}
                            onDownload={(file) => download(file.response)}
                            accept={'image/*'}
                            onChange={handleChange}
                            showUploadList={false}
                            disabled={loadingCapa}
                          >
                            {urlCapa && !loadingCapa ? (
                              <View>
                                <ImageCapa width={220} height={220} src={urlCapa} alt='Capa' />
                                <IconRemoverCapa
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoverImagem();
                                  }}
                                />
                              </View>
                            ) : (
                              uploadCapaButton
                            )}
                            <LabelResolutionCover>Recomendado: 1381 x 859</LabelResolutionCover>
                          </UploadStyled>
                        </ViewUpload>
                      </Form.Item>
                    </View>
                  </View>

                  <ContentOptions>
                    <Options>
                      <View>
                        <View horizontal alignItems='center'>
                          <RadioGroupWithSpace onChange={(e) => setTipoEnvio(e.target.value)} value={tipoEnvio}>
                            <Radio value={1}>Lista de transmissão</Radio>
                            <Radio value={2}>Usuários</Radio>
                            <Radio value={3}>{companyNomenclature.plural}/Perfis</Radio>
                          </RadioGroupWithSpace>
                          <ButtonQuemRecebe type='link' onClick={handleUsuariosEnvio}>
                            Quem pode ver o curso?
                          </ButtonQuemRecebe>

                        </View>
                        {tipoEnvio === 1 && (
                          <Form.Item
                            label=''
                            name='idListaTransmissao'
                            rules={[{ required: true, message: 'Informe uma lista de transmissão!' }]}
                          >
                            <Select
                              mode='multiple'
                              form={form}
                              name='idListaTransmissao'
                              placeholder='Selecione as listas de transmissões'
                              value={listasSelecionadas}
                              onChange={(items) => setListasSelecionadas(items)}
                              loading={loadingDados}
                              options={dadosCadastro.listas && dadosCadastro.listas.map((lista) => ({ value: lista.id, label: lista.nome }))}
                            />
                          </Form.Item>
                        )}
                        {tipoEnvio === 2 && (
                          <Form.Item label='' name='usuarios' rules={[{ required: true, message: 'Informe os usuários que irão receber!' }]}>
                            <Select form={form} name='usuarios' mode='multiple' action={getUsuarios} placeholder='Escolha os usuários' />
                          </Form.Item>
                        )}
                        {tipoEnvio === 3 && (
                          <>
                            <Form.Item
                              label={companyNomenclature.plural}
                              name='unidades'
                              rules={[{ required: !temUnidadeEnvio && !temPerfilEnvio, message: `Informe ${(companyNomenclature.artigo) === 'a' ? 'as' : 'os'} ${(companyNomenclature.plural).toLowerCase()} que irão receber!` }]}
                            >
                              <Select
                                form={form}
                                name='unidades'
                                mode='multiple'
                                onChange={(items) => setTemUnidadeEnvio(items.length > 0)}
                                action={getUnidades}
                                placeholder={'Escolha ' + ((companyNomenclature.artigo) === 'a' ? 'as' : 'os') + ' ' + (companyNomenclature.plural).toLowerCase()}
                              />
                            </Form.Item>
                            <Form.Item
                              label='Perfis'
                              name='perfisEnvio'
                              rules={[{ required: !temUnidadeEnvio && !temPerfilEnvio, message: 'Informe os perfis que irão receber!' }]}
                            >
                              <Select
                                form={form}
                                name='perfisEnvio'
                                mode='multiple'
                                onChange={(items) => setTemPerfilEnvio(items.length > 0)}
                                action={getPerfis}
                                placeholder='Escolha os perfis'
                              />
                            </Form.Item>
                          </>
                        )}
                      </View>
                    </Options>
                    {id > 0 && <Form.Item label="Publicado" name="publicado" valuePropName="checked">
                      <Switch />
                    </Form.Item>}
                  </ContentOptions>
                </View>
              </Form>
              <View>
                <Materias
                  materias={materias || []}
                  setMaterias={setMaterias}
                  categoriasDelete={categoriasDelete}
                  setCategoriasDelete={setCategoriasDelete}
                />
              </View>
            </View>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
