import React, { useState, useEffect } from 'react';
import App from '@app/';
import { CalendarChart, Body, PageHeader, View, Radio, Skeleton, Row, Column, Button, Breadcrumb, Tag, Icon } from '@components-teammove';
import { InfiniteScroll } from '@components';
import { getAcessoDiario, getAcessosUsuario, reset } from '@ducks/configuracoesGestaoEquipe';
import { getUsuario } from '@ducks/configuracoesUsuarios';
import TableAcessos from './TableAcessos';
import CardResumo from '../CardResumo';
import { getCalendarConfig, getColor, typeFiltroPlataforma, getColorArray } from '../rules';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams, moment, formatDate } from '@utils/';
import { Container, Label, TagText, TagsView } from '../styles';
import { Padding, DashTitle } from './styles';

export default function GestaoEquipe() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filtroCalendario, setFiltroCalendario] = useState('');
  const [calendarConfig, setCalendarConfig] = useState({ data: {} });
  const { dia } = useSearchParams();
  const { cdUsuario } = useParams();
  const filtrosAcessoDiario = { data: [moment().startOf('year'), moment()] };
  const filtrosAcessoUsuario = { data: [moment('2015-01-01'), moment()] };

  const theme = useSelector(({ app }) => app.get('theme'));

  const acessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('acessoDiario').toArray());
  const loadingAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('loadingAcessoDiario'));
  const successAcessoDiario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('successAcessoDiario'));

  const acessosUsuario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('acessosUsuario').toArray());
  const loadingAcessosUsuario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('loadingAcessosUsuario'));
  const hasMoreAcessosUsuario = useSelector(({ configuracoesGestaoEquipe }) => configuracoesGestaoEquipe.get('hasMoreAcessosUsuario'));
  const xCurrentPageAcessosUsuario = useSelector(({ configuracoesGestaoEquipe }) =>
    configuracoesGestaoEquipe.get('xCurrentPageAcessosUsuario')
  );

  const usuario = useSelector(({ configuracoesUsuarios }) => configuracoesUsuarios.get('usuario'));

  const handleReset = () => {
    dispatch(reset());
  };

  const handleGetUser = () => {
    if (cdUsuario) {
      dispatch(getUsuario({ cdUsuario }));
    }
  };

  useEffect(() => handleReset(), [dia, cdUsuario]);

  useEffect(() => handleGetUser(), [cdUsuario]);

  useEffect(() => {
    if (dia) {
      filtrosAcessoDiario.data = [moment(dia), moment(dia)];
      filtrosAcessoUsuario.data = [moment(dia), moment(dia)];
    }

    filtrosAcessoDiario.usuario = cdUsuario;
    filtrosAcessoUsuario.usuario = cdUsuario;
    dispatch(getAcessoDiario({ filtros: filtrosAcessoDiario }));
    handleSearchAcessoUsuario({ filtrosAcessoUsuario });
  }, [dia]);

  const handleSearchAcessoUsuario = ({ filtrosAcessoUsuario, page = 1 }) => {
    dispatch(getAcessosUsuario({ filtros: filtrosAcessoUsuario, page }));
  };

  const handleInfiniteOnLoad = () => {
    if (!hasMoreAcessosUsuario) {
      Notification.info('Todos os acessos foram retornados');
    } else {
      if (dia) {
        filtrosAcessoUsuario.data = [moment(dia), moment(dia)];
      }
      filtrosAcessoUsuario.usuario = cdUsuario;
      handleSearchAcessoUsuario({ page: xCurrentPageAcessosUsuario + 1, filtrosAcessoUsuario });
    }
  };

  useEffect(() => {
    if (!successAcessoDiario) return;
    const filtro = localStorage.getItem('gestaoEquipeFiltroCalendario');

    if (filtro) {
      handleChangeFiltroCalendario(filtro);
    } else {
      handleChangeFiltroCalendario(typeFiltroPlataforma.TODOS );
    }
  }, [successAcessoDiario]);

  useEffect(() => {
    if (!successAcessoDiario || filtroCalendario === '') return;
    setCalendarConfig(getCalendarConfig(filtroCalendario, acessoDiario));
  }, [filtroCalendario, successAcessoDiario]);

  const handleChangeFiltroCalendario = (data) => {
    if (data) {
      localStorage.setItem('gestaoEquipeFiltroCalendario', data);
      setFiltroCalendario(data);
    }
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={usuario && usuario.nm_usuario ? `Acessos do Usuário - ${usuario.nm_usuario}` : 'Acessos do Usuário'}
          onBack={() => window.history.back()}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/gestao-equipe', name: 'Acessos' },
                { path: '/gestao-equipe/usuario/' + cdUsuario, name: usuario.nm_usuario || '...', current: true },
              ]}
            />
          )}
          extra={[
            <Button size='small' type='secondary' key='1' onClick={() => history.push(`/gestao-equipe/visao-mensal?usuario=${cdUsuario}`)}>Visão mensal</Button>,
            <Button size='small' type='secondary' key='2' onClick={() => history.push(`/gestao-equipe/visao-geral?usuario=${cdUsuario}`)}>Visão geral</Button>
          ]}
        >
          {dia && (
            <Container>
              <Label>Filtro: </Label>
              <TagsView>
                <Tag>
                  <TagText>Data: {formatDate(dia)}</TagText>
                </Tag>
                <Button 
                  bordered 
                  transparent 
                  coloredContent
                  context='round'
                  size='small'
                  type='danger' 
                  icon={<Icon.Feather type='fi-trash2'/>} 
                  onClick={() => history.push(`/gestao-equipe/usuario/${cdUsuario}`)}
                >
               Limpar filtros
                </Button>
              </TagsView>
            </Container>
            
          )}
          {loadingAcessoDiario ? (
            <Padding>
              <Column gap='45px'>
                <Row gap='10px'>
                  <Skeleton height='115px' borderRadius='18px'/>
                  <Skeleton height='115px' borderRadius='18px'/>
                  <Skeleton height='115px' borderRadius='18px'/>
                </Row>
              </Column>
            </Padding>
          ) : (
            acessoDiario.length > 0 && (
              <Padding>
                <Column gap='45px'>
                  <Row gap='10px'>
                    <CardResumo
                      title='Acessos'
                      cor={getColor(typeFiltroPlataforma.TODOS)}
                      value={acessoDiario.map((el) => el.qtAcessoWeb + el.qtAcessoApp).reduce((acc, cur) => acc + cur)}
                    />
                    <CardResumo
                      title='Acessos Web'
                      cor={getColor(typeFiltroPlataforma.WEB)}
                      value={acessoDiario.map((el) => el.qtAcessoWeb).reduce((acc, cur) => acc + cur)}
                    />
                    <CardResumo
                      title='Acessos App'
                      cor={getColor(typeFiltroPlataforma.APP)}
                      value={acessoDiario.map((el) => el.qtAcessoApp).reduce((acc, cur) => acc + cur)}
                    />
                  </Row>
                  <Column gap='20px' align='center'>
                    <Radio.Group value={filtroCalendario} onChange={(data) => handleChangeFiltroCalendario(data)}>
                      <Radio value={typeFiltroPlataforma.TODOS}>Todos</Radio>
                      <Radio value={typeFiltroPlataforma.WEB}>Web</Radio>
                      <Radio value={typeFiltroPlataforma.APP}>App</Radio>
                    </Radio.Group>
                    <CalendarChart
                      data={calendarConfig.data.rows}
                      columns={calendarConfig.data.columns}
                      colors={getColorArray(filtroCalendario)}
                    />
                  </Column>
                </Column>
              </Padding>
            )
          )}
          <View>
            <DashTitle>Acessos</DashTitle>
            <InfiniteScroll
              threshHold={0.5}
              dataLength={acessosUsuario.length}
              next={handleInfiniteOnLoad}
              hasMore={!loadingAcessosUsuario && hasMoreAcessosUsuario}
            >
              <TableAcessos data={acessosUsuario} loading={loadingAcessosUsuario}/>
            </InfiniteScroll>
          </View>
        </PageHeader>
      </Body>
    </App>
  );
}
