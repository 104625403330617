import styled from 'styled-components';
import { Button, Text, View } from '@components';

export const ButtonPersonalizar = styled(Button)`
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 16px;
`;

export const ButtonLista = styled(ButtonPersonalizar)`
  background: #1890FF !important;
  border-color: #1890FF !important;
  color: #FFFFFF !important;
  &:hover {
    background-color: #62B1F6 !important;
    border-color: #62B1F6 !important;
  }
`;

export const TextoLista = styled(Text)`
  color: #CC4444 !important;
  font-size: 14px;
  margin-top: -8px;

`;

export const Container = styled(View)`
  flex-direction: column;
  align-items: center;
`;