import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app/';

import { useLocation } from 'react-router-dom';
import { Filtros, TelasNomenclaturas, formatCurrency, formatDate, formatDecimal } from '@utils';
import { 
  Body, 
  PageHeader, 
  Breadcrumb, 
  ActiveFilters, 
  SearchBar, 
  Filter,  
  Table,
  Button,
  Text,
  Column,
  Icon
} from '@components-teammove';
import { 
  FILTERS_KEY, 
  getRankingProducts, 
  getRankingClients
} from '@ducks/pedidosDashboard';
import { getAgrupadores } from '@sdk/Agrupadores';
import { getProductsByCompany } from '@sdk/Produtos';
import { getTiposUnidades } from '@sdk/TiposUnidades';
import { dynamicFilters, initialModalFilters } from '../../rules';
import { TableSmallText } from '../styles';

const RankingPedidos = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const rankingProducts = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('rankingProducts'));
  const successRankingProducts = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('successRankingProducts'));
  const loadingRankingProducts = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingRankingProducts'));

  const rankingClients = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('rankingClients'));
  const successRankingClients = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('successRankingClients'));
  const loadingRankingClients = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingRankingClients'));

  const theme = useSelector(({ app }) => app.get('theme'));
  const { tipo, currentFiltersState, currentParamsState } = location.state;
  const currentParams = currentParamsState;

  const [ visibleFilterModal, setVisibleFilterModal ] = useState(false);
  const [ currentFilters, setCurrentFilters ] = useState(currentFiltersState);
  const [ filtersModal, setFiltersModal] = useState(initialModalFilters);
  const [ agrupadores, setAgrupadores ] = useState(null);
  const [ categoriaProduto, setCategoriaProduto ] = useState(null);
  const [ produtos, setProdutos ] = useState(null);
  const [ tipoUnidade, setTipoUnidade ] = useState(null);
  const [ familias, setFamilias ] = useState(null);
  const [ defaultCategoriesProducts, setDefaultCategoriesProducts ] = useState(null);
  const [ defaultFamilies, setDefaultFamilies ] = useState(null);
  const [ defaultProducts, setDefaultProducts ] = useState(null);
  const [ fisrtFilter, setFirstFilter ] = useState(true);
  const [search, setSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceDefault, setDataSourceDefault] = useState([]);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(()=> {
    (async () => {
      getAgrupadores()
        .then((resp) => {
          setAgrupadores(resp?.map(({ description, id }) => ({ label: description, value: id })));
        });
      getProductsByCompany()
        .then((resp) => {
          const categoriesProductResp = resp.map((res) => ({ value: res.id, label: res.name }));
          const familiesResp = resp.flatMap((res) => res.families || []).map((res) => ({ label: res.name, value: res.id }));
          const productsResp = resp.flatMap((res) => res.families || []).map((res) => res.products || []).flat().map((res) => ({ label: res.name, value: res.id }));
          setDefaultCategoriesProducts(resp);
          setDefaultFamilies(resp.map((res) => res.families || []).flat());
          setDefaultProducts(resp.map((res) => res.families || []).flat().map((res) => res.products || []).flat());
          setCategoriaProduto(categoriesProductResp);
          setFamilias(familiesResp);
          setProdutos(productsResp);
        });
      getTiposUnidades()
        .then((resp) => {
          setTipoUnidade(resp.map((res) => ({ label: res.name, value: res.id })));
        });
    })();
  },[]);

  useEffect(() => {
    if(!successRankingProducts && !successRankingClients) return;
    setDataSource(tipo === 'Produto' ? rankingProducts : rankingClients);  
    setDataSourceDefault(tipo === 'Produto' ? rankingProducts : rankingClients); 
  }, [successRankingProducts, successRankingClients]);

  useEffect(() => {
    if (agrupadores && categoriaProduto && produtos && tipoUnidade && familias) {
      setFiltersModal([...filtersModal.map((filter) => {
        if (filter.name === 'agrupadorUnidade') {
          return { ...filter, options: agrupadores };
        }

        if (filter.name === 'categoriaProduto') { 
          let filteredCategories = null;
          if(currentFilters?.familia && fisrtFilter) {
            filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) =>
              defaultCategoryProduct.families.some((defaultFamily) =>
                currentFilters?.familia.some((option) => option === defaultFamily.id)
              )
            );      
          } else if(currentFilters?.produto && fisrtFilter) {
            filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              defaultCategoryProduct.families.some((defaultFamily) => 
                defaultFamily.products.some((defaultProduct) =>     
                  currentFilters?.produto.some((option) => option === defaultProduct.id)
                )
              )
            );
          }
          const verifyCategoriesPorducts = filteredCategories && filteredCategories?.length > 0;
          if(verifyCategoriesPorducts) setCategoriaProduto(filteredCategories.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyCategoriesPorducts ? filteredCategories.map((res) => ({ label: res.name, value: res.id })) : categoriaProduto };
        }
        
        if (filter.name === 'produto') { 
          let filteredProducts = null;
          if(currentFilters?.familia && fisrtFilter) {

            filteredProducts = defaultFamilies.filter((defaultFamily) => 
              currentFilters?.familia.some((option) => option === defaultFamily.id)
            ).flatMap((defaultFamilyFiltered) => defaultFamilyFiltered.products);

          } else if (currentFilters?.categoriaProduto && fisrtFilter) {
            filteredProducts = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              currentFilters?.categoriaProduto.some((option) => option === defaultCategoryProduct.id)).flatMap((filteredProduct) => filteredProduct.families).flatMap((family) => family.products);
          }
          const verifyProducts = filteredProducts && filteredProducts?.length > 0;
          if(verifyProducts) setProdutos(filteredProducts.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyProducts ? filteredProducts.map((res) => ({ label: res.name, value: res.id })) : produtos };
        }
        
        if (filter.name === 'familia') {
          let filteredFamilies = null;
          if(currentFilters?.produto && fisrtFilter) {
            filteredFamilies = defaultFamilies.filter((defaultFamily) => 
              defaultFamily.products.some((product) =>   
                currentFilters?.produto.some((option) => option === product.id)
              )
            );
          } else if(currentFilters?.categoriaProduto && fisrtFilter) {
            filteredFamilies = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              currentFilters?.categoriaProduto.some((option) => option === defaultCategoryProduct.id)).flatMap((filteredProduct) => filteredProduct.families);                
          }
          const verifyFamilies = filteredFamilies && filteredFamilies?.length > 0;
          if(verifyFamilies) setFamilias(filteredFamilies.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyFamilies ? filteredFamilies.map((res) => ({ label: res.name, value: res.id })) : familias };
        }

        if (filter.name === 'tipoUnidade') {
          return { ...filter, options: tipoUnidade };
        }
        setFirstFilter(false);
        return { ...filter };
      })]);
    }
  }, [agrupadores, categoriaProduto, produtos, tipoUnidade, familias]);

  useEffect(() => {
    if(!currentFilters || (Object.keys(currentFilters).length === 0 && Object.keys(currentParams).length === 0)) return;
    dispatch(getRankingProducts({ ...currentFilters, ...currentParams }));
    dispatch(getRankingClients({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  const handleSearchChangingNewFilters = (filtros, fromTags) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    if(fromTags) {
      if(!filtros?.categoriaProduto) {
        if(!filtros.familia) setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
        if(!filtros.produto) setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
      } 
      if(!filtros?.familia) {
        if(!filtros.categoriaProduto) setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
        if(!filtros.produto) setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
      } 
      if(!filtros?.produto) {
        if(!filtros.categoriaProduto) setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
        if(!filtros.familia) setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
      }
    }
    setCurrentFilters(filtros);
    handleSearchFiltros(filtros);
  };

  const handleSearchFiltros = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
  };

  const handleSearch = (text) => {
    if(text === '') return setDataSource(dataSourceDefault);

    const filteredData = dataSource.filter((item) => {      
      if (tipo === 'Produto') {
        const totalValue = item.totalValue.toString().replace(/\D/g, '');

        const simpleAttributesMatch = (
          item.companies.identifier.toLowerCase().includes(text.toLowerCase()) ||
          item.companies.name.toLowerCase().includes(text.toLowerCase()) ||
          item.weight.toString().includes(text) ||
          totalValue.toString().includes(text)
        );
  
        const familiesMatch = item.families.some((family) => {
          return (
            family.names.toLowerCase().includes(text.toLowerCase()) ||
            family.group.toLowerCase().includes(text.toLowerCase())
          );
        });
  
        return simpleAttributesMatch || familiesMatch;
      } else {
        const totalValue = item.value.toString().replace(/\D/g, '');
        return (
          item.companies.name.toLowerCase().includes(text.toLowerCase()) ||
          item.companies.identifier.toLowerCase().includes(text.toLowerCase()) ||
          item.lastOrder.toLowerCase().includes(text.toLowerCase()) ||
          item.quantityOrders.toString().includes(text) ||
          totalValue.toString().includes(text)
        );
      }
    });
    setDataSource(filteredData);
    setSearch(text);
  };

  const handleChangeSelectDependsOn = (typeField, options, filtrosState, updateFormFields) => {
    const defaultFamiliesFormated = defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id }));
    const defaultProductsFormated = defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id }));
    const defaultCategoriesProductsFormated = defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id }));

    dynamicFilters(typeField, options, filtrosState, updateFormFields, defaultCategoriesProducts, defaultFamilies, defaultCategoriesProductsFormated, defaultFamiliesFormated, defaultProductsFormated, setCategoriaProduto, setProdutos, setFamilias, filtersModal);
  };

  const handleClear = (clear) => {
    if(!clear) return;
    setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
    setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
    setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
  };

  const columns = [
    {
      title: 'Pos',
      dataIndex: 'position',
      render: (text, row, index) => {
        return <Text strong>{index + 1}</Text>;
      },
    },
    tipo === 'Produto' ? {
      title: 'Produto',
      dataIndex: 'name',
      render: (text, row) => <Text>{text}</Text>
    } : 
      {
        title: `${companyNomenclature.nomenclatura}`,
        dataIndex: 'unidade',
        render: (text, row) => {
          return (
            <Column>
              <Text>{row.companies.name}</Text>
              <TableSmallText descricao>{row.companies.identifier}</TableSmallText>
            </Column>
          );        
        }
      },
    tipo === 'Produto' ? {
      title: 'Grupo',
      dataIndex: 'grupo',
      render: (text, row) => {
        return row.families.map((family) =>  (
          <Text key={`${family.group}-${family.names}`}>{family.group}</Text>
        ));
      }
    } : {
      title: 'Último pedido',
      dataIndex: 'lastOrder',
      render: (text, row) => {
        return <Text>{formatDate(text)}</Text>;
      }
    },
    tipo === 'Produto' ? {
      title: 'Família',
      dataIndex: 'familia',
      render: (text, row) => {
        return row.families.map((family) =>  (
          <Text key={`${family.group}-${family.names}`}>{family.names}</Text>
        ));
      }
    } : {
      title: 'Qnt de Pedidos',
      dataIndex: 'quantityOrders',
      render: (text, row) => {
        return <Text>{text} {text > 1 ? 'pedidos' : 'pedido'}</Text>;
      }
    },
    {
      title: 'Peso',
      dataIndex: tipo === 'Produto' ? 'totalWeight' : 'weight',
      render: (text, row) => {
        return <Text>{formatDecimal(text, 1)} kg</Text>;
      }
    },
    {
      title: 'Valor',
      dataIndex: 'totalValue',
      render: (text, row) => {
        return <Text>{formatCurrency(text || row.value)}</Text>;
      },
    },
  ];

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={`Ranking de ${tipo}s`}
          onBack={() => window.history.back()}
          extra={[
            <Button size='small' type='secondary' key='2' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilterModal(true)}>
                Filtros
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/produtos/dashboard-pedidos', name: 'Dashboard pedidos' },
                { path: '/produtos/dashboard-pedidos/ranking', name: 'Ranking', current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          <SearchBar 
            initialValue={search} 
            onSearch={handleSearch}
          />
          <Table
            loading={loadingRankingClients || loadingRankingProducts}
            columns={columns}
            dataSource={dataSource}
            hidePagination
            rowKey='id'
            status='active'
          />              
        </PageHeader>
        {visibleFilterModal && (
          <Filter 
            visible={visibleFilterModal} 
            onClose={(clear) => { setVisibleFilterModal(false); handleClear(clear); }}
            filters={filtersModal}
            filtros={currentFilters}
            onSearch={handleSearchChangingNewFilters}
            onChangeDependsOnFilter={{
              categoriaProduto:  handleChangeSelectDependsOn ,
              familia: handleChangeSelectDependsOn,  
              produto: handleChangeSelectDependsOn,       
            }}
          />
        )}
      </Body>
    </App>
  );
};

export default RankingPedidos;