import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { saveChamado as saveChamadoSdk } from '@sdk/Chamados';

//Action Types
export const Types = {
  SAVE: 'CHAMADOS_CADASTRO/SAVE',
  SAVE_SUCCESS: 'CHAMADOS_CADASTRO/SAVE_SUCCESS',
  SAVE_ERROR: 'CHAMADOS_CADASTRO/SAVE_ERROR',
  
  RESET: 'CHAMADOS_CADASTRO/RESET',
};

//Action Creators
export const saveChamado = (chamado) => ({ type: Types.SAVE, chamado });
export const saveChamadoSuccess = (data) => ({ type: Types.SAVE_SUCCESS, data });
export const saveChamadoError = (error) => ({ type: Types.SAVE_ERROR, error });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchSaveChamado(action) {
  try {
    const { chamado } = action;

    const data = yield call(saveChamadoSdk, chamado);
    yield put(saveChamadoSuccess(data));
    Notification.success('Atendimento salvo com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveChamadoError(err));
  }
}

export const saga = [
  takeLatest(Types.SAVE, fetchSaveChamado),
];

// Reducer
const initialState = Map({
  chamado: null,
  loading: false,
  success: false,
  error: false,
});

const handleSaveChamado = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleSaveChamadoSuccess = (state, action) => {
  return state
    .set('chamado', action.data)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleSaveChamadoError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SAVE: return handleSaveChamado(state, action);
    case Types.SAVE_SUCCESS: return handleSaveChamadoSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveChamadoError(state, action);
    case Types.RESET: return initialState;

    default: return state;
  }
}
