import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { InfoUsuario } from '@utils';

const RouteComponent = ({ component: Component, secret, permissions, allowed, ...otherProps }) => (
  <Route
    {...otherProps}
    render={(routeProps) => {
      const infoUsuario = InfoUsuario.get();

      if ((secret) && (!InfoUsuario.hasUser(infoUsuario))) {
        return (
          <Redirect to={{ pathname: '/login', state: routeProps.location }} />
        );
      } else if (!allowed) {
        return (
          <Redirect to={{ pathname: '/access-denied' }} />
        );
      } else {
        return (
          <Component {...routeProps} infoUsuario={infoUsuario} />
        );
      }
    }}
  />
);

RouteComponent.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  secret: PropTypes.bool,
  permissions: PropTypes.func,
  infoUsuario: PropTypes.object,
  allowed: PropTypes.bool,
};

RouteComponent.defaultProps = {
  secret: false,
};

export default RouteComponent;
