export default function getTheme(Theme) {
  return {
    foregroundColor: Theme['@global-foreground-color'],
    backgroundColor: Theme['@global-background-color'],
    darkPrimaryColor: Theme['@global-dark-primary-color'],
    darkSecondaryColor: Theme['@global-dark-secondary-color'],
    darkHighlightColor: Theme['@global-dark-highlight-color'],
    darkLowlightColor: Theme['@global-dark-lowlight-color'],
    actionColor: Theme['@global-action-color'],
    textColor: Theme['@global-text-color'],
    textContrastColor: Theme['@global-text-contrast-color'],
    textForegroundColor: Theme['@global-text-foreground-color']
  };
}