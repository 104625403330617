import React, { useState } from 'react';
import { getIconNameAlias } from './rules';
import IconPicker from './IconPicker';
import { ColorName, PickedColor, PickedIcon, PickerInactive, SelectArrow } from './styles';
import ColorPicker from './ColorPicker';

const StyledPicker = ({ 
  kind = 'color',
  colors = [
    '#F44336', 
    '#E91E63', 
    '#9C27B0', 
    '#673AB7', 
    '#3F51B5', 
    '#2196F3', 
    '#03A9F4', 
    '#00BCD4', 
    '#009688', 
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#212121',
    '#9C2222',
  ],
  color, 
  icons = [
    'format-list-checks',
    'cached',
    'phone',
    'numeric',
    'chart-line',
    'calendar-check',
    'airplane',
    'bank',
    'cake-variant',
    'camera',
    'truck',
    'cart',
    'car',
    'home',
    'shopping',
    'heart',
    'school',
    'star',
    'wrench',
    'account-multiple',
    'silverware',
    'store',
    'laptop-mac',
    'file-document',
    'domain',
    'wallet-giftcard',
    'sofa',
    'briefcase'
  ],
  icon,
  size,
  ...props 
}) => {
  const [picking, setPicking] = useState(); 

  return(
    picking ? (
      kind === 'icon' ? (
        <IconPicker 
          icons={icons} 
          onChangeComplete={() => setPicking(false)} 
          {...props}
        />
      ) : (
        <ColorPicker 
          onChangeComplete={() => setPicking(false)}
          colors={colors}
          {...props}
        />
      )
    ) : (
      <PickerInactive onClick={() => setPicking(true)}>
        {kind === 'icon' ? (
          <PickedIcon type={icon} title={getIconNameAlias(icon)}/>
        ) : color && (
          <PickedColor color={color} title={color}/>
        )}
        {size !== 'small' && (
          <>
            <ColorName isPlaceholder={!icon && !color}>
              {kind === 'icon' ? (
                icon ? getIconNameAlias(icon) : 'Selecione um ícone'
              ) : (
                color || 'Selecione uma cor'
              )}
            </ColorName>
            <SelectArrow/>
          </>
        )}
      </PickerInactive>
    )
  );
};

export default StyledPicker;