import React, { useEffect, useMemo, useState } from 'react';
import { View, Text, Spin, Image } from '@components';
import { Icon , Form, Upload, Modal, Input, GoogleMap, Select, Switch, Button, InputMask, ContentTitle, Row, Skeleton } from '@components-teammove';

import { getSearchParams, download, InfoUsuario, Notification } from '@utils';
import { initialValuesSecondaryAddress, initialValuesUnidade } from './rules';
import { customFields } from '../rules';
import { DynamicFieldImageContainer, MarginBottom, SecondaryAddressesContainer, NewSecondaryAddressButton, SecondaryAddressInfo, SecondaryAddressTitle, ClusterColumns, ClusterColumnsTitle, Subtitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUnidade, postUnidade, updateUnidade, resetSuccessSave } from '@ducks/unidades';
import { getTiposUnidades, reset as resetTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { getAgrupadores, reset as resetAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getClustersByCompany } from '@ducks/configuracoesUnidadesClusters';

import { getLagLngFromAddress, stateOptions } from '../../../utils/Geography';
import { authorization } from '@sdk/api';
import { ansiToMoment, dateToAnsiDate } from '../../../utils/Datas';
import ConditionalCustomField from './ConditionalCustomField';
import { useHistory } from 'react-router-dom';
import { getOrdersKinds, reset as resetOrdersKinds } from '@ducks/settingsOrdersOrdersKinds';
import { getPriceTables, reset as resetPriceTables } from '@ducks/settingsProductsPriceTables';

const { REACT_APP_API } = window.env;

const EditarUnidade = ({ visible, onCancel, id = -1, title }) => {
  const idUnidade = useMemo(() => parseInt(getSearchParams('unidade') || id), []);
  const history = useHistory();
  const [form] = Form.useForm();
  const [customFieldsForm] = Form.useForm();
  const [secondaryAddressForm] = Form.useForm();

  const hasInternationalization = InfoUsuario.get('key') === '166-cecal';

  const dispatch = useDispatch();
  const unidade = useSelector(({ unidades }) => unidades.get('unidade'));
  const loading = useSelector(({ unidades }) => unidades.get('loading'));
  const success = useSelector(({ unidades }) => unidades.get('success'));
  const unidadeRespSave = useSelector(({ unidades }) => unidades.get('unidadeRespSave'));
  const loadingSave = useSelector(({ unidades }) => unidades.get('loadingSave'));
  const successSave = useSelector(({ unidades }) => unidades.get('successSave'));
  const tiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const loadingTiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loadingTiposUnidades'));
  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));
  const loadingAgrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('loadingAgrupadores'));

  const clustersByCompany = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('clustersByCompany'));
  const loadingClustersByCompany = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingClustersByCompany'));
  const successClustersByCompany = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successClustersByCompany'));
  const ordersKinds = useSelector(({ ordersKinds }) => ordersKinds.get('ordersKinds'));
  const loadingOrdersKinds = useSelector(({ ordersKinds }) => ordersKinds.get('loadingOrdersKinds'));
  const priceTables = useSelector(({ priceTables }) => priceTables.get('priceTables'));
  const loadingPriceTables = useSelector(({ priceTables }) => priceTables.get('loadingPriceTables'));

  const [unidadeToSend, setUnidadeToSend] = useState({});
  const [dynamicFields, setDynamicFields] = useState();
  const [originalFields, setOriginalFields] = useState();
  const [loadingUpload, setLoadingUpload] = useState({});
  const [modalImgVisible, setModalImgVisible] = useState(false);
  const [currentModalImg, setCurrentModalImg] = useState();
  const [modalSecondaryAddressVisible, setModalSecondaryAddressVisible] = useState(false);
  const [secondaryAddressBeingEdited, setSecondaryAddressBeingEdited] = useState(initialValuesSecondaryAddress);

  useEffect(() => {
    if (unidade.id !== idUnidade) {
      dispatch(getUnidade(parseInt(idUnidade), true));
    }
    dispatch(getAgrupadores());
    dispatch(getTiposUnidades());
    dispatch(getOrdersKinds());
    dispatch(getPriceTables());

    return () => {
      dispatch(resetTiposUnidades());
      dispatch(resetAgrupadores());
      dispatch(resetSuccessSave());
      dispatch(resetOrdersKinds());
      dispatch(resetPriceTables());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success) {
      const unidadeToSet = { ...unidade };

      if (unidadeToSet.dtOpening) unidadeToSet.dtOpening = ansiToMoment(unidadeToSet.dtOpening);

      form.setFieldsValue(idUnidade === -1 ? initialValuesUnidade : unidadeToSet);
      customFieldsForm.setFieldsValue(removeCustomFieldsAlpha(idUnidade === -1 ? initialValuesUnidade : unidadeToSet));

      setUnidadeToSend(idUnidade === -1 ? initialValuesUnidade : unidadeToSet);
      setDynamicFields(unidade.dynamicFields);

      setOriginalFields(form.getFieldsValue());
    }
  }, [unidade]);

  useEffect(() => {
    if (dynamicFields) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...dynamicFields.reduce((state, dynField) => ({ ...state, [`dynField${dynField.id}`]: dynField.value?.value }), {})
      });
    }
  }, [dynamicFields]);

  useEffect(() => {
    if (successSave) {
      if(idUnidade === -1) {
        history.push(`/unidades/${unidadeRespSave.id}`);
      }
      onCancel();
    }
  }, [successSave]);

  const removeCustomFieldsAlpha = (customFieldsObj) => {
    Object.keys(customFieldsObj).forEach((key) => {
      if (key === customFields.CELULAR_1 || key === customFields.CELULAR_2 || key === customFields.TELEFONE_1 || key === customFields.TELEFONE_2 || key === customFields.CNPJ || key === customFields.IE) {
        customFieldsObj[key] = customFieldsObj[key].replaceAll(/[^0-9a-z]/gi, '');
      }
    });
    return customFieldsObj;
  };

  useEffect(() => {
    if (form.getFieldValue().idType) {
      dispatch(getClustersByCompany(form.getFieldValue().idType));
    }

  }, [form.getFieldValue().idType]);

  useEffect(() => {
    if (successClustersByCompany) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...clustersByCompany.reduce((state, clusterGroup) => ({
          ...state, [`cluster${clusterGroup.id}`]: clusterGroup.clusters.find((clusterGroup) => unidade.clusters?.includes(clusterGroup.id))?.id

        }), {})
      });

    }
  }, [successClustersByCompany]);

  const handleLocationFocusLost = async (form, currentState, setCurrentState, component, updateForm = false, newFieldValue = '') => {
    if (component === 'cep') {
      if (newFieldValue.length < 8) {
        setCurrentState({ ...currentState, [component]: newFieldValue });
        return;
      }
    }
    if (component === 'address') {
      if (newFieldValue.length < 10) {
        setCurrentState({ ...currentState, [component]: newFieldValue });
        return;
      }
    }
    else {
      if (!newFieldValue) {
        setCurrentState({ ...currentState, [component]: newFieldValue });
        return;
      }
    }

    const addressToSend = `${form.getFieldsValue().address} - ${form.getFieldsValue().city} - ${form.getFieldsValue().district} - ${form.getFieldsValue().state} - ${form.getFieldsValue().cep}`;
    getLagLngFromAddress(addressToSend).then((latLng) => {
      if (latLng.results.length > 0) {    
        const city = latLng.results[0].address_components.find((component) => component.types.includes('administrative_area_level_2'))?.short_name;
        const state = latLng.results[0].address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name;
        const address = latLng.results[0].address_components.find((component) => component.types.includes('route'))?.short_name;
        const district = latLng.results[0].address_components.find((component) => component.types.includes('sublocality_level_1'))?.short_name;

        const { location } = latLng.results[0].geometry;

        let updatedUnidadeToSend = {
          ...currentState,
          cep: form.getFieldsValue().cep,
          city: form.getFieldsValue().city,
          state: form.getFieldsValue().state,
          address: form.getFieldsValue().address,
          district: form.getFieldsValue().district,
          latitude: String(location.lat),
          longitude: String(location.lng)
        };

        if (updateForm && component === 'cep') {
          if (city) {
            updatedUnidadeToSend = { ...updatedUnidadeToSend, city };
          }
          if (state) {
            updatedUnidadeToSend = { ...updatedUnidadeToSend, state };
          }
          if (address) {
            updatedUnidadeToSend = { ...updatedUnidadeToSend, address };
          }
          if (district) {
            updatedUnidadeToSend = { ...updatedUnidadeToSend, district };
          }
        }

        setCurrentState({ ...updatedUnidadeToSend });
        form.setFieldsValue({ ...form.getFieldsValue(), ...updatedUnidadeToSend });
      }
    });
  };

  const handleUploadChange = ({ file }, dynamicField) => {
    switch (file.status) {
      case 'uploading': {
        return setLoadingUpload({ [dynamicField.id]: true });
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        return setLoadingUpload({ [dynamicField.id]: false });
      }
      case 'done': {
        handleDynamicFieldChange(dynamicField, file.response);
        return setLoadingUpload({ [dynamicField.id]: false });
      }
      default: {
        return setLoadingUpload({ [dynamicField.id]: false });
      }
    }
  };

  const handleDynamicFieldChange = (dynamicField, newValue) => {
    setDynamicFields(dynamicFields.map((dynField) => {
      return dynField.id === dynamicField.id ?
        { ...dynField, value: { idDynamicField: dynField.id, value: newValue } } :
        { ...dynField };
    }));
    setCurrentModalImg(newValue);
  };

  const handleImageClick = (url) => {
    setModalImgVisible(!modalImgVisible);
    setCurrentModalImg(url);
  };

  const handleNewAddressClick = (initialValuesForm = initialValuesSecondaryAddress) => {
    secondaryAddressForm.setFieldsValue(initialValuesForm);
    setSecondaryAddressBeingEdited(initialValuesForm);
    setModalSecondaryAddressVisible(!modalSecondaryAddressVisible);
  };

  const handleDeleteImage = (image) => {
    const updatedDynamicFields = dynamicFields.map((dynamicField) => {
      if (dynamicField?.value?.value === image) {
        dynamicField.value.value = '';
      }
      return dynamicField;
    });

    setDynamicFields(updatedDynamicFields);
  };

  const handleSecondaryAddressSave = () => {
    secondaryAddressForm.validateFields().then((values) => {
      values.latitude = secondaryAddressBeingEdited.latitude;
      values.longitude = secondaryAddressBeingEdited.longitude;
      values.cep = values.cep.replace('-', '');

      if (!values.id) {
        delete values.id;
        setUnidadeToSend({
          ...unidadeToSend,
          secondaryAddresses: [...(unidadeToSend.secondaryAddresses || []), values]
        });
      } else {
        setUnidadeToSend({
          ...unidadeToSend,
          secondaryAddresses: unidadeToSend.secondaryAddresses.map((secAddress) => secAddress.id === values.id ? values : secAddress)
        });
      }

      setModalSecondaryAddressVisible(false);
    });
  };

  const handleSave = () => {
    form.validateFields().then(async (values) => {
      const addressToSend = `${values.address} - ${values.city} - ${values.district} - ${values.state} - ${values.cep?.replace('-', '')}`;
      const latLng = await getLagLngFromAddress(addressToSend);
      const { location } = latLng.results[0].geometry;

      const customFields = await customFieldsForm.validateFields();

      const clusters = Object.entries(values).reduce((acc, [prop, value]) => {
        if (prop.includes('cluster') && value !== undefined) {
          return [...acc, value];
        } else {
          return acc;
        }
      }, []);

      let valuesToSend = {
        ...unidadeToSend,
        ...customFields,
        dynamicFields,
        latitude: String(location.lat),
        longitude: String(location.lng),
        clusters: clusters !== undefined ? [...clusters] : []
      };

      if (valuesToSend.idCluster === -1) delete valuesToSend.idCluster;

      if (valuesToSend.dtOpening) valuesToSend.dtOpening = dateToAnsiDate(valuesToSend.dtOpening);
      if (!valuesToSend.idApi) delete valuesToSend.idApi;

      valuesToSend = { ...valuesToSend, cellphone: valuesToSend?.cellphone?.replace(/\D/g, ''), telephone: valuesToSend?.telephone?.replace(/\D/g, '') };

      if (idUnidade === -1) dispatch(postUnidade(valuesToSend));
      else dispatch(updateUnidade(valuesToSend, parseInt(idUnidade)));
    })
      .catch(Form.scrollToFirstError);
  };

  const onBeforeCancel = () => {
    if(originalFields) {
      const currentFormValues = form.getFieldsValue();
      const valuesChanged = !Object.entries(originalFields).every(
        ([key, value]) => currentFormValues[key] === value
      );
      return valuesChanged;
    }
    return form.isFieldsTouched() || Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
  };
  
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onBeforeCancel={onBeforeCancel}
      title={'Cadastro de ' + title}
      onOk={handleSave}
      okText='Salvar'
      okButtonProps={{ loading: loadingSave }}
    >
      <Form form={form} layout='vertical'>
        {loading ?
          <View>
            <Spin />
          </View>
          : success && (
            <>
              <Form.Item name='identifier' rules={[{ required: true, message: 'Informe o identificador' }, { max: 15, message: 'Informe um identificador mais curto' }]} label={'Identificador'} colon={false}>
                <Input autocomplete='off' placeholder='Identificador' onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, identifier: evt?.target?.value })} autoFocus />
              </Form.Item>
              <Form.Item name='name' rules={[{ required: true, message: 'Informe o nome' }]} label={'Nome'} colon={false}>
                <Input autocomplete='off' placeholder='Nome' onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, name: evt?.target?.value })} />
              </Form.Item>
              {idUnidade !== '-1' && (
                <Form.Item name='active' label='Ativo'>
                  <Switch checked={unidadeToSend.active} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, active: value })} />
                </Form.Item>
              )}
              <Form.Item name='email' label={'E-mail'} colon={false}>
                <Input autocomplete='off' placeholder='E-mail' onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, email: evt?.target?.value })} />
              </Form.Item>
              <Form.Item name='cep' label={hasInternationalization ? 'Postal Code' : 'CEP'} colon={false}>
                <InputMask mask={hasInternationalization ? '' : '99999-999'} placeholder={hasInternationalization ? 'Postal Code' : 'CEP'} onChange={(evt) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'cep', true, evt?.target?.value)} />
              </Form.Item>
              <Form.Item name='address' label={'Endereço'} colon={false}>
                <Input autocomplete='off' placeholder='Endereço' onChange={(evt) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'address', true, evt?.target?.value)} />
              </Form.Item>
              <Form.Item name='district' label={'Bairro'} colon={false}>
                <Input autocomplete='off' placeholder='Bairro' onChange={(evt) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'district', false, evt?.target?.value)} />
              </Form.Item>
              <Form.Item name='city' rules={[{ required: true, message: 'Informe a cidade' }]} label={'Cidade'} colon={false}>
                <Input autocomplete='off' placeholder='Cidade' onChange={(evt) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'city', false, evt?.target?.value)} />
              </Form.Item>
              <Form.Item name='state' rules={[{ required: true, message: 'Informe o estado' }]} label={'Estado'} colon={false}>
                {hasInternationalization ? (
                  <Input autocomplete='off' placeholder='Estado' onChange={(evt) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'state', false, evt?.target?.value)} />
                ) : (
                  <Select placeholder='Estado' options={stateOptions} onChange={(value) => handleLocationFocusLost(form, unidadeToSend, setUnidadeToSend, 'state', false, value)} />
                )}
              </Form.Item>
              <MarginBottom>
                <GoogleMap
                  center={{ lat: parseFloat(unidadeToSend.latitude) || 0, lng: parseFloat(unidadeToSend.longitude) || 0 }}
                  zoom={15}
                  mapStyles={{ height: '500px', width: '100%', borderRadius: '12px' }}
                  marker={{ position: { lat: parseFloat(unidadeToSend.latitude) || 0, lng: parseFloat(unidadeToSend.longitude) || 0 } }}
                />
              </MarginBottom>

              <Form.Item name='idGroup' label={'Agrupador'} colon={false}>
                <Select
                  placeholder='Agrupador'
                  options={agrupadores?.map(({ description, id }) => ({ label: description, value: id }))}
                  loading={loadingAgrupadores}
                  onChange={(value) => setUnidadeToSend({ ...unidadeToSend, idGroup: value })}
                />
              </Form.Item>
              <Form.Item name='idType' rules={[{ required: true, message: `Informe o tipo de ${title}` }]} label={'Tipo de ' + title} colon={false}>
                <Select
                  placeholder={'Tipo de ' + title}
                  options={tiposUnidades?.map(({ name, id }) => ({ label: name, value: id }))}
                  loading={loadingTiposUnidades}
                  onChange={(value) => setUnidadeToSend({ ...unidadeToSend, idType: value })}
                />
              </Form.Item>

              {clustersByCompany.length > 0 && (
                loadingClustersByCompany ? <Skeleton.Form />
                  : successClustersByCompany && (
                    <Row gap='25px'>
                      <ClusterColumns>
                        {clustersByCompany.length > 0 && (
                          <ClusterColumnsTitle>Tipo de Cluster</ClusterColumnsTitle>
                        )}
                        {clustersByCompany?.map(({ id, name }) =>
                          <Form.Item key={id} rules={[{ required: true, message: 'Selecione o tipo de cluster' }]} colon={false}>
                            <Input value={name} placeholder='Tipo de cluster' disabled />
                          </Form.Item>
                        )}
                      </ClusterColumns>

                      <ClusterColumns>
                        {clustersByCompany.length > 0 && (
                          <ClusterColumnsTitle>Cluster</ClusterColumnsTitle>
                        )}
                        {clustersByCompany.map(({ id, clusters }) => (
                          <Form.Item key={id} name={'cluster' + id} rules={[{ required: false, message: 'Selecione o cluster' }]} colon={false}>
                            <Select
                              label='Cluster'
                              placeholder='Selecione o cluster'
                              options={clusters?.map(({ name, id }) => ({ label: name, title: name, value: id }))}
                            />
                          </Form.Item>
                        ))}
                      </ClusterColumns>
                    </Row>
                  ))}

              {unidade.customFields?.map((customField) => {
                return <ConditionalCustomField key={customField.field} form={customFieldsForm} customField={customField} unidadeToSend={unidadeToSend} setUnidadeToSend={setUnidadeToSend} hasInternationalization={hasInternationalization} />;
              })}
              {dynamicFields?.map((dynamicField) => dynamicField.preview && (
                <Form.Item name={`dynField${dynamicField.id}`} key={dynamicField.id} required={dynamicField.required} rules={[{ required: dynamicField.required, message: 'Insira um valor para o campo' }]} label={dynamicField.alias} colon={false}>
                  {dynamicField.kind === 'TEXT' && (
                    <Input
                      autocomplete='off'
                      value={dynamicFields?.find((dynField) => dynField.id === dynamicField.id)?.value?.value}
                      placeholder={dynamicField.alias}
                      onChange={(evt) => handleDynamicFieldChange(dynamicField, evt?.target?.value)}
                    />
                  )}
                  {dynamicField.kind === 'IMAGE' && (
                    <DynamicFieldImageContainer>
                      {dynamicField.value?.value && (
                        <>
                          <Button
                            icon={<Icon.PictureOutlined />}
                            onClick={() => handleImageClick(dynamicField.value.value)}
                          />
                          <Modal visible={modalImgVisible} footer={''} okText={'Excluir Imagem'} onOk={() => handleDeleteImage(dynamicField.value.value)} onCancel={() => setModalImgVisible(false)}>
                            <Image src={currentModalImg} />
                          </Modal>
                        </>
                      )}
                      <Upload
                        showUploadList={false}
                        headers={authorization()}
                        folder={`Empresas/${InfoUsuario.get('cdCliente')}`}
                        URI={REACT_APP_API}
                        onDownload={(file) => download(file.response)}
                        accept={'image/*'}
                        onChange={(info) => handleUploadChange(info, dynamicField)}
                      >
                        <Button icon={<Icon.DownloadOutlined />} loading={loadingUpload[dynamicField.id]}>
                          {dynamicField.alias}
                        </Button>
                      </Upload>
                    </DynamicFieldImageContainer>
                  )}
                  {dynamicField.kind === 'NUMBER' && (
                    <Input
                      autocomplete='off'
                      type='number'
                      placeholder={dynamicField.alias}
                      onChange={(evt) => handleDynamicFieldChange(dynamicField, evt?.target?.value)}
                    />
                  )}
                </Form.Item>
              ))}
              <Form.Item name='idApi' label={'ID API'} colon={false}>
                {unidade.apiIds.length > 0 ? (
                  <Select
                    placeholder='ID API'
                    options={unidade.apiIds.map((idApi) => ({ label: idApi, value: idApi }))}
                    loading={loading}
                    onChange={(value) => setUnidadeToSend({ ...unidadeToSend, idApi: value })}
                  />
                ) : (
                  <Input
                    placeholder='ID API'
                    onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, idApi: evt?.target?.value })}
                  />
                )}
              </Form.Item>
              {ordersKinds.length > 0 && (
                <>
                  <Subtitle level={3}>Pedidos</Subtitle><Form.Item name='orderKindId' label={'Tipo de Pedido'} colon={false}>
                    <Select
                      placeholder='Tipos de pedidos'
                      options={[...ordersKinds?.map(({ distributorName, id }) => ({ label: distributorName, value: id }))]}
                      loading={loadingOrdersKinds}
                      onChange={(value) => setUnidadeToSend({ ...unidadeToSend, orderKindId: value })} 
                    />
                  </Form.Item>
                </>
              )}
              {priceTables.length > 0 && (
                <>
                  <Subtitle level={3}>Produtos</Subtitle>
                  <Form.Item name='priceTableId' label={'Tabela de preço'} colon={false}>
                    <Select
                      placeholder='Tabela de preço'
                      options={priceTables?.filter((table)=>table.active).map(({ name, id })=> ({ label: name, value: id }))}
                      loading={loadingPriceTables}
                      onChange={(value) => setUnidadeToSend({ ...unidadeToSend, priceTableId: value })}
                    />
                  </Form.Item>
                </>
              )}
              {idUnidade !== '-1' && (
                <>
                  <ContentTitle level={3}>Endereços</ContentTitle>
                  <SecondaryAddressesContainer>
                    {unidadeToSend.secondaryAddresses?.map((secLocation) => secLocation.active && (
                      <SecondaryAddressInfo onClick={() => handleNewAddressClick(secLocation)} key={secLocation.id || secLocation.description}>
                        <SecondaryAddressTitle level={4}>{secLocation.description}</SecondaryAddressTitle>
                        <Text>{secLocation.address}</Text>
                        <Text>{`${secLocation.district} - ${secLocation.city} - ${secLocation.state}`}</Text>
                        <Text>{secLocation.cep}</Text>
                      </SecondaryAddressInfo>
                    ))}
                    <NewSecondaryAddressButton type='link' onClick={() => handleNewAddressClick()}>Novo Endereço</NewSecondaryAddressButton>
                  </SecondaryAddressesContainer>
                </>
              )}

              <Modal
                visible={modalSecondaryAddressVisible}
                title='Endereço secundário'
                onBeforeCancel={() => Object.keys(secondaryAddressForm.getFieldsValue()).some((key) => key !== 'active' && secondaryAddressForm.getFieldValue(key))}
                onCancel={() => setModalSecondaryAddressVisible(false)}
                onOk={handleSecondaryAddressSave}
                closable={false}
                width={700}
              >
                <Form form={secondaryAddressForm} layout='vertical'>
                  <Form.Item name='id' hidden />
                  <Form.Item name='description' rules={[{ required: true, message: 'Informe a descrição do endereço' }]} label='Descrição' colon={false}>
                    <Input autocomplete='off' placeholder='Descrição' onChange={(evt) => setSecondaryAddressBeingEdited({ ...secondaryAddressBeingEdited, description: evt?.target?.value })} />
                  </Form.Item>
                  <Form.Item name='cep' rules={[{ required: true, message: 'Informe o CEP' }, { min: 8, message: 'Preencha o CEP completo' }]} label={'CEP'} colon={false}>
                    <Input
                      autocomplete='off' placeholder='CEP' onChange={(evt) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'cep', true, evt?.target?.value)}
                    />
                  </Form.Item>
                  <Form.Item name='address' rules={[{ required: true, message: 'Informe o endereço' }]} label={'Endereço'} colon={false}>
                    <Input autocomplete='off' placeholder='Endereço' onChange={(evt) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'address', true, evt?.target?.value)} />
                  </Form.Item>
                  <Form.Item name='district' rules={[{ required: true, message: 'Informe o bairro' }]} label={'Bairro'} colon={false}>
                    <Input autocomplete='off' placeholder='Bairro' onChange={(evt) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'district', false, evt?.target?.value)} />
                  </Form.Item>
                  <Form.Item name='city' rules={[{ required: true, message: 'Informe a cidade' }]} label={'Cidade'} colon={false}>
                    <Input autocomplete='off' placeholder='Cidade' onChange={(evt) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'city', false, evt?.target?.value)} />
                  </Form.Item>
                  <Form.Item name='state' rules={[{ required: true, message: 'Informe o estado' }]} label={'Estado'} colon={false}>
                    {hasInternationalization ? (
                      <Input autocomplete='off' placeholder='Estado' onChange={(evt) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'state', false, evt?.target?.value)} />
                    ) : (
                      <Select placeholder='Estado' options={stateOptions} onChange={(value) => handleLocationFocusLost(secondaryAddressForm, secondaryAddressBeingEdited, setSecondaryAddressBeingEdited, 'state', false, value)} />
                    )}
                  </Form.Item>
                  <MarginBottom>
                    <GoogleMap
                      center={{ lat: parseFloat(secondaryAddressBeingEdited?.latitude) || 0, lng: parseFloat(secondaryAddressBeingEdited?.longitude) || 0 }}
                      zoom={15}
                      mapStyles={{ height: '400px', width: '100%', borderRadius: '12px' }}
                      marker={{ position: { lat: parseFloat(secondaryAddressBeingEdited?.latitude) || 0, lng: parseFloat(secondaryAddressBeingEdited?.longitude) || 0 } }}
                    />
                  </MarginBottom>
                  {secondaryAddressBeingEdited.id ? (
                    <Form.Item name='active' label='Ativo'>
                      <Switch checked={secondaryAddressBeingEdited.active} onChange={(value) => setSecondaryAddressBeingEdited({ ...secondaryAddressBeingEdited, active: value })} />
                    </Form.Item>
                  ) : (
                    <Form.Item name='active' hidden />
                  )}
                </Form>
              </Modal>

              {InfoUsuario.get('senhaMaster') && (
                <>
                  <Form.Item name='useDre' label='Utiliza DRE'>
                    <Switch checked={unidadeToSend.useDre} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, useDre: value })} />
                  </Form.Item>
                  <Form.Item name='useConversionRate' label='Utiliza Taxa de Conversão'>
                    <Switch checked={unidadeToSend.useConversionRate} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, useConversionRate: value })} />
                  </Form.Item>
                  <Form.Item name='useSalesTarget' label='Utiliza Alvo de Vendas'>
                    <Switch checked={unidadeToSend.useSalesTarget} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, useSalesTarget: value })} />
                  </Form.Item>
                  <Form.Item name='useRegistrationGoalScaleChangeQuota' label='Utiliza Registro de Mudança na Meta'>
                    <Switch checked={unidadeToSend.useRegistrationGoalScaleChangeQuota} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, useRegistrationGoalScaleChangeQuota: value })} />
                  </Form.Item>
                </>
              )}
            </>
          )}
      </Form>
    </Modal >
  );
};

export default EditarUnidade;