import React, { useState } from 'react';
import ActiveFilters from './ActiveFilters';
import { Icon } from '@components-teammove';
import { CloseIcon, StyledSearchBar } from './styles';

const SearchBar = ({ initialValue, placeholder = 'Pesquisar', activeFiltersProps, ...props }) => {
  const [value, setValue] = useState(initialValue);

  const handleClear = () => {
    setValue();
    props.onSearch('');
  };

  const handlePressEnter = (evt) => {
    if (evt.target.value) {
      props.onSearch(evt.target.value);
    } else {
      handleClear();
    }
  };
  
  return(
    <>
      <ActiveFilters {...activeFiltersProps}/>
      <StyledSearchBar 
        prefix={<Icon.Box type='search'/>} 
        placeholder={placeholder}
        value={value} 
        onChange={(e) => {
          setValue(e.target.value);
          if (props.onChange) {
            props.onChange(e.target.value);
          }
        }} 
        allowClear={false}
        suffix={!props.disabled && value?.length > 0 ? <CloseIcon type='X' onClick={handleClear}/> : <span/>}
        onPressEnter={handlePressEnter}
        {...props}
      />
    </>
  );
};

export default SearchBar;