export const formatParams = (params) => {
  const {
    classifications,
    users,
    search,
    limit,
    typePeriod,
    initialDate,
    orderByField,
    orderByDirection,
  } = params;

  const typePeriodFormat = typePeriod === 'DIARIO' ? 'daily' : typePeriod === 'SEMANAL' ? 'weekly' : typePeriod === 'MENSAL' && 'monthly';

  const classificationsParam = classifications ? `classifications=${classifications}` : '';
  const usersParam = users ? `usersId=${users.join(' ')}` : '';
  const limitParam = limit ? `limit=${limit}` : '';
  const searchParam = search ? `search=${search}` : '';
  const typePeriodParam = typePeriodFormat ? `typePeriod=${typePeriodFormat}` : '';
  const initialDateParam = initialDate ? `initialDate=${initialDate}` : '';
  const orderByFieldParam = orderByField ? `orderByField=${orderByField}` : '';
  const orderByDirectionParam = orderByDirection ? `orderByDirection=${orderByDirection}` : '';

  return [
    classificationsParam,
    usersParam,
    searchParam,
    limitParam,
    typePeriodParam,
    initialDateParam,
    orderByFieldParam,
    orderByDirectionParam
  ]
    .filter((param) => param)
    .join('&');
};