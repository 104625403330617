import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
  background: ${(props) => props.theme.darkPrimaryColor};
  border: 1px solid ${(props) => props.theme.darkHighlightColor};
  border-radius: 32px;

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.actionColor} !important;
  }
`;