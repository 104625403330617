import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Button, Filter, ActiveFilters, Skeleton, Row, Column, Icon } from '@components-teammove';

import { getAudiencia, getAudienciaCategoria, getAudienciaUltimosAcessos, setFiltersWithOptions, FILTERS_KEY } from '@ducks/wikisAudiencia';
import { Filtros } from '@utils';
import { getCategorias } from '@sdk/Wikis/Audiencia';
import RankingItem from './RankingItem';
import { useHistory } from 'react-router-dom';
import Visualizacao from './RankingCategoria/Visualizacao';
import Timeline from './Timeline';
import { ContentCard, ContentTitle, Flex, RankingContainer } from './styles';

export default function WikisConteudos() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visibleFilters, setVisibleFilters] = useState(false);
  const filters = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('filters'));

  const theme = useSelector(({ app }) => app.get('theme'));

  const audiencias = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('audiencias').toArray());
  const loading = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('loading'));

  const loadingUltimosAcessos = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('loadingUltimosAcessos'));

  const audienciasCategoria = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('audienciasCategoria').toArray());

  const filtros = Filtros.get(FILTERS_KEY);

  const handleSearchAudiencia = (filtros) => {
    dispatch(getAudiencia(10, filtros));
  };

  const handleSearchCategoria = (filtros) => {
    dispatch(getAudienciaCategoria(undefined, filtros));
  };

  const handleSearchAcessos = (page, filtros) => {
    if (!loadingUltimosAcessos) {
      dispatch(getAudienciaUltimosAcessos(page, filtros));
    }
  };

  const handleSearch = (filtros) => {
    handleSearchAudiencia(filtros);
    handleSearchCategoria(filtros);
    handleSearchAcessos(1, filtros);
  };

  useEffect(() => {
    handleSearch(filtros);
    (async () => {
      const categorias = await getCategorias();
      dispatch(setFiltersWithOptions(filters.map((filter) => {
        return filter.name === 'categoria' ? ({ ...filter, options: categorias }) : ({ ...filter });
      })));
    })();
  }, []);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Dashboard de audiência'
          onBack={() => window.history.back()}
          extra={[
            <Button key="1" size='small' context='header' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>
              Filtros
            </Button>,
          ]}
        >
          <ActiveFilters filtros={filtros} onSearch={handleSearch} filters={filters}/>

          <Column gap='28px'>
            <Flex direction={audienciasCategoria.length > 10 ? 'column' : 'row'} gap='12px'>
              <Column gap='20px' flex={1}>
                <Row justify='space-between'>
                  <ContentTitle>
                  Top 10 Categorias
                  </ContentTitle>
                  <Button type='secondary' size='small' onClick={audiencias.length > 9 && (() => history.push('/conteudos/audiencia/acesso/categorias'))}>
                  Ver todos os conteúdos
                  </Button>
                </Row>
                {loading ? (
                  <Skeleton.Form/>
                ) : (
                  <ContentCard>
                    <RankingContainer>
                      {audiencias.map((item, index) => (
                        <RankingItem key={item.id} pos={index + 1} item={item} />
                      ))}
                    </RankingContainer>
                  </ContentCard>
                )}
              </Column>
              <Column gap='20px' flex={1}>
                <ContentTitle>Ranking / Categoria</ContentTitle>
                <ContentCard>
                  <Visualizacao search={handleSearchCategoria} />
                </ContentCard>
              </Column>
            </Flex>

            <Column gap='20px'>
              <ContentTitle>Timeline</ContentTitle>
              <ContentCard>
                <Timeline search={handleSearchAcessos} history={history} />
              </ContentCard>
            </Column>
          </Column>
        </PageHeader>

        <Filter 
          visible={visibleFilters} 
          onClose={() => setVisibleFilters(false)} 
          filtros={filtros} 
          onSearch={handleSearch} 
          filters={filters}
        />
      </Body>
    </App>
  );
}
