import { Input } from 'antd';
import Icon from '../Icon';
import styled from 'styled-components';

export const StyledInputComponent = styled(Input)`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 14px;

  ${({ disabled }) => disabled && `
    opacity: .5;
  `}

  &.ant-input-affix-wrapper: hover, :hover{
    border-right-width: 2px !important;
  }

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  .ant-input-prefix {
    display: flex;
    align-self: center;
    font-size: 16px;
  }
  .ant-input-suffix {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
  }

  &.ant-input-group-wrapper {
    padding: 0;

    .ant-input-group {
      color: ${({ theme }) => theme.textColor};

      .ant-input-group-addon {
        background-color: inherit;
        color: inherit;
        border: none;

        +.ant-input-affix-wrapper {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left: 1px solid ${(props) => props.theme.darkHighlightColor} !important;
        }
      }

      .ant-input-affix-wrapper {
        border: none !important;

        +.ant-input-group-addon {
          border-left: 1px solid ${(props) => props.theme.darkHighlightColor} !important;
        }
      }
    }
  }
`;

export const StyledInputPasswordComponent = styled(Input.Password)`
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  padding: 16px;

  input {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }
  }

  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
    border-right-width: 2px !important;
    box-shadow: none !important;

    :hover {
      border-right-width: 2px !important;
    }
  }
  font-weight: 400;
  color: ${(props) => props.theme.textColor};

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }
`;

export const ErrorIcon = styled(Icon.MDI)`
  path {
    color: var(--danger-color) !important;
  }
`;

export const TextArea = styled(Input.TextArea)`
  &.ant-input {
    background-color: ${(props) => props.theme.darkPrimaryColor};
    border: 2px solid ${(props) => props.theme.darkHighlightColor};
    border-radius: 8px !important;
    padding: 16px;
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    margin-bottom: 0;

    ::placeholder {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 50%;
    }

    :focus {
      box-shadow: none;
    }

    :focus-within {
      border: 2px solid ${(props) => props.theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }
    font-weight: 400;
    color: ${(props) => props.theme.textColor};

    .ant-input-suffix {
      display: flex;
      align-items: center;
      opacity: .5;
    }

    :hover, :focus {
      border-color: ${(props) => props.theme.actionColor};
    }
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;