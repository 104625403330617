import React from 'react';
import { Column, Row, Text, Avatar, RaceLineProgress, Skeleton, Tooltip, FinishFlag } from '@components-teammove';
import { HorizontalDivider, LineLegend, PanelTheRun, RowStyle, StyleEmpty, TheRunHeader, Typography, VerticalDivider } from './styles';
import { getDownloadUrlAvatar } from '@utils';
import { useSelector } from 'react-redux';
import { findColorByPercentage } from './rules';

export const ActivitiesGoalsPanel = ({ onClick, activeUsersList }) => {

  const activitiesGoals = useSelector(({ activitiesGoals }) => activitiesGoals.get('activitiesGoals'));
  const successActivitiesGoals = useSelector(({ activitiesGoals }) => activitiesGoals.get('successActivitiesGoals'));
  const loadingActivitiesGoals = useSelector(({ activitiesGoals }) => activitiesGoals.get('loadingActivitiesGoals'));

  return(
    <>
      {loadingActivitiesGoals ? <Skeleton.LargeBox/> : successActivitiesGoals && activitiesGoals.length > 0 ? (
        <PanelTheRun>
          <TheRunHeader align='center' >
            <Text size='14px' weight='700'>Corrida</Text>
          </TheRunHeader>

          {activitiesGoals.filter((item)=> item.numberOfActivitiesGoal !== 0).map(({ id, 
            idealNumberOfFinishedActivitiesByGivenMomentPercentage, name, numberOfFinishedActivitiesPercentage, idealNumberOfFinishedActivitiesByGivenMoment,
            numberOfPendingActivitiesPercentage, numberOfPendingActivities, numberOfActivitiesGoal, numberOfFinishedActivities, picture, profileColor, profileName 
          }, index, array) => (  
            <RowStyle onClick={()=> onClick(id)} active={activeUsersList.find((item)=> item === id)} lastChild={array.length - 1 === index} align='center' color={index % 2 === 0 ? '1' : ''} justify='space-between' gap='40px' key={id}>
              <Row align='center'gap='12px' >
                <Avatar size='44px' src={getDownloadUrlAvatar(picture)}/>
                <VerticalDivider color={profileColor}/>
        
                <Column>
                  <Tooltip title={name}>
                    <Typography maxWidth='150px' size='18px' weight='400'>{name}</Typography>
                  </Tooltip>
                  <Tooltip title={profileName}>
                    <Typography maxWidth='150px' size='14px' weight='400' lowOpacity>{profileName}</Typography>
                  </Tooltip>
              
                </Column>
              </Row>
              <Row aling='center' width='100%'>
                <RaceLineProgress
                  showFinishFlag
                  maxPercentage={120}
                  info={
                    <Column width='100%' gap='6px'>
                      <Row align='center' gap='4px'><FinishFlag rows={3} height={12}/><Text>Meta: {numberOfActivitiesGoal?.toFixed(2).replace('.', ',')} ({numberOfFinishedActivitiesPercentage?.toFixed(2).replace('.', ',')}%)</Text></Row>
                      <Row align='center' gap='4px'><LineLegend vertical color='#34C759'/><Text>Meta Projetada: {idealNumberOfFinishedActivitiesByGivenMoment?.toFixed(2).replace('.', ',')} ({((numberOfFinishedActivities / idealNumberOfFinishedActivitiesByGivenMoment) * 100).toFixed(2).replace('.', ',')}%)</Text></Row>
                      <HorizontalDivider/>
                      <Row align='center' gap='4px'>{findColorByPercentage(numberOfFinishedActivitiesPercentage, idealNumberOfFinishedActivitiesByGivenMomentPercentage)}<Text>Realizado: {numberOfFinishedActivities?.toFixed(2).replace('.', ',')}</Text></Row>
                      <Row align='center' gap='4px'><LineLegend typeLine='dashed' color='#FFCC00'/><Text>Agendado: {numberOfPendingActivities?.toFixed(2).replace('.', ',')}</Text></Row>
                    </Column>
                  }
                  data={[
                    { percent: numberOfFinishedActivitiesPercentage, typeLine: 'solid' }, 
                    { percent: numberOfPendingActivitiesPercentage, typeLine: 'dashed' },
                  ]}
                  goal={{ percent: idealNumberOfFinishedActivitiesByGivenMomentPercentage, color: '#34C759' }}
                />
              </Row>
            </RowStyle>
          ))}
        </PanelTheRun>
      ) : (
        <StyleEmpty description="Sem dados" />
      )}
    </>
  );
};