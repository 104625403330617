import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, View } from '@components-teammove';
import FamilyRegister from '../FamilyRegister';
import CategoryRegister from '../CategoryRegister';
import { ButtonSmall, StyledRow } from './styles';

export default function Titulo({ id, families, name, active }) {

  const [visibleSubCategoryRegister, setVisibleSubCategory] = useState(false);
  const [visibleCategoryRegister, setVisibleCategoryRegister] = useState(false);
  const history = useHistory();

  const handleButtonClick = ({ id, families, name }) => {
    if (families) {
      history.push('?tipo=' + id, { data: { id, families, name } });
      setVisibleCategoryRegister(true);

    } else {
      history.push('?tipo=' + id, { data: { id, families, name } });
      setVisibleSubCategory(true);
    }
  };

  return (
    <StyledRow align='center' gap='8px' active={active}>
      <View onClick={() => handleButtonClick({ id, families, name })}>
        <Text>{name}</Text>
      </View>
      {families && (
        <ButtonSmall
          shape="circle"
          type="primary"
          onClick={() => setVisibleSubCategory(true)}
        >+</ButtonSmall>
      )}
      {visibleCategoryRegister && (
        <CategoryRegister visible={visibleCategoryRegister} onCancel={() => setVisibleCategoryRegister(false)} />
      )}
      {visibleSubCategoryRegister && (
        <FamilyRegister visible={visibleSubCategoryRegister} onCancel={() => setVisibleSubCategory(false)} categoryId={id} />
      )}
    </StyledRow>
  );
}
