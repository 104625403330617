import styled from 'styled-components';
import { View, MessageList } from '@components';
import { Icon, Button, Column, PageHeader, Body, Text } from '@components-teammove';

import { ToolTipIcon } from './MenuItem/styles';

export const Row = styled(View)`
  flex-direction: row;
  ${({ marginLeft }) => marginLeft && `
    margin-left: auto;
  `};
  ${({ marginRight }) => marginRight && `
    margin-right: 3rem;
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const JustifyCenterView = styled(View)`
  flex: 1;
  justify-content: center;
`;

export const FillView = styled(View)`
  flex: 1;
  position: relative;
  ${({ bordered, theme }) => bordered && `
    border-top: 2px solid ${theme.darkSecondaryColor};
  `}
`;

export const Margin = styled(View)`
  margin-bottom: 48px;
`;

export const DetailsRow = styled(Column)`
  display: flex;
  justify-content: space-between;
`;

export const DetailsColumn = styled(View)`
  gap: 12px;
`;

export const DetailsView = styled(View)`
  border-top: 2px solid ${({ theme }) => theme.darkSecondaryColor};
  border-bottom: 2px solid ${({ theme }) => theme.darkSecondaryColor};
  padding: 20px 50px;
`;

export const DetailText = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: #F5F5F5;
    height: fit-content;
  }
`;

export const DetailLabel = styled(DetailText)`
  &.ant-typography {
    font-weight: 700;
    opacity: 50%;
  }
`;

export const EditIcon = styled(Icon.EditOutlined)`
  &.anticon {
    font-size: 16px;
    opacity: 50%;
    height: fit-content;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const Header = styled(View)`
  flex: 1;
  width: 100%;
`;

export const ChatTitleRow = styled(Row)`
  gap: 10px;
  align-items: center;
`;

export const ChatTitle = styled.input`
  color: #F5F5F5;
  font-size: 32px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background-color: transparent;
  width: ${(props) => props?.value?.length * 18}px;
  max-width: 750px;
  min-width: 140px;
`;

export const Chat = styled(MessageList)`
  img {
    max-width: fit-content;    
  }

  div[class*=rce-smsg] {
    background-color: transparent;
    box-shadow: none;
  }

  .rce-mbox-title{
    span{
      flex: 1;
    }
  }
  
  .rce-mbox-text:after{
    content: none !important;
  }

  .rce-mbox-photo--img {
    border-radius: 0;
  }

`;

export const CollapseDownButton = styled(Icon.DownOutlined)`
  position: absolute;
  bottom: calc(16px * -1);
  z-index: 1;
  align-self: center;
  svg {
    background-color: ${(props) => props.theme.darkSecondaryColor};
    padding: 8px;
    border-radius: 50%;
    color: ${({ theme }) => theme.textColor};
    width: max-content;
    height: max-content;
  }
`;

export const CollapseUpButton = styled(Icon.UpOutlined)`
  position: absolute;
  bottom: calc(16px * -1);
  z-index: 1;
  align-self: center;
  svg {
    background-color: ${(props) => props.theme.darkSecondaryColor};
    padding: 8px;
    border-radius: 50%;
    color: ${({ theme }) => theme.textColor};
    width: max-content;
    height: max-content;
  }
`;

export const VisualizeBriefingButton = styled(Button)`
  margin-top: 12px;
`;

export const ProtocolText = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const StylePageHeader = styled(PageHeader)`
  .ant-page-header-content {
    padding-top: 0;
  }
  margin-bottom: 0 !important;
  .ant-page-header-heading {
    padding-top: 16px;
  }
`;

export const StyleColumn = styled(Column)`
  margin-right: ${({ collapsed })=> collapsed ? '130px' : '380px'};
`;

export const ContentMessage = styled(Column)`
  margin-right: ${({ collapsed })=> collapsed ? '120px' : '340px'};
  position: sticky;
  bottom: 0;
`;

export const StyleBody = styled(Body)`
  .ant-page-header {
    padding: 0px 24px !important;
  }
`;

export const InfoParentTag = styled.div`
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 27px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  cursor: pointer;
`;

export const DataIcons = styled(ToolTipIcon)`
  :hover{
    cursor: pointer;
  }
  margin-bottom: 10px;
`;

export const AlertText = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
`;

export const AlertStar = styled.span`
  color: var(--danger-color) !important;
  font-size: 12px;
`;