import styled from 'styled-components';
import { Icon, Row, Text } from '@components-teammove';

export const StepBall = styled(Row)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${({ theme, status }) => status === 'Fechado' ? '' : `1px solid ${theme.darkHighlightColor}` };
  background-color: ${({ status }) => status === 'Fechado' ? 'var(--success-color)' : ''};
`;

export const ContentPhases = styled(Row)`
  max-width: 190px;
  flex-wrap: wrap;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme, status }) => status === 'Fechado' ? theme.textColor : theme.darkHighlightColor} !important;
  opacity: ${({ opacity })=> opacity ? '.5' : '1'};
`;

export const InfoDot = styled.div`
  background-color: ${({ color, theme }) => color ? 'var(--success-color)' : theme.darkBackgroundColor};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: ${({ color, theme })=> color ? '' : `1px solid ${theme.darkHighlightColor}`};
`;

export const StyleText = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: 12px;
  font-weight: 600;
`;