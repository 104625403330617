import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Column, Text, LineChart, Skeleton } from '@components-teammove';
import { rgba } from 'polished';
import { Container, SelectType } from './styles';
import { getDashboard } from '@ducks/pedidosDashboard';
import { formatDaysInLabels, getOptions } from './rules';

const OrderEvolution = ({ currentFilters, currentParams, setCurrentParams }) => {
  const dispatch = useDispatch();
  const graphicOptions = getOptions(currentParams.typeValue);

  const dashboard = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('dashboard').toArray ());
  const loading = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loading'));
  const success = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('success'));

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if(Object.keys(currentFilters).length === 0 && Object.keys(currentParams).length === 0) return;
    dispatch(getDashboard({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  useEffect(() => {
    if(success) {
      setLabels(formatDaysInLabels(currentParams?.typePeriod, dashboard));
    }
  }, [success]);

  const handleChangeTypeValue = (typeValue) => {
    setCurrentParams({ ...currentParams, typeValue });
  };

  const data = {
    labels: labels,
    datasets: [{
      data: dashboard?.map((item) => item.count),
      backgroundColor: (context) => {
        const { ctx } = context.chart;
        const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        gradient.addColorStop(0, rgba(52, 199, 89, 1));
        gradient.addColorStop(1, rgba(52, 199, 89, 0));
        return gradient;
      },
      pointRadius: 4,
      borderColor: rgba(92, 184, 92, 1),
      fill: true,
      boundary: 'start',
    }]
  };

  return (
    !loading ? (
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Evolução de</Text>
            <Text size='20px' weight='800' line='20px'>Pedidos</Text>
          </Column>
          <SelectType 
            size='small'
            value={currentParams.typeValue} 
            options={[{ label: 'Valor R$', value: 'value' }, { label: 'Peso', value: 'weight' }, { label: 'Quantidade', value: 'quantity' }]}
            loading={loading}
            onChange={handleChangeTypeValue}
          />
        </Row>
        <LineChart data={data} typePeriod={currentParams?.typePeriod} options={graphicOptions}/>
      </Container>
    ) : <Skeleton.Map/>
  );
};

export default OrderEvolution;