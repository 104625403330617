export function userActivityTimesSort(a, b) {
  const icons = {
    'VISITA': 'briefcase',
    'HOME_OFFICE': 'home',
    'FOLGA': 'rest-time-line',
    'DESLOCAMENTO': 'car-line',
    'PESQUISA': 'search',
    'OUTROS': 'more-vertical'
  };
  a.icon = icons[a.id];
  b.icon = icons[b.id];

  if (a.id === 'VISITA' && b.id !== 'VISITA') {
    return -1;
  } else if (a.id !== 'VISITA' && b.id === 'VISITA') {
    return 1;
  } else {
    return 0;
  }
  
}

export function RemoveEmptyItemsFromUserActivityTypeGroupsSummary(items) {
  
  const removedEmptyItems = items.filter((item) => {
    const { hours, minutes, seconds } = item.totalTime;
    const isTotalTimeEmpty = hours === 0 && minutes === 0 && seconds === 0;
    
    return !(item.percent === 0 && isTotalTimeEmpty);
  });
  
  return removedEmptyItems ;
}

