import React, { useState } from 'react';
import { CardTitle, CollapseButton, StyledCard, CardBody, CardHeader } from './styles';

const Card = ({ title, subtitle, allowCollapse = true, collapsed, children, ...props }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  
  return(
    <StyledCard collapsed={isCollapsed}>
      {allowCollapse && <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}/>}
      <CardHeader>
        {title && <CardTitle>{title}</CardTitle>}
        {subtitle && subtitle}
      </CardHeader>
      {!isCollapsed && (
        <CardBody>
          {children}
        </CardBody>
      )}
    </StyledCard>
  );
};

export default Card;