import styled, { css } from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background-color: ${(props) => props.theme.darkPrimaryColor};
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  ${(props) => (props.type === 'danger') && css`
      * {
        color: #FF6866 !important;
      }
    `}
  &.ant-dropdown-menu-item {
    color: ${(props) => props.theme.textColor} !important;

    .ant-btn {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 1;

      :hover {
        background-color: transparent !important;
        opacity: 1;
      }
    }

    :hover {
      background-color: ${(props) => props.theme.darkSecondaryColor};
    }
  }
`;