import React from 'react';
import PropTypes from 'prop-types';
import { StyledTree } from './styles';
import { Icon, Column, Skeleton } from '@components-teammove';

const Tree = ({ loading, name, ...otherProps }) => {
  const treeName = `tree-${name}`;

  const handleExpand = (expandedKeys) => {
    localStorage.setItem(treeName, expandedKeys);
  };

  const getExpandedKeys = () => {
    const keys = localStorage.getItem(treeName);
    return (keys) ? keys.split(',') : [];
  };

  return(
    <>
      {loading ? (
        <Column gap='9px'>
          <Skeleton.Title/>
          <Skeleton.Title/>
          <Skeleton.Title/>
          <Skeleton.Title/>
        </Column>
      ) : (
        <StyledTree 
          onExpand={handleExpand}
          defaultExpandedKeys={getExpandedKeys()}
          switcherIcon={<Icon.Feather type='chevron-down'/>}
          {...otherProps}
          
        />
      )}
    </>
  );
  
};

Tree.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

Tree.defaultProps = {
  loading: false,
  showLine: false,
  showIcon: true,
};

export default Tree;