import React from 'react';
import { Modal, View } from '@components-teammove';
import { formatDateTime } from '@utils';
import { InfoText } from './styles';

export default function Informacoes({ visible, onCancel, conteudo }) {
  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      title='Informações'
      footer={null}
    >
      <View>
        <InfoText>Criado dia {formatDateTime(conteudo.get('dtCriacao'))} por {conteudo.get('usuarioCriacao') || '(Sem registro)'}
        </InfoText>
        <InfoText>Alterado dia {formatDateTime(conteudo.get('dtUltimaAtualizacao'))} por {conteudo.get('usuarioAlteracao') || '(Sem registro)'}</InfoText>
      </View>
    </Modal>
  );
}
