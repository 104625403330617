import styled from 'styled-components';
import { View, Text } from '@components-teammove';

export const MarginBottom = styled(View)`
  margin-bottom: 1.5rem;
`;

export const SmallText = styled(Text)`
  &.ant-typography {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;

    strong {
      font-weight: 700;
    }
  }
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const SendToContainer = styled(SpaceBetweenRow)`
  align-items: center;
  margin-bottom: 12px;
`;