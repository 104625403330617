import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, View } from '@components';
import { Icon } from '@components-teammove';
import ItemLayout from '../Item';

const Container = styled(View)`
  margin: 25px 0 30px 0;
  border-radius: 10px;
  padding: 10px 0 0 0;
  background: #FFF7E2;
  flex: 1;

  > div + div {
    border-top: 1px solid #f0f0f0;
  }

  > div {
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(60,90,100,0.04);
    }
  }
`;

const Title = styled(Icon.PushpinFilled)`
  font-size: 18px;
  margin-left: 35px;
  text-align: left;
`;

const Line = styled(View)`
  padding: 10px;
`;

export default function Destaques({ list, comunicados, onItemClick }) {
  const [viewMore, setViewMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setViewMore(false);
    setHasMore(list && list.length > 5);
  }, [list]);

  return (
    <Container>
      <Title />
      {list.slice(0, 5).map((wiki) => (
        <Line key={wiki.id} onClick={onItemClick(wiki)}>
          <ItemLayout item={wiki} comunicados={comunicados} />
        </Line>
      ))}
      {hasMore && !viewMore && <Button onClick={() => setViewMore(true)}>Ver Mais</Button>}
      {viewMore && (
        <>
          {list.slice(5).map((wiki) => (
            <Line key={wiki.id} onClick={onItemClick(wiki)}>
              <ItemLayout item={wiki} comunicados={comunicados} />
            </Line>
          ))}
        </>
      )}
    </Container>
  );
}
