import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Select, Row, Icon, Picker } from '@components-teammove';
import { getTiposAtividades } from '@ducks/configuracoesAtividadesTipos';
import { putAgrupadoresMetasAtividades } from '@ducks/configuracoesAgrupadoresMetasAtividades';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityName, IconWrapper } from './styles';

const CadastroAgrupadoresMetas = ({ visible, onCancel, infoAgrupador }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [options, setOptions] = useState(null);

  const active = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));

  const successPutAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('successPutAgrupadoresMetas'));
  const loadingPutAgrupadoresMetas = useSelector(({ configuracoesAgrupadoresMetasAtividades }) => configuracoesAgrupadoresMetasAtividades.get('loadingPutAgrupadoresMetas'));

  useEffect(() => {
    dispatch(getTiposAtividades());
  }, []);

  useEffect(() => {
    if(!successPutAgrupadoresMetas) return;
    onCancel();
  }, [successPutAgrupadoresMetas]);

  useEffect(() => {
    const options = active.map((activityType) => ({
      value: activityType.id,
      title: activityType.nome,
      label: renderActivityTypeOption(activityType)
    }));
    setOptions(options);
  }, [active]);

  useEffect(() => {
    if(!infoAgrupador) {
      form.resetFields();
      return;
    }
    const activityKindIds = infoAgrupador.activityKinds.map((activity) => activity.id);

    form.setFieldsValue({ ...infoAgrupador, activityKinds: activityKindIds });
  }, [infoAgrupador]);
  
  const handleOk = () => {
    form.validateFields().then((values) => {
      dispatch(putAgrupadoresMetasAtividades({
        ...values, 
        id: infoAgrupador ? infoAgrupador.id : values.id
      }));
    });
  };

  const renderActivityTypeOption = (activityType) => (
    <Row gap='15px' align='center'>
      <IconWrapper color={activityType.cor}>
        <Icon.MDI type={activityType.icone}/>
      </IconWrapper>
      <ActivityName>{activityType.nome}</ActivityName>
    </Row>
  );

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      okText='Adicionar'
      title='Adicionar agrupador de metas'
      okButtonProps={{ loading: loadingPutAgrupadoresMetas }}
      onCancel={onCancel}
    >
      <Form form={form} layout='vertical'>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'Digite um nome para seu agrupador' }]}
          label='Nome do agrupador'
          colon
        >
          <Input placeholder='Digite o nome do agrupador de atividades'/>
        </Form.Item>
        <Form.Item
          name='activityKinds'
          rules={[{ required: true, message: 'Insira atividades para seu agrupador' }]}
          label='Atividades'
        >
          <Select
            placeholder='Selecione as atividades do agrupador'
            tokenSeparators={[',']}
            mode="tags"
            options={options}
          />
        </Form.Item>
        <Row gap='20px'>
          <Form.Item
            name='color'
            rules={[{ required: true, message: 'Insira uma cor para o agrupadores de atividades' }]}
            label='Cor'
            valuePropName='color'
          >
            <Picker/>
          </Form.Item>
          <Form.Item
            name='icon'
            rules={[{ required: true, message: 'Insira um icone para seu agrupadores' }]}
            label='Ícone'
            valuePropName='icon'
          >
            <Picker kind='icon'/>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default CadastroAgrupadoresMetas;