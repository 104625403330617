import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Input, InputMask, Skeleton, Select } from '@components-teammove';
import { getSearchParams } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { getContato, resetContato , postContato, updateContato, deleteContato } from '@ducks/contatos';
import { useHistory, useParams } from 'react-router-dom';
import { getClassificationContacts } from '@ducks/settingsClassificationContacts';

export default function CadastroContato({ visible, onCancel }) {
  const id = useMemo(() => parseInt(getSearchParams('contact')), []);
  const { idUnidade: companyId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const contato = useSelector(({ contatos }) => contatos.get('contato'));
  const loading = useSelector(({ contatos }) => contatos.get('loading'));
  const success = useSelector(({ contatos }) => contatos.get('success'));
  const loadingSave = useSelector(({ contatos }) => contatos.get('loadingSave'));
  const successSave = useSelector(({ contatos }) => contatos.get('successSave'));
  const loadingDelete = useSelector(({ contatos }) => contatos.get('loadingDelete'));

  const classificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('classificationContacts'));
  const loadingClassificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('loadingClassificationContacts'));
  const successClassificationContacts = useSelector(({ settingsClassificationContacts }) => settingsClassificationContacts.get('successClassificationContacts'));

  useEffect(() => {
    if (id) {
      dispatch(getContato(id));
    }

    return () => {
      dispatch(resetContato());
      history.push('#');
    };
  }, [id]);

  useEffect(() => {
    if (success) {
      form.setFieldsValue({ ...contato, idClassificacaoContato: contato.idClassificacaoContato === 0 ? undefined : contato.idClassificacaoContato }); 
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      onCancel();
    }
  }, [successSave]);

  useEffect(() => {
    dispatch(getClassificationContacts());
  }, []);

  const onBeforeCancel = () => {
    if (!id) {
      return Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
    }
  };

  const onOk = () => {
    form.validateFields().then((values) => {
      values.idUnidade = companyId;
      values.telefone = values.telefone?.replaceAll(/[^0-9a-z]/gi, '');
      if (id) {
        dispatch(updateContato(values));
      } else {
        dispatch(postContato(values));
      }
    }).catch(Form.scrollToFirstError);
  };

  const handleDeleteContact = (contact) => {
    dispatch(deleteContato({ ...contact, ativo: false }));
  };

  return(
    <Modal 
      visible={visible}
      title='Cadastro de contato'
      type='form'
      onBeforeCancel={onBeforeCancel}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ loading: loadingSave }}
      okText='Salvar'
      cancelButtonProps={id && { type: 'danger', loading: loadingDelete, onClick: null }}
      cancelText={id ? 'Excluir' : 'Cancelar'}
      cancelButtonConfirm={!!id}
      cancelButtonConfirmProps={id && { title: 'Deseja realmente excluir este contato?', okText: 'Sim', cancelText: 'Não', onConfirm: () => handleDeleteContact(contato) }}
    >
      {loading ? (
        <>
          <Form.Item disabled label={<Skeleton.MinorLine/>}>
            <Skeleton.SearchBar/>
          </Form.Item>
          <Form.Item disabled label={<Skeleton.MinorLine/>}>
            <Skeleton.SearchBar/>
          </Form.Item>
          <Form.Item disabled label={<Skeleton.MinorLine/>}>
            <Skeleton.SearchBar/>
          </Form.Item>
          <Form.Item disabled label={<Skeleton.MinorLine/>}>
            <Skeleton.SearchBar/>
          </Form.Item>
        </>
      ) : (
        <Form form={form} layout='vertical'>
          <Form.Item name='id' hidden/>
          <Form.Item name='idTemp' hidden/>
          <Form.Item name='ativo' hidden/>
          <Form.Item name='nome' label='Nome' rules={[{ required: true, message: 'Insira um nome para o contato' }]}>
            <Input placeholder='Nome'/>
          </Form.Item>
          <Form.Item name='cargo' label='Cargo'>
            <Input placeholder='Cargo'/>
          </Form.Item>
          <Form.Item name='idClassificacaoContato' label='Classificação'>
            <Select 
              placeholder='Selecone uma das opções'
              options = {[
                { key: '0', value: null, label: 'Sem Classificação' },
                ...classificationContacts.map((classification) => ({
                  key: classification.id,
                  value: classification.id,
                  label: classification.description
                }))
              ]}
            />
          </Form.Item>
         
          <Form.Item name='telefone' label='Telefone'>
            <InputMask mask='99 99999-9999' placeholder='Telefone'/>
          </Form.Item>
          <Form.Item name='email' label='E-mail'>
            <Input type='email' placeholder='E-mail'/>
          </Form.Item>
          <Form.Item name='linkedin' label='LinkedIn'>
            <Input type='url' placeholder='LinkedIn'/>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}