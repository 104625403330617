import React from 'react';
import { Column, Row } from '@components-teammove';
import { ColorIndicator, Container, Division, Label } from './styles';

const Legend = ({ colors, labels }) => (
  <Column gap='4px'>
    <Container>
      {colors.map((color) => (
        <Division bgColor={color} key={color}/>
      ))}
    </Container>
    <Row justify='space-between'>
      {labels.map(({ label, color }) => (
        <Row gap='4px' align='center' key={label + color}>
          <ColorIndicator bgColor={color}/>
          <Label>{label}</Label>
        </Row>
      ))}
    </Row>
  </Column>
);

export default Legend;