import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Bugsnag } from '@utils';

const openNotif = (type, message, otherProps) => {
  return toast[type](message,{
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'dark', 
    ...otherProps,
  });
};

const success = (message, otherProps) => openNotif('success', message, otherProps);
const info = (message, otherProps) => openNotif('info', message, otherProps);
const warning = (message, otherProps) => openNotif('warning', message, otherProps);
const error = (message, otherProps) => {
  Bugsnag.notify(new Error(message));
  return openNotif('error', message, otherProps);
};

export default {
  success,
  info,
  warning,
  error,
};
