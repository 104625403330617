import styled from 'styled-components';
import { Column, Row, Empty } from '@components-teammove';

export const Container = styled(Column)`
  background-color: ${({ theme })=> theme.darkPrimaryColor};
  border-radius: 18px;
  padding: 20px 20px 17px 20px;
  width: 60%;
`;

export const Footer = styled.div`
  padding: 20px 110px 20px 40px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  border-radius: 18px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Tag = styled.div`
  padding: 8px 16px 8px 16px;
  background-color: var(--danger-color);
  border-radius: 27px;
  font-size:20px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme })=> theme.textColor};
`;

export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

export const VerticalLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme })=> theme.darkHighlightColor};
`;

export const StyleEmpty = styled(Empty)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor} !important;
  margin: 0;
	height: 300px; 
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;