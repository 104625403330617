import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {  Row, Column, Text, Divider, VerticalBarWithAverageLineChart, Skeleton } from '@components-teammove';
import { Container, StyleEmpty } from './styles';

import { TelasNomenclaturas, getCustomColor, moment } from '@utils';
import { getOpenTicketsDash } from '@ducks/chamadosDashboard';

export default function OpenTicketsEvolution({ currentFilters, currentParams }) {
  const dispatch = useDispatch();

  const openTickets = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('openTicketsDash'));
  const loadingOpenTickets = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingOpenTicketsDash'));
  const successOpenTickets = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successOpenTicketsDash'));
  
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getOpenTicketsDash({ ...currentFilters, ...currentParams }));
    }
  }, [currentFilters, currentParams]);

  const fillWeekendIfIsZero = openTickets.filter((item) => {
    const isWeekend = item.momentDescription === 'DOM' || item.momentDescription === 'SÁB';
    const isZeroQuantity = item.quantity === 0 && item.quantityCompleted === 0 && item.quantityPending === 0;
    
    return !(isWeekend && isZeroQuantity);
  });

  const arrayQuantity = fillWeekendIfIsZero.filter((item) => {
    const openingMoment = moment(item.openingMoment);
    const currentMoment = moment();

    if (currentParams.typePeriod === 'DIARIO') {
      return currentMoment.isSameOrAfter(openingMoment, 'hour');
    } else if (currentParams.typePeriod === 'SEMANAL') {
      return currentMoment.isSameOrAfter(openingMoment, 'day');
    } else if (currentParams.typePeriod === 'MENSAL') {
      return currentMoment.isSameOrAfter(openingMoment, 'week');
    } else if (currentParams.typePeriod === 'TRIMESTRAL') {
      return currentMoment.isSameOrAfter(openingMoment, 'month');
    } else if (currentParams.typePeriod === 'SEMESTRAL') {
      return currentMoment.isSameOrAfter(openingMoment, 'month');
    } else if (currentParams.typePeriod === 'ANUAL') {
      return currentMoment.isSameOrAfter(openingMoment, 'quarter');
    } else {
      return currentMoment.isSameOrAfter(openingMoment);
    }
    
  }).map((item)=> item.quantity);
  
  const accumulatedAverage = (arrayQuantity) => arrayQuantity.map((acc, index, arr) => arr.slice(0, index + 1).reduce((acc, cur) => acc + cur) / (index + 1));
  
  const barWidth = currentParams.typePeriod === 'DIARIO' ? 50 : 150;

  const datasets = [
    {
      label: 'Média acumulada',
      data: accumulatedAverage(arrayQuantity),
      borderColor: getCustomColor('--success-color'), 
      borderWidth: 3,
      type: 'line',
      order:1,
      pointStyle: true,
    },
    {
      label: 'Concluídas',
      fill: true,
      data: fillWeekendIfIsZero.map(({ quantityCompleted })=> quantityCompleted),
      backgroundColor: '#1E80F0', 
      borderWidth: 0,
      order: 2,
      barThickness: barWidth,
    },
    {
      label: 'Pendentes',
      fill: true,
      data: fillWeekendIfIsZero.map(({ quantityPending })=> quantityPending),
      backgroundColor: '#78B3F6', 
      borderWidth: 0,
      order: 3,
      barThickness: barWidth,
    },
  ];

  return(
    loadingOpenTickets ? (
      <Skeleton.Map/>
    ) : successOpenTickets && (
      
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Evolução de</Text>
            <Text size='20px' weight='800' line='20px'>{ticketNomenclatures.plural}</Text>
          </Column>
        </Row>
        <Divider/>
        {openTickets.length < 1 ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <VerticalBarWithAverageLineChart 
              data={fillWeekendIfIsZero.map(({ quantity, momentDescription, quantityCompleted, quantityPending }) => ({ totalValue: quantity, label: momentDescription, completeValue: quantityCompleted, pendingValue: quantityPending  }))} 
              datasets= {datasets}
            />
          )}
      </Container>
    )
  );
}