import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Text, View } from '@components-teammove';
import { authorization } from '@sdk/api';
import { postLogoImpressao, getLogoImpressao, deleteLogoImpressao } from '@ducks/configuracoesLogoImpressao';
import { useDispatch, useSelector } from 'react-redux';
import { download } from '@utils';
import { ComponentEmptyCover, ContainerBody, IconLoading, IconRemoveCover, ImageCover, UploadEdge, UploadIcon, UploadText } from './styles';

const { REACT_APP_API } = window.env;

const LogoImpressao = () => {
  const dispatch = useDispatch();
  
  const [cover, setCover] = useState(null);
  const [loadingCover, setLoadingCover] = useState(false);

  const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));
  const theme = useSelector(({ app }) => app.get('theme'));

  const logoImpressao = useSelector(({ configuracoesLogoImpressao }) => configuracoesLogoImpressao.get('logoImpressao'));
  const success = useSelector(({ configuracoesLogoImpressao }) => configuracoesLogoImpressao.get('success'));
  
  useEffect(() => {
    setLoadingCover(true);
    dispatch(getLogoImpressao());
  },[]);

  useEffect(() => {
    if(success) {
      setLoadingCover(false);
      setCover(logoImpressao?.url);
    }
  },[success]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleRemoveCover = () => {
    setCover(null);
    dispatch(deleteLogoImpressao());
  };

  const handleChangeCover = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingCover(true);
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setCover(url);
        setLoadingCover(false);
      });
      dispatch(postLogoImpressao(info.file.response, infoUsuario.nmCliente));
    }
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Logo impressão'

          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/telasNomenclaturas', name: 'Logo impressão', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <ContainerBody>
            <Text>
            Anexe o arquivo do seu logo (preferencialmente em jpg, preto e branco, formato 450px x 350px)
            </Text>

            <UploadEdge
              showUploadList={false}
              headers={authorization()}
              folder={`LogoImpressao/${infoUsuario.cdCliente}`}
              URI={REACT_APP_API}
              onDownload={(file) => download(file.response)}
              accept="image/jpeg,image/png"
              onChange={handleChangeCover}
            >
              {cover && !loadingCover ? (
                <View>
                  <ImageCover height={120} src={cover} alt='Picture' />
                  <IconRemoveCover
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRemoveCover();
                    }}
                  />
                </View>
              ) : (
                <ComponentEmptyCover>
                  {loadingCover ? <IconLoading /> : <UploadIcon type='upload' />}
                  <UploadText>Clique ou arraste para anexar (tamanho recomendado: 450x120px)</UploadText>
                </ComponentEmptyCover>
              )}
            </UploadEdge>   
          </ContainerBody>
        </PageHeader>
      </Body>
    </App>
  );
};

export default LogoImpressao;