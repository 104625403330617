import styled from 'styled-components';
import { Row, Text } from '@components';
import { Icon } from '@components-teammove';

export const FooterCards = styled(Row)`
	border-radius: 32px;
	background-color: ${({ theme, activeStatus })=> activeStatus ? `${theme.darkPrimaryColor}` : `${theme.darkSecondaryColor}` };
	padding: 7px 18px 7px 7px;
	width: 100%;
	justify-content: space-between;
	border: ${({ activeStatus })=> activeStatus ? '2px var(--super-success-color) solid' : '' };
	:hover {
		background-color: ${({ theme })=> theme.darkPrimaryColor};
		cursor: pointer;
	}
`;

export const Circle = styled.div`
	background-color: ${({ theme, color }) => color === 'OK' ? 'var(--success-color)' : color === 'ATENCAO' ? 'var(--warning-color)' : color === 'CRITICO' ? 'var(--danger-color)' : color === 'NADA' ? theme.backgroundColor : theme.darkSecondaryColor};
	border-radius: 50%;
	height: 48px;
	width: 48px;
	padding: 12px;
`;

export const FeatherIcon = styled(Icon.Feather)`
	color: #fff !important;
`;

export const TitleFooterCards = styled(Text)`
	font-size: 16px;
	font-weight: 700;
	&.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
`;

export const NumberFooterCards = styled(Text)`
	font-size: ${({ font })=> font ? font : '16px'};
	font-weight: ${({ bold })=> bold ? '700' : '400'}};
	&.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
	opacity: ${({ lowOpacity })=> lowOpacity ? '0.5' : '1'};
`;