import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Dropdown, Menu, Tooltip, Icon } from '@components-teammove';

import { InfoUsuario, download } from '@utils';
import { postLido, postFavorito, postLidoSuccess, postFavoritoSuccess } from '@ducks/wikisAcoes';
import { changeVisibleAudiencia, changeVisibleConfirmacoes, changeVisibleInformacoes, changeVisibleNewFeedback } from '@ducks/comunicados';
import { getFeedback } from '@sdk/Wikis';

const FavoriteIcon = styled(Icon.Bootstrap)`
  color: ${({ type, theme }) => type.includes('fill') ? 'var(--warning-color)' : theme.textColor} !important;

  :hover {
    color: var(--warning-color) !important;
  }

  * {
    color: ${({ type, theme }) => type.includes('fill') ? 'var(--warning-color)' : theme.textColor} !important;

    :hover {
      color: var(--warning-color) !important;
    }
  }
`;

export default function VisualizacaoOpcoes({ item }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingItem, setLoadingItem] = useState(true);
  const [allowFeedback, setAllowFeedback] = useState(false);
  const [downloadArquivo, setDownloadArquivo] = useState(false);
  const [audiencia, setAudiencia] = useState(false);
  const [visualizaConfirmacaoLeitura, setVisualizaConfirmacaoLeitura] = useState(false);
  const [permiteAlterar, setPermiteAlterar] = useState(false);
  const [informacoes, setInformacoes] = useState(false);

  const iconSizeDefault = 0.7;

  const lido = useSelector(({ wikisAcoes }) => wikisAcoes.get('lido'));
  const loadingLido = useSelector(({ wikisAcoes }) => wikisAcoes.get('loadingLido'));
  const favorito = useSelector(({ wikisAcoes }) => wikisAcoes.get('favorito'));
  const loadingFavorito = useSelector(({ wikisAcoes }) => wikisAcoes.get('loadingFavorito'));

  useEffect(() => {
    if (item && item.size > 0) {
      const permissoes = item.get('permissoes');
      if (!permissoes) return;

      setDownloadArquivo(permissoes.download);

      const isComunicado = window.location.pathname.includes('/comunicado');
      const isConteudo = (item.get('tipo') !== 'GRUPO' || isComunicado);

      setAudiencia(InfoUsuario.perm('conteudosAudiencia') && isConteudo);
      setVisualizaConfirmacaoLeitura(InfoUsuario.perm('conteudosAudiencia') && permissoes.visualizaConfirmacaoLeitura);
      setAllowFeedback(permissoes.feedback);
      setPermiteAlterar(InfoUsuario.perm('comunicadosCadastro') && isComunicado);
      setInformacoes(InfoUsuario.perm('conteudosAudiencia') && isConteudo);

      dispatch(postLidoSuccess(item.get('lido')));
      dispatch(postFavoritoSuccess(item.get('favorito')));

      if (permissoes.feedback) {
        getFeedback(item.get('id'))
          .then((itemFeedbacksFromEveryone) => {
            const itemFeedbacksFromThisUser = itemFeedbacksFromEveryone.filter((item) => item.cdUsuario === InfoUsuario.get('cdUsuario'));
            setAllowFeedback(itemFeedbacksFromThisUser.length === 0);
          });
      }

      setLoadingItem(false);
    }
  }, [item]);

  return (
    !loadingItem && [
      <Tooltip key='fav' placement='top' title='Favorito'>
        <Button
          type='icon'
          icon={<FavoriteIcon type={favorito ? 'star-fill' : 'star'}/>}
          opacity={favorito ? 1 : null}
          disabled={loadingFavorito}
          onClick={() => {
            dispatch(postFavorito(item.get('id'), !favorito));
          }}
        />
      </Tooltip>,
      <Tooltip key='read' placement='top' title={lido ? 'Marcar como não lido' : 'Marcar como lido'}>
        <Button
          type='icon'
          icon={<Icon.MDI type={lido ? 'email-mark-as-unread' : 'email-open'} />}
          disabled={loadingLido}
          onClick={() => {
            dispatch(postLido(item.get('id'), !lido));
          }}
        />
      </Tooltip>,
      // prettier-ignore
      ...(audiencia || allowFeedback || downloadArquivo || visualizaConfirmacaoLeitura || permiteAlterar || informacoes
        ? [
          <Dropdown
            key="more"
            overlay={(
              <Menu>
                {allowFeedback && (
                  <Menu.Item onClick={() => dispatch(changeVisibleNewFeedback(true, item.get('id'), () => setAllowFeedback(false)))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="bullhorn" size={iconSizeDefault} />}
                    >
                      Feedback
                    </Button>
                  </Menu.Item>
                )}

                {audiencia && (
                  <Menu.Item onClick={() => dispatch(changeVisibleAudiencia(true, item.get('id'), item.get('titulo')))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="account-check" size={iconSizeDefault}/>}
                    >
                      Audiência
                    </Button>
                  </Menu.Item>
                )}

                {visualizaConfirmacaoLeitura && (
                  <Menu.Item onClick={() => dispatch(changeVisibleConfirmacoes(true, item.get('id'), item.get('titulo')))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="text-box-check-outline" size={iconSizeDefault}/>}
                    >
                      Confirmações de Leitura
                    </Button>
                  </Menu.Item>
                )}

                {downloadArquivo && (
                  <Menu.Item onClick={() => download(item.get('conteudo'))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="download" size={iconSizeDefault} />}
                    >
                      Download
                    </Button>
                  </Menu.Item>
                )}

                {permiteAlterar && (
                  <Menu.Item onClick={() => {
                    const objetoDuplicar = Object.fromEntries(item);
                    delete objetoDuplicar.id;
                    history.push('/comunicado/cadastro', objetoDuplicar);
                  }}
                  >
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="content-duplicate" size={iconSizeDefault} />}
                    >
                      Duplicar
                    </Button>
                  </Menu.Item>
                )}

                {permiteAlterar && (
                  <Menu.Item onClick={() => history.push('/comunicado/cadastro', Object.fromEntries(item))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="pencil" size={iconSizeDefault} />}
                    >
                      Editar
                    </Button>
                  </Menu.Item>
                )}

                {informacoes && (
                  <Menu.Item onClick={() => dispatch(changeVisibleInformacoes(true, item))}>
                    <Button
                      type="icon"
                      icon={<Icon.MDI type="information" size={iconSizeDefault}/>}
                    >
                      Informações
                    </Button>
                  </Menu.Item>
                )}
              </Menu>
            )
            }
          >
            <Button type="icon">
              <Icon.MDI type="dots-vertical" />
            </Button>
          </Dropdown>
        ]
        : []),
    ]
  );
}
