import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Map } from 'immutable';
import styled from 'styled-components';
import App from '@app';
import { Button, Body, PageHeader, Image, View, Form, Input, Spin, PopConfirm, List, PDFViewer, Text, Filter } from '@components';
import { Icon } from '@components-teammove';
import {  generatePreview, getCategoria, saveCategoria } from '@ducks/wikisPersonalizacao';
import CadastroForm from './Cadastro';
import { getConteudo } from '@ducks/wikis';
import { getNewId } from './rules';
import InfiniteScroller from '../../../components/InfiniteScroll';

const ListForm = styled(Form)`
  flex: 1;
  margin-right: 50px;
`;

const PreviewView = styled(View)`
  flex: 1;
  text-align: center;
  max-height: 100vh;
  overflow: scroll;
`;

const PreviewImg = styled(Image)`
  max-width: 50%;
  max-height: 50%;
  border-style: outset;
`;

const LabelItem = styled(Text)`
  flex: 1;
`;

const LabelPosition = styled(Text)`
  padding-right: 10px;
`;

export default function WikisPersonalizacao() {
  const { idCategoria } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [textoEdicao, setTextoEdicao] = useState(false);
  const [searchText, setSearchText] = useState('');
  const handleShowUserModal = (item = {}) => {
    setVisible(true);
    setTextoEdicao(item);
  };
  const handleHideUserModal = () => {
    setVisible(false);
    setTextoEdicao(false);
  };
  const conteudo = useSelector(({ wikis }) => wikis.get('conteudo'));
  const categoria = useSelector((state) => state.wikisPersonalizacao.get('categoria'));
  const success = useSelector((state) => state.wikisPersonalizacao.get('success'));
  const preview = useSelector((state) => state.wikisPersonalizacao.get('preview'));
  const loadingPreview = useSelector((state) => state.wikisPersonalizacao.get('loadingPreview'));
  const loadingSave = useSelector((state) => state.wikisPersonalizacao.get('loadingSave'));
  
  useEffect(() => {
    dispatch(getCategoria(idCategoria));
    dispatch(getConteudo(idCategoria));
  }, []);

  useEffect(() => {
    if (success) {
      form.setFieldsValue(categoria);
    }
  }, [success]);

  const getInitialValues = () => {
    const items = form.getFieldValue('textos') || [];
    return ({ id: getNewId(items), order: items.length });
  };

  const handlePreview = (usePreviewImage = false) => {
    form.validateFields().then(({ original, textos }) => {
      dispatch(generatePreview(original, textos, usePreviewImage));
    });
  };

  const handleValidade = () => {
    form.validateFields();
  };

  const handleRemoveTexto = (item) => {
    let textos = form.getFieldValue('textos') || [];
    textos = Map(textos.map((texto) => [texto.id, texto])).delete(item.id).toList().toArray();
    form.setFieldsValue({ textos });
    handleValidade();
    // handlePreview();
  };

  const handleCopyTexto = (item) => {
    const textos = form.getFieldValue('textos') || [];
    textos.push({ ...item, id: getNewId(textos), label: item.label + ' - Cópia' });
    form.setFieldsValue({ textos });
    handleValidade();
    // handlePreview();
  };

  const validateArray = (rule, value) => {
    if ((!value.textos) || (value.textos.length < 1)) {
      return Promise.reject('Informe ao menos um item');
    }
    return Promise.resolve();
  };
  
  const handleChangeOrder = (oldIndex, newIndex) => {
    const textos = form.getFieldValue('textos');  
    textos.splice(newIndex, 0, textos.splice(oldIndex, 1)[0]);
    form.setFieldsValue({ textos: textos.map((item, index) => ({ ...item, order: index })) });
  };

  const filterBySearchText = (list) => {
    if (!searchText) return list;

    return list.filter(({ label, texto }) => {
      return (
        label?.trim().toLowerCase().includes(searchText.trim().toLowerCase()) || 
        texto?.trim().toLowerCase().includes(searchText.trim().toLowerCase())
      );
    });
  };

  let previewButton = null;
  let previewElement = null;
  if (preview.compressed) {
    previewElement = (
      <PreviewImg src={preview.compressed} alt="Preview personalização da imagem" />
    );
    previewButton = (
      <Button key="external" icon={<Icon.ExpandAltOutlined />} target="_blank" href={preview.compressed}>Expandir</Button>
    );
  } else if (preview.outputPath) {
    previewElement = (
      <PDFViewer url={preview.outputPath} />
    );
    previewButton = (
      <Button key="external" icon={<Icon.ExpandAltOutlined />} target="_blank" href={preview.outputPath}>Expandir</Button>
    );
  }

  return (
    <App siderProps={{ width: 0 }}>
      <Body fixed>
        <PageHeader
          fixed
          title="Wiki / Personalização"
          extra={[
            previewButton,
            <Button key="1" icon={<Icon.EyeOutlined />} onClick={() => handlePreview(false)}>Preview</Button>,
            <Button key="2" icon={<Icon.EyeOutlined />} onClick={() => handlePreview(true)}>Preview (imagem)</Button>,
            <Button key="3" type="success" icon={<Icon.SaveOutlined />} onClick={() => form.submit()} loading={loadingSave}>Salvar</Button>,
          ]}
        >
          <View horizontal>
            <Form.Provider
              onFormFinish={(name, { values, forms }) => {
                if (name === 'textoForm') {
                  let textos = form.getFieldValue('textos') || [];
                  textos = Map(textos.map((texto, index) => [texto.id, { ...texto, order: index }])).set(values.id, values).toList().toArray();

                  form.setFieldsValue({ textos });
                  handleHideUserModal();
                } else if (name === 'mainForm') {
                  const { original, textos } = values;
                  dispatch(saveCategoria(idCategoria, original, textos));
                }
              }}
            >
              <ListForm layout="vertical" name="mainForm" form={form}>
                <Form.Item name="original" label="Caminho do arquivo" rules={[{ required: true, message: 'Informe o caminho do arquivo' }, { type: 'url', message: 'Caminho inválido!' }]}>
                  <Input onBlur={handlePreview} />
                </Form.Item>

                <Form.Item label="Itens" shouldUpdate={(prevValues, curValues) => prevValues.textos !== curValues.textos} rules={[{ validator: validateArray }]}>
                  {({ getFieldValue }) => {
                    const textos = getFieldValue('textos') || [];

                    return (
                      <>
                        {textos.length ? (
                          <>
                            <Filter onChange={(text) => setSearchText(text)} onSearch={() => null}/>
                            <div>
                              <InfiniteScroller
                                dataLength={textos.length}
                              >
                                <List
                                  dataSource={filterBySearchText(textos.sort(({ order: orderOne }, { order: orderTwo }) => orderOne - orderTwo))}
                                  renderItem={(item, index) => (
                                    <List.Item
                                      key={index}
                                      actions={[
                                        <Button key="moveUp" icon={<Icon.UpOutlined />} onClick={() => handleChangeOrder(index, index - 1)} disabled={index === 0} />,
                                        <Button key="moveDown" icon={<Icon.DownOutlined />} onClick={() => handleChangeOrder(index, index + 1)} disabled={index === textos.length - 1 } />,
                                        <Button key="copy" icon={<Icon.CopyOutlined />} onClick={() => handleCopyTexto(item)} />,
                                        <PopConfirm key="remove" title="Confirma a exclusão?" onConfirm={() => handleRemoveTexto(item)} okText="Sim" cancelText="Não">
                                          <Button icon={<Icon.DeleteOutlined />} />
                                        </PopConfirm>,
                                        <Button key="edit" type="primary" icon={<Icon.EditOutlined />} onClick={() => handleShowUserModal(item)} />,
                                      ]}
                                    >
                                      <LabelItem>{item.label || item.texto}</LabelItem>
                                      <LabelPosition>X: {item.posX}%</LabelPosition>
                                      <LabelPosition>Y: {item.posY}%</LabelPosition>
                                    </List.Item>
                                  )}
                                />
                              </InfiniteScroller>

                            </div>
                          </>
                        ) : null }
                        <Button type="dashed" onClick={() => handleShowUserModal(getInitialValues())}>
                          <Icon.PlusOutlined /> Adicionar novo item
                        </Button>
                      </>
                    );
                  }}
                </Form.Item>
              </ListForm>

              <CadastroForm visible={visible} onCancel={handleHideUserModal} textoEdicao={textoEdicao} conteudo={conteudo}/>
            </Form.Provider>

            <PreviewView>
              <Spin spinning={loadingPreview}>
                {previewElement}
              </Spin>
            </PreviewView>
          </View>
        </PageHeader>
      </Body>
    </App>
  );
}
