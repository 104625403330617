import styled from 'styled-components';
import { Checkbox, DatePicker, Button } from '@components';
import { ContentTitle, InputNumber, View, Form, Text, Select } from '@components-teammove';

export const FlexContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5.375rem;
`;

export const FlexRow = styled(View)`
  flex-direction: row;
  align-items: end;
  gap: 5.375rem;

  > *{
    flex: 1;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item-control{
    flex: 1;
  }

  .ant-form-item-required span {
    display: none;
  }

  .ant-form-item-required:after {
    display: none;
  }

  .ant-form-item-label {
    width: fit-content;
  }
  
  .ant-form-item-label:after {
    display: none;
  }
`;

export const MarginfulFormItem = styled(Form.Item)`
  margin-bottom: 1.875rem;
  .ant-form-item-label{
    padding: 0;
  }

  *:not(.ant-radio-button-wrapper){
    border-radius: 10px !important;
  }
`;

export const ContentSizeFormItem = styled(MarginfulFormItem)`
  width: fit-content;
`;

export const SmallFormItem = styled(MarginfulFormItem)`
  width: 200px;
`;

export const AverageFormItem = styled(MarginfulFormItem)`
  width: calc(50% - 43px);
`;

export const FlexFormItem = styled(MarginfulFormItem)`
  flex: 1;

  .ant-picker{
    width: 100% !important;
  }
`;

export const MarginBottom = styled(View)`
  margin-bottom: 1.875rem;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100% !important;
`;

export const Empty = styled.span`
  display: none;
`;

export const DynamicFieldImageContainer = styled(View)`
  width: fit-content;
  flex-direction: row;
  gap: 10px;
  > *{
    flex: 1;
  }
`;

export const MarginCheckbox = styled(Checkbox)`
  margin-left: 10px;
`;

export const SecondaryAddressesContainer = styled(MarginBottom)`
  width: calc(25%);
`;

export const SecondaryAddressInfo = styled(View)`
  cursor: pointer;
  padding: 10px;
  :hover{
    background-color: rgba(166, 166, 166, 0.2);
  }

  .ant-typography {
    color: #f5f5f5;
  }
`;

export const SecondaryAddressTitle = styled(Text)`
  &.ant-typography {
    font-size: 20px;
    font-weight: 600;
    color: #F5F5F5;
  }
`;

export const NewSecondaryAddressButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  height: auto;
  border: none !important;
  border-radius: 8px !important;

  *{
      border-radius: 8px !important;
  }

  ${({ type }) => type === 'success' && `
    background-color: #73B666 !important;
  `}
  padding: 14px;
  line-height: 20px;
  font-weight: 700;
  font-size: 14px; 
`;

export const ClusterColumns = styled.div`
  justify-content: space-between;
  width: 100%;
`;

export const ClusterColumnsTitle = styled.div`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const NumberInput = styled(InputNumber)`
  height: 20px;
`;

export const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
  margin-top: ${({ top }) => (top ? top : '40px')};
  margin-bottom: 20px;
`;

export const AddClusterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const SelectStyle = styled(Select)`
  height: 20px;
`;

export const Subtitle = styled(ContentTitle)`
  padding-bottom: 12px;
`;
