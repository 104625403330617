import styled from 'styled-components';
import { Icon, Row, View, Column, Filter } from '@components-teammove';

export const CalendarHeader = styled(Row)`
  display: flex;  
  justify-content: space-between;
  padding-bottom: 11px;
  
`;

export const CalendarYear = styled(View)`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  color: ${({ theme }) => theme.textColor};
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const CalendarViews = styled(View)`
  display: flex;
  flex-direction: row;
  gap: 9px
`;

export const CalendarViewTotal = styled(View)`
  border-radius: 8px;
  background-color: #118675;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.textColor};
  font-weight: 600;
  font-size: 12px

`;

export const CalendarViewApp = styled(CalendarViewTotal)`
  background-color: #FD8509;
`;

export const CalendarViewWeb = styled(CalendarViewTotal)`
  background-color: #118675;
`;

export const IconEye = styled(Icon.Feather)`
  margin-right: 4px;
`;

export const IconSmartphone = styled(Icon.Feather)`
  margin-right: 4px;
`;

export const IconCloud = styled(Icon.Feather)`
  margin-right: 4px;
`;

export const CalendarSkeleton = styled(View)`
  padding-top: 20px;
`;

export const CalendarColumn = styled(Column)`
  margin-bottom: 20px;
`;

export const FilterStyle = styled(Filter)`
    &.ant-modal {
    .ant-modal-body {
      :focus-within {
        height: 250px;
      }
    }
  }
`;

export const ViewFiltroDia = styled(View)`
  font-size: 12px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  width: fit-content;
  padding: 0 5px;
  border-radius: 5px;
`;

export const ViewFiltroDiaFechar = styled(View)`
  margin-left: 10px;
  cursor: pointer;
  padding: 0 5px;
`;
