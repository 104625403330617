import { moment, formatCurrency } from '@utils';
import { rgba } from 'polished';

export const formatDaysInLabels = (period, dashboard) => {
  let date = [];
  switch (period) {
    case 'DIARIO':
      date = dashboard.map((item) => moment.utc(item.date).hour());
      break;
    case 'SEMANAL':
      date = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
      break;
    case 'MENSAL':
      date = ['S1', 'S2', 'S3', 'S4', 'S5'];
      break;
    case 'TRIMESTRAL':
      date = dashboard.map((item) => moment.utc(item.date).format('MMM').toUpperCase());
      break;
    case 'SEMESTRAL':
      date = dashboard.map((item) => moment.utc(item.date).format('MMM').toUpperCase());
      break;
    case 'ANUAL': 
      date = ['T1', 'T2', 'T3', 'T4'];
      break;
    default:
      date = dashboard.map((item) => moment.utc(item.date).year());
      break;
  }
  return date;
};

export const getOptions = (typeValue) => {
  return {
    indexAxis: 'x', 
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      datalabels:{
        display: false,
      },
      tooltip: typeValue === 'value' ? {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatCurrency(context.parsed.y);
            }
            return label;
          }
        }
      } : {}
    },
    aspectRatio: 3,
    scales: {
      y:{
        stacked: true,
        beginAtZero: true,
        position: 'top',
        border:{
          color: rgba(255, 255, 255, 0.5),
        },
        ticks: {
          font: {
            weight: '400',
            size: 16,
          },
          color: rgba(255, 255, 255, 1),
        },
        grid:{
          color: rgba(255, 255, 255, 0.2),
        },
      },
      x:{
        stacked: true,
        beginAtZero: true,
        border:{
          color: rgba(255, 255, 255, 0.5),
        },
        ticks: {
          font: {
            weight: '400',
            size: 16,
          },
          color: rgba(255, 255, 255, 1),
        },
        grid: {
          display: false,
        },
      }
    }
  };
};

