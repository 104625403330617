import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Button, Form, Input } from '@components-teammove';
import { recuperarSenha, reset } from '@ducks/recuperarSenha';
import { BackIcon, ButtonItem, Content, ForgotPasswordCard } from './styles';

export default function RecuperarSenha({ history }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useSelector(({ app }) => app.get('theme'));
  const loading = useSelector(({ recuperarSenha }) => recuperarSenha.get('loading'));
  const success = useSelector(({ recuperarSenha }) => recuperarSenha.get('success'));

  useEffect(() => {
    if (success) {
      history.replace('/login');
    }

    return () => dispatch(reset());
  }, [success]);

  const handleSendEmail = ({ username }) => {
    dispatch(recuperarSenha(username));
  };

  return (
    <App bgColor={theme['@global-background-color']} hideSider hideHeader>
      <Content>
        <ForgotPasswordCard>
          <BackIcon onClick={() => history.goBack()} />
          <Form form={form} hideRequiredMark layout='vertical' onFinish={handleSendEmail} initialValues={location.state}>
            <Form.Item label='Informe o email que vocë deseja recuperar a sua senha' name='username' rules={[{ required: true, message: 'Informe o email!' }]}>
              <Input placeholder='Endereço de e-mail' autoFocus/>
            </Form.Item>

            <ButtonItem marginless>
              <Button type='primary' htmlType='submit' block loading={loading} fillWidth>
                    Enviar e-mail
              </Button>
            </ButtonItem>
          </Form>
        </ForgotPasswordCard>
      </Content>
    </App>
  );
}
