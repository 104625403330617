import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DrawerStyled = styled(Drawer)`
  @media(max-width: ${(props) => `${props.width}px`}) {
    div.ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;

DrawerStyled.propTypes = {
  width: PropTypes.number,
};

DrawerStyled.defaultProps = {
  width: 256,
};

export default DrawerStyled;
