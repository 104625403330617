import React from 'react';
import { View } from '@components';
import styled from 'styled-components';
import { formatDateTime, prettyTimeDifference, formatDecimal } from '@utils';
import { useDispatch } from 'react-redux';
import { Desistencia, estadosQuiz } from '../../rules';
import { postNovaTentativaSuccess, reset } from '@ducks/wikisEADQuiz';

const TaxaDeAcerto = styled(View)`
  font-size: 24px;
`;

const Resumo = styled(View)`
  margin-top: 15px;
  width: 80%;
  justify-content: center;
  align-items: center;
`;

export default function ResumoTaxaDeAcerto({ data, handlers }) {
  const { tentativa } = data;
  const { setEstadoAtual } = handlers;
  const dispatch = useDispatch();

  if (!tentativa) return <></>;

  const goToResultado = (id) => {
    dispatch(postNovaTentativaSuccess({ id }));
    dispatch(reset());
    setEstadoAtual(estadosQuiz.FINALIZADO);
  };

  return (
    <Resumo>
      {tentativa.desistencia ? (
        <View justifyContent='center' alignItems='center'>
          <Desistencia fontSize='18px' weight='normal'>
            Não finalizada
          </Desistencia>
          <View>Início: {formatDateTime(tentativa.dtInicio)}</View>
        </View>
      ) : (
        <View justifyContent='center' alignItems='center'>
          <TaxaDeAcerto horizontal>
            Taxa de Acerto:&nbsp;<strong>{formatDecimal((tentativa.nota / tentativa.total) * 100, 2)}%</strong>
          </TaxaDeAcerto>
          <View>Início: {formatDateTime(tentativa.dtInicio)}</View>

          <View>Fim: {formatDateTime(tentativa.dtFim)}</View>
          <View>Duração: {prettyTimeDifference(tentativa.dtInicio, tentativa.dtFim).extenseDifference}</View>
          <View horizontal>Tentativa: {tentativa.tentativa}</View>

          <View
            onClick={() => goToResultado(tentativa.id) /* eslint-disable-next-line*/}
            className='action-element'
            horizontal
            alignItems='center'
            fontSize='12px'
            fontWeight='bold'
          >
            Visualizar Resultado
          </View>
        </View>
      )}
    </Resumo>
  );
}
