export const getColorByPercentage = (percent, theme) => {
  if (percent < 50) {
    return 'var(--danger-color)';
  } else if (percent < 70) {
    return 'var(--warning-color)';
  } else if (percent < 90) {
    return 'var(--success-color)';
  } else {
    return theme['@global-action-color'];
  }
};