import React, { useEffect, useRef } from 'react';
import { Radio, Form } from '@components';
import styled from 'styled-components';

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

const RadioStyled = styled(Radio)`
  margin-bottom: 10px;
  white-space: initial;
`;

export default function MultiplaEscolha({ data }) {
  const radioRef = useRef(null);
  const { opcoes, isMultiplaEscolha } = data;

  useEffect(() => {
    if (isMultiplaEscolha) setTimeout(() => radioRef.current?.focus(), 1);
  }, [isMultiplaEscolha]);

  return (
    <Form.Item label='' name='radio'>
      <RadioGroup>
        {opcoes.map((opcao, index) => (
          <RadioStyled key={opcao.id} value={opcao.id} ref={index === 0 ? radioRef : null}>
            {opcao.opcao}
          </RadioStyled>
        ))}
      </RadioGroup>
    </Form.Item>
  );
}
