import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, OrderedMap, List } from 'immutable';
import { Notification } from '@utils';
import {
  getCategorias as getCategoriasSdk,
  getCategoryById as getCategoryByIdSdk,
  changeOrdemCategoria as changeOrdemCategoriaSdk,
  getAtendimentos as getAtendimentosSdk,
} from '@sdk/Chamados';

//Action Types
export const Types = {
  GET_CATEGORIAS: 'CONFIGURACOES_CHAMADOS/GET_CATEGORIAS',
  GET_CATEGORIAS_SUCCESS: 'CONFIGURACOES_CHAMADOS/GET_CATEGORIAS_SUCCESS',
  GET_CATEGORIAS_ERROR: 'CONFIGURACOES_CHAMADOS/GET_CATEGORIAS_ERROR',

  GET_CATEGORY_BY_ID: 'CONFIGURACOES_CHAMADOS/GET_CATEGORY_BY_ID',
  GET_CATEGORY_BY_ID_SUCCESS: 'CONFIGURACOES_CHAMADOS/GET_CATEGORY_BY_ID_SUCCESS',
  GET_CATEGORY_BY_ID_ERROR: 'CONFIGURACOES_CHAMADOS/GET_CATEGORY_BY_ID_ERROR',

  ORDER_CATEGORIAS: 'CONFIGURACOES_CHAMADOS/ORDER_CATEGORIAS',
  ORDER_CATEGORIAS_SUCCESS: 'CONFIGURACOES_CHAMADOS/ORDER_CATEGORIAS_SUCCESS',
  ORDER_CATEGORIAS_ERROR: 'CONFIGURACOES_CHAMADOS/ORDER_CATEGORIAS_ERROR',

  GET_ATENDIMENTOS: 'CONFIGURACOES_CHAMADOS/GET_ATENDIMENTOS',
  GET_ATENDIMENTOS_SUCCESS: 'CONFIGURACOES_CHAMADOS/GET_ATENDIMENTOS_SUCCESS',
  GET_ATENDIMENTOS_ERROR: 'CONFIGURACOES_CHAMADOS/GET_ATENDIMENTOS_ERROR',

  RESET_CATEGORY: 'CONFIGURACOES_CHAMADOS/RESET_CATEGORY',
};

//Action Creators
export const getCategorias = (somenteArvore) => ({ type: Types.GET_CATEGORIAS, somenteArvore });
export const getCategoriasSuccess = (categorias) => ({ type: Types.GET_CATEGORIAS_SUCCESS, categorias });
export const getCategoriasError = (error) => ({ type: Types.GET_CATEGORIAS_ERROR, error });

export const getCategoryById = (categoryId) => ({ type: Types.GET_CATEGORY_BY_ID, categoryId });
export const getCategoryByIdSuccess = (category) => ({ type: Types.GET_CATEGORY_BY_ID_SUCCESS, category });
export const getCategoryByIdError = () => ({ type: Types.GET_CATEGORY_BY_ID_ERROR });

export const orderCategoria = (id, idParent, position, movingInto) => ({ type: Types.ORDER_CATEGORIAS, id, idParent, position, movingInto });
export const orderCategoriaSuccess = (id, idParent, position, movingInto) => ({ type: Types.ORDER_CATEGORIAS_SUCCESS, id, idParent, position, movingInto });
export const orderCategoriaError = (error) => ({ type: Types.ORDER_CATEGORIAS_ERROR, error });

export const getAtendimentos = () => ({ type: Types.GET_ATENDIMENTOS });
export const getAtendimentosSuccess = (atendimentos) => ({ type: Types.GET_ATENDIMENTOS_SUCCESS, atendimentos });
export const getAtendimentosError = (error) => ({ type: Types.GET_ATENDIMENTOS_ERROR, error });

export const resetCategory = () => ({ type: Types.RESET_CATEGORY });
//saga
function* fetchGetCategorias(action) {
  try {
    const { somenteArvore } = action;
    
    const categorias = yield call(getCategoriasSdk, false, false, false, somenteArvore);
    yield put(getCategoriasSuccess(categorias));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriasError(err));
  }
}

function* fetchGetCategoryById(action) {
  try {
    const { categoryId } = action;
    
    const category = yield call(getCategoryByIdSdk, categoryId );
    yield put(getCategoryByIdSuccess(category));
  } catch (err) {
    Notification.error(err.message);
    yield put(orderCategoriaError(err));
  }
}

function* fetchOrderCategoria(action) {
  try {
    const { id, idParent, position, movingInto } = action;
    
    yield call(changeOrdemCategoriaSdk, id, idParent, position);
    yield put(orderCategoriaSuccess(id, idParent, position, movingInto));
  } catch (err) {
    Notification.error(err.message);
    yield put(orderCategoriaError(err));
  }
}

function* fetchGetAtendimento(action) {
  try {
    const atendimentos = yield call(getAtendimentosSdk);
    yield put(getAtendimentosSuccess(atendimentos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtendimentosError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CATEGORIAS, fetchGetCategorias),
  takeLatest(Types.GET_CATEGORY_BY_ID, fetchGetCategoryById),
  takeLatest(Types.ORDER_CATEGORIAS, fetchOrderCategoria),
  takeLatest(Types.GET_ATENDIMENTOS, fetchGetAtendimento),
];

// Reducer
const initialState = Map({
  categorias: OrderedMap(),
  categoriesForOptions: List(),
  loadingCategorias: false,
  successCategorias: false,
  errorCategorias: false,

  category: List(),
  successCategory: false,
  loadingCategory: false,
  errorCategory: false,

  atendimentos: List(),
  loadingAtendimento: false,
  successAtendimento: false,
  errorAtendimento: false,

  loadingOrderCategorias:false,
  successOrderCategorias:false
});

const handleGetCategorias = (state, action) => {
  return state
    .set('loadingCategorias', true)
    .set('successCategorias', false)
    .set('errorCategorias', false);
};

const handleGetCategoriasSuccess = (state, action) => {
  const { categorias } = action;

  return state
    .set('categoriesForOptions', categorias)
    .set('categorias', OrderedMap(categorias.map((cat) => [cat.id, cat])))
    .set('loadingCategorias', false)
    .set('successCategorias', true)
    .set('errorCategorias', false);
};

const handleGetCategoriasError = (state, action) => {
  return state
    .set('loadingCategorias', false)
    .set('successCategorias', false)
    .set('errorCategorias', action.error);
};

const handleGetCategoryById = (state, action) => {
  return state
    .set('loadingCategory', true)
    .set('successCategory', false)
    .set('errorCategory', false);
};

const handleGetCategoryByIdSuccess = (state, action) => {
  const { category } = action;

  return state
    .set('category', category)
    .set('loadingCategory', false)
    .set('successCategory', true)
    .set('errorCategory', false);
};

const handleGetCategoryByIdError = (state, action) => {
  return state
    .set('loadingCategory', false)
    .set('successCategory', false)
    .set('errorCategory', action.error);
};

const handleOrderCategorias = (state, action) => {
  const { id, idParent, position, movingInto } = action;

  let categorias = state.get('categorias');
  let categoriasForOrder = [];
  let orderedMapFromArray = OrderedMap();
  let removedObjectFromArray = [];

  const objectMoved = categorias.find((item) => item.id === id);
  const isForSameCategory = objectMoved.idCategoria === idParent;

  const categoryMoved = categorias.find((cat) => cat.id === id);
  if (categoryMoved) categoryMoved.idCategoria = idParent;

  let objectWithoutCategoryMoved = categorias.filter((cat)=> cat.id !== id).toList().toArray();
  objectWithoutCategoryMoved.push(categoryMoved);

  function ordenarArray(array, idObjeto, novaPosicao) {
    const indexObjeto = array.findIndex((objeto) => objeto.id === idObjeto);

    if (indexObjeto === -1) {
      return;
    }

    const objetoRemovido = array.splice(indexObjeto, 1)[0];

    array.splice(novaPosicao, 0, objetoRemovido);

    array.forEach((objeto, index) => {
      objeto.ordem = index;
    });
  }
  
  if(movingInto) {
    removedObjectFromArray =
    objectWithoutCategoryMoved
      .filter((item) => item.idCategoria !== idParent );

    categoriasForOrder = categorias
      .filter((cat) => (cat.idCategoria === idParent))
      .sortBy((cat) => cat.ordem)
      .toList()
      .toArray();
    categoriasForOrder = categoriasForOrder.map((item, index) => ({
      ...item,
      ordem: index,
    }));
    
  }else if(idParent && isForSameCategory) {
    categoriasForOrder = categorias
      .filter((cat) => (cat.idCategoria === idParent))
      .sortBy((cat) => cat.ordem)
      .toList()
      .toArray();
    categoriasForOrder = categoriasForOrder.map((item, index) => ({
      ...item,
      ordem: index,
    }));
    ordenarArray(categoriasForOrder, id, position);
    
    removedObjectFromArray =
      categorias
        .toList()
        .toArray()
        .filter((item) => item.idCategoria !== idParent );

  }else if(!isForSameCategory && !idParent) {
    if (categoryMoved) {
      delete categoryMoved.idCategoria;
    }

    categoriasForOrder = objectWithoutCategoryMoved.filter((item)=> !item.idCategoria);
    ordenarArray(categoriasForOrder, id, position);

    removedObjectFromArray =
      categorias
        .toList()
        .toArray()
        .filter((item) => item.idCategoria);

  }else if(!idParent) {
    categoriasForOrder = categorias.sortBy((cat) => cat.ordem).toList().toArray().filter((item)=> !item.idCategoria);
    ordenarArray(categoriasForOrder, id, position);
    
    removedObjectFromArray =
      categorias
        .toList()
        .toArray()
        .filter((item) => item.idCategoria);

  }else if(!isForSameCategory) {
    removedObjectFromArray = objectWithoutCategoryMoved.filter((item) => item.idCategoria !== idParent );

    categoriasForOrder = categorias
      .filter((cat) => (cat.idCategoria === idParent))
      .sortBy((cat) => cat.ordem)
      .toList()
      .toArray();
    categoriasForOrder = categoriasForOrder.map((item, index) => ({
      ...item,
      ordem: index,
    }));
    ordenarArray(categoriasForOrder, id, position);
    
  }
  const newArray = [...removedObjectFromArray, ...categoriasForOrder];
  orderedMapFromArray = OrderedMap(newArray.map((cat) => [cat.id, cat]));

  return state
    .set('categorias', orderedMapFromArray)
    .set('loadingOrderCategorias', true)
    .set('successOrderCategorias', false);
};

const handleOrderCategoriaSuccess = (state, action) => {  
  return state
    .set('loadingOrderCategorias', false)
    .set('successOrderCategorias', true);
};

const handleGetAtendimento = (state, action) => {
  return state
    .set('loadingAtendimento', true)
    .set('successAtendimento', false)
    .set('errorAtendimento', false);
};

const handleGetAtendimentoSuccess = (state, action) => {
  const { atendimentos } = action;

  return state
    .set('atendimentos', List(atendimentos))
    .set('loadingAtendimento', false)
    .set('successAtendimento', true)
    .set('errorAtendimento', false);
};

const handleGetAtendimentoError = (state, action) => {
  return state
    .set('loadingAtendimento', false)
    .set('successAtendimento', false)
    .set('errorAtendimento', action.error);
};

const handleResetGetByID = (state, action) => {
  return state
    .set('category', List() )
    .set('loadingCategory', false)
    .set('successCategory', false)
    .set('errorCategory', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CATEGORIAS: return handleGetCategorias(state, action);
    case Types.GET_CATEGORIAS_SUCCESS: return handleGetCategoriasSuccess(state, action);
    case Types.GET_CATEGORIAS_ERROR: return handleGetCategoriasError(state, action);

    case Types.GET_CATEGORY_BY_ID: return handleGetCategoryById(state, action);
    case Types.GET_CATEGORY_BY_ID_SUCCESS: return handleGetCategoryByIdSuccess(state, action);
    case Types.GET_CATEGORY_BY_ID_ERROR: return handleGetCategoryByIdError(state, action);
    
    case Types.ORDER_CATEGORIAS: return handleOrderCategorias(state, action);
    case Types.ORDER_CATEGORIAS_SUCCESS: return handleOrderCategoriaSuccess(state, action);

    case Types.GET_ATENDIMENTOS: return handleGetAtendimento(state, action);
    case Types.GET_ATENDIMENTOS_SUCCESS: return handleGetAtendimentoSuccess(state, action);
    case Types.GET_ATENDIMENTOS_ERROR: return handleGetAtendimentoError(state, action);
    
    case Types.RESET_CATEGORY: return handleResetGetByID(state, action);
    default: return state;
  }
}
