import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, Input, DatePicker } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from '../../styles';
import { IconWrapper, ActivityIcon, ActivityName } from '../../../../Cadastro/styles';
import { moment } from '@utils';
import { resetSaveSuccesses, updateChecklistFinishPreview, deleteChecklistActionPlan , getChecklistFinishPreview } from '@ducks/checklists';
import { useParams } from 'react-router-dom';

export default function ActionPlanRegister({ visible, onCancel, actionPlan }) {
  const { idChecklist } = useParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const preview = useSelector(({ checklists }) => checklists.get('checklistFinishPreview'));
  const successPreview = useSelector(({ checklists }) => checklists.get('successChecklistFinishPreview'));
  const loadingSavePreview = useSelector(({ checklists }) => checklists.get('loadingSaveChecklistFinishPreview'));
  const loadingDelete = useSelector(({ checklists }) => checklists.get('loadingDeleteChecklistActionPlan'));
  const successDelete = useSelector(({ checklists }) => checklists.get('successDeleteChecklistActionPlan'));
  
  const [currentActivityType, setCurrentActivityType] = useState();

  useEffect(() => {
    if (successPreview) {
      if (!actionPlan && preview.activityTypes.length > 0) {
        setCurrentActivityType(preview.activityTypes[0]);
      }
      if (actionPlan) {
        form.setFieldsValue({ ...actionPlan, date: moment(actionPlan.date) });
        setCurrentActivityType(preview.activityTypes.find(({ id }) => id === actionPlan.idTypeActivity));
      }
    }
  }, [successPreview]);

  useEffect(() => {
    return () => {
      form.resetFields();
      dispatch(resetSaveSuccesses());
    };
  }, []);

  useEffect(() => {
    if (successDelete) {
      dispatch(getChecklistFinishPreview(idChecklist));
      onCancel();
    }
  }, [successDelete]);

  const renderActivityTypeOption = (activityType) => (
    <Row gap='15px' align='center'>
      <IconWrapper color={activityType.color}>
        <ActivityIcon type={activityType.icon}/>
      </IconWrapper>
      <ActivityName>{activityType.name}</ActivityName>
    </Row>
  );

  const handleDeleteActionPlan = (idActionPlanActivity) => {
    dispatch(deleteChecklistActionPlan(idActionPlanActivity));
  };

  const onBeforeCancel = () => {
    return form.isFieldsTouched() || Object.keys(form.getFieldsValue()).some((key) => form.getFieldValue(key));
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      values = {
        ...values, 
        title: values.title || currentActivityType.name,
        idTypeActivity: currentActivityType.id, 
        date: new Date(values.date?.valueOf()), 
      };

      const previewToSend = {
        ...preview, 
        actionActivitiesChecklist: actionPlan ? 
          preview.actionActivitiesChecklist.map((actP) => actP.id === actionPlan.id ? { ...actionPlan, ...values } : { ...actP }) :
          [...preview.actionActivitiesChecklist, { ...values }]
      };
      
      delete previewToSend.usersList;
      delete previewToSend.activityTypes;

      dispatch(updateChecklistFinishPreview(idChecklist, previewToSend));
    }).catch(Form.scrollToFirstError);
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={preview?.strategicActionAlias || 'Ação estratégica'}
      onBeforeCancel={onBeforeCancel}
      onOk={handleOk}
      okButtonProps={{ loading: loadingSavePreview }}
      cancelButtonProps={actionPlan && { type: 'danger', transparent: true, bordered: true, coloredContent: true, loading: loadingDelete, onClick: null }}
      cancelText={actionPlan ? 'Excluir' : 'Cancelar'}
      cancelButtonConfirm={!!actionPlan}
      cancelButtonConfirmProps={actionPlan && { title: 'Deseja realmente excluir este plano de ação?', okText: 'Sim', cancelText: 'Não', onConfirm: () => handleDeleteActionPlan(actionPlan.id) }}
    >
      <Form form={form} layout='vertical'>
        <Form.Item label='Tipo de atividade'>
          <Select 
            placeholder='Tipo de atividade' 
            options={preview.activityTypes?.map((activityType) => ({ label: renderActivityTypeOption(activityType), value: activityType.id }))}
            onChange={(newType) => setCurrentActivityType(preview.activityTypes.find(({ id }) => id === newType))}
            value={currentActivityType?.id}
            fitContent
            // disabled={success && !activity?.allowToEdit}
          />
        </Form.Item>
        {preview?.strategicActionFields?.includes('OBJETIVO') && (
          <Form.Item name='title' label='Objetivo'>
            <Input placeholder={currentActivityType?.name}/>
          </Form.Item>
        )}
        <Row gap='12px'>
          {preview?.strategicActionFields?.includes('RESPONSAVEL') && (
            <Form.Item label='Quem' name='responsible'>
              <Select placeholder='Quem fará' options={preview?.usersList?.map(({ name, id }) => ({ value: name, label: name }))}/>
            </Form.Item>
          )}
          {preview?.strategicActionFields?.includes('QUANDO') && (
            <Form.Item label='Quando' name='date' rules={[{ required: true, message: 'Insira uma data' }]}>
              <DatePicker placeholder='Data e hora' format='DD/MM/YYYY HH:mm' showTime/>
            </Form.Item>
          )}
        </Row>
        {preview?.strategicActionFields?.includes('COMO') && (
          <Form.Item label='Como' name='observation'>
            <Input.TextArea placeholder='Como será feito'/>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}