import * as Containers from '@containers';
import { Icon } from '@components-teammove';
import { InfoUsuario } from '@utils';

export default () => {
  return InfoUsuario.perm('conteudosAudiencia') ? [
    {
      path: '/conteudos',
      icon: Icon.ProfileOutlined,
      text: 'Conteúdos',
      match: /^\/conteudos?/,
      exact: true,
      sider: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
    },
    {
      path: '/conteudos/visualizacao',
      parent: '/conteudos',
      match: /^\/conteudos\/visualizacao?/,
      icon: Icon.ProfileOutlined,
      component: Containers.WikisConteudos,
      text: 'Visualização',
      exact: true,
      sider: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
    },
    {
      path: '/conteudos/visualizacao/:id',
      match: /^\/conteudo\/?/,
      component: Containers.WikiVisualizacao,
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
    },
    {
      path: '/conteudos/audiencia/dashboard',
      parent: '/conteudos',
      text: 'Audiência',
      component: Containers.WikisAudiencia,
      match: /\/conteudos\/audiencia\/dashboard+$/,
      sider: true,
      exact: true,
      secret: true,
    },
    {
      path: '/conteudos/audiencia/acesso/categorias',
      match: /\/conteudos\/audiencia\/dashboard+$/,
      component: Containers.WikisAudienciaCategorias,
      exact: true,
    },
    {
      path: '/conteudos/audiencia/acesso/categorias/:id',
      match: /\/conteudos\/audiencia\/dashboard+$/,
      component: Containers.WikisAudienciaRankingCategoria,
      exact: true,
    }
  ] : [
    {
      path: '/conteudos/visualizacao',
      icon: Icon.ProfileOutlined,
      text: 'Conteúdos',
      match: /^\/conteudos\/visualizacao?/,
      component: Containers.WikisConteudos,
      exact: true,
      sider: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
    },
    {
      path: '/conteudos/visualizacao/:id',
      match: /^\/conteudo\/?/,
      component: Containers.WikiVisualizacao,
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
    }
  ];
};