import styled from 'styled-components';
import { Text, Column } from '@components-teammove';

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ status }) => status === 'OK' ? 'var(--success-color)' : status === 'ATENCAO' ? 'var(--warning-color)' : status === 'CRITICO' ? 'var(--danger-color)' : 'var(--success-color)'};
`;

export const TextStyle = styled(Text)`
  font-size: 14px;
  font-weight: ${({ weight })=> weight ? weight : '400'};
`;

export const ContentTitle = styled(Column)`
  padding: 8px 10px 8px 10px;
`;