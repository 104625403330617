import React from 'react';

import { Icon, Text, View, Tooltip } from '@components-teammove';

import { formatDate } from '@utils';

import { StatusTag, TextStyle } from './styles';

import { STATUS_FECHADO } from '../rules';
import { getColor, getLabelStatus, getText } from './rules';

const Status = ({ chamado, texto }) => {

  const color = getColor(chamado);
  const text = getText(chamado, texto);
  return( 
    <StatusTag align='center' gap='4px' status={color}>
      <TextStyle font='12px' weight='600'>{text}</TextStyle>
      {chamado.statusFechado && (
        <Tooltip title={<TooltipStatus chamado={chamado}/>}>
          {chamado.statusFechado === STATUS_FECHADO.NO_PRAZO ? <Icon.LikeOutlined /> : <Icon.DislikeOutlined />}
        </Tooltip>
      )}
    </StatusTag>
  );};

const TooltipStatus = ({ chamado }) => {
  const { statusFechado, statusFechadoPeriodo, prazo, dtManutencaoStatus } = chamado;
  
  return (
    <View>
      <Text>Prazo: {formatDate(prazo)}</Text>
      <Text>Fechado: {formatDate(dtManutencaoStatus)}</Text>
      <Text>{getLabelStatus(statusFechado, statusFechadoPeriodo)}</Text>
    </View>  
  );
};
export default Status;