import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Row, Text, Column, Divider } from '@components-teammove';
import { ButtonStyle, Container, StyleEmpty  } from './styles';

import { TelasNomenclaturas } from '@utils';

import { getCompaniesRanking } from '@ducks/chamadosDashboard';
import CompaniesRakingTable from './CompaniesRankingDetails/CompaniesRankingTable';

export default function CompaniesRanking({ currentFilters, currentParams }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const companiesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('companiesRanking'));
  const loadingCompaniesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingCompaniesRanking'));
  const successCompaniesRanking = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successCompaniesRanking'));

  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getCompaniesRanking({ ...currentFilters, ...currentParams }, { limit:10 }));
    }
  }, [currentFilters, currentFilters]);

  return(
    loadingCompaniesRanking ? (
      <Skeleton.Map/>
    ) : successCompaniesRanking && (
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Raking de </Text>
            <Text size='20px' weight='800' line='20px'>{companyNomenclature.plural}</Text>
          </Column>
          <ButtonStyle size='small' context='header' key="1" type='tertiary' onClick={() => history.push('/chamados/dashboard-chamados/ranking-companies-details')}>
              Ver Todos
          </ButtonStyle>
        </Row>
        <Divider/>
        {companiesRanking.companyRanking.length < 1 ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <CompaniesRakingTable 
              resum
              companiesTotal={companiesRanking.totalRanking} 
              companies={companiesRanking.companyRanking} 
              loadingCompanies={loadingCompaniesRanking} 
              successCompanies={successCompaniesRanking}
            />
          )}
        
      </Container>
    )
  );
}