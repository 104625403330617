import React, { useEffect } from 'react';
import { Row, Button, Table, Column } from '@components-teammove';
import PedidosCadastro from './Cadastro';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersKinds } from '@ducks/settingsOrdersOrdersKinds';

export default function TipoDePedidos({ visibleOrderKindModal, setVisibleOrderKindModal, }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const ordersKinds = useSelector(({ ordersKinds }) => ordersKinds.get('ordersKinds'));
  const loadingOrdersKinds = useSelector(({ ordersKinds }) => ordersKinds.get('loadingOrdersKinds'));

  const successOrderKindSave = useSelector(({ ordersKinds }) => ordersKinds.get('successOrderKindSave'));

  useEffect(()=>{
    dispatch(getOrdersKinds());
  },[]); 
  
  useEffect(()=>{
    if(successOrderKindSave) {
      dispatch(getOrdersKinds());
    }
  },[successOrderKindSave]);

  const handleButtonClick = (tipo) => {
    if(tipo) {
      history.push('?tipo=' + tipo.id, { tipoPedido: tipo });
    }
    setVisibleOrderKindModal(true);
  };
  
  const columns = [
    {
      title:'Tipo do pedido',
      dataIndex:'distributorName',
      sorter:true,
      render:(text)=>(
        <Row justify='space-between' align='center'>
          {text}
          <Button
            type='secondary'
            size='small'
            context='list'
          >
            Editar
          </Button>
        </Row>
      )
    }
  ];

  return(
    <Column>
      <Table
        columns={columns}
        dataSource={ordersKinds}
        loading={loadingOrdersKinds}
        rowKey='id'
        onRow={(tipo) => ({ onClick: () => handleButtonClick(tipo) })}
      />
      {visibleOrderKindModal && (
        <PedidosCadastro
          visible={visibleOrderKindModal}
          onCancel={() => setVisibleOrderKindModal(false)}
        />
      )}
    </Column>
  );
}