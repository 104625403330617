export const getIconNameAlias = (icon) => {
  switch (icon) {
    case 'format-list-checks':
      return 'Lista';
    case 'cached':
      return 'Atualização';
    case 'phone':
      return 'Telefone';
    case 'numeric':
      return 'Numérico';
    case 'chart-line':
      return 'Gráfico';
    case 'calendar-check':
      return 'Calendário';
    case 'airplane':
      return 'Avião';
    case 'bank':
      return 'Banco';
    case 'cake-variant':
      return 'Bolo';
    case 'camera':
      return 'Câmera';
    case 'truck':
      return 'Caminhão';
    case 'cart':
      return 'Carrinho de compras';
    case 'car':
      return 'Carro';
    case 'home':
      return 'Casa';
    case 'shopping':
      return 'Sacola';
    case 'heart':
      return 'Coração';
    case 'school':
      return 'Formatura';
    case 'star':
      return 'Estrela';
    case 'wrench':
      return 'Ferramenta';
    case 'account-multiple':
      return 'Pessoas';
    case 'silverware':
      return 'Talheres';
    case 'store':
      return 'Loja';
    case 'laptop-mac':
      return 'Laptop';
    case 'file-document':
      return 'Documento';
    case 'domain':
      return 'Prédio';
    case 'wallet-giftcard':
      return 'Vale-compras';
    case 'sofa':
      return 'Sofá';
    case 'briefcase':
      return 'Mala';
    default: return '';
  }
};