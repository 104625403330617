import { TreeSelect } from 'antd';
import Icon from '../Icon';
import styled from 'styled-components';

export const StyledTreeSelect = styled(TreeSelect)`
  width: 600px !important;
  max-width: 100%;
  ${({ disabled }) => disabled && `
      opacity: .5;
    `
  }
  margin: ${(props) => props.margin ? props.margin : '0px'} !important;
  background-color: ${(props) => props.theme.darkPrimaryColor} !important;
  border: 2px solid ${(props) => props.theme.darkHighlightColor} !important;
  border-radius: 8px !important;
  width: 100% !important;
  
  :focus-within {
    border: 2px solid ${(props) => props.theme.actionColor} !important;
  }

  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.textColor} !important;
    opacity: 50%;
    left: auto;
    right: auto;
  }

  .ant-select-selector {
    ${({ disabled }) => 
    disabled && `
    opacity: .5;
    cursor: not-allowed !important;
  `
}
    padding: 14px !important;
    height: fit-content !important;
    border: none !important;
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      background-color: ${(props) => props.theme.darkPrimaryColor};
      border: none;
      display: flex;
      align-items: center;

      .ant-select-selection-item-content {
        padding: 4px;
      }

      .ant-select-selection-item-remove {
        color: ${(props) => props.theme.textColor};
      }
    }
  }

  .ant-select-arrow {	
		color: ${(props) => props.theme.textColor};
    width: fit-content;
    height: fit-content;
    top: 40%;
    opacity: .5;
    transition: all .25s;
    ${({ value }) => value?.length > 0 && `
      pointer-events: auto;
    `}
	}
`;

export const DropdownStyles = styled.div`
  .ant-select-tree {
    background-color: ${(props) => props.theme.darkPrimaryColor};

    .ant-select-tree-treenode {
      :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
        padding-bottom: 4px;
      }
      padding-top: 4px;
    }

    .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
      background-color: ${({ theme }) => theme.actionColor};
    }

    .ant-select-tree-node-content-wrapper:hover {
      background-color: ${(props) => props.theme.actionColor} !important;
      color: ${(props) => props.theme.textColor} !important;
    }

    .ant-select-tree-checkbox {
      margin: 0;
      margin-right: 8px;

      .ant-select-tree-checkbox-inner {
        background: ${(props) => props.theme.darkPrimaryColor};
        border: 1px solid ${(props) => props.theme.darkHighlightColor};
        border-radius: 8px !important;
        width: 24px;
        height: 24px;
        text-align: center;
        color: ${(props) => props.theme.actionColor} !important;

        ::after {
          color: ${(props) => props.theme.actionColor} !important;
        }
      }
    }
  }

  .ant-select-item-option {
    background-color: ${(props) => props.theme.darkPrimaryColor};
    color: ${(props) => props.theme.textColor};
    font-size: 16px;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props.theme.actionColor};
  }

  .ant-select-item-option-selected {
    background-color: ${(props) => props.theme.actionColor} !important;
    color: ${(props) => props.theme.textColor} !important;
  }
`;

export const DropdownContainerStyles = styled.div`
  .ant-select-dropdown {
    background-color: ${(props) => props.theme.darkPrimaryColor};
    border: 1px solid ${({ theme }) => theme.darkHighlightColor};
    padding: 0;
    outline: 2px solid ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  opacity: .5;
`;

export const LoadingIcon = styled(Icon.LoadingOutlined)`
  font-size: 24;
`;