import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { getCampaign as getCampaignSdk } from '@sdk/Campanhas';

//Action Types
export const Types = {
  GET_CAMPANHA: 'CAMPANHA/GET_CAMPANHA',
  GET_CAMPANHA_SUCCESS: 'CAMPANHA/GET_CAMPANHA_SUCCESS',
  GET_CAMPANHA_ERROR: 'CAMPANHA/GET_CAMPANHA_ERROR',

  RESET_CAMPANHA: 'CAMPANHA/RESET_CAMPANHA',
};

//Action Creators
export const getCampaign = (externalId, userId, idApi) => ({ type: Types.GET_CAMPANHA, externalId, userId, idApi });
export const getCampaignSuccess = (data) => ({ type: Types.GET_CAMPANHA_SUCCESS, data });
export const getCampaignError = (error) => ({ type: Types.GET_CAMPANHA_ERROR, error });

export const resetCampaign = () => ({ type: Types.RESET_CAMPANHA });

//saga
function* fetchCampaignSaga(action) {
  try {
    const { externalId, userId, idApi } = action;
    const data = yield call(getCampaignSdk, externalId, userId, idApi);
    const { campaign, roles, relatedCampaigns } = data;
    yield put(getCampaignSuccess({ ...campaign, roles, relatedCampaigns }));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCampaignError(err));
  }
}

export const saga = [takeLatest(Types.GET_CAMPANHA, fetchCampaignSaga)];

// Reducer
const initialState = Map({
  campaign: null,
  loading: false,
  success: false,
  error: false,
});

const handleGetCampaign = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetCampaignSuccess = (state, action) => {
  return state.set('campaign', action.data).set('loading', false).set('success', true).set('error', false);
};

const handleGetCampaignError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case Types.GET_CAMPANHA:
      return handleGetCampaign(state, action);
    case Types.GET_CAMPANHA_SUCCESS:
      return handleGetCampaignSuccess(state, action);
    case Types.GET_CAMPANHA_ERROR:
      return handleGetCampaignError(state, action);

    case Types.RESET_CAMPANHA:
      return initialState;
    
    default: return state;
  }
}