import React from 'react';
import { Text, Row } from '@components-teammove';
import { ContentNumber, Divider } from './styles';

export const StepProgress = ({ currentStep, loadingUpload, visibleModals, onCancel }) => {

  return (
    <Row gap='8px'>
      <Row gap='8px' align='center'>
        <ContentNumber id={1} active={currentStep >= 1}><Text size='16px' weight='400'>1</Text></ContentNumber>
        <Text size='16px' weight='400'>Upload da planilha</Text>
        <Divider/>
      </Row>

      <Row gap='8px' align='center'>
        <ContentNumber id={2} active={currentStep >= 2}><Text size='16px' weight='400'>2</Text></ContentNumber>
        <Text size='16px' weight='400'>Relacionar colunas</Text>
        <Divider/>
      </Row>

      <Row gap='8px' align='center'>
        <ContentNumber id={3} active={currentStep >= 3}><Text size='16px' weight='400'>3</Text></ContentNumber>
        <Text size='16px' weight='400'>Validação dos dados</Text>
        <Divider/>
      </Row>

      <Row gap='8px' align='center'>
        <ContentNumber id={4} active={currentStep === 4}><Text size='16px' weight='400'>4</Text></ContentNumber>
        <Text size='16px' weight='400'>Importação de unidades</Text>
      </Row>

    </Row>

  );

};