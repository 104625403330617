import React, { useState } from 'react';
import Atributos from './Atributos';
import Afastamentos from './Afastamentos';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Tabs, Button } from '@components-teammove';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AfastamentoCadastro from './Afastamentos/Cadastro';
import AtributoCadastro from './Atributos/Cadastro';
import IdApiDuplicados from './IdApiDuplicados';
import { Session } from '@utils';

export default function ConfiguracoesUsuarios() {
  const theme = useSelector(({ app }) => app.get('theme'));

  const history = useHistory();
  
  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_USERS_TAB') || 'Atributos');
  const [visibleRegisterAfastamento, setVisibleRegisterAfastamento] = useState(false);
  const [visibleRegisterAtributo, setVisibleRegisterAtributo] = useState(false);

  const handleButtonClick = currentTab === 'Atributos' ? (
    (data) => {
      if (data) {
        history.push('?atributo=' + data.id, data);
      }
      setVisibleRegisterAtributo(true);
    }
  ) : (
    (data) => {
      if (data) {
        history.push('?usuario=' + data.id, data);
      }
      setVisibleRegisterAfastamento(true);
    }
  );
  
  const ExtraAtributo = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => handleButtonClick()}>Novo</Button>,
  ];
  
  const ExtraAfastamento = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => handleButtonClick()}>
      Novo
    </Button>,
  ];

  const switchBodyByTab = () => {
    switch (currentTab) {
      case 'Atributos':
        return <Atributos handleEditClick={handleButtonClick}/>;
      case 'Afastamentos':
        return <Afastamentos handleEditClick={handleButtonClick}/>;
      case 'Id api duplicado':
        return <IdApiDuplicados />;
      default: return null;
    }
  };

  const switchExtraByTab = () => {
    switch(currentTab) {
      case 'Atributos':
        return <ExtraAtributo/>;
      case 'Afastamentos':
        return <ExtraAfastamento/>;
      default: return null;
    }
  };

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_USERS_TAB', newTab);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Usuários'
          extra={ switchExtraByTab()}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/usuarios', name: 'Configurações de Usuários', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Atributos' }, { name: 'Afastamentos' }, { name: 'Id api duplicado' }]} current={currentTab} onChange={handleChangeTab}/>
            {switchBodyByTab()}
          </Column>

          {visibleRegisterAfastamento && (
            <AfastamentoCadastro
              visible={visibleRegisterAfastamento}
              onCancel={() => setVisibleRegisterAfastamento(false)}
            />
          )}
          {visibleRegisterAtributo && (
            <AtributoCadastro
              visible={visibleRegisterAtributo}
              onCancel={() => setVisibleRegisterAtributo(false)}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
