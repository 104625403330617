import { dateToAnsi, DATE_FORMATS } from '@utils';

export const getQueryParamsAtividades = (params) => {
  if (!params) return '';
  const query = [];
  const { period, search, status, typesActivities, users, typesCompanies, createdByMyself, companies, limit, offset, orderBy, orderDirection } = params;

  if ((period) && (period.length > 0)) {
    query.push(`start=${dateToAnsi(period[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`end=${dateToAnsi(period[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  if ((search) && (search.length > 0) && (search.trim().length > 0)) query.push(`search=${search}`);
  if ((status) && (status.length > 0)) query.push(`status=${status.join('+')}`);
  if ((typesActivities) && (typesActivities.length > 0)) query.push(`typesActivities=${typesActivities.join('+')}`);
  if ((users) && (users.length > 0)) query.push(`users=${users.join('+')}`);
  if ((typesCompanies) && (typesCompanies.length > 0)) query.push(`typesCompanies=${typesCompanies.join('+')}`);
  if (createdByMyself) query.push(`createdByMyself=${createdByMyself}`);
  if ((companies) && (companies.length > 0)) query.push(`companies=${companies.join('+')}`);
  if (limit) query.push(`limit=${limit}`);
  if ((offset) && (offset.length > 0)) query.push(`offset=${offset.join('+')}`);
  if ((orderBy) && (orderBy.length > 0)) query.push(`orderBy=${orderBy}`);
  if ((orderDirection) && (orderDirection.length > 0)) query.push(`orderDirection=${orderDirection}`);

  if (query.length === 0) return '';
  return '?' + query.join('&');
};