import { Map } from 'immutable';

export const getNewId = (items) => ((items && items.length > 0 ? Math.max.apply(Math, items.map((item) => item.id)) : 0) + 1);

/* Functions to manipulate itens observation */
export const getInitialValuesToItemObservation = (form) => {
  const items = form.getFieldValue('observacao') || [];
  return ({ id: getNewId(items), ordem: items.length });
};

export const handleRemoveValueToItemObservation = (form, item) => {
  let observacao = form.getFieldValue('observacao') || [];
  observacao = Map(observacao.map((item) => [item.id, item])).delete(item.id).toList().toArray();
  form.setFieldsValue({ observacao });
};

export const handleChangeOrderItemObservation = (form, oldIndex, newIndex) => {
  const observacao = form.getFieldValue('observacao') || [];
  observacao.splice(newIndex, 0, observacao.splice(oldIndex, 1)[0]);
  form.setFieldsValue({ observacao: observacao.map((item, index) => ({ ...item, ordem: index })) });
};
/***/