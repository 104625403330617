import styled from 'styled-components';
import Icon from '../../Icon';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkHighlightColor};
  border-radius: 12px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 225px;
  max-height: 230px;
`;

export const CloseButton = styled(Icon.MDI)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
`;

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ align }) => align && `
    align-items: ${align};
  `}
`;

export const EventsContainer = styled(Column)`
  gap: 4px;
  overflow-y: scroll;
`;

export const DayOfWeek = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const DayOfMonth = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textContrastColor};
  background-color: ${({ theme }) => theme.actionColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
`;