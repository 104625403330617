import styled from 'styled-components';
import { lighten } from 'polished';

export const StyledViewControl = styled.div`
  background-color: ${(props) => props.theme.darkSecondaryColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: fit-content;
  flex: none;
`;

export const ViewOption = styled.div`
  background-color: ${({ current, theme }) => current ? lighten(0.085, theme.darkPrimaryColor) : theme.darkSecondaryColor};
  color: ${(props) => props.theme.textColor};
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .5s;

  :first-child {
    border-radius: 8px 0 0 8px;
  }

  ${({ current }) => current && `
    border-radius: 8px !important;
  `}

  :last-child {
    border-radius: 0 8px 8px 0;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;