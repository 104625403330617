import { get, put, del } from '../api';

const useApiNode = true;

export const getAgrupadoresMetasAtividades = () => {
  return get('/api/activityKindGoalGroup', {}, useApiNode);
};

export const getAgrupadorMetaAtividade = (id) => {
  return get(`/api/activityKindGoalGroup/${id}`, {}, useApiNode);

};

export const putAgrupadoresMetasAtividades = (agrupador) => {
  return put('/api/activityKindGoalGroup', agrupador, {}, useApiNode);
};

export const deleteAgrupadorMetaAtividade = (id) => {
  return del(`/api/activityKindGoalGroup/${id}`, {}, useApiNode);
};