import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getMetasDiarias as getMetasDiariasSdk, 
  getNotificationMeta as getNotificationMetaSdk
} from '@sdk/MetaAtividade';

//Action Types
export const Types = {
  GET_METAS_DIARIAS: 'METAS_DIARIAS/GET_METAS_DIARIAS',
  GET_METAS_DIARIAS_SUCCESS: 'METAS_DIARIAS/GET_METAS_DIARIAS_SUCCESS',
  GET_METAS_DIARIAS_ERROR: 'METAS_DIARIAS/GET_METAS_DIARIAS_ERROR',

  GET_NOTIFICATION_META: 'METAS_DIARIAS/GET_NOTIFICATION_META',
  GET_NOTIFICATION_META_SUCCESS: 'METAS_DIARIAS/GET_NOTIFICATION_META_SUCCESS',
  GET_NOTIFICATION_META_ERROR: 'METAS_DIARIAS/GET_NOTIFICATION_META_ERROR',

  RESET: 'METAS_DIARIAS/RESET',
};

//Action Creators
export const getMetasDiarias = (userId) => ({ type: Types.GET_METAS_DIARIAS, userId });
export const getMetasDiariasSuccess = (metasDiarias) => ({ type: Types.GET_METAS_DIARIAS_SUCCESS, metasDiarias });
export const getMetasDiariasError = (error) => ({ type: Types.GET_METAS_DIARIAS_ERROR, error }); 

export const getNotificationMeta = (idAtividade, idUser) => ({ type: Types.GET_NOTIFICATION_META, idAtividade, idUser });
export const getNotificationMetaSuccess = (notificationResp) => ({ type: Types.GET_NOTIFICATION_META_SUCCESS, notificationResp });
export const getNotificationMetaError = (error) => ({ type: Types.GET_NOTIFICATION_META_ERROR, error }); 

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetMetasDiarias(action) {
  try {
    const { userId } = action;
    const metasDiarias = yield call(getMetasDiariasSdk, userId);
    yield put(getMetasDiariasSuccess(metasDiarias));
  } catch (err) {
    Notification.error(err.message);
    yield put(getMetasDiariasError(err));
  }
}

function* fetchGetNotificationMetas(action) {
  try {
    const { idAtividade, idUser } = action;
    const notificationMeta = yield call(getNotificationMetaSdk, idAtividade, idUser);
    yield put(getNotificationMetaSuccess(notificationMeta));
  } catch (err) {
    Notification.error(err.message);
    yield put(getNotificationMetaError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_METAS_DIARIAS, fetchGetMetasDiarias),
  takeLatest(Types.GET_NOTIFICATION_META, fetchGetNotificationMetas),
];

// Reducer
const initialState = Map({
  metasDiarias: List(),
  loadingMetasDiarias: false,
  successMetasDiarias: false,
  errorMetasDiarias: false,

  notificationMetas: List(),
  loadingNotificationMetas: false,
  successNotificationMetas: false,
  errorNotificationMetas: false,
});

const handleGetMetasDiarias = (state, action) => {
  return state.set('loadingMetasDiarias', true).set('successMetasDiarias', false).set('errorMetasDiarias', false);
};

const handleGetMetasDiariasSuccess = (state, action) => {
  const { metasDiarias } = action;

  return state.set('metasDiarias', metasDiarias).set('loadingMetasDiarias', false).set('successMetasDiarias', true).set('errorMetasDiarias', false);
};

const handleGetMetasDiariasError = (state, action) => {
  return state.set('loadingMetasDiarias', false).set('successMetasDiarias', false).set('errorMetasDiarias', action.error);
};

const handleGetNotificationMetas = (state, action) => {
  return state.set('loadingNotificationMetas', true).set('successNotificationMetas', false).set('errorNotificationMetas', false);
};
  
const handleGetNotificationMetasSuccess = (state, action) => {
  const { notificationResp } = action;
  return state.set('notificationMetas', notificationResp).set('loadingNotificationMetas', false).set('successNotificationMetas', true).set('errorNotificationMetas', false);
};
  
const handleGetNotificationMetasError = (state, action) => {
  return state.set('loadingNotificationMetas', false).set('successNotificationMetas', false).set('errorNotificationMetas', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_METAS_DIARIAS:
      return handleGetMetasDiarias(state, action);
    case Types.GET_METAS_DIARIAS_SUCCESS:
      return handleGetMetasDiariasSuccess(state, action);
    case Types.GET_METAS_DIARIAS_ERROR:
      return handleGetMetasDiariasError(state, action);

    case Types.GET_NOTIFICATION_META:
      return handleGetNotificationMetas(state, action);
    case Types.GET_NOTIFICATION_META_SUCCESS:
      return handleGetNotificationMetasSuccess(state, action);
    case Types.GET_NOTIFICATION_META_ERROR:
      return handleGetNotificationMetasError(state, action);
    
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}