import styled from 'styled-components';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

export const PlayerAnimation = styled(Player)`
    width: 100%;
    height: 100%;
`;

export const ControlsAnimation = styled(Controls)`
    width: 100%;
`;