import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Radio, Statistic, Input, Button, PopConfirm, Text, Row, Column , Icon } from '@components-teammove';
import { InfiniteScroll  } from '@components';

import { FlexRow } from '../styles';
import { Fill, GapListItem, Padding } from '../../styles';
import { getIdTemp, arrayReorder, Notification } from '@utils';
import { useSelector } from 'react-redux';

export default function ModalPremios({ visible, setVisible, rolesOptions, prizes, setPrizes }) {
  const [editing, setEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState();
  const [tempPrizes, setTempPrizes] = useState();
  const [newPrizeInputValue, setNewPrizeInputValue] = useState('');

  const theme = useSelector(({ app }) => app.get('theme'));

  const currentRolePrizes = useMemo(() => {
    return tempPrizes?.filter(({ campaignRoleId }) => campaignRoleId === currentRole);
  }, [currentRole, tempPrizes]);

  useEffect(() => {
    setTempPrizes(prizes);
  }, [prizes]);
  
  useEffect(() => {
    setCurrentRole(rolesOptions[0]?.value);
  }, [rolesOptions]);

  const getPositionStyle = (pos) => {
    return pos === 1 ? {
      backgroundColor: 'gold',
      color: 'white'
    } : pos === 2 ? {
      backgroundColor: 'silver',
      color: 'white'
    } : pos === 3 ? {
      backgroundColor: '#cd7f32',
      color: 'white'
    } : {
      backgroundColor: 'transparent',
      color: theme['@global-text-color']
    };
  };

  const handleCancel = () => {
    setTempPrizes(prizes);
    setVisible(false);
    setEditing(false);
  };

  const handleOk = () => {
    if (tempPrizes.some(({ prize }) => !prize)) return Notification.error('Insira um valor para a premiação em branco');
    setPrizes(tempPrizes);
    setVisible(false);
    setEditing(false);
  };

  const handleReorder = (oldPos, newPos) => {
    const oldIndexInPrizes = tempPrizes.findIndex(({ campaignRoleId, position }) => campaignRoleId === currentRole && position === oldPos);
    const prizesReordered = arrayReorder(tempPrizes, oldIndexInPrizes, newPos > oldPos ? oldIndexInPrizes + 1 : oldIndexInPrizes - 1);

    const rolesPositions = rolesOptions.reduce((state, role) => ({ ...state, [role.value]: 1 }), {});
    
    setTempPrizes(prizesReordered.map((prz) => {
      const updatedPrize = { ...prz, position: rolesPositions[prz.campaignRoleId] };
      rolesPositions[prz.campaignRoleId]++;
      return updatedPrize;
    }));
  };

  const handleNew = () => {
    if (!newPrizeInputValue) return;
    setTempPrizes([...tempPrizes, { position: currentRolePrizes.length + 1, prize: newPrizeInputValue, campaignRoleId: currentRole, tempId: getIdTemp() }]);
    setNewPrizeInputValue('');
  };

  const handleEdit = (prize) => {
    if (!prize.prize) return;
    setEditing({ ...editing, [prize.id || prize.tempId]: false });
  };

  const handleDelete = (prize) => {
    const prizesFiltered = tempPrizes.filter(({ campaignRoleId, tempId, id }) => campaignRoleId === prize.campaignRoleId && (tempId ? tempId !== prize.tempId : id !== prize.id))
      .map((prz, index) => ({ ...prz, position: index + 1 }));
    
    const prizesUpdatedPositions = tempPrizes.filter(({ campaignRoleId }) => campaignRoleId !== prize.campaignRoleId);

    setTempPrizes([...prizesFiltered, ...prizesUpdatedPositions]);
  };

  return(
    <Modal 
      title='Premiações' 
      visible={visible} 
      onCancel={handleCancel} 
      onOk={handleOk}
      okText='Salvar'
    >
      <Column gap='24px'>
        <Row justify='center'>
          <Radio.Group buttonStyle='solid' value={currentRole} onChange={(value) => setCurrentRole(value)}>
            {rolesOptions.map((role) => <Radio.Button key={role.value} size='large' value={role.value}>{role.label}</Radio.Button>)}
          </Radio.Group>
        </Row>
      
        <InfiniteScroll dataLength={currentRolePrizes?.length - 5 || 0} height={400}>
          {currentRolePrizes?.map((prize) => (
            <GapListItem key={prize.id || prize.tempId}>
              <Statistic 
                value={`${prize.position}°`} 
                valueStyle={{ ...getPositionStyle(prize.position), width: 'fit-content', borderRadius: 100, padding: '.5rem .825rem', fontSize: 14 }}
              />
              <Fill>
                {!editing[prize.id || prize.tempId] ? 
                  <Text strong>{prize.prize}</Text> : 
                  <Input 
                    autoFocus
                    placeholder='Premiação' 
                    value={prize.prize} 
                    onChange={
                      (event) => setTempPrizes(
                        tempPrizes.map((prz) => (prz.id && prz.id === prize.id) || (prz.tempId && prz.tempId === prize.tempId) ? ({ ...prz, prize: event.target.value }) : ({ ...prz }))
                      )
                    } 
                    onPressEnter={() => handleEdit(prize)}
                  />
                }
              </Fill>
              {!editing[prize.id || prize.tempId] ? (
                <Button.Group>
                  <Button type='secondary' size='smaller' context='list' icon={<Icon.UpOutlined/>} onClick={() => handleReorder(prize.position, prize.position - 1)} disabled={prize.position === 1}/>
                  <Button type='secondary' size='smaller' context='list' icon={<Icon.DownOutlined/>} onClick={() => handleReorder(prize.position, prize.position + 1)} disabled={prize.position === currentRolePrizes.length}/>
                  <Button type='secondary' size='smaller' context='list' icon={<Icon.EditOutlined/>} onClick={() => setEditing({ ...editing, [prize.id || prize.tempId]: true })}/>
                  <PopConfirm title='Deseja realmente excluir esta premiação?' onConfirm={() => handleDelete(prize)}>
                    <Button type='secondary' size='smaller' context='list' icon={<Icon.DeleteOutlined/>}/>
                  </PopConfirm>
                </Button.Group>
              ) : (
                <Button type='secondary' icon={<Icon.PlusOutlined/>} onClick={() => handleEdit(prize)}/>
              )
              }
            </GapListItem>
          ))}
        </InfiniteScroll>
        <Padding>
          <FlexRow>
            <Statistic 
              value={`${(currentRolePrizes?.length || 0) + 1}°`} 
              valueStyle={{ ...getPositionStyle((currentRolePrizes?.length || 0) + 1), width: 'fit-content', borderRadius: 100, padding: '.5rem .825rem', fontSize: 14 }}
            />
            <Input autoFocus placeholder='Premiação' value={newPrizeInputValue} onChange={(event) => setNewPrizeInputValue(event.target.value)} onPressEnter={handleNew}/>
            <Button type='secondary' icon={<Icon.PlusOutlined/>} onClick={handleNew}/>
          </FlexRow>
        </Padding>
      </Column>
    </Modal>
  );
}