import React from 'react';
import { useSelector } from 'react-redux';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { getCustomColor } from '@utils';

import { StyledRow } from './styles'; 

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartDonut = ({ successNumber, successPercent, values, ...props }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: [
          getCustomColor('--success-color'),
          getCustomColor('--warning-color'),
          getCustomColor('--danger-color'),
          theme['@global-background-color'],
        ],
        borderWidth: 0,
        cutout : 38,
        radius: 40,
        
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels:{
        display: false,
      }
    },
  };

  return(
    <StyledRow>
      <Doughnut data={data} options={options} />
    </StyledRow>
  );
};

export default ChartDonut;