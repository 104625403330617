import * as Containers from '@containers';
import { Icon } from '@components-teammove';
import { InfoUsuario, TelasNomenclaturas } from '@utils';

export default () => {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  return InfoUsuario.perm('chamadosRelatorio') ? [
    {
      path: '/chamados',
      icon: Icon.CommentOutlined,
      text: TelasNomenclaturas.getNomenclatures('CHAMADOS')?.plural,
      match: /^\/chamados?/,
      sider: true,
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/tickets',
      text: 'Tickets',
      match: /^\/chamados\/tickets?|^\/chamados\/novo?/,
      sider: InfoUsuario.perm('chamados'),
      parent:'/chamados',
      icon: Icon.CommentOutlined,
      component: Containers.ChamadosTickets,
      exact: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/tickets/:id',
      parent: '/chamados/tickets',
      text: 'Tickets',
      match: /^\/chamados\/tickets?/,
      component: Containers.ChamadoTicket,
      exact: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/tickets/:id/printing',
      parent: '/chamados/tickets',
      text: 'Tickets',
      match: /^\/chamados\/tickets?|^\/chamados\/printing?/,
      component: Containers.ChamadosPrinting,
      exact: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/dashboard-chamados',
      text: 'Dashboard',
      component: Containers.DashboardChamados,
      match: /^\/chamados\/dashboard-chamados?/,
      parent: '/chamados',
      sider: InfoUsuario.perm('chamadosRelatorio'),
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamadosRelatorio', infoUsuario),
    },
    {
      path: '/chamados/dashboard-chamados/ranking-categorias-details',
      text: 'Ranking de departamentos/categorias',
      component: Containers.CategoriesRankingDetails,
      match: /^\/chamados\/dashboard-chamados\/ranking-categorias-details?/,
      parent: '/dashboard-chamados',
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/dashboard-chamados/indice-satisfacao',
      text: 'Ranking de departamentos/categorias',
      component: Containers.SatisfactionIndexDetails,
      match: /^\/chamados\/dashboard-chamados\/indice-satisfacao?/,
      parent: '/dashboard-chamados',
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/dashboard-chamados/ranking-companies-details',
      text: `Ranking de ${companyNomenclature.plural}`,
      component: Containers.CompaniesRankingDetails,
      match: /^\/chamados\/dashboard-chamados\/ranking-companies-details?/,
      parent: '/dashboard-chamados',
      exact: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
  ] : [
    {
      path: '/chamados/tickets',
      icon: Icon.CommentOutlined,
      text: TelasNomenclaturas.getNomenclatures('CHAMADOS')?.plural,
      match: /^\/chamados\/tickets?|^\/chamados\/novo?/,
      component: Containers.ChamadosTickets,
      exact: true,
      sider: InfoUsuario.perm('chamados'),
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/tickets/:id',
      parent: '/chamados/tickets',
      text: 'Tickets',
      match: /^\/chamados\/tickets?/,
      component: Containers.ChamadoTicket,
      exact: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
    {
      path: '/chamados/tickets/:id/printing',
      parent: '/chamados/tickets',
      text: 'Tickets',
      match: /^\/chamados\/tickets?|^\/chamados\/printing?/,
      component: Containers.ChamadosPrinting,
      exact: true,
      secret: true,
      permissions: (infoUsuario) => InfoUsuario.perm('chamados', infoUsuario),
    },
  ];
};