import { getIdTemp } from '@utils';

export const formattedWorkingDayForm = (workingDays) => {
  return workingDays.map((workingDay) => {
    const { day, shifts } = workingDay;

    const formattedShifts = shifts.map((shift) => ({
      inicio: shift.start,
      fim: shift.end,
      id: shift.id || getIdTemp(),
    }));

    let formattedDays = '';

    switch (day) {
      case 'MONDAY':
        formattedDays = 'Segunda-Feira';
        break;
      case 'TUESDAY':
        formattedDays = 'Terça-Feira';
        break;
      case 'WEDNESDAY':
        formattedDays = 'Quarta-Feira';
        break;
      case 'THURSDAY':
        formattedDays = 'Quinta-Feira';
        break;
      case 'FRIDAY':
        formattedDays = 'Sexta-Feira';
        break;
      case 'SATURDAY':
        formattedDays = 'Sábado';
        break;
      default:
        formattedDays = 'Domingo';
    }

    return {
      diaSemana: formattedDays,
      intervalos: formattedShifts,
    };
  });
};

export const mountHorario = (values, horarios) => {
  const { id, diaSemana, datas } = values;
  const idValue = id || getIdTemp();
  const inicio = datas[0].format('HH:mm:ss');
  const fim = datas[1].format('HH:mm:ss');

  const indexHorario = horarios.findIndex((hor) => hor.diaSemana === diaSemana);
  const horario = horarios[indexHorario];
  const intervalos = (horario) && (horario.intervalos) ? horario.intervalos : [];
  const indexIntervalos = intervalos.findIndex((int) => int.id === idValue);
  const intervalo = intervalos.find((int) => int.id === idValue);

  if (!intervalo) {
    intervalos.push({ diaSemana, id: idValue, inicio, fim });
  } else {
    intervalos[indexIntervalos] = {
      ...intervalo,
      inicio,
      fim,
    };
  }

  if (!horario) {
    horarios.push({ diaSemana, intervalos });
  } else {
    horarios[indexHorario] = {
      ...horario,
      intervalos,
    };
  }

  return horarios;
};

export const copiarHorarios = (values, horarios) => {
  const { diaSemana, diaSelecionado } = values;

  const horario = horarios.find((hor) => hor.diaSemana === diaSemana);
  const indexSelecionado = horarios.findIndex((hor) => hor.diaSemana === diaSelecionado);
  const horariosSelecionado = {
    ...horario,
    diaSemana: diaSelecionado,
    intervalos: horario.intervalos.map((intervalo) => ({
      ...intervalo,
      id: getIdTemp(),
      diaSemana: diaSelecionado,
    })),
  };

  if (indexSelecionado > -1) {
    horarios[indexSelecionado] = horariosSelecionado;
  } else {
    horarios.push(horariosSelecionado);
  }

  return horarios;
};

export const excluirHorario = (item, horarios) => {
  const { id, diaSemana } = item;

  const indexHorario = horarios.findIndex((hor) => hor.diaSemana === diaSemana);
  const horario = horarios[indexHorario];
  const intervalos = (horario) && (horario.intervalos) ? horario.intervalos : [];
  const indexIntervalos = intervalos.findIndex((int) => int.id === id);

  intervalos.splice(indexIntervalos, 1);
  horarios[indexHorario] = {
    ...horario,
    intervalos,
  };
  
  return horarios;
};

export const workingDaysEnum = {
  'Segunda-Feira': 'MONDAY', 
  'Terça-Feira': 'TUESDAY', 
  'Quarta-Feira': 'WEDNESDAY', 
  'Quinta-Feira': 'THURSDAY',
  'Sexta-Feira': 'FRIDAY', 
  'Sábado': 'SATURDAY', 
  'Domingo': 'SUNDAY',
};
