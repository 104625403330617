import { getUsuarios } from '@sdk/Usuarios';
import { getPerfis } from '@sdk/Perfil';
import { getListasTransmissao } from '@sdk/Wikis/Audiencia';

export const initialFilters = [
  {
    name: 'data',
    label: 'Data',
    type: 'RANGE',
    placeholder: 'Seleciona o período',
    options: []
  },
  {
    name: 'usuario',
    label: 'Usuário',
    type: 'SELECT',
    placeholder: 'Seleciona os usuários',
    action: getUsuarios
  },
  {
    name: 'perfil',
    label: 'Perfil',
    type: 'SELECT',
    placeholder: 'Seleciona os perfis',
    action: getPerfis
  },
  {
    name: 'categoria',
    label: 'Categoria',
    type: 'TREE_SELECT',
    placeholder: 'Seleciona as categorias',
    options: []
  },
  {
    name: 'listaTransmissao',
    label: 'Lista de Transmissão',
    type: 'SELECT',
    placeholder: 'Seleciona as listas',
    action: getListasTransmissao
  },
];