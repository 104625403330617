import styled from 'styled-components';
import View from '../View';
import { Row, Text } from '@components-teammove';

export const InfoWindowView = styled(View)`
  cursor: pointer;
  padding: 5px 10px 5px 0;

  overflow: hidden !important;
`;

export const Info = styled(Text)`
  font-size: ${({ size })=> size};
  font-weight: ${({ weight })=> weight};
  line-height: 19.07px;
`;

export const TagStatus = styled(Row)`
  padding: 7px 12px;
  border-radius: 8px;
  align-items: end;
  gap: 8px;
  background-color: ${({ colorStatus }) => colorStatus === 'OK' ? 'var(--success-color)' : colorStatus === 'ATENCAO' ? 'var(--warning-color)' : colorStatus === 'CRITICO' ? 'var(--danger-color)' : 'var(--success-color)'};
`;

export const Title = styled(Text)`
  font-size: ${({ activity }) => activity ? '14px' : '16px'};
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
  text-align: ${({ fromBottom }) => fromBottom ? 'center' : 'left'};
`;

export const SubText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: ${({ opacity }) => opacity ? .5 : 1};
  max-width: 90%;
`;

export const ViewContainer = styled(View)`
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 12px;
  padding: 8px 12px;
  margin-bottom: 10px;
`;

export const ViewTag = styled(View)`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-radius: ${({ nextActivity }) => nextActivity ? '27px' : '8px'};
  padding: ${({ nextActivity }) => nextActivity ? '4px 8px' : '6px 30px'};
`;