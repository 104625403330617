import styled, { css } from 'styled-components';

const View = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
  props.horizontal &&
    css`
      flex-direction: row;
    `}
  ${(props) =>
      props.background &&
    css`
      background: ${props.background};
    `}
  ${(props) =>
      props.height &&
    css`
      height: ${props.height}px;
    `}
  ${(props) =>
      props.width &&
    css`
      width: ${props.width}px;
    `}
  ${(props) =>
      props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `}
  text-align: ${(props) => props.textAlign};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};

  ${(props) =>
      props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

  ${(props) =>
      props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}

  ${({ flex }) =>
      flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
      gap &&
  css`
    gap: ${gap};
  `}
`;

export default View;
