import { get, put } from '../api';
import { formatParams } from './rules';

export const getCallList = (param) => {
  const useApiNode = true;
  return get(`/integration/dashCall?${formatParams(param)}`,{}, useApiNode);
};

export const getCallDetailed = (id) => {
  const useApiNode = true;
  return get(`/integration/dashCallDetail?voice_call_record_id=${id}`,{}, useApiNode);
};

export const putEditCallNote = (id, newNote) => {
  const useApiNode = true;
  return put(`/integration/update/callNotes/${id}`, { notes: newNote }, {}, useApiNode);
};
