import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, Breadcrumb, Row, Button, FixedCascader, SearchBar, Skeleton } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '@ducks/produtos';
import { useHistory } from 'react-router-dom';
import { ProductImageWrapper, ProductItem, ProductName, Header, ImageRow, StyleEmpty } from './styles';
import { formatCategoriesWithProducts, getFilteredCategoriesWithProducts } from './rules';
import { InfoUsuario } from '@utils';

export default function Produtos() {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));

  const categoriesWithProducts = useSelector(({ produtos }) => produtos.get('categoriesWithProducts'));
  const loadingProducts = useSelector(({ produtos }) => produtos.get('loadingProducts'));
  const successProducts = useSelector(({ produtos }) => produtos.get('successProducts'));

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const renderCascaderItem = (item) => (
    <ProductItem hasImage={!!item.picture} onClick={() => history.push(`mixprodutos/${item.id}/detalhes`)}>
      {item.picture && (
        <ProductImageWrapper>
          <ImageRow src={item.picture} />
        </ProductImageWrapper>
      )}
      <Row align='center'>
        <ProductName>{item.name}</ProductName>
      </Row>
    </ProductItem>
  );

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <Header
          title='Mix de Produtos'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/mixprodutos', name: 'Mix de Produtos', current: true },
              ]}
            />
          )}
          extra={[
            <Button visible={InfoUsuario.perm('produtosCadastro')} key='new' size='small' context='header' type='primary' onClick={() => history.push('/mixprodutos/cadastro/-1')}>Adicionar produto</Button>,
          ]}
        >
          <SearchBar
            placeholder='Pesquisar'
            onSearch={(term) => setSearch(term)}
          />

          {loadingProducts ? (
            <Skeleton.Map />
          ) : successProducts && (
            <FixedCascader
              allOpen={!!search.trim()}
              disabled
              options={formatCategoriesWithProducts(search ? getFilteredCategoriesWithProducts(search, categoriesWithProducts) : categoriesWithProducts)}
              renderItem={renderCascaderItem}
              loading={loadingProducts}
            />
          )}
          {categoriesWithProducts.length === 0 && (
            <StyleEmpty description="Sem dados" />
          )}
        </Header>
      </Body>
    </App>
  );
}