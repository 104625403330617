import { formatCurrency } from '@utils';

export const filter = (search, campaigns) => {
  
  if (!search) return campaigns;

  return campaigns.filter(({ campaign }) => campaign.name.trim().toLowerCase().includes(search.trim().toLowerCase()));
};

export const FORMAT = {
  MONEY: 'MONEY',
  INTEGER: 'INTEGER'
};

export const getValueFromCampaignFormat = (format, value) => {
  switch (format) {
    case FORMAT.MONEY:
      return formatCurrency(value);
    case FORMAT.INTEGER:
      return parseInt(value);
    default: return value;
  }
};