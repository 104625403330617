import React, { useEffect } from 'react';
import App from '@app';
import { Body, PageHeader, View, Spin } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { getCurso, getMateriasAulas, resetAula } from '@ducks/wikisEAD';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Materias from './Materias';
import Aula from './Aula';

const ViewMain = styled(View)`
  height: 100%;
`;

const ViewAula = styled(View)`
  flex: 3;
  padding: 20px;
  height: 100%;
  border-right: 1px solid #dddddd;
`;

const ViewAulaMaterial = styled(View)``;

const ViewMateriasAulas = styled(View)`
  flex: 1;
  padding: 20px;
`;

export default function WikisConteudos() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const curso = useSelector(({ wikisEAD }) => wikisEAD.get('curso'));
  const materiasAulas = useSelector(({ wikisEAD }) => wikisEAD.get('materiasAulas').toArray());
  const loadingMateriasAulas = useSelector(({ wikisEAD }) => wikisEAD.get('loadingMateriasAulas'));

  useEffect(() => {
    dispatch(resetAula());
    dispatch(getCurso({ id }));
    dispatch(getMateriasAulas({ idCurso: id }));
  }, []);

  return (
    <App>
      <Body>
        <PageHeader title={curso.titulo} onBack={() => window.history.back()}>
          {loadingMateriasAulas ? (
            <View>
              <Spin />
            </View>
          ) : (
            <ViewMain horizontal>
              <ViewAula>
                <ViewAulaMaterial>
                  <Aula />
                </ViewAulaMaterial>
              </ViewAula>
              <ViewMateriasAulas>
                <Materias data={materiasAulas} />
              </ViewMateriasAulas>
            </ViewMain>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
