import styled from 'styled-components';
import { View, Button } from '@components-teammove';

export const Container = styled(View)`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border-radius: 8px;
  gap: 8px;
  background: ${({ theme }) => theme.darkSecondaryColor};
  color: ${({ theme }) => theme.textColor};
`;

export const ButtonConfirmacao = styled(Button)`
  width: 200px;
  margin-bottom: 10px 10px 0 10px;
`;