export const fakeData = [
  { id: 'AB', descricao: 'Aberto', cor: '#FF9800', total:28, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 48,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 49,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 77,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 50,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] }, 
  { id:'BR',descricao:'Aguardando Briefing',cor:'#e65100',total:3, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 51,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 52,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 53,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 54,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] },
  { id:'EA', descricao:'Em Atendimento', cor:'#2196F3', total:1, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 55,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 56,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 57,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 58,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] },
  { id:'FE', descricao:'Fechado', cor:'#009688', total:7, totalFechadoNoPrazo:7, totalFechadoForaPrazo:0, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 59,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 60,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 61,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 62,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] },
  { id:'RE', descricao:'Reaberto', cor:'#00BCD4', total:1, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 63,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 64,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 65,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 66,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] },
  { id:'AR', descricao:'Aguardando Pendência', cor:'#e65100', total:3, chamados: [
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 67,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 68,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 69,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    },
    {
      aguardandoResposta: false,
      assunto: 'testando',
      avatarUsuarioAtendimento: '/upload/usuario/409/47jd8es4f5s88v5hp776rateid.jpg',
      categoriaBriefingTitulo: 'Teste',
      diffDias: 1,
      dtCriacao: '2022-09-29 15:57:34',
      dtManutencaoStatus: '2022-09-29 15:57:34',
      dtUltimaAtualizacao: '2022-09-29 15:57:54',
      id: 70,
      idBriefing: 23,
      idCategoria: 8,
      idUnidade: 3,
      idUsuario: 378,
      idUsuarioAtendimento: 409,
      idUsuarioCriacao: 378,
      idsCategoria: '1/8',
      lido: true,
      minutosSobrando: 0,
      nmCategoria: 'Dúvidas financeiro',
      nmCategoriaCompleto: 'Comercial / Dúvidas financeiro',
      nmUnidade: '0012',
      nmUnidadeCompleto: 'Farmácia Nova Próspera',
      nmUsuario: 'Master Farma',
      nmUsuarioAtendimento: 'Comercial ',
      prazo: '2022-10-01 15:57:54',
      protocolo: '220929-0002',
      status: 'ABERTO',
      statusCor: '#FF9800',
      statusDs: 'Aberto',
      tempoHoras: 0,
      unidadeEndereco: 'RUA GENERAL OSVALDO PINTO DA VEIGA, 742 - CRICIÚMA',
    }
  ] }
];