import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 20px;
  border-radius: 28px;
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-t::after {
    background: ${({ theme }) => theme.darkSecondaryColor};	
    min-width: 300px !important;
  }
  .gm-style-iw-tc::after {
    background: ${({ theme }) => theme.darkSecondaryColor};	
  }

  .gm-ui-hover-effect > span {
    background-color: ${({ theme }) => theme.textColor};
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }
`;