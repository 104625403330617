import React from 'react';
import { useSelector } from 'react-redux';
import { StyledTooltip } from './styles';

const Tooltip = ({ ...props }) => {
  const theme = useSelector(({ app }) => app.get('theme'));
  
  return(
    <StyledTooltip overlayStyle={{ backgroundColor: theme['@global-background-color'], paddingBottom: 0, paddingTop: 0, borderRadius: '10px' }} {...props} />
  );
};

export default Tooltip;