import React from 'react';
import { ChatMessageOtherColumn, ChatMessageSelfColumn, ChatMessageOtherSkeleton, ChatMessageRow, ChatMessageSelfSkeleton, ChatSkeleton, CircleSkeleton, ContentTitleSkeleton, ExtraButtonSkeleton, InnerGradient, MinorLineSkeleton, MediumLineSkeleton, SearchBarSkeleton, SkeletonStyles, TitleSkeleton, LargeBoxSkeleton, MapSkeleton, CardSkeleton } from './styles';
import { Column } from '@components-teammove';

const Skeleton = ({ children, ...props }) => (
  <SkeletonStyles {...props}>
    <InnerGradient/>
  </SkeletonStyles>
);

const Circle = ({ ...props }) => (
  <CircleSkeleton { ...props }>
    <Skeleton/>
  </CircleSkeleton>
);

const MinorLine = ({ ...props }) => (
  <MinorLineSkeleton { ...props }>
    <Skeleton/>
  </MinorLineSkeleton>
);

const LargeBox = () => (
  <LargeBoxSkeleton>
    <Skeleton/>
  </LargeBoxSkeleton>
);

const MediumLine = ({ ...props }) => (
  <MediumLineSkeleton { ...props }>
    <Skeleton />
  </MediumLineSkeleton>
);

const Title = ({ ...props }) => (
  <TitleSkeleton { ...props }>
    <Skeleton/>
  </TitleSkeleton>
);

const ExtraButton = ({ ...props }) => (
  <ExtraButtonSkeleton { ...props }>
    <Skeleton/>
  </ExtraButtonSkeleton>
);

const SearchBar = ({ ...props }) => (
  <SearchBarSkeleton { ...props }>
    <Skeleton/>
  </SearchBarSkeleton>
);

const ContentTitle = ({ ...props }) => (
  <ContentTitleSkeleton { ...props }>
    <Skeleton/>
  </ContentTitleSkeleton>
);

const Chat = ({ ...props }) => (
  <ChatSkeleton { ...props }>
    <ChatMessageOtherColumn>
      <ChatMessageRow>
        <Circle/>
        <MinorLine/>
      </ChatMessageRow>
      <ChatMessageOtherSkeleton>
        <Skeleton/>
      </ChatMessageOtherSkeleton>
    </ChatMessageOtherColumn>
    <ChatMessageSelfColumn>
      <ChatMessageRow>
        <Circle/>
        <MinorLine/>
      </ChatMessageRow>
      <ChatMessageSelfSkeleton>
        <Skeleton/>
      </ChatMessageSelfSkeleton>
    </ChatMessageSelfColumn>
  </ChatSkeleton>
);

const Map = () => (
  <MapSkeleton>
    <Skeleton/>
  </MapSkeleton>
);

const Form = () => (
  <Column gap='1.25rem'>
    {new Array(1).fill('').map((elem, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Column gap='8px' key={index}>
        <Skeleton.MinorLine/>
        <Skeleton.SearchBar/>
      </Column>
    ))}
  </Column>
);

const Card = () => (
  <CardSkeleton>
    <Skeleton/>
  </CardSkeleton>
);

Skeleton.Circle = Circle;
Skeleton.MinorLine = MinorLine;
Skeleton.LargeBox = LargeBox;
Skeleton.MediumLine = MediumLine;
Skeleton.Title = Title;
Skeleton.ExtraButton = ExtraButton;
Skeleton.SearchBar = SearchBar;
Skeleton.ContentTitle = ContentTitle;
Skeleton.Chat = Chat;
Skeleton.Map = Map;
Skeleton.Form = Form;
Skeleton.Card = Card;

export default Skeleton;