import React, { useEffect, useMemo, useState } from 'react';
import { Modal, View, Tabs, Avatar, Tooltip, Column , Icon } from '@components-teammove';
import { Spin  } from '@components';

import { apiGetAudienciaUltimosAcessos } from '@ducks/wikisAudiencia';
import { apiGetAudiencia } from '@ducks/wikis';
import { getDiffDays, moment, formatDate, formatDateTime, formatTime } from '@utils';
import { CaminhoCategoriaView, CaminhoView, CategoriaView, Foto, Nome, Perfil, UsuarioContainer, ViewAcessoDados, ViewAcessos, ViewDadoAcesso, ViewDados, ViewDadosAcesso, ViewDadosUsuario, ViewData, ViewDataHora, ViewHoje, ViewHora, ViewOntem, ViewResumo, ViewResumoAcessados, ViewResumoPercentual, ViewResumoTitulo, ViewResumoTotalAcesso, ViewResumoValor, ViewUsuario } from './styles';

export default function WikiAudiencias({ visible, onCancel, id, ds }) {
  const categoriaSplit = useMemo(() => ds.split(' / '), [ds]);
  const categoria = useMemo(() => categoriaSplit.pop(), []);
  const caminho = useMemo(() => categoriaSplit.join(' / '), []);

  const [audienciasAcompanhamento, setAudienciasAcompanhamento] = useState();
  const [audienciasAcessos, setAudienciasAcessos] = useState();
  const [currentTab, setCurrentTab] = useState('Timeline');

  useEffect(() => {
    (async () => {
      const res = await apiGetAudienciaUltimosAcessos(1, { categoria: [id] });
      setAudienciasAcompanhamento(res);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const res = await apiGetAudiencia(id);
      setAudienciasAcessos(res);
    })();
  }, []);

  const getPeriodo = (item, prevItem) => {
    if (prevItem) {
      if (formatDate(item.dataFim) === formatDate(prevItem.dataFim)) {
        return;
      }
    }

    if (formatDate(item.dataFim) === formatDate(moment())) {
      return <ViewHoje>Hoje</ViewHoje>;
    } else if (formatDate(item.dataFim) === formatDate(moment().subtract(1, 'day'))) {
      return <ViewOntem>Ontem</ViewOntem>;
    } else {
      return (
        <View>
          <View>{formatDate(item.dataFim)}</View>
          <View>Há {getDiffDays(item.dataFim)} dias</View>
        </View>
      );
    }
  };

  const getUsuario = (item, prevItem) => {
    if (!prevItem || item.cdUsuario !== prevItem.cdUsuario || formatDate(item.dataFim) !== formatDate(prevItem.dataFim)) {
      return (
        <ViewUsuario>
          <Foto>
            <Avatar size='large' src={item.avatarURL} />
          </Foto>
          <View>
            <UsuarioContainer bordercolor={item.corPerfil}>
              <Nome>{item.nmUsuario}</Nome>
              <Perfil>{item.dsPerfil}</Perfil>
            </UsuarioContainer>
          </View>
        </ViewUsuario>
      );
    }
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={(
        <View horizontal alignItems='center' justifyContent='space-between'>
          <CaminhoCategoriaView>
            <CategoriaView>{categoria}</CategoriaView>
            <CaminhoView>{caminho}</CaminhoView>
          </CaminhoCategoriaView>
        </View>
      )}
      footer={null}
    >
      <Column gap='16px'>
        <Tabs options={[{ name: 'Timeline' }, { name: 'Acessos' }]} current={currentTab} onChange={(newTab) => setCurrentTab(newTab)}/>
        {currentTab === 'Timeline' && (
          <View>
            {audienciasAcompanhamento ? audienciasAcompanhamento.data.map((item, index, arr) => {
              const previousItem = arr[index - 1];

              return (
                <ViewDados
                  key={item.cdAudiencia}
                  newUser={
                    !previousItem
                      ? false
                      : item.cdUsuario !== previousItem.cdUsuario || formatDate(item.dataFim) !== formatDate(previousItem.dataFim)
                  }
                  dadoAntigo={getDiffDays(item.dataFim) > 90}
                >
                  <ViewDadosUsuario>
                    <ViewDataHora>
                      <ViewData>{getPeriodo(item, previousItem)}</ViewData>
                    </ViewDataHora>
                    {getUsuario(item, previousItem)}
                  </ViewDadosUsuario>
                  <Tooltip title='Data de acesso'>
                    <ViewDadosAcesso>
                      <Icon.ClockCircleOutlined /> &nbsp;
                      <ViewHora>{formatTime(item.dataFim)}</ViewHora>
                    </ViewDadosAcesso>
                  </Tooltip>
                </ViewDados>
              );
            }) : <View><Spin/></View>}
          </View>
        )}
        {currentTab === 'Acessos' && (
          <>
            {audienciasAcessos ? (
              <>
                <ViewResumo>
                  <ViewResumoTotalAcesso>
                    <ViewResumoTitulo>Deveriam Acessar</ViewResumoTitulo>
                    <ViewResumoValor>{audienciasAcessos.length} </ViewResumoValor>
                  </ViewResumoTotalAcesso>
                  <ViewResumoAcessados>
                    <ViewResumoTitulo>Acessaram</ViewResumoTitulo>
                    <ViewResumoValor>{audienciasAcessos.filter((item) => item.acessado).length}</ViewResumoValor>
                    <ViewResumoPercentual>
                      {audienciasAcessos.length > 0
                        ? ((audienciasAcessos.filter((item) => item.acessado).length / audienciasAcessos.length) * 100).toFixed(2)
                        : '0.00'}
                %
                    </ViewResumoPercentual>
                  </ViewResumoAcessados>
                </ViewResumo>
                <View>
                  {audienciasAcessos.map((item) => (
                    <ViewAcessos key={item.cdUsuario * 111}>
                      <ViewUsuario acessado={item.acessado}>
                        <Foto>
                          <Avatar size='large' src={item.avatarUrl} />
                        </Foto>
                        <View>
                          <UsuarioContainer bordercolor={item.corPerfil}>
                            <Nome>{item.nmUsuario}</Nome>
                            <Perfil>{item.descricaoPerfil}</Perfil>
                          </UsuarioContainer>
                        </View>
                      </ViewUsuario>
                      {item.acessado && (
                        <ViewDadoAcesso>
                          <ViewAcessoDados>
                            <Tooltip title='Último acesso'>
                              <Icon.ClockCircleOutlined />
                        &nbsp;
                              {formatDateTime(item.ultimoAcesso)}
                            </Tooltip>
                          </ViewAcessoDados>
                          <ViewAcessoDados>
                            <Tooltip title='Visualizações'>
                              <Icon.EyeFilled />
                        &nbsp;{item.qtAcesso}
                            </Tooltip>
                          </ViewAcessoDados>
                        </ViewDadoAcesso>
                      )}
                    </ViewAcessos>
                  ))}
                </View>
              </>
            ) : <View><Spin/></View>}
          </>
        )}
      </Column>
    </Modal>
  );
}
