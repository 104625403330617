import React from 'react';
import { Table, View } from '@components-teammove';
import { getNomeMesData, formatDecimal } from '@utils';
import { typeFiltroDado, typeFiltroPlataforma } from '../../rules';
import { ViewTotais } from './styles';

export default function TableAcessoPerfis({ data, filtroTipoDado, filtroTipoPlataforma }) {
  const getDates = () => {
    return [...new Set(data.map((item) => item.date))];
  };

  const getPerfis = () => {
    let perfis = [];

    data.forEach((item) => {
      if (perfis.filter((re) => re.ds === item.dsPerfil).length === 0) {
        perfis.push({ ds: item.dsPerfil, cor: item.corPerfil });
      }
    });

    return perfis;
  };

  const getData = () => {
    let result = [];
    const perfis = getPerfis();
    const dates = getDates();

    perfis.forEach((perfil) => {
      let obj = { perfil: perfil.ds, cor: perfil.cor };

      const datesPerfil = data.filter((item) => item.dsPerfil === perfil.ds && item.corPerfil === perfil.cor);

      dates.forEach((date, inc) => {
        const row = datesPerfil.filter((item) => item.date === date)[0];

        let value = 0;

        switch (filtroTipoDado) {
          case typeFiltroDado.ACESSOS:
            switch (filtroTipoPlataforma) {
              case typeFiltroPlataforma.TODOS:
                value = row.qtAcessoApp + row.qtAcessoWeb;
                break;
              case typeFiltroPlataforma.APP:
                value = row.qtAcessoApp;
                break;
              case typeFiltroPlataforma.WEB:
                value = row.qtAcessoWeb;
                break;
              default:
                return false;
            }
            break;
          case typeFiltroDado.USUARIOS:
            switch (filtroTipoPlataforma) {
              case typeFiltroPlataforma.TODOS:
                value = row.qtUsuario;
                break;
              case typeFiltroPlataforma.APP:
                value = row.qtUsuarioApp;
                break;
              case typeFiltroPlataforma.WEB:
                value = row.qtUsuarioWeb;
                break;
              default:
                return false;
            }
            break;
          default:
            return false;
        }

        obj[`data${inc}`] = value;
      });
      result.push(obj);
    });

    return result;
  };

  const getColumns = () => {
    const dates = getDates();
    let columns = [{ title: 'Perfil', dataIndex: 'perfil', key: 'perfil', sorter: (a, b) => a.perfil > b.perfil }];

    dates.forEach((date, inc) => {
      columns.push({
        title: getNomeMesData(date),
        dataIndex: `data${inc}`,
        key: `data${inc}`,
        align: 'start',
        sorter: (a, b) => a[`data${inc}`] - b[`data${inc}`],
        render: (text) => formatDecimal(text, 0),
      });
    });
    return columns;
  };

  const handleTableSummary = (pageData) => {

    let totals = new Array(7).fill(0);

    pageData.forEach((item) => {
      totals[0] += item.data0;
      totals[1] += item.data1;
      totals[2] += item.data2;
      totals[3] += item.data3;
      totals[4] += item.data4;
      totals[5] += item.data5;
      totals[6] += item.data6;
    });

    return (
      <>
        <ViewTotais>
          <View>Total: {totals.reduce((total, num) => total + num)}</View>             
        </ViewTotais>
        {/* <ViewTotais>
          <View>Total: {totals.reduce((total, num) => total + num)}</View>
          {totals.map((total, inc) => (
            <View key='total'>
              <Text>
                {getNomeMesData(dates[inc])}: {formatDecimal(total, 0)}
              </Text>
            </View>
          ))}
        </ViewTotais> */}
      </>
    );
  };

  return (
    <Table
      rowKey='perfil'
      summary={(pageData) => handleTableSummary(pageData)}
      columns={getColumns()}
      dataSource={getData()}
      loading={false}
      hidePagination
    />
  );
}
