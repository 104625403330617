import React from 'react';
import omit from 'omit.js';
import { Icon, Tag, Button } from '@components-teammove';

import { Container, Label, TagsView, TagText } from './styles';

const Tags = ({ field, title, dependentFields = [], filtros, onSearch, join = ', ', type, disabled }) => {
  const handleCloseTag = (e) => {
    e.preventDefault();
    
    filtros = omit(filtros, [field]);
    filtros.descFiltros = omit(filtros.descFiltros, [field]);
    
    dependentFields.forEach((depField) => {
      filtros = omit(filtros, [depField]);
      filtros.descFiltros = omit(filtros.descFiltros, [depField]);
    });

    onSearch(filtros, true);
  };

  if ((filtros[field]) && (filtros.descFiltros[field])) {
    if (Array.isArray(filtros.descFiltros[field])) {
      const tags = filtros.descFiltros[field].filter((item) => (item));  
      
      if (tags.length === 0) return null;
      
      return (
        <Tag closable={!disabled} onClose={handleCloseTag}>
          <TagText><strong>{title}:</strong> {tags.join(type === 'RANGE' ? ' até ' : join)}</TagText> 
        </Tag>
      );
    }
    return (
      <Tag closable={!disabled} onClose={handleCloseTag}>
        <TagText strong>{type === 'SELECT' ? filtros.descFiltros[field] : title}</TagText>
      </Tag>
    );
     
  }
  return null;
};

const ActiveFilters = ({ filtros, onSearch, filters, disabled = false }) => {
  return (filtros) && (filtros.descFiltros) && (Object.entries(filtros.descFiltros).filter((entry) => entry[1]).length > 0) ? (
    <Container>
      <Label>Filtros: </Label>
      <TagsView>
        {filters.map((filter) => (
          <Tags key={filter.name} title={filter.tagLabel || filter.label} field={filter.name} filtros={filtros} onSearch={onSearch} type={filter.type} dependentFields={filter.dependentFields} disabled={disabled || filter.required}/>  
        ))}
        {!disabled && !filters.some(({ required }) => required) && (
          <Button 
            bordered 
            transparent 
            coloredContent
            context='round'
            size='small'
            type='danger' 
            icon={<Icon.Feather type='fi-trash2'/>} 
            onClick={() => onSearch(Object.keys(filtros).reduce((state, key) => key === 'descFiltros' ? ({ ...state }) : ({ ...state, [key]: undefined }), {}), true)}
          >
            Limpar filtros
          </Button>
        )}
      </TagsView>
    </Container>
  ) : null;
};

export default ActiveFilters;