import { moment, formatDate, getDistanceBetweenLocations } from '@utils';

export const getCurrentDayLabel = (day) => {
  if (day.isSame(moment().subtract(1, 'days'), 'day')) {
    return 'ontem';
  } else if (day.isSame(moment().add(1, 'days'), 'day')) {
    return 'amanhã';
  } else if (day.isSame(moment(), 'day')) {
    return 'hoje';
  } else {
    return formatDate(day);
  }
};

export const getTotalDistance = (userLocations) => {
  return userLocations?.reduce((total, location, index, allLocations) => {
    return index !== allLocations.length - 1 ?
      total + (getDistanceBetweenLocations(location, allLocations[index + 1]) / 1000.0) :
      total;
  }, 0) || 0;
};

export const getTotalTime = (userLocations) => {
  if (!userLocations || userLocations.length === 0) {
    return '00:00:00';
  }

  const difference = moment(userLocations[userLocations.length - 1].date).diff(moment(userLocations[0].date));

  return new Date(difference).toISOString().slice(11,19);
};