import styled, { css } from 'styled-components';
import { Button, Form, MessageList, View, Modal, Image, Text } from '@components';
import { Icon } from '@components-teammove';

export const MessageForm = styled(Form)`
  align-items: center;
`;

export const MessageItem = styled(Form.Item)`
  flex: 1 !important;
`;

export const ContainerUploads = styled(View)`
  flex-direction: row;
`;

export const ContentUpload = styled(View)`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  overflow-y: hidden; 
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ContentImageUpload = styled(View)`
  display: relative !important;  

  & + div {
    margin-left: 5px;
  }
`;

export const ImageUpload = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
`;

export const IconFileDoneOutlinedUpload = styled(Icon.FileDoneOutlined)`
  font-size: 50px;
  border-radius: 50%;
  margin-top: 10px;
`;

export const ButtonDeleteUpload = styled(Button)`
  position: absolute;
  width: 25px;
  height: 25px;
  min-width: 20px !important;
  margin-left: 30px; 
`;

export const IconDeleteUpload = styled(Icon.CloseOutlined)`
  font-size: 12px;
  height: 12px;
  width: 12px;
  vertical-align: 0.2em;
`;

export const Chat = styled(MessageList)`
  img {
    max-width: fit-content;    
  }

  div[class*=rce-smsg] {
    background-color: transparent;
    box-shadow: none;
  }

  .rce-mbox-title{
    span{
      flex: 1;
    }
  }
  
  .rce-mbox-text:after{
    content: none !important;
  }

  .rce-mbox-photo--img {
    border-radius: 0;
  }

`;

export const ContainerCompletedService = styled(View)`
  flex-direction: row;
`;

export const CoverCompletedService = styled(Image)`
  max-width: 250px;
  margin-right: 10px;
`;

export const ContentCompletedService = styled(View)`
  flex: 1;
  justify-content: center;
`;

export const LabelCompletedService = styled(View)`
  color: #000;
  font-size: 20px;
`;

export const ContainerActionsCompletedService = styled(View)`
  flex-direction: row;
`;

export const ContentActionsCompletedService = styled(View)`
  flex: 1;
  margin-top: 15px;

  button {
    max-width: 200px;
    margin-top: 5px;
  }
`;

export const ContainerAnswerPending = styled(View)`
  flex-direction: row;
`;

export const CoverAnswerPending = styled(Image)`
  min-width: 180px;
  margin-right: 10px;
`;

export const ContentAnswerPending = styled(View)`
  flex: 1;
  justify-content: center;
`;

export const LabelAnswerPending = styled(View)`
  font-size: 18px;
  font-weight: bold;
`;

export const ContentButtonPendency = styled(View)`
  width: 200px;
  margin-top: 20px;
`;

export const ButtonPendency = styled(Button)`
${(props) =>
  props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor} !important;
      border-color: ${props.backgroundColor} !important;
      :hover {
        background-color: ${props.backgroundColor} !important;
        border-color: ${props.backgroundColor} !important;
        opacity: 0.8;
      }
    `}
`;

export const ModalPhoto = styled(Modal)`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
`;

export const ModalPhotoContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModalPhotoImg = styled(Image)`
  @media (max-width: 600px){
    max-width: 200px;
  }

  flex: 1;
  max-height: 400px;
  max-width: 600px;
  border-radius: .2rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
`;

export const ChangeImgBtnPrev = styled(Button)`
  margin: 1rem;
  border: none !important;
  position: absolute;
  top: 40%;
  left: 0;
`;

export const ChangeImgBtnNext = styled(Button)`
  margin: 1rem;
  border: none !important;
  position: absolute;
  top: 40%;
  right: 0;
`;

export const Row = styled(View)`
  flex-direction: row;
  ${({ marginLeft }) => marginLeft && `
    margin-left: auto;
  `};
  gap: ${({ gap }) => gap || '5px'};
  align-items: center;
  ${({ marginRight }) => marginRight && `
    margin-right: 3rem;
  `}
`;

export const MarginBottom = styled(View)`
  margin-bottom: 1rem;
`;

export const DeletedMessageWrapper = styled(Row)`
  margin-right: 3rem;
  * {
    font-style: italic;
    opacity: .75;
  }
`;

export const MessageText = styled(Text)`
  ${({ hasTitle }) => !hasTitle && `
    margin-top: 10px;
  `}
  margin-left: auto;
  margin-right: 3rem;
  margin-bottom: 10px;
`;

export const MessageOptionsButton = styled(Button)`
  height: fit-content;
  border: none !important;
  align-self: flex-start;
  margin-left: auto;
  position: ${({ absolute }) => absolute ? 'absolute' : 'relative'};
  top: ${({ absolute }) => absolute && '-10px'};
  right: -35px;
  transform: rotate(90deg);
  color: white !important;
  font-size: 22px;
`;

export const MessageContainer = styled(View)`
  
`;