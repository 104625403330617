import React, { useMemo } from 'react';
import { ActionsContainer, Container, ContentColumn, ContentTitle, FavoriteIndicator, ActionIndicatorBox, FolderCard, FolderCardSkeleton, FolderImage, FoldersContainer, ItemCard, ItemCardSkeleton, ItemImage, ItemsContainer, ItemTitle, NoItemImage, ToggleReadIndicator, ContentPath, ItemSummary } from './styles';
import { Skeleton, Row, List, Column } from '@components-teammove';
import { NoImageIconWrapper } from '../FirstPageListing/styles';
import { getIconByType } from '../FirstPageListing/rules';
import { UnreadIndicator } from '../styles';
import { postFavorito , postLido } from '@ducks/wikisAcoes';
import { changeFavorite , changeRead } from '@ducks/wikis';
import { useDispatch } from 'react-redux';

export default function InnerPageListing({ dataSource, loading, onItemClick, isFiltering }) {
  const dispatch = useDispatch();

  const [folders, items] = useMemo(() => {
    return dataSource.reduce((state, content) => {
      state[content.tipo === 'GRUPO' ? 0 : 1].push(content);
      return state;
    }, [[], []]);
  }, [dataSource]);

  return(
    <>
      <Container>
        {loading ? (
          <>
            <ContentColumn>
              <Skeleton.MinorLine/>
              <FoldersContainer>
                {new Array(4).fill('').map((content, index) => (
                // eslint-disable-next-line react/no-array-index-key
                  <FolderCardSkeleton key={index}>
                    <Skeleton/>
                  </FolderCardSkeleton>
                ))}
              </FoldersContainer>
            </ContentColumn>

            <ContentColumn>
              <Skeleton.MinorLine/>
              <ItemsContainer>
                {new Array(12).fill('').map((content, index) => (
                // eslint-disable-next-line react/no-array-index-key
                  <ItemCardSkeleton key={index}>
                    <Skeleton/>
                  </ItemCardSkeleton>
                ))}
              </ItemsContainer>
            </ContentColumn>
          </>
        ) : (
          <>
            {folders.length > 0 && (
              <ContentColumn>
                <ContentTitle>Pastas</ContentTitle>
                <FoldersContainer>
                  {folders.map((folder) => (
                    <FolderCard key={folder.id} onClick={() => onItemClick(folder)} favorite={folder.favorito}>
                      <ActionsContainer>
                        <ActionIndicatorBox favorite={folder.favorito}>
                          <FavoriteIndicator 
                            type={'star' + (folder.favorito ? '-fill' : '')} 
                            size='0.75rem'
                            onClick={(evt) => {
                              evt.stopPropagation();
                              dispatch(postFavorito(folder.id, !folder.favorito));
                              dispatch(changeFavorite(!folder.favorito, folder.id));
                            }}
                            title='Marcar como favorito'
                          />
                        </ActionIndicatorBox>
                        <ActionIndicatorBox read={folder.lido}>
                          <ToggleReadIndicator 
                            type={folder.lido ? 'email-mark-as-unread' : 'email-open'}
                            size='0.75rem'
                            onClick={(evt) => {
                              evt.stopPropagation();
                              dispatch(postLido(folder.id, !folder.lido));
                              dispatch(changeRead(!folder.lido, folder.id));
                            }}
                            title={'Marcar como ' + (folder.lido ? 'não ' : '') + 'lido'}
                          />
                        </ActionIndicatorBox>
                      </ActionsContainer>
                      <FolderImage src={folder.imagem}/>
                      <Column>
                        {isFiltering && folder.path ? (
                          <Row>
                            {folder.path.map((itemPath) => {
                              const path = itemPath.split('||');
                              return <ContentPath key={path[0]}>{path[0] === folder.id.toString() ? '' : path[1] + ' > '}</ContentPath>;
                            })}
                          </Row>
                        ) : null}
                        <Row gap='4px' align='center'>
                          {!folder.lido && (
                            <UnreadIndicator/>
                          )}
                          <ContentTitle>{folder.titulo}</ContentTitle>
                        </Row>
                      </Column>
                    </FolderCard>
                  ))}
                </FoldersContainer>
              </ContentColumn>
            )}
  
            {items.length > 0 && (
              <ContentColumn>
                <ContentTitle>Arquivos</ContentTitle>
                <ItemsContainer>
                  {items.map((item) => (
                    <ItemCard key={item.id} onClick={() => onItemClick(item)}>
                      <ActionsContainer>
                        <ActionIndicatorBox favorite={item.favorito}>
                          <FavoriteIndicator
                            type={'star' + (item.favorito ? '-fill' : '')} 
                            size='0.75rem'
                            onClick={(evt) => {
                              evt.stopPropagation();
                              dispatch(postFavorito(item.id, !item.favorito));
                              dispatch(changeFavorite(!item.favorito, item.id));
                            }}
                            title='Marcar como favorito'
                          />
                        </ActionIndicatorBox>
                        <ActionIndicatorBox read={item.lido}>
                          <ToggleReadIndicator 
                            type={item.lido ? 'email' : 'email-open'}
                            size='0.75rem'
                            onClick={(evt) => {
                              evt.stopPropagation();
                              dispatch(postLido(item.id, !item.lido));
                              dispatch(changeRead(!item.lido, item.id));
                            }}
                            title={'Marcar como ' + (item.lido ? 'não ' : '') + 'lido'}
                          />
                        </ActionIndicatorBox>
                      </ActionsContainer>
                      {!item.imagem || item.imagem?.substring(item.imagem?.length - 4).toLowerCase() === '.pdf' ? (
                        <NoItemImage>
                          <NoImageIconWrapper>
                            {getIconByType(item.tipo)}
                          </NoImageIconWrapper>
                        </NoItemImage>
                      ) : (
                        <ItemImage src={item.imagem}/>
                      )}
                      {isFiltering && item.path ? (
                        <Row>
                          <ContentPath>
                            {item.path.map((item) => item.split('||')[1]).join(' > ')}
                          </ContentPath>
                        </Row>
                      ) : null}
                      <Row gap='4px' align='center'>
                        <ItemTitle>{item.titulo}</ItemTitle>
                        {!item.lido && (
                          <UnreadIndicator/>
                        )}
                      </Row>
                      <ItemSummary>{item.resumo}</ItemSummary>
                    </ItemCard>
                  ))}
                </ItemsContainer>
              </ContentColumn>
            )}
          </>
        )}
      </Container>

      {!loading && folders.length === 0 && items.length === 0 && (
        <List/>
      )}
    </>
  );
}