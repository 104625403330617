import React, { useEffect , useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from '@components';
import { Modal, Form, StatusTag, Skeleton } from '@components-teammove';
import { InfoUsuario, getSearchParams, TelasNomenclaturas } from '@utils';
import { getTicket, saveBriefingReply, finishBriefingReply, saveReportReply, finishReportReply, reset } from '@ducks/chamadosBriefingReply';
import { convertArrayToFormObject, handleRenderAsk } from './rules';
import { ContainerLoading, Row, DetailText, DetailLabel, ColumnWithGap, ProtocolText, ThinModalTitle, Container, WarningText, WarningIcon } from './style';

export default function ModalQuestionarioReply({ visible, onCancel, tipo, chamado, isEditing }) {
  const [blockingSave, setBlockingSave] = useState(false);
  const [hasQuestionarioChanged, setHasQuestionarioChanged] = useState(false);
  const idTicket = useMemo(() => {
    if (visible) {
      if(chamado && Object.keys(chamado).length > 0) {
        return chamado.id;
      }
      return getSearchParams('ticket');
    }
  }, [visible]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  
  const usuario = InfoUsuario.get();
  let isQuestionarioCompleted = false;
  
  const ticket = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('ticket'));
  const loadingGetTicket = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('loadingGetTicket'));
  const successGetTicket = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successGetTicket'));

  const loadingFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('loadingFinishBriefingReply'));
  const loadingFinishReportReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('loadingFinishReportReply'));
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  
  const userId = usuario.cdUsuario;
  const isUsuarioCriador = userId === ticket?.idUsuarioCriacao;
  const administrador = InfoUsuario.perm('chamadosAdministrador', usuario);
  const reportLogicUser = ticket?.idUsuarioAtendimento === userId;

  // useEffect(() => {
  //   if (!visible) {
  //     handleCloseScreen();
  //   }
  // }, [visible]);
  
  useEffect(() => {
    if (idTicket) {
      handleDispatchGetTicket(idTicket);
    }
  }, [idTicket]);

  useEffect(() => {
    if (successGetTicket) {
      const tipoQuestionario = ticket.questionario.filter((quest) => quest.tipoQuestionario === tipo);
      if(tipoQuestionario.length > 0) {
        isQuestionarioCompleted = tipoQuestionario[0]?.status === 'CONCLUIDO';
        const initialValues = convertArrayToFormObject(tipoQuestionario[0]?.respostas?.map(({ id, resposta, pergunta }) => ({ id, resposta, pergunta })));
        form.setFieldsValue(initialValues);
      }
    }
  }, [successGetTicket]);

  const handleDispatchGetTicket = (idTicket) => {
    dispatch(getTicket(idTicket));
  };

  const handleDispatchSaveQuestionarioReply = (questionarioRes) => {
    tipo === 'BRIEFING' ? dispatch(saveBriefingReply(questionarioRes)) : dispatch(saveReportReply(questionarioRes));
  };

  const handleDispatchFinishQuestionarioReply = (questionarioRes) => {
    tipo === 'BRIEFING' ? dispatch(finishBriefingReply(questionarioRes, userId)) : dispatch(finishReportReply(questionarioRes, userId));
  };

  const handleDispatchReset = () => {
    dispatch(reset());
  };

  const handleAnswerQuestionario = (values, updateOnlyAnswers) => {
    let filteredValues = {};
    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key]) && values[key].length > 0 && values[key][0].response) {
        values[key] = values[key].map(({ response }) => response).join(',');
      }
      if (Array.isArray(values[key])) {
        const arrayFieldItems = values[key]
          .map((item) => {
            if (item && item.response) {
              return item.response;
            } else if (item && item.url) {
              return item.url;
            }
            return item;
          })
          .filter((url) => url);

        filteredValues[key] = arrayFieldItems.length === 1 ? arrayFieldItems[0] : arrayFieldItems;
      } else {
        filteredValues[key] = values[key];
      }
    });
    
    const tipoQuestionario = ticket?.questionario?.filter((quest) => quest.tipoQuestionario === tipo);
    let questionarioRes =  tipoQuestionario[0];
    if(Object.keys(tipoQuestionario).length > 0) {
      questionarioRes.respostas = questionarioRes?.respostas.map((answer) => ({ ...answer, respondido: !updateOnlyAnswers ? true : answer.respondido, resposta: filteredValues[answer.id] ? filteredValues[answer.id].toString() : null }));
      questionarioRes = {
        ...questionarioRes,
        dtRealizacao: new Date().toISOString()
      };
      if (updateOnlyAnswers) {
        handleDispatchSaveQuestionarioReply(questionarioRes); 
      } else {
        handleDispatchFinishQuestionarioReply(questionarioRes);
      }
    }
  };

  const handleOnlyAnswerQuestionario = () => {
    handleAnswerQuestionario(form.getFieldsValue(), true);
  };

  const onValuesChange = () => {
    setHasQuestionarioChanged(true);
  };

  const handleCancel = () => {
    onCancel();
    isEditing && handleDispatchReset();
  };

  const onBeforeCancel = () => {
    return hasQuestionarioChanged;
  };

  const handleFinishQuestionario = () => {
    form.validateFields().then((values) => {
      handleAnswerQuestionario(values);
    }).catch(Form.scrollToFirstError);
  };
  
  return (
    <Modal
      visible={visible} 
      onCancel={() => handleCancel()} 
      onBeforeCancel={onBeforeCancel}
      footer={(administrador || (tipo === 'BRIEFING' && isUsuarioCriador) || (tipo !== 'BRIEFING' && reportLogicUser)) ? undefined : null}
      numberOfButtons={isEditing ? 1 : 2}
      cancelText='Salvar alterações'
      cancelButtonProps={{ 
        onClick: () => { onCancel(); handleOnlyAnswerQuestionario(); }
      }}
      okText={isQuestionarioCompleted ? 'Concluído' : (tipo === 'BRIEFING' ? 'Finalizar briefing' : 'Finalizar report')}
      okButtonProps={isQuestionarioCompleted ? { type: 'success', loading: (tipo === 'BRIEFING' ? loadingFinishBriefingReply : loadingFinishReportReply), disabled: blockingSave } : { loading: (tipo === 'BRIEFING' ? loadingFinishBriefingReply : loadingFinishReportReply), disabled: blockingSave }}
      onOk={() => {
        handleFinishQuestionario();
      }}
      contrastFooter
      stickyHeader
      contrastHeader
      width={960}
      spaceBetweenTitleAndHeader
      backable
      title={(
        <Row gap='28px'>
          <Row>
            <ThinModalTitle>{loadingGetTicket ? <Skeleton.MinorLine/> : ticket?.questionario?.filter((quest) => quest.tipoQuestionario === tipo)[0]?.questionarioTitulo}</ThinModalTitle>
          </Row>
          {(!loadingGetTicket && !isQuestionarioCompleted) ? <StatusTag fixedSize text={ticket?.statusDs} color={ticket?.statusCor} size='large'/> : <Skeleton.ExtraButton/>}
        </Row>
      )}
      header={(
        <ColumnWithGap>
          <Row gap='33px'>
            <ProtocolText>{loadingGetTicket ? <Skeleton.MinorLine/> : ticket?.protocolo}</ProtocolText>
            <Row>
              <DetailLabel>{companyNomenclature.nomenclatura}:&nbsp;</DetailLabel>
              <DetailText>{ticket.nmUnidade}</DetailText>
            </Row>
            <Row>
              <DetailLabel>Categoria:&nbsp;</DetailLabel>
              <DetailText>{ticket.nmCategoriaCompleto}</DetailText>
            </Row>
          </Row>
        </ColumnWithGap>
      )}
    >
      <Container>
        {loadingGetTicket ? (
          <ContainerLoading>
            <Skeleton.MinorLine/>
            <Skeleton.SearchBar/>
            <Skeleton.MinorLine/>
            <Skeleton.SearchBar/>
            <Skeleton.MinorLine/>
            <Skeleton.SearchBar/>
          </ContainerLoading>  
        ) : (
          <View>
            <WarningText>
              <WarningIcon/>
              <span>
                <span className='danger-color'>*</span> você precisa preencher as informações do {tipo === 'BRIEFING' ? 'briefing' : 'report'} para dar sequência no seu atendimento
              </span>
            </WarningText>
            <Form layout='vertical' onValuesChange={onValuesChange} form={form} disabled={isQuestionarioCompleted || !isUsuarioCriador}> 
              {successGetTicket ? (
                ticket.questionario
                  .filter((quest) => quest.tipoQuestionario === tipo)
                  .map((quest) =>
                    quest.respostas && quest.respostas.map((answer, index) =>
                      handleRenderAsk(answer, index, form, isQuestionarioCompleted, isUsuarioCriador, setBlockingSave, tipo)
                    )
                  )
              ) : (
                <Skeleton.SearchBar/>
              )}
            </Form>
          </View>
        )}
      </Container>
    </Modal>
  );
}