import styled from 'styled-components';
import { Column , Icon } from '@components-teammove';

export const Container = styled(Column)`
  gap: 12px;
`;

export const Scrollable = styled(Container)`
  overflow-y: scroll;
  max-height: 500px;
`;

export const CollapseIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
`;