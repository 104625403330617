
export const formatParams = (params) => {
  const {
    profiles,
    initialDate,
    typePeriod,
  } = params;
  
  const profilesParam = profiles ? `profiles=${profiles.join(' ')}` : '';
  const initialDateParam = initialDate ? `start=${initialDate}` : '';
  const typePeriodParam = typePeriod ? `typePeriod=${typePeriod}` : '';
  
  return [
    profilesParam,
    initialDateParam,
    typePeriodParam
  ]
    .filter((param) => param)
    .join('&');
};