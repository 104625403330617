import React, { useEffect } from 'react';
import { Modal, Form, Input, TimePicker } from '@components-teammove';
import { moment } from '@utils';

const ModalTimePicker = ({ shifts, visible, onCancel, objCadastro, handleDelete }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if ((visible) && (objCadastro)) {
      form.resetFields();
      const { inicio, fim, ...rest } = objCadastro;

      if ((inicio) && (fim)) {
        rest.datas = [moment(inicio, 'HH:mm:ss'), moment(fim, 'HH:mm:ss')];
      }

      form.setFieldsValue(rest);
    }
  }, [visible]);

  const onOk = () => {
    form.submit();
  };

  const validateShifts = (rule, value) => {
    if ((value) && (value.length === 2)) {
      let isValueBetweenSavedShifts = false;
      if(shifts && shifts.length > 0) {
        const foundWorkingDay = shifts.find((item) => item.diaSemana === objCadastro.diaSemana);
        if(foundWorkingDay && foundWorkingDay.intervalos.length > 0) {
          isValueBetweenSavedShifts = foundWorkingDay.intervalos.some((intervalo) => {
            const dataInicio = value[0].format('HH:mm:ss');
            const dataFim = value[1].format('HH:mm:ss');
            if(objCadastro.id === intervalo.id) return false;
            return (dataInicio >= intervalo.inicio &&  dataInicio < intervalo.fim) || (dataFim > intervalo.inicio && dataFim <= intervalo.fim) || (dataInicio <= intervalo.inicio && dataFim >= intervalo.fim);
          });
        }
      }
      if(isValueBetweenSavedShifts) {
        return Promise.reject('Horário inválido');
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.reject('Informe o horário');
    }
  };

  return(
    <Modal 
      visible={visible} 
      title="Horário" 
      okText="Salvar" 
      onOk={onOk} 
      numberOfButtons={objCadastro?.id ? 2 : 1}
      onCancel={onCancel}
      cancelText='Excluir'
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Confirma exclusão?', onConfirm: () => handleDelete(objCadastro),  cancelText: 'Cancelar', okText: 'Confirmar' }}
      cancelButtonProps={{ type: 'danger', onClick: null, title: 'Excluir agrupador' }}
    >
      <Form form={form} layout="vertical" name="cadForm">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="diaSemana" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="datas" label="Horários" rules={[{ validator: validateShifts }]}
        >
          <TimePicker.RangePicker 
            autoFocus
            format="HH:mm" 
            ranges={{
              'Comercial (manhã)': [moment('08:00', 'HH:mm'), moment('12:00', 'HH:mm')],
              'Comercial (tarde)': [moment('13:30', 'HH:mm'), moment('18:00', 'HH:mm')],
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalTimePicker;
