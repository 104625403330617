import React from 'react';
import { IconHome, Title } from './styles';

const CampanhaBreadcrumb = (campaigns, onClickItem) => {
  
  if (!campaigns) return null;

  const routesMapped = campaigns.map((item, index) => ({
    index: index,
    id: item.id,
    externalId: item.externalId,
    name: item.name,
  }));

  return {
    separator: '>',
    itemRender: ({ index, id, externalId, name }) => (
      <Title key={index} onClick={() => onClickItem(externalId)}>{index === 0 && <IconHome />}{name}</Title>
    ),
    routes: [...routesMapped],
  };
};

export default CampanhaBreadcrumb;