import React from 'react';
import { Popconfirm } from 'antd';

const PopComponent = ({ children, ...otherProps }) => (
  <Popconfirm
    okText="Confirma"
    cancelText="Cancela"
    {...otherProps}
  >
    {children}
  </Popconfirm>
);

export default PopComponent;
