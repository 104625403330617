import React from 'react';
import { StyledProgressComponent } from './styles';

const StyledProgress = (
  { trailColor = '#201D2E', percent, reachedGoal = percent >= 100, showInfo = false, strokeWidth = 10, innerFontSize = '1.5em', format = (percent) => String(percent).replace('.', ',') + '%', successColor = '#70BF73', averageColor = '#FD9733', dangerColor = '#F04D4D', width = 75, height, ...props }
) => (
  <StyledProgressComponent 
    width={width}
    height={height}
    reachedGoal={reachedGoal}
    strokeColor={reachedGoal ? successColor :  percent >= 70 ? averageColor : dangerColor} 
    strokeWidth={strokeWidth}
    trailColor={trailColor}
    percent={String(percent).includes('.') ? percent.toFixed(1) : percent} 
    format={format}
    fontSize={innerFontSize}
    successColor={successColor}
    averageColor={averageColor}
    dangerColor={dangerColor}
    {...props}
  />
);

export default StyledProgress;