import React, { useEffect, useState } from 'react';
import App from '@app/';

import { Filtros, TelasNomenclaturas } from '@utils';
import { 
  Body, 
  PageHeader, 
  Breadcrumb, 
  ActiveFilters, 
  Button, 
  Column, 
  Filter,  
  DateControllerHeader,
  Row,
  Icon
} from '@components-teammove';
import { getAgrupadores } from '@sdk/Agrupadores';
import { getProductsByCompany } from '@sdk/Produtos';
import { getTiposUnidades } from '@sdk/TiposUnidades';

import { 
  PARAMS_KEY, 
  FILTERS_KEY, 
} from '@ducks/pedidosDashboard';
  
import { useSelector } from 'react-redux';
import { dynamicFilters, initialModalFilters } from './rules';
import OrderEvolution from './OrderEvolution';
import Cards from './Cards';
import Ranking from './Ranking';
import ParticipationsPerFamilies from './ParticipationsPerFamilies';

const DashboardPedidos = () => {
  const [ visibleFilterModal, setVisibleFilterModal ] = useState(false);
  const [ currentFilters, setCurrentFilters ] = useState(Filtros.get(FILTERS_KEY));
  const [ currentParams, setCurrentParams ] = useState(Filtros.get(PARAMS_KEY));
  const [ filtersModal, setFiltersModal] = useState(initialModalFilters);
  const [ agrupadores, setAgrupadores ] = useState(null);
  const [ categoriaProduto, setCategoriaProduto ] = useState(null);
  const [ produtos, setProdutos ] = useState(null);
  const [ tipoUnidade, setTipoUnidade ] = useState(null);
  const [ familias, setFamilias ] = useState(null);
  const [ defaultCategoriesProducts, setDefaultCategoriesProducts ] = useState(null);
  const [ defaultFamilies, setDefaultFamilies ] = useState(null);
  const [ defaultProducts, setDefaultProducts ] = useState(null);
  const [ fisrtFilter, setFirstFilter ] = useState(true);

  const theme = useSelector(({ app }) => app.get('theme'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  useEffect(()=> {
    (async () => {
      getAgrupadores()
        .then((resp) => {
          setAgrupadores(resp?.map(({ description, id }) => ({ label: description, value: id })));
        });
      getProductsByCompany()
        .then((resp) => {
          const categoriesProductResp = resp.map((res) => ({ value: res.id, label: res.name }));
          const familiesResp = resp.flatMap((res) => res.families || []).map((res) => ({ label: res.name, value: res.id }));
          const productsResp = resp.flatMap((res) => res.families || []).map((res) => res.products || []).flat().map((res) => ({ label: res.name, value: res.id }));
          setDefaultCategoriesProducts(resp);
          setDefaultFamilies(resp.map((res) => res.families || []).flat());
          setDefaultProducts(resp.map((res) => res.families || []).flat().map((res) => res.products || []).flat());
          setCategoriaProduto(categoriesProductResp);
          setFamilias(familiesResp);
          setProdutos(productsResp);
        });
      getTiposUnidades()
        .then((resp) => {
          setTipoUnidade(resp.map((res) => ({ label: res.name, value: res.id })));
        });
    })();
  },[]);

  useEffect(() => {
    if (agrupadores && categoriaProduto && produtos && tipoUnidade && familias) {
      setFiltersModal([...filtersModal.map((filter) => {
        if (filter.name === 'agrupadorUnidade') {
          return { ...filter, options: agrupadores };
        }

        if (filter.name === 'categoriaProduto') { 
          let filteredCategories = null;
          if(currentFilters?.familia && fisrtFilter) {
            filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) =>
              defaultCategoryProduct.families.some((defaultFamily) =>
                currentFilters?.familia.some((option) => option === defaultFamily.id)
              )
            );      
          } else if(currentFilters?.produto && fisrtFilter) {
            filteredCategories = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              defaultCategoryProduct.families.some((defaultFamily) => 
                defaultFamily.products.some((defaultProduct) =>     
                  currentFilters?.produto.some((option) => option === defaultProduct.id)
                )
              )
            );
          }
          const verifyCategoriesPorducts = filteredCategories && filteredCategories?.length > 0;
          if(verifyCategoriesPorducts) setCategoriaProduto(filteredCategories.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyCategoriesPorducts ? filteredCategories.map((res) => ({ label: res.name, value: res.id })) : categoriaProduto };
        }
        
        if (filter.name === 'produto') { 
          let filteredProducts = null;
          if(currentFilters?.familia && fisrtFilter) {

            filteredProducts = defaultFamilies.filter((defaultFamily) => 
              currentFilters?.familia.some((option) => option === defaultFamily.id)
            ).flatMap((defaultFamilyFiltered) => defaultFamilyFiltered.products);

          } else if (currentFilters?.categoriaProduto && fisrtFilter) {
            filteredProducts = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              currentFilters?.categoriaProduto.some((option) => option === defaultCategoryProduct.id)).flatMap((filteredProduct) => filteredProduct.families).flatMap((family) => family.products);
          }
          const verifyProducts = filteredProducts && filteredProducts?.length > 0;
          if(verifyProducts) setProdutos(filteredProducts.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyProducts ? filteredProducts.map((res) => ({ label: res.name, value: res.id })) : produtos };
        }
        
        if (filter.name === 'familia') {
          let filteredFamilies = null;
          if(currentFilters?.produto && fisrtFilter) {
            filteredFamilies = defaultFamilies.filter((defaultFamily) => 
              defaultFamily.products.some((product) =>   
                currentFilters?.produto.some((option) => option === product.id)
              )
            );
          } else if(currentFilters?.categoriaProduto && fisrtFilter) {
            filteredFamilies = defaultCategoriesProducts.filter((defaultCategoryProduct) => 
              currentFilters?.categoriaProduto.some((option) => option === defaultCategoryProduct.id)).flatMap((filteredProduct) => filteredProduct.families);                
          }
          const verifyFamilies = filteredFamilies && filteredFamilies?.length > 0;
          if(verifyFamilies) setFamilias(filteredFamilies.map((res) => ({ label: res.name, value: res.id })));
          return { ...filter, options: verifyFamilies ? filteredFamilies.map((res) => ({ label: res.name, value: res.id })) : familias };
        }

        if (filter.name === 'tipoUnidade') {
          return { ...filter, options: tipoUnidade };
        }
        setFirstFilter(false);
        return { ...filter };
      })]);
    }
  }, [agrupadores, categoriaProduto, produtos, tipoUnidade, familias]);

  useEffect(() => {
    Filtros.set(PARAMS_KEY, currentParams);
  }, [currentParams]);

  const handleChangePeriod = ({ typePeriod, initialDate, formattedTypePeriod }) => {
    setCurrentParams({ ...currentParams, typePeriod, initialDate, formattedRangeDate: formattedTypePeriod, typeValue: currentParams.typeValue ? currentParams.typeValue : 'value' });
  };

  const handleSearchChangingNewFilters = (filtros, fromTags) => {
    if (filtros?.descFiltros) {
      Object.keys(filtros.descFiltros).forEach((key) => {
        if (!filtros.descFiltros[key] || filtros.descFiltros[key].length === 0) {
          delete filtros.descFiltros[key];
        }
      });
    }
    if(fromTags) {
      if(!filtros?.categoriaProduto) {
        if(!filtros.familia) setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
        if(!filtros.produto) setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
      } 
      if(!filtros?.familia) {
        if(!filtros.categoriaProduto) setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
        if(!filtros.produto) setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
      } 
      if(!filtros?.produto) {
        if(!filtros.categoriaProduto) setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
        if(!filtros.familia) setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
      }
    }
    setCurrentFilters(filtros);
    handleSearch(filtros);
  };

  const handleSearch = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
  };

  const handleChangeSelectDependsOn = (typeField, options, filtrosState, updateFormFields) => {
    const defaultFamiliesFormated = defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id }));
    const defaultProductsFormated = defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id }));
    const defaultCategoriesProductsFormated = defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id }));

    dynamicFilters(typeField, options, filtrosState, updateFormFields, defaultCategoriesProducts, defaultFamilies, defaultCategoriesProductsFormated, defaultFamiliesFormated, defaultProductsFormated, setCategoriaProduto, setProdutos, setFamilias, filtersModal);
  };

  const handleClear = (clear) => {
    if(!clear) return;
    setCategoriaProduto(defaultCategoriesProducts.map((defaultCategorieProduct) => ({ label: defaultCategorieProduct.name, value: defaultCategorieProduct.id })));
    setFamilias(defaultFamilies.map((defaultFamily) => ({ label: defaultFamily.name, value: defaultFamily.id })));
    setProdutos(defaultProducts.map((defaultProduct) => ({ label: defaultProduct.name, value: defaultProduct.id })));
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Dashboard de Pedidos'}
          onBack={() => window.history.back()}
          extra={[
            <Button size='small' type='secondary' key='2' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilterModal(true)}>
                Filtros
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/produtos/dashboard-pedidos', name: 'Dashboard pedidos', current: true },
              ]}
            />
          )}
        >
          <ActiveFilters filters={filtersModal} filtros={currentFilters} onSearch={handleSearchChangingNewFilters}/>
          <Column gap='32px'>
            <DateControllerHeader
              onChange={handleChangePeriod}
              typePeriod={currentParams.typePeriod}
              initialDate={currentParams.initialDate}
              formattedTypePeriod={currentParams.formattedRangeDate}
            />
            <Cards currentFilters={currentFilters} currentParams={currentParams}/>
            <OrderEvolution currentFilters={currentFilters} currentParams={currentParams} setCurrentParams={setCurrentParams}/>
            <ParticipationsPerFamilies currentFilters={currentFilters} currentParams={currentParams}/>
            <Row gap='10px'>
              <Ranking tipo='Produto' currentFilters={currentFilters} currentParams={currentParams}/>
              <Ranking tipo={companyNomenclature.nomenclatura} currentFilters={currentFilters} currentParams={currentParams}/>
            </Row>
          </Column>       
        </PageHeader>
        {visibleFilterModal && (
          <Filter 
            visible={visibleFilterModal} 
            onClose={(clear) => { setVisibleFilterModal(false); handleClear(clear); }}
            filters={filtersModal}
            filtros={currentFilters}
            onSearch={handleSearchChangingNewFilters}
            onChangeDependsOnFilter={{
              categoriaProduto:  handleChangeSelectDependsOn ,
              familia: handleChangeSelectDependsOn,  
              produto: handleChangeSelectDependsOn,       
            }}
          />
        )}
      </Body>
    </App>
  );
};

export default DashboardPedidos;