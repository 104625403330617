import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getAtividades as getAtividadesSdk, 
  getAtividade as getAtividadeSdk, 
  getAtividadeDetalhe as getAtividadeDetalheSdk,
  getDadosCadastroAtividade as getDadosCadastroSdk,
  postAtividade as postAtividadeSdk,
  putAtividade as updateAtividadeSdk,
  finishAtividade as finishAtividadeSdk,
  cancelAtividade as cancelAtividadeSdk,
  reopenAtividade as reopenAtividadeSdk,
  getUsersByCompany as getUsersByCompanySdk
} from '@sdk/Atividades';

export const FILTERS_KEY = 'ATIVIDADES';

//Action Types
export const Types = {
  GET_ATIVIDADES: 'ATIVIDADES/GET_ATIVIDADES',
  GET_ATIVIDADES_SUCCESS: 'ATIVIDADES/GET_ATIVIDADES_SUCCESS',
  GET_ATIVIDADES_ERROR: 'ATIVIDADES/GET_ATIVIDADES_ERROR',

  POST_ATIVIDADE: 'ATIVIDADES/POST_ATIVIDADE',
  POST_ATIVIDADE_SUCCESS: 'ATIVIDADES/POST_ATIVIDADE_SUCCESS',
  POST_ATIVIDADE_ERROR: 'ATIVIDADES/POST_ATIVIDADE_ERROR',

  UPDATE_ATIVIDADE: 'ATIVIDADES/UPDATE_ATIVIDADE',
  UPDATE_ATIVIDADE_SUCCESS: 'ATIVIDADES/UPDATE_ATIVIDADE_SUCCESS',
  UPDATE_ATIVIDADE_ERROR: 'ATIVIDADES/UPDATE_ATIVIDADE_ERROR',

  FINISH_ATIVIDADE: 'ATIVIDADES/FINISH_ATIVIDADE',
  FINISH_ATIVIDADE_SUCCESS: 'ATIVIDADES/FINISH_ATIVIDADE_SUCCESS',
  FINISH_ATIVIDADE_ERROR: 'ATIVIDADES/FINISH_ATIVIDADE_ERROR',

  CANCEL_ATIVIDADE: 'ATIVIDADES/CANCEL_ATIVIDADE',
  CANCEL_ATIVIDADE_SUCCESS: 'ATIVIDADES/CANCEL_ATIVIDADE_SUCCESS',
  CANCEL_ATIVIDADE_ERROR: 'ATIVIDADES/CANCEL_ATIVIDADE_ERROR',

  REOPEN_ATIVIDADE: 'ATIVIDADES/REOPEN_ATIVIDADE',
  REOPEN_ATIVIDADE_SUCCESS: 'ATIVIDADES/REOPEN_ATIVIDADE_SUCCESS',
  REOPEN_ATIVIDADE_ERROR: 'ATIVIDADES/REOPEN_ATIVIDADE_ERROR',

  DELETE_ATIVIDADE: 'ATIVIDADES/DELETE_ATIVIDADE',
  DELETE_ATIVIDADE_SUCCESS: 'ATIVIDADES/DELETE_ATIVIDADE_SUCCESS',
  DELETE_ATIVIDADE_ERROR: 'ATIVIDADES/DELETE_ATIVIDADE_ERROR',

  GET_ATIVIDADE: 'ATIVIDADES/GET_ATIVIDADE',
  GET_ATIVIDADE_SUCCESS: 'ATIVIDADES/GET_ATIVIDADE_SUCCESS',
  GET_ATIVIDADE_ERROR: 'ATIVIDADES/GET_ATIVIDADE_ERROR',

  GET_ATIVIDADE_DETALHE: 'ATIVIDADES/GET_ATIVIDADE_DETALHE',
  GET_ATIVIDADE_DETALHE_SUCCESS: 'ATIVIDADES/GET_ATIVIDADE_DETALHE_SUCCESS',
  GET_ATIVIDADE_DETALHE_ERROR: 'ATIVIDADES/GET_ATIVIDADE_DETALHE_ERROR',

  GET_DADOS_CADASTRO: 'ATIVIDADES/GET_DADOS_CADASTRO',
  GET_DADOS_CADASTRO_SUCCESS: 'ATIVIDADES/GET_DADOS_CADASTRO_SUCCESS',
  GET_DADOS_CADASTRO_ERROR: 'ATIVIDADES/GET_DADOS_CADASTRO_ERROR',

  GET_USERS_BY_COMPANY: 'ATIVIDADES/GET_USER_BY_COMPANY',
  GET_USERS_BY_COMPANY_SUCCESS: 'ATIVIDADES/GET_USER_BY_COMPANY_SUCCESS',
  GET_USERS_BY_COMPANY_ERROR: 'ATIVIDADES/GET_USER_BY_COMPANY_ERROR',

  RESET: 'ATIVIDADES/RESET',
  RESET_ATIVIDADE: 'ATIVIDADES/RESET_ATIVIDADE',
  RESET_CANCEL_AND_REOPEN: 'ATIVIDADES/RESET_CANCEL_AND_REOPEN',
};

//Action Creators
export const getAtividades = ({ filtros, limit, offset, search, orderBy, orderDirection, allowGoals, useFinishedDateOnFinishedActivities }) => ({ type: Types.GET_ATIVIDADES, filtros, limit, offset, search, orderBy, orderDirection, allowGoals, useFinishedDateOnFinishedActivities });
export const getAtividadesSuccess = ({ activities, total }) => ({ type: Types.GET_ATIVIDADES_SUCCESS, activities, total });
export const getAtividadesError = (error) => ({ type: Types.GET_ATIVIDADES_ERROR, error }); 

export const postAtividade = (atividade) => ({ type: Types.POST_ATIVIDADE, atividade });
export const postAtividadeSuccess = (atividade) => ({ type: Types.POST_ATIVIDADE_SUCCESS, atividade });
export const postAtividadeError = (error) => ({ type: Types.POST_ATIVIDADE_ERROR, error });

export const updateAtividade = (atividade) => ({ type: Types.UPDATE_ATIVIDADE, atividade });
export const updateAtividadeSuccess = (atividade) => ({ type: Types.UPDATE_ATIVIDADE_SUCCESS, atividade });
export const updateAtividadeError = (error) => ({ type: Types.UPDATE_ATIVIDADE_ERROR, error });

export const finishAtividade = (id, observation) => ({ type: Types.FINISH_ATIVIDADE, id, observation });
export const finishAtividadeSuccess = (atividade) => ({ type: Types.FINISH_ATIVIDADE_SUCCESS, atividade });
export const finishAtividadeError = (error) => ({ type: Types.FINISH_ATIVIDADE_ERROR, error });

export const cancelAtividade = (id, observation) => ({ type: Types.CANCEL_ATIVIDADE, id, observation });
export const cancelAtividadeSuccess = (atividade) => ({ type: Types.CANCEL_ATIVIDADE_SUCCESS, atividade });
export const cancelAtividadeError = (error) => ({ type: Types.CANCEL_ATIVIDADE_ERROR, error });

export const reopenAtividade = (id, observation) => ({ type: Types.REOPEN_ATIVIDADE, id, observation });
export const reopenAtividadeSuccess = (atividade) => ({ type: Types.REOPEN_ATIVIDADE_SUCCESS, atividade });
export const reopenAtividadeError = (error) => ({ type: Types.REOPEN_ATIVIDADE_ERROR, error });

export const deleteAtividade = (id) => ({ type: Types.DELETE_ATIVIDADE, id });
export const deleteAtividadeSuccess = () => ({ type: Types.DELETE_ATIVIDADE_SUCCESS });
export const deleteAtividadeError = (error) => ({ type: Types.DELETE_ATIVIDADE_ERROR, error });

export const getAtividade = (id) => ({ type: Types.GET_ATIVIDADE, id });
export const getAtividadeSuccess = (atividade) => ({ type: Types.GET_ATIVIDADE_SUCCESS, atividade });
export const getAtividadeError = (error) => ({ type: Types.GET_ATIVIDADE_ERROR, error });

export const getAtividadeDetalhe = (id) => ({ type: Types.GET_ATIVIDADE_DETALHE, id });
export const getAtividadeDetalheSuccess = (atividade) => ({ type: Types.GET_ATIVIDADE_DETALHE_SUCCESS, atividade });
export const getAtividadeDetalheError = (error) => ({ type: Types.GET_ATIVIDADE_DETALHE_ERROR, error });

export const getDadosCadastro = () => ({ type: Types.GET_DADOS_CADASTRO });
export const getDadosCadastroSuccess = (dados) => ({ type: Types.GET_DADOS_CADASTRO_SUCCESS, dados });
export const getDadosCadastroError = (error) => ({ type: Types.GET_DADOS_CADASTRO_ERROR, error });

export const getUsersByCompany = (companyId) => ({ type: Types.GET_USERS_BY_COMPANY, companyId });
export const getUsersByCompanySuccess = (users) => ({ type: Types.GET_USERS_BY_COMPANY_SUCCESS, users });
export const getUsersByCompanyError = (error) => ({ type: Types.GET_USERS_BY_COMPANY_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetAtividade = () => ({ type: Types.RESET_ATIVIDADE });
export const resetCancelAndReopen = () => ({ type: Types.RESET_CANCEL_AND_REOPEN });

//saga
function* fetchGetAtividades(action) {
  try {
    const { filtros, limit, offset, search, orderBy, orderDirection, allowGoals, useFinishedDateOnFinishedActivities } = action;
    const activities = yield call(getAtividadesSdk, { filtros, limit, offset, search, orderBy, orderDirection, allowGoals, useFinishedDateOnFinishedActivities });
    yield put(getAtividadesSuccess(activities));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtividadesError(err));
  }
}

function* fetchGetAtividade(action) {
  try {
    const { id } = action;
    const atividade = yield call(getAtividadeSdk, id);
    yield put(getAtividadeSuccess(atividade));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtividadeError(err));
  }
}

function* fetchGetAtividadeDetalhe(action) {
  try {
    const { id } = action;
    const atividade = yield call(getAtividadeDetalheSdk, id);
    yield put(getAtividadeDetalheSuccess(atividade));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAtividadeDetalheError(err));
  }
}

function* fetchGetDadosCadastro(action) {
  try {
    const dados = yield call(getDadosCadastroSdk);
    yield put(getDadosCadastroSuccess(dados));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDadosCadastroError(err));
  }
}

function* fetchPostAtividade(action) {
  let { atividade } = action;
  try {
    const response = yield call(postAtividadeSdk, atividade);
    if (typeof response === 'string') {
      Notification.error(response);
      yield put(postAtividadeError(response));  
    } else {
      yield put(postAtividadeSuccess(response));
    }
  } catch (err) {
    Notification.error(err.message);
    yield put(postAtividadeError(err));
  }
}

function* fetchUpdateAtividade(action) {
  try {
    let { atividade } = action;

    const response = yield call(updateAtividadeSdk, atividade);
    if (typeof response === 'string') {
      Notification.error(response);
      yield put(updateAtividadeError(response));  
    } else {
      yield put(updateAtividadeSuccess(response));
    }
  } catch (err) {
    Notification.error(err.message);
    yield put(updateAtividadeError(err));
  }
}

function* fetchFinishAtividade(action) {
  try {
    let { id, observation } = action;

    const answer = yield call(finishAtividadeSdk, id, observation);
    yield put(finishAtividadeSuccess(answer));
  } catch (err) {
    Notification.error(err.message);
    yield put(finishAtividadeError(err));
  }
}

function* fetchCancelAtividade(action) {
  try {
    let { id, observation } = action;

    const answer = yield call(cancelAtividadeSdk, id, observation);
    yield put(cancelAtividadeSuccess(answer));
  } catch (err) {
    Notification.error(err.message);
    yield put(cancelAtividadeError(err));
  }
}

function* fetchReopenAtividade(action) {
  try {
    let { id, observation } = action;

    const answer = yield call(reopenAtividadeSdk, id, observation);
    yield put(reopenAtividadeSuccess(answer));
  } catch (err) {
    Notification.error(err.message);
    yield put(reopenAtividadeError(err));
  }
}

function* fetchGetUsersByCompany(action) {
  try {
    const { companyId } = action;
    const users = yield call(getUsersByCompanySdk, companyId);
    yield put(getUsersByCompanySuccess(users));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUsersByCompanyError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ATIVIDADES, fetchGetAtividades),
  takeLatest(Types.GET_ATIVIDADE, fetchGetAtividade),
  takeLatest(Types.GET_ATIVIDADE_DETALHE, fetchGetAtividadeDetalhe),
  takeLatest(Types.GET_DADOS_CADASTRO, fetchGetDadosCadastro),
  takeLatest(Types.POST_ATIVIDADE, fetchPostAtividade),
  takeLatest(Types.UPDATE_ATIVIDADE, fetchUpdateAtividade),
  takeLatest(Types.FINISH_ATIVIDADE, fetchFinishAtividade),
  takeLatest(Types.CANCEL_ATIVIDADE, fetchCancelAtividade),
  takeLatest(Types.REOPEN_ATIVIDADE, fetchReopenAtividade),
  takeLatest(Types.GET_USERS_BY_COMPANY, fetchGetUsersByCompany)
];

// Reducer
const initialState = Map({
  atividades: List(),
  loadingAtividades: false,
  successAtividades: false,
  errorAtividades: false,
  total: 0,
  atividade: Map(),
  loading: false,
  success: false,
  error: false,
  atividadeDetalhe: Map(),
  loadingDetalhe: false,
  successDetalhe: false,
  errorDetalhe: false,
  dadosCadastro: Map(),
  loadingDadosCadastro: false,
  successDadosCadastro: false,
  errorDadosCadastro: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingFinish: false,
  successFinish: false,
  errorFinish: false,
  loadingCancel: false,
  successCancel: false,
  errorCancel: false,
  loadingReopen: false,
  successReopen: false,
  errorReopen: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,

  usersByCompany: List(),
  loadingUsersByCompany: false,
  successUsersByCompany: false,
  errorUsersByCompany: false,
});

const handleGetAtividades = (state, action) => {
  return state.set('loadingAtividades', true).set('successAtividades', false).set('errorAtividades', false);
};

const handleGetAtividadesSuccess = (state, action) => {
  const { activities, total } = action;

  return state.set('atividades', activities).set('total', total).set('loadingAtividades', false).set('successAtividades', true).set('errorAtividades', false);
};

const handleGetAtividadesError = (state, action) => {
  return state.set('loadingAtividades', false).set('successAtividades', false).set('errorAtividades', action.error);
};

const handleGetAtividade = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetAtividadeSuccess = (state, action) => {
  const { atividade } = action;

  return state.set('atividade', atividade).set('loading', false).set('success', true).set('error', false);
};

const handleGetAtividadeError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetAtividadeDetalhe = (state, action) => {
  return state.set('loadingDetalhe', true).set('successDetalhe', false).set('errorDetalhe', false);
};

const handleGetAtividadeDetalheSuccess = (state, action) => {
  const { atividade } = action;

  return state.set('atividadeDetalhe', atividade).set('loadingDetalhe', false).set('successDetalhe', true).set('errorDetalhe', false);
};

const handleGetAtividadeDetalheError = (state, action) => {
  return state.set('loadingDetalhe', false).set('successDetalhe', false).set('errorDetalhe', action.error);
};

const handleGetDadosCadastro = (state, action) => {
  return state.set('loadingDadosCadastro', true).set('successDadosCadastro', false).set('errorDadosCadastro', false);
};

const handleGetDadosCadastroSuccess = (state, action) => {
  const { dados } = action;

  return state.set('dadosCadastro', dados).set('loadingDadosCadastro', false).set('successDadosCadastro', true).set('errorDadosCadastro', false);
};

const handleGetDadosCadastroError = (state, action) => {
  return state.set('loadingDadosCadastro', false).set('successDadosCadastro', false).set('errorDadosCadastro', action.error);
};

const handleDeleteAtividade = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteAtividadeSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteAtividadeError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSaveAtividade = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveAtividadeSuccess = (state, action) => {
  return state.set('loadingSave', false).set('atividade', action.atividade).set('successSave', true).set('errorSave', false);
};

const handleSaveAtividadeError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleFinishAtividade = (state, action) => {
  return state.set('loadingFinish', true).set('successFinish', false).set('errorFinish', false);
};

const handleFinishAtividadeSuccess = (state, action) => {
  return state.set('loadingFinish', false).set('successFinish', true).set('errorFinish', false);
};

const handleFinishAtividadeError = (state, action) => {
  return state.set('loadingFinish', false).set('successFinish', false).set('errorFinish', action.error);
};

const handleCancelAtividade = (state, action) => {
  return state.set('loadingCancel', true).set('successCancel', false).set('errorCancel', false);
};

const handleCancelAtividadeSuccess = (state, action) => {
  return state.set('loadingCancel', false).set('successCancel', true).set('errorCancel', false);
};

const handleCancelAtividadeError = (state, action) => {
  return state.set('loadingCancel', false).set('successCancel', false).set('errorCancel', action.error);
};

const handleReopenAtividade = (state, action) => {
  return state.set('loadingReopen', true).set('successReopen', false).set('errorReopen', false);
};

const handleReopenAtividadeSuccess = (state, action) => {
  return state.set('loadingReopen', false).set('successReopen', true).set('errorReopen', false);
};

const handleReopenAtividadeError = (state, action) => {
  return state.set('loadingReopen', false).set('successReopen', false).set('errorReopen', action.error);
};

const handleGetUsersByCompany = (state, action) => {
  return state
    .set('loadingUsersByCompany', true)
    .set('successUsersByCompany', false)
    .set('errorUsersByCompany', false);
};

const handleGetUsersByCompanySuccess = (state, action)=>{
  return state
    .set('usersByCompany', action.users)
    .set('loadingUsersByCompany', false)
    .set('successUsersByCompany', true)
    .set('errorUsersByCompany', false);
};

const handleGetUsersByCompanyError = (state, action)=>{
  return state
    .set('loadingUsersByCompany', false)
    .set('successUsersByCompany', false)
    .set('errorUsersByCompany', action.error);
};
const handleReset = (state, action) => {
  return initialState;
};

const handleResetAtividade = (state, action) => {
  return state
    .set('atividade', initialState.get('atividade'))
    .set('loading', initialState.get('loading'))
    .set('success', initialState.get('success'))
    .set('error', initialState.get('error'))
    .set('successSave', initialState.get('successSave'))
    .set('loadingSave', initialState.get('loadingSave'))
    .set('errorSave', initialState.get('errorSave'))
    .set('successDelete', initialState.get('successDelete'))
    .set('loadingDelete', initialState.get('loadingDelete'))
    .set('errorDelete', initialState.get('errorDelete'))
    .set('successDadosCadastro', initialState.get('successDadosCadastro'))
    .set('loadingDadosCadastro', initialState.get('loadingDadosCadastro'))
    .set('errorDadosCadastro', initialState.get('errorDadosCadastro'))
    .set('usersByCompany', initialState.get('usersByCompany'))
    .set('loadingUsersByCompany', initialState.get('loadingUsersByCompany'))
    .set('successUsersByCompany', initialState.get('successUsersByCompany'))
    .set('errorUsersByCompany', initialState.get('errorUsersByCompany'));
};

const handleResetCancelAndReopen = (state, action) => {
  return state
    .set('successCancel', initialState.get('successCancel'))
    .set('successReopen', initialState.get('successReopen'))
    .set('errorCancel', initialState.get('errorCancel'))
    .set('errorReopen', initialState.get('errorReopen'));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ATIVIDADES:
      return handleGetAtividades(state, action);
    case Types.GET_ATIVIDADES_SUCCESS:
      return handleGetAtividadesSuccess(state, action);
    case Types.GET_ATIVIDADES_ERROR:
      return handleGetAtividadesError(state, action);
    case Types.GET_ATIVIDADE:
      return handleGetAtividade(state, action);
    case Types.GET_ATIVIDADE_SUCCESS:
      return handleGetAtividadeSuccess(state, action);
    case Types.GET_ATIVIDADE_ERROR:
      return handleGetAtividadeError(state, action);
    case Types.GET_ATIVIDADE_DETALHE:
      return handleGetAtividadeDetalhe(state, action);
    case Types.GET_ATIVIDADE_DETALHE_SUCCESS:
      return handleGetAtividadeDetalheSuccess(state, action);
    case Types.GET_ATIVIDADE_DETALHE_ERROR:
      return handleGetAtividadeDetalheError(state, action);
    case Types.GET_DADOS_CADASTRO:
      return handleGetDadosCadastro(state, action);
    case Types.GET_DADOS_CADASTRO_SUCCESS:
      return handleGetDadosCadastroSuccess(state, action);
    case Types.GET_DADOS_CADASTRO_ERROR:
      return handleGetDadosCadastroError(state, action);
    case Types.DELETE_ATIVIDADE:
      return handleDeleteAtividade(state, action);
    case Types.DELETE_ATIVIDADE_SUCCESS:
      return handleDeleteAtividadeSuccess(state, action);
    case Types.DELETE_ATIVIDADE_ERROR:
      return handleDeleteAtividadeError(state, action);
    case Types.POST_ATIVIDADE:
      return handleSaveAtividade(state, action);
    case Types.POST_ATIVIDADE_SUCCESS:
      return handleSaveAtividadeSuccess(state, action);
    case Types.POST_ATIVIDADE_ERROR:
      return handleSaveAtividadeError(state, action);
    case Types.UPDATE_ATIVIDADE:
      return handleSaveAtividade(state, action);
    case Types.UPDATE_ATIVIDADE_SUCCESS:
      return handleSaveAtividadeSuccess(state, action);
    case Types.UPDATE_ATIVIDADE_ERROR:
      return handleSaveAtividadeError(state, action);
    case Types.FINISH_ATIVIDADE:
      return handleFinishAtividade(state, action);
    case Types.FINISH_ATIVIDADE_SUCCESS:
      return handleFinishAtividadeSuccess(state, action);
    case Types.FINISH_ATIVIDADE_ERROR:
      return handleFinishAtividadeError(state, action);
    case Types.CANCEL_ATIVIDADE:
      return handleCancelAtividade(state, action);
    case Types.CANCEL_ATIVIDADE_SUCCESS:
      return handleCancelAtividadeSuccess(state, action);
    case Types.CANCEL_ATIVIDADE_ERROR:
      return handleCancelAtividadeError(state, action);
    case Types.REOPEN_ATIVIDADE:
      return handleReopenAtividade(state, action);
    case Types.REOPEN_ATIVIDADE_SUCCESS:
      return handleReopenAtividadeSuccess(state, action);
    case Types.REOPEN_ATIVIDADE_ERROR:
      return handleReopenAtividadeError(state, action);

    case Types.GET_USERS_BY_COMPANY:
      return handleGetUsersByCompany(state, action);
    case Types.GET_USERS_BY_COMPANY_SUCCESS:
      return handleGetUsersByCompanySuccess(state, action);
    case Types.GET_USERS_BY_COMPANY_ERROR:
      return handleGetUsersByCompanyError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_ATIVIDADE:
      return handleResetAtividade(state, action);
    case Types.RESET_CANCEL_AND_REOPEN:
      return handleResetCancelAndReopen(state, action);

    default:
      return state;
  }
}