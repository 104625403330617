import React from 'react';
import { Modal } from '@components-teammove';
import { ViewConatiner, TextModal } from '../styles';
import { useSelector } from 'react-redux';

const ChangeMonthModal = ({ visible, onCancel, onOk }) => {
  const loadingSave = useSelector(({ configuracoesMetaAtividades }) => configuracoesMetaAtividades.get('loadingSave'));

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title='Confirmação'
      cancelText='Cancelar'
      okText='Salvar'
      onOk={onOk}
      okButtonProps={{ loading: loadingSave }}

    >
      <ViewConatiner>
        <TextModal>Você tem metas cadastradas, deseja salvar as  metas cadastradas deste mês?</TextModal>
      </ViewConatiner>
    </Modal>
  );
};

export default ChangeMonthModal;