import styled from 'styled-components';
import { Column } from '@components-teammove';

export const Container = styled(Column)`
  background-color: ${({ theme })=> theme.darkPrimaryColor};
  border-radius: 18px;
  padding: 20px 20px 17px 20px;
`;

export const VerticalDivider = styled.div`
  border-left: 2px solid #D9D9D9};
  height: 44px;
`;