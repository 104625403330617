import React, { useState } from 'react';
import { Modal, Form, Select } from '@components-teammove';
import { TelasNomenclaturas, manipulationHeight } from '@utils';
import { ClusterColumnsTitle, DescriptionDeleteModal, FormItem } from '../styles';

const ModalMoverUnidades = ({ clustersOptions, clusterToRemove, visibleConfirmExclusionModal, onClose }) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [newClusterId, setNewClusterId] = useState(null);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const confirmDelete = () => {
    onClose(newClusterId);
  };

  return (
    <Modal
      visible={visibleConfirmExclusionModal}
      onCancel={onClose}
      title={`Excluir cluster ${clusterToRemove.nome}`}
      onOk={confirmDelete}
      okText='Mover e excluir'
    >
      <ClusterColumnsTitle>Você tem {clusterToRemove.numberOfCompanies} {clusterToRemove.numberOfCompanies > 1 ? (companyNomenclature.plural).toLowerCase() : (companyNomenclature.nomenclatura).toLowerCase()} vinculad{companyNomenclature.artigo}s</ClusterColumnsTitle>
      <DescriptionDeleteModal>Para excluir um cluster você precisa mover {companyNomenclature.artigo}s {(companyNomenclature.plural).toLowerCase()} vinculad{companyNomenclature.artigo}s para outro cluster</DescriptionDeleteModal>
      <Form layout='vertical'>
        <FormItem selectOpen={openSelect} dropdownHeight={dropdownHeight} name='clusterGroup' rules={[{ required: true, message: 'Informe o cluster' }]} label={'Clusters'}>
          <Select
            value={newClusterId}
            onChange={(value) => setNewClusterId(value)}
            placeholder='Selecione o cluster' 
            options={clustersOptions}
            onDropdownVisibleChange={(open) => manipulationHeight(open, setOpenSelect, setDropdownHeight, clustersOptions)}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ModalMoverUnidades;