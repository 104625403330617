import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getAgendaManagementSummary as getAgendaManagementSummarySdk,
  getAgendaManagementUsers as getAgendaManagementUsersSdk,
  getUserActivityTypeGroupsSummary as getUserActivityTypeGroupsSummarySdk
} from '@sdk/AtividadesGestaoAgenda';

export const FILTERS_KEY = 'ATIVIDADES_GESTAO_AGENDA';
export const PARAMS_KEY = 'ATIVIDADES_GESTAO_AGENDA_PARAMS';

export const Types = {
  GET_GESTAO_MANAGEMENT_USERS: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USERS',
  GET_GESTAO_MANAGEMENT_USERS_SUCCESS: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USERS_SUCCESS',
  GET_GESTAO_MANAGEMENT_USERS_ERROR: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USERS_ERROR',

  GET_GESTAO_MANAGEMENT_SUMMARY: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_SUMMARY',
  GET_GESTAO_MANAGEMENT_SUMMARY_SUCCESS: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_SUMMARY_SUCCESS',
  GET_GESTAO_MANAGEMENT_SUMMARY_ERROR: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_SUMMARY_ERROR',

  GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY',
  GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_SUCCESS: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_SUCCESS',
  GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_ERROR: 'ATIVIDADES/GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_ERROR',
};

//Action Creators4
export const getAgendaManagementUsers = (params) => ({ type: Types.GET_GESTAO_MANAGEMENT_USERS, params });
export const getAgendaManagementUsersSuccess = (gestaoAgendaManagementUsers) => ({ type: Types.GET_GESTAO_MANAGEMENT_USERS_SUCCESS, gestaoAgendaManagementUsers });
export const getAgendaManagementUsersError = (error) => ({ type: Types.GET_GESTAO_MANAGEMENT_USERS_ERROR, error });

export const getAgendaManagementSummary = (params, idUser) => ({ type: Types.GET_GESTAO_MANAGEMENT_SUMMARY, params, idUser });
export const getAgendaManagementSummarySuccess = (gestaoAgendaManagementSummary) => ({ type: Types.GET_GESTAO_MANAGEMENT_SUMMARY_SUCCESS, gestaoAgendaManagementSummary });
export const getAgendaManagementSummaryError = (error) => ({ type: Types.GET_GESTAO_MANAGEMENT_SUMMARY_ERROR, error });

export const getUserActivityTypeGroupsSummary = (userId, params) => ({ type: Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY, userId, params });
export const getUserActivityTypeGroupsSummarySuccess = (userActivityTypeGroupsSummary) => ({ type: Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_SUCCESS, userActivityTypeGroupsSummary });
export const getUserActivityTypeGroupsSummaryError = (error) => ({ type: Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_ERROR, error });
//saga
function* fetchAgendaManagementUsers(action) {
  try { 
    const { params } = action;
    const gestaoAgendaManagementUsers = yield call(getAgendaManagementUsersSdk, params);
    yield put(getAgendaManagementUsersSuccess(gestaoAgendaManagementUsers));

  } catch (err) {
    Notification.error(err.message);
    yield put(getAgendaManagementUsersError(err));
  }
}

function* fetchAgendaManagementSummary(action) {
  try { 
    const { params, idUser } = action;

    const gestaoAgendaManagementSummary = yield call(getAgendaManagementSummarySdk, params, idUser);
    yield put(getAgendaManagementSummarySuccess(gestaoAgendaManagementSummary));

  } catch (err) {
    Notification.error(err.message);
    yield put(getAgendaManagementSummaryError(err));
  }
}

function* fetchUserActivityTypeGroupsSummary(action) {
  try { 
    const { userId, params } = action;
    const userActivityTypeGroupsSummary = yield call(getUserActivityTypeGroupsSummarySdk, userId, params);
    yield put(getUserActivityTypeGroupsSummarySuccess(userActivityTypeGroupsSummary));

  } catch (err) {
    Notification.error(err.message);
    yield put(getUserActivityTypeGroupsSummaryError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_GESTAO_MANAGEMENT_USERS, fetchAgendaManagementUsers),
  takeLatest(Types.GET_GESTAO_MANAGEMENT_SUMMARY, fetchAgendaManagementSummary),
  takeLatest(Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY, fetchUserActivityTypeGroupsSummary),
];

// Reducer
const initialState = Map({  
  gestaoAgendaManagementUsers: List(),
  loadingGestaoAgendaManagementUsers: false,
  successGestaoAgendaManagementUsers: false,
  errorGestaoAgendaManagementUsers: false,

  gestaoAgendaManagementSummary: List(),
  loadingGestaoAgendaManagementSummary: false,
  successGestaoAgendaManagementSummary: false,
  errorGestaoAgendaManagementSummary: false,

  userActivityTypeGroupsSummary: List(),
  loadingUserActivityTypeGroupsSummary: false,
  successUserActivityTypeGroupsSummary: false,
  errorUserActivityTypeGroupsSummary: false,
});

const handleGetAgendaManagementUsers = (state, action) => {
  return state
    .set('loadingGestaoAgendaManagementUsers', true)
    .set('successGestaoAgendaManagementUsers', false)
    .set('errorGestaoAgendaManagementUsers', false);
};

const handleGetAgendaManagementUsersSuccess = (state, action) => {
  const { gestaoAgendaManagementUsers } = action;
  return state
    .set('gestaoAgendaManagementUsers', gestaoAgendaManagementUsers)
    .set('loadingGestaoAgendaManagementUsers', false)
    .set('successGestaoAgendaManagementUsers', true)
    .set('errorGestaoAgendaManagementUsers', false);
};

const handleGetAgendaManagementUsersError = (state, action) => {
  return state
    .set('loadingGestaoAgendaManagementUsers', false)
    .set('successGestaoAgendaManagementUsers', false)
    .set('errorGestaoAgendaManagementUsers', action.error);
};
const handleGetGestaoAgendaManagementSummary = (state, action) => {
  return state    
    .set('loadingGestaoAgendaManagementSummary', true)
    .set('successGestaoAgendaManagementSummary', false)
    .set('errorGestaoAgendaManagementSummary', false);
};

const handleGetGestaoAgendaManagementSummarySuccess = (state, action) => {
  const { gestaoAgendaManagementSummary } = action;

  return state
    .set('gestaoAgendaManagementSummary', gestaoAgendaManagementSummary)
    .set('loadingGestaoAgendaManagementSummary', false)
    .set('successGestaoAgendaManagementSummary', true)
    .set('errorGestaoAgendaManagementSummary', false);
};

const handleGetGestaoAgendaManagementSummaryError = (state, action) => {
  return state
    .set('loadingGestaoAgendaManagementSummary', false)
    .set('successGestaoAgendaManagementSummary', false)
    .set('errorGestaoAgendaManagementSummary', action.error);
};

const handleGetUserActivityTypeGroupsSummary = (state, action) => {
  return state
    .set('loadingUserActivityTypeGroupsSummary', true)
    .set('successUserActivityTypeGroupsSummary', false)
    .set('errorUserActivityTypeGroupsSummary', false);
};

const handleGetUserActivityTypeGroupsSummarySuccess = (state, action) => {
  const { userActivityTypeGroupsSummary } = action;
  return state
    .set('userActivityTypeGroupsSummary', userActivityTypeGroupsSummary)
    .set('loadingUserActivityTypeGroupsSummary', false)
    .set('successUserActivityTypeGroupsSummary', true)
    .set('errorUserActivityTypeGroupsSummary', false);
};

const handleGetUserActivityTypeGroupsSummaryError = (state, action) => {
  return state
    .set('loadingUserActivityTypeGroupsSummary', false)
    .set('successUserActivityTypeGroupsSummary', false)
    .set('errorUserActivityTypeGroupsSummary', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_GESTAO_MANAGEMENT_USERS: return handleGetAgendaManagementUsers(state, action);
    case Types.GET_GESTAO_MANAGEMENT_USERS_SUCCESS: return handleGetAgendaManagementUsersSuccess(state, action);
    case Types.GET_GESTAO_MANAGEMENT_USERS_ERROR: return handleGetAgendaManagementUsersError(state, action);

    case Types.GET_GESTAO_MANAGEMENT_SUMMARY: return handleGetGestaoAgendaManagementSummary(state, action);
    case Types.GET_GESTAO_MANAGEMENT_SUMMARY_SUCCESS: return handleGetGestaoAgendaManagementSummarySuccess(state, action);
    case Types.GET_GESTAO_MANAGEMENT_SUMMARY_ERROR: return handleGetGestaoAgendaManagementSummaryError(state, action);

    case Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY: return handleGetUserActivityTypeGroupsSummary(state, action);
    case Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_SUCCESS: return handleGetUserActivityTypeGroupsSummarySuccess(state, action);
    case Types.GET_GESTAO_MANAGEMENT_USER_ACTIVITY_TYPE_GROUP_SUMMARY_ERROR: return handleGetUserActivityTypeGroupsSummaryError(state, action);

    default: 
      return state;
  }
}
