import { get, put, post } from '../api';

export const getPriceTables = () => {
  return get('/api/products/priceTables/');
};

export const getPriceTable = (priceTableId) => {
  return get(`/api/products/priceTables/${priceTableId}`);
};

export const putPriceTable = (priceTable) => {
  return put('/api/products/priceTables', priceTable);
};

export const postPriceTable = (priceTable) => {
  return post('/api/products/priceTables', priceTable);
};