export const formatCategoriesWithProducts = (categoriesWithProducts) => {
  return categoriesWithProducts
    .map((category) => {
      return {
        ...category,
        children: category.families.map((family) => {
          return ({ ...family, children: family.products });
        })
      };
    });
};

export const getProductsFromChange = (value, categoriesWithProducts) => {
  return categoriesWithProducts
    .reduce((products, category) => [...products, ...category.families.reduce((products, family) => {
      const foundProducts = family.products.filter((product) => value.includes(product.id));
      return foundProducts && [...products, ...foundProducts.filter((foundProduct) => !products.find(({ id }) => id === foundProduct.id))];
    }, [])], []);
};

export const getFilteredCategoriesWithProducts = (term, categoriesWithProducts) => term ? categoriesWithProducts.reduce((categories, category) => {    
  const families = category.families.reduce((families, family) => {
    const products = family.products.filter((product) => product.name.trim().toLowerCase().includes(term.toLowerCase()));
    return products.length > 0 || family.name.trim().toLowerCase().includes(term.toLowerCase()) ? [...families, { ...family, products }] : families;
  }, []);

  return families.length > 0 || category.name.trim().toLowerCase().includes(term.toLowerCase()) ? [...categories, { ...category, families }] : categories;
}, []) : categoriesWithProducts;

export const updateOrderItemsFromCategoriesWithProducts = (orderItems, categoriesWithProducts) => {
  return orderItems.map((orderItem) => {
    return {
      ...orderItem,
      price: categoriesWithProducts.reduce((price, category) => {
        return price || category.families.reduce((price, family) => {
          return price || family.products.reduce((price, product) => {
            return product.id === orderItem.id ? product.price : price;
          }, 0);
        }, 0);
      }, 0)
    };
  });
};