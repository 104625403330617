import React, { useEffect, useState } from 'react';
import { View } from '@components';
import styled from 'styled-components';
import Header from './Header';
import NovoComentario from './Novo';
import ListaComentarios from './Lista';
import types from './types';
import { useDispatch, useSelector } from 'react-redux';
import { getComentariosConteudo } from '@ducks/wikisComentario';

const Main = styled(View)`
  border-top: 1px solid #cccccc;
  padding-top: 10px;
`;

export default function Comentarios({ data, idCategoria }) {
  const [tipoOrdenacao, setTipoOrdenacao] = useState(null);
  const [comentarios, setComentarios] = useState([...data]);
  const dispatch = useDispatch();

  const comentariosConteudo = useSelector(({ wikisComentario }) => wikisComentario.get('comentarios').toArray());
  const successComentarios = useSelector(({ wikisComentario }) => wikisComentario.get('successComentarios'));

  useEffect(() => {
    if (idCategoria) dispatch(getComentariosConteudo({ idCategoria }));
  }, [idCategoria]);

  useEffect(() => {
    if (successComentarios) {
      setComentarios(comentariosConteudo);
    }
  }, [successComentarios]);

  const clickHandler = (e, fn) => {
    e.preventDefault();
    e.stopPropagation();
    fn();
  };

  useEffect(() => {
    const ordenacao = localStorage.getItem('WIKI_ORDENACAO_COMENTARIO');

    if (ordenacao === null) handleTipoOrdenacao({ key: types.ordenacao.DATA });
  }, []);

  const handleTipoOrdenacao = ({ key }) => {
    localStorage.setItem('WIKI_ORDENACAO_COMENTARIO', key);
    setTipoOrdenacao(key);
  };

  return (
    <Main>
      <Header data={{ comentarios }} handlers={{ tipoOrdenacao: handleTipoOrdenacao }} />
      <NovoComentario data={{ idCategoria, comentarios }} handlers={{ setComentarios, click: clickHandler }} />
      <ListaComentarios data={{ comentarios, tipoOrdenacao }} handlers={{ setComentarios, click: clickHandler }} />
    </Main>
  );
}
