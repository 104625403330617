import { dateToAnsi, DATE_FORMATS, InfoUsuario } from '@utils';

export const queryParamsGestaoEquipe = (filtros) => {
  if (!filtros) return '';

  const query = [];
  const { data, usuario, perfil } = filtros;

  query.push(`usuario_logado=${InfoUsuario.get('cdUsuario')}`);

  if (data && data.length > 0) {
    query.push(`dt_inicio=${dateToAnsi(data[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`dt_fim=${dateToAnsi(data[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }

  if (usuario) query.push(`usuario=${usuario}`);

  if (perfil && perfil.length > 0) query.push(`perfil=${perfil.join(',')}`);

  if (query.length === 0) return '?';

  return `?${query.join('&')}`;
};
