import { get, post } from '../../api';

export const getTemplate = (idCategoria) => {
  return get(`/api/wiki/template/${idCategoria}`).then((template) => {
    return (template) && (template.htmlJson) ? JSON.parse(template.htmlJson) : false;
  });
};

export const saveTemplate = (idCategoria, json, html) => {
  return post('/api/wiki/salvar/template', {
    id: idCategoria,
    conteudo: html,
    htmlJson: JSON.stringify(json),
  });
};
