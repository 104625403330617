import * as Containers from '@containers';
import { InfoUsuario } from '@utils/';
import { Icon } from '@components-teammove';

export default [
  {
    path: '/gestao-equipe/visao-geral',
    match: /^\/gestao-equipe\/visao-geral/,
    component: Containers.GestaoEquipeVisaoGeral,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/gestao-equipe/visao-mensal',
    match: /^\/gestao-equipe\/visao-mensal/,
    component: Containers.GestaoEquipeVisaoMensal,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/gestao-equipe/usuario/:cdUsuario',
    match: /^\/gestao-equipe\/usuario/,
    component: Containers.GestaoEquipeUsuario,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/gestao',
    text: 'Gestão',
    match: /^gestao/,
    icon: Icon.TeamOutlined,
    sider: true,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/gestao-equipe',
    text: 'Acessos',
    component: Containers.GestaoEquipe,
    match: /^gestao-equipe/,
    parent: '/gestao',
    sider: true,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/report-semanal',
    text: 'Report Semanal',
    component: Containers.GestaoEquipeReportSemanal,
    match: /^\/report-semanal/,
    parent: '/gestao',
    sider: true,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/controle-atividades',
    text: 'Gestão de equipes',
    component: Containers.GestaoEquipeControleAtividades,
    match: /^\/controle-atividades/,
    parent: '/gestao',
    sider: true,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  },
  {
    path: '/controle-atividades/:userId',
    component: Containers.GestaoEquipeControleAtividadesUsuario,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario),
  }
];