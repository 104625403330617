import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfiguracoesInfo } from '@ducks/home';
import { setTheme } from '@ducks/app';
import { registerUserControl } from '@sdk/Usuarios';
import { getDefaultWhitelabel } from '@sdk/Whitelabel';
import { Result, Body, Button, Spin, View, Text } from '@components';
import { Icon, Modal } from '@components-teammove';
import { InfoUsuario, TelasNomenclaturas } from '@utils';
import App from '@app';
import styled from 'styled-components';
import { ImageContainer, ImageWrapped, ModalFooter, ButtonConfirmRelease, TitleModal, TitleRelease, SubText } from './styles';
import { formatDateString } from '../../utils/Datas';
import { getRelease, postUser, reset } from '../../store/ducks/novidades';

const { REACT_APP_API } = window.env;

const LoadingView = styled(View)`
  padding-top: 30px;
`;

const Html = styled(View)`
  white-space: normal;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
`;

const ContentView = styled(View)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
`;

const ContentButton = styled(View)`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  font-size: 15px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: #E0E0E0;
  }
`;

const ContactsOutlined = styled(Icon.ContactsOutlined)`
  font-size: 25px;
`;

const ProfileOutlined = styled(Icon.ProfileOutlined)`
  font-size: 25px;
`;

const NotificationOutlined = styled(Icon.NotificationOutlined)`
  font-size: 25px;
`;

const LaptopOutlined = styled(Icon.LaptopOutlined)`
  font-size: 25px;
`;

const packageJson = require('../../../package.json');

export default function Home({ history }) {

  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const configuracoes = useSelector(({ home }) => home.get('configuracoes').toArray());
  const loadingConfiguracoes = useSelector(({ home }) => home.get('loadingConfiguracoes'));

  const usuario = InfoUsuario.get();
  const aliasChamados = TelasNomenclaturas.getNomenclatures('CHAMADOS');
  const aliasEAD = TelasNomenclaturas.getNomenclatures('EAD');
  const release = useSelector(({ novidades }) => novidades.get('release'));
  const successRelease = useSelector(({ novidades }) => novidades.get('successRelease'));
  const loadingPostUserViewedRelease = useSelector(({ novidades }) => novidades.get('loadingPostUser'));
  const successPostUserViewedRelease = useSelector(({ novidades }) => novidades.get('successPostUser'));

  useEffect(() => {
    handleGetDefaultWhitelabel();
    handleGetConfiguracoesInfo();
    handleRegisterUserControl();
    
    if (!usuario.senhaMaster && localStorage.getItem('version') !== packageJson.version) {
      dispatch(getRelease());
    }

    return () => dispatch(reset());
  }, []);

  const handleGetDefaultWhitelabel = () => {
    getDefaultWhitelabel(usuario.cdCliente)
      .then((res) => {
        if (theme !== res.theme) {
          dispatch(setTheme(res.theme));
        }
      });
  };

  const handleGetConfiguracoesInfo = () => {
    dispatch(getConfiguracoesInfo());
  };

  const handleRegisterUserControl = () => {
    if (!InfoUsuario.get('senhaMaster')) {
      registerUserControl();
    }
  };

  const getMessageWelcome = () => {
    if (configuracoes.length === 0) return null;
    
    const configMensagem = configuracoes.filter((item) => item.chave === 'MENSAGEM')[0];

    if (configMensagem) {
      const { configuracao } = configMensagem;
      return configuracao ? configuracao.replace('$usuario', usuario.nmUsuario) : null;
    } else {
      return null;
    }
  };

  const handleCloseReleaseModal = () => {
    dispatch(postUser());
  };

  const Loading = (
    <LoadingView>
      <Spin />
    </LoadingView>
  );

  const EmptyInfo = (
    <Result
      title="Aguarde, novidades em breve!"
      icon={(
        <Icon.SmileTwoTone />
      )}
      extra={(
        <Button type="primary" onClick={() => window.location.href = REACT_APP_API}>Voltar</Button>
      )}
    />);

  const Info = (
    <>
      <Html dangerouslySetInnerHTML={{ __html: getMessageWelcome() }} />
      <ContentView>
        <ContentButton onClick={() => history.push('/chamados/tickets')}>
          <ContactsOutlined />
          <Text>{aliasChamados.plural}</Text>
        </ContentButton>
        <ContentButton onClick={() => history.push('/conteudos/visualizacao?id=-1')}>
          <ProfileOutlined />
          <Text>Conteúdos</Text>
        </ContentButton>
        <ContentButton onClick={() => history.push('/comunicados?id=-1')}>
          <NotificationOutlined />
          <Text>Comunicados</Text>
        </ContentButton>
        <ContentButton onClick={() => history.push('/ead')}>
          <LaptopOutlined />
          <Text>{aliasEAD.nomenclatura}</Text>
        </ContentButton>
      </ContentView>
    </>  
  ); 

  return (
    <App>
      <Body>
        {release?.version && !release?.read && (
          <Modal 
            visible={successRelease && !successPostUserViewedRelease}
            bodyStyle={{ overflowY: 'scroll' }}
            title={(
              <View>
                <TitleModal>{release?.title}</TitleModal>
                <SubText>{formatDateString(release?.createdAt)}</SubText>
              </View>
            )}
            onCancel={handleCloseReleaseModal}
            footer={(
              <ModalFooter>
                <ButtonConfirmRelease type='primary' onClick={() => handleCloseReleaseModal()} loading={loadingPostUserViewedRelease}>Continuar</ButtonConfirmRelease>
              </ModalFooter>
            )}
          >
            <ImageContainer>
              <ImageWrapped src={release?.cover}/>
            </ImageContainer>
            <View>
              <SubText>Versão {packageJson.version}</SubText>
              <TitleRelease>{release?.description}</TitleRelease>
              <div dangerouslySetInnerHTML={{ __html: release?.body }}/>
            </View>
          </Modal>
        )}
        {loadingConfiguracoes ? Loading : (configuracoes.length > 0 ? Info : EmptyInfo)}
      </Body>
    </App>
  );
  
}
