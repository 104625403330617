import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'debounce';
import { getUsuarios } from '@sdk/Usuarios';
import { getPerfis } from '@sdk/Perfil';
import { getGruposComunicadosSelect } from '@sdk/Wikis';
import { Button, Input, Form, DatePicker, Select, View, Text, Tag } from '../';
import { CenteredButton, Container, FiltersModal, IconAdvanced, IconSearch } from './styles';
import styled from 'styled-components';

const StyledView = styled(View)`
  display: inline-block;
`;

const MarginLeftTag = styled(Tag)`
  margin-left: .25rem;
`;  

function Filter({ filters, filterValues = {}, onSearch, onChange, searchOnChange = false, placeholder = 'Pesquisar', labelCol = 4, wrapperCol = 20, textAlign = 'center', displayFiltersAbove = false, onPressEnter }) {
  const [form] = Form.useForm();
  const inputSearch = useRef(null);
  const [visible, setVisible] = useState(false);

  const [grupos, setGrupos] = useState();

  const handleOpenFilters = () => setVisible(true);
  const handleCloseFilters = () => setVisible(false);
  
  useEffect(() => {
    if (!filterValues || Object.keys(filterValues).length === 0) return;

    const { text, ...formValues } = filterValues;
    if (inputSearch && inputSearch.current && inputSearch.current.input) {
      inputSearch.current.setValue(text);
    }
    if (form && formValues) {
      Object.keys(formValues).forEach((field) => {
        if (typeof formValues[field] === 'object') formValues[field] = formValues[field].filter((elem) => !!elem);
      });
      form.setFieldsValue(formValues);
    }
  }, [filterValues]);

  useEffect(() => {
    if (!window.location.pathname.includes('comunicados')) return;
    asyncGetGruposComunicadosSelect();
  }, []);

  const asyncGetGruposComunicadosSelect = async () => {
    if (!grupos) {
      const gruposSelect = await getGruposComunicadosSelect();
      setGrupos(gruposSelect);
    }
  };

  async function handleSearch() {
    form.validateFields().then((values) => {
      const filtersToFunction = { ...values, text: encodeURI(inputSearch.current.input.value) };
      if (searchOnChange) {
        onChange(filtersToFunction);
      }
      if (onSearch) {
        onSearch(filtersToFunction);
      }
      setVisible(false);
    });
  }

  const createFormComponents = () => {
    if (!filters) {
      return null;
    }

    const typeComponentRender = {
      INPUT: (filter) => <Input placeholder={filter.placeholder} />,
      RANGE: (filter) => <DatePicker.RangePicker format={filter.format} ranges={filter.ranges} />,
      SELECT: (filter) => <Select mode={filter.mode} placeholder={filter.placeholder} options={filter.options} />,
      USUARIO: (filter) => <Select action={getUsuarios} mode={filter.mode} placeholder={filter.placeholder} />,
      PERFIL: (filter) => <Select action={getPerfis} mode={filter.mode} placeholder={filter.placeholder} />,
      COMUNICADOS: (filter) => <Select mode={filter.mode} placeholder={filter.placeholder} action={getGruposComunicadosSelect} />,
    };

    return filters.map((filter) => {
      const { type, name, label } = filter;

      const typeComponentFunction = typeComponentRender[type];
      const component = typeComponentFunction && typeComponentFunction(filter);

      return (
        component && (
          <Form.Item key={name} label={label} name={name}>
            {component}
          </Form.Item>
        )
      );
    });
  };

  const handleRemoveFilter = (name, optToRemove) => {
    if (optToRemove) {
      form.setFieldsValue({ ...form, [name] : form.getFieldsValue()[name].filter((opt) => opt !== optToRemove) });
    }else{
      form.resetFields([name]);
    }
    handleSearch();
  };
  
  const CreateFiltersIndicators = () => {
    if (!filters) {
      return null;
    }

    const getFilterTypeRender = {
      INPUT: (value, name) => <MarginLeftTag closable onClose={() => handleRemoveFilter(name)}>{value}</MarginLeftTag>,
      RANGE: (value, name) => {
        const dates = value.map(({ _d }) => {
          const day = _d.getDate().toString().padStart(2, '0');
          const month = _d.getMonth().toString().padStart(2, '0');
          const year = _d.getFullYear();
          return `${day}/${month}/${year}`;
        });
        return <MarginLeftTag closable key={value} onClose={() => handleRemoveFilter(name)}>{`${dates[0]} à ${dates[1]}`}</MarginLeftTag>;
      },
      SELECT: (value, name) => {
        return (
          <>
            {value?.filter((val) => !!val).map((selectedOpt) => (
              <MarginLeftTag closable key={selectedOpt} onClose={() => handleRemoveFilter(name, selectedOpt)}>
                {
                  filters.find((filt) => filt.name === name)
                    .options.find(({ value }) => value === selectedOpt)?.label
                }
              </MarginLeftTag>
            ))}
          </>
        );
      },
      COMUNICADOS: (value, name) => (
        <>
          {value?.map((selectedOpt) => <MarginLeftTag closable key={selectedOpt} onClose={() => handleRemoveFilter(name, selectedOpt)}>{grupos.find((grupoOpt) => grupoOpt.value === selectedOpt).title}</MarginLeftTag>)}
        </>
      ),
    };
    
    const filtersToRender = filters.map((filter) => {
      const { type, name, label } = filter;

      if (form.getFieldsValue()[name]) {
      
        const typeComponentFunction = getFilterTypeRender[type];
        const component = typeComponentFunction && typeComponentFunction(form.getFieldsValue()[name], name);
      
        return component && (
          <StyledView>
            <Text>{label}:</Text>
            {component}
          </StyledView>
        );
      }
      return false;

    }).filter((filt) => !!filt);

    return filtersToRender;
  };

  const handleChange = (e) => {
    handleDebounce(e.target.value);
  };

  const handleDebounce = debounce((value) => {
    if (onChange) {
      if (searchOnChange) {
        handleSearch();
      }else{
        onChange(value);
      }
    }
  }, 500); 

  return (
    <>
      {displayFiltersAbove && <CreateFiltersIndicators/>}
      <Container>
        <Input
          ref={inputSearch}
          placeholder={placeholder}
          prefix={<IconSearch onClick={() => handleSearch()} />}
          suffix={filters && <Button type='icon' icon={<IconAdvanced />} onClick={handleOpenFilters} />}
          onPressEnter={onPressEnter || handleSearch}
          onChange={handleChange}
        />

        <FiltersModal visible={visible} mask={false} onCancel={handleCloseFilters} closable={false} footer={null} forceRender textAlign={textAlign}>
          <Form form={form} labelCol={{ span: labelCol }} wrapperCol={{ span: wrapperCol }}>
            {createFormComponents()}

            <CenteredButton type='primary' onClick={handleSearch}>
              Pesquisar
            </CenteredButton>
          </Form>
        </FiltersModal>
      </Container>
    </>
  );
}

export default Filter;

const types = { INPUT: 'INPUT', RANGE: 'RANGE', SELECT: 'SELECT', USUARIO: 'USUARIO', PERFIL: 'PERFIL', COMUNICADOS: 'COMUNICADOS' };

export { types };
