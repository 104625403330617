import styled from 'styled-components';
import { Icon, Text } from '@components-teammove';

export const AudioPlayerContainer = styled.div`
    padding: ${({ padding })=> padding ? padding : '14px 15px'};
    border-radius: 200px;
    border: 1px solid ${({ theme }) => theme.darkHighlightColor};
    background-color: ${({ theme, bgColor }) => bgColor === 'secondary' ? theme.darkSecondaryColor : theme.darkPrimaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: ${({ widthContainer })=> widthContainer ? widthContainer : 'auto'};
`;

export const IconPlayer = styled(Icon.MDI)`
    width: ${({ iconOptions }) => iconOptions ? '20px' : '22px'};
    height: ${({ iconOptions }) => iconOptions ? '20px' : '22px'};
    color: ${({ theme }) => theme.textColor};
`;

export const Timer = styled.div`
    color: ${({ theme }) => theme.textColor};
    width: 100px;
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  height: 4px;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 5px;
  overflow: hidden;
  width: ${({ width })=> width ? '100%' : '60%'};
  cursor: pointer;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.actionColor};
  transition: width 0.1s;
  width: ${({ progress }) => progress}%;
`;

export const VolumeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const VolumeSlider = styled.input`
  width: 100px;
  margin-left: 5px;
  -webkit-appearance: none;
  appearance: none;
  background: ${({ theme }) => theme.backgroundColor} !important;
  border-radius: 5px;
  height: 5px;
  outline: none;
  position: relative;
  overflow: hidden;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.actionColor};
    border-radius: 50%;
    cursor: pointer;
    box-shadow: -80px 0 0 80px ${({ theme }) => theme.actionColor};
  }

  &::-webkit-slider-runnable-track {
    height: 16px;
    -webkit-appearance: none;
    color: ${({ theme }) => theme.actionColor};
    margin-top: -1px;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.actionColor};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.actionColor};
    border-radius: 50%;
    cursor: pointer;
  }

   &::-ms-track {
    width: 100px;
    height: 10px;
    background: ${({ theme }) => theme.actionColor};
    border: none;
    border-radius: 3px;
  }

  &::-moz-range-progress {
  background-color: ${({ theme }) => theme.actionColor}; 
  }

  &::-ms-fill-upper {  
    background-color: ${({ theme }) => theme.actionColor};
  }

  &::-ms-fill-lower { 
    background-color: ${({ theme }) => theme.backgroundColor}; 
  }
`;

export const OptionsContainer = styled.div`
  position: relative;
  display: flex;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  background: ${({ theme }) => theme.darkPrimaryColor};
  border: 1px solid ${({ theme }) => theme.darkHighlightColor};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.textColor};
  min-width: ${({ isPlaybackRateMenuVisible }) => isPlaybackRateMenuVisible ? '150px' : '200px'};
  z-index: 1;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.darkHighlightColor};
    ${({ borderTop }) => borderTop && `
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    `};
    ${({ borderBottom }) => borderBottom && `
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    `};
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
`;

export const SubMenu = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  background: ${({ theme }) => theme.darkPrimaryColor};
  border: 1px solid ${({ theme }) => theme.darkHighlightColor};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const SubMenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const TextOptions = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  font-size: 12px;
  font-weight: 400px;
  line-height: 16.34px;
`;