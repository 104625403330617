import React, { useEffect, useMemo } from 'react';
import { Modal, Form, Input, Column } from '@components-teammove';
import { cancelAtividade, reopenAtividade, resetCancelAndReopen } from '@ducks/atividades';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InfoUsuario } from '@utils';
import { WarningIcon, WarningText } from './styles';

export default function ActionModal({ visible, onCancel, actionType }) {
  const { idActivity } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const loadingCancel = useSelector(({ atividades }) => atividades.get('loadingCancel'));
  const successCancel = useSelector(({ atividades }) => atividades.get('successCancel'));
  const loadingReopen = useSelector(({ atividades }) => atividades.get('loadingReopen'));
  const successReopen = useSelector(({ atividades }) => atividades.get('successReopen'));
  const activity = useSelector(({ atividades }) => atividades.get('atividadeDetalhe'));
  const userIntegrations = useSelector(({ integracoes }) => integracoes.get('userIntegrations'));
  const successUserIntegrations = useSelector(({ integracoes }) => integracoes.get('successUserIntegrations'));
  
  const userHasOutlookIntegration = useMemo(() => {
    return successUserIntegrations && userIntegrations.some(({ integrationId, value }) => integrationId === 'OUTLOOK' && value);
  }, [successUserIntegrations, userIntegrations]);

  const userIsActivityCreator = useMemo(() => {
    return activity.creatorUserId === InfoUsuario.get('cdUsuario');
  }, [activity]);

  useEffect(() => {
    if (successCancel || successReopen) {
      onCancel();
    }

    return () => {
      dispatch(resetCancelAndReopen());
    };
  }, [successCancel, successReopen]);
  
  const handleOk = () => {
    form.validateFields().then(({ reason }) => {
      dispatch(
        actionType === 'cancel' ? 
          cancelAtividade(parseInt(idActivity), reason) : 
          reopenAtividade(parseInt(idActivity), reason)
      );
    }).catch(Form.scrollToFirstError);
  };

  return(
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={actionType === 'cancel' ? 'Cancelar atividade' : 'Reabrir atividade'}
      cancelText='Retornar'
      okText={actionType === 'cancel' ? 'Cancelar Atividade' : 'Reabrir Atividade'}
      onOk={handleOk}
      okButtonProps={{ loading: actionType === 'cancel' ? loadingCancel : loadingReopen }}
    >
      <Column>
        {actionType === 'cancel' && userHasOutlookIntegration && !userIsActivityCreator && (
          <WarningText>
            <WarningIcon/>
            <span>
              <span className='danger-color'>*</span> Você não é o criador desta atividade e por isso ela não será cancelada no Outlook.
            </span>
          </WarningText>
        )}
        <Form layout='vertical' form={form}>
          <Form.Item name='reason' label='Motivo'>
            <Input.TextArea placeholder={'Escreva o motivo para ' + (actionType === 'cancel' ? 'o cancelamento' : 'a reabertura') + ' da atividade'}/>
          </Form.Item>
        </Form>
      </Column>
    </Modal>
  );
}