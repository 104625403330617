import React from 'react';
import { ContentHeaderContainer, GapRow } from './styles';
import { ContentTitle } from '@components-teammove';

const ContentHeader = ({ title, extra, subTitle, ...props }) => (
  <ContentHeaderContainer {...props}>
    <GapRow>
      <ContentTitle>{title}</ContentTitle>
      {subTitle}
    </GapRow>
    {extra}
  </ContentHeaderContainer>
);

export default ContentHeader;