import styled from 'styled-components';
import Text from '../Text';

export const StyledStatusTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${({ size }) => size === 'large' ? '8px' : size === 'average' ? '27px' : '14px'};
  background-color: ${({ color, theme }) => color || theme.actionColor};
  padding: ${({ size }) => size === 'large' ? '7px' : '4px'} 12px;
  gap: 4px;
  height: fit-content;
  ${({ fixedWidth }) => fixedWidth && `
    width: ${fixedWidth};
    justify-content: center;
  `}
  ${({ fixedSize }) => fixedSize && `
    min-width: 154px;
  `}
  ${({ disabled }) => disabled && `
    opacity: .5;
  `}
  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}
`;

export const StyledText = styled(Text)`
  &.ant-typography {
    font-size: ${({ size }) => size === 'large' ? '12px' : size === 'average' ? '14px' : '10px'};
    font-weight: ${({ size }) => size === 'large' ? '600' : '400'};
    color: ${(props) => props.color ? props.theme.textColor : props.theme.textContrastColor};
  }
`;