import styled from 'styled-components';
import { Row, View, Text , Icon } from '@components-teammove';

export const Content = styled(View)`
  width: max-content;  
`;

export const WhiteText = styled(Text)`
  &.ant-typography {
    color: #F5F5F5;
  }
`;

export const StatusTag = styled(Row)`
  background-color: ${({ status })=> status ? status : '#FD8509'};
  border-radius: 8px;
  width: 141px;
  padding: 7px 0 7px 0;
  justify-content:center;
  color: ${({ theme })=> theme.textColor} !important;
`;

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ opacity })=> opacity && '.5'};
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme, ok, lock }) => ok ? theme.textColor : lock ? theme.darkHighlightColor : theme.darkBackgroundColor} !important;
  opacity: ${({ opacity })=> opacity ? '.5' : '1'};
`;
