import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { saveCategoria as saveCategoriaSdk } from '@sdk/Chamados';

//Action Types
export const Types = {
  SAVE: 'CONFIGURACOES_CHAMADOS_CATEGORIA/SAVE',
  SAVE_SUCCESS: 'CONFIGURACOES_CHAMADOS_CATEGORIA/SAVE_SUCCESS',
  SAVE_ERROR: 'CONFIGURACOES_CHAMADOS_CATEGORIA/SAVE_ERROR',
  
  RESET: 'CONFIGURACOES_CHAMADOS_CATEGORIA/RESET',
};

//Action Creators
export const saveCategoria = (categoria) => ({ type: Types.SAVE, categoria });
export const saveCategoriaSuccess = () => ({ type: Types.SAVE_SUCCESS });
export const saveCategoriaError = (error) => ({ type: Types.SAVE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchSaveCategoria(action) {
  try {
    const { categoria } = action;

    yield call(saveCategoriaSdk, categoria);
    yield put(saveCategoriaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCategoriaError(err));
  }
}

export const saga = [
  takeLatest(Types.SAVE, fetchSaveCategoria),
];

// Reducer
const initialState = Map({
  loading: false,
  success: false,
  error: false,
});

const handleSaveCategoria = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleSaveCategoriaSuccess = (state, action) => {
  return state
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleSaveCategoriaError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SAVE: return handleSaveCategoria(state, action);
    case Types.SAVE_SUCCESS: return handleSaveCategoriaSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveCategoriaError(state, action);
    case Types.RESET: return initialState;

    default: return state;
  }
}
