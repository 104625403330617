import styled from 'styled-components';
import { Icon, Column, Input } from '@components-teammove';

export const Card = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  ${({ height })=> height && height};
`;

export const FeatherIcon = styled(Icon.Feather)`
  ${({ cursor })=> cursor && 'cursor: pointer;'}
 color: ${({ theme, importance }) => importance === 'Relevante' ? 'var(--success-color)' : importance === 'Nao_Relevante' ? 'var(--danger-color)' : theme.textColor};
`;

export const MoreInfoTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.actionColor};
  padding: 4px 18px 4px 18px;
  :hover {
    cursor: pointer;
  }
`;

export const ContentWritings = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 16px 20px 20px 20px;
`;

export const CardWriting = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 16px;
`;

export const ImportanceIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid ${({ theme, importance }) => importance === 'Relevante' ? 'var(--success-color)' : importance === 'Nao_Relevante' ? 'var(--danger-color)' : theme.darkSecondaryColor};
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const InputText = styled(Input.TextArea)`
 margin-bottom: 10px !important;
`;