import Icon from '../../Icon';
import styled from 'styled-components';

export const IconOption = styled(Icon.MDI)`
  width: 28px !important;
  height: 28px !important;
  color: ${(props) => props.theme.textColor};
  cursor: pointer;
  transition: all 100ms;

  :hover {
    transform: scale(1.2);
  }
`;