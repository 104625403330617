export const formatTelephone = (value) => {
  return value ? value.length === 11 ? formatNineDigitsTelephone(value) : `(${value.substr(0, 2)}) ${value.substr(2, 4)}-${value.substr(6, 4)}` : '-';
};

export const formatNineDigitsTelephone = (value) => {
  return value ? `(${value.substr(0, 2)}) ${value.substr(2, 5)}-${value.substr(7, 4)}` : '-';
};

export const formatCnpj = (value = '') => {
  return `${value?.substr(0, 2)}.${value?.substr(2, 3)}.${value?.substr(5, 3)}/${value?.substr(8, 4)}-${value?.substr(12, 2)}`;
};

export const formatIe = (value = '') => {
  return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}`;
};