import { get, post } from '../api';
import { ansiToMoment, dateToAnsi, DATE_FORMATS, Crypto } from '@utils';

export const getPerfil = (idUsuario, headers) => {
  return get(`/api/perfil/${idUsuario}`, headers).then((perfil) => ({
    ...perfil,
    telefone: perfil.telefone ? perfil.telefone.replace(/\D/gi, '') : '',
    dtNascimento: ansiToMoment(perfil.dtNascimento),
  }));
};

export const getPerfis = () => {
  return get('/api/profile?active=true', {}, true).then((res) => {
    return res.map((perfil) => ({
      key: perfil.id,
      value: perfil.id,
      title: perfil.description,
    }));
  });
};

export const savePerfil = (idUsuario, perfil, headers) => {
  return post(`/api/perfil/salvar${perfil.senha ? '?cadastrarSenha=true' : ''}`, {
    ...perfil,
    cdUsuario: idUsuario,
    telefone: perfil.telefone ? perfil.telefone.replace(/\D/gi, '') : '',
    dtNascimento: dateToAnsi(perfil.dtNascimento, null, DATE_FORMATS.ANSI_DATE),
  }, headers);
};

export const alterarSenha = (idUsuario, oldPassword, newPassword) => {
  return post('/api/usuario/alterar-senha', {
    cdUsuario: idUsuario,
    senhaAtual: Crypto.SHA256(oldPassword),
    senhaNova: Crypto.SHA256(newPassword),
  });
};
