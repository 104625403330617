import styled from 'styled-components';
import { Image, Text, View } from '@components';
import { Button } from '@components-teammove';

export const CoverAnswerPending = styled(Image)`
  min-width: 180px;
  margin-right: 10px;
`;

export const LabelAnswerPending = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: bold;
    color: #F5F5F5;
  }
`;

export const MessagePendingText = styled(Text)`
  &.ant-typography {
    color: #F5F5F5;
  }
`;

export const AnswerButtonContainer = styled(View)`
  width: 200px;
  margin-top: 20px;
`;

export const ButtonPendency = styled(Button)`

`;