import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Column, Text, Progress, Skeleton } from '@components-teammove';
import { Card } from './styles';

import { getSatisfactionIndexDash, getSatisfactionIndexTickets } from '@ducks/chamadosDashboard';

export default function AssessmentCards({ currentFilters, currentParams, limit }) {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  
  const satisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('satisfactionIndexDash'));
  const loadingSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingSatisfactionIndexDash'));
  const successSatisfactionIndexDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successSatisfactionIndexDash'));
  
  const totalPercentQuantity = satisfactionIndexDash?.quantitiesByGrade?.reduce((acc, cur)=> acc + cur.quantity, 0);

  useEffect(() => {
    dispatch(getSatisfactionIndexDash({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  const [ cardActive, setCardActive ] = useState(0);

  const handleChangeActive = (id) => {
    setCardActive(id);
    dispatch(getSatisfactionIndexTickets({ ...currentFilters, avaliations: id > 0 ? [`${id}`] : undefined,  ...currentParams }, { limit: limit }));
  };
  
  return(
    loadingSatisfactionIndexDash ? <Skeleton.Map/> 
      : successSatisfactionIndexDash && (
        <Column gap='12px'>
          <Row padding='0 0 0 20px'><Text size='20px' weight='700'>Avaliações</Text></Row>
          <Card id={0} cardActive={cardActive} onClick={()=> handleChangeActive(0)} gap='12px' align='center' justify='space-between'>
          
            <Text size='20px' weight='700'>Todos</Text>
            <Row align='center' gap='6px'>
              <Text size='20px' weight='700'>{totalPercentQuantity}</Text>
              <Text size='20px' weight='400' lowOpacity>tickets</Text>
            </Row>
          </Card>
          
          <Row gap='12px' justify='space-between'>
            <Card id={5} cardActive={cardActive} onClick={()=> handleChangeActive(5)} align='center' justify='space-between'>
              <Row align='center' gap='8px'>
                <Text size='20px'>5</Text>
                <Progress.Star totalStars='1' size='xsmall' color={theme['@global-dark-highlight-color']} percent='100'/>
              </Row>
              <Row align='center' gap='6px'>
                <Text size='20px' weight='700'>{satisfactionIndexDash.quantitiesByGrade[4].quantity}</Text>
                <Text size='20px' weight='400' lowOpacity>tickets</Text>
              </Row>
            </Card>

            <Card id={4} cardActive={cardActive} onClick={()=> handleChangeActive(4)} align='center' justify='space-between'>
              <Row align='center' gap='8px'>
                <Text size='20px'>4</Text>
                <Progress.Star totalStars='1' size='xsmall' color={theme['@global-dark-highlight-color']} percent='100'/>
              </Row>
              <Row align='center' gap='6px'>
                <Text size='20px' weight='700'>{satisfactionIndexDash.quantitiesByGrade[3].quantity}</Text>
                <Text size='20px' weight='400' lowOpacity>tickets</Text>
              </Row>
            </Card>
          </Row>
          <Row gap='12px'>
            <Card id={3} cardActive={cardActive} onClick={()=> handleChangeActive(3)} align='center' justify='space-between'>
              <Row align='center' gap='8px'>
                <Text size='20px'>3</Text>
                <Progress.Star totalStars='1' size='xsmall' color={theme['@global-dark-highlight-color']} percent='100'/>
              </Row>
              <Row align='center' gap='6px'>
                <Text size='20px' weight='700'>{satisfactionIndexDash.quantitiesByGrade[2].quantity}</Text>
                <Text size='20px' weight='400' lowOpacity>tickets</Text>
              </Row>
            </Card>
            <Card id={2} cardActive={cardActive} onClick={()=> handleChangeActive(2)} align='center' justify='space-between'>
              <Row align='center' gap='8px'>
                <Text size='20px'>2</Text>
                <Progress.Star totalStars='1' size='xsmall' color={theme['@global-dark-highlight-color']} percent='100'/>
              </Row>
              <Row align='center' gap='6px'>
                <Text size='20px' weight='700'>{satisfactionIndexDash.quantitiesByGrade[1].quantity}</Text>
                <Text size='20px' weight='400' lowOpacity>tickets</Text>
              </Row>
            </Card>
            <Card id={1} cardActive={cardActive} onClick={()=> handleChangeActive(1)} align='center' justify='space-between'>
              <Row align='center' gap='8px'>
                <Text size='20px'>1</Text>
                <Progress.Star totalStars='1' size='xsmall' color={theme['@global-dark-highlight-color']} percent='100'/>
              </Row>
              <Row align='center' gap='6px'>
                <Text size='20px' weight='700'>{satisfactionIndexDash.quantitiesByGrade[0].quantity}</Text>
                <Text size='20px' weight='400' lowOpacity>tickets</Text>
              </Row>
            </Card>
          </Row>
          
        </Column>
      )
    
  );
}