import styled from 'styled-components';

export const ContainerClassificacao = styled.div`
    border-radius: 27px;
    padding: 3px 8px;
    background-color: ${(props) => props.corOpcaoEscolhida };
    width: max-content;
    display: flex;
    gap: 5px;
`;
