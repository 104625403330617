import { call, put, takeLatest } from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { 
  getAgrupadoresMetasAtividades as getAgrupadoresMetasAtividadesSdk, 
  getAgrupadorMetaAtividade as getAgrupadorMetaAtividadeSdk,
  putAgrupadoresMetasAtividades as putAgrupadoresMetasAtividadesSdk,
  deleteAgrupadorMetaAtividade as deleteAgrupadorMetaAtividadeSdk,
} from '@sdk/AgrupadoresMetasAtividades';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_AGRUPADORES_METAS_ATIVIDADES: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADORES_METAS_ATIVIDADES',
  GET_AGRUPADORES_METAS_ATIVIDADES_SUCCESS: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADORES_METAS_ATIVIDADES_SUCCESS',
  GET_AGRUPADORES_METAS_ATIVIDADES_ERROR: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADORES_METAS_ATIVIDADES_ERROR',

  GET_AGRUPADOR_META_ATIVIDADE: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADOR_META_ATIVIDADE',
  GET_AGRUPADOR_META_ATIVIDADE_SUCCESS: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADOR_META_ATIVIDADE_SUCCESS',
  GET_AGRUPADOR_META_ATIVIDADE_ERROR: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/GET_AGRUPADOR_META_ATIVIDADE_ERROR',

  PUT_AGRUPADORES_METAS_ATIVIDADES: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/PUT_AGRUPADORES_METAS_ATIVIDADES',
  PUT_AGRUPADORES_METAS_ATIVIDADES_SUCCESS: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/PUT_AGRUPADORES_METAS_ATIVIDADES_SUCCESS',
  PUT_AGRUPADORES_METAS_ATIVIDADES_ERROR: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/PUT_AGRUPADORES_METAS_ATIVIDADES_ERROR',

  DELETE_AGRUPADOR_META_ATIVIDADE: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/DELETE_AGRUPADOR_META_ATIVIDADE',
  DELETE_AGRUPADOR_META_ATIVIDADE_SUCCESS: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/DELETE_AGRUPADOR_META_ATIVIDADE_SUCCESS',
  DELETE_AGRUPADOR_META_ATIVIDADE_ERROR: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/DELETE_AGRUPADOR_META_ATIVIDADE_ERROR',

  RESET: 'CONFIGURACOES_AGRUPADORES_METAS_ATIVIDADES/RESET',
};

//Action Creators
export const getAgrupadoresMetasAtividades = () => ({ type: Types.GET_AGRUPADORES_METAS_ATIVIDADES });
export const getAgrupadoresMetasAtividadesSuccess = (agrupadores) => ({ type: Types.GET_AGRUPADORES_METAS_ATIVIDADES_SUCCESS, agrupadores });
export const getAgrupadoresMetasAtividadesError = (error) => ({ type: Types.GET_AGRUPADORES_METAS_ATIVIDADES_ERROR, error });

export const getAgrupadorMetaAtividade = (id) => ({ type: Types.GET_AGRUPADOR_META_ATIVIDADE, id });
export const getAgrupadorMetaAtividadeSuccess = (agrupador) => ({ type: Types.GET_AGRUPADOR_META_ATIVIDADE_SUCCESS, agrupador });
export const getAgrupadorMetaAtividadeError = (error) => ({ type: Types.GET_AGRUPADOR_META_ATIVIDADE_ERROR, error });

export const putAgrupadoresMetasAtividades = (agrupador) => ({ type: Types.PUT_AGRUPADORES_METAS_ATIVIDADES, agrupador });
export const putAgrupadoresMetasAtividadesSuccess = (resp) => ({ type: Types.PUT_AGRUPADORES_METAS_ATIVIDADES_SUCCESS, resp });
export const putAgrupadoresMetasAtividadesError = (error) => ({ type: Types.PUT_AGRUPADORES_METAS_ATIVIDADES_ERROR, error });

export const deleteAgrupadorMetaAtividade = (id) => ({ type: Types.DELETE_AGRUPADOR_META_ATIVIDADE, id });
export const deleteAgrupadorMetaAtividadeSuccess = (resp) => ({ type: Types.DELETE_AGRUPADOR_META_ATIVIDADE_SUCCESS, resp });
export const deleteAgrupadorMetaAtividadeError = (error) => ({ type: Types.DELETE_AGRUPADOR_META_ATIVIDADE_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetAgrupadoresMetasAtividades(action) {
  try {
    const agrupadores = yield call(getAgrupadoresMetasAtividadesSdk, action.date);
    yield put(getAgrupadoresMetasAtividadesSuccess(agrupadores));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAgrupadoresMetasAtividadesError(err));
  }
}

function* fetchGetAgrupadorMetaAtividade(action) {
  try {
    const agrupador = yield call(getAgrupadorMetaAtividadeSdk, action.id);
    yield put(getAgrupadorMetaAtividadeSuccess(agrupador));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAgrupadorMetaAtividadeError(err));
  }
}

function* fetchPutAgrupadoresMetasAtividades(action) {
  try {
    const resp = yield call(putAgrupadoresMetasAtividadesSdk, action.agrupador);
    yield put(putAgrupadoresMetasAtividadesSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(putAgrupadoresMetasAtividadesError(err));
  }
}

function* fetchDeleteAgrupadorMetaAtividade(action) {
  try {
    const resp = yield call(deleteAgrupadorMetaAtividadeSdk, action.id);
    yield put(deleteAgrupadorMetaAtividadeSuccess(resp));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteAgrupadorMetaAtividadeError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_AGRUPADORES_METAS_ATIVIDADES, fetchGetAgrupadoresMetasAtividades),
  takeLatest(Types.GET_AGRUPADOR_META_ATIVIDADE, fetchGetAgrupadorMetaAtividade),
  takeLatest(Types.PUT_AGRUPADORES_METAS_ATIVIDADES, fetchPutAgrupadoresMetasAtividades),
  takeLatest(Types.DELETE_AGRUPADOR_META_ATIVIDADE, fetchDeleteAgrupadorMetaAtividade),
];

// Reducer
const initialState = Map({
  agrupadoresMetas: List(),
  loadingAgrupadoresMetas: false,
  successAgrupadoresMetas: false,
  errorAgrupadoresMetas: false,

  agrupadorMeta: {},
  loadingAgrupadorMeta: false,
  successAgrupadorMeta: false,
  errorAgrupadorMeta: false,

  loadingPutAgrupadoresMetas: false,
  successPutAgrupadoresMetas: false,
  errorPutAgrupadoresMetas: false,

  loadingDeleteAgrupadorMeta: false,
  successDeleteAgrupadorMeta: false,
  errorDeleteAgrupadorMeta: false,
});

const handleGetAgrupadoresMetasAtividades = (state, action) => {
  return state
    .set('loadingAgrupadoresMetas', true)
    .set('successAgrupadoresMetas', false)
    .set('errorAgrupadoresMetas', false);
};
  
const handleGetAgrupadoresMetasAtividadesSuccess = (state, action) => {
  console.log('action', action);
  return state
    .set('agrupadoresMetas', action.agrupadores)
    .set('loadingAgrupadoresMetas', false)
    .set('successAgrupadoresMetas', true)
    .set('errorAgrupadoresMetas', false);
};
  
const handleGetAgrupadoresMetasAtividadesError = (state, action) => {
  return state
    .set('loadingAgrupadoresMetas', false)
    .set('successAgrupadoresMetas', false)
    .set('errorAgrupadoresMetas', action.error);
};

const handleGetAgrupadorMetaAtividade = (state, action) => {
  return state
    .set('loadingAgrupadorMeta', true)
    .set('successAgrupadorMeta', false)
    .set('errorAgrupadorMeta', false);
};

const handleGetAgrupadorMetaAtividadeSuccess = (state, action) => {
  return state
    .set('agrupadorMeta', action.agrupador)
    .set('loadingAgrupadorMeta', false)
    .set('successAgrupadorMeta', true)
    .set('errorAgrupadorMeta', false);
};

const handleGetAgrupadorMetaAtividadeError = (state, action) => {
  return state
    .set('loadingAgrupadorMeta', false)
    .set('successAgrupadorMeta', false)
    .set('errorAgrupadorMeta', action.error);
};

const handlePutAgrupadoresMetasAtividades = (state, action) => {
  return state
    .set('loadingPutAgrupadoresMetas', true)
    .set('successPutAgrupadoresMetas', false)
    .set('errorPutAgrupadoresMetas', false);
};

const handlePutAgrupadoresMetasAtividadesSuccess = (state, action) => {
  return state
    .set('loadingPutAgrupadoresMetas', false)
    .set('successPutAgrupadoresMetas', true)
    .set('errorPutAgrupadoresMetas', false);
};

const handlePutAgrupadoresMetasAtividadesError = (state, action) => {
  return state
    .set('loadingPutAgrupadoresMetas', false)
    .set('successPutAgrupadoresMetas', false)
    .set('errorPutAgrupadoresMetas', action.error);
};

const handleDeleteAgrupadorMetaAtividade = (state, action) => {
  return state
    .set('loadingDeleteAgrupadorMeta', true)
    .set('successDeleteAgrupadorMeta', false)
    .set('errorDeleteAgrupadorMeta', false);
};

const handleDeleteAgrupadorMetaAtividadeSuccess = (state, action) => {
  return state
    .set('loadingDeleteAgrupadorMeta', false)
    .set('successDeleteAgrupadorMeta', true)
    .set('errorDeleteAgrupadorMeta', false);
};

const handleDeleteAgrupadorMetaAtividadeError = (state, action) => {
  return state
    .set('loadingDeleteAgrupadorMeta', false)
    .set('successDeleteAgrupadorMeta', false)
    .set('errorDeleteAgrupadorMeta', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_AGRUPADORES_METAS_ATIVIDADES: return handleGetAgrupadoresMetasAtividades(state, action);
    case Types.GET_AGRUPADORES_METAS_ATIVIDADES_SUCCESS: return handleGetAgrupadoresMetasAtividadesSuccess(state, action);
    case Types.GET_AGRUPADORES_METAS_ATIVIDADES_ERROR: return handleGetAgrupadoresMetasAtividadesError(state, action);

    case Types.GET_AGRUPADOR_META_ATIVIDADE: return handleGetAgrupadorMetaAtividade(state, action);
    case Types.GET_AGRUPADOR_META_ATIVIDADE_SUCCESS: return handleGetAgrupadorMetaAtividadeSuccess(state, action);
    case Types.GET_AGRUPADOR_META_ATIVIDADE_ERROR: return handleGetAgrupadorMetaAtividadeError(state, action);

    case Types.PUT_AGRUPADORES_METAS_ATIVIDADES: return handlePutAgrupadoresMetasAtividades(state, action);
    case Types.PUT_AGRUPADORES_METAS_ATIVIDADES_SUCCESS: return handlePutAgrupadoresMetasAtividadesSuccess(state, action);
    case Types.PUT_AGRUPADORES_METAS_ATIVIDADES_ERROR: return handlePutAgrupadoresMetasAtividadesError(state, action);

    case Types.DELETE_AGRUPADOR_META_ATIVIDADE: return handleDeleteAgrupadorMetaAtividade(state, action);
    case Types.DELETE_AGRUPADOR_META_ATIVIDADE_SUCCESS: return handleDeleteAgrupadorMetaAtividadeSuccess(state, action);
    case Types.DELETE_AGRUPADOR_META_ATIVIDADE_ERROR: return handleDeleteAgrupadorMetaAtividadeError(state, action);

    case Types.RESET: return handleReset(state, action);
  
    default: return state;
  }
}