import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { postConfigurationVoip, getConfigurationVoip } from '@ducks/configuracoesVoip';
import { Body, PageHeader, Breadcrumb, Column, Button, Form, Select } from '@components-teammove';

export default function ConfiguracoesVoip() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const successConfigurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('successConfigurationVoip'));
  const loadingSaveConfigurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('loadingSaveConfigurationVoip'));
  const configurationVoip = useSelector(({ configuracoesVoip }) => configuracoesVoip.get('configurationVoip'));

  const [form] = Form.useForm(); 
  const dispatch = useDispatch();

  const options = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Nao', label: 'Não' },
    { value: 'Opcional', label: 'Opcional' }
  ];

  const Salvar = () => [
    <Button key="1" type="primary" size="small" context="header" loading={loadingSaveConfigurationVoip} onClick={() => handleSave()}>Salvar</Button>
  ];

  useEffect(() => {
    dispatch(getConfigurationVoip());
  }, []);

  useEffect(() => {
    if(successConfigurationVoip) {
      form.setFieldsValue(configurationVoip);
    }
  }, [successConfigurationVoip]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      dispatch(postConfigurationVoip({ ...values, EnableCallVoice: true }));
    });
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Voip'}
          extra={<Salvar/>}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/voip', name:'Configurações de VOIP', current: true }
              ]}    
            />
          )}
          onBack={() => window.history.back()}
        >
          <Form form={form} layout={'vertical'}>
            <Column gap='12px' width='25%'>
              <Form.Item label='Habilitar gravação de ligações' name="EnableCallRecording">
                <Select options={options}/>
              </Form.Item>
              <Form.Item label='Habilitar transcrição em texto da ligação completa' name="EnableCallTextTranscription">
                <Select options={options}/>
              </Form.Item>
              <Form.Item label='Habilitar transcrição em texto dos highlights com IA' name="EnableCallTextTranscriptionHighlights">
                <Select options={options}/>
              </Form.Item>
            </Column>
          </Form>
        </PageHeader>
      </Body>
    </App>
  );
}
