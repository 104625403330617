const set = (key, value) => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : value;
};

const remove = (key) => {
  return sessionStorage.removeItem(key);
};

export default {
  set,
  get,
  remove,
};