export const mountOptionsCascader = (dados, idField, idGroupField, labelField) => {
  if (!idGroupField) return dados;

  const filterItems = (items) => {
    return items.map((item) => {
      const childrenItems = dados.filter((aux) => item[idField] === aux[idGroupField]);

      return {
        value: item[idField].toString(),
        label: item[labelField],
        selectable: childrenItems.length === 0,
        children: childrenItems.length !== 0 ? filterItems(childrenItems) : null,
      };
    });
  };

  return filterItems(dados.filter((item) => (!item[idGroupField])));
};

export const mountDataTree = (dados, idField, idGroupField, labelField) => {
  if (!idGroupField) return dados;

  const filterItems = (items) => {
    return items.map((item) => {
      const childrenItems = dados.filter((aux) => item[idField] === aux[idGroupField]);

      return {
        ...item,
        key: item[idField].toString(),
        title: item[labelField],
        selectable: false,//childrenItems.length === 0,
        children: childrenItems.length !== 0 ? filterItems(childrenItems) : null,
      };
    });
  };

  return filterItems(dados.filter((item) => (!item[idGroupField])));
};

export const mountDataTreeSelect = (dados, idField, idGroupField, labelField) => {
  if (!idGroupField) return dados;

  const filterItems = (items) => {
    return items.map((item) => {
      const childrenItems = dados.filter((aux) => item[idField] === aux[idGroupField]);

      return {        
        key: item[idField],
        value: item[idField],
        title: item[labelField],        
        children: childrenItems.length !== 0 ? filterItems(childrenItems) : null,
      };
    });
  };

  return filterItems(dados.filter((item) => (!item[idGroupField])));
};

export const mountSelect = (dados, idField, idGroupField, labelField) => {  
  if (!idGroupField) return dados;

  const dataArray = [];
  const filterItems = (items, previousTitle) => {
    return items.map((item) => {
      const childrenItems = dados.filter((aux) => item[idField] === aux[idGroupField]);
      const title = previousTitle ? [previousTitle, item[labelField]].join(' / ') : item[labelField];

      if (childrenItems.length === 0) {
        return dataArray.push({
          key: item[idField].toString(),
          value: item[idField].toString(),
          title,
        });
      } else {
        return filterItems(childrenItems, title);
      }
    });
  };

  filterItems(dados.filter((item) => (!item[idGroupField])));

  return dataArray;
};