import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getChamados as getChamadosSdk } from '@sdk/Chamados';

export const FILTERS_KEY = 'CHAMADOS_TICKETS';

//Action Types
export const Types = {
  GET_CHAMADOS: 'CHAMADOS_TICKETS/GET_CHAMADOS',
  GET_CHAMADOS_SUCCESS: 'CHAMADOS_TICKETS/GET_CHAMADOS_SUCCESS',
  GET_CHAMADOS_ERROR: 'CHAMADOS_TICKETS/GET_CHAMADOS_ERROR',

  UPDATE_CHAMADO: 'CHAMADOS_TICKETS/UPDATE_CHAMADO',

  CHANGE_BUSCA: 'CHAMADOS_TICKETS/CHANGE_BUSCA',

  SET_CHAMADO_ID: 'CHAMADOS_TICKETS/SET_CHAMADO_ID', 
  
  RESET_CHAMADOS: 'CHAMADOS_TICKETS/RESET_CHAMADOS',
};

//Action Creators
export const getChamados = (idUsuario, filtros = {}, limit, search, orderBy) => ({ type: Types.GET_CHAMADOS, idUsuario, filtros, limit, search, orderBy });
export const getChamadosSuccess = (chamados, total, totalMensagensNaoLidas) => ({ type: Types.GET_CHAMADOS_SUCCESS, chamados, total, totalMensagensNaoLidas });
export const getChamadosError = (error) => ({ type: Types.GET_CHAMADOS_ERROR, error });

export const updateChamado = (chamado, options) => ({ type: Types.UPDATE_CHAMADO, chamado, options });

export const setChamadoId = (chamadoId) => ({ type: Types.SET_CHAMADO_ID, chamadoId });

export const resetChamados = () => ({ type: Types.RESET_CHAMADOS });

//saga
function* fetchChamadosSaga(action) {
  try {
    const { idUsuario, filtros, limit, search, orderBy } = action;

    const { chamados, total, totalMensagensNaoLidas } = yield call(getChamadosSdk, idUsuario, filtros, limit, search, orderBy);
    yield put(getChamadosSuccess(chamados, total, totalMensagensNaoLidas));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChamadosError(err));
  }
}

export const saga = [takeLatest(Types.GET_CHAMADOS, fetchChamadosSaga)];

// Reducer
const initialState = Map({
  chamados: List(),
  loading: false,
  success: false,
  error: false,
  total: 0,
  totalUnseenMessages: 0,

  chamadoId: null,
});

const handleGetChamados = (state, action) => {
  return state.set('filtros', action.filtros).set('loading', true).set('success', false).set('error', false);
};

const handleGetChamadosSuccess = (state, action) => {
  const { chamados, total, totalMensagensNaoLidas } = action;

  return state.set('chamados', List(chamados)).set('loading', false).set('success', true).set('error', false).set('total', total).set('totalUnseenMessages', totalMensagensNaoLidas);
};

const handleGetChamadosError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleUpdateChamado = (state, action) => {
  const { chamado, options } = action;

  if (options?.updateUnseenMessages) {
    return state
      .set('totalUnseenMessages',  state.get('totalUnseenMessages') - state.get('chamados').find(({ id }) => id === parseInt(chamado.id))?.totalMensagensPendentes)
      .set('chamados', state.get('chamados').map((chamadoInList) => chamadoInList.id === parseInt(chamado.id) ? ({ ...chamadoInList, totalMensagensPendentes: 0 }) : ({ ...chamadoInList })));
  } else if (options?.updateAwaitingBriefing) {
    const newChamados = state.get('chamados').map((chamadoInList) => chamadoInList.id === parseInt(chamado.id) ? ({ ...chamadoInList, status: 'ABERTO', statusCor: '#FF9800', statusDs: 'Aberto' }) : ({ ...chamadoInList }));

    return state.set('chamados', newChamados);
  } else {
    const newChamados = state.get('chamados').map((chamadoInList) => chamadoInList.id === parseInt(chamado.id) ? ({ ...chamado }) : ({ ...chamadoInList }));

    return state.set('chamados', newChamados);
  }
};

const handleSetChamadoId = (state, action) => {
  return state
    .set('chamadoId', action.chamadoId);
};

const handleResetChamados = (state, action) => {
  return state
    .set('chamados', List());
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHAMADOS:
      return handleGetChamados(state, action);
    case Types.GET_CHAMADOS_SUCCESS:
      return handleGetChamadosSuccess(state, action);
    case Types.GET_CHAMADOS_ERROR:
      return handleGetChamadosError(state, action);
    case Types.UPDATE_CHAMADO:
      return handleUpdateChamado(state, action);
    case Types.SET_CHAMADO_ID:
      return handleSetChamadoId(state, action);

    case Types.RESET_CHAMADOS:
      return handleResetChamados(state, action);
    default: return state;
  }
}
