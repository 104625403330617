import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductRegister, putProduct, postProduct, reset } from '@ducks/produtos';
import { getValueFromFile, InfoUsuario, download } from '@utils';
import { authorization } from '@sdk/api';
import App from '@app';
import { Body, Breadcrumb, Button, Form, Column, Grid, Select, Input, Skeleton, View, Switch, InputNumber } from '@components-teammove';
import { Atachments, Container, Header, Forms, UploadIcon, UploadText, UploadEdge, ImageCover, ComponentEmptyCover, IconRemoveCover, IconLoading, SkeletonLoad } from './styles';
import { InitialValuesProducts } from './rules';

const { REACT_APP_API } = window.env;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function ProductRegistration() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();

  const productRegister = useSelector(({ produtos }) => produtos.get('productRegister'));
  const loadingProductRegister = useSelector(({ produtos }) => produtos.get('loadingProductRegister'));
  const successProductRegister = useSelector(({ produtos }) => produtos.get('successProductRegister'));

  const loadingProductRegisterSave = useSelector(({ produtos }) => produtos.get('loadingProductRegisterSave'));
  const successProductRegisterSave = useSelector(({ produtos }) => produtos.get('successProductRegisterSave'));

  const [cover, setCover] = useState(null);
  const [loadingCover, setLoadingCover] = useState(false);

  const [extraFields, setExtraFields] = useState();

  useEffect(() => {
    dispatch(getProductRegister(id > 0 ? parseInt(id) : undefined));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (successProductRegister) {
      const productToSet = { ...productRegister.product };
      form.setFieldsValue(id === -1 ? InitialValuesProducts : productToSet);

      handleSetInititalPicture();
      setExtraFields(productRegister.extraFields);
    }

  }, [productRegister, successProductRegister]);

  useEffect(() => {
    if (productRegister.product?.extraFields) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...productRegister.product.extraFields.reduce((state, exField) => ({ ...state, [`exField${exField.id}`]: exField.value }), {})
      });
    }

  }, [productRegister]);

  useEffect(() => {
    if (successProductRegisterSave) {

      window.history.back();
    }
  }, [successProductRegisterSave]);

  const handleSetInititalPicture = () => {
    form.setFieldsValue({ picture: productRegister.product?.picture });
    setCover(productRegister.product?.picture);
  };

  const handleChangeCover = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingCover(true);
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        form.setFieldsValue({ picture: info.file.response });
        setCover(url);
        setLoadingCover(false);
      });
    }
  };

  const handleRemoveCover = () => {
    setCover(null);
    form.setFieldsValue({ picture: null });
    form.validateFields(['picture']).then();
  };

  const handleExtraFieldChange = (extraField, newValue) => {
    setExtraFields(extraFields.map((exField) => {
      return exField.id === extraField.id ?
        { ...exField, value: { id: exField.id, value: newValue } } :
        { ...exField };
    }));
  };
  const handleActive = (value) => {
    form.setFieldsValue({ active: value });
  };

  const handleSalvar = () => {

    form.validateFields().then((values) => {

      values.extraFields = extraFields.map(({ value, id }) => {

        const extraField = productRegister?.extraFields?.find((exField) => exField.id === id);

        return value ? { id: value.id, value: value.value } : { id: extraField.id, value: extraField.value };
      });

      Object.keys(values).forEach((key) => key.includes('exField') && delete values[key]);
      if (id > 0) {
        dispatch(putProduct(values));
      }
      else {
        dispatch(postProduct(values));
      }
    });
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <Header
          title={`${id === '-1' ? 'Cadastro' : 'Edição'} de produto`}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/mixprodutos', name: 'Produtos' },
                { path: '/mixprodutos/cadastro', name: 'Cadastro de produtos', current: true }
              ]}
            />
          )}
          extra={[
            <Button key='2' size='small' context='header' type='secondary' onClick={() => history.goBack()}>
              Cancelar
            </Button>,
            <Button key='1' size='small' context='header' type='primary' onClick={handleSalvar} loading={loadingProductRegisterSave}>
              Salvar
            </Button>,
          ]}
        >
          <Forms>
            <Container>
              <Form layout='vertical' form={form}>
                <Form.Item name='id' hidden />
                <Column gap='30px'>
                  <Grid>
                    {loadingProductRegister ? <Skeleton.Form /> : successProductRegister &&
                      <Form.Item name='families' label='Famílias' rules={[{ required: true, message: 'Selecione as famílias do item' }]}>
                        <Select mode={'multiple'} placeholder='Selecione as famílias do item' options={productRegister.families} />
                      </Form.Item>}

                    {loadingProductRegister ? <Skeleton.Form /> : successProductRegister &&
                      <Form.Item name='name' label='Item' rules={[{ required: true, message: 'Digite o nome do item' }]}>
                        <Input placeholder='Digite o nome do item' />
                      </Form.Item>}

                    {loadingProductRegister ? <Skeleton.Form /> : successProductRegister &&
                      <Form.Item name='description' label='Descrição'>
                        <Input.TextArea placeholder='Adicione uma descrição para o produto' />
                      </Form.Item>}

                    {loadingProductRegister ? <Skeleton.Form /> : successProductRegister &&
                      <Form.Item name='weight' label='Peso' rules={[{ required: true, message: 'Adicione o peso do produto' }]}>
                        <InputNumber precision={2} suffix=' Kg' placeholder='Adicione o peso do produto' />
                      </Form.Item>}

                    {loadingProductRegister ? <Skeleton.Form /> :
                      (extraFields?.map((extraField) => extraField.highlight && extraField.kind === 'TEXT' ? (
                        <Form.Item name={`exField${extraField.id}`} key={extraField.id} required={extraField.required} rules={[{ required: extraField.required, message: 'Insira um valor para o campo' }]} label={extraField.name} colon={false}>
                          <Input
                            autocomplete='off'
                            value={extraFields?.find((exField) => exField === extraField.id)?.value?.value}
                            placeholder={extraField.name}
                            onChange={(evt) => handleExtraFieldChange(extraField, evt?.target?.value)}
                          />
                        </Form.Item>
                      ) : extraField.highlight && extraField.kind === 'PARAGRAPH' && (
                        <Form.Item name={`exField${extraField.id}`} key={extraField.id} required={extraField.required} rules={[{ required: extraField.required, message: 'Insira um valor para o campo' }]} label={extraField.name} >
                          <Input.TextArea
                            value={extraFields?.find((exField) => exField === extraField.id)?.value?.value}
                            placeholder='Descreva sobre o campo'
                            onChange={(evt) => handleExtraFieldChange(extraField, evt?.target?.value)}
                          />
                        </Form.Item>
                      )))}
                    {id !== '-1' && (
                      <Form.Item name='active' label='Ativo' valuePropName='checked'>
                        <Switch checked={productRegister.product?.active} onChange={(value) => handleActive(value)} />
                      </Form.Item>)}
                  </Grid>
                </Column>
              </Form>
            </Container>

            <Atachments>
              {loadingProductRegister ? (<SkeletonLoad />
              ) : successProductRegister && (
                <Form layout='vertical' form={form}>
                  <Form.Item label="Anexos" valuePropName name="picture" getValueFromEvent={getValueFromFile}>
                    <UploadEdge
                      showUploadList={false}
                      headers={authorization()}
                      folder={`Produtos/${InfoUsuario.get('cdCliente')}`}
                      URI={REACT_APP_API}
                      onDownload={(file) => download(file.response)}
                      accept={'image/*'}

                      onChange={handleChangeCover}
                    >
                      {cover && !loadingCover ? (
                        <View>
                          <ImageCover width={223} height={170} src={cover} alt='Picture' />
                          <IconRemoveCover
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRemoveCover();
                            }}
                          />
                        </View>
                      ) : (
                        <ComponentEmptyCover>
                          {loadingCover ? <IconLoading /> : <UploadIcon type='upload' />}
                          <UploadText>Clique ou arraste para anexar</UploadText>
                        </ComponentEmptyCover>
                      )}
                    </UploadEdge>
                  </Form.Item>
                </Form>)}
            </Atachments>
          </Forms>
        </Header>
      </Body>
    </App>
  );
}