import styled from 'styled-components';
import { View } from '@components';
import { Form } from '@components-teammove';

export const FlexRow = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const FlexRowMinorGap = styled(FlexRow)`
  gap: 5px;
`;
  
export const HalfWidthFormItem = styled(Form.Item)`
  width: 50%;
  .ant-form-item-label{
    padding: 0;
  }

  *:not(.ant-radio-button-wrapper){
    border-radius: 10px !important;
  }
`;