import React, { useEffect } from 'react';
import { Button } from '@components-teammove';
import { Container, Upload, ContentImageUpload, IconFileDoneOutlinedUpload, Column, ContainerTitle, FileName, ImageWrapper } from './styles';
import { saveFile } from '@sdk/Files';
import { isImage } from '@utils';

const Uploads = ({ print, folder, onDelete, onPush, setLoadingUpload, uploads, label }) => {  
  useEffect(() => {
    if (print?.name) {
      setLoadingUpload(true);
      saveFile(print, folder).then((res) => {
        onPush({ response: res });
      }).catch((err) => console.error(err.message))
        .finally(() => setLoadingUpload(false));
    }
  }, [print]);

  return uploads.length > 0 ? (
    <Column gap='2px'>
      {label && (
        <ContainerTitle>{label}:</ContainerTitle>
      )}
      <Container>
        {uploads.map(({ path, name }) => (
          <ContentImageUpload key={path}>
            <Button.Delete onClick={() => onDelete(path)} />
            <ImageWrapper>
              {isImage(path) ? <Upload src={path}/> : <IconFileDoneOutlinedUpload />}
            </ImageWrapper>
            <FileName>{name}</FileName>
          </ContentImageUpload>
        ))}
      </Container>
    </Column>
  ) : null;
};

export default Uploads;