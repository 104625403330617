import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getActivityGoalDash as getActivityGoalDashSdk,
  getActivitiesKinds as getActivitiesKindsSdk,
  getCheckIfAnyGoal as getCheckIfAnyGoalSdk
} from '@sdk/MetaAtividade';

export const PARAMS_KEY = 'PARAMS/ACITIVY_GOALS_PARAMS';

export const Types = {
  GET_ACTIVITY_GOAL_DASH: 'ACTIVITY/GET_ACTIVITY_GOAL_DASH',
  GET_ACTIVITY_GOAL_DASH_SUCCESS: 'ACTIVITY/GET_ACTIVITY_GOAL_DASH_SUCCESS',
  GET_ACTIVITY_GOAL_DASH_ERROR: 'ACTIVITY/GET_ACTIVITY_GOAL_DASH_ERROR',

  GET_ACTIVITIES_KINDS: 'ACTIVITY/GET_ACTIVITIES_KINDS',
  GET_ACTIVITIES_KINDS_SUCCESS: 'ACTIVITY/GET_ACTIVITIES_KINDS_SUCCESS',
  GET_ACTIVITIES_KINDS_ERROR: 'ACTIVITY/GET_ACTIVITIES_KINDS_ERROR',

  GET_CHECK_IF_ANY_GOAL: 'ACTIVITY/GET_CHECK_IF_ANY_GOAL',
  GET_CHECK_IF_ANY_GOAL_SUCCESS: 'ACTIVITY/GET_CHECK_IF_ANY_GOAL_SUCCESS',
  GET_CHECK_IF_ANY_GOAL_ERROR: 'ACTIVITY/GET_CHECK_IF_ANY_GOAL_ERROR',
};

export const getActivityGoalDash = (params, filters) => ({ type: Types.GET_ACTIVITY_GOAL_DASH, params, filters });
export const getActivityGoalDashSuccess = (activitiesGoals) => ({ type: Types.GET_ACTIVITY_GOAL_DASH_SUCCESS, activitiesGoals });
export const getActivityGoalDashError = (error) => ({ type: Types.GET_ACTIVITY_GOAL_DASH_ERROR, error });

export const getActivitiesKinds = (params, filters) => ({ type: Types.GET_ACTIVITIES_KINDS, params, filters });
export const getActivitiesKindsSuccess = (activitiesKinds) => ({ type: Types.GET_ACTIVITIES_KINDS_SUCCESS, activitiesKinds });
export const getActivitiesKindsError = (error) => ({ type: Types.GET_ACTIVITIES_KINDS_ERROR, error });

export const getCheckIfAnyGoal = () => ({ type: Types.GET_CHECK_IF_ANY_GOAL });
export const getCheckIfAnyGoalSuccess = (response) => ({ type: Types.GET_CHECK_IF_ANY_GOAL_SUCCESS, response });
export const getCheckIfAnyGoalError = (response) => ({ type: Types.GET_CHECK_IF_ANY_GOAL_ERROR, response });

function* fetchGetActivityGoalDash(action) {
  try {
    const { params, filters } = action;
    const activitiesGoals = yield call(getActivityGoalDashSdk, params, filters);
    yield put(getActivityGoalDashSuccess(activitiesGoals));
  } catch (err) {
    Notification.error(err.message);
    yield put(getActivityGoalDashError(err));
  }
}

function* fetchGetActivitiesKinds(action) {
  try {
    const { params, filters } = action;
    const activitiesKinds = yield call(getActivitiesKindsSdk, params, filters);
    yield put(getActivitiesKindsSuccess(activitiesKinds));
  } catch (err) {
    Notification.error(err.message);
    yield put(getActivitiesKindsError(err));
  }
}

function* fetchGetCheckIfAnyGoal(action) {
  try {
    const response = yield call(getCheckIfAnyGoalSdk);
    yield put(getCheckIfAnyGoalSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCheckIfAnyGoalError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ACTIVITY_GOAL_DASH, fetchGetActivityGoalDash),
  takeLatest(Types.GET_ACTIVITIES_KINDS, fetchGetActivitiesKinds),
  takeLatest(Types.GET_CHECK_IF_ANY_GOAL, fetchGetCheckIfAnyGoal)
];

const initialState = Map({
  activitiesGoals: List(),
  loadingActivitiesGoals: false,
  successActivitiesGoals: false,
  errorActivitiesGoals: false,

  activitiesKinds: List(),
  loadingActivitiesKinds: false,
  successActivitiesKinds: false,
  errorActivitiesKinds: false,

  checkIfAnyGoal: List(),
  loadingCheckIfAnyGoal: false,
  successCheckIfAnyGoal: false,
  errorCheckIfAnyGoal: false,
});

const handleGetActivityGoalDash = (state, action) => {
  return state.set('loadingActivitiesGoals', true).set('successActivitiesGoals', false).set('errorActivitiesGoals', false);
};

const handleGetActivityGoalDashSuccess = (state, action) => {
  const { activitiesGoals } = action;
  return state.set('activitiesGoals', activitiesGoals).set('loadingActivitiesGoals', false).set('successActivitiesGoals', true).set('errorActivitiesGoals', false);
};

const handleGetActivityGoalDashError = (state, action) => {
  return state.set('loadingActivitiesGoals', false).set('successActivitiesGoals', false).set('errorActivitiesGoals', action.error);
};

const handleGetActivitiesKinds = (state, action) => {
  return state.set('loadingActivitiesKinds', true).set('successActivitiesKinds', false).set('errorActivitiesKinds', false);
};

const handleGetActivitiesKindsSuccess = (state, action) => {
  const { activitiesKinds } = action;
  return state.set('activitiesKinds', activitiesKinds).set('loadingActivitiesKinds', false).set('successActivitiesKinds', true).set('errorActivitiesKinds', false);
};

const handleGetActivitiesKindsError = (state, action) => {
  return state.set('loadingActivitiesKinds', false).set('successActivitiesKinds', false).set('errorActivitiesKinds', action.error);
};

const handleGetCheckIfAnyGoal = (state) => {
  return state.set('loadingCheckIfAnyGoal', true).set('successCheckIfAnyGoal', false).set('errorCheckIfAnyGoal', false);
};

const handleGetCheckIfAnyGoalSuccess = (state, action) => {
  const { response } = action;
  return state.set('checkIfAnyGoal', response).set('loadingCheckIfAnyGoal', false).set('successCheckIfAnyGoal', true).set('errorCheckIfAnyGoal', false);
};

const handleGetCheckIfAnyGoalError = (state, action) => {
  return state.set('loadingCheckIfAnyGoal', false).set('successCheckIfAnyGoal', false).set('errorCheckIfAnyGoal', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ACTIVITY_GOAL_DASH:
      return handleGetActivityGoalDash(state, action);
    case Types.GET_ACTIVITY_GOAL_DASH_SUCCESS:
      return handleGetActivityGoalDashSuccess(state, action);
    case Types.GET_ACTIVITY_GOAL_DASH_ERROR:
      return handleGetActivityGoalDashError(state, action);

    case Types.GET_ACTIVITIES_KINDS:
      return handleGetActivitiesKinds(state, action);
    case Types.GET_ACTIVITIES_KINDS_SUCCESS:
      return handleGetActivitiesKindsSuccess(state, action);
    case Types.GET_ACTIVITIES_KINDS_ERROR:
      return handleGetActivitiesKindsError(state, action);

    case Types.GET_CHECK_IF_ANY_GOAL: 
      return handleGetCheckIfAnyGoal(state, action);
    case Types.GET_CHECK_IF_ANY_GOAL_SUCCESS:
      return handleGetCheckIfAnyGoalSuccess(state, action);
    case Types.GET_CHECK_IF_ANY_GOAL_ERROR:
      return handleGetCheckIfAnyGoalError(state, action);
      
    default:
      return state;
  }
}
