import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const exportExcel = (excelData, fileName) => {
  
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);

    ws['!cols'] = [
      { wch: 15 },
      { wch: 18 },
      { wch: 35 },
      { wch: 12 },
      { wch: 18 },
      { wch: 18 },
      { wch: 18 },
      { wch: 12 },
      { wch: 12 },
      { wch: 22 },
      { wch: 12 },
      { wch: 22 },
      { wch: 38 },
    ];

    const headerRange = XLSX.utils.decode_range(ws['!ref']);
    for (let col = headerRange.s.c; col <= headerRange.e.c; ++col) {
      // eslint-disable-next-line id-length
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: col });
      // eslint-disable-next-line id-length
      ws[headerCell].s = { fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '1890FF' } } };
    }

    const dataRange = XLSX.utils.decode_range(ws['!ref']);
    for (let row = dataRange.s.r; row <= dataRange.e.r; ++row) {
      for (let col = dataRange.s.c; col <= dataRange.e.c; ++col) {
        // eslint-disable-next-line id-length
        const cell = XLSX.utils.encode_cell({ r: row, c: col });
        if (!ws[cell]) continue;
        // eslint-disable-next-line id-length
        if (!ws[cell].s) ws[cell].s = {};
        ws[cell].s.alignment = { horizontal: 'left' };
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportToExcel();
};