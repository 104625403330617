import styled from 'styled-components';
import { Row  } from '@components';
import { Icon, Form, View } from '@components-teammove';

export const Content = styled(Row)`
  flex: 1;
  background: ${({ theme }) => theme.backgroundColor};
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  align-items: center;
  justify-content: center;
`;

export const ForgotPasswordCard = styled(View)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 28px !important;
  border: none;
  text-align: center;
  height: fit-content;
  padding: 49px 64px;
  width: 542px;
  position: relative;

  input {
    :-webkit-autofill, :-webkit-autofill:focus {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.darkPrimaryColor} inset !important;
      -webkit-text-fill-color: ${({ theme }) => theme.textColor};
    }
  }
`;

export const ButtonItem = styled(Form.Item)`
  text-align: right;
`;

export const BackIcon = styled(Icon.ArrowLeftOutlined)`
  &.anticon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: ${({ theme }) => theme.textColor};
    font-size: 24px;
  }
`;