import styled from 'styled-components';

export const StylesMultiDatePicker = styled.div`
  .rmdp-container {
    width: 100%;
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border: 1px solid ${({ theme }) => theme.darkHighlightColor} !important;
    border-radius: 8px !important;
    color: ${({ theme }) => theme.textColor} !important;
    padding: 16px;

    input.rmdp-input {
      background-color: transparent;
      color: ${({ theme }) => theme.textColor};
      border: none;
      font-size: 16px;
      box-shadow: none;

      ::placeholder {
        color: ${({ theme }) => theme.textColor};
        opacity: .5;
      }
    }

    :focus-within, .ant-picker-focused {
      border: 2px solid ${({ theme }) => theme.actionColor} !important;
      border-right-width: 2px !important;
      box-shadow: none !important;

      :hover {
        border-right-width: 2px !important;
      }
    }

    

    .ep-arrow {
      border-color: ${({ theme }) => theme.darkPrimaryColor}; 

      :after {
        background-color: ${({ theme }) => theme.darkPrimaryColor}; 
        box-shadow: none;
      }
    }

    .rmdp-wrapper {
      background-color: ${({ theme }) => theme.darkPrimaryColor}; 

      &.rmdp-shadow {
        box-shadow: none;
      }

      .rmdp-calendar {
        .rmdp-header {
          .rmdp-arrow-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .rmdp-arrow {
              border-color: ${({ theme }) => theme.actionColor};
              margin: 0;
            }

            :hover {
              background-color: ${({ theme }) => theme.actionColor};

              .rmdp-arrow {
                border-color: ${({ theme }) => theme.textContrastColor};
              }
            }
          }
        }

        .rmdp-day-picker {
          .rmdp-week {
            .rmdp-day {
              span {
                :hover {
                  background-color: ${({ theme }) => theme.darkSecondaryColor};
                  /* background-color: ${({ theme }) => theme.actionColor}; */
                }
              }

              &.rmdp-today {
                span {
                  background-color: ${({ theme }) => theme.actionColor};

                  :hover {
                    background-color: ${({ theme }) => theme.darkSecondaryColor};
                    /* background-color: ${({ theme }) => theme.actionColor}; */
                  }
                }
              }

              &.rmdp-selected {
                span {
                  background-color: ${({ theme }) => theme.darkSecondaryColor};
                  box-shadow: none;
                }
              }
            }
          }
        }
      }

      .rmdp-panel {
        .rmdp-panel-body {
          li {
            background-color: ${({ theme }) => theme.darkSecondaryColor};
            box-shadow: none;

            button.b-deselect {
              background-color: transparent;
              color: ${({ theme }) => theme.textContrastColor};
            }
          }
        }
      }

      .ant-picker-panel-container {
        background-color: ${({ theme }) => theme.darkPrimaryColor};
        color: ${({ theme }) => theme.textColor};

        .ant-picker-header {
          color: ${({ theme }) => theme.textColor};

          .ant-picker-header-super-prev-btn,
          .ant-picker-header-prev-btn,
          .ant-picker-header-next-btn,
          .ant-picker-header-super-next-btn {
            color: ${({ theme }) => theme.textColor};
          }
        }

        .ant-picker-content th {
          color: ${({ theme }) => theme.textColor};
        }

        .ant-picker-cell {
          color: ${({ theme }) => theme.textColor};

          &.ant-picker-cell-disabled {
            background-color: ${({ theme }) => theme.darkSecondary};
            opacity: .4;

            :before {
              background-color: ${({ theme }) => theme.darkSecondary};
            }

            .ant-picker-cell-inner {
              color: ${({ theme }) => theme.textColor};
            }
          }

          :hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, 
          :hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
            background-color: ${({ theme }) => theme.actionColor};
          }

          &.ant-picker-cell-in-view {
            &.ant-picker-cell-in-range,
            &.ant-picker-cell-range-start,
            &.ant-picker-cell-range-end {

              &.ant-picker-cell-range-start-single {
                :before {
                  background-color: transparent;
                }
              }

              &.ant-picker-cell-range-hover-start,
              &.ant-picker-cell-range-hover-end {
                .ant-picker-cell-inner {
                  :not(.ant-picker-cell-range-end) {
                    ::after {
                      background-color: ${({ theme }) => theme.actionColor} !important;
                    }
                  }
                }
              }
              :before {
                background-color: ${({ theme }) => theme.darkSecondary};
              }
            }
          }

          :not(.ant-picker-cell-in-view):not(.ant-picker-cell-disabled) {
            opacity: .4;
          }
        }

        .ant-picker-footer .ant-picker-ranges .ant-picker-preset .ant-tag {
          color: ${({ theme }) => theme.textColor};
          background: transparent;
          border-color: ${({ theme }) => theme.textColor};

          :hover {
            background-color: ${({ theme }) => theme.actionColor};
            border: none;
          }
        }
      }

      .ant-picker-cell-in-view {
        .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
          background-color: ${({ theme }) => theme.actionColor} !important;
        }

        &.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-color: ${({ theme }) => theme.actionColor};
        }

        &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
          .ant-picker-cell-inner {
            background-color: ${({ theme }) => theme.actionColor};
          }
        }

        &.ant-picker-range-hover, &.ant-picker-range-hover-end {
          border-color: ${({ theme }) => theme.actionColor};

          ::after {
            background-color: ${({ theme }) => theme.actionColor};
            border-color: ${({ theme }) => theme.actionColor};
          }
        }
      }
    }
  }
`;