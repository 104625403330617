import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActivityView } from '../styles';

export function SortableItem({ children, id, ...props }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    // eslint-disable-next-line
    <ActivityView ref={setNodeRef} style={style} {...attributes} {...listeners} {...props}>
      {children}
    </ActivityView>
  );
}