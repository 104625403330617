import React from 'react';
import { Icon, Form } from '@components-teammove';
import { Container, Header, ContentImageHeader, ContentInfoHeader, UserNameHeader, UserPhotoHeader, IconDropDown, ContentBody, ContentMenu, MenuItem, ContainerPreview, ContainerPreviewLogin, CardContainer, ContentImage, FormItemInput, PrimaryButton, SecondaryButton, RecoverPassword, ItemInput, ItemPasswordInput, ItemTextAreaInput, SelectItem, PreviewModalContainer, ModalContainer, ModalHeader, ModalTitle, ModalBody, ModalFooter, UploadContent, UploadIcon, UploadLabel, DropdownItem, Logo } from './styles';
import { TelasNomenclaturas } from '@utils';

const DEFAULT_LOGO = 'https://res.cloudinary.com/teammove/image/upload/v1667907784/logo_teammove_small_white.png';
const DEFAULT_USER_PHOTO = 'https://res.cloudinary.com/teammove/image/upload/v1566574600/clientes/sem_foto.png';

export default function WhitelabelPreview({ whitelabel }) {
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  return (
    <Container>
      <Header themePreview={whitelabel}>
        <ContentImageHeader>
          <Logo src={whitelabel?.logoHeader || DEFAULT_LOGO} alt='Logo header' />
        </ContentImageHeader>
        <ContentInfoHeader>
          <UserNameHeader themePreview={whitelabel}>John Doe</UserNameHeader>
          <UserPhotoHeader src={DEFAULT_USER_PHOTO} />
          <IconDropDown themePreview={whitelabel} />
        </ContentInfoHeader>
      </Header>
      <ContentBody>
        <ContentMenu themePreview={whitelabel}>
          <MenuItem><Icon.HomeOutlined />Início</MenuItem>
          <MenuItem><Icon.TrophyOutlined />Campanhas</MenuItem>
          <MenuItem><Icon.ShopOutlined />{companyNomenclature.plural}</MenuItem>
          <MenuItem><Icon.SettingOutlined />Configurações</MenuItem>
        </ContentMenu>
        <ContainerPreview themePreview={whitelabel}>
          <ContainerPreviewLogin>
            <CardContainer themePreview={whitelabel}>
              <ContentImage themePreview={whitelabel}>
                <Logo src={whitelabel?.logo || DEFAULT_LOGO} alt='Logo' />
              </ContentImage>
              <Form hideRequiredMark layout='vertical'>
                <FormItemInput themePreview={whitelabel} label='Login' rules={[{ required: true, message: 'Informe o login!' }]}>
                  <ItemInput themePreview={whitelabel} placeholder='teammove@teammove.com.br' />
                </FormItemInput>
                <FormItemInput themePreview={whitelabel} label='Senha' rules={[{ required: true, message: 'Informe a senha!' }]}>
                  <ItemPasswordInput themePreview={whitelabel} placeholder='********' />
                </FormItemInput>
                <FormItemInput>
                  <PrimaryButton themePreview={whitelabel} type='primary' htmlType='submit' block>Entrar</PrimaryButton>
                  <RecoverPassword themePreview={whitelabel}>Esqueci minha senha</RecoverPassword>
                </FormItemInput>
              </Form>
            </CardContainer>
          </ContainerPreviewLogin>
          <PreviewModalContainer>
            <ModalContainer themePreview={whitelabel}>
              <ModalHeader>
                <ModalTitle themePreview={whitelabel}>Exemplo de modal</ModalTitle>
              </ModalHeader>
              <Form hideRequiredMark layout='vertical'>
                <ModalBody>
                  <FormItemInput themePreview={whitelabel} label={companyNomenclature.nomenclatura} rules={[ { required: true, message: `Informe ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ${(companyNomenclature.nomenclatura).toLowerCase()}` } ]}>
                    <SelectItem themePreview={whitelabel} placeholder={'Selecione ' + ((companyNomenclature.artigo) === 'a' ? 'uma' : 'um') + ' ' + (companyNomenclature.nomenclatura).toLowerCase()} dropdownRender={(menu) => <DropdownItem themePreview={whitelabel}>{menu}</DropdownItem>}>
                      <SelectItem.Option value={1}>{companyNomenclature.nomenclatura} 1</SelectItem.Option>
                      <SelectItem.Option value={2}>{companyNomenclature.nomenclatura} 2</SelectItem.Option>
                      <SelectItem.Option value={3}>{companyNomenclature.nomenclatura} 3</SelectItem.Option>
                    </SelectItem>
                  </FormItemInput>
                  <FormItemInput themePreview={whitelabel} label='Assunto' rules={[{ required: true, message: 'Informe o assunto!' }]}>
                    <ItemInput themePreview={whitelabel} placeholder='Escreva o assunto do ticket' />
                  </FormItemInput>
                  <FormItemInput themePreview={whitelabel} label='Mensagem' rules={[{ required: true, message: 'Informe a mensagem!' }]}>
                    <ItemTextAreaInput themePreview={whitelabel} placeholder='Escreva uma mensagem para quem vai resolver seu ticket' />
                  </FormItemInput>
                  <FormItemInput themePreview={whitelabel} label='Anexo' rules={[{ required: true, message: 'Informe um anexo!' }]}>
                    <UploadContent themePreview={whitelabel}>
                      <UploadIcon themePreview={whitelabel} type='upload' />
                      <UploadLabel themePreview={whitelabel}>Clique ou arraste um arquivo para anexar ao ticket</UploadLabel>
                    </UploadContent>
                  </FormItemInput>
                </ModalBody>
                <FormItemInput>
                  <ModalFooter>
                    <SecondaryButton themePreview={whitelabel} type='secondary' htmlType='submit' block>Cancelar</SecondaryButton>
                    <PrimaryButton themePreview={whitelabel} type='primary' htmlType='submit' block>Salvar</PrimaryButton>
                  </ModalFooter>
                </FormItemInput>
              </Form>
            </ModalContainer>
          </PreviewModalContainer>
        </ContainerPreview>
      </ContentBody>
    </Container>
  );
}