import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification, getCurrentYear, getCurrentMonth } from '@utils';
import {
  getDashCluster as getDashClusterSdk,
  getYearSummary as getYearSummarySdk,
  getClustersByGroup as getClustersByGroupSdk,
  getCompanysWithClusterSituation as getCompanysWithClusterSituationSdk,
} from '@sdk/Clusters';

export const FILTERS_KEY = 'FILTROS/DASH';
export const YEAR = 'YEAR';
export const CLUSTER = 'CLUSTER';
export const MONTH = 'MONTH';
export const GROUP = 'GROUP';
export const TESTE = 'TESTE';
export const SEARCH = 'SEARCH';
export const STATUS = 'STATUS';

//Action Types
export const Types = {
  GET_DASH_CLUSTER: 'GET_DASH_CLUSTER',
  GET_DASH_CLUSTER_SUCCESS: 'GET_DASH_CLUSTER_SUCCESS',
  GET_DASH_CLUSTER_ERROR: 'GET_DASH_CLUSTER_ERROR',

  GET_YEAR_SUMMARY: 'GET_YEAR_SUMMARY',
  GET_YEAR_SUMMARY_SUCCESS: 'GET_YEAR_SUMMARY_SUCCESS',
  GET_YEAR_SUMMARY_ERROR: 'GET_YEAR_SUMMARY_ERROR',

  GET_CLUSTER_BY_GROUP: 'GET_CLUSTER_BY_GROUP',
  GET_CLUSTER_BY_GROUP_SUCCESS: 'GET_CLUSTER_BY_GROUP_SUCCESS',
  GET_CLUSTER_BY_GROUP_ERROR: 'GET_CLUSTER_BY_GROUP_ERROR',

  GET_COMPANY_CLUSTER_SITUATION: 'GET_COMPANY_CLUSTER_SITUATION',
  GET_COMPANY_CLUSTER_SITUATION_SUCCESS: 'GET_COMPANY_CLUSTER_SITUATION_SUCCESS',
  GET_COMPANY_CLUSTER_SITUATION_ERROR: 'GET_COMPANY_CLUSTER_SITUATION_ERROR',
};

//Action Creators
export const getDashCluster = (filters, search) => ({ type: Types.GET_DASH_CLUSTER, filters, search });
export const getDashClusterSuccess = (dash) => ({ type: Types.GET_DASH_CLUSTER_SUCCESS, dash });
export const getDashClusterError = (error) => ({ type: Types.GET_DASH_CLUSTER_ERROR, error });

export const getYearSummary = (groupId, year, filters, clusterId, month) => ({ type: Types.GET_YEAR_SUMMARY, groupId, year, filters, clusterId, month });
export const getYearSummarySuccess = (yearSummary) => ({ type: Types.GET_YEAR_SUMMARY_SUCCESS, yearSummary });
export const getYearSummaryError = (error) => ({ type: Types.GET_YEAR_SUMMARY_ERROR, error });

export const getClustersByGroup = (groups) => ({ type: Types.GET_CLUSTER_BY_GROUP, groups });
export const getClustersByGroupSuccess = (clustersByGroup) => ({ type: Types.GET_CLUSTER_BY_GROUP_SUCCESS, clustersByGroup });
export const getClustersByGroupError = (error) => ({ type: Types.GET_CLUSTER_BY_GROUP_ERROR, error });

export const getCompanyClusterSituation = (group, endDate, search, filters, cluster, limit, status) => ({ type: Types.GET_COMPANY_CLUSTER_SITUATION, group, endDate, search, filters, cluster, limit, status });
export const getCompanyClusterSituationSuccess = (companyClusterSituation) => ({ type: Types.GET_COMPANY_CLUSTER_SITUATION_SUCCESS, companyClusterSituation });
export const getCompanyClusterSituationError = (error) => ({ type: Types.GET_COMPANY_CLUSTER_SITUATION_ERROR, error });

function* fetchGetDashCluster(action) {
  try {
    const { filters, search } = action;
    const dash = yield call(getDashClusterSdk, filters, search);
    yield put(getDashClusterSuccess(dash));
  } catch (err) {
    Notification.error(err.message);
    yield put(getDashClusterError(err));
  }
}

function* fetchGetYearSummary(action) {
  try {
    const { groupId, year, filters, clusterId, month } = action;
    const getMonth = parseInt(localStorage.getItem('MONTH')); 
    const getYear = parseInt(localStorage.getItem('YEAR'));
    const getCluster = parseInt(localStorage.getItem('CLUSTER'));
    const getGroup = parseInt(localStorage.getItem('GROUP'));
  
    if(clusterId >= 0) {
      localStorage.setItem('CLUSTER', JSON.stringify(clusterId));
    }else if(!getCluster >= 0) {
      localStorage.setItem('CLUSTER', JSON.stringify(0));
    }
    if(groupId) {
      localStorage.setItem('GROUP', JSON.stringify(groupId));
    }else if(!getGroup) {
      localStorage.setItem('GROUP', JSON.stringify(1));
    }
    if(month) {
      const falseMonth = month - 1;
      localStorage.setItem('MONTH', JSON.stringify(falseMonth));
    }else if(!getMonth) {
      const currentMonth = getCurrentMonth();
      localStorage.setItem('MONTH', JSON.stringify(currentMonth));  
    } 
    if(year) {
      localStorage.setItem('YEAR',  JSON.stringify(year));
    } else if(!getYear) {
      const currentYear = parseInt(getCurrentYear());
      localStorage.setItem('YEAR',  JSON.stringify(currentYear));
    }
   
    const yearSummary = yield call(getYearSummarySdk, groupId, year, filters, clusterId);
    yield put(getYearSummarySuccess(yearSummary));
  } catch (err) {
    Notification.error(err.message);
    yield put(getYearSummaryError(err));
  }
}

function* fetchGetClusterByGroup(action) {
  try {
    const { groups } = action;
    const clustersByGroup = yield call(getClustersByGroupSdk, groups);
    yield put(getClustersByGroupSuccess(clustersByGroup));
  } catch (err) {
    Notification.error(err.message);
    yield put(getClustersByGroupError(err));
  }
}

function* fetchGetCompanyClusterSituation(action) {
  try{
    const getCluster = parseInt(localStorage.getItem('CLUSTER'));
    const getSearch = localStorage.getItem('SEARCH');
    
    const { group, endDate, search, filters, cluster, limit, status } = action;
    
    if(status) {
      localStorage.setItem('STATUS', (status));
    }else{
      localStorage.removeItem('STATUS');
    }
    
    if(search === '' || search) {
      localStorage.setItem('SEARCH', (search));
    }else if(!getSearch) {
      localStorage.setItem('SEARCH', (''));
    }
    if(cluster >= 0) {
      localStorage.setItem('CLUSTER', JSON.stringify(cluster));
    }else if(!getCluster >= 0) {
      localStorage.setItem('CLUSTER', JSON.stringify(0));
    }
    if(group) {
      localStorage.setItem('GROUP', JSON.stringify(group));
    }else if(!group) {
      localStorage.setItem('GROUP', JSON.stringify(1));
    }
    const companyClusterSituation = yield call(getCompanysWithClusterSituationSdk, group, endDate, search, filters, cluster, limit, status);
    yield put(getCompanyClusterSituationSuccess(companyClusterSituation));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCompanyClusterSituationError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_DASH_CLUSTER, fetchGetDashCluster),
  takeLatest(Types.GET_YEAR_SUMMARY, fetchGetYearSummary),
  takeLatest(Types.GET_CLUSTER_BY_GROUP, fetchGetClusterByGroup),
  takeLatest(Types.GET_COMPANY_CLUSTER_SITUATION, fetchGetCompanyClusterSituation)
];

// Reducer
const initialState = Map({
  dash: List(),
  loadingDash: false,
  successDash: false,
  errorDash: false,
  yearSummary: List(),
  loadingYearSummary: false,
  successYearSummary: false,
  errorYearSummary: false,
  clustersByGroup: List(),
  loadingClustersByGroup: false,
  successClustersByGroup: false,
  errorClustersByGroup: false,
  companyClusterSituation: List(),
  loadingCompanyClusterSituation: false,
  successCompanyClusterSituation: false,
  errorCompanyClusterSituation: false,
  groupId: null,
  clusterId: null,
});

const handleGetDashCluster = (state, action) => {
  return state.set('loadingDash', true).set('successDash', false).set('errorDash', false);
};

const handleGetDashClusterSuccess = (state, action) => {
  const { dash } = action;
  return state.set('dash', dash).set('loadingDash', false).set('successDash', true).set('errorDash', false);
};

const handleGetDashClusterError = (state, action) => {
  return state.set('loadingDash', false).set('successDash', false).set('errorDash', action.error);
};

const handleGetYearSummary = (state, action) => {
  const { groupId, clusterId } = action;
  return state.set('loadingYearSummary', true).set('successYearSummary', false).set('errorYearSummary', false).set('groupId', groupId).set('clusterId', clusterId);
};

const handleGetYearSummarySuccess = (state, action) => {
  const { yearSummary } = action;
  return state.set('yearSummary', yearSummary).set('loadingYearSummary', false).set('successYearSummary', true).set('errorYearSummary', false);
};

const handleGetYearSummaryError = (state, action) => {
  return state.set('loadingYearSummary', false).set('successYearSummary', false).set('errorYearSummary', action.error);
};

const handleGetClustersByGroup = (state, action) => {
  return state.set('loadingClustersByGroup', true).set('successClustersByGroup', false).set('errorClustersByGroup', false);
};

const handleGetClustersByGroupSuccess = (state, action) => {
  const { clustersByGroup } = action;
  return state.set('clustersByGroup', clustersByGroup).set('loadingClustersByGroup', false).set('successClustersByGroup', true).set('errorClustersByGroup', false);
};

const handleGetClustersByGroupError = (state, action) => {
  return state.set('loadingClustersByGroup', false).set('successClustersByGroup', false).set('errorClustersByGroup', action.error);
};

const handleGetCompanyClusterSituation = (state, action) => {
  return state.set('loadingCompanyClusterSituation', true).set('successCompanyClusterSituation', false).set('errorCompanyClusterSituation', false);
};

const handleGetCompanyClusterSituationSuccess = (state, action) => {
  const { companyClusterSituation } = action;
  return state.set('companyClusterSituation', companyClusterSituation).set('loadingCompanyClusterSituation', false).set('successCompanyClusterSituation', true).set('errorCompanyClusterSituation', false);
};

const handleGetComapnyClusterSituationError = (state, action)=>{
  return state.set('loadingCompanyClusterSituation', false).set('successCompanyClusterSituation', false).set('errorCompanyClusterSituation', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DASH_CLUSTER:
      return handleGetDashCluster(state, action);
    case Types.GET_DASH_CLUSTER_SUCCESS:
      return handleGetDashClusterSuccess(state, action);
    case Types.GET_DASH_CLUSTER_ERROR:
      return handleGetDashClusterError(state, action);

    case Types.GET_YEAR_SUMMARY:
      return handleGetYearSummary(state, action);
    case Types.GET_YEAR_SUMMARY_SUCCESS:
      return handleGetYearSummarySuccess(state, action);
    case Types.GET_YEAR_SUMMARY_ERROR:
      return handleGetYearSummaryError(state, action);

    case Types.GET_CLUSTER_BY_GROUP:
      return handleGetClustersByGroup(state, action);
    case Types.GET_CLUSTER_BY_GROUP_SUCCESS:
      return handleGetClustersByGroupSuccess(state, action);
    case Types.GET_CLUSTER_BY_GROUP_ERROR:
      return handleGetClustersByGroupError(state, action);
      
    case Types.GET_COMPANY_CLUSTER_SITUATION:
      return handleGetCompanyClusterSituation(state, action);
    case Types.GET_COMPANY_CLUSTER_SITUATION_SUCCESS:
      return handleGetCompanyClusterSituationSuccess(state, action);
    case Types.GET_COMPANY_CLUSTER_SITUATION_ERROR:
      return handleGetComapnyClusterSituationError(state, action);
      
    default:
      return state;
  }
}
