import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getDuplicatedIdApi as getDuplicatedIdApiSdk } from '@sdk/Unidades';

//Action Types
export const Types = {
  GET_ID_API_DUPLICADO: 'CONFIGURACOES_UNIDADES_ID_API/GET_ID_API_DUPLICADO',
  GET_ID_API_DUPLICADO_SUCCESS: 'CONFIGURACOES_UNIDADES_ID_API/GET_ID_API_DUPLICADO_SUCCESS',
  GET_ID_API_DUPLICADO_ERROR: 'CONFIGURACOES_UNIDADES_ID_API/GET_ID_API_DUPLICADO_ERROR'
};

//Action Creators
export const getIdApiDuplicados = () => ({ type: Types.GET_ID_API_DUPLICADO });
export const getIdApiDuplicadosSuccess = (data) => ({ type: Types.GET_ID_API_DUPLICADO_SUCCESS, data });
export const getIdApiDuplicadosError = (error) => ({ type: Types.GET_ID_API_DUPLICADO_ERROR, error });

//saga
function* fetchGetIdApiDuplicados(action) {
  try {
    const idApiDuplicados = yield call(getDuplicatedIdApiSdk);
    yield put(getIdApiDuplicadosSuccess(idApiDuplicados));
  } catch(err) {
    Notification.error(err.message);
    yield put(getIdApiDuplicadosError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_ID_API_DUPLICADO, fetchGetIdApiDuplicados)
];

// Reducer
const initialState = Map({
  idApiDuplicados: List(),
  loadingIdApiDuplicados: false,
  successIdApiDuplicados: false,
  errorIdApiDuplicados: false
});

const handleGetIdApiDuplicado = (state, action) => {
  return state
    .set('loadingIdApiDuplicados', true)
    .set('successIdApiDuplicados', false)
    .set('errorIdApiDuplicados', false);
};

const handleGetIdApiDuplicadoSuccess = (state, action) => {
  return state
    .set('idApiDuplicados', List(action.data))
    .set('loadingIdApiDuplicados', false)
    .set('successIdApiDuplicados', true)
    .set('errorIdApiDuplicados', false);
};

const handleGetIdApiDuplicadoError = (state, action) => {
  return state
    .set('loadingIdApiDuplicados', false)
    .set('successIdApiDuplicados', false)
    .set('errorIdApiDuplicados', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ID_API_DUPLICADO:
      return handleGetIdApiDuplicado(state, action);
    case Types.GET_ID_API_DUPLICADO_SUCCESS:
      return handleGetIdApiDuplicadoSuccess(state, action);
    case Types.GET_ID_API_DUPLICADO_ERROR:
      return handleGetIdApiDuplicadoError(state, action);
    default:
      return state;
  }
}