import React, { useEffect, useState } from 'react';
import { AudioPlayer, Column, Row, TypeAnimation, Skeleton } from '@components-teammove';
import { getVoipRecordDetails } from '@ducks/voip';  
import { ButtonOption, ContainerField, IconContainer, IconFeedback, OptionsText, TextFeedback, TitleText } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { moment } from '@utils';

const AfterCall = ({ info, classification, setClassification }) => {
  const dispatch = useDispatch();
  const [highlightsText, setHighlightsText] = useState(null);
  const [transcriptionText, setTranscriptionText] = useState(null);
  const [loadingRecord, setLoadingRecord] = useState(true);

  const callSid = useSelector(({ voip }) => voip.get('callSid'));
  const totalRequestForRecord = useSelector(({ voip }) => voip.get('totalRequestForRecord'));
  const recordDetails = useSelector(({ voip }) => voip.get('recordDetails'));
  const successVoipRecordDetails = useSelector(({ voip }) => voip.get('successVoipRecordDetails'));

  const { name } = info;

  const options = [
    { id: 'Relevante', label: 'Relevante', borderColor: 'green', iconColor: 'green', iconType: 'thumbs-up' },
    { id: 'Nao_Relevante', label: 'Não relevante', borderColor: 'red', iconColor: 'red', iconType: 'thumbs-down' },
    { id: 'Sem_Contato', label: 'Sem contato', borderColor: 'white', iconType: 'user-x' }
  ];

  useEffect(() => {
    if(!callSid) {
      setLoadingRecord(false);
      return;
    }
    dispatch(getVoipRecordDetails(callSid));
  }, []);

  useEffect(() => {
    if(!successVoipRecordDetails) return;
    if(!recordDetails.VoiceCallRecordDetail.RecordingUrl && totalRequestForRecord < 3) {  
      const timer = setTimeout(() => {
        dispatch(getVoipRecordDetails(callSid));
      }, 5000);
      return () => clearTimeout(timer);   
    } else {
      setLoadingRecord(false);
    }
  }, [successVoipRecordDetails]);

  const handleButtonOptions = (type) => {
    type === 'highlights' ? setHighlightsText('Esta é a transcrição da ligação. Mauris vel odio mi. Proin malesuada, eros at dignissim malesuada, turpis dui tempor nulla, sit amet iaculis dui est sed turpis. Mauris ac magna vitae augue mollis egestas. Proin sagittis ligula id metus finibus fringilla. Aliquam erat volutpat. Sed nec mi lorem. Phasellus maximus accumsan lorem non eleifend. Etiam quis tincidunt nulla. Suspendisse auctor velit sit amet tortor tempus scelerisque. In congue varius ex nec consequat. Sed vel urna malesuada, consequat nisl at, suscipit orci. Pellentesque quis viverra purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec non purus nec purus volutpat feugiat non at lorem. Etiam vel mi a elit tempor cursus. Fusce convallis leo et tortor tristique malesuada. Nulla posuere quam eu quam feugiat, ac cursus ex condimentum. Nullam ultrices urna a hendrerit molestie. Nam ac dui vel libero ultricies bibendum.') : setTranscriptionText('lorem ipsum dolor sit amet');
  };
  
  return (
    <Column gap='20px' align='center' justify='center' width='100%'>
      {!loadingRecord ? (
        recordDetails?.VoiceCallRecordDetail?.RecordingUrl && (
          <AudioPlayer widthContainer='80%' src={recordDetails.VoiceCallRecordDetail.RecordingUrl} downloadFileName={`Teammove Audio - ${name} - ${moment().format('DD-MM-YYYY HH:mm:ss')}.mp3`}/>
        )
      ) : (
        <Skeleton.MediumLine width='380px' height='50px'/>
      )}
      <TitleText>Classifique a importância dessa ligação</TitleText>
      {!classification ? (
        <Row gap='47px' justify='center' align='center'>
          {options.map((option) => (
            <Column key={option.id} gap='7px' justify='center' align='center'>
              <IconContainer onClick={() => setClassification(option.id)}>
                <IconFeedback colorIcon={option.iconColor} type={option.iconType}/>
              </IconContainer>
              <TextFeedback>{option.label}</TextFeedback>
            </Column>
          ))}
        </Row>
      ) : (
        <Column gap='7px' justify='center' align='center'>
          <IconContainer onClick={() => setClassification(null)} borderColor={options.find((option) => option.id === classification).borderColor}>
            <IconFeedback colorIcon={options.find((option) => option.id === classification).iconColor} type={options.find((option) => option.id === classification).iconType}/>
          </IconContainer>
          <TextFeedback>{options.find((option) => option.id === classification).label}</TextFeedback>
        </Column>
      )}
      <Column gap='20px' width='100%'>
        {recordDetails.HighlightsTranscript && (
          <Column width='100%' gap='8px'>
            <TextFeedback optionsFeedback>Highlights da ligação</TextFeedback>
            <ContainerField hasText={highlightsText} gap='7px' justify='center' align='center'>
              {highlightsText ? (
                <TypeAnimation
                  text={highlightsText}
                  cursor={false}
                  maxHeight='200px'
                />
              ) : (
                <ButtonOption onClick={() => handleButtonOptions('highlights')}><OptionsText>Gerar highlights</OptionsText></ButtonOption>
              )}
            </ContainerField>
          </Column>
        )}
        {recordDetails.FullTranscript && (
          <Column width='100%' gap='8px' justify='center'>
            <TextFeedback optionsFeedback>Transcrição da ligação</TextFeedback>
            <ContainerField hasText={transcriptionText} gap='7px' justify='center' align='center'>   
              {transcriptionText ? (
                <TypeAnimation
                  text={transcriptionText}
                  cursor={false}
                  maxHeight='200px'
                />
              ) : (
                <ButtonOption onClick={() => handleButtonOptions('transcription')}><OptionsText>Transcrição de texto</OptionsText></ButtonOption>
              )}
            </ContainerField>
          </Column>
        )}
      </Column>
    </Column>
  );
};

export default AfterCall;