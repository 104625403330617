import React from 'react';
import { NotificationMetas } from '@components-teammove';
import Diario from './Diario';
import Mensal from './Mensal';
import Semanal from './Semanal';

const Notifications = ({ onClose, notificationMetas }) => {
  const ordem = { monthly: 1, weekly: 2, daily: 3 };
  const metasOrdenadasTipo = notificationMetas.sort((a, b) => ordem[a.goalPeriod] - ordem[b.goalPeriod]);
  const metasOrdenadas = metasOrdenadasTipo.find((metaOrdenadaTipo) => metaOrdenadaTipo.reachedAllActivitiesKindsGoals === true);
  const metas = metasOrdenadas || metasOrdenadasTipo[0];
  
  return (
    <NotificationMetas onClose={onClose} type={metas.goalPeriod}>
      {metas.goalPeriod === 'monthly' ? 
        <Mensal onClose={onClose} metas={metas}/>
        : metas.goalPeriod === 'weekly' ?
          <Semanal metas={metas}/>
          : <Diario metas={metas}/>  
      }
    </NotificationMetas>
  );
};

export default Notifications;