import styled from 'styled-components';
import { MessageList } from 'react-chat-elements';

export const StyledChat = styled(MessageList)`
  margin: 48px 0;

  .rce-container-mbox {
    padding: 0 8%;

    .rce-mbox {
      min-width: 70px;
    }

    .rce-mbox:not(.rce-mbox-right) {
      .rce-mbox-body {
        .rce-mbox-text {
          width: fit-content;
          div {
            width: fit-content;
            div {
              width: fit-content;
            }
          }
        }

        .rce-mbox-time.non-copiable {
          left: 0;
          bottom: -5px;
          ::before {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .rce-mbox-photo + .rce-mbox-time.rce-mbox-time-block.non-copiable {
        width: fit-content;
        background: none;
        bottom: 0;

        ::before {
          background: ${(props) => props.theme.darkHighlightColor};
          padding: 4px;
          border-radius: 0 10px 0 20px;
          bottom: -1px;
          left: -1px;
        }
      }
    }

    .rce-mbox-right .rce-mbox-body .rce-mbox-time.non-copiable::before {
      right: 15px;
    }

    .rce-mbox .rce-mbox-body .rce-mbox-text {
      .ant-typography {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .rce-mbox-right .rce-mbox-body {
      .rce-mbox-title .rce-avatar-container {
        order: 1;
      }
      .rce-mbox-text {
        margin-left: auto;
        .ant-typography {
          margin-left: auto;
          margin-right: 0;
          color: ${({ theme }) => theme.textContrastColor};
        }
      }

      * {
        color: ${({ theme }) => theme.textContrastColor};
      }
    }

    .rce-avatar-container + span div {
      width: fit-content;
    }
  }

  img {
    max-width: fit-content;    
  }

  .rce-mbox-file {
    > button {
      background-color: ${(props) => props.theme.darkSecondaryColor};
   }

    .rce-mbox-file--text {
      .ant-typography {
        color: ${(props) => props.theme.textColor};
        margin: 0;
      }
    }

    .rce-mbox-file--icon svg {
      * {
        color: ${(props) => props.theme.textColor} !important;
      }
    }

    .rce-mbox-file--buttons svg {
      * {
        color: ${(props) => props.theme.textColor} !important;
      }
    }
  }

  .rce-mbox .rce-mbox-photo, .rce-mbox .rce-mbox-photo--img {
    height: auto !important;
    border-radius: 10px 20px 20px 20px;

    img {
      min-height: unset;
      max-height: 200px;
      max-width: 400px;
    }
  }
  .rce-mbox-right .rce-mbox-photo, .rce-mbox-right .rce-mbox-photo--img {
    border-radius: 20px 10px 20px 20px;
  }
  
  .rce-mbox .rce-mbox-title+.rce-mbox-photo, .rce-mbox .rce-mbox-title+.rce-mbox-photo .rce-mbox-photo--img,
  .rce-mbox-right .rce-mbox-title+.rce-mbox-photo, .rce-mbox-right .rce-mbox-title+.rce-mbox-photo .rce-mbox-photo--img {
    border-radius: 14px 8px 20px 20px;
  }

  .rce-mbox .rce-mbox-file > button {
    border-radius: 10px 20px 8px 8px;
  }
  .rce-mbox-right .rce-mbox-file > button {
    border-radius: 20px 10px 8px 8px;
  }

  .rce-mbox .rce-mbox-title+.rce-mbox-file > button {
    border-radius: 8px;
  }

  div[class*=rce-smsg] {
    background-color: transparent;
    box-shadow: none;
  }

  div[class*=date-msg] {
    div[class*=rce-smsg] {
      font-size: 14px;
      padding: 2px 3px;
      border-radius: 27px;
      background-color: ${(props) => props.theme.darkPrimaryColor};
      color: ${(props) => props.theme.textColor};
      opacity: .5;
    }
  }

  .rce-mbox-title{
    color: ${(props) => props.theme.textColor};
    font-size: 14px;
    font-weight: 400;
    gap: 4px;
    span{
      flex: 1;
    }
    margin-bottom: 3px;
  }
  
  .rce-mbox-text:after{
    content: none !important;
  }

  .rce-mbox, &.rce-mbox--clear-notch {
    background-color: ${(props) => props.theme.darkHighlightColor};
    border-radius: 10px 20px 20px 20px !important;

    .rce-mbox-left-notch {
      display: none;
      fill: ${(props) => props.theme.darkHighlightColor};
    }

    .rce-mbox-title {
      span div div {
        position: absolute;
        top: -10px;
        right: -35px;
        
        .ant-btn {
          transform: rotate(90deg);
          right: 0;
          .anticon {
            visibility: hidden;
          }
        }
      }
    }

    .rce-mbox-text {
      .ant-typography {
        color: ${(props) => props.theme.textColor};
        font-size: 16px;
        font-weight: 400;
      }

      .ant-btn .anticon {
        visibility: hidden;
      }
    }

    .rce-mbox-time {
      visibility: hidden;
      color: ${(props) => props.theme.textColor};
    }

    :hover {
      .rce-mbox-text, .rce-mbox-title {
        .ant-btn .anticon {
          visibility: visible;
        }
      }

      .rce-mbox-time {
        visibility: visible;
      }
    }
  }

  /* .rce-mbox-right .rce-mbox-title {
    margin-right: 12px;
  } */

  .rce-mbox-right, &.rce-mbox--clear-notch {
    background-color: ${(props) => props.theme.actionColor};
    border-radius: 20px 10px 20px 20px !important;

    .rce-mbox-right-notch {
      display: none;
      fill: ${(props) => props.theme.actionColor};
    }
  }
  div[class*=rce-avatar-container] {
    border-radius: 100%;
  }
  div[class*=rce-mbox-text] {
    white-space: pre-line;
  }
  .rce-mbox {
    box-shadow: none;
  }
  .rce-mbox-right {
    background-color: #0084ff;
  }
  .rce-mbox-right-notch {
    fill: #0084ff;
  }

  div[class*=rce-mbox-right] {
    .rce-mbox-title, .rce-mbox-text, .rce-mbox-time, .ant-typography {
      color: white;
    }
  }  

  div[class*=date-msg] {
    div[class*=rce-smsg] {
      font-size: 12px;
      padding: 2px 3px;
    }
  }
  .rce-avatar-container.default {
    width: 40px;
    height: 40px;
  }
`;