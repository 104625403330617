import styled from 'styled-components';
import { Column, Text } from '@components-teammove';

export const SimpleLine = styled(Column)`
  height: 349px;
`;

export const SimpleDivision = styled.div`
  background-color: ${({ status, useBgColor, theme }) => status ? `var(--${status}-color)` : useBgColor ? `${theme.backgroundColor}` : 'var(--undefined-color)'};
  width: 8px;
  height: ${({ percentage }) => percentage + '%'};

  :first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const Label = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: .5;
  }
`;
