import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getChamadosFilhosEtapas as getChamadosFilhosEtapasSdk, getPrintoutOfSteps as getPrintoutOfStepsSdk } from '@sdk/Chamados';

//Action Types
export const Types = {
  GET_CHAMADOS_ETAPAS: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS',
  GET_CHAMADOS_ETAPAS_SUCCESS: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_SUCCESS',
  GET_CHAMADOS_ETAPAS_ERROR: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_ERROR',
  GET_CHAMADOS_ETAPAS_IMPRESSAO: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_IMPRESSAO',
  GET_CHAMADOS_ETAPAS_IMPRESSAO_SUCCESS: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_IMPRESSAO_SUCCESS',
  GET_CHAMADOS_ETAPAS_IMPRESSAO_ERROR: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_IMPRESSAO_ERROR',
  GET_CHAMADOS_ETAPAS_RESET_BLOB: 'CHAMADOS_ETAPAS/GET_CHAMADOS_ETAPAS_RESET_BLOB',
};

//Action Creators
export const getSteps = (idChamado) => ({ type: Types.GET_CHAMADOS_ETAPAS, idChamado });
export const getStepsSuccess = (steps) => ({ type: Types.GET_CHAMADOS_ETAPAS_SUCCESS, steps });
export const getStepsError = (error) => ({ type: Types.GET_CHAMADOS_ETAPAS_ERROR, error });

export const getPrintoutOfSteps = (idChamado) => ({ type: Types.GET_CHAMADOS_ETAPAS_IMPRESSAO, idChamado });
export const getPrintoutOfStepsSuccess = (printing) => ({ type: Types.GET_CHAMADOS_ETAPAS_IMPRESSAO_SUCCESS, printing });
export const getPrintoutOfStepsError = (error) => ({ type: Types.GET_CHAMADOS_ETAPAS_IMPRESSAO_ERROR, error });

export const resetBlob = () => ({ type: Types.GET_CHAMADOS_ETAPAS_RESET_BLOB });

//saga
function* fetchSteps(action) {
  try {
    const { idChamado } = action;

    const steps = yield call(getChamadosFilhosEtapasSdk, idChamado);
    yield put(getStepsSuccess(steps));

  } catch (err) {
    Notification.error(err.message);
    yield put(getStepsError(err));
  }
}

function* fetchPrintingOfSteps(action) {
  try {
    const { idChamado } = action;
    const steps = yield call(getPrintoutOfStepsSdk, idChamado);
    yield put(getPrintoutOfStepsSuccess(steps));

  } catch (err) {
    Notification.error(err.message);
    yield put(getPrintoutOfStepsError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHAMADOS_ETAPAS, fetchSteps),
  takeLatest(Types.GET_CHAMADOS_ETAPAS_IMPRESSAO, fetchPrintingOfSteps)
];

// Reducer
const initialState = Map({
  steps: List(),
  loadingSteps: false,
  successSteps: false,
  errorSteps: false,

  blob: null,
  loadingBlob: false,
  successBlob: false,
  errorBlob: false,
});

const handleGetSteps = (state, action) => {
  return state
    .set('loadingSteps', true)
    .set('successSteps', false)
    .set('errorSteps', false);
};

const handleGetStepsSuccess = (state, action) => {
  const { steps } = action;

  return state
    .set('steps', steps)
    .set('loadingSteps', false)
    .set('successSteps', true)
    .set('errorSteps', false);
};

const handleGetGetError = (state, action) => {
  return state
    .set('loadingSteps', false)
    .set('successSteps', false)
    .set('errorSteps', action.error);
};

const handleGetPrintoutOfSteps = (state, action) => {
  return state
    .set('loadingBlob', true)
    .set('successBlob', false)
    .set('errorBlob', false);
};

const handleGetPrintoutOfStepsSuccess = (state, action) => {
  const { printing } = action;

  return state
    .set('blob', printing)
    .set('loadingBlob', false)
    .set('successBlob', true)
    .set('errorBlob', false);
};

const handleGetPrintoutOfStepsError = (state, action) => {
  return state
    .set('loadingBlob', false)
    .set('successBlob', false)
    .set('errorBlob', action.error);
};

const handleResetBlob = (state, action) => {
  return state
    .set('blob', null)
    .set('loadingBlob', false)
    .set('successBlob', false)
    .set('errorBlob', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHAMADOS_ETAPAS: return handleGetSteps(state, action);
    case Types.GET_CHAMADOS_ETAPAS_SUCCESS: return handleGetStepsSuccess(state, action);
    case Types.GET_CHAMADOS_ETAPAS_ERROR: return handleGetGetError(state, action);
    case Types.GET_CHAMADOS_ETAPAS_IMPRESSAO: return handleGetPrintoutOfSteps(state, action);
    case Types.GET_CHAMADOS_ETAPAS_IMPRESSAO_SUCCESS: return handleGetPrintoutOfStepsSuccess(state, action);
    case Types.GET_CHAMADOS_ETAPAS_IMPRESSAO_ERROR: return handleGetPrintoutOfStepsError(state, action);
    case Types.GET_CHAMADOS_ETAPAS_RESET_BLOB: return handleResetBlob(state, action);

    default:
      return state;
  }
}
