import React, { useEffect, useState } from 'react';
import { getChamado, changeVisibleReportReply, changeVisibleBriefingReply } from '@ducks/chamadosHistorico';
import { Row, Skeleton, Icon } from '@components-teammove';
import ModalBriefingVisualizacao from '../../../Questionario/Visualizacao';
import ModalQuestionarioReply from '../../../Questionario/Reply';
import { STATUS } from '../../../rules';
import { InfoUsuario, Notification } from '@utils';
import { StyleButton, TextQuest } from '../styles';

import { useDispatch, useSelector } from 'react-redux';

export default function Actions() {
  const dispatch = useDispatch();

  const success = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('success'));
  const visibleReportReply = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('visibleReportReply'));
  const visibleBriefingReply = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('visibleBriefingReply'));
  const successFinishReportReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishReportReply'));
  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));
  const chamado = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));
  const loading = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loading'));

  const usuario = InfoUsuario.get();
  const administrador = InfoUsuario.perm('chamadosAdministrador', usuario);
  const isUserReponsible = (chamado?.idUsuarioAtendimento === usuario.cdUsuario) || administrador;

  const isCancelado = chamado?.status === STATUS.CANCELADO;

  const [existeReport, setExisteReport] = useState({});
  const [printingBriefing, setPrintingBriefing] = useState(false);
  const [tipoQuestionario, setTipoQuestionario] = useState('');
  const [visibleQuestionario, setVisibleQuestionario] = useState(false);
  const [existeBriefing, setExisteBriefing] = useState(false);
  const [verifyReport, setVerifyReport] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const openBriefing = () => {
    setTipoQuestionario('BRIEFING');
    setVisibleQuestionario(true);
  };

  const openReport = () => {
    setTipoQuestionario('REPORT');
    verifyReport.length > 0 ? setVisibleQuestionario(true) : openReportFilling();
  };

  const openReportFilling = () => {
    dispatch(changeVisibleReportReply(true));
  };

  const atualizarReport = () => {
    setVerifyReport(
      chamado.questionario
        .filter((quest) => quest.tipoQuestionario === 'REPORT')
        .map((quest) => quest.respostas)
        .flat()
        .filter((res) => res.respondido)
    );
  };

  useEffect(() => {
    if (successFinishReportReply) {
      Notification.success('Report finalizado com sucesso!');
      dispatch(changeVisibleReportReply(false));
      dispatch(getChamado(chamado.id));
      atualizarReport();
    }
  }, [successFinishReportReply]);

  useEffect(() => {
    if (successFinishBriefingReply) {
      dispatch(getChamado(chamado.id));
      dispatch(changeVisibleBriefingReply(false));
    }
  }, [successFinishBriefingReply]);

  useEffect(() => {
    if (chamado && chamado.questionario) {
      const verifyExistReport = chamado.questionario.filter(
        (quest) => quest.tipoQuestionario === 'REPORT'
      );
      const verifyExistBriefing = chamado.questionario.filter(
        (quest) => quest.tipoQuestionario === 'BRIEFING'
      );
      setExisteReport(verifyExistReport);
      setExisteBriefing(verifyExistBriefing);
      setVerifyReport(
        chamado.questionario
          .filter((quest) => quest.tipoQuestionario === 'REPORT')
          .map((quest) => quest.respostas)
          .flat()
          .filter((res) => res.respondido)
      );
    }
  }, [chamado]);
  return (
    <>
      <Row gap='12px'>
        {chamado.status !== STATUS.AGUARDANDO_BRIEFING && (
          <>
            {loading ? (
              <Skeleton.MediumLine />
            ) : (
              Object.keys(existeBriefing).length > 0 && (
                <StyleButton
                  type='tertiary'
                  size='small'
                  onClick={() => openBriefing()}
                  width='100%' 
                  padding='6px 10px'
                >
                  <Icon.EyeOutlined />
                  Briefing
                </StyleButton>
              )
            )}
            {loading ? (
              <Skeleton.MediumLine />
            ) : (
              success &&
              chamado.status !== STATUS.ABERTO &&
              Object.keys(existeReport).length > 0 &&
              (isUserReponsible ? (
                !isCancelado && (
                  <StyleButton
                    type='tertiary'
                    size='small'
                    onClick={() => openReport()}
                    width='100%' 
                    padding='6px 10px'
                  >
                    <>
                      {verifyReport.length > 0 ? (
                        <Icon.EyeOutlined />
                      ) : (<Icon.PlusOutlined />)}
                      
                      <TextQuest
                        font='16px'
                        weight='600'
                      >
                        Report
                      </TextQuest>
                    </>
                  
                  </StyleButton>
                )
              ) : (
                verifyReport.length > 0 && (
                  <StyleButton
                    type='tertiary'
                    size='small'
                    onClick={() => openReport()}
                    width='100%' 
                    padding='6px 10px'
                  >
                    <Icon.EyeOutlined />
                    <TextQuest
                      font='16px'
                      weight='600'
                    >
                      Report
                    </TextQuest>
                  </StyleButton>
                )
              ))
            )}
          </>
        )}
      </Row>

      {visibleQuestionario && (
        <ModalBriefingVisualizacao
          visible={visibleQuestionario}
          onCancel={(tipoQuestionario) => {
            if(tipoQuestionario === 'BRIEFING') {
              setIsEditing(true);
              dispatch(changeVisibleBriefingReply(true));
            } else if(tipoQuestionario === 'REPORT') {
              setIsEditing(true);
              dispatch(changeVisibleReportReply(true));
            }
            setVisibleQuestionario(false);
          }}
          tipo={tipoQuestionario === 'BRIEFING' ? 'BRIEFING' : 'REPORT'}
          printingBriefing={printingBriefing}
          setPrintingBriefing={setPrintingBriefing}
        />
      )}

      {(visibleReportReply || visibleBriefingReply) && (
        <ModalQuestionarioReply
          visible={visibleReportReply || visibleBriefingReply}
          onCancel={() => {
            dispatch(changeVisibleBriefingReply(false));
            dispatch(changeVisibleReportReply(false));
            setIsEditing(false);
          }}
          tipo={tipoQuestionario}
          chamado={chamado}
          isEditing={isEditing}
        />
      )}
    </>
  );
}
