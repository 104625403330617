export const initialValuesUnidade = {
  identifier: '',
  name: '',
  email: '',
  cep: '',
  address: '',
  district: '',
  city: '',
  state: undefined,
  idGroup: undefined,
  idType: undefined,
  idApi: undefined,
  latitude: 0,
  longitude: 0,
  useDre: false,
  useConversionRate: false,
  useSalesTarget: false,
  useRegistrationGoalScaleChangeQuota: false,
  active: true,
  dynamicFields: []
};

export const initialValuesSecondaryAddress = {
  id: null,
  active: true,
  description: '',
  cep: '',
  address: '',
  district: '',
  city: '',
  state: undefined,
  latitude: 0,
  longitude: 0,
};