import { Button } from 'antd';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { getColorSchemeByType } from './rules';

export const StyledButton = styled(Button)`
  --idle-color: ${({ type, theme }) => getColorSchemeByType(type, theme).idle};
  --idle-label-color: ${({ type, theme }) => getColorSchemeByType(type, theme).idleLabel};

  &.ant-btn {
    background-color: ${({ transparent, type }) => transparent || type === 'link' ? 'transparent' : 'var(--idle-color)'};
    border-radius: ${({ context, rounder, size }) => context === 'form' || context === 'header' || context === 'list' || size === 'small' ? '8px' : context === 'round' || rounder ? '27px' : '12px'};
    border: ${({ bordered, type }) => bordered && type !== 'link' ? `2px solid ${'var(--idle-color)'}` : 'none'};
    border-color: ${({ type }) => type === 'link' ? 'var(--idle-label-color)' : 'var(--idle-color)'};
    opacity: 1;
    padding: ${({ context, type, size }) => type !== 'icon' && (type === 'link' ? '0' : context === 'form' ? '16px' : size === 'small' ? '6px 30px' : size === 'smaller' ? '6px 12px' : '13px 30px')};
    font-weight: ${({ context, bold }) => context === 'form' || context === 'round' || bold ? 700 : 600};
    font-size: ${({ context, size }) => size === 'smaller' ? '12px' : context === 'round' ? '14px' : '16px'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9.67px;
    width: ${({ context, fillWidth }) => context === 'form' || fillWidth ? '100%' : 'fit-content'};
    height: fit-content;
    transition: all .25s;
    &.ant-btn-link {
      text-decoration: underline;
      color: ${({ theme }) => theme.textColor};

      :hover, :focus {
        background-color: transparent !important;
      }
      :focus {
        background-color: transparent !important;
      }
    }

    * {
      color: ${({ coloredContent, context }) => context === 'round' || coloredContent ? 'var(--idle-color)' : 'var(--idle-label-color)'};
    }
    ${({ iconAfterContent }) => iconAfterContent && `
      >:first-child {
        order: 1;
      }
    `}

    :focus {
      background-color: ${({ type, theme, removeFocus }) => removeFocus ? 'var(--idle-color)' : lighten(0.085, getColorSchemeByType(type, theme).idle)} !important;
      border-color: ${({ type, theme, removeFocus }) => removeFocus ? 'var(--idle-color)' : lighten(0.085, getColorSchemeByType(type, theme).idle)} !important;
      ${({ context, type, coloredContent }) => ((context === 'round' && type === 'ghost') || coloredContent) && `
        > * {
          color: var(--idle-label-color) !important;
        }
      `}
    }
    
    :hover {
      background-color: ${({ type, theme }) => lighten(0.085, getColorSchemeByType(type, theme).idle)} !important;
      border-color: ${({ type, theme }) => lighten(0.085, getColorSchemeByType(type, theme).idle)} !important;
      ${({ context, type, coloredContent }) => ((context === 'round' && type === 'ghost') || coloredContent) && `
        * {
          color: var(--idle-label-color) !important;
        }
      `}
    }

    :active {
      background-color: ${({ type, theme }) => darken(0.085, getColorSchemeByType(type, theme).idle)} !important;
      border-color: ${({ type, theme }) => darken(0.085, getColorSchemeByType(type, theme).idle)} !important;
    }

    :disabled {
      background-color: var(--idle-color) !important;
      border-color: var(--idle-color) !important;
      opacity: .5;
      ${({ coloredContent }) => coloredContent && `
        * {
          color: var(--idle-label-color);
        }
      `}
    }
  }
`;

export const Delete = styled(Button)`
  --idle-color: ${({ theme }) => getColorSchemeByType('danger', theme).idle};
  --idle-color-label: ${({ theme }) => getColorSchemeByType('danger', theme).idleLabel};

  &.ant-btn {
    background-color: var(--idle-color);
    color: var(--idle-color-label);
    border: none;

    ${({ fixed }) => fixed && `
      position: absolute;
      top: -10px;
      z-index: 1;
    `}
    ${({ placement }) => placement === 'top-left' ? `
      left: -10px;
    ` : placement === 'top-right' ? `
      right: -10px;
    ` : ''}

    :focus {
      background-color: ${lighten(0.085, getColorSchemeByType('danger').idle)} !important;
      border-color: ${lighten(0.085, getColorSchemeByType('danger').idle)} !important;
      color: var(--idle-color-label);
    }
    
    :hover {
      background-color: ${lighten(0.085, getColorSchemeByType('danger').idle)} !important;
      border-color: ${lighten(0.085, getColorSchemeByType('danger').idle)} !important;
      color: var(--idle-color-label);
    }

    :active {
      background-color: ${darken(0.085, getColorSchemeByType('danger').idle)} !important;
      border-color: ${darken(0.085, getColorSchemeByType('danger').idle)} !important;
      color: var(--idle-color-label);
    }

    :disabled {
      background-color: var(--idle-color) !important;
      border-color: var(--idle-color) !important;
      opacity: .5;
      color: var(--idle-color-label);
    }
  }
`;