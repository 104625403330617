import React from 'react';
import PropTypes from 'prop-types';
import { Tree, } from 'antd';
import Spin from '../Spin';
import View from '../View';
import styled from 'styled-components';

const TreeStyled = styled(Tree)`
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
  }
`;

const TreeComponent = ({ loading, name, ...otherProps }) => {
  const treeName = `tree-${name}`;

  if (loading) {
    return ( 
      <View>
        <Spin /> 
      </View>
    );
  } 

  const handleExpand = (expandedKeys, expanded, node) => {
    localStorage.setItem(treeName, expandedKeys);
  };

  const getExpandedKeys = () => {
    const keys = localStorage.getItem(treeName);
    return (keys) ? keys.split(',') : [];
  };

  return(
    <TreeStyled 
      {...otherProps}
      defaultExpandedKeys={getExpandedKeys()}
      onExpand={handleExpand}
    />
  );
};

TreeComponent.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

TreeComponent.defaultProps = {
  loading: false,
  showLine: false,
  showIcon: true,
};

export default TreeComponent;
