import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Filter, SearchBar, ActiveFilters, Button, Icon } from '@components-teammove';

import { useSearchParams, formatTimeWithSeconds, scrollTop, dateToAnsi, DATE_FORMATS, ansiToMoment, Filtros } from '@utils';
import { getConteudos, getConteudosSuccess, changeVisibleAudiencia, changeVisibleConfirmacoes, changeVisibleInformacoes, changeVisibleNewFeedback, changeRead } from '@ducks/wikis';
import { apiPostNewAudiencia, postLido } from '@ducks/wikisAcoes';
import WikiBreadcrumb from './Breadcrumb';
import WikiDestaques from './Destaques';
import WikiHeaderOptions from './HeaderOptions';
import WikiEstouCiente from './EstouCiente';
import { filters } from './rules';
import FirstPageListing from './FirstPageListing';
import InnerPageListing from './InnerPageListing';
import { Row } from './styles';
import Audiencias from './Audiencias';
import ConfirmacoesLeitura from './ConfirmacoesLeitura';
import Informacoes from './Informacoes';
import NewFeedback from './NewFeedback';

export default function WikisConteudos() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const [breadcrumb, setBreadcrumb] = useState(null);
  const [categoriaPai, setCategoriaPai] = useState(Map());
  const [isFiltering, setIsFiltering] = useState(false);
  const [filterValues, setFilterValues] = useState(Filtros.get('CONTEUDOS'));
  const [pageName, setPageName] = useState('');
  const [pagePath, setPagePath] = useState('');
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(Filtros.get('CONTEUDOS')?.pesquisar?.includes('fav'));

  const history = useHistory();
  const { id } = useSearchParams();
  const dispatch = useDispatch();

  const visibleAudiencia = useSelector(({ wikis }) => wikis.get('visibleAudiencia'));
  const visibleConfirmacoes = useSelector(({ wikis }) => wikis.get('visibleConfirmacoes'));
  const visibleInformacoes = useSelector(({ wikis }) => wikis.get('visibleInformacoes'));
  const visibleNewFeedback = useSelector(({ wikis }) => wikis.get('visibleNewFeedback'));
  const audienciaParams = useSelector(({ wikis }) => wikis.get('audienciaParams'));
  const confirmacoesParams = useSelector(({ wikis }) => wikis.get('confirmacoesParams'));
  const informacoesParams = useSelector(({ wikis }) => wikis.get('informacoesParams'));
  const newFeedbackParams = useSelector(({ wikis }) => wikis.get('newFeedbackParams'));

  const conteudos = useSelector(({ wikis }) => wikis.get('conteudos').toArray());
  const extras = useSelector(({ wikis }) => wikis.get('extras'));
  const loadingConteudos = useSelector(({ wikis }) => wikis.get('loadingConteudos'));

  const inicioAudiencia = new Date();

  const onlyFavorites = useMemo(() => conteudos.filter(({ favorito }) => favorito), [conteudos]);

  const criarAudiencia = () => {
    //Caso não exista um id de categoria válido não fazer o controle da audiencia
    if (!id) return;

    apiPostNewAudiencia({
      idCategoria: id,
      horaInicio: formatTimeWithSeconds(inicioAudiencia),
      horaFim: formatTimeWithSeconds(new Date()),
    });
  };

  const onUnload = (e) => {
    e.preventDefault();
    criarAudiencia();
  };

  useEffect(() => {
    setPagePath(window.location.pathname);
    handleSearch({ descricao:'' });
    if (pageName === '' || !id) {
      setPageName('Conteúdos');
    }

    if (!id) {
      dispatch(getConteudosSuccess({}));
      setBreadcrumb({});
      setCategoriaPai(Map());

      history.replace(`${pagePath}?id=-1`);

      return;
    }

  }, [id]);

  useEffect(() => {
    const pai = extras.get('pai');

    scrollTop();
    setBreadcrumb(WikiBreadcrumb(extras.get('path'), id, false));
    setCategoriaPai(Map(pai));

    if (pai && pai.comunicado) {
      window.addEventListener('beforeunload', onUnload);
    }
    return () => {
      if (pai && pai.comunicado) {
        criarAudiencia();
      }
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [extras]);

  const handleItemClick = (item) => {
    if (!item.lido) {
      dispatch(postLido(item.id, true));
      dispatch(changeRead(true, item.id));
    }
    history.push(
      `${item.tipo === 'GRUPO' ? `${pagePath}?id=` : `${pagePath}/`}${item.id}`
    );
  };

  const handleViewFavorites = () => {
    const newShowFavoritesOnly = !showFavoritesOnly;
    setShowFavoritesOnly(newShowFavoritesOnly);

    const newFilters = {
      ...filterValues, 
      pesquisar: newShowFavoritesOnly ? 
        [...(filterValues.pesquisar || []), 'fav'] : 
        filterValues.pesquisar?.filter((param) => param !== 'fav'),
      descFiltros: {
        ...(filterValues.descFiltros || {}), 
        pesquisar: newShowFavoritesOnly ? [...(filterValues.descFiltros?.pesquisar || []), 'Favoritos'] : filterValues.descFiltros?.pesquisar?.filter((desc) => desc !== 'Favoritos')
      }
    };

    if (!Object.keys(newFilters.descFiltros).some((key) => newFilters[key] && newFilters[key]?.length > 0)) {
      delete newFilters.descFiltros;
    }

    handleSearch(newFilters);
  };

  const handleSearch = (filters) => {
    const { descricao, data, grupos, pesquisar } = filters;
    const existeFiltroFavoritos = pesquisar && pesquisar.includes('fav');
    const existeFiltroLido = pesquisar && pesquisar.includes('lidos');
    const existeFiltroNaoLido = pesquisar && pesquisar.includes('naoLidos');

    setShowFavoritesOnly(existeFiltroFavoritos);

    const favoritos = existeFiltroFavoritos && 'S';
    const lidos = existeFiltroLido && !existeFiltroNaoLido ? 'S' : !existeFiltroLido && existeFiltroNaoLido ? 'N' : existeFiltroLido && existeFiltroNaoLido && 'SN';

    const filterValuesToSet = {
      descricao,
      grupos,
      data: data && [ansiToMoment(data[0]), ansiToMoment(data[1])],
      pesquisar
    };
    if (favoritos || lidos) {
      filterValuesToSet.pesquisar = [lidos && lidos.includes('S') && 'lidos', lidos && lidos.includes('N') && 'naoLidos', favoritos && 'fav'].filter((val) => val);
    }

    setFilterValues({ ...filterValues, ...filterValuesToSet, descFiltros: filters.descFiltros });
    Filtros.set('CONTEUDOS', filters);
    setIsFiltering(descricao || lidos || favoritos || grupos || data);
    dispatch(getConteudos(id, { descricao, lidos, favoritos, comunicados: false, grupos, inicio: data && data[0] && dateToAnsi(data[0], null, DATE_FORMATS.ANSI_DATE), fim: data && data[1] && dateToAnsi(data[1], null, DATE_FORMATS.ANSI_DATE) }));
  };
  
  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={conteudos.find(({ id }) => id === parseInt(id))?.tituloPai || 'Conteúdos'}
          breadcrumb={(
            <Breadcrumb 
              items={id && id !== '-1' && breadcrumb ? [
                { path: '/', name: 'Home' }, 
                ...breadcrumb.routes.map((route) => ({ ...route, name: route.breadcrumbName })).filter(({ name }) => name),
                { path: `/visualizacao?id=${id}`, name: conteudos.find(({ id }) => id === parseInt(id))?.tituloPai, current: true }
              ] : [
                { path: '/', name: 'Home' }, 
                { path: '/visualizacao', name: 'Conteúdos', current: true }
              ]}
            />
          )}
          extra={(
            <Row gap='18px'>
              {((id === '-1' || onlyFavorites.length > 0) || showFavoritesOnly) && (
                <Button key='favorites' size='small' type='secondary' disabled={loadingConteudos} icon={<Icon.Bootstrap type='star' size='0.75rem'/>} onClick={handleViewFavorites}>{showFavoritesOnly ? 'Ver todos' : 'Ver favoritos'}</Button>
              )}
              {id === '-1' ? (
                <Button key='filter' size='small' type='secondary' onClick={() => setVisibleFilter(true)}>Filtros</Button>
              ) : WikiHeaderOptions(categoriaPai, pagePath, extras)}
            </Row>
          )}
          onBack={breadcrumb || isFiltering ? () => window.history.back() : null}
          padding='1rem'
        >
          {id === '-1' && (
            <Filter 
              visible={visibleFilter}
              onClose={() => setVisibleFilter(false)}
              filters={filters} 
              onSearch={handleSearch} 
              filtros={filterValues} 
            />
          )}

          {id === '-1' ? (
            <SearchBar 
              onSearch={(search) => handleSearch({ descricao: search })}
              placeholder="Pesquisar"  
              initialValue={filterValues?.descricao || ''}
              activeFiltersProps={{ filtros: filterValues, onSearch: handleSearch, filters: filters }}
            />
          ) : (
            <ActiveFilters filtros={filterValues} onSearch={handleSearch} filters={filters} />
          )}

          {extras.get('destaques') && extras.get('destaques').length > 0 && (
            <WikiDestaques
              list={extras.get('destaques')}
              comunicados={id === '-1' && pagePath.includes('/comunicados')}
              onItemClick={handleItemClick}
            />
          )}

          {!isFiltering && breadcrumb && categoriaPai && <WikiEstouCiente item={categoriaPai} />}

          {id === '-1' ? (
            <FirstPageListing dataSource={conteudos} loading={loadingConteudos} onItemClick={(item) => handleItemClick(item)} isFiltering={isFiltering}/>
          ) : (
            <InnerPageListing dataSource={conteudos} loading={loadingConteudos} onItemClick={(item) => handleItemClick(item)} isFiltering={isFiltering}/>
          )}

          {visibleAudiencia && (
            <Audiencias
              visible={visibleAudiencia}
              onCancel={() => dispatch(changeVisibleAudiencia(false))}
              ds={audienciaParams.titulo}
              id={audienciaParams.id}
            />
          )}

          {visibleConfirmacoes && (
            <ConfirmacoesLeitura
              visible={visibleConfirmacoes}
              onCancel={() => dispatch(changeVisibleConfirmacoes(false))}
              ds={confirmacoesParams.titulo}
              id={confirmacoesParams.id}
            />
          )}

          {visibleInformacoes && (
            <Informacoes
              visible={visibleInformacoes}
              onCancel={() => dispatch(changeVisibleInformacoes(false))}
              conteudo={informacoesParams.item}
            />
          )}

          {visibleNewFeedback && (
            <NewFeedback
              visible={visibleNewFeedback}
              onCancel={() => dispatch(changeVisibleNewFeedback(false))}
              feedbackGivenCallback={newFeedbackParams.callback}
              id={newFeedbackParams.id}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
