import React from 'react';
import { Table, Tooltip, Row } from '@components-teammove';
import AudienciaOpcoes from '../../RankingItem/Opcoes';
import { formatTimestamp } from '@utils';
import { useSelector } from 'react-redux';
import { Accesses, AccessTotal, BadgeCount, CaminhoCategoriaView, CaminhoView, CategoriaView, FeatherIcon } from '../../RankingItem/styles';

export default function TableCategoria({ data }) {
  const loading = useSelector(({ wikisAudiencia }) => wikisAudiencia.get('loading'));

  const columns = [
    {
      title: 'Conteúdo',
      dataIndex: 'dsCategoria',
      key: 'dsCategoria',
      render: (text, item, pos) => {
        const categoriaSplit = item.dsCategoria.split(' / ');
        const categoria = categoriaSplit.pop();
        const caminho = categoriaSplit.join(' / ');

        return (
          <Row gap='25px' align='center'>
            <BadgeCount>{pos + 1}</BadgeCount>
            <CaminhoCategoriaView>
              <CategoriaView>{categoria}</CategoriaView>
              <CaminhoView>{caminho}</CaminhoView>
            </CaminhoCategoriaView>
          </Row>
        );
      },
    },
    {
      title: 'Último Acesso',
      dataIndex: 'ultimoAcesso',
      key: 'ultimoAcesso',
      align: 'center',
      render: (text) => formatTimestamp(text),
    },
    {
      title: 'Qt. Acesso',
      dataIndex: 'qtAcesso',
      key: 'qtAcesso',
      align: 'right',
      render: (text, item) => (
        <Row justify='space-between' align='center'>
          <Tooltip title="Acessos">
            <Accesses>
              <FeatherIcon type='eye'/>
              <AccessTotal>{item.qtAcesso}</AccessTotal>
            </Accesses>
          </Tooltip>
          <AudienciaOpcoes item={item} />
        </Row>
      ),
    },
  ];

  return <Table rowKey='id' columns={columns} dataSource={data} loading={loading} hidePagination />;
}
