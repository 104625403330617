import styled from 'styled-components';
import View from '../View';
import { Column } from '@components-teammove';

export const SkeletonStyles = styled.div`
  height: ${({ height }) => height || '100%'};
  width: ${({ width }) => width || '100%'};
  border-radius: ${({ width, height, borderRadius }) => borderRadius ? borderRadius : width || height ? '12px' : 'inherit'};
  overflow: hidden;
  position: relative;
  opacity: .5;
`;

export const InnerGradient = styled.div`
  width: 300%;
  height: 100%;
  position: absolute;
  animation: gra 5s infinite;
  -webkit-animation: gra 5s infinite;
  background: linear-gradient(90deg, ${({ theme }) => theme.darkSecondaryColor} 40%, ${({ theme }) => theme.darkPrimaryColor} 50%, ${({ theme }) => theme.darkSecondaryColor} 60%);

  @keyframes gra {
    0% { left: -200%; }
    50% { left: 0%; }
    100% { left: -200%; }
  }
`;

// ----------------COMPONENTS-----------------

const SkeletonBasis = styled.div`
  border-radius: 12px;
`;

export const CircleSkeleton = styled(SkeletonBasis)`
  width: ${({ width }) => width || '32px'};
  height: ${({ height }) => height || '32px'};
  border-radius: 50%;
`;

export const AverageHeightFullWidthSkeleton = styled(SkeletonBasis)`
  width: 100%;
  height: 150px;
`;

export const MinorLineSkeleton = styled(SkeletonBasis)`
  width: ${({ width }) => width || '90px'};
  height: ${({ height }) => height || '24px'};
`;

export const MediumLineSkeleton = styled(SkeletonBasis)`
  width: ${({ width }) => width || '120px'};
  height: ${({ height }) => height || '48px'};
`;

export const LargeBoxSkeleton = styled(SkeletonBasis)`
  width: 100%;
  height: 225px;
`;

export const TitleSkeleton = styled(SkeletonBasis)`
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || '415px'};
`;

export const ExtraButtonSkeleton = styled(SkeletonBasis)`
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || '150px'};
`;

export const SearchBarSkeleton = styled(SkeletonBasis)`
  height: ${({ height }) => height || '48px'};
  width: ${({ width }) => width || '100%'};
  margin-bottom: 17px;
`;

export const ContentTitleSkeleton = styled(SkeletonBasis)`
  height: ${({ height }) => height || '48px'};
  width: ${({ width }) => width || '150px'};
`;

export const ChatSkeleton = styled(View)`
  margin-top: 20px;
  width: ${({ width }) => width || '100%'};
`;

export const ChatMessageColumn = styled(View)`
  gap: 12px;
`;

export const ChatMessageOtherColumn = styled(ChatMessageColumn)`
  align-self: flex-start;

  * {
    align-self: flex-start
  }
`;

export const ChatMessageSelfColumn = styled(ChatMessageColumn)`
  align-self: flex-end;

  * {
    align-self: flex-end
  }
`;

export const ChatMessageRow = styled(View)`
  flex-direction: row;
  gap: 8px;
`;

export const ChatMessageOtherSkeleton = styled(SkeletonBasis)`
  width: 150px;
  height: 90px;
  border-top-left-radius: 2px;
`;

export const ChatMessageSelfSkeleton = styled(SkeletonBasis)`
  align-self: flex-end;
  width: 250px;
  height: 180px;
  border-top-right-radius: 2px;
`;

export const MapSkeleton = styled(SkeletonBasis)`
  height: 500px;
  width: 100%;
`;

export const CardSkeleton = styled(Column)`
  height: 92px;
  border-radius: 12px;
`;