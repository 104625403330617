import styled from 'styled-components';

export const Container = styled.div`
  ${({ darkened }) => darkened && `
    opacity: .5;
  `}
`;

export const Separator = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  width: 2px;
  height: 100%;
`;

export const UserNameText = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const UserProfileText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;