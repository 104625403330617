import React from 'react';
import Linkify from 'react-linkify';

const LinkifyComponent = ({ linkColor = '#71AFE5', textDecoration = 'underline', children, ...otherProps }) => (
  <Linkify
    {...otherProps} 
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <a target='blank' href={decoratedHref} key={key} style={{ color: linkColor, textDecoration }}>
        {decoratedText}
      </a>
    )}
  >
    {children}
  </Linkify>  
);

export default LinkifyComponent;