import { STATUS_FECHADO } from '../rules';

export const getLabelStatus = (status, periodo) => {
  switch (status) {
    case STATUS_FECHADO.NO_PRAZO:
      return periodo > 0 ? ('Fechado ' + getLabelPeriodo(periodo) + ' antes do prazo') : ('Fechado no prazo');
    default: 
      return 'Fechado ' + getLabelPeriodo(periodo) + ' depois do prazo';    
  } 
};

const getLabelPeriodo = (periodo) => {
  const dias = periodo < 0 ? periodo * -1 : periodo;
  
  return dias > 1 ? (dias + ' dias ') : (dias + ' dia ');
};