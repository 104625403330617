import React, { useState } from 'react';
import { Text, Column } from '@components-teammove';

import { moment , dateToAnsi, DATE_FORMATS } from '@utils';

import Calendar from '../../../Agenda/Calendar';
import { handleChangeRangeDate } from '../../../../Chamados/DashboardChamados/rules';

export const CalendarComponent = ({ userId, currentParams, onChange, workingJourneyId }) => {
  const [users] = useState({ users: [userId] });
  
  const handleChange = ({ view, date, period, action }) => {
    const typePeriod = view === 'month' ? 'MENSAL' : 'SEMANAL';
    const baseDate = () => {
      if(view === 'month') {
        const startMonth = moment(date).startOf('month');
        const initialDate = startMonth.format(DATE_FORMATS.ANSI_DATE);
        return initialDate;
      }else{
        const initialDate = dateToAnsi(period[0], null, DATE_FORMATS.ANSI_DATE);
        return initialDate;
      }
    };
    
    const formattedRangeDate = () => {
      if(action) {
        const range = handleChangeRangeDate(baseDate(), typePeriod);
        return range;
      } else{
        return currentParams.formattedRangeDate;
      }
    };
    onChange({ typePeriod:typePeriod, initialDate: baseDate(), formattedTypePeriod: formattedRangeDate() });
  };
  
  return(
    <>
      <Column gap='12px'>
        <Text size='20px' weight='700' line='27px'>Agenda {currentParams.typePeriod.toLowerCase()}</Text>
      </Column>
      <Calendar 
        filtros={users} 
        changeParams={handleChange} 
        customOptions={[{ name: 'Semana' }, { name: 'Mês' }]}
        baseDate={currentParams.initialDate} 
        baseView={currentParams.typePeriod === 'MENSAL' ? 'month' : 'week'}
        workingJourneyId={workingJourneyId}
      />
    </>
  );
}; 