import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification, InfoUsuario } from '@utils';
import {
  getCategoria as getCategoriaSdk,
  generatePreview as generatePreviewSdk,
  saveCategoria as saveCategoriaSdk,
  getListaProdutos as getListaProdutosSdk,
  saveValuesListProduct as saveValuesListProductSdk,
} from '@sdk/Wikis/Personalizacao';

//Action Types
export const Types = {
  GET: 'WIKIS_PERSONALIZACAO/GET',
  GET_SUCCESS: 'WIKIS_PERSONALIZACAO/GET_SUCCESS',
  GET_ERROR: 'WIKIS_PERSONALIZACAO/GET_ERROR',

  SAVE: 'WIKIS_PERSONALIZACAO/SAVE',
  SAVE_SUCCESS: 'WIKIS_PERSONALIZACAO/SAVE_SUCCESS',
  SAVE_ERROR: 'WIKIS_PERSONALIZACAO/SAVE_ERROR',

  PREVIEW: 'WIKIS_PERSONALIZACAO/PREVIEW',
  PREVIEW_SUCCESS: 'WIKIS_PERSONALIZACAO/PREVIEW_SUCCESS',
  PREVIEW_ERROR: 'WIKIS_PERSONALIZACAO/PREVIEW_ERROR',

  GET_LISTA_PRODUTOS: 'WIKIS_PERSONALIZACAO/GET_LISTA_PRODUTOS',
  GET_LISTA_PRODUTOS_SUCCESS: 'WIKIS_PERSONALIZACAO/GET_LISTA_PRODUTOS_SUCCESS',
  GET_LISTA_PRODUTOS_ERROR: 'WIKIS_PERSONALIZACAO/GET_LISTA_PRODUTOS_ERROR',

  SAVE_VALORES_LISTA_PRODUTOS: 'WIKIS_PERSONALIZACAO/SAVE_VALORES_LISTA_PRODUTOS',
  SAVE_VALORES_LISTA_PRODUTOS_SUCCESS: 'WIKIS_PERSONALIZACAO/SAVE_VALORES_LISTA_PRODUTOS_SUCCESS',
  SAVE_VALORES_LISTA_PRODUTOS_ERROR: 'WIKIS_PERSONALIZACAO/SAVE_VALORES_LISTA_PRODUTOS_ERROR',

  SAVE_LISTA_PRODUTOS_EDIT: 'WIKIS/PERSONALIZACAO/SAVE_LISTA_PRODUTOS_EDIT'
};

//Action Creators
export const getCategoria = (idCategoria) => ({ type: Types.GET, idCategoria });
export const getCategoriaSuccess = (categoria) => ({ type: Types.GET_SUCCESS, categoria });
export const getCategoriaError = (error) => ({ type: Types.GET_ERROR, error });

export const saveCategoria = (idCategoria, original, textos) => ({ type: Types.SAVE, idCategoria, original, textos });
export const saveCategoriaSuccess = (data) => ({ type: Types.SAVE_SUCCESS, data });
export const saveCategoriaError = (error) => ({ type: Types.SAVE_ERROR, error });

export const generatePreview = (original, textos, useImagePreview) => ({ type: Types.PREVIEW, original, textos, useImagePreview });
export const generatePreviewSuccess = (preview) => ({ type: Types.PREVIEW_SUCCESS, preview });
export const generatePreviewError = (error) => ({ type: Types.PREVIEW_ERROR, error });

export const getListaProdutos = (idsItemsListaProdutos) => ({ type: Types.GET_LISTA_PRODUTOS, idsItemsListaProdutos });
export const getListaProdutosSuccess = (data) => ({ type: Types.GET_LISTA_PRODUTOS_SUCCESS, data });
export const getListaProdutosError = (error) => ({ type: Types.GET_LISTA_PRODUTOS_ERROR, error });

export const saveValuesListProduct = (data) => ({ type: Types.SAVE_VALORES_LISTA_PRODUTOS, data });
export const saveValuesListProductSuccess = (data) => ({ type: Types.SAVE_VALORES_LISTA_PRODUTOS_SUCCESS, data });
export const saveValuesListProductError = (error) => ({ type: Types.SAVE_VALORES_LISTA_PRODUTOS_ERROR, error });

export const saveListProductEdit = (status) => ({ type: Types.SAVE_LISTA_PRODUTOS_EDIT, status });

//saga
function* fetchGetCategoria(action) {
  try {
    const { idCategoria } = action;

    const categoria = yield call(getCategoriaSdk, idCategoria);
    if ((categoria) && (categoria.original) && (categoria.textos)) {
      // Funcionalidade para iniciar uma ordenação em itens ja criados
      categoria.textos = categoria.textos.map((item, index) => ({
        ...item,
        order: item.order || index,
        type: item.type || 'SI',
        productList: item.productList || null,
        productItemList: item.productItemList || null,
        productItemListFormat: item.productItemListFormat || null
      }));
      yield put(generatePreview(categoria.original, categoria.textos));
    }
    yield put(getCategoriaSuccess(categoria));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCategoriaError(err));
  }
}

function* fetchSaveCategoria(action) {
  try {
    const { idCategoria, original, textos } = action;

    yield call(saveCategoriaSdk, idCategoria, original, textos);
    yield put(saveCategoriaSuccess());
    Notification.success('Categoria salva');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveCategoriaError(err));
  }
}

function* fetchPreview(action) {
  try {
    const { original, textos, useImagePreview } = action;
    const retorno = yield call(generatePreviewSdk, original, textos, true, useImagePreview);
    yield put(generatePreviewSuccess(retorno));
  } catch (err) {
    Notification.error(err.message);
    yield put(generatePreviewError(err));
  }
}

function* fetchGetListaProdutos(action) {
  try {
    const { idsItemsListaProdutos } = action;
    const idUser = InfoUsuario.get('cdUsuario');

    const data = yield call(getListaProdutosSdk, idsItemsListaProdutos, idUser);
    yield put(getListaProdutosSuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(getListaProdutosError(err));
  }
}

function* fetchSaveValuesListProduct(action) {
  try {
    const { data } = action;
    const dataResponse = yield call(saveValuesListProductSdk, data);
    yield put(saveValuesListProductSuccess(dataResponse));
    Notification.success('Itens salvos');
  } catch (err) {
    Notification.error(err.message);
    yield put(saveValuesListProductError(err));
  }
}
export const saga = [
  takeLatest(Types.GET, fetchGetCategoria),
  takeLatest(Types.SAVE, fetchSaveCategoria),
  takeLatest(Types.PREVIEW, fetchPreview),
  takeLatest(Types.GET_LISTA_PRODUTOS, fetchGetListaProdutos),
  takeLatest(Types.SAVE_VALORES_LISTA_PRODUTOS, fetchSaveValuesListProduct)
];

// Reducer
const initialState = Map({
  categoria: {},
  preview: {},
  listaProdutos: [],
  listaProdutosEdit: false,

  loading: false,
  success: false,
  error: false,

  loadingSave: false,
  successSave: false,
  errorSave: false,

  loadingPreview: false,
  successPreview: false,
  errorPreview: false,

  loadingListaProdutos: false,
  successListaProdutos: false,
  errorListaProdutos: false,

  loadingSaveValuesListProduct: false,
  successSaveValuesListProduct: false,
  errorSaveValuesListProduct: false
});

const handleGetCategoria = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleGetCategoriaSuccess = (state, action) => {
  return state
    .set('categoria', action.categoria)
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleGetCategoriaError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

const handleSaveCategoria = (state, action) => {
  return state
    .set('loadingSave', true)
    .set('successSave', false)
    .set('errorSave', false);
};

const handleSaveCategoriaSuccess = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', true)
    .set('errorSave', false);
};

const handleSaveCategoriaError = (state, action) => {
  return state
    .set('loadingSave', false)
    .set('successSave', false)
    .set('errorSave', action.error);
};

const handlePreview = (state, action) => {
  return state
    .set('loadingPreview', true)
    .set('successPreview', false)
    .set('errorPreview', false);
};

const handlePreviewSuccess = (state, action) => {
  return state
    .set('preview', action.preview)
    .set('loadingPreview', false)
    .set('successPreview', true)
    .set('errorPreview', false);
};

const handlePreviewError = (state, action) => {
  return state
    .set('loadingPreview', false)
    .set('successPreview', false)
    .set('errorPreview', action.error);
};

const handleGetListaProdutos = (state, action) => {
  return state
    .set('loadingListaProdutos', true)
    .set('successListaProdutos', false)
    .set('errorListaProdutos', false);
};

const handleGetListaProdutosSuccess = (state, action) => {
  return state
    .set('loadingListaProdutos', false)
    .set('successListaProdutos', true)
    .set('listaProdutos', action.data)
    .set('errorListaProdutos', false);
};

const handleGetListaProdutosError = (state, action) => {
  return state
    .set('loadingListaProdutos', false)
    .set('successListaProdutos', false)
    .set('errorListaProdutos', action.error);
};

const handleSaveValuesListProduct = (state, action) => {
  return state
    .set('loadingSaveValuesListProduct', true)
    .set('successSaveValuesListProduct', false)
    .set('errorSaveValuesListProduct', false);
};

const handleSaveValuesListProductSuccess = (state, action) => {
  return state
    .set('listaProdutos', action.data)
    .set('loadingSaveValuesListProduct', false)
    .set('successSaveValuesListProduct', true)
    .set('errorSaveValuesListProduct', false);
};

const handleSaveValuesListProductError = (state, action) => {
  return state
    .set('loadingSaveValuesListProduct', false)
    .set('successSaveValuesListProduct', false)
    .set('errorSaveValuesListProduct', action.error);
};

const handleSaveListProductEdit = (state, action) => {
  return state
    .set('listaProdutosEdit', action.status);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET: return handleGetCategoria(state, action);
    case Types.GET_SUCCESS: return handleGetCategoriaSuccess(state, action);
    case Types.GET_ERROR: return handleGetCategoriaError(state, action);

    case Types.SAVE: return handleSaveCategoria(state, action);
    case Types.SAVE_SUCCESS: return handleSaveCategoriaSuccess(state, action);
    case Types.SAVE_ERROR: return handleSaveCategoriaError(state, action);

    case Types.PREVIEW: return handlePreview(state, action);
    case Types.PREVIEW_SUCCESS: return handlePreviewSuccess(state, action);
    case Types.PREVIEW_ERROR: return handlePreviewError(state, action);

    case Types.GET_LISTA_PRODUTOS: return handleGetListaProdutos(state, action);
    case Types.GET_LISTA_PRODUTOS_SUCCESS: return handleGetListaProdutosSuccess(state, action);
    case Types.GET_LISTA_PRODUTOS_ERROR: return handleGetListaProdutosError(state, action);

    case Types.SAVE_VALORES_LISTA_PRODUTOS: return handleSaveValuesListProduct(state, action);
    case Types.SAVE_VALORES_LISTA_PRODUTOS_SUCCESS: return handleSaveValuesListProductSuccess(state, action);
    case Types.SAVE_VALORES_LISTA_PRODUTOS_ERROR: return handleSaveValuesListProductError(state, action);

    case Types.SAVE_LISTA_PRODUTOS_EDIT: return handleSaveListProductEdit(state, action);
    
    default: return state;
  }
}
