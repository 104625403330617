export const getLabel = (tipo, tempo) => {
  if (tipo === 'HORAS') {
    return tempo > 1 ? 'horas' : 'hora';
  } else {
    return tempo > 1 ? 'dias' : 'dia';
  }
};

export const getPrazo = (prazo) => {
  return Math.abs(prazo);
};