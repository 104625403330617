import styled from 'styled-components';
import { Icon, Button, Column, Row } from '@components-teammove';

export const FeatherIcon = styled(Icon.Feather)`
  opacity: 0.5;
`;

export const ButtonStyle = styled(Button)`
  &.ant-btn {
    padding: 6px 12px;
  }

  &.ant-btn.ant-btn-loading {
    top: 2px;
    padding: 7px 14px !important;
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ status }) => status === 'Aprovado' ? 'var(--success-color)' : 'var(--warning-color)'};
`;

export const CardStatus = styled(Column)`
  border-radius: 12px;
  background-color: ${({ theme })=> theme.darkSecondaryColor };
  padding: 12px;
  :hover{
    cursor: pointer;
    border: 1px solid ${({ theme })=> theme.actionColor };
    
  }
  ${({ selected, theme }) => selected && `
    border: 1px solid ${theme.actionColor};
  `};
`;

export const Tag = styled(Row)`
  border-radius: 45px;
  background-color: ${({ status, theme })=> status === 'aprovado' ? 'var(--success-color)' : status === 'reprovado' ? 'var(--danger-color)' : status === 'pendente' ?  theme.darkPrimaryColor : ''};
  gap: 4px;
  width: 106px;
`;

export const IconStatus = styled(Icon.Feather)`
  color: ${({ theme })=> theme.textColor };
`;

export const DotIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ color }) => color ? color : '#000000'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconStyle = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;

export const IconChecklist = styled(Icon.Feather)`
  *{
    color: ${({ theme }) => theme.actionColor} !important;
  }
`;

export const CardSkeleton = styled(Column)`
  height: 85px;
  border-radius: 12px;
`;