import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Form, Button, Select, Skeleton } from '@components-teammove';
import { getPerfis } from '@sdk/Perfil';
import { FlexRowMinorGap, HalfWidthFormItem } from './styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReportProfiles, postReportProfiles, putReportProfiles } from '../../../store/ducks/configuracoesReportPerfis';

export default function ConfiguracaoReportPerfis() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const configProfiles = useSelector(({ configuracoesReportPerfis }) => configuracoesReportPerfis.get('configProfiles'));
  const loading = useSelector(({ configuracoesReportPerfis }) => configuracoesReportPerfis.get('loading'));
  const success = useSelector(({ configuracoesReportPerfis }) => configuracoesReportPerfis.get('success'));
  const loadingSave = useSelector(({ configuracoesReportPerfis }) => configuracoesReportPerfis.get('loadingSave'));
  const successSave = useSelector(({ configuracoesReportPerfis }) => configuracoesReportPerfis.get('successSave'));

  const [perfis, setPerfis] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getReportProfiles());

    (async () => {
      const perfisAsync = await getPerfis();
      setPerfis(perfisAsync.map(({ title, value }) => ({ label: title, value })));
    })();
  }, []);

  useEffect(() => {
    if (success) {
      form.setFieldsValue(configProfiles);
    }
  }, [success]);

  useEffect(() => {
    if (successSave) {
      window.location.reload();
    }
  }, [successSave]);

  const handleSave = () => {
    form.validateFields().then(({ perfis, id }) => {
      if (id) {
        dispatch(putReportProfiles({ id, perfis }));
      } else {
        dispatch(postReportProfiles(perfis));
      }
    });
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Report Semanal - Configuração de Perfis'
          extra={[
            <Button key='1' size='small' context='header' type='secondary' onClick={() => history.goBack()}>
              Cancelar
            </Button>,
            <Button key='2' size='small' context='header' onClick={handleSave} loading={loadingSave}>
              Salvar
            </Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/report', name: 'Configurações do Report', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          {loading ? (
            <Skeleton.Form/>
          ) : (
            <Form form={form} layout='vertical'>
              <Form.Item name='id' hidden/>
              <HalfWidthFormItem 
                name='perfis' 
                label={(<FlexRowMinorGap>Perfis Para Excluir do Report</FlexRowMinorGap>)} 
              >
                <Select options={perfis} mode='multiple' placeholder='Selecione um ou mais perfis'/>
              </HalfWidthFormItem>
            </Form>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}