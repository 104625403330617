import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Divider, Column, HorizontalBarChart, Skeleton } from '@components-teammove';
import { ContentCategoriesGraphic, ContentGraphic, RowStyle, SelectPeriod, StyleEmpty } from './styles';

import { getCategoriesGraphic } from '@ducks/chamadosDashboard';

export default function CategoriesGraphic({ currentFilters, currentParams, topRange, setTopRange }) {
  const dispatch = useDispatch();

  const [ heightGraphic, setHeightGraphic ] = useState(300);

  const categoriesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('categoriesGraphic'));
  const loadingCategoriesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingCategoriesGraphic'));
  const successCategoriesGraphic = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successCategoriesGraphic'));

  useEffect(() => {
    if(successCategoriesGraphic) {
      setHeightGraphic(categoriesGraphic.categoryRanking.length * 100);
    }
  }, [successCategoriesGraphic]);

  useEffect(() => {
    setTopRange(3);
    dispatch(getCategoriesGraphic({ ...currentFilters, ...currentParams }, { limit: 3 }));
  }, [currentFilters, currentParams]);

  const handleChangeTopRange = (value) => {
    setTopRange(value);
    dispatch(getCategoriesGraphic({ ...currentFilters, ...currentParams }, { limit: value }));
    setHeightGraphic(value * 100);
  };

  return(
    loadingCategoriesGraphic ? (
      <Skeleton.Map/>
    ) : successCategoriesGraphic && (
      categoriesGraphic.categoryRanking.length === 0 ? (
        <StyleEmpty description="Sem dados" />
      ) : (
        <ContentCategoriesGraphic>
          <Column gap='30px'>
            <RowStyle align='center' justify='space-between'>
              <Text size='20px' weight='700' >
            Categorias
              </Text>
              <SelectPeriod
                placeholder='Tops'
                value={topRange}
                options={[
                  { value:3, label: 'Top 3' },
                  { value:5, label: 'Top 5' },
                  { value:10, label: 'Top 10' },
                  { value:20, label: 'Top 20' },
                  { value:50, label: 'Top 50' },
                  { value:100, label: 'Top 100' },
                  { value:0, label: 'Todos' },
                ]}
                onChange={(value) => handleChangeTopRange(value)}
              />
        
            </RowStyle>
            <Column gap='20px'>
              <Divider/>
              <ContentGraphic heightGraphic={heightGraphic}>
                <HorizontalBarChart data={categoriesGraphic.categoryRanking.map(({ quantity, name }) => ({ value: quantity, label: name }))} />
              </ContentGraphic>
            </Column>
        
          </Column>
        </ContentCategoriesGraphic>
      )
      
    )
  );
}