import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Modal, Text, View } from '@components';
import { arrayReorder } from '@utils';
import { ContentItems, ListItem, ViewTrail, ImageTrail } from './styles';

export default function ModalCursos({ visible, onCancel, courses, coursesChoosed, handleNewOrder }) {
  const [coursesToOrder, setCoursesToOrder] = useState([]);
  
  useEffect(() => {
    if (visible) {
      setCoursesToOrder(coursesChoosed.map((id) => courses.find((item) => item.cursoId === id)));
    }
  }, [visible, coursesChoosed]);

  const ContainerItems = SortableContainer(({ items }) => (
    <ContentItems>
      {items.map((item, index) => <ContentItem key={item.cursoId} index={index} item={item} />)}
    </ContentItems>
  ));

  const ContentItem = SortableElement(({ item }) => (
    <ListItem key={item.cursoId}>
      <ViewTrail horizontal>
        <View>
          <Text strong>{item.cursoTitulo}</Text>
          <Text>{item.cursoResumo}</Text>
        </View>
        <ImageTrail alt={item.cursoTitulo} src={item.cursoImagem || 'https://media-teammove.s3-sa-east-1.amazonaws.com/EAD/ead-default.jpg'} />
      </ViewTrail>
    </ListItem>
  ));

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    handleNewOrder(arrayReorder(coursesChoosed, oldIndex, newIndex));
  };

  return (
    <Modal forceRender visible={visible} onCancel={onCancel} onOk={onCancel} title='Ordenação de cursos selecionados'>
      <ContainerItems items={coursesToOrder} onSortEnd={handleSortEnd} distance={1} lockAxis="y"/>
    </Modal>  
  );
}