import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { Notification } from '@utils';
import { alterarSenha as alterarSenhaSdk } from '@sdk/Perfil';

//Action Types
export const Types = {
  ALTERAR_SENHA: 'ALTERAR_SENHA',
  ALTERAR_SENHA_SUCCESS: 'ALTERAR_SENHA_SUCCESS',
  ALTERAR_SENHA_ERROR: 'ALTERAR_SENHA_ERROR',

  RESET: 'ALTERAR_SENHA/RESET',
};

//Action Creators
export const alterarSenha = (idUsuario, oldPassword, newPassword) => ({ type: Types.ALTERAR_SENHA, idUsuario, oldPassword, newPassword });
export const alterarSenhaSuccess = (perfil) => ({ type: Types.ALTERAR_SENHA_SUCCESS, perfil });
export const alterarSenhaError = (error) => ({ type: Types.ALTERAR_SENHA_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchAlterarSenha(action) {
  try {    
    const { idUsuario, oldPassword, newPassword } = action;

    yield call(alterarSenhaSdk, idUsuario, oldPassword, newPassword);
    yield put(alterarSenhaSuccess());
  } catch (err) {
    Notification.error(err.message);
    yield put(alterarSenhaError(err));
  }
}

export const saga = [
  takeLatest(Types.ALTERAR_SENHA, fetchAlterarSenha),
];

// Reducer
const initialState = Map({
  loading: false,
  success: false,
  error: false,
});

const handleAlterarSenha = (state, action) => {
  return state
    .set('loading', true)
    .set('success', false)
    .set('error', false);
};

const handleAlterarSenhaSuccess = (state, action) => {
  return state
    .set('loading', false)
    .set('success', true)
    .set('error', false);
};

const handleAlterarSenhaError = (state, action) => {
  return state
    .set('loading', false)
    .set('success', false)
    .set('error', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.ALTERAR_SENHA: return handleAlterarSenha(state, action);
    case Types.ALTERAR_SENHA_SUCCESS: return handleAlterarSenhaSuccess(state, action);
    case Types.ALTERAR_SENHA_ERROR: return handleAlterarSenhaError(state, action);

    case Types.RESET: return initialState;

    default: return state;
  }
}
