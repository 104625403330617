import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import App from '@app';
import { useHistory } from 'react-router-dom';
import { TelasNomenclaturas, InfoUsuario } from '@utils';
import { Label, MyIntegrationCard, CardTitle, IntegrationDescription, ContainerNoPermission } from './styles';
import { Body, PageHeader, Grid, Column, SearchBar, Row } from '@components-teammove';

export default function Configuracoes() {
  const history = useHistory();
  const theme = useSelector(({ app }) => app.get('theme'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');
  const ticketNomenclatures = TelasNomenclaturas.getNomenclatures('CHAMADOS');

  const [search, setSearch] = useState();

  const permissions = {
    Atendimentos: InfoUsuario.perm('chamadosConfiguracao', InfoUsuario.get()),
    Usuarios: InfoUsuario.perm('usuariosConfiguracao', InfoUsuario.get()),
    Unidades: InfoUsuario.perm('unidadesConfiguracoesRegras', InfoUsuario.get()),
    Campanhas: InfoUsuario.perm('campanhasVendasCadastro', InfoUsuario.get()),
    Comunicados: InfoUsuario.perm('comunicadosConfiguracao', InfoUsuario.get()),
    Nomenclaturas: InfoUsuario.perm('telasNomenclaturas', InfoUsuario.get()),
    Report: InfoUsuario.perm('gestaoEquipes', InfoUsuario.get()),
    TiposAtividades: InfoUsuario.perm('atividadesTiposConfiguracoes', InfoUsuario.get()) || InfoUsuario.perm('atividadesMetaCadastro', InfoUsuario.get()),
    Produtos: InfoUsuario.perm('produtosConfigurar', InfoUsuario.get()),
    Pedidos: InfoUsuario.perm('pedidosConfigurar', InfoUsuario.get()),
    SenhaMaster: InfoUsuario.get().senhaMaster,
    AtividadesTiposConfiguracoes: InfoUsuario.perm('atividadesTiposConfiguracoes', InfoUsuario.get()),
    Voip: InfoUsuario.perm('voip', InfoUsuario.get())
  };

  const allowedGeneralConfigurations = [
    {
      name: 'Logo impressão',
      description: 'Configure a sua logo',
      link: '/configuracoes/logoImpressao',
      permissions: permissions.SenhaMaster
    },
    {
      name: 'Usuarios',
      description: 'Configure atributos, afastamentos e id duplicado de API',
      link: '/configuracoes/usuarios',
      permissions: permissions.Usuarios
    },
    {
      name: 'Nomenclaturas',
      description: 'Configure a nomenclatura dos atendimentos, EAD e unidades',
      link: '/configuracoes/telasNomenclaturas',
      permissions: permissions.Nomenclaturas
    },
    {
      name: 'Whitelabels',
      description: 'Configure o tema do seu Team Move',
      link: '/configuracoes/whitelabels',
      permissions: permissions.SenhaMaster

    },
    {
      name: 'Jornada',
      description: 'Configure a jornada de trabalho dos perfis de usuarios',
      link: '/configuracoes/atendimento',
      permissions: permissions.TiposAtividades
    },
    {
      name: 'Voip',
      description: 'Configure os parametros de gravação e transcrição das chamadas de áudio',
      link: '/configuracoes/voip',
      permissions: permissions.Voip
    },
  ].filter((data) => data.permissions);
  const allowedFunctionalitiesConfigurations = [
    {
      name: ticketNomenclatures.plural,
      description: 'Configure os niveis de departamentos e categorias, horários de atendimentos, dias de fechamento e etc',
      link: '/configuracoes/chamados',
      permissions: permissions.Atendimentos

    },    
    {
      name: 'Campanhas de Vendas',
      description: 'Configure tipos de campanhas e agrupadores para cargos de usuários',
      link: '/configuracoes/campanhas',
      permissions: permissions.Campanhas

    },
    {
      name: 'Comunicados',
      description: 'Configure grupos de comunicados',
      link: '/configuracoes/comunicados',
      permissions: permissions.Comunicados

    },
    {
      name: 'Report Semanal',
      description: 'Configure os perfis que não aparecerão no report semanal',
      link: '/configuracoes/report',
      permissions: permissions.Report

    },
    {
      name: 'Atividades',
      description: 'Configure os tipos de atividades disponiveis para agenda e seus checklists, crie metas de atividades para sua equipe',
      link: '/configuracoes/atividades',
      permissions: permissions.TiposAtividades

    },
    {
      name: companyNomenclature.plural,
      description: 'Configure tipos de unidades, agrupadores, clusters, campos personalizados, regras de folga e id duplicado de API',
      link: '/configuracoes/unidades',
      permissions: permissions.Unidades

    },
  ].filter((data) => data.permissions);
  const allowedOrdersAndProductsConfigurations = [
    {
      name: 'Mix de Produtos',
      description: 'Configure categoria e sub categoria de produtos, tabelas de preços e campos personalizados',
      link: '/configuracoes/mixprodutos',
      permissions: permissions.Produtos

    },
    {
      name: 'Pedidos',
      description: 'Configure os tipos de pedido',
      link: '/configuracoes/pedidos',
      permissions: permissions.Pedidos
    },
  ].filter((data) => data.permissions);

  const configurationLabels = ['Geral', 'Funcionalidades', 'Produtos e Pedidos'];

  const handleSearch = (text) => {
    setSearch(text);
  };

  const filterBySearch = (list) => {
    if (!search) return list;
    return list.filter(({ name }) => {
      return name.trim().toLowerCase().includes(search.trim().toLowerCase());
    });
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Configurações"
          onBack={() => window.history.back()}
        >
          {(allowedGeneralConfigurations.length > 0 || allowedFunctionalitiesConfigurations.length > 0 || allowedOrdersAndProductsConfigurations.length > 0) ? (
            <>
              <SearchBar 
                initialValue={search} 
                onSearch={handleSearch}
              />
              <Column gap='28px'>
                {[allowedGeneralConfigurations, allowedFunctionalitiesConfigurations, allowedOrdersAndProductsConfigurations].map((configsCategory, index) => (
                  filterBySearch(configsCategory).length > 0 && (
                    <Column key={configsCategory.map(({ name }) => name).join()} gap='12px'>
                      <Label>{configurationLabels[index]}</Label>
                      <Grid numberOfColumns={3} gap='12px'>
                        {
                          filterBySearch(configsCategory).map((screenConfigsInfo) => (
                            screenConfigsInfo.permissions && (
                              <MyIntegrationCard key={screenConfigsInfo.name} onClick={() => history.push(screenConfigsInfo.link)}>
                                <Row justify='space-between' align='center'>
                                  <Row gap='12px'>
                                    <Column>
                                      <CardTitle>{screenConfigsInfo.name}</CardTitle>
                                      <IntegrationDescription>{screenConfigsInfo.description}</IntegrationDescription>
                                    </Column>
                                  </Row>
                                </Row>
                              </MyIntegrationCard>
                            )
                          ))
                        }
                      </Grid>
                    </Column>   
                  )
                )
                )}
              </Column>
            </>
          ) : (
            <ContainerNoPermission>
              <CardTitle>Você não tem permissão para ver nenhuma configuração</CardTitle>
            </ContainerNoPermission>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
