import React from 'react';
import styled from 'styled-components';
import { Slider, View, InputNumber } from '@components';

const SliderComponent = styled(Slider)`
  flex: 1;
  margin-right: 16px;
`;

export const SliderInput = ({ value, onChange }) => (
  <View horizontal>
    <SliderComponent min={0} max={100} step={0.10} tipFormatter={(value) => `${value}%`} value={value} onChange={onChange} />
    <InputNumber min={0} max={100} step={0.10} formatter={(value) => `${value}%`} value={value} onChange={onChange} />
  </View>
);