import React from 'react';
import App from '@app';
import { Result, Body } from '@components';

export default function AccessDenied() {
  return (
    <App>
      <Body>
        <Result
          status="403"
          title="Você não possui permissão para acessar essa página."
        />
      </Body>
    </App>
  );
}
