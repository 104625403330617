import styled from 'styled-components';
import { Column, Text, Icon, Row } from '@components-teammove';

export const ViewAnimation = styled(Column)`
  width: 70%;
  height: 170px;
  align-items: center;
  justify-content: center;
  .lf-player-container {
    height: 230px !important;
  }
`;

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.68px;
  margin-bottom: 10px;
  margin-right: 30px;
`;

export const Description = styled(Text)`
  font-size: 16px;
  font-weight: ${({ bold }) => bold ? 700 : 400};
  line-height: 21.79px;
  margin-bottom: 10px;
`;

export const ButtonProgress = styled.div`
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  padding: 6px 30px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ colorActivity }) => colorActivity};
  padding: 3px;
`;

export const RowContainer = styled(Row)`
  margin-bottom: 12px;
`;

export const IconActivity = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor} !important;
  font-size: 14.5px;
`;

export const CloseIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor} !important;
  font-size: 18px;

  position: absolute;
  top: 10px;
  right: 10px;

  :hover {
    cursor: pointer;
  }
`;

export const ColumnViewText = styled(Column)``;

