import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { View, Spin } from '@components';
import styled from 'styled-components';

const ScrollableDiv = styled.div`
  max-height: ${({ height }) => height};
  overflow: auto;
`;

const ViewSpin = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const InfiniteScroller = ({ children, height = '650px', threshHold = 0.99, ...otherProps }) => (
  // eslint-disable-next-line
  <ScrollableDiv id="scrollableDiv" style={{ maxHeight: height }}>
    <InfiniteScroll
      scrollableTarget="scrollableDiv"
      scrollThreshold={threshHold}
      loader={
        <ViewSpin>
          <Spin />
        </ViewSpin>
      }
      {...otherProps}
    >
      {children}
    </InfiniteScroll>
  </ScrollableDiv>
);

export default InfiniteScroller;
