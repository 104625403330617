import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGestaoEquipesAtividades, FILTERS_KEY } from '@ducks/gestaoEquipes';
import { getUsuarios } from '@sdk/Usuarios';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Filter, SearchBar, Icon } from '@components-teammove';

import { FiltrosSessao, InfoUsuario, moment, formatDate } from '@utils';
import { initialFilters } from './rules';
import UsersList from './UsersList';
import UsersMap from './UsersMap';

export default function ActivitiesControl() {
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));
  const teamsManagementUsers = useSelector(({ gestaoEquipes }) => gestaoEquipes.get('usuarios'));
  const loading = useSelector(({ gestaoEquipes }) => gestaoEquipes.get('loading'));

  const [visibleFilters, setVisibleFilters] = useState(false);
  const [filtros, setFiltros] = useState(FiltrosSessao.get(FILTERS_KEY) || {});
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState();
  const [users, setUsers] = useState();
  const [viewBy, setViewBy] = useState('Cards de usuários');

  const filteredUsers = useMemo(() => {
    return search ? 
      { ...teamsManagementUsers, users: teamsManagementUsers?.users?.filter(({ userName, userProfile }) => userName.toUpperCase().includes(search.toUpperCase()) || userProfile.toUpperCase().includes(search.toUpperCase())) } : 
      teamsManagementUsers;
  }, [teamsManagementUsers, search]);

  useEffect(() => {
    const initialFiltros = Object.keys(FiltrosSessao.get(FILTERS_KEY)).length > 0 ? FiltrosSessao.get(FILTERS_KEY) : { data: [moment(), moment()], descFiltros: { data: [formatDate(moment()), formatDate(moment())] } };
    setFiltros(initialFiltros);
    FiltrosSessao.set(FILTERS_KEY, initialFiltros);
    handleDefaultDispatch(initialFiltros);

    (async () => {
      getUsuarios()
        .then((resp) => {
          setUsers(resp.filter((item) => InfoUsuario.get().hierarquia?.includes(item.key)).map(({ key, title }) => ({ value: key, label: title })));
        });
    })();
  }, []);

  useEffect(() => {
    if (users) {
      setFilters(filters.map((filter) => {
        if (filter.name === 'usuario') {
          return { ...filter, options: users };
        }
        return { ...filter };
      }));
    }
  }, [users]);

  const handleDefaultDispatch = (filtrosParam) => {
    dispatch(getGestaoEquipesAtividades(filtrosParam));
  };

  const handleSearchChangingNewFilters = (filtros) => {
    setFiltros(filtros);
    FiltrosSessao.set(FILTERS_KEY, filtros);
    handleDefaultDispatch(filtros);
  };

  const handleViewByChange = (newView) => {
    setViewBy(newView);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Gestão de equipes'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/gestao', name: 'Gestão de equipes', current: true },
                { path: '/controle-atividades', name: 'Controle de atividades', current: true }
              ]}
            />
          )}
          extra={[
            <Button key='filter' type='secondary' size='small' context='header' icon={<Icon.MDI type='filter-outline'/>} onClick={() => setVisibleFilters(true)}>Filtros</Button>
          ]}
        >
          <SearchBar
            placeholder='Pesquisar'
            initialValue={search}
            activeFiltersProps={{ filtros, filters, onSearch: handleSearchChangingNewFilters }}
            onSearch={(value) => setSearch(value)}
          />

          {viewBy === 'Cards de usuários' ? (
            <UsersList viewBy={viewBy} handleViewByChange={handleViewByChange} users={filteredUsers} loading={loading}/>
          ) : (
            <UsersMap
              viewBy={viewBy} 
              handleViewByChange={handleViewByChange}
              users={filteredUsers.users.filter((checkin) => checkin.lastCheckinLatitude && checkin.lastCheckinLongitude)}
              loading={loading}
              filtros={filtros}
            />
          )}

          {visibleFilters && (
            <Filter
              visible={visibleFilters}
              onClose={() => setVisibleFilters(false)}
              onSearch={handleSearchChangingNewFilters}
              filters={filters}
              filtros={filtros}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}