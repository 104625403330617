import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeatherIcon, TextStyle, Divider } from '../styles';
import { ContainerClassificacao } from './styles';
import { Skeleton, Row, Column, Text } from '@components-teammove';
import ClassificacoessDropdown from './ClassificacoesDropdown';

import { getClassificationsTicket, delTicketClassificationOption, getAllClassificationsWithOptions } from '@ducks/classificacoes';

export default function Classificacoes({ ticketId, statusCancelado }) {
  
  const dispatch = useDispatch();

  const classificationsTicket = useSelector(({ classificacoes }) => classificacoes.get('classificationsTicket'));
  const loadingClassificationsTicket = useSelector(({ classificacoes }) => classificacoes.get('loadingClassificationsTicket'));
  const successClassificationsTicket = useSelector(({ classificacoes }) => classificacoes.get('successClassificationsTicket'));

  const loadingSaveTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('loadingSaveTicketClassificationOption'));
  const successSaveTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('successSaveTicketClassificationOption'));

  const loadingUpdateTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('loadingUpdateTicketClassificationOption'));
  const successUpdateTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('successUpdateTicketClassificationOption'));

  const loadingDeleteTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('loadingDeleteTicketClassificationOption'));
  const successDeleteTicketClassificationOption = useSelector(({ classificacoes }) => classificacoes.get('successDeleteTicketClassificationOption'));
  const successCategoria = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successCategoria'));
  
  const classificationsWithOptions = useSelector(({ classificacoes }) => classificacoes.get('classificationsWithOptions'));
  
  useEffect(() => {
    dispatch((getClassificationsTicket(ticketId)));
    dispatch((getAllClassificationsWithOptions()));
  },[]);

  useEffect(() => {
    dispatch((getClassificationsTicket(ticketId)));
    
  },[successSaveTicketClassificationOption, successUpdateTicketClassificationOption, successDeleteTicketClassificationOption, successCategoria]);

  const handleDelete = (classificacao) => {
    dispatch(delTicketClassificationOption(ticketId, classificacao.id, classificacao.idOpcaoEscolhida));
  };

  return (
    <> 
      {(loadingClassificationsTicket || loadingSaveTicketClassificationOption 
      || loadingUpdateTicketClassificationOption ||  loadingDeleteTicketClassificationOption) 
        ? <>
          <Divider />
          <Row gap='5px' padding='0 0 0 20px' >
            <FeatherIcon type='tag' />
            <TextStyle
              font='16px'
              weight='700'
            >
                    Classificações
            </TextStyle>
          </Row>
          <Row padding='0 0 0 20px'>
            <Skeleton.LargeBox/>
          </Row>
        </> 
        : (successClassificationsTicket && classificationsTicket.length > 0) && (
          <>
            <Divider />
            <Row gap='5px' padding='0 0 0 20px'  >
              <FeatherIcon type='tag' />
              <TextStyle
                font='16px'
                weight='700'
              >
                Classificações
              </TextStyle>
            </Row>

            {classificationsTicket.map((item) => (
              <Column
                padding='0 0 0 20px'          
                key={item.id}
              >
                <Row justify='space-between'>
                  <Text size='14px' weight='600'>{item.nome}</Text>
                  <ClassificacoessDropdown 
                    classification={item}
                    ticketId={ticketId}
                    options={classificationsWithOptions}
                    statusCancelado={statusCancelado}
                  />
                </Row>
                {item.nomeOpcaoEscolhida && (
                  <ContainerClassificacao corOpcaoEscolhida={item.corOpcaoEscolhida}>
                    <Text size='14px' weight='600'>{item.nomeOpcaoEscolhida}</Text>
                    {!statusCancelado && (
                      <FeatherIcon type='x' size='20' cursor='pointer' onClick={() => handleDelete(item)}/>
                    )}
                  </ContainerClassificacao>
                )}
              </Column>
            ))}
          </>
        )}
    </>
  );
}
