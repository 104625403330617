import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Image, View, Spin, PDFViewer } from '@components';
import { Icon } from '@components-teammove';
import { salvarPersonalizacao } from '@sdk/Wikis/Personalizacao';
import { Notification, isImage } from '@utils';
import { getDadosPersonalizacao } from '../utils';

const ContainerButtons = styled(View)`
  margin-top: 10px;
  width: 250px;
  justify-content: space-evenly;
`;

export default function PersonalizacaoAprovacao({ conteudo, file, dados, next, previous }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, [file]);

  const handleOnLoad = () => setLoading(false);

  const handleOnAproved = () => {
    salvarPersonalizacao(
      getDadosPersonalizacao(dados, {
        status: 'A',
        dtAprovacao: new Date(),
      })
    )
      .then(() => {
        if (next) {
          next();
        }
      })
      .catch(() => {
        Notification.error('Não foi possível aprovar');
      });
  };

  const handleOnDisapproved = () => {
    salvarPersonalizacao(
      getDadosPersonalizacao(dados, {
        status: 'R',
        dtAprovacao: new Date(),
      })
    )
      .then(() => {
        if (previous) {
          previous();
        }
      })
      .catch(() => {
        Notification.error('Não foi possível aprovar');
      });
  };

  return (
    <>
      {loading && <Spin />}

      {isImage(file.outputPath || file.compressed) ? <Image width={300} src={file.compressed} onLoad={handleOnLoad} onError={handleOnLoad} alt='Imagem personalizada' /> : <PDFViewer url={file.outputPath} onDocumentLoad={() => handleOnLoad()} />}

      <ContainerButtons horizontal>
        <Button size='large' type='primary' danger icon={<Icon.DislikeFilled />} disabled={loading || !file} onClick={handleOnDisapproved}>
          Reprovar
        </Button>
        <Button size='large' type='success' icon={<Icon.LikeFilled />} disabled={loading || !file} onClick={handleOnAproved}>
          Aprovar
        </Button>
      </ContainerButtons>
    </>
  );
}
