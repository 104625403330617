import styled from 'styled-components';
import { Icon, Row } from '@components-teammove';

export const RemixIcon = styled(Icon.Remix)`
  color: ${({ theme })=> theme.textColor};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 12px;
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 12px;
`;

export const Card = styled(Row)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: 20px;
  border-radius: 12px;
`;

export const FeatherIcon = styled(Icon.Feather)`
  color: ${({ theme })=> theme.textColor};
`;

export const CardSkeleton = styled.div`
  width: 100%;
  border-radius: 12px;
  height: 100px;
`;
