import { del, get, post, put } from '../api';
import { InfoUsuario } from '@utils';
import { formatFilters } from './rules';

export const getClusters = () => {
  return get(`/api/clusters/groups?userId=${InfoUsuario.get().cdUsuario}`);
};

export const getClustersByGroup = (groups) => {
  const isArray = Array.isArray(groups); 
  
  return get(`/api/clusters?groups=${isArray ? groups.join(' ') : groups}`);
  
};

export const getCluster = (id) => {
  return get(`/api/clusters/groups/${id}`);
};

export const getClustersByCompany = (id) => {
  return get(`/api/clusters/groups/getListByCompanyKind/${id}`);
};

export const postCluster = (cluster) => {
  return post('/api/clusters/groups', cluster);
};

export const updateCluster = (cluster) => {
  return put('/api/clusters/groups', cluster);
};

export const deleteCluster = (id) => {
  return del(`/api/clusters/groups/${id}`);
};

export const getDashCluster = (filters, search) => {
  return get(`/api/clusters/groups/dash?userId=${InfoUsuario.get().cdUsuario}&${formatFilters(filters)}${search ? `&search=${search}` : ''}${InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : ''}&searchByRazaoSocial=true`);
};

export const getYearSummary = (groupId, year, filters, clusterId) => {
  return get(`/api/clusters/groups/${groupId}/dash?year=${year}&user=${InfoUsuario.get().cdUsuario}${clusterId ? `&cluster=${clusterId}` : '' }&${formatFilters(filters)}`);
};

export const getCompanysWithClusterSituation = (group, endDate, search, filters, cluster, limit, status) => {
  return get(`/api/companies/clusterGroups/${group}?end=${endDate}&idUser=${InfoUsuario.get().cdUsuario}&orderBy=UNIDADE&orderByDirection=desc${status ? `&status=${status}` : ''}${search ? `&search=${search}` : ''}${limit > 0 ? `&limit=${limit || 20}` : ''}${cluster ? `&cluster=${cluster}` : ''}&${formatFilters(filters)}`);
};

export const moveCompaniesCluster = (allClustersToMove) => {
  return put('/api/clusters/moveCompanies', allClustersToMove);
};

// não será usada
export const getAtividadesOfCluster = (id) => {
  return get(`/api/companies/clusters/types/activities?idCluster=${id}`);
};

