import React, { useEffect, useState } from 'react';
import MultiplaEscolha from './MultiplaEscolha';
import Somatoria from './Somatoria';
import { typesPergunta } from '@containers/Wikis/EAD/Quiz/rules';
import styled from 'styled-components';
import { View } from '@components';
import { setPerguntasOpcoes } from '@ducks/wikisEADQuiz';
import { useDispatch } from 'react-redux';

const Opcoes = styled(View)`
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-right: 15px;
`;

export default function TipoRespostas({ tp, idPergunta, opcoesCadastradas }) {
  const dispatch = useDispatch();
  const [opcoes, setOpcoes] = useState([{ id: -1, ordem: 1, correta: false }]);

  useEffect(() => {
    if (opcoesCadastradas) setOpcoes(opcoesCadastradas);
  }, [opcoesCadastradas]);

  useEffect(() => {
    dispatch(setPerguntasOpcoes({ idPergunta, opcoes }));
  }, [opcoes]);

  switch (tp) {
    case typesPergunta.MULTIPLA_ESCOLHA:
      return (
        <Opcoes>
          <MultiplaEscolha data={{ opcoes, idPergunta, opcoesCadastradas }} handlers={{ setOpcoes }} />
        </Opcoes>
      );
    case typesPergunta.SOMATORIA:
      return (
        <Opcoes>
          <Somatoria data={{ opcoes, idPergunta, opcoesCadastradas }} handlers={{ setOpcoes }} />
        </Opcoes>
      );
    default:
      return null;
  }
}
