import { darken } from 'polished';
import styled , { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const fadeInDaily = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const StyleContainerNotification = styled.div`
    background-color: ${({ theme }) => darken(0.0425, theme.darkPrimaryColor)};
    border-radius: 18px;
    padding: ${({ type }) => type === 'daily' ? '0 10px 10px 10px' : '10px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    max-width: ${({ type }) => type === 'monthly' ? '660px' : '364px'};
    max-height: 85vh;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: fixed;
    top: ${({ type }) => type === 'daily' ? '8%' : '20px'};
    left: ${({ type }) => type === 'daily' ? 'auto' : '50%'};
    right: ${({ type }) => type === 'daily' ? '24px' : 'auto'};
    transform: ${({ type }) => type !== 'daily' ? 'translateX(-50%)' : 'none'}; 
    transition: all 0.5s ease;
    opacity: 1;
    animation: ${({ type }) => type === 'daily' ? fadeInDaily : fadeIn} 0.5s ease-in-out;
    overflow-y:  ${({ type }) => type === 'daily' ? 'auto' : 'hidden'};
`;