import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Image, View, Spin, PDFViewer, Button  } from '@components';
import { Icon } from '@components-teammove';
import { Notification, isImage , generateImagesFromPages , downloadZipFromImages } from '@utils';
import styled from 'styled-components';

const MarginTopButton = styled(Button)`
  margin-top: .5rem;
`;  

export default function PersonalizacaoAprovacao({ conteudo, file }) {
  const [loading, setLoading] = useState(true);
  const [pagesAsImages, setPagesAsImages] = useState();
  const [loadingPagesAsImages, setLoadingPagesAsImages] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, [file]);

  const handleOnLoad = () => setLoading(false);

  const convertImagesFromPdf = async () => {
    setLoadingPagesAsImages(true);
    const images = await generateImagesFromPages(file.outputPath);
    setPagesAsImages(images);
    setLoadingPagesAsImages(false);
    downloadZipFromImages(images);
  };

  const handleConvertClick = () => {
    if (pagesAsImages) downloadZipFromImages(pagesAsImages);
    else convertImagesFromPdf();
  };

  return (
    <>
      {loading && <Spin />}

      {isImage(file.outputPath || file.compressed) ? 
        <Image width={200} src={file.compressed} onLoad={handleOnLoad} onError={handleOnLoad} alt='Imagem personalizada' /> : 
        <PDFViewer url={file.outputPath} onDocumentLoad={() => handleOnLoad()} />
      }
      
      <View justifyContent='space-evenly'>
        <MarginTopButton size='large' icon={<Icon.ExportOutlined />} disabled={loading || !file} onClick={() => window.open(file.outputPath || file.original)}>
          Abrir
        </MarginTopButton>
        <CopyToClipboard text={file.outputPath || file.original} onCopy={() => Notification.info('Link copiado')}>
          <MarginTopButton size='large' icon={<Icon.CopyOutlined />} disabled={loading || !file}>
            Copiar Link
          </MarginTopButton>
        </CopyToClipboard>
        {conteudo.get('tipo') === 'PDF' && !loading && (
          <MarginTopButton size='large' icon={<Icon.FileImageOutlined/>} loading={loadingPagesAsImages} disabled={loading || !file} onClick={handleConvertClick}>
            {loadingPagesAsImages ? 'Convertendo Páginas Para Imagem' : 'Salvar em JPG'}
          </MarginTopButton>
        )}
      </View>
    </>
  );
}
