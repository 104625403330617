import { Typography } from 'antd';
import View from '../View';
import Icon from '../Icon';
import styled from 'styled-components';

export const Row = styled(View)`
  flex-direction: row;
`;

export const ColumnWithGap = styled(View)`
  gap: 8px;
`;

export const CollapseButton = styled(Icon.DownOutlined)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  visibility: hidden;
  z-index: 1;
  
  &.anticon {
    color: ${(props) => props.theme.textColor};
  }
`;

export const StyledCard = styled(View)`
  background-color: ${(props) => props.theme.darkSecondaryColor};
  border-radius: 12px;
  padding: 0 16px 26px 16px;
  width: ${({ collapsed }) => !collapsed ? '420px' : '85px'};
  height: 740px;
  gap: 10px;
  flex: none;
  position: relative;
  overflow-y: scroll;
  max-height: 100%;

  :hover {
    ${CollapseButton} {
      visibility: visible;
    }
  }

  ${({ collapsed }) => collapsed && `
    padding: 26px 16px;

    ${CardHeader} {
      flex-direction: row;
      gap: 27px;
      transform-origin: top left;
      transform: rotate(90deg);
      width: 420px;
      margin-left: calc(85px / 2);
      padding-top: 0;
    }

    ${CollapseButton} {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  `}
`;

export const CardHeader = styled(View)`
  background-color: ${(props) => props.theme.darkSecondaryColor};
  width: 100%;
  word-break: keep-all;
  position: sticky;
  top: 0;
  padding: 26px 0;
`;  

export const CardTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
    margin-bottom: 0;
  }
`;

export const CardBody = styled(View)`
  gap: 12px;
`;