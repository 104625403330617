import React, { useEffect, useState } from 'react';
import App from '@app';
import { Icon, Body, PageHeader, Breadcrumb, Button, SearchBar, Column, View, Dropdown, Menu } from '@components-teammove';

import { useHistory } from 'react-router-dom';
import { formatDate, InfoUsuario } from '@utils';
import { CampaignImage, ListStyle, CampaignName, Fill, FillListItem, FlexRow, GapRow, PeriodText, PositivationIcon, SalesIcon, AlignedCenterButton } from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { getCampanhas, reset } from '@ducks/campanhasCadastro';

const DEFAULT_COVER_IMAGE = 'https://media-teammove.s3-sa-east-1.amazonaws.com/Chamados/62/Rectangle%20303_20230131175257698.png';

export default function CampanhasVendas() {
  const history = useHistory();
  const [search, setSearch] = useState();
  const [limit, setLimit] = useState(50);
  
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const campaigns = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('campanhas'));
  const total = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('total'));
  const loading = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('loading'));
  const success = useSelector(({ campanhasCadastro }) => campanhasCadastro.get('success'));

  useEffect(() => {
    handleGetCampanhas();

    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (success) {
      handleGetCampanhas();
    }
  }, [search, limit]);

  const handleGetCampanhas = () => {
    dispatch(getCampanhas({ search, limit }));
  };

  const handleSearch = (text) => {
    setSearch(text);
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={'Campanhas de Vendas'}
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' },
                { path: '/campanhas/itens', name: 'Campanhas de vendas', current: true }
              ]}
            />
          )}
          extra={InfoUsuario.perm('campanhasVendasCadastro') && (
            <Button size='small' context='header' type='primary' onClick={() => history.push('/campanhas/cadastro/-1')}>Nova campanha</Button>
          )}
        >
          <SearchBar 
            initialValue={search} 
            onSearch={handleSearch}
          />
          <Column gap='8px'>
            <ListStyle
              itemLayout='vertical'
              size='large'
              dataSource={campaigns}
              loading={loading}
              renderItem={(item) => (
                <FillListItem onClick={() => history.push(`/campanhas/cadastro/${item.externalId}`)} active={item.active}>
                  <Fill>
                    <FlexRow>
                      <Column gap='4px'>
                        <CampaignName strong>
                          <GapRow>
                            {item.type === 'POS' ? <PositivationIcon type='thumbs-up'/> : <SalesIcon type='dollar-outlined'/>}
                            {item.name}
                          </GapRow>
                        </CampaignName>
                        <PeriodText>Período: {formatDate(item.startAt)} até {formatDate(item.endAt)}</PeriodText>
                      </Column>
                      <CampaignImage src={item.image || DEFAULT_COVER_IMAGE}/>
                      <View onClick={(e) => (e.stopPropagation())}> 
                        <Dropdown
                          key="more"
                          overlay={(
                            <Menu>
                              <Menu.Item
                                onClick={(e) => { e.domEvent.stopPropagation(); history.push(`/campanhas/cadastro/${item.externalId}-duplicar`); }} 
                              >
                                <Button type="icon" icon={<Icon.Feather type="copy" />}>
                                  Duplicar
                                </Button>                    
                              </Menu.Item>
                            </Menu> 
                          )}
                        >
                          <Button type="icon">
                            <Icon.MDI type="dots-vertical" />
                          </Button>
                        </Dropdown>
                      </View>
                    </FlexRow>
                  </Fill>
                </FillListItem>
              )}
            />
            {total > limit && (
              <AlignedCenterButton
                type='secondary' 
                icon={<Icon.ReloadOutlined/>} 
                loading={loading} 
                onClick={() => setLimit(limit + 50)}
              >
              Carregar mais
              </AlignedCenterButton>
            )}
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}