import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getClassifications as getClassificationsSdk,
  getClassification as getClassificationSdk,
  getAllClassificationsWithOptions as getAllClassificationsWithOptionsSdk,
  saveClassification as saveClassificationSdk,
  updateClassification as updateClassificationSdk,
  getClassificationsTicket as getClassificationsTicketSdk,
  postTicketClassificationOption as postTicketClassificationOptionSdk,
  putTicketClassificationOption as putTicketClassificationOptionSdk,
  delTicketClassificationOption as delTicketClassificationOptionSdk,
} from '@sdk/Classificacoes';

export const Types = {
  GET_CLASSIFICATIONS: 'GET_CLASSIFICATIONS',
  GET_CLASSIFICATIONS_SUCCESS: 'GET_CLASSIFICATIONS_SUCCESS',
  GET_CLASSIFICATIONS_ERROR: 'GET_CLASSIFICATIONS_ERROR',

  GET_CLASSIFICATION: 'GET_CLASSIFICATION',
  GET_CLASSIFICATION_SUCCESS: 'GET_CLASSIFICATION_SUCCESS',
  GET_CLASSIFICATION_ERROR: 'GET_CLASSIFICATION_ERROR',

  GET_CLASSIFICATIONS_WITH_OPTIONS: 'GET_CLASSIFICATIONS_WITH_OPTIONS',
  GET_CLASSIFICATIONS_WITH_OPTIONS_SUCCESS: 'GET_CLASSIFICATIONS_WITH_OPTIONS_SUCCESS',
  GET_CLASSIFICATIONS_WITH_OPTIONS_ERROR: 'GET_CLASSIFICATIONS_WITH_OPTIONS_ERROR',

  SAVE_CLASSIFICATION: 'CLASSIFICACOES/SAVE_CLASSIFICATION',
  SAVE_CLASSIFICATION_SUCCESS: 'CLASSIFICACOES/SAVE_CLASSIFICATION_SUCCESS',
  SAVE_CLASSIFICATION_ERROR: 'CLASSIFICACOES/SAVE_CLASSIFICATION_ERROR',

  GET_CLASSIFICATIONS_TICKET: 'GET_CLASSIFICATIONS_TICKET',
  GET_CLASSIFICATIONS_TICKET_SUCCESS: 'GET_CLASSIFICATIONS_TICKET_SUCCESS',
  GET_CLASSIFICATIONS_TICKET_ERROR: 'GET_CLASSIFICATIONS_TICKET_ERROR',

  POST_TICKET_CLASSIFICATION_OPTION: 'POST_TICKET_CLASSIFICATION_OPTION',
  POST_TICKET_CLASSIFICATION_OPTION_SUCCESS: 'POST_TICKET_CLASSIFICATION_OPTION_SUCCESS',
  POST_TICKET_CLASSIFICATION_OPTION_ERROR: 'POST_TICKET_CLASSIFICATION_OPTION_ERROR',

  PUT_TICKET_CLASSIFICATION_OPTION: 'PUT_TICKET_CLASSIFICATION_OPTION',
  PUT_TICKET_CLASSIFICATION_OPTION_SUCCESS: 'PUT_TICKET_CLASSIFICATION_OPTION_SUCCESS',
  PUT_TICKET_CLASSIFICATION_OPTION_ERROR: 'PUT_TICKET_CLASSIFICATION_OPTION_ERROR',

  DEL_TICKET_CLASSIFICATION_OPTION: 'DEL_TICKET_CLASSIFICATION_OPTION',
  DEL_TICKET_CLASSIFICATION_OPTION_SUCCESS: 'DEL_TICKET_CLASSIFICATION_OPTION_SUCCESS',
  DEL_TICKET_CLASSIFICATION_OPTION_ERROR: 'DEL_TICKET_CLASSIFICATION_OPTION_ERROR',

  RESET: 'RESET',
  RESET_SUCCESS_CLASSIFICACAO: 'CLASSIFICACOES/RESET_SUCCESS_CLASSIFICACAO',
};

export const getClassifications = () => ({ type: Types.GET_CLASSIFICATIONS });
export const getClassificationsSuccess = (classifications) => ({ type: Types.GET_CLASSIFICATIONS_SUCCESS, classifications });
export const getClassificationsError = (error) => ({ type: Types.GET_CLASSIFICATIONS_ERROR, error });

export const getClassification = (idClassification) => ({ type: Types.GET_CLASSIFICATION, idClassification });
export const getClassificationSuccess = (classificationOptions) => ({ type: Types.GET_CLASSIFICATION_SUCCESS, classificationOptions });
export const getClassificationError = (error) => ({ type: Types.GET_CLASSIFICATION_ERROR, error });

export const getAllClassificationsWithOptions = () => ({ type: Types.GET_CLASSIFICATIONS_WITH_OPTIONS });
export const getAllClassificationsWithOptionsSuccess = (classificationsWithOptions) => ({ type: Types.GET_CLASSIFICATIONS_WITH_OPTIONS_SUCCESS, classificationsWithOptions });
export const getAllClassificationsWithOptionsError = (error) => ({ type: Types.GET_CLASSIFICATIONS_WITH_OPTIONS_ERROR, error });

export const saveClassification = (classification) => ({ type: Types.SAVE_CLASSIFICATION, classification });
export const saveClassificationSuccess = (data) => ({ type: Types.SAVE_CLASSIFICATION_SUCCESS, data });
export const saveClassificationError = (error) => ({ type: Types.SAVE_CLASSIFICATION_ERROR, error });

export const getClassificationsTicket = (idTicket) => ({ type: Types.GET_CLASSIFICATIONS_TICKET, idTicket });
export const getClassificationsTicketSuccess = (classificationsTicket) => ({ type: Types.GET_CLASSIFICATIONS_TICKET_SUCCESS, classificationsTicket });
export const getClassificationsTicketError = (error) => ({ type: Types.GET_CLASSIFICATIONS_TICKET_ERROR, error });

export const postTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => ({ type: Types.POST_TICKET_CLASSIFICATION_OPTION, idTicket, idClassification, idClassificationOption });
export const postTicketClassificationOptionSuccess = (classificationOption) => ({ type: Types.POST_TICKET_CLASSIFICATION_OPTION_SUCCESS, classificationOption });
export const postTicketClassificationOptionError = (error) => ({ type: Types.POST_TICKET_CLASSIFICATION_OPTION_ERROR, error });

export const putTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => ({ type: Types.PUT_TICKET_CLASSIFICATION_OPTION, idTicket, idClassification, idClassificationOption });
export const putTicketClassificationOptionSuccess = (classificationOption) => ({ type: Types.PUT_TICKET_CLASSIFICATION_OPTION_SUCCESS, classificationOption });
export const putTicketClassificationOptionError = (error) => ({ type: Types.PUT_TICKET_CLASSIFICATION_OPTION_ERROR, error });

export const delTicketClassificationOption = (idTicket, idClassification, idClassificationOption) => ({ type: Types.DEL_TICKET_CLASSIFICATION_OPTION, idTicket, idClassification, idClassificationOption });
export const delTicketClassificationOptionSuccess = (classificationOption) => ({ type: Types.DEL_TICKET_CLASSIFICATION_OPTION_SUCCESS, classificationOption });
export const delTicketClassificationOptionError = (error) => ({ type: Types.DEL_TICKET_CLASSIFICATION_OPTION_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccesClassificacao = () => ({ type: Types.RESET_SUCCESS_CLASSIFICACAO });

function* fetchGetClassifications(action) {
  try{
    const classifications = yield call(getClassificationsSdk);
    yield put(getClassificationsSuccess(classifications));
  }catch(err) {
    Notification.error(err.message);
    yield put(getClassificationsError(err));
  }
}

function* fetchGetClassification(action) {
  try{
    const classification = yield call(getClassificationSdk, action.idClassification);

    yield put(getClassificationSuccess(classification));
  }catch(err) {
    Notification.error(err.message);
    yield put(getClassificationError(err));
  }
}

function* fetchClassificationWithOptions() {
  try{
    const classificationsWithOptions = yield call(getAllClassificationsWithOptionsSdk);
    yield put(getAllClassificationsWithOptionsSuccess(classificationsWithOptions));
  }catch(err) {
    Notification.error(err.message);
    yield put(getAllClassificationsWithOptionsError(err));
  }
}

function* fetchSaveClassification(action) {
  try {
    const { classification } = action;
    let data = {};
    if(classification.id) {
      data = yield call(updateClassificationSdk, classification);
    } else {
      data = yield call(saveClassificationSdk, classification);
    }
    yield put(saveClassificationSuccess(data));
  } catch (err) {
    Notification.error(err.message);
    yield put(saveClassificationError(err));
  }
}

function* fetchGetClassificationsTicket(action) {
  try{
    
    const classificationsTicket = yield call(getClassificationsTicketSdk, action.idTicket);
    yield put(getClassificationsTicketSuccess(classificationsTicket));
  }catch(err) {
    Notification.error(err.message);
    yield put(getClassificationsTicketError(err));
  }
}

function* fetchPostTicketClassificationOption(action) {
  try{
    const { idTicket, idClassification, idClassificationOption } = action;
    const classificationOption = yield call(postTicketClassificationOptionSdk, idTicket, idClassification, idClassificationOption);
    yield put(postTicketClassificationOptionSuccess(classificationOption));
  }catch(err) {
    Notification.error(err.message);
    yield put(postTicketClassificationOptionError(err));
  }
}

function* fetchPutTicketClassificationOption(action) {
  try{
    const { idTicket, idClassification, idClassificationOption } = action;
    const classificationOption = yield call(putTicketClassificationOptionSdk, idTicket, idClassification, idClassificationOption);
    yield put(putTicketClassificationOptionSuccess(classificationOption));
  }catch(err) {
    Notification.error(err.message);
    yield put(putTicketClassificationOptionError(err));
  }
}

function* fetchDelTicketClassificationOption(action) {
  try{
    const { idTicket, idClassification, idClassificationOption } = action;
    const classificationOption = yield call(delTicketClassificationOptionSdk, idTicket, idClassification, idClassificationOption);
    yield put(delTicketClassificationOptionSuccess(classificationOption));
  }catch(err) {
    Notification.error(err.message);
    yield put(delTicketClassificationOptionError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CLASSIFICATIONS, fetchGetClassifications),
  takeLatest(Types.GET_CLASSIFICATION, fetchGetClassification),
  takeLatest(Types.GET_CLASSIFICATIONS_WITH_OPTIONS, fetchClassificationWithOptions),
  takeLatest(Types.SAVE_CLASSIFICATION, fetchSaveClassification),
  takeLatest(Types.GET_CLASSIFICATIONS_TICKET, fetchGetClassificationsTicket),
  takeLatest(Types.POST_TICKET_CLASSIFICATION_OPTION, fetchPostTicketClassificationOption),
  takeLatest(Types.PUT_TICKET_CLASSIFICATION_OPTION, fetchPutTicketClassificationOption),
  takeLatest(Types.DEL_TICKET_CLASSIFICATION_OPTION, fetchDelTicketClassificationOption),

];

const initialState = Map({
  classifications: List(),
  loadingClassifications: false,
  successClassifications: false,
  errorClassifications: false,

  classification: List(),
  loadingClassification: false,
  successClassification: false,
  errorClassification: false,

  classificationsWithOptions: List(),
  loadingClassificationsWithOptions: false,
  successClassificationsWithOptions: false,
  errorClassificationsWithOptions: false,

  loadingSaveClassification: false,
  successSaveClassification: false,
  errorSaveClassification: false,

  classificationsTicket: List(),
  loadingClassificationsTicket: false,
  successClassificationsTicket: false,
  errorClassificationsTicket: false,

  loadingSaveTicketClassificationOption: false,
  successSaveTicketClassificationOption: false,
  errorSaveTicketClassificationOption: false,

  loadingUpdateTicketClassificationOption: false,
  successUpdateTicketClassificationOption: false,
  errorUpdateTicketClassificationOption: false,

  loadingDeleteTicketClassificationOption: false,
  successDeleteTicketClassificationOption: false,
  errorDeleteTicketClassificationOption: false,
  
});

const handleGetClassifications = (state)=> {
  return state.set('loadingClassifications', true).set('successClassifications', false).set('errorClassifications', false);
};

const handleGetClassificationsSuccess = (state, action) => {
  const { classifications } = action;
  return state.set('classifications', classifications).set('loadingClassifications', false).set('successClassifications', true).set('errorClassifications', false);
};

const handleGetClassificationsError = (state, action) => {
  return state.set('loadingClassifications', false).set('successClassifications', false).set('errorClassifications', action.error);
};

const handleGetClassification = (state, action) => {
  return state.set('loadingClassification', true).set('successClassification', false).set('errorClassification', false);
};

const handleGetClassificationSuccess = (state, action) => {
  const { classificationOptions } = action;
  return state.set('classification', classificationOptions).set('loadingClassification', false).set('successClassification', true).set('errorClassification', false);
};

const handleGetClassificationError = (state, action) => {
  return state.set('loadingClassification', false).set('successClassification', false).set('errorClassification', action.error);
};

const handleGetClassificationWithOptions = (state, action) => {
  return state.set('loadingClassificationsWithOptions', true).set('successClassificationsWithOptions', false).set('errorClassificationsWithOptions', false);
};

const handleGetClassificationWithOptionsSuccess = (state, action) => {
  const { classificationsWithOptions } = action;
  return state.set('classificationsWithOptions', classificationsWithOptions).set('loadingClassificationsWithOptions', false).set('successClassificationsWithOptions', true).set('errorClassificationsWithOptions', false);
};

const handleGetClassificationWithOptionsError = (state, action) => {
  return state.set('loadingClassificationsWithOptions', false).set('successClassificationsWithOptions', false).set('errorClassificationsWithOptions', action.error);
};

const handleSaveClassification = (state, action) => {
  return state
    .set('loadingSaveClassification', true)
    .set('successSaveClassification', false)
    .set('errorSaveClassification', false);
};

const handleSaveClassificationSuccess = (state, action) => {
  return state
    .set('loadingSaveClassification', false)
    .set('successSaveClassification', true)
    .set('errorSaveClassification', false);
};

const handleSaveClassificationError = (state, action) => {
  return state
    .set('loadingSaveClassification', false)
    .set('successSaveClassification', false)
    .set('errorSaveClassification', action.error);
};

const handleGetClassificationsTicket = (state, action) => {
  return state.set('loadingClassificationsTicket', true).set('successClassificationsTicket', false).set('errorClassificationsTicket', false);
};

const handleGetClassificationsTicketSuccess = (state, action) => {
  const { classificationsTicket } = action;
  return state.set('classificationsTicket', classificationsTicket).set('loadingClassificationsTicket', false).set('successClassificationsTicket', true).set('errorClassificationsTicket', false);
};

const handleGetClassificationsTicketError = (state, action) => {
  return state.set('loadingClassificationsTicket', false).set('successClassificationsTicket', false).set('errorClassificationsTicket', action.error);
};

const handleSaveTicketClassificationOption = (state, action) => {
  return state.set('loadingSaveTicketClassificationOption', true).set('successSaveTicketClassificationOption', false).set('errorSaveTicketClassificationOption', false);
};

const handleSaveTicketClassificationOptionSuccess = (state, action) => {
  return state.set('loadingSaveTicketClassificationOption', false).set('successSaveTicketClassificationOption', true).set('errorSaveTicketClassificationOption', false);
};

const handleSaveTicketClassificationOptionError = (state, action) => {
  return state.set('loadingSaveTicketClassificationOption', false).set('successSaveTicketClassificationOption', false).set('errorSaveTicketClassificationOption', action.error);
};

const handleUpdateTicketClassificationOption = (state, action) => {
  return state.set('loadingUpdateTicketClassificationOption', true).set('successUpdateTicketClassificationOption', false).set('errorUpdateTicketClassificationOption', false);
};

const handleUpdateTicketClassificationOptionSuccess = (state, action) => {
  return state.set('loadingUpdateTicketClassificationOption', false).set('successUpdateTicketClassificationOption', true).set('errorUpdateTicketClassificationOption', false);
};

const handleUpdateTicketClassificationOptionError = (state, action) => {
  return state.set('loadingUpdateTicketClassificationOption', false).set('successUpdateTicketClassificationOption', false).set('errorUpdateTicketClassificationOption', action.error);
};

const handleDeleteTicketClassificationOption = (state, action) => {
  return state.set('loadingDeleteTicketClassificationOption', true).set('successDeleteTicketClassificationOption', false).set('errorDeleteTicketClassificationOption', false);
};

const handleDeleteTicketClassificationOptionSuccess = (state, action) => {
  return state.set('loadingDeleteTicketClassificationOption', false).set('successDeleteTicketClassificationOption', true).set('errorDeleteTicketClassificationOption', false);
};

const handleDeleteTicketClassificationOptionError = (state, action) => {
  return state.set('loadingDeleteTicketClassificationOption', false).set('successDeleteTicketClassificationOption', false).set('errorDeleteTicketClassificationOption', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case Types.GET_CLASSIFICATIONS:
      return handleGetClassifications(state, action);
    case Types.GET_CLASSIFICATIONS_SUCCESS:
      return handleGetClassificationsSuccess(state, action);
    case Types.GET_CLASSIFICATIONS_ERROR:
      return handleGetClassificationsError(state, action);

    case Types.GET_CLASSIFICATION:
      return handleGetClassification(state, action);
    case Types.GET_CLASSIFICATION_SUCCESS:
      return handleGetClassificationSuccess(state, action);
    case Types.GET_CLASSIFICATION_ERROR:
      return handleGetClassificationError(state, action);

    case Types.GET_CLASSIFICATIONS_WITH_OPTIONS:
      return handleGetClassificationWithOptions(state, action);
    case Types.GET_CLASSIFICATIONS_WITH_OPTIONS_SUCCESS:
      return handleGetClassificationWithOptionsSuccess(state, action);
    case Types.GET_CLASSIFICATIONS_WITH_OPTIONS_ERROR:
      return handleGetClassificationWithOptionsError(state, action);
      
    case Types.SAVE_CLASSIFICATION: return handleSaveClassification(state, action);
    case Types.SAVE_CLASSIFICATION_SUCCESS: return handleSaveClassificationSuccess(state, action);
    case Types.SAVE_CLASSIFICATION_ERROR: return handleSaveClassificationError(state, action);

    case Types.GET_CLASSIFICATIONS_TICKET:
      return handleGetClassificationsTicket(state, action);
    case Types.GET_CLASSIFICATIONS_TICKET_SUCCESS:
      return handleGetClassificationsTicketSuccess(state, action);
    case Types.GET_CLASSIFICATIONS_TICKET_ERROR:
      return handleGetClassificationsTicketError(state, action);

    case Types.POST_TICKET_CLASSIFICATION_OPTION:
      return handleSaveTicketClassificationOption(state, action);
    case Types.POST_TICKET_CLASSIFICATION_OPTION_SUCCESS:
      return handleSaveTicketClassificationOptionSuccess(state, action);
    case Types.POST_TICKET_CLASSIFICATION_OPTION_ERROR:
      return handleSaveTicketClassificationOptionError(state, action);

    case Types.PUT_TICKET_CLASSIFICATION_OPTION:
      return handleUpdateTicketClassificationOption(state, action);
    case Types.PUT_TICKET_CLASSIFICATION_OPTION_SUCCESS:
      return handleUpdateTicketClassificationOptionSuccess(state, action);
    case Types.PUT_TICKET_CLASSIFICATION_OPTION_ERROR:
      return handleUpdateTicketClassificationOptionError(state, action);

    case Types.DEL_TICKET_CLASSIFICATION_OPTION:
      return handleDeleteTicketClassificationOption(state, action);
    case Types.DEL_TICKET_CLASSIFICATION_OPTION_SUCCESS:
      return handleDeleteTicketClassificationOptionSuccess(state, action);
    case Types.DEL_TICKET_CLASSIFICATION_OPTION_ERROR:
      return handleDeleteTicketClassificationOptionError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    case Types.RESET_SUCCESS_CLASSIFICACAO: return state.set('successClassification', initialState.get('successClassification'));

    default:
      return state;
  }
}
