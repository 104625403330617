import styled from 'styled-components';
import { Icon, View, Image, Text } from '@components-teammove';

export const AlignCenter = styled(View)`
  flex-direction: row;
  justify-content: center;
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const GapFlexCol = styled(View)`
  gap: 10px;
  >:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 2px solid ${({ theme }) => theme.backgroundColor};
  }
  
  overflow-y: scroll;
  padding-right: 16px;
`;

export const ImagePreview = styled(Image)`
  cursor: pointer;
  max-width: 525px;
  max-height: 400px;
  align-self: center;
  border: 2px solid ${({ theme }) => theme.darkHighlightColor};
  border-radius: 9px;
  transition: all .125s;
  :hover{
    box-shadow: .1rem .1rem .5rem .1rem rgba(0, 0, 0, 0.2);
  }
`;

export const DetailView = styled(View)`
  gap: 4px;
  text-overflow: ellipsis;
`;

export const Label = styled(Text)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
    letter-spacing: .5px;
  }
`;

export const Answer = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const CopyIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
  align-self: center;
  cursor: pointer;
`;

export const AddressRow = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
`;

export const ContentCityStateCep = styled(AddressRow)`
  font-weight: 600;
`;

export const ContentMap = styled.div`
  background-color: #fff;
  height: 246px;
  width: 100%;
  border-radius: 12px;
`;

export const PhoneIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};
  cursor: pointer;
`;
