import React, { useState } from 'react';
import Tipos from './Tipos';
import Agrupadores from './Agrupadores';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Tabs, Button } from '@components-teammove';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TiposCadastro from './Tipos/Cadastro';
import AgrupadoresCadastro from './Agrupadores/Cadastro';
import { Session } from '@utils';

export default function ConfiguracoesUnidades() {
  const theme = useSelector(({ app }) => app.get('theme'));

  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_CAMPAIGNS_TAB') || 'Tipos');
  const [visibleRegisterTipo, setVisibleRegisterTipo] = useState(false);
  const [visibleRegisterAgrupador, setVisibleRegisterAgrupador] = useState(false);

  const handleButtonClick = ((data) => {
    if (data) {
      history.push(`?${currentTab === 'Tipos' ? 'tipo' : 'agrupador'}=` + data.id, data);
    }
    if (currentTab === 'Tipos') {
      setVisibleRegisterTipo(true);
    } else {
      setVisibleRegisterAgrupador(true);
    }
  });
  
  const ExtraTipo = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => handleButtonClick()}>Novo</Button>,
  ];

  const ExtraAgrupador = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => handleButtonClick()}>Novo</Button>,
  ];

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_CAMPAIGNS_TAB', newTab);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Campanhas'
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/campanhas', name: 'Configurações de Campanhas', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
          extra={currentTab === 'Tipos' ? <ExtraTipo/> : <ExtraAgrupador/>}
        >
          <Column gap='12px'>
            <Tabs options={[{ name: 'Tipos' }, { name: 'Agrupadores' }]} current={currentTab} onChange={handleChangeTab}/>
            {currentTab === 'Tipos' ? (
              <Tipos handleEditClick={handleButtonClick}/>
            ) : (
              <Agrupadores handleEditClick={handleButtonClick}/>
            )}
          </Column>

          {visibleRegisterTipo && (
            <TiposCadastro
              visible={visibleRegisterTipo}
              onCancel={() => setVisibleRegisterTipo(false)}
            />
          )}
          {visibleRegisterAgrupador && (
            <AgrupadoresCadastro
              visible={visibleRegisterAgrupador}
              onCancel={() => setVisibleRegisterAgrupador(false)}
            />
          )}
        </PageHeader>
      </Body>
    </App>  
  );
}