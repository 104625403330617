import React, { useEffect, useState } from 'react';
import { GoogleMap, Skeleton } from '@components-teammove';
import { ContentMap } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyClusterSituation } from '@ducks/gestaoCarteira';
import { verifyDate } from '../rules';

const MapCluster = ({ filters, search, status }) => {
  const dispatch = useDispatch();

  const companyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('companyClusterSituation'));
  const loadingCompanyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('loadingCompanyClusterSituation'));
  const successCompanyClusterSituation = useSelector(({ gestaoCarteira }) => gestaoCarteira.get('successCompanyClusterSituation'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const [markers, setMarkers] = useState();

  const year = parseInt(localStorage.getItem('YEAR'));
  const month = parseInt(localStorage.getItem('MONTH'));
  const cluster = parseInt(localStorage.getItem('CLUSTER'));
  const group = parseInt(localStorage.getItem('GROUP'));

  const initialLimit = 0;

  useEffect(() => {
    dispatch(getCompanyClusterSituation(group, `${verifyDate(year, month)}`, search, filters, cluster, initialLimit, status));       
  },[search, filters, successSave]);

  useEffect(() => {
    if(successCompanyClusterSituation) {
      const markers = companyClusterSituation.companies.filter(({ latitude, longitude }) => latitude && longitude).map((company) => {
        return {
          position: { lat: parseFloat(company.latitude), lng: parseFloat(company.longitude)  },
          ...company,
          key: company.id,
        };
        
      });
      setMarkers(markers);
    }
  }, [successCompanyClusterSituation]);

  return loadingCompanyClusterSituation ? <Skeleton.Map /> : (
    <ContentMap>
      <GoogleMap
        center={{ lat: 0, lng: 0 }} 
        zoom={3} 
        mapStyles={{ height: '100%', width: '100%', borderRadius: '12px', marginBottom: '12px' }} 
        markers={markers}
        dashClustersScreen
      />
    </ContentMap>
  );
};

export default MapCluster;