import styled from 'styled-components';

import { Icon, Button, View, Text, Row, Modal, Table } from '@components-teammove';

export const TextStyle = styled(Text)`
  &.ant-typography {
    color: ${({ theme })=> theme.textColor};
  }
  font-size: ${({ font })=> font && font};
  font-weight: ${({ weight })=> weight && weight};
  opacity: ${({ opacity })=> opacity && '.5'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ maxWidth }) => maxWidth && maxWidth};
`;

export const MainTicket = styled(Row)`
  border-radius: 18px;
  background-color: ${({ theme })=> theme.darkSecondaryColor};
  padding: 20px;
  justify-content: space-between;
`;

export const Status = styled(Row)`
  background-color: ${({ status, theme })=> status ? status : '#FD8509'};
  border-radius: 8px;
  width: 141px;
  padding: 7px 0 7px 0;
`;

export const StyleModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 20px 20px 20px !important;
  }
`;

export const LateCard = styled(Row)`
  border-radius: 22px;
  background-color: #FF3B30;
  width: 126px;
  padding: 7px 0 7px 0;
`;

export const IconWarning = styled(Icon.WarningFilled)`
  color: #ff9800 !important;
`;

export const FillView = styled(View)`
  height: 100%;
  justify-content: center;
  position: relative;
 color: ${({ theme })=> theme.textColor};
  /* > :first-child {
    position: absolute;
    margin: auto;
    transform: translateY(-50%);
    top: 50%;
  } */
`;

export const DetalheText = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  opacity: .5;
`;

export const RowSpaceBetween = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled(Button)`
  font-size: 14px !important;
`;

export const IconStar = styled(Icon.StarFilled)`
  color: var(--warning-color) !important;
`;

export const TableStyle = styled(Table)`
  &.ant-table {
    .ant-table-content {
     thead {
       > tr {
          > th {
            :hover {
             background-color: ${({ theme })=> theme.darkPrimaryColor} !important;
            }
          }
        }
      }
    }
  }
`;
