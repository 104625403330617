import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Row, Column, Text, HalfDoughnutChart } from '@components-teammove';
import { Container, Footer, StyleEmpty, StyledRow, Tag, VerticalLine } from './styles';

import { getConclusionOnTimeDash } from '@ducks/chamadosDashboard';

export default function OnTimeDeliveries({ currentFilters, currentParams }) {
  const dispatch = useDispatch();

  const conclusionOnTimeDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('conclusionOnTimeDash'));
  const loadingConclusionOnTimeDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('loadingConclusionOnTimeDash'));
  const successConclusionOnTimeDash = useSelector(({ chamadosDashboard }) => chamadosDashboard.get('successConclusionOnTimeDash'));
  
  useEffect(() => {
    if(currentParams.initialDate) {
      dispatch(getConclusionOnTimeDash({ ...currentFilters, ...currentParams }));
    }
  }, [currentFilters, currentParams]);

  return(
    loadingConclusionOnTimeDash ? (
      <Skeleton.Map/>
    ) : successConclusionOnTimeDash && (
      <Container gap='20px'>
        <Row>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Entregas </Text>
            <Text size='20px' weight='800' line='20px'>no prazo</Text>
          </Column>
        </Row>
        <VerticalLine/>
        { (!conclusionOnTimeDash?.quantityOnTime && !conclusionOnTimeDash?.quantityOutOfTime && !conclusionOnTimeDash?.percentOnTime && !conclusionOnTimeDash?.quantityOnTime) ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <Column gap='50px'>
              <Row gap='30px' justify='center' align='center'>
                <Column >
                  <HalfDoughnutChart missingPercent={conclusionOnTimeDash.percentOutOfTime} completedPercent={conclusionOnTimeDash.percentOnTime} completedPercentLabel='dentro do prazo'/>
                </Column>
                <Column width='40%' gap='8px'>
                  <Column>
                    <Text size='24px' weight='700'>{conclusionOnTimeDash?.quantityOnTime} tickets</Text>
                    <Text size='24px' weight='400'>Dentro do prazo</Text>
                  </Column>
                  {/* <Column>
                <Text size='16px' weight='600' lowOpacity>Tempo médio de conclusão</Text>
                <Text size='24px' weight='700'>?1d 14:37h</Text>
              </Column> */}
                </Column>
              </Row>
        
              <Footer>
                <StyledRow align='center' justify='space-beetween'>
                  <Row align='center' gap='12px'>
                    <Tag>{conclusionOnTimeDash?.percentOutOfTime.toFixed(0)}%</Tag>
                    <Text size='16px' weight='600'>{conclusionOnTimeDash.quantityOutOfTime} tickets fora do prazo</Text>
                  </Row>
              
                  {/* <Row align='center' gap='20px'>
                    <Column>
                      <Text size='16px' weight='600' lowOpacity>Média de atraso</Text>
                      <Text size='24px' weight='700'>{conclusionOnTimeDash.days}d {conclusionOnTimeDash.hours}:{conclusionOnTimeDash.minutes}h</Text>
                    </Column> */}
                  {/* <Column>
                  <Text size='16px' weight='600' lowOpacity>Tempo médio de conclusão</Text>
                  <Text size='24px' weight='700'>?1d 14:37h</Text>
                </Column> */}
                  {/* </Row> */}
                </StyledRow>
              </Footer>
            </Column>
          )} 
      </Container>
    ) 
  );
}