import moment from 'moment';

const integer = (a, b, field) => {
  return a[field] - b[field];
};

const string = (a, b, field) => {
  a = String(a[field]).toLowerCase();
  b = String(b[field]).toLowerCase();

  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const datetime = (a, b, field) => {
  a = moment(a[field]);
  b = moment(b[field]);

  if (a.isBefore(b)) return 1;
  if (a.isAfter(b)) return -1;
  return 0;
};

export default {
  integer,
  string,
  datetime,
};
