import React from 'react';
import { CloseIcon, DropdownContainerRangePickerStyles, DropdownContainerStyles, StyledRangeTimePicker, StyledTimePicker } from './styles';

const TimePicker = ({ ...props }) => (
  <>
    <StyledTimePicker {...props} clearIcon={<CloseIcon type='X'/>}/>
    <DropdownContainerStyles/>
  </>
);

TimePicker.defaultProps = {
  allowClear: true,
};

TimePicker.RangePicker = ({ ...props }) => (
  <>
    <StyledRangeTimePicker {...props}/>
    <DropdownContainerRangePickerStyles/>
  </>
);

export default TimePicker;