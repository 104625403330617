import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getExtraFields as getExtraFieldsSdk,
  postExtraFields as postExtraFieldsSdk,
  putExtraFields as putExtraFieldsSdk,
  updateOrder as updateOrderSdk,
} from '@sdk/ExtraFields';

//Action Types
export const Types = {
  GET_EXTRA_FIELDS: 'PRODUCTS/GET_EXTRA_FIELDS',
  GET_EXTRA_FIELDS_SUCCESS: 'PRODUCTS/GET_EXTRA_FIELDS_SUCCESS',
  GET_EXTRA_FIELDS_ERROR: 'PRODUCTS/GET_EXTRA_FIELDS_ERROR',

  POST_EXTRA_FIELDS: 'PRODUCTS/POST_EXTRA_FIELDS',
  POST_EXTRA_FIELDS_SUCCESS: 'PRODUCTS/POST_EXTRA_FIELDS_SUCCESS',
  POST_EXTRA_FIELDS_ERROR: 'PRODUCTS/POST_EXTRA_FIELDS_ERROR',

  PUT_EXTRA_FIELDS: 'PRODUCTS/PUT_EXTRA_FIELD',
  PUT_EXTRA_FIELDS_SUCCESS: 'PRODUCTS/PUT_EXTRA_FIELDS_SUCCESS',
  PUT_EXTRA_FIELDS_ERROR: 'PRODUCTS/PUT_EXTRA_FIELDS_ERROR',

  UPDATE_ORDER: 'PRODUCTS/UPDATE_ORDER',
  UPDATE_ORDER_SUCCESS: 'PRODUCTS/UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_ERROR: 'PRODUCTS/UPDATE_ORDER_ERROR',

  RESET: 'PRODUCTS/RESET',
};

//Action Creators
export const getExtraFields = () => ({ type: Types.GET_EXTRA_FIELDS });
export const getExtraFieldsSuccess = (extraFields) => ({ type: Types.GET_EXTRA_FIELDS_SUCCESS, extraFields });
export const getExtraFieldsError = (error) => ({ type: Types.GET_EXTRA_FIELDS_ERROR, error });

export const postExtraFields = (extraFields) => ({ type: Types.POST_EXTRA_FIELDS, extraFields });
export const postExtraFieldsSuccess = (extraFields) => ({ type: Types.POST_EXTRA_FIELDS_SUCCESS, extraFields });
export const postExtraFieldsError = (error) => ({ type: Types.POST_EXTRA_FIELDS_ERROR, error });

export const putExtraFields = (extraFields) => ({ type: Types.PUT_EXTRA_FIELDS, extraFields });
export const putExtraFieldsSuccess = (extraFields) => ({ type: Types.PUT_EXTRA_FIELDS_SUCCESS, extraFields });
export const putExtraFieldsError = (error) => ({ type: Types.PUT_EXTRA_FIELDS_ERROR, error });

export const updateOrder = (extraFields) => ({ type: Types.UPDATE_ORDER, extraFields });
export const updateOrderSuccess = (extraFields) => ({ type: Types.UPDATE_ORDER_SUCCESS, extraFields });
export const updateOrderError = (error) => ({ type: Types.UPDATE_ORDER_ERROR, error });

export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetExtraFields(action) {
  try{
    
    const extraFields = yield call(getExtraFieldsSdk);
    yield put(getExtraFieldsSuccess(extraFields));
  } catch(err) {
    Notification.error(err.message);
    yield put(getExtraFieldsError(err));
  }
}

function* fetchPostExtraFields(action) {
  try{
    let { extraFields } = action;
    extraFields = yield call(postExtraFieldsSdk, extraFields);
    yield put(postExtraFieldsSuccess(extraFields));
  } catch (err) {
    Notification.error(err.message);
    yield put(postExtraFieldsError(err));
  }
}
  
function* fetchPutExtraFields(action) {
  try{
    let { extraFields } = action;

    yield put(getExtraFieldsSuccess(extraFields));
    extraFields = yield call(putExtraFieldsSdk, extraFields);

    yield put(putExtraFieldsSuccess(extraFields)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(putExtraFieldsError(err));
  }
}

function* fetchUpdateOrder(action) {
  try{
    let { extraFields } = action;

    yield put(getExtraFieldsSuccess(extraFields));
    extraFields = yield call(updateOrderSdk, extraFields);

    yield put(updateOrderSuccess(extraFields)); 
  } catch(err) {
    Notification.error(err.message);
    yield put(updateOrderError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_EXTRA_FIELDS, fetchGetExtraFields),
  takeLatest(Types.POST_EXTRA_FIELDS, fetchPostExtraFields),
  takeLatest(Types.PUT_EXTRA_FIELDS, fetchPutExtraFields),
  takeLatest(Types.UPDATE_ORDER, fetchUpdateOrder),
];

// Reducer
const initialState = Map({
  extraFields: List(),
  loadingExtraFields: false,
  successExtraFields: false,
  errorExtraFields: false,

  loadingExtraFieldsSave: false,
  successExtraFieldsSave: false,
  errorExtraFieldsSave: false,

  loadingUpdateOrder:false,
  successUpdateOrder:false,
  errorUpdateOrder:false,
});

const handleGetExtraFields = (state, action) => {
  return state.set('loadingExtraFields', true).set('successExtraFields', false).set('errorExtraFields', false);
};
    
const handleGetExtraFieldsSuccess = (state, action) => {
  const { extraFields } = action;
  return state.set('extraFields', extraFields).set('loadingExtraFields', false).set('successExtraFields', true).set('errorExtraFields', false);
};
    
const handleGetExtraFieldsError = (state, action) => {
 
  return state.set('loadingExtraFields', false).set('successExtraFields', false).set('errorExtraFields', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingExtraFieldsSave', true).set('successExtraFieldsSave', false).set('errorExtraFieldsSave', false);
};
  
const handleSaveSuccess = (state, action) => {
  return state.set('loadingExtraFieldsSave', false).set('successExtraFieldsSave', true).set('errorExtraFieldsSave', false);
};
  
const handleSaveError = (state, action) => {
  return state.set('loadingExtraFieldsSave', false).set('successExtraFieldsSave', false).set('errorExtraFieldsSave', action.error);
};

const handleUpdate = (state, action) => {
  return state.set('loadingUpdateOrder', true).set('successUpdateOrder', false).set('errorUpdateOrder', false);
};

const handleUpdateSuccess = (state, action) => {
  return state.set('loadingUpdateOrder', false).set('successUpdateOrder', true).set('errorUpdateOrder', false);
};

const handleUpdateError = (state, action) => {
  return state.set('loadingUpdateOrder', false).set('successUpdateOrder', false).set('errorUpdateOrder', action.error);
};

const handleReset = (action, state) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_EXTRA_FIELDS:
      return handleGetExtraFields(state, action);
    case Types.GET_EXTRA_FIELDS_SUCCESS:
      return handleGetExtraFieldsSuccess(state, action);
    case Types.GET_EXTRA_FIELDS_ERROR:
      return handleGetExtraFieldsError(state, action);

    case Types.POST_EXTRA_FIELDS:
      return handleSave(state, action);
    case Types.POST_EXTRA_FIELDS_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_EXTRA_FIELDS_ERROR:
      return handleSaveError(state, action);
    
    case Types.PUT_EXTRA_FIELDS:
      return handleSave(state, action);
    case Types.PUT_EXTRA_FIELDS_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.PUT_EXTRA_FIELDS_ERROR:
      return handleSaveError(state, action);
    
    case Types.UPDATE_ORDER:
      return handleUpdate(state, action);
    case Types.UPDATE_ORDER_SUCCESS:
      return handleUpdateSuccess(state, action);
    case Types.UPDATE_ORDER_ERROR:
      return handleUpdateError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    
    default:
      return state;
  }
}