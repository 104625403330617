import React from 'react';
import { useSelector } from 'react-redux';

import { Text, Column } from '@components-teammove';
import { Chart as ChartJS, ArcElement, Tooltip, Legend  } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getCustomColor } from '@utils';
import { ContentGraphic, Percentage0, Percentage100, Title } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend );

export const HalfDoughnutChart = ({ completedPercent, missingPercent, completedPercentLabel }) => {
  const theme = useSelector(({ app }) => app.get('theme'));

  const data = {
    datasets: [
      {
        data: [(completedPercent), (missingPercent)],
        backgroundColor: [
          getCustomColor('--success-color'),
          theme['@global-background-color']
        ],
        display: true,
        borderWidth: 0, 
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: false
      },
      datalabels:{
        display: false,
      },
    },
    cutout: '80%',
    circumference: 180,
    rotation: -90,
    radius: 140,
    aspectRatio: 2,
    maintainAspectRatio: true,
    responsive: true
  };

  return(
    <ContentGraphic>
      <Doughnut
        data={data}
        options={options}
      />
      <Title>
        <Column align='center' width='100%'>
          <Text size='40px' weight='700'>{completedPercent.toFixed(0)}%</Text>
          <Text size='18px' weight='700'>{completedPercentLabel}</Text>
        </Column>
      </Title>
      <Percentage0><Text size='24px' weight='400' lowOpacity>0%</Text></Percentage0>
      <Percentage100><Text size='24px' weight='400' lowOpacity>100%</Text></Percentage100>
    </ContentGraphic> 
  );
};

export default HalfDoughnutChart;