import React from 'react';
import styled, { css } from 'styled-components';
import { Image, View  } from '@components';
import { Icon } from '@components-teammove';

const IconeContainer = styled(View)`
  height: 55px;
  width: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  ${(props) => (!props.background) && css`
    border: 1px solid #CCC;
  `}
`;

const IconFont = styled(Icon.Font)`
  ${(props) => (props.color) && css`
    color: ${props.color} !important;
  `}
  ${(props) => (props.size) && css`
    font-size: ${props.size * 1.5}em; /*Mesma lógica do material design icons*/
  `}
`;

const ExtraImage = (image) => (
  <View height={80} maxWidth={110} justifyContent="center" alignItems="center">
    <Image src={image} alt="Imagem do Conteúdo" />
  </View>
);

const ExtraIcon = (icon, iconColor, background, size = 1.3) => (
  <IconeContainer background={background}>
    <Icon.MDI type={icon} color={iconColor} size={size} />
  </IconeContainer>
);

const ExtraFont = (icon, iconColor, background, size = 1.3) => (
  <IconeContainer background={background}>
    <IconFont type={icon} color={iconColor} size={size} />
  </IconeContainer>
);

export default function ConteudoIcone ({ item }) {
  if (item.imagem) {
    return ExtraImage(item.imagem);
  }
  if (item.icone) {
    return ExtraIcon(item.icone, item.iconeCor, item.iconeCorFundo);
  }
  if (item.tipo === 'PDF') {
    return ExtraIcon('adobe-acrobat', 'white', '#C01E07', item.size);
  }
  if (item.tipo === 'ARQUIVO') {
    switch (item.arquivoTipo) {
      case 'PDF':
        return ExtraIcon('adobe-acrobat', 'white', '#C01E07', item.size);
      case 'WORD':
        return ExtraFont('microsoftword', 'white', '#2B5796', item.size);
      case 'EXCEL':
        return ExtraFont('microsoftexcel', 'white', '#1E7145', item.size);
      case 'POWERPOINT':
        return ExtraFont('microsoftpowerpoint', 'white', '#D04526', item.size);
      default:
        return ExtraIcon('file-document', 'white', 'grey');
    }
  }

  return ExtraIcon('image-off', 'rgba(0,0,0,.2)');
}