import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { getHomeConfiguracaoInfo as getHomeConfiguracaoInfoSdk } from '@sdk/Home';
import { Notification } from '@utils';

//Action Types
export const Types = {
  GET_HOME_CONFIGURACOES_INFO: 'HOME/GET_CONFIGURACOES_INFO',
  GET_HOME_CONFIGURACOES_INFO_SUCCESS: 'HOME/GET_CONFIGURACOES_INFO_SUCCESS',
  GET_HOME_CONFIGURACOES_INFO_ERROR: 'HOME/GET_CONFIGURACOES_INFO_ERROR',
};

//Action Creators
export const getConfiguracoesInfo = () => ({ type: Types.GET_HOME_CONFIGURACOES_INFO });
export const getConfiguracoesInfoSuccess = (configuracoes) => ({ type: Types.GET_HOME_CONFIGURACOES_INFO_SUCCESS, configuracoes });
export const getConfiguracoesInfoError = (error) => ({ type: Types.GET_HOME_CONFIGURACOES_INFO_ERROR, error });

//saga
function* fetchGetConfiguracoesInfo(action) {
  try {
    const configuracoes = yield call(getHomeConfiguracaoInfoSdk);
    yield put(getConfiguracoesInfoSuccess(configuracoes));
  } catch (err) {
    Notification.error(err.message);
    yield put(getConfiguracoesInfoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_HOME_CONFIGURACOES_INFO, fetchGetConfiguracoesInfo),
];

// Reducer
const initialState = Map({
  configuracoes: List(),
  loadingConfiguracoes: false,
  successConfiguracoes: false,
  errorConfiguracoes: false,
});

const handleGetConfiguracoesInfo = (state, action) => {
  return state
    .set('loadingConfiguracoes', true)
    .set('successConfiguracoes', false)
    .set('errorConfiguracoes', false);
};
  
const handleGetConfiguracoesInfoSuccess = (state, action) => {
  return state
    .set('configuracoes', List(action.configuracoes))
    .set('loadingConfiguracoes', false)
    .set('successConfiguracoes', true)
    .set('errorConfiguracoes', false);
};
  
const handleGetConfiguracoesInfoError = (state, action) => {
  return state
    .set('loadingConfiguracoes', false)
    .set('successConfiguracoes', false)
    .set('errorConfiguracoes', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_HOME_CONFIGURACOES_INFO: return handleGetConfiguracoesInfo(state, action);
    case Types.GET_HOME_CONFIGURACOES_INFO_SUCCESS: return handleGetConfiguracoesInfoSuccess(state, action);
    case Types.GET_HOME_CONFIGURACOES_INFO_ERROR: return handleGetConfiguracoesInfoError(state, action);
  
    default: return state;
  }
}