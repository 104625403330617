import React from 'react';
import { Radio } from '@components';
import styled, { css } from 'styled-components';
import { hexToRGBA } from '@utils';

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

const RadioStyled = styled(Radio)`
  margin-bottom: 10px;
  white-space: initial;
  padding: 2px 5px;
  border-radius: 3px;
  width: fit-content;

  ${({ acerto, erro }) => {
  if (acerto) {
    return css`
        background-color: ${() => hexToRGBA('#66CC66', 0.1)};
      `;
  } else if (erro) {
    return css`
        background-color: ${() => hexToRGBA('#CC4444', 0.1)};
      `;
  }
}}
`;

export default function MultiplaEscolha({ data }) {
  const { opcoes, valor } = data;
  const opcaoCorreta = opcoes.find((op) => op.correta);

  return (
    <RadioGroup defaultValue={valor}>
      {opcoes.map((opcao) => {
        return (
          <RadioStyled
            key={opcao.id}
            value={opcao.id}
            acerto={opcaoCorreta.id === opcao.id}
            erro={opcaoCorreta.id !== valor && opcao.id === valor}
          >
            {opcao.opcao}
          </RadioStyled>
        );
      })}
    </RadioGroup>
  );
}
